import React, { useState, useEffect, useCallback } from 'react';
import api from "../../../services/api";
import { ContainerModal, YesNoContainerGeral } from "../../crm/add/styles"
import { store } from "react-notifications-component";
import Button from '../../button/button_v2';
import CardRight from '../../cardRight';
import InputV2 from '../../input/input_v2';
import Interruptor from '../../interruptor';
import SelectV2 from '../../select/select_v2';
import TextAreaV2 from '../../textarea/text_area_v2';


const AddCrm = ({ ...props }) => {

    const [showAddClient, setShowAddClient] = useState(false);
    const [operacao, setOperacao] = useState('');
    const [operacoes, setOperacoes] = useState([])
    const [loadingOperacao, setLoadingOperacao] = useState(false);
    const [erroOperacao, setErroOperacao] = useState(false);
    const [disabledOperacao, setDisabledOperacao] = useState(false);


    const [etapa, setEtapa] = useState('');
    const [etapas, setEtapas] = useState([])
    const [loadingEtapa, setLoadingEtapa] = useState(false);
    const [erroEtapa, setErroEtapa] = useState(false);
    const [disabledEtapa, setDisabledEtapa] = useState(false);

    const [tipo, setTipo] = useState('');
    const [tipos, setTipos] = useState([])
    const [loadingTipo, setLoadingTipo] = useState(false);
    const [erroTipo, setErroTipo] = useState(false);
    const [disabledTipo, setDisabledTipo] = useState(false);
    const [salvarAgenda, setSalvarAgenda] = useState(false);

    const [resultado, setResultado] = useState('');
    const [resultados, setResultados] = useState([])
    const [loadingResultado, setLoadingResultado] = useState(false);
    const [erroResultado, setErroResultado] = useState(false);
    const [disabledResultado, setDisabledResultado] = useState(false);

    const [numero, setNumero] = useState('');
    const [oportunidade, setOportunidade] = useState('');
    const [descricao, setDescricao] = useState('');
    const [fazenda, setFazenda] = useState('');
    const [produtor, setProdutor] = useState('');
    const [produtorCliente, setProdutorCliente] = useState('');
    const [erroProdutorCliente, setErroProdutorCliente] = useState(false);
    const [fazendaCliente, setFazendaCliente] = useState('');
    const [erroFazendaCliente, setErroFazendaCliente] = useState(false);
    const [loadingProdutor, setLoadingProdutor] = useState(false);
    const [loadingFazenda, setLoadingFazenda] = useState(false);
    const [disabledFazenda, setDisabledFazenda] = useState(false);
    const [disabledProdutor, setDisabledProdutor] = useState(false);
    const [produtores, setProdutores] = useState([])
    const [fazendas, setFazendas] = useState([])
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [data, setData] = useState('');
    const [texto, setTexto] = useState('');
    const [showEventoComum, setShowEventoComum] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);

    const [errorFazenda, setErroFazenda] = useState(false);
    const [errorProdutor, setErroProdutor] = useState(false);
    const [erroEmail, setErroEmail] = useState(false);
    const [erroDescricao, setErroDescricao] = useState(false);
    const [erroTelefone, setErroTelefone] = useState(false);
    const [erroCelular, setErroCelular] = useState(false);
    const [erroOportunidade, setErroOportunidade] = useState(false);

    const [loading, setLoading] = useState(true) // inserir o loading no return

    const clearStates = () => {
        changeOperacao('')
        changeEtapa('')
        changeTipo('')
        changeOportunidade('')
        changeDescricao('')
        changeFazenda('')
        changeProdutor('')
        changeEmail('')
        changeTelefone('')
        changeCelular('')
    }

    const getOperacoes = async () => {
        try {
            setLoadingOperacao(true)
            setErroOperacao(false)
            const response = await api.get('/status')

            if (response) {
                setOperacoes(formatOperacoes(response.data.data))
                setLoadingOperacao(false)
            }
        } catch (error) {
            setLoadingOperacao(false)
            setErroOperacao(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeOperacao = (value) => {
        setOperacao(value)
    }

    const formatOperacoes = (values) => {
        let operacoes = [];

        values.map((operac) => {
            operacoes = [...operacoes, { value: operac.id, label: operac.descricao }];
        });

        return operacoes;
    }

    const getEtapas = async () => {
        try {
            setLoadingEtapa(true)
            setErroEtapa(false)
            const response = await api.get('/etapa')

            if (response) {
                setEtapas(formatEtapas(response.data.data))
                setLoadingEtapa(false)
            }
        } catch (error) {
            setLoadingEtapa(false)
            setErroEtapa(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeEtapa = (value) => {
        setEtapa(value)
    }

    const changeEvento = (value) => {
        setShowEventoComum(value)
    }

    const formatEtapas = (values) => {
        let etapas = [];

        values.map((tip) => {
            etapas = [...etapas, { value: tip.id, label: tip.descricao }];
        });

        return etapas;
    }

    const getTipos = async () => {
        try {
            setLoadingTipo(true)
            setErroTipo(false)
            const response = await api.get('/tipo')

            if (response) {
                setTipos(formatTipos(response.data.data))
                setLoadingTipo(false)
            }
        } catch (error) {
            setLoadingTipo(false)
            setErroTipo(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeTipo = (value) => {
        setTipo(value)
    }

    const formatTipos = (values) => {
        let tipos = [];

        values.map((tip) => {
            tipos = [...tipos, { value: tip.id, label: tip.descricao }];
        });

        return tipos;
    }

    const getResultados = async () => {
        try {
            setLoadingResultado(true)
            setErroResultado(false)
            const response = await api.get('/resultado')

            if (response) {
                setResultados(formatResultados(response.data.data))
                setLoadingResultado(false)
            }
        } catch (error) {
            setLoadingResultado(false)
            setErroResultado(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const getProdutores = async () => {
        setLoading(true)
        try {
            setLoadingProdutor(true)
            setErroProdutorCliente(false)
            const response = await api.get('/produtores')

            if (response) {
                setProdutores(formatProdutores(response.data.data))
                setLoadingProdutor(false)
            }
        } catch (error) {
            setLoadingProdutor(false)
            setErroProdutorCliente(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });

        }

    }

    const getFazendas = async (id) => {
        if (id) {
            try {
                setLoadingFazenda(true)
                setErroFazendaCliente(false)
                const response = await api.get(`/produtores/${id}/fazendas`)

                if (response) {
                    setFazendas(formatFazendas(response.data.data))
                    setLoadingFazenda(false)
                }
            } catch (error) {
                setLoadingFazenda(false)
                setErroFazendaCliente(true)
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}.`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
            }
        }

    }

    const formatResultados = (values) => {
        let resultados = [];

        values.map((result) => {
            resultados = [...resultados, { value: result.id, label: result.descricao }];
        });

        return resultados;
    }

    const formatProdutores = (values) => {
        let produtores = [];

        values.map((prod) => {
            produtores = [...produtores, { value: prod.id, label: prod.nome }];
        });

        return produtores;
    }

    const formatFazendas = (values) => {
        let fazendas = [];

        values.map((faze) => {
            fazendas = [...fazendas, { value: faze.id, label: faze.nome }];
        });

        return fazendas;
    }

    const changeOportunidade = (value) => {
        setOportunidade(value)
        
    }

    const changeDescricao = (value) => {
        setDescricao(value)
    }

    const changeFazenda = (value) => {
        setFazenda(value)
    }

    const changeProdutor = (value) => {
        setProdutor(value)
    }

    const changeEmail = (value) => {
        setEmail(value)
    }

    const changeTelefone = (value) => {
        setTelefone(value);
        setErroTelefone(false);
        setErroCelular(false);
    }

    const changeCelular = (value) => {
        setCelular(value)
        setErroTelefone(false);
        setErroCelular(false);
    }

    const saveOportunidade = async () => {
        try {
            setLoading(false);
            setSaveLoading(false)
            setProdutorCliente('')
            setFazendaCliente('')

            console.log(etapa.value, descricao, showEventoComum, email, fazenda, produtor, showEventoComum, produtorCliente.value, fazendaCliente.value)

            if (etapa.value != undefined && descricao != '' && oportunidade != '' && ((showEventoComum === false && email != '' && fazenda != '' && produtor != '' && (telefone != '' || celular != '')) || (showEventoComum === true && produtorCliente.value != undefined && fazendaCliente.value != undefined))) {
                setErroEtapa(false);
                setErroFazenda(false);
                setErroProdutor(false);
                setErroEmail(false);
                setErroDescricao(false);
                setErroFazendaCliente(false);
                setErroProdutorCliente(false);
                setErroTelefone(false);
                setErroCelular(false);
                setErroOportunidade(false);

                const payload = {
                    fazenda_nome: fazenda,
                    fazenda_cpf_cnpj: '',
                    fazenda_telefone: telefone,
                    fazenda_celular: celular,
                    produtor_nome: produtor,
                    produtor_email: email,
                    produtor_cpf_cnpj: '',
                    oportunidade: oportunidade,
                    etapa_id: etapa.value,
                    status: operacao.value,
                    descricao: descricao,
                    agro_producer_id: produtorCliente.value ? produtorCliente.value : '',
                    agro_farm_id: fazendaCliente.value ? fazendaCliente.value : ''
                }
    
                const response = await api.post('/oportunidade', payload)
    
                if (response) {
                    props.close();
                    props.getOp();
                    clearStates();
    
                }
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: 'Preencha os campos para cadastrar uma oportunidade',
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });

                setErroEtapa(true);
                setErroFazenda(true);
                setErroProdutor(true);
                setErroEmail(true);
                setErroDescricao(true);
                setErroFazendaCliente(true);
                setErroProdutorCliente(true);
                setErroTelefone(true);
                setErroCelular(true);
                setErroOportunidade(true);
                
                setSaveLoading(false)
            }
        } catch (error) {
            console.log(error);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            setSaveLoading(false)
        }

    }

    const changeProdutorCliente = (value)=>{
        setProdutorCliente(value)
    }

    const changeFazendaCliente = (value)=>{
        setFazendaCliente(value)
    }

    useEffect(() => {
        getOperacoes()
        getEtapas()
        getTipos()
        getProdutores()
    }, [])

    useEffect(()=>{
        if(produtorCliente){
            getFazendas(produtorCliente.value)
        }
    }, [produtorCliente]) 

    return (

        <>
            <ContainerModal>
                <InputV2
                    marginBottom='0px'
                    id="oportunidade"
                    value={oportunidade || ''}
                    label="Tipo de Oportunidade"
                    onChange={(e) => changeOportunidade(e)}
                    erro={erroOportunidade}
                />

                <SelectV2
                    marginBottom='0px'
                    name='operacao'
                    className="select"
                    label="Status da Oportunidade"
                    id='operacao'
                    value={1}
                    disabled={true}
                    loading={loadingOperacao}
                    erro={erroOperacao}
                    onChange={(e) => changeOperacao(e)}
                    options={operacoes}
                />

                <SelectV2
                    marginBottom='0px'
                    name='etapa'
                    className="select"
                    label="Etapa da Oportunidade"
                    id='etapa'
                    value={etapa.value || etapa}
                    disabled={disabledEtapa}
                    loading={loadingEtapa}
                    erro={erroEtapa}
                    onChange={(e) => changeEtapa(e)}
                    options={etapas}
                />
                <TextAreaV2
                    marginBottom='10px'
                    id="descricao"
                    value={descricao || ''}
                    name={"descricao"}
                    disabled={false}
                    digitavel={true}
                    label="Descrição"
                    maxlength="number"
                    onChange={(e) => changeDescricao(e)}
                    erro={erroDescricao}
                />

                <YesNoContainerGeral>
                    <span>
                        {'Oportunidade para cliente existente?'}
                    </span>

                    <Interruptor
                        value={showEventoComum}
                        onChange={(e) => changeEvento(e)}
                        disabled={false}
                    />
                </YesNoContainerGeral>

                {!showEventoComum ? (
                    <>
                        <InputV2
                            marginBottom='0px'
                            id="fazenda"
                            value={fazenda || ''}
                            label="Nome da Fazenda"
                            onChange={(e) => changeFazenda(e)}
                            erro={errorFazenda}
                        />
                        <InputV2
                            marginBottom='0px'
                            id="produtor"
                            value={produtor || ''}
                            label="Nome do Produtor"
                            onChange={(e) => changeProdutor(e)}
                            erro={errorProdutor}
                        />
                        <InputV2
                            marginBottom='0px'
                            id="email"
                            value={email || ''}
                            label="E-mail"
                            onChange={(e) => changeEmail(e)}
                            erro={erroEmail}
                        />
                        <InputV2
                            marginBottom='0px'
                            id="telefone"
                            name="telefone"
                            value={telefone || ''}
                            label="Telefone"
                            onChange={(e) => changeTelefone(e)}
                            erro={erroTelefone}
                        />
                        <InputV2
                            marginBottom='0px'
                            id="celular"
                            name="celular"
                            value={celular || ''}
                            label="Celular"
                            onChange={(e) => changeCelular(e)}
                            erro={erroCelular}
                        />
                    </>

                ) : (
                    <>
                        <SelectV2
                            marginBottom='0px'
                            name='produtor'
                            className="select"
                            label="Produtor"
                            id='produtor'
                            value={produtorCliente.value || produtorCliente}
                            disabled={disabledProdutor}
                            loading={loadingProdutor}
                            erro={erroProdutorCliente}
                            onChange={(e) => changeProdutorCliente(e)}
                            options={produtores}
                            selectUniqueOption={true}
                            buttonAddNew={false}
                            onClickAddNew={false}
                            isModal={true}
                            responsive={true}
                        />

                        <SelectV2
                            marginBottom='0px'
                            name='fazendas'
                            className="select"
                            label="Fazenda"
                            id='fazendas'
                            value={fazendaCliente.value || fazendaCliente}
                            disabled={disabledFazenda}
                            loading={loadingFazenda}
                            erro={erroFazendaCliente}
                            selectUniqueOption={true}
                            onChange={(e) => changeFazendaCliente(e)}
                            options={fazendas}
                        />
                    </>
                )}


                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='SALVAR OPORTUNIDADE'
                        outline={false}
                        disabled={false}
                        onClick={() => saveOportunidade()}
                        loading={saveLoading}
                    />
                </div>


            </ContainerModal>
        </>
    );
};

export default AddCrm;
