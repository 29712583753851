import React, { useState, useEffect, useCallback } from 'react';
import { Link } from "react-router-dom";
import api from "../../../services/api";
import { Card, Row, Title, CardResumo } from "../../crm/resumo/styles"
import { formatDateTime } from '../../../services/formatDate';
import NewsCrm from "../../../components/news-home/crm";

const ResumoCrm = ({ ...props }) => {

    const [crm, setCrm] = useState([]);
    const [loading, setLoading] = useState(true)

    const getCrm = async () => {
        try {
            setLoading(true)
            const response = await api.get('/oportunidade/resumo')

            if (response) {
                setLoading(false)
                const crm = response.data.data
                setCrm(crm)
            }
        } catch (error) {
            setLoading(false)

        }

    }

    useEffect(() => {
        getCrm()
    }, [])

    return (

        <>
            <Card
                width={props.width ? props.width : ''}
                height="270px"
            >
                {crm.length > 0 ? (
                    <>

                        <Row justifyContent='flex-start' marginLeft='0px' marginTop='8px'>
                            <Title  widht="auto" >Oportunidade de negocios</Title>
                            <Link to="/crm" style={{textDecoration: "none" }}>
                                <div className="ver_mais">Ver mais</div>
                            </Link>
                        </Row>

                        <hr width="100%"></hr>


                        {crm.map(crm => (
                            <CardResumo>
                                <div className="react-kanban-card">
                                    <div className="titulo"><b>Fazenda / Produtor: </b>{crm.fazenda_nome ? crm.fazenda_nome : crm.fazenda_cliente_nome} </div>
                                    <div className="corpo"><b>Produtor: </b>{crm.produtor_nome ? crm.produtor_nome : crm.produtor_cliente_nome} </div>
                                    <div className="corpo"><b>Data Oport.: </b>{crm.data_acao === "-" || crm.data_acao === null || crm.data_acao === "0000-00-00 00:00:00" ? 'Não possuí data de ação' : formatDateTime(crm.data_acao)}</div>
                                </div>
                            </CardResumo>
                        ))}
                    </>
                ) : (
                    <NewsCrm
                        title1={`Oportunidade de negócios`}
                        text={`Gerencie o relacionamento com os clientes.`}
                        data1={`99/99/9999`}
                        href1={`/crm`}
                    >
                    </NewsCrm>
                )}




            </Card>
        </>
    );
};

export default ResumoCrm;
