import React, { useState, useEffect } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/button_v2';
import Select from '../select/index';
import StyleModal from './stylesModalFilter1';
import api from "../../services/api";
import DotsLoader from "../loader";
import { store } from "react-notifications-component";
import ModalAlert from './modalGeneric';
import ModalPassword from './modal_password'
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';
import SelectV2 from '../select/select_v2';

export const USER = "@userinfo";
const ModalNew = (props) => {
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [responsavelNome, setResponsavelNome] = useState('');
    const [botao, setBotao] = useState('');
    const [agronomos, setAgronomos] = useState([]);
    const [optionValue, setOptionValue] = useState('');
    const [responsavelTipo, setResponsavelTipo] = useState('');
    const [loading, setLoading] = useState(false);
    const [link, setLink] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalAlert, setShowModalAlert] = useState(false);
    const [showModalBlock, setShowModalBlock] = useState(false);
    const [ciencia, setCiencia] = useState(false);
    const [senha, setSenha] = useState('');
    const [verifySign, setVerifySign] = useState(false);
    const [assinaturaDigital, setAssinaturaDigital] = useState(false);
    const [showModalSenha, setShowModalSenha] = useState(false);
    const [showModalCiencia, setShowModalCiencia] = useState(false);

    useEffect(() => {
        setOptionValue(props.optionValue);
        getAgronomos();
    }, [props.optionValue]);

    const getAgronomos = async () => {
        try {
            let response;
            switch (props.optionValue) {
                case 1:
                    response = await api.get(`/siagro/tecnico`);
                    console.log(response.data)
                    break;
                case 2:
                    response = await api.get(`/cidasc/tecnico`);
                    break;
                case 3:
                    response = await api.get(`/sisdev/tecnico`);
                    break;
                case 4:
                    response = await api.get(`/sidago/tecnico`);
                    break;
                case 5:
                    response = await api.get(`/sdars/tecnico`);
                    break;
                case 6:
                    response = await api.get(`/idaf/tecnico`);
                    break;
                default:
                    break;
            }
            let agronomosData = response.data.data;
            agronomosData = formatAgronomos(agronomosData);

            setAgronomos(agronomosData);
        } catch (error) {

        }
    };

    const formatAgronomos = (agronomosData) => {
        return agronomosData.map(agronomo => ({
            value: agronomo.id,
            label: agronomo.nome,
        }));
    };

    const getTipoAssinatura = async () => {
        try {
            const response = await api.post(`/siagro/tipoAssinaturaDigital`, {
                tecnicoId: responsavel,
                data_emissao_ini: dataInicial,
                data_emissao_fim: dataFinal,
            });

            console.log(response);

            if (response) {
                setVerifySign(true);
                setAssinaturaDigital(true);
                setShowModalSenha(true);
            }
        } catch (error) {
            setVerifySign(true);
            setAssinaturaDigital(false);
            setShowModalSenha(false);

            exportar();
        }
    };

    const sicca = async () => {
        const payload = {
            data_inicial: dataInicial,
            data_final: dataFinal,
        }

        try {
            const response = await api.post(`/sicca/emitir_relatorio`, payload);
            setLoading(false);
            window.location = response.data.data;
            setShowModalCiencia(false);
            props.closeModal();
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const exportar = async () => {

        let user = JSON.parse(localStorage.getItem(USER));
        
        const respostaFiltro = ({
            dataInicial: dataInicial, 
            dataFinal: dataFinal,
            agronomo_id: responsavel,
            agronomo_nome: responsavelNome
        });

        try {
            setLoading(true);

            if(props.optionValue === 1){
                if (user.contract_software_id === 6) {
                    if (ciencia === true) {

                        const payload = {
                            tecnicoId: responsavel,
                            data_emissao_ini: dataInicial,
                            data_emissao_fim: dataFinal,
                            senha: senha,
                            link: 1
                        }
            
                        const response = await api.post(`/siagro`, payload);
        
                        window.location = response.data.data.link;
                    } else {
                        setShowModalAlert(true);
                    }
                } else {
                    if (verifySign === false) {
                        getTipoAssinatura()
                    } else {
                        
                        const payload = {
                            tecnicoId: responsavel,
                            data_emissao_ini: dataInicial,
                            data_emissao_fim: dataFinal,
                            senha: assinaturaDigital === true ? senha : '',
                            link: 1
                        }
            
                        const response = await api.post(`/siagro`, payload);
        
                        window.location = response.data.data.link;
                        props.update(respostaFiltro);
                    }
                }
            }else if(props.optionValue === 2){
                if (user.contract_software_id === 6) {
                    setShowModalBlock(true);
                } else {
                    const payload = {
                        tecnicoId: responsavel,
                        data_emissao_ini: dataInicial,
                        data_emissao_fim: dataFinal
                    }
        
                    const response = await api.post(`/cidasc/exportar_receita`, payload);
                }

            }else if(props.optionValue === 3){
                if (user.contract_software_id === 6) {
                    setShowModalBlock(true);
                } else {
                    const payload = {
                        tecnicoId: responsavel,
                        data_emissao_ini: dataInicial,
                        data_emissao_fim: dataFinal
                    }
        
                    const response = await api.post(`/sisdev/exportar_receita`, payload);
                }
                
            }else if(props.optionValue === 4){
                if (user.contract_software_id === 6) {
                    setShowModalBlock(true);
                } else {
                    const payload = {
                        tecnicoId: responsavel,
                        data_emissao_ini: dataInicial,
                        data_emissao_fim: dataFinal
                    }
        
                    const response = await api.post(`/sidago/exportar_receitas`, payload);
    
                    if (response) {
                        if (response.status === 200) {
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${response.data.message}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        } else if (response.status === 206){
                            store.addNotification({
                                title: "Sucesso com alertas!",
                                message: `${response.data.message}`,
                                type: "warning",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 8000,
                                    onScreen: true
                                }
                            });
                        }
                    }
                }
    
            }else if(props.optionValue === 5){

                if (user.contract_software_id === 6) {
                    if (ciencia === true) {
                        const payload = {
                            tecnicoId: responsavel,
                            data_emissao_ini: dataInicial,
                            data_emissao_fim: dataFinal,
                            // responsavel_tecnico: responsavelTipo,
                            responsavel_tecnico: 1,
                            link: 1
                        }
            
                        // console.log(payload);
        
                        const response = await api.post(`/sdars`, payload)
                        window.location = response.data.data.link;


                    } else {
                        setShowModalAlert(true);
                    }
                } else {
                    const payload = {
                        tecnicoId: responsavel,
                        data_emissao_ini: dataInicial,
                        data_emissao_fim: dataFinal,
                        // responsavel_tecnico: responsavelTipo,
                        responsavel_tecnico: 1,
                        link: 1
                    }
        
                    // console.log(payload);
    
                    const response = await api.post(`/sdars`, payload)
                    window.location = response.data.data.link;

                    props.update(respostaFiltro);
                }

            }else if (props.optionValue === 6) {
                if (user.contract_software_id === 6) {
                    setShowModalBlock(true);
                } else {
                    const payload = {
                        tecnicoId: responsavel,
                        data_emissao_ini: dataInicial,
                        data_emissao_fim: dataFinal,
                        // responsavel_tecnico: responsavelTipo,
                        link: 1
                    }
    
                    const response = await api.post(`/idaf/exportar_receita`, payload)
                    window.location = response.data.data.link;
                }
            }else if (props.optionValue === 7) {
                if (user.contract_software_id === 6) {
                    setShowModalBlock(true);
                } else {
                    const payload = {
                        data_inicial: dataInicial,
                        data_final: dataFinal,
                        // responsavel_tecnico: responsavelTipo,
                        link: 1
                    }
    
                    const response = await api.post(`/adab/exportar_receita`, payload)
                    window.location = response.data.data.link;
                }
            }else if (props.optionValue === 8) {
                if (user.contract_software_id === 6) {
                    setShowModalBlock(true);
                } else {
                    try {
                        const payload = {
                            data_inicial: dataInicial,
                            data_final: dataFinal,
                        }

                        const response = await api.post(`/sicca/validar`, payload);
                        
                        if (response) {
                            sicca();
                        }
                    } catch (error) {
                        setShowModalCiencia(true);
                    }
                }
            }

            if (user.contract_software_id === 6) {
                if (props.optionValue === 1 || props.optionValue === 5) {
                    //PR ou RS
                    if (ciencia === true) {
                        props.update(respostaFiltro);
                        setLoading(false);
                    }
                } else {
                    setLoading(false);                
                }   
            } else {
                // this.props.update(respostaFiltro);
                setLoading(false);                
            }
        } catch (error) {
            setLoading(false);                
            setVerifySign(false);
            setAssinaturaDigital(false);
            setShowModalSenha(false);
            setSenha('');

            try {
                if (error.response.data.errors) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    const err = error.response.data.errors;
        
                    let msg = [];
        
                    if(err.length > 0){
                        err.map( e => {
                            e.messages.map(m => {
                                store.addNotification({
                                    title: "Erro!",
                                    message: m,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                }); 
        
                            })
                        })    
                    }
                }
                
            } catch (err) {

                if (!err.response) {
                    store.addNotification({
                        title: "Erro!",
                        message: "Erro ao gerar arquivo!",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    if (err.response.data) {
                        if(err.response.data.errors){
                            store.addNotification({
                                title: "Erro!",
                                message: `${err.response.data.errors}`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }else{
                            store.addNotification({
                                title: "Erro!",
                                message: "Erro ao gerar arquivo!",
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: "Erro ao gerar arquivo!",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        }

        // console.log(respostaFiltro);
    }

    const updateExport = () => {
        setCiencia(true);
        setShowModalAlert(false);

        setTimeout(() => {
            exportar();
        }, 200);
    };

    const onFocus = (e) => {
        e.currentTarget.type = 'date';
    };

    const onBlur = (e) => {
        e.currentTarget.type = 'text';
        e.currentTarget.placeholder = 'Data inicial';
    };

    const onBlur2 = (e) => {
        e.currentTarget.type = 'text';
        e.currentTarget.placeholder = 'Data final';
    };

    const validateAgronomo = (e) => {
        setResponsavel(e.value);
        setResponsavelNome(e.label);
    };

    const modalClose = () => {
        if (showModal) {
            setShowModal(false);
        }
    };

    const updateResposta = (resposta) => {
        if (resposta === 1) {
            exportar();
            modalClose();
        } else {
            // props.onHide();
        }
    };

    const changeDataInicial = (e) => {
        if (e.target.value.length <= 10) {
            setDataInicial(e.target.value);
        }
    };

    const changeDataFinal = (e) => {
        if (e.target.value.length <= 10) {
            setDataFinal(e.target.value);
        }
    };

    const updatePassword = (senha) => {
        setShowModalSenha(false);
        setSenha(senha);
    };

    useEffect(()=>{
        if (senha !== "") {
            exportar();
        }
    }, [senha])

    return (

        // <Modal
            //     {...this.props}
            //     size="lg"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //             {this.props.title} {this.props.option}
            //         </Modal.Title>
            //     </Modal.Header>

            //     <Modal.Body>
            //         <StyleModal>
            //             {/* {console.log(this.props)} */}

            //             {this.props.optionValue === 5 ? (<>
                            
            //                 <div className="tipoUsuario">
            //                     {/* <div className="option">
            //                         <input
            //                             type="radio"
            //                             name="tipo"
            //                             id="tecnico"
            //                             onChange={e => this.setState({responsavelTipo: 1})}
            //                         /><label for="tecnico"> Representante técnico </label>
            //                     </div> */}

            //                     {/* <div className="option">
            //                         <input
            //                             type="radio"
            //                             name="tipo"
            //                             id="comercial"
            //                             onChange={e => this.setState({responsavelTipo: 0})}
            //                         /><label for="comercial"> Representante comercial </label>
            //                     </div> */}
            //                 </div>
            //             </>):(null)}

            //             {this.props.optionValue !== 7 && (
            //                 <Select
            //                     name="produtor" 
            //                     placeholder="REPRESENTANTE TÉCNICO"
            //                     className="select1"
            //                     options={this.state.agronomos}
            //                     value={this.props.setFilter.agronomo_id}
            //                     onChange={e => this.validateAgronomo(e)}
            //                 />
            //             ) }
                        
            //             <label className="lbl-title">PERÍODO</label>
            //             <div className="data-filter">
            //                 <div className="input-data-filter">
            //                     <input
            //                         className="input-date"
            //                         type="text"
            //                         value={this.state.dataInicial}
            //                         placeholder="Data inicial"
                                    
            //                         onFocus = {this._onFocus} 
            //                         onBlur={this._onBlur}
            //                         onChange={e => this.changeDataInicial(e)}
            //                     />

            //                     <input
            //                         className="input-date"
            //                         type="text"
            //                         value={this.state.dataFinal}
            //                         placeholder="Data final"
            //                         mask="99/99/9999"
            //                         onFocus = {this._onFocus} 
            //                         onBlur={this._onBlur2}
            //                         onChange={e => this.changeDataFinal(e)}
                                    
            //                     />
            //                 </div>
            //             </div>

            //             <div className="footer" style={{ display: 'flex', flexDirection: 'row' }}>
            //                 <div className="note">
            //                     <label style={{ fontSize: "medium", fontWeight: "700", width: "70%", textAlign: 'center'  }}>A responsabilidade pelo envio dos dados para as defesas agropecuárias é do usuário. Tem certeza de que deseja gerar o arquivo?</label>
            //                 </div>


            //                 <div className="button-finish" style={{ width: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px' }}>
            //                     <Button
            //                         width='80%'
            //                         height='50px'
            //                         value='EXPORTAR'
            //                         outline={false}
            //                         disabled={false}
            //                         onClick={() => this.exportar()}
            //                         loading={this.state.loading}
            //                     />
            //                 </div>

            //             </div>
                        
                        
            //         </StyleModal>



            //         <ModalAlert
            //             show={this.state.showModalAlert}
            //             onHide={false}
            //         >
            //             <h4 style={{
            //                 textAlign: 'center'
            //             }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

            //             <p style={{
            //                 textAlign: 'center',
            //                 marginTop: '20px',
            //             }}>
            //                 Para não comprometer o controle de suas receitas agronômicas essa função só pode ser utilizada nos planos Light e Pro. Um arquivo de teste será gerado para que você conheça essa funcionalidade. Entre em contato com nosso suporte e faça o upgrade do seu plano para desbloquear esse recurso
            //             </p>

            //             <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
            //                 <Button
            //                     width='296px'
            //                     height='50px'
            //                     value='ENTENDI'
            //                     outline={false}
            //                     disabled={false}
            //                     onClick={() => this.updateExport()}
            //                     loading={false}
            //                 />
            //             </div>
            //         </ModalAlert>

            //         <ModalAlert
            //             show={this.state.showModalBlock}
            //             onHide={false}
            //         >
            //             <h4 style={{
            //                 textAlign: 'center'
            //             }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

            //             <p style={{
            //                 textAlign: 'center',
            //                 marginTop: '20px',
            //             }}>
            //                 Para não comprometer o controle de suas receitas agronômicas essa função só pode ser utilizada nos planos Light e Pro. Entre em contato com nosso suporte e faça o upgrade do seu plano para desbloquear esse recurso.
            //             </p>

            //             <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
            //                 <Button
            //                     width='296px'
            //                     height='50px'
            //                     value='ENTENDI'
            //                     outline={false}
            //                     disabled={false}
            //                     onClick={() => this.setState({ showModalBlock: false })}
            //                     loading={false}
            //                 />
            //             </div>
            //         </ModalAlert>

            //         <ModalPassword
            //             text={"Precisamos da sua senha para continuar com a exportação do arquivo de homologação."}
            //             show={this.state.showModalSenha}
            //             update={(e) => this.updatePassword(e)}
            //             onHide={() => console.log('FECHAR')}
            //         />

            //     </Modal.Body>


            // </Modal>
        <Modal
            {...props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
            width='60%'
        >
            <ModalTitle> {props.title} {props.option} </ModalTitle>

            <ModalBody textAlign='start'>
                <StyleModal>
                    {props.optionValue !== 7 && props.optionValue !== 8 && (
                        <SelectV2
                            name="produtor" 
                            id="representante"
                            label="Representante Técnico"
                            className="select1"
                            options={agronomos}
                            value={props.setFilter.agronomo_id}
                            onChange={e => validateAgronomo(e)}
                        />
                    )}
                    
                    <label className="lbl-title">PERÍODO</label>
                    <div className="data-filter">
                        <div className="input-data-filter">
                            <input
                                className="input-date"
                                type="text"
                                value={dataInicial}
                                placeholder="Data inicial"
                                onFocus = {onFocus} 
                                onBlur={onBlur}
                                onChange={e => changeDataInicial(e)}
                            />

                            <input
                                className="input-date"
                                type="text"
                                value={dataFinal}
                                placeholder="Data final"
                                mask="99/99/9999"
                                onFocus = {onFocus} 
                                onBlur={onBlur2}
                                onChange={e => changeDataFinal(e)}
                            />
                        </div>
                    </div>
                </StyleModal>
            </ModalBody>

            <ModalFooter>
                {/* <div className="footer" style={{ display: 'flex', flexDirection: 'row' }}> */}
                    <div className="note">
                        <label style={{ fontSize: "medium", fontWeight: "700", width: "70%", textAlign: 'center'  }}>A responsabilidade pelo envio dos dados para as defesas agropecuárias é do usuário. Tem certeza de que deseja gerar o arquivo?</label>
                    </div>


                    <div className="button-finish" style={{ width: '30%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginLeft: '10px',  marginTop: '10px', alignItems: 'center' }}>
                        <Button
                            width='100%'
                            height='50px'
                            value='EXPORTAR'
                            outline={false}
                            disabled={false}
                            onClick={() => exportar()}
                            loading={loading}
                        />
                    </div>

                {/* </div> */}
            </ModalFooter>

            <ModalPassword
                text={"Precisamos da sua senha para continuar com a exportação do arquivo de homologação."}
                show={showModalSenha}
                update={(e) => updatePassword(e)}
                onHide={() => console.log('FECHAR')}
            />

            <ModalAlert
                show={showModalCiencia}
                onHide={() => setShowModalCiencia(false)}
                size='md'
            >
                <p style={{ fontSize: "medium", width: "90%", textAlign: 'justify'  }}>
                    Existem receitas sem informações da NF que são obrigatórios para a exportação para o Sicca. Deseja emitir mesmo assim?
                </p><br />

                <p style={{ fontSize: "medium", width: "90%", textAlign: 'justify'  }}><a href='https://sudeste-online.s3.amazonaws.com/sudeste-online/embalagens.csv'>Clique aqui</a> para baixar o documento com o id das embalagens e depois informe no arquivo (caso não tenha informado na emissão da receita). </p><br />
               

                <div className="button-finish" style={{ width: '40%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '40px' }}>
                    <Button
                        width='130px'
                        height='45px'
                        value='EXPORTAR'
                        outline={false}
                        disabled={false}
                        onClick={() => {sicca();setLoading(true)}}
                        loading={loading}
                    />
                    <Button
                        width='130px'
                        height='45px'
                        value='CANCELAR'
                        outline={false}
                        red={true}
                        disabled={false}
                        onClick={() => setShowModalCiencia(false)}
                        loading={false}
                    />
                </div>
            </ModalAlert>
        </Modal>

    );
}
export default ModalNew;