import styled from 'styled-components';
import logoBranco from '../../assets/tour/logoIcone_branco.svg';

export const ContainerSuccess = styled.div`
  background-color: #2FBB69;
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 4;

  @media only screen and (max-width: 600px){
    z-index: 99999;
  }
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${props => `${-100*props.step}vw`};
  transition: all 0.3s ease;
`;

export const ContainerBackground = styled.div`
  background-image: url(${logoBranco});
  transition: all 0.4s ease;
  -webkit-box-flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 105vh;
  background-position-x: ${props => props.position ? props.position : '-880px'};
  background-position-y: ${props => (props.step == 1 || props.step == 2 || props.step == 4 || props.step == 5) && props.sizeScreen > 600 ? '95%' : '5%'};
  background-size: 51px;
  background-repeat: no-repeat;
  flex: 1 1 0%;

  @media only screen and (max-width: 600px){
    background-size: 40px;
    margin-left: 5px;
  }
`;

export const ClickEsquerda = styled.div`
    display:none;

    @media only screen and (max-width: 600px){
      position: absolute;
      display: flex;
      width: 49vw;
      height: 100vh;
      z-index: 2;
    }
`;

export const ClickDireita = styled.div`
    display:none;

    @media only screen and (max-width: 600px){
      display: flex;
      width: 49vw;
      position: absolute;
      height: 100vh;
      margin-left: 49vw;
      z-index: 2;
    }
`;

export const ImgFundo = styled.div`
    display: flex;
    position: absolute;
    justify-content: flex-end;
    overflow: hidden;
    margin-left: 99vw;

  img {
    height: 95vh;
    opacity: 0.2;
  }

  @media only screen and (max-width: 600px){
    display:none;
  }

  @media only screen and (max-width: 820px){
    display:none;
  }

  
`;

export const Done = styled.div`
  padding: 8px;
  height: 50px;
  transition: all 0.3s ease;
  &:hover{
    border-radius: 64px;
    box-shadow: 3px 5px 34px -8px #000, -2px -2px 14px -4px #fff;
    background: #3bab6a;
    transform: scale(1.0);

    &:active{
      transform: scale(0.8);
    }
  }
  
`;

export const SetaStyle = styled.div`
  transition: all 0.3s ease;

  &:hover{
    transform: scale(1.1);

    &:active{
      transform: scale(0.8);
    }
  }
  
`;


export const Markers = styled.div`
  width: 100%;
  display: flex;
  position: absolute;
  margin-top: 94vh;
  justify-content: center;

  @media only screen and (max-width: 600px){
    margin-top: 88vh;
  }
`;

export const CloseIcon = styled.div`
  width: 97%;
  display: flex;
  position: absolute;
  margin-top: 5vh;
  justify-content: flex-end;
  height: 15px;
  
  img{
    cursor: pointer;
    height: 12px;
    z-index: 10;
  }
`;
    
export const Marker = styled.div`
    transition: all 0.3s ease;
    margin-left: 38px;
    width: ${props => props.active ? '58px' : '38px'};
    height: 6px;
    border-radius: 50px;
    background-color: white;

    @media only screen and (max-width: 600px){
      width: 12px;
      height: 12px;
      background-color: ${props => props.active ? '#2CE378' : '#2DAD62'};
      margin-left: 10px;
    }
`;

export const PrevProx01 = styled.p`
  @media only screen and (max-width: 600px){
    font-size: 18px;
    position: absolute;
    margin-top: -9px;
    color: white;
    left: 17px;
    font-family: Lato;
    z-index: 3;

    a{
      text-decoration: none;
      color: white;
    }
  }
  @media only screen and (min-width: 601px){
    display: none;
  }
`;

export const PrevProx02 = styled.p`
  @media only screen and (max-width: 600px){
    position: absolute;
    font-size: 18px;
    margin-top: -9px;
    color: white;
    right: 17px;
    font-family: Lato;
    z-index: 3;

    a{
      text-decoration: none;
      color: white;
    }
  }
  @media only screen and (min-width: 601px){
    display: none;
  }
`;

export const Icon02 = styled.div`
    margin-left: 92%;
    margin-top: 5%;
    position: absolute;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 3;

    @media only screen and (max-width: 600px){
      display: none;
    }
`;

export const Icon03 = styled.div`
    margin-right: 92%;
    transform: rotate(180deg);
    margin-top: 5%;
    position: absolute;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    z-index: 3;

    @media only screen and (max-width: 600px){
      display: none;
    }
`;

export const Icon = styled.div`
    width: 100%;
    height: 40vh;
    margin-top: 35vh;
    display: flex;
    position: absolute;
    justify-content: center;
    z-index: 1000;

    img{
        height: 100%;
    }

    @media only screen and (max-width: 600px){
      z-index: 1;
    }
`;