import React from "react";

import { Container } from "react-grid-system";
import "moment/locale/pt-br";
import GenericUpper from "../../components/generic-upper";
import Input from "../../components/input/input_v2";
import Select from "../../components/select/select_v2";
import Button from "../../components/button/button_v2";
import api from "../../services/api";
import { ArtsContainer } from "./styles";

class ContractLet extends React.Component {
    state = {
        id: "",
        produtor: [],
        produtorId: "",
        locaisDevolucao: [],
        local_devolucao: "",
        valor_contrato: "",
        data_inicial: "",
        data_termino: "",
        artId: '',
        status: '',
        opStatus: [
            { value: 1, label: "Ativo" },
            { value: 0, label: "Inativo" }
        ],
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.getContracts();
    }

    formatData = (data) => {
        var date = data;

        let now = new Date(date);

        let day = now.getDate();
        if (day <= 9) {
            day = "0" + day;
        }
        let month = now.getMonth();
        let year = now.getFullYear();
        month = month + 1;
        if (month <= 9) {
            month = "0" + month;
        }

        return day + "/" + month + "/" + year;
    }

    getContracts = async () => {
        try {
            const id = this.props.match.params.id;
            this.setState({ id });
            const response = await api.get(`/contratos/${id}`);
            const contrato = response.data.data;
            
            let dataInicio = this.formatData(contrato.data_inicio);
            let dataFim = this.formatData(contrato.data_termino);
            let status = contrato.status;

            const {
                nome_produtor,
                nome_local_aplicacao,
                agro_art_id
            } = contrato;

            let valor = "" + contrato.valor_contrato;
    
            if(valor.split(".")){
                let separa = valor.split(".");
                if(separa[1]){
                    this.setState({ valor_contrato: valor });
                }else{
                    this.setState({ valor_contrato: valor + ".00" });
                }
            }
    
            this.setState({
                produtorId: nome_produtor,
                local_devolucao: nome_local_aplicacao,
                data_inicial: dataInicio,
                data_termino: dataFim,
                artId: agro_art_id,
                status
            });
        } catch (error) {

        }
    };

    back = () => {
        this.props.history.goBack();
    }

    render() {
        const {
            produtorId,
            local_devolucao,
            valor_contrato,
            data_inicial,
            data_termino,
        } = this.state;
        return (
            <>
                <GenericUpper title="Visualizar Contrato"></GenericUpper>

                <ArtsContainer>
                    <Container>
                        <form 
                            style={{
                                width: '100%'
                            }}
                        >
                            <Input
                                name="produtor"
                                className="select"
                                label="Produtor"
                                id="produtorId"
                                value={produtorId}
                                disabled
                            />
                            <Input
                                name="localDevolucao"
                                className="select"
                                label="Local de Aplicação"
                                id="localDevolucao"
                                value={local_devolucao}
                                disabled
                            />
                            <Input
                                name="valorContrato"
                                className="text"
                                label="Valor do Contrato (R$)"
                                type='number'
                                id="valorContrato"
                                value={valor_contrato}
                                disabled
                                onChange={e =>
                                    this.setState({ valor_contrato: e })
                                }
                            />
                            <Input
                                name="dataInicial"
                                className="text"
                                label="Data Inicial"
                                id="dataInicial"
                                mask="99/99/9999"
                                value={data_inicial}
                                disabled
                                onChange={e => this.setState({ data_inicio: e })}
                            />
                            <Input
                                name="dataFinal"
                                className="text"
                                label="Data Final"
                                id="dataFinal"
                                mask="99/99/9999"
                                value={data_termino}
                                disabled
                                onChange={e => this.setState({ data_termino: e })}
                            />

                            <Select
                                name="status"
                                className="text"
                                label="Status"
                                id="status"
                                options={this.state.opStatus}
                                value={this.state.status}
                                onChange={e => console.log(e)}
                                disabled
                            />

                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='VOLTAR'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => this.back()}
                                />
                            </div>
                        </form>
                    </Container>
                </ArtsContainer>
            </>
        );
    }
}

export default ContractLet;
