import React from "react";
import { Option, Options, RadioContainer } from "./style";

const Radio = (props) => {
  
    return (
      <RadioContainer>
        <Options>
            {props.options.map((op) => (
                <Option>
                    {/* <label className="form-control"> */}
                    <input type="radio" name="radio" id={`radio_${op.value}_id`} onChange={(e) => props.onChange(e.target.value)} value={`${op.value}`}/>
                    <label for={`radio_${op.value}_id`} >{op.label}</label>
                    {/* </label> */}
                </Option>
            ))}
        </Options>
      </RadioContainer>
    );
  };
  
  export default Radio;