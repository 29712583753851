import React, { useState, useEffect } from 'react';
import api from "../../../services/api";
import { store } from "react-notifications-component";
import SelectV2 from "../../select/select_v2";
import Pin from '../../../assets/icons/icons-8-marker@3x.png';
import { Footer } from './styles';
import Modal from '../../modal/modalGeneric';
import AddProdutor from '../../../pages/produtores/add';
import AddDevolucao from '../../../pages/devolucoes/add';
import AddFazenda from '../../../pages/produtores/addlocal';
import EditLocal from '../../../pages/produtores/editLocal';

const FormLocalAplicacao = ({ ...props }) => {
    const [existParams, setExistParams] = useState(false);

    const [produtor, setProdutor] = useState('');
    const [produtores, setProdutores] = useState([]);
    const [disabledProdutor, setDisabledProdutor] = useState(false);
    const [erroProdutor, setErroProdutor] = useState(false);
    const [msgErroProdutor, setMsgErroProdutor] = useState('');
    const [showModalAddProdutor, setShowModalAddProdutor] = useState(false);
    const [loadingProdutor, setLoadingProdutor] = useState(false);
    
    const [fazenda, setFazenda] = useState('');
    const [fazendas, setFazendas] = useState([]);
    const [disabledFazenda, setDisabledFazenda] = useState(false);
    const [erroFazenda, setErroFazenda] = useState(false);
    const [msgErroFazenda, setMsgErroFazenda] = useState('');
    const [showModalAddFazenda, setShowModalAddFazenda] = useState(false);
    const [loadingFazenda, setLoadingFazenda] = useState(false);

    const [devolucao, setDevolucao] = useState('');
    const [devolucoes, setDevolucoes] = useState([]);
    const [disabledDevolucao, setDisabledDevolucao] = useState(false);
    const [erroDevolucao, setErroDevolucao] = useState(false);
    const [msgErroDevolucao, setMsgErroDevolucao] = useState('');
    const [showModalAddDevolucao, setShowModalAddDevolucao] = useState(false);
    const [loadingDevolucao, setLoadingDevolucao] = useState(false);
    const [showModalFazenda, setShowModalFazenda] = useState(false);

    const [load, setLoad] = useState(false);

    const changeProdutor = (value) => {
        if (produtor) {
            if (produtor.value) {
                if (produtor.value != value.value) {
                    setErroProdutor(false);
                    setProdutor(value);
                }
            }else{
                if (value.value) {
                    setErroProdutor(false);
                    setProdutor(value);
                }
            }
        } else {
            setErroProdutor(false);
            setProdutor(value);
        }
    }
    
    const addNewProdutor = () => {
        setShowModalAddProdutor(true);
    }
    
    const closeProdutorModal = () => {
        setShowModalAddProdutor(false);
    }
    
    const updateProdutor = () => {
        setShowModalAddProdutor(false);
        getProdutores(false);
    }
    
    const updateFazendas = () => {
        setShowModalAddFazenda(false);
        getFazendas(false);
    }

    const formatProdutores = (produtores) => {
        produtores = produtores.map(produtor => {
            const format = {
                value: produtor.id || produtor.agro_producer_id,
                label: produtor.nome || produtor.nome_produtor
            };

            return format;
        });
        return produtores;
    };
    
    const getProdutores = async (existParams) => {
        setLoad(true);
        setDisabledProdutor(true);
        setLoadingProdutor(true);
        
        try {
            if(props.crea.tipo === "crea" || props.crea.tipo === "visto"){
                if (props.estado.value === 'RJ') {
                    const response = await api.get(`/produtores/estado/${props.estado.value}`);
                    let produtores = response.data.data;
                    produtores = formatProdutores(produtores);
    
                    if (!existParams) {
                        setProdutores(produtores);
                    } else {
                        setProdutores(produtores);
                        changeProdutor(props.params.produtor);
                    }
    
                    setDisabledProdutor(false);
                    setLoadingProdutor(false);

                } else {
                    const response = await api.get(`/produtores/art/${props.art.value}`);
                    let produtores = response.data.data;
                    produtores = formatProdutores(produtores);
    
                    if (!existParams) {
                        setProdutores(produtores);
                    } else {
                        setProdutores(produtores);
                        changeProdutor(props.params.produtor);
                    }
    
                    setDisabledProdutor(false);
                    setLoadingProdutor(false);
                }

            } else {
                const response = await api.get(`/produtores/estado/${props.estado.value}`);
                let produtores = response.data.data;
                produtores = formatProdutores(produtores);
                setProdutores(produtores);

                setDisabledProdutor(false);
                setErroProdutor(false);
                setLoadingProdutor(false);
            }
        } catch (e) {
            setProdutores([]);
            setDisabledProdutor(false);
            setErroProdutor(true);
            setLoadingProdutor(false);

            if (e.response) {
                setMsgErroProdutor(`${e.response.data.errors ? e.response.data.errors : "Não há produtores ou locais de aplicação cadastrados nesse estado." }`);
                store.addNotification({
                    title: "Erro!",
                    message: `${e.response.data.errors ? e.response.data.errors : "Não há produtores ou locais de aplicação cadastrados nesse estado." }`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
            } else {
                setMsgErroProdutor("Não há produtores ou locais de aplicação cadastrados nesse estado.");
                store.addNotification({
                    title: "Erro!",
                    message: "Não há produtores ou locais de aplicação cadastrados nesse estado.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
            }
        }
    };

    const changeFazenda = (value) => {
        if (value != fazenda) {
            setErroFazenda(false);
            setFazenda(value);
        }
    }
    
    const addNewFazenda = () => {
        setShowModalAddFazenda(true);
    }

    const formatFazendas = (fazendas) => {
        fazendas = fazendas.map(fazenda => {
            const format = {
                value: fazenda.id,
                label: fazenda.nome
            };

            return format;
        });
        return fazendas;
    };

    const getFazendas = async (existParams) => {
        try {
            if (produtor.value) {
                setLoadingFazenda(true);
                setFazenda('');
                if (props.estado.value === 'RJ') {
                    const response = await api.get(`/fazendas/estado/${produtor.value}/${props.estado.value}`);
        
                        let fazendas = response.data.data;
                        fazendas = formatFazendas(fazendas);
        
                        if (!existParams) {
                            setFazendas(fazendas);
                        } else {
                            setFazendas(fazendas);
                            changeFazenda(props.params.fazenda);
                        }
                } else {
                    if(props.crea.tipo === "crea"){
        
                        const response = await api.get(`/fazendas/${produtor.value}/${props.art.value}`);
        
                        let fazendas = response.data.data;
                        fazendas = formatFazendas(fazendas);
        
                        if (!existParams) {
                            setFazendas(fazendas);
                        } else {
                            setFazendas(fazendas);
                            changeFazenda(props.params.fazenda);
                        }
    
                    } else {
                        const response = await api.get(`/fazendas/estado/${produtor.value}/${props.estado.value}`);
        
                        let fazendas = response.data.data;
                        fazendas = formatFazendas(fazendas);
        
                        if (!existParams) {
                            setFazendas(fazendas);
                        } else {
                            setFazendas(fazendas);
                            changeFazenda(props.params.fazenda);
                        }
                    }
                }

                setDisabledFazenda(false);
                setErroFazenda(false);
                setLoadingFazenda(false);
            }
        } catch (e) {
            setFazendas([]);
            setDisabledFazenda(false);
            setErroFazenda(true);
            setMsgErroFazenda("Produtor não possui fazendas no estado selecionado.");
            setLoadingFazenda(false);

            store.addNotification({
                title: "Erro!",
                message: "Produtor não possui fazendas no estado selecionado.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };
    
    const changeDevolucao = (value) => {
        if (value != devolucao) {
            setErroDevolucao(false);
            setDevolucao(value);
        }
    }
    
    const addNewDevolucao = () => {
        setShowModalAddDevolucao(true);
    }
    
    const closeDevolucaoModal = () => {
        setShowModalAddDevolucao(false);
    }

    const updateDevolucao = () => {
        setShowModalAddDevolucao(false);
        setDevolucao('');
        getDevolucoes(false);
        setErroDevolucao(false);
    }
    
    const formatDevolucoes = (devolucoes) => {
        devolucoes = devolucoes.map(devolucao => {
            const format = {
                value: devolucao.id,
                label: devolucao.nome
            };
            return format;
        });
        return devolucoes;
    };
    
    const getDevolucoes = async (existParams) => {
        try {
            setLoad(true);
            setLoadingDevolucao(true);
            const response = await api.get(`/devolucoes`);

            let devolucoes = response.data.data;
            devolucoes = formatDevolucoes(devolucoes);

            if (!existParams) {
                setDevolucoes(devolucoes);
            } else {
                setDevolucoes(devolucoes);
                changeDevolucao(props.params.devolucao);
            }

            setErroDevolucao(false);
            setDisabledDevolucao(false);
            setLoadingDevolucao(false);

        } catch (e) {
            setDevolucoes([]);
            setDisabledDevolucao(false);
            setErroDevolucao(true);
            setMsgErroDevolucao("Não há locais de devolução cadastrados nesse estado.");
            setLoadingDevolucao(false);

            store.addNotification({
                title: "Erro!",
                message: "Não há locais de devolução cadastrados nesse estado.",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    };

    const validaFazendas = async () => {
        if (fazenda != "" && fazenda.value) {
            try {
                const response = await api.get(`/fazendas/valida/${fazenda.value}`);
            } catch (e) {
                setShowModalFazenda(true);
                if (e.response) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${e.response.data.errors ? e.response.data.errors : "A fazenda informada não possui as coodernadas geográficas obrigatórias!" }`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 7000,
                            onScreen: true
                        }
                    });
                }
            }
        }
    }

    useEffect(() => {

        if (load === false) {
            if (props.params) {
                if (props.params === []) {
                    setExistParams(false);
                    getProdutores(false);
                    getDevolucoes(false);

                } else if (props.params.produtor || props.params.fazenda || props.params.devolucao) {
                    setExistParams(true);
                    getProdutores(true);
                    getDevolucoes(true);
                } else {
                    setExistParams(false);
                    getProdutores(false);
                    getDevolucoes(false);
                }
            } else {   
                setExistParams(false);
                getProdutores(false);
                getDevolucoes(false);
            }
        }
    },[]);

    useEffect(()=>{
        validaFazendas();
    }, [fazenda]);

    useEffect(() => {
        if (produtor.value) {
            getFazendas(existParams);
        }
    },[produtor]);

    useEffect(() => {
        props.update({produtor, fazenda, devolucao, erroProdutor, erroFazenda, erroDevolucao})
    },[produtor, fazenda, devolucao, erroProdutor, erroFazenda, erroDevolucao])

    return(
        <div style={{
            maxWidth: '300px',
            margin: 'auto'
        }}>
            <SelectV2 
                name='produtor'
                className="select"
                label="Produtor"
                id='produtor'
                value={produtor.value || produtor}
                disabled={disabledProdutor}
                loading={loadingProdutor}
                erro={erroProdutor}
                onChange={(e) => changeProdutor(e)}
                options={produtores}
                selectUniqueOption={existParams ? produtores.length === 1 ? true : false : true}
                buttonAddNew={true}
                onClickAddNew={() => addNewProdutor()}
                messageErro={msgErroProdutor}
                isModal={true}
            />

            <SelectV2 
                name='fazenda'
                className="select"
                label="Local de aplicação"
                id='fazenda'
                value={fazenda.value || fazenda}
                disabled={disabledFazenda}
                loading={loadingFazenda}
                erro={erroFazenda}
                onChange={(e) => changeFazenda(e)}
                options={fazendas}
                selectUniqueOption={existParams ? fazendas.length === 1 ? true : false : true}
                buttonAddNew={produtor.value ? true : false}
                onClickAddNew={() => addNewFazenda()}
                messageErro={msgErroFazenda}
            />

            <SelectV2 
                name='devolucao'
                className="select"
                label="Local de devolução"
                id='devolucao'
                value={devolucao.value || devolucao}
                disabled={disabledDevolucao}
                loading={loadingDevolucao}
                erro={erroDevolucao}
                onChange={(e) => changeDevolucao(e)}
                options={devolucoes}
                selectUniqueOption={!existParams}
                buttonAddNew={true}
                onClickAddNew={() => addNewDevolucao()}
                messageErro={msgErroDevolucao}
                isModal={true}

            />
            
            <Footer> 
                <img className="pin" src={Pin} alt='estado' />
                <p>Exibindo resultados para <span>{props.estado.label}</span></p>
            </Footer>

            {showModalAddProdutor ? (
                <Modal
                    size='lg'
                    show={showModalAddProdutor}
                    onHide={() => closeProdutorModal()}
                >
                    <AddProdutor isModal={true} update={() => updateProdutor()}/>
                </Modal>
            ) : (null)}

            {showModalAddFazenda ? (
                <Modal
                    size='lg'
                    show={showModalAddFazenda}
                    onHide={() => setShowModalAddFazenda(false)}
                >
                    <AddFazenda 
                        isModal={true} 
                        update={() => updateFazendas()}
                        match={{
                            params: {
                                id: produtor.value
                            }
                        }}
                    />
                </Modal>
            ) : (null)}

            {showModalFazenda ? (
                <Modal
                    size='lg'
                    show={showModalFazenda}
                    onHide={() => {setFazenda("") ; setShowModalFazenda(false)}}
                >
                    <EditLocal 
                        idFazenda={fazenda.value || fazenda}
                        idProdutor={produtor.value || produtor}
                        isModal={true}
                        openModal={(e) => setShowModalFazenda(e)}
                    />
                </Modal>
            ) : (null)}

            {showModalAddDevolucao ? (
                <Modal
                    size='lg'
                    show={showModalAddDevolucao}
                    onHide={() => closeDevolucaoModal()}
                >
                    <AddDevolucao isModal={true} update={() => updateDevolucao()}/>
                </Modal>
            ) : (null)}
        </div>
    )
}

export default FormLocalAplicacao;
