import React, {useState, useEffect} from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom";


import Logo from '../../assets/logo.png';
import Cell from '../../assets/celular.png';

import Button from '../../components/button';

import { ContainerSuccess, Images, BannerTextRight } from './style';
import { login } from '../../services/auth';

const Success = ({ ...props }) => {

    const [isUpload, setIsUpload] = useState(false);

    useEffect(() => {
        if (window.location.search) {
            if (window.location.search === '?update=true') {
                setIsUpload(true);
            }
        }
        localStorage.removeItem('@store');
    })

    const redirectHome = () => {
        let password = localStorage.getItem('@useraccesskey');
        let user = JSON.parse(localStorage.getItem('@userinfo'))

        const username = user.username;

        if (password) {
            const psswd = Buffer.from(password, 'base64').toString('utf8');
    
            if (username && psswd) {
                try {
                    const headers = {
                        'apiKey': process.env.REACT_APP_API_KEY
                    }
                    axios.post(`${process.env.REACT_APP_API_HOST}/login`, { username, password: psswd }, { headers })
                    .then(response => {
                        
                        if (response) {
                            login(response.data.data);
                            localStorage.setItem('@useraccesskey', Buffer.from(psswd, 'utf8').toString('base64'))
                            window.location = "/";
                        }
                    }).catch(error => {
                        window.location = "/";
                    });
                } catch (err) {
                    window.location = "/";
                }
            } else {
                window.location = '/';
            }
        } else {
            const googleProfile = JSON.parse(localStorage.getItem('@agroreceita_google_user_profile'));

            const id = googleProfile.id;

            if (id) {
                try {
                    const headers = {
                        'apiKey': process.env.REACT_APP_API_KEY
                    }
                    axios.post(`${process.env.REACT_APP_API_HOST}/login`, { username, password: id }, { headers })
                    .then(response => {
                        if (response) {
                            login(response.data.data);
                            localStorage.setItem('@useraccesskey', Buffer.from(id, 'utf8').toString('base64'))
                            window.location = "/";
                        }
                    }).catch(error => {
                        window.location = "/";
                    });
                } catch (err) {
                    window.location = "/";
                }
            } else {
                window.location = '/';
            }
        }
    }

    return (
        <ContainerSuccess>
            <Images>
                <div className="img1">
                    <a href="https://agroreceita.com.br">
                        <img src={Logo} />
                    </a>
                </div>
                <div className="img2">
                    <img src={Cell} />
                </div>
            </Images>

            <BannerTextRight>
                {isUpload ? (
                    <h1>Atualização efetuada</h1>
                ) : (
                    <h1>Compra efetuada</h1>
                )}
                <h1>com sucesso!</h1>
                <div className="paragraph">
                    <p>Você, também, receberá um email</p>
                    {isUpload ? (
                        <p>de confirmação da atualização.</p>
                    ):(
                        <p>de confirmação de compra.</p>
                    )}
                </div>

                <Button
                    style={{padding: '15px'}}
                    onClick={() => redirectHome()}
                    disabled={false}
                    color="#00b43f"
                >
                    Voltar para o sistema
                </Button>
            </BannerTextRight>
        </ContainerSuccess>
    );
}

export default withRouter(Success);
