import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';

class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }

    updateResponseYes = () => {
        let resposta = 1;
        this.props.update(resposta);
    }

    updateResponseNo = () => {
        let resposta = 0;
        this.props.update(resposta);
    }

    render() {
        return (

            // <Modal
            //     {...this.props}
            //     size="lg"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8"}}>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //             {this.props.title}
            //         </Modal.Title>
            //     </Modal.Header>
            //     <Modal.Body style={{backgroundColor: " #f8f8f8"}}>
            //         {this.props.text}
            //     </Modal.Body>
            //     <Modal.Footer style={{backgroundColor: " #f8f8f8"}}>

            //         <Button style={{outline:0, padding: "15px"}}
            //             outline
            //             color="#00b43f"
            //             onClick={() => this.updateResponseNo()}
            //         >Apenas alterar os dados</Button>

            //         <Button style={{outline:0, padding: "15px"}}
            //             outline
            //             color="#00b43f"
            //             onClick={() => this.updateResponseYes()}
            //         >Alterar os dados e providenciar uma nova ART</Button>

            //     </Modal.Footer>
            // </Modal>


            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='60%'
            >
                <ModalTitle>
                    {this.props.title}
                </ModalTitle>

                <ModalBody>
                    {this.props.text}
                </ModalBody>


                <ModalFooter justifyContent='space-evenly'>
                    <Button style={{outline:0, padding: "15px"}}
                        outline
                        color="#00b43f"
                        onClick={() => this.updateResponseNo()}
                    >Apenas alterar os dados</Button>

                    <Button style={{outline:0, padding: "15px"}}
                        outline
                        color="#00b43f"
                        onClick={() => this.updateResponseYes()}
                    >Alterar os dados e providenciar uma nova ART</Button>
                </ModalFooter>
            </Modal>

        );
    }
}
export default ModalNew;