import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";
import { FormContainer, Card, Title, Paragraph, ContainerForm, RowInfo, ColInfo, Label, Texto } from "./styles";
import ArrowIcon from "../../../assets/icons/icon-arrow.svg";
import ArrowIconDisabled from "../../../assets/icons/icon-arrow-disabled.svg";
import FormLocalAplicacao from "./form";

const PrescriptionStepThree = ({ ...props }) => {

    const [isOpenBox, setIsOpenBox] = useState(false);
    const [erroProd, setErroProd] = useState(false);
    const [erroFarm, setErroFarm] = useState(false);
    const [erroDevolution, setErroDevolution] = useState(false);

    const openBoxProdutor = () => {
        setIsOpenBox(!isOpenBox);
    }

    const openBoxProdutor2 = () => {
        if ((isOpenBox === false || isOpenBox === undefined) && props.disabled === false) {
            setIsOpenBox(!isOpenBox);
        }
    }

    const updateValues = (values) => {
        props.update(values);
    }

    useEffect(() => {
        props.updateIsOpen(isOpenBox);

        if (isOpenBox === false) {
            if (props.existInformation) {
                if (props.params.produtor || props.params.fazenda || props.params.devolucao) {
                    if (props.params.produtor === '') {
                        setErroProd(true);
                        props.updateError(true);
                    } else {
                        setErroProd(false);
                    }

                    if (props.params.fazenda === '') {
                        setErroFarm(true);
                        props.updateError(true);
                    } else {
                        setErroFarm(false);
                    }

                    if (props.params.devolucao === '') {
                        setErroDevolution(true);
                        props.updateError(true);
                    } else {
                        setErroDevolution(false);
                    }
                }
            }
        }
    },[isOpenBox])

    useEffect(() => {
        if (props.isOpen != isOpenBox) {
            setIsOpenBox(props.isOpen);
        }
    },[props.isOpen])

    return (
        <Container>
            <FormContainer>
                <Card className="step03" existInformation={props.existInformation} onClick={() => openBoxProdutor2()} openBox={isOpenBox} disabled={props.disabled} erro={erroProd === true || erroFarm === true || erroDevolution === true ? true : props.error}>
                    <div className="row">
                        <Title disabled={props.disabled}>Onde o produto será aplicado?</Title>
                        {!props.disabled ? (
                            <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBoxProdutor()}/>
                        ) : (
                            <img className="arrow" src={ArrowIconDisabled} alt='arrow'/>   
                        )}
                    </div>
                    
                    {!props.existInformation ? (
                        <>
                            {!isOpenBox ? (
                                <Paragraph disabled={props.disabled}>Infome o Produtor, o Local de aplicação e o Local de devolução.</Paragraph>
                            ) : (
                                <ContainerForm>
                                    <FormLocalAplicacao 
                                        estado={props.estado}
                                        art={props.art}
                                        crea={props.crea}
                                        update={(value) => updateValues(value)}
                                        params={props.params}
                                    />
                                </ContainerForm>
                            )}
                        </>
                    ) : (
                        <>
                            {!isOpenBox ? (
                                <RowInfo>
                                    <ColInfo>
                                        <Label erro={erroProd === true ? erroProd : props.params.erroProdutor} disabled={props.disabled}>Produtor</Label>
                                        <Texto erro={erroProd === true ? erroProd : props.params.erroProdutor} disabled={props.disabled}>{props.params.produtor.label}</Texto>
                                    </ColInfo>

                                    <ColInfo>
                                        <Label erro={erroFarm === true ? erroFarm : props.params.erroFazenda} disabled={props.disabled}>Local de aplicação</Label>
                                        <Texto erro={erroFarm === true ? erroFarm : props.params.erroFazenda} disabled={props.disabled}>{props.params.fazenda.label}</Texto>
                                    </ColInfo>
                                    
                                    <ColInfo>
                                        <Label erro={erroDevolution === true ? erroDevolution : props.params.erroDevolucao} disabled={props.disabled}>Local de devolução</Label>
                                        <Texto erro={erroDevolution === true ? erroDevolution : props.params.erroDevolucao} disabled={props.disabled}>{props.params.devolucao.label}</Texto>
                                    </ColInfo>
                                </RowInfo>
                            ) : (
                                <ContainerForm>
                                    <FormLocalAplicacao 
                                        estado={props.estado}
                                        art={props.art}
                                        crea={props.crea}
                                        update={(value) => updateValues(value)}
                                        params={props.params}
                                    />
                                </ContainerForm>
                            )}
                        </>
                    )}
                </Card>
            </FormContainer>
        </Container>
    )
};

export default PrescriptionStepThree;
