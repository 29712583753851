import React from "react";
import { withRouter } from "react-router-dom";
import GenericUpper from "../../components/generic-upper";
import api from "../../services/api";
import { ProdutoresContainer } from "./styles";
import { Container } from "react-grid-system";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import Button from "../../components/button/button_v2";
import ButtonOld from "../../components/button";
import DotsLoader from "../../components/loader";
import InputSearch from "../../components/input-search";
import Importacao from "../../components/modal/modalImportacao";
import CardGeral from "../../components/card-geral/index";
import ModalTesteGratisAcabou from '../../components/modal/modalFuncaoBloqueada';
import ModalCadastrarFazenda from "../../components/modal/modalText"
import Paginator from "../../components/paginator";

export const USER = "@userinfo";
export const VAL = "@validade";

class Fazendas extends React.Component {
    state = {
        fazendas: [],
        produtores: [],
        limit: 30,
        offset: 0,
        totalFarms: 0,
        offsetSearch: 0,
        loading: false,
        loadingMore: false,
        hideButton: false,
        result: "",
        result2: "",
        resultLimpo: '',
        isLimpo: true,
        lastResult: "",
        size_screen: window.screen.width,
        importar: false,
        modalTesteGratisAcabou: false,
        showModal: false,
        user: JSON.parse(localStorage.getItem(USER)),
        val: JSON.parse(localStorage.getItem(VAL)),
        viewOptions: false,
        loadingSearch: false,
    };

    componentDidMount() {
        setInterval(async()=>{
            this.setState({size_screen: window.screen.width});
        }, 990);
        this.getProducers();
        this.search(this.state.result, this.state.result2, 30, 0);
    }

    getXlms = async () => {
        try {
            this.setState({ loading: true });
            const response = await api.get("/relatorios/fazendas", {
                responseType: "blob"
            });
    
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
    
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "RelatorioFazendas.xlsx";
            link.href = url;
            link.click();
            this.setState({ loading: false });
            
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    updateResposta = async (resposta) => {
        console.log(resposta);
        if (resposta == 0) {
            this.setState({importar: false})
        }
    }

    searchDefault = async () => {
        this.setState({ isLimpo: true });
        this.setState({ produtores: [] });
        this.setState({ result: '' });
        this.setState({ loadingSearch: true }); 


        this.getProducers();

        try {
            const response = await api.get("/fazendas?limit=" + 5 + "&offset=" + 0);
            let newOffset = 5;
            let farms = response.data.data;

            if(farms && farms.length > 0){
                this.setState({
                    fazendas: farms,
                    loading: false,
                    hideButton: false,
                    offset: newOffset,
                });
            }else{
                this.setState({
                    hideButton: true,
                    loading: false,
                    offset: newOffset,
                });
            }
            this.setState({ loadingSearch: false }); 
            
        } catch (error) {
            this.setState({ loadingSearch: false }); 
        }
    }

    search = async (result, result2, limit, offset) => {
        try {
            this.setState({ loadingSearch: true }); //inicia loading
            this.setState({ fazendas: [] }); //limpa lista atual
            
            const search = result;
            const estado = result2;


            const payload = {
                search,
                estado
            }

            const response = await api.post(`/fazendas/pesquisa?limit=${limit}&offset=${offset}`, payload);

            this.setState({ 
                fazendas: response.data.data.fazendas,
                totalFarms: response.data.data.total, 
                loading: false
            });

            this.setState({ loadingSearch: false }); //termina loading
            
        } catch (error) {
            this.setState({ loadingSearch: false }); //termina loading
        }
    };

    cleanFilter = e => {
        this.searchDefault();
    };

    openModalCadastroFazenda = () => {
        this.setState({ showModal: true });
    }

    closeModal = () => {
        if(this.state.showModal == true){
            this.setState({ showModal: false });
        }
    }

    updateModalAdd = (resposta) => {
        if(resposta == 1){
            this.props.history.push('/produtores');
            this.closeModal();
        }
    }

    formatProducers = produtores => {
        produtores = produtores.map(produtor => {
            const format = {
                value: produtor.id,
                label: produtor.nome
            };
            return format;
        });
        return produtores;
    };

    getProducers = async () => {
        try {
            const response = await api.get(`/produtores`);
            let produtores = response.data.data;
            produtores = this.formatProducers(produtores);

            if (produtores && produtores.length > 0) {
                this.setState({ produtores, loading: false, });
            } else {
            }
        } catch (e) {
        }
    }

    loadMore = async () => {
        this.setState({ loadingMore: true, hideButton: true });
        const { limit, offset, fazendas, result } = this.state;

        if (result) {
            try {
                const response = await api.get(
                    `/produtores/${result}/fazendas?limit=` + limit + "&offset=" + offset
                );
                let newFarms = response.data.data;
                let newOffset = limit + offset;
                const farmsConCat = fazendas.concat(newFarms);
                this.setState({
                    fazendas: farmsConCat,
                    offset: newOffset,
                    hideButton: false,
                    loading: false
                });
            } catch (error) {
                this.setState({ loadingMore: false,  hideButton: true });
            }
            
        } else {
            try {
                const response = await api.get(
                    "/fazendas?limit=" + limit + "&offset=" + offset
                );
                let newFarms = response.data.data;
                const farmsConCat = fazendas.concat(newFarms);
                let newOffset = limit + offset;
                this.setState({
                    fazendas: farmsConCat,
                    offset: newOffset,
                    hideButton: false,
                    loadingMore: false
                });
            } catch (error) {
                this.setState({ loadingMore: false, hideButton: true });
            }
        }
    };

    updateValues = async (text, estado, search) => {
        if (search == 1) {
            if(!text && !estado){
                this.searchDefault();
            }else{   
                this.setState({ result2: estado });
                this.setState({ result: text });
                this.search(text, estado, 30, 0);
            }
        }else{
            this.setState({result: text});
            this.setState({result2: estado});
        }
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.search(this.state.result, this.state.result2, 30, 0);
        }
    };

    openOption = () => {
        if(this.state.viewOptions == true){
            this.setState({ viewOptions: false });
        }else{
            this.setState({ viewOptions: true });
        }
    }

    updateLimitOffset = (l,o) => {
        if (l !== this.state.limit || o !== this.state.offset) {
            this.setState({
                limit: l,
                offset: o
            })

            this.search(this.state.result, this.state.result2, l, o);
        }
    }

    render() {
        const {
            fazendas,
            loading,
            size_screen,
            importar,
            modalTesteGratisAcabou,
            user,
            val,
            loadingSearch,
            limit,
            totalFarms
        } = this.state;

        return (
            <>
                <GenericUpper
                    title="Locais de Aplicação"
                    subtitle="Gerencie os locais de aplicação"
                    search={true}
                    searchFunction={(text, estado, search) => this.updateValues(text, estado, search)}
                    estados={true}
                    placeholder="Nome, Produtor ou Cidade"
                    addButton={true}
                    addOptions={true}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    tooltip={"Adicionar"}
                    addOptionModal = {() => this.openModalCadastroFazenda()}
                    importar={(e) => this.setState({importar: e})}
                ></GenericUpper>
                <ProdutoresContainer>
                    <Container style={size_screen <= 768 ? {paddingLeft: "0px", paddingRight: "0px", margin: 'auto'}:{}}>
                    {size_screen <= 768 ? (<>
                        <CardGeral
                            titulos={ ["FAZENDAS","NOME", "PRODUTOR","CIDADE", "ESTADO"]}
                            valores={fazendas}
                        />

                        <Paginator 
                            perPage={limit} 
                            total={totalFarms} 
                            onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                            totalItens={fazendas.length}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                height: '200px',
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                            }}
                        >
                            <Button 
                                width='87%'
                                height='50px'
                                value='ADICIONAR PRODUTORES'
                                outline={false}
                                disabled={false}
                                onClick={() => this.openModalCadastroFazenda()}
                                loading={false}
                            />
                            
                        </div>

                    </>):(<>
                        <Importacao
                            style={{display: "flex", justifyContent: "center"}}
                            show={importar}
                            onHide={e => {this.setState({importar: false})}}
                            resposta=''
                            update={this.updateResposta}
                            title='Importar locais de aplicação'
                            text='Você pode importar seus locais de aplicação de uma planilha excel para o AgroReceita.'
                            link='https://sudeste-online.s3.amazonaws.com/importacoes/Importarlocaisdeaplicacao.xlsx'
                            tipo={2}
                        ></Importacao>

                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Produtor</th>
                                    <th>Cidade</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <TBody>
                                {fazendas.map(fazenda => (

                                    < tr
                                        onClick={e =>{
                                            if(user.contract_software_id == 6 && val == 0) {
                                                this.setState({modalTesteGratisAcabou: true})
                                            }else{ 
                                                this.props.history.push(`fazendas/${fazenda.id}/${fazenda.produtor.id}`)
                                            }
                                        }}
                                        key={fazenda.id}
                                    >
                                        <td>{fazenda.nome}</td>
                                        <td>{fazenda.produtor.nome}</td>
                                        <td>{fazenda.cidade}</td>
                                        <td><div className="estado">{fazenda.estado}</div></td>
                                    </tr>
                                ))}
                            </TBody>
                        </Table>
                        <DotsLoader show={loadingSearch}></DotsLoader>

                        <Paginator 
                            perPage={limit} 
                            total={totalFarms} 
                            onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                            totalItens={fazendas.length}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                                marginTop: '40px',
                            }}
                        >
                            <Button 
                                width='25%'
                                height='50px'
                                value='RELATÓRIO'
                                outline={!loading}
                                disabled={false}
                                onClick={() => this.getXlms()}
                                loading={loading}
                            />
                        </div>

                        </>)}
                        <ModalTesteGratisAcabou
                            show={modalTesteGratisAcabou}
                            title="Essa é uma função exclusiva para assinantes..."
                            subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                            onHide={() => {
                                this.setState({modalTesteGratisAcabou: false})
                                this.props.history.push('/produtores');

                            }}
                        ></ModalTesteGratisAcabou>
                        <ModalCadastrarFazenda
                            show={this.state.showModal}
                            text="Para adicionar um local de aplicação selecione o produtor desejado ou clique em Adicionar produtores"
                            btnOk={true}
                            btnCancelar={false}
                            onHide={this.closeModal}
                            update={this.updateModalAdd}
                        >

                        </ModalCadastrarFazenda>
                    </Container>
                </ProdutoresContainer>
            </>
        );
    }
}

export default withRouter(Fazendas);
