import React, { useState, useEffect } from "react";

import Select from "../../select/select_v2";
import estados from "../../../services/estados";
import api from "../../../services/api";
import { store } from "react-notifications-component";
import { Container } from "react-grid-system";
import { FormContainer, Card, RowCard, Row, BlueLink, ContainerRelationOptions, Dispensed } from "./styles";
import Relation from './relation';
import Modal from '../../modal/modalGeneric';

import cancelaIcon from "../../../assets/icons/icons-8-trash.png";
import ArrowIcon from "../../../assets/icons/icon-arrow.svg";


const PrescriptionStepOne = ({ ...props }) => {
    const { updateQuantidadeRelations, compendioParams, startValidation } = props;

    const [disabledEstado, setDisabledEstado] = useState(false);
    const [disabledCultura, setDisabledCultura] = useState(false);
    const [disabledPraga, setDisabledPraga] = useState(false);
    const [disabledProduto, setDisabledProduto] = useState(false);

    //values
    const [estadoId, setEstado] = useState('');
    const [culturaId, setCulturaId] = useState("");
    const [pragasId, setPragasId] = useState([]);
    const [produtosId, setProdutosId] = useState([]);
    const [produtosLabel, setProdutosLabel] = useState([]);

    //options select
    const [culturas, setCulturas] = useState("");
    const [pragas, setPragas] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [state, setState] = useState(estados);
    const [estadosParaSelecionar, setEstadosParaSelecionar] = useState(state)


    const [relations, setRelations] = useState([0]);
    const [openRelation, setOpenRelation] = useState([false]);
    const [maxRelations, setMaxRelations] = useState(1);
    const [isLoadingRelations, setIsLoadongRelations] = useState(false);

    const [relationsInfoArray, setRelationsInfoArray] = useState([]);

    const [loading, setLoading] = useState(false);
    const [saveDate, setSaveDate] = useState('');
    const [isErroNotRelation, setIsErroNotRelation] = useState(false);
    const [closeAll, setCloseAll] = useState(false);
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [existDispensedProduct, setExistDispensedProduct] = useState(false);
    const [dispensedIndex, setDispensedIndex] = useState('');
    const [loadingCompendio, setLoadingCompendio] = useState(true);

    const [isCompendio, setIsCompendio] = useState(false);

    const formatCulturas = culturas => {
        culturas = culturas.map(cultura => {
            const format = {
                value: cultura.id,
                label: cultura.nome_comum
            };
            return format;
        });
        return culturas;
    };

    const formatPragas = pragas => {
        pragas = pragas.map(praga => {
            const format = {
                value: praga.id,
                label: `${praga.nome_comum} - ${praga.nome_cientifico}`
            };
            return format;
        });
        return pragas;
    };

    const formatProdutos = produtos => {
        produtos = produtos.map(produto => {
            const format = {
                value: produto.id,
                label: `${produto.nome_comercial} - ${produto.fabricante}`,
                dispensado: produto.receituario_dispensado
            };

            return format;
        });

        return produtos;
    };

    const getCulturas = async () => {
        try {
            setCulturas([]);
            setDisabledCultura(true);

            const response = await api.get(`/culturas`);
            let culturas = response.data.data;
            setTimeout(() => {
                culturas = formatCulturas(culturas);
                setCulturas(culturas);
                setDisabledCultura(false);                
            }, 200);

        } catch (error) {

        }
    };

    const getProdutos = async (index) => {
        if (index === 0) {
            setProdutos([]);
            setLoading(true);
            try {
                const response = await api.get(`/produtos`);
                let responseProdutos = response.data.data;

                setTimeout(() => {
                    let produtosFormat = formatProdutos(responseProdutos);
                    let produtosArray = produtos;
                    produtosArray[index] = produtosFormat;
                    setProdutos(produtosArray);                    
                }, 200);

            } catch (error) {
            }
            setLoading(false);

        } else {
            setLoading(true);

            let p = produtos;

            if (p.length > 0) {
                setProdutos([]);

                let newP = [];

                p.map((pm, i) => {
                    if (i !== index) {
                        newP = [...newP, pm];
                    }
                })

                try {
                    const response = await api.get(`/produtos`);
                    let responseProdutos = response.data.data;

                    setTimeout(() => {
                        let produtosFormat = formatProdutos(responseProdutos);
                        newP[index] = produtosFormat;
                        setProdutos(newP);
                    }, 200);
                } catch (error) {
                }
                setLoading(false);
            }
        }
    };

    const getPragas = async (index) => {
        setLoading(true);

        if (index === 0) {
            setPragas([]);
            try {
                setDisabledPraga(true);
                const response = await api.get(`/pragas`);

                setTimeout(() => {
                    let responsePragas = response.data.data;
                    let pragasFormat = formatPragas(responsePragas);
                    
                    let pragasArray = pragas;
                    pragasArray[index] = pragasFormat;
                    
                    setPragas(pragasArray);
                    setDisabledPraga(false);
                }, 200);
    
            } catch (error) {
    
            }
        } else {


            let p = pragas;

            if (p.length > 0) {
                setPragas([]);

                let newP = [];

                p.map((pm, i) => {
                    if (i !== index) {
                        newP = [...newP, pm];
                    }
                })
            
                try {
                    setDisabledPraga(true);
                    const response = await api.get(`/pragas`);

                    setTimeout(() => {
                        let responsePragas = response.data.data;
                        let pragasFormat = formatPragas(responsePragas);
                        
                        newP[index] = pragasFormat;
                        
                        setPragas(newP);
                        setDisabledPraga(false);                
                    }, 200);
                } catch (error) {
        
                }
            }
        }
        setLoading(false);
    };

    const searchCompendio = async (produto = '*', cultura = '*', praga = '*', estado = '*', index) => {
        try {

            if (!produto) {
                produto = '*'
            }
            if (!cultura) {
                cultura = '*'
            }
            if (!praga) {
                praga = '*'
            }
            if (!estado) {
                estado = '*'
            }


            if (produto === '*' && cultura === '*' && praga === '*' && estado === '*' && index === 0) {
                setExistDispensedProduct(false);
                setDispensedIndex('');
                setLoadingCompendio(false);
                setLoading(true);
                getCulturas();  
                getPragas(0);
                getProdutos(0);
                setLoading(false);
            } else {
                setDisabledEstado(true);
                setDisabledCultura(true);
                setDisabledPraga(true);
                setDisabledProduto(true);
    
                const response = await api.get(`/compendio/${produto}/${cultura}/${praga}/${estado}`);
                
                if(response){
                    let responseEstados = response.data.data.estados;
                    let responsePragas = response.data.data.pragas;
                    let responseProdutos = response.data.data.produtos;
                    let responseCulturas = response.data.data.culturas;
        
                    setEstadosParaSelecionar([""]);
                    let estadosProibidos = [];
                        
                    responseEstados.map(st => {
                        function buscaEstadosIguais(value){
                            return value.value === st.uf;
                        }
            
                        const estadosFiltrados = state.filter(buscaEstadosIguais);
                        
                        estadosProibidos.push(estadosFiltrados[0]);
                    })
        
                    setEstadosParaSelecionar(estadosProibidos);
                    setDisabledEstado(false);
    
                    setCulturas([""])
                    let culturasFormat = formatCulturas(responseCulturas);
                    setCulturas(culturasFormat);
                    setDisabledCultura(false);
    
                    setPragas([""])
                    let pragasFormat = formatPragas(responsePragas);
                    let pragasArray = pragas;
                    pragasArray[index] = pragasFormat
                    setPragas(pragasArray);
                    setDisabledPraga(false);
    
                    setProdutos([""])
                    let produtosFormat = formatProdutos(responseProdutos);
    
                    if (produtosFormat.length === 1 && produto && produto !== '*') {
                        if (produtosFormat[0].dispensado === 1) {
                            setExistDispensedProduct(true);
                            setDispensedIndex(index);
                        } else {
                            setExistDispensedProduct(false);
                            setDispensedIndex('');
                        }
                    }
    
                    let produtosArray = produtos;
                    produtosArray[index] = produtosFormat;
                    setProdutos(produtosArray);
                    setDisabledProduto(false);
                }
    
                if (produto !== '*' && cultura !== '*'  && praga !== '*'  && estado !== '*' ) {
                    //Aqui todos os parâmetros foram informados
                    //Necessário realizar a consulta para capturar o ID da relação
                    searchRelationId(produto, cultura, praga, index)
                }
    
                setDisabledEstado(false);
                setDisabledCultura(false);
                setDisabledPraga(false);
                setDisabledProduto(false);
            }

        } catch (error) {
            setDisabledEstado(false);
            setDisabledCultura(false);
            setDisabledPraga(false);
            setDisabledProduto(false);
        }
    }

    const searchRelationId = async (produto, cultura, praga, index) => {
        try {

            const response = await api.get(`/relacoes/${produto}/${cultura}/${praga}`);

            if (response) {
                if (response.data.data.length === 1) {

                    if (response.data.data[0].agro_relations_exceptions.length > 0) { 
                        let r = relations;
                        let relationsId = []

                        response.data.data.map(rel => {
                            relationsId = [...relationsId, {relacao: rel}];
                        })
                        
                        r[index] = relationsId;

                        setRelations(r);
                        props.updateRelationIds(r);
                        props.countRelations(r.length);

                        openBoxRelation(index, 7);
                    } else {
                        let relationId = response.data.data[0].id;
                        let r = relations;
                        r[index] = relationId;


                        if (relations[index] && relations[index] === relationId) {
                            openBoxRelation(index, 8.1);
                            setRelations(r);
                            props.updateRelationIds(r);
                        
                        } else {
                            setRelations(r);
                            props.updateRelationIds(r);

                            props.countRelations(r.length);
                            openBoxRelation(index, 8);

                        }
                    }
                } else {
                    let relationsId = []
                    response.data.data.map(rel => {
                        relationsId = [...relationsId, {relacao: rel}];
                    })
                    let r = relations;
                    r[index] = relationsId;

                    setRelations(r);

                    openBoxRelation(index, 9);
                }
            }
        } catch (error) {
            
        }
    }

    const changeEstado = (estado) => {
        if (isErroNotRelation === true) {
            setIsErroNotRelation(false);
        }

        if (typeof estado === 'object') {
            if (estado.value === '' && estado.label === '') {
                setEstado('');
                
                if (produtosId[0] || culturaId.value || pragasId[0]) {
                    searchCompendio(produtosId[0], culturaId.value, pragasId[0], '*', 0);
                } else {
                    setEstadosParaSelecionar(state);
                }

            } else {
                setEstado(estado);
                if (estado.value !== estadoId.value) {
                    searchCompendio(produtosId[0], culturaId.value, pragasId[0], estado.value, 0);
                }
            }
        }
    }

    const changeCultura = (cultura) => {
        if (isErroNotRelation === true) {

            setIsErroNotRelation(false);
        }
        setLoading(true);
        
        if (isCompendio === true) {
            //Se compendio for TRUE e estiver sendo alterado justamente o valor que veio do compendio (index 0), o state isCompendio deve ser setado para FALSE
            if (culturaId) {
                if (cultura.value !== culturaId) {
                    setIsCompendio(false);
                }
            }
        }
        
        if (typeof cultura === 'object') {
            if (cultura.value === '' && cultura.label === '') {
                setCulturaId('');

                if (produtosId[0] || estadoId.value || pragasId[0]) {
                    searchCompendio(produtosId[0], '*', pragasId[0], estadoId.value, 0);
                }
            } else {
                setCulturaId(cultura);
                if (cultura.value !== culturaId.value) {
                    searchCompendio(produtosId[0], cultura.value, pragasId[0], estadoId.value, 0);
                }
            }
        }
        setLoading(false);
    }

    const changeProductOnRelation = (product, index) => {

        if (isErroNotRelation === true) {

            setIsErroNotRelation(false);
        }

        if (typeof product === 'object') {

            let isComp = false

            if (isCompendio === true) {
                isComp = true;
                if (index === 0) {
                    //Se compendio for TRUE e estiver sendo alterado justamente o valor que veio do compendio (index 0), o state isCompendio deve ser setado para FALSE
                    
                    if (produtosId[index]) {
                        if (product.value !== produtosId[index]) {
                            setIsCompendio(false);
                            isComp = false;
                        }
                    }
                }
            }

            if (relations.length === 1 && index === 0) {
                if (product.value === '') {
                    setOpenRelation([false]);
                    if (typeof product === 'object') {
                        if (product) {
                            if (produtosId[index] === "") {    
                                let newList = [];
                
                                produtosId.map((p, i) => {
                                    if (i === index) {
                                        newList = [...newList, product.value];
                                    } else {
                                        newList = [...newList, p];
                                    }
                                });
                                setProdutosId(newList);
                            } else {
                                if (produtosId[index] === product.value) {
                
                                    let newList = [];
                
                                    produtosId.map((p, i) => {
                                        if (i === index) {
                                            newList = [...newList, product.value];
                                        } else {
                                            newList = [...newList, p];
                                        }
                                    });
                                    setProdutosId(newList);
                                } else {
                                    if (product.value === '') {
                                        let newList = [];
                
                                        produtosId.map((p, i) => {
                                            if (i === index) {
                                                newList = [...newList, product.value];
                                            } else {
                                                newList = [...newList, p];
                                            }
                                        });
                                        setProdutosId(newList);
                                    } else {
                                        let productsList = produtosId;
                            
                                        productsList = [...productsList, product.value];

                                        setProdutosId(productsList);
                                    }
                                }
                            }
                        } else {
                            let productsList = produtosId;
                            productsList[index] = '';
                            setProdutosId(productsList);
                        }

                        if (isComp && index === 0) {
                            //Não deve consultar aqui
                        } else {
                            searchCompendio(product.value, culturaId.value, pragasId[index], estadoId.value, index);
                        }
                    }
                } else {
                    if (typeof product === 'object') {
                        if (product) {
                            if (produtosId[index] === "") {    
                                let newList = [];
                
                                produtosId.map((p, i) => {
                                    if (i === index) {
                                        newList = [...newList, product.value];
                                    } else {
                                        newList = [...newList, p];
                                    }
                                });

                                setProdutosId(newList);
                
                            } else {
                                if (produtosId[index] === product.value) {
                
                                    let newList = [];
                
                                    produtosId.map((p, i) => {
                                        if (i === index) {
                                            newList = [...newList, product.value];
                                        } else {
                                            newList = [...newList, p];
                                        }
                                    });
                                    setProdutosId(newList);
                                } else {
            
                                    if (product.value === '') {
                                        let newList = [];
                
                                        produtosId.map((p, i) => {
                                            if (i === index) {
                                                newList = [...newList, product.value];
                                            } else {
                                                newList = [...newList, p];
                                            }
                                        });
                                        setProdutosId(newList);
                                    } else {
                                        let productsList = produtosId;
                            
                                        productsList = [...productsList, product.value];
                                        setProdutosId(productsList);
                                    }
                                }
                            }
                        } else {
                            let productsList = produtosId;
                            productsList[index] = '';
                            setProdutosId(productsList);
                        }

                        if (isComp && index === 0) {
                            //Não deve consultar aqui
                        } else {
                            searchCompendio(product.value, culturaId.value, pragasId[index], estadoId.value, index);
                        }
                    }
                }
            } else {
                let newRelations = [];
                let newOpenRelations = [];
    
                relations.map((r, i) => {
                    if (i === index) {
                        newRelations[i] = 0;
                    } else {
                        newRelations[i] = r;
                    }
                })
    
                openRelation.map((o, i) => {
                    if (i === index) {
                        newOpenRelations[i] = false;
                    } else {
                        newOpenRelations[i] = o;
                    }
                })
    
                setRelations(newRelations);
                setOpenRelation(newOpenRelations);
    
                if (typeof product === 'object') {
                    if (product) {
                        if (produtosId[index] === "") {    
                            let newList = [];
            
                            produtosId.map((p, i) => {
                                if (i === index) {
                                    newList = [...newList, product.value];
                                } else {
                                    newList = [...newList, p];
                                }
                            });
                            
                            setProdutosId(newList);
            
                        } else {
                            if (produtosId[index] === product.value) {
            
                                let newList = [];
            
                                produtosId.map((p, i) => {
                                    if (i === index) {
                                        newList = [...newList, product.value];
                                    } else {
                                        newList = [...newList, p];
                                    }
                                });
            
                                setProdutosId(newList);
                            } else {
        
                                if (product.value === '') {
                                    let newList = [];
            
                                    produtosId.map((p, i) => {
                                        if (i === index) {
                                            newList = [...newList, product.value];
                                        } else {
                                            newList = [...newList, p];
                                        }
                                    });
                
                                    setProdutosId(newList);
                                } else {
                                    let productsList = produtosId;
                        
                                    productsList = [...productsList, product.value];

                                    setProdutosId(productsList);
                                }
                            }
                        }
                    } else {
                        let productsList = produtosId;
                        productsList[index] = '';
                        setProdutosId(productsList);
                    }

                    if (isComp && index === 0) {
                        //Não deve consultar aqui
                    } else {
                        searchCompendio(product.value, culturaId.value, pragasId[index], estadoId.value, index);
                    }
                }
            }

            let productsLabels = produtosLabel;
            productsLabels[index] = product.label;
            setProdutosLabel(productsLabels);
        }
    }
    
    const changePestOnRelation = (pest, index) => {
        if (isErroNotRelation === true) {

            setIsErroNotRelation(false);
        }

        if (isCompendio === true && index === 0) {
            //Se compendio for TRUE e estiver sendo alterado justamente o valor que veio do compendio (index 0), o state isCompendio deve ser setado para FALSE
            if (pragasId[index]) {
                if (pest.value !== pragasId[index]) {
                    setIsCompendio(false);
                }
            }
        }


        if (typeof pest === 'object'){
            let isComp = false
            if (isCompendio === true) {
                isComp = true;
                if (index === 0) {
                    //Se compendio for TRUE e estiver sendo alterado justamente o valor que veio do compendio (index 0), o state isCompendio deve ser setado para FALSE
                    if (pragasId[index]) {
                        if (pest.value !== pragasId[index]) {
                            setIsCompendio(false);
                            isComp = false;
                        }
                    }
                }
            }

            if (relations.length === 1 && index === 0) {
                if (pest.value === '') {
                    setOpenRelation([false]);
                }
            } else {
                let newRelations = [];
                let newOpenRelations = [];
    
                relations.map((r, i) => {
                    if (i === index) {
                        newRelations[i] = 0;
                    } else {
                        newRelations[i] = r;
                    }
                })
    
                openRelation.map((o, i) => {
                    if (i === index) {
                        newOpenRelations[i] = false;
                    } else {
                        newOpenRelations[i] = o;
                    }
                })
    
                setRelations(newRelations);
                setOpenRelation(newOpenRelations);
            }
    
            if (typeof pest === 'object') {

                if (pest.value === '' && (!produtosId[index] || produtosId[index] === '') && culturaId === '' && estadoId === '') {
                    let pestsList = pragasId;
                    pestsList[index] = '';
                    setPragasId(pestsList);
                    
                    getCulturas();
                    getPragas(index);
                    getProdutos(index);
                    setEstadosParaSelecionar(state);
                } else {
                    if (pest) {
                        if (pragasId[index] === "") {
                            let newList = [];
            
                            pragasId.map((p, i) => {
                                if (i === index) {
                                    newList = [...newList, pest.value];
                                } else {
                                    newList = [...newList, p];
                                }
                            });
            
                            setPragasId(newList);
                        } else {
            
                            if (pragasId[index] === pest.value) {
            
                                let newList = [];
            
                                pragasId.map((p, i) => {
                                    if (i === index) {
                                        newList = [...newList, pest.value];
                                    } else {
                                        newList = [...newList, p];
                                    }
                                });
            
                                setPragasId(newList);
                            } else {
                                if (pest.value === '') {
                                    let newList = [];
            
                                    pragasId.map((p, i) => {
                                        if (i === index) {
                                            newList = [...newList, pest.value];
                                        } else {
                                            newList = [...newList, p];
                                        }
                                    });
                
                                    setPragasId(newList);
                                } else {
                                    let pestsList = pragasId;
                                    pestsList = [...pestsList, pest.value];
                                    setPragasId(pestsList);
                                }
                            }
                        }
                    } else {
                        let pestsList = pragasId;
                        pestsList[index] = '';
                        setPragasId(pestsList);
                    }
                }
            }


            if (isComp && index === 0) {
                //Não deve consultar aqui
            } else {
                searchCompendio(produtosId[index], culturaId.value, pest.value, estadoId.value, index);
            }


        }
    }

    const getQuantidadeRelacoes = async (estado) => {
        try {
            if (estado.value != '' && estado.value !== '*') {
                const response = await api.get(`/relacoes/quantidade/${estado.value}`);
                if (response) {
                    updateQuantidadeRelations(response.data.data.quantidade);
                    setMaxRelations(response.data.data.quantidade);
                }
            } else {
                updateQuantidadeRelations(1);
                setMaxRelations(1);
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const openBoxRelation = (index, id) => {
        let rels = openRelation;
        let newRels = [];

        if (rels.length !== relations.length) {
            relations.map((r, i) => {
                if (!rels[i]) {
                    rels[i] = false;
                }
            })
        }

        if (existDispensedProduct === false) {
            if (relations[index] === 0) {
                store.addNotification({
                    title: "Erro!",
                    message: `A relação está incompleta!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                rels.map((value, i) => {
    
                    if (i === index) {
                        newRels[i] = !value;
                    } else {
                        if (value === true) {
                            newRels[i] = !value;
                        } else {
                            newRels[i] = value;
                        }
                    }
                });
                setOpenRelation(newRels);
            }
        }
    }

    const closeAllRelations = (relations) => {
        setCloseAll(true);

        relations.map((rel, i) => {
            if (rel === true) { //Se card estiver aberta
                openBoxRelation(i, 10);
            }
        })
    }

    const addNewRelation = () => {

        if (existDispensedProduct === true) {
            store.addNotification({
                title: "Erro!",
                message: `O produto selecionado está dispensado de receituário agronômico!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        } else {
            if (!loading && !disabledEstado && !disabledCultura && !disabledPraga && !disabledProduto) {
                if (!estadoId) {
                    store.addNotification({
                        title: "Erro!",
                        message: `Primeiro selecione um estado!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    if (!culturaId) {
                        store.addNotification({
                            title: "Erro!",
                            message: `Primeiro selecione uma cultura!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    } else {
    
                        const lastValue = relations[relations.length - 1];
    
                        if (lastValue === 0) {
                            store.addNotification({
                                title: "Erro!",
                                message: `Complete a prescrição anterior para adicionar mais produtos!`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        } else {
                            if (maxRelations) {
                                if (relations.length < maxRelations) {
                                    let indexNewRelation = relations.length
                    
                                    const openRelationsLength = openRelation.length;
                                    let existOpen = false;
                                    let indexIsOpen = 0;
                    
                                    if (openRelationsLength > 0) {
                                        openRelation.map((value, i) => {
                                            if (value === true) {
                                                existOpen = true;
                                                indexIsOpen = i;
                                            }
                                        })
                                        
                                        if (existOpen) {
                                            let rels = openRelation;
                                            
                                            let newRels = [];
                    
                                            rels.map((value, i) => {
                                                if (i === indexIsOpen) {
                                                    if (value === true) {
                                                    }
                                                    newRels[i] = !value;
                                                } else {
                                                    if (value === true) {
                                                        newRels[i] = !value;
                                                    } else {
                                                        newRels[i] = value;
                                                    }
                                                }
                                            });
                                            
                                            newRels = [...newRels, false];
                                            setOpenRelation(newRels);
                                        } else {
                                            setOpenRelation([...openRelation, false]);
                                        }
                                    }
                                    setRelations([...relations, 0]);
                                    setRelationsInfoArray([...relationsInfoArray, []]);
                    
                                    let prd = produtos;
                                    let prg = pragas;
                                    
                                    setTimeout(() => {
                                        setProdutos([]);
                                        setPragas([]);
                                    }, 100);
                    
                                    setTimeout(() => {
                                        prd[indexNewRelation] = [];
                                        prg[indexNewRelation] = [];
                                        
                                    }, 100);
                                    
                                    setTimeout(() => {
                                        setProdutos(prd);
                                        setPragas(prg);
                                    }, 100);

                                    searchCompendio('*', culturaId.value, '*', estadoId.value, indexNewRelation)


                                } else {
                                    store.addNotification({
                                        title: "Erro!",
                                        message: `Você alcançou o limite de produtos permitidos!`,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 4000,
                                            onScreen: true
                                        }
                                    });
                                }
                            } else {
                                if (relations.length < 10) {
                                    let indexNewRelation = relations.length
                        
                                    const openRelationsLength = openRelation.length;
                                    let existOpen = false;
                                    let indexIsOpen = 0;
                    
                                    if (openRelationsLength > 0) {
                                        openRelation.map((value, i) => {
                                            if (value === true) {
                                                existOpen = true;
                                                indexIsOpen = i;
                                            }
                                        })
                                        
                                        if (existOpen) {
                                            let rels = openRelation;
                                            let newRels = [];
                    
                                            rels.map((value, i) => {
                                                if (i === indexIsOpen) {
                                                    newRels[i] = !value;
                                                } else {
                                                    if (value === true) {
                                                        newRels[i] = !value;
                                                    } else {
                                                        newRels[i] = value;
                                                    }
                                                }
                                            });
                                            
                                            newRels = [...newRels, false];
                                            setOpenRelation(newRels);
                                        } else {
                                            setOpenRelation([...openRelation, false]);
                                        }
                                    }
                                    setRelations([...relations, 0]);
                                    setRelationsInfoArray([...relationsInfoArray, []]);
                    
                                    let prd = produtos;
                                    let prg = pragas;
                                    
                                    setTimeout(() => {
                                        setProdutos([]);
                                        setPragas([]);
                                    }, 100);
                    
                                    setTimeout(() => {
                                        prd[indexNewRelation] = [];
                                        prg[indexNewRelation] = [];
                                        
                                    }, 100);
                                    
                                    setTimeout(() => {
                                        setProdutos(prd);
                                        setPragas(prg);
                                    }, 100);

                                    searchCompendio('*', culturaId.value, '*', estadoId.value, indexNewRelation)
                                } else {
                                    store.addNotification({
                                        title: "Erro!",
                                        message: `Você alcançou o limite de produtos permitidos!`,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 4000,
                                            onScreen: true
                                        }
                                    });
                                }
                            }
                        }
                    }
                } 
            }
        }
    }

    const saveRelationInfo = (relationInfo, index) => {
        let relArray = relationsInfoArray;
        relArray[index] = relationInfo;
        setRelationsInfoArray(relArray);

        if (index === 0) {
            setSaveDate(relationInfo.date);
        }

        updateValues(relArray, culturaId, estadoId);

        if (relArray[index].erro === true) {
            setIsErroNotRelation(true);
        } else {
            setIsErroNotRelation(false);
        }
    }

    const removePragas = async (newPragas, index) => {
        let newArray = [];
        newPragas.map((p, i) => {
            if (i !== index) {
                newArray = [...newArray, p];
            } else {
            }
        });
        return newArray;
    }
    
    const removeProdutos = async (newProdutos, index) => {
        let newArray = [];
        newProdutos.map((p, i) => {
            if (i !== index) {
                newArray = [...newArray, p];
            } else {
            }
        });
        return newArray;
    }
    
    const removeProdutosLabel = async (produtosLabel, index) => {
        let newArray = [];
        produtosLabel.map((p, i) => {
            if (i !== index) {
                newArray = [...newArray, p];
            } else {
            }
        });
        return newArray;
    }
    
    const removeRelations = async (newRelations, index) => {
        let newArray = [];
        newRelations.map((p, i) => {
            if (i !== index) {
                newArray = [...newArray, p];
            } else {
            }
        });
        return newArray;
    }
    
    const removeOpenRelations = async (newOpenRelations, index) => {
        let newArray = [];
        newOpenRelations.map((p, i) => {
            if (i !== index) {
                newArray = [...newArray, p];
            } else {
            }
        });
        return newArray;
    }
    
    const removeRelationsInfoArray = async (newRelationsArray, index) => {
        let newArray = [];
        newRelationsArray.map((p, i) => {
            if (i !== index) {
                newArray = [...newArray, p];
            } else {
            }
        });
        return newArray;
    }
    
    const validaIndexRelations = async (newRelationsArray) => {
        let newRelationsIndoValidated = [];
        newRelationsArray.map((r, i) => {
            if (r.index > i) {
                newRelationsIndoValidated = [...newRelationsIndoValidated, {
                    areaTratar: r.areaTratar,
                    date: r.date,
                    dose: r.dose,
                    equipamento: r.equipamento,
                    index: i,
                    relationId: r.relationId,
                    modalidadeAplicacao: r.modalidadeAplicacao,
                    numeroAplicacoes: r.numeroAplicacoes,
                    quantidadeAdquirir: r.quantidadeAdquirir,
                    unidadeQuantidadeAdquirir: r.unidadeQuantidadeAdquirir,
                    volumeCalda: r.volumeCalda
                }]
            } else if (r.index === i) {
                newRelationsIndoValidated = [...newRelationsIndoValidated, r];
            }
        });
        return newRelationsIndoValidated;
    }
    
    const loadingNewSelectOptions = async (pragas, produtos) => {
        let length = pragas.length;

        if (length === produtos.length) {
            let load = [];
            for (let i = 0; i < length; i++) {
                try {
                    const response = await api.get(
                        `/compendio/${ produtos[i]}/${culturaId ? culturaId.value : "*"}/${pragas[i]}/${estadoId ? estadoId.value : "*"}`
                    );

                    if (response) {
                        let responsePragas = response.data.data.pragas;
                        let responseProdutos = response.data.data.produtos;
            
                        setPragas([""])
                        let pragasFormat = formatPragas(responsePragas);
                        let pragasArray = pragas;
                        pragasArray[i] = pragasFormat
                        setPragas(pragasArray);
        
                        setProdutos([""])
                        let produtosFormat = formatProdutos(responseProdutos);
                        let produtosArray = produtos;
                        produtosArray[i] = produtosFormat;
                        setProdutos(produtosArray);

                        load = [...load, true];
                    } else {
                        load = [...load, false];
                    }
                } catch (error) {
                    load = [...load, false];
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                    setIsErroNotRelation(true);
                }
            }

            if (load.length === length) {
                let existFalse = false;
                load.map((l) => {
                    if (l === false) {
                        existFalse = true;
                    }
                });

                if (!existFalse) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const deleteRelation = async(index) => {
        setIsLoadongRelations(true);

        if (relations.length === 1 && index === 0) {
            // setRelationsInfo([]);
            setRelations([]);
            setExistDispensedProduct(false);

            setPragasId([]);
            setProdutosId([]);

            setTimeout(() => {
                setRelations([0]);
                
                setDisabledEstado(true);
                setDisabledCultura(true);
                setDisabledPraga(true);
                setDisabledProduto(true);

                try {
                    if (culturaId || estadoId) {
                        api.get(
                            `/compendio/*/${culturaId ? culturaId.value : "*"}/*/${estadoId ? estadoId.value : "*"}`
                        ).then(response => {
                            if(response){
                
                                let responseEstados = response.data.data.estados;
                                let responsePragas = response.data.data.pragas;
                                let responseProdutos = response.data.data.produtos;
                                let responseCulturas = response.data.data.culturas;
                    
                                setEstadosParaSelecionar([""]);
                                let estadosProibidos = [];
                                    
                                responseEstados.map(st => {
                                    function buscaEstadosIguais(value){
                                        return value.value === st.uf;
                                    }
                        
                                    const estadosFiltrados = state.filter(buscaEstadosIguais);
                                    
                                    estadosProibidos.push(estadosFiltrados[0]);
                                })
                    
                                setEstadosParaSelecionar(estadosProibidos);
                                setDisabledEstado(false);
                
                                setCulturas([""])
                                let culturasFormat = formatCulturas(responseCulturas);
                                setCulturas(culturasFormat);
                                setDisabledCultura(false);
                
                                setPragas([""])
                                let pragasFormat = formatPragas(responsePragas);
                                let pragasArray = pragas;
                                pragasArray[index] = pragasFormat
                                setPragas(pragasArray);
                                setDisabledPraga(false);
                
                                setProdutos([""])
                                let produtosFormat = formatProdutos(responseProdutos);
                                let produtosArray = produtos;
                                produtosArray[index] = produtosFormat;
                                setProdutos(produtosArray);
                                setDisabledProduto(false);

                                updateValues([], culturaId, estadoId);
                            }
                        })
                    } else {
                        setLoading(true);
                        getCulturas();  
                        getPragas(0);
                        getProdutos(0);
                        setLoading(false);
                        setIsLoadongRelations(false);
                        setDisabledEstado(false);
                        setDisabledCultura(false);
                        setDisabledPraga(false);
                        setDisabledProduto(false);
                        updateValues([], '', '');
                    }
        
                } catch (e) {
                    setDisabledEstado(false);
                    setDisabledCultura(false);
                    setDisabledPraga(false);
                    setDisabledProduto(false);

                    setIsErroNotRelation(true);
                    
                    store.addNotification({
                        title: "Erro!",
                        message: `${e.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }

                setIsLoadongRelations(false);
            }, 1000);

           
        } else {
            let newPragas = pragasId;
            let newProdutos = produtosId;
            let newRelations = relations;
            let newOpenRelations = openRelation;
            let newRelationInfoArray = relationsInfoArray;
            let newProdutosLabel = produtosLabel;
    
            setRelations([]);
    
            if (existDispensedProduct === true && dispensedIndex === index) {
                setExistDispensedProduct(false);
                setDispensedIndex('');
            }

            newPragas = await removePragas(pragasId, index);
            newProdutos = await removeProdutos(produtosId, index);
            newRelations = await removeRelations(relations, index);
            newOpenRelations = await removeOpenRelations(openRelation, index);
            newRelationInfoArray = await removeRelationsInfoArray(relationsInfoArray, index);
            newProdutosLabel = await removeProdutosLabel(produtosLabel, index);
            
            if (newRelationInfoArray) {
                newRelationInfoArray = await validaIndexRelations(newRelationInfoArray);
    
                if (newRelationInfoArray) {
                    setTimeout(() => {
                        removeArraysPragas(newPragas);
                        removeArraysProdutos(newProdutos);
                        setRelations(newRelations);
                        setOpenRelation(newOpenRelations);
                        setRelationsInfoArray(newRelationInfoArray);
                        setProdutosLabel(newProdutosLabel)
    
                        props.countRelations(newRelationInfoArray.length);
    
                        updateValues(newRelationInfoArray, culturaId, estadoId, newProdutosLabel);
                    }, 300);
            
                    let isLoadingNewSelectOptions = false;
                    isLoadingNewSelectOptions = await loadingNewSelectOptions(newPragas, newProdutos);
    
                    setTimeout(() => {
                        setIsLoadongRelations(false);
                    }, 1000);
                }
            }
            
        }

    }

    const removeArraysProdutos = (produtosArrayIdList) => {
        let newArray = [];
        produtosArrayIdList.map((p, i) => {
            if (typeof p === 'object') {
                newArray = [...newArray, p[0].value];
            } else {
                newArray = [...newArray, p];
            }
        })

        setProdutosId(newArray);
    }
    
    const removeArraysPragas = (pragasArrayIdList) => {
        let newArray = [];
        pragasArrayIdList.map((p, i) => {
            if (typeof p === 'object') {
                newArray = [...newArray, p[0].value];
            } else {
                newArray = [...newArray, p];
            }
        })
        setPragasId(newArray);
    }

    const changeRelationId = (id, index) => {
        let relationsIds = [];

        relations.map((r, i) => {
            if (i === index) {
                relationsIds = [...relationsIds, id];
            } else {
                relationsIds = [...relationsIds, r];
            }
        });

        setRelations(relationsIds);
    }

    const updateValues = (relations, cultura, estado, produtos) => {
        const payload = {
            relations,
            cultura,
            estado,
            produtos: produtos ? produtos : produtosLabel
        };

        props.update(payload);
    }

    const loadCompendio = async (compendio) => {
        
        const loadRelation = async (compendio, index) => {
            setDisabledEstado(true);
            setDisabledCultura(true);
            setDisabledPraga(true);
            setDisabledProduto(true);
    
            const {
                relacaoId,
                produto,
                praga,
                cultura,
                estado,
                modoAplicacao,
                area,
                dose,
                numApli,
                quantidade
            } = compendio;
    
            let r = relations
            r[index] = parseInt(relacaoId);
            setRelations(r);
            
            let relArray = [];
            relArray[index] = {};

            relArray[index]['modalidadeAplicacao'] = modoAplicacao ? modoAplicacao : '';
            relArray[index]['dose'] = dose ? dose : '';
            relArray[index]['areaTratar'] = area ? area : '';
            relArray[index]['numeroAplicacoes'] = numApli ? numApli : '';
            relArray[index]['quantidadeAdquirir'] = quantidade ? quantidade : '';

            setRelationsInfoArray(relArray);

            try {
                const response = await api.get(`/compendio/${produto}/${cultura}/${praga}/${estado ? estado : '*'}`);
                
                if(response){
                    let responseEstados = response.data.data.estados;
                    let responsePragas = response.data.data.pragas;
                    let responseProdutos = response.data.data.produtos;
                    let responseCulturas = response.data.data.culturas;
        
                    let estadosProibidos = [];
                        
                    responseEstados.map(st => {
                        function buscaEstadosIguais(value){
                            return value.value === st.uf;
                        }
            
                        const estadosFiltrados = state.filter(buscaEstadosIguais);
                        
                        estadosProibidos.push(estadosFiltrados[index]);
                    })
        
                    setEstadosParaSelecionar(estadosProibidos);
                    setDisabledEstado(false);

                    //Cultura
                    if (index == 0) {
                        let culturasFormat = formatCulturas(responseCulturas);
                        setCulturaId(culturasFormat[index]);
                        setDisabledCultura(false);

                        if (estado && estado !== '*') {
                            setEstado({value: estado, label: estado});
                        }
                    }
                    
                    //Pragas
                    let pragasFormat = formatPragas(responsePragas);
                    let pragasOptions = pragas;

                    setPragas('');
                    pragasOptions[index] = pragasFormat;
                    setPragas(pragasOptions);

                    let pst = pragasId;
                    setPragasId('');
                    pst[index] = pragasFormat[0].value;
                    setPragasId(pst);
                    setDisabledPraga(false);
    

                    //Produtos
                    let produtosFormat = formatProdutos(responseProdutos);
                    let produtosOptions = produtos;
                    setProdutos('');
                    produtosOptions[index] = produtosFormat;
                    setProdutos(produtosOptions);

                    let prd = produtosId;
                    setProdutosId('');
                    prd[index] = produtosFormat[0].value;

                    let productsLabels = produtosLabel;
                    setProdutosLabel([]);
                    productsLabels[index] = produtosFormat[0].label;
                    setProdutosLabel(productsLabels);

                    setProdutosId(prd);
                    setDisabledProduto(false);

                    setDisabledEstado(false);
                    setDisabledCultura(false);

                }
    
            } catch (e) {
                setDisabledEstado(false);
                setDisabledCultura(false);
                setDisabledPraga(false);
                setDisabledProduto(false);

                setIsErroNotRelation(true);

                store.addNotification({
                    title: "Erro!",
                    message: `${e.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        }

        for (let i = 0; i < compendio.length; i++) {
            const c = compendio[i];
            await loadRelation(c, i);
        }

        props.countRelations(compendio.length);
    }

    const validaFirstPrescriptTest = async () => {
        let existPrescript = localStorage.getItem('@agroreceita:exist_prescription');

        if (existPrescript === 'false') {
            let user = JSON.parse(localStorage.getItem('@userinfo'));
            let uf = user.uf;

            changeEstado({value: uf, label: uf});
        } else {
            setLoadingCompendio(false);
            setLoading(true);
            getCulturas();  
            getPragas(0);
            getProdutos(0);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (props.isCompendio) {
            setIsCompendio(true);
            getCulturas();
            getProdutos(0);
            getPragas(0);
            
            loadCompendio(compendioParams)

            setTimeout(() => {
                validaFirstPrescriptTest();
            }, 1500);
        } else {
            validaFirstPrescriptTest();
            // setLoadingCompendio(false);
            // setLoading(true);
            // getCulturas();  
            // getPragas(0);
            // getProdutos(0);
            // setLoading(false);
        }
    },[])

    useEffect(() => {
        if (estadoId) {
            if (props.maxRelations !== false) {
                getQuantidadeRelacoes(estadoId);
            } else {
                setMaxRelations(false);
            }
        }
    }, [estadoId])

    useEffect(() => {
        if (produtosId.length > 0) {
            let remove = false;
            produtosId.map((p) => {
                if (typeof p === 'object') {
                    remove = true;
                }
            })

            if(remove) {
                removeArraysProdutos(produtosId);
            } else {
                // if (isCompendio && loadingCompendio) {
                //     continueToCompendio();
                // }
            }
        }
    },[produtosId])
    
    useEffect(() => {

        if (pragasId.length > 0) {
            let remove = false;
            pragasId.map(p => {
                if (typeof p === 'object') {
                    remove = true;
                }
            })

            if (remove) {
                removeArraysPragas(pragasId);
            } else {
                // if (isCompendio && loadingCompendio) {
                //     continueToCompendio();
                // }
            }
        }
    },[pragasId])

    useEffect(() => {
        if (isErroNotRelation === true) {
            setIsErroNotRelation(false);
        }

        if (culturaId === '' || estadoId === '') {
            setOpenRelation([false]);
        }
        
    }, [culturaId, estadoId]);

    useEffect(() => {
        props.updateRelationIds(relations)
        props.countRelations(relations.length);
    },[relations])

    useEffect(() => {

        if(openRelation.indexOf(true) == -1 && closeAll === true) {  
            setCloseAll(false);
            setTimeout(() => {
                props.enviaReceita();
            }, 300);
        }
        props.updateOpenRelations(openRelation);
    },[openRelation])

    useEffect(() => {
        if (startValidation === true) {

            if(openRelation.indexOf(true) == -1) {  
                //Está tudo fechado!
                
                setTimeout(() => {
                    props.enviaReceita();
                }, 300);
            } else {
                //Tem relações abertas
                closeAllRelations(openRelation);
            }
        }
    }, [startValidation])

    useEffect(() => {
        props.updateErrorStepOne(isErroNotRelation)
    }, [isErroNotRelation])

    useEffect(() => {
        updateValues(relationsInfoArray, culturaId, estadoId, produtosLabel);
        console.log('relationsInfoArray', relationsInfoArray)
    }, [relationsInfoArray, culturaId, estadoId, produtosLabel])

    useEffect(() => {
        if (disabledEstado === false && disabledCultura === false && disabledPraga === false && disabledProduto === false) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    },[disabledEstado, disabledCultura, disabledPraga, disabledProduto])

    return (
        <Container>

            <FormContainer>
                <Card className="step01">
                    <h2>Comece por onde quiser</h2>

                    <Row style={{ maxWidth: '700px' }}>
                        <div className="selectValue">
                            <Select
                                name="estado"
                                className="select"
                                label="UF"
                                id="estado"
                                value={estadoId ? estadoId.value : ''}
                                disabled={disabledEstado || loading}
                                onChange={estado => (changeEstado(estado))}
                                options={estadosParaSelecionar || [""]}
                                isCompact={true}
                                isSearch={true}
                                width='270px'
                                isClearable={true}
                                selectUniqueOption={false}
                                loading={loading}
                                erro={isErroNotRelation}
                            />
                        </div>
                        
                        <div className="selectValue">
                            <Select
                                name="agro_culture_id"
                                className="select"
                                label="Cultura"
                                id="cultura"
                                value={culturaId ? culturaId.value : ''}
                                disabled={disabledCultura || loading}
                                onChange={cultura => changeCultura(cultura)}
                                options={culturas || [""]}
                                isCompact={true}
                                isSearch={true}
                                width='270px'
                                isClearable={true}
                                selectUniqueOption={false}
                                loading={loading}
                                erro={isErroNotRelation}
                                searchPhoto={true}
                            />
                        </div>
                    </Row>

                    {isLoadingRelations === false ? (
                        <>
                            {relations ? (
                                <>
                                    {relations.map((relationId, index) => (
                                        <>
                                            <RowCard 
                                                isBorder={true} 
                                                key={relationId} 
                                                openBox={openRelation[index]} 
                                                erro={ isErroNotRelation === true ? true : relationsInfoArray[index] ? 
                                                        relationsInfoArray[index].isException ?
                                                            relationsInfoArray[index].fase.length === 0 ? 
                                                                true 
                                                            : !relationsInfoArray[index].modalidadeAplicacao ?
                                                                true
                                                            : !relationsInfoArray[index].dose ?
                                                                true
                                                            : !relationsInfoArray[index].numeroAplicacoes ?
                                                                true
                                                            : !relationsInfoArray[index].areaTratar ?
                                                                true
                                                            : !relationsInfoArray[index].quantidadeAdquirir ?
                                                                true
                                                            : false
                                                        : !relationsInfoArray[index].modalidadeAplicacao ?
                                                            true
                                                        : !relationsInfoArray[index].dose ?
                                                            true
                                                        : !relationsInfoArray[index].numeroAplicacoes ?
                                                            true
                                                        : !relationsInfoArray[index].areaTratar ?
                                                            true
                                                        : !relationsInfoArray[index].quantidadeAdquirir ?
                                                            true
                                                        : false
                                                    : false}
                                            >
                                                <div className='align' style={{
                                                    width: '100%',
                                                    height: '100px',
                                                    borderBottom: openRelation[index] ? 'solid 1px #e8edf3' : 'solid 0px #e8edf3',
                                                }}>
                                                    <img className="cancel" alt='cancelar' onClick={() => deleteRelation(index)} src={cancelaIcon} />

                                                    <div className="selectValue">
                                                        <Select
                                                            name="agro_product_id"
                                                            className="select"
                                                            label="Produto"
                                                            id={`produto-${index}`}
                                                            value={typeof produtosId[index] === 'object' ? produtosId[index][0].value : produtosId[index] }
                                                            disabled={disabledProduto || loading}
                                                            isClearable={true}
                                                            onChange={produto => changeProductOnRelation(produto, index)}
                                                            options={produtos[index] || [""]}
                                                            isCompact={true}
                                                            isSearch={true}
                                                            width='270px'
                                                            selectUniqueOption={false}
                                                            isArrow={true}
                                                            loading={disabledProduto || loading}
                                                            erro={isErroNotRelation}
                                                            solicitar={true}
                                                        />
                                                    </div>

                                                    <div className="selectValue">
                                                        <Select
                                                            name="agro_pest_id"
                                                            className="select"
                                                            label="Praga ou Alvo"
                                                            id={`praga-${index}`}
                                                            value={typeof pragasId[index] === 'object' ? pragasId[index][0].value : pragasId[index] }
                                                            disabled={disabledPraga || loading}
                                                            isClearable={true}
                                                            onChange={praga => changePestOnRelation(praga, index)}
                                                            options={pragas[index] || [""]}
                                                            isCompact={true}
                                                            isSearch={true}
                                                            width='270px'
                                                            selectUniqueOption={false}
                                                            loading={loading}
                                                            erro={isErroNotRelation}
                                                            searchPhoto={true}

                                                        />
                                                    </div>
        
                                                    <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBoxRelation(index, 11)}/>
                                                </div>
  
                                                {existDispensedProduct && dispensedIndex === index ? (
                                                    <Dispensed> Produto dispensado de receituário </Dispensed>
                                                ) : (
                                                    <>
                                                        {openRelation[index] ? (
                                                            <ContainerRelationOptions>
                                                                <Relation 
                                                                    index={index}
                                                                    date={saveDate}
                                                                    relation={relations[index]}
                                                                    relationInfo={relationsInfoArray[index]}
                                                                    updateRelationInfo={(relationInfo) => saveRelationInfo(relationInfo, index)}
                                                                    updateRelationId={(id) => changeRelationId(id, index)}
                                                                    isCompendio={isCompendio}
                                                                    compendioParams={isCompendio ? compendioParams[index] : ''}
                                                                    estado={estadoId}
                                                                    compendioLoading={loadingCompendio}
                                                                />
        
                                                            </ContainerRelationOptions>
                                                        ) : (null)}
                                                    </>
                                                )}
                                            </RowCard>
                                        </>
                                    ))}
                                </>
                            ):(null)}
                        </>
                    ) : (
                        <h3 style={{
                            width: '100%',
                            height: '70px',
                            textAlign: 'center',
                            fontSize: '14px',
                        }}>Removendo relação...</h3>
                    )}

                    <BlueLink disabled={!loading && !disabledEstado && !disabledCultura && !disabledPraga && !disabledProduto ? false : true} onClick={() => addNewRelation()}>Adicionar outro produto</BlueLink>
                    {props.maxRelations ? (
                        <p style={{ fontSize: '65%', width: '100%', textAlign: 'center' }}>Insira até {props.maxRelations} produtos por receita</p>
                    ) : (null)}
                </Card>
            </FormContainer>

            <Modal
                show={showModalHelp}
                onHide={() => setShowModalHelp(false)}
                size='md'
            >
                <div>
                    <h4 style={{
                        width: '100%',
                        textAlign: 'center'
                    }}>Dúvidas na emissão da sua receita?</h4>
                    <div>
                        <p style={{
                            width: '100%',
                            textAlign: 'center'
                        }}><a href='https://www.agroreceita.com.br/como-emitir-seu-receituario-agronomico' target='_blank'>Clique aqui</a>  e acesse o tutorial. </p>
                    </div>
                </div >
            </Modal>

        </Container>
    );
};

export default PrescriptionStepOne;
