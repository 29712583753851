import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
import Input from "../input/input_v2";
import Button from '../button/index';
import Modal from '.';
import { ModalTitle } from './styles';
// import 'bootstrap/dist/css/bootstrap.min.css';

class ModalPassword extends React.Component {
    constructor(props){
        super(props);
    }

    state = {
      password: '',
    };

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    changePassword = (e) => {
      this.setState({password: e});
    }


    validate = () => {
      this.props.update(this.state.password);
      this.setState({ password: '' });
    }

    render(){
        return(
            
          // <Modal
          //   {...this.props}
          //   size="lg"
          //   aria-labelledby = "contained-modal-title-vcenter"
          //   centered
          // >
          //   <Modal.Header closeButton style={{backgroundColor: " #f8f8f8"}}>
          //       <Modal.Title id="contained-modal-title-vcenter">
          //         Validar autenticidade
          //       </Modal.Title>
          //   </Modal.Header >

          //   <Modal.Body style={{backgroundColor: " #f8f8f8"}}>
          //     <p>{this.props.text}</p>
          //     <div 
          //       className="row" 
          //       style={{
          //         display: 'flex',
          //         flexDirection: 'row',
          //         alignItems: 'center',
          //         padding: '10px',
          //       }}
          //     >
          //       <div 
          //         className="input"
          //         style={{
          //           width: '60%',
          //         }}
          //       >
          //         <input name='email' id='email' style={{display: 'none'}}></input> 

          //         <Input
          //             name="password"
          //             type="password"
          //             id="password"
          //             label="Senha"
          //             placeholder="Senha"
          //             onChange={e => this.changePassword(e.target.value)}
          //             value={this.state.password}
          //             width="60%"
          //         />

          //       </div>
          //     </div>

          //     {this.props.openUpload ? (
          //         <label 
          //           className='linkOpen' 
          //           onClick={() => this.props.onHideToUpload()}
          //           style={{
          //             color: '#589bff',
          //             cursor: 'pointer', 
          //             fontSize: '11px',
          //           }}
          //         >Fazer upload de novo certificado?</label>
          //     ):(null)}

          //   </Modal.Body>
          //   <Modal.Footer style={{backgroundColor: " #f8f8f8", justifyContent: "flex-end"}}>
          //       <Button outline 
          //           style={{
          //           padding: "15px", 
          //           outline:0,     
          //           height: '50px',
          //           width: '125px',
          //           marginLeft: '0px',
          //           }} 
          //           color="#00b43f"  
          //           onClick={() => this.validate()}
          //       >
          //           Enviar
          //       </Button>
          //   </Modal.Footer>
          // </Modal>

          <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
          >
            <ModalTitle> Validar autenticidade </ModalTitle>

              <p>{this.props.text}</p>

              <div 
                className="row" 
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <div 
                  className="input"
                  style={{
                    width: '320px',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto'
                  }}
                >
                  <input name='email' id='email' style={{display: 'none'}}></input> 

                  <Input
                    name="password"
                    type="password"
                    id="password"
                    label="Senha"
                    // placeholder="Senha"
                    // onChange={e => this.changePassword(e.target.value)}
                    onChange={e => this.changePassword(e)}
                    value={this.state.password}
                  />

                </div>
              </div>

              {this.props.openUpload ? (
                <label 
                  className='linkOpen' 
                  onClick={() => this.props.onHideToUpload()}
                  style={{
                    color: '#589bff',
                    cursor: 'pointer', 
                    fontSize: '11px',
                  }}
                >Fazer upload de novo certificado?</label>
              ):(null)}


              <div>
                <Button 
                  outline 
                  style={{
                    padding: "15px", 
                    outline:0,     
                    height: '50px',
                    width: '125px',
                    margin: 'auto'
                  }} 
                  color="#00b43f"  
                  onClick={() => this.validate()}
                >
                    Enviar
                </Button>
              </div>
          </Modal>
        );
    }
}
export default ModalPassword;