import React from "react";
import lupa from "../../assets/icons/icons-8-search.png";
import InputMask from "inputmask";

import { InputContainer } from "./styles";

class InputSearch extends React.Component {

    documentos = [
        { value: '*', label: "todos os Documentos" },
        { value: 'FQ', label: "FISPQ"},
        { value: 'FE', label: "Fichas de Emergência"}
    ];

    state = {
        ref: React.createRef(),
        documento: '',
        valor: ''
    };

    componentDidMount() {
        const { ref } = this.state;

        let input = ref.current;

        let im = new InputMask(this.props.mask, { showMaskOnHover: false });

        im.mask(input);
    }

    search = () => {
        let search = 1;
        let text = this.state.valor;        
        let documento = this.state.documento;
        
        this.props.update(text, search, documento);
    }

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.search();
        }
    }

    validateInput = async (e) => {
        this.setState({ valor: e});
        
        if (this.state.documento !== '') {
            this.props.update(e, 0, this.state.documento);
            
        } else {
            this.props.update(this.state.valor, 0);
        }
    }

    getValue2 = (e) => {
        this.setState({ documento: e });
        let documento = e;
        this.props.update(this.state.valor, 0, documento);
    }

    render() {
        const { ref } = this.state;
        return (

            <InputContainer documento={this.props.docSeguranca}>

                    <input ref={ref} onKeyDown={this.onKeyDown} {...this.props} value={this.state.valor} onChange={e => this.validateInput( e.target.value)}></input>
                    
                        <select value={this.props.value} onChange={(e => this.getValue2(e.target.value))}>
                            {this.documentos.map(e => (
                                <option id="value" key={e.value} value={e.value}> {e.label} </option>
                            ))}
                        </select>


                    <div className="search-button">
                        <img src={lupa} onClick={() => this.search()}></img>
                    </div>

            </InputContainer>
        );
    }
}

export default InputSearch;
