import React, { useState, useEffect } from "react";

import Select from "../../select/select_v2";
import Button from "../../button/button_v2";
import api from "../../../services/api";
import { Container } from "react-grid-system";
import Input from "../../input/input_v2";
import { FormContainer } from "./styles";
import { store } from "react-notifications-component";
import ModalUpload from '../../modal/modalUploadCertificado';
import ModalGeneric from '../../modal/modalGeneric';
import CardUserCfta from "../../card-user-cfta";
export const USER = "@userinfo";


const AddCreas = ({ ...props }) => {

    const { nome, cpf, email, sign, agronomoId, enviar_receita, whatsapp } = props;

    const [numeroCrea, setNumeroCrea] = useState('');
    const [erroNumeroCrea, setErroNumeroCrea] = useState(false);
    const [agronomoNome, setAgronomoNome] = useState('')
    const [tipoId, setTipoId] = useState('');
    const [erroTipo, setErroTipo] = useState(false);
    const tipos = [{
        value: 'crea', label: 'CREA'
    }, {
        value: 'visto', label: 'VISTO'
    }, {
        value: 'cfta', label: 'CFTA'
    }];
    const [receitaInicial, setReceitaInicial] = useState('');
    const [erroReceitaInicial, setErroReceitaInicial] = useState(false);
    const [receitasEmitidas, setReceitasEmitidas] = useState('');
    const [status] = useState({value: 1, label: 'Ativo'});
    const [homologAutomatico, setHomologAutomatico] = useState('');
    const estados = [
        { value: "NACIONAL", label: "Nacional" },
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ];
    const estados1 = [
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ];
    const [estadoId, setEstadoId] = useState("");
    const [erroEstado, setErroEstado] = useState(false);
    const [filiais, setFiliais] = useState([]);
    const [filial, setFilial] = useState('');
    const [erroFilial, setErroFilial] = useState(false);
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');

    const [usuarioSc, setUsuarioSc] = useState('');
    const [senhaSc, setSenhaSc] = useState('');
    const [usuarioEs, setUsuarioEs] = useState('');
    const [senhaEs, setSenhaEs] = useState('');

    const [typePassword, setTypePassword] = useState('password');
    const [tiposAssinatura, setTiposAssinatura] = useState([
        {value: 'M', label: 'Manual'},
        {value: 'D', label: 'Digital'},
        {value: 'E', label: 'Eletrônica'}
    ]);
    const [assinaturaId, setAssinaturaId] = useState('');
    const [texto, setTexto] = useState('');
    const [showModalUpload, setShowModalUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [contractSoftId, setContractSoftId] = useState('');
    const [isCrea, setIsCrea] = useState(false);
    const [agronomo, setAgronomo] = useState([]);
    const [blockSign, setBlockSign] = useState(false);
    const [showModalBlockSign, setShowModalBlockSign] = useState(false);


    const selectAssinatura = (ass) => {

        if (blockSign) {
            if (ass.value === 'M') {
                setTexto('Assinatura Manual é aquela onde você assina à próprio punho. Aceita em todos os estados brasileiros.');
            } else if (ass.value === 'D' || ass.value === 'E') {
                setShowModalBlockSign(true);
                setTexto('');
            } else {
                setTexto('');
            }
            setAssinaturaId(ass);
        } else {
            if (ass.value === 'M') {
                setTexto('Assinatura Manual é aquela onde você assina à próprio punho. Aceita em todos os estados brasileiros.');
            } else if (ass.value === 'D') {
                setTexto('Assinatura Digital é aquela que precisa de um certificado A1 válido. Aceita nos estados MT, PR, SC, RS, GO, MG, ES, SP e MS.');
            } else if (ass.value === 'E') {
                setTexto('Assinatura Eletrônica é aquela que utiliza um conjunto de dados digitais para validação. Aceita em todos os estados brasileiros, com exceção de MT, PR, SC e RS.');
            } else {
                setTexto('');
            }
            setAssinaturaId(ass);

        }
    }

    const getAgronomos = async () => {
        try {
            const response = await api.get(`/agronomos/${agronomoId}`);
            const agronomo = response.data.data;
            setAgronomo(agronomo);
            setAgronomoNome(agronomo.nome);
            setFilial(agronomo.filial.nome_fantasia);
            if (agronomo.creas.length > 0) {
                agronomo.creas.map(c => {
                    if(c.status == 1 ){
                        setIsCrea(true);
                    }
                })
            }
        } catch (error) {
        }
    };

    const getFiliais = async () => {
        try {
            const response = await api.get("/filiais");
            const filiais = response.data.data;
            
            if(filiais.length){
                const formatFiliais = filiais.map(f => {
                    let format = {
                        value: f.id,
                        label: f.nome_fantasia
                    };
                    return format;
                });            
                
                if (filiais && filiais.length > 0) {                
                    setFiliais(formatFiliais);
                } else {
                    setFiliais(formatFiliais);
                }
            }else{
                const formatFiliais = [{value: filiais.id, label: filiais.nome_fantasia}];
                setFiliais(formatFiliais);

            }
        } catch (e) {
            
        }
    };

    const validateNumeroCrea = numeroCrea => {
        setNumeroCrea(numeroCrea);
    }
    const validateReceitaInicial = receitaInicial => {
        let inputWithErrors = document.querySelectorAll(".error");
        for (let input of inputWithErrors) {
            input.classList.remove("error");
        }
        setReceitaInicial(receitaInicial);
    }
    const validateReceitaZero = valor => {
        if(valor == 0){
            setReceitaInicial('');
            
            const select = document.getElementById("receita_inicial");
            select.classList.add("error");

            store.addNotification({
                title: "Erro!",
                message: `O campo receita inicial deve ser maior que zero`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const handlePost = async e => {
        if (agronomoId) {            
            const payload = {
                estado: estadoId.value || estadoId,
                homologar_automatico: (
                    estadoId.value === 'MT' || estadoId.value === 'SC'|| estadoId.value === 'ES' || estadoId.value === 'GO' || estadoId === 'NACIONAL'
                ) && contractSoftId != 6 ? 1 : 0,
                crea: numeroCrea,
                tipo: tipoId.value,
                status: status.value,
                receita_inicial: receitaInicial,
                receitas_emitidas: receitasEmitidas,
                filial: filial.value,
                assinatura: assinaturaId.value,
                usuario: usuario == '' ? null : usuario,
                senha: senha == '' ? null : senha,
            }
            
            try {
                if(estadoId && numeroCrea && tipoId.value && status.value && filial){
    
                    setErroEstado(false);
                    setErroNumeroCrea(false);
                    setErroTipo(false);
                    setErroFilial(false);
    
                    if(estadoId === 'RJ'){
                        if(receitaInicial && receitaInicial != '' && receitaInicial != 0 && receitaInicial != '0' ){
            
                            const response = await api.post(`/agronomos/${agronomoId}/creas`, payload);
                            if (props.isModal === true) {
                                props.update();
                            } else {
                                window.location = '/agronomos';
                            }
                            setLoading(false);
                            
                            store.addNotification({
                                title: "Sucesso!",
                                message: `O ${tipoId.label} foi salvo`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            })
                        }else{
                            if(!receitaInicial){
                                setErroReceitaInicial(true);
    
                                store.addNotification({
                                    title: "Erro!",
                                    message: `Verifique os campos em vermelho`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            }else{
                                setErroReceitaInicial(false);
                            }
                        }
                    }else{
                        const response = await api.post(`/agronomos/${agronomoId}/creas`, payload);

                        if(response){
                            if (usuarioEs !== '' && senhaEs !== '') {
                                const responseUsersES = await api.post(`/agronomos/usuario/${agronomoId}/ES`, {
                                    usuario: usuarioEs,
                                    senha: senhaEs
                                });
                            }
                            if (usuarioSc != '' && senhaSc != '') {
                                const responseUsersSC = await api.post(`/agronomos/usuario/${agronomoId}/SC`, {
                                    usuario: usuarioSc,
                                    senha: senhaSc
                                });
                            }
                        }

                        setLoading(false);
                        if (props.isModal === true) {
                            props.update();
                        } else {
                            window.location = '/agronomos';
                        }

                        store.addNotification({
                            title: "Sucesso!",
                            message: `O ${tipoId.label} foi salvo`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        })
    
                    }
                }else{
                    setLoading(false);
                    
                    if(!estadoId){
                        setErroEstado(true);
                    }else{
                        setErroEstado(false);
                        if(estadoId === "RJ"){
                            if(!receitaInicial){
                                setErroReceitaInicial(true);
                            }else{
                                const select = document.getElementById("receita_inicial");
                                select.classList.remove("error");
                                setErroReceitaInicial(false);
                            }
                        }
                    }
    
                    if(!numeroCrea){
                        setErroNumeroCrea(true);
                    }else{
                        setErroNumeroCrea(false);
                    }
    
                    if(!tipoId.value){
                        setErroTipo(true);
                    }else{
                        setErroTipo(false);
                    }
    
                    if(!filial){
                        setErroFilial(true);
                    }else{
                        setErroFilial(false);
                    }
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Verifique os campos em vermelho`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } catch (error) {
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
    

        } else {
            setLoading(true);
            const payloadA = {
                nome,
                cpf,
                email,
                assinatura: sign,
                email,
                enviar_receita,
                whatsapp
            };
            const payload = {
                estado: estadoId.value || estadoId,
                homologar_automatico: (
                    estadoId.value === 'MT' || estadoId.value === 'SC'|| estadoId.value === 'ES' || estadoId.value === 'GO' || estadoId === 'NACIONAL'
                ) && contractSoftId != 6 ? 1 : 0,
                crea: numeroCrea,
                tipo: tipoId.value,
                status: status.value,
                receita_inicial: receitaInicial,
                receitas_emitidas: receitasEmitidas,
                filial: filial.value,
                assinatura: assinaturaId.value,
                usuario: usuario == '' ? null : usuario,
                senha: senha == '' ? null : senha,
            }
    
            try {
                if(estadoId && numeroCrea && tipoId.value && status.value && filial){
                    setErroEstado(false);
                    setErroNumeroCrea(false);
                    setErroTipo(false);
                    setErroFilial(false);
    
                    if(estadoId === 'RJ'){
                        if(receitaInicial && receitaInicial != '' && receitaInicial != 0 && receitaInicial != '0' ){
                            const response = await api.post(`/agronomos`, payloadA);
                            let agronomo_id = response.data.data.id;
            
                            const responsee = await api.post(`/agronomos/${agronomo_id}/creas`, payload);
                            if (props.isModal === true) {
                                props.update();
                            } else {
                                props.loc.history.push('/agronomos');
                            }
                            setLoading(false);
                            return (<>
                                {store.addNotification({
                                    title: "Sucesso!",
                                    message: `O ${tipoId.label} foi salvo`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                })
            
                                }
                            </>)
                        }else{
                            if(!receitaInicial){
                                setErroReceitaInicial(true);
                                store.addNotification({
                                    title: "Erro!",
                                    message: `Verifique os campos em vermelho`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            }else{
                                setErroReceitaInicial(false);
                            }
                        }
                    }else{
    
                        const response = await api.post(`/agronomos`, payloadA);
                        let agronomo_id = response.data.data.id;
    
                        const responsee = await api.post(`/agronomos/${agronomo_id}/creas`, payload);

                        if (responsee) {
                            if(response){
                                if (usuarioEs !== '' && senhaEs !== '') {
                                    const responseUsersES = await api.post(`/agronomos/usuario/${agronomo_id}/ES`, {
                                        usuario: usuarioEs,
                                        senha: senhaEs
                                    });
                                }
                                if (usuarioSc != '' && senhaSc != '') {
                                    const responseUsersSC = await api.post(`/agronomos/usuario/${agronomo_id}/SC`, {
                                        usuario: usuarioSc,
                                        senha: senhaSc
                                    });
                                }
                            }
                        }
                        setLoading(false);
                        if (props.isModal === true) {
                            props.update();
                        } else {
                            props.loc.history.push('/agronomos');
                        }
                        return (<>
                            {store.addNotification({
                                title: "Sucesso!",
                                message: `O ${tipoId.label} foi salvo`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            })
    
                            }
                        </>)
                    }
                }else{
                    setLoading(false);
                    
                    if(!estadoId){
                        setErroEstado(true);
                    }else{
                        setErroEstado(false);
                        if(estadoId === "RJ"){
                            if(!receitaInicial){
                                setErroReceitaInicial(true);
                            }else{
                                const select = document.getElementById("receita_inicial");
                                select.classList.remove("error");
                                setErroReceitaInicial(false);
                            }
                        }
                    }
    
                    if(!numeroCrea){
                        setErroNumeroCrea(true);
                    }else{
                        setErroNumeroCrea(false);
                    }
                    if(!tipoId.value){
                        setErroTipo(true);
                    }else{
                        setErroTipo(false);
                    }
    
                    if(!filial){
                        setErroFilial(true);
                    }else{
                        setErroFilial(false);
                    }
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Verifique os campos em vermelho`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } catch (err) {
    
                try {
                    if(err.response.data.errors[0].alerta){
                        store.addNotification({
                            title: "Aviso!",
                            message: `${err.response.data.errors[0].alerta}`,
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }else{

                        if (err.response.data.errors) {
                            store.addNotification({
                                title: "Erro!",
                                message: `${err.response.data.errors}`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        } else {
                            store.addNotification({
                                title: "Erro!",
                                message: `Verifique os campos em vermelho`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
            
                    }
                } catch (error) {
                    if(err.response.data.errors){
                        store.addNotification({
                            title: "Erro!",
                            message: `${err.response.data.errors}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                        
                    }
                    
                }
            }
            setLoading(false);
        }
    }

    const modalUploadClose = (e) => {
        if (!e) {
            setAssinaturaId({value: 'M', label: 'Manual'});
        }
        setShowModalUpload(false);
    }

    const enviaSenha = (usuarios) => {
        if (usuarios['sc']) {
            setUsuarioSc(usuarios['sc'].usuario);
            setSenhaSc(usuarios['sc'].senha);
        }

        if (usuarios['es']) {
            setUsuarioEs(usuarios['es'].usuario);
            setSenhaEs(usuarios['es'].senha);
        }
    }

    const getTiposAssinatura = async (estado) => {
        try {
            setTiposAssinatura([]);
            const response = await api.get(`/sign/estado/${estado}`);

            const tipos = response.data.data;
            let tipo = []

            if (tipos.manual === true) {
                tipo = [...tipo, {value: 'M', label: 'Manual'}];
            } 
            if (tipos.digital === true) {
                tipo = [...tipo, {value: 'D', label: 'Digital'}]; 
            }
            if (tipos.eletronica === true) {
                tipo = [...tipo, {value: 'E', label: 'Eletrônica'}]; 
            }

            setTiposAssinatura(tipo);

        } catch (error) {
            console.log(error.response.data);
        }
    }

    const changeEstado = (estado) => {
        if (estadoId === '') {
            setEstadoId(estado);

            if (estado.value === 'NACIONAL') {
                setTipoId({value: 'cfta', label: 'CFTA'});
            }

            if (estado.value) {
                getTiposAssinatura(estado.value);
            }
        } else if (estadoId.value != estado.value) {
            setEstadoId(estado);
            
            if (estado.value === 'NACIONAL') {
                setTipoId({value: 'cfta', label: 'CFTA'});
            }

            if (estado.value) {
                getTiposAssinatura(estado.value);
            }
        }
    }

    const changeTipoCrea = (tipo) => {

        if (tipo) {

            if (tipo.value != tipoId.value) {
                setTipoId(tipo);
                if(tipo.value === 'cfta') {
                    setEstadoId('NACIONAL');
                }else {
                    if (estadoId.value == 'NACIONAL') {
                        setEstadoId({value: '', label: ''});
                    }
                }
            }
        } else {
            setTipoId('');
        }
    }

    const validaEstadoVisto = async () => {
        if (agronomo !== null) {
            if (agronomo.creas) {
                agronomo.creas.map(c => {
                    if(c.tipo == 'crea' && c.estado == estadoId && c.status == 1){
                        store.addNotification({
                            title: "Erro!",
                            message: `O Responsável Técnico já possui CREA cadastrado nesse estado! `,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        })
                    }else if(c.tipo == 'visto' && c.estado == estadoId && c.status == 1){
                        store.addNotification({
                            title: "Erro!",
                            message: `O Responsável Técnico já possui VISTO cadastrado para esse estado! `,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        })
                    }
                })
            }
        }

    };

    const goToPlans = () => {
        setShowModalBlockSign(false);
        props.propsLocation.history.push('/planos');
    }


    useEffect(() => {
        getFiliais();
        let user = JSON.parse(localStorage.getItem(USER));

        user.contrato.agro_plan_functions.map((e) => {
            if (e.agro_plans_function.id === 9) {
                if (e.status === 0) {
                    setBlockSign(true);
                }
            }
        });

        if (user.contrato.agro_plan_config.is_teste === 0) {
            setContractSoftId(8);
        } else {
            setContractSoftId(6);
        }

        setHomologAutomatico({value: 0, label: 'Não'});

        if (agronomoId) {
            getAgronomos();
        } else {
            setAgronomoNome(props.nome)
        }


        if (props.estadoId) {
            changeEstado(props.estadoId);
        }
    },[]);

    useEffect(() => {
        if (tiposAssinatura.length > 0) {
            if (props.certificado == true) {
                selectAssinatura({value: 'D', label: 'Digital'})
            } else {
                selectAssinatura({value: 'M', label: 'Manual'})
            }
        }
    },[tiposAssinatura]);

    useEffect(() => {
        console.log('ASSINATURA SELECIONADA: ', assinaturaId)
        if (!blockSign) {
            if (assinaturaId.value === 'D') {
                if (props.certificado == false) {
                    setShowModalUpload(true);
                } 
            }
        }
    },[assinaturaId]);

    useEffect(() => {
        if ((tipoId.value == 'crea') && isCrea == true) {

            store.addNotification({
                title: "Erro!",
                message: `O Responsável Técnico já possui CREA cadastrado! `,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            })
        } else {

            if(tipoId.value == 'visto'){
                validaEstadoVisto();
            }else if(tipoId.value == 'cfta'){
            }

        }
    }, [tipoId]);

    return (
        <>
            <FormContainer { ...props }>
                <Container>
                
                    <form>

                        <Input
                            name="responsavelTecnico"
                            type="text"
                            label="Responsável Técnico"
                            id="responsavel_tecnico"
                            value={agronomoNome}
                        />

                        <Select
                            name="filial"
                            className="select"
                            label="Filial"
                            id="filial_id"
                            disabled={false}
                            value={filial.value || filial}
                            onChange={e => setFilial(e)}
                            erro={erroFilial}
                            options={filiais}
                            selectUniqueOption={true}
                        />
                        
                        <Select
                            name="tipo"
                            className="select"
                            label="CREA/VISTO/CFTA"
                            id="tipo_id"
                            disabled={false}
                            value={tipoId.value || tipoId}
                            onChange={(tipo) => changeTipoCrea(tipo)}
                            erro={erroTipo}
                            options={tipos}
                            selectUniqueOption={true}
                        />


                        {tipoId.value == 'cfta' ? (
                            <Select
                                name="estado"
                                className="select"
                                label="Estado"
                                id="estado_cfta_id"
                                disabled={true}
                                value={"NACIONAL"}
                                estado={true}
                                onChange={estado => (changeEstado(estado))}
                                options={estados}
                                selectUniqueOption={true}
                            />
                        ):(
                            <Select
                                name="estado"
                                className="select"
                                label="Estado"
                                id="estado_crea_id"
                                disabled={false}
                                value={estadoId.value != '' ? estadoId.value : '' }
                                estado={true}
                                onChange={estado => (changeEstado(estado))}
                                options={estados1}
                                erro={erroEstado}
                                selectUniqueOption={true}
                            />
                        )}


                        <Input
                            name="numeroCrea"
                            type="text"
                            label="Número - CREA/Visto/CFTA"
                            id="crea_id"
                            onChange={e => validateNumeroCrea(e)}
                            value={numeroCrea}
                            erro={erroNumeroCrea}
                        />

                        {estadoId.value === "SC" || estadoId === "SC" ? (
                            <>
                            <Input 
                                    id='user'
                                    name="user"
                                    type="text"
                                    label="Usuário SIGEN"
                                    onChange={e => setUsuario(e)}
                                    value={usuario}
                                />

                                <div className="senha">
                                    <Input 
                                        id='pass'
                                        name="pass"
                                        type={typePassword}
                                        label="Senha SIGEN"
                                        onChange={e => setSenha(e)}
                                        value={senha}
                                    />
                                </div>
                            </>
                        ):(<>
                            {estadoId.value === "ES" || estadoId === "ES" && contractSoftId != 6 ? (
                                <>
                                    <Input 
                                        id='user'
                                        name="user"
                                        type="text"
                                        label="Usuário IDAF"
                                        onChange={e => setUsuario(e)}
                                        value={usuario}
                                        />

                                    <div className="senha">
                                        <Input 
                                            id='pass'
                                            name="pass"
                                            type={typePassword}
                                            label="Senha IDAF"
                                            onChange={e => setSenha(e)}
                                            value={senha}
                                        />
                                    </div>
                                </>
                            ):(
                                null
                            )}
                        </>)}

                        <Select
                            name="assinatura"
                            className="select"
                            label="Tipo de assinatura preferencial"
                            id="assinatura_id"
                            options={tiposAssinatura}
                            value={assinaturaId.value || assinaturaId}
                            onChange={(e) => selectAssinatura(e)}
                            selectUniqueOption={true}
                            marginBottom='-15px'
                        />


                        <label style={{ textAlign: 'center', fontSize: '11px', marginBottom: '25px'  }}>{texto}</label>


                        {estadoId === 'RJ'  || estadoId.value === 'RJ'? <>
                            <Input
                                name="receitaInicial"
                                type="text"
                                label="Receita Inicial"
                                id="receita_inicial_id"
                                value={receitaInicial}
                                onChange={e => validateReceitaInicial(e)}
                                onBlur={e => validateReceitaZero(e.target.value)}
                                marginBottom='20px'
                            />
                        </> : null}


                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                            <Button 
                                width='100%'
                                height='50px'
                                value='CADASTRAR'
                                outline={false}
                                disabled={false}
                                onClick={() => handlePost()}
                                loading={loading}
                            />
                        </div>

                    </form>

                <ModalUpload 
                    show={showModalUpload}
                    onHide={(e) => modalUploadClose(e)}
                    cpf={props.cpf}
                />

                <ModalGeneric
                    show={showModalBlockSign}
                    size='lg'
                    onHide={() =>{ 
                        setShowModalBlockSign(false);
                        setAssinaturaId({value: 'M', label: 'Manual'})
                    }}
                >
                    <p 
                        style={{
                            fontFamily: 'Lato', 
                            fontSize: '22px', 
                            fontWeight: 'bold', 
                            fontStretch: 'normal', 
                            width: '100%', 
                            textAlign: 'center', 
                            alignSelf: 'center'
                        }}
                    > Essa função está disponível apenas nos planos LIGHT e PRO.</p>

                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Faça o upgrade do seu plano para utilizar assinatura eletrônica e digital</p>
                    
                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Emita as suas receitas direto do campo, otimizando o seu tempo e garantindo a segurança na emissão desses documentos.</p>
                    
                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Utilize o cupom <b>ASSINEJA</b> e garanta 10% de desconto OFF nas mensalidades dos planos Light e Pro do AgroReceita!</p>
                    
                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Faça um upgrade para desbloquear esse recurso.</p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                        <Button
                            width='296px'
                            height='50px'
                            value='VER PLANOS'
                            outline={false}
                            disabled={false}
                            onClick={() => goToPlans()}
                            loading={false}
                        />
                    </div>
                </ModalGeneric>

                </Container>
            </FormContainer>
        </>
    );
};

export default AddCreas;
