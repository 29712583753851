import styled from "styled-components";


export const ProgressBar = styled.div`
  width: 120px;
  height: 7px;
  border-radius: 3.5px;
  background-color: #f3f3f5;
  padding-top: 2px;
  padding-right: ${props => props.progress};
  margin: auto;

`;

export const Progress = styled.div`
  height: 3px;
  border-radius: 1.5px;
  background-color: #00b43f;

`;

export const ArtsContainer = styled.section`
  padding: 50px 0;
  background: #f8f8f9;
  height: 100%;
  width: 100%;
  max-width: ${props => props.isIndex ? '100%' : '400px'};
  margin: auto;
  
  a{
    text-decoration: none;
  }

  .search{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -80px;
    margin-bottom: 20px;
    
  }

  .filterbuttons{
    margin-left: -5px;
  }
  
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .filterbuttons {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-around;
      i{ 
        margin-top: 2px;
        margin-right: 10px;
      }
      .pesquisar {
        margin: 30px 0;
        padding: 15px 20px;
      }
    }

    #newclassbuttonfortests{
      display: flex;
    }

    .botoesfinais{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 50%;
      margin-top: 20px;
      .loadmore {
      }
      .relatorio {

      }
    }
  }


  @media only screen and (max-width: 991px){
    form{
      width: 95%;
    }


    .search{
      .add{
        margin-left: 0px;
        a{
          text-decoration: none;
        }
      }
    }
  }


  @media only screen and (max-width: 768px) {
    background: #f8f8f9;
    height: 100%;

    .search{
      align-self: center;
      margin-left: 0px;
      margin-top: 65px;
      width: 100%;
      max-width: 375px;
    }

      .add{
        margin-left: -10px;
        margin-top: 10px;
        width: 100%;
        max-width: 375px;
        button{
          width: 87%;
          margin: auto;
          border-radius: 30px;
          border: solid 1px #00b43f;
          z-index: 9;
          display: flex;
          justify-content: center;
        }   
        a{
          text-decoration: none;
        }  
    }

    .filterbuttons{
      margin-left: -5px;
    }
    
    .loadmoree {
      margin-top: -50px;

        .loadmore {
          width: 100%;
          max-width: 375px;
          margin: 30px 0;
          padding: 15px 20px;

      
    }
      #newclassbuttonfortests{
        display: flex;
      }

      
      .add {
        align-self: flex-end;
        button {
          margin: 15px 0 30px;
          padding: 15px 30px;
        }
        a{
          text-decoration: none;
        }
      }
    }
    >div{

      .relatorio {
        width: 100%;
        max-width: 375px;
        margin-left: 0px;
        padding: 15px;
        justify-content: center;
      }
    }
  }

  @media only screen and (max-width: 540px){
    margin-top: 50px;


    .search{
      margin-top: 0px;
    }
  }


`;

export const ArtsAddContainer = styled.div`
  padding: 50px 0;
  background: #f8f8f9;
  div {
    form {
      margin: auto;
      min-width: 250px;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      small {
        color: #b53737;
        font-weight: 700;
      }
    }
  }
  .salvar {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-around;
    .salvado {
      margin: 30px 0;
      padding: 15px 20px;
    }
    a{
      text-decoration: none;
    }
  }
  .specific {
    display: flex;
    justify-content: space-around;
    .link{
      &:hover{
        text-decoration: none;
      }
    }

    a{
      text-decoration: none;
    }
  }

  .button-finish{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .btn {
      width: 100%;
    }
  }

  @media only screen and (max-width: 991px){
    div{
      form{

      }
    }
    .salvar {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-around;
      flex-direction: column-reverse;
      .salvado {
        margin: 30px 0;
        padding: 15px 20px;
        width: 100%;
      }
      a{
        text-decoration: none;
      }
    }

    .specific {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      .link{
        &:hover{
          text-decoration: none;
        }
      }

      a{
        text-decoration: none;
      }
    }
  }

  @media only screen and (max-width: 411px){
    .button-finish{
    flex-direction: column;
      .btn {
        width: 100%;
      }
    }

  }
`;

export const FormContainer = styled.div`
width: 400px;
padding: 50px 0;
background: #f8f8f9;
margin: auto;
form{
  margin: auto;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .adicionar{
    width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .add{
      width: 146px;
      text-align: center;
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #589bff;
      cursor: pointer;
      position: absolute;
      margin-top: 57px;
      margin-left: 242px;
      .click{
        position: absolute;
        margin-left: 161px;
        width: 90px;
      }
    }
  }
}

 @media only screen and (max-width: 991px){
  padding: 50px 0;
  background: #f8f8f9;
  form{
    margin: auto;
    min-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .adicionar{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .add{
        width: 146px;
        text-align: center;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #589bff;
        cursor: pointer;
        position: absolute;
        margin-top: 15px;
        margin-left: 75px;
        .click{
          position: absolute;
          margin-left: 35px;
        }
      }
        
    }
  }
 }
`;
