import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';

class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    updateOk = () => {
      this.props.onHide();
    }



    render(){
        return(
            
        //     <Modal
        //       {...this.props}
        //       size="lg"
        //       aria-labelledby = "contained-modal-title-vcenter"
        //       centered
        //     >
              
        //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8", border: 'none'}}>
        //     </Modal.Header >

        //     <Modal.Body style={{backgroundColor: " #f8f8f8", borderRadius: '3px'}}>

        //         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', paddingLeft: '20px', paddingRight: '20px'}}>
        //             <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>POLITICA DE PRIVACIDADE</p>
        //             <p style={{textAlign: 'justify', fontWeight: '300',  lineHeight: '1.5'}}>
        //                 Nós, da Sudeste Automação da Informação Ltda, CNPJ: 11.080.217/0001-75, doravante SUDESTE ONLINE, julgamos ser de extrema importância resguardar a sua privacidade. Para tanto, tomamos todas as medidas possíveis para protegê-la.  O objetivo deste documento é esclarecer quais informações são coletadas dos usuários de nossos sites <a href="https://sudesteonline.com.br" target="_blank">https://sudesteonline.com.br</a>, <a href="https://softficha.com.br" target="_blank">https://softficha.com.br</a>  <a href="https://www.agroreceita.com.br" target="_blank">https://www.agroreceita.com.br</a>, <a href="https://sistema.softficha.com.br/" target="_blank">https://sistema.softficha.com.br/</a>, <a href="https://sistema.agroreceita.com.br/" target="_blank">https://sistema.agroreceita.com.br/</a> e seus respectivos serviços e de que forma esses dados são manipulados e utilizados.
        //                 <br /><br />
        //                 Para disponibilizar os nossos serviços coletamos diversos dados e informações, por meio de nossos sites, dos equipamentos onde o mesmo se encontra instalado ou sendo utilizado e dos dispositivos sincronizados com ele. Dessa forma, a presente Política de Privacidade visa esclarecer como os seus dados serão coletados, usados, compartilhados e armazenados por meio dos nossos sites e respectivos serviços.
        //                 <br /><br />
        //                 Entende-se por Política de Privacidade o conjunto de regras que determina o tratamento destinado a todas as informações que você fornece quando preenche dados cadastrais. Essas regras são fixadas e obedecidas pela SUDESTE ONLINE. As informações para a solicitação de contato servem exclusivamente para oferecer o melhor dos nossos serviços e produtos.
        //                 <br /><br />
        //                 O aceite à nossa Política será feito quando você se cadastrar em nossas plataformas para usufruir dos nossos serviços, mesmo que de forma gratuita. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados, consentindo que a Sudeste Online colete, utilize, armazene e faça o tratamento de suas informações, que serão necessários para que o serviço ofertado seja proporcionado em sua integralidade. 
        //                 <br /><br />
        //                 No caso de discordância com nossos termos, solicitamos que não prossiga com seu cadastro e não utilize os nossos serviços, nos informando, por favor, o motivo da não concordância, para que possamos aperfeiçoar nossa prestação de serviços.
        //                 <br /><br />
        //                 Em caso de dúvidas em relação à nossa Política de Privacidade, favor nos enviar um e-mail para marketing@sudesteonline.com.br. Estamos localizados a Rua Rosa Lotfi de Almeida Bueno, 155 – Vila Nastri II, Itapetininga (SP). CEP: 18206-390.
        //                 <br /><br />
        //                 <b style={{fontWeight: '700'}}>Sobre a coleta de dados</b>
        //                 <br /><br />
        //                 Quando você se cadastra em nossos sites, via formulários, para download de nossos conteúdos gratuitos, para solicitar orçamentos ou para realizar o teste grátis de nossos sistemas, nos fornece algumas informações pessoais, como: nome, telefone, e-mail, empresa em que trabalha e o seu cargo, sua formação acadêmica e suas preferências dos serviços.
        //                 <br /><br />
        //                 Outras informações, também, podem ser solicitadas, seja por meio de contato direto da SUDESTE ONLINE, com os usuários via e-mail ou telefone. A SUDESTE ONLINE usará as informações coletadas para personalizar ao máximo o atendimento às suas necessidades, visando, sobretudo, proporcionar uma experiência cada vez melhor para você.
        //                 <br /><br />
        //                 Quando você visita os nossos sites, é inserido um ‘cookie’ no seu navegador por meio de softwares de análise de navegação, como o Google Analytics, e/ou tecnologias similares, que tem por objetivo identificar a quantidade de acessos que o usuário faz em nosso site. São coletadas informações como: endereço IP, localização geográfica, fonte de referência, tipo de navegador, duração da visita e páginas visitadas. As informações são armazenadas a partir de todos os contatos já realizados com nossos usuários, seja através de interações via e-mail ou de conteúdos baixados em nossas páginas.
        //                 <br /><br />
        //                 <b style={{fontWeight: '700'}}>Sobre o uso de seus dados pessoais</b>
        //                 <br /><br />
        //                 Todas as informações que coletamos sobre você são utilizadas para a prestação de nossos serviços. Estes dados são tratados como confidenciais e somente serão utilizados para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar nossos serviços de forma plena, visando sempre melhorar a sua experiência como usuário.
        //                 <br /><br />
        //                 Eventualmente, poderemos utilizar os seus dados para outros fins não previstos nesta Política de Privacidade, porém mediante a sua autorização prévia. Também, utilizaremos seu e-mail para o envio de materiais ou de informações solicitadas por você no momento do preenchimento de formulários, envio de newsletters, comunicados de lançamentos de novos conteúdos gratuitos ou de novos produtos da SUDESTE ONLINE. Os dados de download poderão ser divulgados como pesquisas e estatísticas, não sendo revelada nenhuma informação pessoal, a menos que autorizada explicitamente.
        //                 <br /><br />
        //                 Nós, da SUDESTE ONLINE, também, poderemos entrar em contato via e-mail ou telefone para fazer pesquisas ou apresentar produtos e serviços. Enviar mensagens sobre suporte ou serviços, notificações e atualizações, eventos ou outros assuntos que possam ser do seu interesse.
        //                 <br /><br />
        //                 <b style={{fontWeight: '700'}}>Sobre o acesso aos seus dados pessoais</b>
        //                 <br /><br />
        //                 Somente os funcionários da SUDESTE ONLINE terão acesso a seus dados pessoais, onde se comprometem a manter o sigilo de todas as informações coletadas. Nenhum dado pessoal poderá ser divulgado publicamente. Nós da SUDESTE ONLINE procuramos sempre a melhor tecnologia para garantir a segurança dos nossos sistemas e dos seus dados. Nossos servidores utilizados são os mais conceituados do mercado, localizados fora do Brasil, para garantir estabilidade e segurança, e somente podem ser acessados por meio de canais de comunicação previamente autorizados.
        //                 <br /><br />
        //                 A SUDESTE ONLINE considera a sua privacidade primordial e tomará todas as precauções para protegê-la. Porém, não temos como garantir complemente que todos os dados e informações sobre você em nosso sistema estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das credenciais necessárias para acessar a nossos sistemas.
        //                 Portanto, você é o único responsável por manter sua senha de acesso em local seguro e é vedado o compartilhamento desta com terceiros. Você se compromete a notificar a SUDESTE ONLINE imediatamente, por meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.
        //                 <br /><br />
        //                 <b style={{fontWeight: '700'}}>Sobre o compartilhamento de dados</b>
        //                 <br /><br />
        //                 Todas as informações sobre você podem ser consideradas relevantes no caso de negociações em que a SUDESTE ONLINE fizer parte. Portanto, nos reservamos no direito de incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra.
        //                 Por meio desta Política você concorda e está ciente desta possibilidade.
        //                 Todos os dados que você informar não serão comunicados a terceiros sem a sua autorização expressa. A única exceção é no caso de uma autoridade judicial ou administrativa nos apresentar alguma determinação nesse sentido, na eventualidade de alguma prática criminosa.
        //                 <br /><br />
        //                 Sobre o cancelamento da assinatura e alteração/exclusão de informações pessoais Você pode optar por cancelar sua assinatura para não receber mais qualquer tipo de e-mail da Sudeste Online. Em todos os nossos e-mails que enviamos consta um link de cancelamento de assinatura, disponível no rodapé. Ao clicar nesse link você será automaticamente descadastrado da lista.
        //                 <br /><br />
        //                 É importante informar que ao preencher um novo formulário em nosso site, ficará caracterizada a reinserção do seu e-mail à lista. Portanto, a requisição de cancelamento deve ser feita novamente caso seja de seu interesse.
        //                 Todos os dados coletados serão excluídos de nossos servidores quando solicitado. Para alterar suas informações pessoais ou excluí-las do nosso banco de dados manualmente, basta enviar um e-mail para marketing@sudesteonline.com.br.
        //                 <br /><br />
        //                 <b style={{fontWeight: '700'}}>Mudança desta política</b>
        //                 <br /><br />
        //                 A evolução constante da Internet requer ajustes ocasionais da nossa declaração de privacidade. Nós nos reservamos ao direito de efetuar tais alterações, quando necessário. No caso, a Política de Privacidade da SUDESTE ONLINE pode passar por atualizações. Você será avisado para tomar conhecimento e aceitar ou não os novos termos. De qualquer forma, tenha a certeza de que a SUDESTE ONLINE busca fazer o melhor para proteger os seus interesses e direitos.
        //                 <br /><br />
        //                 <b style={{fontWeight: '700'}}>Lei aplicável</b>
        //                 <br /><br />
        //                 Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Cidade de Itapetininga, Estado de São Paulo, para dirimir quaisquer questões, porventura oriundas do presente documento, com expressa renúncia a qualquer outro, por mais privilegiado que seja.
        //                 <br /><br /><br />
        //             </p>
        //             <p style={{textAlign:'center', fontWeight: '300'}}>
        //                 <b style={{fontWeight: '700'}}>SUDESTE AUTOMAÇÃO DA INFORMAÇÃO LTDA</b> – 2020- Todos os direitos reservados.
        //             </p>
        //         </div>


        //         <div className="btns" style={{ display: 'flex' }}>
        //             {this.props.btnOk == true ? (
        //                 <Button
        //                 style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
        //                 color="#00b43f"
        //                 onClick={() => this.updateOk() }
        //                 >
        //                 ENTENDI
        //                 </Button>
        //             ):(null)}
        //         </div>

        //     </Modal.Body>
        //   </Modal>

        <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
            width='60%' 
        >

            <ModalTitle> POLITICA DE PRIVACIDADE </ModalTitle>

            <ModalBody >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', paddingLeft: '20px', paddingRight: '20px'}}>
                    {/* <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>POLITICA DE PRIVACIDADE</p> */}
                    <p style={{textAlign: 'justify', fontWeight: '300',  lineHeight: '1.5'}}>
                        Nós, da Sudeste Automação da Informação Ltda, CNPJ: 11.080.217/0001-75, doravante SUDESTE ONLINE, julgamos ser de extrema importância resguardar a sua privacidade. Para tanto, tomamos todas as medidas possíveis para protegê-la.  O objetivo deste documento é esclarecer quais informações são coletadas dos usuários de nossos sites <a href="https://sudesteonline.com.br" target="_blank">https://sudesteonline.com.br</a>, <a href="https://softficha.com.br" target="_blank">https://softficha.com.br</a>  <a href="https://www.agroreceita.com.br" target="_blank">https://www.agroreceita.com.br</a>, <a href="https://sistema.softficha.com.br/" target="_blank">https://sistema.softficha.com.br/</a>, <a href="https://sistema.agroreceita.com.br/" target="_blank">https://sistema.agroreceita.com.br/</a> e seus respectivos serviços e de que forma esses dados são manipulados e utilizados.
                        <br /><br />
                        Para disponibilizar os nossos serviços coletamos diversos dados e informações, por meio de nossos sites, dos equipamentos onde o mesmo se encontra instalado ou sendo utilizado e dos dispositivos sincronizados com ele. Dessa forma, a presente Política de Privacidade visa esclarecer como os seus dados serão coletados, usados, compartilhados e armazenados por meio dos nossos sites e respectivos serviços.
                        <br /><br />
                        Entende-se por Política de Privacidade o conjunto de regras que determina o tratamento destinado a todas as informações que você fornece quando preenche dados cadastrais. Essas regras são fixadas e obedecidas pela SUDESTE ONLINE. As informações para a solicitação de contato servem exclusivamente para oferecer o melhor dos nossos serviços e produtos.
                        <br /><br />
                        O aceite à nossa Política será feito quando você se cadastrar em nossas plataformas para usufruir dos nossos serviços, mesmo que de forma gratuita. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e seus dados, consentindo que a Sudeste Online colete, utilize, armazene e faça o tratamento de suas informações, que serão necessários para que o serviço ofertado seja proporcionado em sua integralidade. 
                        <br /><br />
                        No caso de discordância com nossos termos, solicitamos que não prossiga com seu cadastro e não utilize os nossos serviços, nos informando, por favor, o motivo da não concordância, para que possamos aperfeiçoar nossa prestação de serviços.
                        <br /><br />
                        Em caso de dúvidas em relação à nossa Política de Privacidade, favor nos enviar um e-mail para marketing@sudesteonline.com.br. Estamos localizados a Rua Rosa Lotfi de Almeida Bueno, 155 – Vila Nastri II, Itapetininga (SP). CEP: 18206-390.
                        <br /><br />
                        <b style={{fontWeight: '700'}}>Sobre a coleta de dados</b>
                        <br /><br />
                        Quando você se cadastra em nossos sites, via formulários, para download de nossos conteúdos gratuitos, para solicitar orçamentos ou para realizar o teste grátis de nossos sistemas, nos fornece algumas informações pessoais, como: nome, telefone, e-mail, empresa em que trabalha e o seu cargo, sua formação acadêmica e suas preferências dos serviços.
                        <br /><br />
                        Outras informações, também, podem ser solicitadas, seja por meio de contato direto da SUDESTE ONLINE, com os usuários via e-mail ou telefone. A SUDESTE ONLINE usará as informações coletadas para personalizar ao máximo o atendimento às suas necessidades, visando, sobretudo, proporcionar uma experiência cada vez melhor para você.
                        <br /><br />
                        Quando você visita os nossos sites, é inserido um ‘cookie’ no seu navegador por meio de softwares de análise de navegação, como o Google Analytics, e/ou tecnologias similares, que tem por objetivo identificar a quantidade de acessos que o usuário faz em nosso site. São coletadas informações como: endereço IP, localização geográfica, fonte de referência, tipo de navegador, duração da visita e páginas visitadas. As informações são armazenadas a partir de todos os contatos já realizados com nossos usuários, seja através de interações via e-mail ou de conteúdos baixados em nossas páginas.
                        <br /><br />
                        <b style={{fontWeight: '700'}}>Sobre o uso de seus dados pessoais</b>
                        <br /><br />
                        Todas as informações que coletamos sobre você são utilizadas para a prestação de nossos serviços. Estes dados são tratados como confidenciais e somente serão utilizados para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar nossos serviços de forma plena, visando sempre melhorar a sua experiência como usuário.
                        <br /><br />
                        Eventualmente, poderemos utilizar os seus dados para outros fins não previstos nesta Política de Privacidade, porém mediante a sua autorização prévia. Também, utilizaremos seu e-mail para o envio de materiais ou de informações solicitadas por você no momento do preenchimento de formulários, envio de newsletters, comunicados de lançamentos de novos conteúdos gratuitos ou de novos produtos da SUDESTE ONLINE. Os dados de download poderão ser divulgados como pesquisas e estatísticas, não sendo revelada nenhuma informação pessoal, a menos que autorizada explicitamente.
                        <br /><br />
                        Nós, da SUDESTE ONLINE, também, poderemos entrar em contato via e-mail ou telefone para fazer pesquisas ou apresentar produtos e serviços. Enviar mensagens sobre suporte ou serviços, notificações e atualizações, eventos ou outros assuntos que possam ser do seu interesse.
                        <br /><br />
                        <b style={{fontWeight: '700'}}>Sobre o acesso aos seus dados pessoais</b>
                        <br /><br />
                        Somente os funcionários da SUDESTE ONLINE terão acesso a seus dados pessoais, onde se comprometem a manter o sigilo de todas as informações coletadas. Nenhum dado pessoal poderá ser divulgado publicamente. Nós da SUDESTE ONLINE procuramos sempre a melhor tecnologia para garantir a segurança dos nossos sistemas e dos seus dados. Nossos servidores utilizados são os mais conceituados do mercado, localizados fora do Brasil, para garantir estabilidade e segurança, e somente podem ser acessados por meio de canais de comunicação previamente autorizados.
                        <br /><br />
                        A SUDESTE ONLINE considera a sua privacidade primordial e tomará todas as precauções para protegê-la. Porém, não temos como garantir complemente que todos os dados e informações sobre você em nosso sistema estarão livres de acessos não autorizados, principalmente caso haja compartilhamento indevido das credenciais necessárias para acessar a nossos sistemas.
                        Portanto, você é o único responsável por manter sua senha de acesso em local seguro e é vedado o compartilhamento desta com terceiros. Você se compromete a notificar a SUDESTE ONLINE imediatamente, por meio seguro, a respeito de qualquer uso não autorizado de sua conta, bem como o acesso não autorizado por terceiros a esta.
                        <br /><br />
                        <b style={{fontWeight: '700'}}>Sobre o compartilhamento de dados</b>
                        <br /><br />
                        Todas as informações sobre você podem ser consideradas relevantes no caso de negociações em que a SUDESTE ONLINE fizer parte. Portanto, nos reservamos no direito de incluir seus dados dentre os ativos da empresa caso esta venha a ser vendida, adquirida ou fundida com outra.
                        Por meio desta Política você concorda e está ciente desta possibilidade.
                        Todos os dados que você informar não serão comunicados a terceiros sem a sua autorização expressa. A única exceção é no caso de uma autoridade judicial ou administrativa nos apresentar alguma determinação nesse sentido, na eventualidade de alguma prática criminosa.
                        <br /><br />
                        Sobre o cancelamento da assinatura e alteração/exclusão de informações pessoais Você pode optar por cancelar sua assinatura para não receber mais qualquer tipo de e-mail da Sudeste Online. Em todos os nossos e-mails que enviamos consta um link de cancelamento de assinatura, disponível no rodapé. Ao clicar nesse link você será automaticamente descadastrado da lista.
                        <br /><br />
                        É importante informar que ao preencher um novo formulário em nosso site, ficará caracterizada a reinserção do seu e-mail à lista. Portanto, a requisição de cancelamento deve ser feita novamente caso seja de seu interesse.
                        Todos os dados coletados serão excluídos de nossos servidores quando solicitado. Para alterar suas informações pessoais ou excluí-las do nosso banco de dados manualmente, basta enviar um e-mail para marketing@sudesteonline.com.br.
                        <br /><br />
                        <b style={{fontWeight: '700'}}>Mudança desta política</b>
                        <br /><br />
                        A evolução constante da Internet requer ajustes ocasionais da nossa declaração de privacidade. Nós nos reservamos ao direito de efetuar tais alterações, quando necessário. No caso, a Política de Privacidade da SUDESTE ONLINE pode passar por atualizações. Você será avisado para tomar conhecimento e aceitar ou não os novos termos. De qualquer forma, tenha a certeza de que a SUDESTE ONLINE busca fazer o melhor para proteger os seus interesses e direitos.
                        <br /><br />
                        <b style={{fontWeight: '700'}}>Lei aplicável</b>
                        <br /><br />
                        Este documento é regido e deve ser interpretado de acordo com as leis da República Federativa do Brasil. Fica eleito o Foro da Cidade de Itapetininga, Estado de São Paulo, para dirimir quaisquer questões, porventura oriundas do presente documento, com expressa renúncia a qualquer outro, por mais privilegiado que seja.
                        <br /><br /><br />
                    </p>
                    <p style={{textAlign:'center', fontWeight: '300'}}>
                        <b style={{fontWeight: '700'}}>SUDESTE AUTOMAÇÃO DA INFORMAÇÃO LTDA</b> – 2020- Todos os direitos reservados.
                    </p>
                </div>
            </ModalBody>

            <ModalFooter>
                {this.props.btnOk == true ? (
                    <Button
                        style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
                        color="#00b43f"
                        onClick={() => this.updateOk() }
                    >
                        ENTENDI
                    </Button>
                ):(null)}
            </ModalFooter>
        </Modal>
          
        );
    }
}
export default ModalNew;