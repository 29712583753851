import React from 'react';

const IconHome = () => {
    return (
        <svg viewBox="0 0 20 20" width="20px" height="20px">
        <g id="surface23875656">
            <path 
                style={{
                    stroke: 'none',
                    fillRule: 'nonzero',
                    fill: 'rgb(54.11765%,57.254905%,63.529414%)',
                    fillOpacity: 1
                }}
                d="M 12 2.097656 L 1 12 L 4 12 L 4 21 L 11 21 L 11 15 L 13 15 L 13 21 L 20 21 L 20 12 L 23 12 Z M 12 4.789062 L 18 10.191406 L 18 19 L 15 19 L 15 13 L 9 13 L 9 19 L 6 19 L 6 10.191406 Z M 12 4.789062 "
            />
        </g>
        </svg>
    )
}

export default IconHome;
