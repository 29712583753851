import React from 'react';

const IconSendWebHook = ({ ...props }) => {
    return (
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            viewBox="0,0,256,256"
            {...props}
        >
            <g 
                fill-opacity="0.98824" 
                fill="#000000" 
                fill-rule="nonzero" 
                stroke="none" stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none" 
                text-anchor="none" 
                style={{mixblendMode: 'normal'}}
            >
                <g transform="scale(16,16)">
                    <path d="M1.5,1c-0.82422,0 -1.5,0.67578 -1.5,1.5v8c0,0.82422 0.67578,1.5 1.5,1.5h0.5v0.5c0,0.82422 0.67578,1.5 1.5,1.5h4.5v-1h-4.5c-0.28125,0 -0.5,-0.21875 -0.5,-0.5v-7.51953l5.5,4.14453l5.5,-4.14453v5.01953h1v-5.5c0,-0.82422 -0.67578,-1.5 -1.5,-1.5h-0.5v-0.5c0,-0.82422 -0.67578,-1.5 -1.5,-1.5zM1.5,2h10c0.28125,0 0.5,0.21875 0.5,0.5v0.5h-8.5c-0.82422,0 -1.5,0.67578 -1.5,1.5v6.5h-0.5c-0.28125,0 -0.5,-0.21875 -0.5,-0.5v-8c0,-0.28125 0.21875,-0.5 0.5,-0.5zM3.5,4h10c0.03906,0 0.07422,0.01172 0.10938,0.02344l-5.10937,3.85156l-5.10937,-3.85156c0.03516,-0.01172 0.07031,-0.02344 0.10938,-0.02344zM13,11v2h-4v1h4v2l2.5,-2.5z"></path>
                </g>
            </g>
        </svg>
    )
}

export default IconSendWebHook;



