import styled from "styled-components";

export const BoxArea = styled.div`
    width: 200px;
    height: 50px;
    background-color: #FFF;
    border: 1px solid rgba(186,193,207, 0);
    border-radius: 25px;
    font-size: 16px;
    color: rgb(59, 56, 72);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

export const Subtract = styled.div`
    width: 50px;
    height: 50px;   
    border: 1px solid rgba(255, 82, 82, 0.5);
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;

    label {
        transition: all 0.2s ease;
        margin: 0px;
        color: rgb(255, 82, 82);
        cursor: pointer;

        &::-moz-selection { /* Code for Firefox */
            color: rgb(255, 82, 82);
            background: transparent;
        }

        &::selection {
            color: rgb(255, 82, 82);
            background: transparent;
        }
    }

    &:hover{
        background-color: rgba(255, 82, 82, 0.2);
        label {
            transform: scale(1.2);
        }
    }
    &:active{
        background-color: rgba(255, 82, 82, 0.5);
        label {
            transform: scale(0.8);
        }
    }
`;

export const Value = styled.div`
    width: 100px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: rgb(59, 56, 72);
    font-size: 24px;
    font-weight: 600;

    border-top: 1px solid rgba(186,193,207, 1);
    border-bottom: 1px solid rgba(186,193,207, 1);

    label {
        margin: 0px;
    }
`;

export const Add = styled.div`
    width: 50px;
    height: 50px;
    border: 1px solid rgba(47,187,105, 0.5);
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    cursor: pointer;

    label {
        transition: all 0.2s ease;
        margin: 0px;
        color: rgb(47,187,105);
        cursor: pointer;

        &::-moz-selection { /* Code for Firefox */
            color: rgb(47,187,105);
            background: transparent;
        }

        &::selection {
            color: rgb(47,187,105);
            background: transparent;
        }
    }

    &:hover{
        background-color: rgba(47,187,105, 0.2);
        label {
            transform: scale(1.2);
        }
    }
    &:active{
        background-color: rgba(47,187,105, 0.5);
        label {
            transform: scale(0.8);
        }
    }
    
`;