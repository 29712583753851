import styled from "styled-components";



export const ContainerDrag = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border: dashed 1px #8a92a2;
    border-radius: 8px;
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0px'};
    .legenda{
      font-size: smaller;
      width: 100%;
      margin-left: 5px;
      text-align: center;
      color: #8a92a2;
    }



    @media only screen and (max-width: 768px) {

    }

`;