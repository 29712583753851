export const compareData = (dtInicial, operador, dtFinal) => {

    let dt1 = new Date(dtInicial);
    let dt2 = new Date(dtFinal);

    let result = false;

    switch (operador) {
        case '=':
            if (dt1.getTime() === dt2.getTime()) {
                result = true;
            } else {
                result = false;
            }

            break;
    
        case '<':
            if (dt1.getTime() < dt2.getTime()) {
                result = true;
            } else {
                result = false;
            }
            break;
    
        case '>':
            if (dt1.getTime() > dt2.getTime()) {
                result = true;
            } else {
                result = false;
            }
            break;
    
        case '<=':
            if (dt1.getTime() <= dt2.getTime()) {
                result = true;
            } else {
                result = false;
            }
            break;
    
        case '>=':
            if (dt1.getTime() >= dt2.getTime()) {
                result = true;
            } else {
                result = false;
            }
            break;
    
        case '!=':
            if (dt1.getTime() != dt2.getTime()) {
                result = true;
            } else {
                result = false;
            }
            break;
    
        default:
            break;
    }

    return result;
}

export const base64UrlEncode = (string) => {
    return Buffer.from(string)
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}