import React, { useEffect } from "react";
import { GuiasContainer, ProdutoresContainer } from "./styles";
import api from "../../services/api";
import { Container } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import Button from "../../components/button/button_v2";
import DotsLoader from "../../components/loader";
import { store } from "react-notifications-component";
import CardGeral from "../../components/card-geral/index";
import ModalTesteGratisAcabou from '../../components/modal/modalFuncaoBloqueada';
import Paginator from '../../components/paginator';
import ModalAlert from '../../components/modal/modalGeneric';
import ModalConfirmar from "../../components/modal/modalConfirmar";
import { useState } from "react";

export const USER = "@userinfo";
export const VAL = "@validade";

const GuiasAplicacao = ({ ...props }) =>  {
    const [guias, setGuias] = useState([]);
    const [limit, setLimit] = useState(30);
    const [offset, setOffset] = useState(0);
    const [totalGuias, setTotalGuias] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hideButton, setHideButton] = useState(false);
    const [result, setResult] = useState("");
    const [result2, setResult2] = useState("");
    const [result3, setResult3] = useState("");
    const [result4, setResult4] = useState("");
    const [idGuia, setIdGuia] = useState(null);
    const [urlGuide, setUrlGuide] = useState(null);
    const [size_screen, setSizeScreen] = useState(window.screen.width);
    const [importar, setImportar] = useState(false);
    const [modalTesteGratisAcabou, setModalTesteGratisAcabou] = useState(false);
    const [user] = useState(JSON.parse(localStorage.getItem(USER)));
    const [val] = useState(JSON.parse(localStorage.getItem(VAL)));
    const [viewOptions, setViewOptions] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [modalApagarVerPdf, setModalApagarVerPdf] = useState(false);
    const [showModalGuides, setShowModalGuides] = useState(false);

    const updateResposta = async (resposta) => {
        console.log(resposta);
        if (resposta == 0) {
            setImportar(false);
        }
    }

    const updateValues = async (text, estado, searchP) => {
        if (searchP == 1) {
            
            if(!text && !estado){
                search('','', 30, 0);
            }else{   
                setResult2(estado);
                setResult(text);
                search(text, estado, 30,0);
            }
        }else{
            setResult(text);
            setResult2(estado);

        }
    }

    const search = async (result, result2, limit, offset) => {
        try {
            setLoadingSearch(true);
            const search = result;
            const estado = result2;

            const payload = {
                search,
                estado
            }

            const response = await api.post(`/guias/historico?limit=${limit}&offset=${offset}`, payload);

            setGuias(response.data.data);
            setTotalGuias(response.data.message);
            setLoadingSearch(false);

        } catch (error) {
            setLoadingSearch(false);
        }
    };

    const cleanFilter = () => {
        setResult("");
        setResult2("");
        setResult3("");
        setResult4("");
        setOffset(0);

        search('','',30,0);
    };

    const loadMore = async () => {
        setLoadingMore(true);
        setHideButton(true);

        const newOffset = limit + offset;

        try {
            const response = await api.post(
                "/guias/historico?limit=" + limit + "&offset=" + newOffset
            );
            let newGuias = response.data.data;
            const guiasConcat = guias.concat(newGuias);

            setGuias(guiasConcat);
            setOffset(newOffset);
            setHideButton(false);
        } catch (erro) {
            setHideButton(true);
            console.log("btn some");
            
        }
        setLoadingMore(false);
    };

    const formatDate = (data) => {
        if (data.length === 10) {
            data = data + 'T00:00:00';
        }

        let now = new Date(data);
        let day = now.getDate();
        let month = now.getMonth();
        let year = now.getFullYear();
        month = month + 1;
        if (day <= 9) {
            day = "0" + day;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        data = day + "/" + month + "/" + year;
        return data;
    }

    const deleteGuide = async (id) => {
        try {
            const response = await api.delete(`/guias/${id}`);
            if(response.data.message){
                store.addNotification({
                    title: "Sucesso!",
                    message: `${response.data.message}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                setModalApagarVerPdf(false);
                setTimeout(() => {
                    window.location = "/guia_aplicacao"
                }, 1000);
            }
        } catch (error) {
            setModalApagarVerPdf(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            search(result, result2, 30, 0);
        }
    }

    const openOption = () => {
        if(viewOptions == true){
            setViewOptions(false);
        }else{
            setViewOptions(true);
        }
    }

    const resposta = async (resposta, id) => {
        if(resposta == 1 && id){
            deleteGuide(id);
        }
        setShowModalGuides(false);
    };

    const updateLimitOffset = (l,o) => {
        if (l !== limit || o !== offset) {
            setLimit(l);
            setOffset(o);

            search(result, result2, l, o);
        }
    }

    useEffect(()=>{
        window.scrollTo(0, 0);

        search('', '', 30, 0);
        setInterval(async()=>{
            setSizeScreen(window.screen.width);
        }, 990);
    }, [])

    useEffect(()=>{
        console.log(guias);
    })

    return (
        <>
            <GenericUpper
                title="Guias de Aplicação"
                subtitle="Gerencie seus guias de aplicação emitidos"
                help={true}
                text={"Para emitir o guia de aplicação encontre a receita com o produto desejado na tela de histórico, clique no ícone e preencha os dados da guia. Depois disso, suas guias aparecerão nessa tela."}
                search={true}
                searchFunction={(text) => updateValues(text, '', 1)}
                placeholder="N.Receita, Produto, Praga, Cultura ou Aplicador"
                addButton={true}
                tooltip={"Emitir Guia"}
                linkSearch = {'/historico'}
            ></GenericUpper>
            <GuiasContainer>
                <Container style={size_screen <= 768 ? {paddingLeft: "0px", paddingRight: "0px", margin: 'auto'}:{}}>
                    {size_screen <= 768 ? (<>
                        <CardGeral
                            titulos={ ["GUIAS","N.RECEITA","PRODUTO (PRAGA)", "CULTURA", "APLICADOR","DATA DE APLICAÇÃO"]}
                            valores={guias}
                        />

                        <DotsLoader show={loadingSearch}></DotsLoader> 

                        <Paginator 
                            perPage={limit} 
                            total={totalGuias} 
                            onChange={(limit, offset) => updateLimitOffset(limit, offset)}
                            totalItens={guias.length}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                height: '200px',
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                            }}
                        >
                            <Button 
                                width='87%'
                                height='50px'
                                value='EMITIR GUIA'
                                outline={false}
                                disabled={false}
                                onClick={() => window.location = '/historico'}
                                loading={false}
                            />
                        </div>

                    </>):(<>
                        <Table>
                            <thead>
                                <tr>
                                    <th>N.Receita</th>
                                    <th>Produto (Praga)</th>
                                    <th>Cultura</th>
                                    <th>Aplicador</th>
                                    <th>Aplicação</th>
                                </tr>
                            </thead>
                            <TBody>
                                {guias.map(guia => (
                                    <>
                                    <tr
                                        onClick={() => {setModalApagarVerPdf(true) ; setIdGuia(guia.id) ; setUrlGuide (guia.pdf_guia)}}

                                        key={guia.id}
                                    >
                                        <td>{guia.numero_receita}</td>
                                        <td>
                                        {
                                            guia.receitas ? (
                                                guia.receitas.map(re => (
                                                        <ul>
                                                            <b>{re.nome_comercial}</b> ({re.praga_nome_comum})
                                                        </ul>
                                                ))
                                                ) : (
                                                    <ul>
                                                        <b>{guia.nome_comercial}</b> ({guia.praga})
                                                    </ul>
                                            )
                                        }
                                        </td>
                                        <td>{guia.cultura}</td>
                                        <td>{guia.aplicador}</td>
                                        <td>{formatDate(guia.data_aplicacao)}</td>
                                    </tr>
                                    </>
                                ))}
                            </TBody>
                        </Table>

                        <DotsLoader show={loadingSearch}></DotsLoader> 

                        <Paginator 
                            perPage={limit} 
                            total={totalGuias} 
                            onChange={(limit, offset) => updateLimitOffset(limit, offset)}
                            totalItens={guias.length}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                                marginTop: '40px',
                            }}
                        >
                        </div>
                    </>)}
                    <ModalTesteGratisAcabou
                        show={modalTesteGratisAcabou}
                        title="Essa é uma função exclusiva para assinantes..."
                        subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                        onHide={() => {
                            setModalTesteGratisAcabou(false);
                            window.location.href="/"
                        }}
                    ></ModalTesteGratisAcabou>
                    <ModalAlert
                        show={modalApagarVerPdf}
                        onHide={() => setModalApagarVerPdf(false)}
                    >
                        <h4 style={{
                            textAlign: 'center'
                        }}>O que deseja realizar?</h4>
                        <div style={{
                            display:"flex",
                            justifyContent: "space-evenly",
                            alignItems: "center",
                            marginTop:"30px",
                        }}>
                            <Button 
                                value='Apagar Guia'
                                width="200px"
                                height="35px"
                                outline={true}
                                color="#FF5277"
                                onClick={() => setShowModalGuides(true)}
                            />
                            <Button 
                                value='Visualizar Guia'
                                width="200px"
                                height="35px"
                                onClick={() => {window.open(urlGuide, '_blank') ; setModalApagarVerPdf(false)}}
                            />
                        </div>
                    </ModalAlert>
                    <ModalConfirmar
                        show={showModalGuides}
                        text="Digite 'CANCELAR' para continuar o cancelamento do guia" 
                        title="Confirmar Cancelamento do Guia de Aplicação"
                        id={idGuia}
                        update={resposta}
                        onHide={() => setShowModalGuides(false)}
                    />
                </Container>
            </GuiasContainer>
        </>
    );
}
export default GuiasAplicacao;
