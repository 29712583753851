import styled from "styled-components";

export const ContainerInterruptor = styled.div`
    width: 48px;
    height: 24px;
    cursor: pointer;
`;

export const InterruptorArea = styled.div`
    width: 48px;
    height: 100%;
    border-radius: 15px;
    background-color: ${props => (props.isOn ? '#2fbb69' : '#ced2dc')};
    cursor: pointer;
`;

export const InterruptorMarker = styled.div`
    width: 24px;
    height: 24px;
    border-radius: 15px;
    background-color: #FFF;
    border:  ${props => (props.isOn ? '2px solid #2fbb69' : '2px solid #ced2dc')};
    margin-left:  ${props => (props.isOn ? '24px' : '0px')};
    transition: all 0.2s;
    cursor: pointer;
`;
