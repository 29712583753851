import React from 'react';
import api from "../../services/api";
// import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Select from "../../components/select";
import { store } from "react-notifications-component";
import { ModalBody, ModalFooter, ModalTitle, StyleModal } from "./styles";
import Modal from '.';

// import ModalDocuments from '../../pages/documentosSeg/styles';

class Importacao extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            options: [],
            optionSelected: [],
            title: "",
            documentos: [],
            textfile: "",
            file:[],
            namesfiles:[],
            hideButton: false,
            run: false
        }
    }

    componentDidMount(){
        const a = [];
        const a2 = a.slice(0,26)

        this.setState({
            title: this.props.title
        })       
    }


    updateResponseYes = () => {
        let resposta = 1;
        this.props.update(resposta);
    }

    updateResponseNo = () => {
        let resposta = 0;
        this.props.update(resposta);
        this.setState({passo: 1, file:[],  namesfiles:[], documentos: [], textfile: ""})
    }

    load = async () =>{
        const { file } = this.state;
        const payload = {
            file: file[0]
        };

        try{
            if (this.props.tipo == 1) {
                const response = await api.post('/importar/cadastro/produtores', payload);
                this.setState({file: []});
                console.log(response);
                store.addNotification({
                    title: "Sucesso!",
                    message: `${response.data.data} ${response.data.message}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                this.updateResponseNo();
            } else if (this.props.tipo == 2) {
                const response = await api.post('/importar/cadastro/fazendas', payload);
                this.setState({file: []});
                console.log(response);
                store.addNotification({
                    title: "Sucesso!",
                    message: `${response.data.data} ${response.data.message}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                this.updateResponseNo();
            }
        }catch(e){
            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });

            this.setState({file: [], namesfiles: [], textfile: ""});

        }
    }

    onChange = async (e) =>{
        if (this.state.file.length == 0) {
            if(e.target.files){
                if(e.target.files[0].size < 3098486){
                    console.log(e.target.files[0].type);
                    if(
                        e.target.files[0].type == "application/vnd.ms-excel" || 
                        e.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ){                    
                        const {file, namesfiles} = this.state;
                        let fil = e.target.files;
                        let reader = new FileReader();
                        reader.readAsDataURL(fil[0]);
    
                        reader.onload=(e)=>{
                            var another = file.push(e.target.result);
                        }
                        
                        let archives = (file.length) + 1;
                        namesfiles.push(e.target.files[0].name);
                        console.log(file);
                        this.setState({textfile: (
                            <>
                                {this.state.namesfiles.map((files)=>{
                                    return (
                                        <>
                                            {files}
                                        </>
                                    )
                                })}
                            </>
                        )});
                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: `O arquivo selecionado não possui a extensão correta`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                } else {
                    store.addNotification({
                        title: "Erro!",
                        message: `Arquivo maior que o permitido`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        } else {
            store.addNotification({
                title: "Erro!",
                message: `Não é possível enviar mais que um arquivo para importação`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    formatpayload = (option, camp) => {
        const fieldSelected = `${camp} / ${option.value}`
        console.log(fieldSelected);
    }


    render() {
        const {file, title} = this.state;
        return (
            // <Modal
            //     {...this.props}
            //     size="lg"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8"}}>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //             {title}
            //         </Modal.Title>
            //     </Modal.Header>

            //     <Modal.Body style={{backgroundColor: " #f8f8f8"}}>
            //         <StyleModal>
            //             <p>{this.props.text}</p>
            //             <p>Dica: Está em dúvida de como deve ser seu arquivo? <a href={this.props.link}> <b>Clique aqui</b> </a> e veja uma planilha de exemplo.</p>
                        
            //             {this.props.tipo === 2 && (
            //                 <>
            //                     <label></label>
            //                     <p><b>Atenção:</b> Certifique-se de ter importado/cadastrado os produtores antes de importar os locais de aplicação!</p>
            //                 </>
            //             )}
                        
            //             <div>
            //                 <div class='input-wrapper'>
            //                     <label></label>

            //                     <div className="arquivos">

            //                         <label 
            //                             className='btn-update'
            //                             for='input-file' 
            //                             multiple
            //                         >
            //                             Selecionar arquivo
            //                         </label>

            //                         <label className="arq-name">{this.state.textfile ? this.state.textfile : "Nenhum arquivo selecionado" }</label>
            //                     </div>

            //                     <label className="legenda" >Selecione o arquivo XLS, XLSX ou CSV em seu computador (tamanho máximo de 3MB)</label>
            //                     <input 
            //                         style={{display: "none"}}
            //                         type="file"
            //                         id="input-file"
            //                         name="file"
            //                         multiple
            //                         onChange={(e)=>this.onChange(e)}
            //                     />

            //                     <span id='file-name'></span>
            //                 </div>
            //             </div>      
            //         </StyleModal>
            //     </Modal.Body>

            //     <Modal.Footer 
            //         style={{
            //             backgroundColor: " #f8f8f8", 
            //             display: "flex", 
            //             alignItems: "center",
            //             justifyContent: this.props.tipo === 1 ? "space-between" : "flex-end"
            //         }}
            //     >
            //         {this.props.tipo === 1 && (
            //             <Button 
            //                 color="#2fbb69" 
            //                 outline={true}
            //                 style={{ 
            //                     outline:1,
            //                     padding: "10px"
            //                 }} 
            //                 id="botaoformdocs" 
            //                 disabled={false}
            //                 onClick={() => this.props.goToFarm()}
            //             >
            //                 Importar locais de aplicação
            //             </Button>
            //         )}
                    
            //         <Button 
            //             color="#2fbb69" 
            //             style={{ outline:0, marginLeft: "-10px",padding: "10px"}} 
            //             id="botaoformdocs" 
            //             disabled={file.length != 0 ? false : true }
            //             onClick={this.load}
            //         >
            //             Importar 
            //         </Button>
            //     </Modal.Footer>
            // </Modal>





            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='60%' 
            >
                <ModalTitle> {title} </ModalTitle>

                <ModalBody textAlign='start'>
                    <StyleModal>
                        <p>{this.props.text}</p>
                        <p>Dica: Está em dúvida de como deve ser seu arquivo? <a href={this.props.link}> <b>Clique aqui</b> </a> e veja uma planilha de exemplo.</p>
                        
                        {this.props.tipo === 2 && (
                            <>
                                <label></label>
                                <p><b>Atenção:</b> Certifique-se de ter importado/cadastrado os produtores antes de importar os locais de aplicação!</p>
                            </>
                        )}
                        
                        <div>
                            <div class='input-wrapper'>
                                <label></label>

                                <div className="arquivos">

                                    <label 
                                        className='btn-update'
                                        for='input-file' 
                                        multiple
                                    >
                                        Selecionar arquivo
                                    </label>

                                    <label className="arq-name">{this.state.textfile ? this.state.textfile : "Nenhum arquivo selecionado" }</label>
                                </div>

                                <label className="legenda" >Selecione o arquivo XLS, XLSX ou CSV em seu computador (tamanho máximo de 3MB)</label>
                                <input 
                                    style={{display: "none"}}
                                    type="file"
                                    id="input-file"
                                    name="file"
                                    multiple
                                    onChange={(e)=>this.onChange(e)}
                                />

                                <span id='file-name'></span>
                            </div>
                        </div>      
                    </StyleModal>
                </ModalBody>

                <ModalFooter justifyContent='space-evenly'>
                    {this.props.tipo === 1 && (
                        <Button 
                            color="#2fbb69" 
                            outline={true}
                            style={{ 
                                outline:1,
                                padding: "10px"
                            }} 
                            id="botaoformdocs" 
                            disabled={false}
                            onClick={() => this.props.goToFarm()}
                        >
                            Importar locais de aplicação
                        </Button>
                    )}
                    
                    <Button 
                        color="#2fbb69" 
                        style={{ outline:0, marginLeft: "-10px",padding: "10px"}} 
                        id="botaoformdocs" 
                        disabled={file.length != 0 ? false : true }
                        onClick={this.load}
                    >
                        Importar 
                    </Button>
                </ModalFooter>

            </Modal>
        );
    }
}
export default Importacao;