import React from "react";

import { LdsDots } from "./styles";

const DotsLoader = ({ ...props }) => {
  return (
    <LdsDots {...props}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </LdsDots>
  );
};

export default DotsLoader;
