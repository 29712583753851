import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import GenericUpper from '../../components/generic-upper';
import api from '../../services/api';

import Button from '../../components/button/button_v2';

import Integration from '../../../src/assets/integration.svg';

import { store } from "react-notifications-component";

import {
    IntegracaoContainer, Text, Title
} from './styles';
import CardIntegration from '../../components/card-integracao';
import AddIntegracao from './add';
import Manual from './manual';


const Integracao = ({ ...props }) => {

    const [load, setLoad] = useState(false);
    const [integrations, setIntegrations] = useState([]);
    const [criar, setCriar] = useState(false);
    const [manual, setManual] = useState(false)
    const [functions, setFunctions] = useState([]);


    const getIntegrations = async () => {
        try {
            const response = await api.get('/integracao');

            if (response) {
                setIntegrations(response.data.data);
                setLoad(true);
            }
        } catch (error) {
            setLoad(true);
        }
    }

    const getIntegrationFunctions = async () => {
        try {
            const response = await api.get('/integracao/funcoes');

            if (response) {
                setFunctions(response.data.data);
            }
        } catch (error) {

        }
    }

    const criarIntegracao = () => {
        setCriar(true);
    }

    const verManual = () => {
        setManual(true);
    }

    const postIntegration = async (e) => {
        try {
            try {

                const response = await api.post(`/integracao`, e);

                if (response) {
                    store.addNotification({
                        title: "Sucesso!",
                        message: `Integração configurada com sucesso!`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });

                    // setIntegrations([]);

                    setTimeout(() => {
                        getIntegrations();
                        setCriar(false);
                    }, 500);
                }
            } catch (error) {
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.message}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {

        }
    }

    const editIntegration = async (e) => {
        try {
            // setCriar(true);

            const response = await api.put(`/integracao/${e.id}`, e);

            if (response) {
                store.addNotification({
                    title: "Sucesso!",
                    message: `Editado com sucesso!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });

                // setIntegrations([]);

                setTimeout(() => {
                    loadIntegrations();
                    // getIntegrations();
                    // setCriar(false);
                }, 100);
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.message}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const loadIntegrations = () => {
        getIntegrations();
        getIntegrationFunctions();
    }

    useEffect(() => {
        loadIntegrations();
    }, []);

    return (
        <>
            <GenericUpper
                title="Integração"
                subtitle="Integre o AgroReceita com o seu sistema."
            />

            <IntegracaoContainer>
                {manual ? (
                    <Manual fechar={()=> setManual(false)}/>
                ) : (
                    <>
                        {load === true && criar === false && integrations.length === 0 ? (
                            <>
                                {/* Usuário não tem nenhuma integração cadastrada */}

                                <img src={Integration} width="300" />
                                <Title>Integrar o AgroReceita</Title>

                                <Text>Integre o AgroReceita ao seu sistema web de ERP</Text>

                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                                    <Button
                                        width='296px'
                                        height='50px'
                                        value='CRIAR INTEGRAÇÃO'
                                        outline={false}
                                        disabled={false}
                                        onClick={() => criarIntegracao()}
                                    />
                                </div>

                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                    <Button
                                        width='296px'
                                        height='50px'
                                        value='VER MANUAL'
                                        outline={true}
                                        disabled={false}
                                        onClick={() => verManual()}
                                    />
                                </div>
                            </>
                        ) : (
                            <>
                                {criar === true ? (
                                    // Usuário está criando uma nova integração
                                    <AddIntegracao save={(e) => postIntegration(e)} />
                                ) : (
                                    <>
                                        {integrations.length > 0 && (
                                            <>
                                                {/* Usuário já possui integrações cadastradas */}

                                                {integrations.map((i) => (
                                                    <CardIntegration
                                                        id={i.id}
                                                        title={i.function}
                                                        url={i.url}
                                                        functionId={i.agro_integration_function_id}
                                                        framework={i.framework}
                                                        headers={i.headers}
                                                        status={i.status}
                                                        save={(e) => editIntegration(e)}
                                                        reload={() => loadIntegrations()}
                                                    />
                                                ))}

                                                {integrations.length < functions.length && (
                                                    <div className="button" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                                        <Button
                                                            width='296px'
                                                            height='50px'
                                                            value='CRIAR NOVA INTEGRAÇÂO'
                                                            outline={false}
                                                            disabled={false}
                                                            onClick={() => setCriar(true)}
                                                        />
                                                    </div>

                                                )}
                                            </>
                                        )}
                                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px', marginBottom: '10px' }}>
                                            <Button
                                                width='296px'
                                                height='50px'
                                                value='VER MANUAL'
                                                outline={true}
                                                disabled={false}
                                                onClick={() => verManual()}
                                            />
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}



            </IntegracaoContainer>
        </>
    )
}

export default withRouter(Integracao);
