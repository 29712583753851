import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import CloseIcon from "../../assets/icons/icon-delete.svg";
import Modal from '.';
import { ModalBody } from './styles';

class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };



    render(){
        return(   
        //     <Modal
        //         size="lg"
        //         {...this.props}
        //         aria-labelledby = "contained-modal-title-vcenter"
        //         centered
        //     >
        //         <Modal.Body
        //         style={{backgroundColor: " #f8f8f8"}}
        //         >

        //         {this.props.onHide === false ? (null) : (
        //             <div className="close" style={{ display: 'flex', cursor: 'pointer' }}>
        //                 <img 
        //                     className="delete-select" 
        //                     src={CloseIcon} 
        //                     alt='delete'
        //                     onClick={() => this.props.onHide()}
        //                     width='20px'
        //                 />
        //             </div>
        //         )}

        //         {this.props.children}
        //         </Modal.Body>
        //   </Modal>
          
            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
            >
                <ModalBody marginBottom={this.props.marginBottom}>
                    {this.props.children}
                </ModalBody>
            </Modal>

        );
    }
}
export default ModalNew;