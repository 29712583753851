import styled from "styled-components";

export const FormContainer = styled.div`
background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
display: flex;
form{
  margin-top: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
}

i{
   margin-left: 15px;
 }

@media only screen and (max-width: 480px){
  form{
    width: 100%;
  }
}


`;

export const FormContainerEdit = styled.div`
margin-top: 20px;
/* background: #f8f8f9; */
display: flex;
width: 100%;
height: auto;
max-width: 400px;
margin: auto;
form{
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
}

i{
   margin-left: 15px;
 }
 @media only screen and (max-width: 991px){
    padding-left: 15px;
    padding-right: 15px;
   form{
     width: 100%;
   }
 }
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: 10px;
  margin-left: 15px;
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a92a2;
  display: inline-table;
  text-align: start;
`;