import styled from "styled-components";

export const FiliaisContainer = styled.section`
  padding: 50px 0;
  height: 100%;

  .search{
    display: flex;
    flex-direction: row;
    margin-left: 190px;
    margin-top: -80px;
    
    a{
      text-decoration: none;
    }
    
    .add{
      margin-left: 90px;
      margin-top: -12px;
      button{
        border-radius: 30px;
        border: solid 3px #FFF;
      }     

    }
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loadmore {
      margin: 30px 0;
      padding: 15px 20px;
    }
    .filterbuttons {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-around;
      .pesquisar {
        margin: 30px 0;
        padding: 15px 20px;
      }
    }
    .salvar {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-around;
      .salvado {
        margin: 30px 0;
        padding: 15px 20px;
      }
    }

    .botoesfinais{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 50%;
      margin-top: 20px;
      .loadmore {
        margin: 30px 0;
        padding: 15px 20px;
      }
      .relatorio {
        display: flex;
        margin: 30px 0;
        padding: 15px 20px;
      }
    }

    .add {
      align-self: flex-end;
      button {
        margin: 15px 0 30px;
        padding: 15px 30px;
      }
    }
  }

  @media only screen and (max-width: 768px){
    margin-top: 106px;
  }

`;

export const FiliaisAddContainer = styled.div`
  padding: 50px 0;
  background: #f8f8f9;
  div {
    form {
      margin: auto;
      min-width: 250px;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      input[type='file'] {
      }

      .tel{
        width: 100%;
        max-width: 400px;
        margin-top: 0px;
      }

      .input-wrapper label {
      background-color: #fff;
      border-radius: 1px;
      border: dashed 1px #8a92a2;
      color: #8a92a2;
      margin: 10px;
      padding: 12px 20px
      }
      
      .input-wrapper label:hover {
      background-color: #DCDCDC
      }

      input[type='file'] {
        display: none
      }
      .input-wrapper label {
        background-color: #fff;
        border-radius: 1px;
        border: 1px dashed #8a92a2;
        color: #8a92a2;
        margin: 10px;
        padding: 12px 20px
      }

    }
  }
  .salvar {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    
    a {
      width: 35%;
    }

    .salvado {
      width: 60%;
      margin: 30px 0;
      padding: 15px 20px;
    }
  }
`;
