import React, { useState, useEffect, useRef } from 'react';
import { InputContainerV2, Label, Unidade, Legenda, Loading, YesNoContainer, YesNoContainerMobile, Plus } from './styles';
import check from '../../assets/icons/icons8-check-mark-96.png'
import Interruptor from '../../components/interruptor';
import DeleteIcon from "../../assets/icons/icon-delete.svg";
import IconHelp from '../../assets/icons/help';
import IconWhatsapp from '../../assets/icons/whatsapp';

const InputV2 = ({ ...props }) => {

    const [ref] = useState(useRef());
    const [typePassword, setTypePassword] = useState('password');
    const [isFocus, setIsFocus] = useState(false);
    const [value, setValue] = useState('');
    const [yesNoValue, setYesNoValue] = useState(false);

    const validaInitialValue = (min, max) => {
        if (min === max) {
            props.updateValue(min);
        }
    }

    const changeInput = (text) => {
        if (text || text === '0' || text === 0) {

            if (props.name === 'cpf') {
                let value = text.replace(/\D/g, '');

                if (value.length <= 4) {
                    value = value.replace(/^(\d{3})(\d)/, '$1.$2');
                } else if (value.length <= 6) {
                    value = value.replace(/^(\d{3})(\d)/, '$1.$2');
                    value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                } else if (value.length <= 9) {
                    value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                } else if (value.length <= 14) {
                    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4');
                }
                if (value.length <= 14) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'cnpj') {
                let value = text.replace(/\D/g, '');

                if (value.length <= 4) {
                    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
                } else if (value.length <= 6) {
                    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
                    value = value.replace(/^(\d{2})(\d{3})(\d)/, '$1.$2.$3');
                } else if (value.length <= 9) {
                    value = value.replace(/^(\d{2})(\d{3})(\d)/, '$1.$2.$3');
                    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                } else if (value.length <= 11) {
                    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4');
                } else if (value.length === 12) {
                    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4');
                } else {
                    if (value.length <= 18) {
                        value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5');
                    }
                }
                if (value.length <= 18) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'cpfcnpj' || props.name === 'cpfCnpj' || props.name === 'cpf_cnpj') {
                let value = text.replace(/\D/g, '');

                if (value.length <= 4) {
                    value = value.replace(/^(\d{3})(\d)/, '$1.$2');
                } else if (value.length <= 6) {
                    value = value.replace(/^(\d{3})(\d)/, '$1.$2');
                    value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                } else if (value.length <= 9) {
                    value = value.replace(/^(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3');
                } else if (value.length <= 11) {
                    value = value.replace(/^(\d{3})(\d{3})(\d{3})(\d)/, '$1.$2.$3-$4');
                } else if (value.length === 12) {
                    value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d)/, '$1.$2.$3/$4');
                } else {
                    if (value.length <= 18) {
                        value = value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d)/, '$1.$2.$3/$4-$5');
                    }
                }
                if (value.length <= 18) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'cep') {
                let value = text.replace(/\D/g, '');

                if (value.length <= 5) {
                    value = value.replace(/^(\d{2})(\d)/, '$1.$2');
                } else {
                    if (value.length <= 10) {
                        value = value.replace(/^(\d{2})(\d{3})(\d)/, '$1.$2-$3');
                    }
                }

                if (value.length <= 10) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'telefone') {
                let value = text.replace(/\D/g, '');

                if (value.length === 1) {
                    value = value.replace(/^(\d{1})/, '($1');
                } else if (value.length === 2) {
                    value = value.replace(/^(\d{2})/, '($1)');
                } else if (value.length === 3) {
                    value = value.replace(/^(\d{2})(\d{1})/, '($1)$2');
                } else if (value.length === 4) {
                    value = value.replace(/^(\d{2})(\d{2})/, '($1)$2');
                } else if (value.length === 5) {
                    value = value.replace(/^(\d{2})(\d{3})/, '($1)$2');
                } else if (value.length === 6) {
                    value = value.replace(/^(\d{2})(\d{4})/, '($1)$2');
                } else if (value.length === 7) {
                    value = value.replace(/^(\d{2})(\d{4})(\d)/, '($1)$2-$3');
                } else if (value.length < 11) {
                    value = value.replace(/^(\d{2})(\d{4})(\d{1})/, '($1)$2-$3');
                } else {
                    value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1)$2-$3');
                }

                if (value.length <= 14) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'celular') {
                let value = text.replace(/\D/g, '');

                if (value.length === 1) {
                    value = value.replace(/^(\d{1})/, '($1');
                } else if (value.length === 2) {
                    value = value.replace(/^(\d{2})/, '($1)');
                } else if (value.length === 3) {
                    value = value.replace(/^(\d{2})(\d{1})/, '($1)$2');
                } else if (value.length === 4) {
                    value = value.replace(/^(\d{2})(\d{2})/, '($1)$2');
                } else if (value.length === 5) {
                    value = value.replace(/^(\d{2})(\d{3})/, '($1)$2');
                } else if (value.length === 6) {
                    value = value.replace(/^(\d{2})(\d{4})/, '($1)$2');
                } else if (value.length === 7) {
                    value = value.replace(/^(\d{2})(\d{5})/, '($1)$2');
                } else {
                    if (value.length <= 14) {
                        value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1)$2-$3');
                    }
                }
                if (value.length <= 14) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'whatsapp') {
                let value = text.replace(/\D/g, '');

                if (value.length === 1) {
                    value = value.replace(/^(\d{1})/, '($1');
                } else if (value.length === 2) {
                    value = value.replace(/^(\d{2})/, '($1)');
                } else if (value.length === 3) {
                    value = value.replace(/^(\d{2})(\d{1})/, '($1)$2');
                } else if (value.length === 4) {
                    value = value.replace(/^(\d{2})(\d{2})/, '($1)$2');
                } else if (value.length === 5) {
                    value = value.replace(/^(\d{2})(\d{3})/, '($1)$2');
                } else if (value.length === 6) {
                    value = value.replace(/^(\d{2})(\d{4})/, '($1)$2');
                } else if (value.length === 7) {
                    value = value.replace(/^(\d{2})(\d{5})/, '($1)$2');
                } else {
                    if (value.length <= 14) {
                        value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1)$2-$3');
                    }
                }
                if (value.length <= 14) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'telefonecelular') {
                let value = text.replace(/\D/g, '');

                if (value.length === 1) {
                    value = value.replace(/^(\d{1})/, '($1');
                } else if (value.length === 2) {
                    value = value.replace(/^(\d{2})/, '($1)');
                } else if (value.length === 3) {
                    value = value.replace(/^(\d{2})(\d{1})/, '($1)$2');
                } else if (value.length === 4) {
                    value = value.replace(/^(\d{2})(\d{2})/, '($1)$2');
                } else if (value.length === 5) {
                    value = value.replace(/^(\d{2})(\d{3})/, '($1)$2');
                } else if (value.length === 6) {
                    value = value.replace(/^(\d{2})(\d{4})/, '($1)$2');
                } else if (value.length === 7) {
                    value = value.replace(/^(\d{2})(\d{4})(\d)/, '($1)$2-$3');
                } else if (value.length < 11) {
                    value = value.replace(/^(\d{2})(\d{4})(\d{1})/, '($1)$2-$3');
                } else {
                    value = value.replace(/^(\d{2})(\d{5})(\d)/, '($1)$2-$3');
                }

                if (value.length <= 14) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'codigo-validacao') {
                let value = text.replace(/\D/g, '');

                if (value.length <= 4) {
                    value = value.replace(/^(\d{4})/, '$1');
                } else if (value.length == 5) {
                    value = value.replace(/^(\d{4})(\d{1})/, '$1-$2');
                } else if (value.length == 6) {
                    value = value.replace(/^(\d{4})(\d{2})/, '$1-$2');
                } else if (value.length == 7) {
                    value = value.replace(/^(\d{4})(\d{3})/, '$1-$2');
                } else if (value.length == 8) {
                    value = value.replace(/^(\d{4})(\d{4})/, '$1-$2-A');
                } else if (value.length == 9) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{1})/, '$1-$2-A$3');
                } else if (value.length == 10) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{2})/, '$1-$2-A$3');
                } else if (value.length == 11) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{3})/, '$1-$2-A$3');
                } else if (value.length == 12) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})/, '$1-$2-A$3-R');
                } else if (value.length == 13) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d{1})/, '$1-$2-A$3-R$4');
                } else if (value.length == 14) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d{2})/, '$1-$2-A$3-R$4');
                } else if (value.length == 15) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d{3})/, '$1-$2-A$3-R$4');
                } else if (value.length == 16) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d{4})/, '$1-$2-A$3-R$4');
                }

                setValue(value);
                props.onChange(value);

            } else if (props.name === 'nfkey') {
                let value = text.replace(/\D/g, ''); // Remove tudo que não for dígito

                let formattedValue = '';
                for (let i = 0; i < value.length; i++) {
                    if (i > 0 && i % 4 === 0) {
                        formattedValue += ' ';
                    }
                    formattedValue += value[i];
                }

                if (value.length <= 44) {
                    setValue(formattedValue);
                    props.onChange(formattedValue);
                } else {
                    // Se ultrapassar 44 dígitos, ignore a entrada extra
                    let truncatedValue = value.slice(0, 44);
                    let truncatedFormattedValue = '';
                    for (let i = 0; i < truncatedValue.length; i++) {
                        if (i > 0 && i % 4 === 0) {
                            truncatedFormattedValue += ' ';
                        }
                        truncatedFormattedValue += truncatedValue[i];
                    }
                    setValue(truncatedFormattedValue);
                    props.onChange(truncatedFormattedValue);
                }
            } else if (props.name === 'qtd_produto') {
                let valor = text;
                var v = valor.replace(/\D/g, '');
                v = (v / 100).toFixed(2) + '';
                v = v.replace(".", ",");
                v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1');
                valor = v;

                if (valor.length <= 20) {

                    setValue(valor);
                    props.onChange(valor);

                }



                // if (value.length === 1){
                //     value = value.replace(/^(\d{1})(\d)/, '$1,00');
                // }else if (value.length === 2){
                //     value = value.replace(/^(\d{2})(\d)/, '$1,00');
                // }else if (value.length === 3){
                //     value = value.replace(/^(\d{3})(\d)/, '$1,00');
                // }else if (value.length === 4){
                //     value = value.replace(/^(\d{4})(\d)/, '$1,00');
                // }



            } else if (props.name === 'credit-card') {
                let value = text.replace(/\D/g, '');
                value = value.replace(' ', '');

                if (value.length <= 4) {
                    value = value.replace(/^(\d{4})(\d)/, '$1 $2');
                } else if (value.length <= 8) {
                    value = value.replace(/^(\d{4})(\d)/, '$1 $2');
                    value = value.replace(/^(\d{4})(\d{4})(\d)/, '$1 $2 $3');
                } else if (value.length <= 12) {
                    value = value.replace(/^(\d{4})(\d{4})(\d)/, '$1 $2 $3');
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d)/, '$1 $2 $3');
                } else if (value.length <= 16) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d)/, '$1 $2 $3 $4');
                } else if (value.length <= 19) {
                    value = value.replace(/^(\d{4})(\d{4})(\d{4})(\d)/, '$1 $2 $3 $4');
                }

                if (value.length <= 19) {
                    setValue(value);
                    props.onChange(value);
                }
            } else if (props.name === 'cvv') {
                let value = text.replace(/\D/g, '');
                value = value.replace(' ', '');

                if (value.length <= 3) {
                    value = value.replace(/^(\d{3})/, '$1');
                }

                if (value.length <= 3) {
                    setValue(value);
                    props.onChange(value);
                }

            } else {
                setValue(text);
                if (props.onChange) {
                    props.onChange(text);
                }
            }
        } else {
            setValue('');
            if (props.onChange) {
                props.onChange('');
            }
        }

    }

    const close = (e) => {

        if (isFocus === true) {
            if (props.labelUp == true) {
                setIsFocus(true);
            } else {
                setIsFocus(false);
            }
        }
        if (props.onBlur) {
            props.onBlur(e);
        }
    }


    const alterTypePassword = async () => {
        if (typePassword === "password") {
            setTypePassword('text');
        } else if (typePassword === "text") {
            setTypePassword('password');
        }
    }

    const keyDown = (e) => {
        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    }

    const onFocusInput = () => {
        if (props.disabled === false || !props.disabled) {
            if (!isFocus) {
                document.getElementById(props.id).select();
            }
            setIsFocus(true);
        }
    }

    useEffect(() => {
        if (props.min && props.max) {
            validaInitialValue(props.min, props.max);
        }
    }, [props.min, props.max]);


    useEffect(() => {
        if (props.value != value || props.value === 0 || props.value === '0') {
            setValue(props.value);
            changeInput(props.value);
        }
    }, [props.value])

    useEffect(() => {
        if (props.onChangeYesNo) {
            props.onChangeYesNo(yesNoValue);
        }
    }, [yesNoValue]);


    useEffect(() => {
        if (props.onFocus) {
            props.onFocus(isFocus);
        }
    }, [isFocus])

    useEffect(() => {
        if (props.initialYesNoValue) {
            setYesNoValue(props.initialYesNoValue);
        }
    }, [])

    useEffect(() => {
        if (props.labelUp === true) {
            setIsFocus(true)
        }
    }, [])

    return (
        <InputContainerV2 yesNo={props.yesNo} erro={props.erro} disabled={props.disabled} marginBottom={props.marginBottom} type={props.type} width={props.width}>
            {props.label === ' ' ? (<>
                {props.id === 'latitude' || props.id === 'longitude' ? (
                    <Label
                        erro={props.erro}
                        isFocus={isFocus}
                        existValue={value === '' && value !== '0' && value !== 0 ? false : true}
                        onClick={() => onFocusInput()}
                        isDisabled={props.disabled}
                    >{props.label}</Label>
                ) : (null)}
            </>) : (
                <>
                    <Label
                        yesNo={props.yesNo}
                        erro={props.erro}
                        isFocus={isFocus}
                        existValue={value === '' && value !== '0' && value !== 0 ? false : true}
                        onClick={() => onFocusInput()}
                        isDisabled={props.disabled}
                        isDate={props.type === 'date' ? true : false}
                    >{props.label || props.placeholder}</Label>
                </>
            )}


            <input
                ref={ref}
                {...props}
                type={props.type === 'password' ? typePassword : props.type}
                onChange={(e) => changeInput(e.target.value)}
                onKeyDown={(e) => keyDown(e.key)}
                onBlur={(e) => close(e)}
                onFocus={() => onFocusInput()}
                style={{
                    width: '100%',
                    height: '50px',
                    padding: '13px 15px 12px 25px',
                    borderRadius: '25px',
                    border: props.erro ? 'solid 1px #ff5252' : props.disabled ? 'solid 1px #cfd5df' : 'solid 1px #bac1cf',
                    backgroundColor: '#FFF',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#303848',
                }}
            >
            </input>

            {props.yesNo === true ? (
                <YesNoContainer
                    disabled={props.disabled}
                    loading={props.loading}
                    yesNoStepSix={props.yesNoStepSix} 
                >
                    <span
                        style={{
                            width: props.yesNoSpanWidth ? props.yesNoSpanWidth : 'auto',
                            marginRight: '5px'
                        }}
                    >
                        {props.messageYesNo}
                    </span>
                    <Interruptor value={yesNoValue} onChange={(e) => setYesNoValue(e)} />
                </YesNoContainer>
            ) : (null)}

            {props.yesNo === true ? (
                <YesNoContainerMobile
                    disabled={props.disabled}
                    loading={props.loading}
                >
                    <span>{props.messageYesNo}</span>
                    <Interruptor value={yesNoValue} onChange={(e) => setYesNoValue(e)} />
                </YesNoContainerMobile>
            ) : (null)}

            {props.type === 'password' ? (
                <>
                    {typePassword === 'password' ? (
                        <i class="fa fa-eye" onClick={() => alterTypePassword()}></i>
                    ) : (
                        <i class="fa fa-eye-slash" onClick={() => alterTypePassword()}></i>
                    )}
                </>
            ) : (null)}

            {props.loading === true ? (
                <>
                    <Loading />
                </>
            ) : (
                <>
                    {props.isValid ? (
                        <img src={check} width='15px' alt='valido' style={{
                            position: 'absolute',
                            top: '18px',
                            right: '30px'
                        }} />
                    ) : (
                        <>
                            {props.unidade ? (
                                <Unidade erro={props.erro} disabled={props.disabled}>{props.unidade}</Unidade>
                            ) : (props.addNew ? (
                                <Plus>
                                    <p id="msg">Adquirir mais licenças</p>
                                    <img
                                        className="add-select"
                                        src={DeleteIcon}
                                        alt='add'
                                        onClick={() => props.onClickAddNew()}
                                        width='10px'
                                    />
                                </Plus>
                            ) : (null))}
                        </>
                    )}
                </>
            )}

            {props.name === 'whatsapp' && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'end',
                    marginTop: '-38px',
                    marginRight: '14px'
                }}>
                    <IconWhatsapp
                        width='24px'
                        height='24px'
                    />
                </div>
            )}

            {props.erro && props.messageErro ? (
                <Legenda isPassword={props.type === 'password' ? true : false} unidade={props.unidade ? true : false}>{props.messageErro}</Legenda>
            ) : (<>
                {props.legenda && (
                    <Legenda isPassword={props.type === 'password' ? true : false} unidade={props.unidade ? true : false}>{props.legenda}</Legenda>
                )}
            </>)}


            {props.help === true ? (
                <div className="help-buttom" onClick={() => props.helpClick()}>
                    <IconHelp
                        fill='#5DA8FD'
                        cursor='pointer'
                    />
                </div>
            ) : (null)}

        </InputContainerV2>
    )
}

export default InputV2;
