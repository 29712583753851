import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import api from "../../services/api";
import GenericUpper from "../../components/generic-upper";
import {ContainerPlanos} from "./styles";
import TablePlans from '../../components/tablePlans';
export const USER = "@userinfo";

const Planos = ({...props}) => {

    const [planos, setPlanos] = useState([]);
    const [userPlanId, setUserPLanId] = useState('');
    const [userPlanLevel, setUserPLanLevel] = useState('');


    const getPlanos = async () => {
        try {
            const response =  await api.get(`/planos/funcoes`);
            
            let planos = response.data.data;
            setPlanos(planos);

        } catch (error) {
        }
    }

    const getPlanUser = () => {
        const user = JSON.parse(localStorage.getItem(USER));
        const plan = user.contrato.agro_plan_config.agro_plan_id;
        const level = user.contrato.agro_plan_config.nivel;

        setUserPLanId(plan);
        setUserPLanLevel(level);
    }

    useEffect(() => {
        getPlanos();
        getPlanUser();
    },[])

    return(
       <>
            <GenericUpper
                title="Planos"
                subtitle="Selecione o plano e veja suas vantagens!"
            />

            {planos ? (
                <ContainerPlanos>
                    {planos.planos ? (
                        <TablePlans
                            planos={planos}
                            history={props.history}
                            allProps={props}
                            userPlanId={userPlanId}
                            userPlanLevel={userPlanLevel}
                        />
                    ) : (null)}
                </ContainerPlanos>
            ) : (null)}
       </>
    )
}

export default withRouter(Planos);
