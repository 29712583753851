import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import {
    Container,
    SessionRow,
    Card,
    FormContainer,
    Line,
    ContainerLogo
} from './style';
import Input from '../../components/input/input_v2';
import Button from '../../components/button/button_v2';
import logoBranco from '../../assets/simbolo-branco-1.png';
import Logo from "../../assets/logo_hd.svg";
import CardIcon from "../../assets/icons/payment-card-icon.svg";

import ModalTermos from '../../components/modal/modalTermos';
import ModalPoliticas from '../../components/modal/modalPoliticas';

import { store } from "react-notifications-component";

export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';


const CadastroStep1 = ({ ...props }) => {


    const [nome, setNome] = useState('');
    const [erroNome, setErroNome] = useState(false);
    const [erroNomeMessage] = useState('');

    const [email, setEmail] = useState('');
    const [erroEmail, setErroEmail] = useState(false);
    const [erroEmailMessage, setErroEmailMessage] = useState('');

    const [showPolitica, setShowPolitica] = useState(false);
    const [showTermos, setShowTermos] = useState(false);

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [emailGoogle, setEmailGoogle] = useState('');

    const [loadingEmail, setLoadingEmail] = useState(false);
    const [emailIsOk, setEmailIsOk] = useState(false);


    const changeNome = (value) => {
        setNome(value);
        setErroNome(false);
    }

    const changeEmail = (value) => {
        setEmail(value);
        setErroEmail(false);
    }


    const clickPolitica = () => {
        setShowPolitica(true);
    }

    const clickTermos = () => {
        setShowTermos(true);
    }

    const handlePost = async () => {
        setLoading(true);

        const payload = {
            nome,
            email,
        };


        props.updateData(payload);
        setLoading(false);
    }

    const handlePostSocial = async () => {
        setLoading(true);

        const a = localStorage.getItem('@agroreceita_google_user_profile');
        let userProfile = JSON.parse(a);

        const payload = {
            name: userProfile.name,
            email: userProfile.email,
            id: userProfile.id
        };

        try {
            setLoadingEmail(true);
            const response = await api.post(`/cadastro/validaEmail`, { email: payload.email });

            if (response) {
                setEmailIsOk(true);
                props.updateData(payload);
            }

            setLoadingEmail(false);

        } catch (error) {
            setLoadingEmail(false);

            if (error.response.status === 417) {
                window.location = '/';
            } else {
                localStorage.removeItem(GOOGLE_EMAIL);
                localStorage.removeItem(GOOGLE_USER);
                localStorage.removeItem(GOOGLE_USER_PROFILE);

                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }

        setLoading(false);
    }

    const goLogin = () => {
        window.location = '/login';
    }

    const checkSocialLogin = () => {
        setInterval(() => {
            const email = localStorage.getItem('@agroreceita_google_email');
            if (email && !emailGoogle) {
                setEmailGoogle(email);
            }
        }, 300);
    }

    const validaEmail = async (e) => {

        try {
            if (e) {
                setLoadingEmail(true);
                const response = await api.post(`/cadastro/validaEmail`, { email: e });

                if (response) {
                    setEmailIsOk(true);
                }

                setLoadingEmail(false);
            } else {
                setEmailIsOk(false);
            }

        } catch (error) {
            setLoadingEmail(false);
            setEmailIsOk(false);
            setErroEmail(true);
            setErroEmailMessage(error.response.data.errors);

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    useEffect(() => {

        checkSocialLogin();

        setTimeout(() => {
            let aTags = document.getElementsByTagName("span");
            let searchText = "Login";

            for (let i = 0; i < aTags.length; i++) {
                if (aTags[i].textContent == searchText) {
                    aTags[i].innerText = 'Continuar com Google';
                    break;
                }
            }
            setInterval(() => {
                let aTags = document.getElementsByTagName("span");
                let searchText = "Login";

                for (let i = 0; i < aTags.length; i++) {
                    if (aTags[i].textContent == searchText) {
                        aTags[i].innerText = 'Continuar com Google';
                        break;
                    }
                }
            }, 500);
        }, 300);
    }, [])

    useEffect(() => {
        if (emailGoogle) {
            handlePostSocial();
        }
    }, [emailGoogle])

    useEffect(() => {
        if (nome && email) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    }, [nome, email])


    return (
        <Container>
            <img src={logoBranco} alt='logo' className='imagem-fundo' />

            <SessionRow>
                <Card>

                    <ContainerLogo>
                        <div className='logo'>
                            <a href="http://www.agroreceita.com.br" target="_blank">
                                <img src={Logo} alt="logo" />
                            </a>
                        </div>
                        {props.isCompendio ? (
                            <>
                                <label className="title">Comece já suas consultas agronômicas ilimitadas!</label>
                                {/* <label className="subtitle">Emita 5 receitas grátis!</label> */}
                            </>
                        ) : (
                            <>
                                <label className="title">Comece já suas consultas agronômicas ilimitadas!</label>
                                {/* <label className="subtitle">Emita 5 receitas grátis!</label> */}
                            </>
                        )}

                        <div className='no-card'>
                            <img src={CardIcon} alt="icone cartão de crédito" style={{ width: '16px' }} />
                            <label>Não precisa cadastrar cartão de crédito.</label>
                        </div>
                    </ContainerLogo>

                </Card>

                <Card backgroundColor='#fff'>
                    <FormContainer>

                        <div className="btnGoogle">
                            {/* <div id='g-signin' className="g-signin2" data-onsuccess="onSignIn" data-onload="true">
                            </div> */}
                            <div id="g_id_onload"
                                data-client_id={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}
                                data-callback="handleCredentialResponse">
                            </div>
                            <div className="g_id_signin" data-type="standard" theme="outline" size="large" text="continue_with" shape="pill"></div>
                        </div>

                        <Line>
                            <hr />
                            <span>OU</span>
                        </Line>

                        <Input
                            id="nome"
                            value={nome}

                            label="Nome"
                            onChange={(e) => changeNome(e)}
                            erro={erroNome}
                            messageErro={erroNomeMessage}
                            marginBottom='0px'
                        />
                        <Input
                            id="email"
                            value={email}

                            label="E-mail"
                            onChange={(e) => changeEmail(e)}
                            erro={erroEmail}
                            messageErro={erroEmailMessage}
                            marginBottom='0px'
                            onBlur={(e) => validaEmail(e.target.value)}
                            loading={loadingEmail}
                            disabled={loadingEmail}
                            isValid={emailIsOk}
                        />

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', marginBottom: '15px' }}>
                            <label className='link' onClick={() => goLogin()}>Já tenho cadastro</label>
                            <Button
                                width='139px'
                                height='48px'
                                value='Continuar'
                                outline={false}
                                disabled={disabled || !emailIsOk}
                                onClick={() => handlePost()}
                                loading={loading}
                            />
                        </div>

                        <div className='terms'>
                            <p>Ao continuar você concorda com a <label onClick={() => clickPolitica()}>Politica de Privacidade</label> e <label onClick={() => clickTermos()}>Termos de Uso</label></p>
                        </div>

                    </FormContainer>
                </Card>
            </SessionRow>


            <ModalTermos
                show={showTermos}
                btnOk={true}
                onHide={() => setShowTermos(false)}
            ></ModalTermos>

            <ModalPoliticas
                show={showPolitica}
                btnOk={true}
                onHide={() => setShowPolitica(false)}
            ></ModalPoliticas>
        </Container>
    )
}

export default CadastroStep1;