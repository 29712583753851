import React, { useState, useEffect } from "react";

import Button from "../button/button_v2";
import Select from "../select/select_v2";
import api from "../../services/api";
import { store } from "react-notifications-component";
import Input from '../input/input_v2';
import GenericUpper from "../generic-upper";
import { set } from "date-fns";
import { FormContainer } from "./styles";
import { Container } from "react-grid-system";



export const HELPP = "@helpp";
const AddProviders = ({ ...props }) => {
    const [title, setTitle] = useState('Cadastre a prestadora de serviços!');
    const [step, setStep] = useState(1);
    const [align] = useState("center");

    const [razao, setRazao] = useState(props.razao);
    const [cnpj, setCnpj] = useState(props.cnpj);

    const [loadingSend, setLoadingSend] = useState(false);



    useEffect(() => {
    }, [step]);

    const sendProviders = async () => {
        setLoadingSend(true);
        try {
            const payload = {
                razao_social: razao,
                cnpj: cnpj,
            }

            const response = await api.post(`/prestador`, payload);
            store.addNotification({
                title: "Sucesso!",
                message: `${response.data.message}`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            props.success();
            setLoadingSend(false);
        } catch (e) {
            setLoadingSend(false);
            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors ? e.response.data.errors : "Não há prestadora de serviços cadastrada!" }`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 7000,
                    onScreen: true
                }
            });
        }
    };

    return (
        <>
            <FormContainer isModal={props.isModal}>
                <Container style={{ width: '100%', paddingLeft: props.isModal ? '0px' : '15px', paddingRight: props.isModal ? '0px' : '15px' }}>
                    {props.isModal === true ? (
                    <h4 style={{ textAlign: 'center' }}>{title}</h4> 
                    ) : (
                        <GenericUpper
                            align={align}
                            title={title}
                        ></GenericUpper>
                    )}
                    <br />

                    <Input
                        name="razao"
                        className="text"
                        label="Razão Social"
                        id="razao"
                        type='text'
                        value={razao}
                        onChange={e => setRazao(e)}
                    />
                    <Input
                        name="cnpj"
                        className="text"
                        label="CNPJ"
                        id="cnpj"
                        type='text'
                        value={cnpj}
                        onChange={e => setCnpj(e)}
                    />
                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>
                        <Button
                            width='296px'
                            height='50px'
                            value='Enviar'
                            outline={false}
                            disabled={false}
                            onClick={() => sendProviders()}
                            loading={loadingSend}
                        />
                    </div>
                </Container>
            </FormContainer>
        </>
    );
};
export default AddProviders;