import styled from "styled-components";

export const Card = styled.div`
  width: 100%;

  .estado{
    width: 81px;
    height: 25px;
    border-radius: 5px;
    background-color: #f3f3f5;
    padding-top: 3px;
    text-align: center;
  }

  .estado-inativo{
    width: 81px;
    height: 25px;
    border-radius: 5px;
    background-color: #f3f3f5;
    font-family: Lato;
    color: #ff5252;
    padding-top: 3px;
    text-align: center;
  }

  #container{
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    max-width: 375px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    
    margin: auto;
    margin-bottom: 20px;
    /* margin-top: 20px; */

    #titulos{
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      /* margin-top: 20px; */
      font-weight: bold;
      font-size: 14px;
      color: #8a92a2;
      padding-left: 10px;

      p {
        margin: 4px;
        /* height: 2rem; */
      }
    }
    
    #values{
      width: 48%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-top: 0px;
      font-size: 14px;

      
      td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .val-grid {
        label {
          width: 100%;
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    
    }
  }

  #container:hover {
    background-color: white;
  }

  .link-azul{
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #589bff;
    margin: 2px;
    margin-left: -2px;

    display: flex;
    flex-direction: row;
    align-items: center;


    transition: all 0.3s ease;

    strike {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    label {
      margin: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    i {
      font-size: 14px
    }
    
    &:hover {
      transform: scale(1.03);
    }
  }
  
  .link{
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 2px;

    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      margin: 0px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    #container{
      background-color: ${props => (props.idColor % 2 == 0 ? "#f8f8f9" : "#ffffff")};

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
      max-width: 375px;
      height: auto;
      min-height: 220px;
      margin: auto;
      padding-top: 20px;
      padding-bottom: 20px;
  
      #titulos{
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        /* margin-top: 20px; */
        font-weight: bold;
        font-size: 10px;
        color: #8a92a2;
      }
      
      #values{
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 7px;
        font-size: 14px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .nome{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p{
            font-weight: bold;
            font-size: 10px;
            color: #8a92a2;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        td{
            margin-top: -4px;
            font-size: 14px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            p{
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
      }


      .crea .individualCrea p{
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 20px;
        font-weight: bold;
        font-size: 10px;
        color: #8a92a2;
      }

      .crea td{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 0px;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        p{
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      

      .estados p{
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-top: 20px;
        font-weight: bold;
        font-size: 10px;
        color: #8a92a2;
      }
    }
  }
`;

export const ProgressBar = styled.div`
  width: 120px;
  height: 7px;
  border-radius: 3.5px;
  background-color: #f3f3f5;
  padding-top: 2px;
  padding-right: ${props => props.progress};
  margin: auto;
  margin-left: 0px;
`;

export const Progress = styled.div`
  height: 3px;
  border-radius: 1.5px;
  background-color: #00b43f;
  margin-left: 0px;
`;