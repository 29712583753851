import React from 'react';
import { ButtonContainerV2, Loading } from './styles';

const Button = ({ ...props }) => {

    return(
        <ButtonContainerV2
            width={props.width}
            height={props.height}
            disabled={props.disabled}
            outline={props.outline}
            loading={props.loading}
            red={props.red}
            blog={props.blog}
            color={props.color}
            tooltipMarginLeft={props.tooltipMarginLeft}
            margin={props.margin}
        >
            <button type="button" className='btnv2' onClick={() => props.onCLick()} {...props} disabled={props.disabled || props.loading}>
                {props.loading === true ? (
                    <Loading 
                        disabled={props.disabled}
                        outline={props.outline}
                        red={props.red}
                    />
                ) : (
                    <>
                        {props.value}
                    </>
                )}
            </button>

            {props.disabled === true && props.tooltip === true && props.tooltipMsg ? (
                <p>{props.tooltipMsg}</p>
            ) : (props.tooltip === true && props.showTooltipMsg ? (
                <p>{props.showTooltipMsg}</p>
            ) : (null))}
        </ButtonContainerV2>
    )
}

export default Button;
