import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { store } from "react-notifications-component";
import { Container, ScreenClassRender } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import InputSearch from "../../components/input-search";
import Button from "../../components/button/button_v2";
import iconFilter from "../../assets/icons/icons-8-filter.png";
import CardPrescription from "../../components/card-historico/card";
import ModalFilter from "../../components/modal/modalFilter1";
import ModalExportar from "../../components/modal/modalHistoricoExportar";
import DotsLoader from "../../components/loader";
import ModalAlert from '../../components/modal/modalGeneric';
import BlackNovember from '../../assets/black-november.svg';
import Close from '../../assets/icons/close-white.svg';

import Paginator from '../../components/paginator';


import { HistoricoContainer } from "./styles";
export const USER = "@userinfo";

const Historico = ({ ...props }) => {

    const [receitas, setReceitas] = useState([]);
    const [result, setResult] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [filtroState, setFiltro] = useState('');
    const [screenSize, setScreenSize] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [estadosHistorico, setEstadosHistorico] = useState([]);
    const [setOptions, setViewOptions] = useState(false);
    const [option, setOption] = useState('');
    const [openModalExport, setOpenModalExport] = useState(false);
    const [optionTitle, setOptionTitle] = useState('');
    const [optionValue, setOptionValue] = useState('');
    const [screenSize2, setScreenSize2] = useState('');
    const [limit, setLimit] = useState(30);
    const [limitPost] = useState(30);
    const [offset, setOffset] = useState(0);
    const [offsetPost, setOffsetPost] = useState(0);
    const [hideButton, setHideButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingLoadMore, setLoadingloadMore] = useState(false);
    const [load, setLoad] = useState(false);
    const [totalReceitas, setTotalReceitas] = useState(0);
    const [showModalBlock, setShowModalBlock] = useState(false);
    const [payloadData, setPayloadData] = useState(null);
    // const [modalShowPromo, setModalShowPromo] = useState(false);

    useEffect(() => {
        setScreenSize2(window.screen.width);
    }, [window.screen.width])

    const getScreenSize = screensize => {
        const sizesToHide = ["xl", "lg"];
        const hide = sizesToHide.includes(screensize);
        const prevScreenSize = screenSize;


        if (prevScreenSize !== screensize) {
            if (hide) {
                setScreenSize(screensize);
                setIsMobile(false);

            } else {
                setScreenSize(screensize);
                setIsMobile(true);
            }
        }
    };

    const modalClose = e => {

        if (showFilter == true) {
            setShowFilter(false);
        }

        if (openModalExport == true) {
            setOpenModalExport(false);
        }
    };

    const openModal = e => {
        setShowFilter(true);
    };

    const openOptions = () => {
        if (setOptions == true) {
            setViewOptions(false);
        } else {
            setViewOptions(true);
        }
    }
    
    const getEmpresa = async () => {
        try {

            const user = JSON.parse(localStorage.getItem(USER));

            const response = await api.get(`/filiais/${user.agro_subsidiary_id}`);

            if (response) {
                const filial = response.data.data;
                if (filial.estado === "MG") {
                    return true;
                }
                return false;
            }

        } catch (error) {
            
        }
    }
    const updateResposta = async (resposta) => {
        setFiltro(resposta);
        modalClose();
    };

    const updateRespostaExportar = async (resposta) => {
        modalClose();
    };

    const SearchTap = (tap) => {
    
    }  

    const search = async (result, produtorId) => {
        console.log(result, 'resultado', produtorId, "prodId")
        if (((!result || result == '') && produtorId == 0) && (filtroState.agronomo_id == undefined || filtroState.agronomo_id == '') && (filtroState.agronomo_nome == undefined || filtroState.agronomo_nome == '') && (filtroState.botao == undefined || filtroState.botao == '') && (filtroState.dataInicial == undefined || filtroState.dataInicial == '') && (filtroState.dataFinal == undefined || filtroState.dataFinal == '')) {
            setReceitas([]);
            setLoading(true);
            setHideButton(true);

            setOffset(0);
            setOffsetPost(0);
            getReceitas(limit, offset);
        } else if (result == '' && produtorId !== 0) {
            try {
                setReceitas([]);
                setLoading(true);
                setHideButton(true);

                let payload = {
                    data_inicial: "",
                    data_final: "",
                    representante_tecnico: "",
                    search: "",
                    produtor_id: parseInt(produtorId),
                    data_preset: "",
                    receitas_canceladas: true,
                }

                console.log(payload)

                const response = await api.post(`/receitas/historico?limit=${limitPost}&offset=${offsetPost}`, payload);

                if (response) {
                    if (totalReceitas !== response.data.message.total) {
                        updatePaginator(response.data.message.total);
                    }
                    const receitas = response.data.data;


                    if (receitas.length > 0) {
                        setReceitas(receitas);
                        setLoading(false);
                        setHideButton(false);
                    } else {
                        setReceitas([]);
                        setLoading(false);
                        setHideButton(true);
                    }

                } else {
                    setLoading(false);
                    setHideButton(true);
                }

            } catch (error) {
                setHideButton(true);
                setLoading(false);

                setReceitas([]);

                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }



        } else {

            if ((filtroState.agronomo_id != "" || filtroState.agronomo_nome != "" || filtroState.botao != "" || filtroState.dataInicial != "" || filtroState.dataFinal != "" || filtroState.yesNoValue == false) || result.length > 0) {
                try {
                    setReceitas([]);
                    setLoading(true);
                    setHideButton(true);

                    let payload = {
                        data_inicial: filtroState.dataInicial ? filtroState.dataInicial : "",
                        data_final: filtroState.dataFinal ? filtroState.dataFinal : "",
                        representante_tecnico: filtroState.agronomo_id ? filtroState.agronomo_id : "",
                        search: result ? result : "",
                        produtor_id: produtorId ? produtorId : 0,
                        data_preset: filtroState.botao ? filtroState.botao : "",
                        receitas_canceladas: filtroState.yesNoValue == false ? false : true,
                    }

                    console.log(payload)

                    const response = await api.post(`/receitas/historico?limit=${limitPost}&offset=${offsetPost}`, payload);

                    if (response) {
                        if (totalReceitas !== response.data.message.total) {
                            updatePaginator(response.data.message.total);
                        }
                        const receitas = response.data.data;


                        if (receitas.length > 0) {
                            setReceitas(receitas);
                            setLoading(false);
                            setHideButton(false);
                        } else {
                            setReceitas([]);
                            setLoading(false);
                            setHideButton(true);
                        }

                    } else {
                        setLoading(false);
                        setHideButton(true);
                    }

                } catch (error) {
                    setHideButton(true);
                    setLoading(false);

                    setReceitas([]);

                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        }

    }

    const onKeyDown = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            setOffset(0);
            setResult(e.target.value);
            search(e.target.value);
        } else {
        }
    };

    const updateValues = async (text, estado, pesquisa) => {
        setResult(text);
        setOffset(0);
        search(text);
    };

    const updatePaginator = (total) => {
        setTotalReceitas(0);

        setTimeout(() => {
            setTotalReceitas(total);
        }, 100);
    }
    const getReceitas = async (limit, offset, produtor) => {
        if (limit != 0) {
            setOffset(offset);

            try {
                setLoading(true);
                setHideButton(true);

                let payload = {
                    data_inicial: filtroState.dataInicial ? filtroState.dataInicial : "",
                    data_final: filtroState.dataFinal ? filtroState.dataFinal : "",
                    representante_tecnico: filtroState.agronomo_id ? filtroState.agronomo_id : "",
                    search: result ? result : "",
                    produtor_id: produtor ? parseInt(produtor) : 0,
                    data_preset: filtroState.botao ? filtroState.botao : "",
                    receitas_canceladas: filtroState.yesNoValue == false ? false : true,
                }
                const response = await api.post(`/receitas/historico?limit=${limit}&offset=${offset}`, payload);

                localStorage.setItem("@agroreceita:exist_prescription", true);
                setReceitas(response.data.data);
                let opExport = response.data.message;
                console.log(opExport)
                let options = [];

                if (opExport.es === true) {
                    options = [...options, 'ES'];
                }
                if (opExport.go === true) {
                    options = [...options, 'GO'];
                }

                if (opExport.ba === true) {
                    options = [...options, 'BA'];
                }
                if (opExport.mt === true) {
                    if (opExport.mensagem_mt && load === false) {
                        store.addNotification({
                            title: "Atenção!",
                            message: `${opExport.mensagem_mt}`,
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                    }
                }
                if (opExport.pr === true) {
                    options = [...options, 'PR'];
                }
                if (opExport.rs === true) {
                    options = [...options, 'RS'];
                    if (opExport.mensagem_rs && load === false) {
                        store.addNotification({
                            title: "Atenção!",
                            message: `${opExport.mensagem_rs}`,
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                    }
                }
                if (opExport.sc === true) {
                    options = [...options, 'SC'];
                }

                const empresaSicca = getEmpresa();

                if (empresaSicca) {
                    options = [...options, 'MG'];
                }

                setLoad(true);
                setEstadosHistorico(options);
                setLoading(false);
                setHideButton(false);

                if (totalReceitas !== response.data.message.total) {
                    updatePaginator(response.data.message.total);
                }

                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }, 200);


            } catch (error) {
                setHideButton(true);
                setLoading(false);
                setReceitas([]);
            }
        }
    };

    const clearFiltroBtn = () => {
        let filtro = filtroState;
        setFiltro('');

        setTimeout(() => {
            filtro.botao = "";
            setFiltro(filtro);
        }, 200);
    };

    const clearFiltroDtInicial = () => {
        let filtro = filtroState;
        setFiltro('');

        setTimeout(() => {
            filtro.dataInicial = "";
            setFiltro(filtro);
        }, 200);
    };

    const clearFiltroDtFinal = async () => {
        let filtro = filtroState;
        setFiltro('');

        setTimeout(() => {
            filtro.dataFinal = "";
            setFiltro(filtro);
        }, 200);
    };

    const clearFiltroAgronomo = async () => {
        let filtro = filtroState;
        setFiltro('');

        setTimeout(() => {
            filtro.agronomo_id = "";
            filtro.agronomo_nome = "";

            setFiltro(filtro);
        }, 200)

    };

    const openExport = (option) => {

        let user = JSON.parse(localStorage.getItem(USER));

        if (user.contrato.agro_plan_config.is_teste === 1) {
            setShowModalBlock(true);
        } else {
            if (option === 1) {
                setOptionTitle('Siagro');
                setOptionValue(1);

            } else if (option === 2) {
                setOptionTitle('Cidasc');
                setOptionValue(2);


            } else if (option === 3) {
                setOptionTitle('Sisdev');
                setOptionValue(3);

            } else if (option === 4) {
                setOptionTitle('Sidago');
                setOptionValue(4);


            } else if (option === 5) {
                setOptionTitle('SDA RS');
                setOptionValue(5);
            } else if (option === 6) {
                setOptionTitle('IDAF');
                setOptionValue(6);
            } else if (option === 7) {
                setOptionTitle('ADAB');
                setOptionValue(7);
            } else if (option === 8) {
                setOptionTitle('SICCA');
                setOptionValue(8);
            }
            setOption(option);
            setOpenModalExport(true);

        }

    }

    const openPlanos = () => {
        setShowModalBlock(false);
        props.history.push('/planos');
    }

    const updateLimitOffset = (l, o) => {
        if ((window.location.search.slice(1))) {
            let idProducer = window.location.search.slice(1);
            if (l !== limit || o !== offset) {
                setLimit(l);
                setOffset(o);
                getReceitas(l, o, idProducer);
            }
        } else {
            if (l !== limit || o !== offset) {
                setLimit(l);
                setOffset(o);
                getReceitas(l, o);
            }
        }
    }

    useEffect(() => {
        let pdf = localStorage.getItem('@prescription');
        if (pdf) {
            window.open(pdf);
            localStorage.removeItem('@prescription');
        }

        if ((window.location.search.slice(1))) {
            let idProducer = window.location.search.slice(1);
            getReceitas(limit, 0, idProducer);
        } else {
            getReceitas(limit, 0, 0);
        }

        let userInfo = JSON.parse(localStorage.getItem(USER));
        // if (
        //     userInfo.contrato.agro_plan_config.agro_plan_id == 1 || 
        //     userInfo.contrato.agro_plan_config.agro_plan_id == 6 || 
        //     userInfo.contrato.agro_plan_config.agro_plan_id == 7
        // ) {
        //     setModalShowPromo(true);
        // }

    }, []);

    useEffect(() => {
        if ((window.location.search.slice(1))) {
            let idProducer = window.location.search.slice(1);
            search('', idProducer);
        } else {
            if (filtroState) {
                if (result) {
                    search(result, 0);
                } else {
                    search('', 0);
                }
            }
        }
    }, [filtroState]);

    useEffect(() => {
        if (offset == 0) {
            setOffsetPost(0);
        }
    }, [offset])

    const exibeFiltro = (
        <>
            {filtroState ? (
                <>
                    {filtroState.dataInicial != "" || filtroState.dataFinal != "" || filtroState.botao != "" || filtroState.agronomo_id != "" || filtroState.agronomo_nome != "" ? (
                        <div className="card-filtro">
                            {filtroState.dataInicial && filtroState.dataInicial != "" ? (
                                <div className="item"> Data Inicial: {filtroState.dataInicial} <div className="close-filter" onClick={clearFiltroDtInicial}> x </div> </div>
                            ) : null}

                            {filtroState.dataFinal && filtroState.dataFinal != "" ? (
                                <div className="item"> Data Final: {filtroState.dataFinal} <div className="close-filter" onClick={clearFiltroDtFinal}> x </div> </div>
                            ) : null}

                            {filtroState.botao == 1 ? (
                                <div className="item"> HOJE <div className="close-filter" onClick={clearFiltroBtn}> x </div> </div>
                            ) : null}

                            {filtroState.botao == 2 ? (
                                <div className="item"> ONTEM <div className="close-filter" onClick={clearFiltroBtn}> x </div> </div>
                            ) : null}

                            {filtroState.botao == 3 ? (
                                <div className="item"> ÚLTIMOS 7 DIAS <div className="close-filter" onClick={clearFiltroBtn}> x </div> </div>
                            ) : null}

                            {filtroState.botao == 4 ? (
                                <div className="item"> ÚLTIMOS 15 DIAS <div className="close-filter" onClick={clearFiltroBtn}> x </div> </div>
                            ) : null}

                            {filtroState.agronomo_id && filtroState.agronomo_id != "" && filtroState.agronomo_nome && filtroState.agronomo_nome != "" ? (
                                <div className="item"> AGRONOMO: {filtroState.agronomo_nome} <div className="close-filter" onClick={clearFiltroAgronomo}> x </div> </div>
                            ) : null}

                        </div>
                    ) : (<> </>)}
                </>) : null}
        </>
    )

    return (
        <ScreenClassRender
            render={screenClass => (
                <>
                    {getScreenSize(screenClass)}

                    <GenericUpper
                        title="Meu histórico"
                        props={props}
                        subtitle="Procure por receitas emitidas ou exporte relatórios"
                        search={true}
                        searchFunction={(text) => search(text, 0)}
                        searchFilter={true}
                        placeholder={'N.Receita, Produto, Praga, Cultura ou Produtor'}
                        showFilter={(value) => setShowFilter(value)}
                        tooltip={'Exportar Dados'}
                        searchButtonAct = {(value) => openExport(value)}
                        searchButtonOptions={estadosHistorico}
                        isMobile={isMobile}
                        hideFilter={false}
                        hideExportar={false}
                    ></GenericUpper>

                    <HistoricoContainer isMobile={isMobile}>
                        <Container id='container' style={{ maxWidth: '700px'}}>
                            {loading ? (
                                <div className="loading" style={{
                                    width: '100%',
                                    height: '50vh',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <DotsLoader show={loading}></DotsLoader>
                                </div>
                            ) : (
                                <>
                                    {receitas.length > 0 ? (
                                        <>
                                            {receitas.map(r => (
                                                <CardPrescription
                                                    receita={r}
                                                    isMobile={isMobile}
                                                    updateReceitas={() => getReceitas(limit, offset)}
                                                    propsLocation={props}
                                                />
                                            ), console.log(receitas))}
                                        </>
                                    ) : (
                                        <div className="loading" style={{
                                            width: '100%',
                                            height: '25vh',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                            <h4 style={{ textAlign: 'center' }}>Nenhuma receita encontrada</h4>
                                        </div>
                                    )}
                                </>

                            )}


                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                <Paginator perPage={limit} total={totalReceitas} totalItens={receitas.length} onChange={(limit, offset) => updateLimitOffset(limit, offset)} />
                            </div>

                            <ModalFilter
                                show={showFilter}
                                onHide={modalClose}
                                resposta=''
                                update={updateResposta}
                                title='Filtros'
                                setFilter={filtroState}
                                isMobile={isMobile}
                            ></ModalFilter>

                            {openModalExport == true ? (
                                <ModalExportar
                                    show={openModalExport}
                                    onHide={() => setOpenModalExport(false)}
                                    resposta=''
                                    update={updateRespostaExportar}
                                    closeModal={() => setOpenModalExport(false)}
                                    title='Exportar dados - '
                                    option={optionTitle}
                                    optionValue={optionValue}
                                    setFilter={filtroState}
                                ></ModalExportar>
                            ) : (null)}


                            <ModalAlert
                                show={showModalBlock}
                                onHide={() => setShowModalBlock(false)}
                            >
                                <h4 style={{
                                    textAlign: 'center'
                                }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

                                <p style={{
                                    textAlign: 'center',
                                    marginTop: '20px',
                                }}>
                                    Para não comprometer o controle de suas receitas agronômicas durante o período de teste esta função é bloqueada.
                                </p>

                                <p style={{
                                    textAlign: 'center',
                                    marginTop: '20px',
                                }}>
                                    Assine o AgroReceita para desbloquear esta função!
                                </p>

                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                                    <Button
                                        width='296px'
                                        height='50px'
                                        value='VER PLANOS'
                                        outline={false}
                                        disabled={false}
                                        onClick={() => openPlanos(false)}
                                        loading={false}
                                    />
                                </div>
                            </ModalAlert>

                            {/* {modalShowPromo && (
                                <ModalAlert
                                    show={modalShowPromo}
                                    removeHeader={true}
                                    onHide={() => setModalShowPromo(false)}
                                    backgroundColor='#1D2129'
                                    responsive={true}
                                    width={'600px'}
                                    marginBottom={'0px'}
                                >
                                    <div className="msg-promo">
                                        <div className="logo">
                                            <img src={BlackNovember} />
                                        </div>
                                        <div className="texto">
                                            <p>Utilize o cupom "<b>AGROBLACK</b>" e garanta </p>
                                            <p><b>20% de desconto</b> nas 12 primeiras mensalidades!</p>
                                        </div>
                                    </div>

                                    <div className="dois-cards">

                                        <div className="card">
                                            <h6>Plano Light</h6>
                                            <p className="valor-antigo">R$ 139</p>
                                            <p className="valor-novo">R$ 111</p>
                                            <p className="tipo">mensais/usuário</p>

                                        </div>
                                        <div className="card">
                                            <h6>Plano Pro</h6>
                                            <p className="valor-antigo">R$ 189</p>
                                            <p className="valor-novo">R$ 151</p>
                                            <p className="tipo">mensais/usuário</p>


                                        </div>
                                    </div>

                                    <div className="validade-promo">
                                        <p>Promoção válida até 30 de novembro.</p>
                                    </div>

                                    <Button 
                                        width='55%'
                                        height='50px'
                                        value='GARANTA DESCONTO'
                                        outline={false}
                                        disabled={false}
                                        loading={false}
                                        onClick={() => window.location = '/planos?utm_source=sistema&utm_medium=modal&utm_campaign=black-november-23&cupom=AGROBLACK'}
                                        margin={'16px 0px'}
                                    />

                                    <div className="close-icon" onClick={() => setModalShowPromo(false)}>
                                        <img src={Close} />
                                        <p>Não quero desconto</p>
                                    </div>
                                </ModalAlert>

                            )} */}
                        </Container>
                    </HistoricoContainer>
                </>
            )}
        />);
};

export default Historico;
