import React, {useState, useEffect} from 'react';
import Items from './items';
import api from '../../../services/api';

const Relation = ({...props}) => {
    const [totalItens, setTotalItens] = useState(0);
    const [relationsSelecionadas, setRelationsSelecionadas] = useState([]);
    const [relations, setRelations] = useState([]);
    const [quantidades, setQuantidades] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [embalagens, setEmbalagens] = useState([]);
    const [produtos, setProdutos] = useState([]);

    const setInitialValues = (prod) => {
        let listProducts = [];

        prod.map((p, i) => {
            listProducts[i] = p;
        });
    }

    const loadProducts = (p) => {
        setIsLoad(true);
        if (p.produtos.length !== totalItens) {
            setTotalItens(p.produtos.length);

            if (p.relations.length > 0) {
                setProdutos(p.produtos);
                setInitialValues(p.produtos);
            }
        } else {
            if (p.produtos.length > 0) {
                let listProducts = [];
                let existDiferent = false;
    
                p.produtos.map((pr, i) => {
                    if (pr === '') {
                        listProducts[i] = produtos[i];
                    }else if (pr !== produtos[i]) {
                        existDiferent = true;
                        listProducts[i] = pr;
                    } else {
                        listProducts[i] = produtos[i];
                    }
                })
                
                if (existDiferent) {
                    setProdutos(listProducts);
                }
            }
        }
    }

    const changeQuantidade = (value, index) => {
        let listQtds = quantidades;
        if (listQtds[index] !== value) {
            listQtds[index] = value;
            setQuantidades(listQtds);
            props.updateQuantidade(listQtds);
        }
    }

    const changeProduto = (value, index) => {
        let p = produtos;
        if (p[index] !== value) {
            p[index] = value;
            setProdutos(p);
            props.updateProduto(p);
        }
    }

    const changeEmbalagemValue = (value, index) => {
        let listQtds = embalagens;
        if (listQtds[index] !== value) {
            listQtds[index] = value;
            setEmbalagens(listQtds);
            props.updateEmbalagemValue(listQtds);
        }
    }

    useEffect(() => {
        if (isLoad === false) {
            loadProducts(props);
        }
        console.log(props)
        setRelations(props.relations);
        setQuantidades(props.quantidades);
        setEmbalagens(props.embalagens)
    }, []);

    return(
        <>  
            {relations.length > 0 && (
                <>
                    {relations.map((p, i) => (
                        <Items 
                            index={i}
                            relation={p}
                            quantidades={props.quantidades[i]}
                            produto={props.produtos[i]}
                            embalagensValues={embalagens[i]}
                            updateProduto={(e, i) => changeProduto(e, i)}
                            updateQuantidade={(e, i) => changeQuantidade(e, i)}
                            updateEmbalagem={(e, i) => changeEmbalagemValue(e, i)}
                        />
                    ))}
                </>
            )}
        </>
    )
}

export default Relation;
