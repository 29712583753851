import React from "react";

import estados from "../../services/estados";
import api from "../../services/api";
import { store } from "react-notifications-component";
import GenericUpper from "../../components/generic-upper";
import { FiliaisAddContainer } from "./styles";
import { Container } from "react-grid-system";
import Input from "../../components/input/input_v2";
import Button  from '../../components/button/button_v2';
import Select from "../../components/select/select_v2";
import buscaCep from "../../services/buscaCep";
import { string } from "prop-types";

class AddFiliais extends React.Component {
    state = {
        nome_fantasia: "",
        erroFantasia: false,
        erroFantasiaMsg: false,
        razao_social: "",
        erroSocial: false,
        erroSocialMsg: false,
        cnpj: "",
        erroCnpj: false,
        erroCnpjMsg: false,
        telefone: "",
        erroTelefone: false,
        erroTelefoneMsg: false,
        cep: "",
        erroCep: false,
        erroCepMsg: false,
        endereco: "",
        erroEndereco: false,
        erroEnderecoMsg: false,
        cidade: "",
        erroCidade: false,
        erroCidadeMsg: false,
        bairro: "",
        erroBairro: false,
        erroBairroMsg: false,
        numero: "",
        erroNumero: false,
        erroNumeroMsg: false,
        estado: "",
        erroEstado: false,
        erroEstadoMsg: false,
        loading: false
    };

    fetchCep = async () => {
        const { cep } = this.state;
        
        try {
            const responseCep = await buscaCep(cep);
            const { logradouro, localidade, bairro, uf } = responseCep;

            this.setState({
                endereco: logradouro,
                cidade: localidade,
                bairro: bairro,
                estado: uf
            });
        } catch (err) {
            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    handlePost = async e => {
        this.setState({ loading: true });

        const {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            cep,
            endereco,
            cidade,
            bairro,
            numero,
            estado
        } = this.state;

        const payload = {
            nome_fantasia,
            razao_social,
            telefone_emergencia: "",
            cnpj,
            telefone,
            cep,
            endereco,
            bairro,
            numero,
            cidade,
            estado,
            is_matriz: 0
        };

        try {
            const response = await api.post("/filiais/", payload);

            if (response.status === 200) {
                this.props.history.push("/filiais");
                this.setState({ loading: false });
                store.addNotification({
                    title: "Sucesso!",
                    message: "A filial foi cadastrada corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false });

            const ERRO = err.response.data.errors;

            if (typeof ERRO === "string") {
                store.addNotification({
                    title: "Erro!",
                    message: `${ERRO}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                try {
                    let inputWithErrors = document.querySelectorAll(".error");
        
                    for (let input of inputWithErrors) {
                        input.classList.remove("error");
                    }
        
                    const errors = err.response.data.errors;
                    
        
                    for (let field in errors) {
                        if (field === 'bairro') {
                            this.setState({ erroBairro: true, erroBairroMsg: errors.bairro._empty ? errors.bairro._empty : '' });
                        }
                        if (field === 'cep') {
                            this.setState({ erroCep: true, erroCepMsg: errors.cep._empty ? errors.cep._empty : '' });
                        }
                        if (field === 'cidade') {
                            this.setState({ erroCidade: true, erroCidadeMsg: errors.cidade._empty ? errors.cidade._empty : '' });
                        }
                        if (field === 'cnpj') {
                            this.setState({ erroCnpj: true, erroCnpjMsg: errors.cnpj._empty ? errors.cnpj._empty : '' });
                        }
                        if (field === 'endereco') {
                            this.setState({ erroEndereco: true, erroEnderecoMsg: errors.endereco._empty ? errors.endereco._empty : '' });
                        }
                        if (field === 'estado') {
                            this.setState({ erroEstado: true, erroEstadoMsg: errors.estado._empty ? errors.estado._empty : '' });
                        }
                        if (field === 'nome_fantasia') {
                            this.setState({ erroFantasia: true, erroFantasiaMsg: errors.nome_fantasia._empty ? errors.nome_fantasia._empty : '' });
                        }
                        if (field === 'razao_social') {
                            this.setState({ erroSocial: true, erroSocialMsg: errors.razao_social._empty ? errors.razao_social._empty : '' });
                        }
                        if (field === 'telefone') {
                            this.setState({ erroTelefone: true, erroTelefoneMsg: errors.telefone._empty ? errors.telefone._empty : '' });
                        }
                        if (field === 'numero') {
                            this.setState({ erroNumero: true, erroNumeroMsg: errors.numero._empty ? errors.numero._empty : '' });
                        }
                    }
                
                    store.addNotification({
                        title: "Erro!",
                        message: "Verifique os campos em vermelho",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }catch(err) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${ERRO}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        }
    };

    render() {

        const {
            nome_fantasia,
            erroFantasia,
            erroFantasiaMsg,
            razao_social,
            erroSocial,
            erroSocialMsg,
            cnpj,
            erroCnpj,
            erroCnpjMsg,
            telefone,
            erroTelefone,
            erroTelefoneMsg,
            cep,
            erroCep,
            erroCepMsg,
            endereco,
            erroEndereco,
            erroEnderecoMsg,
            cidade,
            erroCidade,
            erroCidadeMsg,
            bairro,
            erroBairro,
            erroBairroMsg,
            numero,
            erroNumero,
            erroNumeroMsg,
            estado,
            erroEstado,
            erroEstadoMsg,
            loading
        } = this.state;

        return (
            <>
                <GenericUpper
                    title="Filiais"
                    subtitle="Cadastre uma filial"
                ></GenericUpper>

                <FiliaisAddContainer>

                    <Container>
                        <form>
                            <Input
                                name="nome_fantasia"
                                type="text"
                                label="Nome Fantasia"
                                id="nome_fantasia_id"
                                value={nome_fantasia}
                                onChange={e => this.setState({ nome_fantasia: e, erroFantasia: false })}
                                erro={erroFantasia}
                                messageErro={erroFantasiaMsg}

                            />

                            <Input
                                name="razao_social"
                                type="text"
                                label="Razão Social"
                                id="razao_social_id"
                                value={razao_social}
                                onChange={e => this.setState({ razao_social: e, erroSocial: false })}
                                erro={erroSocial}
                                messageErro={erroSocialMsg}

                            />

                            <Input
                                name="cnpj"
                                type="text"
                                label="CNPJ"
                                id="cnpj_id"
                                value={cnpj}
                                onChange={e => this.setState({ cnpj: e, erroCnpj: false })}
                                erro={erroCnpj}
                                messageErro={erroCnpjMsg}
                                
                            />

                            <Input
                                name="telefone"
                                type="text"
                                label="Telefone"
                                id="telefone_id"
                                value={telefone}
                                onChange={e => this.setState({ telefone: e, erroTelefone: false })}
                                erro={erroTelefone}
                                messageErro={erroTelefoneMsg}

                            />

                            <Input
                                name="cep"
                                type="text"
                                label="CEP"
                                id="cep_id"
                                value={cep}
                                onChange={e => this.setState({ cep: e, erroCep: false })}
                                onBlur={this.fetchCep}
                                erro={erroCep}
                                messageErro={erroCepMsg}

                            />

                            <Input
                                name="endereco"
                                type="text"
                                label="Endereço"
                                id="endereco_id"
                                value={endereco}
                                onChange={e => this.setState({ endereco: e, erroEndereco: false })}
                                erro={erroEndereco}
                                messageErro={erroEnderecoMsg}

                            />

                            <Input
                                name="numero"
                                label="Número"
                                id="numero_id"
                                value={numero}
                                onChange={e => this.setState({ numero: e, erroNumero: false })}
                                erro={erroNumero}
                                messageErro={erroNumeroMsg}

                            />

                            <Input
                                name="bairro"
                                type="text"
                                label="Bairro"
                                id="bairro_id"
                                value={bairro}
                                onChange={e => this.setState({ bairro: e, erroBairro: false })}
                                erro={erroBairro}
                                messageErro={erroBairroMsg}
                            />

                            <Input
                                name="cidade"
                                type="text"
                                label="Cidade"
                                id="cidade_id"
                                value={cidade}
                                onChange={e => this.setState({ cidade: e, erroCidade: false })}
                                erro={erroCidade}
                                messageErro={erroCidadeMsg}

                            />

                            <Select
                                name="estado"
                                className="select"
                                label="Estado"
                                id="estado_id"
                                value={estado}
                                onChange={state => this.setState({ estado: state.value, erroEstado: false })}
                                options={estados}
                                estado={true}
                                erro={erroEstado}
                                messageErro={erroEstadoMsg}

                            />

                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='CADASTRAR'
                                    outline={false}
                                    disabled={loading}
                                    onClick={() => this.handlePost()}
                                    loading={loading}
                                />
                            </div>
                        </form>
                    </Container>
                </FiliaisAddContainer>
            </>
        );
    }
}

export default AddFiliais;
