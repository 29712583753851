import React, { useState, useEffect } from 'react';

import { BoxArea, Subtract, Value, Add } from './style';
const NumericBox = ({ ...props }) => {

    const [value, setValue] = useState(parseInt(props.initialValue));

    const sub = () => {
        
        const v = value-1;

        if (v < 0) {
            if (props.allowNegative === true) {
                setValue(v);
                props.onChange(v);
            }
        } else {
            if (v === 0) {
                if (props.allowEmpty === true) {
                    setValue(v);
                    props.onChange(v);
                }

            } else {
                setValue(v);
                props.onChange(v);
            }
        }
    }
    
    const add = () => {
        const v = value+1;
        setValue(v);
        props.onChange(v);
    }

    useEffect(() => {
        props.onChange(props.initialValue);
    }, [props.initialValue])

    return (
        <BoxArea>
            <Subtract onClick={() => sub()}>
                <label>-</label>
            </Subtract>
            <Value>
                <label>{value}</label>
            </Value>
            <Add onClick={() => add()}>
                <label>+</label>
            </Add>
        </BoxArea>
    )
}

export default NumericBox;
