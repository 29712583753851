import styled from "styled-components";

export const UserContainer = styled.section`
    padding: 50px 0;
    background: #f8f8f9;
    max-width: 400px;
    height: auto;
    margin: auto;

    .index-usuarios{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .loadmore{
            margin: 30px 0;
            padding: 15px 20px;
        }
    }

    .container-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
            margin-left: 0px;
        }

        .salvar {
            width: 100%;
            max-width: 400px;
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            
            a {
                width: 35%;
                text-decoration: none;
            }

            .salvado {
                width: 60%;
                margin: 30px 0;
                padding: 15px 20px;
            }
        }

        .adicionar{
            width: 100%;
            max-width: 400px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .add{
                width: 146px;
                text-align: center;
                font-family: Lato;
                font-size: 11px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #589bff;
                cursor: pointer;
                position: absolute;
                margin-top: 15px;
                margin-left: 85px;
                .click{
                    position: absolute;
                    margin-left: 240px;
                    margin-top: 35px;
                    width: 80px;
                }
            }
        }

    }

    .search{
        display: flex;
        flex-direction: row;
        margin-left: 190px;
        margin-top: -80px;
        margin-bottom: 20px;
        
        a {
            margin-left: 90px;
            margin-top: -12px;
            text-decoration: none;
        
            button {
                border-radius: 30px;
                border: solid 3px #FFF;
                margin: 15px 0 30px;
                padding: 15px 30px;
            }
        }



        .add{
            margin-left: 90px;
            margin-top: -10px;
            display: flex;
            align-items: center;
            z-index: 2;

        }
    }
    .search-select{
        width: 500px;
        display: flex;
        flex-direction: row;
        margin-top: -110px;

        .add{
            margin-left: 90px;
            margin-top: 8px;
  
        }

    }

    @media only screen and (max-width: 991px){
        .container-center{
            .salvar{
                flex-direction: column;


                a {
                    width: 100%;
                    margin: auto;
                    margin-bottom: 10px;
                }

                .salvado {
                    width: 100%;
                    margin: auto;
                    padding: 15px 20px;
                }


                button{
                    width: 100%
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
    .search {
            display: flex;
            flex-direction: row;
            margin-left: 0;
            margin-top: -80px;
            align-items: center;
        }

        a {
            padding: 10px;
            width: 100%;
            max-width: 500px;
            margin-bottom: 10px;
        }
    
        .container-center{

            .adicionar{
                width: 100%;
                max-width: 400px;
                display: flex;
                flex-direction: row;
                .add{
                    width: 146px;
                    text-align: center;
                    margin-top: auto;
                    padding-bottom: 22px;
                    margin-left: 10px;
                    font-family: Lato;
                    font-size: 11px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #589bff;
                    cursor: pointer;
                    position: absolute;
                    margin-top: 17px;
                    margin-left: 160px;
                    .click{ 
                        position: absolute;
                        margin-top: 0px;
                        margin-left: -30px;
                        width: 210px;
                    }
                }
            }
        }
    }
    


`;

export const UserIndexContainer = styled.section`
    padding: 50px 0;
    background: #f8f8f9;
    width: 100%;
    height: auto;
    margin: auto;

    .index-usuarios{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .loadmore{
            margin: 30px 0;
            padding: 15px 20px;
        }
    }

    .container-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        button {
            margin-left: 0px;
        }

        .salvar {
            width: 100%;
            max-width: 400px;
            margin-top: 20px;
            display: flex;
            justify-content: space-around;
            
            a {
                width: 35%;
                text-decoration: none;
            }

            .salvado {
                width: 60%;
                margin: 30px 0;
                padding: 15px 20px;
            }
        }

        .adicionar{
            width: 100%;
            max-width: 400px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            .add{
                width: 146px;
                text-align: center;
                font-family: Lato;
                font-size: 11px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #589bff;
                cursor: pointer;
                position: absolute;
                margin-top: 15px;
                margin-left: 85px;
                .click{
                    position: absolute;
                    margin-left: 240px;
                    margin-top: 35px;
                    width: 80px;
                }
            }
        }

    }

    .search{
        display: flex;
        flex-direction: row;
        margin-left: 190px;
        margin-top: -80px;
        margin-bottom: 20px;
        
        a {
            margin-left: 90px;
            margin-top: -12px;
            text-decoration: none;
        
            button {
                border-radius: 30px;
                border: solid 3px #FFF;
                margin: 15px 0 30px;
                padding: 15px 30px;
            }
        }



        .add{
            margin-left: 90px;
            margin-top: -10px;
            display: flex;
            align-items: center;
            z-index: 2;

        }
    }
    .search-select{
        width: 500px;
        display: flex;
        flex-direction: row;
        margin-top: -110px;

        .add{
            margin-left: 90px;
            margin-top: 8px;
  
        }

    }

    @media only screen and (max-width: 991px){
        .container-center{
            .salvar{
                flex-direction: column;


                a {
                    width: 100%;
                    margin: auto;
                    margin-bottom: 10px;
                }

                .salvado {
                    width: 100%;
                    margin: auto;
                    padding: 15px 20px;
                }


                button{
                    width: 100%
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
    .search {
            display: flex;
            flex-direction: row;
            margin-left: 0;
            margin-top: 30px;
            align-items: center;
        }

        a {
            padding: 10px;
            width: 100%;
            max-width: 500px;
            margin-bottom: 10px;
        }
    
        .container-center{

            .adicionar{
                width: 100%;
                max-width: 400px;
                display: flex;
                flex-direction: row;
                .add{
                    width: 146px;
                    text-align: center;
                    margin-top: auto;
                    padding-bottom: 22px;
                    margin-left: 10px;
                    font-family: Lato;
                    font-size: 11px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #589bff;
                    cursor: pointer;
                    position: absolute;
                    margin-top: 17px;
                    margin-left: 160px;
                    .click{ 
                        position: absolute;
                        margin-top: 0px;
                        margin-left: -30px;
                        width: 210px;
                    }
                }
            }
        }
    }

`;
