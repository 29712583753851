import styled from "styled-components";

export const FormContainer = styled.div`
    width: 90%;
    max-width: 900px;
    height: auto;
    margin: auto;

    form {
        background-color: #FFF;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-around;
        padding-top: 20px;
        border-radius: 10px;
        box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
        margin-top: 8px;

        .selectValue {
            width: 30%;
            height: auto;
        }
    }


    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: auto;

        label {
            width: 338px;
            margin-bottom: 50px;
            font-family: Lato;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: rgb(186, 193, 207);
        }

    }

    @media only screen and (max-width: 870px) {
        
        form {
            flex-direction: column;
            align-items: center;
            margin-top: 0px;
            .selectValue {
                width: 90%;
            }
        }
    }

`;

export const FormContainerCompendioGratis = styled.div`


padding: 50px 0 0 0;
background: #f8f8f9;

div {
    form {
        margin: auto;
        margin-top: -100px;
        background-color: #FFF;
        height: ${props => (props.isOpen ? "400px" : "100px")};
        border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
        width: 900px;
        margin-left: ${props => (props.isMobile ? "-85px" : "")};
        display: flex;
        flex-direction: row;
        justify-content: ${props => (props.isMobile ? "space-between" : "center")};

        .css-26l3qy-menu{
            button{
                width: 50px;
                height: 20px;
                border-radius: 10px;
                border: solid 1.3px #00b43f;
                font-family: Lato;
                font-size: 8px;
                font-weight: 900;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #00b43f;
                transition: all 0.3s;
                position: absolute;
                right: 15px;
                margin-top: -15px;
    
                &:hover{
                    background-color: #00b43f;
                    color: #FFF;
                }
            }
        }

        .botaolabel {
            width: 100%;
            max-width: 400px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-direction: row-reverse;

            label {
                margin-top: 25px;
            }
        }

        

        .css-1uccc91-singleValue{
            a{
                display: none;
            }
            label{
                display: none;
            }
            p{
                margin-top: 15px;
            }
        }
        
        .css-z2bfxu-control{
            a{
                display: none;
            }
            label{
                display: none;
            }
            p{
                margin-top: 15px;
            }
        }

        .click{
            margin-left: 10px;
            margin-right: 10px;
            margin-top: 10px;
            height: 50px;
            border-radius: 25px;
            
            .sc-TOsTZ {
                margin: 0px;
                padding: 0px;
                margin-top: -15px;
                width: ${props => (props.isMobile ? "50px" : "")};

            }

        }
    }

    .boxClear{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        label{
            color: #589BFF;
            text-align: right;
            margin-right: 120px;

            &:hover{
                cursor: pointer;
            }
        }
    }
    .info{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 7%;
        z-index: -1;
        
        .text{
            width: 338px;
            height: 68px;
            margin: auto;
            font-family: Lato;
            font-size: 28px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #bac1cf;
            margin-bottom: 50px;
            left: 50%;
        }

        .image-search{
        }
    }
    @media only screen and (max-width: 768px) {
        .info{
            margin-top: 90px !important;
        }
    }
}

@media only screen and (max-width: 1105px) {
    div{
        form {
            
        }
    }
}

@media only screen and (max-width: 768px) {
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 50%;
            height: auto;

            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                margin-bottom: 20px;
                height: 50px;
                border-radius: 25px;

                .css-26l3qy-menu{
                    width: 270px;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    left: 35px;
                    padding-top: 0px;
                    margin-top: -15px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 20px;
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 50%;
            }

            .image-search{
            }
        }
    }

    
}

@media only screen and (max-width: 766px) {
    div{
        form {
            width: 60%;
        }
    }

}

@media only screen and (max-width: 640px) {
 
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 90%;
            height: 300px;

            #cultura{
                &:focus{
                    z-index: 2;
                }
            }

            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                height: 50px;
                border-radius: 25px;

                .css-26l3qy-menu{
                    width: 270px;
                    background-color: #f3f3f5;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    padding-top: 0px;
                    margin-top: -15px;
                    margin-left: 30px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 50%;
            }

            .image-search{
            }
        }
    }

}


@media only screen and (max-width: 550px) {
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 90%;
            height: 300px;

            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                height: 50px;
                border-radius: 25px;

                .css-26l3qy-menu{
                    width: 270px;
                    background-color: #f3f3f5;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    padding-top: 0px;
                    margin-top: -15px;
                    margin-left: 30px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 50%;
            }

            .image-search{
            }
        }
    }

}

@media only screen and (max-width: 411px) {
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 90%;
            height: 300px;

            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                height: 50px;
                border-radius: 25px;

                .css-26l3qy-menu{
                    width: 270px;
                    background-color: #f3f3f5;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    padding-top: 0px;
                    margin-top: -15px;
                    margin-left: -10px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 50%;
            }

            .image-search{
            }
        }
    }

}
@media only screen and (max-width: 375px) {
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 90%;
            height: 300px;

            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                height: 50px;
                border-radius: 25px;

                .css-26l3qy-menu{
                    width: 270px;
                    background-color: #f3f3f5;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    padding-top: 0px;
                    margin-top: -15px;
                    margin-left: -25px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 50%;
            }

            .image-search{
            }
        }
    }

}

@media only screen and (max-width: 360px) {
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 90%;
            height: 300px;

            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                height: 50px;
                border-radius: 25px;

                .css-26l3qy-menu{
                    width: 270px;
                    background-color: #f3f3f5;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    padding-top: 0px;
                    margin-top: -15px;
                    margin-left: -32px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 18px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 50%;
            }

            .image-search{
            }
        }
    }

}

@media only screen and (max-width: 350px) {
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 90%;
            height: 300px;

            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                height: 50px;
                border-radius: 25px;

                .css-27im3b-control{
                    width: 242px;
                }
                .css-qi07vp-control {
                    width: 242px;
                }

                .css-26l3qy-menu{
                    width: 270px;
                    background-color: #f3f3f5;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    padding-top: 0px;
                    margin-top: -15px;
                    margin-left: 30px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 20%;
            }

            .image-search{
            }
        }
    }
}

@media only screen and (max-width: 320px) {
    div {
        form {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: -100px;
            background-color: #FFF;
            padding-bottom: 45px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
            width: 90%;
            height: 300px;



            .click{
                margin-left: 10px;
                margin-right: 10px;
                margin-top: 8px;
                height: 50px;
                border-radius: 25px;

        
                .css-27im3b-control{
                    width: 242px;
                }
                .css-qi07vp-control {
                    width: 242px;
                }

                .css-1g36vmg-control {
                    width: 242px;
                }

                .css-26l3qy-menu{
                    width: 242px;
                    background-color: #f3f3f5;
                    border-color: rgba(255, 255, 255, 0);
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
                    position: absolute;
                    padding-top: 0px;
                    margin-top: -15px;
                    margin-left: -35px;
                    z-index: 3;
                    border-bottom-right-radius: 20px;
                    border-bottom-left-radius: 20px;
                }
                
                .sc-TOsTZ {
                    margin: 0px;
                    padding: 0px;
                    margin-top: -15px;
                    margin-left: 10px;
                    z-index: 3;
                }
            }
        }

        .info{

            
            z-index: -1;
            
            .text{
                width: 338px;
                height: 68px;
                margin: auto;
                font-family: Lato;
                font-size: 14px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-bottom: 50px;
                left: 4%;
                margin-left: -23px;
            }

            .image-search{
            }
        }
    }
}



`;

export const HeaderCompendioGratis = styled.div`
    background-color: rgb(41, 46, 56);
    background-size: cover;
    background-position: 0px -20px;
    width: 100vw;
    height: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: start;

    .header{
        width: 90%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo{
            img{
                width: 150px;
            }
        }

        .links{
            margin-left: 17px;
            a{
                font-family: "Lato", Sans-serif;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-left: 60px;
                fill: #fff;
                color: #fff;
                background-color: #fff0;
                border-radius: 50px 50px 50px 50px;
                padding: 10px 12px 10px 12px;
                text-decoration: none;
                text-align: auto;
                transition: all 0.3s;
            }

            a:hover{
                background-color: #FFFFFF2B;
                
            }
        }

        .planos{
            #plans{
                font-family: "Lato", Sans-serif;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                fill: #fff;
                color: #fff;
                background-color: #fff0;
                border-style: solid;
                border-width: 2px 2px 2px 2px;
                border-color: #fff;
                border-radius: 50px 50px 50px 50px;
                padding: 14px 14px 14px 14px;
                text-decoration: none;
                transition: all 0.3s;

                &:hover{
                    color: #21262F;
                    background-color: #fff;
                    border-color: #fff;
                }
            }

            #teste{
                display: inline-block;
                font-family: "Lato", Sans-serif;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                background-color: #2FBB69;
                border-style: solid;
                border-color: #2FBB69;
                border-radius: 50px 50px 50px 50px;
                padding: 17px 22px 17px 22px;
                text-decoration: none;
                transition: all 0.3s;
                color: #FFF;

                margin-left: 20px;

                &:hover{
                    transform: scale(1.1);
                }

            }
        }
    }

    .title{
        width: 100%;
        h1{
            color: #FFF;
            text-align: center;
            font-family: "Lato", Sans-serif;
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
        }
        h2{
            text-align: center;
            color: #BAC1CF;
            font-family: "Lato", Sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 29px;
        }
    }

    @media only screen and (max-width: 768px) {
        height: 15%;

        .logo{
            img{
                width: 110px !important;
            }
        }

        .header{
            margin-bottom: 10px;

            .links{
                margin-left: 40px;

                #help{
                    display: none;
                }
            }

            .planos{
                #teste{
                    position: fixed;
                    left: -5px;
                    bottom: 25px;
                    z-index: 10;
                    padding: 10px 30px 10px 30px;

                }

                #plans{
                    font-family: "Lato", Sans-serif;
                    font-size: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    fill: #fff;
                    color: #fff;
                    background-color: #fff0;
                    border-radius: 50px 50px 50px 50px;
                    border: 0px;
                    padding: 10px 12px 10px 12px;
                    text-decoration: none;
                    text-align: auto;
                    transition: all 0.3s;
                    &:hover{
                        background-color: #FFFFFF2B;
                    }
                }

            }

        }

        .title{
            margin-top: 35px;
        }
    }

`;

export const InfoCompendioGratis = styled.div`
    background: #fff;
    width: 100%;
    height: auto;
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 25px;
    padding: 5% 0% 0% 9%;

    .box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        .infoCompendio{
            width: 40%;
            align-content: center;
            align-items: center;
            .head{
                display: flex;
                flex-direction: column;
                justify-content: left;
                img{
                    width: 20px;
                    height: 20px;
                    margin-top: 50px;
                    margin-bottom: 25px;
                }
                h3{
                    color: #21262F;
                    font-family: "Lato", Sans-serif;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 29px;
                    margin-bottom: 40px;
                } 
            }
            .row{
                display: flex;
                flex-direction: row;
                margin-bottom: 40px;
                padding-left: 18px;

                .itemCompendio{
                    width: 25%;
                    margin: 0px 30px 0px 0px;
                    color: #21262F;
                    font-family: "Lato", Sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                }
            }
        }   
        .img{
            img{
                width: 670px;
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .infoCompendio{
            width: 100% !important;
            .row{
                width: 100%;

                .itemCompendio{
                    width: 22% !important;
                }
            }
        }
        .img{
            img{
                width: 100% !important;
            }
        }

    }
`;

export const Footer = styled.div`
    width: 100%;
    height: 506px;
    background-color: #21262f;

    .section1 {
        width: 100%;
        height: 463px;
        background-color: #21262f;
        
        .row {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-right: 130px;
            padding-left: 130px;
            position: relative;

            .column {
                width: 25%;
                display: flex;
                flex-direction: column;
                justify-content: left;
                flex-wrap: wrap;
                padding: 10px;
                margin-top: 48px;

                h2 {
                    color: #fff;
                    font-family: "Lato", Sans-serif;
                    font-size: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1.5em;
                    letter-spacing: 1px;
                    margin-bottom: 24px;

                    a {
                        color: #fff;
                        ont-family: "Lato", Sans-serif;
                        font-size: 10px;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 1.5em;
                        letter-spacing: 1px;
                        margin-bottom: 24px;
                        text-decoration: none;
                    }
                }

                p {
                    color: #8A92A2;
                    font-family: "Lato", Sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 2em;
                    
                    a {
                        color: #8a92a2;
                        box-sizing: border-box;
                        font-family: "Lato", Sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 2em;
                        text-decoration: none;
                    }
                }


                span {
                    color: #8a92a2;
                    box-sizing: border-box;
                    font-family: "Lato", Sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 2em;
                    text-decoration: none;

                    a {
                        color: #589BFF;
                        font-family: "Lato", Sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 2em;
                    }
                }

                .social-icons-wrapper{
                    display: flex;
                    flex-direction: row;

                    .item{
                        width: 53px;
                        i {
                            font-size: 24px;
                            color: #fff;

                        }
                        .instagram{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                        .facebook{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                        .linkedin{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                    }
                }
            }

            .col {
                width: 50%;
                display: flex;
                flex-direction: row;

                p {
                    color: #8a92a2;
                    font-family: "Lato", Sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5em;
                    margin-left: -5px;

                    a {
                        color: #8a92a2;
                        font-family: "Lato", Sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.5em;
                        text-decoration: none;
                    }
                }
                .linha {
                    color: #8a92a2;
                    font-family: "Lato", Sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5em;
                    margin-left: 5px;
                    margin-right: 10px;
                }
            }
        }
    }

    .section2 {
        width: 100%;
        height: 43px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
            color: #21262F;
            font-family: "Lato", Sans-serif;
            font-size: 12px;
            font-weight: 400;
            margin-right: 10px;
            margin-bottom: 0px;
        }
        a {
            img {
                width: 100px;
                height: 13px;
                margin-left: 10px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        height: auto;

        .section1 {
            height: auto;

            .row {
                padding-right: 10px;
                padding-left: 10px;
                flex-direction: column;

                .column {
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 30px;
                }

                .col {
                    width: 100%;
                    justify-content: center;
                    
                    p {
                        text-align: center !important; 
                        justify-content: center;
                        margin: auto
                    }
                }
            }
        }

        .section2 {
            height: 300px;
            flex-direction: column;
            p {
                margin-top: -170px;
            }
            a {
                img {
                    margin-left: -10px;
                }
            }
        }
    }
`;
