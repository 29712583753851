import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import GenericUpper from '../../components/generic-upper';
import api from '../../services/api';

import Input from '../../components/input/input_v2';
import Select from '../../components/select/select_v2';
import Button from '../../components/button/button_v2';
import ModalGeneric from '../../components/modal/modalGeneric';

import { store } from "react-notifications-component";

import {
    FaturamentoContainer, Label, Page, Pages
} from './styles';
import buscaCep from '../../services/buscaCep';
import estados from '../../services/estados';

const Faturamento = ({...props}) => {

    //SATES CONTATO
    const [contatoId, setContatoId] = useState('');

    const [nome, setNome] = useState('');
    const [nomeErro, setNomeErro] = useState(false);
    const [nomeErroMsg, setNomeErroMsg] = useState('');

    const [email, setEmail] = useState('');
    const [emailErro, setEmailErro] = useState(false);
    const [emailErroMsg, setEmailErroMsg] = useState('');

    const [cargo, setCargo] = useState('');
    const [cargoErro, setCargoErro] = useState(false);
    const [cargoErroMsg, setCargoErroMsg] = useState('');

    const [contact_area_id, setContactAreaId] = useState('');
    const [erroDepartamento, setErroDepartamento] = useState(false);
    const [erroDepartamentoMsg, setErroDepartamentoMsg] = useState('');
    const [departamentos, setDepartamentos] = useState([]);

    const [telefone, setTelefone] = useState('');
    const [telefoneErro, setTelefoneErro] = useState(false);
    const [telefoneErroMsg, setTelefoneErroMsg] = useState('');

    const [ramal, setRamal] = useState('');
    const [ramalErro, setRamalErro] = useState(false);
    const [ramalErroMsg, setRamalErroMsg] = useState('');

    const [celular, setCelular] = useState('');
    const [celularErro, setCelularErro] = useState(false);
    const [celularErroMsg, setCelularErroMsg] = useState('');

    const [loadingFaturamento, setLoadingFaturamento] = useState(true);
    const [loadingDepartamento, setLoadingDepartamento] = useState(true);

    const [loadingSave, setLoadingSave] = useState(false);
    const [disabledSave, setDisabledSave] = useState(true);

    const [showModalErro, setShowModalErro] = useState(false);

    const [showContato, setShowContato] = useState(true);

    //STATE COMPANY
    const [company_category_id, setCompanyCategory] = useState('');
    const [companyCategoryErro, setCompanyCategoryErro] = useState(false);
    const [companyCategoryErroMsg, setCompanyCategoryErroMsg] = useState('');
    const [companyCategories, setCompanyCategories] = useState([]);
    const [loadingCategorias, setLoadingCategorias] = useState(false);

    const [razao_social, setRazaoSocial] = useState('');
    const [razaoSocialErro, setRazaoSocialErro] = useState(false);
    const [razaoSocialErroMsg, setRazaoSocialErroMsg] = useState('');
    
    const [nome_fantasia, setNomeFantasia] = useState('');
    const [nomeFantasiaErro, setNomeFantasiaErro] = useState(false);
    const [nomeFantasiaErroMsg, setNomeFantasiaErroMsg] = useState('');

    const [cnpj, setCnpj] = useState('');
    const [CnpjErro, setCnpjErro] = useState(false);
    const [CnpjErroMsg, setCnpjErroMsg] = useState('');
    
    const [telefone1, setTelefone1] = useState('');
    const [Telefone1Erro, setTelefone1Erro] = useState(false);
    const [Telefone1ErroMsg, setTelefone1ErroMsg] = useState('');
    
    const [telefone2, setTelefone2] = useState('');
    const [Telefone2Erro, setTelefone2Erro] = useState(false);
    const [Telefone2ErroMsg, setTelefone2ErroMsg] = useState('');

    const [cep, setCep] = useState('');
    const [CepErro, setCepErro] = useState(false);
    const [CepErroMsg, setCepErroMsg] = useState('');
    const [loadingCep, setLoadingCep] = useState(false);
    
    const [endereco, setEndereco] = useState('');
    const [EnderecoErro, setEnderecoErro] = useState(false);
    const [EnderecoErroMsg, setEnderecoErroMsg] = useState('');
    
    const [numero, setNumero] = useState('');
    const [NumeroErro, setNumeroErro] = useState(false);
    const [NumeroErroMsg, setNumeroErroMsg] = useState('');
    
    const [bairro, setBairro] = useState('');
    const [BairroErro, setBairroErro] = useState(false);
    const [BairroErroMsg, setBairroErroMsg] = useState('');
    
    const [complemento, setComplemento] = useState('');
    const [ComplementoErro] = useState(false);
    const [ComplementoErroMsg] = useState('');
    
    const [cidade, setCidade] = useState('');
    const [CidadeErro, setCidadeErro] = useState(false);
    const [CidadeErroMsg, setCidadeErroMsg] = useState('');
    
    const [estado, setEstado] = useState('');
    const [EstadoErro, setEstadoErro] = useState(false);
    const [EstadoErroMsg, setEstadoErroMsg] = useState('');
    
    const [ie, setIe] = useState('');
    const [IeErro, setIeErro] = useState(false);
    const [IeErroMsg, setIeErroMsg] = useState('');

    const [disabledSaveCompany, setDisabledSaveCompany] = useState(true);

    const getUsersFaturamento = async () => {
        setLoadingFaturamento(true);
        try {
            const response = await api.get('/contato/faturamento');

            let user = response.data.data;

            setContatoId(user.id);

            changeNome(user.nome);
            changeEmail(user.email);
            changeCargo(user.cargo);
            changeDepartamento(user.contact_area_id);
            changeTelefone(user.telefone);
            changeRamal(user.ramal);
            changeCelular(user.celular);

            setLoadingFaturamento(false);

        } catch (error) {
            setLoadingFaturamento(false);
            setShowModalErro(true);
        }
    }

    const getCompanyFaturamento = async () => {
        try {
            const response = await api.get('/company/faturamento');

            if (response) {
                let company = response.data.data;
    
                changeRazaoSocial(company.razao_social);
                changeNomeFantasia(company.nome_fantasia);
                changeCategoria(company.company_category_id);
                changeCnpj(company.cnpj);
                changeTelefone1(company.telefone1);
                changeTelefone2(company.telefone2);
                changeCep(company.cep);
                changeEndereco(company.endereco);
                changeNumero(company.numero);
                changeComplemento(company.complemento);
                changeBairro(company.bairro);
                changeCidade(company.cidade);
                changeEstado(company.estado);
                changeIe(company.ie);
            }

        } catch (error) {
            
        }
    }

    const fetchCep = async (cep) => {
        try {
            if (showContato === false) {
                setLoadingCep(true);
    
                const c = cep.replace('.', '');
                const cepResponse = await buscaCep(c);
    
                const { logradouro, ibge, bairro, localidade, uf } = cepResponse;
        
                changeEndereco(logradouro);
                changeBairro(bairro);
                changeCidade(localidade);
                changeEstado(uf);
    
                setLoadingCep(false);         
            }
        } catch (err) {
            setLoadingCep(false);      
            setCepErro(true);   
            setCepErroMsg(err.message);

            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const formatDepartamentos = async (departamentos) => {
        departamentos = await departamentos.map(dep => {
            const format = {
                value: dep.id,
                label: `${dep.nome}`
            };

            return format;
        });

        setDepartamentos(departamentos);
    }

    const getDepartamentos = async () => {
        setLoadingDepartamento(true);
        try {
            const response = await api.get(`/info/departamentos`);
            let departamentos = response.data.data;
    
            await formatDepartamentos(departamentos);

            setLoadingDepartamento(false);
            
        } catch (error) {
            setLoadingDepartamento(false);
        }
    }

    const formatCategorias = async (categorias) => {
        categorias = await categorias.map(c => {
            const format = {
                value: c.id,
                label: `${c.nome}`
            };

            return format;
        });

        setCompanyCategories(categorias);
    }

    const getCategorias = async () => {
        setLoadingCategorias(true);
        try {
            const response = await api.get(`/info/categorias`);
            let categorias = response.data.data;

            await formatCategorias(categorias);
            setLoadingCategorias(false);
        } catch (error) {
            setLoadingCategorias(false);
        }
    }

    const saveFaturamento = async () => {
        setLoadingSave(true);

        try {
            if (contatoId) {
                const payload = {
                    id: contatoId,
                    nome,
                    email,
                    cargo,
                    contact_area_id: contact_area_id.value || contact_area_id,
                    telefone, 
                    ramal,
                    celular
                }

                const response = await api.put(`/contato/faturamento`, payload);

                if (response) {
                    store.addNotification({
                        title: "Sucesso!",
                        message: 'Dado de faturamento atualizado com sucesso!',
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            } else {
                //POST
                const payload = {
                    nome,
                    email,
                    cargo,
                    contact_area_id: contact_area_id.value || contact_area_id,
                    telefone, 
                    ramal,
                    celular
                }

                const response = await api.post(`/contato/faturamento`, payload);
                if (response) {
                    store.addNotification({
                        title: "Sucesso!",
                        message: 'Dado de faturamento cadastrado com sucesso!',
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }

            setLoadingSave(false);

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: error.response.data.errors ? `${error.response.data.errors}` : 'Tente novamente mais tarde',
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    }

    const saveCompany = async () => {
        setLoadingSave(true);

        const payload = {
            razao_social, 
            nome_fantasia, 
            company_category_id: company_category_id.value || company_category_id, 
            cnpj, 
            telefone1, 
            telefone2, 
            cep, 
            endereco, 
            numero, 
            complemento,
            bairro, 
            cidade, 
            estado: estado.value || estado, 
            ie
        }

        try {
            const response = await api.put('/company/faturamento', payload);

            if (response) {
                store.addNotification({
                    title: "Sucesso!",
                    message: 'Dado de faturamento atualizado com sucesso!',
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: error.response.data.errors ? `${error.response.data.errors}` : 'Tente novamente mais tarde',
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }

        setLoadingSave(false);
    }

    const changeNome = (e) => {
        setNome(e);

        if (e) {
            setNomeErro(false);
            setNomeErroMsg('');
        } else {
            setNomeErro(true);
            setNomeErroMsg('O campo não pode estar em branco!');
        }
    } 
    
    const changeEmail = (e) => {
        setEmail(e);

        if (e) {
            setEmailErro(false);
            setEmailErroMsg('');
        } else {
            setEmailErro(true);
            setEmailErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeCargo = (e) => {
        setCargo(e);

        if (e) {
            setCargoErro(false);
            setCargoErroMsg('');
        } else {
            setCargoErro(true);
            setCargoErroMsg('O campo não pode estar em branco!');
        }
    }

    const changeDepartamento = (e) => {
        setContactAreaId(e);
        setErroDepartamento(false);
        setErroDepartamentoMsg('');
    }

    const changeTelefone = (e) => {
        setTelefone(e);

        if (e) {
            setTelefoneErro(false);
            setTelefoneErroMsg('');
        } else {
            setTelefoneErro(true);
            setTelefoneErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeRamal = (e) => {
        setRamal(e);
        setRamalErro(false);
        setRamalErroMsg('');
    }
    
    const changeCelular = (e) => {
        setCelular(e);
        setCelularErro(false);
        setCelularErroMsg('');
    }

    const saveAll = async () => {
        try {
            if (contatoId) {
                const payload = {
                    id: contatoId,
                    nome,
                    email,
                    cargo,
                    contact_area_id: contact_area_id.value || contact_area_id,
                    telefone, 
                    ramal,
                    celular
                }

                const response = await api.put(`/contato/faturamento`, payload);

                const payloadcompany = {
                    razao_social, 
                    nome_fantasia, 
                    company_category_id: company_category_id.value || company_category_id, 
                    cnpj, 
                    telefone1, 
                    telefone2, 
                    cep, 
                    endereco, 
                    numero, 
                    complemento,
                    bairro, 
                    cidade, 
                    estado: estado.value || estado, 
                    ie
                }
        
                const responseCompany = await api.put('/company/faturamento', payloadcompany);

                if (response && responseCompany) {
                    store.addNotification({
                        title: "Sucesso!",
                        message: 'Dado confirmados!',
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    props.ok();
                }
            } else {
                const payload = {
                    nome,
                    email,
                    cargo,
                    contact_area_id: contact_area_id.value || contact_area_id,
                    telefone, 
                    ramal,
                    celular
                }

                const response = await api.post(`/contato/faturamento`, payload);
                if (response) {
                    store.addNotification({
                        title: "Sucesso!",
                        message: 'Dado de faturamento cadastrado com sucesso!',
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }

            setLoadingSave(false);

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: error.response.data.errors ? `${error.response.data.errors}` : 'Tente novamente mais tarde',
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    }

    const validaPropContato = () => {
        if (props.isModal) {
            setShowContato(false);
        } else {
            saveFaturamento();
        }
    }

    const validaPropCompany = () => {
        if (props.isModal) {
            saveAll();
        } else {
            saveCompany();
        }
    }

    //cahnges company
    const changeRazaoSocial = (e) => {
        setRazaoSocial(e);

        if (e) {
            setRazaoSocialErro(false);
            setRazaoSocialErroMsg('');
        } else {
            setRazaoSocialErro(true);
            setRazaoSocialErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeNomeFantasia = (e) => {
        setNomeFantasia(e);

        if (e) {
            setNomeFantasiaErro(false);
            setNomeFantasiaErroMsg('');
        } else {
            setNomeFantasiaErro(true);
            setNomeFantasiaErroMsg('O campo não pode estar em branco!');
        }
    }

    const changeCategoria = (e) => {
        setCompanyCategory(e);
        setCompanyCategoryErro(false);
        setCompanyCategoryErroMsg('');
    }

    const changeCnpj = (e) => {
        setCnpj(e);

        if (e) {
            setCnpjErro(false);
            setCnpjErroMsg('');
        } else {
            setCnpjErro(true);
            setCnpjErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeTelefone1 = (e) => {
        setTelefone1(e);

        if (e) {
            setTelefone1Erro(false);
            setTelefone1ErroMsg('');
        } else {
            setTelefone1Erro(true);
            setTelefone1ErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeTelefone2 = (e) => {
        setTelefone2(e);

        setTelefone2Erro(false);
        setTelefone2ErroMsg('');
    }

    const changeCep = (e) => {
        setCep(e);

        if (e) {
            setCepErro(false);
            setCepErroMsg('');
            fetchCep(e);

        } else {
            setCepErro(true);
            setCepErroMsg('O campo não pode estar em branco!');
        }
    }

    const changeEndereco = (e) => {
        setEndereco(e);

        if (e) {
            setEnderecoErro(false);
            setEnderecoErroMsg('');
        } else {
            setEnderecoErro(false);
            setEnderecoErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeNumero = (e) => {
        setNumero(e);

        if (e) {
            setNumeroErro(false);
            setNumeroErroMsg('');
        } else {
            setNumeroErro(false);
            setNumeroErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeComplemento = (e) => {
        setComplemento(e);
    }

    const changeBairro = (e) => {
        setBairro(e);

        if (e) {
            setBairroErro(false);
            setBairroErroMsg('');
        } else {
            setBairroErro(false);
            setBairroErroMsg('O campo não pode estar em branco!');
        }
    }

    const changeCidade = (e) => {
        setCidade(e);

        if (e) {
            setCidadeErro(false);
            setCidadeErroMsg('');
        } else {
            setCidadeErro(false);
            setCidadeErroMsg('O campo não pode estar em branco!');
        }
    }

    const changeEstado = (e) => {
        setEstado(e);

        if (e) {
            setEstadoErro(false);
            setEstadoErroMsg('');
        } else {
            setEstadoErro(false);
            setEstadoErroMsg('O campo não pode estar em branco!');
        }
    }
    
    const changeIe = (e) => {
        setIe(e);

        if (e) {
            setIeErro(false);
            setIeErroMsg('');
        } else {
            setIeErro(false);
            setIeErroMsg('O campo não pode estar em branco!');
        }
    }
    
    useEffect(() => {
        getDepartamentos();
        getCategorias();
        getUsersFaturamento();
        getCompanyFaturamento();
    },[])

    useEffect(() => {
        if (nome &&email &&cargo &&contact_area_id &&telefone) {
            setDisabledSave(false);
        } else {
            setDisabledSave(true);
        }
    }, [nome, email, cargo, contact_area_id, telefone])

    useEffect(() => {
        if (razao_social && nome_fantasia && company_category_id && cnpj && telefone1 && cep && endereco && numero && bairro && cidade && estado && ie) {
            setDisabledSaveCompany(false)
        } else {
            setDisabledSaveCompany(true)
        }
    }, [razao_social, nome_fantasia, company_category_id, cnpj, telefone1, cep, endereco, numero, bairro, cidade, estado, ie])

    return (
        <>

            {props.isModal ? (null) : (
                <GenericUpper
                    title="Dados de faturamento"
                    subtitle="Gerencie seus dados de faturamento"
                />
            )}

            <FaturamentoContainer>

                <Pages>
                    <Page isActive={showContato} onClick={() => setShowContato(true)}>
                        <Label isActive={showContato}>Contato</Label>
                    </Page>
                    <Page isActive={!showContato} onClick={() => setShowContato(false)}>
                        <Label isActive={!showContato}>Empresa</Label>
                    </Page>
                </Pages>

                {showContato ? (
                    <>
                        <Input
                            id="nome_fat"
                            value={nome}
                            label="Nome"
                            onChange={(e) => changeNome(e)}
                            updateValue={(e) => changeNome(`${e}`)}
                            erro={nomeErro}
                            messageErro={nomeErroMsg}
                            loading={loadingFaturamento}
                            disabled={loadingFaturamento}
                        />
                        
                        <Input
                            id="email_fat"
                            value={email}
                            label="Email"
                            onChange={(e) => changeEmail(e)}
                            updateValue={(e) => changeEmail(`${e}`)}
                            erro={emailErro}
                            messageErro={emailErroMsg}
                            loading={loadingFaturamento}
                            disabled={loadingFaturamento}
                        />
                        
                        <Input
                            id="cargo_fat"
                            value={cargo}
                            label="Cargo"
                            onChange={(e) => changeCargo(e)}
                            updateValue={(e) => changeCargo(`${e}`)}
                            erro={cargoErro}
                            messageErro={cargoErroMsg}
                            loading={loadingFaturamento}
                            disabled={loadingFaturamento}
                        />

                        <Select
                            name="departamnto_fat"
                            className="select"
                            label="Departamento"
                            id="departamnto_fat"
                            value={contact_area_id.value || contact_area_id || ''}
                            options={departamentos}
                            onChange={e => changeDepartamento(e)}
                            loading={loadingDepartamento}
                            disabled={loadingDepartamento}
                            selectUniqueOption={true}
                            erro={erroDepartamento}
                            messageErro={erroDepartamentoMsg}
                        />

                        <Input
                            id="telefone_fat"
                            value={telefone}
                            label="Telefone"
                            name='telefone'
                            onChange={(e) => changeTelefone(e)}
                            updateValue={(e) => changeTelefone(`${e}`)}
                            erro={telefoneErro}
                            messageErro={telefoneErroMsg}
                            loading={loadingFaturamento}
                            disabled={loadingFaturamento}
                        />
                        
                        <Input
                            id="ramal_fat"
                            value={ramal}
                            label="Ramal (Opcional)"
                            onChange={(e) => changeRamal(e)}
                            updateValue={(e) => changeRamal(`${e}`)}
                            erro={ramalErro}
                            messageErro={ramalErroMsg}
                            loading={loadingFaturamento}
                            disabled={loadingFaturamento}
                        />

                        <Input
                            id="celular_fat"
                            value={celular}
                            label="Celular (Opcional)"
                            name='celular'
                            onChange={(e) => changeCelular(e)}
                            updateValue={(e) => changeCelular(`${e}`)}
                            erro={celularErro}
                            messageErro={celularErroMsg}
                            loading={loadingFaturamento}
                            disabled={loadingFaturamento}
                        />

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                            <Button 
                                width='100%'
                                height='50px'
                                value={props.isModal ? 'PROXIMO' : 'SALVAR'}
                                outline={false}
                                disabled={disabledSave}
                                onClick={() => validaPropContato()}
                                loading={loadingSave}
                            />
                        </div>
                    
                    </>   
                ) : (
                    <>
                        <Input
                            id="razao_social_cnpny"
                            value={razao_social}
                            label="Razão social"
                            onChange={(e) => changeRazaoSocial(e)}
                            updateValue={(e) => changeRazaoSocial(`${e}`)}
                            erro={razaoSocialErro}
                            messageErro={razaoSocialErroMsg}
                        />
                        
                        <Input
                            id="nome_fantasia_cnpny"
                            value={nome_fantasia}
                            label="Nome fantasia"
                            onChange={(e) => changeNomeFantasia(e)}
                            updateValue={(e) => changeNomeFantasia(`${e}`)}
                            erro={nomeFantasiaErro}
                            messageErro={nomeFantasiaErroMsg}
                        />

                        <Select
                            name="categoria"
                            id="id_categoria_id"
                            label="Categoria"
                            onChange={e => changeCategoria(e)}
                            options={companyCategories}
                            value={company_category_id ? company_category_id.value : company_category_id}
                            loading={loadingCategorias}
                            disabled={loadingCategorias}
                        />

                        <Input
                            id="cnpj_cnpny"
                            value={cnpj}
                            label="CPF/CNPJ"
                            name='cpfcnpj'
                            onChange={(e) => changeCnpj(e)}
                            updateValue={(e) => changeCnpj(`${e}`)}
                            erro={CnpjErro}
                            messageErro={CnpjErroMsg}
                        />
                        
                        <Input
                            id="telefone1_cnpny"
                            value={telefone1}
                            label="Telefone 1"
                            name='telefone'
                            onChange={(e) => changeTelefone1(e)}
                            updateValue={(e) => changeTelefone1(`${e}`)}
                            erro={Telefone1Erro}
                            messageErro={Telefone1ErroMsg}
                        />
                        
                        <Input
                            id="telefone2_cnpny"
                            value={telefone2}
                            label="Telefone 2 (Opcional)"
                            name='telefone'
                            onChange={(e) => changeTelefone2(e)}
                            updateValue={(e) => changeTelefone2(`${e}`)}
                        />

                        <Input
                            name="cep"
                            label="CEP"
                            id="cep_company"
                            value={cep}
                            onChange={e => changeCep(e)}
                            onBlur={(e) => fetchCep(e.target.value)}
                            inputmode="numeric"
                            loading={loadingCep}
                            disabled={loadingCep}
                            erro={CepErro}
                            messageErro={CepErroMsg}
                        />

                        <Input
                            id="endereco_cnpny"
                            value={endereco}
                            label="Endereço"
                            onChange={(e) => changeEndereco(e)}
                            updateValue={(e) => changeEndereco(`${e}`)}
                            erro={EnderecoErro}
                            messageErro={EnderecoErroMsg}
                            loading={loadingCep}
                            disabled={loadingCep}
                        />
                        <Input
                            id="numero_cnpny"
                            value={numero}
                            label="Número"
                            onChange={(e) => changeNumero(e)}
                            updateValue={(e) => changeNumero(`${e}`)}
                            erro={NumeroErro}
                            messageErro={NumeroErroMsg}
                        />
                        
                        <Input
                            id="complemento_cnpny"
                            value={complemento}
                            label="Complemento (Opcional)"
                            onChange={(e) => changeComplemento(e)}
                            updateValue={(e) => changeComplemento(`${e}`)}
                            erro={ComplementoErro}
                            messageErro={ComplementoErroMsg}
                        />
                        
                        <Input
                            id="bairro_cnpny"
                            value={bairro}
                            label="Bairro"
                            onChange={(e) => changeBairro(e)}
                            updateValue={(e) => changeBairro(`${e}`)}
                            erro={BairroErro}
                            messageErro={BairroErroMsg}
                            loading={loadingCep}
                            disabled={loadingCep}
                        />
                        
                        <Input
                            id="cidade_cnpny"
                            value={cidade}
                            label="Cidade"
                            onChange={(e) => changeCidade(e)}
                            updateValue={(e) => changeCidade(`${e}`)}
                            erro={CidadeErro}
                            messageErro={CidadeErroMsg}
                            loading={loadingCep}
                            disabled={loadingCep}
                        />
                        
                        <Input
                            id="ie_cnpny"
                            value={ie}
                            label="IE"
                            onChange={(e) => changeIe(e)}
                            updateValue={(e) => changeIe(`${e}`)}
                            erro={IeErro}
                            messageErro={IeErroMsg}
                            loading={loadingCep}
                            disabled={loadingCep}
                        />

                        <Select
                            name="estado"
                            id="estado_id"
                            label="Estado"
                            onChange={e => changeEstado(e)}
                            options={estados}
                            value={estado.value || estado}
                            loading={loadingCep}
                            disabled={loadingCep}
                        />

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                            <Button 
                                width='100%'
                                height='50px'
                                value={props.isModal ? 'CONFIRMAR' : 'SALVAR'}
                                outline={false}
                                disabled={disabledSaveCompany}
                                onClick={() => validaPropCompany()}
                                loading={loadingSave}
                            />
                        </div>
                    </>
                )}

                <ModalGeneric
                    show={showModalErro}
                    onHide={() => setShowModalErro(false)}
                    size='lg'
                >
                    <>
                        <h3
                            style={{
                                width: '100%',
                                textAlign: 'center'
                            }}
                        >
                            Ops!
                        </h3>

                        <p style={{ width: '100%', textAlign: 'center' }} >Não foi possível encontrar um contato de faturamento cadastrado!</p>
                        <p style={{ width: '100%', textAlign: 'center' }} >Você pode cadastrar ele por aqui novamente ou entrar em contato com nosso suporte!</p>

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems:'center', marginBottom: '20px' }}>
                            <Button 
                                width='151px'
                                height='48px'
                                value='Entendi'
                                outline={false}
                                disabled={false}
                                onClick={() => setShowModalErro(false)}
                                loading={false}
                            />
                        </div>
                    </>
                </ModalGeneric>
            </FaturamentoContainer>
        </>
    )
}

export default withRouter(Faturamento);
