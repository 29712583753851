import React, { useState, useEffect, useCallback } from 'react';
import { ContainerSuccess, TitleS2, ContainerBackground, Icon, Icon02, Marker, Markers, Content, Text, ImgFundo,Icon03, FundoSt2, Option, BoxTextoFoto, ClickEsquerda, ClickDireita, ImgStep02 } from './styles';
import TelaEmissao from '../../assets/tour/tela_emissao.svg';
import Seta from '../../assets/tour/undraw_arrow.svg';
import tela_agroreceita from '../../assets/tour/telaagroreceita.svg';
import logoBranco from '../../assets/tour/logoIcone_branco.svg';


const Step02 = ({ ...props }) => {

    return (
        <>
            <Content marginLeft={props.steps}>
                <TitleS2>
                    Maior banco de dados para <br /> a emissão do receituário agronômico
                </TitleS2>
                <BoxTextoFoto>
                    <FundoSt2>
                        <Text>
                            <Option>✅ Bulas dos defensivos agrícolas atualizadas em tempo real.</Option>
                            <Option>✅ Consulta fitossanitária dos defensivos registrados no Brasil.</Option>
                            <Option>✅ Emissão do receituário agronômico em até 1 minuto.</Option>
                            <Option>✅ Assinatura digital ou eletrônica.</Option>
                            <Option>✅ Envio de dados às Defesas Agropecuárias.</Option>
                        </Text>
                    </FundoSt2>
                    <ImgStep02>
                        <img src={tela_agroreceita} />
                    </ImgStep02>
                </BoxTextoFoto>
                
            </Content>
        </>
    );
};

export default Step02;
