import styled from "styled-components";

export const ContainerModalEdit = styled.div`
    width: 100%;
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10% 0px 10%;
    height:auto;



    .CardOp{
      width: 130%;
      height: auto;
      padding: 20px;
      margin: 20px 0px 20px 0px;
      border-radius: 10px;
      box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
      background-color: rgb(255, 255, 255);
      border: none;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-box-align: center;
      transition: all 0.2s ease 0s;
      .editCard{
        height: 16px;
        margin: 0px;
        padding: 0px;
        width: 16px;
        display: none;
        flex-direction:row;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        p{
            width: 75px;
            height: 22px;

            display:none;
            position:absolute;
            align-items: center;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            
            margin-top: 80px;
            margin-left: -16px;
            
            padding-top: 2px;
            
            color:#FFF;
            background-color: #1d2129;
            opacity: 0.9;
            
            border-radius: 5px;

            &:before{
                position: absolute;
                background-color:  #1d2129;
                width: 8px;
                height: 8px;
                top: -4px;
                margin-left: 17px;
                content: '';
                transform: rotate(45deg);
            }

        }
        &:hover{
          p{
            display: block;
          }
          svg{
            &:hover{
              transform: scale(1.2);

            }
          }
        }
        &:active{
          svg{
            &:hover{
              transform: scale(0.9);

            }
          }
        }

      }
      &:hover{
        .editCard{
          display: flex;
          cursor: pointer;


        }
      }
    }

    .CardAcao{
      width: 130%;
      height: auto;
      padding: 20px;
      margin: 20px 0px 20px 0px;
      border-radius: 10px;
      box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
      background-color: rgb(255, 255, 255);
      border: none;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-box-align: center;
      transition: all 0.2s ease 0s;
      .editCard{
        height: 16px;
        margin: 0px;
        padding: 0px;
        width: 16px;
        display: none;
        flex-direction:row;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        p{
            width: 75px;
            height: 22px;

            display:none;
            position:absolute;
            align-items: center;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            
            margin-top: 80px;
            margin-left: -16px;
            
            padding-top: 2px;
            
            color:#FFF;
            background-color: #1d2129;
            opacity: 0.9;
            
            border-radius: 5px;

            &:before{
                position: absolute;
                background-color:  #1d2129;
                width: 8px;
                height: 8px;
                top: -4px;
                margin-left: 17px;
                content: '';
                transform: rotate(45deg);
            }

        }
        &:hover{
          p{
            display: block;
          }
          svg{
            &:hover{
              transform: scale(1.2);

            }
          }
        }
        &:active{
          svg{
            &:hover{
              transform: scale(0.9);

            }
          }
        }

      }
      &:hover{
        .editCard{
          display: flex;
          cursor: pointer;


        }
      }
    }

    .headerCard{
      margin-bottom: 10px;
      height: 30px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
    }

    .spaceIcons{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 10%;
    }



    .titleCard{
      font-family: Lato;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: rgb(48, 56, 72);
    }

    .itemsCard{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: left;
      margin-top: -5px;
    }

    .itens{
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      padding: 6px;
    }

    .nenhuma{
      width: 100%;
      margin-top: 10px;
      font-family: Lato;
      font-size: 13px;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      padding: 6px;
    }

    @media only screen and (max-width: 912px) {
        width: 100%;
        padding: 80px 0px 0px 0px;
        height: auto;

        .CardOp{
        width: 100%;
        height: auto;
        padding: 20px;
        margin: 20px 0px 20px 0px;
        border-radius: 10px;
        box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
        background-color: rgb(255, 255, 255);
        border: none;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-box-align: center;
        transition: all 0.2s ease 0s;
        .editCard{
          height: 16px;
          margin: 0px;
          padding: 0px;
          width: 16px;
          display: none;
          flex-direction:row;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          p{
              width: 75px;
              height: 22px;

              display:none;
              position:absolute;
              align-items: center;

              font-size: 11px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              
              margin-top: 80px;
              margin-left: -16px;
              
              padding-top: 2px;
              
              color:#FFF;
              background-color: #1d2129;
              opacity: 0.9;
              
              border-radius: 5px;

              &:before{
                  position: absolute;
                  background-color:  #1d2129;
                  width: 8px;
                  height: 8px;
                  top: -4px;
                  margin-left: 17px;
                  content: '';
                  transform: rotate(45deg);
              }

          }
          &:hover{
            p{
              display: block;
            }
            svg{
              &:hover{
                transform: scale(1.2);

              }
            }
          }
          &:active{
            svg{
              &:hover{
                transform: scale(0.9);

              }
            }
          }

        }
        &:hover{
          .editCard{
            display: flex;
            cursor: pointer;


          }
        }
      }
      .CardAcao{
        width: 100%;
        height: auto;
        padding: 20px;
        margin: 20px 0px 20px 0px;
        border-radius: 10px;
        box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
        background-color: rgb(255, 255, 255);
        border: none;
        display: flex;
        flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-box-align: center;
        transition: all 0.2s ease 0s;
        .editCard{
          height: 16px;
          margin: 0px;
          padding: 0px;
          width: 16px;
          display: none;
          flex-direction:row;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease;
          p{
              width: 75px;
              height: 22px;

              display:none;
              position:absolute;
              align-items: center;

              font-size: 11px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: normal;
              letter-spacing: normal;
              text-align: center;
              
              margin-top: 80px;
              margin-left: -16px;
              
              padding-top: 2px;
              
              color:#FFF;
              background-color: #1d2129;
              opacity: 0.9;
              
              border-radius: 5px;

              &:before{
                  position: absolute;
                  background-color:  #1d2129;
                  width: 8px;
                  height: 8px;
                  top: -4px;
                  margin-left: 17px;
                  content: '';
                  transform: rotate(45deg);
              }

          }
          &:hover{
            p{
              display: block;
            }
            svg{
              &:hover{
                transform: scale(1.2);

              }
            }
          }
          &:active{
            svg{
              &:hover{
                transform: scale(0.9);

              }
            }
          }

        }
        &:hover{
          .editCard{
            display: flex;
            cursor: pointer;


          }
        }
      }
    }

`;

export const YesNoContainerGeral = styled.div`
  width: 100%;
  padding: 8px 8px 7px 0px;
  border-radius: 8px;
  background-color: #f3f3f5;
  margin: 0px 0px 20px 0px;
  margin-right: -1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
  z-index: 1;


  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
    margin-right: 102px;
    margin-left: 9px;
  }

  @media only screen and (max-width: 903px) {
    span{
      margin-right: 130px;
      margin-left: 5px;
    }
  }


`;