import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 400px;
  width: 100%;
  margin: 10px 0;


  .css-26l3qy-menu{
    width: 270px;
    background-color: #f8f8f9;
    border-color: rgba(255, 255, 255, 0);
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 0 20px 0 rgba(29,33,41,0.05);
    position: absolute;
    padding-top: 50px;
    margin-top: -30px;
    z-index: 1;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .css-1ml51p6-MenuList{
    width: 270px;

    border-top-style: solid;
    border-width: 1px;
    border-color: #D3D3D3;
    margin-top: -21px;
    margin-left: -1px;

    ::-webkit-scrollbar-track {
      background-color: #f8f8f5;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #F4F4F4;
    }
    ::-webkit-scrollbar-thumb {
      background: #dad7d7;
    }
    
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  
  .css-yt9ioa-option{
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #303848;
    .linha_options{
      display:flex;
      justify-content: space-between;
      flex-direction: row;
      button{
        display: none;
      }
    }
  }

  // hover
  .css-1n7v3ny-option{
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #303848;
    background-color: #FFF;
    &.btn-option {
      width: 50px;
      height: 20px;
      border-radius: 10px;
      border: solid 1.3px #00b43f;
    }
  }

  // checked
  .css-9gakcf-option{
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    color: #8a92a2;
    background-color: #FFF;

  }


  .select {
    margin: 10px 0 0;
  }

  .css-1uccc91-singleValue{
    .linha_options{
      button{
        display: none;
      }
    }
  }
  
  .linha_options{
    margin-bottom: -15px;
    display:flex;
    justify-content: space-between;
    flex-direction: row;
    button{
      display: flex;
      width: 50px;
      height: 20px;
      font-family: Lato;
      font-size: 8px;
      font-weight: 900;
      margin-left: 2px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #00b43f;
      padding: 5px;
    }
  }
      label{
        margin-top: -10px;
        width: 230px;
        height: 13px;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #bac1cf;
      }

`;
