import React,{ useState, useEffect } from "react";
import api from "../../services/api";
import {
    Container,
    Row,
    Col,
    Hidden,
    ScreenClassRender
} from "react-grid-system";

import ModalFuncaoBloqueada from '../../components/modal/modalFuncaoBloqueada';
import GenericUpper from "../../components/generic-upper";
import Select from "../../components/select";
import Input from "../../components/input";
import Button from "../../components/button";
import { store } from "react-notifications-component";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import InputSearch from "../../components/input-search";
import { Link } from "react-router-dom";
import DotsLoader from "../../components/loader";
import ModalTesteGratisAcabou from '../../components/modal/modalFuncaoBloqueada';

import CardGeral from "../../components/card-geral";
import { UserIndexContainer } from "./styles";

export const USER = "@userinfo";
export const VAL = "@validade";

const Usuario = ({...props}) => {

    const [userInfo, setUserInfo] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [hideButton, setHideButton] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(5);
    const [result, setResult] = useState('');
    const [userr] = useState(JSON.parse(localStorage.getItem(USER)));
    const [val] = useState(JSON.parse(localStorage.getItem(VAL)));
    const [modalTesteGratis, setModalTesteGratis] = useState(false);
    const [modalTesteGratisAcabou, setModalTesteGratisAcabou] = useState(false);

    const getUsers = async () => {
        await setUserInfo(localStorage.getItem(USER)); 
        let user = localStorage.getItem(USER);
        
        try {
            let obj = JSON.parse(user);
            const response = await api.post(`/loja/${obj.agro_subsidiary_id}/users/search?limit=${limit}`);
            await setUsuarios(response.data.data);
            setLoading(false);
            
        } catch (error) {
            setLoading(false);
            setHideButton(true);
            
        }
        
    } 

    const updateValues = async (text, estado, search) => {
        if (search == 1) {
            if (!text) {
                searchData('');
            }else{
                setResult(text);
                searchData(text);
            }
        }else{
            setResult(text);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            searchData(e.target.value);
        }
    }


    const loadMore = async () => {
        setLoading(true);
        setHideButton(true);
        const newOffset = limit + offset;
        try {
            setUserInfo(localStorage.getItem(USER)); 
            let user = localStorage.getItem(USER);
            
            let obj = JSON.parse(user);
            const response = await api.post("/loja/" + obj.agro_subsidiary_id + "/users/search?limit=" + limit + "&offset=" + newOffset);

            let newUsers = response.data.data;
            const UserConCat = usuarios.concat(newUsers);
            
            setUsuarios(UserConCat);
            
            setOffset(newOffset);
            setHideButton(false);

        } catch (erro) {
            setHideButton(true);
        }
        setLoading(false);

    };

    //função para realizar buscas dos campos
    const searchData = async (text) => {
        try {
            if (text.length < 1 && text != '') {
                return;
            }
            let user = localStorage.getItem(USER);
            let obj = JSON.parse(user);
            if (text != '') {
                const response = await api.post(`/loja/${obj.agro_subsidiary_id}/users/search?limit=${limit}`, {search: text});
                setUsuarios(response.data.data);
            }else{
                const response = await api.post(`/loja/${obj.agro_subsidiary_id}/users/search?limit=${limit}`);
                setUsuarios(response.data.data);
            }
            
        } catch (error) {
            setUsuarios([]);
        }
    };


    useEffect(() => {
        getUsers();
    },[])

    useEffect(() => {
        if (usuarios.length == 0) {
            searchData();
        }
    },[])


    return(
        <ScreenClassRender
            render={screenClass => (
                <>
                    <GenericUpper
                        title="Usuários"
                        subtitle="Gerencie seus usuários"
                        search={true}
                        searchFunction={(text, estado, search) => updateValues(text, estado, search)}
                        onKeyDown={(e) => onKeyDown(e)}
                        addButton={true}
                        tooltip={"Adicionar"}
                        placeholder={'E-mail'}
                        linkSearch={'/usuarios/add'}
                    ></GenericUpper>
                    <UserIndexContainer>
                        <Container>

                            {window.screen.width <= 768 ? (<>
                                <div className="search" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                                    <CardGeral
                                        titulos={["USUARIOS",'Email','Perfil de acesso','Loja','Cidade','Empresa','Status']}
                                        valores={usuarios}
                                    />
                                    <DotsLoader show={loading}></DotsLoader>
                                    <Button
                                        style={{outline:0, width: "87%", margin: "auto", marginBottom: "15px", padding: "15px"}}
                                        className="loadmore"
                                        hide={hideButton}
                                        outline
                                        onClick={loadMore}
                                        color="#00b43f"
                                    >
                                        Ver mais
                                    </Button>
                                </div>
                            </>) : (<>
                        <div className="index-usuarios">
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Perfil de acesso</th>
                                        <th>Loja</th>
                                        <th>Cidade</th>
                                        <th>Empresa</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <TBody>
                                    {usuarios.length > 0 ? 
                                        usuarios.map(user => (
                                            <tr
                                                onClick={(e) => {
                                                    if(userr.contract_software_id == 6 && val == 0) {
                                                        setModalTesteGratisAcabou(true)
                                                    }else{
                                                        props.history.push(`usuarios/${user.id}`)
                                                    }
                                                }}
                                                key={user.id}
                                            >
                                                <td>{user.username}</td>

                                                <td>{user.agro_permission.permission}</td>
                                                <td>{user.agro_subsidiary.nome_fantasia}</td>
                                                <td>{user.agro_subsidiary.cidade}</td>
                                                {user.agro_subsidiary.is_matriz == 1? (<td>Matriz</td>):(<td>Filial</td>)}

                                            
                                                {user.status == "AT" ? (<td> <ul className="status-at">ATIVO</ul></td>):(<td> <ul className="status-in">INATIVO</ul></td>)}

                                            </tr>
                                        ))
                                    :(
                                        <tr><td colSpan="100%" className="error">Nenhum resultado encontrado.</td></tr>
                                    )}
                                </TBody>
                            </Table>
                            <DotsLoader show={loading}></DotsLoader>
                        
                            <Button
                             style={{outline:0}} 
                                className="loadmore"
                                hide={hideButton}
                                outline
                                onClick={loadMore}
                                color="#00b43f"
                            >
                                Ver mais
                            </Button>
                        </div>               
                        </>)}
                            <ModalTesteGratisAcabou
                                show={modalTesteGratisAcabou}
                                title="Essa é uma função exclusiva para assinantes..."
                                subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                                onHide={() => {
                                    setModalTesteGratisAcabou(false);
                                    window.location.href="/"
                                }}
                            ></ModalTesteGratisAcabou>

                            <ModalFuncaoBloqueada
                                    show={modalTesteGratis}
                                    onHide={() => {
                                        setModalTesteGratis(false);
                                        window.location.href="/"
                                    }}
                            ></ModalFuncaoBloqueada>
                        </Container>
                    </UserIndexContainer>
                </>
            )}
        />
    )
}

export default Usuario;
