import styled from "styled-components";

export const Card = styled.div`
    background-color: #FFF;
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin: auto;
    margin-left: ${props => (props.isMobile ? "-85px" : "")};
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 10px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);

    .dispensado {
        width: auto;
        height: 25px;
        border-radius: 5px;
        background-color: #f3f3f5;
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        color: #ff5252;
        margin-right: 25px;
        padding: 4px;
        cursor: context-menu;
        box-shadow: 1px 1px 5px 0px rgb(29 33 41 / 40%);
    }

    .Epoca {
        width: 96%;
        margin: auto;

        label{
            font-family: Lato;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #bac1cf;
            /* margin-left: 36px; */
        }
        p{
            /* margin-left: 36px; */
            margin: auto;
            font-family: Lato;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #303848;
            /* width: 830px; */
            width: 100%;
            margin-bottom: 16px;
        }
    }

    .row-card1{
        width: 96%;
        height: 23%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: auto;
        margin-bottom: 40px;
        margin-top: 15px;
        .infoCard{
            height: 100%;
            width: auto;

            h3{
                font-family: Lato;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #303848;
                margin-bottom: -5px;
                /* margin-left: 36px; */
            }

            label{
                font-family: Lato;
                font-size: 11px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #bac1cf;
                /* margin-left: 36px; */

            }

            button{
                width: 113px;
                height: 40px;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                border-radius: 30px;
                border: solid 2px #00b43f;
                background-color: #00b43f;
                color: #FFF;
                cursor: pointer;
                margin-right: 30px;
            }
        }
    }

    .row-card{
        width: 96%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* margin-left: 36px; */
        margin: auto;
        margin-top: -15px;

        .infoCard{
            width: 27%;
            height: 100%;
            margin-bottom: 20px;
        
            label{
                font-family: Lato;
                font-size: 11px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #bac1cf;
            }

            .estados{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                width: auto;
                p{
                    width: 40px;
                    height: 25px;
                    border-radius: 5px;
                    background-color: #f3f3f5;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                    color: #ff5252;
                    margin-right: 15px;
                    padding-top: 4px;
                    margin-bottom: 10px;
                    cursor: context-menu;
                    box-shadow: 1px 1px 5px 0px rgb(29 33 41 / 40%);
                }
                label{
                    font-family: Lato;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #303848;
                }
            }

            p{
                font-family: Lato;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #303848;
                margin: 0px;

                overflow: hidden;
                text-overflow: ellipsis; 
            }
        }
    }

    @media only screen and (max-width: 1065px){
        background-color: #FFF;
        width: auto;
        height: auto;
        border-radius: 10px;
        margin: auto;
        margin-left: 5px;
        margin-top: 15px;
        margin-bottom: 28px;
        display: flex;
        flex-direction: column;
        padding-top: 10px;

        .dispensado {
            width: 90%;
            margin-left: 5%;
        }

        .group-them{
            display: flex;
            flex-direction: column;
            justify-content: space-around;

            .infoCardEpoca {
                margin-left: 8%;

                label{
                    font-family: Lato;
                    font-size: 11px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #bac1cf;
                }

            }

            .colunas{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                
                .row-card-1{
                    margin-left: 0px;
                    display: flex;
                    flex-direction: column;
                
                    width: 50%;
                    height: 100%;
                    margin-bottom: 20px;

            
                    label{
                        font-family: Lato;
                        font-size: 11px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #bac1cf;
                    }

                    p{
                        font-family: Lato;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #303848;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 0px;
                        
                    }

                }
                .row-card-2{
                    margin-right: -47px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;


                    .infoCard{
                        width: 100%;
                        height: 100%;
                        margin-bottom: 20px;
            
                    
                        label{
                            font-family: Lato;
                            font-size: 11px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: #bac1cf;
                        }
            
                        .estados{
                            display: flex;
                            flex-direction: row;
                            flex-wrap: wrap;
                            width: 120;
                            margin-right: 13px;
                            p{
                                width: 40px;
                                height: 25px;
                                border-radius: 5px;
                                background-color: #f3f3f5;
                                font-family: Lato;
                                font-size: 12px;
                                font-weight: bold;
                                text-align: center;
                                color: #ff5252;
                                margin-right: 15px;
                                padding-top: 4px;
                            }
                            label{
                                font-family: Lato;
                                font-size: 14px;
                                font-weight: normal;
                                font-stretch: normal;
                                font-style: normal;
                                line-height: normal;
                                letter-spacing: normal;
                                color: #303848;
                            }
                        }
            
                        p{
                            font-family: Lato;
                            font-size: 14px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: #303848;
                            overflow: hidden;
                            text-overflow: ellipsis; 
                        }
                    }

                }
            }
        }
        

        .infoCard{
            height: 100%;
            width: auto;
            h3{
                font-family: Lato;
                font-size: 24px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #303848;
                margin-bottom: -5px;
                /* margin-left: 36px; */
            }
        }

        button{
            width: 113px;
            height: 40px;
            font-family: Lato;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            border-radius: 30px;
            border: solid 2px #00b43f;
            background-color: #00b43f;
            color: #FFF;
            cursor: pointer;
            margin-right: 30px;
            margin-left: 7%;
            width: 88%;
            margin-bottom: 18px;
        }
    }

    
@media only screen and (max-width: 540px) {
    .group-them{
        .row-card-2{
            .infoCard{
                .estados{
                    width: 150px;
                }
            }
        }
    }
}

`;