import styled from "styled-components";



export const ContainerModal = styled.div`
    width: 100%;
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10% 0px 10%;
    height:auto;

    .acoes{
      width: 120%;
      padding:20px 20px 20px 20px;
      border: solid 1px rgb(186, 193, 207);
      margin-bottom: 30px;
    }

    .historico{
      width: 140%;
      padding:20px 0px 20px 0px;
      border: solid 1px rgb(186, 193, 207);
      margin-bottom: 30px;
    }

    .pTitulo{
      text-align: left;
      width: 100%;
      margin-bottom: 0px;
      color:#8A92A2;
      font-weight: 600;
      font-size: 14px;
    }

    .pTituloAcoes{
      text-align: left;
      width: 100%;
      margin-bottom: 0px;
      margin-left: -95px;
      color:#8A92A2;
      font-weight: 600;
      font-size: 14px;
    }

    .div-button{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 10px;
    }

    .button-finish{
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 80px 0px 0px 0px;
        height: auto;
    }

`;

export const YesNoContainerGeral = styled.div`
  width: 100%;
  padding: 8px 8px 7px 0px;
  border-radius: 8px;
  background-color: #f3f3f5;
  margin: 0px 0px 20px 0px;
  margin-right: -1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
  z-index: 1;


  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
    margin-right: 102px;
    margin-left: 9px;
  }

  @media only screen and (max-width: 903px) {
    span{
      margin-right: 130px;
      margin-left: 5px;
    }
  }


`;


