import styled from "styled-components";

export const FormContainer = styled.div`
background: #f8f8f9;
height: 1920px;
form {
  background: #f8f8f9;

  margin: auto;
  min-width: 250px;
  display: flex;
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 340px;
  height: 60px;
}

margin: 0px;
button {
  padding: 25px;
  margin: 40px 15px 10px 10px;
  width: 340px;
  height: 60px;
}
i {
  margin-left: 15px;
}

#multiselectContainerReact{
  margin: 40px 0 10px 100px;
  ._2OR24XnUXt8OCrysr3G0XI{
    .searchBox{
      margin: 5px 0 30px 10px;
    }      
    span{
      background: #00b43f;
      width: auto;
    }
    background: white;
    width: 400px;
    border-radius: 25px;
  }

}
`;

export const ProdutoresContainer = styled.section`
padding: 50px 0;
background: #f8f8f9;
height: 100%;

.modal-dialog modal-lg modal-dialog-centered{
  display: flex;
  justify-content:center;
}

#botaoformdocs{
  margin-left: 128px;
  margin-top: 24px;
  padding: 10px; 
    button{
      border-radius: 30px;
      border: solid 3px #FFF;
    }   
}

.search-select{
  width: 500px;
  display: flex;
  flex-direction: row;
  margin-top: -110px;

  .add{
    margin-left: 90px;
    margin-top: 8px;
    button{
      border-radius: 30px;
      border: solid 3px #FFF;
    }     

  }

}

.search{
  display: flex;
  flex-direction: row;
  margin-left: 190px;
  margin-top: -80px;
  
  .add{
    margin-left: 90px;
    margin-top: -12px;
    button{
      border-radius: 30px;
      border: solid 3px #FFF;
    }     

  }
}

> div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loadmore {
    margin: 30px 0;
    padding: 15px 20px;
  }
  .limpar{
    display: flex;
    justify-content: space-around;
    .botao{
      margin: 40px 30px 40px 1px;
      padding: 15px 50px;
    }
  .filterbuttons {
    width: 100%;
    max-width: 400px;
    .pesquisar {
      margin: 40px 0;
      padding: 20px 80px;
    }
  }
}
  .relatorio {
    display: flex;
    margin-left: 70%;
    padding: 15px 20px;
  }
  .add {
    align-self: flex-end;
    button {
      margin: 15px 0 30px;
      padding: 20px 30px;
    }
  }
}

@media only screen and (max-width: 768px) {
  padding: 108px 0;
background: #f8f8f9;
height: 100%;

.modal-dialog modal-lg modal-dialog-centered{
  display: flex;
  justify-content:center;
}

#botaoformdocs{
  margin-left: 128px;
  margin-top: 24px;
  padding: 10px; 
    button{
      border-radius: 30px;
      border: solid 3px #FFF;
    }   
}

.ght{
    width:100%;
    max-width: 375px;
    margin-left: 0px;
    margin-top: 12px;
    padding: 15px 20px; 
}

.search-select{
  width: 500px;
  display: flex;
  flex-direction: row;
  margin-top: -110px;

  .add{
    width:100%;
    max-width: 375px;
    margin-left: 0px;
    margin-top: 8px;
    button{
      border-radius: 30px;
      border: solid 3px #FFF;
    }     

  }

}


.search{
  display: flex;
  flex-direction: row;
  margin-left: 190px;
  margin-top: -80px;
  
  .add{
    width:100%;
    max-width: 375px;
    margin-left: 0px;
    margin-top: -12px;
    button{
      border-radius: 30px;
      border: solid 3px #FFF;
    }     

  }
}

> div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .loadmore {
    width:100%;
    max-width: 375px;
    margin-top: -15px;
    padding: 15px 20px;
  }
  .limpar{
    display: flex;
    justify-content: space-around;
    .botao{
      margin: 40px 30px 40px 1px;
      padding: 15px 50px;
    }
  .filterbuttons {
    width: 100%;
    max-width: 400px;
    .pesquisar {
      margin: 40px 0;
      padding: 20px 80px;
    }
  }
}
  .relatorio {
    display: flex;
    margin-left: 70%;
    padding: 15px 20px;
  }
  .add {
    width:100%;
    max-width: 375px;
    margin-left: 0px;
    align-self: center;
    button {
      margin: 15px 0 30px;
      padding: 20px 30px;
    }
  }
}
}
`;

export const ProdutoresAddContainer = styled.div`
padding: 50px 0;
  background: #f8f8f9;
  flex-direction:row;
  
    form {
      margin: auto;
      min-width: 250px;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    button {
       
        margin: 30px 0px;
        padding: 15px 80px;
        .add {
       
          justify-content: flex-end;
        }
        i {
          margin-left: 15px;
        }
      }
    
  
  .salvar {
    width: 100%;
    max-width: 400px;
    display: flex;
    justify-content: space-around;
    .salvado {
      margin: 30px 0;
      padding: 20px 49px;
    }
  }

  .crea{
    justify-content: center;
    padding: 30px;
    align-items: flex-end;
    .new{
      padding: 15px 70px;
      margin: 30px;

    }
  }

  #labelcultura{
    margin: 0px 0px 0px 25px;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a92a2;
  }
  
  #multiselectContainerReact{
    margin: 40px 0 10px 100px;
    ._2OR24XnUXt8OCrysr3G0XI{
      .searchBox{
        margin: 5px 0 30px 10px;
      }      
      span{
        background: #00b43f;
        width: auto;
      }
      background: white;
      width: 400px;
      border-radius: 25px;
    }

}


`;
