import React, {useEffect, useState} from 'react';
import { Item, ListContainer, Value, AddValue, Title } from './style';
import cancelaIcon from "../../assets/icons/icons-8-trash.png"
import api from '../../services/api';
import { store } from "react-notifications-component";

const List = ({ ...props }) => {

    const [values, setValues] = useState([]);
    const [digiteHeader, setDigiteHeader] = useState(false);
    const [digiteValue, setDigiteValue] = useState(false);
    const [headerText, setHeadertext] = useState('');
    const [valueText, setValueText] = useState('');

    const openHeader = () => {
        setDigiteHeader(true);

        setTimeout(() => {
            document.getElementById(`setheader-${props.id}`).focus();
        }, 50);
    }

    const openValue = () => {
        setDigiteValue(true);

        setTimeout(() => {
            document.getElementById(`setvalue-${props.id}`).focus();
        }, 50);
    }

    const changeHeader = (value) => {
        setHeadertext(value);
    }
    
    const changeValue = (value) => {
        setValueText(value);
    }

    const blur = () => {
        if (digiteHeader && digiteValue) {
            if (headerText !== '' && valueText !== '') {
                props.onChange({
                    header: headerText,
                    value: valueText
                });
            }
        }
    }

    const onDelete = async (id, index) => {
        try {

            if (id) {
                const response = await api.delete(`/integracao/inativar/${id}`);
                if (response) {

                    let hd = values;
                    let head = [];

                    hd.map((h, i) => {
                        if (h.header_id !== id && i !== index) {
                            head = [...head, h];
                        }
                    });

                    setValues(head);

                    store.addNotification({
                        title: "Sucesso!",
                        message: `Excluido com sucesso!`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } else {
                let hd = values;
                let head = [];

                hd.map((h, i) => {
                    if (i !== index) {
                        head = [...head, h];
                    }
                });

                setValues(head);

            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.message}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }
    useEffect(() => {
        setValues(props.values);

        setDigiteHeader(false);
        setDigiteValue(false);
        setHeadertext('');
        setValueText('');

    }, [props.values]);
    
    return (
        <ListContainer>

            <Title>Headers</Title>

            {values.map((i, index) => (
                <Item>  
                    {console.log(i)}
                    <Value> <p>{i.header}</p> </Value>
                    <Value> <p>{i.value}</p> </Value>
                    <img onClick={() => onDelete(i.header_id, index)} src={cancelaIcon} />
                </Item>
            ))}

            <Item>
                <AddValue onClick={() => openHeader()}> 
                    {digiteHeader === false ? (
                        <p onClick={() => openHeader()} className='placeholder'>Add header</p>
                    ) : (
                        <input 
                            id={`setheader-${props.id}`}
                            type='text' 
                            placeholder='Add header'
                            onChange={(e) => changeHeader(e.target.value)}
                            value={headerText}
                            onBlur={() => blur()}
                        />
                    )}
                </AddValue>

                <AddValue onClick={() => openValue()}> 
                    {digiteValue === false ? (
                        <p onClick={() => openValue()} className='placeholder'>Add value</p> 
                    ) : (
                        <input 
                            id={`setvalue-${props.id}`}
                            type='text' 
                            placeholder="Add value"
                            onChange={(e) => changeValue(e.target.value)}
                            value={valueText}
                            onBlur={() => blur()}
                        />
                    )}
                </AddValue>
            </Item>
        </ListContainer>
    )
}

export default List;