import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import GenericUpper from "../../components/generic-upper";
import AddProdutor from "../../components/produtores/produtores";
import AddLocalAplicacao from "../../components/produtores/fazendas";
import { FormSlideContainer, SlideForm } from "../../styles/global";


const AddProdutores = ({ ...props }) => {

    const [estado, setEstado] = useState("");
    const [nome, setNome] = useState("");
    const [cpf_cnpj, setCpfCnpj] = useState("");
    const [telefone, setTelefone] = useState("");
    const [celular, setCelular] = useState("");
    const [cep, setCep] = useState("");
    const [logradouro, setLogradouro] = useState("");
    const [bairro, setBairro] = useState("");
    const [numero, setNumero] = useState("");
    const [complemento, setComplemento] = useState("");
    const [cidade, setCidade] = useState("");
    const [produtor, setProdutor] = useState("");
    const [email, setEmail] = useState("");

    const [nomeProdutorCrm, setNomeProdutorCrm] = useState("");
    const [emailProdutorCrm, setEmailProdutorCrm] = useState("");
    const [telefoneProdutorCrm, setTelefoneProdutorCrm] = useState("");
    const [celularProdutorCrm, setCelularProdutorCrm] = useState("");
    const [fazendaNomeCrm, setFazendaNomeCrm] = useState("");

    const [nomeLocal, setNomeLocal] = useState('');
    const [cpfCnpjLocal, setCpfCnpjLocal] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [cepLocal, setCepLocal] = useState('');
    const [logradouroLocal, setLogradouroLocal] = useState('');
    const [bairroLocal, setBairroLocal] = useState('');
    const [numeroLocal, setNumeroLocal] = useState('');
    const [complementoLocal, setComplementoLocal] = useState('');
    const [cidadeLocal, setCidadeLocal] = useState('');
    const [codIbge, setCodIbge] = useState('');
    const [numeroOrgao, setNumeroOrgao] = useState('');
    const [areaTotal, setAreaTotal] = useState('');
    const [cultura, setCultura] = useState('');
    const [cadastroAmbiental, setCadastroAmbiental] = useState('');
    const [estadoLocal, setEstadoLocal] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [enderecoLocal, setEnderecoLocal] = useState('');

    const [title, setTitle] = useState('Cadastre um produtor');
    const [step, setStep] = useState(1);
    const [align] = useState("center");

    const updateProdutorInfo = (
        estado,
        nome,
        cpf_cnpj,
        telefone,
        celular,
        cep,
        logradouro,
        bairro,
        numero,
        complemento,
        cidade,
        produtor,
        email
    ) => {
        setEstado(estado);
        setNome(nome);
        setCpfCnpj(cpf_cnpj);
        setTelefone(telefone);
        setCelular(celular);
        setCep(cep);
        setLogradouro(logradouro);
        setBairro(bairro);
        setNumero(numero);
        setComplemento(complemento);
        setCidade(cidade);
        setProdutor(produtor);
        setEmail(email);
    }


    const updateAddLocalAplicacao = (
        nomeLocal,
        cpfCnpjLocal,
        inscricaoEstadual,
        cepLocal,
        logradouroLocal,
        bairroLocal,
        numeroLocal,
        complementoLocal,
        cidadeLocal,
        codIbge,
        numeroOrgao,
        areaTotal,
        cultura,
        cadastroAmbiental,
        estadoLocal,
        latitude,
        longitude,
        enderecoLocal
    ) => {
        setNomeLocal(nomeLocal);
        setCpfCnpjLocal(cpfCnpjLocal);
        setInscricaoEstadual(inscricaoEstadual);
        setCepLocal(cepLocal);
        setLogradouroLocal(logradouroLocal);
        setBairroLocal(bairroLocal);
        setNumeroLocal(numeroLocal);
        setComplementoLocal(complementoLocal);
        setCidadeLocal(cidadeLocal);
        setCodIbge(codIbge);
        setNumeroOrgao(numeroOrgao);
        setAreaTotal(areaTotal);
        setCultura(cultura);
        setCadastroAmbiental(cadastroAmbiental);
        setEstadoLocal(estadoLocal);
        setLatitude(latitude);
        setLongitude(longitude);
        setEnderecoLocal(enderecoLocal);
    }

    const nextStep = (s) => {
        if (props.isModal && props.update) {
            props.update();
        } else {
            setStep(2);
        }

    }


    useEffect(() => {
        if (step === 2) {
            setTitle('Cadastre um local de aplicação');

            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 500);
        }
    }, [step]);

    useEffect(() => {
        setNomeProdutorCrm(props.nomeOp);
        setEmailProdutorCrm(props.emailOp);
        setTelefoneProdutorCrm(props.telefoneFz);
        setCelularProdutorCrm(props.celularFz);
        setFazendaNomeCrm(props.nomeFz);
    }, [props]);


    return (<>

        {props.isModal === true ? (
            <>
                {props.crm ? (
                    <>
                        {step === 1 ? (<h4 style={{ textAlign: 'center' }}>Finalize o cadastro do produtor</h4>) : (<h4 style={{ textAlign: 'center' }}>Finalize o cadastro do Local de aplicação</h4>)}
                    </>

                ) : (
                    <>
                        {step === 1 ? (<h4 style={{ textAlign: 'center' }}>Cadastre um produtor</h4>) : (<h4 style={{ textAlign: 'center' }}>Cadastre um local de aplicação</h4>)}
                    </>

                )}
            </>
        ) : (
            <GenericUpper
                align={align}
                title={title}
                help={true}
                text={"Dúvidas para cadastrar um produtor ou local de aplicação?"}
                toSearch={'https://agroreceita.com.br/como-cadastrar-produtores-e-locais-de-aplicacao/'}
                textToSearch={'Clique aqui e acesse o tutorial!'}
            ></GenericUpper>
        )}




        {props.isModal === true ? (
            <>
                {step === 1 && (
                    <AddProdutor
                        estado={estado}
                        nome={nome}
                        nomeProdutorCrm={nomeProdutorCrm}
                        emailProdutorCrm={emailProdutorCrm}
                        telefoneProdutorCrm={telefoneProdutorCrm}
                        celularProdutorCrm={celularProdutorCrm}
                        cpf_cnpj={cpf_cnpj}
                        telefone={telefone}
                        celular={celular}
                        cep={cep}
                        logradouro={logradouro}
                        bairro={bairro}
                        numero={numero}
                        complemento={complemento}
                        cidade={cidade}
                        update={updateProdutorInfo}
                        produtor={produtor}
                        crm={props.crm}
                        email={email}
                        next={() => nextStep(2)}
                        // next={() => setStep(2)}
                        isModal={props.isModal}
                        isFocus={step == 1 ? true : false}
                    />
                )}


                {step === 2 && (
                    <AddLocalAplicacao
                        produtor={produtor}
                        estado={estado}
                        nome={nome}
                        cpf_cnpj={cpf_cnpj}
                        telefone={telefone}
                        celular={celular}
                        cep={cep}
                        logradouro={logradouro}
                        bairro={bairro}
                        numero={numero}
                        complemento={complemento}
                        cidade={cidade}
                        nomeLocal={nomeLocal}
                        cpfCnpjLocal={cpfCnpjLocal}
                        inscricaoEstadual={inscricaoEstadual}
                        cepLocal={cepLocal}
                        logradouroLocal={logradouroLocal}
                        bairroLocal={bairroLocal}
                        numeroLocal={numeroLocal}
                        complementoLocal={complementoLocal}
                        cidadeLocal={cidadeLocal}
                        codIbge={codIbge}
                        numeroOrgao={numeroOrgao}
                        areaTotal={areaTotal}
                        cultura={cultura}
                        cadastroAmbiental={cadastroAmbiental}
                        estadoLocal={estadoLocal}
                        enderecoLocal={enderecoLocal}
                        latitude={latitude}
                        longitude={longitude}
                        update={updateAddLocalAplicacao}
                        loc={props}
                        isModal={props.isModal}
                        crm={props.crm}
                        opId={props.opId}
                        fazendaNomeCrm={fazendaNomeCrm}
                        closeModal={props.closeModal}
                        getOp={props.getOp}
                        updateModal={() => props.update()}
                        isFocus={step == 2 ? true : false}
                    />
                )}
            </>
        ) : (
            <SlideForm
                totalForms={2}
                foco={step}
            >
                <FormSlideContainer totalForms={2} isFocus={step == 1 ? true : false}>
                    <AddProdutor
                        estado={estado}
                        nome={nome}
                        nomeProdutorCrm={nomeProdutorCrm}
                        emailProdutorCrm={emailProdutorCrm}
                        telefoneProdutorCrm={telefoneProdutorCrm}
                        celularProdutorCrm={celularProdutorCrm}
                        cpf_cnpj={cpf_cnpj}
                        telefone={telefone}
                        celular={celular}
                        cep={cep}
                        logradouro={logradouro}
                        bairro={bairro}
                        numero={numero}
                        complemento={complemento}
                        cidade={cidade}
                        update={updateProdutorInfo}
                        produtor={produtor}
                        crm={props.crm}
                        email={email}
                        next={() => nextStep(2)}
                        // next={() => setStep(2)}
                        isModal={props.isModal}
                        isFocus={step == 1 ? true : false}
                    />
                </FormSlideContainer>

                <FormSlideContainer totalForms={2} isFocus={step == 2 ? true : false}>
                    <AddLocalAplicacao
                        produtor={produtor}
                        estado={estado}
                        nome={nome}
                        cpf_cnpj={cpf_cnpj}
                        telefone={telefone}
                        celular={celular}
                        cep={cep}
                        logradouro={logradouro}
                        bairro={bairro}
                        numero={numero}
                        complemento={complemento}
                        cidade={cidade}
                        nomeLocal={nomeLocal}
                        cpfCnpjLocal={cpfCnpjLocal}
                        inscricaoEstadual={inscricaoEstadual}
                        cepLocal={cepLocal}
                        logradouroLocal={logradouroLocal}
                        bairroLocal={bairroLocal}
                        numeroLocal={numeroLocal}
                        complementoLocal={complementoLocal}
                        cidadeLocal={cidadeLocal}
                        codIbge={codIbge}
                        numeroOrgao={numeroOrgao}
                        areaTotal={areaTotal}
                        cultura={cultura}
                        cadastroAmbiental={cadastroAmbiental}
                        estadoLocal={estadoLocal}
                        enderecoLocal={enderecoLocal}
                        latitude={latitude}
                        longitude={longitude}
                        update={updateAddLocalAplicacao}
                        loc={props}
                        isModal={props.isModal}
                        crm={props.crm}
                        opId={props.opId}
                        fazendaNomeCrm={fazendaNomeCrm}
                        closeModal={props.closeModal}
                        getOp={props.getOp}
                        updateModal={() => props.update()}
                        isFocus={step == 2 ? true : false}
                    />
                </FormSlideContainer>
            </SlideForm>
        )}

    </>)
}
export default withRouter(AddProdutores);
