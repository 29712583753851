import styled from "styled-components";

export const FormContainer = styled.div`
  background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
  height: auto;
  padding-bottom: 20px;
  width: 100%;
  form {
    /* background: #f8f8f9; */

    margin: auto;
    min-width: 250px;
    display: flex;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding-top: 40px;

    .labelcoord {
      width: 100%;
      margin-bottom: 10px;
      /* margin-left: 52px; */
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8a92a2;
    }

    .coord {
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .partCoord {
          width: 30%;
          height: 90px;

          span {
            position: relative;
            top: -100px;
            left: 123px;
            color: #8a92a2;
            font-weight: 500;
          }
          
        }
      }
  }

  margin: 0px;
  button {
    width: 100%;
    max-width: 400px;
    height: 50px;
  }
  i {
    margin-left: 15px;
  }

  #labelcultura{
    margin: -10px 0px 18px 0px;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    margin-left:25px;
    letter-spacing: normal;
    color: #8a92a2;
    z-index: 3;
  }
  .optionListContainer{
    z-index: 3;
  }

  #multiselectContainerReact{
    margin-top: -10px;
    border-radius: 25px;
    border: 1px solid rgb(186, 193, 207);
    background-color: rgb(255, 255, 255) !important;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    height: 100%;
    color: rgb(48, 56, 72);
    background: white;
    width: 100%;
    max-width: 400px;

    .searchWrapper{
      border: 0px solid #ccc;
      span{
        background-color: #00b43f;
      }
      input{
        margin: 5px 0px 30px 10px
      }
    }

  }

  @media only screen and (max-width: 768px) {
    form{

      .labelcoord {
        width: 100%;
        margin-bottom: 10px;
        /* margin-left: 52px; */
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a92a2;
      }
    
      .coord {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .partCoord {
          width: 100%;
          span {
            position: relative;
            top: -85px;
            left: 91%;
            color: #8a92a2;
            font-weight: 500;
          }
        }
      }
    
    }
  }
`;

export const YesNoContainer = styled.div`
  width: 100%;
  margin: 0px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;

  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 10px;
  }

`;

export const ProdutoresContainer = styled.section`
padding: 50px 0;
background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
height: 100%;
margin: auto;

.search-select{
  width: 500px;
  display: flex;
  flex-direction: row;
  margin-top: -110px;

}

.search{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -83px;
  margin-bottom: 20px;

  .exibe-options{        
    .opcoes{

      a {
        text-decoration: none;
      }

        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #FFF;
        width: 160px;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
        padding: 15px;
        margin-left: 70px;
        margin-top: 5px;


        #arrow {
            position: relative;
            background-color: #FFF;
            width: 8px;
            height: 8px;
            top: -90px;
            margin-left: 104px;
            transform: rotate(45deg);
        }

        #tooltip[data-popper-placement^='top'] > #arrow {
            bottom: -4px;
        }

        #tooltip[data-popper-placement^='bottom'] > #arrow {
            top: -4px;
        }

        #tooltip[data-popper-placement^='left'] > #arrow {
            right: -4px;
        }

        #tooltip[data-popper-placement^='right'] > #arrow {
            left: -4px;
        }

        .option{ 
            width: 110%;
            height: 36px;
            border-radius: 5px;
            align-items: center;

            p{
                margin-left: 10px;
                margin-top: 11px;
                font-family: Lato;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;
                cursor: pointer;
            }

            :hover{
                background-color: #f3f3f5;
                font-weight: bold;
                cursor: pointer;

            }
        }
    }

    .btn-relatorio{
        
        width: 145px;
        height: 40px;
        border-radius: 30px;
        border: solid 3px #f8f8f9;
        background-color: #00b43f;
        padding: 15px;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center; 
        cursor: pointer;
        margin-top: 10px;
        transition: all 0.3s;

        i{
            color: #FFF;
        }

        label{
          margin-top: 4px;
          font-family: Lato;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          cursor: pointer;
        }

        img{
          width: 10px;
          cursor: pointer;
        }

        &:hover{
            box-shadow: 0 0 20px 0 #00b43f;
        }
    }
  }
}

> div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .limpar{
    display: flex;
    justify-content: space-around;
    .botao{
      margin: 40px 30px 40px 1px;
      padding: 15px 50px;
    }
  .filterbuttons {
    width: 100%;
    max-width: 400px;
    .pesquisar {
      margin: 40px 0;
      padding: 20px 80px;
    }
  }
}
.botoesfinais{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin-top: 20px;
    .loadmore {
      margin: 30px 0;
      padding: 15px 20px;
    }
    .relatorio {
      display: flex;
      margin: 30px 0;
      padding: 15px 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  padding: 0px;
  background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
  height: 100%;

  margin-top: 106px;

  .search-select{
    width: 100%;
    max-width: 375px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: -58px;
    margin-bottom: -30px;
    .select{                        
      width: 80%;
    }
  

    .add{
      margin-left: -30px;
      margin-top: 17px;
      width: 36%;
      justify-content: center;
      button{
        width:100px;
        padding: 16px;
        justify-content: center;
        border-radius: 30px;
        border: solid 3px #FFF;
      }     
  
    }
  
  }



  .search{
    width:100%;
    max-width: 375px;
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-top: -30px;
    
    
  }
      .add{
        width:100%;
        max-width: 375px;
        align-self: center;
        margin-top: -12px;
        button{
          width:100%;
          max-width: 375px;
          align-self: center;
          border-radius: 30px;
          border: solid 3px #FFF;
        }     
  }
  
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loadmore {
      width:100%;
      max-width: 375px;
      align-self: center;
      margin-top: -40px;
      padding: 15px 20px;
    }
    .limpar{
      display: flex;
      justify-content: space-around;
      .botao{
        margin: 40px 30px 40px 1px;
        padding: 15px 50px;
      }
    .filterbuttons {
      width: 100%;
      max-width: 400px;
      .pesquisar {
        margin: 40px 0;
        padding: 20px 80px;
      }
    }
  }
    .relatorio {
      width:100%;
      max-width: 375px;
      display: flex;
      margin-left: 0px;
      padding: 15px;
      justify-content: center;
      margin-bottom: 10px
    }
    .add {
      width:100%;
      max-width: 375px;
      align-self: center;
      button {
        width:100%;
        max-width: 375px;
        align-self: center;
        margin: 15px 0 30px;
        padding: 20px 30px;
      }
    } 
  } 
}
`;

export const ProdutoresAddContainer = styled.div`
  padding: 50px 0;
  background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
  flex-direction:row;
  
    form {
      margin: auto;
      min-width: 250px;
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      a{
        text-decoration: none;
      }

      .labelcoord {
        width: 100%;
        margin-bottom: 10px;
        /* margin-left: 52px; */
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a92a2;
      }

      .coord {
        width: 100%;
        height: 90px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .partCoord {
          width: 30%;
          height: 90px;

          span {
            position: relative;
            top: -100px;
            left: 123px;
            color: #8a92a2;
            font-weight: 500;
          }
          
        }
      }


      .salvar{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom:15px;
        a{
          text-decoration: none;
        }
        .add{
          width: 60%;
        }
        .link {
          width: 30%;
        }
      }


      a {
        width: 100%;
        max-width: 400px;
        .new{
          width: 100%;
          height: 50px;
          margin-bottom: 60px;
        }
      }


      #labelcultura{
        margin: -10px 0px 18px 0px;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        margin-left:25px;
        letter-spacing: normal;
        color: #8a92a2;
        z-index: 3;
      }
      .optionListContainer{
        z-index: 3;
      }
    
      #multiselectContainerReact{
        margin-top: -10px;
        margin-left: 0px;
        border-radius: 25px;
        border: 1px solid rgb(186, 193, 207);
        background-color: rgb(255, 255, 255) !important;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        height: 100%;
        color: rgb(48, 56, 72);
        background: white;
        width: 100%;
        max-width: 400px;
    
        .searchWrapper{
          border: 0px solid #ccc;
          span{
            background-color: #00b43f;
          }
          input{
            margin: 5px 0px 30px 10px
          }
        }
    
      }
      }

    @media only screen and (max-width: 989px){
      form{
        .salvar{
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .add{
            width: 100%;
          }
          .link {
            width: 100%;
          }
        }
      }

        .crea{
          justify-content: center;
          padding: 30px;
          align-items: flex-end;
          .new{
            width: 100%;
            margin: 30px 0px;
            padding: 15px 80px;
          }
        }
    }

    @media only screen and (max-width: 768px) {
      form{
        height: auto;

        .labelcoord {
          width: 100%;
          margin-bottom: 10px;
          /* margin-left: 52px; */
          font-family: Lato;
          font-size: 11px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #8a92a2;
        }
      
        .coord {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          
          .partCoord {
            width: 100%;

            span {
              position: relative;
              top: -85px;
              left: 91%;
              color: #8a92a2;
              font-weight: 500;
            }
            
          }
        }
      
      }
    }

  @media only screen and (max-width: 500px){
      padding: 50px 0;
      background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
      flex-direction:row;
      
    form {
      margin: auto;
      min-width: 250px;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    button {
        margin: 30px 0px;
        padding: 20px 50px;
        .add {
      
          justify-content: flex-end;
        }
        i {
          margin-left: 15px;
        }
      }
        
      
      .salvar {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        height: 114px;

        .salvado{
          margin: 0;
          padding: 0;
        }

        .add{
          margin: 0;
          padding: 0;
        }
        button {
          padding: 20px 25px;
        }
      }

      

      .crea{
        justify-content: center;
        padding: 30px;
        align-items: flex-end;
        .new{
          width: 100%;
          margin: 30px 0px;
          padding: 15px 80px;

        }
      }

      #labelcultura{
        margin: 3px 218px -24px 16px;
        font-size: 12px;
        font-weight: 700;
        color: #8a92a2;
      }
      
      #multiselectContainerReact{
        margin: 10px 0 10px 100px;
        ._2OR24XnUXt8OCrysr3G0XI{
          .searchBox{
            margin: 5px 0 30px 10px;
          }      
          span{
            background: #00b43f;
            width: auto;
          }
          background: white;
          width: 100%;
          max-width: 400px;
          border-radius: 25px;
          margin-left: -45px;
        }
      }
    }
`;

export const Icons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;

    #send {

        svg {
            transition: all 0.2s ease;
            &:hover{
                transform: scale(1.15);
            }
        }


        p{
            width: 75px;
            height: 22px;

            display:none;
            position:absolute;
            align-items: center;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            
            margin-top: 5px;
            margin-left: -28px;
            
            padding-top: 2px;
            
            color:#FFF;
            background-color: #1d2129;
            opacity: 0.9;
            
            border-radius: 5px;

            &:before{
                position: absolute;
                background-color:  #1d2129;
                width: 8px;
                height: 8px;
                top: -4px;
                margin-left: 17px;
                content: '';
                transform: rotate(45deg);
            }

        }
        svg g{
            fill: #424754
        }

        &:hover{
            text-decoration: none;
            p{
                display: block;
            }
        }
    }

    #send-resp {

      display: flex;
      align-items: center;

      svg {
        transition: all 0.2s ease;
        &:hover{
          transform: scale(1.15);
        }
      }


      p{
          /* width: 75px;
          height: 22px; */

          /* display:none; */
          /* position:absolute; */
          align-items: center;

          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          
          /* margin-top: 5px;
          margin-left: -28px; */
          
          /* padding-top: 2px; */
          
          /* color:#FFF; */
          /* background-color: #1d2129; */
          /* opacity: 0.9; */
          
          /* border-radius: 5px; */

          /* &:before{
              position: absolute;
              background-color:  #1d2129;
              width: 8px;
              height: 8px;
              top: -4px;
              margin-left: 17px;
              content: '';
              transform: rotate(45deg);
          } */

      }
      svg g{
          fill: #424754
      }

      &:hover{
          text-decoration: none;
          /* p{
              display: block;
          } */
      }
    }

    a{
        


        p{
            width: 63px;
            height: 22px;

            display:none;
            position:absolute;
            align-items: center;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            
            margin-top: 5px;
            margin-left: -22px;
            
            padding-top: 2px;
            
            color:#FFF;
            background-color: #1d2129;
            opacity: 0.9;
            
            border-radius: 5px;

            &:before{
                position: absolute;
                background-color:  #1d2129;
                width: 8px;
                height: 8px;
                top: -4px;
                margin-left: 17px;
                content: '';
                transform: rotate(45deg);
            }

        }

        &:hover{
            text-decoration: none;
            p{
                display: block;
            }
        }
    }


    img{
        cursor: pointer;
        
        transition: all 0.2s ease;
        &:hover{
            transform: scale(1.15);
        }
    }
    
    .cancel{
        margin-left: 20px;
        height: 18px;
    }

    @media only screen and (max-width: 600px) { 
        margin-left: 0px;
    }
`;

export const Btn = styled.div`
  width: 100%;
  height: auto;
  min-height: 100px;

  background-color: ${props => props.active ? '#FFF' : '#f3f3f8'};

  border: 1px solid transparent;
  border-radius: 8px;

  padding: 16px 8px;

  box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);

  cursor: pointer;
  transition: all 0.3s ease;

  transform: ${props => props.active ? 'scale(1.02)' : 'scale(1)'};

  &:hover {
    transform: scale(1.02);
  }
  
  &:active {
    transform: scale(0.98);
  }

  p {
    cursor: pointer;
  }


`;

export const Row = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  padding: 8px;

  li::marker{
    font-size: 12px !important;
  }
`;

export const Col2 = styled.div`
  width: 45%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 8px;
`;

export const Col1 = styled.div`
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;

  padding: 8px;

  ol {
    width: 100%;
    margin: 0px;

    text-align: start;

    p {
      font-size: 14px;
      /* margin: 0px; */
    }



  }

  @media only screen and (max-width: 991px) {
    ol {
      width: 90%;
    }
  }
`;

export const Title = styled.h1`
  width: 100%;
  font-size: 22px;
  text-align: center;
  margin-bottom: 16px;
`;

export const Subtitle = styled.h1`
  width: 100%;
  font-size: 18px;
  text-align: center;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  width: 100%;
  font-size: 14px;
  text-align: center;
`;

export const Paragraph = styled.p`
  width: 100%;
  font-size: 14px;
`;

export const Download = styled.label`
  width: auto;
  font-size: 14px;
  text-align: center;
  color: #589BFF;
  cursor: pointer;

  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
  
  &:active {
    transform: scale(0.98);
  }
`;

export const Update = styled.div`

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -25px;

  .arquivos{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    /* margin-left: -10px; */

    .btn-update{
      display: flex;
      align-items: center;
      height: 40px;
      background-color: #2fbb69;
      border-radius: 30px;
      border: 1px dashed #2fbb69;
      font-size: 65%;
      color: #FFF;
      margin: 10px 10px 10px 0px ;
      padding: 2px 20px;
      cursor: pointer;
      transition: all 0.3s;
      z-index: 2;
    
      &:hover{
        cursor: pointer;
        background: #2fbb69;
        color: #FFF;
        box-shadow: 0 0 20px 0 #2fbb69;
      }
    }

    .arq-name{
      display: flex;
      align-items: center;
      width: 350px;
      height: 40px;
      background-color: #FFF;
      border-radius: 30px;
      padding: 13px;
      padding-left: 60px;
      margin-left: -55px;
      margin-top: 7px;
      box-shadow: inset 0px 0px 7px 2px #b6b6b6;
      font-family: Lato;
      font-size: 65%;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    img {
      transform: rotate(45deg);
      z-index: 10;
    }

    img:hover{
      cursor: pointer;
    }
  }

  .legenda {
    /* font-size: smaller; */
    font-size: 14px;
    width: 100%;
  }

  .input-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

`;
