import styled from "styled-components";

export const FormContainer = styled.div`
padding-top: 50px;
/* padding: 50px 0; */
background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
.steps{
    display: flex;
    flex-direction: row;
    width: 180px;
    margin: auto;
    justify-content: space-evenly;
    margin-top: -50px;

    .btn-step-selected{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
      border: solid 4px #f8f8f9;
      background-color: #589bff;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #FFF;
      margin-top: -20px;
      /* cursor: pointer; */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .btn-step{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
      border: solid 4px #f8f8f9;
      background-color: #ced2dc;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #FFF;
      margin-top: -20px;
      /* cursor: pointer; */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

form{
  /* margin: auto;
  min-width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */

    background: transparent;
    margin: auto;
    min-width: 250px;
    /* display: flex; */
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    width: 400px;
    /* height: 60px; */
    padding-top: ${props => props.isModal === true ? '0px' : '40px'};

  .adicionar{
      width: 135%;
      display: flex;
      flex-direction: row;
      justify-content: center;


      .add{
        width: 146px;
        text-align: center;
        margin-top: auto;
        padding-bottom: 16px;
        margin-left: 10px;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #589bff;
        cursor: pointer;
      }
    }

}

  
/* button{
  padding: 15px ; 
  margin: 15px 15px 10px 0px;
  width: 100%;
  max-width: 400px;
  height: 50px;
  margin-top: 50px;
}
i{
   margin-left: 15px;
 } */

 @media only screen and (max-width: 780px) {
   padding-top: 50px;
  /* padding: 50px 0; */
  /* background: #f8f8f9; */
  .steps{
      display: flex;
      flex-direction: row;
      width: 180px;
      margin: auto;
      justify-content: space-evenly;
      margin-top: -25px;
      .btn-step-selected{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
        border: solid 4px #f8f8f9;
        background-color: #589bff;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #FFF;
        margin-top: -20px;
        /* cursor: pointer; */
      }
      .btn-step{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
        border: solid 4px #f8f8f9;
        background-color: #ced2dc;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #FFF;
        margin-top: -20px;
        /* cursor: pointer; */
      }
    }
  
  form{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; 
    /* background: #f8f8f9; */
    margin: auto;
    min-width: 250px;
    /* display: flex; */
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    /* height: 60px; */

    .adicionar{
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: center;
  
  
        .add{
          width: 146px;
          text-align: center;
          margin-top: auto;
          padding-bottom: 16px;
          margin-left: 10px;
          font-family: Lato;
          font-size: 11px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #589bff;
          cursor: pointer;
          position: absolute;
          margin-top: 17px;
          margin-left: 90px;
        }
      }
  
  }
  
    
  /* button{
    padding: 20px;
    width: 100%;
    max-width: 400px;
    margin-left: 0px;
    margin-top: 60px;
  }
  i{
     margin-left: 15px;
   } */
 }
`;