import styled from "styled-components";

export const FormContainer = styled.div`
background: #f8f8f9;
height:  auto;
form {
  background: #f8f8f9;

  margin: auto;
  display: flex;
  display: block;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  height: auto;
  padding-top: 40px;
  padding-bottom: 20px;

  .labelcoord {
    width: 100%;
    margin-bottom: 10px;
    /* margin-left: 25px; */
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a92a2;
  }

  .coord {
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .partCoord {
      width: 30%;
      height: 90px;

      span {
        position: relative;
        top: -100px;
        left: 123px;
        color: #8a92a2;
        font-weight: 500;
      }
      
    }
  }
}

margin: 0px;

i {
  margin-left: 15px;
}

#labelcultura{
  margin: 0px 0px 0px 25px;
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a92a2;
}

.kXSLkQ form #multiselectContainerReact{
  border-radius: 25px;
  border: 1px solid rgb(186, 193, 207);
  background-color: rgb(255, 255, 255) !important;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  height: 71px;
  color: rgb(48, 56, 72);
}
#labelcultura{
  margin: 0px 0px 25px 0px;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a92a2;
    z-index: 3;
}

@media only screen and (max-width: 768px) {
    form{

      .labelcoord {
        width: 100%;
        margin-bottom: 10px;
        margin-left: 25px;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a92a2;
      }

      .coord {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .partCoord {
          width: 100%;

          span {
            position: relative;
            top: -85px;
            left: 91%;
            color: #8a92a2;
            font-weight: 500;
          }
          
        }
      }
    }
  }
`;