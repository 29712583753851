import styled from 'styled-components';

export const IntegracaoContainer = styled.div`
    width: 100%;
    height: auto;
    margin: auto;
    padding: 40px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    margin-top: 11px;
`;

export const Container = styled.div`
    width: 100%;
    max-width: 440px;
`;

export const ManualContainer = styled.div`
    width: 100%;
    max-width: 800px;

    .quadro{
        background-color: rgb(40, 42, 54);
        color: white;
        padding-bottom: 32px;
    }

    .format{
        color: white;
    }

    img {

        transition: all 0.3s ease;

        &:hover{
            transform: scale(1.1);
        }
        
        &:active {
            transform: scale(0.95);
        }

    }
`;



export const Title = styled.h2`
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};
    margin-top: 20px;

`;

export const Text = styled.label`
    font-family: Lato;
    font-size: 14px;
    color: #303848;
    margin-bottom: 0px;
    margin-top: 10px;
`;

export const FrameworksList = styled.div`
    width: 80%;
    height: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;

    margin: auto;
    margin-top: 32px;

`;

export const FrameworksItem = styled.div`
    width: 300px;
    min-height: 120px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-color: #FFF;
    border-radius: 10px;
    box-shadow: -2px -1px 4px -1px rgba(255,255,255,0.15), 2px 2px 4px -1px rgba(29,33,41,0.15);
    padding: 15px;

    margin: 16px;

    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
    }
`;

export const Col = styled.div`
    width: ${props => (props.width ? props.width :  "100%")};
    height: 40%;
    display: flex;
    flex-direction: column;
    align-items: ${props => (props.alignItems ? props.alignItems :  "flex-start")};
    justify-content: center;
    font-size: 12px;
    cursor: pointer;
    
    label {
        cursor: pointer;
    }

    /* img {
        width: 45%;
        max-width: 80px;
    } */
`;
