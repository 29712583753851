import styled from 'styled-components';

export const FaturamentoContainer = styled.div`
    width: 450px;
    height: auto;
    margin: auto;

    padding: 40px 15px;
`;

export const Pages = styled.div`
  width: 100%;
  height: 75px;
  border: 1px solid transparent;
  border-radius: 12px;

  margin: auto;
  margin-bottom: 30px;
  
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    border-radius: 12px 0px 0px 12px;
  }

  div + div {
    border-radius: 0px 12px 12px 0px;
  }
`;

export const Page = styled.div`
  width: 50%;
  height: 65px;
  margin: 5px;
  border: 1px solid transparent;
  
  background-color: ${props => props.isActive ? '#f8f8f9' : '#fff'};

  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease-out;
`;

export const Label = styled.p`
    width: 100%;
    margin: 0px;
    text-align: center;
    color:  ${props => props.isActive ? '#589BFF' : '#8A92A2'};
    font-family: "Lato", Sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.2px;
    transition: all 0.4s ease-out;
`;
