import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { UserContainer } from "./styles";
import api from "../../services/api";
import { Container } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import ModalYesNo from "../../components/modal/modalSolicitar";
import { store } from "react-notifications-component";
import Input from "../../components/input/input_v2";
import Button from "../../components/button/button_v2";
import Select from "../../components/select/select_v2";
import estadosP from "../../../src/services/estados";
import ModalText from "../../components/modal/modalText";

import { async } from "q";

const Solicitar = ({ ...props }) => {
    const [nome, setNome] = useState("");
    const [revisao, setRevisao] = useState(0);
    const [fabricante, setFabricante] = useState("");
    const [file, setFile] = useState([]);
    const [another, setAnother] = useState([]);
    const [obs, setObs] = useState("");
    const [modal, setModal] = useState(false);
    const [namesfiles, setNamesfiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [textfile, setTextfile] = useState("ADICIONAR ANEXOS (OPCIONAL, MAX 3MB)");
    const [erroNome, setErroNome] = useState(false);
    const [erroFabricante, setErroFabricante] = useState(false);
    const [erroMsg, setErroMsg] = useState("O campo não pode estar vazio!");
    const [products, setProducts] = useState([]);
    const [produto, setProduto] = useState('');
    const [cultures, setCultures] = useState([]);
    const [cultura, setCultura] = useState('');
    const [estadoProps, setEstadoProps] = useState(estadosP);
    const [estado, setEstado] = useState('');
    const [erroEstado, setErroEstado] = useState(false);
    const [showModalHelp, setShowModalHelp] = useState(false);

    const getProduct = async () => {
        try {
            const response = await api.get("/solicitar/lista/");
            if (response.status === 200) {
                setProducts(formatProducts(response.data.data))
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Houve um problema no processamento da lista`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (e) {
            store.addNotification({
                title: "Erro!",
                message: `Houve um problema no processamento da lista`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const getCultures = async () => {
        try {
            const response = await api.get("/culturas");
            if (response.status === 200) {
                setCultures(formatCultures(response.data.data))
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Houve um problema para trazer as culturas`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (e) {
            store.addNotification({
                title: "Erro!",
                message: `Houve um problema para trazer as culturas`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const openModalHelp = e => {
        setShowModalHelp(true);
    }

    const formatProducts = (values) => {
        let produtos = [];

        values.map((prod) => {
            console.log(prod)
            produtos = [...produtos, { value: prod.nome_comercial, label: `${prod.nome_comercial} - ${prod.fabricante},`, fabricante: prod.fabricante }];
        });

        return produtos;
    }

    const formatCultures = (values) => {
        let culturas = [];

        values.map((cult) => {
            console.log(cult)
            culturas = [...culturas, { value: cult.nome_comum, label: cult.nome_comum }];
        });

        return culturas;
    }

    const load = async () => {
        if (revisao === 0) {
            const payload = {
                nome_produto: nome,
                revisao,
                fabricante,
                cultura: cultura.value,
                estado: estado.value,
                file: file,
                observacoes: obs
            };
            try {
                setLoading(true);
                const response = await api.post("/solicitar/produto/", payload);
                if (response.status === 200) {
                    if (props.isModal && props.isModal == true) {
                        props.closeModalS();
                    } else {
                        setModalShow(true);
                        setLoading(false);
                    }
                }
            } catch (e) {
                setLoading(false);

                if (!nome) {
                    setErroNome(true);
                }

                if (!fabricante) {
                    setErroFabricante(true);
                }

                if (!estado.value) {
                    setErroEstado(true);
                }

                store.addNotification({
                    title: "Erro!",
                    message: `Verifique os campos em vermelho!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        } else {
            const payload = {
                nome_produto: nome.value,
                revisao,
                fabricante: nome.fabricante,
                cultura: cultura.value,
                estado: estado.value,
                file: file,
                observacoes: obs
            };
            try {
                setLoading(true);
                const response = await api.post("/solicitar/produto/", payload);
                if (response.status === 200) {
                    if (props.isModal && props.isModal == true) {
                        props.closeModalS();
                    } else {
                        setModalShow(true);
                        setLoading(false);
                    }
                }
            } catch (e) {
                setLoading(false);

                if (!nome) {
                    setErroNome(true);
                }

                if (!fabricante) {
                    setErroFabricante(true);
                }

                if (!estado.value) {
                    setErroEstado(true);
                }

                store.addNotification({
                    title: "Erro!",
                    message: `Verifique os campos em vermelho!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const modalClose = (e) => {
        setModalShow(false);
        window.location.href = '/';
    }

    const updateResposta = async (resposta) => {
        if (resposta == 1) {
            window.location.href = '/';
        } else {
            window.location.href = '/solicitar';
        }
    }

    const onChange = async (e) => {
        if (e.target.files) {
            if (e.target.files[0].size < 3098486) {
                if (
                    e.target.files[0].type != "application/pdf" &&
                    e.target.files[0].type != "text/plain" &&
                    e.target.files[0].type != "image/png" &&
                    e.target.files[0].type != "image/gif" &&
                    e.target.files[0].type != "image/jpeg"
                ) {
                    store.addNotification({
                        title: "Erro!",
                        message: `O arquivo selecionado não possui a extensão correta`,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                    return;
                }
                let fil = e.target.files;
                let reader = new FileReader();
                reader.readAsDataURL(fil[0]);
                reader.onload = (e) => {
                    file.push(e.target.result);
                }
                namesfiles.push(e.target.files[0].name);
                setTextfile(
                    <>
                        <p>ADICIONAR ANEXOS (OPCIONAL, MAX 3MB)</p>
                        {namesfiles.map((files) => {
                            return (<>
                                <dt>
                                    <dd>{files}</dd>
                                </dt>
                            </>)
                        })}
                    </>
                );
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `O arquivo selecionado extendeu o tamanho permitido!`,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                return;
            }
        }
    }

    const changeProducts = (value) => {
        console.log(value)
        setProduto(value)
        setNome(value)
    }

    const changeCultures = (value) => {
        console.log(value)
        setCultura(value)
    }

    const changeEstado = (value) => {
        console.log(value)
        setEstado(value)
    }



    const changeNomeProduto = (value) => {
        setNome(value)
    }

    const changeFabricante = (value) => {
        setFabricante(value)
    }

    const changeObs = (value) => {
        setObs(value)
    }

    useEffect(() => {
        console.log(props)
    }, [props])

    useEffect(() => {
        getProduct()
        getCultures()
    }, [])

    return (
        <>
            {props.isModal ? null : (
                <GenericUpper
                    title="Solicitar um ou mais produto(s)"
                    subtitle="Preencha os campos abaixo para solicitar. É fácil e rápido."
                />
            )}

            <UserContainer {...props}>
                <Container>
                    <div className="container-center">
                        <div className="radio" style={{ marginBottom: '30px' }}>
                            <div className="input1">
                                <input
                                    type="radio"
                                    defaultChecked
                                    name="radio1"
                                    onChange={() => setRevisao(0)}
                                /> Novo <br />
                            </div>
                            <div className="input1">
                                <input
                                    type="radio"
                                    name="radio1"
                                    onChange={() => setRevisao(1)}
                                /> Revisão <br />
                            </div>
                        </div>

                        {revisao === 0 ? (
                            <>
                                <Input
                                    label="Nome do(s) produto(s)"
                                    type="text"
                                    id="nome_id"
                                    value={nome}
                                    onChange={(e) => { changeNomeProduto(e); setErroNome(false); }}
                                    erro={erroNome}
                                    messageErro={erroMsg}
                                />
                                <Input
                                    label="Fabricante(s)"
                                    type="text"
                                    id="fabricante_id"
                                    value={fabricante}
                                    onChange={(e) => { changeFabricante(e); setErroFabricante(false); }}
                                    erro={erroFabricante}
                                    messageErro={erroMsg}
                                />

                                <Select
                                    name="estado"
                                    className="select"
                                    label="Estado que deseja emitir a receita"
                                    id="estado"
                                    onChange={e => { changeEstado(e); setErroEstado(false); }}
                                    value={estadoProps.value || estadoProps}
                                    options={estadoProps}
                                    disabled={false}
                                    selectUniqueOption={true}
                                    erro={erroEstado}
                                    messageErro={erroMsg}
                                />
                                <Select
                                    name="culturas"
                                    className="select"
                                    label="Cultura que deseja emitir a receita (opcional)"
                                    id="culturas"
                                    onChange={e => changeCultures(e)}
                                    value={cultura.value || cultura}
                                    options={cultures}
                                    disabled={false}
                                    selectUniqueOption={true}
                                    erro={''}
                                    help={true}
                                    helpClick={() => openModalHelp()}
                                />


                                <div className='input-wrapper' style={{ marginBottom: '40px' }}>
                                    <div onClick={() => document.getElementById('input-file').click()}>
                                        <p>{textfile}</p>
                                        (Apenas: PDF, TXT, PNG, GIF, JPEG)
                                    </div>
                                    <input
                                        type="file"
                                        id="input-file"
                                        name="file"
                                        accept="application/pdf, text/plain, image/png, image/gif, image/jpeg"
                                        multiple
                                        onChange={(e) => onChange(e)}
                                    />
                                    <span id='file-name'></span>
                                </div>
                                <Input
                                    label="Alguma Observação? (opcional)"
                                    type="text"
                                    id='observacoes'
                                    value={obs}
                                    onChange={(e) => changeObs(e)}
                                />
                                <ModalYesNo
                                    show={modalShow}
                                    onHide={modalClose}
                                    resposta=''
                                    update={updateResposta}
                                    responsive={true}
                                    title='Solicitação enviada!'
                                    text='Estamos analizando o seu pedido. Em breve entraremos em contato.'
                                />
                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                    <Button
                                        width='100%'
                                        height='50px'
                                        value='SOLICITAR'
                                        outline={false}
                                        disabled={loading}
                                        onClick={() => load()}
                                        loading={loading}
                                    />
                                </div>
                            </>
                        ) : (<>
                            <Select
                                name="nome_id"
                                className="select"
                                label="Nome do produto"
                                id="nome_id"
                                onChange={e => changeProducts(e)}
                                value={produto.value || produto}
                                options={products}
                                disabled={false}
                                selectUniqueOption={true}
                                erro={''}
                            />

                            <Select
                                name="estado"
                                className="select"
                                label="Estado que deseja emitir a receita"
                                id="estado"
                                onChange={e => { changeEstado(e); setErroEstado(false); }}
                                value={estadoProps.value || estadoProps}
                                options={estadoProps}
                                disabled={false}
                                selectUniqueOption={true}
                                erro={erroEstado}
                                messageErro={erroMsg}
                            />
                            <Select
                                name="culturas"
                                className="select"
                                label="Cultura que deseja emitir a receita (opcional)"
                                id="culturas"
                                onChange={e => changeCultures(e)}
                                value={cultura.value || cultura}
                                options={cultures}
                                disabled={false}
                                selectUniqueOption={true}
                                erro={''}
                                help={true}
                                helpClick={() => openModalHelp()}
                            />


                            <div className='input-wrapper' style={{ marginBottom: '40px' }}>
                                <div onClick={() => document.getElementById('input-file').click()}>
                                    <p>{textfile}</p>
                                    (Apenas: PDF, TXT, PNG, GIF, JPEG)
                                </div>
                                <input
                                    type="file"
                                    id="input-file"
                                    name="file"
                                    accept="application/pdf, text/plain, image/png, image/gif, image/jpeg"
                                    multiple
                                    onChange={(e) => onChange(e)}
                                />
                                <span id='file-name'></span>
                            </div>
                            <Input
                                label="Alguma Observação? (opcional)"
                                type="text"
                                id='observacoes'
                                value={obs}
                                onChange={(e) => changeObs(e)}
                            />
                            <ModalYesNo
                                show={modalShow}
                                onHide={modalClose}
                                resposta=''
                                update={updateResposta}
                                responsive={true}
                                title='Solicitação enviada!'
                                text='Estamos analizando o seu pedido. Em breve entraremos em contato.'
                            />
                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                <Button
                                    width='100%'
                                    height='50px'
                                    value='SOLICITAR'
                                    outline={false}
                                    disabled={loading}
                                    onClick={() => load()}
                                    loading={loading}
                                />
                            </div>

                        </>
                        )}
                    </div>

                    <ModalText
                        show={showModalHelp}
                        onHide={() => setShowModalHelp(false)}
                        text={"O envio não é obrigatório, mas caso tenha urgência na liberação do produto, nos envie a cultura que deseja emitir, assim nós conseguimos agilizar a revisão do produto dando prioridade na informação que será utilizada."}
                        btnOk={false}
                    />
                </Container>
            </UserContainer>
        </>
    );


}

export default withRouter(Solicitar);
