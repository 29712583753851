import styled from "styled-components";
import iconCalendar from "../../assets/icons/icons8-calendário-16.png"


export const InputContainerV2 = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '25px'};
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  input {
    &::placeholder {
      color: ${props => props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#8a92a2'};
      opacity: 1; /* Firefox */
    }
  }

  .fa-eye {
    cursor: pointer;
    position: relative;
    top: -36px;
    left: 85%;

    transition: all 0.3s ease;
    &:hover{
      opacity: 0.5;
    }

    &:active {
      transform: scale(0.7);
    }
  }
  
  .fa-eye-slash {
    cursor: pointer;
    position: relative;
    top: -36px;
    left: 85%;

    transition: all 0.3s ease;
    &:hover{
      opacity: 0.5;
    }

    &:active {
      transform: scale(0.7);
    }
  }

  .help-buttom{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -37px;
    margin-right: -40px;

    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #589bff;
    cursor: pointer
  }



  @media only screen and (max-width: 780px) {
    margin-bottom: ${props => props.yesNo && props. disabled === false ? '58px' : props.marginBottom ? props.marginBottom : '25px'};
  }

  @media only screen and (max-width: 540px) {
    height: ${props => props.isOpen ? 'auto' : props.yesNo ? props.disabled === false ? '100px' : '70px' : '70px'};
    margin-bottom: ${props => props.yesNo && props. disabled === false ? '32px' : props.marginBottom ? props.marginBottom : '25px'};
    
    input {
      top: ${props => props.yesNo ? props.disabled === false ? '-6px' : '0px' : '0px'} !important;

    }
  }
  
`;

export const Label = styled.label`
  width: 56px;
  height: 3px;

  background-color: #FFF;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 0px;
  margin-left: 25px;
  margin-top: ${props => props.isDate ? '-2px' : !props.existValue ? !props.isFocus ? '24px' : '-2px' : '-2px'};

  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  position: absolute;
  
  color: ${props => props.erro ? '#ff5252' : '#8a92a2'};
  opacity: ${props => props.isDisabled ? '0.5' : '1'};
  z-index: 1;
  
  transition: all 0.3s;
  border-radius: 65px;


  &:hover {
    cursor: ${props => !props.existValue ? !props.isFocus ? !props.isDisabled ? 'text' : 'context-menu' : 'context-menu' : 'context-menu'};
  }

  @media only screen and (max-width: 540px){
    margin-top: ${props => props.isDate ? '-2px' : !props.existValue ? !props.isFocus ? props.buttonAddNew ? '36px' : '24px' : props.buttonAddNew ? '11px' : props.isYesNo ? '-8px' : '-2px' :  props.buttonAddNew ? '11px' : props.isYesNo ? '-8px' : '-2px'};
  }
`;

export const Unidade = styled.p`
  font-family: Lato;
  font-size: 12px;
  margin-top: -33px;
  padding-right: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${props => props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#8a92a2'};
`;

export const Legenda = styled.p`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff5252;
  margin-top: ${props => props.unidade ? '22px' : props.isPassword ? '-21px' : '3px'};
`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 87%;
  margin-top: -35px;


  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;
export const YesNoContainer = styled.div`
  width: 165px;
  height: 40px;
  padding: 8px 8px 7px 21px;
  border-radius: 8px;
  background-color: #f3f3f5;
  position: absolute;
  margin: 0px;
  margin-top: -46px;
  left: ${props => (!props.disabled ? '384px' : '33px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
  z-index: -1;


  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
  }


  @media only screen and (max-width: 1024px) {
    left: ${props => (!props.disabled ? '310px' : '33px')};
  }
  
  @media only screen and (max-width: 991px) {
    left: ${props => (!props.disabled ? '269px' : '33px')};
  }
  
  @media only screen and (max-width:780px) {
    display: none;
  }

  @media only screen and (max-width: 540px) {
  }
`;


export const YesNoContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 780px) {
    display: ${props => (!props.disabled ? 'flex' : 'none')};;
    flex-direction: row;
    align-items: center;
    width: 160px;
    height: 50px;
    padding: 8px 8px 7px 8px;
    border-radius: 8px;
    background-color: #f3f3f5;
    position: absolute;
    margin: 0px;
    margin-top: 5px;
    left: 33%;
    top: ${props => (!props.disabled ? '35px' : '-6px')};
    transition: all 0.2s;
    z-index: -1;

    span {
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #586377;
    }
  }

  @media only screen and (max-width: 690px) {
    left: 32%
  }
  
  @media only screen and (max-width: 460px) {
    left: 28%;
  }

  @media only screen and (max-width: 420px) {
    left: 25%
  }
  @media only screen and (max-width: 390px) {
    left: 24%
  }
  @media only screen and (max-width: 350px) {
    left: 20%
  }
  @media only screen and (max-width: 320px) {
    left: 15%
  }
  @media only screen and (max-width: 280px) {
    left: 11%
  }
  @media only screen and (max-width: 270px) {
    left: 9%
  }

`;


export const Inicial = styled.div`
  width: 50%;
  @media only screen and (max-width: 1285px){
    input{
      height: 50px !important;
      padding: 13px 4px 12px 10px !important;
      border-radius: 25px 0px 0px 25px !important;
      background-color: rgb(255,255,255) !important;
      font-family: Lato !important;
      font-size: 16px !important;
      font-weight: bold !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: normal !important;
      letter-spacing: normal !important;
      color: rgb(48,56,72) !important;
    }
  }
`;

export const Final = styled.div`
  width: 50%;
  @media only screen and (max-width: 1285px){
    input{
      height: 50px !important;
      padding: 13px 10px 12px 6px !important;
      border-radius: 0px 25px 25px 0px !important;
      border: 1pxsolidrgb(186,193,207) !important;
      background-color: rgb(255,255,255) !important;
      font-family: Lato !important;
      font-size: 16px !important;
      font-weight: bold !important;
      font-stretch: normal !important;
      font-style: normal !important;
      margin-left: -25px !important;
      line-height: normal !important;
      letter-spacing: normal !important;
      color: rgb(48,56,72) !important;
    }
  }
`;


