import React from 'react';

const IconSendEmail = ({ ...props }) => {
    return (
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 172 172"
            {...props}
        >
            <g 
                fill="none" 
                fill-rule="nonzero" 
                stroke="none" 
                stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none" 
                text-anchor="none" 
                style={{mixBlendMode: 'normal'}}>
                {/* <path d="M0,172v-172h172v172z" fill="none"></path> */}
                <g fill="#424754">
                    <path d="M28.66667,28.66667c-7.91917,0 -14.33333,6.41417 -14.33333,14.33333v86c0,7.91917 6.41417,14.33333 14.33333,14.33333h58.34115c-0.59483,-2.3005 -1.00781,-4.67983 -1.00781,-7.16667c0,-2.48683 0.41298,-4.86617 1.00781,-7.16667h-58.34115v-64.486l57.33333,35.81934l57.33333,-35.81934v25.46127c4.22833,-2.46533 9.08733,-3.97526 14.33333,-3.97526v-43c0,-7.91917 -6.41417,-14.33333 -14.33333,-14.33333zM28.66667,43h114.66667v7.18066l-57.33333,35.81934l-57.33333,-35.81934zM157.66667,100.33333c-7.573,0.00829 -13.83283,5.90639 -14.29134,13.46549l-20.82812,10.41406c-2.33766,-1.54668 -5.07752,-2.37399 -7.88053,-2.37956c-7.91608,0 -14.33333,6.41725 -14.33333,14.33333c0,7.91608 6.41725,14.33333 14.33333,14.33333c2.80778,-0.00283 5.55289,-0.83026 7.89453,-2.37956l20.81413,10.41406c0.45852,7.55911 6.71835,13.4572 14.29134,13.46549c7.91608,0 14.33333,-6.41725 14.33333,-14.33333c0,-7.91608 -6.41725,-14.33333 -14.33333,-14.33333c-2.80778,0.00283 -5.55289,0.83026 -7.89453,2.37956l-19.07845,-9.54622l19.09245,-9.54622c2.33766,1.54668 5.07752,2.37399 7.88053,2.37956c7.91608,0 14.33333,-6.41725 14.33333,-14.33333c0,-7.91608 -6.41725,-14.33333 -14.33333,-14.33333z">
                    </path>
                </g>
            </g>
        </svg>
    )
}

export default IconSendEmail;



