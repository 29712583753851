import React, { useState, useEffect } from "react";

import GenericUpper from "../../components/generic-upper";
import ModalUpdate from '../../components/modal/modalGeneric';
import Step0 from "../../components/compra/step0";
import Step1 from "../../components/compra/step1";
import Step2 from "../../components/compra/step2";
import Step3 from "../../components/compra/step3";
import api from "../../services/api";
import { store } from "react-notifications-component";
import Button from "../../components/button/button_v2";

export const USER = "@userinfo";


const Compra = ({ ...props }) => {


    const [subtitle, setSubtitle] = useState("Dados cadastrais");
    const [align, setAlign] = useState("center");
    const [title, setTitle] = useState("");
    const [step, setStep] = useState(0);

    const [planoId, setPlanoId] = useState('');
    const [gridId, setGridId] = useState('');
    const [numUsers, setNumUsers] = useState('');
    const [numDocs, setNumDocs] = useState('');
    const [numpresctipt, setNumPrescript] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [valorPlano, setValorPlano] = useState('');


    //step1
    const [categoria, setCategoria] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [telefone, setTelefone] = useState('');
    const [planoName, setPlanoName] = useState('');
    const [categoriaLabel, setCategoriaLabel] = useState('');


    //step2
    const [dataVencimento, setDataVencimento] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [cargo, setCargo] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [email, setEmail] = useState('');
    const [telefone2, setTelefone2] = useState('');
    const [numeroPedido, setNumeroPedido] = useState('');
    const [userContract, setUserContract] = useState([]);
    const [cupom] = useState('');

    const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);


    const [cadastrar, setCadastrar] = useState(false);

    

    const updateStep0 = (gridId, planId, numUsersId, numDocuments, numPrescriptions, planoSelecionado, valorTotal, valorPlano) => {
        setGridId(gridId);
            
        setPlanoId(planId);                
        setPlanoName(planoSelecionado.label);

        setNumUsers(numUsersId);
        setNumDocs(numDocuments);
        setNumPrescript(numPrescriptions);

        setValorTotal(valorTotal);
        setValorPlano(valorPlano);
    }

    const updateStep1 = (tipoCompra, categoriaId, razaoSocialId, nomeFantasiaId, cnpjId, inscricaoEstadualId, cepId, logradouroId, numeroId, bairroId, complementoId, cidadeId, estadoId, telefoneId, categoriaLabel) => {
        setCategoria(categoriaId);
        setRazaoSocial(razaoSocialId);
        setNomeFantasia(nomeFantasiaId);
        setCnpj(cnpjId);
        setInscricaoEstadual(inscricaoEstadualId);
        setCep(cepId);
        setLogradouro(logradouroId);
        setNumero(numeroId);
        setBairro(bairroId);
        setComplemento(complementoId);
        setCidade(cidadeId);
        setEstado(estadoId);
        setTelefone(telefoneId);
        setCategoriaLabel(categoriaLabel);
    }

    const updateStep2 = (dataVencimentoId, responsavelId, cargoId, departamentoId, emailId, telefoneId, numeroPedidoId) => {
        
        setDataVencimento(dataVencimentoId);
        setResponsavel(responsavelId);
        setCargo(cargoId);
        setDepartamento(departamentoId);
        setEmail(emailId);
        setTelefone2(telefoneId);
        setNumeroPedido(numeroPedidoId);
    }

    const validateParams = async () => {
        let query = props.location.pathname;
        let partes = query.split('/');
        
        setPlanoId(partes[2]);

        const getPlano = async (id) => {
        
            const response =  await api.get(`/planos/${id}`);
            
            let plano = response.data.data[0];
            setPlanoName(plano.nome_plano);
        } 

        getPlano(partes[2]);

    }

    const atualizarPlano = async (id, gridId) => {
        try {

            if ((numUsers || numDocs || numpresctipt) && valorTotal && valorPlano) {

                const payload = {
                    qtd_users: numUsers,
                    qtd_documents: numDocs,
                    qtd_prescriptions: numpresctipt,
                    valor_total_plano: valorTotal,
                    valor_unitario: valorPlano,
                    grid_id: gridId || 0
                }
                const response = await api.post(`/atualizar/plano/${id}`, payload);

                if (response) {
                    window.location = '/compra/sucesso';
                }
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Informe todos os campos para realizar a compra`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const toStep = (num) => {

        let userInfo = JSON.parse(localStorage.getItem(USER));

        let isTest = userInfo = userInfo.contrato.agro_plan_config.is_teste;

        if (isTest === 1) {
            setStep(num);
        } else {
            setCadastrar(true);
        }
    }

    const goHome = () => {
        setCadastrar(false);
        setShowConfirmUpdate(false);
    }

    const updatePlan = () => {
        atualizarPlano(planoId, gridId);
        setShowConfirmUpdate(false);
    }


    useEffect(() => {
        validateParams();
        setUserContract(JSON.parse(localStorage.getItem("@userinfo")));
    },[]);

    useEffect(() => {        
        if(step === 0){
            setTitle("Simule seu plano");
            setSubtitle("Quanto mais usuários, mais descontos.");
            setAlign("center");
        }else if(step === 1){
            setTitle("Dados cadastrais");
            setSubtitle("Formulário de compra");
            setAlign("left");
        }else if(step === 2){
            setTitle("Dados de faturamento");
            setSubtitle("Formulário de compra");
            setAlign("left");
        }else{
            setTitle("Dados do responsável pela compra");
            setSubtitle("Formulário de compra");
            setAlign("left");
        }
    },[step])
  
    useEffect(() => {

        if (cadastrar === true) {

            if ((numUsers || numDocs || numpresctipt) && valorTotal && valorPlano) {
                setShowConfirmUpdate(true);
            }
        }
    },[cadastrar, numUsers, numDocs, numpresctipt, valorTotal, valorPlano, gridId])

    return (
        <>
            <GenericUpper
                align={align}
                title={title}
                subtitle={subtitle}
            ></GenericUpper>

            {step === 0 ? (
                <>
                    <Step0
                        planoId={planoId}
                        numUsers={numUsers}
                        numDocuments={numDocs}
                        numPrescriptions={numpresctipt}
                        valorTotalProps={valorTotal}
                        valorPlanoProps={valorPlano}
                        update={updateStep0}
                        next={()=>toStep(1)}
                        locProp={props}
                        isSimulation={true}
                    />
                </>
            ): step === 1 ? (
                <>
                    <Step1
                        update={updateStep1}
                        planoName={planoName}
                        numUsers={numUsers}
                        valorTotal={valorTotal}
                        categoria={categoria}
                        razao={razaoSocial}
                        nome={nomeFantasia}
                        cnpj={cnpj}
                        ie={inscricaoEstadual}
                        cep={cep}
                        logradouro={logradouro}
                        numero={numero}
                        bairro={bairro}
                        complemento={complemento}
                        cidade={cidade}
                        estado={estado}
                        telefone={telefone}

                        locProp={props}

                        next={()=>setStep(2)}
                        prev={()=>setStep(0)}
                    />
                </>
            ): step === 2 ? (
                <>  
                    <Step2
                        update={updateStep2}
                        next={()=>setStep(3)}
                        prev={()=>setStep(1)}
                        prevToOne={()=>setStep(0)}
                        categoria={categoriaLabel}
                        nome={nomeFantasia}
                        cnpj={cnpj}
                        logradouro={logradouro}
                        cidade={cidade}
                        telefone={telefone}
                        
                        dataVencimento={dataVencimento}
                        responsavel={responsavel}
                        cargo={cargo}
                        departamento={departamento}
                        email={email}
                        telefoneProps={telefone2}
                        numeroPedido={numeroPedido}

                        locProp={props}
                    />
                </>
            ):(
                <>
                    <Step3
                        planoId={planoId}
                        gridId={gridId}
                        usuarios={numUsers}
                        documentos={numDocs}
                        receitas={numpresctipt}
                        valorTotal={valorTotal}
                        valorUnitario={valorPlano}

                        categoria={categoria}
                        razaoSocial={razaoSocial}
                        nomeFantasia={nomeFantasia}
                        cnpj={cnpj}
                        inscricaoEstadual={inscricaoEstadual}
                        cep={cep}
                        logradouro={logradouro}
                        numero={numero}
                        bairro={bairro}
                        complemento={complemento}
                        cidade={cidade}
                        estado={estado}
                        telefone={telefone}

                        dataVencimento={dataVencimento}
                        responsavel={responsavel}
                        cargo={cargo}
                        departamento={departamento}
                        email={email}
                        telefone2={telefone2}
                        numeroPedido={numeroPedido}

                        userEmail={userContract.username}
                        coupon={cupom} 
                        prev={()=>setStep(2)}
                        prevToOne={()=>setStep(0)}
                        prevToTwo={()=>setStep(1)}
                    />
                </>
            )}

            <ModalUpdate
                size='md'
                show={showConfirmUpdate}
                onHide={() => goHome()}
            >
                <h4 style={{ 
                    width: '100%',
                    textAlign: 'center',
                 }}> Alterar meu plano </h4>
                <p style={{ 
                    width: '100%',
                    textAlign: 'center',
                 }}> Atenção, você está prestes a atualizar seu plano, tem certeza de que deseja continuar? </p>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '40px' }}>
                    <Button 
                        width='160px'
                        height='40px'
                        value='NÃO'
                        outline={true}
                        disabled={false}
                        onClick={() => goHome()}
                        loading={false}
                        red={true}
                    />
                    <Button 
                        width='160px'
                        height='40px'
                        value='SIM'
                        outline={false}
                        disabled={false}
                        onClick={() => updatePlan()}
                        loading={false}
                    />
                </div>
            </ModalUpdate>
        </>
    );
};

export default Compra;