import styled from "styled-components";


export const HistoricoContainer = styled.section`
    padding: 50px 0;
    background: #f8f8f9;
    height: 100%;

    #container {
        margin-top: -30px;
    }

    .card-filtro{
        width: auto;
        height: auto;
        margin: auto;
        margin-top: 28px;
        margin-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;

        .item{
            border-radius: 10px;
            border: solid 1.3px #589bff;
            color: #589bff;
            height: auto;
            font-family: Lato;
            font-size: 8px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            display: flex;
            flex-direction: row;
            color: #589bff;
            padding: 5px 20px 5px 20px;

            .close-filter{
                margin-left: 10px;
                font-size: 12px;
                margin-top: -4px;
                cursor: pointer;

            }
        }

    }

    .loadmore {
      margin: 30px auto;
      padding: 15px 20px;

    }


    .search{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: auto;
        margin-top: -20px;
        
        .add{
            margin-left: 90px;
            margin-top: -12px;
    
            button{
                border-radius: 30px;
                border: solid 3px #FFF;
            }     
        }


        .btn-filtro{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;  
            width: ${props => (props.isMobile ? "110px" : "98x")};
            height: 40px;
            border-radius: 30px;
            border: solid 3px #f8f8f9;
            background-color: #e8edf3;
            padding: 15px;
            cursor: pointer;

            img{
                width: 15px;
                height: 15px;
                margin-top: -4px;
                margin-right: 2px;
                cursor: pointer;
            }

            label{
                margin-top: 4px;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #586377;
                cursor: pointer;
            }
        }

        .exibe-options{
            
            .defesas{
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #FFF;
                width: 160px;
                height: auto;
                border-radius: 10px;
                box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
                padding: 15px;
                margin-left: -5px;
                margin-top: 5px;


                #arrow::before {
                    background-color: #FFF;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    top: -4px;
                    margin-left: 35px;
                }

                #arrow::before {
                    content: '';
                    transform: rotate(45deg);
                    background: #FFF;
                }

                #tooltip[data-popper-placement^='top'] > #arrow {
                    bottom: -4px;
                }

                #tooltip[data-popper-placement^='bottom'] > #arrow {
                    top: -4px;
                }

                #tooltip[data-popper-placement^='left'] > #arrow {
                    right: -4px;
                }

                #tooltip[data-popper-placement^='right'] > #arrow {
                    left: -4px;
                }

                .option{ 
                    width: 145px;
                    height: 36px;
                    border-radius: 5px;
                    align-items: center;

                    p{
                        margin-left: 10px;
                        margin-top: 11px;
                        font-family: Lato;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #8a92a2;
                        cursor: pointer;
                    }

                    :hover{
                        background-color: #f3f3f5;
                        font-weight: bold;
                        cursor: pointer;

                    }
                }
            }

            .btn-relatorio{
                
                width: 145px;
                height: 40px;
                border-radius: 30px;
                border: solid 3px #f8f8f9;
                background-color: #00b43f;
                padding: 15px;

                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center; 
                cursor: pointer;
                transition: all 0.3s;

                i{
                    color: #FFF;
                }

                label{
                    margin-top: 4px;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #fff;
                    cursor: pointer;
                }

                img{
                    width: 10px;
                    cursor: pointer;
                }

                &:hover{
                    box-shadow: 0 0 20px 0 #00b43f;
                }
            }
        }
    }

    .search-2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: ${props => (props.isMobile ? "110px" : "135px")};
        margin-top: -80px;
        
        .btn-relatorio{
            width: 120px;
            height: 40px;
            border-radius: 30px;
            border: solid 3px #f8f8f9;
            background-color: #00b43f;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center; 
            cursor: pointer;
            margin-top: 10px;
            margin-left: 8%;
            padding: 15px;

            label{
                margin-top: 4px;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                cursor: pointer;
            }
        }
    }


    @media only screen and (max-width: 1130px) {
        .search{
            width: 90%;
        }
    }

    @media only screen and (max-width: 991px) {
        .search{
            width: 100%;
        }
    }

    @media only screen and (max-width: 768px) {
        padding: 50px 0;
        background: #f8f8f9;
        height: 100%;

        #container {
            max-width: 700px !important;
        }

        .card-filtro{
            margin-left: 5px;
            margin-right: 5px;
            padding: 0px;
            padding-bottom: 10px;
            justify-content: space-between;

            .item + .item{
                margin-top: 5px;
            }
        }

        .search{
            width: 100%;
            max-width:375px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: auto;
            margin-top: -80px;
            
            .add{
                margin-left: 90px;
                margin-top: -12px;
        
                button{
                    border-radius: 30px;
                    border: solid 3px #FFF;
                }     
            }

            .btn-filtro{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;  
                width: ${props => (props.isMobile ? "110px" : "98x")};
                height: 40px;
                border-radius: 30px;
                border: solid 3px #f8f8f9;
                background-color: #e8edf3;
                padding: 15px;
                cursor: pointer;
                margin-top: 10px;

                img{
                    width: 15px;
                    height: 15px;
                    margin-top: -4px;
                    margin-right: 2px;
                    cursor: pointer;
                }

                label{
                    margin-top: 4px;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #586377;
                    cursor: pointer;

                }
            }

            .exibe-options{
                
                .defesas{

                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #FFF;
                    width: 160px;
                    border-radius: 10px;
                    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
                    padding: 15px;
                    margin-left: -5px;
                    margin-top: 5px;


                    #arrow::before {
                        background-color: #FFF;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        top: -2px;
                        margin-left: 35px;
                    }

                    #arrow::before {
                        content: '';
                        transform: rotate(45deg);
                        background: #FFF;
                    }

                    #tooltip[data-popper-placement^='top'] > #arrow {
                        bottom: -4px;
                    }

                    #tooltip[data-popper-placement^='bottom'] > #arrow {
                        top: -4px;
                    }

                    #tooltip[data-popper-placement^='left'] > #arrow {
                        right: -4px;
                    }

                    #tooltip[data-popper-placement^='right'] > #arrow {
                        left: -4px;
                    }

                    .option{ 
                        width: 145px;
                        height: 36px;
                        border-radius: 5px;
                        align-items: center;

                        p{
                            margin-left: 10px;
                            margin-top: 11px;
                            font-family: Lato;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: #8a92a2;
                            cursor: pointer;
                        }

                        :hover{
                            background-color: #f3f3f5;
                            font-weight: bold;
                            cursor: pointer;

                        }
                    }
                }

                .btn-relatorio{
                    
                    width: 145px;
                    height: 40px;
                    border-radius: 30px;
                    border: solid 3px #f8f8f9;
                    background-color: #00b43f;
                    padding: 15px;

                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center; 
                    cursor: pointer;
                    margin-top: 10px;
                    i{
                        color: #FFF;
                    }

                    label{
                        margin-top: 4px;
                        font-family: Lato;
                        font-size: 12px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #fff;
                        cursor: pointer;
                    }

                    img{
                        width: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
        .search-2{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: ${props => (props.isMobile ? "110px" : "135px")};
            margin-top: -80px;
            
            .btn-relatorio{
                width: 120px;
                height: 40px;
                border-radius: 30px;
                border: solid 3px #f8f8f9;
                background-color: #00b43f;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center; 
                cursor: pointer;
                margin-top: 10px;
                margin-left: 8%;
                padding: 15px;

                label{
                    margin-top: 4px;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                }

            }
        }
    }

    @media only screen and (max-width: 760px) {
        #container {
            margin-top: 0px;
        }
    }

    @media only screen and (max-width: 540px) {
        #container {
            margin-top: 70px;
        }
    }
`;

export const Row = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px;
`;

export const RowRel = styled.div`
    width: 93%;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    background-color: white;
    border-radius: 16px;
    margin-top:20px;
    padding: 16px;
    margin-left: 15px;

    h1{
        text-align: center;
    }
`;


export const ColGraph02 = styled.div`
    width: 31.4%;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    margin-left: 15px;
    height: 205px;
`;

export const ColGraph03 = styled.div`
    width: 23.2%;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    background-color: white;
    border-radius: 16px;
    padding: 16px;
    margin-left: 15px;
    min-height: 146px;
`;

export const RowGraph01 = styled.div`
    width: 73.9%;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    background-color: white;
    border-radius: 16px;
    margin-top:20px;
    padding: 16px;
    margin-left: 15px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const RowGraph02 = styled.div`
    width: 23%;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    background-color: white;
    border-radius: 16px;
    margin-top:20px;
    padding: 0px 16px 16px;
    margin-left: 15px;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const IconeBotao = styled.div`
    display: flex;
    justify-content: space-between;

    .pdf{
      #msgBaixarPdf{
        display: none;
      } 
  
      &:hover{
        #msgBaixarPdf{
            transform: scale(1.1);
            display: flex;
            justify-content: center;
            position:absolute;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            align-items: center;
            color:#FFF;
            background-color: #1d2129;
            width: 80px !important;
            height: 18px;
            opacity: 0.9;
            border-radius: 5px;
            margin-top: -17px;
            margin-left: -33px;
  
            &:after{
                display:flex;
                position: absolute;
                background-color:  #1d2129;
                width: 6px;
                height: 6px;
                top: 14px;
                margin-left: 0px;
                content: '';
                transform: rotate(45deg);
            }
        }
      }
    }

    .xlsx{
      #msgBaixar{
        display: none;
      } 
  
      &:hover{
        #msgBaixar{
            transform: scale(1.1);
            display: flex;
            justify-content: center;
            position:absolute;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            align-items: center;
            color:#FFF;
            background-color: #1d2129;
            width: 80px !important;
            height: 18px;
            opacity: 0.9;
            border-radius: 5px;
            margin-top: -42px;
            margin-left: -30px;
  
            &:after{
                display:flex;
                position: absolute;
                background-color:  #1d2129;
                width: 6px;
                height: 6px;
                top: 14px;
                content: '';
                transform: rotate(45deg);
            }
        }
      }
    }
`;

export const Col2 = styled.div`
    width: 50%;
    height: ${props => props.height ? props.height : 'auto'};
    /* display: flex;
    flec-direction: row;
    justify-content: flex-start;
    align-items: flex-start; */
`;

export const Container = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 20px;

`;

export const Drop = styled.div`
    width: 95%;
    background-color: #FFF;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 40px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
    padding: 20px;
    height: ${props => props.openBox ? 'auto' : '80px'};
    cursor: ${props => props.openBox ? '' : 'pointer'};
    transition: all 0.5s ease;

    display: ${props => props.openBox ? 'block' : 'flex'};

    flex-direction: ${props => props.openBox ? 'column' : 'row'};
    justify-content: ${props => props.openBox ? 'flex-start' : 'space-between'};

    .arrow {
      transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
      }

      &:active {
        transform: scale(0.8);
      }
    }
`;

