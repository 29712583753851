import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import GenericUpper from '../../components/generic-upper';
import InputSearch from "../../components/input-search";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import Paginator from "../../components/paginator";
import api from '../../services/api';
import { FarmsContainer, Loading, Search } from './style';

const PrevisaoTempo = ({ ...props }) => {

    const [isLimpo, setIslimpo] = useState(true);
    const [produtores, setProdutores] = useState([]);
    const [result, setResult] = useState('');
    const [result2, setResult2] = useState('');
    const [loading, setLoading] = useState(false);
    const [fazendas, setFazendas] = useState([]);
    const [totalFarms, setTotalFarms] = useState();

    const [limit, setLimit] = useState(30);
    const [offset, setOffset] = useState(0);
    const [newOffset, setNewOffset] = useState(0);



    const searchDefault = async () => {
        setIslimpo(true);
        setProdutores([]);
        setResult('');

        setLoading(true);

        try {
            const response = await api.get("/fazendas?limit=" + 30 + "&offset=" + 0);

            let newOffset = 30;
            let farms = response.data.data;

            if(farms && farms.length > 0){
                setFazendas(farms);
                setLoading(false);
                setNewOffset(newOffset);
            }else{
                setLoading(false);
                setNewOffset(newOffset);
            }

            setLoading(false);
            
        } catch (error) {
            setLoading(false);
        }

    }

    const search = async (result, result2, limit, offset) => {
        try {
            setLoading(true);

            const search = result;
            const estado = result2;

            const payload = {
                search,
                estado
            }

            const response = await api.post(`/fazendas/pesquisa?limit=${limit}&offset=${offset}`, payload);

            setFazendas(response.data.data.fazendas);
            setTotalFarms(response.data.data.total);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const updateValues = async (text, estado, s) => {
        if (s == 1) {
            if(!text && !estado){
                searchDefault();
            }else{   
                setResult2(estado);
                setResult(text);
                search(text, estado, 30, 0);
            }
        }else{
            // this.setState({result: text});
            // this.setState({result2: estado});
            setResult2(estado);
            setResult(text);
        }
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
           search(result, result2, 30, 0);
        }
    };



    const updateLimitOffset = (l,o) => {
        if (l !== limit || o !== offset) {
            setLimit(l);
            setOffset(o);

            search(result, result2, l, o);
        }
    }

    useEffect(() => {
        search(result, result2, limit, offset)
    }, [])



    return (
        <>
            <GenericUpper 
                title={'Previsão do tempo'}
                subtitle={'Selecione o local de aplicação para vizualizar a previsão do tempo'}
                search={true}
                searchFunction={(text, estado, search) => updateValues(text, estado, search)}
                onKeyDown={(e) => onKeyDown(e)}
                estados={true}
                placeholder="Nome, Produtor ou Cidade"
            />

            <FarmsContainer>
                <Table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Produtor</th>
                            <th>Cidade</th>
                            <th>Estado</th>
                        </tr>
                    </thead>
                    <TBody>
                        {fazendas.map(fazenda => (

                            < tr
                                onClick={(e) => props.history.push(`/previsao_tempo/${fazenda.id}`)}
                                key={fazenda.id}
                            >
                                <td>{fazenda.nome}</td>
                                <td>{fazenda.produtor.nome}</td>
                                <td>{fazenda.cidade}</td>
                                <td><div className="estado">{fazenda.estado}</div></td>
                            </tr>
                        ))}
                    </TBody>
                </Table>

                {loading && (
                    <Loading />
                )}

                <Paginator 
                    perPage={limit} 
                    total={totalFarms} 
                    onChange={(limit, offset) => updateLimitOffset(limit, offset)}
                    totalItens={fazendas.length}
                />

            </FarmsContainer>
        </>
    )
}

export default withRouter(PrevisaoTempo);
