import axios from "axios";
import { getToken } from "./auth";
const token = localStorage.getItem('@agroReceita-Token');

let common = token ? {
  Authorization: `Bearer ${token}`,
  apiKey: process.env.REACT_APP_API_KEY
} : {
  apiKey: process.env.REACT_APP_API_KEY
}

const api = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,   //rota de desenvolvimento
  headers: {
    common
  }
});

// api.interceptors.request.use(async config => {
//   config.headers.apiKey = process.env.REACT_APP_API_KEY;
//   const token = getToken();
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

export default api;
