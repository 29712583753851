import styled from "styled-components";
import iconCalendar from "../../assets/icons/icons8-calendário-16.png"


export const Relatorios = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;
`;

export const CardForm = styled.div`
    width: 100%;
    height: auto;
    background-color: #f8f8f9;
    border-radius: 10px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 25px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 30px;

    .css-2b097c-container{
        width: 460px;
    }

    .lblPeriodo{
        padding-left: 15px;
        text-align: left;
        font-size: 12px;
        font-weight: 700;
        color: #8a92a2;
        margin-top: 20px;
    }

    .data-filter{
        width: 460px;
        height: 50px;
        border-radius: 25px;
        background-color: #f3f3f5;
        display: flex;
        justify-content: center;
        align-items: center;


        .input-data-filter{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .input-date{
                border: none;
                border-right: 1px;
                background-color: #f3f3f5;
                padding-right: 25px;
                height: 40px;
            }

            .input-date + .input-date{
                border-left: solid 1px #dfe4eb;
                padding-left: 25px;
                padding-right: 0px;
            }

            input[type="date"]::-webkit-calendar-picker-indicator{
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url(${iconCalendar}) no-repeat;
                border-width: thin;
                margin-top: 5px;
            }

            input[type="date"]::-webkit-inner-spin-button{
                margin-top: 8px;
            }
 
        }

    }

    .buttons{
        display: flex;
        flex-direction: row;
        margin-top: 15px;
        justify-content: center;
        button + button{
            margin-left: 20px;
        }
    }


@media only screen and (max-width: 533px){
    .data-filter{
        width: 100%;
        height: 100px;
        border-radius: 25px;
        background-color: #f3f3f5;
        display: flex;
        justify-content: center;
        align-items: center;


        .input-data-filter{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .input-date{
                width: 80%;
                border: none;
                border-right: 1px;
                background-color: #f3f3f5;
                padding-right: 25px;
                height: 50px;
            }

            .input-date + .input-date{
                border-top: solid 1px #dfe4eb;
                border-left: none;
                padding-left: 0px;
                padding-right: 0px;
            }

            input[type="date"]::-webkit-calendar-picker-indicator{
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url(${iconCalendar}) no-repeat;
                border-width: thin;
                margin-top: 5px;
            }

            input[type="date"]::-webkit-inner-spin-button{
                margin-top: 8px;
            }
        }
    }  


    .cultura-select{
        #cultura{
            width: 100%;
        }
    }

}

`;


export const CardRelatorio = styled.div`
    width: 240px;
    height: 280px;
    margin: auto;
    margin-bottom: 30px;
    display: inline;
    background-color:  #f8f8f9;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
    cursor: pointer;
    transition: all 0.3s;

    h2{
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        text-align:center;
    }

    img{
        width: 150px;
        margin-top: 25px;
        margin-left: 15px;
    }

    .text{
        height: 40%;
        position: relative;
        bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    :hover{
       background-color: #FFF;
       transform: scale(1.06);
    }
`;