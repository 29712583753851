import React, { useEffect, useState } from 'react';
import statusIcon0 from "../../assets/icons/status-0.png"
import statusIcon1 from "../../assets/icons/status-1.png"
import statusIcon2 from "../../assets/icons/status-2.png"
import statusIcon3 from "../../assets/icons/status-3.png"
import DotsLoader from "../../components/loader";
import { BtnHomolog, Tooltip } from './styles';
import ModalAlert from '../../components/modal/modalGeneric';

import { store } from "react-notifications-component";
import Button from '../../components/button/button_v2';
import api from '../../services/api';
import Input from '../../components/input/input_v2';
import { StyleModal } from '../modal/styles';
import { Label } from '../input/styles';
import mais from "../../assets/Mais.png";


export const USER = "@userinfo";


const HomologButton = ({ ...props }) => {

    const [loading, setLoading] = useState(false);
    const [contractSoftId, setContractSoftId] = useState('');
    const [showModalBlock, setShowModalBlock] = useState(false);

    const [idReceitaExport, setIdReceitaExport ] = useState();
    const [estadoReceitaExport, setEstadoReceitaExport ] = useState();
    const [showModalExport, setModalExport] = useState(false);
    const [motivoAtraso, setMotivoAtraso] = useState('');

    const [showModalUpload, setModalUpload] = useState(false);
    const [file64, setFile64] = useState(false);
    const [namesfiles, setNamesFiles] = useState([]);
    const [isValidate, setIsValidate] = useState(false);


    const [showModal, setShowModal] = useState(false);


    const openPlanos = () => {
        setShowModalBlock(false);
        console.log("PROPS => ", props)
        props.propsLocation.history.push('/planos');
    }  

    const exportarReceita = async (id, estado, motivo, file64) => {
        if (props.statusEnvio === 1 || props.statusEnvio === 2 || props.statusEnvio === 4) {
            setLoading(false);
            let user = JSON.parse(localStorage.getItem(USER));

            if (user.contrato.agro_plan_config.is_teste === 1) {
                setShowModalBlock(true);
            } else {
                try {
                    if (estado === 'GO') {
                        setLoading(true);
                        const response = await api.post(`/sidago/exportar_receita/${id}`);
                        if (response) {
                            let msg = response.data.message;
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${msg}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                            props.updateReceitas();
                            
                        }
                    } else if (estado === 'MT') {
                        setLoading(true);
                        const response = await api.post(`/sisdev/exportar_receita/${id}`);
                        if (response) {
                            let msg = response.data.message;
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${msg}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                            props.updateReceitas();
                            
                        }
                    } else if (estado === 'SC') {
                        setLoading(true);
                        const response = await api.post(`/cidasc/exportar_receita/${id}`);
                        
                        if (response) {
                            let msg = response.data.message;
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${msg}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                            props.updateReceitas();
                            
                        }
    
                        
                        setLoading(false);
                    } else if (estado === 'ES') {
                        setLoading(true);
                        if(motivo === '' && file64 !== ''){
                            const response = await api.post(`/idaf/exportar_receita/${id}`, {receita:file64});
                            if (response) {
                                let msg = response.data.message;
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `${msg}`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                                props.updateReceitas();
                                
                            }
                        }else if(motivo !== '' && file64 === ''){
                            const response = await api.post(`/idaf/exportar_receita/${id}`, {xMotivo:motivo});
                            if (response) {
                                let msg = response.data.message;
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `${msg}`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                                props.updateReceitas();
                                
                            }
                        }else if(motivo !== '' && file64 !== ''){
                            const response = await api.post(`/idaf/exportar_receita/${id}`, {xMotivo:motivo, receita:file64});
                            if (response) {
                                let msg = response.data.message;
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `${msg}`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                                props.updateReceitas();
                                
                            }
                        }else{
                            const response = await api.post(`/idaf/exportar_receita/${id}`, {xMotivo:motivo});
                            if (response) {
                                let msg = response.data.message;
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `${msg}`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                                props.updateReceitas();
                                
                            }
                        }
                    }
                } catch (error) {
                    setLoading(false);
                    console.log(error);
                    if (error.response.status === 400) {
                        store.addNotification({
                            title: "Erro!",
                            message: `${error.response.data.errors}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                    } else if (error.response.status === 406) {
                        setIdReceitaExport(id);
                        setEstadoReceitaExport(estado);
                        setModalExport(false);

                        store.addNotification({
                            title: "Erro!",
                            message: `${error.response.data.errors}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                    } else if (error.response.status === 404) {
                        setIdReceitaExport(id);
                        setEstadoReceitaExport(estado);
                        setModalUpload(true);
                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: `${error.response.data.errors} Aguarde alguns minutos ou entre em contato com nosso suporte!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        }
    }

    const validarMotivo = () => {
        setLoading(true);
        if (idReceitaExport && estadoReceitaExport && motivoAtraso && file64) {
            exportarReceita(idReceitaExport, estadoReceitaExport, motivoAtraso,file64);
            setModalExport(false);
        }else if (idReceitaExport && estadoReceitaExport && motivoAtraso){
            exportarReceita(idReceitaExport, estadoReceitaExport, motivoAtraso,'');
            setModalUpload(false);
        }
    }
    
    const validarArquivo = () => {
        if (idReceitaExport && estadoReceitaExport && motivoAtraso && file64) {
            exportarReceita(idReceitaExport, estadoReceitaExport, motivoAtraso,file64);
            setModalUpload(false);
        }else if (idReceitaExport && estadoReceitaExport && file64){
            exportarReceita(idReceitaExport, estadoReceitaExport, '',file64);
            setModalUpload(false);
        }
    }

    const uploadArquivo = (e) => {
        let user = JSON.parse(localStorage.getItem(USER));
        if (user.contract_software_id === 6) {
            setShowModalBlock(true);
        } else {
            let cont = e.target.files.length;
            cont = cont - 1;
            console.log(e);
            if(e.target.files){
                if(e.target.files[cont].type == "application/pdf"){                    
                    let fil = e.target.files;
                    let file = null;
                    let fileName = "";
                    if (fil.length > 0) {
                        let fileToLoad = fil[0];
                        fileName = fileToLoad.name;

                        let fileReader = new FileReader();

                        fileReader.onload = function(fileLoadedEvent) {
                            file = fileLoadedEvent.target.result;
                            setFile64(file);
                        };

                        // Convert data to base64
                        fileReader.readAsDataURL(fileToLoad);
                    }
                    setNamesFiles(e.target.files[cont].name);                   
    
                } else {
                    store.addNotification({
                        title: "Erro!",
                        message: `O arquivo selecionado não possui a extensão correta`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Não é possível enviar mais que um arquivo!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const deleteFile = () => {
        setNamesFiles([]);
        setFile64(false);
        setIsValidate(false);
    };

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem(USER));
        setContractSoftId(user.contract_software_id);
    },[])

    useEffect(() => {
        setLoading(false);
    },[props.statusEnvio])

    return (
        <>
            {loading ? (
                <DotsLoader style={{ marginTop: '-19px', marginLeft: '10px' }} show={loading}></DotsLoader>
            ) : (
                <BtnHomolog
                    status={props.statusEnvio} 
                    onClick={() => exportarReceita(props.receitaId, props.estado)}
                >
                    <p>{props.estado === 'GO' ? `SIDAGO` : props.estado === 'MT' ? `SISDEV` : props.estado === 'SC' ? `SIGEN` : props.estado === 'ES' ? `IDAF` : ``}</p>
                    <img className='status-icon' src={ props.statusEnvio === 0 ? statusIcon0 : props.statusEnvio === 1 ? statusIcon1 : props.statusEnvio === 2 ? statusIcon2 : props.statusEnvio === 3 ? statusIcon3 : statusIcon0 } alt=''/>
                    
                    <p id='msg'>
                        {props.statusEnvio === 0 ? 'Não enviado, prazo expirado' : props.statusEnvio === 1 ? 'Enviar hoje' : props.statusEnvio === 2 ? 'Pendente' : props.statusEnvio === 3 ? 'Enviado' : props.statusEnvio === 4 ? 'Não enviado, enviar agora' : ''}
                    </p>
                </BtnHomolog>
            )}

            <ModalAlert
                show={showModalBlock}
                onHide={() => setShowModalBlock(false)}
            >
                <h4 style={{
                    textAlign: 'center'
                }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

                <p style={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}>
                    Para não comprometer o controle de suas receitas agronômicas durante o período de teste esta função é bloqueada.
                </p>
                
                <p style={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}>
                    Assine o AgroReceita para desbloquear esta função!
                </p>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='VER PLANOS'
                        outline={false}
                        disabled={false}
                        onClick={() => openPlanos(false)}
                        loading={false}
                    />
                </div>
            </ModalAlert>

            <ModalAlert
                show={showModalExport}
                onHide = {() => setModalExport(false)}
                title="Exportação de Receita com Atraso"
            >
                <h2 style={{
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: '20px',
                    fontSize: '22px'
                }}>Descreva o motivo para o atraso da exportação</h2>

                <div style={{
                    width: '100%',
                    padding: '2% 20%',
                    margin: 'auto'
                }}>
                    <Input
                        id="motivoatraso"
                        label="Motivo"
                        value={motivoAtraso}
                        maxlength="5000"
                        onChange={(e) => setMotivoAtraso(e)}
                        onBlur={(e) => setMotivoAtraso(e.target.value)}
                    />


                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                        <Button
                            width='296px'
                            height='50px'
                            value='ENVIAR'
                            outline={false}
                            disabled={motivoAtraso.trim() === '' ? true : false}
                            onClick={() => validarMotivo(idReceitaExport, estadoReceitaExport, file64)}
                            loading={loading}
                        />
                    </div>
                </div>
            </ModalAlert>

            <ModalAlert
                show={showModalUpload}
                onHide={() => setModalUpload(false)}
            >
                <h4 style={{
                    textAlign: 'center'
                }}>Enviar receita assinada pelo responsável técnico</h4>
                <StyleModal style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                }}>
                    <div className="arquivos">
                        <label
                            className='btn-update'
                            for='input-file' 
                            multiple
                            onClick={() => {setFile64(true)}}
                        >
                            Selecionar arquivo
                        </label>
                        <label className="arq-name" style={{ width: '330px' }}>
                            {namesfiles.length > 0 ? 
                            (<>
                                {namesfiles}
                                <img src={mais}  width="20" height="20" onClick={() => deleteFile()}/>
                            </>)  : "Nenhum arquivo selecionado" } 
                        </label>

                        {file64 ? (
                            console.log(file64),
                            <input 
                                style={{display: "none"}}
                                type="file"
                                id="input-file"
                                name="file"
                                multiple
                                onChange={(e) => uploadArquivo(e)}
                            />
                        ):(null)}
                    </div>
                </StyleModal>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '10px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='ENVIAR'
                        outline={false}
                        disabled={typeof file64 == 'string' ? file64 !== '' ? false : true : true }
                        onClick={(e) => validarArquivo(e)}
                        loading={false}
                    />
                </div>
            </ModalAlert>
        </>
    )
}

export default HomologButton;
