import styled from "styled-components";

export const Card = styled.div`
    width: ${props => props.width ? props.width : '29.5%'};
    height: ${props => props.height ? props.height : '100%'};
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    padding: 0px 10px 5px 10px;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'wrap'};
    overflow: hidden;

    @media only screen and (max-width: 1199px){
      margin-bottom: 20px;

    }
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};

    .item-dados{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${props => props.isMobile === true ? '13px' : '11px'};
        margin-bottom: 1px;
        margin-left: ${props => props.isMobile === true ? '25px' : '0px'};
        

        img{
            margin-left: -109px;
            position: absolute;
        }

        #msgVentosCompleta{
            display: none;
        }

        &:hover{
            #msgVentosCompleta{
                transform: scale(1.1);
                display: flex;
                justify-content: center;
                position:absolute;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                text-align: center;
                align-items: center;
                color:#FFF;
                background-color: #1d2129;
                width: 200px !important;
                height: 18px;
                opacity: 0.9;
                border-radius: 5px;
                margin-bottom: 40px;
                margin-right: 40px;

                &:after{
                    display: inline-block;
                    position: absolute;
                    background-color: #1d2129;
                    width: 6px;
                    height: 6px;
                    top: 14px;
                    margin-left: 40px;
                    content: '';
                    transform: rotate(45deg);
                }
            }
        }
    }

    .item-dados .msgVentos{
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
        cursor: default;
    }

    .ver_mais{
      width: auto;
      display: flex;
      font-size: 10px;
      margin-left: ${props => props.marginLeft ? props.marginLeft : '30px'};
      margin-top: ${props => props.marginTop ? props.marginTop : '20px'};
      font-family: Lato;
      font-size: ${props => props.fontSize ? props.fontSize : '10px'};
      color: #589bff;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      -webkit-letter-spacing: normal;
      -moz-letter-spacing: normal;
      -ms-letter-spacing: normal;
      letter-spacing: normal;
    }

    @media only screen and (max-width: 815px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const Title = styled.h2`
    /* color: #000;
    font-size: 20px; */
    width: 60%;
    display: flex;
    font-family: Lato;
    font-size: ${props => props.fontSize ? props.fontSize : '10px'};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #8a92a2;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : '20px'};
    margin-top: ${props => props.marginTop ? props.marginTop : '27px'};
`;

export const Calendario = styled.div`
.react-calendar {
  width: 350px;
  margin: auto;
  margin-top: 6px;
  max-width: 100%;
  background: white;
  font-family: Lato;
  color: #8a92a2;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
  color: #303848;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #303848;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: #303848;
  
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #8a92a2;
  border-radius: 50%;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #e6e6e6;
}
.react-calendar__tile--hasActive {
  background: #2FBB69;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #2FBB69;
}
.react-calendar__tile--active {
  background: #2FBB69;
  border-radius: 50%;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #7DD5A2;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

`;

export const CardTarefa = styled.div`
    width: 100%;
    margin-bottom: 10px;
    height: 60px;
    background-color: #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-shadow: 1px 1px 5px 1px rgb(29 33 41 / 40%);


    .row{
        width: 100%;
        max-width: 260px;
        margin-left: 20px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    .column{
        width: auto;
        display: flex;
        flex-direction: column;
        margin-right: 30px;

        .tarefa{
            width: 170px;
            align-items: center;
            font-family: Lato;
            font-size: 12px;
            font-weight: bold;
            color: #000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .dados{
            width: 170px;
            align-items: center;
            font-family: Lato;
            font-size: 11px;
            color: #000;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }
    }

    .seta{
        width: auto;
        display: flex;
        align-items: center;
    }

    .icone{
        width: auto;
        margin-right: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
`;