import React, { useEffect, useRef, useState } from 'react';
import { Final, Inicial, InputContainerV2, Label } from './style';

const DateRange = ({ ...props }) => {

    const [ref] = useState(useRef());

    const [initialDate, setInitialDate] = useState('');
    const [finalDate, setFinalDate] = useState('');
    const [isFocusInitial, setIsFocusInitial] = useState(false);
    const [isFocusFinal, setIsFocusFinal] = useState(false);


    const changeInput = (type, text) => {
        if (type === 1) {
            if (text || text === '0' || text === 0) {
                setInitialDate(text);
                if (props.onChangeInitial) {
                    props.onChangeInitial(text);
                }
                
            } else {
                setInitialDate('');
                if (props.onChangeInitial) {
                    props.onChangeInitial('');
                }
            }
        } else if (type === 2) {
            if (text || text === '0' || text === 0) {
                setFinalDate(text);
                if (props.onChangeFinal) {
                    props.onChangeFinal(text);
                }
                
            } else {
                setFinalDate('');
                if (props.onChangeFinal) {
                    props.onChangeFinal('');
                }
            }
        }

    }

    const close = (e) => {
        if (isFocusInitial === true) {
            setIsFocusInitial(false);
        }
        if (isFocusFinal === true) {
            setIsFocusFinal(false);
        }
        if (props.onBlur) {
            props.onBlur(e);
        }
    }

    const keyDown = (e) => {
        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    }

    useEffect(() => {
        
    }, [])
    
    useEffect(() => {
        if (props.initial) {
            if (props.initial !== initialDate) {
                setInitialDate(props.initial);
            }
        }
    }, [props.initial])

    useEffect(() => {
        if (props.final) {
            if (props.final !== finalDate) {
                setFinalDate(props.final);
            }
        }
    }, [props.final])


    return (
        <InputContainerV2 
            erro={props.erro} 
            disabled={props.disabled} 
            marginBottom={props.marginBottom}
        >
            <Inicial>
                <Label 
                    erro={props.erro}
                    isFocus={isFocusInitial}
                    existValue={initialDate === '' && initialDate !== '0' && initialDate !== 0 ? false : true}
                    onClick={() => setIsFocusInitial(!isFocusInitial)}
                    isDisabled={props.disabled}
                    isDate={true}
                >
                    Data inicial
                </Label>

                <input  
                    ref={ref} 
                    value={initialDate}
                    {...props} 
                    type='date'
                    onChange={(e) => changeInput(1, e.target.value)}
                    onKeyDown={(e) => keyDown(e.key)}
                    onBlur={(e) => close(e)}
                    onFocus={() => setIsFocusInitial(!isFocusInitial)}
                    style={{
                        width: '100%',
                        height: '36px',
                        padding: '13px 15px 12px 25px',
                        borderRadius: '25px 0px 0px 25px',
                        border: props.erro ? 'solid 1px #ff5252' : props.disabled ? 'solid 1px #cfd5df' : 'solid 1px #bac1cf',
                        backgroundColor: '#FFF',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        color: props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#303848',
                    }}
                >
                </input> 
            </Inicial>

            <Final>
                <Label 
                    erro={props.erro}
                    isFocus={isFocusFinal}
                    existValue={finalDate === '' && finalDate !== '0' && finalDate !== 0 ? false : true}
                    onClick={() => setIsFocusFinal(!isFocusFinal)}
                    isDisabled={props.disabled}
                    isDate={true}
                >
                    Data final
                </Label>

                <input  
                    ref={ref} 
                    value={finalDate}
                    {...props} 
                    type='date'
                    onChange={(e) => changeInput(2, e.target.value)}
                    onKeyDown={(e) => keyDown(e.key)}
                    onBlur={(e) => close(e)}
                    onFocus={() => setIsFocusFinal(!isFocusFinal)}
                    style={{
                        width: '100%',
                        height: '36px',
                        padding: '13px 15px 12px 25px',
                        borderRadius: '0px 25px 25px 0px',
                        border: props.erro ? 'solid 1px #ff5252' : props.disabled ? 'solid 1px #cfd5df' : 'solid 1px #bac1cf',
                        backgroundColor: '#FFF',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        color: props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#303848',
                    }}
                >
                </input> 
            </Final>
        </InputContainerV2>
    )
}

export default DateRange;