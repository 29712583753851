import React, {useState, useEffect, useRef} from 'react';
import { 
    SelectContainerV2, 
    Label, 
    Arrow, 
    Clearabled, 
    Plus,
    Legenda, 
    Options, 
    Option, 
    NoOptions, 
    ButtonAddNew, 
    YesNoContainer, 
    YesNoContainerMobile,
    Loading
} from './styles';
import ArrowIcon from "../../assets/icons/icon-arrow.svg";
import DeleteIcon from "../../assets/icons/icon-delete.svg";
import Interruptor from '../../components/interruptor';
import ModalAlert from '../../components/modal/modalGeneric';
import Button from '../../components/button/button_v2';
import Solicitar from '../../pages/solicitar';
import { store } from "react-notifications-component";
import IconHelp from '../../assets/icons/help';
export const USER = "@userinfo";


const SelectV2 = ({ ...props }) => {
    const [ref] = useState(useRef());
    const [isOpen, setIsOpen] = useState(false);
    const [value, setValue] = useState('');
    const [selectOptions, setSelectOptions] = useState([]);
    const [yesNoValue, setYesNoValue] = useState(true);

    const [solicitaP, setSolicitaP] = useState(false);
    const [showModalSolicitar, setModalSolicitar] = useState(false);
    const [showModalBlock, setModalBlock] = useState(false);

    const [addOptions, setAddOptions] = useState(false);
    const [limit, setLimit] = useState(20);

    const [isclose, setisclose] = useState(false);

    const onChange = (valueOp) => {
        if (value) {
            if (value.value !== valueOp.value) {
                if (props.isSearch === true) {
                    if (valueOp.value != props.value) {
                        props.onChange(valueOp);
                        setValue(valueOp);
                        setIsOpen(false);
                    } else {
                        setValue(valueOp);
                    }
                } else {
                    props.onChange(valueOp);
                    setValue(valueOp);
                    setIsOpen(false);
                }
            }
        } else {
            if (props.isSearch === true) {
                if (valueOp.value != props.value) {
                    props.onChange(valueOp);
                    setValue(valueOp);
                    setIsOpen(false);
                } else {
                    setValue(valueOp);
                }
            } else {
                props.onChange(valueOp);
                setValue(valueOp);
                setIsOpen(false);
            }
        }
    }

    const changeInput = (value) => {
        setValue(value.target.value);

        let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ-";
        let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr ";

        let filterOptions = props.options;

        filterOptions = filterOptions.filter(val => {
            let valLabel = val.label;
            let valInput = value.target.value;

            let newValLabel = '';
            let newValInput = '';

            if (valLabel) {
                for(let i=0; i<valLabel.length; i++) {
                    let troca=false;
                    for (let a=0; a<com_acento.length; a++) {
                        if (valLabel.substr(i,1)==com_acento.substr(a,1)) {
                            newValLabel+=sem_acento.substr(a,1);
                            troca=true;
                            break;
                        }
                    }
                    if (troca==false) {
                        newValLabel+=valLabel.substr(i,1);
                    }
                }
                
            }

            if (valInput) {
                for(let i=0; i<valInput.length; i++) {
                    let troca=false;
                    for (let a=0; a<com_acento.length; a++) {
                        if (valInput.substr(i,1)==com_acento.substr(a,1)) {
                            newValInput+=sem_acento.substr(a,1);
                            troca=true;
                            break;
                        } 
                    }
                    if (troca==false) {
                        newValInput+=valInput.substr(i,1);
                    }
                }
            }

            if (newValLabel.toLocaleLowerCase().match(`${newValInput.toLocaleLowerCase()}`) || valInput.toUpperCase() === val.value) {
                return val.label;
            }
        });
        
        setSelectOptions(filterOptions);
    }

    const close = (e) => {
        setTimeout(() => {
            setisclose(true);
        }, 300);
    }

    const selectByValue = (value) => {
        if (props.options.value) {
            if (props.options.value === value) {
                onChange(props.options);
            } else if (props.options.value === undefined && props.options.length > 0) {
                props.options.map((op) => {
                    if (op) {
                        if (op.value === value) {
                            onChange(op);
                        }
                    }
                })
            }
        } else {
            props.options.map((op) => {
                if (op) {
                    if (op.value === value) {
                        onChange(op);
                    }
                }
            })
        }
    }

    const solicitarProduto = () => {
        const user = JSON.parse(localStorage.getItem(USER));

        const functions = user.contrato.agro_plan_functions;
        functions.map((f) => {
            if (f.agro_plan_function_id === 7) {
                if ( f.status === 0) {
                    setModalBlock(true);
                } else {
                    setModalSolicitar(true);
                }
            }       
        });
    }

    const solicitarSucesso = () => {
        store.addNotification({
            title: "Sucesso!",
            message: `Produto solicitado com sucesso!`,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 4000,
                onScreen: true
            }
        });
        setModalSolicitar(false);
    }

    const clear = () => {
        if (value.value) {
            setIsOpen(false);
            props.onChange({value: '', label: ''});
        } else if (typeof value === 'string') {
            setValue('');
            setIsOpen(false);
            props.onChange({value: '', label: ''});
            setSelectOptions(props.options)
        }
    }

    const clickArrow = () => {

        if (props.disabled === !true) {
            if (isOpen === true) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
                ref.current.focus();
            }
        } else if (!props.disabled) {
            if (isOpen === true) {
                setIsOpen(false);
            } else {
                setIsOpen(true);
                ref.current.focus();
            }
        } else {
            
        }
    }

    const onFocusInput = () => {
        if (props.disabled === false || !props.disabled) {
            if (!isOpen) {
                document.getElementById(props.id).select();
            }
            setIsOpen(true);
        }
    }

    const searchGoogleImages = (search) => {
        window.open(`https://www.google.com/search?q=${search}&sxsrf=ALiCzsY0y3LwsxgN0oeayW3_AJMzZFXcng:1657226469380&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjV5ZPY0ef4AhWYpZUCHYKmDtAQ_AUoAXoECAIQAw&biw=1536&bih=792&dpr=1.25`, '_blank');
    }


    useEffect(() => {
        if (props.options) {
            setSelectOptions(props.options);
            
            if (props.options.length === 0) {
                setValue('');
            }
        }
        
        if (props.value != 0 && props.value != undefined && props.value != '') {
            if (props.selectUniqueOption === true) {
                selectByValue(props.value);
            }
        }

        if (props.value || props.value === 0) {
            selectByValue(props.value);
        } else if (props.value === '') {
            setValue('');
        }
    },[props.options, props.value, props.selectUniqueOption]);

    useEffect(() => {
        if (selectOptions.length > 0) {
            if (selectOptions.length == 1) {
                if (value) {
                    if (selectOptions[0]) {
                        if (value.value != selectOptions[0].value) {
                            if (props.selectUniqueOption === true) {
                                onChange(selectOptions[0]);
                            }
                        }
                    }
                } else {
                    if (selectOptions[0]) {
                        if (selectOptions[0].value) {
                            if (props.selectUniqueOption === true) {
                                onChange(selectOptions[0]);
                            }
                        }
                    }
                }
            }
            setSolicitaP(false)
        }else{
            setSolicitaP(true)
        }

        if (selectOptions.value) {
            if (selectOptions[0] === undefined) {
                setSelectOptions([selectOptions]);
            }
        }
    },[selectOptions]);

    useEffect(() => {
        if (props.yesNo === true) {
            props.onChangeYesNo(yesNoValue);
        }
    },[yesNoValue]);

    useEffect(() => {
        setYesNoValue(props.yesNoValue);
    },[props.yesNoValue])

    useEffect(() => {
        if (props.isOpen) {
            props.isOpen(isOpen);
        }

        if (isOpen) {
            setLimit(20);
        }
    }, [isOpen])

    useEffect(() => {

        if (isclose) {
            if (value && selectOptions.length === 0) {
                setValue('');
                clear();
            } else {
                if (value) {
                    if (selectOptions.length > 1) {
                        if (value.label) {
                            let save = '';
                            selectOptions.map((so) => {
                                if (so.label === value.label) {
                                    save = so;
                                }
                            });
        
                            if (save !== '') {
                                onChange(save);
                            } else {
                                setValue('');
                                clear();
                            }
                        } else {
                            setValue('');
                            clear();
                        }
                    } else {
                        if (value.label) {
                            let save = '';
                            selectOptions.map((so) => {
                                if (so.label === value.label) {
                                    save = so;
                                }
                            });
        
                            if (save !== '') {
                                onChange(save);
                            } else {
                                setValue('');
                                clear();
                            }
                        } else {
                            let save = '';
                            selectOptions.map((so) => {
                                if (so.label === value) {
                                    save = so;
                                }
                            });
        
                            if (save !== '') {
                                onChange(save);
                            } else {
                                setValue('');
                                clear();
                            }
                        }
                    }
                } else {
                    if (selectOptions.length === 1) {
                        onChange(selectOptions[0]);
                    }
                }
            }
    
            setTimeout(() => {
                setIsOpen(false);
                setisclose(false)
            }, 300);
        }
    }, [isclose])

    useEffect(() => {
        if (addOptions) {
            let sum = limit + 20;

            if (sum <= selectOptions.length) {
                if (limit !== sum) {
                    setLimit(sum);
                }
            } else {
                if (limit !== selectOptions.length) {
                    setLimit(selectOptions.length);
                }
            }
            setTimeout(() => {
                setAddOptions(false);
            }, 500);
        }
    }, [addOptions]);

    const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
                setAddOptions(true);
            }
        },
        {
            root: null,
            rootMargin: "0px",
            threshold: 0.1
        }
    );

    const element = document.querySelector('#loading-options')
    if (element) {
        observer.observe(element);
    }

    return (
        <SelectContainerV2 
            isOpen={isOpen} 
            erro={props.erro} 
            isModal={props.isModal} 
            disabled={props.disabled} 
            loading={props.loading} 
            yesNo={props.yesNo} 
            marginBottom={props.marginBottom}
            addNew={props.buttonAddNew}
        >
            <div className="container-Input" style={{
                    marginTop: props.buttonAddNew ? '-15px' : '0px',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
            }}>
                <div className='select'>
                    <Label 
                        erro={props.erro} 
                        isFocus={isOpen}
                        existValue={value == '' ? false : true}
                        onClick={() => onFocusInput()}
                        isSearch={props.isSearch}
                        isDisabled={props.disabled}
                        buttonAddNew={props.buttonAddNew}
                        isYesNo={props.yesNo}
                        isModal={props.isModal}
                    > 
                        {props.label || props.placeholder} 
                    </Label>

                    
                    {props.yesNo === true ? (
                        <>
                        
                            {props.responsive ? (
                                <YesNoContainerMobile responsive={props.responsive} disabled={props.disabled} loading={props.loading}> <span>{props.messageYesNo}</span> <Interruptor value={yesNoValue} onChange={(e) => setYesNoValue(e)}/> </YesNoContainerMobile>

                            ) : (
                                <YesNoContainer 
                                    yesNoLeft={props.yesNoLeft} 
                                    showDisabled={props.showDisabledYesNo} 
                                    disabled={props.disabled} 
                                    loading={props.loading}
                                    // responsive={props.responsive}
                                > 
                                    <span style={{ marginRight: '5px' }}>
                                        {props.messageYesNo}
                                    </span> 
        
                                    <Interruptor 
                                        value={yesNoValue} 
                                        onChange={(e) => setYesNoValue(e)}
                                    /> 
                                </YesNoContainer>
                            )}
                        </>
                    ) : (null)}

                    {props.yesNo === true ? (
                        <YesNoContainerMobile disabled={props.disabled} loading={props.loading}> <span>{props.messageYesNo}</span> <Interruptor value={yesNoValue} onChange={(e) => setYesNoValue(e)}/> </YesNoContainerMobile>
                    ) : (null)}

                    <input  
                        ref={ref} 
                        {...props} 
                        value={value.label || value || ''}
                        autocomplete='off'
                        style={{
                            width: '100%',
                            height: '50px',
                            padding: '13px 65px 12px 25px',
                            borderTopLeftRadius: '25px',
                            borderTopRightRadius: '25px',
                            borderBottomLeftRadius: isOpen ? '0px' : '25px',
                            borderBottomRightRadius: isOpen ? '0px' : '25px',
                            border: props.erro ? 'solid 1px #ff5252' : props.isSearch ? 'none' : props.disabled ? 'solid 1px #cfd5df' : 'solid 1px #bac1cf',
                            backgroundColor: props.isSearch ? '#f3f3f5' : '#FFF',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            color: props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#303848',
                            transition: 'all 0.15s ease-in',
                            position: 'relative',
                        }}
                        onChange={(e) => changeInput(e)}
                        onBlur={(e) => close(e.target)}
                        onFocus={() => onFocusInput()}
                    ></input> 
                </div>

                <div className='addnew'>
                    {props.buttonAddNew === true ? (
                        <ButtonAddNew responsive={props.responsive} isModal={props.isModal} onClick={() => props.onClickAddNew()}> Adicionar novo </ButtonAddNew>
                    ) : (null)}
                    </div>
            </div>

            {props.loading === true ? (
                <>
                    <Loading 
                        addNew={props.buttonAddNew}
                    />
                </>
            ) : (
                <>
                    {props.disabled === true ? (null) : (
                        <>
                            <Arrow 
                                yesNo={props.yesNo} 
                                erro={props.erro} 
                                isOpen={isOpen} 
                                addNew={props.buttonAddNew}
                                isClearable={props.isClearable} 
                                disabled={props.disabled}
                                onClick={() => clickArrow()}
                            >
                                <img 
                                    className="arrow-select" 
                                    src={ArrowIcon} 
                                    alt='arrow' 
                                    onClick={() => clickArrow()}
                                />
                            </Arrow>
                
                            {
                                props.solicitar === true ? (
                                    <>
                                        {
                                            solicitaP === true ? (
                                                <Plus>
                                                    <p id="msg">Solicitar produto(s)</p>
                                                    <img 
                                                        className="add-select" 
                                                        src={DeleteIcon} 
                                                        alt='add'
                                                        onClick={() => solicitarProduto()}
                                                        width='10px'
                                                    />
                                                </Plus>
                                            ) : (
                                                <>
                                                    {
                                                        props.isClearable ? (
                                                            <Clearabled erro={props.erro} isOpen={isOpen} disabled={props.disabled}>
                                                                <img 
                                                                    className="delete-select" 
                                                                    src={DeleteIcon} 
                                                                    alt='delete'
                                                                    onClick={() => clear()}
                                                                    width='10px'
                                                                />
                                                            </Clearabled>
                                                        ) : (
                                                            null
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                ) : (
                                    <>
                                        {
                                            props.isClearable ? (
                                                <Clearabled erro={props.erro} isOpen={isOpen} disabled={props.disabled}>
                                                    <img 
                                                        className="delete-select" 
                                                        src={DeleteIcon} 
                                                        alt='delete'
                                                        onClick={() => clear()}
                                                        width='10px'
                                                    />
                                                </Clearabled>
                                            ) : (
                                                null
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    )}
                </>
            )}

            {props.help === true ? (
                <div className="help-buttom" onClick={() => props.helpClick()}>
                    <IconHelp 
                        fill='#5DA8FD'
                        cursor='pointer'
                    />
                </div>
            ) : (null)}


            {isOpen && (
                <Options isOpen={isOpen} isSearch={props.isSearch} isClearable={props.isClearable} erro={props.erro} marginBottom={props.marginBottom}>
                    <>
                        {selectOptions.length > 0 ? (
                            <>
                                {selectOptions.map((op, i) => (
                                    <>
                                        {op && i < limit && (
                                            <>
                                                <Option isSearch={props.isSearch} key={i} erro={props.erro} onClick={() => onChange(op)}>
                                                    {op.label}
                                                    {op.label && props.searchPhoto ? (
                                                        <div 
                                                            className="button-foto" 
                                                            style={{ 
                                                                width: '50px', 
                                                                display: 'flex', 
                                                                flexDirection: 'row', 
                                                                justifyContent: 'space-evenly',
                                                                alignItems: 'center', 
                                                            }}
                                                        >
                                                            <Button 
                                                                width='100%'
                                                                value='Foto'
                                                                outline={true}
                                                                disabled={false}
                                                                onClick={() => searchGoogleImages(op.label)}
                                                                loading={false}
                                                            />
                                                        </div>
                                                    ) : (null)}
                                                </Option>

                                                {i === (limit-3) ? (
                                                    <div 
                                                        id='loading-options'
                                                    ></div>
                                                ) : (null)}
                                            </>
                                        )}

                                    </>     
                                ))}
                                
                            </>
                        ) : (
                            <NoOptions>
                                Não há resultados
                            </NoOptions>
                        )}
                    </>
                </Options>
            )}
            
            {props.erro && props.messageErro ? (
                <Legenda>{props.messageErro}</Legenda>
            ) : (<>
                {props.legenda && (
                    <Legenda>{props.legenda}</Legenda>
                )}
            </>)}

            <ModalAlert
                show={showModalSolicitar}
                onHide = {() => setModalSolicitar(false)}
                title="Solicitar Produto"
                size='lg'
            >
                <p style={{
                    width: '100%',
                    textAlign: 'center',
                    fontSize: '22px',
                    fontWeight: 'bold'
                }}>Solicitar um ou mais produto(s)</p>

                <Solicitar isModal={true} closeModalS={() => solicitarSucesso()}/>
            </ModalAlert>

            <ModalAlert
                show={showModalBlock}
                onHide = {() => setModalBlock(false)}
                title="Solicitar Produto"
            >
                <p style={{
                    fontFamily: 'Lato', 
                    fontSize: '22px', 
                    fontWeight: 'bold', 
                    fontStretch: 'normal', 
                    width: '100%', 
                    textAlign: 'center', 
                    alignSelf: 'center'
                }}>
                    Essa função não está liberada para o seu plano
                </p>

                <div 
                    style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}
                >
                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}> 
                        Faça um upgrade para desbloquear esse recurso
                    </p>

                    <div 
                        style={{ 
                            width: '100%', 
                            maxWidth: '350px', 
                            margin: 'auto',
                            marginTop: '20px', 
                            alignSelf: 'center', 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            width='296px'
                            height='50px'
                            value='VER PLANOS'
                            outline={false}
                            disabled={false}
                            onClick={() => {
                                setModalBlock(false);
                                window.location = '/planos';
                            }}
                            loading={false}
                        />
                    </div>
                </div>
            </ModalAlert>
        </SelectContainerV2>
    );
}

export default SelectV2;
