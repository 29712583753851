import React from "react";

import { store } from "react-notifications-component";
import GenericUpper from "../../components/generic-upper";
import Input from "../../components/input/input_v2";
import Button from "../../components/button/button_v2";
import api from "../../services/api";
import { FormContainerEdit, ContainerForm, Label} from "../../components/creas/creas/styles";
import Select from "../../components/select/select_v2";
import ModalUpload from '../../components/modal/modalUploadCertificado';
import ModalHomolog from '../../components/modal/modalGeneric';
import DotsLoader from "../../components/loader";
import CardUser from "../../components/card-user";
import ModalGeneric from '../../components/modal/modalGeneric'
import CardUserCfta from "../../components/card-user-cfta";

export const USER = "@userinfo";

class EditCrea extends React.Component {
    state = {
        tipos: '',
        tipoId: '',
        estadoId: '',
        numeroCrea: '',
        receitaInicial: '',
        receitasEmitidas: '',
        status: '',
        creasId: '',
        filial: '',
        OpStatus: [{ value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }],
        id: '',
        estados: [
            { value: "NACIONAL", label: "Nacional" },
            { value: "SP", label: "São Paulo" },
            { value: "RJ", label: "Rio de Janeiro" },
            { value: "SC", label: "Santa Catarina" },
            { value: "AC", label: "Acre" },
            { value: "AL", label: "Alagoas" },
            { value: "AP", label: "Amapa" },
            { value: "AM", label: "Amazonas" },
            { value: "BA", label: "Bahia" },
            { value: "CE", label: "Ceará" },
            { value: "DF", label: "Distrito Federal" },
            { value: "ES", label: "Espirito Santo" },
            { value: "GO", label: "Goiás" },
            { value: "MA", label: "Maranhão" },
            { value: "MT", label: "Mato Grosso" },
            { value: "MS", label: "Mato Grosso do Sul" },
            { value: "MG", label: "Minas Gerais" },
            { value: "PA", label: "Pará" },
            { value: "PB", label: "Paraíba" },
            { value: "PR", label: "Paraná" },
            { value: "PE", label: "Pernambuco" },
            { value: "PI", label: "Piaui" },
            { value: "RN", label: "Rio Grande do Norte" },
            { value: "RS", label: "Rio Grande do Sul" },
            { value: "RO", label: "Rondônia" },
            { value: "RR", label: "Roraima" },
            { value: "SE", label: "Sergipe" },
            { value: "TO", label: "Tocantins" },
            { value: "", label: "" }
        ],
        cpf: '',
        tiposAssinatura: [
            {value: 'M', label: 'Manual'},
            {value: 'D', label: 'Digital'},
            {value: 'E', label: 'Eletrônica'}
        ],
        assinaturaId: {},
        texto: '',
        showModal: false,
        assinaturaDefult: {},
        loadAssinaturaDefult: false,
        homologar_automatico: {},
        showModalHomolog: false,
        showModalNotHomolog: false,
        loadingHomolog: false,
        loading: false,
        optionsHomolog: [
            {value: 0, label: 'Não'},
            {value: 1, label: 'Sim'},
        ],
        isFreeUser: false,
        usuarios: [],
        newUsuarios: [],
        blockSign: false,
        showModalBlockSign: false,
        
        usuarioSc: '',
        senhaSc: '',
        usuarioEs: '',
        senhaEs:'',
    };

    componentDidMount() {

        console.log(this.props)
        this.getCreas();
        const id = this.props.match.params.id;
        this.setState({ id });

        this.setState({
            tipos: [{
                value: 'crea', label: 'CREA'
            }, {
                value: 'visto', label: 'VISTO'
            }, {
                value: 'cfta', label: 'CFTA'
            }]
        })

        let user = JSON.parse(localStorage.getItem(USER));

        user.contrato.agro_plan_functions.map((e) => {
            if (e.agro_plans_function.id === 9) {
                if (e.status === 0) {
                    this.setState({ blockSign: true });
                }
            }
        });

        if (user.contrato.agro_plan_config.is_teste === 1) {
            this.setState({ isFreeUser: true });
        }
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    getUsersStates = async (id) => {
        try {
            const response = await api.get(`/agronomos/usuario/${id}`);

            if (response) {
                let users = response.data.data;

                this.setState({ usuarios: users });

            }
        } catch (error) {
            
        }
    }

    getCreas = async () => {

        try {
            const id = this.props.match.params.id;
    
            const response = await api.get(`/creas/${id}`);
    
            const creas = response.data.data;
    
    
            let tipoId = creas.tipo;
            let numeroCrea = creas.crea;
            let estadoId = creas.estado;
            let idAgronomo = creas.agronomo.id;
            let cpfAgronomo = creas.agronomo.cpf;
            let status = creas.status;
            let creasId = creas.id;
            let receitaInicial = creas.receita_inicial;
            let receitasEmitidas = creas.receitas_emitidas;
            let nomeFilial = creas.agronomo.filial.nome_fantasia;
            let assinatura = creas.assinatura;
            let homolog = creas.homologar_automatico;
    
            this.setState({ tipoId });
            this.setState({ numeroCrea });
            this.setState({ estadoId });
            this.setState({ id: idAgronomo });
            this.setState({ cpf: cpfAgronomo });
            this.setState({filial: nomeFilial})
            this.setState({ status });
            this.setState({ creasId });
            this.setState({ receitaInicial });
            this.setState({ receitasEmitidas });

            this.getUsersStates(idAgronomo);

            if (homolog === 0) {
                this.setState({ homologar_automatico: {value: homolog, label: 'Não'}});
            } else {
                this.setState({ homologar_automatico: {value: homolog, label: 'Sim'}});
            }

            this.getTiposAssinatura(estadoId);

            if (assinatura === 'M') {
                this.selectAssinatura({value: 'M', label: 'Manual'});

            } else if (assinatura === 'D') {
                this.setState({ assinaturaId: {value: 'D', label: 'Digital'} })

                this.setState({texto: 'Assinatura Digital é aquela que precisa de um certificado A1 válido. Aceita nos estados MT, PR, SC, RS, GO, MG, ES, SP e MS.'});

            } else if (assinatura === 'E') {
                this.selectAssinatura({value: 'E', label: 'Eletrônica'});
            }
        } catch (error) {
        }
    }

    getTiposAssinatura = async (estado) => {
        try {
            this.setState({ tiposAssinatura: [] });
            const response = await api.get(`/sign/estado/${estado.toUpperCase()}`);

            const tipos = response.data.data;
            let tipo = []

            if (tipos.manual === true) {
                tipo = [...tipo, {value: 'M', label: 'Manual'}];
            } 
            if (tipos.digital === true) {
                tipo = [...tipo, {value: 'D', label: 'Digital'}]; 
            }
            if (tipos.eletronica === true) {
                tipo = [...tipo, {value: 'E', label: 'Eletrônica'}]; 
            }

            this.setState({ tiposAssinatura: tipo });


        } catch (error) {
        }
    }

    addCrea = async e => {
        this.setState({ loading: true });

        const {
            estadoId,
            numeroCrea,
            tipoId,
            id,
            status,
            receitaInicial,
            creasId,
            receitasEmitidas,
            filial,
            homologar_automatico,
            newUsuarios,
            usuarios,
            usuarioEs,
            senhaEs,
            usuarioSc,
            senhaSc
        } = this.state;


        let inicial;
        let emitidas;

        if ((!receitaInicial) && (!receitasEmitidas)) {
            inicial = 0;
            emitidas = 0;
        } else {
            inicial = receitaInicial;
            emitidas = receitasEmitidas;
        }

        const payload = {
            estado: estadoId,
            crea: numeroCrea,
            tipo: tipoId,
            filial,
            agro_agronomist_id: id,
            receita_inicial: inicial,
            status,
            assinatura: this.state.assinaturaId.value,
            homologar_automatico: (
                estadoId === 'MT' || estadoId === 'SC' || estadoId === 'ES' || estadoId === 'GO' || estadoId === 'NACIONAL'
            ) && this.state.isFreeUser === false ? 1 : 0,
        }

        try {
            const response = await api.put(`/creas/${creasId}`, payload);

            store.addNotification({
                title: "Sucesso!",
                message: `O ${tipoId} foi salvo`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            })

            if (response) {

                if (estadoId == 'NACIONAL') {
                    if (usuarioEs !== '' && senhaEs !== '') {
                        const responseUsersES = await api.post(`/agronomos/usuario/${id}/ES`, {
                            usuario: usuarioEs,
                            senha: senhaEs
                        });
                    }
                    if (usuarioSc != '' && senhaSc != '') {
                        const responseUsersSC = await api.post(`/agronomos/usuario/${id}/SC`, {
                            usuario: usuarioSc,
                            senha: senhaSc
                        });
                    }

                    this.setState({ loading: false });
                    this.props.history.push(`/agronomos/${id}`);
                } else {
                    if (newUsuarios !== [] && newUsuarios.length !== 0) {
                        const responseUsers = await api.post(`/agronomos/usuario/${id}/${estadoId}`, newUsuarios[estadoId.toLocaleLowerCase()]);
    
                        if (responseUsers) {
                            this.setState({ loading: false });
                            this.props.history.push(`/agronomos/${id}`);
                        }
    
                    } else {
                        this.setState({ loading: false });
                        this.props.history.push(`/agronomos/${id}`);
                    }
                }
            }


        } catch (err) {
            this.setState({ loading: false });

            if (err.response.status === 412) {
                this.setState({showModalHomolog: true});
            } else {
                const errors = err.response.data.errors;
                for (let field in errors) {
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Verifique o campo: ${field}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });

                    if (field === 'assinatura') {
                        const input = document.getElementById(field);
                        input.classList.add("error");
                    }
                }
            }
        }
    }

    addCrea2 = async e => {
        const {
            estadoId,
            numeroCrea,
            tipoId,
            id,
            status,
            receitaInicial,
            creasId,
            receitasEmitidas,
            filial,
            homologar_automatico,
        } = this.state;


        let inicial;
        let emitidas;

        if ((!receitaInicial) && (!receitasEmitidas)) {
            inicial = 0;
            emitidas = 0;
        } else {
            inicial = receitaInicial;
            emitidas = receitasEmitidas;
        }

        const payload = {
            estado: estadoId,
            crea: numeroCrea,
            tipo: tipoId,
            filial,
            agro_agronomist_id: id,
            receita_inicial: inicial,
            status,
            assinatura: this.state.assinaturaId.value,
            homologar_automatico: (
                estadoId === 'MT' || estadoId === 'SC' || estadoId === 'ES' || estadoId === 'GO' || estadoId === 'NACIONAL'
            ) && this.state.isFreeUser === false ? 1 : 0,            
        }

        try {
            const response = await api.put(`/creas/${creasId}`, payload);

            store.addNotification({
                title: "Sucesso!",
                message: `O ${tipoId} foi salvo`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            })

            await this.sleep(2000)
            window.location.href = `/agronomos/${id}`;

        } catch (err) {
            if (err.response.status === 412) {
                this.setState({showModalHomolog: true});
            } else {
                const errors = err.response.data.errors;
                for (let field in errors) {
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Verifique o campo: ${field}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });

                    if (field === 'assinatura') {
                        const input = document.getElementById(field);
                        input.classList.add("error");
                    }
                }
            }
        }
    }

    validaExistCertificado = async () => {
        const payload = {
            cpf: this.state.cpf
        }
        try {
            const responseCertificado = await api.post('/sign/consulta/', payload);
    
        } catch (error) {   
            this.setState({ showModal: true });
        }
    }

    selectAssinatura = (ass) => {

        if (this.state.blockSign) {
            if (ass.value === 'M') {
                this.setState({texto: 'Assinatura Manual é aquela onde você assina à próprio punho. Aceita em todos os estados brasileiros.'});
            } else if (ass.value === 'D' || ass.value === 'E') {
                this.setState({ showModalBlockSign: true });
            } else {
                this.setState({texto: ''});
            }

            this.setState({ assinaturaId: ass });
        } else {
            if (ass.value === 'M') {
                this.setState({texto: 'Assinatura Manual é aquela onde você assina à próprio punho. Aceita em todos os estados brasileiros.'});
            } else if (ass.value === 'D') {
                this.setState({texto: 'Assinatura Digital é aquela que precisa de um certificado A1 válido. Aceita nos estados MT, PR, SC, RS, GO, MG, ES, SP e MS.'});
                
                this.validaExistCertificado();
            } else if (ass.value === 'E') {
                this.setState({texto: 'Assinatura Eletrônica é aquela que utiliza um conjunto de dados digitais para validação. Aceita em todos os estados brasileiros, com exceção de MT, PR, SC e RS.'});
            } else {
                this.setState({texto: ''});
            }

            this.setState({ assinaturaId: ass });
    
            if (this.state.loadAssinaturaDefult === false) {
                this.setState({ assinaturaDefult: ass });
                this.setState({ loadAssinaturaDefult: true });
            }
        }
    }

    modalUploadClose = (e) => {

        if (e) {
            this.setState({ showModal: false });
            this.selectAssinatura({value: 'D', label: 'Digital'})
        } else {
            this.setState({ showModal: false });
            this.selectAssinatura(this.state.assinaturaDefult);
        }
    }

    homologarReceitas = async () => {
        this.setState({loadingHomolog: true})
        if (this.state.estadoId === 'GO' || this.state.estadoId === 'NACIONAL') {
            try {

                const timeElapsed = Date.now();
                const today = new Date(timeElapsed);

                let dt = today.toISOString();
                dt = dt.split('T');
                const iniDate = dt[0];

                let dateFin = new Date();

                // add a day
                dateFin.setDate(dateFin.getDate() - 6);

                let dtFin = dateFin.toISOString();
                dtFin = dtFin.split('T');
                const dateFinal = dtFin[0];


                const payload = {
                    tecnicoId: this.state.id,
                    data_emissao_fim: iniDate,
                    data_emissao_ini: dateFinal
                }
    
                const response = await api.post(`/sidago/exportar_receitas`, payload);

                if (response) {
                    if (response.status === 200) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `${response.data.message}`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                        this.addCrea2();
                    } else if (response.status === 206){
                        store.addNotification({
                            title: "Sucesso com alertas!",
                            message: `${response.data.message}`,
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                        this.setState({ showModalHomolog: false });

                    }
                }
            } catch (error) {
                this.setState({ showModalHomolog: false });
                if (error.response.data) {
                    if(error.response.data.errors){
                        this.setState({ homologar_automatico: {value: 0, label: 'Não'} });
                        store.addNotification({
                            title: "Erro!",
                            message: `${error.response.data.errors}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        }
    }   

    notSave = () => {
        this.setState({ showModalHomolog: false });
        this.setState({ showModalNotHomolog: true });
        this.setState({ homologar_automatico: {value: 0, label: 'Não'} });
    }

    sendUsersStates = (values) =>{
        if (values['sc']) {
            this.setState({usuarioSc: values['sc'].usuario});
            this.setState({senhaSc: values['sc'].senha});
        }
        if (values['es']) {
            this.setState({usuarioEs: values['es'].usuario});
            this.setState({senhaEs: values['es'].senha});
        }
    }

    goToPlans = () => {
        this.setState({ showModalBlockSign: false })
        window.location = '/planos';
    }

    render() {
        const { 
            estadoId,
            numeroCrea,
            tipoId,
            tipos,
            status,
            OpStatus,
            receitaInicial,
            receitasEmitidas,
            isFreeUser,
            usuarios,
            
        } = this.state

        return (
            <>
                <GenericUpper
                    title="Edite o CREA/Visto/CFTA"
                ></GenericUpper>
                {estadoId === 'RJ' ? (

                    <FormContainerEdit style={{marginTop:"40px",     marginBottom: '105px'}}>
                        <ContainerForm>
                            <form onSubmit={this.addCrea}>
                                <Select
                                    style={{marginTop: "-153px"}}
                                    name="estado"
                                    className="select"
                                    label="UF de aplicação"
                                    id="estado"
                                    value={estadoId}
                                    isClearable
                                    onChange={estadoId =>
                                        this.setState({ estadoId: estadoId.value })
                                    }
                                    disabled
                                    options={this.state.estados}
                                />

                                <Input
                                    name="numeroCrea"
                                    value={numeroCrea}
                                    type="text"
                                    label="Número do Crea/Visto/CFTA"
                                    id="numeroCrea"
                                    disabled
                                    onChange={e => this.setState({ numeroCrea: e })}
                                />

                                <Select
                                    name="tipo"
                                    className="select"
                                    label="Tipo"
                                    id="tipo"
                                    value={tipoId}
                                    isClearable
                                    onChange={tipoId =>
                                        this.setState({ tipoId: tipoId.value })}
                                    options={tipos}
                                    disabled
                                />

                                {estadoId === 'RJ' ? <>
                                    <Input
                                        name="receitaInicial"
                                        type="text"
                                        label="Receita Inicial"
                                        id="receitaInicial"
                                        value={receitaInicial}
                                        onChange={e => this.setState({ receitaInicial: e })}
                                        disabled
                                    />

                                    <Input
                                        name="receitasEmitidas"
                                        type="text"
                                        label="Receitas Emitidas"
                                        id="receitasEmitidas"
                                        value={receitasEmitidas}
                                        onChange={e => this.setState({ receitasEmitidas: e })}
                                        disabled

                                    />
                                </> : null}

                                <Select
                                    name="assinatura"
                                    className="select"
                                    label="Tipo de assinatura preferencial"
                                    id="assinatura"
                                    options={this.state.tiposAssinatura}
                                    value={this.state.assinaturaId.value}
                                    onChange={(e) => this.selectAssinatura(e)}
                                />
                                <label 
                                    style={{ 
                                        textAlign: 'center', 
                                        width: '100%', 
                                        maxWidth: '400px',
                                        fontSize: '11px',
                                    }}
                                > {this.state.texto} </label>

                                <Select
                                    name="status"
                                    value={status}
                                    type="text"
                                    label="Status"
                                    id="status"
                                    onChange={status => this.setState({ status: status.value })}
                                    options={OpStatus}
                                />

                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                    <Button 
                                        width='100%'
                                        height='50px'
                                        value='SALVAR'
                                        outline={false}
                                        disabled={false}
                                        onClick={() => this.addCrea()}
                                        loading={this.state.loading}
                                    />
                                </div>

                            </form>
                        </ContainerForm>
                    </FormContainerEdit>

                ):(
                    <FormContainerEdit style={{marginTop:"40px"}}>
                        <ContainerForm>
                            <form>
                                <Select
                                    name="estado"
                                    className="select"
                                    label="UF de aplicação"
                                    id="estado"
                                    value={estadoId}
                                    isClearable
                                    onChange={estadoId =>
                                        this.setState({ estadoId: estadoId.value })
                                    }
                                    disabled
                                    options={this.state.estados}
                                />

                                <Input
                                    name="numeroCrea"
                                    value={numeroCrea}
                                    type="text"
                                    label="Número do Crea/Visto/CFTA"
                                    id="numeroCrea"
                                    disabled
                                    onChange={e => this.setState({ numeroCrea: e })}
                                />

                                <Select
                                    name="tipo"
                                    className="select"
                                    label="Tipo"
                                    id="tipo"
                                    value={tipoId}
                                    isClearable
                                    onChange={tipoId =>
                                        this.setState({ tipoId: tipoId.value })}
                                    options={tipos}
                                    disabled
                                />

                                <Select
                                    name="assinatura"
                                    className="select"
                                    label="Tipo de assinatura preferencial"
                                    id="assinatura"
                                    options={this.state.tiposAssinatura}
                                    value={this.state.assinaturaId.value}
                                    onChange={(e) => this.selectAssinatura(e)}
                                />

                                <label 
                                    style={{ 
                                        textAlign: 'center', 
                                        width: '100%', 
                                        maxWidth: '400px',
                                        fontSize: '11px',
                                        marginTop: '-40px',
                                        marginBottom: '30px'
                                    }}
                                > {this.state.texto} </label>

                                <Select
                                    name="status"
                                    value={status}
                                    type="text"
                                    label="Status"
                                    id="status"
                                    onChange={status => this.setState({ status: status.value })}
                                    options={OpStatus}
                                />

                                {(estadoId === 'SC' || estadoId === 'ES' || estadoId === 'NACIONAL') && (
                                    <>                                        
                                        {
                                            estadoId == "NACIONAL" ? (
                                                <CardUserCfta 
                                                    estado={estadoId}
                                                    users={usuarios}
                                                    update={(values) => this.sendUsersStates(values)}
                                                />
                                            ) : (
                                                <CardUser 
                                                    estado={estadoId}
                                                    users={usuarios}
                                                    update={(values) => this.setState({newUsuarios: values})}
                                                />
                                            )
                                        }
                                        
                                    </>
                                )}
                                

                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                    <Button 
                                        width='100%'
                                        height='50px'
                                        value='SALVAR'
                                        outline={false}
                                        disabled={false}
                                        onClick={() => this.addCrea()}
                                        loading={this.state.loading}
                                    />
                                </div>

                            </form>
                            <ModalUpload 
                                show={this.state.showModal}
                                onHide={(e) => this.modalUploadClose(e)}
                                cpf={this.state.cpf}
                            />
                            
                            <ModalHomolog
                                show={this.state.showModalHomolog}
                                onHide={(e) => this.modalUploadClose(e)}
                                cpf={this.state.cpf}
                            >
                                <div className="container">
                                    <h4 style={{
                                        textAlign: 'center'
                                    }}>Você ainda possui receitas pendentes de envio para a Agrodefesa.</h4>
                                    <p style={{
                                        textAlign: 'center'
                                    }}>Para começar a enviar automaticamente é necessário remeter todas as suas receitas. Deseja fazer isso agora?</p>

                                    <div className="btns" style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center'
                                    }}>
                                        {this.state.loadingHomolog === false ? (
                                            <>

                                                <div className="btnsEnd" style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                                                    <Button 
                                                        width='30%'
                                                        height='50px'
                                                        value='SIM'
                                                        outline={true}
                                                        disabled={false}
                                                        onClick={() => this.homologarReceitas()}
                                                        loading={false}
                                                    />
                                                    
                                                    <Button 
                                                        width='30%'
                                                        height='50px'
                                                        value='NÃO'
                                                        outline={true}
                                                        disabled={false}
                                                        onClick={() => this.notSave()}
                                                        loading={false}
                                                        red={true}
                                                    />

                                                </div>

                                            </>
                                        ) : (<>
                                            <DotsLoader show={this.state.loadingHomolog}></DotsLoader>
                                        </>)}
                                    </div>
                                </div>

                            </ModalHomolog>

                                
                            <ModalHomolog
                                show={this.state.showModalNotHomolog}
                                onHide={() => this.setState({ showModalNotHomolog: false, homologar_automatico: {value: 0, label: 'Não'} })}
                            >
                                <div className="container">
                                    <p style={{
                                        textAlign: 'center'
                                    }}><b>Não é possível alterar a forma de envio para a automática enquanto houver receitas pendentes de envio.</b></p>

                                    <div className="btns" style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginTop: '20px',
                                    }}>
                                        <Button 
                                            width='30%'
                                            height='50px'
                                            value='ENTENDI'
                                            outline={true}
                                            disabled={false}
                                            onClick={() => this.setState({ showModalNotHomolog: false, homologar_automatico: {value: 0, label: 'Não'} })}
                                            loading={false}
                                        /> 
                                    </div>
                                </div>

                            </ModalHomolog>

                                
                        </ContainerForm>
                    </FormContainerEdit>
                )}

                <ModalGeneric
                    show={this.state.showModalBlockSign}
                    size='lg'
                    onHide={() => {
                        this.setState({ showModalBlockSign: false, assinaturaId: {value: 'M', label: 'Manual'} });
                    }}
                >
                    <p 
                        style={{
                            fontFamily: 'Lato', 
                            fontSize: '22px', 
                            fontWeight: 'bold', 
                            fontStretch: 'normal', 
                            width: '100%', 
                            textAlign: 'center', 
                            alignSelf: 'center'
                        }}
                    > Essa função está disponível apenas nos planos LIGHT e PRO.</p>

                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Faça o upgrade do seu plano para utilizar assinatura eletrônica e digital</p>
                    
                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Emita as suas receitas direto do campo, otimizando o seu tempo e garantindo a segurança na emissão desses documentos.</p>
                    
                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Utilize o cupom <b>ASSINEJA</b> e garanta 10% de desconto OFF nas mensalidades dos planos Light e Pro do AgroReceita!</p>
                
                    <p style={{
                        color: '#303848', 
                        fontFamily: 'Lato', 
                        fontSize: '16px', 
                        fontWeight: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}>Faça um upgrade para desbloquear esse recurso.</p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                        <Button
                            width='296px'
                            height='50px'
                            value='VER PLANOS'
                            outline={false}
                            disabled={false}
                            onClick={() => this.goToPlans()}
                            loading={false}
                        />
                    </div>
                </ModalGeneric>
            </>)
    };
}
export default EditCrea;
