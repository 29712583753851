import React, { useState, useEffect } from 'react';

import { RowCard } from './styles';
import Input from '../../input/input_v2';
import Select from "../../select/select_v2";
import api from '../../../services/api';
import { object } from 'prop-types';


const Items = ({ ...props }) => {
    const [quantidade, setQuantidade] = useState([]);
    const [embalagem, setEmbalagem] = useState([]);
    const [index, setIndex] = useState('');
    const [embalagens, setEmbalagens] = useState([]);
    const [produto, setProduto] = useState('');

    const formatUnidade = (relations) => {
        let format = [];

        relations = relations.map(rel => {
            const format = {
                value: rel.embalagem_id,
                label: rel.embalagem_unidade
            };

            return format;
        });

        return relations;

    }

    const getEmbalagens = async (rela) => {
        try {
            const response = await api.post(`/sicca/embalagens`,{
                relations: [rela]
            });

            if (response) {
                const embalagem = formatUnidade(response.data.data[0]);
                setEmbalagens(embalagem);
                
            }

        } catch (error) {
            console.log(error);
        }
    }


    const changeEmbalagemValue = (value) => {
        console.log(value, embalagem)
        if (embalagem !== value) {
            setEmbalagem(value);
            props.updateEmbalagem(value, index);
        }
    }

    const handleInputChange = (event) => {
        // Ensure that the entered value is a valid integer
        const value = parseInt(event.target.value, 10);
        if (!isNaN(value)) {
            setQuantidade(value);
            props.updateQuantidade(value, index);
        }
    };

    const changeProduto = (value) => {
        setProduto(value);
        props.updateProduto(value);
    }

    useEffect(() => {
        setIndex(props.index);
        console.log(props);

        if (props.produto !== undefined) {
            changeProduto(props.produto);
        }

        if (props.quantidades !== undefined) {
            setQuantidade(props.quantidades);
        }

        if (props.relation !== undefined) {
            getEmbalagens(props.relation);
        }

        if (props.embalagensValues !== undefined) {
            setEmbalagem(props.embalagensValues);
        }

    }, [])

    return (
        <>
            <RowCard
                isBorder={true}
            >
                <div className='align' style={{
                    width: '100%',
                    height: 'auto'
                }}>
                   <Input
                        name="produto_selecionado"
                        type="text"
                        id={`produtoselecionado${index}`}
                        label="Produto"
                        value={produto}
                        disabled={true}
                        marginBottom='0px'
                    />
                </div>

                <div className='align' style={{
                    width: '100%',
                    height: 'auto',
                    justifyContent: 'space-around'
                }}>
                    <Input
                        name="qtd_produto1"
                        type="number"
                        id={`qtdproduto${index}`}
                        label="Qtdade Produto"
                        legenda={`Quantidade de produto adquirida`}
                        onInput={handleInputChange}
                        value={quantidade}
                        marginBottom='0px'
                    />
                </div>

                <div className='align' style={{
                    width: '100%',
                    height: 'auto'
                }}>
                   <Select
                        name="embalagens"
                        className="select"
                        label="Embalagens"
                        id={`embalagem${index}`}
                        options={embalagens}
                        value={embalagem.value || embalagem}
                        onChange={(e) => changeEmbalagemValue(e)}
                        selectUniqueOption={true}
                        marginBottom='10px'
                    />
                </div>
            </RowCard>
        </>
    )
}

export default Items;