import React, { useState, useEffect, useRef } from 'react';
import { H3, Label, FilterArea, SelectArea, DataFilter, ChartArea, Loading, iconeBotao, IconeBotao, ProdutosNome} from './style';
import api from '../../services/api';
import DateRange from '../date-range';
import { Bar, Doughnut, Pie } from 'react-chartjs-2'
import Button from '../button/button_v2';
import { store } from 'react-notifications-component';
import { formatDate } from '../../services/formatDate';
import IconeBaixar from '../../assets/iconeBaixar.svg';


const ProdutosMaisFrequentesPorPeriodo = ({ ...props }) => {

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 365));

    const [dataInicial, setDataInicial] = useState(priorDate.toISOString().slice(0, 10));
    const [dataFinal, setDataFinal] = useState(today.toISOString().slice(0, 10));

    const [dataGraph, setDataGraph] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [optionsGraph, setOptionsGraph] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const [mostrarBotao, setMostrarBotao] = useState(false);

    const [loadingPdf, setShowLoadingPdf] = useState(false);
    const [loadingXls, setShowLoadingXls] = useState(false);

    const [ref] = useState(useRef());

    const searchInitialData = async () => {
        try {
            const response = await api.post('/relatorios/produtos/grafico', {
                dtInicial: dataInicial,
                dtFinal: dataFinal,
            });

            if (response.data.data.produtos.length > 0) {
                setMostrarBotao(true);
            }else{
                setMostrarBotao(false);
            }
            setDataGraph({
                labels: response.data.data.produtos,
                datasets: [
                    {
                        data: response.data.data.qtdReceitas,
                        backgroundColor: ['#2FBB69', '#50C681', '#63CC8F', '#7DD5A2', '#97DDB4'],
                        //borderColor: 'rgb(47, 187, 105, 1)',
                        borderWidth: 1,
                    },
                ],
            });

            // setOptionsGraph({
            //     responsive: true,
            //     indexAxis: 'y',
            //     scales: {
            //         y: {
            //             ticks: {
            //                 color: 'black',
            //                 font: {
            //                     size: 10,
            //                     color: 'black'
            //                 },
            //                 stepSize: 1,
            //                 mirror: true,
            //             },
            //         },
            //     },
            //     plugins: {
            //         legend: {
            //             position: 'top',
            //         },
            //         title: {
            //             display: true,
            //             text: `Produtos mais frequentes de ${formatDate(response.data.data.dtInicial)} à ${formatDate(response.data.data.dtFinal)}`,
            //         },
            //     },
            // });

            setShowChart(true);
        } catch (error) {
            
        }
    }
    
    const updateData = async () => {
        try {
            const response = await api.post('/relatorios/produtos/grafico', {
                dtInicial: dataInicial,
                dtFinal: dataFinal,
            });

            if (response.data.data.produtos.length > 0 && response.data.data.qtdReceitas.length > 0) {
                setMostrarBotao(true);
            }else{
                setMostrarBotao(false);
            }

            setDataGraph({
                labels: response.data.data.produtos,
                datasets: [
                    {
                        data: response.data.data.qtdReceitas,
                        backgroundColor: ['#2FBB69', '#50C681', '#63CC8F', '#7DD5A2', '#97DDB4'],
                        //borderColor: 'rgb(47, 187, 105, 1)',
                        borderWidth: 1,
                    },
                ],
            });

            // setOptionsGraph({
            //     responsive: true,
            //     indexAxis: 'y',
            //     scales: {
            //         y: {
            //             ticks: {
            //                 color: 'black',
            //                 font: {
            //                     size: 10,
            //                     color: 'black'
            //                 },
            //                 stepSize: 1,
            //                 mirror: true,
            //             },
            //         },
            //     },
            //     plugins: {
            //         legend: {
            //             position: 'top',
            //         },
            //         title: {
            //             display: true,
            //             text: `Produtos mais frequentes de ${formatDate(response.data.data.dtInicial)} à ${formatDate(response.data.data.dtFinal)}`,
            //         },
            //     },
            // });

            setShowChart(true);
        } catch (error) {
            
        }
    }

    const gerarPDF = async () => {
        const payload = {
            dtInicial: dataInicial,
            dtFinal: dataFinal,
            pdf: 1
        }

        try {
            setShowLoadingPdf(true);

            const response = await api.post(`/relatorios/produtos`, payload)


            let win = window.open(response.data.data.link);

            setShowLoadingPdf(false);

        } catch (error) {
            setShowLoadingPdf(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }
    
    const gerarXLS = async () => {
        const payload = {
            dtInicial: dataInicial,
            dtFinal: dataFinal,
            pdf: 0
        }

        try {
            setShowLoadingXls(true);

            const response = await api.post(`/relatorios/produtos`, payload)


            let win = window.open(response.data.data.link);

            setShowLoadingXls(false);

        } catch (error) {
            setShowLoadingXls(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    useEffect(() => {
        searchInitialData();
    }, [])

    useEffect(() => {
        updateData();
    }, [dataInicial, dataFinal])
    
    return (
        <>
            {/* <H3> Produtos mais frequentes por período </H3> */}
            
            {/* <FilterArea>
                <DataFilter>
                    <DateRange
                        id='datarange'
                        initial={dataInicial}
                        final={dataFinal}
                        onChangeInitial={(e) => setDataInicial(e)}
                        onChangeFinal={(e) => setDataFinal(e)}
                        erro={false}
                        disabled={false}
                        loading={false}
                        marginBottom='0px'

                    />
                    
                </DataFilter>
            </FilterArea> */}
            <div>
                <IconeBotao>
                    <div className='card-titulo'><h2 style={{
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontFamily: 'Lato',
                        fontSize: '10px',
                        color: '#8a92a2',
                        paddingTop: '5px',
                        marginBottom: '0px'
                    }}>Produtos mais indicados (%)</h2></div>
                    <div className='icons' style={{
                        display:'flex'
                    }}>    
                        <div className='pdf'>
                            <p id='msgBaixarPdf'>Baixar PDF</ p>
                            <a onClick={()=>gerarPDF()}><img width={20} src={IconeBaixar} /></a>
                        </div>
                    </div>
                </IconeBotao>
                <p style={{marginBottom:'0px',fontSize: '10px'}}>últimos 12 meses</p>
            </div>

            {
                showChart && loadingPdf == false ? (
                    <ChartArea style={{
                        padding: '15px 0px',
                        margin: 'auto',
                        width: '80%',
                    }}> 
                        <Doughnut 
                            data={dataGraph} 
                            height={120} 
                            options={{
                                plugins:{
                                    legend:{
                                        position: "right",
                                        labels: {
                                            // This more specific font property overrides the global property
                                            font: {
                                                size: 12
                                            }
                                        }
                                    },
                                    title: {
                                        display: false,
                                        text: 'Produtos mais recomendados',
                                    },
                                },
                                maintainAspectRatio: false,

                            }}
                        />
                    </ChartArea>
                ) : (
                    <Loading style={{
                        width: '60px',
                        height: '60px'
                    }}/>
                )
            }

        </>
    )
}

export default ProdutosMaisFrequentesPorPeriodo;
