import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import api from "../../services/api";
import { login, logout } from "../../services/auth";
import Logo from "../../assets/logo.png";
import Button from "../../components/button/button_v2";
import ModalSenha from "../../components/modal/modalSenha";
import ModalText2 from "../../components/modal/modalText";
import ModalLoginErrado from "../../components/modal/modal_login_errado";


import { Col2, ContainerForm, ContainerInputsForm, ContainerLogo, Line, LoginWrapper } from "./styles";

import Input from "../../components/input/input_v2";

const flex = {
    display: "flex"
};

class LoginPageoff extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        username: "",
        username2: "",
        password: "",
        error: "",
        error2: "",
        showModal: false,
        showModalText2: false,
        email: '',
        typePassword: "password",
        planId: '',
        loading: false,
        emailGoogle: ''
    };

    handleSocialSignIn = async (email) => {
        try {

            if (!this.state.loading) {
                this.setState({loading: true});

                try {
    
                    const headers = {
                        'apiKey': process.env.REACT_APP_API_KEY
                    }
                    axios.post(`${process.env.REACT_APP_API_HOST}/v1/login`, { username: email, password: null, loginSocial: 1 }, { headers })
                    .then(response => {
                        if (response) {
                            login(response.data.data);
                            window.location = `/compra/?${this.state.planId}`;
                        }
                    }).catch(error => {
                        this.setState({
                            error: "Houve um problema com o login, verifique suas credenciais."
                        });
                    });
                } catch (err) {
                    this.setState({
                        error: "Houve um problema com o login, verifique suas credenciais."
                    });
                }
            }
        } catch (err) {
            this.setState({
                error: "Houve um problema com o login, verifique suas credenciais."
            });

        }

        this.setState({loading: false});
    };

    checkSocialLogin = () => {
        const email = localStorage.getItem('@agroreceita_google_email');
        if (email && !this.state.emailGoogle) {
            this.setState({ emailGoogle: email});
            this.handleSocialSignIn(email);
        }
    }

    componentDidMount(){
        logout();
        this.getParamId();

        setInterval(() => {
            this.checkSocialLogin();
        }, 100);

        setTimeout(() => {

            let aTags = document.getElementsByTagName("span");
            let searchText = "Login";
    
            for (let i = 0; i < aTags.length; i++) {
                if (aTags[i].textContent == searchText) {
                    aTags[i].innerText = 'Continuar com Google';
                    break;
                }
            }
            setInterval(() => {
                let aTags = document.getElementsByTagName("span");
                let searchText = "Login";
        
                for (let i = 0; i < aTags.length; i++) {
                    if (aTags[i].textContent == searchText) {
                        aTags[i].innerText = 'Continuar com Google';
                        break;
                    }
                }
            }, 500);
        }, 300);
    }

    getParamId = () => {
        this.setState({ planId: this.props.match.params.id });
    }

    modalClose = e => {
        this.setState({showModal: false});
    }
    updateResposta = async (resposta, email) => {
        console.log(resposta);
        if (resposta == 10) {
            this.setState({ showModal: false });
            this.setState({ showModalText2: true });
            this.setState({ email });
        }
    }

    updateTextOk2 = async (resposta) => {
        this.setState({ showModalText: false });
        window.location.href = '/login-compra';
    }

    handleSignIn2 = async e => {
        e.preventDefault();
        const { username2 } = this.state;

        if(username2 == ""){
            this.setState({error2: true});
        }else{
            this.setState({error2: false});
            window.location = '/cadastro';
        }
    }

    
    handleSignIn = async e => {
        const { username, password, planId } = this.state;

        this.setState({ loading: true });

        if (!username || !password) {
            this.setState({ error: "Preencha e-mail e senha para continuar!" });
        } else {
            try {
                const response = await api.post("/login", { username, password });
                if(response.data.data.user != 'Usuário já está logado.'){
                    login(response.data.data);
                    window.location.href = `/compra/?${planId}`;
                }
            } catch (err) {
                this.setState({
                    error: "Houve um problema com o login, verifique suas credenciais."
                });
            }
        }

        this.setState({ loading: false });

    };

    modalCloseText3 = e => {
        this.setState({ error: "" })
    }

    updateTextOk3 = async (resposta) => {
        this.setState({ error: "" });
        if (resposta == 0) {
            window.location.href= "https://www.agroreceita.com.br";
        }else{
        }
    }
    
    alterTypePassword = async () => {
        if(this.state.typePassword == "password"){
            this.setState({ typePassword: "text" });
        }else if(this.state.typePassword == "text"){
            this.setState({ typePassword: "password" });
        }
    }


    render() {
        const {showModal} = this.state;
        return (
            <>
                <LoginWrapper>
                    <ModalSenha
                        show={showModal}
                        onHide={this.modalClose}
                        resposta=''
                        update={this.updateResposta}
                        title='Esqueceu a senha?'
                        text='Digite o e-mail ou usuário cadastrado para alterá-la.'
                    ></ModalSenha>
                    {this.state.email ? (
                        <ModalText2
                            show={this.state.showModalText2}
                            senha={true}
                            onHide={this.modalCloseText2}
                            btnOk={true}
                            text={` ${this.state.email} para que você `}
                            update={this.updateTextOk2}
                        />
                    ):(null)}

                    {this.state.error && (
                        <ModalLoginErrado
                            show={this.state.error}
                            onHide={this.modalCloseText3}
                            title={'ACESSO NEGADO'}
                            text={'Confira se o e-mail e senha foram digitados corretamente.'}
                            update={this.updateTextOk3}
                        />
                    )}

                    <Col2>
                        <ContainerLogo>
                            <div className="logo">
                                <a href="https://www.agroreceita.com.br" target="_blank">
                                    <img src={Logo} alt="logo" />
                                </a>
                            </div>
                            <label className="title">Olá, seja bem vindo(a)!</label>
                            <label className="subtitle" >Preencha os campos ao lado para entrar</label>
                        </ContainerLogo>
                    </Col2>


                    <Col2>
                        <ContainerForm>
                            <div className="btnGoogle">
                                <div id='g-signin' className="g-signin2" data-onsuccess="onSignIn" data-onload="true">
                                </div>
                            </div>

                            <Line>
                                <hr/>
                                <span>OU</span>
                            </Line>

                            <ContainerInputsForm>
                                <Input
                                    id='email'
                                    label="E-mail"
                                    name="email"
                                    type="email"
                                    onChange={e => this.setState({ username: e })}
                                    marginBottom='0px'
                                    value={this.state.username}
                                    erro={this.state.error}
                                    messageErro=''
                                />

                                <Input
                                    name="password"
                                    label="Senha"
                                    type='password'
                                    onChange={e =>  this.setState({ password: e })}
                                    marginBottom='0px'
                                    id='senha'
                                    value={this.state.password}
                                    erro={this.state.error}
                                    messageErro=''
                                />

                                <div className='recover'>
                                    <label onClick={e=> this.setState({showModal:true})}> Esqueci minha senha </label>
                                </div>

                                <div className='box-end'>
                                    <label className="link" onClick={() => window.location = '/cadastro'}> Não possuo cadastro </label>
                                    
                                    <div className="button-login" style={{ width: '112px'}}>
                                        <Button 
                                            width='100%'
                                            height='50px'
                                            value='Entrar'
                                            outline={false}
                                            disabled={false}
                                            onClick={() => this.handleSignIn()}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>

                            </ContainerInputsForm>
                        </ContainerForm>
                    </Col2>
                </LoginWrapper>
            </>
        );
    }
}

export default withRouter(LoginPageoff);
