import React, { useState, useEffect, useCallback } from 'react';
// import { Modal } from 'react-bootstrap';
import Button from '../button/index';
import Select from '../select/index';
import Input from '../input/input_v2';
// import 'bootstrap/dist/css/bootstrap.min.css';
import StyleModal from './stylesModalFilter2';
import api from "../../services/api";

const ModalFilter2 = ({ ...props }) => {

    const [dataInicial, setDataInicial] = useState('');
    const [botao, setBotao] = useState(0);
    const [nome, setNome] = useState('');
    const [filtro, setFiltro] = useState([]);

    const changeDataInicial = (value) => {
        setDataInicial(value)
    }

    const changeNome = (value) => {
        setNome(value)
    }

    const updateFilter = () => {

        const respostaFiltro = ({
            dataInicial: dataInicial,
            botao: botao,
            nome: nome
        });

        props.search(respostaFiltro)
        props.closeModal()
        

    }


    return (
        <>
            <StyleModal>
                <Input
                    marginBottom='0px'
                    id="data_inicial"
                    value={dataInicial || ''}
                    type="datetime-local"
                    label="Data da ação"
                    labelUp={true}
                    onChange={(e) => changeDataInicial(e)}
                />

                <div className="btn-filters">
                    <button
                        style={{
                            outline: botao === 1 ? 1 : 0,
                            backgroundColor: botao === 1 ? '#589bff' : 'transparent',
                            color: botao === 1 ? '#FFF' : '#589bff',
                        }}
                        className="btnf"
                        onClick={() => setBotao(1)}
                    >
                        ABERTA
                    </button>

                    <button
                        style={{
                            outline: botao === 2 ? 1 : 0,
                            backgroundColor: botao === 2 ? '#589bff' : 'transparent',
                            color: botao === 2 ? '#FFF' : '#589bff',
                        }}
                        className="btnf"
                        onClick={() => setBotao(2)}
                    >
                        CONCLUIDA
                    </button>

                    <button
                        style={{
                            outline: botao === 3 ? 1 : 0,
                            backgroundColor: botao === 3 ? '#589bff' : 'transparent',
                            color: botao === 3 ? '#FFF' : '#589bff',
                        }}
                        className="btnf"
                        onClick={() => setBotao(3)}
                    >
                        PENDENTE
                    </button>

                    <button
                        style={{
                            outline: botao === 4 ? 1 : 0,
                            backgroundColor: botao === 4 ? '#589bff' : 'transparent',
                            color: botao === 4 ? '#FFF' : '#589bff',
                        }}
                        className="btnf"
                        onClick={() => setBotao(4)}
                    >
                        PERDIDA
                    </button>
                </div>

                <Input
                    marginBottom='0px'
                    id="nome_id"
                    value={nome || ''}
                    label="Nome da Oportunidade"
                    onChange={(e) => changeNome(e)}
                />

                <Button style={{ outline: 0, padding: "15px", margin: "auto" }}
                    outline
                    color="#00b43f"
                    onClick={() => updateFilter()}
                    width="100px"
                >Filtrar</Button>

            </StyleModal>
        </>
    );
};

export default ModalFilter2;