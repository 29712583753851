import styled from "styled-components";

export const AgronomosContainer = styled.section`
  background: #f8f8f9;
  height: 100%;
  margin: auto;
  margin-top: 50px;
  
  ul:first-child{
    margin-top: 15px;
  }

  .search{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -83px;
    margin-bottom: 20px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    
    .limpar{
      display: flex;
      justify-content: space-around;
      .botao{
        margin: 40px 30px 40px 1px;
        padding: 15px 50px;
      }
    .filterbuttons {
      width: 100%;
      max-width: 400px;
      .pesquisar {
        margin: 40px 0;
        padding: 20px 80px;
      }
    }
  }

  .botoesfinais{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin-top: 20px;
    .loadmore {
      margin: 30px 0;
      padding: 15px 20px;
    }
    .relatorio {
      display: flex;
      margin: 30px 0;
      padding: 15px 20px;
    }
  }

  }

  

  @media only screen and (max-width: 768px) {
    padding: 50px 0;
    background: #f8f8f9;
    height: 100%;
    
    ul:first-child{
      margin-top: 15px;
    }
  
    .search{
      display: flex;
      flex-direction: row;
      width: 100%;
      max-width: 375px;
      margin-left: 0px;
      margin-top: -80px;
      
    }
    .add{
      width: 100%;
      max-width: 375px;
      margin-left: 0px;
      margin-top: -12px;
      align-self: center;
      button{
        width: 100%;
        align-self: center;
        max-width: 375px;
        margin-left: 0px;
        border-radius: 30px;
        border: solid 3px #FFF;
      }     
    }
  
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .loadmore {
        margin-top: -20px;
        width: 100%;
        max-width: 375px;
        margin-left: 0px;
        padding: 15px 20px;
      }
      .limpar{
        display: flex;
        justify-content: space-around;
        .botao{
          margin: 40px 30px 40px 1px;
          padding: 15px 50px;
        }
      .filterbuttons {
        width: 100%;
        max-width: 400px;
        .pesquisar {
          margin: 40px 0;
          padding: 20px 80px;
        }
      }
    }
  
    .relatorio {
      display: flex;
      width: 100%;
      justify-content: center;
      max-width: 375px;
      margin-left: 0px;
      padding: 15px;
    }
  
    .add {
      width: 100%;
      max-width: 375px;
      margin-left: 0px;
      align-self: center;
      button {
        align-self: center;
        width: 100%;
        max-width: 375px;
        margin-left: 0px;
        margin: 0px;
        padding: 20px 30px;
      }
    }
    }
  }

`;

export const AgronomosAddContainer = styled.div`
  padding: 50px 0;
  background: #f8f8f9;
  flex-direction:row;

    form {
      margin: auto;
      min-width: 250px;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .radios{
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      label{
        width: 100%;
        padding-left: 5px;
        font-size: 11px;
        font-family: Lato;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        -webkit-letter-spacing: normal;
        -moz-letter-spacing: normal;
        -ms-letter-spacing: normal;
        letter-spacing: normal;
        color: #586377;
        display: flex;
        align-items: center;
        margin-bottom: 0px;
      }

      input[type='radio'] {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #2FBB69;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }
      input[type='radio']:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }

      input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #2FBB69;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }
    }

      .input-wrapper{
        width: 90%;
      }
      .arquivos{
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      label{
        font-size: smaller;
        width: 100%;
        margin-left: 5px;
        text-align: center;
        color: #8a92a2;
      }
      .btn-update{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        border: dashed 1px #8a92a2;
        border-radius: 8px;
      }
      .arq-name{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          transform: rotate(45deg);
          z-index: 10;
        }

        img:hover{
          cursor: pointer;
        }
      }
      }
      .legenda {
        font-size: smaller;
        width: 100%;
        margin-left: 5px;
      }
    }
  .salvar {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -5%;
    .salvado {
      width: 30%;
    }
    .add {
      width: 60%;
    }
  }
  .addcrea{
    width: 100%;
    height: 50px;
    max-width: 400px;
    margin-bottom: 60px;
  }

  @media only screen and (max-width: 989px){
    .salvar {
      flex-direction: column-reverse;

      .salvado {
        width: 100%;
      }
      
      .add {
        width: 100%;
        height: 50px;
      }
    }

      .crea{
        width: 100%;
        justify-content: center;
        padding: 30px;
        padding: 0px;
        align-items: flex-end;
        .new{
          width: 82%;
          padding: 18px 80px;
          margin: auto;
          margin-top: 26px;
        }
      }
  }

  @media only screen and (max-width: 500px){
    .salvar {
      flex-direction: column-reverse;

      .salvado {
        width: 100%;
      }

      .add {
        width: 100%;
        height: 50px;
      }

    }

      .crea{
        width: 100%;
        justify-content: center;
        padding: 30px;
        padding: 0px;
        align-items: flex-end;
        .new{
          width: 86%;
          padding: 15px 80px;
          margin: auto;
          margin-top: 26px;

        }
      }
  }

  @media only screen and (max-width: 472px){
    .salvar {
      flex-direction: column-reverse;

      .salvado {
        width: 100%;
      }
      .add {
        width: 100%;
        height: 50px;
      }
    }

      .crea{
        width: 100%;
        justify-content: center;
        padding: 30px;
        padding: 0px;
        align-items: flex-end;
        .new{
          width: 90%;
          padding: 18px 80px;
          margin: auto;
          margin-top: 26px;

        }
      }
 
  }


  @media only screen and (max-width: 432px){
    .salvar {
      flex-direction: column-reverse;

      .salvado {
        width: 100%;
        }
      }

      .crea{
        width: 100%;
        justify-content: center;
        padding: 30px;
        padding: 0px;
        align-items: flex-end;
        .new{
          width: 100%;
          padding: 11px 80px;
          margin: auto;
          margin-top: 26px;

        }
      }
  }

`;
export const AgronomosnewContainer = styled.div`
padding: 50px 100px ;
background: #f8f8f9;
div {
  display: flex;
  justify-content: space-around;
  align-items:flex-start;
  left: 180%;
  min-height: 400px;
}
`;
export const Assinaturas = styled.p`
  text-align: center;
  width: 100%;
  max-width: 400px;
  font-size: 11px;
  margin-top: -24px;
  margin-bottom: 14px;
`;