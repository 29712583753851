import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import api from '../../services/api';
import googlemaps from '../../services/googlemaps';
import { apiprevmet3 } from '../../services/inmet_api';
import { AreaInfo, Card, Col5, Loading, Row, Text, Title } from './style';
import TemperaturaMapa from './temperatura_mapa';
export const AGRORECEITA_USER_INFO_LOCATION = '@agroreceita_user_info_location';

const ResumoHoje = ({ ...props }) => {

    const [previsao, setPrevisao] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [objKeys, setObjKeys] = useState([]);


    const getPrevisaoTempo = async (ibge) => {
        try {
            console.log('IBGE: ', ibge);

            const response = await apiprevmet3.get(`/previsao/${ibge}`);

            if (response) {
                let prev = response.data;
                setPrevisao(prev[ibge]);

                let keys = Object.keys(prev[ibge]);
                setObjKeys(keys)
            }
        } catch (error) {
            console.log(error);
            // store.addNotification({
            //     title: "Erro!",
            //     message: "Erro INMET",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }

    const getIBGE = async (cidade, estado) => {
        console.log('GET IBGE FROM ', cidade, estado);

        try {
            const response = await api.post(`/ibge`, {cidade, estado});

            if (response) {
                let ibge = response.data.data.codigo;

                getPrevisaoTempo(ibge);

            } else {
                store.addNotification({
                    title: "Erro!",
                    message: "Erro ao buscar IBGE",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.error}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const saveLocation = async (lat, lng) => {
        const response = await googlemaps.get(`json?latlng=${lat},%20${lng}&sensor=true&key=AIzaSyAn3UaVotrho9E6f_BWtW4wDshxnEmM8LI`);
    
        let address = response.data.results[8];
        const cidade = address.address_components[0].long_name;
        const estado = address.address_components[1].short_name;

        const validade = new Date();

        localStorage.setItem(AGRORECEITA_USER_INFO_LOCATION, JSON.stringify({
            cidade, 
            estado,
            validade
        }));

        setCidade(cidade);
        setEstado(estado);
        getIBGE(cidade, estado);
    }

    const getWeek = async () => {
        try {

            const userInfoLocation = JSON.parse(localStorage.getItem(AGRORECEITA_USER_INFO_LOCATION));
            let lat = '';
            let lng = '';

            if (userInfoLocation) {
                //Validar validade da ultima checagem.
                //Utilizar a rota do google somente uma vez ao dia, para isso foi criado esse esquema de 'cache' para que o sistema leia o endereço salvo em memoria
                const then = new Date(userInfoLocation.validade);
                const now = new Date();
    
                const msBetweenDates = Math.abs(then.getTime() - now.getTime());
                const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

                if (hoursBetweenDates < 24) {
                    const cidade = userInfoLocation.cidade;
                    const estado = userInfoLocation.estado;
    
                    setCidade(cidade);
                    setEstado(estado);
                    getIBGE(cidade, estado);
                } else {
    
                    if('geolocation'in navigator){
                        navigator.geolocation.getCurrentPosition(function(position){
                            lat = position.coords.latitude;
                            lng = position.coords.longitude;
                            
                            saveLocation(lat, lng);
                        },function(error){
                        })
                      }else{
                     }
                }
            } else {
                if('geolocation'in navigator){
                    navigator.geolocation.getCurrentPosition(function(position){
                        lat = position.coords.latitude;
                        lng = position.coords.longitude;
                        
                        saveLocation(lat, lng);
                    },function(error){
                    })
                  }else{
                 }
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getWeek();
    }, [])

    return(
        <Card 
            height="auto"
            // alignItems='felx-start'
        >
            {/* <Row justifyContent='flex-start'>
                <Title>Previsão para {cidade}, {estado}</Title>
            </Row> */}
            

            {objKeys.length > 0 ? objKeys.map((key, i) => (
                <>
                {i == 0 ? (
                    <>
                        <Row justifyContent='flex-start'> 
                            {/* <Text><b>{key}</b></Text> */}
                            <Title><b>Previsão de hoje - {key}</b></Title>
                        </Row>  
                        <hr width="100%"></hr>

                        <Row justifyContent='flex-start'>
                            
                            <AreaInfo>
                                <Title fontSize="20px" marginTop="0px" marginLeft="60px" textAlign="left">{previsao[key].tarde.dia_semana}</Title>
                                <Title textAlign="left" marginTop="0px" marginLeft="60px">Medição realizada as {previsao[key].tarde.hora}:00</Title>
                            </AreaInfo>

                            <AreaInfo>
                                <Row>
                                    <AreaInfo>
                                        <Text><img width="50px" src={`${previsao[key].manha.icone}`}></img></Text>
                                        <Text>Manhã</Text>
                                        <Text>{previsao[key].manha.resumo}</Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo>
                                        <Text><img width="50px" src={`${previsao[key].tarde.icone}`}></img></Text>
                                        <Text>Tarde</Text>
                                        <Text>{previsao[key].tarde.resumo}</Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo>
                                        <Text><img width="50px" src={`${previsao[key].noite.icone}`}></img></Text>
                                        <Text>Noite</Text>
                                        <Text>{previsao[key].noite.resumo}</Text>
                                    </AreaInfo>
                                </Row>
                            </AreaInfo>
                        </Row>

                        <hr width="100%"></hr>


                        <Row justifyContent='flex-start'>
                            <AreaInfo>
                                <Row>
                                    <AreaInfo width="50%">
                                        <Text textAlign="left"><b>Temperatura</b></Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            <img style={{ width: '30px', marginRight: '-10px', transform: 'rotate(180deg)' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAABmJLR0QA/wD/AP+gvaeTAAACE0lEQVR4nO3Xu2sUURgF8PPdCeujkIgEkspiNxEhzZqd6IaAjX+BjaAIirhJ/oR0ESzFYiE4u8WGQMTOQtFC7CQIeRQWCu5dURAsBIsUgq5kPoukGMOMz5l7g5xft/fO/fZw9jkAERERERERERERERXqdNMe8Z0BAIzXZ19QMxHZle0StmpRtwVV8RnHaxkTw72bAlzaeSSNWrs37zOPt1cijLpXFdLZs6yiuLw+N3rXRyYvZYRte1ZjPAFQStn+ApVzG3OVVde5nJdxarF7MghkVYGjP7nskzFmaq1R7joLBsffGdWOHTKBPPxFEQBwLI7jx9WOHXISbJezMuq33x8a6OMBgPJvHikHfb1fadoDReZKclPGgppvh7+uKHDmzw7K9GAJy65+cp2UURvp3QL0/F8evxBGb27kGihD4Y2Hd3rXVbT9r3NU5MrmTGU5j0xZCi1jMrL1GHgGIMhhXN8gnlybPfEih1mpCv2YxKLTyKcIAChtQ+o5zUo1UORwhS4JTBUaVwCz9cPmTlEHU459hsrzPdcOAngFCe4VFhYe/47XWvYdFMdTtl5uzI6OOw8E33et+wzLSGAZCSwjgWUksIwElpHgrwxFnLGTtV44n+8Mm7H+2mmKBG9lqGgzddloy3mYXd7K2JwZewTFNQE+AgAUH6Bycb0x9tRXpn0hXHw77DsDERERERERERER0f/uOxD7gnKnWy8VAAAAAElFTkSuQmCC"></img>
                                            {previsao[key].tarde.temp_min}°
                                        </Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            <img style={{ width: '30px', marginRight: '-10px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAABmJLR0QA/wD/AP+gvaeTAAACEklEQVR4nO3XvWtTURzG8e+5t9YWRHSQGhUc6iK4uKipuergEpssggiKoIj+D24VHJ3c6lApVNwcSqMgbknooIuDghZBEJK20qGgUEp7fy6lXOUW33LPKfJ8xt95ycNzyRuIiIiIiIiIiIiISKGWqid3h84AEIV8cRsjmq8lU6vxjuX5emXcwIXME7SM7uvknmFXAcy4vTCa3AmZJ9iT6NYqN4CJn8Zm2LUDM+3HITIFKaNbT85i9gLoz1leSSN3/uB0s+07l/cyOqNnjjqXtoG9W+9yS+upjRx61vrgLRiey+hWk3302SzG8C83Oz6y5sql580vHqIBHj9AP18qDxLb9G8VAWAME9vTuWp1Z8HRNnkpw8aI+lbiKeDUHx6t7Iq/Tvr6yvVSxsKr0/cxLv7dabs8X0/u9jZRvsIb79SSWw57+M8XmV0vNdqTPYi0pULLWKiNlFOiJhD34LrVCE4MzbTe9OCuXIW+TVLiCr0pAqB/HSv36K5cfUVebi5+RLp23Dl3xLDl7JqDCjCQc+ybwewPe409ON4NrAw+KTJvyJ/jn4DDOUtvSzOtY77zQOA/atuNyshQGRkqI0NlZKiMDJWREbKMNHfqtph7EKwMh5vLXTDee46yKVgZZjzIG0fGuPcwG4KVUWo0G864CSxujDrO3JWhRutlqEzbwuKFc/tDZxARERERERERERH5330H6cWGuszNy3AAAAAASUVORK5CYII="></img> 
                                            {previsao[key].tarde.temp_max}°
                                        </Text>
                                    </AreaInfo>
                                </Row>

                                <Row>
                                    <AreaInfo width="50%">
                                        <Text textAlign="left"><b>Sol</b></Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            {previsao[key].tarde.nascer }
                                        </Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            {console.log(previsao[key])}
                                            {previsao[key].tarde.ocaso}
                                        </Text>
                                    </AreaInfo>
                                </Row>

                                <Row>
                                    <AreaInfo width="50%">
                                        <Text textAlign="left"><b>Estação</b></Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            {previsao[key].tarde.estacao }
                                        </Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                        </Text>
                                    </AreaInfo>
                                </Row>

                                <Row>
                                    <AreaInfo width="50%">
                                        <Text textAlign="left"><b>Umidade</b></Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            <img style={{ width: '30px', marginRight: '-10px', transform: 'rotate(180deg)' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAABmJLR0QA/wD/AP+gvaeTAAACE0lEQVR4nO3Xu2sUURgF8PPdCeujkIgEkspiNxEhzZqd6IaAjX+BjaAIirhJ/oR0ESzFYiE4u8WGQMTOQtFC7CQIeRQWCu5dURAsBIsUgq5kPoukGMOMz5l7g5xft/fO/fZw9jkAERERERERERERERXqdNMe8Z0BAIzXZ19QMxHZle0StmpRtwVV8RnHaxkTw72bAlzaeSSNWrs37zOPt1cijLpXFdLZs6yiuLw+N3rXRyYvZYRte1ZjPAFQStn+ApVzG3OVVde5nJdxarF7MghkVYGjP7nskzFmaq1R7joLBsffGdWOHTKBPPxFEQBwLI7jx9WOHXISbJezMuq33x8a6OMBgPJvHikHfb1fadoDReZKclPGgppvh7+uKHDmzw7K9GAJy65+cp2UURvp3QL0/F8evxBGb27kGihD4Y2Hd3rXVbT9r3NU5MrmTGU5j0xZCi1jMrL1GHgGIMhhXN8gnlybPfEih1mpCv2YxKLTyKcIAChtQ+o5zUo1UORwhS4JTBUaVwCz9cPmTlEHU459hsrzPdcOAngFCe4VFhYe/47XWvYdFMdTtl5uzI6OOw8E33et+wzLSGAZCSwjgWUksIwElpHgrwxFnLGTtV44n+8Mm7H+2mmKBG9lqGgzddloy3mYXd7K2JwZewTFNQE+AgAUH6Bycb0x9tRXpn0hXHw77DsDERERERERERER0f/uOxD7gnKnWy8VAAAAAElFTkSuQmCC"></img>
                                            {previsao[key].tarde.umidade_min }%
                                        </Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            <img style={{ width: '30px', marginRight: '-10px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEMAAABDCAYAAADHyrhzAAAABmJLR0QA/wD/AP+gvaeTAAACEklEQVR4nO3XvWtTURzG8e+5t9YWRHSQGhUc6iK4uKipuergEpssggiKoIj+D24VHJ3c6lApVNwcSqMgbknooIuDghZBEJK20qGgUEp7fy6lXOUW33LPKfJ8xt95ycNzyRuIiIiIiIiIiIiISKGWqid3h84AEIV8cRsjmq8lU6vxjuX5emXcwIXME7SM7uvknmFXAcy4vTCa3AmZJ9iT6NYqN4CJn8Zm2LUDM+3HITIFKaNbT85i9gLoz1leSSN3/uB0s+07l/cyOqNnjjqXtoG9W+9yS+upjRx61vrgLRiey+hWk3302SzG8C83Oz6y5sql580vHqIBHj9AP18qDxLb9G8VAWAME9vTuWp1Z8HRNnkpw8aI+lbiKeDUHx6t7Iq/Tvr6yvVSxsKr0/cxLv7dabs8X0/u9jZRvsIb79SSWw57+M8XmV0vNdqTPYi0pULLWKiNlFOiJhD34LrVCE4MzbTe9OCuXIW+TVLiCr0pAqB/HSv36K5cfUVebi5+RLp23Dl3xLDl7JqDCjCQc+ybwewPe409ON4NrAw+KTJvyJ/jn4DDOUtvSzOtY77zQOA/atuNyshQGRkqI0NlZKiMDJWREbKMNHfqtph7EKwMh5vLXTDee46yKVgZZjzIG0fGuPcwG4KVUWo0G864CSxujDrO3JWhRutlqEzbwuKFc/tDZxARERERERERERH5330H6cWGuszNy3AAAAAASUVORK5CYII="></img> 
                                            {previsao[key].tarde.umidade_max }%
                                        </Text>
                                    </AreaInfo>
                                </Row>

                                <Row>
                                    <AreaInfo width="50%">
                                        <Text textAlign="left"><b>Intencidade dos ventos</b></Text>
                                    </AreaInfo>
                                    
                                    <AreaInfo width="25%">
                                        <Text>
                                            <img style={{ width: '17px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEs0lEQVR4nO2aS2wbVRSG/zN+JVGo0kiVWFAo1E6pTHgodppGogRCKFSCZQQbJBSIbUAgBUTUBVUFC0olHopa1Y6CWEbqig2PpiHQSMhO7YgKYag6CQQqWBSJRpDGjR9zWMRpx9PYM2OPEzvcb3fPnMe9Z+beuTPnAgKBQCAQCP6v0GZ3QI+OyHy7pPCLTNwLYFdevMCgSWKMJULuHyvxX7MJcI/Iru0OfMSEAACpiFoOQDjVmh5K9nvT5cSpyQS4R2RXixNfAnjUiD4BU8ut6afKSUKxzG4qLU76GAYHDwAMPNZ41flBObFqLgEdkfl2gAc14ihBejzdtNycblpuJon7CIgVaDBC/lHZazaevZLOVgXmARTemGiqNd2jebwnvaeT001/O88x0JWX2ZgxAGDITLiaewIA7lO3CNLb683tZL83zeAjhabo0+rpUXMJIGCnur3StBQrppvKZaIa0V1m49XcFEgEPds2Ml7NPQFmaLA7uzWi38z6qNsEuEdkFzHeKRASzpr1U3NTQA/vyWRzg93ZnR/8PtWlnI0xZtafbgJ8YZnNOq066/SImE7OhNw/mXVVt1OgAKZJRVp8sxzTek9AjphGWFo8NBvwZcpxUHdrAIAlAAsAJmzAJ+U89gKBQCAQrKL7T7Amd4I3uQbgMsDTYBpPhDzfmnVQ7wnQctbO2cFYaO+CUYN63wlq6cvCfr4jcmmfvuoqWy0BAGEHMX3eEZF3G1OvY25+GvNbAPVqLk8kgp6Dej7qOgE3YCZ/ZO49BobVYpLQEx/0nCtlujWmABHHA+7DBEypxazQs3qmWyMBAEDECuH9Qhk/ome2dRIAoMGeK/xNzrhDz6Zm/wf4wvI/AG5ba7scuW3fDdz7bymb61mHRFDUIt09TNX/CRJwlYEFBr6SFB6Lv9z2i0HLPwHes9ZayUhdQOm/vqwoXaRe1gl/6EWp+hRgYDuAhwg4zBJd9IflYz1HvzHw5Cna1XsYzMXfWswkUeFbAEwl3wDAxq8BDgaGl27f+ZluEpjGCwXU6xudP7ZuEpipIyIfh6akTlDGb9HVsJnfAh8mgp43dGJPANAUSzGVYxx3ZBAFgIwD+/N3XjN4nIkHPU/qdaLqG6EHP/21xb6idINzR0HkV13KMints4E9F4vZdp36eVcW9vMg7DATk4Ar2ayt8/tX79EtlVV9Clx44e7FRHD3F4sZelhzqMFOLL1UyjYW2rvAEj8Nxl9G4xFwRSF+xsjggQ1cA+Ze86zcUs8HntCzmw20zdiR7QQwoadLwJls1tY5G2ibMdqvDd0HpHKZaKPNqRYZqufnv+8P+sOXDjCk5xh8gIA7AYCB3wk0TVDG48G2abN9qjgB2g1Lumm5+YfnH7hWqd/1yA/Q9CBLUfEUYOCyuu263ri/mK4V9XyrsWANoMLdmULvek8nnVotq+r5VlPxa9B3au4+EF8AYFM5jTH4yNoZnqL1fOD+maBnU2t7luwDfGH5BIBXTAVmGomH3K9bEb8SLHkNplrTQwB/bdiggnq+1ViSgGS/N51qzRwC4QRWDzAXo+J6vtVYvhX2j8peZgzkDy3eON6OtXr+Js95gUAgEAgEgjX+Axe2kswAiZejAAAAAElFTkSuQmCC" />
                                            {previsao[key].tarde.int_vento }
                                        </Text>
                                    </AreaInfo>

                                    <AreaInfo width="25%">
                                        <Text>
                                        </Text>
                                    </AreaInfo>
                                </Row>

                            </AreaInfo>

                            <AreaInfo>
                                <Row>
                                    <AreaInfo width='300px'>
                                        <TemperaturaMapa estado={estado}/>
                                    </AreaInfo>
                                </Row>
                            </AreaInfo>
                        </Row>
                    </>
                ) : (null)}
                
                </>
            )) : (<Loading />)}
        </Card>
    )
}

export default ResumoHoje;

