import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { ContainerModalEdit } from "../edit/styles"
import { store } from "react-notifications-component";
import { Loading } from '../../relatorios/style';
import { IconEdit } from "../../../assets/icons/edit"
import { IconAddAction } from "../../../assets/icons/iconAddAction"
import { IconDeliver } from "../../../assets/icons/iconDeliver"
import ModalGeneric from '../../modal/modalGeneric';
import PutOp from "../edit/putOp"
import PutAcao from "../edit/putAcao"
import Button from '../../button/button_v2';
import InputV2 from '../../input/input_v2';
import SelectV2 from '../../select/select_v2';
import TextArea from '../../textarea/text_area_v2';
import { formatDateTime } from '../../../services/formatDate';
import Table from "../../table";
import TBody from "../../tbody";
import Add from "../../../pages/produtores/add";


const EditOp = ({ ...props }) => {

    const [loading, setLoading] = useState(true)
    const [op, setOp] = useState([])
    const [operacao, setOperacao] = useState('');
    const [showPutClient, setShowPutClient] = useState(false);
    const [showAddAcao, setShowAddAcao] = useState(false);
    const [showPutAcao, setShowPutAcao] = useState(false);
    const [oportunidadeId, setOportunidadeId] = useState(0);
    const [acao, setAcao] = useState(0);
    const [data, setData] = useState('');
    const [erroData, setErroData] = useState(false);
    const [texto, setTexto] = useState('');
    const [tipo, setTipo] = useState('');
    const [tipos, setTipos] = useState([])
    const [loadingTipo, setLoadingTipo] = useState(false);
    const [erroTipo, setErroTipo] = useState(false);
    const [disabledTipo, setDisabledTipo] = useState(false);
    const [resultado, setResultado] = useState('');
    const [resultados, setResultados] = useState([])
    const [loadingResultado, setLoadingResultado] = useState(false);
    const [erroResultado, setErroResultado] = useState(false);
    const [disabledResultado, setDisabledResultado] = useState(false);
    const [salvarAgenda, setSalvarAgenda] = useState(true);
    const [actionEdit, setActionEdit] = useState(false);
    const [idAction, setIdAction] = useState(0);
    const [myHistory, setMyHistory] = useState([])
    const [showHistory, setShowHistory] = useState(false);

    const [showModalProdutor, setShowModalProdutor] = useState(false);
    const [produtorNome, setProdutorNome] = useState('');
    const [produtorEmail, setProdutorEmail] = useState('');
    const [produtorTelefone, setProdutorTelefone] = useState('');
    const [produtorCelular, setProdutorCelular] = useState('');
    const [fazendaNome, setFazendaNome] = useState('');
    const [saveLoading, setSaveLoading] = useState(false);

    const getTipos = async () => {
        try {
            setLoadingTipo(true)
            setErroTipo(false)
            const response = await api.get('/tipo')

            if (response) {
                setTipos(formatTipos(response.data.data))
                setLoadingTipo(false)
            }
        } catch (error) {
            setLoadingTipo(false)
            setErroTipo(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeTipo = (value) => {
        setTipo(value)
        setErroTipo(false)
    }

    const formatTipos = (values) => {
        let tipos = [];

        values.map((tip) => {
            tipos = [...tipos, { value: tip.id, label: tip.descricao }];
        });

        return tipos;
    }

    const getResultados = async () => {
        try {
            setLoadingResultado(true)
            setErroResultado(false)
            const response = await api.get('/resultado')

            if (response) {
                setResultados(formatResultados(response.data.data))
                setLoadingResultado(false)
            }
        } catch (error) {
            setLoadingResultado(false)
            setErroResultado(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeResultado = (value) => {
        setResultado(value)
        setErroResultado(false)

    }

    const formatResultados = (values) => {
        let resultados = [];

        values.map((result) => {
            resultados = [...resultados, { value: result.id, label: result.descricao }];
        });

        return resultados;
    }

    const changeData = (value) => {
        setData(value)
        setErroData(false)
    }

    const changeTexto = (value) => {
        setTexto(value)
    }

    const changeSalvarAgenda = (value) => {
        setSalvarAgenda(value)
    }

    const putClient = () => {
        setShowPutClient(true)
    }

    const putAcao = () => {
        setLoading(false)
        setShowPutAcao(true)
    }

    const addAcao = () => {
        setData('')
        setTexto('')
        setTipo('')
        setResultado('')
        setSaveLoading(false)
        setShowAddAcao(true)

    }

    const closeModal = () => {
        setShowPutClient(false)
        setShowPutAcao(false)
        setShowAddAcao(false)
        setShowHistory(false)
        setMyHistory([])
    }

    const modalHistory = () => {
        setShowHistory(true)
        getHistory()
    }

    const getOportunidadeId = async (oport) => {
        try {

            const response = await api.get(`/oportunidade/${oport}`)

            if (response) {
                setOp(formatOportunidadeId(response.data.data))
                setLoading(false)
                props.get()
            }
        } catch (error) {

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const formatOportunidadeId = (values) => {
        let ops = [];

        values.map((a) => {
            ops = [...ops, {
                id: a.id,
                agro_subsidiary_id: a.agro_subsidiary_id,
                numero: a.numero,
                tipo_oportunidade: a.tipo_oportunidade,
                status: a.status,
                id_status: a.id_status,
                etapa: a.etapa,
                id_etapa: a.id_etapa,
                descricao: a.descricao,
                produtor_id: a.produtor_id,
                produtor_nome: a.produtor_nome,
                produtor_email: a.produtor_email,
                produtor_cpf_cnpj: a.produtor_cpf_cnpj,
                fazenda_id: a.fazenda_id,
                fazenda_nome: a.fazenda_nome,
                fazenda_cpf_cnpj: a.fazenda_cpf_cnpj,
                fazenda_telefone: a.fazenda_telefone,
                fazenda_celular: a.fazenda_celular,
                agro_producer_id: a.agro_producer_id,
                produtor_cliente_nome: a.produtor_cliente_nome,
                produtor_cliente_email: a.produtor_cliente_email,
                produtor_cliente_telefone: a.produtor_cliente_telefone,
                produtor_cliente_celular: a.produtor_cliente_celular,
                produtor_cliente_cpf_cnpj: a.produtor_cliente_cpf_cnpj,
                agro_farm_id: a.agro_farm_id,
                fazenda_cliente_nome: a.fazenda_cliente_nome,
                fazenda_cliente_cpf_cnpj: a.fazenda_cliente_cpf_cnpj,
                data_acao: a.data_acao,
                tipo_acao: a.tipo_acao
            }];
        });

        return ops;
    }

    const getAcao = async (oport) => {

        try {

            if (oport) {
                const response = await api.get(`/oportunidade/acao/${oport}`)


                if (response) {

                    setLoading(false)

                    setAcao(response.data.data)

                    setIdAction(response.data.data.acao.id)
                }
            }

        } catch (error) {
            setAcao(false)
        }

    }

    const saveAcao = async (opid) => {
        try {
            setSaveLoading(true)
            if (!tipo.value || !resultado.value || !data ) {
                if (!tipo.value) {
                    setErroTipo(true)
                    setSaveLoading(false)
                }

                if (!resultado.value) {
                    setErroResultado(true)
                    setSaveLoading(false)
                }

                if (!data) {
                    setErroData(true)
                    setSaveLoading(false)
                }
            }else{
                const payload = {
                    date_time: data,
                    description: texto ? texto : '',
                    type: tipo.value,
                    result: resultado.value,
                    finished: 0,
                    notify_schedule: salvarAgenda ? 1 : 0
                }
                const response = await api.post(`/oportunidade/acao/${opid}`, payload)
    
                if (response) {
                    setShowAddAcao(false)
                    getAcao(props.id)
                    props.getOp()
                }

            }
        } catch (error) {
            setSaveLoading(false)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const finalizarOp = async () => {
        try {

            const response = await api.post(`/oportunidade/cliente/${props.id}`)

            if (response) {
                getOportunidadeId(props.id)
                // props.closeModal()
            }
        } catch (error) {

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const saveHistory = async () => {
        try {

            const response = await api.post(`/oportunidade/historico/${props.id}/${idAction}`)

            if (response) {
                getAcao(props.id)
                props.getOp()
            }
        } catch (error) {

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const getHistory = async () => {
        try {

            const response = await api.get(`/oportunidade/historico/${props.id}`)

            if (response) {
                const historicos = response.data.data

                setMyHistory(formatHistory(historicos))

            }
        } catch (error) {
            setLoading(false)

        }

    }

    const formatHistory = (values) => {
        let history = [];

        values.map((a) => {
            history = [...history, {
                id: a.id,
                opportunity_id: a.opportunity_id,
                action_id: a.action_id,
                date_time_start: a.date_time_start,
                date_time_end: a.date_time_end,
                description: a.description,
                task_type_id: a.task_type_id,
                result_action: a.result_action,
                tipo: a.tipo,
                resultado: a.resultado
            }];
        });

        return history;
    }


    useEffect(() => {
        if (props.id !== 0) {
            getOportunidadeId(props.id)
            setOportunidadeId(props.id)
            getAcao(props.id)
        }

    }, [props.id])

    useEffect(() => {
        getTipos()
        getResultados()

    }, [])

    useEffect(() => {
        if (typeof op[0] !== 'undefined') {
            setProdutorNome(op[0].produtor_nome);
            setProdutorEmail(op[0].produtor_email);
            setProdutorTelefone(op[0].fazenda_telefone);
            setProdutorCelular(op[0].fazenda_celular);
            setFazendaNome(op[0].fazenda_nome);
        }
    }, [op]);



    return (

        <>
            <ContainerModalEdit>
                <div className="CardOp">
                    <div className="headerCard">
                        <div className="titleCard">Dados da Oportunidade</div>
                        <div className="editCard" aria-describedby="tooltip"><IconEdit onClick={() => putClient()} /><p>Editar</p></div>
                    </div>
                    {loading ? (
                        <div><Loading></Loading></div>
                    ) : (
                        op.map(oportunidade => (<>
                            <div className="itemsCard">
                                <div className="itens"><b>N° Oportunidade: </b>{oportunidade.numero}</div>
                                <div className="itens"><b>Status: </b>{oportunidade.status}</div>
                                <div className="itens"><b>Etapa: </b>{oportunidade.etapa}</div>
                            </div>
                            <div className="itemsCard">
                                <div className="itens"><b>Oportunidade: </b>{oportunidade.tipo_oportunidade}</div>
                                <div className="itens"><b>Descrição: </b>{oportunidade.descricao}</div>
                            </div>
                            <div className="itemsCard">
                                <div className="itens"><b>Fazenda: </b>{oportunidade.agro_producer_id ? oportunidade.fazenda_cliente_nome : oportunidade.fazenda_nome}</div>
                            </div>
                            <div className="itemsCard">
                                <div className="itens"><b>Produtor: </b>{oportunidade.agro_producer_id ? oportunidade.produtor_cliente_nome : oportunidade.produtor_nome}</div>
                            </div>
                            <div className="itemsCard">
                                <div className="itens"><b>E-mail: </b>{oportunidade.agro_producer_id ? oportunidade.produtor_cliente_email : oportunidade.produtor_email}</div>
                            </div>
                            <div className="itemsCard">
                                <div className="itens"><b>Telefone: </b>{oportunidade.agro_producer_id ? oportunidade.produtor_cliente_telefone : oportunidade.fazenda_telefone}</div>
                                <div className="itens"><b>Celular: </b>{oportunidade.agro_producer_id ? oportunidade.produtor_cliente_celular : oportunidade.fazenda_celular}</div>
                            </div>
                        </>))

                    )}
                </div>

                <ModalGeneric //Modal de edição de Oportunidade
                    show={showPutClient}
                    onHide={() => closeModal()}
                    size='md'
                >
                    <h2 style={{
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        width: '100%',
                        textAlign: 'center',
                        alignSelf: 'center'
                    }}> Editar Oportunidade </h2>

                    <PutOp
                        id={oportunidadeId}
                        close={() => setShowPutClient(false)}
                        get={() => getOportunidadeId(props.id)}
                    />

                </ModalGeneric>
                <div className="CardAcao">
                    {acao ? (

                        <>
                            <div className="headerCard">
                                <div className="titleCard">Ação da oportunidade</div>
                                <div className="spaceIcons">
                                    <div className="editCard" aria-describedby="tooltip"><IconEdit onClick={() => putAcao()} /><p>Editar</p></div>
                                    <div className="editCard" aria-describedby="tooltip"><IconDeliver onClick={() => saveHistory()} /><p>Finalizar</p></div>
                                </div>
                            </div>
                            <div className="itemsCard">
                                <div className="itens"><b>Data: </b> {formatDateTime(acao.acao.date_time_start)}</div>
                                <div className="itens"><b>Texto: </b>{acao.acao.description}</div>
                            </div>
                            <div className="itemsCard">
                                <div className="itens"><b>Tipo do evento: </b> {acao.tipo.descricao}</div>
                                <div className="itens"><b>Resultado da ação: </b> {acao.result.descricao}</div>
                            </div>
                        </>

                    ) : (
                        <>
                            <div className="headerCard">
                                <div className="titleCard">Ação da oportunidade</div>
                                <div className="editCard" aria-describedby="tooltip"><IconAddAction onClick={() => addAcao()} /><p>Adicionar</p></div>
                            </div>
                            <div className="itemsCard">
                                <div className="nenhuma">A oportunidade não possui ação.</div>
                            </div>
                        </>
                    )}

                </div>
                <ModalGeneric //Modal de edição de Ação
                    show={showPutAcao}
                    onHide={() => closeModal()}
                    size='md'
                >
                    <h2 style={{
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        width: '100%',
                        textAlign: 'center',
                        alignSelf: 'center'
                    }}> Editar Ação </h2>

                    <PutAcao
                        id={oportunidadeId}
                        close={() => setShowPutAcao(false)}
                        get={() => getAcao(oportunidadeId)}
                        getOp={props.getOp}
                    ></PutAcao>

                </ModalGeneric>

                <ModalGeneric //Modal para adicionar nova ação
                    show={showAddAcao}
                    onHide={() => closeModal()}
                    size='md'
                >
                    <h2 style={{
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        width: '100%',
                        textAlign: 'center',
                        alignSelf: 'center'
                    }}> Adicionar Ação </h2>

                    <InputV2
                        marginBottom='0px'
                        id="data"
                        value={data || ''}
                        label="Data"
                        type="datetime-local"
                        labelUp={true}
                        erro={erroData}
                        onChange={(e) => changeData(e)}
                    />

                    <TextArea
                        marginBottom='10px'
                        id="texto"
                        value={texto || ''}
                        name={"descricao"}
                        disabled={false}
                        digitavel={true}
                        label="Texto"
                        maxlength="number"
                        onChange={(e) => changeTexto(e)}
                    />

                    <SelectV2
                        name='tipo'
                        className="select"
                        label="Tipo do Evento"
                        id='tipo'
                        value={tipo.value || tipo}
                        disabled={disabledTipo}
                        loading={loadingTipo}
                        erro={erroTipo}
                        // yesNo={true}
                        // yesNoValue={salvarAgenda}
                        // responsive={true}
                        // messageYesNo='Salvar na Agenda?'
                        onChangeYesNo={(e) => changeSalvarAgenda(e)}
                        onChange={(e) => changeTipo(e)}
                        options={tipos}
                        marginBottom='0px'
                    />
                    <SelectV2
                        name='resultado'
                        className="select"
                        label="Resultado da ação"
                        id='resultado'
                        value={resultado.value || resultado}
                        disabled={disabledResultado}
                        loading={loadingResultado}
                        erro={erroResultado}
                        onChange={(e) => changeResultado(e)}
                        options={resultados}
                    />

                    <div className="div-button" style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '30px', justifyContent: 'space-evenly' }}>
                        <div className="button-finish" style={{ width: '40%', display: 'flex', flexDirection: 'row' }}>
                            <Button
                                width='296px'
                                height='50px'
                                value='SALVAR AÇÃO'
                                outline={false}
                                disabled={false}
                                onClick={() => saveAcao(props.id)}
                                loading={saveLoading}
                            />
                        </div>
                    </div>
                </ModalGeneric>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='VER HISTÓRICO'
                        outline={true}
                        disabled={false}
                        onClick={() => modalHistory()}
                        loading={false}
                    />
                </div>

                {op.map(oportunidade => (
                    !oportunidade.agro_producer_id ? (
                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>
                            <Button
                                width='296px'
                                height='50px'
                                value='CONVERTER PARA CLIENTE'
                                outline={false}
                                disabled={false}
                                onClick={() => setShowModalProdutor(true)}
                                loading={false}
                            />
                        </div>
                    ) : oportunidade.agro_producer_id && oportunidade.id_status !== 2 ? (
                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>
                            <Button
                                width='296px'
                                height='50px'
                                value='FINALIZAR OPORTUNIDADE'
                                outline={false}
                                disabled={false}
                                onClick={() => finalizarOp()}
                                loading={false}
                            />
                        </div>
                    ) : (
                        <></>
                    )
                ))}


                <ModalGeneric
                    show={showModalProdutor}
                    onHide={() => setShowModalProdutor(false)}
                    size={'md'}
                >
                    <Add
                        isModal={true}
                        crm={true}
                        nomeOp={produtorNome}
                        emailOp={produtorEmail}
                        telefoneFz={produtorTelefone}
                        celularFz={produtorCelular}
                        nomeFz={fazendaNome}
                        opId={props.id}
                        getOp={() => getOportunidadeId(props.id)}
                        closeModal={() => setShowModalProdutor(false)}
                    />
                </ModalGeneric>


                <ModalGeneric //Modal do histórico
                    show={showHistory}
                    onHide={() => closeModal()}
                    size='lg'
                >

                    <h2 style={{
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        width: '100%',
                        textAlign: 'center',
                        alignSelf: 'center'
                    }}> Histórico de ações </h2>

                    <Table>
                        <thead>
                            <tr>
                                <th>Descrição</th>
                                <th>Tipo</th>
                                <th>Resultado</th>
                                <th>Data de Inicio</th>
                                <th>Data de término</th>
                            </tr>
                        </thead>
                        <TBody>
                            {myHistory.map(historico => (
                                < tr onClick={(e) => console.log}>
                                    <td style={{fontSize: "14px", fontWeight: "400"}}>{historico.description}</td>
                                    <td>{historico.tipo}</td>
                                    <td>{historico.resultado}</td>
                                    <td>{formatDateTime(historico.date_time_start)}</td>
                                    <td>{formatDateTime(historico.date_time_end)}</td>
                                </tr>
                            ))}
                        </TBody>
                    </Table>

                </ModalGeneric>

            </ContainerModalEdit>
        </>
    );
};

export default EditOp;