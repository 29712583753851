import React, { useState, useEffect } from 'react'
import ArrowIcon from "../../assets/icons/icon-arrow.svg";
import Button from '../button/button_v2';
import InputV2 from '../input/input_v2';
import List from '../list';
import logomini from '../../assets/logo-mini.png';
import cancelaIcon from '../../assets/icons/icons-8-trash.png'
import logobling from '../../assets/logos/bling.png';
import logotiny from '../../assets/logos/tiny.png';
import logoomie from '../../assets/logos/omie.png';
import logocontaazul from '../../assets/logos/contaazul.png';
import logopluga from '../../assets/logos/pluga.png';
import ModalGeneric from '../../components/modal/modalGeneric';

import { store } from "react-notifications-component";

import { Card, Container, Status, Title, Text } from "./style";
import api from '../../services/api';


const CardIntegration = ({ ...props }) => {

    const [open, setOpen] = useState(false);

    const [url, setUrl] = useState('');
    const [headers, setHeaders] = useState([]);
    const [editado, setEditado] = useState(false);
    const [status, setStatus] = useState(0);
    const [load, setLoad] = useState(0);
    const [showDelete, setShowDelete] = useState(false);
    const [deleteIntegrationId, setDeleteIntegrationId] = useState(0);

    const openBox = () => {
        setOpen(!open);
    }

    const changeUrl = (value) => {
        if (value !== url) {
            setUrl(value);
            setEditado(true);
        }
    }

    const changeHeaders = (value) => {
        let h = headers;

        h = [...h, value];

        setHeaders(h);
        setEditado(true);
    }

    const changeStatus = (value) => {

        let v = value ? 1 : 0;
        console.log("V => ", v);
        console.log("STATUS => ", status)
        console.log('load', load)
        
        if(props.status === 0 && load > 0){
            setEditado(true);
            setStatus(v);
        }else{
            if (load < 2) {
                //Foi preciso fazer isso porque o yesno não está funcionando corretamente
                setLoad(load + 1);
                setStatus(props.status);
            } else {
                setEditado(true);
                setStatus(v);
            }

        }
        
    }

    const save = () => {
        const payload = {
            id: props.id,
            url: url,
            function_id: props.functionId,
            status: status,
            header: headers
        }

        // setOpen(false);
        setEditado(false);
        props.save(payload);
    }

    const openDelete = (id) => {
        setDeleteIntegrationId(id);
        setShowDelete(true);
    }

    const deleteIntegration = async () => {
        try {
            if (deleteIntegration !== 0) {
                const response = await api.delete(`/integracao/deletar/${deleteIntegrationId}`);

                if (response) {

                    setShowDelete(false);

                    store.addNotification({
                        title: "Sucesso!",
                        message: response.data.message,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });

                    props.reload();
                }
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: error.response.data.errors,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });

            setShowDelete(false);
        }
    }


    useEffect(() => {
        setUrl(props.url);
    }, [])

    useEffect(() => {
        console.log("UP HEADERS")
        setHeaders(props.headers)

    }, [props.headers])

    useEffect(() => {
        if (showDelete === false) {
            setDeleteIntegrationId(0);
        }
    }, [showDelete])



    return (

        <Card openBox={open}>

            {open ? (
                <>
                    <Container>
                        <Title open={open}>{props.title}</Title>

                        <InputV2
                            id="url_id"
                            name="url"
                            label='URL'
                            value={url}
                            onChange={(e) => changeUrl(e)}
                            yesNo={true}
                            initialYesNoValue={props.status}
                            messageYesNo={'Ativo'}
                            onChangeYesNo={(e) => changeStatus(e)}
                        />

                        <List
                            values={headers}
                            onChange={(e) => changeHeaders(e)}
                            id={props.functionId}
                        />

                        {editado && (
                            <div className="button" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                <Button
                                    width='296px'
                                    height='50px'
                                    value='SALVAR'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => save()}
                                />
                            </div>
                        )}

                        <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBox()} />
                    </Container>

                </>
            ) : (
                <>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <Status status={props.status} />
                        <Title>{props.title}</Title>

                    </div>


                    <div style={{
                        display: 'flex'
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            {props.framework.framework === 'AgroReceita' ? (
                                <img src={logomini} width='25px' alt='Logo Agroreceita'/>
                            ) : (
                                <>
                                    {props.framework.framework === 'Bling' ? (
                                        <img src={logobling} width='40px' alt='Logo Bling'/>
                                    ) : (
                                        <>
                                            {props.framework.framework === 'Tiny' ? (
                                                <img src={logotiny} width='40px' alt='Logo Tiny'/>
                                            ) : (
                                                <>
                                                    {props.framework.framework === 'Omie' ? (
                                                        <img src={logoomie} width='40px' alt='Logo Omie'/>
                                                    ) : (
                                                        <>
                                                            {props.framework.framework === 'Conta Azul' ? (
                                                                <img src={logocontaazul} width='40px' alt='Logo Conta Azul'/>
                                                            ) : (
                                                                <>
                                                                
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}

                            {props.framework.integration_type.id === 2 ? (
                                <>
                                    <img src={logopluga} width='25px' alt='Logo Pluga'/>
                                </>
                            ) : (
                                <>
                                </>
                            )}
                        </div>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginRight: '16px',
                            marginLeft: '16px'
                        }}>

                            <img  className="cancel" onClick={() => openDelete(props.id)} src={cancelaIcon} />
                        </div>

                        <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBox()} />
                    </div>
                </>
            )}


            <ModalGeneric
                show={showDelete}
                size='md'
                onHide={() => setShowDelete(false)}
            >
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Title> Deletar integração </Title>
                    <Text> Deseja continuar com a exclusão da integração? </Text>
                    <Text> Este processo é irreversivel! </Text>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10px', marginBottom: '10px' }}>
                        <Button 
                            width='150px'
                            height='40px'
                            value='CANCELAR'
                            outline={true}
                            disabled={false}
                            onClick={() => setShowDelete(false)}
                            loading={false}
                        />
    
                        <Button 
                            width='150px'
                            height='40px'
                            value='SIM, EXCLUIR'
                            red={true}
                            outline={false}
                            disabled={false}
                            onClick={() => deleteIntegration()}
                            loading={false}
                        />
                    </div>   
                </div>

            </ModalGeneric>



        </Card>
    )
}

export default CardIntegration;
