import React, { useState, useEffect, useCallback } from 'react';
import { ContainerSuccess, Title, ContainerBackground, Icon, Icon02, Marker, Markers, Content, Text, Icon03, FundoSt3, BoxTextoFotoSt3, ImgStep03, BoxSt31, BoxSt32, BoxSt33 } from './styles';
import Receita from '../../assets/tour/undraw_receitas.svg';
import tela_app from '../../assets/tour/tela_app.svg';
import logoBranco from '../../assets/tour/logoIcone_branco.svg';
import { ImgFundo } from '../../pages/tour/styles';

const Step03 = ({ ...props }) => {

    return (
        <>
            <Content marginLeft={props.steps}>
                <BoxTextoFotoSt3>
                    <FundoSt3>
                        <BoxSt31>
                            Aplicativo Receituário Agronômico
                        </BoxSt31>
                        <BoxSt32>
                            Mais mobilidade para a emissão do receituário agronômico 🌽
                        </BoxSt32>
                        <BoxSt33>
                            Emita direto do campo ou de onde você estiver
                        </BoxSt33>
                    </FundoSt3>
                    <ImgStep03>
                        <img src={tela_app} />
                    </ImgStep03>
                </BoxTextoFotoSt3>
                <ImgFundo>
                    <img src={logoBranco} />
                </ImgFundo>
            </Content>
        </>
    );
};

export default Step03;
