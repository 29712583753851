import React, { useEffect, useState } from "react";
import Routes from "./routes";
import GlobalStyles, { Layout, Main } from "./styles/global";
import { ScreenClassProvider } from "react-grid-system";
import ReactNotification from "react-notifications-component";
import Navbar from "../src/components/navbar";
import { BrowserRouter } from "react-router-dom";
export const USER = "@userinfo";

const App = () => {

  const [isFull, setIsFull] = useState(false);
  const [closeAllTabs, setCloseAllTabs] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [routeConfig, setRouteConfig] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  // const [tourStep02, setTourStep02] = useState(false);
  // const [tourStep03, setTourStep03] = useState(false);
  // const [tourButton, setTourButton] = useState(false);


  const closeAllTabsFromNavBar = () => {
    setCloseAllTabs(true);
  }

  return (
    <ScreenClassProvider>
      <ReactNotification />
      <GlobalStyles />
      <BrowserRouter>
        <Layout>
          <Navbar 
            updateIsFullScreen={(e) => setIsFull(e)}
            isOpen={(e) => setIsOpen(e)}
            closeAllTabs={closeAllTabs}
            isClosed={() => setCloseAllTabs(!closeAllTabs)}
            updateIsMobile={(e) => setIsMobile(e)}
            routeConfig={routeConfig}
            // step02={tourStep02}
            // step03={tourStep03}
            // tourButton={tourButton}
          />
          <Main 
            isFull={isFull}
            isOpen={isOpen}
            onClick={() => closeAllTabsFromNavBar()}
          >
            <Routes 
              isMobile={isMobile} 
              isOpen={isOpen}
              upConfig={(e) => setRouteConfig(e)} 
              // updateTourDisabled02={(e)=>setTourStep02(e)}
              // updateTourDisabled03={(e)=>setTourStep03(e)}
              // updateTourDisableButton={(e) => setTourButton(e)}
            />
          </Main>
        </Layout>
      </BrowserRouter>
    </ScreenClassProvider>
  )
};
export default App;