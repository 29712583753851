import React, {useState, useEffect} from 'react';
import api from "../../../services/api";
import { store } from "react-notifications-component";
import SelectV2 from "../../select/select_v2";
import Pin from '../../../assets/icons/icons-8-marker@3x.png';
import { Footer } from './styles';
import Modal from '../../modal/modalGeneric';
import AddAgronomo from '../../../pages/agronomos/add';
import AddArt from '../../../pages/arts/add';
import Contracts from '../../arts/contracts';
import ModalUpload from '../../modal/modalUploadCertificado';
import Button from '../../button/button_v2';
import Input from '../../input/input_v2';
import { logout } from '../../../services/auth';
import InputSign from '../../inputSign';

export const USER = "@userinfo";


const FormAgronomo = ({ ...props }) => {
    const [responsavel, setResponsavel] = useState('');
    const [disabledResponsavel, setDisabledResponsavel] = useState(false);
    const [erroResponsavel, setErroResponsavel] = useState(false);
    const [responsaveis, setResponsaveis] = useState([]);
    const [showModalAddResponsavel, setShowModalAddResponsavel] = useState(false);
    const [showModalArt, setShowModalArt] = useState(false);
    const [msgErroResponsavel, setMsgErroResponsavel] = useState('');
    const [loadingResponsavel, setLoadingResponsavel] = useState(false);

    const [crea, setCrea] = useState('');
    const [disabledCrea, setDisabledCrea] = useState(true);
    const [erroCrea, setErroCrea] = useState(false);
    const [creas, setCreas] = useState([]);
    const [tipoCrea, setTipoCrea] = useState('');
    const [loadingCreas, setLoadingCreas] = useState(false);
    const [msgErroCrea, setMsgErroCrea] = useState('');

    const [assinatura, setAssinatura] = useState('');
    const [disabledAssinatura, setDisabledAssinatura] = useState(true);
    const [erroAssinatura, setErroAssinatura] = useState(false);
    const [assinaturas, setAssinaturas] = useState([]);
    const [salvarAssinatura, setSalvarAssinatura] = useState(true);
    const [artAndSignLoading, setArtAndSignLoading] = useState(false);
    const [loadingAssinatura, setLoadingAssinatura] = useState(false);
    
    const [art, setArt] = useState('');
    const [disabledArt, setDisabledArt] = useState(true);
    const [erroArt, setErroArt] = useState(false);
    const [arts, setArts] = useState([]);
    const [showModalAddArt, setShowModalAddArt] = useState(false);
    const [showModalAddContrato, setShowModalAddContrato] = useState(false);
    const [dataEmissaoLoading, setDataEmissaoLoading] = useState(false);
    const [msgErroArt, setMsgErroArt] = useState('');
    const [loadingArt, setLoadingArt] = useState(false);

    const [existParams, setExistParams] = useState(false);
    const [params, setParams] = useState([]);

    const [load, setLoad] = useState(false);
    const [messageArt, setMessageArt] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [password, setPassword] = useState('');

    const [dataStepOne, setDataStepOne] = useState('');

    const [erroUser, setErroUser] = useState(true);
    const [msgErroUser, setMsgErroUser] = useState('');
    const [showModalUser, setShowModalUser] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);

    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [defesa, setDefesa] = useState('');

    const [showModalSign, setShowModalSign] = useState(false);
    
    const changeResponsavel = (value) => {
        if (value != responsavel) {
            setCrea('');
            setAssinatura('');
            setArt('');
            setResponsavel(value);
        } else {
        }
    }
    
    const formatAgronomos = (values) => {
        let agronomos = [];

        values.map((agr) => {
            agronomos = [...agronomos, {value: agr.id, label: agr.nome, cpf: agr.cpf}];
        });

        return agronomos;
    }
    
    const getAgronomos = async () => {
        setLoad(true);
        try {
            setErroResponsavel(false);
            setDisabledResponsavel(true);
            setLoadingResponsavel(true);
            const response = await api.get(`/agronomos/estado/${props.estado.value}`);

            if (response) {
                let agronomos = response.data.data;
                agronomos = formatAgronomos(agronomos);
                setResponsaveis(agronomos);
                if (existParams) {
                    if (responsavel === '') {
                        changeResponsavel(params.responsavel);
                    }
                }
            }
            setDisabledResponsavel(false);
            setLoadingResponsavel(false);


        } catch (error) {

            setErroResponsavel(true);
            setMsgErroResponsavel(`${error.response.data.errors}`);
            setDisabledResponsavel(false);
            setLoadingResponsavel(false);

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const addNewResponsavel = () => {
        setShowModalAddResponsavel(true);
    }

    const formatSign = (value) => {
        let sign = [];

        if (value.digital === true) {
            sign = [...sign, {value: 'D', label: 'Digital'}];
        }
        if (value.eletronica === true) {
            sign = [...sign, {value: 'E', label: 'Eletrônica'}];
        }
        if (value.manual === true) {
            sign = [...sign, {value: 'M', label: 'Manual'}];
        }

        return sign;
    }
    
    const getSign = async () => {
        setLoad(true);
        setLoadingAssinatura(true);
        try {
            const response = await api.get(`/sign/estado/${crea.estado}`);
            let user = JSON.parse(localStorage.getItem(USER));

            if (user.contrato) {

                if (user.contrato.agro_plan_functions) {

                    let isBlock = false;

                    user.contrato.agro_plan_functions.map((e) => {
                        if (e.agro_plans_function.id === 9) {
                            if (e.status === 0) {
                                isBlock = true;
                            }
                        }
                    });

                    if (isBlock === true) {
                        setAssinaturas([
                            {value: 'M', label: 'Manual'}
                        ]);
                        setLoadingAssinatura(false);

                    } else {
                        if (response) {
                            let sign = response.data.data;
                            sign = formatSign(sign);
                            setAssinaturas(sign);
                            setLoadingAssinatura(false);
                        }
                    }
                } else {
                    logout();
                    window.location = '/login';
                }
            } else {
                logout();
                window.location = '/login';
            }

        } catch (error) {
            setErroAssinatura(true);
            setLoadingAssinatura(false);

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const changeSaveAssinatura = (isSave) => {
        if (isSave != salvarAssinatura) {
            setSalvarAssinatura(isSave);
        }
    }


    const formatCreas = (values) => {
        let creas = [];
        
        values.map((c) => {
            creas = [...creas, {value: c.id, label: c.crea, tipo: c.tipo, estado: c.estado}];
        });

        return creas;
    }

    const getCreas = async (id) => {
        try {
            setLoadingCreas(true);
            setDisabledCrea(true);

            const response = await api.get(`/agronomos/${id}/creas?estado=${props.estado.value || props.estado}`);

            if (response) {
                let creas = response.data.data;

                creas = formatCreas(creas);
                setCreas(creas);

                if (existParams) {
                    if (crea === '') {
                        changeCrea(params.crea);
                    }
                }
            }

            setDisabledCrea(false);
            setLoadingCreas(false);

        } catch (error) {
            setLoadingCreas(false);
            setErroCrea(true);
            setDisabledCrea(false);
            setMsgErroCrea(`${error.response.data.errors}`);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const changeCrea = (value) => {
        if (value != crea) {
            setAssinatura('');
            setArt('');
            setCrea(value);
            setTipoCrea(value.tipo);
        } else {
        }
    }

    const changeAssinatura = (value) => {
        if (value != assinaturas) {
            if (value === 'D') {
                setAssinatura({value: 'D', label: 'Digital'});
            } else if (value === 'E') {
                setAssinatura({value: 'E', label: 'Eletrônica'});
            } else if (value === 'M') {
                setAssinatura({value: 'M', label: 'Manual'});
            } else {
                setAssinatura(value);
            }
        } else {
        }
    }

    const formatArts = (values) => {
        let arts = [];
        values.map((a) => {
            arts = [...arts, {value: a.id, label: a.numero_art}];
        });

        return arts;
    }
    
    const getArtsAndPreferentialSign = async () => {
        try {
            setArtAndSignLoading(true);
            setDisabledArt(true);
            setLoadingArt(true);

            const response = await api.get(`/creas/${crea.value}`);

            const artResponse = response.data.data;

            if (artResponse) {
                if (artResponse.assinatura) {
                    if (existParams) {
                        if (assinatura === '') {
                            setAssinatura(params.assinatura.value);
                        }
                    } else {
                        setAssinatura(artResponse.assinatura);
                    }
                } else {
                    if (existParams) {
                        if (assinatura === '') {
                            setAssinatura(params.assinatura.value);
                        }
                    } else {
                        setAssinatura('M');
                    }
                }
                if (crea.estado != 'RJ') {
                    if (artResponse.arts.length > 0) {
                        if (art != '') {
                            setArt('')
                        }
    
                        let arts = formatArts(artResponse.arts);
                        setArts(arts);
    
                        if (existParams) {
                            if (art === '') {
                                changeArt(params.art);
                            }
                        }
                    } else {
                        setArt('')
                        setArts([])
                        setErroArt(true);
                        store.addNotification({
                            title: "Erro!",
                            message: `O CREA/CFTA selecionado não possui nenhuma ART vinculada a ele. \nCadastre uma nova ART para continuar!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 7000,
                                onScreen: true
                            }
                        });
                        setMsgErroArt("Nenhuma ART/TRT cadastrada!")
                    }
                }
            }

            setArtAndSignLoading(false);
            setDisabledArt(false);
            setDisabledAssinatura(false);
            setLoadingArt(false);

        } catch (error) {
            setArtAndSignLoading(false);
            setLoadingArt(false);

            if (error.response.data.errors) {
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }
    
    const addNewArt = () => {
        setShowModalAddArt(true);
    }

    const changeArt = (value) => {
        if (value != art) {
            setArt(value);
        } else {
        }
    }
    
    const validaCertificadoArt = async (artId) => {
        try {
            const response = await api.get(`/arts/${artId}`);
            if (props.estado.value === 'SP') {
                if (crea.estado === 'SP') {
                    if (response.data.data.contratos.length === 0) {
                        setErroArt(true);
                        setMsgErroArt("A ART/TRT selecionada não possui nenhum contrato ativo!");
                        store.addNotification({
                            title: "Erro!",
                            message: `A ART selecionada não possui nenhum contrato ativo!`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }
    
    const updateModalAddContrato = () => {
        setShowModalAddContrato(false);

        setErroArt(false);
        validaCertificadoArt(art.value);
    }

    const validaAvisoArt = async (artId) => {
        try {
            const responseArt = await api.get(`/arts/aviso/${artId}`);
            if (responseArt.data.data.avisar && responseArt.data.data.avisar == 1) {
                setMessageArt(responseArt.data.message);
                setShowModalArt(true);
            }else if(responseArt.data.data === true){
                setMessageArt(responseArt.data.message);
                setShowModalArt(true);
            }
        } catch (error) {
            setMsgErroArt(`${error.response.data.errors}`);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const loadParams = (params) => {
        setExistParams(true);
        setParams(params);
        setSalvarAssinatura(params.salvarAssinatura);
    }

    const updateAgronomo = () => {
        setShowModalAddResponsavel(false);
        getAgronomos();
    }

    const updateArt = async () => {
        setShowModalAddArt(false);
        if (crea.value) {
            if (artAndSignLoading === false) {
                getArtsAndPreferentialSign();
            }
        }
    }

    const getPassword = (pass) => {
        setPassword(pass);
    }

    const consultaAssinatura = async (tipo) => {
        if (tipo === 'D') {
            try {
                const response = await api.post('/sign/consulta', {cpf: responsavel.cpf})
            } catch (error) {
                setShowModal(true);
            }
        } else if (tipo === 'E') {
            try {
                const response = await api.get(`/sign/consulta/${responsavel.value}`);
            } catch (error) {
                if (error.response.status === 402) {
                    setShowModalSign(true);
                }
            }
        }
    }

    const modalUploadClose = (e) => {
        getPassword(e);
        if (!e || e === undefined) {
            setAssinatura({value: 'M', label: 'Manual'});
        }
        setShowModal(false);
    }

    const getUserHomolog = async (id, estado) => {
        try {
            const response = await api.get(`/agronomos/usuario/${id}/${estado}`);

            if (response) {
                setErroUser(false);
            }
        } catch (error) {
            if (defesa != '') {
                setMsgErroUser(error.response.data.errors)
                setErroUser(true);
                setShowModalUser(true);
            }
        }
    }

    const saveUser = async () => {
        setLoadingUser(true);
        try {
            const payload = {
                usuario: user,
                senha: pass
            }

            const response = await api.post(`/agronomos/usuario/${responsavel.value}/${props.estado.value}`, payload);

            if (response) {
                setErroUser(false);
                setLoadingUser(false);
                setShowModalUser(false);
            }

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
        setLoadingUser(false);
    }

    const saveSign = async (e) => {
        try {
            const response = api.put(`/agronomos/${responsavel.value}`, {assinatura: e});

            if (response) {
                setShowModalSign(false);
                store.addNotification({
                    title: "Sucesso!",
                    message: `Assinatura salva com sucesso!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
             store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const clearParamsUser = async () => {
        changeResponsavel('');
        setResponsaveis([]);
        setCreas([]);
        setAssinaturas([]);
        setArts([]);
    }

    const closeModalUser = () => {
        setShowModalUser(false); 
        clearParamsUser();
        getAgronomos();
    }

    const getDefesa = async (estado) => {
        try {
            const response = await api.get(`/agronomos/defesa/${estado}`);
    
            if (response) {
                setDefesa(response.data.data.defesa);
                setErroUser(false);
            }else{
                setDefesa('')
            }
            
        } catch (error) {
            setDefesa('')
        }
    }

    useEffect(() => {
        if (responsavel) {
            if (responsavel.value) {
                if (loadingCreas === false) {
                    getCreas(responsavel.value);
                }
            }
    
            if (responsavel.value && defesa != '') {
                getUserHomolog(responsavel.value, props.estado.value);   
            } else {
                if (responsavel.value) {
                    setErroUser(false);
                }
            }
        }
    },[responsavel]);
    
    useEffect(() => {
        setErroCrea(false);
        if (crea.value) {
            if (artAndSignLoading === false) {
                getArtsAndPreferentialSign();
            }
        }

        if (crea.estado) {
            getSign();

            if (crea.estado === 'RJ') {
                changeArt('');
            }
        }
    },[crea]);

    useEffect(() => {
        setDataStepOne(props.dataEmissao);
        if (props.params != undefined) {
            if (props.params.responsavel.value || props.params.crea.value || props.params.art.value || props.params.assinatura.value) {
                loadParams(props.params);
            }
        } else {
            if (existParams === true) {
                getAgronomos();
            } else if (load === false) {
                getAgronomos();
            }
        }
    },[existParams])
    
    useEffect(() => {
        if (assinatura.value === 'D' || assinatura.value === 'E') {
            consultaAssinatura(assinatura.value);
        }
    },[assinatura])

    useEffect(()=>{
        if (props.estado.value != 'undefined') {
            getDefesa(props.estado.value);
            clearParamsUser();
        }
    }, [props.estado.value])
    
    useEffect(() => {
        if (art && load) {
            setErroArt(false);

            if (dataEmissaoLoading === false) {
                validaAvisoArt(art.value);
            }

            if(existParams) {
                setExistParams(false);
            }
        }
    },[art.value, load]);
    
    useEffect(() => {
        if (assinatura === 'D' || assinatura === 'E' || assinatura === 'M') {
            changeAssinatura(assinatura);
        } else {
            props.update({responsavel, crea, assinatura, salvarAssinatura, art, erroResponsavel, erroCrea, erroAssinatura, erroArt, password, erroUser});
        }

    },[responsavel, crea, assinatura, art, salvarAssinatura, erroResponsavel, erroCrea, erroAssinatura, erroArt, password, erroUser]);

    return(
        <div style={{
            maxWidth: '300px',
            margin: 'auto'
        }}>
            <SelectV2 
                name='responsavel_tecnico'
                className="select"
                label="Responsável Técnico"
                id='responsavel_tecnico'
                value={responsavel.value || responsavel}
                disabled={disabledResponsavel}
                loading={loadingResponsavel}
                erro={erroResponsavel}
                onChange={(e) => changeResponsavel(e)}
                options={responsaveis}
                selectUniqueOption={existParams ? responsaveis.length === 1 ? true : false : true}
                buttonAddNew={true}
                onClickAddNew={() => addNewResponsavel()}
                messageErro={msgErroResponsavel}
                isModal={true}
            />

            <SelectV2 
                name='crea_cfta'
                className="select"
                label="CREA/CFTA"
                id='crea_cfta'
                value={crea.value || crea}
                disabled={disabledCrea}
                loading={loadingCreas}
                erro={erroCrea}
                onChange={(e) => changeCrea(e)}
                options={creas}
                selectUniqueOption={existParams ? creas.length === 1 ? true : false : true}
                messageErro={msgErroCrea}
            />

            <SelectV2 
                name='assinatura'
                className="select"
                label="Tipo de assinatura"
                id='assinatura'
                value={assinatura.value || assinatura}
                disabled={disabledAssinatura}
                loading={loadingAssinatura}
                erro={erroAssinatura}
                onChange={(e) => changeAssinatura(e)}
                options={assinaturas}
                selectUniqueOption={existParams ? assinaturas.length === 1 ? true : false : true}
                yesNo={true}
                yesNoValue={salvarAssinatura}
                messageYesNo='Salvar preferência'
                onChangeYesNo={(e) => changeSaveAssinatura(e)}
            />

            <SelectV2 
                name='art_trt'
                className="select"
                label="ART/TRT"
                id='art_trt'
                value={ art.value || art}
                disabled={ crea.estado === 'RJ' ? true : disabledArt}
                loading={ crea.estado === 'RJ' ? false : loadingArt}
                erro={erroArt}
                onChange={(e) => changeArt(e)}
                options={arts}
                selectUniqueOption={existParams ? arts.length === 1 ? true : false : true}
                buttonAddNew={crea.estado === 'RJ' ? false : !disabledArt}
                legenda={crea.estado === 'RJ' ? 'Não utilizado no estado selecionado!' : ''}
                onClickAddNew={() => addNewArt()}
                messageErro={msgErroArt}
                isModal={true}

            />

            {props.estado.value === 'SP' ? (
                <>
                    {(crea.estado === 'SP') && (art.value || art) ? ( 
                        <div 
                            className="containerButton"
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: erroArt ? '10px' : '0px',
                            }}
                        >
                            <Button 
                                width='100%'
                                height='35px'
                                value='Adicionar novo contrato'
                                outline={true}
                                disabled={false}
                                onClick={() => setShowModalAddContrato(true)}
                            />
                        </div>
                    ) : (null)}
                </>
            ):(null)}
            
            <Footer> 
                <img className="pin" src={Pin} alt='estado' />
                <p>Exibindo resultados para <span>{props.estado.label}</span></p>
            </Footer>

            {showModalAddResponsavel && (
                <Modal
                    size='lg'
                    show={showModalAddResponsavel}
                    onHide={() => setShowModalAddResponsavel(false)}
                >
                    <AddAgronomo 
                        isModal={true} 
                        update={() => updateAgronomo()}
                        estado={props.estado}
                    />
                </Modal>
            )}

            {showModalArt && (
                <Modal
                    size='lg'
                    show={showModalArt}   
                    onHide={()=>setShowModalArt(false)} 
                >
                    <h4 style={{
                        width: '100%',
                        marginTop: '10px',
                        textAlign: 'center'
                    }}>
                        ATENÇÃO!
                    </h4>
    
                    <p style={{
                        width: '100%',
                        textAlign: 'center'
                    }}>
                        {messageArt}
                    </p>
    
                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '10px', marginBottom: '10px' }}>
                        <Button 
                            width='170px'
                            height='40px'
                            value='OK'
                            outline={false}
                            disabled={false}
                            onClick={() => setShowModalArt(false)}
                            loading={false}
                            tooltip={true}
                            tooltipMsg={''}
                        />
                    </div> 
                </Modal>
            )}

            {showModalAddArt && (
                <Modal
                    size='lg'
                    show={showModalAddArt}
                    onHide={() => setShowModalAddArt(false)}
                >
                    <AddArt 
                        isModal={true} 
                        update={() => updateAgronomo()}
                        estado={crea.estado}
                        agronomo={responsavel}
                        crea={crea}
                        updateArt={() => updateArt()}
                    />
                </Modal>
            )}
            
            {showModalAddContrato && (
                <Modal
                    size='lg'
                    show={showModalAddContrato}
                    onHide={() => setShowModalAddContrato(false)}
                >
                    <h4 style={{ textAlign: 'center' }} >Cadastrar contrato</h4>
                    <Contracts
                        estado={props.estado}
                        agronomo={responsavel.value}
                        numeroArt={art.value}
                        isModal={true}
                        update={() => updateModalAddContrato()}
                    />

                </Modal>
            )}

            {showModal ? (
                <ModalUpload 
                    show={showModal}
                    onHide={(e) => modalUploadClose(e)}
                    cpf={responsavel.cpf}
                    updatePassword={(e) => getPassword(e)}
                />
            ):(null)}

            {showModalUser ? (
                <Modal
                    size='lg'
                    show={setMsgErroUser}
                    onHide={() => closeModalUser()}
                >

                    <div style={{
                        width: '52%',
                        minWidth: '270px',
                        display: 'flex',
                        flexDirection: 'column',
                        margin: 'auto'
                    }}>
                        <h5 style={{ 
                            textAlign: 'center'
                         }}>{msgErroUser}</h5>

                        <p style={{ 
                            textAlign: 'center'
                         }}>Para emitir receitas para o estado de {props.estado.value} é necessário informar seu usuário e senha de acesso ao {defesa}.</p>

                        <Input 
                            name="user"
                            type="text"
                            id='userid'
                            label={`Usuário ${defesa}`}
                            onChange={e => setUser(e)}
                            value={user}
                        />

                        <Input 
                            name="pass"
                            type='password'
                            id='passid'
                            label={`Senha ${defesa}`}
                            onChange={e => setPass(e)}
                            value={pass}
                        />

                        <div 
                            className="containerButton"
                            style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: erroArt ? '10px' : '0px',
                            }}
                        >
                            <Button 
                                width='200px'
                                height='35px'
                                value='Salvar'
                                outline={true}
                                disabled={false}
                                loading={loadingUser}
                                onClick={() => saveUser()}
                            />
                        </div>
                    </div>
                    
                </Modal>
            ) : (null)}

            {showModalSign ? (
                <Modal
                    size='lg'
                    show={showModalSign}
                    onHide={() => {
                        setShowModalSign(false);
                        changeAssinatura('M')
                    }}
                >
                    <div style={{
                        width: "400px",
                        margin: 'auto'
                    }}>
                        <InputSign update={(e) => saveSign(e)} />
                    </div>

                </Modal>
            ) : (null)}

        </div>
    )
}

export default FormAgronomo;
