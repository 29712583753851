import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
// import Button from '../button/index';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Input from "../../components/input";
import Button from "../../components/button";
import Select from "../../components/select";
import viacep from "../../services/viacep";
import estados from "../../services/estados";
import api from "../../services/api";
import { store } from "react-notifications-component";
import Modal from '.';
import { ModalTitle } from './styles';

class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    state = {
        nome_fantasia: "",
        razao_social: "",
        cnpj: "",
        telefone: "",
        cep: "",
        endereco: "",
        cidade: "",
        bairro: "",
        numero: "",
        estado: ""
    };

    fetchCep = async () => {
        const { cep } = this.state;
        if(cep != ''){
            try {
                const response = await viacep.get(cep + "/json");
                const info = response.data;

                const { logradouro, localidade, bairro, uf } = info;

                this.setState({
                    endereco: logradouro,
                    cidade: localidade,
                    bairro: bairro,
                    estado: uf
                });
            } catch (err) {
                store.addNotification({
                    title: "Erro!",
                    message: "CEP inserido é inválido.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    };

    handlePost = async e => {
        e.preventDefault();

        // console.log("ABRIU");
        
        const {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            cep,
            endereco,
            cidade,
            bairro,
            numero,
            estado
        } = this.state;

        const payload = {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            cep,
            endereco,
            bairro,
            numero,
            cidade,
            estado,
            is_matriz: 0
        };

        try {
            const response = await api.post("/filiais", payload);
            console.log(response);

            if (response.status === 200) {
                // this.props.history.push("/filiais");
                this.props.update();    
                this.props.onHide();
                store.addNotification({
                    title: "Sucesso!",
                    message: "A filial foi cadastrada corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (err) {

            // if(err.response.status == 500){
            //     window.location = '/login';
            // }else{
                console.log(err);
                
                // if(err.response.data.error.length > 0){

                    if(err.response.data.errors[0]){
                        store.addNotification({
                            title: "Atenção!",
                            message: `${err.response.data.errors[0].alerta}`,
                            type: "warning",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }else{
                        let inputWithErrors = document.querySelectorAll(".error");
            
                        for (let input of inputWithErrors) {
                            input.classList.remove("error");
                        }
            
                        const errors = err.response.data.errors;
                        console.log(errors);
                        for (let field in errors) {
                            const input = document.getElementById(field);
                            input.classList.add("error");
                        }
            
                        store.addNotification({
                            title: "Erro!",
                            message: "Verifique os campos em vermelho",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                // }
            // }


        }
    };



    render(){
        const {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            cep,
            endereco,
            cidade,
            bairro,
            numero,
            estado
        } = this.state;

        return(
            
        //     <Modal
        //         {...this.props}
        //         size="lg"
        //         aria-labelledby = "contained-modal-title-vcenter"
        //         centered
        //     >

        //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8"}}>
        //         <Modal.Title id="contained-modal-title-vcenter">
        //             {this.props.title}
        //         </Modal.Title>
        //     </Modal.Header>
            
        //     <Modal.Body style={{backgroundColor: " #f8f8f8"}}>

        //         <form onSubmit={this.handlePost}>
        //             <Input
        //                 name="nome_fantasia"
        //                 type="text"
        //                 placeholder="Nome Fantasia"
        //                 id="nome_fantasia"
        //                 value={nome_fantasia}
        //                 onChange={e => this.setState({ nome_fantasia: e.target.value })}
        //             />

        //             <Input
        //                 name="razao_social"
        //                 type="text"
        //                 placeholder="Razão Social"
        //                 id="razao_social"
        //                 value={razao_social}
        //                 onChange={e => this.setState({ razao_social: e.target.value })}
        //             />

        //             <Input
        //                 name="cnpj"
        //                 type="text"
        //                 placeholder="CNPJ"
        //                 id="cnpj"
        //                 value={cnpj}
        //                 onChange={e => this.setState({ cnpj: e.target.value })}
        //                 mask="99.999.999/9999-99"
        //             />

        //             <Input
        //                 name="telefone"
        //                 type="text"
        //                 placeholder="Telefone"
        //                 id="telefone"
        //                 value={telefone}
        //                 onChange={e => this.setState({ telefone: e.target.value })}
        //                 mask="(99) 9999-9999"
        //             />

        //             <Input
        //                 name="cep"
        //                 type="text"
        //                 placeholder="CEP"
        //                 mask="99999-999"
        //                 id="cep"
        //                 value={cep}
        //                 onChange={e => this.setState({ cep: e.target.value })}
        //                 onBlur={this.fetchCep}
        //             />

        //             <Input
        //                 name="endereco"
        //                 type="text"
        //                 placeholder="Endereço"
        //                 id="endereco"
        //                 value={endereco}
        //                 onChange={e => this.setState({ endereco: e.target.value })}
        //             />

        //             <Input
        //                 name="numero"
        //                 type="text"
        //                 placeholder="Número"
        //                 id="numero"
        //                 value={numero}
        //                 onChange={e => this.setState({ numero: e.target.value })}
        //             />

        //             <Input
        //                 name="bairro"
        //                 type="text"
        //                 placeholder="Bairro"
        //                 id="bairro"
        //                 value={bairro}
        //                 onChange={e => this.setState({ bairro: e.target.value })}
        //             />

        //             <Input
        //                 name="cidade"
        //                 type="text"
        //                 placeholder="Cidade"
        //                 id="cidade"
        //                 value={cidade}
        //                 onChange={e => this.setState({ cidade: e.target.value })}
        //             />

        //             <Select
        //                 name="estado"
        //                 className="select"
        //                 placeholder="Estado"
        //                 id="estado"
        //                 value={estado}
        //                 onChange={state => this.setState({ estado: state.value })}
        //                 options={estados}
        //                 estado={true}
        //             />

        //             <div className="btn" style={{display: "flex", flexDirection: "row", justifyContent:"flex-end", marginTop: "20px"}}>

        //                 <Button outline style={{padding: "15px", outline:0}}
        //                     color="#00b43f"  onClick={this.props.onHide}>Fechar
        //                 </Button>
                        
        //                 <Button style={{outline:0, marginLeft:"10px", padding: "15px"}} color="#00b43f" type="submit">
        //                     Cadastrar <i className="fa fa-arrow-right"></i>
        //                 </Button>
        //             </div>
        //         </form>
        //     </Modal.Body>

        //   </Modal>


        <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
            width='33%'
        >

            <ModalTitle>{this.props.title}</ModalTitle>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <form onSubmit={this.handlePost}>
                    <Input
                        name="nome_fantasia"
                        type="text"
                        placeholder="Nome Fantasia"
                        id="nome_fantasia"
                        value={nome_fantasia}
                        onChange={e => this.setState({ nome_fantasia: e.target.value })}
                    />

                    <Input
                        name="razao_social"
                        type="text"
                        placeholder="Razão Social"
                        id="razao_social"
                        value={razao_social}
                        onChange={e => this.setState({ razao_social: e.target.value })}
                    />

                    <Input
                        name="cnpj"
                        type="text"
                        placeholder="CNPJ"
                        id="cnpj"
                        value={cnpj}
                        onChange={e => this.setState({ cnpj: e.target.value })}
                        mask="99.999.999/9999-99"
                    />

                    <Input
                        name="telefone"
                        type="text"
                        placeholder="Telefone"
                        id="telefone"
                        value={telefone}
                        onChange={e => this.setState({ telefone: e.target.value })}
                        mask="(99) 9999-9999"
                    />

                    <Input
                        name="cep"
                        type="text"
                        placeholder="CEP"
                        mask="99999-999"
                        id="cep"
                        value={cep}
                        onChange={e => this.setState({ cep: e.target.value })}
                        onBlur={this.fetchCep}
                    />

                    <Input
                        name="endereco"
                        type="text"
                        placeholder="Endereço"
                        id="endereco"
                        value={endereco}
                        onChange={e => this.setState({ endereco: e.target.value })}
                    />

                    <Input
                        name="numero"
                        type="text"
                        placeholder="Número"
                        id="numero"
                        value={numero}
                        onChange={e => this.setState({ numero: e.target.value })}
                    />

                    <Input
                        name="bairro"
                        type="text"
                        placeholder="Bairro"
                        id="bairro"
                        value={bairro}
                        onChange={e => this.setState({ bairro: e.target.value })}
                    />

                    <Input
                        name="cidade"
                        type="text"
                        placeholder="Cidade"
                        id="cidade"
                        value={cidade}
                        onChange={e => this.setState({ cidade: e.target.value })}
                    />

                    <Select
                        name="estado"
                        className="select"
                        placeholder="Estado"
                        id="estado"
                        value={estado}
                        onChange={state => this.setState({ estado: state.value })}
                        options={estados}
                        estado={true}
                    />

                    <div className="btn" style={{display: "flex", flexDirection: "row", justifyContent:"space-evenly", marginTop: "20px"}}>

                        <Button outline style={{padding: "15px", outline:0}}
                            color="#00b43f"  onClick={this.props.onHide}>Fechar
                        </Button>
                        
                        <Button style={{outline:0, marginLeft:"10px", padding: "15px"}} color="#00b43f" type="submit">
                            Cadastrar <i className="fa fa-arrow-right"></i>
                        </Button>
                    </div>
                </form>
            </div>
        </Modal>
          
        );
    }
}
export default ModalNew;