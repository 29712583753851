import styled from "styled-components";

export const FarmsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f8f8f9;
  padding: 40px;

 @media only screen and (max-width: 768px) {
    margin-top: 70px;
 }
`;

export const Search = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: -72px;
    margin-bottom: 20px;

    .exibe-options{        
        .opcoes{

        a {
            text-decoration: none;
        }

            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #FFF;
            width: 160px;
            border-radius: 10px;
            box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
            padding: 15px;
            margin-left: 70px;
            margin-top: 5px;


            #arrow {
                position: relative;
                background-color: #FFF;
                width: 8px;
                height: 8px;
                top: -90px;
                margin-left: 104px;
                transform: rotate(45deg);
            }

            #tooltip[data-popper-placement^='top'] > #arrow {
                bottom: -4px;
            }

            #tooltip[data-popper-placement^='bottom'] > #arrow {
                top: -4px;
            }

            #tooltip[data-popper-placement^='left'] > #arrow {
                right: -4px;
            }

            #tooltip[data-popper-placement^='right'] > #arrow {
                left: -4px;
            }

            .option{ 
                width: 110%;
                height: 36px;
                border-radius: 5px;
                align-items: center;

                p{
                    margin-left: 10px;
                    margin-top: 11px;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #8a92a2;
                    cursor: pointer;
                }

                :hover{
                    background-color: #f3f3f5;
                    font-weight: bold;
                    cursor: pointer;

                }
            }
        }

        .btn-relatorio{
            
            width: 145px;
            height: 40px;
            border-radius: 30px;
            border: solid 3px #f8f8f9;
            background-color: #00b43f;
            padding: 15px;

            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center; 
            cursor: pointer;
            margin-top: 10px;
            transition: all 0.3s;

            i{
                color: #FFF;
            }

            label{
            margin-top: 4px;
            font-family: Lato;
            font-size: 12px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            cursor: pointer;
            }

            img{
            width: 10px;
            cursor: pointer;
            }

            &:hover{
                box-shadow: 0 0 20px 0 #00b43f;
            }
        }
    }
`;

export const Title = styled.h2`
    /* color: #000;
    font-size: 20px; */
    width: 100%;
    text-align: left;
    font-family: Lato;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #8a92a2;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5e5e5;
    /* margin-left: 20px;
    margin-top: 27px; */
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: center;
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};

    .card-alerta {
        width: ${props => props.isMobile == false ? '27%' : '93%'};
        min-height: 240px;
        height: 60%;
        border-radius: 10px;
        box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
        margin-top: ${props => props.isMobile == false? '40px' : '0px'};
        margin-bottom: 10px;
        padding: 20px;
        background-color: rgb(255, 255, 255);
    }

    .card-previsao{
        width: ${props => props.isMobile == true ? '93%' : '64%'};
        margin-left: ${props => props.isMobile == true ? '0px' : '2%'};
        border-radius: 10px;
        box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
        margin-top: 40px;
        margin-bottom: 10px;
        padding: 20px;
        min-height: 240px;
        height: 60%;
        background-color: rgb(255, 255, 255);
    }

    @media only screen and (max-width: 815px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const Col2 = styled.div`
    width: ${props => props.width ? props.width : '48%'};
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: center;

    @media only screen and (max-width: 780px) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
    }
`;

export const Col5 = styled.div`
    width: ${props => props.width ? props.width : '20%'};
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: center;

    @media only screen and (max-width: 780px) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
    }
`;

export const Text = styled.p`
    width: 100%;
    color: #000;
    font-size: ${props => props.fontSize ? props.fontSize : '12px'};
    text-align: center;
    margin: 0px;
`;

export const AreaInfo = styled.div`
    width: 95%;
    /* height: 160px; */
    padding: 5px;
    /* border: 1px solid rgba(0,0,0,0.0); */
    /* border-radius: 25px; */
    border-radius: 10px;
    /* box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%); */
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: center;
`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 80px;
  height: 80px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;


  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;

export const CardMapa = styled.div`
    width: ${props => props.width ? props.width : '48%'};
    height: auto;
    min-height: 450px;
    border-radius: 10px;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 20px;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    @media only screen and (max-width: 780px) {
        width: 98%;
        height: auto;
        min-height: 0px;

    }
`;