import React, { useEffect, useState } from 'react';
import Input from '../input/input_v2';
import { Card, Label, LabelText, User } from './style';
import ArrowIcon from "../../assets/icons/icon-arrow.svg";


const CardUser = ({...props}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [userSc, setUserSc] = useState('');
    const [passSc, setPassSc] = useState('');
    const [userEs, setUserEs] = useState('');
    const [passEs, setPassEs] = useState('');



    useEffect(() => {
        props.users.map((u) => {
            if (u.estado === 'SC') {
                setUserSc(u.usuario);
                setPassSc(u.senha);
            } else if (u.estado === 'ES') {
                setUserEs(u.usuario);
                setPassEs(u.senha);
            }
        })
    },[props.users]);


    useEffect(() => {


        if (userSc != '' && passSc != '' && userEs != '' && passEs != '') {
            props.update({
                sc: {
                    usuario: userSc,
                    senha: passSc
                },
                es: {
                    usuario: userEs,
                    senha: passEs
                }
            });
        } else if (userSc != '' && passSc != '') {
            props.update({
                sc: {
                    usuario: userSc,
                    senha: passSc
                }
            });
        } else if (userEs != '' && passEs != '') {
            props.update({
                es: {
                    usuario: userEs,
                    senha: passEs
                }
            });
        }
    },[userSc, passSc, userEs, passEs])



    return(
        <>
            <Label>Emissão de receitas para {props.estado}</Label>
            <Card isOpen={isOpen} tamanho={props.users.length} estado={props.estado}>
                {!isOpen ? (
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <div style={{width: '90%'}} onClick={() => setIsOpen(!isOpen)}>
                            <>
                                {props.estado === 'ES' ? (
                                    <h3>IDAF</h3>
                                ):(
                                    props.estado === 'SC' && (
                                        <h3>SIGEN</h3>
                                    )
                                )}
                            </>
                        </div>

                        <div style={{width: '10%'}}>
                            <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => setIsOpen(!isOpen)}/>
                        </div>
                    </div>
                ) : (
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <>
                            {props.estado === 'ES' ? (
                                <User>
                                    <Input 
                                        id='user'
                                        name="user"
                                        type="text"
                                        label="Usuário IDAF"
                                        onChange={e => setUserEs(e)}
                                        value={userEs}
                                    />

                                    <Input 
                                        id='pass'
                                        name="pass"
                                        type='password'
                                        label="Senha IDAF"
                                        onChange={e => setPassEs(e)}
                                        value={passEs}
                                    />
                                </User>
                            ):(
                                props.estado === 'SC' && (
                                    <User>
                                        <Input 
                                            id='user'
                                            name="user"
                                            type="text"
                                            label="Usuário SIGEN"
                                            onChange={e => setUserSc(e)}
                                            value={userSc}
                                        />

                                        <Input 
                                            id='pass'
                                            name="pass"
                                            type='password'
                                            label="Senha SIGEN"
                                            onChange={e => setPassSc(e)}
                                            value={passSc}
                                        />
                                    </User>
                                )
                            )}
                        </>
                        
                        <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => setIsOpen(!isOpen)}/>

                    </div>
                )}

            </Card>
            {
                props.estado === 'ES' ? (
                    <LabelText style={{display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: '11px', marginBottom: '45px'}}>Usuário e senha de acesso ao IDAF. Obrigatório para emissão de receitas para ES.</LabelText>
                ) : (
                    props.estado === 'SC' && (
                        <LabelText style={{display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: '11px', marginBottom: '45px'}}>Usuário e senha de acesso ao SIGEN. Obrigatório para emissão de receitas para SC.</LabelText>
                    )  
                )
            }
        </>
    )
}

export default CardUser