import React, { useEffect, useState } from 'react';
import api from "../../services/api";
import { store } from 'react-notifications-component';
import GenericUpper from '../../components/generic-upper';
import ModalGeneric from '../../components/modal/modalGeneric';
import Button from "../../components/button/button_v2";
import Input from "../../components/input/input_v2";
import Select from "../../components/select/select_v2";
import { Container, Subtitle, Title } from './styles';
import { withRouter } from 'react-router-dom';
import NumericBox from '../../components/numeric-box';


const EditarPlano = ({ ...props }) => {

    const [contrato, setContrato] = useState([]);
    const [plano, setPlano] = useState([]);
    const [renew, setRenew] = useState([]);
    const [planoResponse, setplanoResponse] = useState([])
    const [showNewUser, setShowNewUser] = useState(false);
    const [newQtdUsers, setNewQtdUsers] = useState(0);
    const [loading, setLoading] = useState(false);
    // const [valorPlano, setValorPlano] = useState('');
    const [valor, setValor] = useState(0);
    const [valorMes, setValorMes] = useState(0);
    const [showNoUpdate, setShowNoUpdate] = useState(false);

    const getPlano = async () => {
        try {
            const response = await api.get('/plano');

            console.log(response);

            setContrato(response.data.data.contrato);
            setPlano(response.data.data.plano);
            setRenew({label: response.data.data.plano.agro_plans_renew_type.descricao, value: response.data.data.plano.agro_plans_renew_type.id})
            getPlanInfo(response.data.data.plano.plan.id)
        } catch (error) {
            console.log(error);
        }
    }

    const getPlanInfo = async (id) => {
        try {
            const response = await api.get(`/planos/${id}`);

            if (response) {
                let p = response.data.data;

                setplanoResponse(p);

            }

        } catch (error) {
            
        }
    }

    const addNew = () => {
        setShowNewUser(true);
    }

    const update = async () => {
        setLoading(true);

        try {
            const payload = {
                qtd_total_licencas: newQtdUsers,
                valor: renew.value === 4 ? `${valor}` : `${valorMes}`
            }

            const response = await api.put('/plano/licencas', payload);

            if (response) {
                store.addNotification({
                    title: "Sucesso!",
                    message: "Licenças adquiridas com sucesso!",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                setShowNewUser(false);
                getPlano();
            }

        } catch (error) {
            store.addNotification({
                title: "Sucesso!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            setShowNewUser(false);
        }
    }

    const calculaValorTotal = (vlr) => {
        const plan = planoResponse;

        console.log("PLAN");
        
        if (plan.length > 0) {
            const p = plan[0];
            console.log(p);
    
            let valorPlano = 0;
            let valorTotal = 0;
            // let grid = 0;

            let find = false;

            p.plans_grid.map((g) => {
                if (find === false) {
                    if (parseInt(renew.value) === parseInt(g.renew_type_id)) {
                        if (vlr == parseInt(g.qtd_users)) {
                            valorPlano = g.vlr_unit;
                            // grid = g.id;
                            find = true;
                            
                        } else if (vlr >= parseInt(g.qtd_users) && parseInt(g.vlr_minimo) === 1) {
                            valorPlano = g.vlr_unit;
                            // grid = g.id;
                            find = true;
                        }
                    }
                }
            });

            
            valorPlano = ""+valorPlano;
            let calculaTotal = valorPlano.replace(",", ".");
            valorTotal = vlr * calculaTotal;
            valorTotal = valorTotal.toFixed(2);
            valorTotal = ""+valorTotal;

            valorTotal = valorTotal.replace(".", ",");

            if (renew.value === 4) {
                // alert('1');
                setValor(valorTotal);
                const v = parseFloat(valorTotal)
                const ano = v/12;
                // alert('2');

                setValorMes(ano.toFixed(2));
            } else {
                // alert('3');
                setValorMes(valorTotal);
                const v = parseFloat(valorTotal);
                const ano = v*12;
                setValor(ano.toFixed(2));


            }

            // setValorPlano(valorPlano);
            // setValorTotal(valorTotal);

        }
    }

    useEffect(() => {
        console.log("PROPS => ", props)
        getPlano();
    }, [])

    useEffect(() => {
        if (showNewUser === false) {
            // alert('4');

            setNewQtdUsers(0);
            setValor(0);
            setValorMes(0);
            setLoading(false);
        }
    }, [showNewUser])

    useEffect(() => {
        calculaValorTotal(newQtdUsers);
    }, [newQtdUsers])

    useEffect(() => {
        if (plano.length !== 0) {
            if (plano.plan.nivel <= 3) {
                setShowNoUpdate(true);
            }
        }
    }, [plano])

    return (
        <>
            <GenericUpper
                align="center"
                title="Meu plano"
                subtitle="Configurações do plano atual"
            />

            <Container>
                {contrato.length !== 0 && plano.length !== 0 ? (
                    <>
                        <Input
                            id="plano"
                            onChange={e => console.log(e)}
                            label="Plano"
                            value={plano.plan.plan_name}
                            disabled={true}
                        />
                        
                        <Input
                            id="valor"
                            onChange={e => console.log(e)}
                            label="Valor"
                            value={parseFloat(plano.plan_value).toFixed(2)}
                            unidade={plano.agro_plans_renew_type.descricao}
                            disabled={true}
                            addNew={true}

                        />
                        
                        <Input
                            id="licencas"
                            onChange={e => console.log(e)}
                            label="Total de Licenças"
                            value={contrato.qtd_adquirida}
                            addNew={plano.plan.nivel <= 3 ? false : true}
                            onClickAddNew={() => addNew()}
                            isMobile={props.isMobile}
                            disabled={plano.plan.nivel <= 3 ? true : false}
                        />
                    </>
                ) : (
                    <></>
                )}


            </Container>

            <ModalGeneric
                show={showNewUser}
                onHide={() => setShowNewUser(false)}
                size='md'
            >
                <Container marginTop="0px">
                    <Title>Adquirir mais licenças</Title>
                    <NumericBox 
                        initialValue={contrato.qtd_adquirida}
                        allowNegative={false}
                        allowEmpty={false}
                        onChange={(e) => setNewQtdUsers(e)}
                    />
                    <Subtitle>Selecione a quantidade de licenças desejada</Subtitle>

                    <Title marginBottom='0px' marginTop='16px'>R${valorMes}/mês</Title>
                    <Subtitle>Valor total: R${valor}/ano</Subtitle>

                    <Button 
                        width='200px'
                        height='50px'
                        margin='16px 0px'
                        value='CONFIRMAR'
                        loading={loading}
                        outline={false}
                        disabled={false}
                        onClick={() => update()}
                    />
                </Container>
            </ModalGeneric>
            
            <ModalGeneric
                show={showNoUpdate}
                onHide={() => setShowNoUpdate(false)}
                size='md'
            >
                <Container marginTop="0px">
                    <Title>Atenção!</Title>
                    <p className='text'>Não é possível a inclusão de mais usuários no <b>{plano.length !== 0 && plano.plan.plan_name}</b>!</p>
                    <p className='text'>Faça um upgrade para os planos Light ou Pro e inclua mais usuários. </p>
                    <p className='text'>Utilize o cupom <b>+LICENCAS</b> e garanta 10% de desconto na mensalidade! </p>

                    <Button 
                        width='200px'
                        height='50px'
                        margin='16px 0px'
                        value='ATUALIZAR PLANO'
                        loading={false}
                        outline={false}
                        disabled={false}
                        onClick={() => window.location = '/planos'}
                    />
                </Container>
            </ModalGeneric>
        </>
    )
}

export default withRouter(EditarPlano);