import React, {useEffect, useState} from 'react';
import { NewsCardSchedule } from "./style";
import { Link } from "react-router-dom";
import Schedule from "../../assets/schedule.svg";
import Button from '../button';

const NewsAgenda = ({ ...props }) => {
    
    return (
        <NewsCardSchedule>
            <div className="row-news">
                <div className="news-card">
                    <Link className="add" to={`${props.href1}`}>
                        <div className="image">
                        <img src={Schedule} style={{borderTopLeftRadius: "20px", borderTopRightRadius: "20px"}}/>
                        </div>
                    </Link>
                    <div className="title" style={{marginTop: "-14px", marginBottom:"-8px"}}>
                        <h3> {props.title1} </h3>
                    </div>
                    <div className="text">
                        <label>{props.text}</label>
                    </div>
                    <div className="data">
                    </div>
                    <div className="btn-area">
                        <Link className="add" to={`${props.href1}`}>
                            <Button
                                className="btn-right"
                                color="#00b43f"
                                height="30px"
                                fontSize="10px"
                                outline="0"
                                padding="8px"
                            >
                                CADASTRAR COMPROMISSO
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </NewsCardSchedule>

    );
};

export default NewsAgenda;