import styled from "styled-components";

export const FormContainer = styled.div`
  padding-top: 50;
  background: #f8f8f9;
  div {
    form {
      padding-top: 50px;
      margin: auto;
      min-width: 250px;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .valores{
            .valor{
                text-align: center;
                font-family: Lato;
                font-weight: normal;
                font-size: 23px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;
            }
            .total{
                text-align: center;
                font-family: Lato;
                font-weight: normal;
                font-size: 12px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;

            }
        }

      .btns{
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        
        a {
          width: 80%;
        }

        button {
          margin: 15px;
          width: 50%;
          height: 40px;
          padding: 12px 20px;
          i {
          }
        }
      }

    
    }
  }
@media only screen and (max-width: 550px) {
  padding-top: 50;
  background: #f8f8f9;
  div {
    form {
      padding-top: 50px;
      margin: auto;
      min-width: 250px;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .valores{
            margin-top: 40px;
            .valor{
                text-align: center;
                font-family: Lato;
                font-weight: normal;
                font-size: 23px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;
            }
            .total{
                text-align: center;
                font-family: Lato;
                font-weight: normal;
                font-size: 12px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;

            }
        }

      .btns{
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        margin-left: -33px;
        a {
          width: 100%;
        }

        button {
          margin: 15px;
          width: 100%;
          max-width: 400px;
          height: 60px;
          i {
          }
        }
      }

    
    }
  }
  }
`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 80px;
  height: 80px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;


  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;
