import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import Logo from '../../assets/logo.png';
import Cell from '../../assets/celular.png';
import { ContainerSuccess, Text, BannerTextRight, ContainerBackground, Icon, Icon03, Icon02, Steps, Markers, Marker, CloseIcon, PrevProx, PrevProx01, PrevProx02, ClickEsquerda, ClickDireita, Done, SetaStyle } from './styles';
import Step01 from '../../components/tour/step01';
import Step02 from '../../components/tour/step02';
import Seta from '../../assets/tour/undraw_arrow.svg';
import DoneImg from '../../assets/tour/done.svg';
import Close from '../../assets/tour/undraw_close.svg';
import Step03 from '../../components/tour/step03';
import Step04 from '../../components/tour/step04';
import Step05 from '../../components/tour/step05';
import Step07 from '../../components/tour/step07';
import Step06 from '../../components/tour/step06';

const TourSteps = ({ ...props }) => {

    const [step, setStep] = useState(0);
    const [position, setPosition] = useState('');

    const handleTouchStart = (e) => {
        const touchStartX = e.touches[0].clientX;
        
        const handleTouchMove = (e) => {
            const touchEndX = e.touches[0].clientX;
            const deltaX = touchStartX - touchEndX;
            console.log(deltaX, step)
            
            if (deltaX < 0 && step > 0 ) {
                setStep(step-1)
            } else if (deltaX > 0 && step < 6) {
                setStep(step+1);
            }
            
            document.removeEventListener('touchmove', handleTouchMove);
        };
        
        document.addEventListener('touchmove', handleTouchMove);
    };

    useEffect(()=>{
        if (step == 0) {
            if (window.screen.width <= 600) {
                setPosition('0px');
            }else{
                setPosition('10px');
            }
        }else if (step == 1) {
            if (window.screen.width <= 600) {
                setPosition('0px');
            }else{
                setPosition('30px');
            }
        }else if (step == 2) {
            if (window.screen.width <= 600) {
                setPosition('0px');
            }else{
                setPosition('93vw');
            }
        }else if (step == 3) {
            if (window.screen.width <= 600) {
                setPosition('0px');
            }else{
                setPosition('30px');
            }
        }else if (step == 4) {
            if (window.screen.width <= 600) {
                setPosition('0px');
            }else{
                setPosition('30px')
            }
        }else if (step == 5) {
            if (window.screen.width <= 600) {
                setPosition('0px');
            }else{
                setPosition('93vw')
            }
        }else if (step == 6) {
            if (window.screen.width <= 600) {
                setPosition('0px');
            }else{
                setPosition('30px')
            }
        }
    }, [step])

    return (
        <>
            <div
                onTouchStart={handleTouchStart}
            >
                <ContainerSuccess>
                    <ClickEsquerda onClick={() => step > 0 && setStep(step-1)}/>
                    <ClickDireita onClick={() => step < 6 && setStep(step+1)}/>
                    <CloseIcon>
                        <img src={Close} onClick={() => {localStorage.setItem("@open", "false") ; window.location = "/"}}/>
                    </CloseIcon>
                    <ContainerBackground position={position} step={step} sizeScreen={window.screen.width}>
                        {
                            <Steps step={step}>
                                <Step01/>
                                <Step02/>
                                <Step03/>
                                <Step04 />
                                <Step05 />
                                <Step06 />
                                <Step07 steps={step}/>
                            </Steps>
                        }
                    </ContainerBackground>
                    <Icon>
                        <Icon03>
                            {
                                step > 0 && (
                                    <SetaStyle title='Retornar'>
                                        <img src={Seta} onClick={() => setStep(step-1)}/>
                                    </SetaStyle>
                                )
                            }
                        </Icon03>
                        <Icon02>
                            {
                                step < 6 ? (
                                    <SetaStyle title='Avançar'>
                                        <img src={Seta} onClick={() => setStep(step+1)}/>
                                    </SetaStyle>
                                ) : (
                                    <Done title='Finalizar'>
                                        <img src={DoneImg} onClick={() => {localStorage.setItem("@open", "false") ; window.location = "/"}}/>
                                    </Done>
                                )
                            }
                        </Icon02>
                    </Icon>
                    <Markers>
                        <PrevProx01>
                            {
                                step == 0 && (
                                    <a onClick={() => {localStorage.setItem("@open", "false") ; window.location = "/"}}>Pular</a>
                                )
                            }
                        </PrevProx01>
                        <Marker active={step == 0 && true} style={{marginLeft: '0px'}}/>
                        <Marker active={step == 1 && true}/>
                        <Marker active={step == 2 && true}/>
                        <Marker active={step == 3 && true}/>
                        <Marker active={step == 4 && true}/>
                        <Marker active={step == 5 && true}/>
                        <Marker active={step == 6 && true}/>
                        <PrevProx02>
                            {
                                step == 6 && (
                                    <a onClick={() => {localStorage.setItem("@open", "false") ; window.location = "/"}}>Começar</a>
                                )
                            }
                        </PrevProx02>
                    </Markers>
                </ContainerSuccess>
            </div>
        </>
    );
}

export default withRouter(TourSteps);
