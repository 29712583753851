import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { ContainerModalEdit } from "./styles"
import { store } from "react-notifications-component";
import { IconEdit } from "../../../assets/icons/edit"
import Button from '../../button/button_v2';
import InputV2 from '../../input/input_v2';
import SelectV2 from '../../select/select_v2';
import TextArea from '../../textarea/text_area_v2';
import { Loading } from '../../relatorios/style';



const PutAcao = ({ ...props }) => {

    const [data, setData] = useState('');
    const [erroData, setErroData] = useState(false);
    const [texto, setTexto] = useState('');
    const [tipo, setTipo] = useState('');
    const [tipos, setTipos] = useState([])
    const [loadingTipo, setLoadingTipo] = useState(false);
    const [erroTipo, setErroTipo] = useState(false);
    const [disabledTipo, setDisabledTipo] = useState(false);
    const [resultado, setResultado] = useState('');
    const [resultados, setResultados] = useState([])
    const [loadingResultado, setLoadingResultado] = useState(false);
    const [erroResultado, setErroResultado] = useState(false);
    const [disabledResultado, setDisabledResultado] = useState(false);
    const [salvarAgenda, setSalvarAgenda] = useState(true);
    const [actionEdit, setActionEdit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saveLoading, setSaveLoading] = useState(false);

    const getTipos = async () => {
        try {
            setLoadingTipo(true)
            setErroTipo(false)
            const response = await api.get('/tipo')

            if (response) {
                setTipos(formatTipos(response.data.data))
                setLoadingTipo(false)
            }
        } catch (error) {
            setLoadingTipo(false)
            setErroTipo(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeTipo = (value) => {
        setTipo(value)
        setErroTipo(false)
    }

    const formatTipos = (values) => {
        let tipos = [];

        values.map((tip) => {
            tipos = [...tipos, { value: tip.id, label: tip.descricao }];
        });

        return tipos;
    }

    const getResultados = async () => {
        try {
            setLoadingResultado(true)
            setErroResultado(false)
            const response = await api.get('/resultado')

            if (response) {
                setResultados(formatResultados(response.data.data))
                setLoadingResultado(false)
            }
        } catch (error) {
            setLoadingResultado(false)
            setErroResultado(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeResultado = (value) => {
        setResultado(value)
        setErroResultado(false)
    }

    const formatResultados = (values) => {
        let resultados = [];

        values.map((result) => {
            resultados = [...resultados, { value: result.id, label: result.descricao }];
        });

        return resultados;
    }

    const changeData = (value) => {
        setData(value)
        setErroData(false)
    }

    const changeTexto = (value) => {
        setTexto(value)
    }

    const changeSalvarAgenda = (value) => {
        setSalvarAgenda(value)
    }

    const getAcao = async (oport) => {

        try {

            if (oport) {
                const response = await api.get(`/oportunidade/acao/${oport}`)

                if (response) {
                    setData(response.data.data.acao.date_time_start ? response.data.data.acao.date_time_start : '')
                    setTexto(response.data.data.acao.description ? response.data.data.acao.description : '')
                    setTipo(response.data.data.acao.task_type_id ? response.data.data.acao.task_type_id : '')
                    setResultado(response.data.data.acao.action_result ? response.data.data.acao.action_result : '')
                    setActionEdit(true)

                    if (response.data.data.acao.notify_schedule == 1) {
                        setSalvarAgenda(true)
                    } else {
                        setSalvarAgenda(false)
                    }

                }
            }

        } catch (error) {
            setActionEdit(false)
        }

    }

    const putAcao = async () => {
        try {
            setLoading(true);
            setSaveLoading(true);
            if (!tipo.value || !resultado.value ||  !data) {
                if (!tipo.value) {
                    setErroTipo(true)
                    setSaveLoading(false)
                }

                if (!resultado.value) {
                    setErroResultado(true)
                    setSaveLoading(false)
                }

                if (!data) {
                    setErroData(true)
                    setSaveLoading(false)
                }
            } else {
                const payload = {
                    date_time: data,
                    description: texto ? texto : '',
                    type: tipo.value,
                    result: resultado.value,
                    finished: 0,
                    notify_schedule: salvarAgenda ? 1 : 0
                }


                const response = await api.put(`/oportunidade/acao/${props.id}`, payload)

                if (response) {
                    setLoading(false);
                    props.close();
                    props.get();
                    props.getOp();


                }
            }
        } catch (error) {
            setSaveLoading(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    useEffect(() => {
        getTipos()
        getResultados()
        props.get(props.id)


    }, [])

    useEffect(() => {
        if (props.id !== 0) {
            getAcao(props.id)
        }

    }, [props.id])

    return (

        <>
            <InputV2
                marginBottom='0px'
                id="data"
                value={data || ''}
                label="Data"
                type="datetime-local"
                labelUp={true}
                erro={erroData}
                onChange={(e) => changeData(e)}
            />

            <TextArea
                marginBottom='10px'
                id="texto"
                value={texto || ''}
                name={"descricao"}
                disabled={false}
                digitavel={true}
                label="Texto"
                maxlength="number"
                onChange={(e) => changeTexto(e)}
            />

            <SelectV2
                name='tipo'
                className="select"
                label="Tipo do Evento"
                id='tipo'
                value={tipo.value || tipo}
                disabled={disabledTipo}
                loading={loadingTipo}
                erro={erroTipo}
                // yesNo={false}
                // yesNoValue={salvarAgenda}
                // responsive={true}
                // messageYesNo='Salvar na Agenda?'
                // onChangeYesNo={(e) => changeSalvarAgenda(e)}
                onChange={(e) => changeTipo(e)}
                options={tipos}
                marginBottom='0px'
            />
            <SelectV2
                name='resultado'
                className="select"
                label="Resultado da ação"
                id='resultado'
                value={resultado.value || resultado}
                disabled={disabledResultado}
                loading={loadingResultado}
                erro={erroResultado}
                onChange={(e) => changeResultado(e)}
                options={resultados}
            />

            <div className="div-button" style={{ width: '100%', display: 'flex', flexDirection: 'row', marginTop: '30px', justifyContent: 'space-evenly' }}>
                <div className="button-finish" style={{ width: '40%', display: 'flex', flexDirection: 'row' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='SALVAR AÇÃO'
                        outline={true}
                        disabled={false}
                        onClick={() => putAcao()}
                        loading={saveLoading}
                    />
                </div>
            </div>
        </>
    );
};

export default PutAcao;