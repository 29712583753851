import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { render } from 'react-dom';
import api from "../../services/api";
import { Container, ScreenClassRender } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import { Calendar, momentLocalizer, Views } from "react-big-calendar"
import moment from 'moment';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import './lib/react-big-calendar.css'
import './lib/style.css'
import { ContainerModal, Page, Pages, LabelPage, TitleModal, YesNoContainer, Plus, YesNoContainerGeral, FundoCalendario } from "./styles";
import ModalGeneric from "../../components/modal/modalGeneric"
import SelectV2 from '../../components/select/select_v2';
import InputV2 from '../../components/input/input_v2';
import CardRight from "../../components/cardRight";
import TextArea from "../../components/textarea/text_area_v2";
import Interruptor from '../../components/interruptor';
import Button from '../../components/button/button_v2';
import InputSearch from "../../components/input-search";
import Modal from '../../components/modal/modalGeneric';
import AddProdutor from '../../pages/produtores/add';
import { store } from "react-notifications-component";
import DateRange from '../../components/date-range';
import { H3, Label, FilterArea, SelectArea, DataFilter, ChartArea, Loading } from '../../components/relatorios/style';
import { compareData } from '../../services/globalFunctions';
import DeleteIcon from "../../assets/icons/icon-delete.svg";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import { formatDateTime, formatDate, formatarData, pegarHora, pegarDia, parseDateToObject } from '../../services/formatDate';
import IconList from '../../assets/icons/iconList';

export const USER = "@userinfo";

const localizer = momentLocalizer(moment) 
const DnDCalendar = withDragAndDrop(Calendar)


const Agenda = ({ ...props }) => {
    console.log(props)
    const [myEvents, setMyEvents] = useState([])

    const [showNewEvent, setShowNewEvent] = useState(false)
    const [showEventoComum, setShowEventoComum] = useState(false)
    const [showList, setShowList] = useState(false)

    
    const [newEventConfig, setNewEventConfig] = useState('')
    const [modalText, setModalText] = useState(false);

    const [agronomos, setAgronomos] = useState([])
    const [produtores, setProdutores] = useState([])
    const [fazendas, setFazendas] = useState([])
    const [tipos, setTipos] = useState([])
    const [motivos, setMotivos] = useState([])
    const [agenda, setAgenda] = useState([])
    

    const [responsavel, setResponsavel] = useState('');
    const [produtor, setProdutor] = useState('');
    const [agendaId, setAgendaId] = useState('');
    const [fazenda, setFazenda] = useState('');
    const [tipo, setTipo] = useState('');
    const [motivo, setMotivo] = useState('');
    const [titulo, setTitulo] = useState('');
    const [contato, setContato] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [local, setLocal] = useState(''); // local é a Fazenda digitada e fazenda acima é do select.
    const [endereco, setEndereco] = useState('');
    const [showEndereco, setShowEndereco] = useState(false)
    const [descricao, setDescricao] = useState('');
    const [minutos, setMinutos] = useState('');
    const [dataInicial, setDataInicial] = useState('');
    const [horaInicial, setHoraInicial] = useState('');
    const [horaFinal, setHoraFinal] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [id, setId] = useState('');
    const [search, setSearch] = useState('');

    const [disabledResponsavel, setDisabledResponsavel] = useState(false);
    const [disabledProdutor, setDisabledProdutor] = useState(false);
    const [disabledFazenda, setDisabledFazenda] = useState(false);
    const [disabledAgendaId, setDisabledAgendaId] = useState(false);
    const [disabledTipo, setDisabledTipo] = useState(false);
    const [disabledMotivo, setDisabledMotivo] = useState(false);
    const [showModalAddProdutor, setShowModalAddProdutor] = useState(false);
    const [msgErroProdutor, setMsgErroProdutor] = useState('');
    const [existParams, setExistParams] = useState(false);
    const [load, setLoad] = useState(false);

    const [avisarProdutor, setAvisarProdutor] = useState(true);
    const [avisarResponsavel, setAvisarResponsavel] = useState(true);
    const [tipoYesNo, setTipoYesNo] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const [erroResponsavel, setErroResponsavel] = useState(false);
    const [erroMinutos, setErroMinutos] = useState(false);
    const [erroProdutor, setErroProdutor] = useState(false);
    const [erroFazenda, setErroFazenda] = useState(false);
    const [erroAgendaId, setErroAgendaId] = useState(false);
    const [erroTipo, setErroTipo] = useState(false);
    const [erroMotivo, setErroMotivo] = useState(false);

    const [loadingResponsavel, setLoadingResponsavel] = useState(false);
    const [loadingProdutor, setLoadingProdutor] = useState(false);
    const [loadingFazenda, setLoadingFazenda] = useState(false);
    const [loadingAgendaId, setLoadingAgendaId] = useState(false);
    const [loadingTipo, setLoadingTipo] = useState(false);
    const [loadingMotivo, setLoadingMotivo] = useState(false);

    const [loading, setLoading] = useState(true)
    const [animationCard, setAnimationCard] = useState(false);

    const handleSelectEvent = a => {
        setIsEdit(true)
        setId(a.id)
        changeTitulo(a.title)
        changeContato(a.producer_name)

        if(a.producer_name){
            changeEvento(false)
        }else{
            changeEvento(true)
        }

        changeEmail(a.email)
        changeTelefone(a.telefone)
        changeLocal(a.farm_name)
        changeEndereco(a.endereco)
        changeDescricao(a.description)
        changeResponsavel({value: a.agronomist_id, label: a.agronomist})
        changeProdutor({value: a.producer_id, label: a.producer})
        changeFazenda({value: a.farm_id, label: a.farm})
        changeTipo({value: a.agro_task_type_id, label: a.agro_task_type})
        changeMotivo({value: a.agro_task_reason_id, label: a.agro_task_reason})
        changeAvisarResponsavel(a.alert_agronomist)
        changeAvisarProdutor(a.alert_producer)
        changeMinutos(a.send_alert)
        setDataInicial(a.data_ini)
        setHoraInicial(a.date_hour_start)
        setHoraFinal(a.date_hour_end)
        setDataFinal(a.data_fin)
        setShowNewEvent(true)
        
    }

    const clearStates = () => {
        setIsEdit(false)
        setId('')
        changeTitulo('')
        changeContato('')
        changeEmail('')
        changeTelefone('')
        changeLocal('')
        changeEndereco('')
        changeDescricao('')
        changeResponsavel('')
        changeProdutor('')
        changeFazenda('')
        changeTipo('')
        changeMotivo('')
        changeAvisarResponsavel('')
        changeAvisarProdutor('')
        changeMinutos('')
        changeHoraInicial('')
        changeHoraFinal('')
        setDataInicial('')
        setDataFinal('')     
        setShowEndereco(false)   
    }



    const closeModal = () => {
        if(modalText == true){
            setModalText(false);
        }
    }

    const address = () => {
        setShowEndereco(true);
      }

    const addNewProdutor = () => {
        setShowModalAddProdutor(true);
    }

    const closeProdutorModal = () => {
        setShowModalAddProdutor(false);
    }

    const updateProdutor = () => {
        setShowModalAddProdutor(false);
        getProdutores(false);
    }

     const moveEvent = (e) => {
        putDataAgendas(e)    
    }

     const resizeEvent = (e) => {
        console.log('resizeEvent')
        console.log(e)

        let event = e.event
        let start = e.start
        let end = e.end
        putDataAgendas(e)

     }

     const criarNovo = (e) => {
        console.log('criarNovo')

        console.log(e)

        let date = ''
            let dateEnd = ''
            let month = e.start.getUTCMonth() + 1
            let monthEnd = e.end.getUTCMonth() + 1
            let day = e.start.getDate()
            let dayEnd = e.end.getDate()
            let hourStart = e.start.getHours()
            let hourEnd = e.end.getHours()
            let minutesStart = e.start.getMinutes()
            let minutesEnd = e.end.getMinutes()
            date = ''+e.start.getFullYear()
            dateEnd = ''+e.end.getFullYear()

            if(hourStart < 10 ){
                hourStart  = '0'+hourStart
            }

            if(minutesStart < 10 ){
                minutesStart  = '0'+minutesStart
            }

            if(hourEnd < 10 ){
                hourEnd  = '0'+hourEnd
            }

            if(minutesEnd < 10 ){
                minutesEnd  = '0'+minutesEnd
            }

            if(month < 10 ){
                date = date+'-0'+month
            }else{
                date = date+'-'+month
            }

            if(monthEnd < 10 ){
                dateEnd = dateEnd+'-0'+monthEnd
            }else{
                dateEnd = dateEnd+'-'+monthEnd
            }

            if(day < 10 ){
                date = date+'-0'+day
            }else{
                date = date+'-'+day
            }

            if(dayEnd < 10 ){
                dateEnd = dateEnd+'-0'+dayEnd
            }else{
                dateEnd = dateEnd+'-'+dayEnd
            }

        setAnimationCard(true);
        setDataInicial(date+' '+ hourStart+':'+minutesStart);
        setDataFinal(dateEnd+ ' '+ hourEnd+':'+minutesEnd);

        setTimeout(() => {
            setShowNewEvent(true);
            setNewEventConfig(e);

        }, 100);
     }

     const newEvent = () => {
        console.log('criarNovo')

        setAnimationCard(true);

        setTimeout(() => {
            setShowNewEvent(true);
            // setNewEventConfig();

        }, 100);
     }

     const formatAgronomos = (values) => {
        let agronomos = [];

        values.map((agr) => {
            agronomos = [...agronomos, {value: agr.id, label: agr.nome, cpf: agr.cpf}];
        });

        return agronomos;
    }

    const formatProdutores = (values) => {
        let produtores = [];

        values.map((prod) => {
            produtores = [...produtores, {value: prod.id, label: prod.nome}];
        });

        return produtores;
    }

    const formatFazendas = (values) => {
        let fazendas = [];

        values.map((faze) => {
            fazendas = [...fazendas, {value: faze.id, label: faze.nome}];
        });

        return fazendas;
    }

    const formatAgendaId = (values) => {
        let agendasId = [];

        values.map((agen) => {
            agendasId = [...agendasId, {value: agen.id, label: agen.nome}];
        });

        return agendasId;
    }

    const formatTipos = (values) => {
        let tipos = [];

        values.map((tip) => {
            tipos = [...tipos, {value: tip.id, label: tip.descricao}];
        });

        return tipos;
    }

    const formatMotivos = (values) => {
        let motivos = [];

        values.map((mot) => {
            motivos = [...motivos, {value: mot.id, label: mot.descricao}];
        });

        return motivos;
    }

    const formatAgenda = (values) => {
        let ags = [];


        
        values.map((a) => {
            // console.log(a)

            // console.log("  ")
            // console.log("DATA INICIO => ", a.date_begin)
            // console.log("DATA INICIO NEW DATE=> ", new Date(a.date_begin))
            // console.log("  ")

            if (a.date_begin && a.date_end) {
                ags = [...ags, {
                    id: a.schedule_id,
                    agro_task_reason_id: a.agro_task_reason_id,
                    agro_task_reason: a.agro_task_reason,
                    agro_task_type_id: a.agro_task_type_id,
                    agro_task_type: a.agro_task_type,
                    agronomist_id: a.agronomist_id,
                    agronomist: a.agronomist,
                    farm_id: a.farm_id,
                    farm: a.farm,
                    producer_id: a.producer_id,
                    producer: a.producer,
                    description: a.description,
                    title: a.title,
                    producer_name: a.producer_name,
                    email: a.email,
                    telefone: a.telefone,
                    farm_name: a.farm_name,
                    endereco: a.endereco,
                    send_alert: a.send_alert,
                    alert_agronomist: a.alert_agronomist,
                    alert_producer: a.alert_producer,
                    start: parseDateToObject(a.date_begin),
                    end: parseDateToObject(a.date_end),
                    data_ini: a.date_begin,
                    data_fin: a.date_end,
                }];
            }
        });
        
        return ags;
    }
    
    const getAgronomos = async ()=> {
        try {
            setLoadingResponsavel(true)
            setErroResponsavel(false)
            const response = await api.get('/agronomos')

            if(response){
                setAgronomos(formatAgronomos(response.data.data))
                setLoadingResponsavel(false)
            }
        } catch (error) {
            setLoadingResponsavel(false)
            setErroResponsavel(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }
        
    }

    const getProdutores = async ()=> {
        setLoad(true)
        try {
            setLoadingProdutor(true)
            setErroProdutor(false)
            const response = await api.get('/produtores')

            if(response){
                setProdutores(formatProdutores(response.data.data))
                setLoadingProdutor(false)
            }
        } catch (error) {
            setLoadingProdutor(false)
            setErroProdutor(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            
        }
        
    }

    const getFazendas = async (id)=> {
        if(id){
            try {
                setLoadingFazenda(true)
                setErroFazenda(false)
                const response = await api.get(`/produtores/${id}/fazendas`)
    
                if(response){
                    setFazendas(formatFazendas(response.data.data))
                    setLoadingFazenda(false)
                }
            } catch (error) {
                setLoadingFazenda(false)
                setErroFazenda(true)
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}.`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
            }
        }
        
    }

    const getTipos = async ()=> {
        try {
            setLoadingTipo(true)
            setErroTipo(false)
            const response = await api.get('/tipo')

            if(response){
                setTipos(formatTipos(response.data.data))
                setLoadingTipo(false)
            }
        } catch (error) {
            console.log(error)
            setLoadingTipo(false)
            setErroTipo(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }
        
    }

    const postSearch = async (e, produtorId)=> {
        console.log(e.key, produtorId)
        if (e.key === 'Enter') {
            console.log(search)
            try {
                if(search === '' && produtorId === 0){
                    setShowList(false)
                }else{
                    const payload = {search, produtorId}
                    const response = await api.post('/agenda/pesquisa', payload)
        
                    if(response){
                        const agendas = response.data.data
            
                        setMyEvents(formatAgenda(agendas))
                        setShowList(true)
                        setLoading(false)
                    }

                }
            } catch (error) {
                setLoading(false)
                setShowList(true)
    
            }
        }
        
    }

    const postSearchText = async (search, produtorId)=> {
        try {
            if(search === '' && produtorId === 0){
                setShowList(false)
            }else{
                const payload = {search, produtorId}
                const response = await api.post('/agenda/pesquisa', payload)
    
                if(response){
                    const agendas = response.data.data
        
                    setMyEvents(formatAgenda(agendas))
                    setShowList(true)
                    setLoading(false)
                }

            }
        } catch (error) {
            setLoading(false)
            setShowList(true)

        }
        
        
    }


    const getMotivos = async ()=> {
        try {
            setLoadingMotivo(true)
            setErroMotivo(false)
            const response = await api.get('/motivo')

            if(response){
                setMotivos(formatMotivos(response.data.data))
                setLoadingMotivo(false)
            }
        } catch (error) {
            setLoadingMotivo(false)
            setErroMotivo(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }
        
    }

    const saveEvent = async ()=> {
        try {
            setLoading(true);
            
            const payload = {
                agro_agronomist_id: responsavel.value ? responsavel.value : '',
                agro_producer_id: produtor.value ? produtor.value : '',
                agro_farm_id: fazenda.value ? fazenda.value : '',
                agro_task_type_id: tipo.value ? tipo.value : '',
                agro_task_reason_id: motivo.value ? motivo.value : '',
                title: titulo,
                producer_name: contato ? contato : '',
                email: email ? email : '',
                telefone: telefone ? telefone : '',
                farm_name: local ? local : '',
                endereco: endereco ? endereco : '',
                description: descricao,
                date_hour_start: dataInicial,
                date_hour_end: dataFinal,
                alert_agronomist: avisarResponsavel ? 1 : 0,
                alert_producer: avisarProdutor ? 1 : 0,
                send_alert: minutos ? parseInt(minutos) : 0,
            }
            console.log(payload)
            const response = await api.post('/agenda', payload)

            if(response){
                setShowNewEvent(false)
                setErroMinutos(false)
                getAgendas()
                clearStates()
                setLoading(false);

            }
        } catch (error) {
            console.log(error)
            setShowNewEvent(false)
            setErroMinutos(true)
            setLoading(false)
            getAgendas()

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }
        
    }

    const getAgendas = async ()=> {
        try {

            const response = await api.get('/agenda')

            if(response){
                const agendas = response.data.data
    
                setMyEvents(formatAgenda(agendas))
                setLoading(false)

            }
        } catch (error) {
            setLoading(false)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }
        
    }

    const putDataAgendas = async (agenda)=> {
        try {
            console.log(agenda)
            let date = ''
            let dateEnd = ''
            let month = agenda.start.getUTCMonth() + 1
            let monthEnd = agenda.end.getUTCMonth() + 1
            let day = agenda.start.getDate()
            let dayEnd = agenda.end.getDate()
            let hourStart = agenda.start.getHours()
            let hourEnd = agenda.end.getHours()
            let minutesStart = agenda.start.getMinutes()
            let minutesEnd = agenda.end.getMinutes()
            date = ''+agenda.start.getFullYear()
            dateEnd = ''+agenda.end.getFullYear()

            if(hourStart < 10 ){
                hourStart  = '0'+hourStart
            }

            if(minutesStart < 10 ){
                minutesStart  = '0'+minutesStart
            }

            if(hourEnd < 10 ){
                hourEnd  = '0'+hourEnd
            }

            if(minutesEnd < 10 ){
                minutesEnd  = '0'+minutesEnd
            }

            if(month < 10 ){
                date = date+'-0'+month
            }else{
                date = date+'-'+month
            }

            if(monthEnd < 10 ){
                dateEnd = dateEnd+'-0'+monthEnd
            }else{
                dateEnd = dateEnd+'-'+monthEnd
            }

            if(day < 10 ){
                date = date+'-0'+day
            }else{
                date = date+'-'+day
            }

            if(dayEnd < 10 ){
                dateEnd = dateEnd+'-0'+dayEnd
            }else{
                dateEnd = dateEnd+'-'+dayEnd
            }

            const payload = {
                date_hour_start: date+' '+ hourStart+':'+minutesStart,
                date_hour_end: dateEnd+ ' '+ hourEnd+':'+minutesEnd,
            }
            
            const events = myEvents
            let newEvents = []
            
            events.map((ev)=> {

                
                if(ev.id === agenda.event.id){
                    console.log(ev)
 
                    newEvents = [ ...newEvents, {
                        id: agenda.event.id,
                        title: agenda.event.title,
                        producer_name: agenda.event.producer_name,
                        email: agenda.event.email,
                        telefone: agenda.event.telefone,
                        farm_name: agenda.event.farm_name,
                        endreco: agenda.event.endereco,
                        start: agenda.start,
                        end: agenda.end,
                        agro_task_type_id: agenda.event.agro_task_type_id,
                        data_fin: agenda.end,
                        producer_id: agenda.event.producer_id

                    }]
                }else{
                    newEvents = [ ...newEvents, ev]
                }
            })
    
            setMyEvents(newEvents);

            const response = await api.put(`/agenda/data/${agenda.event.id}`, payload)

            if(response){
                getAgendas()
            }
        } catch (error) {
            console.log(error)
        }
        
    }

    const putAgenda = async ()=> {
        try {
            setLoading(true);


            const payload = {
                agro_agronomist_id: responsavel.value,
                agro_producer_id: produtor.value ? produtor.value : '',
                agro_farm_id: fazenda.value ? fazenda.value : '',
                agro_task_type_id: tipo.value,
                agro_task_reason_id: motivo.value,
                title: titulo,
                producer_name: contato ? contato : '',
                email: email ? email : '',
                telefone: telefone ? telefone : '',
                farm_name: local ? local : '',
                endereco: endereco ? endereco : '',
                description: descricao,
                alert_agronomist: avisarResponsavel ? 1 : 0,
                alert_producer: avisarProdutor ? 1 : 0,
                send_alert: parseInt(minutos),
                date_hour_start: horaInicial ? horaInicial : dataInicial,
                date_hour_end: horaFinal ? horaFinal : dataFinal,
            }
            console.log(payload)
            
            const events = myEvents
            let newEvents = []
            
            events.map((ev)=> {
                if(ev.id === id){
                    newEvents = [ ...newEvents, {
                        id: id,
                        title: titulo,
                        start: parseDateToObject(dataInicial),
                        end: parseDateToObject(dataFinal),
                        agro_task_type_id: tipo.value
                    }]
                }else{
                    newEvents = [ ...newEvents, ev]
                }
            })
    
            setMyEvents(newEvents);
            
            const response = await api.put(`/agenda/${id}`, payload)

            if(response){
                setShowNewEvent(false)
                setIsEdit(false)
                getAgendas()
                clearStates()
                setLoading(false);

            }
        } catch (error) {
            console.log(error)
            setErroMinutos(true)
            setLoading(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }
        
    }
    
      

    const deleteEvent = async () => {
        try {
            const response = await api.delete(`/agenda/${id}`);
    
            if (response) {
                setShowNewEvent(false)
                setIsEdit(false)
                getAgendas()
                clearStates()
            } 
            
        } catch (error) {
            console.log(error)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
            
        }
    }

    const getAgendaId = async (id)=> {
        try {
            setLoadingAgendaId(true)
            setErroAgendaId(false)
            const response = await api.get(`/agenda/${id}`)

            if(response){
                setAgenda(formatAgendaId(response.data.data))
                setLoadingAgendaId(false)
            }
        } catch (error) {
            setLoadingAgendaId(false)
            setErroAgendaId(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }
        
    }

    const changeResponsavel = (value)=>{
        setResponsavel(value)
    }

    const changeProdutor = (value)=>{
        setProdutor(value)
    }

    const changeFazenda = (value)=>{
        setFazenda(value)
    }

    const changeTipo = (value)=>{
        setTipo(value)
        if(value.value === 2){
            setTipoYesNo(true)
        }else{
            setTipoYesNo(false)
        }

    }

    const changeAvisarResponsavel = (value)=>{
        setAvisarResponsavel(value)

    }

    const changeMotivo = (value)=>{
        setMotivo(value)
    }

    const changeTitulo = (value)=>{
        setTitulo(value)
    }

    const changeContato = (value)=>{
        setContato(value)
    }

    const changeEmail = (value)=>{
        setEmail(value)
    }

    const changeTelefone = (value)=>{
        setTelefone(value)
    }

    const changeLocal = (value)=>{
        setLocal(value)
    }

    const changeEndereco = (value)=>{
        setEndereco(value)
    }

    const changeDescricao = (value)=>{
        setDescricao(value)
    }

    const changeAvisarProdutor = (value)=>{
        setAvisarProdutor(value)
    }

    const changeEvento = (value)=>{
        setShowEventoComum(value)
        setShowEndereco(false)
    }

    const changeHoraInicial = (value)=>{
        console.log(value)
        setDataInicial(value)
    }

    const changeHoraFinal = (value)=>{
        setDataFinal(value)
    }

    const changeMinutos = (value)=>{
        setMinutos(value)
        setErroMinutos(false)
    }

    useEffect(()=>{
        if(showNewEvent){
            getAgronomos()
            getProdutores()
            getTipos()
            getMotivos()
        } else {
            setAnimationCard(false);
        }
    },[showNewEvent])

    useEffect(()=>{
        if(produtor){
            getFazendas(produtor.value)
        }
    }, [produtor]) 
    
    useEffect(()=>{
        if(agendaId){
            getAgendaId(agendaId.value)
        }
    }, [agendaId]) 

    useEffect(()=>{
        if((window.location.search.slice(1))){
            
            let idProducer = window.location.search.slice(1);

            postSearch({key: 'Enter'}, idProducer)

        }else{
            getAgendas()
        }
        
        setTimeout(() => {
            setAvisarProdutor(false)
            setAvisarResponsavel(false)
        }, 100);
    }, []) 

    useEffect(() => {

        if (load === false) {
            if (props.params) {
                if (props.params === []) {
                    setExistParams(false);


                } else if (props.params.produtor || props.params.fazenda || props.params.devolucao) {
                    setExistParams(true);

                } else {
                    setExistParams(false);

                }
            } else {   
                setExistParams(false);

            }
        }
    },[]);

    useEffect(() => {
        if (showNewEvent === false) {
            clearStates();
        }
    }, [showNewEvent])

    const eventPropGetter = useCallback((event, start, end, isSelected) => ({
        ...(compareData(parseDateToObject(event.data_fin), '<', new Date()) ? {
            style: {
                border: '1px solid rgba(186, 193, 207, 0.9)',
                backgroundColor: 'rgba(186, 193, 207, 0.1)',
                color: 'rgba(186, 193, 207, 0.9)',
                fontWeight: 700,
                fontSize: '16px'
            }

        } : event.agro_task_type_id === 1 ? {
            style: {
                border: '1px solid #03903e',
                backgroundColor: 'rgba(3, 144, 62, 0.05)',
                color: '#03903e',
                fontWeight: 700,
                fontSize: '16px'

            }
        } : event.agro_task_type_id === 2 ? {
            style: {
                border: '1px solid #d9261d',
                backgroundColor: 'rgba(217, 38, 29, 0.05)',
                color: '#d9261d',
                fontWeight: 700,
                fontSize: '16px'

            }
        } : event.agro_task_type_id === 3 ? {
            style: {
                border: '1px solid #589bff',
                backgroundColor: 'rgba(88, 155, 255, 0.05)',
                color: '#589bff',
                fontWeight: 700,
                fontSize: '16px'

            }
        } : {
            style: {
                border: '1px solid #2fbb69',
                backgroundColor: 'rgba(47, 187, 105, 0.05)',
                color: '#2fbb69',
                fontWeight: 700,
                fontSize: '16px'

            }
        })
        
        
        // ...(isSelected && {
        //     style: {
        //         backgroundColor: '#F00',
        //     },
        // }),
        // ...(moment(start).hour() < 12 && {
        //     className: 'powderBlue',
        // }),
        // ...(event.title.includes('Meeting') && {
        //     className: 'darkGreen',
        // }),
    }),[])

    const { defaultDate, formats } = useMemo(
        () => ({
          formats: {
            // the 'date' on each day cell of the 'month' view
            dateFormat: (date, culture, localizer) =>
                localizer.format(date, 'D', culture),
            // the day header in the 'week' and 'day' (Time Grid) views
            dayFormat: (date, culture, localizer) =>
              props.isMobile === true ? localizer.format(date, 'ddd', culture):
              localizer.format(date, 'ddd D', culture)
            //dia e mês na parte do mês
            ,monthHeaderFormat: (date, culture, localizer) =>
              localizer.format(date, 'MMMM YYYY', culture),
            // the time in the gutter in the Time Grid views
            timeGutterFormat: (date, culture, localizer) =>
              localizer.format(date, 'HH:mm ', culture),
            //Nome do mês na semana
            dayRangeHeaderFormat: (date, culture, localizer) =>
              localizer.format(date, "MMMM YYYY", culture),
            dayHeaderFormat: (date, culture, localizer) =>
              localizer.format(date, 'MMMM D, YYYY', culture),
          },
        }),
        [props]
      )
    
    return (         
        
        <>
            <GenericUpper
                title="Agenda"
                subtitle="Cadastre suas tarefas, reuniões e compromissos."
                search={true}
                searchFunction={(text) => postSearchText(text)}
                placeholder="Responsável técnico, Produtor, Fazenda ou Tarefa"
            ></GenericUpper>

            {loading ? (
                <Loading></Loading>
            ) : (
                <>
                    {showList ? (
                        <>
                            <div style={{marginLeft: '20px', marginRight: '20px', marginBottom: '10px'}}>
                                
                                
                                <Table>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Dia</th>
                                            <th>Hora</th>
                                            <th>Assunto</th>
                                            <th>Descrição</th>
                                            <th>Tipo Tarefa</th>
                                            <th>Motivo Tarefa</th>
                                            
                                        </tr>
                                    </thead>
                                    <TBody>
                                        {myEvents.map(agenda => (
                                            
                                            <tr
                                                onClick={e => console.log()
                                                }
                                                key={agenda.id}
                                            >
                                                <td>{pegarDia(agenda.data_ini)}</td>
                                                <td>{formatarData(formatDate(agenda.data_ini))}</td>
                                                <td>{pegarHora(agenda.data_ini) === '00:00' && pegarHora(agenda.data_fin) === '00:00' ? <b>O dia todo</b> :  pegarHora(agenda.data_ini) + ' até ' + pegarHora(agenda.data_fin)}</td>    
                                                <td>{agenda.title}</td>
                                                <td>{agenda.description}</td>
                                                <td>{agenda.agro_task_type}</td>
                                                <td>{agenda.agro_task_reason}</td>  

                                            </tr>
                                        ))}
                                    </TBody>
                                </Table>
                            </div>

                            <div className="voltar" id='back' style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px", marginBottom: "10px"}}>
                                <Button 
                                    width="235px"
                                    height='50px'
                                    value='VOLTAR'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => {
                                        props.history.push('/agenda')
                                        setShowList(false)
                                        getAgendas()
                                    }}
                                    loading={false}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <FundoCalendario>

                                <div 
                                    className="button-finish" 
                                    style={{ 
                                        width: '100%', 
                                        // height: '200px',
                                        // display: 'flex', 
                                        flexDirection: 'column', 
                                        justifyContent: 'center',
                                        alignItems: 'center', 
                                        marginBottom: '16px',
                                    }}
                                >
                                    <Button 
                                        width='95%'
                                        height='50px'
                                        value='ADICIONAR EVENTO'
                                        outline={false}
                                        disabled={false}
                                        onClick={() => newEvent()}
                                        loading={false}
                                    />

                                </div>

                                <DnDCalendar style={{fontFamily: 'lato'}}
                                    localizer={localizer}
                                    defaultView={Views.WEEK}
                                    events={myEvents}
                                    formats={formats}
                                    onSelectEvent={(e) => handleSelectEvent(e)}
                                    messages={{
                                        week: 'Semana',
                                        work_week: 'Semana de trabalho',
                                        day: 'Dia',
                                        month: 'Mês',
                                        previous: '<',
                                        next: '>',
                                        today: 'Hoje',
                                        agenda: 'Agenda',
                                        noEventsInRange: 'Nenhum evento programado.',
                                        date: 'Data',
                                        time: 'Hora',
                                        event: 'Evento',
                                        allDay: 'O dia todo',
                                        showMore: (total) => `+${total} eventos`,

                                    }}
                                    eventPropGetter={eventPropGetter}
                                    draggableAccessor={(events) => true}
                                    resizable={true}
                                    onEventDrop={(e) => moveEvent(e)}
                                    onEventResize={resizeEvent}
                                    popup
                                    onSelectSlot={(events)=> criarNovo(events)}
                                    onSelecting={(events)=> criarNovo(events)}
                                    selectable
                                />
                            </FundoCalendario>
                        </>
                    )}
                </>
            )}

            <CardRight 
                open={showNewEvent} 
                updateIsOpen={(e)=> setShowNewEvent(e)}
                animation={animationCard}
                title={isEdit ? 'Editar evento' : 'Novo evento'}
                isEdit={isEdit}
                updateDelete={() => deleteEvent()}
            >
                <ContainerModal>
                    <InputV2
                        id="titulo"
                        value={titulo || ''}
                        label="Título"
                        onChange={(e) => changeTitulo(e)}
                    />

                    <TextArea
                        id="descricao"
                        value={descricao}
                        name={"descricao"}
                        disabled={false}
                        digitavel={true}
                        label="Descrição"
                        maxlength="number"
                        onChange={(e) => changeDescricao(e)}
                    />

                    <SelectV2 
                        name='responsavel_tecnico'
                        className="select"
                        label="Responsável Técnico"
                        id='responsavel_tecnico'
                        value={responsavel.value || responsavel}
                        disabled={disabledResponsavel}
                        loading={loadingResponsavel}
                        erro={erroResponsavel}
                        selectUniqueOption={true}
                        onChange={(e) => changeResponsavel(e)}
                        options={agronomos}
                    />

                    <YesNoContainerGeral> 
                        <span>
                            {'Agendar evento com cliente '}
                        </span>

                        <Interruptor 
                            value={showEventoComum} 
                            onChange={(e) => changeEvento(e)}
                            disabled={false}
                        />
                    </YesNoContainerGeral>

                    {!showEventoComum ? (
                        <>
                       <InputV2
                            id="contato"
                            value={contato || ''}
                            label="Nome do contato"
                            onChange={(e) => changeContato(e)}
                        />
                        
                        <InputV2
                            id="email"
                            value={email || ''}
                            label="E-mail"
                            onChange={(e) => changeEmail(e)}
                        />
    
                        <InputV2
                            id="telefone"
                            value={telefone || ''}
                            label="Telefone"
                            name="telefone"
                            onChange={(e) => changeTelefone(e)}
                        />                            
    
                        <InputV2
                            id="fazenda"
                            value={local || ''}
                            label="Fazenda"
                            onChange={(e) => changeLocal(e)}
                        />
    
    
                            {showEndereco ? (
                                <InputV2
                                    id="endereco"
                                    value={endereco || ''}
                                    label="Endereço"
                                    onChange={(e) => changeEndereco(e)}
                                />
        
                            ) : (
                                <Plus>
                                <p id="msg">Adicionar Endereço</p>
                                <img 
                                    className="add-select" 
                                    src={DeleteIcon} 
                                    alt='add'
                                    onClick={address}
                                    width='10px'
                                />
                            </Plus>
                            )
                            
                            }
                        </>

                    ) : (
                        <>
                            <SelectV2 
                                name='produtor'
                                className="select"
                                label="Produtor"
                                id='produtor'
                                value={produtor.value || produtor}
                                disabled={disabledProdutor}
                                loading={loadingProdutor}
                                erro={erroProdutor}
                                onChange={(e) => changeProdutor(e)}
                                options={produtores}
                                selectUniqueOption={true}
                                buttonAddNew={true}
                                onClickAddNew={() => addNewProdutor()}
                                isModal={true}
                                responsive={true}
                            />
                            
                            <SelectV2 
                                name='fazendas'
                                className="select"
                                label="Fazenda"
                                id='fazendas'
                                value={fazenda.value || fazenda}
                                disabled={disabledFazenda}
                                loading={loadingFazenda}
                                erro={erroFazenda}
                                selectUniqueOption={true}
                                onChange={(e) => changeFazenda(e)}
                                options={fazendas}
                            />
                        </>
                    )}                 

                    <SelectV2 
                        name='tipo'
                        className="select"
                        label="Tipo do Evento"
                        id='tipo'
                        value={tipo.value || tipo}
                        disabled={disabledTipo}
                        loading={loadingTipo}
                        erro={erroTipo}
                        yesNo={tipoYesNo}
                        yesNoValue={avisarProdutor}
                        responsive={true}
                        messageYesNo='Avisar Produtor'
                        onChangeYesNo={(e) => changeAvisarProdutor(e)}
                        onChange={(e) => changeTipo(e)}
                        options={tipos}
                        marginBottom='30px'
                    />

                    <SelectV2 
                        name='motivo'
                        className="select"
                        label="Motivo do evento"
                        id='motivo'
                        value={motivo.value || motivo}
                        disabled={disabledMotivo}
                        loading={loadingMotivo}
                        erro={erroMotivo}
                        onChange={(e) => changeMotivo(e)}
                        options={motivos}
                    />

                    <InputV2
                        id="horaInicial"
                        value={dataInicial || ''}
                        label="Hora inicial"
                        type="datetime-local"
                        labelUp={true}
                        onChange={(e) => changeHoraInicial(e)}
                    />

                    <InputV2
                        id="horaFinal"
                        value={dataFinal || ''}
                        label="Hora final"
                        type="datetime-local"
                        labelUp={true}
                        onChange={(e) => changeHoraFinal(e)}
                    />

                    <div className="Notificar" style={{
                        width: "-4px",
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        height: "60px",
                    }}>
                        <YesNoContainer> 
                            <Interruptor 
                                value={avisarResponsavel} 
                                onChange={(e) => changeAvisarResponsavel(e)}
                                disabled={false}
                            />

                            <span style={{marginLeft: '5px' }}>
                                {'Notificar'}
                            </span>
                        </YesNoContainer>

                        <InputV2
                            name={'minutos'}
                            marginBottom="-20px"
                            disabled={!avisarResponsavel}
                            unidade={'min. antes'}
                            id="minutos"
                            value={minutos || ''}
                            erro={erroMinutos}
                            onChange={(e) => changeMinutos(e)}
                        />
                    </div>
                    
                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                        <Button 
                            width='296px'
                            height='50px'
                            value='SALVAR EVENTO'
                            outline={false}
                            disabled={false}
                            onClick={() => isEdit ? putAgenda() : saveEvent()}
                            loading={loading}
                        />
                    </div>

                </ContainerModal>
            </CardRight>

            {showModalAddProdutor ? (
                <Modal
                    show={showModalAddProdutor}
                    onHide={() => closeProdutorModal()}
                >
                    <AddProdutor isModal={true} update={() => updateProdutor()}/>
                </Modal>
            ) : (null)}

            
        </>
    );
};

export default Agenda;
