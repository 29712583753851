import styled from "styled-components";

export const ContainerNotificacao = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .seemore {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    button{
      margin: 30px 0;
      padding: 15px 20px;
    }
  }
 
  @media only screen and (max-width: 980px) {
    margin-top: 0px;
  }

`;
