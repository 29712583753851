import styled from "styled-components";

export const LoginWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #1d2129;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;



  @media only screen and (max-width: 820px) {
    height: calc(100vh + 100px);
    background-color: #1d2129;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 30px;

  }

  /* @media only screen and (max-height: 568px) {
    height: auto;
  } */
  
  
`;

export const Col2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

export const ContainerLogo = styled.div`
  width: 60%;

  display: flex;
  flex-direction: column;


  .logo{
    width: 100%;
  }

  .title{
    width: 100%;
    color: #FFF;
    font-family: Lato;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    line-height: 33.6px;
  }

  .subtitle{
    width: 100%;
    color: #CED2DC;
    font-family: Lato;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
  }

  img {
    width: 120px;
    margin-bottom: 48px;
  }

  @media only screen and (max-width: 820px) {

    margin-bottom: 10px;

    .logo{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .title{
      width: 100%;
      text-align: center;
    }

    .subtitle {
      width: 100%;
      text-align: center;
    }
  }

`;

export const ContainerForm = styled.div`
  width: 400px;
  height: auto;
  background-color: #FFF;
  position: static;
  top: calc(50% - 386px/2);
  border: 1px solid #FFF;
  border-radius: 8px;
  padding: 24px 32px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .btnLinkedin{
    /* width: 242px; */
    width: 216px;
    height: auto;
    padding: 7px;
    display: flex;
    align-items: center;
    border: 1px solid #dbdde1;
    border-radius: 5px;
    cursor: pointer;
  }

  .iniciar{
    margin-left: 4px;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    /* font-family: "Google Sans", arial, sans-serif; */
    font-size: 12.05px;
    letter-spacing: 0.25px;
    text-align: center;
    white-space: nowrap;
    color: #3c4043;
    font-weight: 500;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    vertical-align: top;
  }

  .btnGoogle{
    width: auto;
    height: auto;
    padding: 5px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }

  @media only screen and (max-width: 820px) {
    /* padding: 10px; */

  }

  @media only screen and (max-width: 540px) {
    width: 90%;
  }
  
  @media only screen and (max-width: 460px) {
    width: 90%;
  }

  @media only screen and (max-width: 371px) {
    .box-end {
      .link {
        text-align: start;
      }
    }
  }

`;

export const Line = styled.div`
  width: 100%;
  height: 47px;
  padding-top: 5px;
  padding-bottom: 0px;

  hr {
    margin-top: 16px;
  }

  span {
    width: 16px;
    height: 15px;
    padding-left: 12px;
    padding-right: 12px;
    color: #000000;
    background-color: #FFF;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 150%;
    position: relative;
    top: -14px;
    left: calc(50% - 28px/2);
  }

`;

export const ContainerInputsForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .recover{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    label{
      text-align: end;
      font-weight: 700;
      font-size: 14px;
      font-style: normal;
      color: #589BFF;
      cursor: pointer;
    }
  }


  .box-end{
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a {
      text-decoration: none;
    }

    label{
      text-align: end;
      font-weight: 700;
      font-size: 14px;
      font-style: normal;
      color: #589BFF;
      cursor: pointer;
    }
  }


  
`;


