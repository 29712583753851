import React from "react";

import Select2 from "react-select";
import { Wrapper } from "./styles";
import IconState from "../../assets/icons/pin.svg";

const Select = ({ ...props }) => {

  const styles = {
    control: provided => ({
      ...provided,
      borderRadius: "25px",
      height: "50px",
      border: "none",
      boxShadow: "0 0 10px 0 rgba(29, 33, 41, 0.05)",
      padding: "0 10px 5px",
      fontWeight: "bold",
      marginTop: '-10px'
    }),
    placeholder: provided => ({
      ...provided,
      color: "#ced2dc"
    })
  };

  return (
    <Wrapper state={props.estado}  isCompact={props.isCompact} width={props.width}>
      <label> {props.label || props.placeholder}</label>

      <Select2
        className="select"
        {...props}
        value={
          props.options ? props.options.find(option => {
                return option.value === props.value;
              })
            : props.value
        }
        isDisabled={props.disabled}
        noOptionsMessage={() => "Não há opções disponíveis."}
        styles={styles}
      >

      </Select2>
      {props.estado == true ? ( 
        <div className="pin-estado">
          <img src={IconState} />
        </div>
      ):null}
    </Wrapper>
  );
};

export default Select;
