import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  background: transparent;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 900px;
  height: ${props => ( !props.openBox ? props.existInformation ? 'auto' : 'auto' : 'auto')};
  margin: 20px auto 20px;
  padding: 36px 36px 30px 36px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
  background-color: #FFF;
  border: ${props => (props.erro ? 'solid 1px #ff5252' : ' none')};
  display: flex;
  flex-direction: column;
  justify-content: ${props => (!props.openBox ? 'space-between' : 'flex-start')};
  align-items: center;
  color: ${props => (props.disabled? '#bbbbbb' : '#303848')};
  transition: all 0.2s;
  
  h3 {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #303848;
    position: absolute;
    top: 24px;
    margin: auto;
    left: 50%;
    margin-left: -75px;
  }


  .row{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .arrow {
      transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
      transition: all 0.2s;
      &:hover {
        cursor: pointer;
        transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
      }

      &:active {
        transform: scale(0.8);
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: auto;
    margin: 20px 0px 20px;
    padding: 36px 36px 30px 36px;
  }

  @media only screen and (max-width: 780px) {
    width: 100%;
    height: ${props => ( !props.openBox ? props.existInformation ? 'auto' : 'auto' : 'auto')};
    margin: 0px;
    margin-top: 20px;
    padding: 36px 5% 30px 5%;

    .row{
      justify-content: center;
    }

  }
`;

export const Title = styled.h2`
  font-family: Lato;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${props => (props.disabled? '#bbbbbb' : '#303848')};

  @media only screen and (max-width: 780px) {
    font-size: 21px;
  }
`;

export const Paragraph = styled.p`
  width: 100%;
  margin: 8px 0 0;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: ${props => (props.disabled? '#bbbbbb' : '#303848')};
`;

export const RowInfo = styled.div`
  width: 100%;
  margin: 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  @media only screen and (max-width: 780px) {
    flex-direction: column;

  }
`;

export const RowCard = styled.div`
  width: 100%;
  /* height: ${props => (props.openBox ? '550px' : '90px')}; */
  max-height: 550px;
  padding: ${props => (props.openBox ? '0px 24px 20px 24px' : '24px 24px 20px 24px')};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: ${props => (props.openBox ? 'flex-start' : 'center')};
  border: ${props => (props.isBorder ? props.erro ? 'solid 1px #ff5252' : 'solid 1px #e8edf3' : 'none')};
  border-radius: ${props => (props.isBorder ? '10px' : '0px')};
  transition: all 0.2s;

  .input_area {
    width: 45%;
  }

  .input_area_2 {
    width: 45%;
  }

  .selectValue{
    width: 270px;
    height: 50px;
  }

  .align {
    display: flex;                            
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .cancel {
    width: 15px;
    height: 17px;
    margin-top: ${props => (props.openBox ? '0px' : '0px')};
    transition: all 0.2s;
    
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
    &:active {
      transform: scale(0.8);
    }
  }
  .arrow {
    transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
    margin-top: ${props => (!props.openBox ? '-10px' : '0px')};
    transition: all 0.2s;
    &:hover {
      cursor: pointer;
      transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
    }

    &:active {
      transform: scale(0.8);
    }
  }


  @media only screen and (max-width: 780px) {
    height: auto;
    padding: ${props => (props.openBox ? '45px 24px 40px 24px' : '45px 24px 40px 24px')};



    .align {
      height: auto;
      display: flex;                            
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 10px;
    }

    .input_area {
      width: 100%;
    }

    .input_area_2 {
      width: 100%;
    }

    .selectValue{
      width: 97%;
      height: 65px;
    }

    .cancel {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .arrow {
      transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
      margin-top: ${props => (!props.openBox ? '-10px' : '0px')};
      
      position: ${props => props.openBox ? 'relative' : 'initial'};
      bottom: ${props => props.openBox ? '-358px' : '0px'};
      transition: all 0.2s;

      

      &:hover {
        cursor: pointer;
        transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
      }

      &:active {
        transform: scale(0.8);
      }
    }
  }
`;

export const ColInfo = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  ~ div {
    margin-left: 48px;
  }
  @media only screen and (max-width: 780px) {
    ~ div {
      margin-left: 0px !important;
    }    
  }
`;

export const Label = styled.label`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.disabled? '#bbbbbb' : props.erro ? '#ff5252' : '#bac1cf')};

  @media only screen and (max-width: 780px) {
    margin-bottom: 0px !important;
  }

`;

export const Texto = styled.p`
  margin: 0px;
  font-family: Lato;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.disabled? '#bbbbbb' : props.erro ? '#ff5252' : '#303848')};
  height: 17px;

  @media only screen and (max-width: 780px) {
    margin-bottom: 10px !important;
  }

`;
export const ContainerForm = styled.div`
  width: 100%;
  height: auto;
  padding-right: 200px;
  padding-left: 200px;
  padding-top: 30px;
  margin-top: 36px;
  border-top: 1px solid #e8edf3;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 991px) {
    display: block; /* Remove flexbox */
    padding-right: 25%;
    padding-left: 25%;
    height: auto;
    justify-content: unset;
    align-items: unset;
  }

  @media only screen and (max-width: 780px) {
    padding-right: 15%;
    padding-left: 15%;
    height: auto;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
`;





export const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  
  .pin{
    width: 12px;
  }
  p{
    margin: 0px;
    margin-left: 8px;

    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a92a2;
    
    span{
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #21262f;
    }
  }
`;

export const ContainerRelationOptions = styled.div`
  width: 100%;
  height: auto;
  /* padding-right: 170px;
  padding-left: 170px; */
  padding-top: 30px;
  margin-top: 3px;
  overflow-y: scroll;

  @media only screen and (max-width: 990px) {
    padding-right: 120px;
    padding-left: 120px;
  }
  

  @media only screen and (max-width: 780px) {
    padding-right: 15%;
    padding-left: 15%;
  }
  
  @media only screen and (max-width: 440px) {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  @media only screen and (max-width: 380px) {
    padding-right: 5px;
    padding-left: 5px;
  }

  /* width */
  ::-webkit-scrollbar {
  width: 6px;

  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 8px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
`;
