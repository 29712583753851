import React, {useState, useEffect} from 'react';
import Contracts from '../../components/arts/contracts';
import { withRouter } from "react-router-dom";
import GenericUpper from '../../components/generic-upper';
import api from '../../services/api';
import { store } from 'react-notifications-component';

const Contract = ({ ...props }) => {

    const [agronomo, setAgronomo] = useState('');
    const [estado, setEstado] = useState([]);
    const [filial] = useState('');
    const [crea, setCrea] = useState('');
    const [numeroArt, setNumeroArt] = useState('');
    const [artId, setArtId] = useState('');
    const [receitaInicial] = useState('');
    const [quantidadeContratos] = useState('');
    const [numeroOrdem] = useState('');
    const [quantidadeReceita] = useState('');
    const [produtorId, setProdutorId] = useState('');
    const [localDevolucaoId, setLocalDevolucaoId] = useState('');
    const [valorContrato, setValorContrato] = useState('');
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');

    const getAgronomo = async (artId) => {
        try {
            const response = await api.get(`/arts/${artId}`);
            if (response) {
                let agronomo = response.data.data.crea.agronomo;
                let crea = response.data.data.crea;
                let art = response.data.data;

                setAgronomo(agronomo.id);
                setCrea(crea.id);
                
                let estadoC = {value: crea.estado};
                setEstado(estadoC);
                
                setNumeroArt(art.numero_art);
                setArtId(art.id);

            }
        } catch (error) {
            
        }
    }

    const update = () => {
        props.history.push(`/contracts/${props.match.params.id}`);
        store.addNotification({
            title: "Sucesso!",
            message: "o Contrato foi cadastrado com sucesso!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    useEffect(() => {
        getAgronomo(props.match.params.id)
    },[]);

    return (
        <>
            {agronomo && crea && estado.value && numeroArt && artId && (
                <>
                    <GenericUpper
                        align='center'
                        title='Cadastrar contrato'
                    ></GenericUpper>

                    <Contracts
                        estado={estado}
                        agronomo={agronomo}
                        crea={crea}
                        filial={filial}
                        numeroArt={numeroArt}
                        artId={artId}
                        quantidadeReceita={quantidadeReceita}
                        receitaInicial={receitaInicial}
                        quantidadeContratos={quantidadeContratos}
                        numeroOrdem={numeroOrdem}
                        produtorId={produtorId}
                        localDevolucaoId={localDevolucaoId}
                        valorContrato={valorContrato}
                        dataInicial={dataInicial}
                        dataFinal={dataFinal}
                        loc={props}
                        isModal={true}
                        update={() => update()}
                    />
                </>
            )}
        </>
    )
}

export default withRouter(Contract);
