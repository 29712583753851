import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Modal from '.';
import { ModalBody } from './styles';

class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    updateOk = () => {
      let resposta = 1;
      this.props.update(resposta);
    }

    updateCancelar = () => {
      let resposta = 0;
      this.props.update(resposta);
    }



    render(){
        return(
          <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
            width='60%'
          >
            <ModalBody>
              {this.props.senha == true ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
                  {this.props.loginUsado ? (<>
                    <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '138px', alignSelf: 'center'}}>Conta em uso</p>
                  
                    <p style={{marginTop: '8px', color: '#bac1cf', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '369px', alignSelf: 'center'}}>{this.props.text}</p>
                  </>) : (<>
                    <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>Alteração de senha</p>

                    <p style={{marginTop: '8px', color: '#bac1cf', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>Enviamos um e-mail ao endereço</p>
                    <p style={{marginTop: '8px', color: '#bac1cf', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.text} altere a sua senha!</p>
                    {/* <p style={{marginTop: '8px', color: '#bac1cf', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}></p> */}
                  </>)}

                  {this.props.btnOk == true ? (
                      <Button
                        style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
                        color="#00b43f"
                        onClick={() => this.updateOk() }
                        >
                        ENTENDI
                      </Button>
                  ):(null)}
                  {this.props.btnCancelar == true ? (
                      <Button
                        style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
                        color="#F00"
                        onClick={() => this.updateCancelar() }
                        >
                        CANCELAR
                      </Button>
                  ):(null)}
                </div>
              ):(
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
                  {this.props.title ? (<>
                    <p style={{fontFamily: 'Lato', marginTop: '-30px',fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.title}</p>
                  
                  </>):(null)}
                  {
                    this.props.toSearch && this.props.textToSearch ? (
                      <p style={{marginTop: '8px', color: '#000', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>
                        {this.props.text} <a target="_blank" href={this.props.toSearch} style={{fontWeight: 'bolder', textDecoration: 'none'}}>{this.props.textToSearch}</a>
                      </p>
                    ) : <p style={{marginTop: '8px', color: '#000', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.text}</p>
                  }
                    {this.props.text2 ? (<>
                      <p style={{marginTop: '8px', color: '#000', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.text2}</p>
                  
                    </>):(null)}
                  <div className="btns" style={{ display: 'flex' }}>
                      {this.props.btnOk == true ? (
                          <Button
                            style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
                            color="#00b43f"
                            onClick={() => this.updateOk() }
                          >
                            ENTENDI
                          </Button>
                      ):(null)}
                      {this.props.btnCancelar == true ? (
                          <Button
                            style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
                            color="#F00"
                            onClick={() => this.updateCancelar() }
                            >
                            CANCELAR
                          </Button>
                      ):(null)}
                  </div>

                  <div className="btns" style={{ display: 'flex' }}>
                      {this.props.btnLogin == true ? (
                          <Button
                            outline
                            color="#21262F"
                            style={{ outline:0, padding: "10px", width:"140px", margin:"auto", marginTop: '15px'}}
                            onClick={() => this.updateOk() }
                            >
                            JÁ TENHO CADASTRO
                          </Button>
                      ):(null)}
                      {this.props.btnCadastro == true ? (
                          <Button
                            outline
                            color="#21262F"
                            style={{ outline:0, padding: "10px", width:"140px", margin:"auto", marginTop: '15px'}}
                            onClick={() => this.updateOk() }
                            >
                            JÁ TENHO CADASTRO
                          </Button>
                      ):(null)}
                      {this.props.btnCadastrar == true ? (
                          <Button
                            style={{ outline:0, padding: "10px", width:"125px", margin:"auto", marginTop: '15px'}}
                            color="#00b43f"
                            onClick={() => this.updateCancelar() }
                            >
                            CADASTRAR
                          </Button>
                      ):(null)}
                      {this.props.btnCadastrarTeste == true ? (
                          <Button
                            style={{ outline:0, padding: "10px", width:"125px", margin:"auto", marginTop: '15px'}}
                            color="#00b43f"
                            onClick={() => this.updateCancelar() }
                            >
                            TESTE GRÁTIS
                          </Button>
                      ):(null)}
                  </div>
                </div>
              )}
            </ModalBody>
          </Modal>
        );
    }
}
export default ModalNew;