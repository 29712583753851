import viacep from "./viacep";

function UserException(message) {
    this.message = message;
    this.name = "UserException";
 }

const buscaCep = async (cep) => {

    if (cep != '') {
        let formatCep = cep.replace(/([^\d])+/gim, '');
    
        try {
            const response = await viacep.get(formatCep + "/json");

            if (response.data.cep) {
                return response.data;
            } else {
                throw new UserException("CEP inserido é inválido!");
            }
        } catch (error) {
            throw new UserException("CEP inserido é inválido!");
        }
    
    } else {
        throw new UserException("CEP não pode estar em branco!");
    }
}

export default buscaCep;
