import styled from "styled-components";



export const Grid = styled.div`
  width: 100%;
  max-width: 1108px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;

  .row1{
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: ${props => (props.isMobile ? "column" : "row")};
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    height: auto;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    margin-bottom: 20px;
    margin-top: ${props => (props.isMobile ? "0px" : "0px")};

    .ultima-receita{
      margin-right: ${props => (props.isMobile ? "auto" : "31px")};

    }

    .notificacoes{
      margin: auto;
      margin-left: ${props => (props.isMobile ? "0px" : "0px")};
    }



  }

  .total-receitas{
    width: 100%;
    height: 270px;
    border-radius: 10px;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    background-color: #FFF;
    padding-left: 15px;
    padding-right: 15px;
    margin: 16px 0px 0px 0px;
  }

  .tempo{
    margin: auto;
    width: 90%;
    background-color: red;
    display: flex;
    flex-direction: ${props => (props.isMobile ? "column" : "row")};
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    height: auto;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    margin-bottom: 20px;
    margin-top: ${props => (props.isMobile ? "0px" : "0px")};

    .ultima-receita{
      margin-right: ${props => (props.isMobile ? "auto" : "31px")};

    }

    .notificacoes{
      margin: auto;
      margin-left: ${props => (props.isMobile ? "0px" : "0px")};
    }

  }

  .tempo{
    margin: auto;
    width: 90%;
    background-color: red;
    display: flex;
    flex-direction: ${props => (props.isMobile ? "column" : "row")};
    justify-content: space-between;
    align-items: center;
    border-radius: 0.5rem;
    height: auto;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    margin-bottom: 20px;
    margin-top: ${props => (props.isMobile ? "0px" : "0px")};

    .ultima-receita{
      margin-right: ${props => (props.isMobile ? "auto" : "31px")};

    }



    .notificacoes{
      margin: auto;
      margin-left: ${props => (props.isMobile ? "0px" : "0px")};
    }

  }

  .news{
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;

    .row-news{
      flex-direction: ${props => (props.isMobile ? "column" : "row")};
      flex-wrap: wrap;
      width: ${props => (props.isMobile ? "285px" : "100%")};
      .news-card{
        margin-top: ${props => (props.isMobile ? "20px" : "20px")};
      }
    }

  }

  .buttom-right{
    width: 100%;
    margin: auto;
    margin-bottom: ${props => (props.isMobile ? "40px" : "0")};

    button{
        display: ${props => (props.isMobile ? "" : "none")};
    }
  }



  @media only screen and (max-width: 1199px){
    .row1{
      flex-direction: column;
      justify-content: space-between;

      .notificacoes{
        margin-top: 35px;
      }

    }

    /* .total-receitas{
      flex-direction: column;
    } */
  }
  @media only screen and (max-width: 991px){
    .news{
      width: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;

      .row-news{
        flex-direction: ${props => (props.isMobile ? "column" : "row")};
        flex-wrap: wrap;
        width: ${props => (props.isMobile ? "285px" : "100%")};
        .news-card{
          margin-top: ${props => (props.isMobile ? "20px" : "20px")};
          width: 100%;
        }
      }
    }
  }



  @media only screen and (max-width: 843px){
    .total-receitas{
      margin: 0px 0px 16px 0px;
    }
  }

`;

export const Row1 = styled.div`
  margin: auto;
  width: 90%;
  display: flex;
  flex-direction: ${props => (props.isMobile ? "column" : "row")};
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  height: auto;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  margin-bottom: 20px;
  margin-top: ${props => (props.isMobile ? "0px" : "20px")};

  .ultima-receita{
    margin-right: ${props => (props.isMobile ? "auto" : "31px")};

  }

  .notificacoes{
    margin: auto;
    margin-left: ${props => (props.isMobile ? "0px" : "0px")};
  }
`;

export const Row = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: auto;

  margin: ${props => (props.margin ? props.margin : 'auto')};
  padding: ${props => (props.padding ? props.padding : '8px')};
  display: flex;
  flex-direction: row;

  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0);
  color: white;


  @media only screen and (max-width: 843px){
    flex-direction: column;
  }

`;

export const Col = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'auto')};

  margin: ${props => (props.margin ? props.margin : 'auto')};
  display: flex;
  flex-direction: column;

  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0);
  color: white;

  ${props => props.alignSelf && `
    align-self: ${props.alignSelf};
  `}

  @media only screen and (max-width: 843px){
    width: ${props => props.widthResp ? props.widthResp : '100%'};
    flex-direction: column;
  }
`;

export const AreaHome = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : 'auto')};

  margin: ${props => (props.margin ? props.margin : 'auto')};
  display: flex;
  flex-direction: column;

  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};


  @media only screen and (max-width: 1164px){
    width: ${props => props.widthResp ? props.widthResp : '100%'};
  }

  @media only screen and (max-width: 1000px){
    transform: scale(0.85);
    width: 116%;
    margin-left: -8%;
    margin-top: -13%;
  }

  @media only screen and (max-width: 843px){
    transform: scale(1);
    width: ${props => (props.width ? props.width : '100%')};
    height: ${props => (props.height ? props.height : 'auto')};

    margin: ${props => (props.margin ? props.margin : 'auto')};
  }
`;
