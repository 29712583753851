import styled from "styled-components";


export const BlogContainer = styled.section`
    padding: 50px 0;
    background: #f8f8f9;
    height: 100%;
    margin-top: 10px;
    

    

    .card-filtro{
        width: auto;
        height: auto;
        /* background-color: #FFF; */
        /* border-radius: 10px; */
        margin: auto;
        margin-top: 28px;
        margin-bottom: 30px;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        /* box-shadow: 0 0 10px 10px rgba(29, 33, 41, 0.04); */

        .item{
            border-radius: 10px;
            border: solid 1.3px #589bff;
            color: #589bff;
            height: auto;
            font-family: Lato;
            font-size: 8px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            display: flex;
            flex-direction: row;
            color: #589bff;
            padding: 5px 20px 5px 20px;

            .close-filter{
                margin-left: 10px;
                font-size: 12px;
                margin-top: -4px;
                cursor: pointer;

            }
        }

    }

    .loadmore {
      margin: 30px auto;
      padding: 15px 20px;

    }


    .search{
        /* ${props => (props.isMobile ? "column" : "row")} */
        /* background-color: #0F0; */
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin: auto;
        /* margin-left: ${props => (props.isMobile ? "-30px" : "135px")}; */
        margin-top: -80px;
        /* background-color: red; */
        
        .add{
            margin-left: 90px;
            margin-top: -12px;
    
            button{
                border-radius: 30px;
                border: solid 3px #FFF;
            }     
        }


        .btn-filtro{
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;  
            width: ${props => (props.isMobile ? "110px" : "98x")};
            height: 40px;
            border-radius: 30px;
            border: solid 3px #f8f8f9;
            background-color: #e8edf3;
            padding: 15px;
            /* padding-top: 7px; */
            cursor: pointer;
            /* margin-top: 8px; */
            /* margin-right: 8%; */

            img{
                width: 15px;
                height: 15px;
                margin-top: -4px;
                margin-right: 2px;
                cursor: pointer;
            }

            label{
                margin-top: 4px;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #586377;
                cursor: pointer;

            }
        }

        .exibe-options{
            
            .defesas{
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #FFF;
                width: 160px;
                height: auto;
                border-radius: 10px;
                box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
                padding: 15px;
                /* margin-left: 10px; */
                margin-left: -5px;
                margin-top: 5px;


                #arrow::before {
                    background-color: #FFF;
                    position: absolute;
                    width: 8px;
                    height: 8px;
                    /* z-index: -1; */
                    top: -4px;
                    /* right: 15px; */
                    margin-left: 35px;
                }

                #arrow::before {
                    content: '';
                    transform: rotate(45deg);
                    background: #FFF;
                }

                #tooltip[data-popper-placement^='top'] > #arrow {
                    bottom: -4px;
                }

                #tooltip[data-popper-placement^='bottom'] > #arrow {
                    top: -4px;
                }

                #tooltip[data-popper-placement^='left'] > #arrow {
                    right: -4px;
                }

                #tooltip[data-popper-placement^='right'] > #arrow {
                    left: -4px;
                }

                .option{ 
                    width: 145px;
                    height: 36px;
                    border-radius: 5px;
                    align-items: center;

                    p{
                        margin-left: 10px;
                        margin-top: 11px;
                        font-family: Lato;
                        font-size: 12px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #8a92a2;
                        cursor: pointer;
                    }

                    :hover{
                        background-color: #f3f3f5;
                        font-weight: bold;
                        cursor: pointer;

                    }
                }
            }

            .btn-relatorio{
                
                width: 145px;
                height: 40px;
                border-radius: 30px;
                border: solid 3px #f8f8f9;
                background-color: #00b43f;
                padding: 15px;

                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center; 
                cursor: pointer;
                /* margin-top: 10px; */
                /* margin-left: 8%; */
                transition: all 0.3s;

                i{
                    color: #FFF;
                }

                label{
                    margin-top: 4px;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    /* text-align: right; */
                    color: #fff;
                    cursor: pointer;

                }

                img{
                    width: 10px;
                    cursor: pointer;

                }

                &:hover{
                    box-shadow: 0 0 20px 0 #00b43f;
                }


            }
        }




    }



    .search-2{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-left: ${props => (props.isMobile ? "110px" : "135px")};
        margin-top: -80px;
        
        .btn-relatorio{
            width: 120px;
            height: 40px;
            border-radius: 30px;
            border: solid 3px #f8f8f9;
            background-color: #00b43f;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center; 
            cursor: pointer;
            margin-top: 10px;
            margin-left: 8%;
            padding: 15px;

            label{
                margin-top: 4px;
                font-family: Lato;
                font-size: 12px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #fff;
                cursor: pointer;
            }

        }


    }


    @media only screen and (max-width: 1130px) {
        .search{
            width: 90%;
        }
    }

    @media only screen and (max-width: 991px) {
        margin-top: 32px;
        .search{
            width: 100%;
        }
    }


    @media only screen and (max-width: 768px) {
        padding: 50px 0;
        background: #f8f8f9;
        height: 100%;
        margin-top: 68px;

        #container {
            max-width: 700px !important;
        }

        .card-filtro{
            margin-left: 5px;
            margin-right: 5px;
            padding: 0px;
            padding-bottom: 10px;
            justify-content: space-between;

            .item + .item{
                margin-top: 5px;

            }
        }


        .search{
            width: 100%;
            max-width:375px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: auto;
            margin-top: -80px;
            
            .add{
                margin-left: 90px;
                margin-top: -12px;
        
                button{
                    border-radius: 30px;
                    border: solid 3px #FFF;
                }     
            }


            .btn-filtro{
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;  
                width: ${props => (props.isMobile ? "110px" : "98x")};
                height: 40px;
                border-radius: 30px;
                border: solid 3px #f8f8f9;
                background-color: #e8edf3;
                padding: 15px;
                /* padding-top: 7px; */
                cursor: pointer;
                margin-top: 10px;
                /* margin-right: 8%; */

                img{
                    width: 15px;
                    height: 15px;
                    margin-top: -4px;
                    margin-right: 2px;
                    cursor: pointer;

                }


                label{
                    margin-top: 4px;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #586377;
                    cursor: pointer;

                }
            }

            .exibe-options{
                
                .defesas{
                    /* display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #FFF;
                    width: 160px;
                    border-radius: 10px;
                    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
                    padding: 15px;
                    margin-left: -5px;
                    margin-top: 5px; */

                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background-color: #FFF;
                    width: 160px;
                    border-radius: 10px;
                    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
                    padding: 15px;
                    margin-left: -5px;
                    margin-top: 5px;


                    #arrow::before {
                        background-color: #FFF;
                        position: absolute;
                        width: 8px;
                        height: 8px;
                        /* z-index: -1; */
                        top: -2px;
                        /* right: 15px; */
                        margin-left: 35px;
                    }

                    #arrow::before {
                        content: '';
                        transform: rotate(45deg);
                        background: #FFF;
                    }

                    #tooltip[data-popper-placement^='top'] > #arrow {
                        bottom: -4px;
                    }

                    #tooltip[data-popper-placement^='bottom'] > #arrow {
                        top: -4px;
                    }

                    #tooltip[data-popper-placement^='left'] > #arrow {
                        right: -4px;
                    }

                    #tooltip[data-popper-placement^='right'] > #arrow {
                        left: -4px;
                    }

                    .option{ 
                        width: 145px;
                        height: 36px;
                        border-radius: 5px;
                        align-items: center;

                        p{
                            margin-left: 10px;
                            margin-top: 11px;
                            font-family: Lato;
                            font-size: 12px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            color: #8a92a2;
                            cursor: pointer;
                        }

                        :hover{
                            background-color: #f3f3f5;
                            font-weight: bold;
                            cursor: pointer;

                        }
                    }
                }

                .btn-relatorio{
                    
                    width: 145px;
                    height: 40px;
                    border-radius: 30px;
                    border: solid 3px #f8f8f9;
                    background-color: #00b43f;
                    padding: 15px;

                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center; 
                    cursor: pointer;
                    margin-top: 10px;
                    /* margin-left: 8%; */

                    i{
                        color: #FFF;
                    }

                    label{
                        margin-top: 4px;
                        font-family: Lato;
                        font-size: 12px;
                        font-weight: bold;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        /* text-align: right; */
                        color: #fff;
                        cursor: pointer;

                    }

                    img{
                        width: 10px;
                        cursor: pointer;

                    }


                }
            }




        }



        .search-2{
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-left: ${props => (props.isMobile ? "110px" : "135px")};
            margin-top: -80px;
            
            .btn-relatorio{
                width: 120px;
                height: 40px;
                border-radius: 30px;
                border: solid 3px #f8f8f9;
                background-color: #00b43f;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center; 
                cursor: pointer;
                margin-top: 10px;
                margin-left: 8%;
                padding: 15px;

                label{
                    margin-top: 4px;
                    font-family: Lato;
                    font-size: 12px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #fff;
                    cursor: pointer;
                }

            }
            


        }

    }


`;