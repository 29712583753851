import React, { useState, useEffect, useCallback } from "react";
import Input from "../../input/input_v2";
import Select from "../../select/select_v2";
import Button from "../../button/button_v2";
import { Container } from "react-grid-system";
import { FormContainer } from "./styles";
import viacep from "../../../services/viacep";
import { store } from "react-notifications-component";
import api from "../../../services/api";
import { Multiselect } from 'multiselect-react-dropdown';
import validadorDeCpf from "../../../services/validadorCpf";
import buscaCep from "../../../services/buscaCep";
import { Form } from "../../../styles/global";
import MapaArea from "../../mapaArea";


const AddLocalAplicacao = ({ ...props }) => {
    const {
        produtor,
        isModal,
        updateModal
    } = props;

    const [nomeLocal, setNomeLocal] = useState('');
    const [cpfCnpjLocal, setCpfCnpjLocal] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [cepLocal, setCepLocal] = useState('');
    const [logradouroLocal, setLogradouroLocal] = useState('');
    const [bairroLocal, setBairroLocal] = useState('');
    const [numeroLocal, setNumeroLocal] = useState('');
    const [complementoLocal, setComplementoLocal] = useState('');
    const [cidadeLocal, setCidadeLocal] = useState('');
    const [codIbge, setCodIbge] = useState('');
    const [numeroOrgao, setNumeroOrgao] = useState('');
    const [areaTotal, setAreaTotal] = useState('');
    const [culturas, setCulturas] = useState([]);
    const [mascaraCoordenada, setMascaraCoordenada] = useState(`99°99'99"S`);
    const [cadastroAmbiental, setCadastroAmbiental] = useState('');
    const [enderecoLocal, setEnderecoLocal] = useState('');
    const estados = [
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ];
    const [estadoLocal, setEstadoLocal] = useState('');
    const [estadoCep, setEstadoCep] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [disabledEstado, setDisabledEstado] = useState(false);
    const [disabledCidade, setDisabledCidade] = useState(false);
    const [selected, setSelected] = useState([]);
    const [altitude, setAltitude] = useState('');

    const [erroLatitude, setErroLatitude] = useState(false);
    const [erroLongitude, setErroLongitude] = useState(false);
    const [erroAltitude, setErroAltitude] = useState(false);

    const [loading, setLoading] = useState(false);
    const [loadingCep, setLoadingCep] = useState(false);

    const [erroNome, setErroNome] = useState(false);
    const [erroNomeMsg, setErroNomeMsg] = useState('');

    const [erroCpfCnpj, setErroCpfCnpj] = useState(false);
    const [erroCpfCnpjMsg, setErroCpfCnpjMsg] = useState('');

    const [erroCep, setErroCep] = useState(false);
    const [erroCepMsg, setErroCepMsg] = useState('');

    const [erroEndereco, setErroEndereco] = useState(false);
    const [erroEnderecoMsg, setErroEnderecoMsg] = useState('');

    const [erroNumero, setErroNumero] = useState(false);
    const [erroNumeroMsg, setErroNumeroMsg] = useState('');

    const [erroBairro, setErroBairro] = useState(false);
    const [erroBairroMsg, setErroBairroMsg] = useState('');

    const [erroCidade, setErroCidade] = useState(false);
    const [erroCidadeMsg, setErroCidadeMsg] = useState('');

    const [erroEstado, setErroEstado] = useState(false);
    const [erroEstadoMsg, setErroEstadoMsg] = useState('');

    const [isCpfValido, setIsCpfValido] = useState(false);

    const [showMapa, setShowMapa] = useState(false);

    const [modoCoord, setModoCoord] = useState(0);
    const [showInputs, setShowInputs] = useState(false);
    const [longitudeGraus, setLongitudeGraus] = useState('');
    const [longitudeMinutos, setLongitudeMinutos] = useState('');
    const [longitudeSegundos, setLongitudeSegundos] = useState('');
    const [latitudeGraus, setLatitudeGraus] = useState('');
    const [latitudeMinutos, setLatitudeMinutos] = useState('');
    const [latitudeSegundos, setLatitudeSegundos] = useState('');
    const [idOportunidade, setIdOportunidade] = useState('');


    const formatCulturas = culturas => {
        culturas = culturas.map(cultura => {
            const format = {
                name: cultura.nome_comum,
                id: cultura.id
            };
            return format;
        });
        return culturas;
    }

    useEffect(() => {
        if (props.isModal != true) {
            window.scrollTo(0, 0);
        }

        api.get(`/culturas`).then(response => {
            let culturas = response.data.data;
            culturas = formatCulturas(culturas);
            setCulturas(culturas);
        });
    }, [])

    useEffect(() => {
        if (props.crm === true) {
            setNomeLocal(props.fazendaNomeCrm);
            setIdOportunidade(props.opId)
        }
    }, [props])

    const fetchCep = async () => {
        try {
            setLoadingCep(true);

            const cep = await buscaCep(cepLocal);

            const { logradouro, ibge, bairro, localidade, uf } = cep;

            setLogradouroLocal(logradouro);
            setBairroLocal(bairro);
            setCidadeLocal(localidade);
            setCodIbge(ibge);
            setEstadoLocal(uf);
            setEstadoCep(uf);

            setLoadingCep(false);

            setErroCep(false);
            setErroEndereco(false);
            setErroBairro(false);
            setErroCidade(false);
            setErroEstado(false);


        } catch (err) {
            setLoadingCep(false);
            setErroCep(true);

            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }


    const handlePost = async e => {


        setLoading(true);

        if (erroCpfCnpj === true) {
            store.addNotification({
                title: "Erro!",
                message: "Informe um CPF válido!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            setLoading(false);

        } else {
            if (estadoLocal === 'SC') {
                if (altitude === '' || !altitude) {
                    setErroAltitude(true);
                    setLoading(false);

                    return false;
                }
            }

            let lat = latitude;
            let lng = longitude;
            let existErro = false;

            if (lat === '' && lng === '') {
                lat = `${latitudeGraus}°${latitudeMinutos}'${latitudeSegundos}"S`;
                lng = `${longitudeGraus}°${longitudeMinutos}'${longitudeSegundos}"W`
        
            }

            if (estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP') {
                if (lat === '' || lat === `°'"S`) {
                    setErroLatitude(true);
                    existErro = true;
                }
                if (lng === '' || lng === `°'"W`) {
                    setErroLongitude(true);
                    existErro = true;
    
                }
    
                if (estadoLocal === 'SC' && altitude === '') {
                    setErroAltitude(true);
                    existErro = true;
    
                }
            }

            if (existErro == false) {
                const payloadB = {
                    nome: nomeLocal,
                    cpf_cnpj: isCpfValido ? cpfCnpjLocal : '',
                    cod_ibge: codIbge,
                    cultura: selected,
                    area_total: areaTotal,
                    inscricao_estadual: inscricaoEstadual,
                    cadastro_ambiental: cadastroAmbiental,
                    inscricao_orgao: numeroOrgao,
                    cep: cepLocal,
                    endereco: enderecoLocal,
                    numero: numeroLocal,
                    complemento: complementoLocal,
                    bairro: bairroLocal,
                    estado: estadoLocal,
                    cidade: cidadeLocal,
                    latitude: latitude,
                    longitude: longitude,
                    altitude,
                };
    
                const local = produtor;
    
                try {
    
                    const response = await api.post(`/produtores/${local}/fazendas`, payloadB);
    
                    const id_fazenda = response.data.data.id;
                    const id_produtor = response.data.data.agro_producer_id;
                    const payload_culturas = {
                        agro_farms_id: id_fazenda,
                        agro_producers_id: id_produtor,
                        agro_cultures_id: selected.length > 0 ? selected : [],
                        status: 1
                    }
    
                    if (props.crm === true) {
                        ConvertClient(id_fazenda, id_produtor)
                    }
    
                    await api.post(`/fazenda/culturas`, payload_culturas);
                    setLoading(false);
    
                    store.addNotification({
                        title: "Sucesso!",
                        message: "O local de aplicação foi salvo!",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
    
                    if (props.crm !== true) {
                        if (isModal === true) {
                            updateModal();
                        } else {
        
                            if (props.returnTo) {
                                props.loc.history.push(props.returnTo);
        
                            } else {
                                props.loc.history.push('/produtores');
                            }
                        }
                    }
    
    
    
                } catch (err) {
                    setLoading(false);
    
                    setErroNome(false);
                    setErroCpfCnpj(false);
                    setErroNumero(false);
                    setErroCep(false);
                    setErroEndereco(false);
                    setErroBairro(false);
                    setErroCidade(false);
                    setErroEstado(false);
                    setErroLatitude(false);
                    setErroLongitude(false);
    
                    const ERRO = err.response.data.errors;
                    try {
    
                        const errors = err.response.data.errors;
    
                        for (let field in errors) {
                            if (field === 'nome') {
                                setErroNome(true);
                                setErroNomeMsg('O campo não pode estar em branco');
                            } else if (field === 'cpf_cnpj') {
                                setErroCpfCnpj(true);
                                setErroCpfCnpjMsg('O campo não pode estar em branco');
                            } else if (field === 'cep') {
                                setErroCep(true);
                                setErroCepMsg('O campo não pode estar em branco');
                            } else if (field === 'endereco') {
                                setErroEndereco(true);
                                setErroEnderecoMsg('O campo não pode estar em branco');
                            } else if (field === 'numero') {
                                setErroNumero(true);
                                setErroNumeroMsg('O campo não pode estar em branco');
                            } else if (field === 'bairro') {
                                setErroBairro(true);
                                setErroBairroMsg('O campo não pode estar em branco');
                            } else if (field === 'cidade') {
                                setErroCidade(true);
                                setErroCidadeMsg('O campo não pode estar em branco');
                            } else if (field === 'estado') {
                                setErroEstado(true);
                                setErroEstadoMsg('O campo não pode estar em branco');
                            } else if (field === 'latitude') {
                                setErroLatitude(true);
                            } else if (field === 'longitude') {
                                setErroLongitude(true)
                            }
                        }
    
                        store.addNotification({
                            title: "Erro!",
                            message: "Verifique os campos em vermelho",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    } catch (err) {
                        store.addNotification({
                            title: "Erro!",
                            message: `${ERRO}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            } else {
                setLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: "Verifique os campos em vermelho",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        }

    }

    const ConvertClient = async (id_fazenda, id_produtor) => {
        try {

            const payload = {
                id_fazenda: id_fazenda,
                id_produtor: id_produtor
            }
            const response = await api.post(`/oportunidade/converter/${idOportunidade}`, payload)

            if (response) {
                props.closeModal()
                props.getOp()

            }

        } catch (error) {


        }


    }

    const disabledverify = () => {
        if (estadoLocal === 'PR' || estadoLocal === 'ES' || estadoLocal === 'GO' || estadoLocal === 'SC' || estadoLocal === 'SP') {
            if (estadoLocal && nomeLocal && cpfCnpjLocal && inscricaoEstadual && cepLocal && logradouroLocal && numeroLocal && bairroLocal && cidadeLocal && codIbge && latitude && longitude) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        } else {
            if (estadoLocal && nomeLocal && cpfCnpjLocal && inscricaoEstadual && cepLocal && logradouroLocal && numeroLocal && bairroLocal && cidadeLocal && codIbge) {
                setDisabled(false);
            } else {
                setDisabled(true);
            }
        }
    }

    const validateNome = nomeLocal => {
        setNomeLocal(nomeLocal);
        setErroNome(false);

    }
    const validateCpfCnpj = cpfCnpjLocal => {
        setCpfCnpjLocal(cpfCnpjLocal);
        setErroCpfCnpj(false);

    }
    const validateInscricao = inscricaoEstadual => {
        setInscricaoEstadual(inscricaoEstadual);
    }
    const validateCep = cepLocal => {
        setCepLocal(cepLocal);
        setErroCep(false);

    }
    const validateNumero = numeroLocal => {
        setNumeroLocal(numeroLocal);
        setErroNumero(false);

    }
    const validateComplemento = complementoLocal => {
        setComplementoLocal(complementoLocal);
    }
    const validateEndereco = logrLocal => {
        setLogradouroLocal(logrLocal);
        setErroEndereco(false);

    }
    const validateBairro = bairroLocal => {
        setBairroLocal(bairroLocal);
        setErroBairro(false);

    }
    const validateCidade = cidadeLocal => {
        setCidadeLocal(cidadeLocal);
        setErroCidade(false);

    }
    const validateCodIbge = codIbge => {
        setCodIbge(codIbge);
    }
    const validateNumOrgao = numeroOrgao => {
        setNumeroOrgao(numeroOrgao);
    }
    const validateArea = areaTotal => {
        setAreaTotal(areaTotal);
    }
    const validateCadastro = cadastroAmbiental => {
        setCadastroAmbiental(cadastroAmbiental);
    }

    const changeCoordinates = (lat, lng) => {
        setLatitude(`${lat}`);
        setLongitude(`${lng}`);
    }

    const validateAltitude = (valor) => {
        setAltitude(valor);
        setErroAltitude(false);
    }

    const onSelect = (selectedList, selectedItem) => {
        setSelected([])
        const b = [];
        const a = selectedList.map((list) => {
            b.push(list.id)
        })
        setSelected(b)
    }

    const onRemove = (selectedList, removedItem) => {
        setSelected([])
        const b = [];
        const a = selectedList.map((list) => {
            b.push(list.id)
        })
        setSelected(b)
    }

    const validaCpf = (value) => {

        if (value.length <= 14) {
            const CpfValido = validadorDeCpf(value);

            setErroCpfCnpj(!CpfValido);
            setErroCpfCnpjMsg('Informe um CPF válido')
            setIsCpfValido(CpfValido);

            if (!CpfValido) {
                store.addNotification({
                    title: "Erro!",
                    message: "Informe um CPF válido.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
            setIsCpfValido(true);
            setErroCpfCnpj(false);
        }
    }

    const validateEstado = uf => {
        if (estadoLocal != uf) {
            setEstadoLocal(uf);
        }
    }

    const changeModoCoord = (e) => {
        setModoCoord(e)
        if (e.value == 2) {
            setShowMapa(false);
            setShowInputs(true);
        } else {
            setShowMapa(true);
            setShowInputs(false);
        }
    }
    useEffect(() => {
        setEnderecoLocal(logradouroLocal);
    }, [logradouroLocal])


    useEffect(() => {
        disabledverify();
    }, [estadoLocal, nomeLocal, cpfCnpjLocal, inscricaoEstadual, cepLocal, logradouroLocal, numeroLocal, complementoLocal, bairroLocal, cidadeLocal, codIbge, numeroOrgao, areaTotal, cadastroAmbiental, latitude, longitude, disabledverify]);

    useEffect(() => {
        if (estadoLocal && cidadeLocal) {
            setDisabledCidade(true);
            setDisabledEstado(true);
        } else {
            setDisabledCidade(false);
            if (estadoLocal) {
                setDisabledEstado(true);
            } else {
                setDisabledEstado(false);
            }
        }
    }, [estadoLocal, cidadeLocal])


    useEffect(() => {
        if (estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP') {
            // setShowMapa(true);
        } else {
            setShowMapa(false);
        }
    }, [estadoLocal])






    const validateLatitude = async (tipo, valor) => {
        setErroLatitude(false);
        if (tipo == 1) {

            if (latitudeGraus.length > valor.length) {
                //apagando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLatitudeGraus(`${separa[0]}${separa[1]}`);
                    } else {
                        setLatitudeGraus(valor);
                    }
                } else {
                    setLatitudeGraus(valor);
                }
            } else {
                //digitando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLatitudeGraus(valor);
                    } else {

                        if (separa[2] == ',') {
                            setLatitudeGraus(`${separa[0]}${separa[1]}.`);
                        } else {
                            setLatitudeGraus(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                } else {
                    setLatitudeGraus(valor);
                }
            }

        } else if (tipo == 2) {

            if (latitudeMinutos.length > valor.length) {
                //apagando o valor

                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLatitudeMinutos(`${separa[0]}${separa[1]}`);
                    } else {
                        setLatitudeMinutos(valor);
                    }
                } else {
                    setLatitudeMinutos(valor);
                }
            } else {
                //digitando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLatitudeMinutos(valor);
                    } else {

                        if (separa[2] == ',') {
                            setLatitudeMinutos(`${separa[0]}${separa[1]}.`);
                        } else {
                            setLatitudeMinutos(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                } else {
                    setLatitudeMinutos(valor);
                }
            }

        } else if (tipo == 3) {

            if (latitudeSegundos.length > valor.length) {
                //apagando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLatitudeSegundos(`${separa[0]}${separa[1]}`);
                    } else {
                        setLatitudeSegundos(valor);
                    }
                } else {
                    setLatitudeSegundos(valor);
                }
            } else {
                //digitando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLatitudeSegundos(valor);
                    } else {

                        if (separa[2] == ',') {
                            setLatitudeSegundos(`${separa[0]}${separa[1]}.`);
                        } else {
                            setLatitudeSegundos(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                } else {
                    setLatitudeSegundos(valor);
                }
            }
        }

        if (latitudeGraus && latitudeMinutos && latitudeSegundos) {
            if (tipo == 3) {
                setLatitude(`${latitudeGraus}°${latitudeMinutos}'${valor}"S`);
            } else {
                setLatitude(`${latitudeGraus}°${latitudeMinutos}'${latitudeSegundos}"S`);
            }
        }
    }

    const validateLongitude = async (tipo, valor) => {
        setErroLongitude(false);
        if (tipo == 1) {

            if (longitudeGraus.length > valor.length) {
                //apagando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLongitudeGraus(`${separa[0]}${separa[1]}`);
                    } else {
                        setLongitudeGraus(valor);
                    }
                } else {
                    setLongitudeGraus(valor);
                }
            } else {
                //digitando o valor

                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLongitudeGraus(valor);
                    } else {

                        if (separa[2] == ',') {
                            setLongitudeGraus(`${separa[0]}${separa[1]}.`);
                        } else {
                            setLongitudeGraus(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                } else {
                    setLongitudeGraus(valor);
                }
            }

        } else if (tipo == 2) {

            if (longitudeMinutos.length > valor.length) {
                //apagando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLongitudeMinutos(`${separa[0]}${separa[1]}`);
                    } else {
                        setLongitudeMinutos(valor);
                    }
                } else {
                    setLongitudeMinutos(valor);
                }
            } else {
                //digitando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLongitudeMinutos(valor);
                    } else {

                        if (separa[2] == ',') {
                            setLongitudeMinutos(`${separa[0]}${separa[1]}.`);
                        } else {
                            setLongitudeMinutos(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                } else {
                    setLongitudeMinutos(valor);
                }
            }

        } else if (tipo == 3) {

            if (longitudeSegundos.length > valor.length) {
                //apagando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLongitudeSegundos(`${separa[0]}${separa[1]}`);
                    } else {
                        setLongitudeSegundos(valor);
                    }
                } else {
                    setLongitudeSegundos(valor);
                }
            } else {
                //digitando o valor
                if (valor.length == 3) {
                    let separa = valor.split("");
                    if (separa[2] == ".") {
                        setLongitudeSegundos(valor);
                    } else {

                        if (separa[2] == ',') {
                            setLongitudeSegundos(`${separa[0]}${separa[1]}.`);
                        } else {
                            setLongitudeSegundos(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                } else {
                    setLongitudeSegundos(valor);
                }
            }
        }

        if (longitudeGraus && longitudeMinutos && longitudeSegundos) {
            if (tipo == 3) {
                setLongitude(`${longitudeGraus}°${longitudeMinutos}'${valor}"W`);
            } else {
                setLongitude(`${longitudeGraus}°${longitudeMinutos}'${longitudeSegundos}"W`);
            }
        }
    }

    useEffect(() => {
        if (modoCoord.value === 1) {
            setShowMapa(true);
        } else if (modoCoord.value === 2) {
            setShowInputs(true);
        }
    }, [modoCoord])

    return (<>
        <Form
            onSubmit={handlePost}
            step={2}
            isModal={props.isModal}
            isFocus={props.isFocus}
        >
            <Input
                label="Nome da fazenda"
                name="nome"
                className="input"
                id="farm_nome"
                onChange={e => validateNome(e)}
                maxlength={50}
                erro={erroNome}
                messageErro={erroNomeMsg}
                value={nomeLocal}
            />

            <Input
                label="CPF/CNPJ"
                name="cpfcnpj"
                className="input"
                id="farm_cpf_cnpj"
                onChange={e => validateCpfCnpj(e)}
                value={cpfCnpjLocal}
                erro={erroCpfCnpj}
                messageErro={erroCpfCnpjMsg}
                maxlength={18}
                onBlur={(e) => validaCpf(e.target.value)}
                inputmode="numeric"
            />

            <Input
                label="Inscrição Estadual (Opcional)"
                name="inscricaoEstadual"
                className="input"
                id="farm_inscricao_estadual"
                onChange={e => validateInscricao(e)}
                value={inscricaoEstadual}
            />

            <Input
                label="CEP"
                name="cep"
                className="input"
                id="farm_cep"
                onChange={e => validateCep(e)}
                onBlur={fetchCep}
                maxLength={10}
                value={cepLocal}
                loading={loadingCep}
                disabled={loadingCep}
                erro={erroCep}
                messageErro={erroCepMsg}
                inputmode="numeric"
            />

            <Input
                label="Logradouro"
                value={logradouroLocal}
                name="logradouroLocal"
                className="input"
                id="farm_endereco"
                onChange={e => validateEndereco(e)}
                loading={loadingCep}
                disabled={loadingCep}
                erro={erroEndereco}
                messageErro={erroEnderecoMsg}
            />

            <Input
                label="Bairro"
                value={bairroLocal}
                name="bairroLocal"
                className="input"
                id="farm_bairro"
                onChange={e => validateBairro(e)}
                loading={loadingCep}
                disabled={loadingCep}
                erro={erroBairro}
                messageErro={erroBairroMsg}
            />

            <Input
                label="Número"
                name="numeroLocal"
                className="input"
                id="farm_numero"
                onChange={e => validateNumero(e)}
                maxLength={5}
                erro={erroNumero}
                messageErro={erroNumeroMsg}
                value={numeroLocal}
            />

            <Input
                label="Complemento(Opcional)"
                name="complementoLocal"
                className="input"
                id="farm_complementoLocal"
                onChange={e => validateComplemento(e)}
                value={complementoLocal}
            />

            <Input
                label="Código IBGE"
                value={codIbge}
                name="codIbge"
                className="input"
                id="farm_cod_ibge"
                onChange={e => validateCodIbge(e)}
                loading={loadingCep}
                disabled={loadingCep}
                inputmode="numeric"
            />

            <Input
                label="Cidade"
                value={cidadeLocal}
                name="cidadeLocal"
                className="input"
                id="farm_cidade"
                onChange={e => validateCidade(e)}
                loading={loadingCep}
                disabled={loadingCep}
                erro={erroCidade}
                messageErro={erroCidadeMsg}

            />

            <Select
                label="Estado"
                name="estado"
                className="select"
                id="farm_estado_id"
                onChange={(e) => e.value ? validateEstado(e.value) : validateEstado(e)}
                options={estados}
                value={estadoLocal}
                estado={true}
                loading={loadingCep}
                disabled={disabledEstado || loadingCep}
                erro={erroEstado}
                messageErro={erroEstadoMsg}
            />

            <Input
                label="Número de inscrição do produtor ao órgão estadual (opcional)"
                name="numeroOrgao"
                className="input"
                id="farm_inscricao_orgao"
                onChange={e => validateNumOrgao(e)}
                value={numeroOrgao}
            />

            <Input
                label="Área Total (ha) (opcional)"
                name="areaTotal"
                className="input"
                id="farm_areaTotal"
                onChange={e => validateArea(e)}
                value={areaTotal}
                inputmode="numeric"
            />
            {/* -----------------------------------------------------------------------------------> */}

            <div style={{ width: '100%', marginTop: '-13px', marginBottom: '40px', display: 'flex', flexDirection: 'column'}}>
                <label id="labelcultura">Culturas (opcional)</label>
                <Multiselect
                    label="Culturas (opcional)"
                    options={culturas}
                    placeholder="" // Options to display in the dropdown
                    closeIcon="close"
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name" // Property name to display in the dropdown options
                />
            </div>

            {/* -----------------------------------------------------------------------------------> */}

            <Input
                label="Cadastro Ambiental Rural (opcional)"
                name="cadastroAmbientalRural"
                className="input"
                id="cadastro_ambiental"
                onChange={e => validateCadastro(e)}
                value={cadastroAmbiental}
                marginBottom='10px'
            />

            <Select
                label={estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP' ? "Selecionar coordenadas geográficas (Obrigatório)" : "Selecionar coordenadas geográficas (Opcional)"}
                name="select_coordinates"
                className="select"
                id="select_coordinates"
                onChange={(e) => changeModoCoord(e)}
                options={[
                    { value: 1, label: 'Selecionar no mapa' },
                    { value: 2, label: 'Digitar coordenadas' },

                ]}
                value={modoCoord}
                estado={false}
                loading={false}
                disabled={false}
                erro={erroLatitude == true || erroLongitude == true || erroAltitude == true ? true : false}
                messageErro={false}
            />

            {showMapa ? (
                <MapaArea
                    label="Selecione o local de aplicação"
                    onChange={(lat, lgt) => changeCoordinates(lat, lgt)}
                />
            ) : showInputs ? (
                <>
                    <label className='labelcoord'>{estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP' ? "Latitude (Obrigatório)" : "Latitude (opcional)"}</label>
                    <div className="coord">
                        <div className="partCoord">
                            <Input
                                label='Graus'
                                name="latatitude"
                                className="input"
                                id="lgraus"
                                value={latitudeGraus}
                                maxLength={2}
                                onChange={e => validateLatitude(1, e)}
                                erro={erroLatitude}
                                inputmode="numeric"

                            />
                            <span>°</span>
                        </div>

                        <div className="partCoord">
                            <Input
                                label='Minutos'
                                name="latatitude"
                                className="input"
                                id="lminutos"
                                value={latitudeMinutos}
                                maxLength={2}
                                onChange={e => validateLatitude(2, e)}
                                style={{ marginTop: '16px' }}
                                erro={erroLatitude}
                                inputmode="numeric"

                            />
                            <span>'</span>
                        </div>

                        <div className="partCoord">
                            <Input
                                label='Segundos'
                                name="latatitude"
                                className="input"
                                id="lsegundos"
                                value={latitudeSegundos}
                                maxLength={4}
                                onChange={e => validateLatitude(3, e)}
                                style={{ marginTop: '16px' }}
                                erro={erroLatitude}
                                inputmode="numeric"

                            />
                            <span>"S</span>
                        </div>
                    </div>

                    <label className='labelcoord'>{estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP' ? "Longitude (Obrigatório)" : "Longitude (opcional)"}</label>
                    <div className="coord">
                        <div className="partCoord">
                            <Input
                                label='Graus'
                                name="longitude"
                                className="input"
                                id="longrau"
                                value={longitudeGraus}
                                maxLength={2}
                                onChange={e => validateLongitude(1, e)}
                                erro={erroLongitude}
                                inputmode="numeric"

                            />
                            <span>°</span>
                        </div>

                        <div className="partCoord">
                            <Input
                                label='Minutos'
                                name="longitude"
                                className="input"
                                id="lonminutos"
                                value={longitudeMinutos}
                                maxLength={2}
                                onChange={e => validateLongitude(2, e)}
                                style={{ marginTop: '16px' }}
                                erro={erroLongitude}
                                inputmode="numeric"

                            />
                            <span>'</span>
                        </div>

                        <div className="partCoord">
                            <Input
                                label='Segundos'
                                name="longitude"
                                className="input"
                                id="lonsegundos"
                                value={longitudeSegundos}
                                maxLength={4}
                                onChange={e => validateLongitude(3, e)}
                                style={{ marginTop: '16px' }}
                                erro={erroLongitude}
                                inputmode="numeric"

                            />
                            <span>"W</span>
                        </div>
                    </div>
                </>
            ) : (
                <>
                </>
            )}


            {estadoLocal === 'SC' ? (
                <Input
                    label="Altitude (Obrigatório)"
                    value={altitude}
                    name="altitude"
                    className="input"
                    id="altitude"
                    onChange={e => validateAltitude(e)}
                    unidade="Metros"
                    erro={erroAltitude}
                    inputmode="numeric"
                />
            ) : (null)}

            <div
                className="button-finish"
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <Button
                    width='100%'
                    height='50px'
                    value='CADASTRAR'
                    outline={false}
                    disabled={false}
                    onClick={() => handlePost()}
                    loading={loading}
                />

            </div>
        </Form>
    </>)
}
export default AddLocalAplicacao;