import styled from "styled-components";

export const UserContainer = styled.section`
    padding: 50px 0;
    /* background-color: ${props => props.isModal ? '#FFF' : '#f3f3f8'}; */
    height: 100%;
    max-width: 400px;
    margin: auto;

    .index-usuarios{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .loadmore{
            margin: 30px 0;
            padding: 15px 20px;
        }
    }

    .container-center{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .radio{
        width: 400px;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    
    input[type='file'] {
        display: none;
    }

    .input-wrapper div {
        width: 100%;
        height: 108px;
        background-color: #fff;
        border-radius: 1px;
        border: 1px dashed #8a92a2;
        color: #8a92a2;
        /* margin: 10px; */
        padding: 12px 20px;
        
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            width: 100%;
            text-align: center;
            font-size: 15px;
        }
    }
    
    .input-wrapper div:hover {
        background-color: #DCDCDC;
    }
`;



export const DropContainer = styled.div.attrs({
    classname: "dropzone"
})`
    border: 1px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;

    transition : height 0.2s ease;
`;

export const UploadMessage = styled.p``;
