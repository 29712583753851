import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../button/index';
import Input from '../input/index';
import Modal from '.';
import { ModalFooter, ModalTitle } from './styles';

// import 'bootstrap/dist/css/bootstrap.min.css';

class ModalNew extends React.Component {

    state = {
        disabled: true,
        motivo: '',
    }

    constructor(props) {
        super(props);
    }

    updateResponseYes = () => {

        if (this.props.estado === 'MT' || this.props.estado === 'ES') {
            let resposta = 1;
            this.props.updateMt(resposta, this.state.motivo)
        } else {
            let resposta = 1;
            if (this.props.id) {
                this.props.update(resposta, this.props.id);
            }else{
                this.props.update(resposta);
            }
        }
    }

    validateConfirmar = (value) => {
        
        if(value == "CANCELAR"){
            this.setState({disabled: false});
        }else{
            this.setState({disabled: true});
        }
    }

    validaMotivo  = (value) => {
        if(value && value.trim() !== '') {
            this.setState({motivo: value});
            this.setState({disabled: false});
        } else {
            this.setState({motivo: value});
            this.setState({disabled: true});
        }
    }


    render() {
        return (

            // <Modal
            //     {...this.props}
            //     size="lg"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8"}}>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //             {this.props.title}
            //         </Modal.Title>
            //     </Modal.Header >

            //     <Modal.Body style={{backgroundColor: " #f8f8f8"}}>
            //         {this.props.estado === 'MT' || this.props.estado === 'ES' ? (
            //             <>
            //                 <p>Informe o motivo do cancelamento</p>
            //                 <Input
            //                     label=" "
            //                     placeholder="Motivo"
            //                     onChange={e => this.validaMotivo(e.target.value)}
            //                 />
            //             </>
            //         ) : (
            //             <>
            //                 {this.props.text}
            //                 <Input
            //                     label=" "
            //                     placeholder="CANCELAR"
            //                     onChange={e => this.validateConfirmar(e.target.value)}
            //                 />
            //             </>
            //         )}
            //     </Modal.Body>

            //     <Modal.Footer style={{backgroundColor: " #f8f8f8"}}>
            //         <Button style={{ outline:0, padding: "10px", width:"100px"}}
            //             outline
            //             color="#00b43f"
            //             disabled={this.state.disabled}
            //             onClick={() => this.updateResponseYes()}
            //         >Continuar</Button>
            //     </Modal.Footer>

            // </Modal>


            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='50%'
            >
                <ModalTitle> {this.props.title} </ModalTitle>

                {this.props.estado === 'MT' || this.props.estado === 'ES' ? (
                    <>
                        <p>Informe o motivo do cancelamento</p>
                        <Input
                            label=" "
                            placeholder="Motivo"
                            onChange={e => this.validaMotivo(e.target.value)}
                        />
                    </>
                ) : (
                    <>
                        {this.props.text}
                        <Input
                            label=" "
                            placeholder="CANCELAR"
                            onChange={e => this.validateConfirmar(e.target.value)}
                        />
                    </>
                )}

                <ModalFooter>
                    <Button 
                        style={{ outline:0, padding: "10px", width:"100px"}}
                        outline
                        color="#00b43f"
                        disabled={this.state.disabled}
                        onClick={() => this.updateResponseYes()}
                    >
                        Continuar
                    </Button>

                </ModalFooter>
            </Modal>

        );
    }
}
export default ModalNew;