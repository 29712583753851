import axios from "axios";

export const apiprevmet3 = axios.create({
  baseURL: 'https://apiprevmet3.inmet.gov.br',
});

export const apisat = axios.create({
  baseURL: 'https://apisat.inmet.gov.br',
});

export const apiprec = axios.create({
  baseURL: 'https://apiprec.inmet.gov.br',
});






