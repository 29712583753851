import React, { useState, useEffect, useCallback } from 'react';
import api from "../../services/api";
import { Kanban, ContainerGeral, ContainerModal, DivSearch, NewButton, SpaceButton, ContainerModalEdit } from "../crm/styles"
import GenericUpper from "../../components/generic-upper";
import InputSearch from "../../components/input-search";
import ButtonOld from "../../components/button";
import Button from '../../components/button/button_v2';
import IconCrm from '../../assets/icons/iconCrm';
import IconList from '../../assets/icons/iconList';
import { IconFilter } from '../../assets/icons/iconFilter';
import CardRight from "../../components/cardRight";
import ModalGeneric from '../../components/modal/modalGeneric';
import AddCrm from "../../components/crm/add"
import EditOp from "../../components/crm/edit"
import { Loading } from '../../components/relatorios/style';
import { store } from "react-notifications-component";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import { formatDateTime } from '../../services/formatDate';
import Board, { moveCard } from "@lourenci/react-kanban";
import ModalFilterCrm from "../../components/modal/modalFilter2";
import { compareData } from '../../services/globalFunctions';
import CardGeral from "../../components/card-geral/index";


const Crm = ({ ...props }) => {
    const [animationCard, setAnimationCard] = useState(false);
    const [showAddClient, setShowAddClient] = useState(false);
    const [showEditClient, setShowEditClient] = useState(false);
    const [oportunidadeId, setOportunidadeId] = useState(0);
    const [loading, setLoading] = useState(true)
    const [myEvents, setMyEvents] = useState([])
    const [semContato, setSemContato] = useState([])
    const [contatoFeito, setContatoFeito] = useState([])
    const [propostaEnviada, setPropostaEnviada] = useState([])
    const [negocioFechado, setNegocioFechado] = useState([])
    const [showKanban, setShowKanban] = useState(false)
    const [showList, setShowList] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [filtroState, setFiltro] = useState('');
    const [mySearch, setMySearch] = useState(false);
    const [search, setSearch] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [isCard, setIsCard] = useState(false);
    const [sizeScreen, setSizeScren] = useState(window.screen.width);


    const addClient = () => {
        setShowAddClient(true);
        setAnimationCard(true);
    }

    const kanban = () => {
        setShowKanban(true);
        setShowList(false);
        setMySearch(false);
    }

    const lista = () => {
        setShowKanban(false);
        setShowList(true);
    }

    const editClient = (id) => {
        setShowEditClient(true)
        setOportunidadeId(id)
    }

    const modalFilter = () => {
        setShowFilter(true);
    }

    const closeModal = () => {
        if (showFilter == true) {
            setShowFilter(false);
        }
    }

    const postSearch = async (filtro) => {
        try {
            setFiltro(filtro);
            setLoading(true)

            const payload = {
                data_inicial: filtro.dataInicial ? filtro.dataInicial : '',
                botao: filtro.botao === 0 ? '' : filtro.botao,
                nome: filtro.nome ? filtro.nome : '',
                search: ''
            }
            const response = await api.post('/oportunidade/pesquisa', payload)

            if (response) {
                const results = response.data.data

                console.log(results)
                setShowList(true)
                setShowKanban(false)
                setMySearch(results)
                setLoading(false)
            }

        } catch (error) {
            setLoading(false)
            setShowList(true)


        }


    }

    const postSearchText = async (text, search) => {
        try {

            setSearch(text)
            if (search == 1) {
                setLoading(true)
                if (text === '') {
                    setLoading(true)
                    setMyEvents([])
                    getOportunidades()

                } else {

                    const payload = {
                        data_inicial: '',
                        botao: '',
                        nome: '',
                        search: text
                    }
                    const response = await api.post('/oportunidade/pesquisa', payload)

                    if (response) {
                        const results = response.data.data

                        setMyEvents(results)
                        setShowList(true)
                        setLoading(false)

                    }
                }
            }

        } catch (error) {
            setLoading(false)
            setShowList(true)

        }


    }

    const getOportunidades = async () => {
        try {
            setLoading(true)
            const response = await api.get('/oportunidade')

            if (response) {
                const oportunidades = response.data.data

                setMyEvents(formatOportunidade(oportunidades, 0))
                setSemContato(formatOportunidade(oportunidades, 1))
                setContatoFeito(formatOportunidade(oportunidades, 2))
                setPropostaEnviada(formatOportunidade(oportunidades, 4))
                setNegocioFechado(formatOportunidade(oportunidades, 5))
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            setMyEvents([])

        }

    }

    const formatOportunidade = (values, etapa) => {
        let op = [];

        values.map((a) => {
            if (a.id_etapa === etapa || etapa === 0) {
                op = [...op, {
                    oportunidade_id: a.id,
                    agro_subsidiary_id: a.agro_subsidiary_id,
                    numero: a.numero,
                    id: a.ordem,
                    tipo_oportunidade: a.tipo_oportunidade,
                    title: a.tipo_oportunidade,
                    status: a.status,
                    id_status: a.id_status,
                    etapa: a.etapa,
                    id_etapa: a.id_etapa,
                    descricao: a.descricao,
                    description: a.descricao,
                    produtor_id: a.produtor_id,
                    produtor_nome: a.produtor_nome,
                    produtor_email: a.produtor_email,
                    produtor_cpf_cnpj: a.produtor_cpf_cnpj,
                    fazenda_id: a.fazenda_id,
                    fazenda_nome: a.fazenda_nome,
                    fazenda_cpf_cnpj: a.fazenda_cpf_cnpj,
                    fazenda_telefone: a.fazenda_telefone,
                    fazenda_celular: a.fazenda_celular,
                    agro_producer_id: a.agro_producer_id,
                    produtor_cliente_nome: a.produtor_cliente_nome,
                    produtor_cliente_email: a.produtor_cliente_email,
                    produtor_cliente_telefone: a.produtor_cliente_telefone,
                    produtor_cliente_celular: a.produtor_cliente_celular,
                    produtor_cliente_cpf_cnpj: a.produtor_cliente_cpf_cnpj,
                    agro_farm_id: a.agro_farm_id,
                    fazenda_cliente_nome: a.fazenda_cliente_nome,
                    fazenda_cliente_cpf_cnpj: a.fazenda_cliente_cpf_cnpj,
                    data_acao: a.data_acao,
                    tipo_acao: a.tipo_acao,
                    id_tipo_acao: a.id_tipo_acao,
                    atrasado: a.atrasado,
                    get: 1

                }];
            }
        });

        return op;
    }

    const deleteOportunidade = async () => {
        try {
            const response = await api.delete(`/oportunidade/${oportunidadeId}`);

            if (response) {
                setShowEditClient(false);
                getOportunidades();
                setLoading(false);
            }

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });

        }
    }

    const board = {

        columns: [

            {
                id: 1,
                title: "Sem contato",
                cards: semContato,

            },
            {
                id: 2,
                title: "Contato feito",
                cards: contatoFeito
            },
            {
                id: 3,
                title: "Proposta enviada",
                cards: propostaEnviada
            },
            {
                id: 4,
                title: "Negócio fechado",
                cards: negocioFechado
            }
        ]
    };

    const onCradDrag = async (board, _card, source, destination) => {

        try {

            const payload = {
                position: destination.toColumnId
            }

            const response = await api.put(`/oportunidade/status/${_card.oportunidade_id}`, payload)

            if (response) {
                getOportunidades()
            }
        } catch (error) {


        }

    }



    useEffect(() => {
        getOportunidades()
        if (window.innerWidth < 1137) {
            setShowKanban(false);
            setShowList(true);
            setIsMobile(true);
            if (window.innerWidth < 620) {
                setIsCard(true)
            } else {
                setIsCard(false)
            }
        } else {
            setShowKanban(true);
            setShowList(false);
            setIsMobile(false);
        }

        function screen() {
            if (window.innerWidth < 1137) {
                setShowKanban(false);
                setShowList(true);
                setIsMobile(true);
                if (window.innerWidth < 620) {
                    setIsCard(true)
                } else {
                    setIsCard(false)
                }
            } else {
                setShowKanban(true);
                setShowList(false);
                setIsMobile(false);
            }
        }

        window.addEventListener('resize', screen)
    }, [])

    return (

        <>
            <GenericUpper
                title="Oportunidades de negócios"
                subtitle="Responsável"
                search={true}
                searchFunction={(text) => postSearchText(text)}
                placeholder="Produtor, Fazenda ou Tipo de ação"
                tooltip={"Adicionar"}
                addLinkModal={() => addClient()}
            ></GenericUpper>

            <ContainerGeral>
                <DivSearch>
                    <div className="filterKanban">
                        <NewButton onClick={modalFilter}>
                            <IconFilter />
                            <p>Filtros</p>
                        </NewButton>
                        {isMobile == false && (
                            <>
                                {showList ? (
                                    <NewButton onClick={kanban}>
                                        <IconCrm />
                                        <p>Kanban</p>
                                    </NewButton>
                                ) : (
                                    <NewButton onClick={lista}>
                                        <IconList />
                                        <p>Lista</p>
                                    </NewButton>
                                )}
                            </>
                        )}
                        {
                            sizeScreen <= 768 && (
                                <Button
                                    width='60%'
                                    height='32px'
                                    value='NOVA OPORTUNIDADE'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => addClient()}
                                    loading={false}

                                    style={{
                                        fontSize: '11px',
                                        minWidth: '160px'
                                    }}
                                />
                            )
                        }
                    </div>

                </DivSearch>
                <ModalGeneric
                    show={showFilter}
                    onHide={() => closeModal()}
                    size='md'
                >
                    <h2 style={{
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: 'bold',
                        fontStretch: 'normal',
                        width: '100%',
                        textAlign: 'left',
                        alignSelf: 'center'
                    }}> Filtros </h2>

                    <ModalFilterCrm
                        search={(filtro) => postSearch(filtro)}
                        closeModal={closeModal}
                    />
                </ModalGeneric>

                {loading ? (
                    <Loading></Loading>
                ) : (
                    <>
                        {mySearch ? (
                            <>
                                <div className="corpo" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Nome fazenda/Produtor</th>
                                                <th>Telefone/Celular</th>
                                                <th>Data Ação Oportunidade</th>
                                                <th>Tipo de ação</th>
                                            </tr>
                                        </thead>
                                        <TBody>
                                            {mySearch.map(oportunidade => (
                                                < tr onClick={() => editClient(oportunidade.opportunity_id)}>
                                                    <td>{oportunidade.fazenda_cliente_nome ? oportunidade.fazenda_cliente_nome + '-' + oportunidade.produtor_cliente_nome : oportunidade.fazenda_nome + '-' + oportunidade.produtor_nome}</td>
                                                    <td>{oportunidade.fazenda_cliente_nome ? oportunidade.produtor_cliente_telefone + '/' + oportunidade.produtor_cliente_celular : oportunidade.fazenda_telefone + '/' + oportunidade.fazenda_celular}</td>
                                                    <td>{oportunidade.data_acao === "-" || oportunidade.data_acao === null ? 'SEM DATA' : formatDateTime(oportunidade.data_acao)}</td>
                                                    <td>{oportunidade.tipo_acao === "-" || oportunidade.tipo_acao === null ? 'SEM AÇÃO' : oportunidade.tipo_acao}</td>
                                                </tr>
                                            ))}
                                        </TBody>
                                    </Table>
                                </div>
                                <div className="voltar" id='back' style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
                                    <Button
                                        width="235px"
                                        height='50px'
                                        value='VOLTAR'
                                        outline={true}
                                        disabled={false}
                                        onClick={() => {
                                            props.history.push('/crm')
                                            setMySearch(false)
                                            getOportunidades()
                                        }}
                                        loading={false}
                                    />
                                </div>
                            </>
                        ) : showList ? (
                            <>
                                <div className="corpo" style={{ marginLeft: "15px", marginRight: "15px" }}>
                                    {isCard ? (
                                        <CardGeral
                                            titulos={["CRM", "NOME FAZENDA/PRODUTOR", "TELEFONE/CELULAR", "DATA AÇÃO OPORTUNIDADE", "TIPO DE AÇÃO"]}
                                            valores={myEvents}
                                            editClient={(e) => editClient(e)}
                                        />

                                    ) : (
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Nome fazenda/Produtor</th>
                                                    <th>Telefone/Celular</th>
                                                    <th>Data Ação Oportunidade</th>
                                                    <th>Tipo de ação</th>
                                                </tr>
                                            </thead>
                                            <TBody>
                                                {myEvents.map(oportunidade => (
                                                    < tr onClick={() => oportunidade.oportunidade_id ? editClient(oportunidade.oportunidade_id) : editClient(oportunidade.opportunity_id)}>
                                                        <td>{oportunidade.fazenda_cliente_nome ? oportunidade.fazenda_cliente_nome + '-' + oportunidade.produtor_cliente_nome : oportunidade.fazenda_nome + '-' + oportunidade.produtor_nome}</td>
                                                        <td>{oportunidade.fazenda_cliente_nome ? oportunidade.produtor_cliente_telefone + '/' + oportunidade.produtor_cliente_celular : oportunidade.fazenda_telefone + '/' + oportunidade.fazenda_celular}</td>
                                                        <td>{oportunidade.data_acao === "-" || oportunidade.data_acao === null ? 'SEM DATA' : formatDateTime(oportunidade.data_acao)}</td>
                                                        <td>{oportunidade.tipo_acao === "-" || oportunidade.tipo_acao === null ? 'SEM AÇÃO' : oportunidade.tipo_acao}</td>
                                                    </tr>
                                                ))}
                                            </TBody>
                                        </Table>

                                    )}
                                </div>
                                {myEvents.length > 0 && (
                                    <>
                                        {myEvents[0].get !== 1 && (
                                            <div className="voltar" id='back' style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "10px", marginBottom: "10px" }}>
                                                <Button
                                                    width="235px"
                                                    height='50px'
                                                    value='VOLTAR'
                                                    outline={true}
                                                    disabled={false}
                                                    onClick={() => {
                                                        props.history.push('/crm')
                                                        setMySearch(false)
                                                        getOportunidades()
                                                    }}
                                                    loading={false}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <>
                                <Kanban>
                                    <Board
                                        initialBoard={board}

                                        onColumnDragEnd={false}
                                        onCardDragEnd={(board, _card, source, destination) => onCradDrag(board, _card, source, destination)}
                                        disableColumnDrag
                                        renderCard={({ fazenda_nome, produtor_nome, fazenda_telefone, fazenda_celular, data_acao, tipo_acao, id_tipo_acao, atrasado, id, oportunidade_id, produtor_cliente_nome, produtor_cliente_telefone, produtor_cliente_celular, fazenda_cliente_nome, agro_producer_id }, { removeCard, dragging }) => (
                                            <div className="react-kanban-card" dragging={dragging} onClick={() => editClient(oportunidade_id)}>
                                                {data_acao ? (
                                                    <>
                                                        {atrasado === 1 ? (
                                                            <div className="bolinha-vermelha"></div>
                                                        ) : atrasado === 2 ? (
                                                            <div className="bolinha-azul"></div>
                                                        ) : atrasado === 0 ? (
                                                            <div className="bolinha-verde"></div>
                                                        ) : null}
                                                    </>
                                                ) : (
                                                    null
                                                )}
                                                <div className="titulo"><b>Fazenda: </b> {agro_producer_id ? produtor_cliente_nome : fazenda_nome}</div>
                                                <div className="corpo"><b>Produtor: </b> {agro_producer_id ? fazenda_cliente_nome : produtor_nome}</div>
                                                <div className="corpo"><b>Tel: </b> {agro_producer_id ? produtor_cliente_telefone + '/' + produtor_cliente_celular : fazenda_telefone + '/' + fazenda_celular}</div>
                                                <div className="corpo"><b>Data Oport.: </b> {data_acao ? formatDateTime(data_acao) : 'Não possuí ação'}</div>
                                                <div className={`tipo-${id_tipo_acao || 0}`}><p>{tipo_acao ? tipo_acao : 'Não possuí ação'}</p></div>
                                            </div>
                                        )}
                                    >
                                        {board}
                                    </Board>

                                </Kanban>
                            </>
                        )}
                    </>
                )}

                <CardRight
                    open={showAddClient}
                    updateIsOpen={(e) => setShowAddClient(e)}
                    animation={animationCard}
                    title={'Nova Oportunidade'}
                    isEdit={false}
                    updateDelete={false}
                >
                    <AddCrm
                        close={() => setShowAddClient(false)}
                        getOp={() => getOportunidades()}
                    />
                </CardRight>

                <CardRight
                    open={showEditClient}
                    updateIsOpen={(e) => setShowEditClient(e)}
                    animation={animationCard}
                    title={'Editar oportunidade'}
                    isEdit={true}
                    updateDelete={() => deleteOportunidade()} //corrigir delete
                >
                    <EditOp
                        id={oportunidadeId}
                        getOp={() => getOportunidades()}
                        get={() => getOportunidades()}
                        closeModal={() => setShowEditClient(false)}
                    ></EditOp>

                </CardRight>
            </ContainerGeral>



        </>
    );
};

export default Crm;