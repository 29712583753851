import styled from "styled-components";

export const FormContainer = styled.div`
  padding-top: 50;
  background: #f8f8f9;
  padding-bottom: 220px;
  div {
    form {
      padding-top: 50px;
      margin: auto;
      min-width: 250px;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      button {
        margin: 15px;
        width: 100%;
        max-width: 400px;
        height: 60px;
        i {
          margin-left: 15px;
        }
      }
    
    }
  }

`;

export const InfoCard = styled.div`
  width: 285px;
  height: 345px;
  background: #fff;
  margin-top: 15px;
  box-shadow: 0 0 10px 10px rgba(29, 33, 41, 0.1);
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: space-around;
  h1 {
    font-size: 24px;
    color: #303848;
  }
  .item {
    p {
      color: #303848;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 0px;
    }
    .p-estado{
      margin-bottom: 0px;
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
      i {
        margin-right: 7px;
        color: #bbb;
        margin-left: -5px;
      }
    }
    small {
      font-size: 11px;
      color: #bac1cf;
      font-weight: 700;
    }
  }
`;

export const InfoCardWrapper = styled.div`
  margin-top: 0px;
  width: 100%;
  display: ${props =>
    props.show === "sm" ? "none" : props.show === "xs" ? "none" : "flex"};
  justify-content: center;
  align-items: center;
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: collumn;
  margin-top: 45px;
`;

export const InfoCardPrevStepOne = styled.div`
  :hover{
    background-color: #FFF;
    margin-top: -50px;
  }
  width: 285px;
  background: #f8f8f8f8;
  box-shadow: 0 0 10px 10px rgba(29, 33, 41, 0.1);
  border-radius: 30px;
  cursor: pointer;
  margin-top: -30px;
  margin-right: -285px;
  transition: margin-top 0.5s;
  h1 {
    font-size: 20px;
    color: #303848;
    margin-left: 27px;
    margin-top: 10px;
  }
`;
