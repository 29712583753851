import styled from 'styled-components';

export const PaginatorContainer = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .first {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        width: 100px;
    }
    
    .last {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;
        width: 100px;
    }
    
`;

export const Item = styled.div`
    width: 50px;
    height: 40px;
    border: 1px solid #2fbb69;
    display: flex;
    flex-direction: ${props => props.isOpen ? 'column' : 'row'};
    align-items: center;
    justify-content: ${props => props.isOpen ? 'flex-start' : 'center'};

    background-color: ${props => props.isActive ? '#2fbb69' : 'transparent'};

    label {
        margin: 0px;
        color: ${props => props.isActive ? '#fff' : '#2fbb69'};
        font-size: 65%;

        &::selection{
            background: transparent;
        }
    }

    .more {
        width: ${props => props.isOpen ? '50px' : 'auto'};
        height: ${props => props.isOpen ? '40px' : 'auto'};
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }


    &:hover {
        background-color: ${props => props.isActive ? '#2fbb69' : 'rgba(47, 187, 105, 0.2)'};
    }
    
    &:active {
        background-color: ${props => props.isActive ? '#2fbb69' : 'rgba(47, 187, 105, 0.6)'};
    }

    transition: all 0.15s ease-in;
`;


export const DropItem = styled.div`
    width: 50px;
    height: 40px;
    border-bottom: 1px solid #2fbb69;
    display: flex;
    flex-direction: ${props => props.isOpen ? 'column' : 'row'};
    align-items: center;
    justify-content: ${props => props.isOpen ? 'flex-start' : 'center'};
    background-color: ${props => props.isActive ? '#2fbb69' : '#f8f8f9'};

    label {
        margin: 0px;
        color: ${props => props.isActive ? '#fff' : '#2fbb69'};
        font-size: 65%;

        &::selection{
            background: transparent;
        }
    }


    &:hover {
        background-color: ${props => props.isActive ? '#2fbb69' : 'rgba(47, 187, 105, 0.2)'};
    }
    
    &:active {
        background-color: ${props => props.isActive ? '#2fbb69' : 'rgba(47, 187, 105, 0.6)'};
    }

    transition: all 0.15s ease-in;
`;

export const Options = styled.div`
    width: 106%;
    max-height: 100px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    border: 1px solid #2fbb69;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: relative;
    overflow: scroll;
    margin-top: ${props => (
        props.opLength === 1 ? '-40px' :
            props.opLength === 2 ? '-75px' :
                props.opLength >= 3 ? '-100px' : '0px'
    )};
    background-color: #f8f8f9;


    &::selection{
        background: transparent;
    }

    /* width */
    &::-webkit-scrollbar {
        width: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #2fbb69; 
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #2fbb69; 
    }

    overflow-x: hidden !important;
    label {
        margin: 0px;
        height: 30px;
        color: ${props => props.isActive ? '#fff' : '#2fbb69'};
        font-size: 65%;
    }

    transition: all 0.15s ease-in;
`;

export const ContainerPage = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-itens: center;
`;

export const Legenda = styled.p`
    text-align: center;
    font-size: 65%;
    margin-top: 10px;
`;
