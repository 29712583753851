import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";
import { FormContainer, Card, Title, Paragraph, RowInfo, ColInfo, Label, Texto, ContainerForm } from "./styles";
import ArrowIcon from "../../../assets/icons/icon-arrow.svg";
import ArrowIconDisabled from "../../../assets/icons/icon-arrow-disabled.svg";
import FormAgronomo from "./form";

const PrescriptionStepTwo = ({ ...props }) => {

    const [isOpenBox, setIsOpenBox] = useState(false);
    const [erroResp, setErroResp] = useState(false);
    const [erroCrea, setErroCrea] = useState(false);
    const [erroArt, setErroArt] = useState(false);

    const openBoxAgronomo = () => {
        setIsOpenBox(!isOpenBox);
    }

    const openBoxAgronomo2 = () => {
        if (isOpenBox === false && props.disabled === false) {
            setIsOpenBox(!isOpenBox);
        }
    }

    const updateValues = (values) => {
        props.update(values);
    }

    useEffect(() => {
        props.updateIsOpen(isOpenBox);

        if (isOpenBox === false) {
            if (props.existInformation) {
                if (props.params.responsavel || props.params.crea || props.params.art) {
                    if (props.params.responsavel === '') {
                        setErroResp(true);
                        props.updateError(true);
                    } else {
                        setErroResp(false);
                    }

                    if (props.params.crea === '') {
                        setErroCrea(true);
                        props.updateError(true);
                    } else {
                        setErroCrea(false);
                    }

                    if (props.estado.value != 'RJ') {
                        if (props.params.art === '') {
                            setErroArt(true);
                            props.updateError(true);

                        } else {
                            setErroArt(false);
                        }
                    }
                }
            }
        }
    },[isOpenBox])

    useEffect(() => {
        if (props.isOpen != isOpenBox) {
            setIsOpenBox(props.isOpen);
        }
    },[props.isOpen])

    return (
        <Container>
            <FormContainer>
                <Card className="step02" existInformation={props.existInformation} onClick={() => openBoxAgronomo2()} openBox={isOpenBox} disabled={props.disabled} erro={ erroResp === true || erroCrea === true || erroArt === true ? true : props.error}>
                    <div className="row">
                        <Title disabled={props.disabled}>Responsável técnico</Title>
                        {!props.disabled ? (
                            <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBoxAgronomo()}/>
                        ) : (
                            <img className="arrow" src={ArrowIconDisabled} alt='arrow'/>   
                        )}
                    </div>

                    {!props.existInformation ? (
                        <>
                            {!isOpenBox ? (
                                <Paragraph disabled={props.disabled}>Insira o Nome, Número do CREA ou CFTA, da ART ou TRT e escolha se deseja assinar a receita digital, eletrônica ou manualmente.</Paragraph>
                            ) : (
                                <ContainerForm>
                                    <FormAgronomo 
                                        estado={props.estado}
                                        dataEmissao={props.dataEmissao}
                                        update={(value) => updateValues(value)}
                                    />
                                </ContainerForm>
                            )}

                        </>
                    ) : (
                        <>
                            {!isOpenBox ? (
                                <>
                                    <RowInfo>
                                        <ColInfo>
                                            <Label erro={erroResp === true ? erroResp : props.params.erroResponsavel} disabled={props.disabled}>Nome</Label>
                                            <Texto erro={erroResp === true ? erroResp : props.params.erroResponsavel} disabled={props.disabled}>{props.params.responsavel.label}</Texto>
                                        </ColInfo>

                                        <ColInfo>
                                            <Label erro={erroCrea === true ? erroCrea : props.params.erroCrea} disabled={props.disabled}>CREA/CFTA</Label>
                                            <Texto erro={erroCrea === true ? erroCrea : props.params.erroCrea} disabled={props.disabled}>{props.params.crea.label}</Texto>
                                        </ColInfo>
                                        {props.estado.value != 'RJ' ? (
                                            <ColInfo>
                                                <Label erro={erroArt === true ? erroArt : props.params.erroArt} disabled={props.disabled}>ART/TRT</Label>
                                                <Texto erro={erroArt === true ? erroArt : props.params.erroArt} disabled={props.disabled}>{props.params.art.label}</Texto>
                                            </ColInfo>
                                        ) : (null)}

                                        <ColInfo>
                                            <Label erro={props.params.erroAssinatura} disabled={props.disabled}>Tipo de assinatura preferencial</Label>
                                            <Texto erro={props.params.erroAssinatura} disabled={props.disabled}>{props.params.assinatura.label}</Texto>
                                        </ColInfo>
                                    </RowInfo>
                                </>
                            ) : (
                                //formulário
                                <ContainerForm>
                                    <FormAgronomo 
                                        estado={props.estado}
                                        dataEmissao={props.dataEmissao}
                                        update={(value) => updateValues(value)}
                                        params={props.params}
                                    />
                                </ContainerForm>
                            )}
                        </>
                    )}
                </Card>
            </FormContainer>
        </Container>
    )
};

export default PrescriptionStepTwo;
