import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { store } from "react-notifications-component";
import api from "../../services/api";
import GenericUpper from "../../components/generic-upper";
import Input from "../../components/input/input_v2";
import Button from "../../components/button/button_v2";
import Select from "../../components/select/select_v2";
import estados from "../../services/estados";
import { Container } from "react-grid-system";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import { ProdutoresAddContainer, YesNoContainer } from "./styles";
import ModalYesNo from "../../components/modal/modalYesNo";
import ModalText from "../../components/modal/modalText";
import Modal from '../../components/modal/modalGeneric';
import CardGeral from "../../components/card-geral/index";
import validadorDeCpf from "../../services/validadorCpf";
import buscaCep from "../../services/buscaCep";
import Interruptor from "../../components/interruptor";

const EditProdutores = ({ ...props }) => {

    const [nome, setNome] = useState('');
    const [cpf_cnpj, setCpfCnpj] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [estadoCep, setEstadoCep] = useState('');
    const [id, setId] = useState('');
    const [msg, setMsg] = useState('');
    const [msg2, setMsg2] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalYesNo, setShowModalYesNo] = useState(false);
    const [locais, setLocais] = useState([]);
    const [agendas, setAgendas] = useState([]);
    const [enviadoCrea, setEnviadoCrea] = useState(false);
    const [respostaCrea, setRespostaCrea] = useState('');
    const [respostaArt, setRespostaArt] = useState('');
    const [showModalText, setShowModalText] = useState(false);
    const [nomeInicial, setNomeInicial] = useState('');
    const [cpfInicial, setCpfInicial] = useState('');
    const [size_screen, setSizeScreen] = useState(window.screen.width);
    const [telefoneInicial, setTelefoneInicial] = useState('');
    const [loading, setLoading] = useState(false);
    const [erroCpf, setErroCpf] = useState(false);
    const [erroCpfMsg, setErroCpfMsg] = useState('');
    const [resolveConflict, setResolveConflict] = useState([]);
    const [btnCancelar, setBtnCancelar] = useState(false);
    const [modalGeneric, setModalGeneric] = useState(<></>);
    const [showModalGeneric, setShowModalGeneric] = useState(false);
    const [responseConflict, setResponseConflict] = useState([]);
    const [email, setEmail] = useState('');
    const [enviar_receita, setEnviarReceita] = useState('');

    const [status, setStatus] = useState(1)


    const validateCpf = (cpfCnpj) => {
        setCpfCnpj(cpfCnpj);
        setErroCpf(false);
    }

    const cpfIsValid = (value) => {
        if (value.length <= 14) {
            if (value.length > 0) {
                const CpfValido = validadorDeCpf(value);
        
                const strCPF = value.split("").filter(n => (Number(n) || n == 0)).join("");
        
                if( strCPF.length == 11 && !CpfValido) {
                    setErroCpf(true);
                    setErroCpfMsg('Informe um CPF válido!');

                    store.addNotification({
                        title: "Erro!",
                        message: "CPF inserido é inválido.",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } else {
                setErroCpf(true);
                setErroCpfMsg('Informe um CPF/CNPJ.');

                store.addNotification({
                    title: "Erro!",
                    message: "Informe um CPF/CNPJ.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
        }
    }

    const fetchCep = async (c) => {
        try {
            const responseCep = await buscaCep(cep);

            const { logradouro, bairro, localidade, uf } = responseCep;

            setLogradouro(logradouro);
            setBairro(bairro);
            setCidade(localidade);
            setEstado(uf);
            setEstadoCep(uf);

        } catch (err) {

            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    const limpaCep = async e => {
        if ((estadoCep) != estado) {
            if ((cep != "") || (logradouro != "") || (bairro != "") || (cidade != "")) {

                setLogradouro('');
                setBairro('');
                setCidade('');
                setEstado('');
                setCep('');
                setNumero('');


                let input = document.querySelector("#cep");
                let select = document.querySelector("#estado");
                input.classList.add("error");
                select.classList.add("error");

                store.addNotification({
                    title: "Erro!",
                    message: "Este estado não pertence ao CEP informado!",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {

            try {
                let select = document.querySelector("#estado");
                select.classList.remove("error");
            } catch (e) {

            }
        }
    };

    const goBack = () => {
        props.history.goBack();
    }

    const editPost = async e => {
        setLoading(true);

        if (erroCpf === true) {
            store.addNotification({
                title: "Erro!",
                message: `Verifique os campos em vermelho!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            setLoading(false);

        } else {
            
            const payload = {
                nome,
                cpf_cnpj,
                telefone,
                celular,
                cep,
                logradouro,
                numero,
                bairro,
                complemento,
                cidade,
                estado,
                email,
                enviar_receita,
                status
            };
            try {
                const response = await api.put(`/produtores/${id}`, payload);
                
                if (response.status === 200) {
                    setLoading(false);

                    // props.history.push(`/produtores/${id}`);

                    store.addNotification({
                        title: "Sucesso!",
                        message: "O produtor foi editado corretamente.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            } catch (err) {
                setLoading(false);

                if(err.response.status == 409){ 
                    const arts = err.response.data.data.arts;
                    const contracts = err.response.data.data.contratos;
                    const payloadProducer = err.response.data.data.payload_producer;

                    resolveProducerConflict(arts, contracts, payloadProducer);
                } else {

                }
            }
        }
    };

    const resolveProducerConflict = async (arts, contracts, payloadProducer) => {

        setResolveConflict({
            arts, 
            contracts, 
            payloadProducer
        });

        setModalGeneric(
            <div style={{
                width: '100%',
                padding: '10px',
            }}>
                <h2>Atenção</h2>
                <p>Este produtor está vinculado em {contracts.length} {contracts.length > 1 ? `contratos ativos` : `contrato ativo`}.</p>
                <p>Confirme se os dados já foram enviados ao CREA para continuar com a edição do produtor.</p>
                <table style={{
                    width: '100%',
                    height: 'auto',
                    margin: 'auto',
                    marginBottom: '20px'
                }}>
                    <tr>
                        <th style={{border: '1px solid #000', padding: '5px'}}>ART</th>
                        <th style={{border: '1px solid #000', padding: '5px'}}>Existem receitas emitidas?</th>
                        <th style={{border: '1px solid #000', padding: '5px'}}>CREA</th>
                    </tr>
                    {arts.map((art, i) => (
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px'}}>{art.numero_art}</td>
                            <td style={{border: '1px solid #000', padding: '5px'}}>{art.exist_prescription === true ? 'SIM' : 'NÃO'}</td>
                            <td style={{border: '1px solid #000', padding: '5px'}}> <YesNoContainer disabled={false} loading={false}> <span>Dados enviados ao CREA?</span> <Interruptor disabled={art.exist_prescription} value={art.exist_prescription === true ? true : responseConflict[i] ? responseConflict[i].enviado_crea : false} onChange={(e) => saveProducerConflict(e, art.numero_art, art.exist_prescription, art.agro_crea_id, i)}/> </YesNoContainer></td>
                        </tr>
                    ))}
                </table>
                
                <p style={{ width: '100%', fontSize: '75%' }}><span style={{ color: '#F10' }}>*</span>Para as ARTs com os dados <u>já enviados ao CREA</u> é exigido uma nova ART, nesse caso estaremos <b>inativando</b> o contrato na ART vigente.</p>
                <p style={{ width: '100%', fontSize: '75%' }}><span style={{ color: '#F10' }}>*</span>Para as ARTs com os dados <u>não enviados ao CREA</u>, estaremos <b>alterando</b> os dados sob sua responsabilidade, mantendo o produtor como <b>ativo</b> na ART vigente.</p>
                <p style={{ width: '100%', fontSize: '75%' }}><span style={{ color: '#F10' }}>*</span>Para as ARTs que possuem receitas emitidas vamos considerar que os dados <b><u>já foram enviados ao CREA</u></b>.</p>

                <div 
                    className="button-finish" 
                    style={{ 
                        width: '100%', 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'center',
                        alignItems: 'center', 
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}
                >
                    <Button 
                        width='255px'
                        height='50px'
                        value='CONFIRMAR'
                        outline={false}
                        disabled={false}
                        onClick={() => confirmProducerConflict()}
                        loading={false}
                    />
                </div>
            </div>
        );

        setShowModalGeneric(true);
    }

    const saveProducerConflict = (enviado, numArt, existPrescription, agroCreaId, index) => {
        let resolve = responseConflict;

        resolve[index] = {
            enviado_crea: existPrescription === true ? true : enviado, 
            agro_crea_id: agroCreaId, 
            numero_art: numArt,
            exist_prescription: existPrescription
        };

        setResponseConflict(resolve);
    }

    const confirmProducerConflict = async () => {
        setModalGeneric(<></>);
        setShowModalGeneric(false);
        setLoading(true);

        const payload = {
            arts: responseConflict,
            payload_producer: resolveConflict.payloadProducer
        }

        try {
            const response = await api.put(`/produtores/${id}/confirmar`, payload);

            if (response) {
                setLoading(false);

                props.history.push(`/produtores/${id}`);

                store.addNotification({
                    title: "Sucesso!",
                    message: "O produtor foi editado corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            setLoading(false);
        }
    }

    const addLocal = () => {
        props.history.push(`/localdeaplicacao/add/${props.match.params.id}`)
    }

    const modalClose = e => {
        if(showModal == true){
            setShowModal(false);
        }
        if(showModalYesNo == true){
            setShowModalYesNo(false);
        }
    }

    const updateRespostaYesNo = async (resposta) => {
        setShowModalYesNo(false);
    }

    const closeOk = e => {
        setShowModalText(false);
    }

    const updateOk = async (resposta) => {
        setShowModalText(false);
    }

    const getProdutores = async () => {
        try {
            const id = props.match.params.id;
            setId(id);
    
            const response = await api.get(`/produtores/${id}`);
            const produtores = response.data.data;

            setNome(produtores.nome);
            setCpfCnpj(produtores.cpf_cnpj);
            setTelefone(produtores.telefone);
            setCelular(produtores.celular);
            setCep(produtores.cep);
            setLogradouro(produtores.logradouro);
            setNumero(produtores.numero);
            setComplemento(produtores.complemento);
            setCidade(produtores.cidade);
            setBairro(produtores.bairro);
            setEstadoCep(produtores.estado);
            setEstado(produtores.estado);
            setNomeInicial(produtores.nome);
            setCpfInicial(produtores.cpf_cnpj);
            setTelefoneInicial(produtores.telefone);
            setEmail(produtores.email);
            setEnviarReceita(produtores.enviar_receita);
            setStatus(produtores.status);

        } catch (error) {
        }
    };

    const getLocais = async () => {
        const id = props.match.params.id;
        setId(id);

        try {

            let l = [];

            api.get(`/produtores/${id}/fazendas`).then(response => {
                let locais = response.data.data;
                console.log("LOCAIS => ", locais);
                
                setLocais(locais);
                
            });

        } catch (error) {
        }
    }

    const getAgendas = async () => {
        try {
            const id = props.match.params.id;
            setId(id);
    
            const response = await api.get(`/agenda/produtor/${id}`);
            const agendas = response.data.data;

            setAgendas(agendas);
        } catch (error) {
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getProdutores();
        getLocais();
        getAgendas();
    }, []);

    useEffect(() => {
        setSizeScreen(window.screen.width);
    }, [window.screen.width])

    return(
        <>
            <GenericUpper
                title="Edição de Produtores"
                subtitle="Edite este Produtor"
            ></GenericUpper>

            <ProdutoresAddContainer>
                <Container>
                    <form>
                        <Input
                            name="nome"
                            label="Nome"
                            type="text"
                            id="nome"
                            value={nome}
                            onChange={e => setNome(e)}
                            maxlength={50}
                        />

                        {enviar_receita !== "" && (
                            <Input
                                label="E-mail"
                                name="email"
                                className="input"
                                id="email"
                                onChange={e => setEmail(e)}
                                yesNo={true}
                                messageYesNo={'enviar receitas por email'}
                                erro={false}
                                messageErro={false}
                                value={email}
                                onChangeYesNo={(e) => setEnviarReceita(e ? 1 : 0)} 
                                initialYesNoValue={enviar_receita}
                                yesNoSpanWidth='80px'
                            />
                        )}

                        <Input
                            name="cpf_cnpj"
                            type="text"
                            value={cpf_cnpj}
                            label="CPF/CNPJ"
                            id="cpf_cnpj_id"
                            onChange={e => validateCpf(e)}
                            onBlur={(e) => cpfIsValid(e.target.value)}
                            erro={erroCpf}
                            messageErro={erroCpfMsg}
                            inputmode="numeric"
                        />
                        <Input
                            name="telefone"
                            type="text"
                            value={telefone}
                            label="Telefone"
                            id="telefone"
                            onChange={e => setTelefone(e)}
                            inputmode="numeric"
                        />
                        <Input
                            name="celular"
                            value={celular}
                            type="text"
                            label="Celular (opcional)"
                            id="celular"
                            onChange={e => setCelular(e)}
                            inputmode="numeric"
                        />
                        <Input
                            name="cep"
                            value={cep}
                            type="text"
                            label="CEP"
                            id="cep"
                            onChange={e => setCep(e)}
                            onBlur={e => fetchCep(e.target.value)}
                            inputmode="numeric"
                        />

                        <Input
                            name="logradouro"
                            value={logradouro}
                            type="text"
                            label="Logradouro"
                            id="logradouro"
                            onChange={e => setLogradouro(e)}
                        />

                        <Input
                            name="bairro"
                            value={bairro}
                            type="text"
                            label="Bairro"
                            id="bairro"
                            onChange={e => setBairro(e)}
                        />

                        <Input
                            name="numero"
                            value={numero}
                            label="Número"
                            id="numero"
                            onChange={e => setNumero(e)}
                        />
                        <Input
                            name="complemento"
                            value={complemento}
                            type="text"
                            label="Complemento (opcional)"
                            id="complemento"
                            onChange={e => setComplemento(e)}   
                        />
                        <Input
                            name="cidade"
                            value={cidade}
                            type="text"
                            label="Cidade"
                            id="cidade"
                            onChange={e => setCidade(e)}
                        />

                        <Select
                            name="estado"
                            value={estado}
                            label="Estado"
                            className="select"
                            estado={true}
                            id="estado"
                            defaultInputValue={estado}
                            onChange={estado => setEstado(estado.value)}
                            options={estados}
                            onBlur={(e) => limpaCep(e.target.value)}
                            disabled={estado.value || estado ? true : false}
                        />
                        
                        <Select
                            name="status"
                            value={status}
                            label="Status"
                            className="select"
                            id="status"
                            onChange={status => setStatus(status.value)}
                            options={[
                                {label: 'Ativo', value: 1},
                                {label: 'Inativo', value: 0}
                            ]}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-between',
                                alignItems: 'center', 
                                marginTop: '40px',
                            }}
                        >
                            <Button 
                                width='45%'
                                height='50px'
                                value='VOLTAR'
                                outline={true}
                                disabled={false}
                                onClick={() => goBack()}
                                loading={false}
                            />
                            
                            <Button 
                                width='45%'
                                height='50px'
                                value='SALVAR'
                                outline={false}
                                disabled={false}
                                onClick={() => editPost()}
                                loading={loading}
                            />

                        </div>

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'center',
                                alignItems: 'center', 
                                marginTop: '40px',
                                marginBottom: '40px',
                            }}
                        >
                            <Button 
                                width='100%'
                                height='50px'
                                value='ADICIONAR UM LOCAL DE APLICAÇÃO'
                                outline={false}
                                disabled={false}
                                onClick={() => addLocal()}
                                loading={false}
                            />
                            

                        </div>

                        <ModalYesNo
                            show={showModalYesNo}
                            onHide={() => modalClose()}
                            resposta=''
                            update={(e) => updateRespostaYesNo(e)}
                            title='Atenção!'
                            text={msg}
                            btnNo={true}
                        ></ModalYesNo>

                        <ModalText
                            show={showModalText}
                            onHide={() => closeOk()}
                            text={msg2}
                            btnOk={true}
                            btnCancelar={false}
                            update={() => updateOk()}
                        ></ModalText>

                        <Modal
                            show={showModalGeneric}
                            onHide={() => {
                                setShowModalGeneric(false);
                                setModalGeneric(false);
                            }}
                        >
                            {modalGeneric}
                        </Modal>

                    </form>

                    {size_screen <= 768 ? (<>
                        <CardGeral
                            titulos={ ["FAZENDAS_PRODUTOR","NOME DA FAZENDA", "CULTURAS", "ESTADO","CNPJ"]}
                            valores={locais}
                        />
                    </>) : (<>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome da Fazenda</th>
                                    <th>Cultura</th>
                                    <th>Estado</th>
                                    <th>CNPJ</th>
                                </tr>
                            </thead>
                            <TBody>
                                {locais.map(local => (
                                    
                                    <tr
                                        onClick={e =>
                                            props.history.push(`/fazendas/${local.id}/${id}`)
                                        }
                                        key={local.id}
                                    >
                                        {local.status == 1 ? (
                                            <td>{local.nome}</td>
                                        ) : (
                                            <td>
                                                <strike>{local.nome}</strike>
                                            </td>
                                        ) }

                                        {local.status == 1? (
                                            <td>
                                                {local.cultura != null && local.cultura != "" && typeof(local.cultura) != 'string' ? (
                                                    local.cultura.map(cultura=>{
                                                        return <div style={{marginLeft: '2px'}}>{cultura.nome_comum}</div>
                                                    })
                                                ) : (
                                                    <div style={{marginLeft: '2px'}}>-</div>
                                                )}
                                            </td>

                                        ):(
                                            
                                            <td>
                                                {local.cultura != null && local.cultura != "" && typeof(local.cultura) != 'string' ? (
                                                    local.cultura.map(cultura=>{
                                                        return <div style={{marginLeft: '2px'}}><strike>{cultura.nome_comum}</strike></div>
                                                    })
                                                ) : (
                                                    <div style={{marginLeft: '2px'}}>-</div>
                                                )}
                                            </td>
                                            
                                        )}

                                        {local.status == 1 ? (
                                            <td> 
                                                <div className="estado">{local.estado}</div>
                                            </td>
                                        ) : (
                                            <td> 
                                                <div className="estado-inativo"> {local.estado} </div> 
                                            </td>
                                        )}

                                        
                                        {local.status == 1 ? (
                                            <td>{local.cpf_cnpj}</td>
                                        ) : (
                                            <td>
                                                <strike>{local.cpf_cnpj}</strike>
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </TBody>
                        </Table>
                    </>)}
                    
                </Container>
            </ProdutoresAddContainer>
        </>
    )


}
export default withRouter(EditProdutores);
