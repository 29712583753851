import React, { useState, useEffect } from "react";
import api from "../../services/api";
import { CardForm } from "./style";
import Button from '../button/index';
import Select from '../select/index';
import { store } from "react-notifications-component";
import DotsLoader from "../loader";
import { Bar, Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const CardRelatorios = ({ ...props }) => {

    const [culturaId, setCulturaId] = useState('');
    const [culturas, setCulturas] = useState("");
    const [produtoId, setProdutoId] = useState('');
    const [produtos, setProdutos] = useState("");
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [disabledCultura, setDisabledCultura] = useState(true);
    const [disabledProduto, setDisabledProduto] = useState(true);
    const [showLoading, setShowLoading] = useState(false);
    const [dataGraph, setDataGraph] = useState([]);
    const [optionsGraph, setOptionsGraph] = useState([]);
    const [showGraph, setshowGraph] = useState(false);

    const {
        relatorio
    } = props

    const formatCulturas = culturas => {
        culturas = culturas.map(cultura => {
            const format = {
                value: cultura.id,
                label: cultura.nome_comum
            };
            return format;
        });
        return culturas;
    };

    const getCulturas = async () => {
        try {
            const response = await api.get(`/culturas`);
            let culturas = response.data.data;
            culturas = formatCulturas(culturas);
            setCulturas(culturas);
            setDisabledCultura(false);
        } catch (error) {
        }
    }

    const formatProduto = produto => {
        produto = produto.map(produto => {
            console.log(produto);

            const format = {
                value: produto.id,
                label: produto.nome_comercial
            };
            return format;
        });
        return produto;
    };

    const _onFocus = (e) => {
        e.currentTarget.type = "date";
    }

    const _onBlur = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Data inicial";
    }

    const _onBlur2 = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Data final";
    }

    const gerarRelatorioGeral = async () => {
        try {
            setShowLoading(true);
            let dtInicial = dataInicio;
            let dtFinal = dataFim;

            const payload = {
                dtInicial,
                dtFinal
            }

            const response = await api.post(`/relatorios/geral`, payload);
            window.open(response.data.data.link);
            setShowLoading(false);
        } catch (error) {
            setShowLoading(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const gerarRelatorioFrequentesPorCultura = async (tipo) => {
        if (tipo == 1) {
            let dtInicial = dataInicio;
            let dtFinal = dataFim;

            const payload = {
                cultura: culturaId.value,
                dtInicial,
                dtFinal,
                pdf: 1
            }

            try {
                setShowLoading(true);

                const response = await api.post(`/relatorios/alvos/cultura`, payload)


                let win = await window.open(response.data.data.link);

                setShowLoading(false);

            } catch (error) {
                setShowLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }


        } else if (tipo == 2) {
            let dtInicial = dataInicio;
            let dtFinal = dataFim;

            const payload = {
                cultura: culturaId.value,
                dtInicial,
                dtFinal,
                pdf: 0
            }

            try {
                setShowLoading(true);

                const response = await api.post(`/relatorios/alvos/cultura`, payload);
                let link = response.data.data.link;
                console.log(link);
                window.open(link);

                setShowLoading(false);

            } catch (error) {
                setShowLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        }

    }

    const gerarRelatorioRecomendadosPorCultura = async (tipo) => {
        if (tipo == 1) {
            let dtInicial = dataInicio;
            let dtFinal = dataFim;

            const payload = {
                cultura: culturaId.value,
                dtInicial,
                dtFinal,
                pdf: 1
            }

            try {
                setShowLoading(true);
                const response = await api.post(`/relatorios/produtos/cultura/pdf`, payload);
                window.open(response.data.data.link);
                setShowLoading(false);


            } catch (error) {
                setShowLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        } else if (tipo == 2) {
            let dtInicial = dataInicio;
            let dtFinal = dataFim;

            const payload = {
                cultura: culturaId.value,
                dtInicial,
                dtFinal,
                pdf: 0
            }

            try {
                setShowLoading(true);

                const response = await api.post(`/relatorios/produtos/cultura/pdf`, payload);

                let link = response.data.data.link;
                window.open(link);

                setShowLoading(false);

            } catch (error) {
                setShowLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const gerarRelatorioPorPeriodo = async (tipo) => {
        if (tipo == 1) {
            let dtInicial = dataInicio;
            let dtFinal = dataFim;

            const payload = {
                dtInicial,
                dtFinal,
                pdf: 1
            }

            try {
                setShowLoading(true);

                const response = api.post(`/relatorios/produtos`, payload);
                window.open((await response).data.data.link);
                setShowLoading(false);
            } catch (error) {
                setShowLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        } else if (tipo == 2) {
            let dtInicial = dataInicio;
            let dtFinal = dataFim;


            const payload = {
                produto: produtoId.value,
                dtInicial,
                dtFinal,
                pdf: 0
            }

            try {
                setShowLoading(true);

                const response = await api.post(`/relatorios/produtos`, payload);
                let link = response.data.data.link;
                console.log(link);
                window.open(link);
                setShowLoading(false);

            } catch (error) {
                setShowLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const gerarGrafico = async (tipo) =>{ 
        if (showGraph == true && dataGraph == []) {
            setshowGraph(false);  
        }else{
            try{
                setShowLoading(true);
                let dtInicial = dataInicio;
                let dtFinal = dataFim;

                const payload = {
                    cultura: culturaId.value,
                    dtInicial,
                    dtFinal,
                    grafico: 1
                }
                if (tipo == 1) {
                    const response = await api.post(`/relatorios/alvos/cultura`, payload)              
                    setDataGraph({
                        labels: response.data.data.alvos,
                        datasets: [
                            {
                            label: 'Alvo/Praga',
                            data: response.data.data.qtdReceitas,
                            backgroundColor: 'rgb(47, 187, 105)',
                            },
                        ],
                    });
                    setOptionsGraph({
                        responsive: true,
                        plugins: {
                            legend: {
                            position: 'top',
                            },
                            title: {
                                display: true,
                                text: `Alvos mais frequentes no ${response.data.data.cultura}, entre o período de ${response.data.data.dtInicial} à ${response.data.data.dtFinal}`,
                            },
                        },
                    });
                }else if(tipo == 2){
                    const response = await api.post(`/relatorios/produtos/cultura`, payload)              
                    setDataGraph({
                        labels: response.data.data.produtos,
                        datasets: [
                            {
                                label: 'Quantidade de receitas',
                                data:response.data.data.qtdReceitas,
                                backgroundColor: 'rgb(88, 155, 255)',
                            },
                        ],
                    });
                    setOptionsGraph({
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: `Produtos mais recomendados para ${response.data.data.cultura}, entre o período de ${response.data.data.dtInicial} à ${response.data.data.dtFinal}`,
                            },
                        },
                    });
                }else if(tipo == 3){
                    const response = await api.post(`/relatorios/produtos`, payload)              
                    setDataGraph({
                        labels: response.data.data.produtos,
                        datasets: [
                            {
                                label: 'Quantidade de receitas',
                                data:response.data.data.qtdReceitas,
                                backgroundColor: 'rgb(29, 33, 41)',
                            },
                        ],
                    });
                    setOptionsGraph({
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                            title: {
                                display: true,
                                text: `Produtos mais recomendados entre o período de ${response.data.data.dtInicial} à ${response.data.data.dtFinal}`,
                            },
                        },
                    });
                }
    

                setShowLoading(false);
                setshowGraph(true);  
            }catch (error){
                setShowLoading(false);
                setshowGraph(false);  
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    useEffect(() => {
        if (props.relatorio) {
            setshowGraph(false);
            if (props.relatorio == 1 || props.relatorio == 2) {
                if(!culturas){
                    getCulturas();
                }
            }
        }
    }, [props.relatorio])

    return (
        <>
            <CardForm>
                {props.relatorio == 1 || props.relatorio == 2 ? (<>
                    {props.relatorio == 1 ? (
                        <div className="title">
                            <h3> Alvos mais frequentes por cultura e por periodo </h3>
                        </div>
                    ) : (
                            <div className="title">
                                <h3> Produtos mais recomendados por cultura e por periodo </h3>
                            </div>
                        )}

                    <div className="lblPeriodo">
                        <label> Período </label>
                    </div>

                    <div className="data-filter">
                        <div className="input-data-filter">
                            <input
                                className="input-date"
                                type="text"
                                placeholder="Data inicial"

                                onFocus={_onFocus}
                                onBlur={_onBlur}
                                onChange={e => setDataInicio(e.target.value)}
                            />

                            <input
                                className="input-date"
                                type="text"
                                placeholder="Data final"
                                mask="99/99/9999"
                                onFocus={_onFocus}
                                onBlur={_onBlur2}
                                onChange={e => setDataFim(e.target.value)}
                            />
                        </div>
                    </div>

                    <div
                        className="cultura-select"
                    >

                        <Select
                            name="agro_culture_id"
                            className="select"
                            placeholder="Cultura"
                            id="cultura"
                            value={culturaId.value}
                            isClearable
                            onChange={cultura => cultura ? setCulturaId(cultura) : setCulturaId("")}
                            options={culturas}
                            disabled={disabledCultura}
                        />
                    </div>

                    {props.relatorio == 1 ? (
                        <>
                            {showLoading == false ? (

                                <div className="buttons">
                                    <Button
                                        color="#00b43f"
                                        onClick={() => gerarRelatorioFrequentesPorCultura(1)}
                                        style={{ outline: 0, width: "90px", padding: "10px" }}
                                    >
                                        Gerar PDF
                                    </Button>

                                    <Button
                                        color="#00b43f"
                                        style={{ outline: 0, width: "90px", padding: "10px" }}
                                        onClick={() => gerarRelatorioFrequentesPorCultura(2)}
                                    >
                                        Gerar XLSX
                                    </Button>
                                    <Button
                                        color="#00b43f"
                                        onClick={() => gerarGrafico(1)}
                                        style={{ outline: 0, width: "90px", padding: "10px" }}
                                    >
                                        Ver Gráfico
                                    </Button>
                                </div>
                            ) : (
                                <DotsLoader style={{ margin: 'auto', marginTop: '-12px' }} show={showLoading}></DotsLoader>
                                )}
                                {showGraph !== true ? (
                                    console.log(false)
                                ):(
                                    <>
                                    <br />
                                    <Bar options={optionsGraph} data={dataGraph} />
                                    </>
                                )}
                      </>
                    ) : (
                            <>
                                {showLoading == false ? (

                                    <div className="buttons">
                                        <Button
                                            color="#00b43f"
                                            style={{ outline: 0, width: "90px", padding: "10px" }}
                                            onClick={() => gerarRelatorioRecomendadosPorCultura(1)}

                                        >
                                            Gerar PDF
                                        </Button>

                                        <Button
                                            color="#00b43f"
                                            style={{ outline: 0, width: "90px", padding: "10px" }}
                                            onClick={() => gerarRelatorioRecomendadosPorCultura(2)}
                                        >
                                            Gerar XLSX
                                        </Button>
                                        <Button
                                            color="#00b43f"
                                            onClick={() => gerarGrafico(2)}
                                            style={{ outline: 0, width: "90px", padding: "10px" }}
                                        >
                                            Ver Gráfico
                                        </Button>
                                    </div>
                                ) : (
                                        <DotsLoader style={{ margin: 'auto', marginTop: '-12px' }} show={showLoading}></DotsLoader>
                                    )}
                                    {showGraph !== true ? (
                                        console.log(false)
                                    ):(
                                        <>
                                        <br />
                                        <Bar options={optionsGraph} data={dataGraph} />
                                        </>
                                    )}
                            </>
                        )}



                </>) : (<>
                    {props.relatorio == 3 || props.relatorio == 4 ? (<>
                        {props.relatorio == 3 ? (
                            <div className="title">
                                <h3> Produtos mais recomendados por período </h3>
                            </div>

                        ) : (
                            <div className="title">
                                <h3> Relatório geral </h3>
                            </div>
                        )}

                        <div className="lblPeriodo">
                            <label> Período </label>
                        </div>

                        <div className="data-filter">
                            <div className="input-data-filter">
                                <input
                                    className="input-date"
                                    type="text"
                                    placeholder="Data inicial"

                                    onFocus={_onFocus}
                                    onBlur={_onBlur}
                                    onChange={e => setDataInicio(e.target.value)}
                                />

                                <input
                                    className="input-date"
                                    type="text"
                                    placeholder="Data final"
                                    mask="99/99/9999"
                                    onFocus={_onFocus}
                                    onBlur={_onBlur2}
                                    onChange={e => setDataFim(e.target.value)}
                                />
                            </div>
                        </div>

                        {props.relatorio == 3 ? (
                            <>
                                {showLoading == false ? (
                                    <div className="buttons">
                                        <Button
                                            color="#00b43f"
                                            style={{ outline: 0, width: "90px", padding: "10px" }}
                                            onClick={() => gerarRelatorioPorPeriodo(1)}
                                        >
                                            Gerar PDF
                                        </Button>

                                        <Button
                                            color="#00b43f"
                                            style={{ outline: 0, width: "90px", padding: "10px" }}
                                            onClick={() => gerarRelatorioPorPeriodo(2)}
                                        >
                                            Gerar XLSX
                                        </Button>
                                        <Button
                                            color="#00b43f"
                                            onClick={() => gerarGrafico(3)}
                                            style={{ outline: 0, width: "90px", padding: "10px" }}
                                        >
                                            Ver Gráfico
                                        </Button>
                                        
                                    </div>

                                ) : (
                                    <DotsLoader style={{ margin: 'auto', marginTop: '-12px' }} show={showLoading}></DotsLoader>
                                )}
                                {showGraph !== true ? (
                                    console.log(false)
                                ):(
                                    <>
                                    <br />
                                    <Bar options={optionsGraph} data={dataGraph} />
                                    </>
                                )}
                            </>

                        ) : (
                                <>
                                    {showLoading == false ? (
                                        <div className="buttons">
                                            <Button
                                                color="#00b43f"
                                                style={{ outline: 0, width: "90px", padding: "10px" }}
                                                onClick={() => gerarRelatorioGeral()}
                                            >
                                                Gerar XLSX
                                        </Button>
                                            <br></br>
                                        </div>
                                    ) : (
                                            <DotsLoader style={{ margin: 'auto', marginTop: '-12px' }} show={showLoading}></DotsLoader>
                                        )}
                                </>
                            )}

                    </>) : (null)}
                </>)}
            </CardForm>
        </>
    )
}

export default CardRelatorios;