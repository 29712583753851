import React from "react";

import { Container } from "react-grid-system";
import { store } from "react-notifications-component";
import GenericUpper from "../../components/generic-upper";
import Input from "../../components/input/input_v2";
import Button from "../../components/button/button_v2";
import AddCrea from "../../components/creas/creas/index";
import api from "../../services/api";
import { AgronomosAddContainer, Assinaturas } from "./styles";
import mais from "../../assets/Mais.png";
import ModalPassword from '../../components/modal/modal_password';
import ModalText from '../../components/modal/modalText';
import ModalAlert from '../../components/modal/modalGeneric';

import Table from "../../components/table";
import TBody from "../../components/tbody";
import validadorDeCpf from "../../services/validadorCpf";
import { logout } from "../../services/auth";
import InputSign from "../../components/inputSign";
import DotsLoader from "../../components/loader";
import { IconUploads } from '../../assets/icons/iconUploads';
import CardGeral from "../../components/card-geral";

export const USER = "@userinfo";


class EditAgronomos extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        nome: "",
        cpf: "",
        assinatura: "",
        id: "",
        add: false,
        creas: [],
        usuario: "",
        senha: "",
        estadoSC: false,
        estadoES: false,
        typePassword: "password",
        namesfiles: [],
        file: [],
        textFile: "",
        isValidate: false,
        showModal: false,
        showModalText: false,
        idCertificado: '',
        isDelete: false,
        renderInput: true,
        loading: false,
        showModalBlock: false,
        erroCpf: false,
        erroCpfMsg: '',
        load: false,
        email: '',
        enviar_receita: '',
        eletronica: false,
        digital: false,
        manual: false,
        whatsapp: '',
    }

    // função para executar no inicio do componente
    componentDidMount() {
        this.getAgronomos();
        this.getCreas();
    }

    //Função para buscar os agronomos
    getAgronomos = async () => {
        try {
            const id = this.props.match.params.id;
            this.setState({ id });

            const response = await api.get(`/agronomos/${id}`);
            const agronomos = response.data.data;

            if (agronomos) {
                const {
                    nome,
                    cpf,
                    usuario,
                    senha,
                    assinatura,
                    email,
                    enviar_receita,
                    whatsapp
                } = agronomos;

                this.setState({
                    nome,
                    cpf,
                    assinatura,
                    usuario,
                    senha,
                    load: true,
                    email,
                    enviar_receita,
                    whatsapp
                });

                const payload = {
                    cpf
                }
                const responseCertificado = await api.post('/sign/consulta/', payload);

                if (responseCertificado) {
                    let certificado = responseCertificado.data.data;
                    this.setState({ namesfiles: [certificado.arquivo] })
                    this.setState({ idCertificado: certificado.id })
                }

            }
        } catch (error) {
        }
    };

    getCreas = async () => {
        try {
            const id = this.props.match.params.id;
            this.setState({ id });

            const response = await api.get(`/agronomos/${id}/creas`);
            const creas = response.data.data;
            this.setState({ creas });

            response.data.data.map(c => {
                if (c.estado === "SC") {
                    this.setState({ estadoSC: true });
                }
                if (c.estado === "ES") {
                    this.setState({ estadoES: true });
                }
            })

        } catch (error) {
        }

    }

    tipoAssinatura = (e) => {
        if (e === 'E') {
            this.setState({ eletronica: true });
            this.setState({ digital: false});
            this.setState({ manual: false });
        } else if (e === 'D') {
            this.setState({ eletronica: false });
            this.setState({ digital: true});
            this.setState({ manual: false });
        } else {
            this.setState({ eletronica: false });
            this.setState({ digital: false});
            this.setState({ manual: true });
        }

    };

    // Função para Jogar os Dados na API
    editPost = async e => {
        this.setState({ loading: true });

        if (this.state.erroCpf === true) {
            store.addNotification({
                title: "Erro!",
                message: "Informe um CPF válido!",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            this.setState({ loading: false });

        } else {
            const {
                nome,
                cpf,
                id,
                usuario,
                senha,
                assinatura,
                email,
                enviar_receita,
                whatsapp
            } = this.state;

            const payload = {
                nome,
                cpf,
                usuario,
                senha,
                assinatura,
                email,
                enviar_receita,
                whatsapp
            };

            try {
                const response = await api.put(`/agronomos/${id}/`, payload);

                if (response.status === 200) {
                    this.setState({ loading: false });
                    this.props.history.push("/agronomos");
                    store.addNotification({
                        title: "Sucesso!",
                        message: "O responsável técnico foi Editado corretamente.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            } catch (err) {
                this.setState({ loading: false });

                let inputWithErrors = document.querySelectorAll(".error");

                for (let input of inputWithErrors) {
                    input.classList.remove("error");
                }

                const errors = err.response.data.errors;

                for (let field in errors) {
                    const input = document.getElementById(field);
                    input.classList.add("error");
                }

                store.addNotification({
                    title: "Erro!",
                    message: "Verifique os campos em vermelho",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    };

    add = () => {
        this.setState({ add: true });
    }

    onKeyDownFormater = (e, campo) => {
        if (e.keyCode === 8) {
            const value = document.getElementsByName(campo);
            return value[0].value;
        }
    }

    alterTypePassword = async () => {
        if (this.state.typePassword === 'password') {
            this.setState({ typePassword: 'text' });
        } else if (this.state.typePassword === 'text') {
            this.setState({ typePassword: 'password' });
        }
    }

    validaCertificado = async (file, password) => {
        if (this.state.cpf) {
            if (file && !this.state.isValidate && password) {
                const payload = {
                    certificado: file,
                    cpf: this.state.cpf,
                    senha: password
                }
                try {
                    let user = JSON.parse(localStorage.getItem(USER));

                    if (user.contrato) {
                        if (user.contrato.agro_plan_functions) {
                            let isBlock = false;

                            user.contrato.agro_plan_functions.map((e) => {
                                if (e.agro_plans_function.id === 9) {
                                    if (e.status === 0) {
                                        isBlock = true;
                                    }
                                }
                            });

                            if (isBlock === true) {
                                this.closeModalPassword();
                                store.addNotification({
                                    title: "Erro!",
                                    message: `A função de assinatura digital não está liberada para seu plano!`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            } else {
                                const response = await api.post(`/sign/digital`, payload);
                                if (response) {
                                    this.setState({ isValidate: true });
                                    this.setState({ idCertificado: response.data.data.id });
                                }
                                const input = document.getElementById('cpf');
                                input.classList.remove("error");
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `${response.data.data.msg}`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            }
                        } else {
                            logout();
                            window.location = '/login';
                        }
                    } else {
                        logout();
                        window.location = '/login';
                    }
                } catch (error) {
                    this.closeModalPassword();
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        } else {
            const input = document.getElementById('cpf');
            input.classList.add("error");
            store.addNotification({
                title: "Erro!",
                message: `Informe o CPF!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    onChangeCertificate = (e) => {
        let user = JSON.parse(localStorage.getItem(USER));

        if (user.contract_software_id === 6) {
            this.setState({ showModalBlock: true });
        } else {
            let cont = e.target.files.length;
            cont = cont - 1;

            if (e.target.files) {
                if (
                    e.target.files[cont].type == "application/x-pkcs12"
                ) {
                    let fil = e.target.files;
                    let reader = new FileReader();
                    reader.readAsDataURL(fil[0]);

                    reader.onload = (e) => {
                        this.setState({ file: e.target.result });
                        this.setState({ textFile: e.target.result })

                        if (this.state.cpf) {
                            this.setState({ showModal: true })
                        } else {
                            const input = document.getElementById('cpf');
                            input.classList.add("error");
                            store.addNotification({
                                title: "Erro!",
                                message: `Informe o CPF!`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    }
                    this.setState({ namesfiles: e.target.files[cont].name });

                } else {
                    store.addNotification({
                        title: "Erro!",
                        message: `O arquivo selecionado não possui a extensão correta`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Não é possível enviar mais que um arquivo!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    };

    openModalText = () => {
        this.setState({ showModalText: true });
    }
    modalTextClose = () => {
        this.setState({ showModalText: false });
    }

    delete = async () => {
        try {
            if (this.state.idCertificado) {
                const response = await api.delete(`/sign/delete/${this.state.idCertificado}`);
                this.setState({ idCertificado: '' });
                this.setState({ isDelete: true });
                store.addNotification({
                    title: "Sucesso!",
                    message: `${response.data.data}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    deleteFile = () => {
        this.delete();
        this.setState({ namesfiles: [] });
        this.setState({ isValidate: false });
    };

    updatePassword = (e) => {
        this.validaCertificado(this.state.file, e);
        this.setState({ showModal: false });
    }

    closeModalPassword = () => {
        this.setState({ renderInput: false });
        this.setState({ showModal: false });
        this.setState({ namesfiles: [] });
        this.setState({ file: [] });
        this.setState({ textFile: '' });
    }

    changeCpf = (e) => {
        this.setState({ cpf: e })

        if (e.length === 14) {
            const isValid = validadorDeCpf(e);

            this.setState({ erroCpf: !isValid });
            if (isValid === false) {
                this.setState({ erroCpfMsg: 'Informe um CPF válido' });
            }
        }
    }
    
    changeWhatsapp = (e) => {
        this.setState({ whatsapp: e })
    }

    editSign = (e) => {
        this.setState({ assinatura: e })
    }

    validaCpf = (e) => {
        const isValid = validadorDeCpf(e);

        this.setState({ erroCpf: !isValid });
        this.setState({ erroCpfMsg: 'Informe um CPF válido' });

    }



    back = () => {
        this.props.history.goBack();
    }

    render() {
        const {
            nome,
            cpf,
            assinatura,
            creas,
            namesfiles,
            erroCpf,
            erroCpfMsg,
            load,
            email,
            enviar_receita,
            whatsapp
        } = this.state;

        return (
            <>

                {this.state.add == false ? (<>
                    <GenericUpper
                        title="Editar Responsável Técnico"
                        subtitle="Editar o Responsável Técnico"
                    ></GenericUpper>
                    <AgronomosAddContainer>
                        {load === true ? (
                            <Container>
                                <form>
                                    <Input
                                        name="nome"
                                        type="text"
                                        label="Nome"
                                        value={nome}
                                        id="nome"
                                        onChange={e => this.setState({ nome: e })}
                                    />

                                    {enviar_receita !== "" && (
                                        <Input
                                            label="E-mail"
                                            name="email"
                                            className="input"
                                            id="email"
                                            onChange={e => this.setState({ email: e })}
                                            yesNo={true}
                                            messageYesNo={'enviar receitas por email'}
                                            erro={false}
                                            messageErro={false}
                                            value={email}
                                            yesNoSpanWidth={'80px'}
                                            onChangeYesNo={(e) => this.setState({ enviar_receita: e ? 1 : 0 })}
                                            initialYesNoValue={enviar_receita}
                                        />
                                    )}

                                    <Input
                                        name="cpf"
                                        type="text"
                                        label="CPF"
                                        id="cpf"
                                        value={cpf}
                                        onChange={e => this.changeCpf(e)}
                                        onBlur={e => this.validaCpf(e.target.value)}
                                        erro={erroCpf}
                                        messageErro={erroCpfMsg}
                                    />

                                    <Input
                                        name="whatsapp"
                                        value={whatsapp}
                                        type="text"
                                        label="Whatsapp (opcional)"
                                        id="whatsapp"
                                        onChange={e => this.changeWhatsapp(e)}
                                        inputmode="numeric"
                                    />

                                    <div className="radios">
                                        <input type="radio" id="m" name="fav_language" value="M" onChange={(e) => this.tipoAssinatura(e.target.value)}></input>
                                        <label for="m">Assinatura Manual</label>
                                        <input type="radio" id="d" name="fav_language" value="D" onChange={(e) => this.tipoAssinatura(e.target.value)}></input>
                                        <label for="d">Assinatura Digital</label>
                                        <input type="radio" id="e" name="fav_language" value="E" onChange={(e) => this.tipoAssinatura(e.target.value)}></input>
                                        <label for="e">Assinatura Eletrônica</label>
                                    </div>

                                    {this.state.digital ? (
                                        <>
                                            <Assinaturas>Assinatura Digital é aquela que precisa de um certificado A1 válido. Aceita nos estados MT, PR, SC, RS, GO, MG, ES, SP e MS.</Assinaturas>
                                            <div class='input-wrapper'>
                                                <label style={{
                                                    paddingLeft: '15px',
                                                    textAlign: 'left',
                                                    fontSize: '12px',
                                                    fontWeight: 700,
                                                    color: '#8a92a2',
                                                }}>Upload certificado digital</label>
                                                <div className="arquivos">
                                                    <label
                                                        className='btn-update'
                                                        for='input-file'
                                                        multiple
                                                        onClick={() => { this.setState({ renderInput: true }) }}
                                                    >
                                                        <IconUploads width="auto" height="40px" marginBottom="100px" display="flex" alignItems="center" justifyContent="center" />
                                                        Selecione o arquivo PFX em seu computador.<br></br><br></br>O upload do certificado é necessário caso deseje assinar sua receita digitalmente.
                                                        <label className="arq-name">
                                                            {namesfiles.length > 0 ?
                                                                (<>
                                                                    {namesfiles}
                                                                    <img src={mais} width="20" height="20" onClick={() => this.deleteFile()} />
                                                                </>) : "Nenhum arquivo selecionado"} </label>
                                                    </label>
                                                </div>


                                                {this.state.renderInput ? (
                                                    <input
                                                        style={{ display: "none" }}
                                                        type="file"
                                                        id="input-file"
                                                        name="file"
                                                        multiple
                                                        onChange={(e) => this.onChangeCertificate(e)}
                                                    />
                                                ) : (null)}
                                            </div>
                                        </>


                                    ) : this.state.eletronica ? (
                                        <>
                                            <Assinaturas>Assinatura Eletrônica é aquela que utiliza um conjunto de dados digitais para validação. Aceita em todos os estados brasileiros, com exceção de MT, PR, SC e RS.</Assinaturas>
                                            <InputSign sign={assinatura} update={(e) => this.editSign(e)} />
                                        </>

                                    ) : this.state.manual ? (
                                        <>
                                            <Assinaturas>Assinatura Manual é aquela onde você assina à próprio punho. Aceita em todos os estados brasileiros.</Assinaturas>
                                        </>

                                    ) : (
                                        null
                                    )}
                            
                                    <div id="a" style={{
                                        height: '0px',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center',
                                        marginTop: '40px',
                                    }}>
                                        <div className="voltar" style={{ width: '45%' }}>
                                            <Button
                                                width='100%'
                                                height='50px'
                                                value='VOLTAR'
                                                outline={true}
                                                disabled={false}
                                                onClick={() => this.back()}
                                                loading={false}
                                            />

                                        </div>
                                        <div className="cadastrar" style={{ width: '50%' }}>
                                            <Button
                                                width='100%'
                                                height='50px'
                                                value='SALVAR'
                                                outline={false}
                                                disabled={false}
                                                onClick={() => this.editPost()}
                                                loading={this.state.loading}
                                            />
                                        </div>
                                    </div>

                                    <div className="button-add" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                        <Button
                                            width='100%'
                                            height='50px'
                                            value='CADASTRAR CREA/CFTA'
                                            outline={false}
                                            disabled={false}
                                            onClick={() => this.add()}
                                            loading={false}
                                        />
                                    </div>

                                </form>

                                {
                                    this.props.isMobile ? (
                                        <CardGeral
                                            titulos={ ["CREA","NÚMERO","TIPO", "ESTADO", "TIPO DE ASSINATURA"]}
                                            valores={creas}
                                        />
                                    ) : (

                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Número</th>
                                                    <th>Tipo</th>
                                                    <th>Estado</th>
                                                    <th>Tipo de assinatura</th>
                                                </tr>
                                            </thead>
                                            <TBody>
                                                {creas.map(crea => (
                                                    <tr
                                                        onClick={e =>
                                                            this.props.history.push(`/creas/${crea.id}`)
                                                        }
                                                        key={crea.id}
                                                    >
                                                        {crea.status == 0 ? (
                                                            <>
                                                                <td><strike>{crea.crea}</strike></td>
                                                                <td><strike>{crea.tipo}</strike></td>
                                                                <td><div className="estado-inativo">{crea.estado}</div></td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td>{crea.crea}</td>
                                                                <td>{crea.tipo}</td>
                                                                <td><div className="estado">{crea.estado}</div></td>
                                                                <td>{crea.assinatura === 'M' ? 'Manual' : crea.assinatura === 'D' ? 'Digital' : crea.assinatura === 'E' && 'Eletrônica'}</td>
                                                            </>
                                                        )}
                                                    </tr>
                                                ))}
                                            </TBody>
                                        </Table>
                                    )
                                }

                                <ModalPassword
                                    text={"Precisamos da sua senha para validar a autenticidade do certificado. Para sua segurança, essa senha não ficará salva em nossos servidores."}
                                    show={this.state.showModal}
                                    update={(e) => this.updatePassword(e)}
                                    onHide={this.closeModalPassword}
                                />
                                <ModalText
                                    show={this.state.showModalText}
                                    onHide={this.modalTextClose}
                                    text={"Certificado digital é um arquivo eletrônico que serve como identidade virtual para uma pessoa física ou jurídica. Ele serve para agilizar a assinatura conferindo legitimidade aos documentos digitais, que passam a possuir validade jurídica. Esse certificado deve ser emitido por uma Autoridade Certificadora vinculada ao ICP-Brasil"}
                                    btnOk={false}
                                />


                                <ModalAlert
                                    show={this.state.showModalBlock}
                                    onHide={false}
                                >
                                    <h4 style={{
                                        textAlign: 'center'
                                    }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

                                    <p style={{
                                        textAlign: 'center',
                                        marginTop: '20px',
                                    }}>
                                        Para não comprometer o controle de suas receitas agronômicas essa função só pode ser utilizada nos planos Light e Pro. Entre em contato com nosso suporte e faça o upgrade do seu plano para desbloquear esse recurso.
                                    </p>

                                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                                        <Button
                                            width='296px'
                                            height='50px'
                                            value='ENTENDI'
                                            outline={false}
                                            disabled={false}
                                            onClick={() => this.setState({ showModalBlock: false })}
                                            loading={false}
                                        />
                                    </div>
                                </ModalAlert>

                            </Container>
                        ) : (
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <DotsLoader show={true} />
                            </div>
                        )}

                    </AgronomosAddContainer>
                </>) : (<>
                    <GenericUpper
                        title="Cadastrar CREA/Visto/CFTA"
                    ></GenericUpper>

                    <AddCrea
                        agronomoId={this.props.match.params.id}
                    >

                    </AddCrea>

                </>)}

            </>
        );
    }
}

export default EditAgronomos;
