import styled from "styled-components";

export const TableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #FFF;
  thead {
    tr {
      th {
        text-align: left;
        height: 55px;
        padding: 0 0 0 25px;
        cursor: pointer;
        font-family: Lato;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #8a92a2;
        &:hover {
          color: #599bff;
        }
        border-bottom: solid 1px #f3f3f5;
      }
    }
  }

  @media only screen and (max-width: 768px){
    width: 100%;
  }
`;

export const SimpleTableContainer = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  background-color: #FFF;
 
  tr{
    th{
      padding: 8px;
      background-color: #cacaca;
      border: 1px #ddd solid;
    }
  }
`;
