import React from 'react'
import { ModalArea, ModalCard, ModalHeader } from './styles';
import Close from '../../assets/icons/close.svg';

const Modal = ({ ...props }) => {

    const closeModal = () => {
        if (props.onHide) {
            props.onHide();
        }
    }

    const clickModalCard = (e) => {
        e.stopPropagation();
    }



    return (
        <>
            {props.show && (
                <>
                    <ModalArea
                        {...props}
                        onClick={() => closeModal()}
                    >
                        <ModalCard
                            {...props}
                            onClick={(e) => clickModalCard(e)}
                        >
                            <ModalHeader
                                {...props}
                            >
                                {props.onHide && (
                                    <img src={Close} onClick={() => closeModal()}/>
                                )}
                            </ModalHeader>

                            {props.children}
                        </ModalCard>
                    </ModalArea>
                </>
            )}
        </>
    )
}

export default Modal;
