import React, {useEffect, useState} from 'react';
import { NewsCardCrm } from "./style";
import { Link } from "react-router-dom";
import Crm from "../../assets/crm.svg";
import Button from '../button';

const NewsCrm = ({ ...props }) => {
    
    return (
        <NewsCardCrm>
            <div className="row-news">
                <div className='news-card' style={{
                    width: '100%',

                }}>
                    <Link className="add" to={`${props.href1}`}>
                        <div className="image">
                        <img src={Crm} style={{width: '50%', borderTopLeftRadius: "20px", borderTopRightRadius: "20px"}}/>
                        </div>
                    </Link>

                    <div className="title" style={{marginTop: "-14px", marginBottom:"-12px"}}>
                        <h3> {props.title1} </h3>
                    </div>
                    <div className="text">
                        <label>{props.text}</label>
                    </div>
                    <div className="data">
                    </div>
                    <div className="btn-area">
                        <Link className="add" to={`${props.href1}`}>
                            <Button
                                className="btn-right"
                                color="#00b43f"
                                height="30px"
                                fontSize="10px"
                                outline="0"
                                padding="8px"
                            >
                                CADASTRAR OPORTUNIDADE
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </NewsCardCrm>

    );
};

export default NewsCrm;