import React, { useState, useEffect } from "react";
import Input from "../../input/input_v2";
import Select from "../../select/select_v2";
import Button from "../../button/button_v2";
import { store } from "react-notifications-component";
import api from "../../../services/api";

import validadorDeCpf from '../../../services/validadorCpf';
import buscaCep from "../../../services/buscaCep";
import { Form } from "../../../styles/global";


const AddProdutor = ({ ...props }) => {
    const { update, next, estado, nome, cpf_cnpj, telefone, celular, cep, logradouro, bairro, numero, complemento, cidade, produtor, email} = props;

    const [estadoId, setEstadoId] = useState(estado || "");
    const [nomeId, setNomeId] = useState(nome);
    const [cpfCnpj, setCpfCnpj] = useState(cpf_cnpj);
    const [telefoneId, setTelefoneId] = useState(telefone);
    const [celularId, setCelularId] = useState(celular);
    const [cepId, setCepId] = useState(cep);
    const [logradouroId, setLogradouroId] = useState(logradouro);
    const [bairroId, setBairroId] = useState(bairro);
    const [numeroId, setNumeroId] = useState(numero);
    const [complementoId, setComplementoId] = useState(complemento);
    const [cidadeId, setCidadeId] = useState(cidade);
    const [disabled, setDisabled] = useState(true);
    const [disabledEstado, setDisabledEstado] = useState(false);
    const [disabledCidade, setDisabledCidade] = useState(false);
    const [produtorId, setProdutorId] = useState(produtor);
    const [emailProdutor, setEmail] = useState(email);
    const [sendEmail, setSendEmail] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const [a, setA] = useState(true);

    const estados = [
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ];

    const [estadoCep, setEstadoCep] = useState('');

    const [isCpfValido, setIsCpfValido] = useState(false);


    const [loading, setLoading] = useState(false);


    const [erroNome, setErroNome] = useState(false);
    const [erroNomeMsg, setErroNomeMsg] = useState('');

    const [erroCpfCnpj, setErroCpfCnpj] = useState(false);
    const [erroCpfCnpjMsg, setErroCpfCnpjMsg] = useState('');

    const [erroTelefone, setErroTelefone] = useState(false);
    const [erroTelefoneMsg, setErroTelefoneMsg] = useState('');
    
    const [erroCep, setErroCep] = useState(false);
    const [erroCepMsg, setErroCepMsg] = useState('');

    const [erroLogradouro, setErroLogradouro] = useState(false);
    const [erroLogradouroMsg, setErroLogradouroMsg] = useState('');

    const [erroBairro, setErroBairro] = useState(false);
    const [erroBairroMsg, setErroBairroMsg] = useState('');

    const [erroNumero, setErroNumero] = useState(false);
    const [erroNumeroMsg, setErroNumeroMsg] = useState('');
    
    const [erroCidade, setErroCidade] = useState(false);
    const [erroCidadeMsg, setErroCidadeMsg] = useState('');
    
    const [erroEstado, setErroEstado] = useState(false);
    const [erroEstadoMsg, setErroEstadoMsg] = useState('');

    const [loadingCep, setLoadingCep] = useState(false);    

    const saveProducer = async e => {

        setDisabled(false);

        setLoading(true);

        const payload = {
            estado: estadoId,
            nome: nomeId,
            cpf_cnpj: isCpfValido ? cpfCnpj : '',
            telefone: telefoneId,
            celular: celularId,
            cep: cepId,
            logradouro: logradouroId,
            bairro: bairroId,
            numero: numeroId,
            complemento: complementoId,
            cidade: cidadeId,
            email: emailProdutor,
            enviar_receita: sendEmail ? 1 : 0,
        };

        try {

            const response = await api.post(`/produtores`, payload);
            setProdutorId(response.data.data.id);
            setAddModalShow(true);
            setA(false);
            setDisabled(true);
            store.addNotification({
                title: "Sucesso!",
                message: `O produtor foi salvo!`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });

            const produtorID = response.data.data.id 
            
            update(estadoId, nomeId, cpfCnpj, telefoneId, celularId, cepId, logradouroId, bairroId, numeroId, complementoId, cidadeId, produtorID, emailProdutor);
            setLoading(false);
            next();

        } catch (err) {
            setLoading(false);

            const ERRO = err.response.data.errors;
            try {

                setErroNome(false);
                setErroCpfCnpj(false);
                setErroTelefone(false);
                setErroCep(false);
                setErroLogradouro(false);
                setErroBairro(false);
                setErroNumero(false);
                setErroCidade(false);
                setErroEstado(false);
    
                const errors = err.response.data.errors;
                
                for (let field in errors) {
                    if (field === 'nome') {
                        setErroNome(true);
                        setErroNomeMsg('O campo não pode estar em branco');
                        
                    } else if (field === 'cpf_cnpj') {
                        setErroCpfCnpj(true);

                        if (cpfCnpj === '') {
                            setErroCpfCnpjMsg('O campo não pode estar em branco');
                        } else {
                            setErroCpfCnpjMsg('Informe um CPF/CNPJ válido!');
                        }

                        
                    } else if (field === 'telefone') {
                        setErroTelefone(true);
                        setErroTelefoneMsg('O campo não pode estar em branco');
                        
                        
                    } else if (field === 'cep') {
                        setErroCep(true);
                        setErroCepMsg('O campo não pode estar em branco');

                        
                    } else if (field === 'logradouro') {
                        setErroLogradouro(true);
                        setErroLogradouroMsg('O campo não pode estar em branco');

                        
                    } else if (field === 'bairro') {
                        setErroBairro(true);
                        setErroBairroMsg('O campo não pode estar em branco');

                        
                    } else if (field === 'numero') {
                        setErroNumero(true);
                        setErroNumeroMsg('O campo não pode estar em branco');

                        
                    } else if (field === 'cidade') {
                        setErroCidade(true);
                        setErroCidadeMsg('O campo não pode estar em branco');

                        
                    } else if (field === 'estado') {
                        setErroEstado(true);
                        setErroEstadoMsg('O campo não pode estar em branco');

                    }
                }
            
                store.addNotification({
                    title: "Erro!",
                    message: "Verifique os campos em vermelho",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }catch(err) {
                store.addNotification({
                    title: "Erro!",
                    message: `${ERRO}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }
        

    const fetchCep = async () => {
        try {
            
            setLoadingCep(true);

            const cep = await buscaCep(cepId);

            const {logradouro, bairro, localidade, uf} = cep;
            
            setLogradouroId(logradouro);
            setBairroId(bairro);
            setCidadeId(localidade);
            setEstadoId(uf);
            setEstadoCep(uf);

            
            setErroCep(false);
            setErroLogradouro(false);
            setErroBairro(false);
            setErroNumero(false);
            setErroCidade(false);
            setErroEstado(false);
            setLoadingCep(false);
            
        } catch (err) {

            setLoadingCep(false);
            setLogradouroId("");
            setBairroId("");
            setCidadeId("");
            setErroCep(true);
            setErroCepMsg(err.message);

            
            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    const cpfIsValid = (value) => {

        if (value.length <= 14) {
            const CpfValido = validadorDeCpf(value);
    
            setErroCpfCnpj(!CpfValido);
            setErroCpfCnpjMsg('Informe um CPF válido')
            setIsCpfValido(CpfValido);

            if (!CpfValido) {
                store.addNotification({
                    title: "Erro!",
                    message: "Informe um CPF válido.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
            setIsCpfValido(true);
            setErroCpfCnpj(false);
        }

    }

    const validateNome = nomeId => {
        setNomeId(nomeId);
        setErroNome(false);
                
    }
    const validateCpf = (cpfCnpj) => {
        setCpfCnpj(cpfCnpj);
        setErroCpfCnpj(false);
                
    }
    const validateTelefone = telefoneId => {
        setTelefoneId(telefoneId);
        setErroTelefone(false);
                
    }
    const validateCelular = celularId => {
        setCelularId(celularId);
    }
    const validateCep = cepId => {
        setCepId(cepId);
        setErroCep(false);
                
    }
    const validateLogradouro = logradouroId => {
        setLogradouroId(logradouroId);
        setErroLogradouro(false);
                
    }
    const validateBairro = bairroId => {
        setBairroId(bairroId);
        setErroBairro(false);
                
    }
    const validateNumero = numeroId => {
        setNumeroId(numeroId);
        setErroNumero(false);
                
    }
    const validateComplemento = complementoId => {
        setComplementoId(complementoId);
    }
    const validateCidade = cidadeId => {
        setCidadeId(cidadeId);
        setErroCidade(false);
                
    }
    const validateproduto = produtorId => {
        setProdutorId(produtorId);
    }

    const validateEstado = uf => {
        if (estadoId != uf) {
            setEstadoId(uf);
        }
    }

    useEffect(() => {

        if (props.isModal != true) {
            window.scrollTo(0, 0);
        }
    },[]);

    useEffect(() => { validateproduto(produtorId) }, [produtorId])



    useEffect(() => {
        if (estadoId && cidadeId) {
            setDisabledCidade(true);
            setDisabledEstado(true);
        } else {
            setDisabledCidade(false);
            setDisabledEstado(false);
        }

        if (erroEstado === true && estadoId) {
            setErroEstado(false);
        }
    }, [estadoId, cidadeId])

    useEffect(() => {
        if (props.crm === true) {
            setNomeId(props.nomeProdutorCrm);
            setEmail(props.emailProdutorCrm);
            setTelefoneId(props.telefoneProdutorCrm);
            setCelularId(props.celularProdutorCrm);
        }
    }, [props])


    return (<>
        <Form
            step={1}
            isModal={props.isModal}
            isFocus={props.isFocus}
        >
            <Input
                label="Nome"
                name="nome"
                className="input"
                id="nome"
                onChange={e => validateNome(e)}
                maxlength={50}
                erro={erroNome}
                messageErro={erroNomeMsg}
                value={nomeId}
            />

            <Input
                label="E-mail"
                name="email"
                className="input"
                id="email"
                onChange={e => setEmail(e)}
                yesNo={true}
                messageYesNo={'enviar receitas por email'}
                yesNoSpanWidth="75px"
                onChangeYesNo={(e) =>setSendEmail(e)} 
                erro={false}
                messageErro={false}
                value={emailProdutor}
            />

            <Input
                label="CPF/CNPJ"
                name="cpfcnpj"
                className="input"
                id="cpf_cnpj"
                value={cpfCnpj}
                onChange={e => validateCpf(e)}
                onBlur={(e) => cpfIsValid(e.target.value)}
                maxLength={18}
                erro={erroCpfCnpj}
                messageErro={erroCpfCnpjMsg}
                inputmode="numeric"
            />

            <Input
                label="Telefone"
                name="telefone"
                className="input"
                id="telefone"
                onChange={e => validateTelefone(e)}
                value={telefoneId}
                erro={erroTelefone}
                messageErro={erroTelefoneMsg}
                inputmode="numeric"
                // maxLength={13}
            />
            <Input
                label="Celular (Opcional)" 
                name="celular"
                className="input"
                id="celular"
                value={celularId}
                onChange={e => validateCelular(e)}
                inputmode="numeric"
            />
            <Input
                label="CEP"
                name="cep"
                className="input"
                id="cep"
                onChange={e => validateCep(e)}
                onBlur={fetchCep}
                erro={erroCep}
                messageErro={erroCepMsg}
                value={cepId}
                disabled={loadingCep}
                loading={loadingCep}
                inputmode="numeric"
            />

            <Input
                label="Logradouro"
                value={logradouroId}
                name="logradouro"
                className="input"
                id="logradouro"
                onChange={e => validateLogradouro(e)}
                erro={erroLogradouro}
                messageErro={erroLogradouroMsg}
                disabled={loadingCep}
                loading={loadingCep}
            />
            <Input
                label="Bairro"
                value={bairroId}
                name="bairro"
                className="input"
                id="bairro"
                onChange={e => validateBairro(e)}
                erro={erroBairro}
                messageErro={erroBairroMsg}
                disabled={loadingCep}
                loading={loadingCep}
            />

            <Input
                label="Número"
                name="numero"
                className="input"
                id="numero"
                onChange={e => validateNumero(e)}
                erro={erroNumero}
                messageErro={erroNumeroMsg}
                value={numeroId}
            />
            <Input
                label="Complemento (Opcional)"
                name="complemento"
                className="input"
                id="complemento"
                onChange={e => validateComplemento(e)}
                value={complementoId}
                
            />
            <Input
                value={cidadeId}
                label="Cidade"
                name="cidade"
                className="input"
                id="cidade"
                onChange={e => validateCidade(e)}
                disabled={disabledCidade}
                erro={erroCidade}
                messageErro={erroCidadeMsg}
                loading={loadingCep}
            />

            <Select
                label="Estado"
                name="estado"
                className="select"
                id="estado_id"
                onChange={(e) =>  e.value ? validateEstado(e.value) : validateEstado(e)}
                options={estados}
                value={estadoId ? estadoId.value ? estadoId.value : estadoId : ''}
                disabled={disabledEstado || loadingCep}
                loading={loadingCep}
                estado={true}
                erro={erroEstado}
                messageErro={erroEstadoMsg}
            />

            <div 
                className="button-finish" 
                style={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'space-evenly',
                    alignItems: 'center', 
                    marginTop: '40px',
                    marginBottom: props.isModal ? '0px' : '40px',
                }}
            >
                <Button 
                    width='100%'
                    height='50px'
                    value='CADASTRAR'
                    outline={false}
                    disabled={false}
                    onClick={() => saveProducer()}
                    loading={loading}
                />
            </div>
        </Form>
    </>)
}

export default AddProdutor;
