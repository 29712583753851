import React, { useEffect, useState } from 'react';
import { formatDateYMD } from '../../services/formatDate';
import { apiprec, apisat } from '../../services/inmet_api';
import { Loading, NextPage, PrevPage } from './style';

const PrecipitacaoMapa = ({ ...props }) => {

    const [images, setImages] = useState('');
    const [totalImages, setTotalImages] = useState(0);
    const [showImage, setShowImage] = useState(0);
    const [loading, setLoading] = useState(false);

    const getMapaPrecipitacao = async () => {
        let date = new Date();

        date = formatDateYMD(date);
        try {
            setLoading(true)
            const response = await apiprec.get(`${date}`);

            if (response.data.length > 0) {
                setLoading(false)
                setTotalImages(response.data.length);

                setImages(response.data);
                setShowImage(0);
            } else {


                var d = new Date(date),
                    month = '' + (d.getUTCMonth() + 1),
                    day = '' + (d.getUTCDate() - 1),
                    year = d.getUTCFullYear();

                if (month.length < 2) {
                    month = '0' + month;
                } else if (day.length < 2) {
                    day = '0' + day;
                }

                date = [year, month, day].join('-');

                const response = await apiprec.get(`${date}`);
                setTotalImages(response.data.length);

                setImages(response.data);
                setShowImage(0);
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const prev = () => {
        if (showImage > 0) {
            setShowImage(showImage - 1)
        }
    }

    const next = () => {
        if (showImage < (totalImages - 1)) {
            setShowImage(showImage + 1)
        }
    }

    useEffect(() => {
        // getMapa();
        getMapaPrecipitacao();
    }, []);

    useEffect(() => {
        if (totalImages > 0) {
        }
    }, [showImage, totalImages])



    return (
        <>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {images !== '' ? (
                        <>
                            <div
                                className='tarja'
                                style={{
                                    backgroundColor: '#FFF',
                                    width: '70%',
                                    height: '17px',
                                    position: 'absolute'
                                }}
                            >

                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-evenly',
                                alignItems: 'center'
                            }}>
                                <PrevPage onClick={() => prev()}> <p> {'<'} </p> </PrevPage>
                                <img style={{ width: '60%' }} src={images[showImage].base64} />
                                <NextPage onClick={() => next()}> <p> {'>'} </p> </NextPage>
                            </div>
                        </>
                    ) : (<><p style={{ width: "100%", height: "100%", paddingTop: "150px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: "12px", color: "#8A92A2", textAlign: "center" }}>Não foi possível carregar o mapa de precipitação. Aguarde alguns minutos e tente recarregar a página.</p></>)}
                </>
            )}
        </>
    )
}

export default PrecipitacaoMapa;