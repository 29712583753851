import React from 'react';

const IconInfo = ({ ...props }) => {


    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 50 50" 
            width="12px" 
            height="12px"
        >
            <g id="surface68439555">
                <path 
                    style={{
                        stroke: "none",
                        fillRule: "nonzero",
                        fill: "rgb(12.941177%,14.901961%,18.431373%)",
                        fillOpacity: 1
                    }}
                    d="M 25 2 C 12.296875 2 2 12.296875 2 25 C 2 37.703125 12.296875 48 25 48 C 37.703125 48 48 37.703125 48 25 C 48 12.296875 37.703125 2 25 2 Z M 25 11 C 26.65625 11 28 12.34375 28 14 C 28 15.65625 26.65625 17 25 17 C 23.34375 17 22 15.65625 22 14 C 22 12.34375 23.34375 11 25 11 Z M 29 38 L 21 38 L 21 36 L 23 36 L 23 23 L 21 23 L 21 21 L 27 21 L 27 36 L 29 36 Z M 29 38 "
                />
            </g>
        </svg>
    )
}

export default IconInfo
