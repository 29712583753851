import React from "react";
import { ContainerStyles, LabelStyles, Row } from "./style";

const ProgressBar = (props) => {
    const { bgcolor, completed, isMobile } = props;
  
    return (
      <Row>
        <div style={{
          width: '100%'
        }}>
          <ContainerStyles isMobile={isMobile} completed={completed}>
            <div className='fillerStyles'></div>
          </ContainerStyles>
        </div>
        
        <LabelStyles isMobile={isMobile} completed={completed}>  
          {`${completed}%`}
        </LabelStyles>
      </Row>
    );
  };
  
  export default ProgressBar;