import styled from "styled-components";

export const FormContainer = styled.div`
  width: 430px;
  padding: 20px 0px 20px;
  margin: auto;
  background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: auto;
    margin: auto;
    margin-top: 20px;

    .radios{
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      label{
        width: 100%;
        padding-left: 5px;
        font-size: 11px;
        font-family: Lato;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        -webkit-letter-spacing: normal;
        -moz-letter-spacing: normal;
        -ms-letter-spacing: normal;
        letter-spacing: normal;
        color: #586377;
        display: flex;
        align-items: center;
        margin-bottom: 0px;
      }

      input[type='radio'] {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #2FBB69;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }
      input[type='radio']:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }

      input[type='radio']:checked:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: #2FBB69;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
      }

    }

    .input-wrapper{
      width: 90%;
    }

    .bordas{
      width: 100%;
      margin-bottom: 20px;
      padding: 20px;
      border: solid 1px #c5cbd6;
      border-radius: 8px;
    }

    .arquivos{
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      label{
        font-size: smaller;
        width: 100%;
        margin-left: 5px;
        text-align: center;
        color: #8a92a2;
      }
      .btn-update{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        border: dashed 1px #8a92a2;
        border-radius: 8px;
      }
      .arq-name{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          transform: rotate(45deg);
          z-index: 10;
        }

        img:hover{
          cursor: pointer;
        }
      }
    }
    .legenda {
      font-size: smaller;
      width: 100%;
      margin-left: 5px;
      text-align: center;
    }

  }
  
  .button-finish {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }

  #back {
    width: 45% !important;
  }
  #save {
    width: 45% !important;
  }

  @media only screen and (max-width: 991px) {
    form {
      .arquivos{
        
        .btn-update{
          font-size: ${props => props.isModal ? '10px' : '12px'};
        }
        
        .arq-name{
          font-size: ${props => props.isModal ? '9px' : '12px'};
        }
      }
    }
  }

  @media only screen and (max-width: 780px) {
    width: 90%;
    .button-finish {
        height: ${props => props.isModal ? '50px' : '130px'};
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
  }
  
  @media only screen and (max-width: 768px) {
    width: 80%;
    .button-finish {
        height: ${props => props.isModal ? '50px' : '130px'};
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
    }
  }



  @media only screen and (max-width: 505px) {
    form {
      width: 100%;
    }

    #back {
      width: 90% !important;
    }
    #save {
      width: 90% !important;
    }
  }


  @media only screen and (max-width: 415px) {
    width: 100%;

    .button-finish {
      height: ${props => props.isModal ? '50px' : '130px'};
      justify-content: space-around;
      flex-direction: column;
    }
    
    #back {
      width: 90% !important;
    }
    #save {
      width: 90% !important;
    }

    form {
      width: 100%;
    }
  }

  @media only screen and (max-width: 375px) {
    form {
      .arquivos{
        
        .btn-update{
          font-size: ${props => props.isModal ? '10px' : '12px'};
        }
        
        .arq-name{
          font-size: ${props => props.isModal ? '9px' : '12px'};
        }
      }
    }
  }
  
  @media only screen and (max-width: 320px) {
    form {
      .arquivos{
        
        .btn-update{
          font-size: 10px;
        }
        
        .arq-name{
          font-size: ${props => props.isModal ? '9px' : '10px'};
        }
      }
    }
  }
`;


export const FormButtonContainer = styled.div`
background: #f8f8f9;
display: flex;
align-items: center;
margin-top: 20px;

background: #f8f8f9;

#a{
  width: 400px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

}

@media only screen and (max-width: 768px) {
  background: #f8f8f9;
  display: flex;
  align-items: center;
  
  background: #f8f8f9;
  
  #a{
    width: 293px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column-reverse;
    
    .voltar{
      width: 100%;
    }
    .cadastrar{
      width: 100%;

    }

    a{
      width: 100%;
      max-width: 400px;
      
    }

    button{
      width: 100%;
      max-width: 400px;
      padding: 16px 10px;
    }
  }
}
`;

export const Label = styled.label`
  width: 100%;
  height: 3px;

  background-color: #FFF;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin-bottom: 0px;
  margin-left: 25px;
  margin-top: -10px;

  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  color: #8a92a2;
  opacity: 1;
  z-index: 1;
  
  transition: all 0.3s;
  border-radius: 65px;

  &:hover {
    cursor: context-menu;
  }
`;

export const Assinaturas = styled.p`
  text-align: center;
  width: 100%;
  max-width: 400px;
  font-size: 11px;
  margin-top: -24px;
  margin-bottom: 14px;
`;
