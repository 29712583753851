import React from "react";

import GenericUpper from "../../components/generic-upper";
import api from "../../services/api";
import { Container } from "react-grid-system";
import { DevolucaoContainer } from "./styles";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import Button from "../../components/button/button_v2";
import ButtonOld from "../../components/button/index";
import DotsLoader from "../../components/loader";
import InputSearch from "../../components/input-search";
import Importacao from "../../components/modal/modalImportacao";

import CardGeral from "../../components/card-geral/index";
import Paginator from "../../components/paginator";

class Devolucao  extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        devolucao: [],
        limit: 30,
        offSet: 0,
        totalDevolucoes: 0,
        loading: false,
        loadingMore: false,
        hideButton: false,
        result: "",
        result2: "",
        size_screen: window.screen.width,
        importar: false,
        loadingSearch: false
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        setInterval(async()=>{
            this.setState({size_screen: window.screen.width});
        }, 990);
        this.search('', '', 30, 0);
    }

    updateResposta = async (resposta) => {
        console.log(resposta);
        if (resposta == 0) {
            this.setState({importar: false})
        }
    }

    updateValues = async (text, estado, search) => {
        if (search == 1) {
            
            if(!text && !estado){
                this.search('', '', 30, 0);
            }else{   
                this.setState({ result2: estado });
                this.setState({ result: text });
                this.search(text, estado, 30, 0);
            }
                
        }else{
            this.setState({result: text});
            this.setState({result2: estado});
        }
    }

    search = async (result, result2, limit, offset) => {
        try {
            this.setState({loadingSearch: true});
            
            const search = result;
            const estado = result2;


            const payload = {
                search,
                estado
            }

            const response = await api.post(`/devolucoes/pesquisa?limit=${limit}&offset=${offset}`, payload);

            this.setState({ 
                devolucao: response.data.data.devolucoes,
                totalDevolucoes: response.data.data.total, 
                loading: false
            });
            
            
            this.setState({loadingSearch: false});
        } catch (error) {
            this.setState({loadingSearch: false});
        }
        
    };

    getDevolucao = async () => {
        try {
            const { limit } = this.state;
            const response = await api.get("/devolucoes?limit=" + limit);
            const devolucao = response.data.data;
            if(devolucao && devolucao.length > 0){
                this.setState({ devolucao, loadingSearch: false, hideButton: false });
            }else{
                this.setState({ loadingSearch: false, hideButton: false });
            }
        } catch (error) {
            this.setState({ loadingSearch: false, hideButton: true });
        }
    };

    cleanFilter = () => {
        this.setState({
            result: "",
            result2: "",
            offSet: 0
        });
        this.search('', '', 30, 0);
    };

    getXlms = async () => {
        this.setState({ loading: true });
        try {
            const response = await api.get("/relatorios/devolucoes", {
                responseType: "blob"
            });
    
            let blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "RelatorioLocaisdeDevolução.xlsx";
            link.href = url;
            link.click();
            this.setState({ loading: false });
            
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    loadMore = async () => {
        this.setState({ loadingMore: true, hideButton: true });
        const { devolucao, limit, offSet } = this.state;
        const newOffset = limit + offSet;

        try {
            const response = await api.get(
                "/devolucoes?limit=" + limit + "&offset=" + newOffset
            );
            let newdevolucao = response.data.data;
            const devolucaoConCat = devolucao.concat(newdevolucao);
            this.setState({
                devolucao: devolucaoConCat,
                offSet: newOffset,
                hideButton: false
            });
        } catch (erro) {
            this.setState({
                hideButton: true
            });
        }
        this.setState({ loadingMore: false });
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            
            this.search(this.state.result, this.state.result2, 30, 0);
        }
    }

    openAdd = () => {
        this.props.history.push('/devolucoes/add');
    }

    updateLimitOffset = (l,o) => {
        if (l !== this.state.limit || o !== this.state.offset) {
            this.setState({
                limit: l,
                offset: o
            })

            this.search(this.state.result, this.state.result2, l, o);
        }
    }

    render() {
        const { importar, devolucao, loadingSearch, result, size_screen, limit, totalDevolucoes } = this.state;
        return (
            <>
                <GenericUpper
                    title="Locais de Devolução"
                    subtitle="Gerencie seus locais de devolução"
                    search={true}
                    searchFunction={(text, estado, search) => this.updateValues(text, estado, search)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    estados={true}
                    placeholder="Nome, Endereço ou Cidade"
                    addButton={true}
                    tooltip={"Adicionar"}
                    linkSearch = {'/devolucoes/add'}
                ></GenericUpper>

                <DevolucaoContainer>
                    <Container style={size_screen <= 768 ? {paddingLeft:"0px", paddingRight:"0px", margin: 'auto'} : {}}>
                    {size_screen <= 768 ? (<>
                            {devolucao ? (
                                <CardGeral
                                    titulos={ ["DEVOLUCAO","NOME", "ENDEREÇO","CIDADE","ESTADO"]}
                                    valores={devolucao}
                                />
                            ):(null)}
                            
                            <DotsLoader show={loadingSearch}></DotsLoader>
                            
                            <Paginator 
                                perPage={limit} 
                                total={totalDevolucoes} 
                                onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                                totalItens={devolucao.length}
                            />

                            <div 
                                className="button-finish" 
                                style={{ 
                                    width: '100%', 
                                    height: '200px',
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center', 
                                }}
                            >
                                <Button 
                                    width='87%'
                                    height='50px'
                                    value='ADICIONAR LOCAL DE DEVOLUÇÃO'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => this.openAdd()}
                                    loading={false}
                                />
                                <Button 
                                    width='87%'
                                    height='50px'
                                    value='RELATÓRIO'
                                    outline={!this.state.loading}
                                    disabled={false}
                                    onClick={() => this.getXlms()}
                                    loading={this.state.loading}
                                />
                            </div>

                        </>):(<>
                        <Importacao
                            style={{display: "flex", justifyContent: "center"}}
                            show={importar}
                            onHide={e => {this.setState({importar: false})}}
                            step={1}
                            fields={["nome", "responsavel", "telefone", "celular", "cep", "endereco", "numero", "compendio", "bairro", "cidade", "estado"]}
                            resposta=''
                            update={this.updateResposta}
                            title='Importar Locais de Devolução'
                        ></Importacao>
                

                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Endereço</th>
                                    <th>Cidade</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <TBody>
                                {devolucao.map(devoluca => (
                                    <>
                                    {devoluca.status == 0 ? (<>
                                        <tr
                                            onClick={e =>
                                                this.props.history.push(`devolucoes/${devoluca.id}`)
                                            }
                                            key={devoluca.id}
                                        >
                                            <td><strike>{devoluca.nome}</strike></td>
                                            <td><strike>{devoluca.endereco}</strike></td>
                                            <td><strike>{devoluca.cidade}</strike></td>
                                            <td><div className="estado-inativo">{devoluca.estado}</div></td>
                                        </tr>
                                    </>):(<>
                                        <tr
                                            onClick={e =>
                                                this.props.history.push(`devolucoes/${devoluca.id}`)
                                            }
                                            key={devoluca.id}
                                        >
                                            <td>{devoluca.nome}</td>
                                            <td>{devoluca.endereco}</td>
                                            <td>{devoluca.cidade}</td>
                                            <td><div className="estado">{devoluca.estado}</div></td>
                                        </tr>
                                    </>)}
                                    </>
                                ))}
                            </TBody>
                        </Table>

                        <DotsLoader show={loadingSearch}></DotsLoader>

                        <Paginator 
                            perPage={limit} 
                            total={totalDevolucoes} 
                            onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                            totalItens={devolucao.length}
                        />


                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                                marginTop: '40px',
                            }}
                        >
                            <Button 
                                width='25%'
                                height='50px'
                                value='RELATÓRIO'
                                outline={!this.state.loading}
                                disabled={false}
                                onClick={() => this.getXlms()}
                                loading={this.state.loading}
                            />
                        </div>

                        </>)}
                    </Container>
                </DevolucaoContainer>
            </>
        );
    }
}

export default Devolucao;
