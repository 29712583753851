import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
import Button from '../button/index';
import Modal from '.';
import { ModalFooter, ModalTitle } from './styles';
// import 'bootstrap/dist/css/bootstrap.min.css';
// export const USER = "@userinfo";


class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };


    redirect = () => {
        let userInfo = JSON.parse(localStorage.getItem("@userinfo"));

        this.props.onHide();

        window.location.href = `/filiais/${userInfo.parent_id}`;
    }

    close = () => {
      this.props.onHide();

    }



    render(){
        return(
            
          //   <Modal
          //   {...this.props}
          //   size="sm"
          //   aria-labelledby = "contained-modal-title-vcenter"
          //   centered
          // >
          //   <Modal.Header closeButton
          //   style={{backgroundColor: " #f8f8f8"}}>
          //     <Modal.Title id="contained-modal-title-vcenter">
          //       {this.props.title}
          //     </Modal.Title>
          //   </Modal.Header>
          //   <Modal.Body
          //   style={{backgroundColor: " #f8f8f8", textAlign: "center"}}>
          //     {this.props.text}
          //   </Modal.Body>
          //   <Modal.Footer
          //   style={{backgroundColor: " #f8f8f8"}}>
          //     {/* {console.log(this.props.tipoLoja)} */}
          //     {this.props.tipoLoja == 0 ? (
          //       <Button outline style={{width: "100px", padding: "15px", outline:0, margin: "auto"}}
          //                 color="#00b43f"  onClick={this.close}>Ok
          //       </Button>
          //     ):(
          //       <Button outline style={{padding: "15px", outline:0, margin: "auto"}}
          //                 color="#00b43f"  onClick={this.redirect}>Cadastrar telefone
          //       </Button>
          //     )}

                
          //   </Modal.Footer>
          // </Modal>

          <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
            width='50%'
          >
            <ModalTitle> {this.props.title} </ModalTitle>

            <div>
              {this.props.text}
            </div>

            <ModalFooter justifyContent='space-evenly'>
              {this.props.tipoLoja == 0 ? (
                <Button outline style={{width: "100px", padding: "15px", outline:0, margin: "auto"}}
                          color="#00b43f"  onClick={this.close}>Ok
                </Button>
              ):(
                <Button outline style={{padding: "15px", outline:0, margin: "auto"}}
                          color="#00b43f"  onClick={this.redirect}>Cadastrar telefone
                </Button>
              )}
            </ModalFooter>
          </Modal>
          
        );
    }
}
export default ModalNew;