import styled from "styled-components";

export const AreaMapa = styled.div`
    width: 100%;
    height: 300px;
    margin: 0px;
    padding: 0px;

    margin-top: 30px;
    margin-bottom: 30px;

    position: ${props => props.fullScreen ? 'fixed' : 'relative'};
    left: 0;
    top: 0;
`;

export const Mapa = styled.div`
    background-color: gray;
    width: 100%;
    height: 300px;
    margin: 0px;
    padding: 0px;
`;

export const Label = styled.label`
  width: auto;
  height: 3px;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  margin-left: 25px;
  margin-top: ${props => props.isDate ? '-13px' : !props.existValue ? !props.isFocus ? '24px' : '-13px' : '-13px'};
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  position: absolute;
  color: ${props => props.erro ? '#ff5252' : '#8a92a2'};
  opacity: ${props => props.isDisabled ? '0.5' : '1'};
  z-index: 1;
  transition: all 0.3s;
  border-radius: 65px;

  &:hover {
    cursor: ${props => !props.existValue ? !props.isFocus ? !props.isDisabled ? 'text' : 'context-menu' : 'context-menu' : 'context-menu'};
  }


`;
export const Pin = styled.label`
  width: 20px;
  height: 20px;
  border-radius: 45% 45% 45% 0;
  background: #2fbb69;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: ${props => props.fullScreen ? '-8px 0 0 -10px' : '-21px 0 0 -10px'};
  z-index: 2;
  transition: all 0.2s ease-out;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    margin: auto;
    margin-left: 6px;
    margin-top: 4px;
    background: #F1F1F2;
    position: absolute;
    border-radius: 50%;
  }

`;

export const Pulse = styled.label`


  @keyframes pulsate {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      transform: scale(1.2, 1.2);
      opacity: 0;
    }
  }


  background: #082a04;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: ${props => props.fullScreen ? '10px 0 0 -7px' : '-3px 0px 0px -7px'};
  transform: rotateX(55deg);
  z-index: -2;

  &:after {
    content: '';
    border-radius: 50%;
    height: 40px;
    width: 40px;
    position: absolute;
    /* margin: ${props => props.fullScreen ? '10px 0 0 -13px' : '-13px 0 0 -13px'}; */
    margin: -13px 0 0 -13px;
    animation: pulsate 1s ease-out;
    animation-iteration-count: infinite;
    opacity: 0;
    box-shadow: 0 0 1px 2px #2fbb69;
    animation-delay: 1.1s;
  }

`;