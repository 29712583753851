import React from "react";
import { withRouter } from "react-router-dom";

import { 
    Col, 
    Header, 
    LastPrescriptionStyle, 
    Loading, 
    Produto, 
    Row, 
    Status, 
    TitleCard 
} from "./style";
import IconReceita from "../../assets/icons/receita.svg";
import IconRoom from "../../assets/icons/room";
class LastPrescription extends React.Component {

    constructor(props) {
        super(props);
    }

    redirectTo = location => {
        this.props.history.push(location);
    }

    render() {

        return (

            <LastPrescriptionStyle>
                {this.props.loadingPrescription ? (
                    <div style={{
                        width: '100%',
                        height: '283px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        <Loading />
                    </div>
                ) : (
                    <>
                        <Row>
                            <Header>
                                <div>
                                    <h2>ÚLTIMA RECEITA EMITIDA</h2>
                                </div>

                                <div>
                                    <label onClick={e => this.redirectTo("/historico")}>Histórico completo</label> 
                                    <img onClick={e => this.redirectTo("/historico")} src={IconReceita}/>
                                </div>
                            </Header>
                        </Row>

                        <hr/>

                        <Row>
                            <Col>
                                <Row>
                                    <Col width='50%'>
                                        <TitleCard>Produto</TitleCard>
                                        <Produto> {this.props.nomeProduto} </Produto>
                                    </Col>
                                    
                                    <Col width='50%' alignItems='flex-end'>
                                        {this.props.status === 0 ? (
                                            <Status>
                                                <p>CANCELADA</p>
                                            </Status>
                                        ):(null)}
                                    </Col>
                                </Row>
                                
                                
                                <Row>
                                    <Col width='auto' margin='0px'>
                                        <Row widthResponse='50%'>
                                            <Col>
                                                <TitleCard>Praga</TitleCard>
                                                <p> {this.props.nomePraga} </p>
                                            </Col>
                                        </Row>

                                        <Row widthResponse='50%'>
                                            <Col>
                                                <TitleCard>Cultura</TitleCard>
                                                <p> {this.props.nomeCultura} </p>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col width='33%'>
                                        <Row widthResponse='50%'>
                                            <Col>
                                                <TitleCard>Produtor</TitleCard>
                                                <p> {this.props.nomeProdutor} </p>
                                            </Col>
                                        </Row>

                                        <Row widthResponse='50%'>
                                            <Col>
                                                <TitleCard>Local de aplicação</TitleCard>
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'flex-start',
                                                        justifyContent: 'flex-start'
                                                    }}
                                                >
                                                    <IconRoom 
                                                        width='16px'
                                                        height='16px'
                                                        fill='#589bff'
                                                    />
                                                    <p style={{ color: '#589bff'}}> {this.props.local} </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col width='33%' flexDirectrionResp='column'>
                                        <Row widthResponse='100%'>
                                            <Col>
                                                <TitleCard>Responsável técnico</TitleCard>
                                                <p> {this.props.nomeTecnicoResponsavel} </p>
                                            </Col>
                                        </Row>

                                        <Row widthResponse='100%' justifyContent='space-between' flexDirectrionResp='row'>
                                            <Col width='45%' margin='0px'>
                                                <TitleCard>CREA</TitleCard>
                                                <p> {this.props.crea} </p>
                                            </Col>
                                            
                                            <Col width='45%' margin='0px'>
                                                <TitleCard>ART</TitleCard>
                                                <p> {this.props.art} </p>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </>
                )}
            </LastPrescriptionStyle>
        )
    }
}

export default withRouter(LastPrescription);
