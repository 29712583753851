import React, { useState, useEffect } from "react";
import Cancel from "../../assets/cancel";
import IconInfo from "../../assets/icons/iconInfo";
import IconNo from "../../assets/icons/iconNo";
import IconYes from "../../assets/icons/iconYes";
import Button from "../button/button_v2";
import { TableContainer, Line, Card, Pages, Page, Label, Table, Row, Col } from "./styles";

const TablePlans = ({ ...props }) => {

  const [isMobile, setIsMobile] = useState(false);

  const [planos, setPlanos] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [permissoes, setPermissoes] = useState([]);
  const [grid, setGrid] = useState([]);
  const [showPrePago, setShowPrePago] = useState(false);
  const [prepagoLevel, setPrepagoLevel] = useState('');
  const [maiorNivel, setMaiorNivel] = useState('');
  const [utmCompra, setUtmCompra] = useState('');


  const assinar = (planoId) => {
    const cupom = decodeURIComponent(new URLSearchParams(window.location.search).get('cupom'));
    if (cupom && cupom !== '' && cupom !== null && cupom !== 'null') {
      if(utmCompra){
        props.history.push(`/compra?${planoId}&cupom=${cupom}&${utmCompra}`);
      }else{
        props.history.push(`/compra?${planoId}&cupom=${cupom}`);
      }
    }else{
      if(utmCompra){
        props.history.push(`/compra?${planoId}&${utmCompra}`);
      }else{
        props.history.push(`/compra?${planoId}`);
      }
    }
  }

  useEffect(() => {
    const utm = localStorage.getItem("utm_compra")
    setUtmCompra(utm);

  }, [localStorage.getItem("utm_compra")]);

  useEffect(() => {
    setPlanos(props.planos.planos);
    setHeaders(props.planos.funcoes);
    setPermissoes(props.planos.permissoes);
    setGrid(props.planos.grid);
  }, [props.planos]);

  useEffect(() => {
    setIsMobile(props.allProps.isMobile);
  }, [props.allProps.isMobile])

  useEffect(() => {
    if (planos) {
      let nivel = 0;
      planos.map((p) => {
        if (p.is_pre_pago === 1) {
          setPrepagoLevel(p.nivel);
        }
        if (p.nivel > nivel) {
          nivel = p.nivel;
        }
      });

      setMaiorNivel(nivel);
    }
      }, [planos])

  return (
    <TableContainer>
      <>
          {props.userPlanLevel === maiorNivel ? (<>
            <h4
              style={{
                width: '100%',
                textAlign: 'center',
                marginTop: '50px'
              }}
            >
              Não há possibilidade de upgrade
            </h4>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <Cancel />  

            </div>
          </>) : (
            <>
              {
                (window.screen.width > 768 || showPrePago) && (
                  <Pages
                    showPrePago={props.userPlanLevel < prepagoLevel ? true : false}
                  >
                    <Page 
                      isActive={!showPrePago} 
                      onClick={() => setShowPrePago(false)}
                      showPrePago={props.userPlanLevel < prepagoLevel ? true : false}
                    >
                      <Label isActive={!showPrePago}>Assinaturas</Label>
                    </Page>
                    {props.userPlanLevel < prepagoLevel ? (
                      <Page 
                        isActive={showPrePago} 
                        onClick={() => setShowPrePago(true)}
                        showPrePago={props.userPlanLevel < prepagoLevel ? true : false}
                      >
                        <Label isActive={showPrePago}>Pré-pago</Label>
                      </Page>
                    ) : (null)}
                  </Pages>
                )
              }
    
              {showPrePago ? (
                <div className='body-plan'>
                  <Table>
                    <tbody>
                      {headers.map((h, i) => (
                        <Line index={0}>
                          {i === 0 ? (
                              <>
                                <td id='info' aria-describedby="tooltip">{h.descricao} <span><IconInfo /> <p>Preenchimento automático de dados; Cálculo de área a tratar ou quantidade a adquirir; Frases de Modalidade e Época de Aplicação.</p></span></td>
                              </>
                            ) : (
                              <>
                                {i === 5 ? (
                                  <>
                                    <td id='info' aria-describedby="tooltip">{h.descricao} <span><IconInfo /><p style={{ marginLeft: '95px' }}>De acordo com as exigências regulatórias de cada estado</p></span></td>
                                  </>
                                ) : (
                                  <td>{h.descricao}</td>
                                )}
                              </>
                            )
                          }
          
                          {planos.map((p) => (
                            <>
                              {p.nivel > props.userPlanLevel ? (
                                <>
                                  {p.is_pre_pago === 0 ? (
                                    null
                                  ) : (
                                    <>
                                      {permissoes.map((per, i) => (
                                        <>
                                    
                                          {per.agro_plans_function.id === h.id && per.plan.id === p.id ? (
                                            <>
                                              {per.status == 1 ? (
                                                <>
                                                
                                                  {per.descricao !== null && per.descricao !== '' ? (
                                                    <td className='td-align-center'>{per.descricao}<span><IconInfo /></span></td>
                                                  ) : (
                                                    <>
                                                      {i === 8 ? (
                                                        <td> Conforme n° de receitas escolhidas </td>
                                                      ) : (
                                                        <>
                                                          {per.agro_plans_function.id === 1 ? (
                                                           
                                                            <td className='td-align-center'> Escolha o n° de receitas </td>
                                                          ) : (
                                                            <td className='td-align-center'> <IconYes /> </td>
                                                          )}
                                                        </>
                                                      )}
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <td className='td-align-center'> <IconNo /> </td>
                                              )}
                                            </>
                                          ) : (null)}
                                        </>
                                      ))}
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                </>
                              )}
                            </>
                          ))}
                        </Line>
                      ))}
                    </tbody>
                  </Table>
    
                  <div className='info-plan'>
                    <p>Seus créditos <b>não expiram!</b> Compre a quantidade de receitas <br /> que desejar e utilize quando quiser.</p>
                    {grid.map((g) => (
                      <>
                          {g.is_pre_pago === 0 ? (
                            null
                          ) : (
                            <>
                              <p className='td-align-center'><b>R$ <span className='vlr'>{g.vlr}</span>,{g.cents}</b><br /> por receita. </p>
                              
                              {g.plan_id === 4 || g.plan_id === 5 ? (
                                <>
                                  <p className='td-align-center' style={{ color: '#F01' }}>Economize 10% no seu plano anual</p>
                                </>
                                ) : (null)}
                            </>
                          )}
                      </>
                    ))}
    
                    <div 
                      className="button-finish" 
                      style={{ 
                        width: '100%', 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'center', 
                        marginTop: '0px', 
                        marginBottom: '20px' 
                        }}
                      >
                        <Button 
                            width='100px'
                            height='50px'
                            value='ASSINE'
                            outline={false}
                            disabled={false}
                            onClick={() => assinar(6)}
                            loading={false}
                            tooltip={false}
                            tooltipMsg=''
                        />
                      </div>
    
                      <p className='td-align-center'> Quanto mais receitas, maior o seu desconto. <span className='link' onClick={() => props.history.push('/compra?6')}>Simule aqui.</span></p  >
    
                  </div>
                </div>
              ) : (
                <>
                  {planos.length > 0 && headers.length > 0 && grid.length > 0 ? (
                    <Table>
                      <thead>
                        <Line index={0}>
                          <th><img style={{width: '24px'}}  src='https://agroreceita.com.br/wp-content/uploads/2020/09/símbolo-1.png'></img></th>
                          {planos.map((p) => (
                            <>
                              {p.nivel > props.userPlanLevel ? (
                                <>
                                  {showPrePago === false ? (
                                    <>
                                      {p.is_pre_pago === 1 ? (null) : (
                                        <th className='td-align-center' >{p.plan_name}</th>
                                      )}
                                    </>
                                  ) : (
                                    <th className='td-align-center'>{p.plan_name}</th>
                                  )}
                                </>
                              ):(null)}
                            </>
                          ))}
                        </Line>
                      </thead>

                      <tbody>
                        
                        {headers.map((h, i) => (
                          <Line index={i+1}>
    
                            {i === 0 ? (
                              <>
                                <td id='info' aria-describedby="tooltip">{h.descricao} <span><IconInfo /> <p>Preenchimento automático de dados; Cálculo de área a tratar ou quantidade a adquirir; Frases de Modalidade e Época de Aplicação.</p></span></td>
                              </>
                            ) : (
                              <>
                                {i === 5 ? (
                                  <>
                                    <td id='info' aria-describedby="tooltip">{h.descricao} <span><IconInfo /><p style={{ marginLeft: '260px' }}>Conforme as exigências regulatórias de cada estado</p></span></td>
                                  </>
                                ) : (
                                  <td>{h.descricao}</td>
                                )}
                              </>
                            )}
    
            
                            {planos.map((p, i) => (
                              <>
                                {p.nivel > props.userPlanLevel ? (
                                  <>
                                    {showPrePago === false ? (
                                      <>
                                        {p.is_pre_pago === 1 ? (
                                          null
                                        ) : (
                                          <>
                                            {permissoes.map((per, i) => (
                                              <>
                                                {per.agro_plans_function.id === h.id && per.plan.id === p.id ? (
                                                  <>
                                                    {per.status == 1 ? (
                                                      <>
                                                        {per.descricao !== null && per.descricao !== '' ? (
                                                          <td className='td-align-center'>{per.descricao}</td>
                                                        ) : (
                                                          <>
                                                            {i === 0? (
                                                              <td> Escolha o n° de receitas </td>
                                                            ) : (
                                                              <td className='td-align-center'> <IconYes /> </td>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    ) : (
                                                      <td className='td-align-center'> <IconNo /> </td>
                                                    )}
                                                  </>
                                                ) : (null)}
                                              </>
                                            ))}
                                          </>
                                        )}
                                      </>
                                      ):(
                                      <>
                                        {permissoes.map((per) => (
                                          <>
                                            {per.agro_plans_function.id === h.id && per.plan.id === p.id ? (
                                              <>
                                                {per.status == 1 ? (
                                                  <>
                                                    {per.descricao !== null && per.descricao !== '' ? (
                                                      <td className='td-align-center'>{per.descricao}</td>
                                                    ) : (
                                                      <td className='td-align-center'> <IconYes /> </td>
                                                    )}
                                                  </>
                                                ) : (
                                                  <td className='td-align-center'> <IconNo /> </td>
                                                )}
                                              </>
                                            ) : (null)}
                                          </>
                                        ))}
                                      </>
                                    )}
                                  </>
                                ):(null)}
                              </>
                            ))}
                          </Line>
                        ))}

                        {
                          (window.screen.width > 768 || showPrePago) && (
                            <>
                              <Line index={0}>

                                <td className='td-align-center'> </td>
                                {grid.map((g) => (<>
                                  {g.plan_nivel > props.userPlanLevel ? (
                                    <>
                                      {showPrePago === false ? (
                                        <>
                                          {g.is_pre_pago === 1 ? (
                                            null
                                          ) : (
                                            <>
                                              <td className='td-align-center'><span>R$ <span className='vlr'>{g.vlr}</span>,{g.cents}</span><br /> {g.tipo} 
                                                {g.plan_id === 4 || g.plan_id === 5 ? (
                                                  <>
                                                    <p className='td-align-center' style={{ fontSize: '9px', width: '100%', textAligh: 'center', color: '#F01' }}>Economize 10% no seu plano anual</p>
                                                  </>
                                                ) : (null)}
                                              </td>
                                            </>
                                        )}
                                        </>
                                      ) : (
                                        <>
                                          <td className='td-align-center'>
                                            <span>R$ <span className='vlr'>{g.vlr}</span>,{g.cents}</span><br /> {g.tipo} 

                                            {g.plan_id === 4 || g.plan_id === 5 ? (
                                              <>
                                                <p className='td-align-center' style={{ color: '#F01' }}>Economize 10% no seu plano anual</p>
                                              </>
                                              ) : (null)}
                                          </td>
                                        </>
                                      )}
                                    </>
                                  ) : (null)}
                                </>
                                ))}
                              </Line>
                  
                              <Line index={0}>

                                <td className='td-align-center'> 
                                  <p style={{
                                      position: 'relative',
                                      marginTop: '-70px'
                                    }}
                                  > Para mais usuários ou receitas, <span className='link' onClick={() => props.history.push('/compra?0')}>simule aqui</span>. Quanto mais você contratar, maior é o seu desconto! </p>
                                </td>

                                {planos.map((p) => (
                                  <>
                                  {p.nivel > props.userPlanLevel ? (
                                    <>
                                      {showPrePago === false ? (
                                        <>
                                          {p.is_pre_pago === 1 ? (
                                            null
                                          ) : (
                                            <td className='td-align-center'>
                                              <div 
                                                className="button-finish" 
                                              >
                                                <Button 
                                                    width={window.screen.width <= 768 ? '80px' : '100px'}
                                                    height={window.screen.width <= 768 ? '40px' : '50px'}
                                                    value='ASSINAR'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => assinar(p.id)}
                                                    loading={false}
                                                    tooltip={false}
                                                    tooltipMsg=''
                                                />
                                              </div>
                                            </td>
                                          )}
                                        </>
                                      ) : (
                                        <td className='td-align-center'>
                                          <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                              <Button 
                                                  width='100px'
                                                  height='50px'
                                                  value='ASSINAR'
                                                  outline={false}
                                                  disabled={false}
                                                  onClick={() => assinar(p.id)}
                                                  loading={false}
                                                  tooltip={false}
                                                  tooltipMsg=''
                                              />
                                          </div>
                                        </td>
                                      )}
                                    </>
                                  ) : (null)}
                                  </>
                                ))}
                                
                              </Line>
                            </>
                          )
                        }
            
                      </tbody>
                    </Table>
                  ) : (<>{}</>)}

                  {
                    window.screen.width <= 768 && showPrePago === false && grid[0] !== undefined && (
                      <>
                        <Row>
                          {planos.map((p, i) => (
                            grid.map((g, r) => (
                              r === i && (
                                <>
                                  {p.nivel > props.userPlanLevel ? (
                                    <>
                                      {p.is_pre_pago !== 1 && (
                                        <Col index={i} onClick={() => assinar(p.id)} backgroundColor={i === 2 && '#292E38'}>
                                          <Row position='relative' paddingBottom='4px'>
                                            <h3>{p.plan_name}</h3>
                                          </Row>
                                          <Row position='relative' flexDirection='column' paddingBottom='12px'>
                                            <span>R$ {g.vlr},00*</span>
                                            <p>mensais/usuário</p>
                                          </Row>
                                          <Row position='relative' color='#ff5252'>
                                            {
                                              (p.id === 4 || p.id === 5) && (
                                                <p>10% OFF NO PLANO ANUAL</p>
                                              )
                                            }
                                          </Row>
                                        </Col>
                                      )}
                                    </>
                                  ):(null)}
                                </>
                              )
                            ))
                            
                          ))}
                          
                        </Row>
                        <Row>
                          {planos.map((p, i) => (
                            grid.map((g, r) => (
                              r === i && (
                                <>
                                  {p.nivel > props.userPlanLevel ? (
                                    <>
                                      {p.is_pre_pago !== 1 && (
                                        <Button 
                                          height='30px'
                                          margin='8px'
                                          value='ASSINAR'
                                          outline={false}
                                          disabled={false}
                                          onClick={() => assinar(p.id)}
                                          loading={false}
                                          tooltip={false}
                                          tooltipMsg=''
                                        />
                                      )}
                                    </>
                                  ):(null)}
                                </>
                              )
                            ))
                            
                          ))}
                          
                        </Row>
                        {
                          props.userPlanLevel < prepagoLevel ? (
                            <Row margin='0px 0px 80px 0px' flexDirection='column'>
                              <Row>
                                <Col backgroundColor='white' color='black'>
                                  <p className="simule-aqui">*Para mais usuários ou receitas, <b onClick={() => props.history.push('/compra?0')}>simule aqui</b>. Quanto mais você contratar, maior é o seu desconto!</p>
                                </Col>
                              </Row>
                              <Row fontSize='14px' fontWeight='500' backgroundColor='white' color='black' borderRadius='0px 0px 16px 16px'>
                                <Card>
                                  <h2>Pré-pago</h2>

                                  <div className='simulacao'>
                                    <p>Compre a quantidade de receitas que deseja e utilize quando quiser.</p>
                                  </div>

                                  <div className='itens'>
                                    <p><IconYes /> Compêndio Agrícola</p>
                                    <p><IconYes /> Atualização automática de dados</p>
                                    <p><IconYes /> Controle de restrição de produtos por estado</p>
                                    <p><IconYes /> Controle de ARTs/TRTs</p>
                                    <p><IconYes /> Envio de dados para as defesas agropecuárias</p>
                                    <p><IconYes /> Solicitar inclusão de produtos | documentos que não encontrar</p>
                                  </div>


                                  <div className='preco'>
                                    <p className='p-align-center'><span>R$ <span className='vlr'>{grid[0].vlr}</span>,{grid[0].cents}</span></p>
                                    <p className='legenda'>por receita.</p>
                                    
                                  </div>
                                  <div className='simulacao'>
                                    <p>Para mais receitas, <span onClick={() =>  props.history.push('/compra?6')}>simule aqui</span></p>
                                  </div>
                                  <div className='btn'>
                                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                      <Button 
                                          width='168px'
                                          height='50px'
                                          value='COMPRAR RECEITAS'
                                          outline={false}
                                          disabled={false}
                                          onClick={() => assinar(6)}
                                          loading={false}
                                          tooltip={false}
                                          tooltipMsg=''
                                      />
                                    </div>
                                  </div>
                                </Card>
                              </Row>
                            </Row>
                          ) : (
                            <Row margin='0px 0px 80px 0px' flexDirection='column'>
                            </Row>
                          )
                        }
                      </>
                    )
                  }
                </>
              )}
            </>
          )}
        </>
    </TableContainer>
  )
};

export default TablePlans;
