import React from "react";

import GenericUpper from "../../components/generic-upper";
import { AgronomosContainer } from "./styles";
import { Container } from "react-grid-system";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import api from "../../services/api";
import Button from "../../components/button/button_v2";
import DotsLoader from "../../components/loader";
import Importacao from "../../components/modal/modalImportacao";


import CardGeral from "../../components/card-geral/index";

import ModalTesteGratisAcabou from '../../components/modal/modalFuncaoBloqueada';
import Paginator from "../../components/paginator";


export const USER = "@userinfo";
export const VAL = "@validade";

class Agronomos extends React.Component {
    state = {
        agronomos: [],
        creas: [],
        offset: 0,
        limit: 30,
        totalAgronomos: 0,
        hideButton: false,
        loading: false,
        result: "",
        result2: "",
        size_screen: window.screen.width,
        importar: false,
        modalTesteGratisAcabou: false,
        user: JSON.parse(localStorage.getItem(USER)),
        val: JSON.parse(localStorage.getItem(VAL)),
        loading: false,
        loadingMore: false,
        loadingSearch: false,
    };



    //função para executar no inicio do componente
    componentDidMount() {
        setInterval(async()=>{
            this.setState({size_screen: window.screen.width});
        }, 990);
        this.search('', '', 30, 0);
    }

    updateResposta = async (resposta) => {
        if (resposta == 0) {
            this.setState({importar: false})
        }
    }
    
    updateValues = async (text, estado, search) => {
        if (search == 1) {
            
            if(!text && !estado){
                this.getAgronomists('', '', 30, 0);
            }else{   
                this.setState({ result2: estado });
                this.setState({ result: text });
                this.search(text, estado, 30, 0);
            }
                
        }else{
            this.setState({result: text});
            this.setState({result2: estado});

        }
    }

    // função para pegar os agronomos na API
    getAgronomists = async () => {

        try {
            const { limit } = this.state;
            const response = await api.get("/agronomos?limit=" + limit);
            const agronomos = response.data.data;
            const creas = response.data.data.creas;

            if (agronomos && agronomos.length) {
                this.setState({ agronomos: agronomos, creas: creas, loadingSearch: false, hideButton: false });
            } else {
                this.setState({ creas: creas, loadingSearch: false, hideButton: false });
            }

        } catch (error) {

            this.setState({ loadingSearch: false, hideButton: true });

        }

    }

    //função para realizar buscas dos campos
    search = async (result, result2, limit, offset) => {
        this.setState({loadingSearch: true, agronomos: []});
        
        try {
            const search = result;
            const estado = result2;
    
            const payload = {
                search,
                estado
            }
    
            const response = await api.post(`/agronomos/pesquisa?limit=${limit}&offset=${offset}`, payload);
    
            this.setState({ 
                agronomos: response.data.data.agronomos, 
                totalAgronomos: response.data.data.total,
                loadingSearch: false
            });
        } catch (error) {
            this.setState({loadingSearch: false});
        }
    };

    //funcao para realizar a limpeza do filtro de buscas
    cleanFilter = () => {
        this.setState({
            result: "",
            result2: "",
            offset: 0
        });
        this.getAgronomists('', '', 30, 0);
    };

    //função para realizar o download do Xlsx
    getXlms = async () => {
        this.setState({ loading: true });
        try {
            const response = await api.get("/relatorios/agronomos", {
                responseType: "blob"
            });
    
            let blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "RelatorioResponsaveisTecnicos.xlsx";
            link.href = url;
            link.click();
            this.setState({ loading: false });
            
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    //função para o botão  VER MAIS exibir mais agronomos na tela
    loadMore = async () => {
        this.setState({ loadingMore: true, hideButton: true });
        const { limit, offset, agronomos } = this.state;
        const newOffset = limit + offset;

        try {
            const response = await api.get(
                "/agronomos?limit=" + limit + "&offset=" + newOffset
            );

            let newAgronomos = response.data.data;

            const agronomosConcat = agronomos.concat(newAgronomos);

            this.setState({
                agronomos: agronomosConcat,
                offset: newOffset,
                hideButton: false
            });
        } catch (err) {
            this.setState({ hideButton: true });
        }

        this.setState({ loadingMore: false });
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.search(this.state.result, this.state.result2, 30, 0);
        }
    }

    validateResult = (e) => {
    }

    openAdd = () => {
        this.props.history.push('/agronomos/add');
    }

    updateLimitOffset = (l,o) => {
        if (l !== this.state.limit || o !== this.state.offset) {
            this.setState({
                limit: l,
                offset: o
            })

            this.search(this.state.result, this.state.result2, l, o);
        }
    }

    //renderização
    render() {
        const { agronomos, loadingSearch, size_screen, importar, user, modalTesteGratisAcabou, val, totalAgronomos } = this.state;
        return (
            <>
                <GenericUpper
                    title="Responsáveis Técnicos"
                    subtitle="Gerencie os responsáveis técnicos da sua conta"
                    search={true}
                    searchFunction={(text, estado, search) => this.updateValues(text, estado, search)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    placeholder="Nome ou CREA/Visto/CFTA"
                    addButton={true}
                    estados={true}
                    tooltip={"Adicionar"}
                    linkSearch = {'/agronomos/add'}
                ></GenericUpper>
                <AgronomosContainer>
                    <Container style={size_screen <= 768 ? {paddingLeft:"0px", paddingRight:"0px", margin: 'auto'} : {}}>
                    {size_screen <= 768 ? (<>
                    
                        
                        <CardGeral
                            titulos={ ["AGRONOMOS","NOME", "CREA/Visto/CFTA","ESTADO"]}
                            valores={agronomos}
                        />

                        <Paginator 
                            perPage={this.state.limit} 
                            total={totalAgronomos} 
                            onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                            totalItens={agronomos.length}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                height: '200px',
                                display: 'flex', 
                                flexDirection: 'column', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                            }}
                        >
                            <Button 
                                width='87%'
                                height='50px'
                                value='ADICIONAR RESPONSÁVEL TÉCNICO'
                                outline={false}
                                disabled={false}
                                onClick={() => this.openAdd()}
                                loading={false}
                            />

                            <Button 
                                width='87%'
                                height='50px'
                                value='RELATÓRIO'
                                outline={!this.state.loading}
                                disabled={false}
                                onClick={() => this.getXlms()}
                                loading={this.state.loading}
                            />
                        </div>

                        <DotsLoader show={loadingSearch}></DotsLoader>

                    </>):(<>

                        <Importacao
                            style={{display: "flex", justifyContent: "center"}}
                            show={importar}
                            onHide={e => {this.setState({importar: false})}}
                            step={1}
                            fields={[
                                "nome",
                                "CPF",
                                "Responsável Técnico",
                                "Filial",
                                "Tipo",
                                "Estado",
                                "Número- CREA/Visto/CFTA",
                                "Status"
                            ]}
                            resposta=''
                            update={this.updateResposta}
                            title='Importar Responsáveis Técnicos'
                        ></Importacao>

                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>CREA/Visto/CFTA</th>
                                    <th>Estados</th>
                                </tr>
                            </thead>

                            <TBody>
                                {agronomos.map(agronomo => (
                                    <tr
                                        onClick={e =>{
                                            if(user.contract_software_id == 6 && val == 0) {
                                                this.setState({modalTesteGratisAcabou: true})
                                            }else{
                                                this.props.history.push(`agronomos/${agronomo.id}`)
                                            }
                                        }}
                                        key={agronomo.id}
                                    >
                                        <td>{agronomo.nome}</td>

                                        <td>
                                            {agronomo.creas.map(crea => (
                                                <>
                                                    {crea.status == 0 ? (
                                                        <>
                                                            <ul><strike>{crea.crea}</strike></ul>
                                                        </>
                                                    ) : (
                                                            <>
                                                                <ul className="crea">{crea.crea}</ul>
                                                            </>
                                                        )}
                                                </>
                                            ))}
                                        </td>
                                        <td>
                                            {agronomo.creas.map(crea => (
                                                <>
                                                    {crea.status == 0 ? (
                                                        <>
                                                            <ul className="estado-inativo">{crea.estado}</ul>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <ul className="estado">{crea.estado}</ul>
                                                        </>
                                                    )}
                                                </>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </TBody>
                        </Table>

                        <DotsLoader show={loadingSearch}></DotsLoader>

                        <Paginator 
                            perPage={this.state.limit}
                            total={totalAgronomos} 
                            onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                            totalItens={agronomos.length}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                                marginTop: '40px',
                            }}
                        >
                            <Button 
                                width='25%'
                                height='50px'
                                value='RELATÓRIO'
                                outline={!this.state.loading}
                                disabled={false}
                                onClick={() => this.getXlms()}
                                loading={this.state.loading}
                            />
                        </div>


                        </>)}

                        <ModalTesteGratisAcabou
                            show={modalTesteGratisAcabou}
                            title="Essa é uma função exclusiva para assinantes..."
                            subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                            onHide={() => {
                                this.setState({modalTesteGratisAcabou: false})
                                window.location.href="/"
                            }}
                        ></ModalTesteGratisAcabou>

                    </Container>
                </AgronomosContainer>
            </>
        );
    }
}
export default Agronomos;
