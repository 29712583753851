import styled from "styled-components";

export const FormContainer = styled.div`
  width: 100%;
  height: auto;
  background: transparent;

`;

export const Card = styled.div`
  width: 100%;
  max-width: 900px;
  height: auto;
  margin: 20px auto 20px;
  padding: 57px 32px 10px;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #303848;
    position: absolute;
    top: 24px;
    margin: auto;
    left: 50%;
    margin-left: -75px;
  }


  @media only screen and (max-width: 1024px) {
    /* width: 100%;
    height: auto;
    margin: 20px 0px 20px;
    padding: 57px 50px 10px; */
  }

  @media only screen and (max-width: 780px) {
    width: 100%;
    margin-top: 20px;
    //margin-top: -40px;
    padding: 57px 20px 10px;

  }


`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: ${props => (props.openBox ? 'flex-start' : 'center')};
  border: ${props => (props.isBorder ? 'solid 1px #e8edf3' : 'none')};
  border-radius: ${props => (props.isBorder ? '10px' : '0px')};
  padding-left: 50px;
  padding-right: 60px;
  transition: all 0.2s;

  .selectValue{
    width: 270px;
    height: 95px;
  }

  @media only screen and (max-width: 780px) {
    flex-direction: column;
    padding-left: 0px;
    padding-right: 0px;
    
    .selectValue{
      width: 97%;
      height: 95px;
    }
  }
`;

export const RowCard = styled.div`
  width: 100%;
  max-width: 700px;
  margin: auto;
  height: ${props => (props.openBox ? '550px' : '90px')};
  max-height: 550px;
  padding: ${props => (props.openBox ? '0px 24px 20px 24px' : '24px 24px 20px 24px')};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: ${props => (props.openBox ? 'flex-start' : 'center')};
  border: ${props => (props.isBorder ? props.erro ? 'solid 1px #ff5252' : 'solid 1px #e8edf3' : 'none')};
  border-radius: ${props => (props.isBorder ? '10px' : '0px')};
  transition: all 0.2s;

  .selectValue{
    width: 270px;
    height: 50px;
  }

  .align {
    display: flex;                            
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .cancel {
    width: 15px;
    height: 17px;
    margin-top: ${props => (props.openBox ? '0px' : '0px')};
    transition: all 0.2s;
    
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
    &:active {
      transform: scale(0.8);
    }
  }
  .arrow {
    transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
    margin-top: ${props => (!props.openBox ? '-10px' : '0px')};
    transition: all 0.2s;
    &:hover {
      cursor: pointer;
      transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
    }

    &:active {
      transform: scale(0.8);
    }
  }


  @media only screen and (max-width: 780px) {
    height: auto;
    /* padding: ${props => (props.openBox ? '45px 24px 40px 24px' : '45px 24px 40px 24px')}; */
    padding: 22px 8px 20px 8px;

    .align {
      height: 146px !important;
      display: flex;                            
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 10px;
    }
    .selectValue{
      width: 100%;
      height: 65px;
    }

    .cancel {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .arrow {
      transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
      margin-top: ${props => (!props.openBox ? '-10px' : '0px')};
      
      position: ${props => props.openBox ? 'relative' : 'initial'};
      bottom: ${props => props.openBox ? '-358px' : '0px'};
      transition: all 0.2s;

      

      &:hover {
        cursor: pointer;
        transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
      }

      &:active {
        transform: scale(0.8);
      }
    }
  }
`;

export const BlueLink = styled.p`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #589bff;
  cursor: ${props => props.disabled ? 'context-menu' : 'pointer'};
  text-align: center;
  padding: 5px;
  transition: all 0.2s;
  opacity: ${props => props.disabled ? '0.5' : '1'};


  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.98);
  }

`;

export const ContainerRelationOptions = styled.div`
  width: 100%;
  height: 500px;
  padding-right: 170px;
  padding-left: 170px;
  padding-top: 30px;
  margin-top: 3px;
  overflow-y: scroll;

  @media only screen and (max-width: 990px) {
    padding-right: 120px;
    padding-left: 120px;
  }
  

  @media only screen and (max-width: 780px) {
    padding-right: 15%;
    padding-left: 15%;
  }
  
  @media only screen and (max-width: 440px) {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  @media only screen and (max-width: 380px) {
    padding-right: 5px;
    padding-left: 5px;
  }

  /* width */
  ::-webkit-scrollbar {
  width: 6px;

  
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 8px;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
`;

export const Help = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  .help-buttom{
    margin-top: -40px;
    margin-right: -85px;
    
    color: #589bff;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;

    font-family: "Lato", Sans-serif;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    fill: #589bff;
    color: #589bff;
    background-color: #FFFFFF00;
    border-radius: 50px 50px 50px 50px;
    padding: 7px 12px 7px 12px;
    transition: all 0.2s;

    &:hover{
      transform: scale(1.1);
    }
  }

  @media only screen and (max-width: 990px) {
    .help-buttom{
      margin-right: -35px;
    }
  }
  @media only screen and (max-width: 780px) {
    .help-buttom{
      margin-right: -10px;
    }
  }
`;

export const Dispensed = styled.p`
  width: auto;
  height: 25px;
  border-radius: 5px;
  background-color: #f3f3f5;
  font-family: Lato;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #ff5252;
  margin-right: 25px;
  padding: 4px;
  position: absolute;
  margin-top: 100px;

  @media only screen and (max-width: 796px) {
    margin-right: 0px;
    margin-top: 203px;

  }
`;
