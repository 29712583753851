import React, { useState, useEffect } from "react";

import api from "../../../services/api";
import { Container } from "react-grid-system";
import { FormContainer, Card, Title, Paragraph, ContainerForm, ContainerRelationOptions } from "./styles";
import Input from "../../input/input_v2";
import ArrowIcon from "../../../assets/icons/icon-arrow.svg";
import ArrowIconDisabled from "../../../assets/icons/icon-arrow-disabled.svg";
import ModalNew from "../../modal/modalGeneric";


export const USER = "@userinfo";


const PrescriptionStepSix = ({ ...props }) => {

    const [estado, setEstado] = useState('');
    const [firstPrescription, setFirstPrescription] = useState('');
    const [salvarFirst, setSalvarFirst] = useState(false);
    const [isOpenBox, setIsOpenBox] = useState(false);
    const [showModalFirst, setShowModalFirst] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const getEmpresa = async () => {
        try {

            const user = JSON.parse(localStorage.getItem(USER));

            const response = await api.get(`/filiais/${user.agro_subsidiary_id}`);

            if (response) {
                const filial = response.data.data;
                setEstado(filial.estado);
            }

        } catch (error) {

        }
    }

    const openBoxFirst = () => {
        if ((isOpenBox === false || isOpenBox === undefined)) {
            setIsOpenBox(!isOpenBox);
        }
    }

    const openBoxFirstArrow = () => {
        setIsOpenBox(!isOpenBox);
    }


    const changeFirstPrescription = (value) => {
        if(salvarFirst === true){
            setFirstPrescription(value)
        }
    }

    const changeSaveFirst = (isSave) => {
        
        if (isSave != salvarFirst) {
            setSalvarFirst(isSave);
        }
    }

    useEffect(() => {
        if (isOpenBox === true) {
            setIsOpenBox(false);
        }
    }, []);

    useEffect(() => {
        props.update(firstPrescription);
    }, [firstPrescription]);

    useEffect(() => {
        if(salvarFirst === false){
            setFirstPrescription('');
        }
    }, [salvarFirst]);

    useEffect(() => {
        getEmpresa();
    }, [])


    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
    
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <Container>
            {
                
                (props.estado === "MT") && (
                    
                   <FormContainer>
                        <Card
                            onClick={() => openBoxFirst()}
                            openBox={isOpenBox}
                            erro={false}
                            existInformation={false}
                            disabled={props.disabled}
                        >
                            <div className="row">
                                <Title disabled={props.disabled}>Emissão de Receita Complementar (Opcional)</Title>
                                {!props.disabled ? (
                                    <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBoxFirstArrow()} />
                                ) : (
                                    <img className="arrow" src={ArrowIconDisabled} alt='arrow' />
                                )}
                            </div>

                            {!isOpenBox ? (
                                <Paragraph disabled={props.disabled}>Informe os dados para emissão de receita complementar</Paragraph>
                            ) : (
                                <>
                                    <ContainerForm>
                                        <>
                                            <Input
                                                name="num_first_prescription"
                                                type="text"
                                                id="num_first"
                                                label="Número da primeira receita"
                                                width={windowWidth < 991 ? '100%' : '60%'}
                                                help={true}
                                                yesNo={true}
                                                yesNoStepSix={true}
                                                onChangeYesNo={(e) => changeSaveFirst(e)}
                                                yesNoValue={salvarFirst}
                                                disabled={salvarFirst ? false : true}
                                                messageYesNo='Emitir receita complementar'
                                                helpClick={() => setShowModalFirst(true)}
                                                onChange={e => changeFirstPrescription(e)}
                                                value={firstPrescription}
                                            />
                                        </>
                                    </ContainerForm>
                                </>
                            )}
                        </Card>
                    </FormContainer>
                )
            }
            <ModalNew
                size='md'
                show={showModalFirst}
                onHide={() => setShowModalFirst(false)}
            >
                <p>Informe aqui o número da primeira receita emitida que será vinculada à emissão desta receita complementar.</p>
                <br></br>
                <p><b>Obs:</b>Ao informar este campo, a receita emitida não será enviada ao SISDEV</p>
            </ModalNew>
        </Container>
    );
};

export default PrescriptionStepSix;