import styled from "styled-components";

export const ButtonContainer = styled.button`
  display: ${props => (props.hide ? "none" : "block")};
  width: ${props => props.width};
  padding: ${props => props.padding};
  height: ${props => props.height};

  background: ${props => (props.outline ? "transparent" : props.color)};
  color: ${props => (props.outline ? props.color : "white")};
  
  border: ${props => (props.border ? props.border : `1px solid ${props.color}`)};
  border-radius: 30px;
  
  font-weight: bold;
  font-size: ${props => props.fontSize || "11px"};
  text-transform: uppercase;
  margin: ${props => props.margin ? props.margin : "0px"};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transition: all 0.3s;
  
  &:hover {
    cursor: pointer;
    background: ${props => props.color};
    color: #FFF;
    box-shadow: 0 0 20px 0 ${props => props.color};
  }
  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
`;

export const ButtonContainerV2 = styled.div`
  width: ${props => props.width ? props.width : '100%'};
  height: ${props => props.height ? props.height : '100%'};
  margin: ${props => props.margin ? props.margin : '0px'};

  
  .btnv2 {
    outline: none;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    border: ${props => (props.disabled ?  'solid 2px #ddd' : props.red === true ? 'solid 2px #F10' :  props.color ? `solid 2px ${props.color}` : props.blog ? 'solid 2px #fff' : 'solid 2px #2fbb69')};
    background-color: ${props => (props.disabled ? '#ddd' : props.outline ? 'transparent' : props.red === true ? '#F10' : props.color ? props.color : props.blog ? "transparent" : '#2fbb69')};
    color: ${props => (props.disabled ? '#999' : props.outline ? props.red === true ? '#F10' : props.color ? props.color : '#2fbb69' : props.blog ? '#2fbb69' :  '#FFF')};
    font-family: Lato;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    text-align: center;
    transition: all 0.2s;
    padding: 5px 5px;


    &:hover{
      color: ${props => (props.disabled ? '#999' : props.blog ? '#2fbb69' : '#FFF')};
      background-color: ${props => (props.disabled ? '#ddd' : props.red === true ? '#F10' : props.blog ? 'transparent' : props.color ? props.color : '#2fbb69')};
      cursor: ${props => (props.disabled ? 'no-drop' :  props.loading ? 'progress' : 'pointer')};
      transform: ${props => (props.disabled ? 'none' : 'scale(1.04)')};
    }
    
    &:active {
      transform: ${props => (props.disabled ? 'none' : 'scale(0.95)')};
    }

    &:focus{
      outline: 0;
      box-shadow: none;
    }
  }


  p{
    width: auto;

    display:none;
    position:absolute;
    align-items: center;

    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;

    
    margin-top: 8px;
    margin-left: ${props => (props.tooltipMarginLeft ? props.tooltipMarginLeft : '122px')};
    
    padding: 3px;
    
    color:#FFF;
    background-color: #1d2129;
    opacity: 0.9;
    
    border-radius: 5px;

    &:before{
        position: absolute;
        background-color:  #1d2129;
        width: 8px;
        height: 8px;
        top: -4px;
        margin-left: 17px;
        content: '';
        transform: rotate(45deg);
    }

}

&:hover{
    text-decoration: none;
    p{
        display: block;
    }
}

`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 20px;
  height: 20px;
  position: relative;
  margin: auto;

  background-color: transparent;
  border-top: ${props => (props.disabled ? '1px solid #999' : props.outline ? props.red === true ? '1px solid #F10' : '1px solid #2fbb69' : '1px solid #FFF')};
  border-bottom: ${props => (props.disabled ? '1px solid #999' : props.outline ? props.red === true ? '1px solid #F10' : '1px solid #2fbb69' : '1px solid #FFF')};
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;
