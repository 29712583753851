import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas'
import { store } from "react-notifications-component";
import Button from '../button/button_v2';
import DragDropUpload from '../../components/drag-drop-upload/index'
import { InputContainer, Label, Linha, Container } from './styles';
import mais from "../../assets/Mais.png";
import { IconUploads } from '../../assets/icons/iconUploads';

const InputSign = ({ ...props }) => {

    const signRef = useRef();
    const [sign, setSign] = useState('');
    const [tipoSign, setTipoSign] = useState(0);
    const [renderInput, setRenderInput] = useState(true);
    const [namesfiles, setNamesfiles] = useState([]);

    const saveSign = () => {
        let sign = signRef.current.toDataURL();
        setSign(sign);
        props.update(sign);
    }

    const clearSign = () => {
        signRef.current.clear()
        setSign('');
    }

    const editSign = () => {
        setSign('');
        deleteFile();
        props.update('');
    }

    const onChangeCertificate = (e) => {

        let cont = e.target.files.length;
        cont = cont - 1;

        if (e.target.files) {
            if (
                e.target.files[cont].type == "image/png" ||
                e.target.files[cont].type == "image/jpeg"
            ) {
                let fil = e.target.files;
                let reader = new FileReader();
                reader.readAsDataURL(fil[0]);

                reader.onload = (e) => {
                    setSign(e.target.result);
                    props.update(e.target.result);
                }
                setNamesfiles(e.target.files[cont].name);

            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `O arquivo selecionado não possui a extensão correta`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
            store.addNotification({
                title: "Erro!",
                message: `Não é possível enviar mais que um arquivo!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }

    };
    const deleteFile = () => {
        setNamesfiles([]);
    };


    useEffect(() => {
        if (props.sign && props.sign !== '') {
            setSign(props.sign);
        }
    }, [])

    useEffect(() => {
        if (tipoSign != 0) {
            deleteFile();
            setSign('');
        }
    }, [tipoSign])


    return (

        <Container>
            {sign === '' ? (
                <>
                    {tipoSign === 0 ? (<>
                        <div
                            className="button-finish"
                            style={{
                                width: '100%',
                                display: 'flex',
                                marginTop: '10px',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Button
                                width='45%'
                                height='45px'
                                value='Desenhar assinatura'
                                outline={false}
                                disabled={false}
                                onClick={() => setTipoSign(1)}
                                loading={false}
                            />

                            <p style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                            }}> - Ou - </p>

                            <div class='input-wrapper'>
                                <div className="arquivos">
                                    <label
                                        className='btn-update'
                                        for='input-file'
                                        multiple
                                        onClick={() => { setRenderInput(true) }}
                                    >
                                        <IconUploads width="auto" height="40px"  display="flex" alignItems="center" justifyContent="center" />
                                        Selecione o arquivo .PNG ou .JPG em seu computador.<br></br><br></br>A imagem selecionada será utilizada como assinatura para as receitas emitidas com o responsável técnico.
                                    </label>
                                </div>
                                {renderInput ? (
                                    <input
                                        style={{ display: "none" }}
                                        type="file"
                                        id="input-file"
                                        name="file"
                                        onChange={(e) => onChangeCertificate(e)}
                                    />
                                ) : (null)}
                            </div>

                            <div style={{
                                marginBottom: '0px'
                            }}>

                            </div>

                        </div>
                    </>) : (<>
                        {tipoSign === 1 ? (<>
                            <InputContainer>
                                <SignatureCanvas
                                    ref={signRef}
                                    penColor='black'
                                    canvasProps={{
                                        width: 400,
                                        height: 200,
                                        className: 'sigCanvas'
                                    }}
                                />
                                <Linha />

                            </InputContainer>

                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '0px', marginBottom: '10px' }}>
                                <Button
                                    width='40%'
                                    height='40px'
                                    value='SALVAR ASSINATURA'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => saveSign()}
                                    loading={false}
                                />
                                <Button
                                    width='40%'
                                    height='40px'
                                    value='LIMPAR ASSINATURA'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => clearSign()}
                                    loading={false}
                                />
                            </div>

                        </>) : (<>


                        </>)}
                    </>)}


                </>
            ) : (
                <>
                    <InputContainer marginBottom='0px'>
                        <Label>Prévia da assinatura eletrônica</Label>
                        <img src={sign} style={{ border: '0px solid #000', borderRadius: '25px' }} alt='assinatura' width='400px' height='200px' />
                        <Linha />
                    </InputContainer>

                    <label className="legenda" style={{
                        textAlign: 'center'
                    }}>A assinatura será exibida desta forma na receita</label>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '0px', marginBottom: '40px' }}>
                        <Button
                            width='40%'
                            height='40px'
                            value='EDITAR ASSINATURA'
                            outline={true}
                            disabled={false}
                            onClick={() => editSign()}
                            loading={false}
                        />
                    </div>
                </>
            )}
        </Container>

    )
}

export default InputSign;
