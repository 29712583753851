import React from 'react'

const IconFicha = () => {

    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="20" height="20"
            viewBox="0 0 172 172"
            style={{fill:'#000000'}}
        >
            <g 
                fill="none" 
                fill-rule="nonzero" 
                stroke="none" 
                stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none"
                text-anchor="none" 
                style={{mixBlendode: 'normal'}}
            >
                {/* <path d="M0,172v-172h172v172z" fill="none"></path>
                <g fill="#8a92a2"> */}
                    <path d="M86,0c-3.655,0 -7.33687,1.31688 -10.2125,3.9775c-0.04031,0.04031 -0.06719,0.06719 -0.1075,0.1075l-71.595,71.9175c-5.4825,5.4825 -5.42875,14.44531 -0.1075,20.21c0.04031,0.04031 0.06719,0.06719 0.1075,0.1075l71.595,71.595c5.4825,5.4825 14.44531,5.42875 20.21,0.1075c0.04031,-0.04031 0.06719,-0.06719 0.1075,-0.1075l71.595,-71.595c5.72438,-5.72437 5.805,-14.835 0.3225,-20.3175l-71.9175,-71.9175c-2.74125,-2.74125 -6.3425,-4.085 -9.9975,-4.085zM85.8925,6.88c1.935,0 3.88344,0.65844 5.2675,2.0425l71.9175,71.9175c2.78156,2.78156 2.795,7.4175 -0.43,10.6425l-71.38,71.4875c-0.04031,0.04031 -0.06719,0.08063 -0.1075,0.1075c-3.18469,2.83531 -7.90125,2.74125 -10.6425,0l-71.4875,-71.4875c-0.04031,-0.04031 -0.06719,-0.06719 -0.1075,-0.1075c-1.41094,-1.58562 -2.05594,-3.56094 -2.0425,-5.4825c0,-0.48375 0.01344,-0.92719 0.1075,-1.3975c0.26875,-1.3975 0.90031,-2.72781 1.935,-3.7625l71.4875,-71.81l0.1075,-0.1075c1.57219,-1.3975 3.48031,-2.0425 5.375,-2.0425zM81.915,48.16c-1.03469,0 -1.3975,0.71219 -1.3975,1.3975v49.88c0,1.03469 0.71219,1.3975 1.3975,1.3975h7.8475c1.03469,0 1.3975,-0.71219 1.3975,-1.3975v-49.88c0,-1.03469 -0.71219,-1.3975 -1.3975,-1.3975zM81.915,112.1225c-1.03469,0 -1.3975,0.71219 -1.3975,1.3975v9.245c0,1.03469 0.71219,1.3975 1.3975,1.3975h8.17c1.03469,0 1.3975,-0.71219 1.3975,-1.3975v-8.9225c0,-1.03469 -0.71219,-1.3975 -1.3975,-1.3975v-0.3225z"></path>
                {/* </g> */}
            </g>
        </svg>
    )
}

export default IconFicha;
