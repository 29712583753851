import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Form = styled.div`
    width: 450px;
    
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 50px;
    padding-bottom: 10px;

    @media only screen and (max-width: 768px){
        width: 95%;

    }
`;

export const PrescriptionCard = styled.div`
    background-color: #FFF;

    width: 800px;
    border-radius: 10px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    padding: 10px 40px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);


    a {
        text-decoration: none;
        font-weight: 700;
        font-size: 14px;
        font-style: normal;
        color: #589BFF;
        cursor: pointer;
        width: 100%;
        text-align: center;
    }

    @media only screen and (max-width: 768px){
        width: 95%;
        flex-wrap: wrap;
    }
`;

export const Title = styled.h2`
    width: 100%;
    text-align: center;
    margin: 0px;
`;

export const Subtitle = styled.p`
    width: 100%;
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
`;


export const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${props => (props.justifyContent)};
    margin-bottom: 20px;

    @media only screen and (max-width: 768px){
        width: 95%;
        flex-direction: column;
        flex-wrap: wrap;
    }
`;

export const Label = styled.label`
    color: #8a92a2;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    margin: 0px;
`;

export const Value = styled.p`
    margin: 0px;

`;

export const Status = styled.p`
    background-color: ${props => props.status === 1 ? 'rgba(47, 187, 105, 0.12)' : 'rgba(255, 82, 82, 0.12)'};
    color: ${props => props.status === 1 ? '#03903e' : '#d9261d'};
    
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    width: 90px;
    height: 25px;

    border-radius: 8px;

    text-align: center;
    margin: auto;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    cursor: context-menu;

`;

export const HeaderCompendioGratis = styled.div`
    background: url("https://www.agroreceita.com.br/wp-content/uploads/2020/09/símbolo-branco-1.png") center center no-repeat fixed;
    background-color: rgb(41, 46, 56);
    background-size: cover;
    background-position: 0px -20px;
    width: 100vw;
    height: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: start;

    .header{
        width: 90%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo{
            img{
                width: 150px;
            }
        }

        .links{
            margin-left: 17px;
            a{
                font-family: "Lato", Sans-serif;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-left: 60px;
                fill: #fff;
                color: #fff;
                background-color: #fff0;
                border-radius: 50px 50px 50px 50px;
                padding: 10px 12px 10px 12px;
                text-decoration: none;
                text-align: auto;
                transition: all 0.3s;
            }

            a:hover{
                background-color: #FFFFFF2B;
                
            }
        }

        .planos{
            #plans{
                font-family: "Lato", Sans-serif;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                fill: #fff;
                color: #fff;
                background-color: #fff0;
                border-style: solid;
                border-width: 2px 2px 2px 2px;
                border-color: #fff;
                border-radius: 50px 50px 50px 50px;
                padding: 14px 14px 14px 14px;
                text-decoration: none;
                transition: all 0.3s;

                &:hover{
                    color: #21262F;
                    background-color: #fff;
                    border-color: #fff;
                }
            }

            #teste{
                display: inline-block;
                font-family: "Lato", Sans-serif;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                background-color: #2FBB69;
                border-style: solid;
                border-color: #2FBB69;
                border-radius: 50px 50px 50px 50px;
                padding: 17px 22px 17px 22px;
                text-decoration: none;
                transition: all 0.3s;
                color: #FFF;

                margin-left: 20px;

                &:hover{
                    transform: scale(1.1);
                }

            }
        }
    }

    .title{
        width: 100%;
        h1{
            color: #FFF;
            text-align: center;
            font-family: "Lato", Sans-serif;
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
        }
        h2{
            text-align: center;
            color: #BAC1CF;
            font-family: "Lato", Sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 29px;
        }
    }

    @media only screen and (max-width: 768px) {
        height: 15%;

        .logo{
            img{
                width: 110px !important;
            }
        }

        .header{
            margin-bottom: 10px;

            .links{
                margin-left: 40px;

                #help{
                    display: none;
                }
            }

            .planos{
                #teste{
                    position: fixed;
                    left: -5px;
                    bottom: 25px;
                    z-index: 10;
                    padding: 10px 30px 10px 30px;

                }

                #plans{
                    font-family: "Lato", Sans-serif;
                    font-size: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    fill: #fff;
                    color: #fff;
                    background-color: #fff0;
                    border-radius: 50px 50px 50px 50px;
                    border: 0px;
                    padding: 10px 12px 10px 12px;
                    text-decoration: none;
                    text-align: auto;
                    transition: all 0.3s;
                    &:hover{
                        background-color: #FFFFFF2B;
                    }
                }

            }

        }

        .title{
            margin-top: 35px;
        }
    }

`;

export const DivSaldoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8px;
`;


export const DivSaldo = styled.div`
    width: 160px;
    height: auto;
    padding: 5px;
    border-radius: 8px;
   /*  box-shadow: 1px 2px 5px 1px rgba(29,33,41,0.4); */
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /* font-weight: 700; */
    font-size: 12px;
    font-style: normal;
    text-align: center;

    @media only screen and (max-width: 800px) {
        position: initial;
        justify-content: center;
    }
    
`;


export const Footer = styled.div`
    width: 100%;
    height: 506px;
    background-color: #21262f;

    .section1 {
        width: 100%;
        height: 463px;
        background-color: #21262f;
        
        .row {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-right: 130px;
            padding-left: 130px;
            position: relative;

            .column {
                width: 25%;
                display: flex;
                flex-direction: column;
                justify-content: left;
                flex-wrap: wrap;
                padding: 10px;
                margin-top: 48px;

                h2 {
                    color: #fff;
                    font-family: "Lato", Sans-serif;
                    font-size: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1.5em;
                    letter-spacing: 1px;
                    margin-bottom: 24px;

                    a {
                        color: #fff;
                        ont-family: "Lato", Sans-serif;
                        font-size: 10px;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 1.5em;
                        letter-spacing: 1px;
                        margin-bottom: 24px;
                        text-decoration: none;
                    }
                }

                p {
                    color: #8A92A2;
                    font-family: "Lato", Sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 2em;
                    
                    a {
                        color: #8a92a2;
                        box-sizing: border-box;
                        font-family: "Lato", Sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 2em;
                        text-decoration: none;
                    }
                }


                span {
                    color: #8a92a2;
                    box-sizing: border-box;
                    font-family: "Lato", Sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 2em;
                    text-decoration: none;

                    a {
                        color: #589BFF;
                        font-family: "Lato", Sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 2em;
                    }
                }

                .social-icons-wrapper{
                    display: flex;
                    flex-direction: row;

                    .item{
                        width: 53px;
                        i {
                            font-size: 24px;
                            color: #fff;

                        }
                        .instagram{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                        .facebook{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                        .linkedin{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                    }
                }
            }

            .col {
                width: 50%;
                display: flex;
                flex-direction: row;

                p {
                    color: #8a92a2;
                    font-family: "Lato", Sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5em;
                    margin-left: -5px;

                    a {
                        color: #8a92a2;
                        font-family: "Lato", Sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.5em;
                        text-decoration: none;
                    }
                }
                .linha {
                    color: #8a92a2;
                    font-family: "Lato", Sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5em;
                    margin-left: 5px;
                    margin-right: 10px;
                }
            }
        }
    }

    .section2 {
        width: 100%;
        height: 43px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
            color: #21262F;
            font-family: "Lato", Sans-serif;
            font-size: 12px;
            font-weight: 400;
            margin-right: 10px;
            margin-bottom: 0px;
        }
        a {
            img {
                width: 100px;
                height: 13px;
                margin-left: 10px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        height: auto;

        .section1 {
            height: auto;

            .row {
                padding-right: 10px;
                padding-left: 10px;
                flex-direction: column;

                .column {
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 30px;
                }

                .col {
                    width: 100%;
                    justify-content: center;
                    
                    p {
                        text-align: center !important; 
                        justify-content: center;
                        margin: auto
                    }
                }
            }
        }

        .section2 {
            height: 300px;
            flex-direction: column;
            p {
                margin-top: -170px;
            }
            a {
                img {
                    margin-left: -10px;
                }
            }
        }
    }
`;
