import React from 'react';

const IconBuoy = () => {

    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px"
            width="20" 
            height="20"
            viewBox="0 0 172 172"
            style={{fill: '#000000'}}
        >
            <g 
                fill="none" 
                fill-rule="nonzero" 
                stroke="none" 
                stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none" 
                text-anchor="none" 
                style={{mixBlendMode: 'normal'}}
            >
                <path d="M86,21.5c-35.56738,0 -64.5,28.93262 -64.5,64.5c0,35.56739 28.93262,64.5 64.5,64.5c35.56739,0 64.5,-28.93261 64.5,-64.5c0,-35.56738 -28.93261,-64.5 -64.5,-64.5zM86,32.25c1.80567,0 3.61133,0.16797 5.375,0.33594v16.29297c-1.76367,-0.25195 -3.54834,-0.50391 -5.375,-0.50391c-1.82666,0 -3.61133,0.25195 -5.375,0.50391v-16.29297c1.76367,-0.16797 3.56934,-0.33594 5.375,-0.33594zM69.875,34.60156v17.46875c-7.81055,3.73731 -14.08838,9.99414 -17.80469,17.80469h-17.30078c5.22803,-16.79687 18.35059,-30.02441 35.10547,-35.27344zM102.125,34.60156c16.73389,5.24903 30.02442,18.53955 35.27344,35.27344h-17.46875c-3.71631,-7.81055 -9.99414,-14.08838 -17.80469,-17.80469zM86,59.125c14.90723,0 26.875,11.96778 26.875,26.875c0,14.90723 -11.96777,26.875 -26.875,26.875c-14.90722,0 -26.875,-11.96777 -26.875,-26.875c0,-14.90722 11.96778,-26.875 26.875,-26.875zM32.58594,80.625h16.125c-0.25195,1.74268 -0.33594,3.56934 -0.33594,5.375c0,1.82666 0.25195,3.61133 0.50391,5.375h-16.29297c-0.16797,-1.76367 -0.33594,-3.56933 -0.33594,-5.375c0,-1.80566 0.16797,-3.61133 0.33594,-5.375zM123.12109,80.625h16.29297c0.16797,1.76367 0.33594,3.56934 0.33594,5.375c0,1.80567 -0.16797,3.61133 -0.33594,5.375h-16.29297c0.25195,-1.76367 0.50391,-3.54834 0.50391,-5.375c0,-1.82666 -0.25195,-3.61133 -0.50391,-5.375zM34.60156,102.125h17.46875c3.71631,7.81055 9.99414,14.08838 17.80469,17.80469v17.46875c-16.73389,-5.24902 -30.02441,-18.53955 -35.27344,-35.27344zM119.92969,102.125h17.46875c-5.24902,16.73389 -18.53955,30.02442 -35.27344,35.27344v-17.46875c7.81055,-3.71631 14.08838,-9.99414 17.80469,-17.80469zM80.625,123.12109c1.76367,0.25195 3.54834,0.50391 5.375,0.50391c1.82666,0 3.61133,-0.25195 5.375,-0.50391v16.29297c-1.76367,0.16797 -3.56933,0.33594 -5.375,0.33594c-1.80566,0 -3.61133,-0.16797 -5.375,-0.33594z">
                </path>
            </g>
        </svg>
    )
}

export default IconBuoy;
