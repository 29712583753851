import React, { useState, useEffect } from "react";

import api from "../../../services/api";
import { Container } from "react-grid-system";
import { FormContainer, Card, Title, Paragraph, ContainerForm, ContainerRelationOptions } from "./styles";
import Input from "../../input/input_v2";
import ArrowIcon from "../../../assets/icons/icon-arrow.svg";
import ArrowIconDisabled from "../../../assets/icons/icon-arrow-disabled.svg";


import estados from "../../../services/estados";
import Select from "../../select/select_v2";
import Relation from "./relation";

export const USER = "@userinfo";


const PrescriptionStepFour = ({ ...props }) => {

    const [chaveAcesso, setChaveAcesso] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [cpfCnpj, setCpfCnpj] = useState('');
    const [endereco, setEndereco] = useState('');
    const [bairro, setBairro] = useState('');
    const [municipio, setMunicipio] = useState('');
    const [estado, setEstado] = useState('');
    const [dataEmissao, setDataEmissao] = useState('');
    const [numNf, setNumNf] = useState('');
    const [loading, setLoading] = useState(false);
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [quantidades, setQuantidades] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [codigosProduto, setCodigosProduto] = useState([]);
    const [ncmProdutos, setNcmProdutos] = useState([]);
    const [isOpenBox, setIsOpenBox] = useState(false);
    const [erroNf, setErroNf] = useState(false);
    const [erroChaveAcesso, setErroChaveAcesso] = useState(false);
    const [erroNfMsg, setErroNfMsg] = useState('A chave de acesso precisa conter 44 digitos.');
    const [erroRazaoSocial, setErroRazaoSocial] = useState(false);
    const [erroCpfCnpj, setErroCpfCnpj] = useState(false);
    const [erroEndereco, setErroEndereco] = useState(false);
    const [erroBairro, setErroBairro] = useState(false);
    const [erroMunicipio, setErroMunicipio] = useState(false);
    const [erroEstado, setErroEstado] = useState(false);
    const [erroDataEmissao, setErroDataEmissao] = useState(false);

    const getEmpresa = async () => {
        try {

            const user = JSON.parse(localStorage.getItem(USER));

            const response = await api.get(`/filiais/${user.agro_subsidiary_id}`);

            if (response) {
                const filial = response.data.data;

                changeRazaoSocial(filial.razao_social);
                changeCpfCnpj(filial.cnpj);
                changeEndereco(filial.endereco);
                changeBairro(filial.bairro);
                changeMunicipio(filial.cidade);
                changeEstado(filial.estado);
            }

        } catch (error) {

        }
    }

    const openBoxNFe = () => {
        if ((isOpenBox === false || isOpenBox === undefined)) {
            setIsOpenBox(!isOpenBox);
        }
    }

    const openBoxNFeArrow = () => {
        setIsOpenBox(!isOpenBox);
    }

    const changeChaveAcesso = (value) => {

        if (value !== chaveAcesso) {
            setChaveAcesso(value);
            setErroChaveAcesso(false);

            if (value.length < 54) {
                setErroNf(true);
            } else {
                setErroNf(false);
            }
        }

    }
    const changeRazaoSocial = (value) => {
        if (value !== razaoSocial) {
            setRazaoSocial(value);
            setErroRazaoSocial(false);
        }
    }
    const changeCpfCnpj = (value) => {
        if (value !== cpfCnpj) {
            setCpfCnpj(value);
            setErroCpfCnpj(false);
        }
    }
    const changeEndereco = (value) => {
        if (value !== endereco) {
            setEndereco(value);
            setErroEndereco(false);

        }
    }
    const changeBairro = (value) => {
        if (value !== bairro) {
            setBairro(value);
            setErroBairro(false);

        }
    }
    const changeMunicipio = (value) => {
        if (value !== municipio) {
            setMunicipio(value);
            setErroMunicipio(false);

        }
    }
    const changeEstado = (value) => {
        if (value !== estado) {

            if (value.value) {
                setEstado(value);
            } else {
                setEstado({ value });
            }
        }
    }
    const changeDataEmissao = (value) => {
        if (value !== chaveAcesso) {
            setDataEmissao(value);
            setErroDataEmissao(false);
        }
    }

    const changeNumNf = (value) => {
        setNumNf(value)
    }

    useEffect(() => {
        getEmpresa();
    }, [])

    useEffect(() => {
        setErroChaveAcesso(false);
        setErroRazaoSocial(false);
        setErroCpfCnpj(false);
        setErroEndereco(false);
        setErroMunicipio(false);
        setErroBairro(false);
        setErroEstado(false);
        setErroDataEmissao(false);


        const errosAddGo = props.erros;

        errosAddGo.forEach(erro => {
            if (erro === 'chaveAcesso') {
                setErroChaveAcesso(true);
            }
            if (erro === 'razaoSocial') {
                setErroRazaoSocial(true);
            }
            if (erro === 'cpfCnpj') {
                setErroCpfCnpj(true);
            }
            if (erro === 'endereco') {
                setErroEndereco(true);
            }
            if (erro === 'bairro') {
                setErroBairro(true);
            }
            if (erro === 'municipio') {
                setErroMunicipio(true);
            }
            if (erro === 'estado') {
                setErroEstado(true);
            }
            if (erro === 'dataEmissao') {
                setErroDataEmissao(true);
            }
        });

        if(errosAddGo.length > 0){
            setIsOpenBox(true);
        }

    }, [props.erros]);

    useEffect(() => {
        props.update({
            chaveAcesso,
            razaoSocial,
            cpfCnpj,
            endereco,
            bairro,
            municipio,
            estado,
            dataEmissao,
            produtos,
            quantidades,
            unidades,
            codigosProduto,
            ncmProdutos,
            numNf
        })
    }, [
        chaveAcesso,
        razaoSocial,
        cpfCnpj,
        endereco,
        bairro,
        municipio,
        estado,
        dataEmissao,
        produtos,
        quantidades,
        unidades,
        codigosProduto,
        ncmProdutos,
        numNf
    ])

    useEffect(() => {
        if (props.estado === 'GO') {
            if (props.produtos.length < produtosSelecionados.length) {
                let p = [];
                let q = [];
                let u = [];
                let n = [];

                produtosSelecionados.map((e, i) => {
                    if (props.produtos.includes(e) && p.length < props.produtos.length) {
                        p = [...p, e];
                        q = [...q, quantidades[i]];
                        u = [...u, unidades[i]];
                        n = [...n, ncmProdutos[i]];
                    }
                })

                setProdutosSelecionados(p);
                setQuantidades(q);
                setUnidades(u);
                setNcmProdutos(n);

                if (isOpenBox === true) {
                    setIsOpenBox(false);
                }
            } else {
                props.produtos.map((e, i) => {
                    const count = Object.values(props.produtos).filter(value => value === e).length;
                    const countProds = Object.values(produtosSelecionados).filter(value => value === e).length;

                    if (produtosSelecionados.indexOf(e) == -1 || countProds < count) {
                        produtosSelecionados.push(e);
                        setIsOpenBox(false);
                    }
                })
            }
        }
    }, [props])

    return (
        <Container>
            <FormContainer>
                <Card
                    onClick={() => openBoxNFe()}
                    openBox={isOpenBox}
                    erro={false}
                    existInformation={false}
                    disabled={props.disabled}
                >
                    <div className="row">
                        <Title disabled={props.disabled}>Informações da Nota Fiscal {props.estado !== '' && props.estado !== 'GO' ? ` (Opcional)` : ``} </Title>
                        {!props.disabled ? (
                            <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBoxNFeArrow()} />
                        ) : (
                            <img className="arrow" src={ArrowIconDisabled} alt='arrow' />
                        )}
                    </div>

                    {!isOpenBox ? (
                        <Paragraph disabled={props.disabled}>Informe os dados da nota fiscal</Paragraph>
                    ) : (
                        <>
                            <ContainerForm>
                                {props.estado === 'GO' ? (
                                    <>
                                        <Input
                                            name="num_nf"
                                            type="text"
                                            id="num_nf"
                                            label="Numero da NFe (Opcional)"
                                            onChange={e => changeNumNf(e)}
                                            value={numNf}
                                        />
                                        <Input
                                            name="nfkey"
                                            type="text"
                                            id="chaveacesso"
                                            label="Chave de acesso"
                                            onChange={e => changeChaveAcesso(e)}
                                            messageErro={erroNfMsg}
                                            erro={erroNf || erroChaveAcesso}
                                            value={chaveAcesso}
                                        />
                                        <Input
                                            name="razao_social"
                                            type="text"
                                            id="razaosocial"
                                            label="Razão social do emissor"
                                            onChange={e => changeRazaoSocial(e)}
                                            erro={erroRazaoSocial}
                                            value={razaoSocial}
                                        />
                                        <Input
                                            name="cpf_cnpj"
                                            type="cpfcnpj"
                                            id="cpfcnpjemissor"
                                            label="CPF/CNPJ"
                                            onChange={e => changeCpfCnpj(e)}
                                            erro={erroCpfCnpj}
                                            value={cpfCnpj}
                                        />
                                        <Input
                                            name="endereco"
                                            type="text"
                                            id="enderecoemissor"
                                            label="Endereço"
                                            onChange={e => changeEndereco(e)}
                                            erro={erroEndereco}
                                            value={endereco}
                                        />
                                        <Input
                                            name="bairro"
                                            type="text"
                                            id="bairroemissor"
                                            label="Bairro"
                                            onChange={e => changeBairro(e)}
                                            erro={erroBairro}
                                            value={bairro}
                                        />
                                        <Input
                                            name="municipio"
                                            type="text"
                                            id="municipioemissor"
                                            label="Municícpio"
                                            onChange={e => changeMunicipio(e)}
                                            erro={erroMunicipio}
                                            value={municipio}
                                        />

                                        <Select
                                            name="estado"
                                            className="select"
                                            label="UF emissor"
                                            id="estadoemissor"
                                            value={estado ? estado.value : estado}
                                            onChange={estado => (changeEstado(estado))}
                                            options={estados}
                                            isClearable={false}
                                            selectUniqueOption={false}
                                            loading={loading}
                                            erro={erroEstado}
                                        />

                                        <Input
                                            id="dataemissaonf"
                                            onChange={e => changeDataEmissao(e)}
                                            label="Data de emissão"
                                            mask="99/99/9999"
                                            value={dataEmissao}
                                            disabled={false}
                                            erro={erroDataEmissao}
                                            type="date"
                                        />

                                        <ContainerRelationOptions>
                                            <Relation
                                                produtos={produtosSelecionados}
                                                quantidades={quantidades}
                                                unidades={unidades}
                                                ncmprodutos={ncmProdutos}
                                                updateProduto={(e) => setProdutos(e)}
                                                updateQuantidade={(e) => setQuantidades(e)}
                                                updateUnidade={(e) => setUnidades(e)}
                                                updateCodigosProduto={(e) => setCodigosProduto(e)}
                                                updateNcmProduto={(e) => setNcmProdutos(e)}
                                                errosNf={props.erros}
                                            />
                                        </ContainerRelationOptions>
                                    </>
                                ) : (
                                    <>
                                        <Input
                                            name="num_nf"
                                            type="text"
                                            id="num_nf"
                                            label="Numero da NFe"
                                            onChange={e => changeNumNf(e)}
                                            value={numNf}
                                        />
                                    </>
                                )}
                            </ContainerForm>
                        </>
                    )}
                </Card>
            </FormContainer>
        </Container>
    );
};

export default PrescriptionStepFour;