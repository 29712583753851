import React, { useState, useEffect } from "react";

import Button from "../button/button_v2";
import Select from "../select/select_v2";
import api from "../..//services/api";
import { store } from "react-notifications-component";
import Input from '../../components/input/input_v2';
import GenericUpper from "../../components/generic-upper";
import { set } from "date-fns";
import { FormContainer } from "./styles";
import { Container } from "react-grid-system";



export const HELPP = "@helpp";
const AddAplicadores = ({ ...props }) => {
    const [title, setTitle] = useState('Cadastre um aplicador!');
    const [step, setStep] = useState(1);
    const [align] = useState("center");

    const [nome, setNome] = useState(props.nome);
    const [cpf, setCpf] = useState(props.cpf);
    // const [registro, setRegistro] = useState(props.registro);

    const [loadingSend, setLoadingSend] = useState(false);



    useEffect(() => {
    }, [step]);

    const sendApplicators = async () => {
        setLoadingSend(true);
        try {
            const payload = {
                nome_aplicador: nome,
                cpf_aplicador: cpf
                // registro_aplicador: registro
            }

            const response = await api.post(`/aplicador`, payload);
            // let aplicadores = response.data.data;
            // aplicadores = formatApplicators(aplicadores);
            // setAplicadores(aplicadores);
            store.addNotification({
                title: "Sucesso!",
                message: `${response.data.message}`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            props.success();
            setLoadingSend(false);
        } catch (e) {
            setLoadingSend(false);
            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors ? e.response.data.errors : "Não há aplicadores cadastrados!" }`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 7000,
                    onScreen: true
                }
            });
            // }
        }
    };

    return (
        <>
            <FormContainer isModal={props.isModal}>
                <Container style={{ width: '100%', paddingLeft: props.isModal ? '0px' : '15px', paddingRight: props.isModal ? '0px' : '15px' }}>
                    {props.isModal === true ? (
                        <>
                            <h4 style={{ textAlign: 'center' }}>{title}</h4>
                            <br />
                        </>
                    ) : (
                        <GenericUpper
                            align={align}
                            title={title}
                        ></GenericUpper>
                    )}

                    <Input
                        name="nome_aplicador"
                        className="text"
                        label="Nome do Aplicador"
                        id="nome_aplicador"
                        type='text'
                        value={nome}
                        onChange={e => setNome(e)}
                    />
                    <Input
                        name="cpf"
                        className="text"
                        label="CPF"
                        id="cpf_aplicador"
                        type='text'
                        value={cpf}
                        onChange={e => setCpf(e)}
                    />
                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                        <Button
                            width='296px'
                            height='50px'
                            value='Enviar'
                            outline={false}
                            disabled={false}
                            onClick={() => sendApplicators()}
                            loading={loadingSend}
                        />
                    </div>
                </Container>
            </FormContainer>

        </>
    );
};
export default AddAplicadores;