import React from 'react';

const IconCrm = () => {

    return(
        <svg
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px"
            width="22" 
            height="22"
            viewBox="0 0 172 172"
            style={{fill: '#000000'}}
        >
            <g
                fill="none"
                fill-rule="nonzero"
                stroke="none" 
                stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none" 
                text-anchor="none" 
                style={{mixBlendMode: 'normal'}}
            >

            <g
                fill="#8a92a2"
            >
            <path 
                d="M143.33276,22.93276h-114.66552c-6.30552,0 -11.46724,5.16 -11.46724,11.46724v103.2c0,6.30552 5.16172,11.46552 11.46724,11.46552h114.66552c6.30552,0 11.46724,-5.16 11.46724,-11.46552v-103.2c0,-6.30724 -5.16172,-11.46724 -11.46724,-11.46724zM143.33276,137.6h-114.66552v-103.2h114.66552z"
            >
            </path>
            <rect
                x="23.333"
                y="26.667" 
                transform="scale(1.72,1.72)" 
                width="13.334" 
                height="6.667"
            >
            </rect>
            <rect
                x="43.333" 
                y="26.667" 
                transform="scale(1.72,1.72)"
                width="13.334" 
                height="6.667"
            >
            </rect>
            <rect
                x="63.333" 
                y="26.667" 
                transform="scale(1.72,1.72)" 
                width="13.334" 
                height="6.667"
            >
            </rect>
            <rect 
                x="23.333" 
                y="40" 
                transform="scale(1.72,1.72)"
                width="13.334" 
                height="6.667"
            ></rect>
            <rect 
                x="43.333" 
                y="40" 
                transform="scale(1.72,1.72)" 
                width="13.334" 
                height="6.667"
            >
            </rect>
            <rect 
                x="63.333" 
                y="40" 
                transform="scale(1.72,1.72)" 
                width="13.334" 
                height="6.667"
            ></rect>
            <rect 
                x="23.333" 
                y="53.334" 
                transform="scale(1.72,1.72)" 
                width="13.334" 
                height="6.666"
            ></rect>
            <rect 
                x="63.333" 
                y="53.334" 
                transform="scale(1.72,1.72)" 
                width="13.334" 
                height="6.666"
            >
            </rect>
            <rect
                x="23.333" 
                y="66.666" 
                transform="scale(1.72,1.72)" 
                width="13.334" 
                height="6.668"
            >
            </rect>
            </g></g>
        </svg>
    );
}

export default IconCrm;
