import React, { useState, useEffect } from "react";
import api from "../../services/api";
import Menos from "../../assets/menos.png";
import Mais from "../../assets/Mais.png";
import { Card } from "./style";
import ReactHtmlParser from 'react-html-parser';

const CardNotificacao = ({ ...props }) => {
    const {
        id,
        titulo_notificacao,
        data,
        texto,
        visu,
    } = props

    const [idId, setidId] = useState('');
    const [titulo, setTitulo] = useState('');
    const [datas, setDatas] = useState('');
    const [textos, setTextos] = useState('');
    const [visualizados, setvisualizado] = useState('');
    const [visible, setVisibleCard] = useState(true);
    const [aberto, setAberto] = useState(false);
    const [mais] = useState(Mais);
    const [menos] = useState(Menos);

    const loadCard = () => {
        if(id, titulo_notificacao, data, texto){
            let fim = data.substr(-8);
            let inicio = data.substr(0, 10);
            let year = inicio.substr(0,4);
            let month = inicio.substr(5,2);
            let day = inicio.substr(-2);
            const defined = (day+"/"+month+"/"+year);
            setidId(id);
            setTitulo(titulo_notificacao);
            setDatas(defined);
            setTextos(texto);
            setvisualizado(visu);
            setVisibleCard(true);

            if(texto.length < 180 && visu === 0) {
                visto(id);
            }
        }

    }

    const openCard = () =>{
        if(aberto === false){
            visto(id)
        }
        setAberto(!aberto);
    }

    const visto = async (id) => {
        try {
            const response = await api.post(`notificacoes/${id}`);
            console.log(response);
            setvisualizado(1);
        } catch (error) {
        }
    }
   
    useEffect(() => {
        loadCard();
    }, [id])
    
    useEffect(() => {
        console.log(visualizados);
    }, [visualizados])

    return (
        <>
            <Card isMobile={props.isMobile} key={id.toString()} aberto={aberto} tamanho={(textos.length > 140) ? true : false} >
            <div className="row-card">
                <div className="data">
                    <p>{datas}</p>
                    {visualizados === 0 ? 
                    (<>
                        <div className="Oval"/>
                    </>) : (<>

                    </>)}
                </div>
                <div className="titulo">
                    <p><b>{titulo}</b></p>
                </div>

                <div className="texto" aberto={aberto}>
                    <p>{ReactHtmlParser(textos)}</p>
                </div>

                <div className="img">
                    {(textos.length > 120 && window.screen.width <= 500) ? (<>
                        {aberto ? (<>
                            <img src={menos} width="20" height="20" onClick={openCard}/>
                        </>) : (<>
                            <img src={mais}  width="20" height="20" onClick={openCard}/>
                        </>)}
                    </>):(textos.length > 140 && window.screen.width > 500) && (
                        <>
                            {aberto ? (<>
                                <img src={menos} width="20" height="20" onClick={openCard}/>
                            </>) : (<>
                                <img src={mais}  width="20" height="20" onClick={openCard}/>
                            </>)}
                        </>
                    )}
                </div>
                
            </div>        
            </Card >
        
                
        </>
    );
}
export default CardNotificacao;
