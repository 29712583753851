import React from 'react'

const IconSchedule = ({ ...props }) => {
    
    return (
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            viewBox="0 0 172 172"
            {...props}
        >
            <g 
                fill="none" 
                fill-rule="nonzero" 
                stroke="none" 
                stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none" 
                text-anchor="none" 
                style={{
                    mixBlendMode: 'normal'
                }}
            >
                {/* <path d="M0,172v-172h172v172z" fill="none"></path> */}
                <g fill="#8a92a2">
                    <path d="M32.25,10.75v10.75h-5.375c-8.86035,0 -16.125,7.26465 -16.125,16.125v96.75c0,8.86035 7.26465,16.125 16.125,16.125h107.5c8.86035,0 16.125,-7.26465 16.125,-16.125v-96.75c0,-8.86035 -7.26465,-16.125 -16.125,-16.125h-5.375v-10.75h-10.75v10.75h-75.25v-10.75zM26.875,32.25h5.375v10.75h10.75v-10.75h75.25v10.75h10.75v-10.75h5.375c3.02344,0 5.375,2.35156 5.375,5.375v16.125h-118.25v-16.125c0,-3.02344 2.35156,-5.375 5.375,-5.375zM21.5,64.5h118.25v69.875c0,3.02344 -2.35156,5.375 -5.375,5.375h-107.5c-3.02344,0 -5.375,-2.35156 -5.375,-5.375zM32.25,75.25v10.75h10.75v-10.75zM53.75,75.25v10.75h10.75v-10.75zM80.625,75.25c-2.98145,0 -5.375,2.39355 -5.375,5.375v43c0,2.98145 2.39355,5.375 5.375,5.375h43c2.98145,0 5.375,-2.39355 5.375,-5.375v-43c0,-2.98145 -2.39355,-5.375 -5.375,-5.375zM86,86h32.25v32.25h-32.25zM32.25,96.75v10.75h10.75v-10.75zM53.75,96.75v10.75h10.75v-10.75zM32.25,118.25v10.75h10.75v-10.75zM53.75,118.25v10.75h10.75v-10.75z"></path>
                </g>
            </g>
        </svg>
    )
}

export default IconSchedule;