import React, { useState, useEffect, useRef } from 'react';
import { H3, Label, FilterArea, SelectArea, DataFilter, ChartArea, Loading, IconeBotao } from './style';
import api from '../../services/api';
import DateRange from '../date-range';
import { Line } from 'react-chartjs-2'
import Button from '../button/button_v2';
import { store } from 'react-notifications-component';
import IconeBaixar from '../../assets/iconeBaixar.svg';


const MaiorComercializacao = ({ ...props }) => {

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 365));

    const [dataFinal, setDataFinal] = useState(today.toISOString().slice(0, 10));
    const [maiorEmissao, setMaiorEmissao] = useState("");

    const [ref] = useState(useRef());

    const searchInitialData = async () => {
        try {
            const responseC = await api.post('/relatorios/receitas/comercializacao', {
                dtFinal: dataFinal,
            });
            setMaiorEmissao(responseC.data.data.meses);
        } catch (error) {
            
        }
    }

    const comercializacao = async () => {
       
    }

    useEffect(() => {
        searchInitialData();
    }, [])

    // useEffect(() => {
    //     updateData();
    // }, [dataInicial, dataFinal])
    
    return (
        <>
            <div>
                <IconeBotao>
                    <div className='card-titulo'><h2 style={{
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontFamily: 'Lato',
                        fontSize: '10px',
                        color: '#8a92a2',
                        paddingTop: '10px',
                        marginBottom: '0px'
                    }}>maior comercialização</h2></div>
                    <div className='icons' style={{
                        display:'flex'
                    }}></div>
                </IconeBotao>
            </div>
            {
                maiorEmissao !== "" ? (
                    <>
                        <p style={{fontSize: '10px'}}>baseada na quantidade de receitas emitidas</p>
                        <p style={{
                            fontSize: '32px',
                            fontWeight: '600',
                            paddingTop: '2px',
                            marginBottom: '0px',
                            textAlign: props.isMobile == false ? 'left' : 'center',
                        }}>{maiorEmissao}</p>
                    </>
                ) : (<Loading style={{
                    width: '30px',
                    height: '30px',
                    marginTop: '20px',
                    marginBottom: '6px'
                }}/>)
            }
        </>
    )
}

export default MaiorComercializacao;
