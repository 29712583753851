import React, { useState, useEffect } from "react";
import { Container } from "react-grid-system";
import { FormContainer } from "./styles";
import GenericUpper from "../../components/generic-upper";
import AddLocalAplicacao from "../../components/produtores/fazendas";

const AddLocal = ({ ...props }) => {
    // STATE
    const [idProdutor, setIdProdutor] = useState('');

    //USEEFFECT
    useEffect(() => {
        if (props.isModal) {

        } else {
            window.scrollTo(0, 0);
        }
    },[]);
    
    useEffect(() => {
        const idProdutor = props.match.params.id;
        setIdProdutor(idProdutor);
    }, [props.match.params.id])

    return (
        <>
            {props.isModal ? (
                <>
                    <h4 style={{ textAlign: 'center' }}>Cadastre um local de aplicação</h4>
                </>
            ) : (
                <GenericUpper 
                    title="Cadastre um local de aplicação"
                    help={true}
                    text={"Dúvidas para cadastrar um produtor ou local de aplicação?"}
                    toSearch={'https://agroreceita.com.br/como-cadastrar-produtores-e-locais-de-aplicacao/'}
                    textToSearch={'Clique aqui e acesse o tutorial!'}
                >
                </GenericUpper>
            )}

            <FormContainer { ...props }>
                <Container>
                    <AddLocalAplicacao 
                        isFocus={true}
                        isModal={props.isModal ? props.isModal : false}
                        produtor={idProdutor}
                        loc={props}
                        returnTo={`/produtores/${idProdutor}`}
                        updateModal={() => props.update()}
                    />
                </Container>
            </FormContainer>
        </>
    )

}
export default AddLocal;
