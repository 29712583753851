import React from "react";
import { withRouter } from "react-router-dom";
import { UpperContainer, Plans } from "./styles";
import { Container } from "react-grid-system";
import Button from '../button';


import { ScreenClassRender } from "react-grid-system";


class Header extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        location: null,
        isMobile: false,
        screenSize: [],
        isTest: false,
        isPrePago: false,
        logincompra: /\/login-compra\//g,
        simulacaocompra: /\/simulacaoplano\//g,
    };

    redirectTo = location => {
        this.props.history.push(location);
    };

    getScreenSize = screenSize => {
        const sizeToHide = ["xl", "lg"];
        const hide = sizeToHide.includes(screenSize);
        const prevScreenSize = this.state.screenSize;

        if (prevScreenSize !== screenSize) {
            if (hide) {
                this.setState({ screenSize, isMobile: false });
            } else {
                this.setState({ screenSize, isMobile: true });
            }
        }
    };

    getPlan = () => {
        const user = JSON.parse(localStorage.getItem('@userinfo')); 
        const plano = user.contrato.agro_plan_config.agro_plan_id;
        if (plano == 1) {
            this.setState({isTest: true});
        }else if (plano == 6) {
            this.setState({isPrePago: true});
        }
    };

    componentDidMount() {
        this.getPlan();
    };

    render() {
        const { isMobile, isTest, isPrePago, logincompra, simulacaocompra } = this.state;
        return (
            <UpperContainer isMobile={isMobile} isTest={isTest} isPrePago={isPrePago}>
                <ScreenClassRender
                    render={ScreenClass => (
                        <>
                            {this.getScreenSize(ScreenClass)}
                            <Container style={{ marginLeft: '0', marginRight: '0', paddingLeft: '0', paddingRight: '0', maxWidth: '', width: '100%', margin: 'auto' }}>
                                <div className="header-desc" isMobile={isMobile} isTest={isTest} isPrePago={isPrePago}>
                                    <>
                                        <Plans
                                            isMobile={isMobile}
                                            show={
                                                isTest ? 
                                                    window.location.pathname === '/login' ? 
                                                    false : window.location.pathname === '/compra/sucesso' ? 
                                                    false : window.location.pathname.search(logincompra) !== -1 ?
                                                    false : window.location.pathname.search(simulacaocompra) !== -1 ?
                                                    false : true 
                                                : false
                                            }
                                            onClick={() => window.location = '/planos'}
                                        >
                                            ASSINE AGORA
                                        </Plans>
                                        
                                        <Plans
                                            isMobile={isMobile}
                                            style={{
                                                marginLeft: '-24px'
                                            }}
                                            show={
                                                isPrePago ? 
                                                    window.location.pathname === '/login' ? 
                                                    false : window.location.pathname === '/compra/sucesso' ? 
                                                    false : window.location.pathname.search(logincompra) !== -1 ?
                                                    false : window.location.pathname.search(simulacaocompra) !== -1 ?
                                                    false : true 
                                                : false
                                            }
                                            onClick={() => window.location = '/comprar_receitas'}
                                        >
                                            COMPRAR RECEITAS
                                        </Plans>
                                    </>
                                    <div className="text" isTest={isTest} isPrePago={isPrePago}>
                                        <h1>Olá, {this.props.title}</h1>
                                    </div>
                                </div>
                            </Container>
                        </>
                    )}
                />


            </UpperContainer>
        );
    }
}

export default withRouter(Header);