import React from "react";
import { withRouter } from "react-router-dom";

import { Container } from "react-grid-system";
import api from "../../services/api";
import GenericUpper from "../../components/generic-upper";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import Button from "../../components/button/button_v2";
import ModalExportar from "../../components/modal/modalArtExportar";
import ModalYesNo from "../../components/modal/modalYesNo";
import Modal from "../../components/modal/modalGeneric";
import { ArtsAddContainer } from "./styles";
import { store } from "react-notifications-component";

import CardGeral from "../../components/card-geral/index";


export const USER = "@userinfo";


class EditContracts extends React.Component {
    state = {
        id: "",
        loading: false,
        hideButton: false,
        limit: 5,
        offSet: 0,
        newOffSet: "",
        contracts: [],
        addModalShow: false,
        showModalText: false,
        size_screen: window.screen.width,
        estado: '',
        ordemInicial: '',
        ordemFinal: '',
        textModalYesNo: '',
        loadingExport: false,
        showModalAlert: false,
    };



    componentDidMount() {
        setInterval(async()=>{
            this.setState({size_screen: window.screen.width});
        }, 990);
        this.getArtState();  
        this.getContracts();
    };

    getContracts = async () => {
        const id = this.props.match.params.id;
        this.setState({ id });
        const { limit } = this.state;
        try {
            const response = await api.get(`/arts/${id}/contratos?limit=` + limit);
            const contracts = response.data.data;
            this.setState({ contracts, loading: false, hideButton: false });

        } catch (err) {
            this.setState({ loading: false, hideButton: true });
        }

    };

    getArtState = async () => {
        try {
            const id = this.props.match.params.id;
            const response = await api.get(`/arts/${id}`);
            this.setState({ estado: response.data.data.crea.estado });
            
        } catch (error) {
        }
        
    };

    redirect = () => {
        this.props.history.push(`/newcontract/${this.props.match.params.id}`);
    };

    formatData = (data) => {
        var date = data;
        let now = new Date(date);

        let day = now.getDate();
        if (day <= 9) {
            day = "0" + day;
        }
        let month = now.getMonth();
        let year = now.getFullYear();
        month = month + 1;
        if (month <= 9) {
            month = "0" + month;
        }

        return day + "/" + month + "/" + year;

    };

    loadMore = async () => {
        const id = this.props.match.params.id;
        this.setState({ id });
        this.setState({ loading: true, hideButton: true });
        const { limit, offSet, contracts } = this.state;
        const newOffset = limit + offSet;

        try {
            const response = await api.get(
                `/arts/${id}/contratos?limit=` + limit + "&offset=" + newOffset
            );
            let newContract = response.data.data;
            const contractsConCat = contracts.concat(newContract);
            this.setState({
                contracts: contractsConCat,
                offSet: newOffset,
                hideButton: false
            });
        } catch (erro) {
            this.setState({
                hideButton: true
            });
        }
        this.setState({ loading: false });
    };

    addModalClose = e => {
        this.setState({ addModalShow: false });
    };

    ModalYesNoClose = e => {
        this.setState({ showModalText: false });
    }

    updateExportar = async (numeroInicial, numeroFinal) => {
        this.setState({loadingExport : true});
        this.addModalClose();
        this.setState({ ordemInicial: numeroInicial });
        this.setState({ ordemFinal: numeroFinal });
        const payload = {
            art_id: this.state.id,
            num_ordem_inicial: parseInt(numeroInicial),
            num_ordem_final: numeroFinal
        }

        try {
            
            const response = await api.post(`/creasp`, payload);
            if(response.data.message == "Informar parâmetro renew=ok"){
                this.setState({ textModalYesNo: response.data.data });

                if(this.state.textModalYesNo != ''){
                    this.setState({ showModalText: true });
                }else{
                }
                return;
            }else{
                const blob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.download = "exportarDadosCreasSP.txt";
                link.href = url;
                link.click();
            }

        this.setState({loadingExport : false});
            
        } catch (error) {
            this.setState({loadingExport : false});
            
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    updateYesNo = async (resposta) => {
        if(resposta == 1){
            try {
                const payload = {
                    art_id: this.state.id,
                    num_ordem_inicial: parseInt(this.state.ordemInicial),
                    num_ordem_final: parseInt(this.state.ordemFinal)
                }
    
                const responseExportar = await api.post(`/creasp?renew=ok`, payload, {
                    responseType: "blob"
                });
                const blob = new Blob([responseExportar.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.download = "exportarDadosCreasSP.txt";
                link.href = url;
                link.click();
                this.setState({ showModalText: false });
                this.setState({loadingExport : false});

            } catch (error) {
                this.setState({ showModalText: false });
                this.setState({loadingExport : false});
            }
        }else{
            this.setState({ showModalText: false });
            this.setState({loadingExport : false});

        }
    }

    exportCrea = () => {
        let user = JSON.parse(localStorage.getItem(USER));

        if(user.contract_software_id === 6){
            this.setState({ showModalAlert: true })
        } else {
            this.setState({ addModalShow: true });
        }
    }

    updateExport = () => {
        this.setState({ showModalAlert: false });
        this.setState({addModalShow: true});
    }

    render() {
        const { contracts, hideButton, loading, loadingExport, addModalShow, showModalText, size_screen } = this.state;

        return (
            <>
                <GenericUpper
                    title="ARTs/TRT"
                    subtitle="Gerencie os contratos da sua ART/TRT"
                ></GenericUpper>
                <ArtsAddContainer>
                    <Container>

                    {size_screen <= 768 ? (
                        <>
                            <CardGeral
                                titulos={ ["CONTRATOS","NOME DO PRODUTOR","NOME DA PROPRIEDADE", "DATA DE INÍCIO","DATA DE TERMINO"]}
                                valores={contracts}
                            />
                        </>
                    ):(

                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome do Produtor</th>
                                    <th>Nome da Propriedade</th>
                                    <th>Data de Início</th>
                                    <th>Data de Termino</th>
                                </tr>
                            </thead>
                            <TBody>
                                {contracts.map(contract => (
                                    <tr
                                        onClick={e =>
                                            this.props.history.push(`/editcontract/${contract.id}`)
                                        }
                                        key={contracts.id}
                                    >
                                        {contract.status == 0 ? <td><strike>{contract.nome_produtor}</strike></td> : <td>{contract.nome_produtor}</td>}
                                        {contract.status == 0 ? <td><strike>{contract.nome_local_aplicacao}</strike></td> : <td>{contract.nome_local_aplicacao}</td>}
                                        {contract.status == 0 ? <td><strike>{this.formatData(contract.data_inicio)}</strike></td> : <td>{this.formatData(contract.data_inicio)}</td>}
                                        {contract.status == 0 ? <td><strike>{this.formatData(contract.data_termino)}</strike></td> : <td>{this.formatData(contract.data_termino)}</td>}
                                    </tr>
                                ))}
                            </TBody>
                        </Table>

                    )}

                        <div className="button-finish" style={{  }}>
                            
                            <div className="btn">
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='ADICIONAR CONTRATOS'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => this.redirect()}
                                />
                            </div>

                            <div className="btn">
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='VER MAIS'
                                    outline={loading ? false : true}
                                    disabled={false}
                                    onClick={() => this.loadMore()}
                                    loading={loading}
                                />
                            </div>

                            <div className="btn">
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='EXPORTAR DADOS - CREA SP'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => this.exportCrea()}
                                    loading={loadingExport}
                                />
                            </div>
                        </div>

                        <Modal
                            show={this.state.showModalAlert}
                            onHide={false}
                        >
                            <h4 style={{
                                textAlign: 'center'
                            }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

                            <p style={{
                                textAlign: 'center',
                                marginTop: '20px',
                            }}>
                                Para não comprometer o controle de suas receitas agronômicas essa função só pode ser utilizada nos planos Light e Pro. Um arquivo de teste será gerado para que você conheça essa funcionalidade. Entre em contato com nosso suporte e faça o upgrade do seu plano para desbloquear esse recurso
                            </p>

                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                                <Button
                                    width='296px'
                                    height='50px'
                                    value='ENTENDI'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => this.updateExport()}
                                    loading={false}
                                />
                            </div>

                        </Modal>

                        {addModalShow == true ? (
                            <ModalExportar
                                show={addModalShow}
                                onHide={this.addModalClose}
                                update={this.updateExportar}
                                title="Exportar dados - CREA SP"
                                artId={this.state.id}
                            ></ModalExportar>
                        ):null}

                        {this.state.textModalYesNo != "" ? (
                            <>
                                {showModalText == true ? (
                                    <ModalYesNo
                                        show={showModalText}
                                        onHide={this.ModalYesNoClose}
                                        title="Exportar dados - CREA SP"
                                        text={this.state.textModalYesNo}
                                        update={this.updateYesNo}
                                    ></ModalYesNo>
                                ):(null)}
                            </>
                        ):(null)}

                    </Container>
                </ArtsAddContainer>
            </>
        );
    }
}
export default withRouter(EditContracts);
