import styled from "styled-components";

export const Card = styled.div`
    background-color: #FFF;
    width: 500px;
    border-radius: 10px;
    margin: auto;
    margin-left: ${props => (props.isMobile ? "-85px" : "")};
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-top: 10px;
    padding-bottom: 15px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);

    .row-card{
        .img{
            margin-top: 10px;
            margin-left: 400px;
            cursor: pointer;
        }


        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 36px;
        margin-top: -15px;

        .data{
            color: #bac1cf;
            margin-top: 18px;
            margin-left: 1px;
            font-size: 14px;
            .Oval {
                width: 5px;
                margin-left: 420px;
                margin-top: -25px;
                margin-bottom: 15px;
                border-radius: 50%;
                height: 5px;
                border: solid 1px #ff5252;
                background-color: #ff5252;
                text-align: center;
                font-size: 10px;
              }
        }
        .titulo{
            width: 440px;
            font-family: Lato;
            font-size: 20px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #303848;

            p{
                padding: 16px 0px 8px 0px;
            }
        }
        .texto{
            width: 440px;
            transition: max-height 1s;
            max-height: ${props => (props.aberto ? "1000px" : "40px" )};
            font-family: Lato;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.43;
            word-wrap: break-word;
            overflow: hidden;
            letter-spacing: normal;
            color: #303848;
        }
    }


    @media only screen and (max-width: 500px){
        background-color: #FFF;
        width: 310px;
        border-radius: 10px;
        margin: auto;
        margin-left: ${props => (props.isMobile ? "-85px" : "")};
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 10px;
        padding-bottom: 15px;

        .row-card{
            .img{
                margin-top: 10px;
                margin-left: 240px;
                cursor: pointer;
            }

            width: 75%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 36px;
            margin-top: 0px;
    
            .data{
                color: #bac1cf;
                margin-top: 18px;
                margin-left: -14px;
                font-size: 14px;
                .Oval {
                    width: 5px;
                    margin-left: 260px;
                    margin-top: -28px;
                    margin-bottom: 15px;
                    border-radius: 50%;
                    height: 5px;
                    border: solid 1px #ff5252;
                    background-color: #ff5252;
                    text-align: center;
                    font-size: 10px;
                  }
            }
            .titulo{
                margin-left: -14px;
                max-width: 270px;
                font-family: Lato;
                font-size: 20px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #303848;
            }
            .texto{
                margin-left: -14px;
                transition: max-height 1s;
                max-height: ${props => (props.aberto ? "800px" : "40px" )};
                font-family: Lato;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.43;
                word-wrap: break-word;
                overflow: hidden;
                letter-spacing: normal;
                color: #303848;     
                p{
                    width: 270px;
                    height: ${props => !props.aberto && '40px'};
                    white-space: ${props => !props.aberto && 'nowrap'};
                    overflow: ${props => !props.aberto && 'hidden'}; 
                    text-overflow: ${props => !props.aberto && 'ellipsis'};
                }            
            } 
                     
        }       
    }

`;