import React, { useState, useEffect } from "react";

import Select from "../../select/select_v2";
import Button from "../../button/button_v2";
import ModalText from "../../modal/modalText";
import { store } from "react-notifications-component";
import { Container } from "react-grid-system";


import { FormContainer } from "./styles";

import estadosP from "../../../services/estados";
import { Row, Col } from "react-grid-system";

const ArtsOne = ({ ...props }) => {

    const { update, next } = props;
    const [estadoId, setEstado] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [step] = useState(1);
    const [showStep3, setShowStep3] = useState(false);
    const [estadoProps, setEstadoProps] = useState(estadosP);
    const [estados, setEstados] = useState([]);
    const [tipo, setTipo] = useState('');
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [erroArt, setErroArt] = useState(false);
    const [erroArtMsg, setErroArtMsg] = useState('');
    
    const [erroEstado, setErroEstado] = useState(false);
    const [erroEstadoMsg, setErroEstadoMsg] = useState('');



    const nextStep = async e => {

        if (!estadoId || !tipo) {

            if (!estadoId) {
                setErroEstado(true);
                setErroEstadoMsg('Informe o estado');
            }

            if (!tipo) {
                setErroArt(true);
                setErroArtMsg('O campo não pode estar em branco');
            }

            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos em vermelho",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }else{
            if(estadoId){
                update(estadoId, step);
                next();
            }
        }
    };

    const openModalHelp = e => {
        setShowModalHelp(true);
    }

    useEffect(() => {
        setErroEstado(false);
        if(estadoId){
            if(estadoId.value === "SP"){
                setShowStep3(true);
            }else{
                setShowStep3(false);
            }
            setDisabled(false);
        }
    },[estadoId])

    useEffect(() => {
        setErroArt(false);
    },[tipo])

    useEffect(() => {
        if(estadoProps){
            let addNacional = [{ value: "NACIONAL", label: "Nacional" }];
            for (let i = 0; i < estadoProps.length; i++) {
                addNacional = [...addNacional, estadoProps[i]];
            }
            setEstados(addNacional);
        }
    },[estadoProps])

    useEffect(() => {
        if (props.isModal === true && props.estado) {
            setEstado(props.estado);
        }
    },[props])


    return (
        <>
            <FormContainer {...props}>
                <Container>
                    <div className="steps">

                        <div className="btn-step-selected">
                            <label>1</label>
                        </div>

                        <div className="btn-step">
                            <label>2</label>
                        </div>
                        
                        {showStep3 == true ? (
                            <div className="btn-step">
                                <label>3</label>
                            </div>
                        ) : null}

                    </div>
                    
                    <Row>
                        <Col>
                            <form>
                                <Select
                                    name="tipo"
                                    className="select"
                                    label="Tipo"
                                    id="tipo"
                                    estado={false}
                                    onChange={tipo =>
                                        tipo ? setTipo(tipo) : setTipo("")
                                    }
                                    options={[{value: 'art', label: 'ART'}, {value: 'trt', label: 'TRT'}]}
                                    help={true}
                                    helpClick={() => openModalHelp()}
                                    erro={erroArt}
                                    messageErro={erroArtMsg}
                                />
                                    
                                <Select
                                    name="estado"
                                    className="select"
                                    label="UF de registro"
                                    id="estado"
                                    estado={true}
                                    onChange={estado =>
                                        estado ? setEstado(estado) : setEstado("")
                                    }
                                    options={estados.filter(function (estado) {
                                        return tipo.value === 'art' ? estado.value !== "RJ" && estado.value !== 'NACIONAL' : tipo.value === 'trt' ? estado.value === 'NACIONAL' : estado.value;
                                    })}
                                    value={estadoId.value || estadoId}
                                    disabled={tipo !== '' && tipo.value ? false : true}
                                    erro={erroEstado}
                                    messageErro={erroEstadoMsg}
                                />

                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                    <Button 
                                        width='100%'
                                        height='50px'
                                        value='AVANÇAR'
                                        outline={true}
                                        disabled={false}
                                        onClick={() => nextStep()}
                                        loading={false}
                                    />
                                </div>
                                
                            </form>
                            
                            <ModalText
                                show={showModalHelp}
                                onHide={() => setShowModalHelp(false)}
                                text={tipo.value === 'art' ? "Anotação de responsabilidade técnica válida para profissionais inscritos no CREA" : tipo.value === 'trt' ? "Termo de responsabilidade técnica válido para técnicos agrícolas inscritos no CFTA" : "ART: Anotação de responsabilidade técnica válida para profissionais inscritos no CREA. \n - \n TRT: Termo de responsabilidade técnica válido para técnicos agrícolas inscritos no CFTA"}
                                btnOk={false}
                            />   
                        </Col>
                    </Row>
                </Container>
            </FormContainer>
        </>
    );
};
export default ArtsOne;
