import React, {useEffect}  from "react";

import Select2 from "react-select";
import { Wrapper } from "./styles";

const SelectSearch = ({ ...props }) => {
    const styles = {
        control: provided => ({
            ...provided,
            borderRadius: "25px",
            height: "50px",
            width: "270px",
            margin: "auto",
            marginTop: "15px",
            border: "none",
            boxShadow: "0 0 20px 0 rgba(29, 33, 41, 0.05)",
            padding: "0 10px 5px",
            fontWeight: "bold",
            backgroundColor: "#f3f3f5",
            zIndex: "2"
        }),
        placeholder: provided => ({
            ...provided,
            color: "#ced2dc"
        })
    };

    return (
        <Wrapper>

            <Select2
                className="select"
                {...props}
                value={ props.options ? props.options.find(option => {          
                            return option.value === props.value;
                        })
                        : props.value
                }
                isDisabled={props.disabled}
                isMobile={props.isMobile} 
                noOptionsMessage={() => "Não há opções disponíveis."}
                styles={styles}
            >
                {}

            </Select2>
        </Wrapper>
    );
};

export default SelectSearch;
