import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import api from '../../services/api';
import googlemaps from '../../services/googlemaps';
import { apiprevmet3 } from '../../services/inmet_api';
import { AreaInfo, Card, ChartArea, Col2, Col5, Loading, Row, Text, Title } from './style';
import { Line } from 'react-chartjs-2';
import { formatPrevisaoGrafico } from '../../services/format';
export const AGRORECEITA_USER_INFO_LOCATION = '@agroreceita_user_info_location';

const Umidade = ({ ...props }) => {

    const { ibge } = props;

    const [previsao, setPrevisao] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [objKeys, setObjKeys] = useState([]);

    const [dataGraphPrev, setDataGraphPrev] = useState([]);
    const [optionsGraphPrev, setOptionsGraphPrev] = useState([]);
    const [showChartPrevisao, setShowChartPrevisao] = useState(false);

    const [dataGraphUmid, setDataGraphUmid] = useState([]);
    const [optionsGraphUmid, setOptionsGraphUmid] = useState([]);
    const [showChartUmidade, setShowChartUmidade] = useState(false);

    const [loading, setLoading] = useState(false);


    const getPrevisaoTempo = async (ibge) => {
        // setLoading(true);

        try {
            setLoading(true)
            const response = await apiprevmet3.get(`/previsao/${ibge}`);

            if (response) {
                setLoading(false)
                let prev = response.data;
                const p = formatPrevisaoGrafico(prev[ibge]);

                setPrevisao(p);


                // setLoading(false);

            }
        } catch (error) {
            setLoading(false)
            console.log(error);

            // store.addNotification({
            //     title: "Erro!",
            //     message: "Erro INMET",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }

    const mountChartUmidade = () => {
        setDataGraphUmid({
            labels: previsao.labels,
            datasets: [
                {
                    label: 'Mínima',
                    data: previsao.umidade_min,
                    backgroundColor: 'rgb(88, 155, 255, 0.4)',
                    borderColor: 'rgb(88, 155, 255, 1)',
                    borderWidth: 1,
                    tension: 0.3,
                },
                {
                    label: 'Máxima',
                    data: previsao.umidade_max,
                    backgroundColor: 'rgb(255, 52, 76, 0.4)',
                    borderColor: 'rgb(255, 52, 76, 1)',
                    borderWidth: 1,
                    tension: 0.3,
                },
            ],
        });

        setOptionsGraphUmid({
            responsive: true,

            indexAxis: 'x',
            scales: {
                x: {
                    ticks: {
                        // color: 'black',
                        font: {
                            // size: 14,
                            // color: 'black'
                        },
                        stepSize: 1,
                        // mirror: true,
                    },
                },
            },
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: `Umidade mínima e máxima do ar em %`,
                },
            },
        });

        setShowChartUmidade(true);
    }

    useEffect(() => {
        getPrevisaoTempo(ibge);
    }, [ibge])

    useEffect(() => {
        if (previsao !== '') {
            mountChartUmidade();
        }
    }, [previsao]);

    return (
        <>
            <ChartArea>
                {loading ? (
                    <Loading />
                ) : (
                    <>
                        {showChartUmidade ? (
                            <Line options={optionsGraphUmid} data={dataGraphUmid} />
                        ) : (
                            <><p style={{ width: "100%", height: "100%", paddingTop: "50px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: "12px", color: "#8A92A2", textAlign: "center" }}>Não foi possível carregar os dados de umidade.</p></>
                        )}
                    </>
                )}
            </ChartArea>
        </>
    )
}

export default Umidade;
