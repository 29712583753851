import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import api from '../../services/api';
import ImageSearch from "../../assets/valida.svg";

import { store } from "react-notifications-component";

import GenericUpper from "../../components/generic-upper";
import { Container, Form, Row, PrescriptionCard, Subtitle, Title, Label, Value } from './styles';
import Input from '../../components/input/input_v2';
import Button from '../../components/button/button_v2';
import { formatDateTime } from '../../services/formatDate';
import Footer from '../../components/footer';

export const compendioG = "@compendioG";

const ValidacaoReceita = ({ ...props }) => {


    const [codigoValidacao, setCodigoValidacao] = useState('');
    const [erroCodigoValidacao, setErroCodigoValidacao] = useState(false);
    const [erroCodigoValidacaoMsg, setCodigoValidacaoMsg] = useState('');
    const [prescription, setPrescription] = useState([]);

    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState('');

    const changeCodigoValidacao = (e) => {
        setErroCodigoValidacao(false);
        setCodigoValidacao(e);
    }

    const validate = async () => {
        setLoading(true);
        try {
            const response = await api.post(`/receita/validar`, {
                hash: codigoValidacao
            });

            if (response) {
                setPrescription(response.data.data);
                window.scrollTo({
                    top: 400,
                    left: 0,
                    behavior: 'smooth'
                });
                setLoading(false);
            }
        } catch (error) {

            setLoading(false);

            if (error == 'Error: Network Error') {

            } else {
                setPrescription([]);
    
                setErroCodigoValidacao(true);
    
                setCodigoValidacaoMsg(error.response.data.message);
    
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const keyInput = (e) => {
        if (e === 'Enter') {
            validate();
        }
    }

    useEffect(() => { 
        localStorage.setItem(compendioG, 0);

        let search = props.location.search;

        if (search) {
            search = search.split('?');
            search = search[1];

            if (search) {
                setParams(`?${search}`);
            }
        }
    }, [])


    return (
        <>
            <GenericUpper 
                isSite={true}
                title={'Validador de receitas'}
                subtitle={'Informe o código de validação para validar receitas emitidas pelo AgroReceita.'}
                params={params}
            />

            <Container>
                <Form isMobile={props.isMobile}>
                    <Input 
                        label="Código de validação"
                        name="codigo-validacao"
                        className="input"
                        id="codigo"
                        onChange={e => changeCodigoValidacao(e)}
                        maxlength={21}
                        erro={erroCodigoValidacao}
                        messageErro={erroCodigoValidacaoMsg}
                        value={codigoValidacao}
                        onKeyDown={e => keyInput(e)}
                    />

                    <div 
                        className="button-finish" 
                        style={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'space-evenly',
                            alignItems: 'center', 
                            marginTop: '10px',
                            marginBottom: props.isModal ? '0px' : '40px',
                        }}
                    >
                        <Button 
                            width='100%'
                            height='50px'
                            value='VALIDAR'
                            outline={false}
                            disabled={codigoValidacao ? false : true}
                            onClick={() => validate()}
                            loading={loading}
                        />
                    </div>

                </Form>
                
                {prescription.length !== 0 ? (
                    <PrescriptionCard>
                        <Title>Receita encontrada</Title>
                        <Subtitle>A seguinte receita foi emitida pelo AgroReceita</Subtitle>
                        
                        <a href={prescription.receita_pdf} target='_blank' style={{ textDecoration: 'none'}}> Visualizar receita completa </a>
                        
                        <hr />

                        <Row justifyContent="flex-start">
                            <div>
                                <Label>Status</Label>
                                <Value>Receita assinada <u>{prescription.assinatura === 'E' ? 'eletrônicamente ' : prescription.assinatura === 'D' ? 'digitalmente ' : 'manualmente '}</u> mediante senha de acesso, pessoal e intransferivel.</Value>
                            </div>
                        </Row>

                        <hr />

                        <Row justifyContent="flex-start" style={{marginBottom: '0px'}}>
                            <h4 style={{margin: '0px', fontSize: '14px'}}>Assinada por:</h4>
                        </Row>

                        <Row justifyContent="flex-start">
                            <div style={{marginRight: '40px'}}>
                                <Label>Responsável técnico</Label>
                                <Value>{prescription.agronomo_nome}</Value>
                            </div>

                            <div>
                                <Label>Data</Label>
                                <Value>{formatDateTime(prescription.created)}</Value>
                            </div>
                        </Row>

                        <Row justifyContent="space-between">
                            <div>
                                <Label>CPF</Label>
                                <Value>{prescription.agronomo_cpf}</Value>
                            </div>
                            <div>
                                <Label>CREA</Label>
                                <Value>{prescription.agronomo_crea}</Value>
                            </div>
                            <div>
                                <Label>ART</Label>
                                <Value>{prescription.numero_art}</Value>
                            </div>
                        </Row>

                        <hr />

                        <Row justifyContent="flex-start" style={{marginBottom: '0px'}}>
                            <h4 style={{margin: '0px', fontSize: '14px'}}>Usuário:</h4>
                        </Row>

                        <Row justifyContent="flex-start">
                            <div style={{marginRight: '40px'}}>
                                <Label>E-mail</Label>
                                <Value>{prescription.user.username}</Value>
                            </div>
                        </Row>
                        
                        <Row justifyContent="space-between">
                            <div>
                                <Label>Nome</Label>
                                <Value>{prescription.user.name}</Value>
                            </div>

                            {prescription.user.contact.cargo && prescription.user.contact.cargo !== 'S/N' && (
                                <div>
                                    <Label>Cargo</Label>
                                    <Value>{prescription.user.contact.cargo}</Value>
                                </div>
                            )}
                            
                            {prescription.user.contact.telefone && prescription.user.contact.telefone !== 'S/N' && (
                                <div>
                                    <Label>Telefone</Label>
                                    <Value>{prescription.user.contact.telefone}</Value>
                                </div>
                            )}
                        </Row>
                        
                        <Row justifyContent="flex-start">
                            <div style={{marginRight: '40px'}}>
                                <Label>IP</Label>
                                <Value>{prescription.ip}</Value>
                            </div>

                            {prescription.geolocation_lat && prescription.geolocation_lng && (
                                <div>
                                    <Label>Geolocalização</Label>
                                    <Value>{prescription.geolocation_lat}, {prescription.geolocation_lng}</Value>
                                </div>
                            )}
                        </Row>
                    </PrescriptionCard>
                ) : (
                    <div className="info" style={{
                        marginBottom: '50px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: '350px'
                    }}>
                        <div className="text" style={{
                            fontFamily: 'Lato',
                            fontSize: '25px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: '#bac1cf'
                        }}>
                            <label>Informe o código de validação no campo acima!</label>
                        </div>

                        <div className="image-search">
                            <img src={ImageSearch} width="300" />
                        </div>
                    </div>
                )}
            </Container>

            <Footer />
        </>
    )
}

export default withRouter(ValidacaoReceita);
