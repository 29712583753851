import styled from "styled-components";

export const LastPrescriptionStyle = styled.div`
    width: 100%;
    height: auto;

    border-radius: 10px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
    background-color: #FFF;
    padding-left: 15px;
    padding-right: 15px;

    h2 {
        font-family: Lato;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        
        color: #8a92a2;
    }

    label{
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: #589bff;
        margin-right: 8px;
        cursor: pointer;
    }

    img {
        width: 26px;
        height: 25px;
        object-fit: contain;
        color: #8a92a2;
        cursor: pointer;
    }

    hr {
        margin: 2px;
    }

    p {
        width: 100%;
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #303848;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
    }

    i {
        color: #589bff;
        font-size: 16px;
    }


`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: ${props => props.margin ? props.margin : 'auto'};
    padding: ${props => props.padding ? props.padding : '8px 0px'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};

    @media only screen and (max-width: 439px){
        width: ${props => props.widthResponse ? props.widthResponse : '100%'};
        flex-direction: ${props => props.flexDirectrionResp ? props.flexDirectrionResp : 'column'};
    }
`;

export const Col = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    margin: ${props => props.margin ? props.margin : 'auto'};
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};

    @media only screen and (max-width: 439px){
        width: ${props => props.widthResponse ? props.widthResponse : '100%'};
        flex-direction: ${props => props.flexDirectrionResp ? props.flexDirectrionResp : 'row'};
        align-items: ${props => props.alignItemsResp ? props.alignItemsResp : 'center'};
    }
    

`;

export const Header = styled.div`
    width: 100%;
    height: 34px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;  
`;

export const TitleCard = styled.h3`
    width: 100%;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bac1cf;
`;

export const Status = styled.div`
    margin-top: 15px;
    width: 93px;
    height: 25px;
    border-radius: 5px;
    background-color: #f3f3f5;
    margin-left: 10px;
    opacity: 10;
    p {
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ff5252;
        padding-top: 5px;
    }
`;

export const Produto = styled.div`
    width: 100%;

    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;

    text-overflow: ellipsis;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    
    white-space: nowrap;
    display: block;
    overflow: hidden;
`;


export const Loading = styled.div`

    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
    
    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }


    width: 80px;
    height: 80px;
    position: relative;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;


    background-color: transparent;
    border-top: 1px solid #2fbb69;
    border-bottom: 1px solid #2fbb69;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-radius: 50%;
    -webkit-animation: rotating 0.7s linear infinite;
    -moz-animation: rotating 0.7s linear infinite;
    -ms-animation: rotating 0.7s linear infinite;
    -o-animation: rotating 0.7s linear infinite;
    animation: rotating 0.7s linear infinite;

`;

export const ArvoreImg = styled.div`
    width: 100%;
    height: 99px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    img {
        height: auto;
        margin-right: 32px;
    }

    @media only screen and (max-width: 439px){
        justify-content: center;
        margin-top: 32px;

        img {
            margin: 0px;
        }
    }
`;


export const Steps = styled.div`
    width: 100%;
    float: left;
`;

export const StepNext = styled.div`
    color: black;
    margin-left: 16px;
    font-size: 11px;
    cursor: pointer;
`;

export const ErrorPrescriptionStyleLine = styled.div`
    background-color: ${props => `${props.backgroundColor}`};
    height: 12px;
    width: 3px;
    margin-left: 8px;
`;

export const ErrorPrescriptionStyleImage = styled.div`
    background-color: ${props => `${props.backgroundColor}`};
    width: 20px;
    height: 20px;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    
    img {
        width: 16px;
    }
`;
