import React, { useState, useEffect } from "react";

import api from "../../../services/api";
import { Container } from "react-grid-system";
import { FormContainer, Card, Title, Paragraph, ContainerForm, ContainerRelationOptions } from "./styles";
import Input from "../../input/input_v2";
import ArrowIcon from "../../../assets/icons/icon-arrow.svg";
import ArrowIconDisabled from "../../../assets/icons/icon-arrow-disabled.svg";


import estados from "../../../services/estados";
import Select from "../../select/select_v2";
import Relation from "./relation";
import ModalNew from "../../modal/modalGeneric";

export const USER = "@userinfo";

const PrescriptionStepFive = ({ ...props }) => {

    const [estado, setEstado] = useState('');
    const [dataEmissao, setDataEmissao] = useState('');
    const [numNf, setNumNf] = useState('');
    const [serieNf, setSerieNf] = useState([]);
    const [embalagens, setEmbalagens] = useState([]);
    const [embalagemValue, setEmbalagemValue] = useState(null);
    const [relations, setRelations] = useState([]);
    const [quantidades, setQuantidades] = useState([]);
    const [isOpenBox, setIsOpenBox] = useState(false);
    const [showModalSerie, setShowModalSerie] = useState(false);
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);

    const getEmpresa = async () => {
        try {

            const user = JSON.parse(localStorage.getItem(USER));

            const response = await api.get(`/filiais/${user.agro_subsidiary_id}`);

            if (response) {
                const filial = response.data.data;
                setEstado(filial.estado);
            }

        } catch (error) {
            
        }
    }

    const openBoxNFe = () => {
        if ((isOpenBox === false || isOpenBox === undefined)) {
            setIsOpenBox(!isOpenBox);
        }
    }

    const openBoxNFeArrow = () => {
        setIsOpenBox(!isOpenBox);
    }

    const changeDataEmissao = (value) => { 
        setDataEmissao(value);
    }

    const changeNumNf = (value) => {
        setNumNf(value)
    }

    const changeSerieNf = (value) => {
        setSerieNf(value)
    }

    useEffect(() => {
        setRelations(props.relations);
        if (props.produtos.length < produtosSelecionados.length) {
            let p = [];
            let q = [];

            produtosSelecionados.map((e, i)=>{
                if (props.produtos.includes(e) && p.length < props.produtos.length) {
                    p = [...p, e];
                    q = [...q, quantidades[i]];
                }
            })

            setProdutosSelecionados(p);
            setQuantidades(q);

            if (isOpenBox === true) {
                setIsOpenBox(false);
            }
        }else{
            props.produtos.map((e, i)=>{
                const count = Object.values(props.produtos).filter(value => value === e).length;
                const countProds = Object.values(produtosSelecionados).filter(value => value === e).length;

                if(produtosSelecionados.indexOf(e) == -1 || countProds < count){
                    produtosSelecionados.push(e);
                    setIsOpenBox(false);
                }
            })
        }
    }, [props]);

    useEffect(() => {
        props.update({
            estado,
            dataEmissao,
            quantidades,
            embalagens,
            serieNf,
            numNf,
            produtosSelecionados
        })
    }, [
        estado,
        dataEmissao,
        quantidades,
        embalagens,
        serieNf,
        numNf,
        produtosSelecionados
    ])

    useEffect(()=>{
        getEmpresa();
    }, [])

    return (
        <Container>
            {
                (estado === "MG" || props.estado === "MG") && (
                    <FormContainer>
                        <Card 
                            onClick={() => openBoxNFe()} 
                            openBox={isOpenBox} 
                            erro={false}
                            existInformation={false}
                            disabled={props.disabled}
                        >
                            <div className="row">
                                <Title disabled={props.disabled}>SICCA - MG (Opcional)</Title>
                                {!props.disabled ? (
                                    <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => openBoxNFeArrow()}/>
                                ) : (
                                    <img className="arrow" src={ArrowIconDisabled} alt='arrow'/>   
                                )}
                            </div>

                            {!isOpenBox ? (
                                <Paragraph disabled={props.disabled}>Informe os dados para a geração do arquivo do Sicca.</Paragraph>
                            ) : (
                                <>
                                    <ContainerForm>
                                        <>
                                            <Input 
                                                name="serie_nf"
                                                type="text"
                                                id="serie"
                                                label="Série da nota"
                                                help={true}
                                                helpClick={() => setShowModalSerie(true)}
                                                onChange={e => changeSerieNf(e)}
                                                value={serieNf}
                                            />
                                            <Input
                                                id="dataemissaonf"
                                                onChange={e => changeDataEmissao(e)}
                                                label="Data de emissão"
                                                mask="9999-99-99"
                                                value={dataEmissao}
                                                disabled={false}
                                                type="date"
                                            />
                                            
                                            <ContainerRelationOptions>
                                                <Relation 
                                                    relations={relations}
                                                    produtos={produtosSelecionados}
                                                    quantidades={quantidades}
                                                    embalagens={embalagens}
                                                    updateProduto={(e, i) => setProdutosSelecionados(e, i)}
                                                    updateEmbalagemValue={(e) => setEmbalagens(e)}
                                                    updateQuantidade={(e) => setQuantidades(e)}
                                                />
                                            </ContainerRelationOptions>
                                        </>
                                    </ContainerForm>
                                </>
                            )}
                        </Card>
                    </FormContainer>
                )
            }
            <ModalNew
                size='md'
                show={showModalSerie}
                onHide={() => setShowModalSerie(false)}
            >
                <p>Para o lançamento de cupons fiscais, informar nesse campo as letras <b>CF</b>. Se o documento fiscal não possuir série, informar os números <b>00</b>.</p>
            </ModalNew>
        </Container>
    );
};

export default PrescriptionStepFive;