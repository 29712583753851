import React, {useState, useEffect} from 'react';
import Items from './items';

const Relation = ({...props}) => {
    const [totalItens, setTotalItens] = useState(0);
    const [produtosSelecionados, setProdutosSelecionados] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [quantidades, setQuantidades] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [codigosProduto, setCodigosProduto] = useState([]);
    const [ncmProdutos, setNcmProdutos] = useState([]);
    const [isLoad, setIsLoad] = useState(false);


    const loadProducts = (p) => {
        setIsLoad(true);
        
        if (p.produtos.length !== totalItens) {
            setTotalItens(p.produtos.length);

            if (p.produtos.length > 0) {
                setProdutosSelecionados(p.produtos);
                setInitialValues(p.produtos);
            }
        } else {
            if (p.produtos.length > 0) {
                let listProducts = [];
                let existDiferent = false;
    
                p.produtos.map((pr, i) => {
                    if (pr === '') {
                        listProducts[i] = produtosSelecionados[i];
                    }else if (pr !== produtos[i]) {
                        existDiferent = true;
                        listProducts[i] = pr;
                    } else {
                        listProducts[i] = produtosSelecionados[i];
                    }
                })
                
                if (existDiferent) {
                    setProdutos(listProducts);
                }
            }
        }
    }

    const setInitialValues = (prod) => {
        let listProducts = [];

        prod.map((p, i) => {
            listProducts[i] = p;
        });

        setProdutos(listProducts);
        props.updateProduto(listProducts);
    }

    const changeProduto = (value, index) => {
        let p = produtos;
        if (p[index] !== value) {
            p[index] = value;
            setProdutos(p);
            props.updateProduto(p);
        }
    }

    const changeQuantidade = (value, index) => {
        let listQtds = quantidades;
        if (listQtds[index] !== value) {
            listQtds[index] = value;
            setQuantidades(listQtds);
            props.updateQuantidade(listQtds);
        }
    }

    const changeUnidade = (value, index) => {
        let listUnidades = unidades;
        if (listUnidades[index] !== value) {
            listUnidades[index] = value;
            setUnidades(listUnidades);
            props.updateUnidade(listUnidades);
        }
    }

    const changeCodigosProduto = (value, index) => {
        let listCod = codigosProduto;
        if (listCod[index] !== value) {
            listCod[index] = value;
            setCodigosProduto(listCod);
            props.updateCodigosProduto(listCod);
        }
    }

    const changeNcmProduto = (value, index) => {
        let listNcm = ncmProdutos;
        if (ncmProdutos[index] !== value) {
            listNcm[index] = value;
            setNcmProdutos(listNcm);
            props.updateNcmProduto(listNcm);
        }
    }


    useEffect(() => {
        if (isLoad === false) {
            loadProducts(props);
        }
        
        setQuantidades(props.quantidades);
        setNcmProdutos(props.ncmprodutos);
    }, []);

    return(
        <>  
            {produtos.length > 0 && (
                <>
                    {produtos.map((p, i) => (
                        <Items 
                            index={i}
                            produto={p}
                            quantidades={props.quantidades[i]}
                            unidades={props.unidades[i]}
                            ncmprodutos={props.ncmprodutos[i]}
                            updateProduto={(e, i) => changeProduto(e, i)}
                            updateQuantidade={(e, i) => changeQuantidade(e, i)}
                            updateUnidade={(e, i) => changeUnidade(e, i)}
                            updateCodigoProduto={(e, i) => changeCodigosProduto(e, i)}
                            updateNcmProduto={(e, i) => changeNcmProduto(e, i)}
                            errosNf={props.errosNf}
                        />
                    ))}
                </>
            )}
        </>
    )
}

export default Relation;
