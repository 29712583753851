import React from 'react';
// import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Select from "../select/select_v2";
import StyleModal from './stylesModalFilter1';
import api from "../../services/api";
import { YesNoContainer } from '../select/styles';
import Interruptor from '../../components/interruptor';
import { ModalBody, ModalFooter, ModalTitle, ReceitasCanceladas } from './styles';
import { YesNoContainerMobile } from '../input/styles';
import Modal from '.';

class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        dataInicial: '',
        dataFinal: '',
        responsavel: '',
        responsavelNome: '',
        botao: '',
        agronomos: [],
        setFiltro: [],
        yesNoValue: true,
    }


    checkFilters = async (filtrosProps) => {
 
        this.setState({setFiltro: filtrosProps});


        if(filtrosProps.dataInicial || (this.state.dataInicial && !filtrosProps.dataInicial)){
            if(this.state.dataInicial !== filtrosProps.dataInicial){
                if(!filtrosProps.dataInicial){
                    this.setState({dataInicial: ""});
                }else{
                    this.setState({dataInicial: filtrosProps.dataInicial});
                }
            }
        }

        if(filtrosProps.dataFinal || (this.state.dataFinal && !filtrosProps.dataFinal)){
            if(this.state.dataFinal !== filtrosProps.dataFinal){
                if(!filtrosProps.dataFinal){
                    this.setState({dataFinal: ""});
                }else{
                    this.setState({dataFinal: filtrosProps.dataFinal});
                }
            }
        }


        if(filtrosProps.agronomo_id || (this.state.responsavel && (!filtrosProps.agronomo_id || filtrosProps.agronomo_id === "" ))){
            if(this.state.responsavel !== filtrosProps.agronomo_id){
                if(!filtrosProps.agronomo_id){
                    this.setState({responsavel: ""});
                    this.setState({responsavelNome: ""});
                }else{
                    this.setState({responsavel: filtrosProps.agronomo_id});
                    this.setState({responsavelNome: filtrosProps.agronomo_nome});
                }
            }
        }

        if(filtrosProps.botao || (this.state.botao && !filtrosProps.botao)){
            if(this.state.botao !== filtrosProps.botao){
                if(!filtrosProps.botao){
                    this.setState({botao: ""});
                }else{
                    this.setState({botao: filtrosProps.botao});
                }
            }
        }
        // console.log("========================= END CHECK FILTER ============================");

    }

    componentDidMount(){
        this.setState({setFiltro: this.props.setFilter});
        
        this.getAgronomos();       
    }


    componentDidUpdate(){
        if(this.state.setFiltro !== this.props.setFilter){
            this.checkFilters(this.props.setFilter);
        }
    }

    getAgronomos = async () => {
        try {
            const response = await api.get(`/agronomos`);
            let agronomos = response.data.data;
            agronomos = this.formatAgronomos(agronomos);
            this.setState({agronomos})
        } catch (error) {
            // if(error.response.status === 500){
            //     window.location = '/login';
            // }
        }
    };

    formatAgronomos = agronomos => {
        agronomos = agronomos.map(agronomo => {
            const format = {
                value: agronomo.id,
                label: agronomo.nome
            };

            return format;
        });

        return agronomos;
    };

    updateFilter = () => {
        

        if(this.state.botao !== 1 && this.state.botao !== 2 && this.state.botao !== 3 && this.state.botao !== 4){
            this.setState({ botao: 0 });
        }


        const respostaFiltro = ({
            dataInicial: this.state.dataInicial, 
            dataFinal: this.state.dataFinal,
            botao: this.state.botao,
            agronomo_id: this.state.responsavel,
            agronomo_nome: this.state.responsavelNome,
            yesNoValue: this.state.yesNoValue,
        });
    
        this.props.update(respostaFiltro);
    }

    _onFocus = (e) => {
        e.currentTarget.type = "date";
    }

    _onBlur = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Data inicial";
    }

    _onBlur2 = (e) => {
        e.currentTarget.type = "text";
        e.currentTarget.placeholder = "Data final";
    }


    validateAgronomo = (e) => {
        this.setState({ responsavel: e.value });
        this.setState({ responsavelNome: e.label });
    }

    

    render() {
        return (

            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='50%'
            >
                <ModalTitle> {this.props.title} </ModalTitle>

                <ModalBody textAlign='start'>
                    <StyleModal>
                        <label 
                            className="lbl-title"
                        >
                            PERÍODO
                        </label>

                        <div className="data-filter">
                            <div className="input-data-filter">
                                <input
                                    className="input-date"
                                    type="text"
                                    value={this.state.dataInicial}
                                    placeholder="Data inicial"
                            
                                    onFocus = {this._onFocus} 
                                    onBlur={this._onBlur}
                                    onChange={e => this.setState({dataInicial: e.target.value})}
                                />

                                <input
                                    className="input-date"
                                    type="text"
                                    value={this.state.dataFinal}
                                    placeholder="Data final"
                                    mask="99/99/9999"
                                    onFocus = {this._onFocus} 
                                    onBlur={this._onBlur2}
                                    onChange={e => this.setState({dataFinal: e.target.value})}
                            
                                />
                            </div>
                        </div>

                        <div className="btn-filters">
                            <button 
                                style={{
                                    outline: this.state.botao === 1 ? 1 : 0,
                                    backgroundColor: this.state.botao === 1 ? '#589bff' : 'transparent',
                                    color: this.state.botao === 1 ? '#FFF' : '#589bff',
                                }} 
                                className="btnf" 
                                onClick={() => this.setState({ botao: 1 })}
                            >
                                HOJE
                            </button>
                    
                            <button 
                                style={{
                                    outline: this.state.botao === 2 ? 1 : 0,
                                    backgroundColor: this.state.botao === 2 ? '#589bff' : 'transparent',
                                    color: this.state.botao === 2 ? '#FFF' : '#589bff',
                                }} 
                                className="btnf" 
                                onClick={() => this.setState({ botao: 2 })}
                            >
                                ONTEM
                            </button>
                    
                            <button 
                                style={{
                                    outline: this.state.botao === 3 ? 1 : 0,
                                    backgroundColor: this.state.botao === 3 ? '#589bff' : 'transparent',
                                    color: this.state.botao === 3 ? '#FFF' : '#589bff',
                                }} 
                                className="btnf" 
                                onClick={() => this.setState({ botao: 3 })}
                            >
                                ÚLTIMOS 7 DIAS 
                            </button>
                    
                            <button 
                                style={{
                                    outline: this.state.botao === 4 ? 1 : 0,
                                    backgroundColor: this.state.botao === 4 ? '#589bff' : 'transparent',
                                    color: this.state.botao === 4 ? '#FFF' : '#589bff',
                                }} 
                                className="btnf" 
                                onClick={() => this.setState({ botao: 4 })}
                            >
                                ÚLTIMOS 15 DIAS
                            </button>
                        </div>

                        <Select
                            name="representante_tecnico"
                            id="representante_tecnico"
                            label="REPRESENTANTE TÉCNICO"
                            className="select1"
                            options={this.state.agronomos}
                            value={this.props.setFilter.agronomo_id}
                            onChange={e => this.validateAgronomo(e)}
                        />

                        <ReceitasCanceladas>
                            {
                                this.props.isMobile === false ? (
                                    <YesNoContainer style={{
                                        position:'relative', 
                                        left:'0px',
                                        width: '230px',
                                        paddingLeft: '10px'
                                    }}> <span style={{ width: '150px', marginRight: '5px' }}>Mostrar receitas canceladas</span> <Interruptor value={this.state.yesNoValue} onChange={(e) => this.setState({yesNoValue: e})}/> </YesNoContainer>
                                ) : (
                                    <YesNoContainerMobile style={{
                                        position:'relative', 
                                        left:'0px',
                                        width: '230px',
                                        paddingLeft: '10px',
                                        zIndex: '0',
                                        top: '0px',
                                    }}> <span style={{marginRight: '15px'}}>Mostrar receitas canceladas</span> <Interruptor value={this.state.yesNoValue} onChange={(e) => this.setState({yesNoValue: e})}/> </YesNoContainerMobile>
                        
                                )
                            }
                        </ReceitasCanceladas>
                    </StyleModal>
                </ModalBody>

                <ModalFooter>
                    <Button style={{ outline:0, padding: "15px", margin: "auto"}}
                        outline
                        color="#00b43f"
                        onClick={() => this.updateFilter()}
                        width="100px"
                    >
                        Filtrar
                    </Button>
                </ModalFooter>
            </Modal>

        );
    }
}
export default ModalNew;