import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Container } from "react-grid-system";
import Button from "../../components/button/button_v2";
import Input from "../../components/input/input_v2";
import Select from "../../components/select/select_v2";
import api from "../../services/api";
import { FormContainer } from "../../components/compra/step0/style";
import GenericUpper from '../../components/generic-upper';
import { store } from 'react-notifications-component';
import ModalGeneric from '../../components/modal/modalGeneric';
import Faturamento from '../faturamento/index';

const ComprarLimite = ({ ...props }) => {

    const [disabled, setDisabled] = useState(true);
    const [disabledNumUsers, setDisabledNumUsers] = useState(true);
    const [disabledPrescript, setDisabledPrescript] = useState(true);
    const [disabledDoc, setDisabledDoc] = useState(true);
    const [gridId, setGridId] = useState('');
    const [numUsersId, setNumUsersId] = useState('');
    const [numDocuments, setNumDocuments] = useState('');
    const [numPrescriptions, setNumPrescriptions] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [valorPlano, setValorPlano] = useState('');
    const [plano, setPlano] = useState([]);
    const [planoSelecionado, setPlanoSelecionado] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModalConfirm, setOpenModalConfirm] = useState(false);
    const [showModalPix, setOpenModalPix] = useState(false);
    const [showModalFaturamento, setShowModalFaturamento] = useState(false);

    const [oldLimit, setOldLimit] = useState(0);

    const [qrCodePix, setQrCodePix] = useState('');
    const [pixCode, setPixCode] = useState('');
    
    const [email, setEmail] = useState('');

    const nextStep = async (tipoPagamento) => {
        setOpenModalConfirm(false);

        try {
            if (plano[0].plan_type_id === 2 || plano[0].plan_type_id === 4) {
                if ((numPrescriptions && numPrescriptions > 0)) {
                    setLoading(true);
                    const response = await api.post('/adquirir/receita', {qtd_receitas: numPrescriptions, valor_item: valorPlano, valor_total: valorTotal, tipo_pagamento: tipoPagamento});
                    
                    if (response) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Compra realizada com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                        setLoading(false);
    
                        window.location = '/';
                    }
                } else {
                    setLoading(false);
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Informe a quantidade para continuar a compra!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 8000,
                            onScreen: true
                        }
                    });
                }
            } else if (plano[0].plan_type_id === 3) {
                if ((numDocuments && numDocuments > 0)) {
                    setLoading(true);
                    const response = await api.post('/adquirir/receita', {qtd_receitas: numDocuments, valor_item: valorPlano, valor_total: valorTotal, tipo_pagamento: tipoPagamento});
                    
                    if (response) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Compra realizada com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                        setLoading(false);
    
                        window.location = '/';
                    }
                } else {
                    setLoading(false);
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Informe a quantidade para continuar a compra!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 8000,
                            onScreen: true
                        }
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true
                }
            });
        }
    }

    const getPlanos = async () => {
        try {
            const response =  await api.get(`/planos`);
            let planos = response.data.data;
            let format = formatPlanos(planos);
            setPlanos(format);

        } catch (error) {

        }
    }

    const formatPlanos = planos => {
        let formatPlano = planos.map(p => {
            const format = {
                value: p.id,
                label: p.plan_name,
                users: p.users_limit,
                documents: p.documents_limit,
                prescriptions: p.prescriptions_limit,
                type: p.plan_type_id
            };
            return format;
        });
        return formatPlano;
    }

    const getPlano = async (id) => {
        try {
            const response =  await api.get(`/planos/${id}`);
            let plano = response.data.data;
        
            setPlano(plano);

            let p = formatPlanos(plano);
            setPlanoSelecionado(p[0]);
            if(!numUsersId){
                setValorPlano(plano[0].plans_grid[0].vlr_unit);
            }else{
                plano[0].plans_grid.map(p => {
                    if(p.vlr_minimo == 0 && p.qtd_users == numUsersId){
                        setValorPlano(p.vlr_unit);
                    }else{
                        if(p.vlr_minimo == 1 && p.qtd_users <= numUsersId){
                            setValorPlano(p.vlr_unit);
                        }
                    }
                })
            }

            calculaValorTotal(plano, parseInt(numUsersId));

        } catch (error) {
        }
    } 

    const calculaValorTotal = (plano, vlr) => {
        if (plano.length > 0) {
            const p = plano[0];
    
            if (p.plans_grid.length > 0) {
    
                let valorPlano = 0;
                let valorTotal = 0;
    
                let find = false;
    
                if (p.plan_type_id === 2 || p.plan_type_id === 3 || p.plan_type_id === 4) {
                    if (vlr >= 1) {
                        p.plans_grid.map((grid) => {
                            if (find === false) {
    
                                if (vlr <= parseInt(grid.qtd_users)) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                    
                                } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                } else if (parseInt(grid.vlr_minimo) === 1) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                }
                            }
    
                        })
                        
                        valorPlano = ""+valorPlano;
                        let calculaTotal = valorPlano.replace(",", ".");
                        valorTotal = vlr * calculaTotal;
                        valorTotal = valorTotal.toFixed(2);
                        valorTotal = ""+valorTotal;
            
                        valorTotal = valorTotal.replace(".", ",");
            
                        setValorPlano(valorPlano);
                        setValorTotal(valorTotal);
                    }
                    
                } else if (p.plan_type_id === 5) {
                    if (vlr >= 1) {
                        p.plans_grid.map((grid) => {
                            if (find === false) {
    
                                if (vlr == parseInt(grid.qtd_users)) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                    
                                } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                }
                            }
    
                        })
                        
                        valorPlano = ""+valorPlano;
                        let calculaTotal = valorPlano.replace(",", ".");
                        valorTotal = vlr * calculaTotal;
                        valorTotal = valorTotal.toFixed(2);
                        valorTotal = ""+valorTotal;
            
                        valorTotal = valorTotal.replace(".", ",");
            
                        setValorPlano(valorPlano);
                        setValorTotal(valorTotal);
                    }
                }
            } else {
                setValorPlano('0,00');
                setValorTotal('0,00');
                setGridId(0);
            }
        }
    }

    const validateNumUsuarios = valor => {
        setNumUsersId(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor));
    }

    const validateNumPrescript = valor => {
        setNumPrescriptions(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor));

        if (planoSelecionado.type === 4) {
            setNumDocuments(parseInt(valor));
        }
    }

    const validateNumDocument = valor => {
        setNumDocuments(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor));

        if (planoSelecionado.type === 4) {
            setNumPrescriptions(parseInt(valor));
        }
    }

    const validatePlano = plano => {        
        setPlanoSelecionado(plano); 
        getPlano(plano.value);

        if (plano.users === 1) {
            validateNumUsuarios(1)
            setDisabledNumUsers(true);
        } else {
            validateNumUsuarios('')
            setDisabledNumUsers(false);
        }

        if (plano.documents !== null) {
            validateNumDocument(plano.documents);
            setDisabledDoc(true)
        } else {
            validateNumDocument('');
            setDisabledDoc(false);
        }
        
        if (plano.prescriptions !== null) {
            validateNumPrescript(plano.prescriptions);
            setDisabledPrescript(true)
        } else {
            validateNumPrescript('');
            setDisabledPrescript(false);
        }
    }


    const selectActivePlan = () => {
        let user = JSON.parse(localStorage.getItem('@userinfo'));

        let planoId = user.contrato.agro_plan_config.agro_plan_id;
        let planoNome = user.contrato.agro_plan_config.nome_plano;

        setPlanoSelecionado({value: planoId, label: planoNome});
        setOldLimit(user.contrato.agro_plan_config.prescriptions_limit);
    }

    const openFaturamento = () => {
        setShowModalFaturamento(true);
        setOpenModalConfirm(false);
    }

    const confirmFaturamento = () => {
        setShowModalFaturamento(false);
        setOpenModalConfirm(true);
    }

    useEffect(() => {
        getPlanos();
        
        let user = JSON.parse(localStorage.getItem('@userinfo'));
        if (user && user !== null) {
            setEmail(user.username);
        }
    },[]);


    useEffect(() => {
        if (numDocuments || numPrescriptions) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    },[numDocuments, numPrescriptions])

    useEffect(() => {
        if (planos.length > 0) {
            selectActivePlan()
        }
    }, [planos])

    return (
        <>
            <GenericUpper
                align="center"
                title="Aumentar limite de receitas"
                subtitle=""
            ></GenericUpper>

            <FormContainer>
                <Container>
                    <form>
                        <Select 
                            label="Plano"
                            options={planos}
                            value={planoSelecionado.value || planoSelecionado}
                            onChange={(e) => validatePlano(e)}
                            disabled={true}
                            className="select"
                            id='plano'
                        />

                        {planoSelecionado.type === 5 ? (
                            <Input
                                name="numUsuarios"
                                label="Número de usuários"
                                type="number"
                                min="1" 
                                id='num_usuarios'
                                value={numUsersId}
                                disabled={false}
                                onChange={(e) => validateNumUsuarios(e)}
                            />
                        ) : (
                            <>
                                {planoSelecionado.type === 4 ? (
                                    <>
                                        {planoSelecionado.users !== null ? (
                                            <Input
                                                name="numUsuarios"
                                                label="Número de usuários"
                                                type="number"
                                                min="1" 
                                                id='num_usuarios'
                                                value={numUsersId}
                                                disabled={disabledNumUsers}
                                                onChange={(e) => validateNumUsuarios(e)}
                                            />
                                        ) : (null)}

                                        <Input
                                            name="numreceita"
                                            label="Número de receitas"
                                            type="number"
                                            min="1" 
                                            id='num_usuarios'
                                            value={numPrescriptions}
                                            disabled={false}
                                            onChange={(e) => validateNumPrescript(e)}
                                        />
                                        
                                        <Input
                                            name="numdocumentos"
                                            label="Número de documentos"
                                            type="number"
                                            min="1" 
                                            id='num_usuarios'
                                            value={numDocuments}
                                            disabled={disabledDoc}
                                            onChange={(e) => validateNumDocument(e)}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {planoSelecionado.type === 3 ? (
                                            <>
                                                <Input
                                                    name="numdocumentos"
                                                    label="Número de documentos"
                                                    type="number"
                                                    min="1" 
                                                    id='num_usuarios'
                                                    value={numDocuments}
                                                    disabled={disabledDoc}
                                                    onChange={(e) => validateNumDocument(e)}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {planoSelecionado.type === 2 ? (
                                                    <>
                                                        <Input
                                                            name="numreceita"
                                                            label="Número de receitas"
                                                            type="number"
                                                            min="1" 
                                                            id='num_usuarios'
                                                            value={numPrescriptions}
                                                            disabled={false}
                                                            onChange={(e) => validateNumPrescript(e)}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                    
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                            </>
                        )}

                        <div className="valores">
                            {planoSelecionado.type === 5 ? (
                                <>
                                    {valorPlano ? (
                                        <h3 className="valor"><label>R$ {valorPlano}</label> mensais/usuário</h3>
                                    ):(
                                        <h3 className="valor"><label>R$ 0,00</label> mensais/usuário</h3>
                                    )}
                                    
                                    
                                    {valorTotal ? (
                                        <p className="total">Valor Total: R$ {valorTotal}</p>
                                    ):(
                                        <p className="total">Valor Total: R$ 0,00</p>
                                    )}
                                </>
                            ): (
                                <>
                                    {planoSelecionado.type === 4 ? (
                                        <>
                                            {valorPlano ? (
                                                <h3 className="valor"><label>R$ {valorPlano} </label> por receitas e documentos</h3>
                                            ):(
                                                <h3 className="valor"><label>R$ 0,00</label> por receitas e documentos</h3>
                                            )}
                                            
                                            {valorTotal ? (
                                                <p className="total">Valor Total: R$ {valorTotal}</p>
                                            ):(
                                                <p className="total">Valor Total: R$ 0,00</p>
                                            )}
                                        </>
                                    ): (
                                        <>
                                            {planoSelecionado.type === 3 ? (
                                                <>
                                                    {valorPlano ? (
                                                        <h3 className="valor"><label>R$ {valorPlano} </label> mensais/documentos</h3>
                                                    ):(
                                                        <h3 className="valor"><label>R$ 0,00</label> mensais/documentos</h3>
                                                    )}
                                                    
                                                    
                                                    {valorTotal ? (
                                                        <p className="total">Valor Total: R$ {valorTotal}</p>
                                                    ):(
                                                        <p className="total">Valor Total: R$ 0,00</p>
                                                    )}
                                                </>
                                            ): (
                                                <>
                                                    {planoSelecionado.type === 2 ? (
                                                        <>
                                                            {valorPlano ? (
                                                                <h3 className="valor"><label>R$ {valorPlano} </label> por receitas</h3>
                                                            ):(
                                                                <h3 className="valor"><label>R$ 0,00</label> por receitas</h3>
                                                            )}
                                                            
                                                            
                                                            {valorTotal ? (
                                                                <p className="total">Valor Total: R$ {valorTotal}</p>
                                                            ):(
                                                                <p className="total">Valor Total: R$ 0,00</p>
                                                            )}
                                                        </>
                                                    ): (
                                                        <>
                                                            
                                                        </>
                                                    )} 
                                                    
                                                </>
                                            )} 
                                        </>
                                    )}  
                                </>
                            )}  

                        </div>

                        {props.isOff === true ? (
                            null
                        ) : (
                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '40px', marginBottom: '60px' }}>
                                <Button 
                                    width='40%'
                                    height='50px'
                                    value='VOLTAR'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => props.history.goBack()}
                                    loading={false}
                                />
                                <Button 
                                    width='40%'
                                    height='50px'
                                    value={props.isSimulation === false ? 'AVANÇAR' : 'ADQUIRA JÁ'}
                                    outline={false}
                                    disabled={disabled}
                                    onClick={() => setOpenModalConfirm(true)}
                                    loading={loading}
                                />
                            </div>
                        )}
                    </form>
                </Container>
            </FormContainer>

            <ModalGeneric
                show={showModalConfirm}
                onHide={() => setOpenModalConfirm(false)}
                size='lg'
            >
                <>
                    <h3 style={{width: '100%', textAlign: 'center'}}>Confirmar compra</h3>
                    <p style={{width: '100%', textAlign: 'center', fontWeight: 'bold'}}>Seu limite será atualizado de {oldLimit} para {numPrescriptions} receitas/mês.</p>
                    <p style={{width: '100%', textAlign: 'center'}}>Ao clicar em confirmar, sua aquisição será processada automaticamente e o boleto será enviado no próximo dia útil. Caso queira confirmar suas informações de faturamento, <span onClick={() => openFaturamento()} style={{color: '#589BFF', cursor: 'pointer'}}>clique aqui</span>.</p>
                    <p style={{width: '100%', textAlign: 'center'}}>O limite e seu saldo serão atualizados após confirmação do pagamento.</p>


                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '20px' }}>
                        <Button 
                            width='40%'
                            height='50px'
                            value='CONFIRMAR'
                            outline={false}
                            disabled={false}
                            onClick={() => nextStep(1)}
                            loading={false}
                        />
                    </div>

                </>
            </ModalGeneric>


            <ModalGeneric
                show={showModalPix}
                onHide={() => setOpenModalPix(false)}
                size='lg'
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <img src={qrCodePix} alt="qrcode pix" style={{ margin: 'auto', marginBottom: '10px' }}/>
                    <p style={{width: 'auto', height: 'auto', textAlign: 'center', fontSize: 'small'}}>{pixCode}</p>
                </div>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '40px', marginBottom: '20px' }}>
                    <Button 
                        width='40%'
                        height='50px'
                        value='CONFIRMAR PAGAMENTO'
                        outline={false}
                        disabled={false}
                        onClick={() => nextStep(1)}
                        loading={false}
                    />
                </div>
            </ModalGeneric>

            <ModalGeneric
                show={showModalFaturamento}
                onHide={() => setShowModalFaturamento(false)}
                size='md'
            >
                <Faturamento 
                    isModal={true}
                    ok={() => confirmFaturamento()}
                />
            </ModalGeneric>

        </>
    );

}

export default withRouter(ComprarLimite);
