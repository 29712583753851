import React, { useState, useEffect } from "react";

import { UpperContainer, HeaderCompendioGratis, Plans, SearchField, InputField, ExportButton, ExportOptions, Desmarc, Search, Titulo, ContainerNew, AddButton } from "./styles";
import { Container } from "react-grid-system";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
import ModalText from "../modal/modalText";
import Lupa from "../../assets/icons/lupa.svg";
import Filtro from "../../assets/icons/filtro.svg";
import Download from "../../assets/icons/download.svg";
import InputSearch from "../../components/input-search";
import Add from "../../assets/icons/iconAddHeader.svg";
import Arrow from "../../assets/icons/arrow-white.svg";
import Help from "../../assets/icons/help.svg";
import IconHelp from "../../assets/icons/help";
export const USER = "@userinfo";
export const OPEN = "@open";


const GenericUpper = ({ ...props }) => {
  const [showModalHelp, setShowModalHelp] = useState(false);
  const [pesquisar, SetPesquisar] = useState('');
  const [showOptions, SetShowOptions] = useState(false);
  const [isPrePago, setIsPrePago] = useState(false);
  const [isTest, setIsTest] = useState(false);

  const getPlansConfig = () => {
    let user = JSON.parse(localStorage.getItem(USER));

    if (user && user !== null) {
      if (user.contrato) {
        if (user.contrato.status) {
          if (user.contrato.status == 'AP') {
              let is_test = user.contrato.agro_plan_config.is_teste;
              let is_prePago = user.contrato.agro_plan_config.is_pre_pago;
      
              if (is_test === 1) {
                  setIsTest(true);
              }
              
              if (is_prePago === 1) {
                  setIsPrePago(true);
              }
          } else {
              setIsTest(false);
          }
        }
      }
    }
  }

  const logincompra = /\/login-compra\//g;
  const simulacaocompra = /\/simulacaoplano\//g;

  useEffect(() => {
    // console.log("RENDER GENERIC UPPER")
    getPlansConfig();
  },[])

  useEffect(() => {
    console.log(pesquisar);
  },[pesquisar])

  return (
    <>
      {props.isSite ? (
        <HeaderCompendioGratis>
          <div className="header">
              <div className="logo">
                  <a href="http://www.agroreceita.com.br" target="_blank">
                      <img src={Logo} alt="logo" />
                  </a>
              </div>
              <div className="links">
                  <a href="https://www.agroreceita.com.br/ajuda" id="help" target="_blank">AJUDA</a>
                  <a href="https://sistema.agroreceita.com.br" target="_blank">ENTRAR</a>
                  <a href="https://agroreceita.com.br/blog/" id="blog" target="_blank">BLOG</a>
              </div>
              <div className="planos">
                  <a href="https://www.agroreceita.com.br/planos" id="plans" target="_blank">PLANOS</a>
                  <a href={`https://sistema.agroreceita.com.br/cadastro`} id="teste" target="_blank">TESTE GRÁTIS</a>
              </div>
          </div>
          <div className="title">
              <h1>{props.title}</h1>
              <h2>{props.subtitle}</h2>
          </div>
        </HeaderCompendioGratis>
      ) : (
        <UpperContainer {...props}>
          {props.showLogo == true && props.showFaq == true ? (<>
          <div className="iconsLogo">
            <div className="logo">
                <a href="http://www.agroreceita.com.br" >
                    <img src={Logo} alt="logo" />
                </a>
            </div>
            <div className="faq">
                <a href="https://www.agroreceita.com.br/ajuda/" target="_blank">
                    FAQ
                </a>
            </div>
          </div>
          </>) : (null)}
  
          <ContainerNew isTest={isTest} isPrePago={isPrePago} hideFilter={props.hideFilter} search={props.search} isMobile={props.isMobile} estados={props.estados}>
            {props.logo == true ? (<>
              <Link to="/">
                  <img src={Logo} alt="logo" style={{ width: "150px", marginLeft: "-28px", marginTop: "-55px" }}/>
              </Link>
            </>):(null)}
            {
              <>
                <Plans
                    isMobile={props.isMobile}
                    show={
                        isTest ? 
                            window.location.pathname === '/login' ? 
                            false : window.location.pathname === '/compra/sucesso' ? 
                            false : window.location.pathname.search(logincompra) !== -1 ?
                            false : window.location.pathname.search(simulacaocompra) !== -1 ?
                            false : true 
                        : false
                    }
                    onClick={() => window.location = '/planos'}
                >
                    ASSINE AGORA
                </Plans>
                
                <Plans
                    isMobile={props.isMobile}
                    show={
                        isPrePago ? 
                            window.location.pathname === '/login' ? 
                            false : window.location.pathname === '/compra/sucesso' ? 
                            false : window.location.pathname.search(logincompra) !== -1 ?
                            false : window.location.pathname.search(simulacaocompra) !== -1 ?
                            false : true 
                        : false
                    }
                    onClick={() => window.location = '/comprar_receitas'}
                >
                    COMPRAR RECEITAS
                </Plans>
              </>
            }
            <Titulo hideFilter={props.hideFilter} search={props.search} estados={props.estados}>{props.title}{
              props.help === true && props.text && props.text.replace(/ /g, "") != "" && props.text !== null ? (
                <IconHelp 
                  width='19px'
                  height='19px'
                  fill='#5DA8FD'
                  cursor='pointer'
                  onClick={()=>setShowModalHelp(true)} 
                />
              ) : (null)
            }</Titulo>
            {/* <small className="subtitleTour">{props.subtitle}</small> */}
            {
              props.search == true && (
                <Search>
                  {
                    props.docSeguranca ? (
                      props.onKeyDown ? (
                        <InputSearch
                          placeholder={props.placeholder}
                          estado=""
                          search='0'
                          estados={true}
                          update={(text, isSearch, select) => props.searchFunction(text, isSearch, text)}
                          onChange={(text) => SetPesquisar(text.target.value)}
                          onKeyDown={(e) => props.onKeyDown(e)}
                          value={props.valueSelect}
                          docSeguranca={props.docSeguranca}
                          selectEstado={props.estados}
                          pesquisa={props.search}
                        >
                        </InputSearch>
                      ) : (
                        <InputSearch
                          placeholder={props.placeholder}
                          estado=""
                          search='0'
                          estados={true}
                          update={(text, search, documento) => props.searchFunction(text, search, documento)}
                          onChange={(text) => SetPesquisar(text.target.value)}
                          docSeguranca={props.docSeguranca}
                          value={props.valueSelect}
                          selectEstado={props.estados}
                          pesquisa={props.search}
                        >
                        </InputSearch>
                      )
                    ) : props.onKeyDown ? (
                      <InputSearch
                        placeholder={props.placeholder}
                        estado=""
                        search='0'
                        estados={true}
                        update={(text, estado, search) => props.searchFunction(text, estado, search)}
                        onChange={(text) => SetPesquisar(text.target.value)}
                        onKeyDown={(e) => props.onKeyDown(e)}
                        selectEstado={props.estados}
                        pesquisa={props.search}
                      >
                      </InputSearch>
                    ) : (
                      <InputSearch
                        placeholder={props.placeholder}
                        estado=""
                        search='0'
                        estados={true}
                        update={(text, estado, search) => props.searchFunction(text, estado, search)}
                        onChange={(text) => SetPesquisar(text.target.value)}
                        selectEstado={props.estados}
                        pesquisa={props.search}
                      >
                      </InputSearch>
                    )
                  }
                  <div className="buttons" style={{display: 'flex',}}>
                    {
                      props.hideFilter == false && (
                        <>
                          <ExportButton style={{backgroundColor: '#BBC2C6'}} onClick={(val) => props.showFilter(true)}>
                            <img src={Filtro} style={{width: '13px'}}/>
                          </ExportButton>
                          <Desmarc style={{display: showOptions == true ? 'block' : 'none'}} onClick={() => SetShowOptions(false)}></Desmarc>
                        </>
                      )
                    }
                    {
                      props.hideExportar == false && (
                        <div className="teste" style={{zIndex: 2}}>
                          <ExportButton style={{
                            width: '133px'
                          }} onClick={
                            props.linkSearch ? window.location = props.linkSearch : () => SetShowOptions(!showOptions)
                          }>
                            <p>{props.tooltip}</p>
                            <img src={Arrow} style={{marginLeft: '4px', marginTop: '3px'}}/>
                          </ExportButton>
                          <ExportOptions style={{display: showOptions == true ? 'block' : 'none'}}>
                            {
                              showOptions == true && (
                                props.searchButtonOptions.map(e => (
                                  <>
                                    {e == 'PR' ? (
                                      <div className="option" onClick={() => props.searchButtonAct(1)}>
                                          <p onClick={() => props.searchButtonAct(1)}>Siagro</p>
                                      </div>
                                    ) : (<>
                                        {e == 'SC' ? (
                                            <div className="option" onClick={() => props.searchButtonAct(2)}>
                                                <p onClick={() => props.searchButtonAct(2)}>Cidasc</p>
                                            </div>
                                        ) : (<>
                                            {e == 'MT' ? (
                                                <div className="option" onClick={() => props.searchButtonAct(3)}>
                                                    <p onClick={() => props.searchButtonAct(3)}>Sisdev</p>
                                                </div>
                                            ) : (<>
                                                {e == 'GO' ? (
                                                    <div className="option" onClick={() => props.searchButtonAct(4)}>
                                                        <p onClick={() => props.searchButtonAct(4)}>Sidago</p>
                                                    </div>
                                                ) : (<>
                                                    {e == 'RS' ? (
                                                        <div className="option" onClick={() => props.searchButtonAct(5)}>
                                                            <p onClick={() => props.searchButtonAct(5)}>SDA RS</p>
                                                        </div>
                                                    ) : (<>
                                                        {e == 'ES' ? (
                                                            <div className="option" onClick={() => props.searchButtonAct(6)}>
                                                                <p onClick={() => props.searchButtonAct(6)}>IDAF</p>
                                                            </div>
                                                        ) : (<>
                                                            {e === 'BA' ? (
                                                                <div className="option" onClick={() => props.searchButtonAct(7)}>
                                                                    <p onClick={() => props.searchButtonAct(7)}>ADAB</p>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                {e === 'MG' ? (
                                                                  <div className="option" onClick={() => props.searchButtonAct(8)}>
                                                                      <p onClick={() => props.searchButtonAct(8)}>SICCA</p>
                                                                  </div>
                                                                  ) : (
                                                                      <>
                                                                      </>
                                                                  )}
                                                                </>
                                                            )}
                                                        </>)}
                                                    </>)}
                                                </>)}
                                            </>)}
                                        </>)}
                                    </>)}
                                  </>
                                ))
                              )
                            }
                          </ExportOptions>
                        </div>
                      )
                    }
                    {
                      props.addButton == true && (
                        <div className="teste" style={{zIndex: 2}}>
                          <AddButton onClick={
                            props.linkSearch ? () => window.location = props.linkSearch : () => SetShowOptions(!showOptions)
                          }>
                            <p>{props.tooltip}</p>
                            {
                              props.addOptions == true && (
                                <img src={Arrow} style={{marginLeft: '3px', marginTop: '3px'}}/>
                              )
                            }
                          </AddButton>
                          {
                            props.addOptions == true && (
                              <>
                                <ExportOptions style={{display: showOptions == true ? 'block' : 'none'}}>
                                  <div className="option" onClick={() => props.addOption01 ? window.location = props.addOption01 : props.addOptionModal ? props.addOptionModal : null}>
                                    <p onClick={() => props.addOption01 ? window.location = props.addOption01 : props.addOptionModal ? props.addOptionModal() : null}>Cadastrar</p>
                                  </div>
                                  <div className="option" onClick={() => props.importar(true)}>
                                    <p  onClick={() => props.importar(true)}>Importar</p>
                                  </div>
                                  {/* <div className="option" onClick={() => props.importarFarm(true)}>
                                    <p> Importar Fazendas </p>
                                  </div> */}
                                </ExportOptions>
                                <Desmarc style={{display: showOptions == true ? 'block' : 'none'}} onClick={() => SetShowOptions(false)}></Desmarc>
                              </>
                              
                            )
                          }
                        </div>
                      )
                    }
                    {
                      props.addLinkModal && (
                        <div className="teste" style={{zIndex: 2}}>
                          <AddButton onClick={
                            () => props.addLinkModal()
                          }>
                            <p>{props.tooltip}</p>
                            {
                              props.addOptions == true && (
                                <img src={Arrow} style={{marginLeft: '3px', marginTop: '3px'}}/>
                              )
                            }
                          </AddButton>
                        </div>
                      )
                    }
                  </div>
                </Search>
              )
            }
          </ContainerNew>
  
          <ModalText
            show={showModalHelp}
            onHide={() => setShowModalHelp(false)}
            text={props.text}
            btnOk={false}
            title={props.toSearch ? "AJUDA" : ""}
            toSearch={props.toSearch}
            textToSearch={props.textToSearch}
          />
        </UpperContainer>
  
      )}
    </>
  
  );
}

export default GenericUpper;
