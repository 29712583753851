import React, { useState, useEffect, useCallback } from 'react';
import { ContainerSuccess, Title, ContainerBackground, Icon02, Marker, Markers, Content, Text, Icon03, BoxTextoFoto, FundoSt2, TitleS2, Option, ImgStep02, BoxTextoFotoSt7, ImgStep07, FundoSt3, FundoSt7, BoxSt71, BoxSt72, BoxSt73, BoxSt74, BoxSt75, BoxSt76 } from './styles';
import Relatorios from '../../assets/tour/undraw_relatorios.svg';
import Seta from '../../assets/tour/undraw_arrow.svg';
import Card05 from '../../assets/tour/card05.svg';
import Icon from '../../assets/tour/card05-icon.svg';
import msg1 from '../../assets/tour/mensg1.svg';
import msg2 from '../../assets/tour/mensg2.svg';
import msg3 from '../../assets/tour/mensg3.svg';
import emoji3 from '../../assets/tour/emoji3.svg';
import emoji4 from '../../assets/tour/emoji4.svg';

import logoBranco from '../../assets/tour/logoIcone_branco.svg'
import { ImgFundo } from '../../pages/tour/styles';

const Step07 = ({ ...props }) => {

    return (
        <>
            <Content marginLeft={props.steps} style={{justifyContent: 'unset'}}>
                <BoxTextoFotoSt7>
                    <ImgStep07>
                        <div style={{
                            position: 'absolute',
                        }}>
                            <img src={Card05} style={{width: '18vw'}}/>
                            {
                                props.steps == 6 && (
                                <>
                                        <BoxSt71>
                                            <img src={msg1} style={{
                                                width: '17vw'
                                            }}/>
                                        </BoxSt71> 
                                        <BoxSt75>
                                            <img src={msg2} style={{
                                                width: '17vw'
                                            }}/>
                                        </BoxSt75>
                                        <BoxSt76>
                                            <img src={msg3} style={{
                                                width: '17vw'
                                            }}/>
                                        </BoxSt76>
                                </>
                                )
                            }
                        </ div>
                        <div style={{
                            zIndex: 3,
                            position: 'absolute',
                            marginLeft: '17vw'
                        }}>
                            <img src={Icon} style={{
                                marginLeft: '-1vw',
                                width: '12vw'
                            }}/>
                        </div>
                    </ImgStep07>
                    <FundoSt7>
                        <BoxSt72>
                            Atendimento <br />Personalizado <br />AgroReceita
                        </BoxSt72>
                        <BoxSt73>
                            Especialistas disponíveis para solucionar suas dúvidas <img src={emoji3} style={{width: '15px'}}/>
                        </BoxSt73>
                        <BoxSt74>
                            Estamos prontos para atender você <img src={emoji4} style={{width: '15px'}}/>
                        </BoxSt74>
                    </FundoSt7>
                </BoxTextoFotoSt7>
                <ImgFundo>
                    <img src={logoBranco} />
                </ImgFundo>
            </Content>
        </>
    );
};

export default Step07;
