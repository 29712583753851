import React, { useState, useEffect } from 'react';
import Button from '../../components/button/button_v2';
import GenericUpper from '../../components/generic-upper';
import InputV2 from '../../components/input/input_v2';
import List from '../../components/list';
import SelectV2 from '../../components/select/select_v2';
import api from '../../services/api';

import logopluga from '../../assets/logos/pluga.png';
import iconCopy from '../../assets/icons/icons8-copiar-32-branco.png';
import { JsonReceita, JsonProdutor, JsonCancelamento, JsonReceitaNativa, JsonProdutorNativa } from './codes';
import { ManualContainer, Text, Title } from './styles';


const Manual = ({ ...props }) => {
    const [voltar, setVoltar] = useState(false);

    
    const copy = async (json) => {
        await navigator.clipboard.writeText(json);
    }

    return (
        <>
            <ManualContainer>
                <Title>Como preencher os dados para integração:</Title>
                <Text>Na tela anterior, clique no botão <b>CRIAR INTEGRAÇÃO.</b></Text>
                <Text>Na próxima tela, primeiramente selecione qual função do AgroReceita deseja integrar com o seu sistema ERP.<br></br>
                    - A função <b>Emissão de Receita</b> enviará para o link todas as informações do receituário agronômico após a emissão.<br></br>
                    - A função <b>Cancelamento de Receita</b> enviará para o link todas as informações do receituário agronômico cancelado.<br></br>
                    - A função <b>Cadastro de Fazenda</b> enviará os dados do produtor e da fazenda cadastrada no AgroReceita para o link informado.</Text>
                <Text><b>url:</b> neste campo deverá ser digitado o link passado pelo sistema de ERP onde serão enviados os dados do JSON.</Text>
                <Text><b>Header:</b> neste caso deverá ser digitado os dados do header passados pelo sistema ERP com o tipo do Header e seu valor para o envio dos dados do JSON.</Text>
                <Title>Observações e Requisitos:</Title>
                <Text>•  Possuir a URL do outro sistema, que vai receber dados em formato JSON;<br></br>
                    •  Em caso de URLs personalizadas, é importante ter um desenvolvedor ou contato com o suporte do seu outro sistema para checar a compatibilidade com a leitura do pacote de dados que o AgroReceita envia.
                </Text>
                <Title>Exemplo de dados enviados pela função de Emissão de receitas</Title>
                <Title>Integrações via <img src={logopluga} width='60px' alt='Logo Pluga'/>:</Title>
                <Text>Os dados serão enviados seguindo a estrutura abaixo, que é a de um JSON padrão:</Text>
                <Text>    *Receitas emitidas com mais de um produto não serão enviadas via Pluga.</Text>
                <div className="quadro">
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={() => copy(JsonReceita)}
                    >
                        <img src={iconCopy} width='24px' alt='Copiar código'/>
                    </div>

                    <pre className="format">
                        {/* <JsonReceita /> */}
                        {JsonReceita}
                    </pre>
                </div>
                
                <Title>Integrações nativas AgroReceita:</Title>
                <Text>Os dados serão enviados seguindo a estrutura abaixo, que é a de um JSON padrão:</Text>
                <div className="quadro">
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={() => copy(JsonReceitaNativa)}
                    >
                        <img src={iconCopy} width='24px' alt='Copiar código'/>
                    </div>

                    <pre className="format">
                        {JsonReceitaNativa}
                    </pre>
                </div>

                <hr style={{
                    marginTop: '32px',
                    marginBottom: '32px'
                }} />
                
                
                <Title>Exemplo de dados enviados pela função de cancelamento:</Title>
                <Text>Os dados serão enviados seguindo a estrutura abaixo, que é a de um JSON padrão:</Text>
                <div className="quadro">

                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={() => copy(JsonCancelamento)}
                    >
                        <img src={iconCopy} width='24px' alt='Copiar código'/>
                    </div>

                    <pre className="format">
                        {JsonCancelamento}
                    </pre>
                </div>

                <hr style={{
                    marginTop: '32px',
                    marginBottom: '32px'
                }} />

                <Title>Exemplo de dados enviados pela função de Cadastro Fazendas</Title>
                <Title>Integrações via <img src={logopluga} width='60px' alt='Logo Pluga'/>:</Title>
                <Text>Os dados serão enviados seguindo a estrutura abaixo, que é a de um JSON padrão:</Text>
                <div className="quadro">
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={() => copy(JsonProdutor)}
                    >
                        <img src={iconCopy} width='24px' alt='Copiar código'/>
                    </div>

                    <pre className="format">
                        {JsonProdutor}
                    </pre>
                </div>

                
                <Title>Integrações nativas AgroReceita:</Title>
                <Text>Os dados serão enviados seguindo a estrutura abaixo, que é a de um JSON padrão:</Text>
                <div className="quadro">
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: '8px',
                            cursor: 'pointer'
                        }}
                        onClick={() => copy(JsonProdutorNativa)}
                    >
                        <img src={iconCopy} width='24px' alt='Copiar código'/>
                    </div>

                    <pre className="format">
                        {JsonProdutorNativa}
                    </pre>
                </div>

                {/* <hr style={{
                    marginTop: '32px',
                    marginBottom: '32px'
                }} /> */}

                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '32px'
                }}>
                    <Text>Em caso de dúvidas entre em contato pelo e-mail: suporte@sudesteonline.com.br ou pelo whatsApp: (15) 98165.7739</Text>
                </div>
                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px', marginBottom: '10px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='SAIR'
                        outline={false}
                        disabled={false}
                        onClick={() => props.fechar()}
                    />
                </div>
            </ManualContainer>
        </>
    )
}

export default Manual;