import React,{ useState, useEffect } from "react";
import api from "../../services/api";
import {
    Container,
    ScreenClassRender
} from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import { UserContainer } from "./styles";
import Select from "../../components/select/select_v2";
import Input from "../../components/input/input_v2";
import Button from "../../components/button/button_v2";
import { store } from "react-notifications-component";

import ModalLoja from "../../components/modal/modalAddLoja";
import ModalAlert from '../../components/modal/modalGeneric';

export const USER = "@userinfo";

const AddUsuario = ({ ...props }) => {

    const [permissoes] = useState([])
    const [lojas, setLojas] = useState([]);
    const [permissaoId, setPermissaoId] = useState('');
    const [permissaoLabel, setPermissaoLabel] = useState('');
    const [email, setEmail] = useState('');
    const [nome, setNome] = useState('');
    const [lojaId, setLojaId] = useState('');
    const [lojaLabel, setLojaLabel] = useState('');
    const [showModalLoja, setShowModalLoja] = useState(false);
    const [loading, setLoading] = useState(false);

    const [emailIsOk, setEmailIsOk] = useState(false);

    const [erroPermisao, setErroPermissao] = useState(false);
    const [erroNome, setErroNome] = useState(false);
    const [erroEmail, setErroEmail] = useState(false);
    const [erroLoja, setErroLoja] = useState(false);

    const [whatsapp, setWhatsapp] = useState('');

    const [userPlanId, setUserPlanId] = useState();
    const [showDisabledCad, setShowDisabledCad] = useState(false);

    const getFiliais = async () => {
        try {
            let inputWithErrors = document.querySelectorAll(".error");
    
            for (let input of inputWithErrors) {
                input.classList.remove("error");
            }
            
            const response = await api.get(`/permission/${permissaoId}/lojas`);
            const filiais = response.data.data;

            if (filiais && filiais.length > 0) {
                let filialFormat = formatFiliais(filiais);
                setLojas(filialFormat);
                
            } else {
                setLojas([]);
            }
        } catch (e) {
            setLojas([]);

            const input = document.getElementById('agro_subsidiary_id');
            input.classList.add("error");


            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    const formatFiliais = (filiais) => {
        let resultFilial = [];
        filiais.map(f => {
            let format = {value: f.id, label: f.nome_fantasia}
            resultFilial = [...resultFilial, format];
        })

        return resultFilial;
    }

    const validatePermissao = async (permissao) => {
        setPermissaoId(permissao.value);
        setPermissaoLabel(permissao.label);
        setErroPermissao(false);
    }

    const validateEmail = async (email) => {
        setEmail(email);
        setErroEmail(false);

        if (email === '') {
            setEmailIsOk(false);
        }
    }

    const validateName = async (nome) => {
        setNome(nome);

        setErroNome(false);
    }

    const validateLoja = async (loja) => {
        setLojaId(loja.value);
        setLojaLabel(loja.label);
        setErroLoja(false);
    }

    const salvarUser = async () => {
        setLoading(true);
        
        try {
            let user = localStorage.getItem(USER);
            let obj = JSON.parse(user);

            if(lojaId){
                const payload = {
                    agro_permission_id: permissaoId,
                    username: email,
                    agro_subsidiary_id: lojaId,
                    status: 'AT',
                    name: nome,
                    whatsapp
                }
                const response = await api.post(`/loja/${obj.agro_subsidiary_id}/users`, payload);
            }else{
                const payload = {
                    agro_permission_id: permissaoId,
                    username: email,
                    agro_subsidiary_id: '',
                    status: 'AT',
                    name: nome,
                    whatsapp
                }
                const response = await api.post(`/loja/${obj.agro_subsidiary_id}/users`, payload);
            }
            props.history.push('/usuarios');
            setLoading(false);
            
        } catch (err) {

            setLoading(false);

            const ERRO = err.response.data.errors;

            if (ERRO) {

                if (ERRO === 'O limite de licenças para esse contrato foi atingido!') {
                    setShowDisabledCad(true)
                } else {
                    store.addNotification({
                        title: "Erro!",
                        message: `${ERRO}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }

            } else {
                if (permissaoId === '') {
                    setErroPermissao(true);
                }

                if (email === '') {
                    setErroEmail(true);
                }

                if (lojaId === '') {
                    setErroLoja(true);
                }

                if (nome === '') {
                    setErroNome(true);
                }
            
                store.addNotification({
                    title: "Erro!",
                    message: "Verifique os campos em vermelho",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const updateLoja = async e => {
        getFiliais();
    }

    const validaEmail = async (email) => {
        try {
            setEmailIsOk(true);
        } catch (error) {
            setErroEmail(true);
            setEmailIsOk(false);


            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }


    const getPermission = async () => {
        const response = await api.get(`/nivel/permissao`);
        
        
        let v = response.data.data.nivel;

        v[1] = {
            value: 1,
            label: v[1]
        }

        v[2] = {
            value: 2,
            label: v[2]
        }

        v[3] = {
            value: 3,
            label: v[3]
        }

        for (let i in v) {
            permissoes.push(v[i]);
        }
        
    }

    const getUserPlan = () => {
        const user = JSON.parse(localStorage.getItem(USER));
        const planId = user.contrato.agro_plan_config.agro_plan_id;
        setUserPlanId(planId);
    }

    useEffect(() => {
        if(permissaoId){
            getFiliais();
        }
    },[permissaoId])


    useEffect(() => {
        // console.log("PROPS => ", props)
        getUserPlan();
        getPermission();
    },[])


    const openModalLoja = async e => {
        if(showModalLoja == false){
            setShowModalLoja(true);
        }
    }

    const closeModal = async e => {
        if(showModalLoja == true){
            setShowModalLoja(false);
        }
    }

    return(
        <ScreenClassRender
            render={screenClass => (
                <>
                    <GenericUpper
                        title="Cadastrar usuários"
                        subtitle="Gerencie seus usuários"
                    ></GenericUpper>

                    <UserContainer>
                        <Container>
                            <div className="container-center">

                                <Select 
                                    label="Permissão"
                                    options={permissoes}
                                    id="agro_permission_id"
                                    onChange={e => validatePermissao(e)}
                                    erro={erroPermisao}
                                />

                                <Input 
                                    label="Nome"
                                    type="text"
                                    id="name_id"
                                    onChange={e => validateName(e)}
                                    erro={erroNome}
                                />

                                <Input 
                                    label="Email"
                                    type="email"
                                    id="username"
                                    onChange={e => validateEmail(e)}
                                    erro={erroEmail}
                                    onBlur={(e) => validaEmail(e.target.value)}
                                    isValid={emailIsOk && email ? emailIsOk : false}
                                    loading={false}
                                />

                                <Input
                                    name="whatsapp"
                                    value={whatsapp}
                                    type="text"
                                    label="Whatsapp (opcional)"
                                    id="whatsapp"
                                    onChange={e => setWhatsapp(e)}
                                    inputmode="numeric"
                                />

                                <Select 
                                    label="Loja"
                                    options={lojas}
                                    id="agro_subsidiary_id"
                                    onChange={e => validateLoja(e)}
                                    buttonAddNew={true}
                                    onClickAddNew={() => openModalLoja()}
                                    erro={erroLoja}
                                />

                                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                    <Button 
                                        width='100%'
                                        height='50px'
                                        value='ADICIONAR'
                                        outline={false}
                                        disabled={loading}
                                        onClick={() => salvarUser()}
                                        loading={loading}
                                    />
                                </div>

                            </div>

                        </Container>
                    </UserContainer>
                        

                    <ModalLoja
                        title="Adicionar nova loja"
                        show={showModalLoja}
                        onHide={closeModal}
                        update={updateLoja}
                    >

                    </ModalLoja>


                    <ModalAlert
                        show={showDisabledCad}
                        onHide={() => setShowDisabledCad(false)}
                        size='md'
                    >
                        <h4 style={{
                            fontSize: '16px',
                            textAlign: 'center'
                        }}>Limite de cadastros atingido!</h4>

                        <p style={{
                            textAlign: 'center',
                            marginTop: '20px',
                            fontSize: '13px',
                            letterSpacing: '0px'
                        }}>
                            Adquira mais licenças para adicionar novos responsáveis técnicos!
                        </p>

                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                            <Button
                                width='296px'
                                height='50px'
                                value='ADQUIRIR MAIS LICENÇAS'
                                outline={false}
                                disabled={false}
                                onClick={() => window.location = '/plano/edit'}
                                loading={false}
                            />
                        </div>
                    </ModalAlert>
                </>
            )}
        />
    )


}

export default AddUsuario;
