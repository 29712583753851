import React from 'react';
// import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';


class ModalNew extends React.Component {
    state ={
        subititulo: []
    }


    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const [um, dois] = this.props.subtitle.split('.')
        this.setState({subititulo: [um,dois]})
    }

    
    cometoplanos = () =>{
        window.location.href = "/planos";
        this.props.onHide();
    }

    updateResponseOk = () => {
        let resposta = 1;
        this.props.update(resposta);
    }


    render() {
        const { subititulo } = this.state;
        return (

            // <Modal
            //     {...this.props}
            //     size="md"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8", border: 'none'}}>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //         </Modal.Title>
            //     </Modal.Header >
            //     <Modal.Body style={{backgroundColor: " #f8f8f8"}}>
            //     {this.props.title == 'Seu teste grátis terminou...' ? (<>
            //         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
            //                 <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.title}</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{subititulo}</p>
            //                 {/* <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{subititulo[1]}</p> */}
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Emissão de receitas</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Controle de Arts</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Relatórios completos</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Envio de dados para as defesas agropecuárias e CREA SP</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de produtores e locais de aplicação</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de responsáveis técnicos</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Impressão e consulta de fichas de emergência e FISPQs</p>
            //                 {/* <p style={{color: 'blue', cursor: 'pointer', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}} onClick={this.cometoplanos}>Conheça os planos</p> */}
                    
            //             <div style={{ marginLeft: '10px', width: '100%', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse'}}>

            //                 <a style={{ width: "187px", textDecoration: "none" }} href="/planos">
            //                 <Button style={{ outline:0, padding: "5px", width:"166px", height: "45px" }}
            //                     // outline
            //                     color="#00b43f"
            //                     >ASSINAR AGORA</Button>
            //                 </a>
            //             </div>
            //         </div>
            //     </>) : (<>
            //         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
            //                 <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.title}</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.subtitle}</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Emissão de receitas</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Controle de Arts</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Relatórios completos</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Envio de dados para as defesas agropecuárias e CREA SP</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de produtores e locais de aplicação</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de responsáveis técnicos</p>
            //                 <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Impressão e consulta de fichas de emergência e FISPQs</p>
            //                 {/* <p style={{color: 'blue', cursor: 'pointer', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}} onClick={this.cometoplanos}>Conheça os planos</p> */}
                    
            //             <div style={{ width: '100%', maxWidth: '350px', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse'}}>

            //                 <a style={{ width: "187px", textDecoration: "none" }} href="/planos">
            //                 <Button style={{ outline:0, padding: "5px", width:"166px", height: "45px" }}
            //                     color="#00b43f"
            //                     >VEJA OS PLANOS</Button>
            //                 </a>
            //                 {this.props.hoje == true ? (null):(
            //                     <Button
            //                         style={{ outline:0, padding: "5px", width:"150px", height: "45px"}}
            //                         color="#21262F"
            //                         outline
            //                         onClick={() => this.updateResponseOk()}
            //                     >
            //                         SAIR
            //                     </Button>
            //                 )}
            //             </div>
            //         </div>
            //     </>)}
            //     </Modal.Body>
            // </Modal>

            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='60%'
            >
                <ModalTitle> {this.props.title} </ModalTitle>

                <ModalBody>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.subtitle}</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Emissão de receitas</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Controle de Arts</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Relatórios completos</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Envio de dados para as defesas agropecuárias e CREA SP</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de produtores e locais de aplicação</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de responsáveis técnicos</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Impressão e consulta de fichas de emergência e FISPQs</p>
                    </div>
                </ModalBody>


                <ModalFooter justifyContent='space-evenly'>
                    <a style={{ width: "187px", textDecoration: "none" }} href="/planos">
                    <Button style={{ outline:0, padding: "5px", width:"166px", height: "45px" }}
                        color="#00b43f"
                        >VEJA OS PLANOS</Button>
                    </a>

                    {this.props.hoje == true ? (null):(
                        <Button
                            style={{ outline:0, padding: "5px", width:"150px", height: "45px"}}
                            color="#21262F"
                            outline
                            onClick={() => this.updateResponseOk()}
                        >
                            SAIR
                        </Button>
                    )}
                </ModalFooter>
            </Modal>

        );
    }
}
export default ModalNew;