import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { ContainerModalEdit, YesNoContainerGeral } from "../edit/styles"
import { store } from "react-notifications-component";
import { Loading } from '../../relatorios/style';
import { IconEdit } from "../../../assets/icons/edit"
import Button from '../../button/button_v2';
import InputV2 from '../../input/input_v2';
import SelectV2 from '../../select/select_v2';
import TextArea from '../../textarea/text_area_v2';
import Interruptor from '../../interruptor';



const PutOp = ({ ...props }) => {

    const [operacao, setOperacao] = useState('');
    const [operacoes, setOperacoes] = useState([])
    const [loadingOperacao, setLoadingOperacao] = useState(false);
    const [erroOperacao, setErroOperacao] = useState(false);
    const [disabledOperacao, setDisabledOperacao] = useState(false);

    const [etapa, setEtapa] = useState('');
    const [etapas, setEtapas] = useState([])
    const [loadingEtapa, setLoadingEtapa] = useState(false);
    const [erroEtapa, setErroEtapa] = useState(false);
    const [disabledEtapa, setDisabledEtapa] = useState(false);
    const [loading, setLoading] = useState(false)



    const [numero, setNumero] = useState('');
    const [oportunidade, setOportunidade] = useState('');
    const [descricao, setDescricao] = useState('');
    const [fazenda, setFazenda] = useState('');
    const [produtor, setProdutor] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [celular, setCelular] = useState('');

    const [produtorCliente, setProdutorCliente] = useState('');
    const [erroProdutorCliente, setErroProdutorCliente] = useState(false);
    const [fazendaCliente, setFazendaCliente] = useState('');
    const [erroFazendaCliente, setErroFazendaCliente] = useState(false);
    const [loadingProdutor, setLoadingProdutor] = useState(false);
    const [loadingFazenda, setLoadingFazenda] = useState(false);
    const [disabledFazenda, setDisabledFazenda] = useState(false);
    const [disabledProdutor, setDisabledProdutor] = useState(false);
    const [produtores, setProdutores] = useState([])
    const [fazendas, setFazendas] = useState([])
    const [showEventoComum, setShowEventoComum] = useState(false);



    const changeOportunidade = (value) => {
        setOportunidade(value)
    }

    const changeDescricao = (value) => {
        setDescricao(value)
    }

    const getOperacoes = async () => {
        try {
            setLoadingOperacao(true)
            setErroOperacao(false)
            const response = await api.get('/status')

            if (response) {
                setOperacoes(formatOperacoes(response.data.data))
                setLoadingOperacao(false)
            }
        } catch (error) {
            setLoadingOperacao(false)
            setErroOperacao(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeOperacao = (value) => {
        setOperacao(value)
    }

    const changeEvento = (value) => {
        setShowEventoComum(value)
    }

    const changeProdutorCliente = (value)=>{
        setProdutorCliente(value)
    }

    const changeFazendaCliente = (value)=>{
        setFazendaCliente(value)
    }

    const formatOperacoes = (values) => {
        let operacoes = [];

        values.map((operac) => {
            operacoes = [...operacoes, { value: operac.id, label: operac.descricao }];
        });

        return operacoes;
    }

    const formatProdutores = (values) => {
        let produtores = [];

        values.map((prod) => {
            produtores = [...produtores, { value: prod.id, label: prod.nome }];
        });

        return produtores;
    }

    const formatFazendas = (values) => {
        let fazendas = [];

        values.map((faze) => {
            fazendas = [...fazendas, { value: faze.id, label: faze.nome }];
        });

        return fazendas;
    }

    const getProdutores = async () => {
        setLoading(true)
        try {
            setLoadingProdutor(true)
            setErroProdutorCliente(false)
            const response = await api.get('/produtores')

            if (response) {
                setProdutores(formatProdutores(response.data.data))
                setLoadingProdutor(false)
                setLoading(false)
            }
        } catch (error) {
            setLoadingProdutor(false)
            setErroProdutorCliente(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });

        }

    }

    const getFazendas = async (id) => {
        if (id) {
            try {
                setLoadingFazenda(true)
                setErroFazendaCliente(false)
                const response = await api.get(`/produtores/${id}/fazendas`)

                if (response) {
                    setFazendas(formatFazendas(response.data.data))
                    setLoadingFazenda(false)
                }
            } catch (error) {
                setLoadingFazenda(false)
                setErroFazendaCliente(true)
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}.`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                });
            }
        }

    }

    const getEtapas = async () => {
        try {
            setLoadingEtapa(true)
            setErroEtapa(false)
            const response = await api.get('/etapa')

            if (response) {
                setEtapas(formatEtapas(response.data.data))
                setLoadingEtapa(false)
            }
        } catch (error) {
            setLoadingEtapa(false)
            setErroEtapa(true)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const changeEtapa = (value) => {
        setEtapa(value)
    }

    const formatEtapas = (values) => {
        let etapas = [];

        values.map((tip) => {
            etapas = [...etapas, { value: tip.id, label: tip.descricao }];
        });

        return etapas;
    }

    const changeFazenda = (value) => {
        setFazenda(value)
    }

    const changeProdutor = (value) => {
        setProdutor(value)
    }

    const changeEmail = (value) => {
        setEmail(value)
    }

    const changeTelefone = (value) => {
        setTelefone(value)
    }

    const changeCelular = (value) => {
        setCelular(value)
    }

    const getOportunidadeId = async (oport) => {
        try {

            const response = await api.get(`/oportunidade/${oport}`)

            console.log(response.data.data[0], 'response data 0')

            if (response) {
                setNumero(response.data.data[0].numero)
                setOportunidade(response.data.data[0].tipo_oportunidade)
                setOperacao(response.data.data[0].id_status)
                setEtapa(response.data.data[0].id_etapa)
                setDescricao(response.data.data[0].descricao)
                setFazenda(response.data.data[0].fazenda_nome)
                setProdutor(response.data.data[0].produtor_nome)
                setEmail(response.data.data[0].produtor_email)
                setTelefone(response.data.data[0].fazenda_telefone)
                setCelular(response.data.data[0].fazenda_celular)
                setFazendaCliente(response.data.data[0].agro_farm_id)
                setProdutorCliente(response.data.data[0].agro_producer_id)
            }
        } catch (error) {

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    const putOportunidade = async () => {
        try {
            setLoading(true);


            const payload = {
                fazenda_nome: fazenda ? fazenda : '',
                fazenda_cpf_cnpj: '',
                fazenda_telefone: telefone ? telefone : '',
                fazenda_celular: celular ? celular : '',
                produtor_nome: produtor ? produtor : '',
                produtor_email: email ? email : '',
                produtor_cpf_cnpj: '',
                oportunidade: oportunidade,
                etapa_id: etapa.value,
                status: operacao.value,
                descricao: descricao,
                agro_producer_id: produtorCliente ? produtorCliente.value : null,
                agro_farm_id: fazendaCliente ? fazendaCliente.value : null
            }


            const response = await api.put(`/oportunidade/${props.id}`, payload)
            if (response) {
                setLoading(false);
                props.close();
                props.get();



            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}.`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 10000,
                    onScreen: true
                }
            });
        }

    }

    useEffect(() => {
        getOperacoes()
        getEtapas()
        getProdutores()

    }, [])

    useEffect(() => {
        if (props.id !== 0) {
            getOportunidadeId(props.id)
        }

    }, [props.id])

    useEffect(() => {
        if (produtorCliente) {
            getFazendas(produtorCliente.value)
        }
    }, [produtorCliente])

    return (

        <>
            <InputV2
                marginBottom='0px'
                value={numero}
                id="numero"
                label="Número"
                disabled={true}
                digitavel={false}
                onChange={(e) => console.log(e)}
            />

            <InputV2
                marginBottom='0px'
                id="oportunidade"
                value={oportunidade || ''}
                label="Tipo de Oportunidade"
                onChange={(e) => changeOportunidade(e)}
            />

            <SelectV2
                marginBottom='0px'
                name='operacao'
                className="select"
                label="Status da Oportunidade"
                id='operacao_id'
                value={operacao.value || operacao}
                disabled={disabledOperacao}
                loading={loadingOperacao}
                erro={erroOperacao}
                onChange={(e) => changeOperacao(e)}
                options={operacoes}
            />

            <SelectV2
                marginBottom='0px'
                name='etapa'
                className="select"
                label="Etapa da Oportunidade"
                id='etapa_id'
                value={etapa.value || etapa}
                disabled={disabledEtapa}
                loading={loadingEtapa}
                erro={erroEtapa}
                onChange={(e) => changeEtapa(e)}
                options={etapas}
            />
            <TextArea
                marginBottom='10px'
                id="descricao"
                value={descricao || ''}
                name={"descricao"}
                disabled={false}
                digitavel={true}
                label="Descrição"
                maxlength="number"
                onChange={(e) => changeDescricao(e)}
            />
            {!produtorCliente ? (
                <>
                    <InputV2
                        marginBottom='0px'
                        id="fazenda"
                        value={fazenda || ''}
                        label="Nome da Fazenda"
                        onChange={(e) => changeFazenda(e)}
                    />
                    <InputV2
                        marginBottom='0px'
                        id="produtor"
                        value={produtor || ''}
                        label="Nome do Produtor"
                        onChange={(e) => changeProdutor(e)}
                    />
                    <InputV2
                        marginBottom='0px'
                        id="email"
                        value={email || ''}
                        label="E-mail"
                        onChange={(e) => changeEmail(e)}
                    />
                    <InputV2
                        marginBottom='0px'
                        id="telefone"
                        name="telefone"
                        value={telefone || ''}
                        label="Telefone"
                        onChange={(e) => changeTelefone(e)}
                    />
                    <InputV2
                        marginBottom='0px'
                        id="celular"
                        name="celular"
                        value={celular || ''}
                        label="Celular"
                        onChange={(e) => changeCelular(e)}
                    />
                </>

            ) : (
                <>
                    <SelectV2
                        marginBottom='0px'
                        name='produtor_cliente'
                        className="select"
                        label="Produtor"
                        id='produtor_id'
                        value={produtorCliente.value || produtorCliente}
                        disabled={disabledProdutor}
                        loading={loadingProdutor}
                        erro={erroProdutorCliente}
                        onChange={(e) => changeProdutorCliente(e)}
                        options={produtores}
                        selectUniqueOption={true}
                        buttonAddNew={false}
                        onClickAddNew={false}
                        isModal={true}
                        responsive={true}
                    />

                    <SelectV2
                        marginBottom='0px'
                        name='fazendas_cliente'
                        className="select"
                        label="Fazenda"
                        id='fazendas_id'
                        value={fazendaCliente.value || fazendaCliente}
                        disabled={disabledFazenda}
                        loading={loadingFazenda}
                        erro={erroFazendaCliente}
                        selectUniqueOption={true}
                        onChange={(e) => changeFazendaCliente(e)}
                        options={fazendas}
                    />
                </>
            )}

            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>
                <Button
                    width='296px'
                    height='50px'
                    value='SALVAR OPORTUNIDADE'
                    outline={false}
                    disabled={false}
                    onClick={() => putOportunidade()}
                    loading={loading}
                />
            </div>
        </>
    );
};

export default PutOp;