import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import api from "../../services/api";
import GenericUpper from "../../components/generic-upper";
import {Tela, SimulaPlanos} from "./styles";
import Button from '../../components/button/button_v2';
import CompraStep0 from '../../components/compra/step0';
import Footer from '../../components/footer';
import { store } from 'react-notifications-component';

export const store1 = "@store";

const Planos = ({...props}) => {

    const [step, setStep] = useState(0);

    const [planoId, setPlanoId] = useState('');
    const [gridId, setGridId] = useState('');
    const [numUsers, setNumUsers] = useState('');
    const [numDocs, setNumDocs] = useState('');
    const [numpresctipt, setNumPrescript] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [valorPlano, setValorPlano] = useState('');
    const [params, setParams] = useState('');

    const updateStep0 = (e) => {
        const { gridId, planId, numUsersId, numDocuments, numPrescriptions, planoSelecionado, valorTotal, valorPlano } = e;

        setGridId(gridId);
        if (planId) {
            setPlanoId(planId);                
        }

        if (numUsersId) {
            setNumUsers(numUsersId);
        }

        if (!isNaN(numDocuments)) {
            setNumDocs(numDocuments);
        } else {
            setNumDocs(0);
        }


        if (!isNaN(numPrescriptions)) {
            setNumPrescript(numPrescriptions);
        } else {
            setNumPrescript(0);
        }

        setValorTotal(valorTotal);
        setValorPlano(valorPlano);
    }

    const openAdquira = () => {
        try{

            if(planoId && (numUsers || numDocs || numpresctipt)){
                if(valorTotal == 0){
                    throw '';
                }

                const variavel = `${planoId}/${gridId}/${numUsers}/${numDocs}/${numpresctipt}/${valorTotal}/${valorPlano}`;



                localStorage.setItem(store1, variavel);
                window.location.href = `/login-compra/${planoId}`;
            }else{
                throw '';
            }
        }catch(error){
            store.addNotification({
                title: "Erro!",
                message: planoId ? `Preencha a quantidade de usuários`: `Selecione um plano`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const getPlano = async (id) => {
    
        const response =  await api.get(`/planos/${id}`);
        
        let plano = response.data.data[0];
    } 

    const validateParams = async () => {

        setPlanoId(props.match.params.id);
        getPlano(props.match.params.id);

    }

    const openTesteGratis = () => {
        window.location = '/cadastro';
    }

    useEffect(() => { 
        validateParams();
    }, [])

    return(
        <>
            <Tela>
                <GenericUpper
                    isSite={true}
                    title="Simule seu plano"
                    subtitle="Quanto mais usuários, mais descontos."
                    params={params}
                >
                </GenericUpper>

                <SimulaPlanos>
                    <CompraStep0
                        planoId={planoId}
                        numUsers={numUsers}
                        numDocuments={numDocs}
                        numPrescriptions={numpresctipt}
                        valorTotalProps={valorTotal}
                        valorPlanoProps={valorPlano}
                        update={(e) => updateStep0(e)}
                        next={()=>setStep(1)}
                        locProp={props}
                        isSimulation={true}
                        isOff={true}
                    />
    
                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '40px'}}>
                        <Button 
                            color="#21262F"
                            width='40%'
                            height='50px'
                            value='ADQUIRA JÁ!'
                            outline={true}
                            disabled={false}
                            onClick={() => openAdquira()}
                            loading={false}
                        />
                        <Button 
                            width='40%'
                            height='50px'
                            value='TESTE GRÁTIS'
                            outline={false}
                            disabled={false}
                            onClick={() => openTesteGratis()}
                            loading={false}
                        />
                    </div>
                </SimulaPlanos>

                <Footer />
            </Tela>
        </>
     )
}

export default withRouter(Planos);