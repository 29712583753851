
//Formatar planos para tabela
export const formatPlansToTable = (values) => {
    // console.log(values);
    // let headers = values.funcoes;
    // let plans = values.planos;
    // let permissoes = values.permissoes;

    // let table = [[], [], []];

    // table[0] = [''];

    // console.log('TABELA', table);
    // plans.map((p) => {
    //     table[0].append(p);
    // })

}

export const formatCulturas = culturas => {
    culturas = culturas.map(cultura => {
        const format = {
            value: cultura.id,
            label: cultura.nome_comum
        };
        return format;
    });
    return culturas;
};

export const formatAgronomos = agronomos => {
    agronomos = agronomos.map(agronomo => {
        const format = {
            value: agronomo.id,
            label: agronomo.nome
        };
        return format;
    });
    return agronomos;
};

export const formatArt = arts => {
    arts = arts.map(art => {
        const format = {
            value: art.id,
            label: art.numero_art
        };
        return format;
    });
    return arts;
};

export const formatPrevisaoGrafico = (previsao) => {
    let response = [];

    let datasMin = [];
    let datasMax = [];

    let datasUmidMin = [];
    let datasUmidMax = [];

    let datasResumo = [];

    let datasVentos = [];

    let datasDirVentos = [];

    let datasNascerSol = [];
    let datasOcasoSol = [];

    let datasIcone = [];

    let datasEstacao = [];

    let labels = [];

    let keys = Object.keys(previsao);

    keys.map((key, i) => {
        if (i < 2) {    
            // datasMin = [ ...datasMin, previsao[key].manha.temp_min ];
            datasMin = [ ...datasMin, previsao[key].tarde.temp_min ];
            // datasMin = [ ...datasMin, previsao[key].noite.temp_min ];
            
            // datasMax = [ ...datasMax, previsao[key].manha.temp_max ];
            datasMax = [ ...datasMax, previsao[key].tarde.temp_max ];
            // datasMax = [ ...datasMax, previsao[key].noite.temp_max ];

            // datasUmidMin = [ ...datasUmidMin, previsao[key].manha.umidade_min ];
            datasUmidMin = [ ...datasUmidMin, previsao[key].tarde.umidade_min ];
            // datasUmidMin = [ ...datasUmidMin, previsao[key].noite.umidade_min ];
            
            // datasUmidMax = [ ...datasUmidMax, previsao[key].manha.umidade_max ];
            datasUmidMax = [ ...datasUmidMax, previsao[key].tarde.umidade_max ];
            // datasUmidMax = [ ...datasUmidMax, previsao[key].noite.umidade_max ];

            // datasResumo = [ ...datasResumo, previsao[key].manha.resumo ];
            datasResumo = [ ...datasResumo, previsao[key].tarde.resumo ];
            // datasResumo = [ ...datasResumo, previsao[key].noite.resumo ];
            
            // datasVentos = [ ...datasVentos, previsao[key].manha.int_vento ];
            datasVentos = [ ...datasVentos, previsao[key].tarde.int_vento ];
            // datasVentos = [ ...datasVentos, previsao[key].noite.int_vento ];

            datasDirVentos = [ ...datasDirVentos, previsao[key].tarde.dir_vento ];

            
            // datasNascerSol = [ ...datasNascerSol, previsao[key].manha.nascer ];
            datasNascerSol = [ ...datasNascerSol, previsao[key].tarde.nascer ];
            // datasNascerSol = [ ...datasNascerSol, previsao[key].noite.nascer ];
            
            // datasOcasoSol = [ ...datasOcasoSol, previsao[key].manha.ocaso ];
            datasOcasoSol = [ ...datasOcasoSol, previsao[key].tarde.ocaso ];
            // datasOcasoSol = [ ...datasOcasoSol, previsao[key].noite.ocaso ];
            
            // datasIcone = [ ...datasIcone, previsao[key].manha.icone ];
            datasIcone = [ ...datasIcone, previsao[key].tarde.icone ];
            // datasIcone = [ ...datasIcone, previsao[key].noite.icone ];
            
            // datasEstacao = [ ...datasEstacao, previsao[key].manha.estacao ];
            datasEstacao = [ ...datasEstacao, previsao[key].tarde.estacao ];
            // datasEstacao = [ ...datasEstacao, previsao[key].noite.estacao ];

            // labels = [ ...labels, `${key} - Manhã`];
            labels = [ ...labels, `${previsao[key].tarde.dia_semana.split('-')[0]}`]; //TARDE
            // labels = [ ...labels, `${key} - Noite`];
        } else {

            datasMin = [ ...datasMin, previsao[key].temp_min ];
            datasMax = [ ...datasMax, previsao[key].temp_max ];

            datasUmidMin = [ ...datasUmidMin, previsao[key].umidade_min ];
            datasUmidMax = [ ...datasUmidMax, previsao[key].umidade_max ];

            datasResumo = [ ...datasResumo, previsao[key].resumo ];

            datasVentos = [ ...datasVentos, previsao[key].int_vento ];

            datasDirVentos = [ ...datasDirVentos, previsao[key].dir_vento ];

            datasNascerSol = [ ...datasNascerSol, previsao[key].nascer ];

            datasOcasoSol = [ ...datasOcasoSol, previsao[key].ocaso ];

            datasIcone = [ ...datasIcone, previsao[key].icone ];

            datasEstacao = [ ...datasEstacao, previsao[key].estacao ];


            labels = [ ...labels, `${previsao[key].dia_semana.split('-')[0]}`];
        }
    })

    response = {
        tmp_min: datasMin,
        tmp_max: datasMax,
        umidade_min: datasUmidMin,
        umidade_max: datasUmidMax,
        resumo: datasResumo,
        ventos: datasVentos,
        dir_ventos: datasDirVentos,
        nascer_sol: datasNascerSol,
        ocaso_sol: datasOcasoSol,
        icone: datasIcone,
        estacao: datasEstacao,
        labels
    };

    return response;
}

