import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/button_v2';
import Input from "../input";

import { ModalBody, ModalTitle, StyleModal } from "./styles";


import { store } from "react-notifications-component";
import validadorDeCpf from '../../services/validadorCpf';

import api from '../../services/api';

import mais from "../../assets/Mais.png";


import ModalPassword from './modal_password';
import ModalText from './modalText';
import ModalAlert from './modalGeneric';
import { logout } from '../../services/auth';
import Modal from '.';
import IconHelp from '../../assets/icons/help';

export const USER = "@userinfo";


class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        namesfiles: [],
        file: [],
        textFile: "",
        isValidate: false,
        showModal: false,
        showModalText: false,
        renderInput: true,
        showModalBlock: false,

    };
    componentDidMount() {

    }

    validaCertificado = async (file, password) => {
        if (this.props.cpf) {
            if (file && this.state.isValidate == false && password) {
                const payload = {
                    certificado: file,
                    cpf: this.props.cpf,
                    senha: password,
                }
                try {

                    let user = JSON.parse(localStorage.getItem(USER));
                    if (user.contrato) {
                        if (user.contrato.agro_plan_functions) {
                            let isBlock = false;

                            user.contrato.agro_plan_functions.map((e) => {
                                console.log(e);

                                if (e.agro_plans_function.id === 9) {
                                    if (e.status === 0) {
                                        isBlock = true;
                                    }
                                }
                            });

                            if (isBlock === true) { 
                                this.closeModalPassword();
                                store.addNotification({
                                    title: "Erro!",
                                    message: `A função de assinatura digital não está liberada para seu plano!`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            } else { 
                                const response = await api.post(`/sign/digital`, payload);
                                if (response) {
                                    this.setState({isValidate: true});
                                }

                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `${response.data.data.msg}`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });

                                this.props.onHide(password);
                            }
                        } else {
                            logout();
                            window.location = '/login';
                        }
                    } else {
                        logout();
                        window.location = '/login';
                    }
                } catch (error) {
                    this.closeModalPassword();
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        } else {
            store.addNotification({
                title: "Erro!",
                message: `Informe o CPF!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    onChangeCertificate = (e) => {


        let user = JSON.parse(localStorage.getItem(USER));


        if (user.contract_software_id === 6) {
            this.setState({ showModalBlock: true });
        } else {
            let cont = e.target.files.length;
            cont = cont - 1;
    
            if(e.target.files){
                if(
                    e.target.files[cont].type == "application/x-pkcs12"
                ){                    
                    let fil = e.target.files;
                    let reader = new FileReader();
                    reader.readAsDataURL(fil[0]);
    
                    reader.onload=(e)=>{
                        this.setState({file: e.target.result});
                        this.setState({textFile: e.target.result})
                        // this.validaCertificado(e.target.result);
                        if (this.props.cpf) {
                            this.setState({ showModal: true })
                        } else {
                            store.addNotification({
                                title: "Erro!",
                                message: `Informe o CPF!`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    }
                    this.setState({namesfiles: e.target.files[cont].name});                   
    
                } else {
                    store.addNotification({
                        title: "Erro!",
                        message: `O arquivo selecionado não possui a extensão correta`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Não é possível enviar mais que um arquivo!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }


    };
    deleteFile = () => {
        this.setState({namesfiles: []}) ;
        this.setState({isValidate: false});
    };

    openModalText = () => {
        this.setState({ showModalText: true });
    }
    modalTextClose = () => {
        this.setState({ showModalText: false });
    }

    updatePassword = (e) => {
        this.validaCertificado(this.state.file, e);
        this.setState({showModal: false});
    }
    
    closeModalPassword = () => {
        this.setState({renderInput: false});
        this.setState({showModal: false});
        this.setState({namesfiles: []});
        this.setState({textFile: ''});
    }


    render() {
        const {
            namesfiles,
        } = this.state;
        
        return (
            <>
                <Modal
                    {...this.props}
                    align='center' // center, end, start
                    justify='center' // center, end, start
                    responsive
                    width='60%'
                >
                    <ModalTitle> Upload do certificado digital </ModalTitle>

                    <ModalBody>
                        <StyleModal>
                            <form>
                                <div class='input-wrapper' style={{ background: '#FFF' }}>
                                    <div className="arquivos" style={{ justifyContent: 'center' }}>
                                        <label 
                                            className='btn-update'
                                            for='input-file' 
                                            multiple
                                            onClick={() => {this.setState({renderInput: true})}}
                                        >
                                                Selecionar arquivo
                                        </label>
                                        <label className="arq-name" style={{ width: '330px', marginTop: '0px' }}>
                                            {namesfiles.length > 0 ? 
                                            (<>
                                                {namesfiles}
                                                <img src={mais}  width="20" height="20" onClick={() => this.deleteFile()}/>
                                            </>)  : "Nenhum arquivo selecionado" } </label>                 
                                    
                                        <div className="help-buttom" onClick={this.openModalText}>
                                            <IconHelp 
                                                fill='#5DA8FD'
                                                cursor='pointer'
                                                style={{
                                                    marginTop: '6px',
                                                    marginLeft: '6px'
                                                }}
                                            />

                                        </div>
                                    
                                    
                                    </div>

                                    <label className="legenda" >Selecione o arquivo PFX em seu computador.</label>
                                    <label className="legenda" >O upload do certificado é necessário caso deseje assinar sua receita digitalmente</label>
                                    {this.state.renderInput ? (
                                        <input 
                                            style={{display: "none"}}
                                            type="file"
                                            id="input-file"
                                            name="file"
                                            multiple
                                            onChange={(e)=>this.onChangeCertificate(e)}
                                        />
                                    ):(null)}
                                </div>
                            </form>
                        </StyleModal>

                        <ModalPassword
                            text={"Precisamos da sua senha para validar a autenticidade do certificado. Para sua segurança, essa senha não ficará salva em nossos servidores."}
                            show={this.state.showModal}
                            update={(e) => this.updatePassword(e)}
                            onHide={this.closeModalPassword}
                            size='md'
                        />
                        <ModalText
                            show={this.state.showModalText}
                            onHide={this.modalTextClose}
                            text={"Certificado digital é um arquivo eletrônico que serve como identidade virtual para uma pessoa física ou jurídica. Ele serve para agilizar a assinatura conferindo legitimidade aos documentos digitais, que passam a possuir validade jurídica. Esse certificado deve ser emitido por uma Autoridade Certificadora vinculada ao ICP-Brasil"}
                            btnOk={false}
                            size='md'
                        />  

                        <ModalAlert
                            show={this.state.showModalBlock}
                            onHide={false}
                            size='md'
                        >
                            <h4 style={{
                                textAlign: 'center'
                            }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

                            <p style={{
                                textAlign: 'center',
                                marginTop: '20px',
                            }}>
                                Para não comprometer o controle de suas receitas agronômicas essa função só pode ser utilizada nos planos Light e Pro. Entre em contato com nosso suporte e faça o upgrade do seu plano para desbloquear esse recurso.
                            </p>

                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                                <Button
                                    width='296px'
                                    height='50px'
                                    value='ENTENDI'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => this.setState({ showModalBlock: false })}
                                    loading={false}
                                />
                            </div>
                        </ModalAlert>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}
export default ModalNew;