import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import api from "../../services/api";
import { base64UrlEncode } from "../../services/globalFunctions";

export const LINKEDIN_EMAIL = '@agroreceita_linkedin_email';
export const LINKEDIN_USER = '@agroreceita_linkedin_nome_user';
export const LINKEDIN_ID = '@agroreceita_linkedin_id';


const LoginLinkedinPage = ({ ...props }) => {

    const [code, setCode] = useState('');

    useEffect(()=>{
        let code = window.location.search.slice(1);
        code = code.split('&');
        code = code[0].split('=')[1];
        let state = code[1].split('=')[1];
        setCode(code);

        if(code !== ''){
            sigInLinkedin(code);

        }      
    }, [])

    const sigInLinkedin = async (auth) => {
        let email = "";
        try {

            const payload = {
                code: auth,
                redirect: process.env.REACT_APP_API_HOST_LINKEDIN
            }
            const response = await api.post('/linkedin/access', payload)

            if (response) {
                email = response.data.data.email;
                localStorage.setItem("@agroreceita_linkedin_email", response.data.data.email);
                localStorage.setItem("@agroreceita_linkedin_nome_user", response.data.data.nome);
                localStorage.setItem("@agroreceita_linkedin_id", response.data.data.senha);

                const ios = localStorage.getItem('@agroreceita:ios');

                if (ios || ios === 'true') {
                    if (email !== '') {
                        try {
                            const responseLogin = await api.post(`/cadastro/validaEmail`, { email });
    
                            if (responseLogin) {
                                window.location = '/cadastro?username='+encodeURIComponent(email)+'&nome='+encodeURIComponent(response.data.data.nome)+'&id='+encodeURIComponent(response.data.data.senha);
                            }
    
                        } catch (errorLogin) {
                            if (errorLogin.response.status === 417) {
                                window.location = '/login?q='+base64UrlEncode(email + ';'+ null + ';'+ true);
                            }
                        }
                    }
                }else{
                    closeWindow();
                }

            }
        } catch (error) {
            localStorage.setItem('@agroreceita_linkedin_auth', JSON.stringify(auth));

            setTimeout(() => {
                window.location = '/cadastro';
            }, 3000);
        }
    }

    function closeWindow() {
        // Open the new window 
        // with the URL replacing the
        // current page using the
        // _self value
        let new_window =
            window.open(window.location, '_self');
    
        // Close this window
        new_window.close();
    
        return false;
    }

    return (
        <></>
    );
};

export default withRouter(LoginLinkedinPage);
