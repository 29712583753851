import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import api from '../../services/api';
import googlemaps from '../../services/googlemaps';
import { apiprevmet3 } from '../../services/inmet_api';
import Termometro from '../../assets/termometro.svg';
import Higrometro from '../../assets/higrometro.svg';

import { AreaInfo, Card, ChartArea, Col, Col2, Col5, Loading, Row, Text, Title, LineBox, TextDirection, PrimeiraLinha } from './style';
import { Line } from 'react-chartjs-2';
import { formatPrevisaoGrafico } from '../../services/format';
export const AGRORECEITA_USER_INFO_LOCATION = '@agroreceita_user_info_location';

const ResumoWeekAlert = ({ ...props }) => {

    const { ibge } = props;

    const [previsao, setPrevisao] = useState('');
    const [showChartResumo, setShowChartResumo] = useState(false);
    const [loading, setLoading] = useState(false);

    const getPrevisaoTempo = async (ibge) => {
        try {
            setLoading(true)
            const response = await apiprevmet3.get(`/previsao/${ibge}`);

            if (response) {
                setLoading(false)
                let prev = response.data;
                const p = formatPrevisaoGrafico(prev[ibge]);

                setPrevisao(p);
                setShowChartResumo(true);
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
            setShowChartResumo(false);
            // store.addNotification({
            //     title: "Erro!",
            //     message: "Erro INMET",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }

    useEffect(() => {
        getPrevisaoTempo(ibge);
    }, [ibge])

    return(
        <>
            <ChartArea>
                {loading ? (
                    <Loading/>
                ) : (
                    <>
                    {showChartResumo ? (
                        <>
                            {
                                props.isMobile == false && window.innerWidth >= 1130 ? (
                                    <div >
                                        <PrimeiraLinha>
                                            <img style={{marginTop: '-5px'}} height={'75px'} width={'75px'} src={previsao.icone[0]} />
                                            <div style={{
                                                fontSize: '21px'
                                            }}>
                                                <div style={{
                                                    color: '#ABABAB'
                                                }}>
                                                    {previsao.tmp_min[0]}°C
                                                </div>
                                                <hr style={{marginTop:'0px', marginBottom: '0px'}}/>
                                                <div>
                                                    {previsao.tmp_max[0]}°C
                                                </div>
                                            </div>
                                            <div className='itens-previsao'>
                                                <div>
                                                    <b>UMIDADE: </b>{previsao.umidade_min[0]}% | {previsao.umidade_max[0]}%
                                                </div>
                                                <div>
                                                    <b>VENTO: </b>Ventos {previsao.ventos[0]}
                                                </div>
                                                <div>
                                                    <b>DIR. VENTO: </b>{previsao.dir_ventos[0]}
                                                </div>
                                                <p style={{marginBottom: '7px'}}>{previsao.resumo[0]}</p>
                                            </div>
    
                                            <div className='dia-previsao'>
                                                <div className='numero-dia-previsao'>
                                                    {new Date().getDate()}
                                                </div>
                                                <div style={{
                                                    fontSize: '12px',
                                                }}>
                                                    <b>{previsao.labels[0].toUpperCase()}</b>
                                                </div>
                                            </div>
                                        </PrimeiraLinha>
                                        <hr style={{
                                            marginTop: '0px',
                                            marginBottom:'5px'
                                        }} />
    
                                        <Row alignItems='flex-start'>
                                        <>
                                            {previsao.labels.map((label, i) => (
                                            i > 0 && (
                                                <>  
                                                    <div style={{
                                                        width: '100%',
                                                        
                                                    }}>
                                                        <Title textAlign='center' marginLeft='0px' marginTop='0px' style={{marginBottom:'-5px'}}> <b>{label}</b>  </Title>
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            flexDirection: 'column',
                                                            margin: '3px 0px'
                                                        }}> <img src={previsao.icone[i]} style={{ width: '50px' }} />  </div>
    
                                                        <div style={{
                                                            marginLeft: '20px'
                                                        }}>
                                                            <div className='item-dados'> 
                                                                <img width={'17px'} src={Termometro}/>
                                                                {previsao.tmp_min[i]}°C | <b style={{marginLeft: '2px'}}>{previsao.tmp_max[i]}°C</b>
                                                            </div>
    
                                                            <div className='item-dados'> 
                                                                <img width={'17px'} src={Higrometro}/>
                                                                {previsao.umidade_min[i]}% | {previsao.umidade_max[i]}%
                                                            </div>
    
                                                            <div className='item-dados' style={{
                                                                fontSize: '10px',
                                                            }}> 
                                                                <img style={{ width: '17px'}} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEs0lEQVR4nO2aS2wbVRSG/zN+JVGo0kiVWFAo1E6pTHgodppGogRCKFSCZQQbJBSIbUAgBUTUBVUFC0olHopa1Y6CWEbqig2PpiHQSMhO7YgKYag6CQQqWBSJRpDGjR9zWMRpx9PYM2OPEzvcb3fPnMe9Z+beuTPnAgKBQCAQCP6v0GZ3QI+OyHy7pPCLTNwLYFdevMCgSWKMJULuHyvxX7MJcI/Iru0OfMSEAACpiFoOQDjVmh5K9nvT5cSpyQS4R2RXixNfAnjUiD4BU8ut6afKSUKxzG4qLU76GAYHDwAMPNZ41flBObFqLgEdkfl2gAc14ihBejzdtNycblpuJon7CIgVaDBC/lHZazaevZLOVgXmARTemGiqNd2jebwnvaeT001/O88x0JWX2ZgxAGDITLiaewIA7lO3CNLb683tZL83zeAjhabo0+rpUXMJIGCnur3StBQrppvKZaIa0V1m49XcFEgEPds2Ml7NPQFmaLA7uzWi38z6qNsEuEdkFzHeKRASzpr1U3NTQA/vyWRzg93ZnR/8PtWlnI0xZtafbgJ8YZnNOq066/SImE7OhNw/mXVVt1OgAKZJRVp8sxzTek9AjphGWFo8NBvwZcpxUHdrAIAlAAsAJmzAJ+U89gKBQCAQrKL7T7Amd4I3uQbgMsDTYBpPhDzfmnVQ7wnQctbO2cFYaO+CUYN63wlq6cvCfr4jcmmfvuoqWy0BAGEHMX3eEZF3G1OvY25+GvNbAPVqLk8kgp6Dej7qOgE3YCZ/ZO49BobVYpLQEx/0nCtlujWmABHHA+7DBEypxazQs3qmWyMBAEDECuH9Qhk/ome2dRIAoMGeK/xNzrhDz6Zm/wf4wvI/AG5ba7scuW3fDdz7bymb61mHRFDUIt09TNX/CRJwlYEFBr6SFB6Lv9z2i0HLPwHes9ZayUhdQOm/vqwoXaRe1gl/6EWp+hRgYDuAhwg4zBJd9IflYz1HvzHw5Cna1XsYzMXfWswkUeFbAEwl3wDAxq8BDgaGl27f+ZluEpjGCwXU6xudP7ZuEpipIyIfh6akTlDGb9HVsJnfAh8mgp43dGJPANAUSzGVYxx3ZBAFgIwD+/N3XjN4nIkHPU/qdaLqG6EHP/21xb6idINzR0HkV13KMints4E9F4vZdp36eVcW9vMg7DATk4Ar2ayt8/tX79EtlVV9Clx44e7FRHD3F4sZelhzqMFOLL1UyjYW2rvAEj8Nxl9G4xFwRSF+xsjggQ1cA+Ze86zcUs8HntCzmw20zdiR7QQwoadLwJls1tY5G2ibMdqvDd0HpHKZaKPNqRYZqufnv+8P+sOXDjCk5xh8gIA7AYCB3wk0TVDG48G2abN9qjgB2g1Lumm5+YfnH7hWqd/1yA/Q9CBLUfEUYOCyuu263ri/mK4V9XyrsWANoMLdmULvek8nnVotq+r5VlPxa9B3au4+EF8AYFM5jTH4yNoZnqL1fOD+maBnU2t7luwDfGH5BIBXTAVmGomH3K9bEb8SLHkNplrTQwB/bdiggnq+1ViSgGS/N51qzRwC4QRWDzAXo+J6vtVYvhX2j8peZgzkDy3eON6OtXr+Js95gUAgEAgEgjX+Axe2kswAiZejAAAAAElFTkSuQmCC" />
                                                                {
                                                                    previsao.ventos[i].length > 11 ? (
                                                                        <>
                                                                            <p style={{width: '100px', marginLeft: '25px'}} className='msgVentos'>Ventos {previsao.ventos[i]}</ p>
                                                                            <br />
                                                                            <p id='msgVentosCompleta'>Ventos {previsao.ventos[i]}</ p>
                                                                        </>
                                                                    ) : (
                                                                        <p style={{maxWidth: '100px'}} className='msgVentos'>Ventos {previsao.ventos[i]}</ p>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
    
                                                    </div>
                                                    {i < 4 && (
                                                        <LineBox style={{
                                                            height:'118px'
                                                        }} />
                                                    )}
                                                </>
                                            )
                                            ))}
                                        </>
                                    </Row>
                                    </div>
                                ) : (
                                    <>
                                        <Row isMobile={true} alignItems='flex-start'>
                                            <>
                                                {previsao.labels.map((label, i) => (
                                                    <>
                                                        <Col width='93%' justifyContent="space-between">
                                                            <Title textAlign='center' marginLeft='0px' marginTop='0px'> <b>{label}</b>  </Title>
                                                            <Text> <img src={previsao.icone[i]} style={{ width: '50px' }} />  </Text>
                                                            <Text fontSize="11px"> {previsao.resumo[i]}  </Text>
    
                                                            <div className='item-dados' isMobile={true}> 
                                                                <img style={{width: '20px'}} src={Termometro} />
                                                                {previsao.tmp_min[i]}°C | <b style={{marginLeft:'2px'}}>{previsao.tmp_max[i]}°C</b>
    
                                                            </div>
    
                                                            <div className='item-dados' isMobile={true} style={{marginTop:'-6px'}}> 
                                                                <img style={{width: '20px'}} src={Higrometro} />
                                                                {previsao.umidade_min[i]}% | {previsao.umidade_max[i]}%
                                                            </div>
                                                            
                                                            <Text> 
                                                                <img style={{width: '20px' }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAAEs0lEQVR4nO2aS2wbVRSG/zN+JVGo0kiVWFAo1E6pTHgodppGogRCKFSCZQQbJBSIbUAgBUTUBVUFC0olHopa1Y6CWEbqig2PpiHQSMhO7YgKYag6CQQqWBSJRpDGjR9zWMRpx9PYM2OPEzvcb3fPnMe9Z+beuTPnAgKBQCAQCP6v0GZ3QI+OyHy7pPCLTNwLYFdevMCgSWKMJULuHyvxX7MJcI/Iru0OfMSEAACpiFoOQDjVmh5K9nvT5cSpyQS4R2RXixNfAnjUiD4BU8ut6afKSUKxzG4qLU76GAYHDwAMPNZ41flBObFqLgEdkfl2gAc14ihBejzdtNycblpuJon7CIgVaDBC/lHZazaevZLOVgXmARTemGiqNd2jebwnvaeT001/O88x0JWX2ZgxAGDITLiaewIA7lO3CNLb683tZL83zeAjhabo0+rpUXMJIGCnur3StBQrppvKZaIa0V1m49XcFEgEPds2Ml7NPQFmaLA7uzWi38z6qNsEuEdkFzHeKRASzpr1U3NTQA/vyWRzg93ZnR/8PtWlnI0xZtafbgJ8YZnNOq066/SImE7OhNw/mXVVt1OgAKZJRVp8sxzTek9AjphGWFo8NBvwZcpxUHdrAIAlAAsAJmzAJ+U89gKBQCAQrKL7T7Amd4I3uQbgMsDTYBpPhDzfmnVQ7wnQctbO2cFYaO+CUYN63wlq6cvCfr4jcmmfvuoqWy0BAGEHMX3eEZF3G1OvY25+GvNbAPVqLk8kgp6Dej7qOgE3YCZ/ZO49BobVYpLQEx/0nCtlujWmABHHA+7DBEypxazQs3qmWyMBAEDECuH9Qhk/ome2dRIAoMGeK/xNzrhDz6Zm/wf4wvI/AG5ba7scuW3fDdz7bymb61mHRFDUIt09TNX/CRJwlYEFBr6SFB6Lv9z2i0HLPwHes9ZayUhdQOm/vqwoXaRe1gl/6EWp+hRgYDuAhwg4zBJd9IflYz1HvzHw5Cna1XsYzMXfWswkUeFbAEwl3wDAxq8BDgaGl27f+ZluEpjGCwXU6xudP7ZuEpipIyIfh6akTlDGb9HVsJnfAh8mgp43dGJPANAUSzGVYxx3ZBAFgIwD+/N3XjN4nIkHPU/qdaLqG6EHP/21xb6idINzR0HkV13KMints4E9F4vZdp36eVcW9vMg7DATk4Ar2ayt8/tX79EtlVV9Clx44e7FRHD3F4sZelhzqMFOLL1UyjYW2rvAEj8Nxl9G4xFwRSF+xsjggQ1cA+Ze86zcUs8HntCzmw20zdiR7QQwoadLwJls1tY5G2ibMdqvDd0HpHKZaKPNqRYZqufnv+8P+sOXDjCk5xh8gIA7AYCB3wk0TVDG48G2abN9qjgB2g1Lumm5+YfnH7hWqd/1yA/Q9CBLUfEUYOCyuu263ri/mK4V9XyrsWANoMLdmULvek8nnVotq+r5VlPxa9B3au4+EF8AYFM5jTH4yNoZnqL1fOD+maBnU2t7luwDfGH5BIBXTAVmGomH3K9bEb8SLHkNplrTQwB/bdiggnq+1ViSgGS/N51qzRwC4QRWDzAXo+J6vtVYvhX2j8peZgzkDy3eON6OtXr+Js95gUAgEAgEgjX+Axe2kswAiZejAAAAAElFTkSuQmCC" /> 
                                                                <b>Ventos {previsao.ventos[i]}</b>  
                                                            </Text>
                                                            <Text />
                                                        </Col>
                                                        {i < 4 && (
                                                            <LineBox />
                                                        )}
                                                    </>
                                                ))}
                                            </>
                                        </Row>
                                    </>
                                )
                            }
                        </>
                    ) : (
                        <><p style={{width: "100%", height: "100%", paddingTop: "90px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: "12px", color: "#8A92A2", textAlign: "center"}}>Não foi possível carregar os dados. Aguarde alguns minutos e tente recarregar a página.</p></>
                    )}
                    
                    </>
                )}
            </ChartArea>
        </>
    )
}

export default ResumoWeekAlert;
