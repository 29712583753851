import React, { useState, useEffect, useCallback } from 'react';
import { ContainerSuccess, Title, ContainerBackground, Icon, Icon02, Marker, Markers, Content, Text, Icon03, ImgFundo, BoxTextoFoto, FundoSt2, TitleS2, Option, ImgStep02, BoxTextoFotoSt4, ImgStep04, FundoSt3, FundoSt4, BoxSt41, BoxSt42, BoxSt43, BoxSt44 } from './styles';
import Relatorios from '../../assets/tour/undraw_relatorios.svg';
import Seta from '../../assets/tour/undraw_arrow.svg';
import telaApi from '../../assets/tour/tela_api.svg';
import logoBranco from '../../assets/tour/logoIcone_branco.svg'

const Step04 = ({ ...props }) => {

    return (
        <>
            <Content marginLeft={props.steps} style={{justifyContent: 'unset'}}>
                <BoxTextoFotoSt4>
                    <ImgStep04>
                        <img src={telaApi} />
                    </ImgStep04>
                    <FundoSt4>
                        <BoxSt41>
                            Integração de Dados com o AgroReceita
                        </BoxSt41>
                        <BoxSt42>
                            Emita receitas e faça consultas agronômicas diretamente do seu ERP 🔗
                        </BoxSt42>
                        <BoxSt43>
                            Mantenha seus dados unificados 🗂
                        </BoxSt43>
                        <BoxSt44>
                            Cumpra as exigências regulatórias do seu estado ⚖
                        </BoxSt44>
                    </FundoSt4>
                </BoxTextoFotoSt4>
            </Content>
        </>
    );
};

export default Step04;
