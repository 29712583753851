import React, {useState, useEffect} from 'react';
import api from "../../../services/api";
import { store } from "react-notifications-component";
import TextArea from "../../textarea/text_area_v2";
import InputV2 from "../../input/input_v2";
import SelectV2 from "../../select/select_v2";


const Relation = ({...props}) => {

    const {isCompendio, compendioParams, estado, compendioLoading} = props;

    const [isException, setIsException] = useState(false);
    const [loadRelation, setLoadRelation] = useState(false);
    const [relation, setRelation] = useState([]);
    const [isCalculate, setIsCalculate] = useState(false);

    const [optionsFase, setOptionsFase] = useState([]);
    const [fase, setFase] = useState([]);
    const [erroFase, setErroFase] = useState(false);

    const [optionsModalidadeAplicacao, setOptionsModalidadeAplicacao] = useState([]);
    const [modalidadeAplicacao, setModalidadeAplicacao] = useState([]);
    const [erroModalidadeAplicacao, setErroModalidadeAplicacao] = useState(false);

    const [dose, setDose] = useState('');
    const [erroDose, setErroDose] = useState(false);
    
    const [numeroAplicacoes, setNumeroAplicacoes] = useState('');
    const [erroNumeroAplicacoes, setErroNumeroAplicacoes] = useState(false);

    const [areaTratar, setAreaTratar] = useState('');
    const [erroAreaTratar, setErroAreaTratar] = useState(false);
    const [erroAreaMsg, setErroAreaMsg] = useState('');
    const [disableAreaTratar, setDisableAreaTratar] = useState(false);
    const [loadingArea, setLoadingArea] = useState(false);
    
    const [quantidadeAdquirir, setQuantidadeAdquirir] = useState('');
    const [erroQuantodadeAdquirir, setErroQuantidadeAdquirir] = useState('');
    const [disableQuantidadeAdquirir, setDisableQuantidadeAdquirir] = useState(false);
    const [unidadeQuantidadeAdquirir, setUnidadeQuantidadeAdquirir] = useState('');
    const [loadingQuantidade, setLoadingQuantidade] = useState(false);

    const [volumeCalda, setVolumeCalda] = useState('');

    const [equipamento, setEquipamento] = useState('');

    const [date, setDate] = useState('');

    const [existErro, setExistErro] = useState(false);

    const [load, setLoad] = useState(false)

    const [relationInfo, setRelationInfo] = useState([]);



    const searchRelationId = async (relationId) => {
        try {
            if (relationId !== 0) {
                const response = await api.get(`/relacoes/${relationId}`);
                if (response.data.data) {
                    const responseRelation = response.data.data;
                    
                    setRelation(responseRelation);
                    setLoadRelation(true);
                    mountRelationOptions(responseRelation);
                    setUnidadeQuantidadeAdquirir(responseRelation.unidade_dosagem.unidade_quantidade_adquirir);

                    if (responseRelation.agro_relations_exceptions && responseRelation.agro_relations_exceptions.length > 0) {    
                        if (props.relationInfo !== undefined && props.relationInfo.optionsFase !== undefined && props.relationInfo.optionsFase.length > 0) {
                            setOptionsFase(props.relationInfo.optionsFase)
                        } else {
                            let optionsExceptions = [];
                            let agroExceptionsName = '';

                            responseRelation.agro_relations_exceptions.map((ex, j) => {
                                agroExceptionsName = agroExceptionsName + `${ex.agro_exception.nome}; `;
                            })
            
                            optionsExceptions = [...optionsExceptions, {value: relationId, label: agroExceptionsName}]
                
                            setOptionsFase(optionsExceptions);
                            setFase(optionsExceptions);
                        }

                        setIsException(true);

                    }
    
                    if (response.data.message === 'calcular => 1') {
                        setIsCalculate(true);
                    } else {
                        setIsCalculate(false);
                    }
                }
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const setPrescriptionType = (type) => {
        if (type === 1) {
            //sem fase
            setIsException(false);
            searchRelationId(props.relation);
        } else if (type === 2) {
            //com fase            
            let optionsExceptions = [];

            if (typeof props.relation === 'number') {
                //Fase selecionada
                // setOptionsFase(props.relationInfo.optionsExceptions);
                if (optionsFase.length > 0) {
                    setFase(optionsFase);
                }else{
                    setFase(props.relationInfo.fase);
                }
                searchRelationId(props.relation);

            } else {
                //Necessário selecionar uma fase
                //Montando opções do campo
                props.relation.map((r, i) => {
                    let agroExceptionsName = '';
                    r.relacao.agro_relations_exceptions.map((ex, j) => {
                        agroExceptionsName = agroExceptionsName + `${ex.agro_exception.nome}; `;
                    })
    
                    optionsExceptions = [...optionsExceptions, {value: r.relacao.id, label: agroExceptionsName}]
                })
    
                setOptionsFase(optionsExceptions);
    
                if (isCompendio && compendioParams) {
                    if (compendioLoading === false) {
                        if (compendioParams.relacaoId) {
                            optionsExceptions.map((f) => {
                                if (f.value === parseInt(compendioParams.relacaoId)) {
                                    changeException(f);
                                }
                            });
                        }
                    }
                }

                setLoadRelation(true);
            }

            setIsException(true);

        }
    }

    const calculo = async () => {

        if (isException === false) {
            if (dose && numeroAplicacoes) {
                let num_apli = parseInt(numeroAplicacoes);
                let Dose = parseFloat(dose);

                if (areaTratar && !quantidadeAdquirir) {
                    //calcular pela área
                    let area = (estado.value === 'SC' || estado.value === 'PR') ? parseFloat(areaTratar).toFixed(1) : parseFloat(areaTratar).toFixed(2);

                    setLoadingQuantidade(true);

                    const payload = {
                        agro_relation_id: relation.id,
                        dose: Dose,
                        numero_aplicacoes: num_apli,
                        quantidade_adquirir: 0,
                        area_tratar: parseFloat(area)
                    };

                    try {
                        const response = await api.post(`/calculo`, payload);
                        let resultado = response.data.data;
                        if (estado.value === 'SC' || estado.value === 'PR') {
                            changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(1))
                        } else {
                            changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(2))
                        }
                        setAreaTratar(area);
                        setLoadingQuantidade(false);
                        
                    } catch (error) {
                        setLoadingQuantidade(false);
                    }


                } else if (!areaTratar && quantidadeAdquirir) {
                    //calcular pela quantidade
                    setLoadingArea(true);

                    let quantidade = (estado.value === 'SC') ? parseFloat(quantidadeAdquirir).toFixed(1) : parseFloat(quantidadeAdquirir).toFixed(2); 

                    const payload = {
                        agro_relation_id: relation.id,
                        dose: Dose,
                        numero_aplicacoes: num_apli,
                        quantidade_adquirir: parseFloat(quantidade),
                        area_tratar: 0
                    };

                    try {
                        const response = await api.post(`/calculo`, payload);
                        let resultado = response.data.data;

                        let resArea = (estado.value === 'SC' || estado.value === 'PR') ? parseFloat(resultado.area_tratar).toFixed(1) : parseFloat(resultado.area_tratar).toFixed(2); 
                        changeAreaTratar(resArea);
                        setQuantidadeAdquirir(quantidade);
                        setLoadingArea(false);

                    } catch (error) {
                        setLoadingArea(false);
                    }
                } else {
                    if (areaTratar && quantidadeAdquirir) {
                        if (disableAreaTratar) {
                            //calcular pela quantidade
                            setLoadingArea(true);

                            let quantidade = estado.value === 'SC' ? parseFloat(quantidadeAdquirir).toFixed(1) : parseFloat(quantidadeAdquirir).toFixed(2);
    
                            const payload = {
                                agro_relation_id: relation.id,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: parseFloat(quantidade),
                                area_tratar: 0
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);
                                
                                let resultado = response.data.data;
                                let resArea = (estado.value === 'SC' || estado.value === 'PR') ? parseFloat(resultado.area_tratar).toFixed(1) : parseFloat(resultado.area_tratar).toFixed(2); 
                                
                                changeAreaTratar(resArea);
                                setQuantidadeAdquirir(quantidade);
                                setLoadingArea(false);
                                
                            } catch (error) {
                                setLoadingArea(false);
                            }
                            
                        } else if (disableQuantidadeAdquirir) {
                            //calcular pela área
                            setLoadingQuantidade(true);
                            let area = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(areaTratar).toFixed(1) : parseFloat(areaTratar).toFixed(2);
    
                            const payload = {
                                agro_relation_id: relation.id,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: 0,
                                area_tratar: parseFloat(area)
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);
                                let resultado = response.data.data;
                                setAreaTratar(area);

                                if (estado.value === 'SC' || estado.value === 'PR') {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(1))
                                } else {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(2))
                                }

                                setLoadingQuantidade(false);
                                
                            } catch (error) {
                                
                            }
        
                        }
                    } else {
                        if (disableAreaTratar) {
                            //calcular pela quantidade
                            setLoadingArea(true);

                            let quantidade = estado.value === 'SC' ? parseFloat(quantidadeAdquirir).toFixed(1) : parseFloat(quantidadeAdquirir).toFixed(2);
    
                            const payload = {
                                agro_relation_id: relation.id,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: parseFloat(quantidade),
                                area_tratar: 0
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);

                                let resultado = response.data.data;
                                let resArea = (estado.value === 'SC' || estado.value === 'PR') ? parseFloat(resultado.area_tratar).toFixed(1) : parseFloat(resultado.area_tratar).toFixed(2); 

                                changeAreaTratar(resArea);
                                setQuantidadeAdquirir(quantidade);
                                setLoadingArea(false);
                                
                            } catch (error) {
                                setLoadingArea(false);
                            }
                            
                        } else if (disableQuantidadeAdquirir) {
                            //calcular pela área
                            setLoadingQuantidade(true);
                            let area = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(areaTratar).toFixed(1) : parseFloat(areaTratar).toFixed(2);
    
                            const payload = {
                                agro_relation_id: relation.id,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: 0,
                                area_tratar: parseFloat(area)
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);
                                let resultado = response.data.data;
                                setAreaTratar(area);
                                if (estado.value === 'SC' || estado.value === 'PR') {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(1))
                                } else {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(2))
                                }
                                setLoadingQuantidade(false);
                            } catch (error) {
                                setLoadingQuantidade(false);
                            }
        
                        }
                    }
                }
            } else {
                if (!dose && !numeroAplicacoes) {
                    setErroDose(true);
                    setErroNumeroAplicacoes(true);
    
                    store.addNotification({
                        title: "Erro!",
                        message: "Informe a dose e o número de aplicações para realizar o cálculo automático!",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    if (!dose) {
                        setErroDose(true);
                        store.addNotification({
                            title: "Erro!",
                            message: "Informe a dose para realizar o cálculo automático!",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
    
                    if (!numeroAplicacoes) {
                        setErroNumeroAplicacoes(true);
                        store.addNotification({
                            title: "Erro!",
                            message: "Informe o número de aplicações para realizar o cálculo automático!",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        } else {
            if (dose && numeroAplicacoes) {
                let num_apli = parseInt(numeroAplicacoes);
                let Dose = parseFloat(dose);

                if (areaTratar && !quantidadeAdquirir) {
                    //calcular pela área
                    setLoadingQuantidade(true);
                    let area = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(areaTratar).toFixed(1) : parseFloat(areaTratar).toFixed(2);

                    const payload = {
                        agro_relation_id: fase.value,
                        dose: Dose,
                        numero_aplicacoes: num_apli,
                        quantidade_adquirir: 0,
                        area_tratar: parseFloat(area)
                    };

                    try {
                        const response = await api.post(`/calculo`, payload);
                        let resultado = response.data.data;

                        setAreaTratar(area);
                        if (estado.value === 'SC' || estado.value === 'PR') {
                            changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(1))
                        } else {
                            changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(2))
                        }

                        setLoadingQuantidade(false);
                        
                    } catch (error) {
                        setLoadingQuantidade(false);
                    }


                } else if (!areaTratar && quantidadeAdquirir) {
                    //calcular pela quantidade
                    setLoadingArea(true);

                    let quantidade = estado.value === 'SC' ? parseFloat(quantidadeAdquirir).toFixed(1) : parseFloat(quantidadeAdquirir).toFixed(2);

                    const payload = {
                        agro_relation_id: fase.value,
                        dose: Dose,
                        numero_aplicacoes: num_apli,
                        quantidade_adquirir: parseFloat(quantidade),
                        area_tratar: 0
                    };

                    try {
                        const response = await api.post(`/calculo`, payload);
                        let resultado = response.data.data;

                        let resArea = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(resultado.area_tratar).toFixed(1) : parseFloat(resultado.area_tratar).toFixed(2);
                        
                        changeAreaTratar(resArea);
                        setQuantidadeAdquirir(quantidade);
                        setLoadingArea(false);
                        
                    } catch (error) {
                        setLoadingArea(false);
                        
                    }
                } else {
                    if (areaTratar && quantidadeAdquirir) {
                        if (disableAreaTratar) {
                            //calcular pela quantidade
                            setLoadingArea(true);
                            let quantidade = estado.value === 'SC' ? parseFloat(quantidadeAdquirir).toFixed(1) : parseFloat(quantidadeAdquirir).toFixed(2);

                            const payload = {
                                agro_relation_id: fase.value,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: parseFloat(quantidade),
                                area_tratar: 0
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);
                                let resultado = response.data.data;
                                
                                let resArea = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(resultado.area_tratar).toFixed(1) : parseFloat(resultado.area_tratar).toFixed(2);

                                changeAreaTratar(resArea);
                                setQuantidadeAdquirir(quantidade);

                                setLoadingArea(false);
                            } catch (error) {
                                setLoadingArea(false);
                            }
                            
                        } else if (disableQuantidadeAdquirir) {
                            //calcular pela área
                            setLoadingQuantidade(true);

                            let area = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(areaTratar).toFixed(1) : parseFloat(areaTratar).toFixed(2);
    
                            const payload = {
                                agro_relation_id: fase.value,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: 0,
                                area_tratar: parseFloat(area)
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);
                                let resultado = response.data.data;
                                setAreaTratar(area)
                                
                                if (estado.value === 'SC' || estado.value === 'PR') {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(1))
                                } else {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(2))
                                }
                                setLoadingQuantidade(false);
                            } catch (error) {
                                setLoadingQuantidade(false);
                                
                            }
        
                        }
                    } else {
                        if (disableAreaTratar) {
                            //calcular pela quantidade
                            setLoadingArea(true);

                            let quantidade = estado.value === 'SC' ? parseFloat(quantidadeAdquirir).toFixed(1) : parseFloat(quantidadeAdquirir).toFixed(2);

                            const payload = {
                                agro_relation_id: fase.value,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: parseFloat(quantidade),
                                area_tratar: 0
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);
                                let resultado = response.data.data;

                                let resArea = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(resultado.area_tratar).toFixed(1) : parseFloat(resultado.area_tratar).toFixed(2);
    
                                changeAreaTratar(resArea);
                                setQuantidadeAdquirir(quantidade);
                                setLoadingArea(false);
                                
                            } catch (error) {
                                setLoadingArea(false);
                            }
                            
                        } else if (disableQuantidadeAdquirir) {
                            //calcular pela área
                            setLoadingQuantidade(true);
                            
                            let area = estado.value === 'SC' || estado.value === 'PR' ? parseFloat(areaTratar).toFixed(1) : parseFloat(areaTratar).toFixed(2);
    
                            const payload = {
                                agro_relation_id: fase.value,
                                dose: Dose,
                                numero_aplicacoes: num_apli,
                                quantidade_adquirir: 0,
                                area_tratar: parseFloat(area)
                            };
    
                            try {
                                const response = await api.post(`/calculo`, payload);
                                let resultado = response.data.data;

                                if (estado.value === 'SC' || estado.value === 'PR') {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(1))
                                } else {
                                    changeQuantidadeAdquirir(resultado.quantidade_adquirir.toFixed(2))
                                }
                                
                                setAreaTratar(area);
                                setLoadingQuantidade(false);
                                
                            } catch (error) {
                                setLoadingQuantidade(false);
                            }
                        }
                    }
                }
            } else {
                if (!dose && !numeroAplicacoes) {
                    setErroDose(true);
                    setErroNumeroAplicacoes(true);
    
                    store.addNotification({
                        title: "Erro!",
                        message: "Informe a dose e o número de aplicações para realizar o cálculo automático!",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    if (!dose) {
                        setErroDose(true);
                        store.addNotification({
                            title: "Erro!",
                            message: "Informe a dose para realizar o cálculo automático!",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
    
                    if (!numeroAplicacoes) {
                        setErroNumeroAplicacoes(true);
                        store.addNotification({
                            title: "Erro!",
                            message: "Informe o número de aplicações para realizar o cálculo automático!",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        }
    }

    const formatOptionsAplicacao = (options) => {
        let format = [];

        options.map(op => {
            if (estado.value === 'CE' || estado.value === 'RS') {
                if (op.estados_proibidos !== null) {
                    if (op.estados_proibidos.indexOf(estado.value) == -1) {
                        format = [...format, {value: op.id, label: op.nome}];
                    }
                } else {
                    format = [...format, {value: op.id, label: op.nome}];
                }
            } else {
                format = [...format, {value: op.id, label: op.nome}];
            }
        });
        return format;
    }

    const mountRelationOptions = (relation) => {

        const modos = formatOptionsAplicacao(relation.modo_aplicacao);
        setOptionsModalidadeAplicacao(modos);

        if (isCompendio && compendioParams) {
            modos.map((apli) => {
                if (apli.value === parseInt(compendioParams.modoAplicacao)) {
                    changeModoAplicacao(apli);
                }
            })
        }
    }

    const changeException = (value) => {
        setFase(value);
        setErroFase(false);
        // props.updateRelationId(value.value);
    }

    const changeModoAplicacao = (data) => {
        setModalidadeAplicacao(data);
        setErroModalidadeAplicacao(false);
        
        if (data.value === 10) {
            changeVolumeCalda(true);
        } else {
            changeVolumeCalda(false);
        }
    }

    const changeDose = (value) => {
        if (dose !== value) {
            let v = `${value}`;
            if (v) {
                setDose(v.replaceAll(',', '.'));
            } else {
                setDose('');
            }
        }
    }
    
    const validaDose = (value, minimo, maximo) => {
        if (value) {
            value = parseFloat(value);
    
            setDose(value);
    
            if (value >= minimo && value <= maximo) {
                setErroDose(false);

                if (numeroAplicacoes && areaTratar && quantidadeAdquirir) {
                    calculo();
                }
            } else {
                setErroDose(true);
            }
        } else {
            setDose('');
        }
    }

    const changeNumeroAplicacoes = (value, min, max) => {
        if (numeroAplicacoes !== value) {

            if (value < min || value > max) {
                setExistErro(true);
            }

            setNumeroAplicacoes(value);
        }
    }
    
    const validaNumeroAplicacao = (value, minimo, maximo) => {
        if (value) {
            value = parseInt(value);

            setNumeroAplicacoes(value);
    
            if (value >= minimo && value <= maximo) {
                setErroNumeroAplicacoes(false);
                if (dose && areaTratar && quantidadeAdquirir) {
                    calculo();
                }
            } else {
                setErroNumeroAplicacoes(true);
                setExistErro(true);
            }
        } else {
            setNumeroAplicacoes('');
        }
        
    }

    const changeAreaTratar = (value) => {
        value = `${value}`;
        if (value) {
            setAreaTratar(value.replaceAll(',', '.'));
            setErroAreaTratar(false);
            setErroAreaMsg('');
        } else {
            setAreaTratar('');
        }

        if (value === 0 || value === '0' || value === '0.0') {
            setErroAreaTratar(true);
            setErroAreaMsg('A área a tratar deve ser maior que zero');
        } else if (value < 0) {
            setErroAreaTratar(true);
            setErroAreaMsg('A área a tratar deve ser maior que zero');
        } else {
            setErroAreaTratar(false);
            setErroAreaMsg('');
        }
    }
    
    const validaArea = () => {
        if (areaTratar) {
            if (!quantidadeAdquirir) {
                if (!isCalculate) {
                    let area;
                    
                    if (estado.value === 'SC' || estado.value === 'PR') {
                        area = parseFloat(areaTratar).toFixed(1);
                    } else {
                        area = parseFloat(areaTratar).toFixed(2);
                    }

                    setAreaTratar(area);
                    store.addNotification({
                        title: "Aviso!",
                        message: "O cálculo da Quantidade a adquirir e Área a tratar deve ser feito manualmente para esta unidade de medida",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    setDisableQuantidadeAdquirir(true);
                    calculo();
                }
            } else {
                if (isCalculate) {
                    calculo();
                } else {
                    let area;
                    if (estado.value === 'SC' || estado.value === 'PR') {
                        area = parseFloat(areaTratar).toFixed(1);
                    } else {
                        area = parseFloat(areaTratar).toFixed(2);
                    }
                    setAreaTratar(area);
                }
            }
        } else {
            setDisableQuantidadeAdquirir(false);
        }
        
    }
    
    const changeQuantidadeAdquirir = (value) => {
        if (value) {
            setQuantidadeAdquirir(value.replaceAll(',', '.'));
            setErroQuantidadeAdquirir(false);
        } else {
            setQuantidadeAdquirir('');
        }
    }
    
    const validaQuantidade = () => {
        if (quantidadeAdquirir) {
            if (!areaTratar) {
                if (!isCalculate) {
                    let quantidade;
                    if (estado.value === 'SC') {
                        quantidade = parseFloat(quantidadeAdquirir).toFixed(1);
                    } else {
                        quantidade = parseFloat(quantidadeAdquirir).toFixed(2);
                    }
                    setQuantidadeAdquirir(quantidade);
                    store.addNotification({
                        title: "Aviso!",
                        message: "O cálculo da Quantidade a adquirir e Área a tratar deve ser feito manualmente para esta unidade de medida",
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    setDisableAreaTratar(true);
                    calculo();
                }
            } else {
                if (isCalculate) {
                    calculo();
                } else {
                    let quantidade;
                    if (estado.value === 'SC') {
                        quantidade = parseFloat(quantidadeAdquirir).toFixed(1);
                    } else {
                        quantidade = parseFloat(quantidadeAdquirir).toFixed(2);
                    }
                    setQuantidadeAdquirir(quantidade);
                }
            }
        } else {
            setDisableAreaTratar(false);
        }
        
    }

    const changeVolumeCalda = (isAereo) => {
        if (relation !== null) {
            if (isAereo) {
                validateVolumeCalda(relation.calda_aerea);
            } else {
                validateVolumeCalda(relation.calda_terrestre);
            }
        }
    }

    const changeEquipamento = (value) => {
        setEquipamento(value);
        
    }

    const setInitialDate = () => {
        if (props.index === 0) {
            let now = new Date();
            let day = now.getDate();
            let month = now.getMonth();   //valores de 0 a 11, sendo 0 janeiro e 11 dezembro
            month = month + 1;
            let year = now.getFullYear();
    
            if (day <= 9) {
                day = "0" + day;
            }
    
            if (month <= 9) {
                month = "0" + month;
            }
            let date = "" + year + "-" + month + "-" + day;
            setDate(date);
        } else {
            setDate(props.date);

        }
    }
    
    const changeDate = (value) => {
        setDate(value);
    }

    const loadInfo = (relation) => {
        // console.log("LOAD INFO => ", relation)

        if (areaTratar === '') {
            setAreaTratar(relation.areaTratar);
        }

        if (date === '') {
            setDate(relation.date);
        }

        if (dose === '') {
            setDose(relation.dose);
        }
        
        if (equipamento === '') {
            setEquipamento(relation.equipamento);
        }

        if (fase.length === 0) {
            setFase(relation.fase);
        }

        if (isException === '') {
            setIsException(relation.isException);
        }

        if (modalidadeAplicacao.length === 0) {
            setModalidadeAplicacao(relation.modalidadeAplicacao);
        }

        if (numeroAplicacoes === '') {
            setNumeroAplicacoes(relation.numeroAplicacoes);
        }

        if (optionsFase.length === 0) {
            setOptionsFase(relation.optionsFase);
        }

        if (quantidadeAdquirir === '') {
            setQuantidadeAdquirir(relation.quantidadeAdquirir);
        }

        if (unidadeQuantidadeAdquirir === '') {
            setUnidadeQuantidadeAdquirir(relation.unidadeQuantidadeAdquirir);
        }

        if (volumeCalda === '') {
            setVolumeCalda(relation.volumeCalda)
        }
    }

    const validateVolumeCalda = (e) => {
        setVolumeCalda(e)
    }

    useEffect(() => {
        if (typeof props.relation === 'number') {
            if (props.relationInfo) {
                if (props.relationInfo.fase) {
                    if (props.relationInfo.fase.value) {
                        setPrescriptionType(2);
                    } else {
                        setPrescriptionType(1);
                    }
                } else {
                    setPrescriptionType(1);
                }
            } else {
                setPrescriptionType(1);
            }
        } else if (typeof props.relation === 'object') {
            setPrescriptionType(2);
        }
        setInitialDate()
    },[props.relation]);

    useEffect(() => {
        // console.log("props.relationInfo => ", props.relationInfo)

        if (props.relationInfo) {
            if (load === false) {
                setLoad(true);

                setTimeout(() => {
                    setRelationInfo(props.relationInfo);
                }, 500);
            }
        }
    },[props.relationInfo])

    useEffect(() => {
        if (relationInfo !== null && relationInfo.length !== 0) {
            loadInfo(relationInfo);
        }

    }, [relationInfo])

    useEffect(() => {
        if (areaTratar === 0 || areaTratar === '0' || areaTratar === '0.0') {
            setErroAreaTratar(true);
            setErroAreaMsg('A área a tratar deve ser maior que zero');
        } else if (areaTratar < 0) {
            setErroAreaTratar(true);
            setErroAreaMsg('A área a tratar deve ser maior que zero');
        } else {
            setErroAreaTratar(false);
            setErroAreaMsg('');
        }
    }, [areaTratar])

    useEffect(() => {
        if (compendioParams) {
            if (compendioParams.dose && compendioParams.numApli && compendioParams.area && compendioParams.quantidade) {
                changeDose(compendioParams.dose);
                changeNumeroAplicacoes(compendioParams.numApli);
                changeAreaTratar(compendioParams.area);
                changeQuantidadeAdquirir(compendioParams.quantidade)


            } else {
                if (compendioParams.relacaoId) {

                    if (isException === '') {
                        if (typeof props.relation === 'object') {
                            // setPrescriptionType(2);
                            setFase({value: parseInt(compendioParams.relacaoId)})
                            setIsException(true);
                        }
                    }  
                }
            }
        }
    }, [compendioParams])

    useEffect(() => {
        if (erroFase || erroModalidadeAplicacao || erroAreaTratar || erroDose || erroNumeroAplicacoes || erroQuantodadeAdquirir) {
            setExistErro(true);
        } else {
            setExistErro(false);
        }
    },[erroFase, erroModalidadeAplicacao, erroAreaTratar, erroDose, erroNumeroAplicacoes, erroQuantodadeAdquirir])

    useEffect(() => {
        if (isException === true) {
            const payloadResponse = {
                index: props.index,
                relationId: fase.value !== undefined ? fase.value : 0,
                fase,
                optionsFase,
                modalidadeAplicacao, 
                dose: dose !== undefined && dose !== 'undefined' ? dose : '', 
                numeroAplicacoes: numeroAplicacoes !== undefined && numeroAplicacoes !== 'undefined' ? numeroAplicacoes : '', 
                areaTratar, 
                quantidadeAdquirir, 
                unidadeQuantidadeAdquirir,
                volumeCalda, 
                equipamento, 
                date,
                erro: existErro,
                isException
            }
            props.updateRelationInfo(payloadResponse);
        } else if (isException === false) {
            const payloadResponse = {
                index: props.index,
                relationId: props.relation,
                fase,
                optionsFase,
                modalidadeAplicacao, 
                dose, 
                numeroAplicacoes, 
                areaTratar, 
                quantidadeAdquirir, 
                unidadeQuantidadeAdquirir,
                volumeCalda, 
                equipamento, 
                date,
                erro: existErro,
                isException
            }
            props.updateRelationInfo(payloadResponse);
        }
    }, [
        props.compendioLoading, 
        modalidadeAplicacao, 
        dose, 
        numeroAplicacoes, 
        areaTratar, 
        quantidadeAdquirir, 
        unidadeQuantidadeAdquirir, 
        equipamento, 
        date, 
        volumeCalda, 
        fase, 
        optionsFase
    ])

    useEffect(() => {
        if (fase && fase.value && isException === true) {
            
            const payloadResponse = {
                index: props.index,
                relationId: fase.value,
                fase,
                optionsFase,
                modalidadeAplicacao, 
                dose: dose !== undefined && dose !== 'undefined' ? dose : '', 
                numeroAplicacoes: numeroAplicacoes !== undefined && numeroAplicacoes !== 'undefined' ? numeroAplicacoes : '', 
                areaTratar, 
                quantidadeAdquirir, 
                unidadeQuantidadeAdquirir,
                volumeCalda, 
                equipamento, 
                date,
                erro: existErro,
                isException
            }

            props.updateRelationInfo(payloadResponse);
            props.updateRelationId(fase.value);

        }
    }, [fase, optionsFase, isException])

    return(
        <>
            {isException === true && relation.length === 0 ? (
                <>
                    {isException === true ? (
                        <SelectV2
                            name="fase"
                            className="select"
                            label="Fase/Tipo de solo"
                            id="fase"
                            value={fase.value || fase}
                            disabled={!isException}
                            erro={optionsFase ? erroFase : false}      
                            onChange={e => changeException(e)}
                            options={optionsFase}
                            selectUniqueOption={true}
                        />
                    ): (null)}
                </>
            ) : (
                <>
                    {loadRelation ? (
                        <>
                            {isException === true && (
                                <SelectV2
                                    name="fase"
                                    className="select"
                                    label="Fase/Tipo de solo"
                                    id="fase"
                                    value={fase.value || fase}
                                    disabled={!isException}
                                    erro={optionsFase ? isException === true ? erroFase : false : false}      
                                    onChange={e => changeException(e)}
                                    options={optionsFase}
                                    selectUniqueOption={true}
                                />
                            )}

                            <TextArea
                                name="epoca"
                                label="Época de aplicação"
                                id="epoca"
                                value={relation.epoca_aplicacao || ''}
                                maxlength="number"
                                onChange={(e) => console.log(e)}
                            />

                            <SelectV2
                                name="aplication"
                                className="select"
                                label="Modo de aplicação"
                                id="aplication"
                                disabled={false}
                                value={modalidadeAplicacao.value || modalidadeAplicacao || ''}
                                onChange={e => changeModoAplicacao(e)}
                                erro={erroModalidadeAplicacao}
                                options={optionsModalidadeAplicacao}
                                selectUniqueOption={true}
                            />

                            <InputV2
                                id="dose"
                                value={dose || ''}
                                min={relation.dosagem_minima || ''}
                                max={relation.dosagem_maxima || ''}
                                updateValue={(e) => changeDose(`${e}`)}
                                label="Dose"
                                // type="number"
                                inputmode="numeric"
                                onChange={(e) => changeDose(e)}
                                onBlur={(e) => validaDose(e.target.value, relation.dosagem_minima, relation.dosagem_maxima)}
                                unidade = {relation.unidade_dosagem.unidade || ''}
                                legenda = {relation.dosagem_minima && relation.dosagem_maxima ? `De ${relation.dosagem_minima} a ${relation.dosagem_maxima} ${relation.unidade_dosagem.unidade}` : `Selecione o tipo de solo para adicionar a dose`}
                                erro={erroDose}
                                messageErro={relation.dosagem_minima && relation.dosagem_maxima ? `Dose inválida, informe um valor entre ${relation.dosagem_minima} e ${relation.dosagem_maxima}` : `Selecione o tipo de solo para adicionar a dose`}
                            />

                            <InputV2
                                id="numero_aplicacoes"
                                value={numeroAplicacoes}
                                min={relation.numero_aplicacoes_minimo || ''}
                                max={relation.numero_aplicacoes_maximo || ''}
                                updateValue={(e) => changeNumeroAplicacoes(e, relation.numero_aplicacoes_minimo, relation.numero_aplicacoes_maximo)}
                                onChange={(e) => changeNumeroAplicacoes(e)}
                                onBlur={e => validaNumeroAplicacao(e.target.value, relation.numero_aplicacoes_minimo, relation.numero_aplicacoes_maximo)}
                                label="Número de aplicações"
                                // type="number"
                                inputmode="numeric"
                                legenda = {relation.numero_aplicacoes_minimo && relation.numero_aplicacoes_maximo ? relation.numero_aplicacoes_minimo === relation.numero_aplicacoes_maximo ? `De ${relation.numero_aplicacoes_minimo} a ${relation.numero_aplicacoes_maximo} aplicação` : `De ${relation.numero_aplicacoes_minimo} a ${relation.numero_aplicacoes_maximo} aplicações` : `Selecione o tipo de solo para adicionar o número de aplicações`}
                                erro={erroNumeroAplicacoes || ''}
                                messageErro={relation.numero_aplicacoes_minimo && relation.numero_aplicacoes_maximo ? `Informe um valor entre ${relation.numero_aplicacoes_minimo} e ${relation.numero_aplicacoes_maximo}` : `Selecione o tipo de solo para adicionar o número de aplicações`}                        
                            />

                            <InputV2
                                id="areatratar"
                                onChange={e => changeAreaTratar(e)}
                                onBlur={() => validaArea()}
                                label="Área a tratar"
                                // type="number"
                                inputmode="numeric"
                                value={areaTratar}
                                erro={erroAreaTratar}
                                disabled={disableAreaTratar}
                                unidade = {"Ha"}
                                loading = {loadingArea}
                                messageErro={erroAreaMsg}
                            />

                            <InputV2
                                id="quantidade"
                                onChange={e => changeQuantidadeAdquirir(e)}
                                onBlur={(e) => validaQuantidade(e.target.value)} 
                                label="Quantidade a adquirir"
                                // type="number"
                                inputmode="numeric"
                                value={quantidadeAdquirir}
                                erro={erroQuantodadeAdquirir}
                                disabled={disableQuantidadeAdquirir}
                                unidade = { unidadeQuantidadeAdquirir }
                                loading = {loadingQuantidade}
                            />
                
                            <InputV2
                                id="volumeCalda"
                                value={volumeCalda}
                                label="Volume de Calda"
                                legenda = {volumeCalda === '' ? 'Informe o valor de acordo com a bula!' : ''}
                                onChange={(e) => validateVolumeCalda(e)}
                            />
                
                            <InputV2
                                id="equipamento"
                                onChange={e => changeEquipamento(e)}
                                label="Equipamento de aplicação"
                                value={equipamento}
                            />
                
                            <InputV2
                                id="dataemissao"
                                onChange={e => changeDate(e)}
                                label="Data de emissão"
                                mask="99/99/9999"
                                value={date}
                                disabled={props.index > 0 ? true : false}
                                type="date"
                            />
                        </>
                    ) : (null)}
                </>
            )}
        </>
    )
}

export default Relation;
