import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  height: 100%;

  table {
    width: 100%;
    font-size: 12px;
  }

  .body-plan {
    display: flex;
    flex-direction: row;

    .info-plan{
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        text-align: center;
        margin-bottom: 30px;

        b {
          .vlr {
            font-size: 42px;
          }
        }

        .link {
          color: #589BFF;
          cursor: pointer;
          font-weight: normal;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;

    
  }
`;

export const Table = styled.table`
  width: 50%;
  margin-top: 10px;

  @media only screen and (max-width: 768px) {
    background-color: white;
    border-radius: 16px 16px 0px 0px;
  }
`;

export const Line = styled.tr`
  height: 50px;
  background-color: ${props => props.index%2 == 0 ? 'trasnparent' : '#ebedf0'};

  td {
    width: 285px;
    padding: 5px;
    padding-left: 20px;
    align-items: center;

    span {
      font-size: medium;
      font-weight: 500;

      .vlr {
        font-size: 42px;
      }
    }

  }

  #info {

    span {
      p {
        transition: 0.3s;
        display: none;
      }
      
      &:hover {
        p {
          transition: 0.3s;
          display: flex;
          position: absolute;
          margin-left: 120px;
          margin-top: -23px;
          font-size: 10px;
          background: #21262f;
          color: #fff;
          padding: 5px;
          border-radius: 5px;
          font-weight: 100;
          max-width: 150px;
        }
      }
    }
  }

  th {
    width: 70px;
    padding: 5px;
    align-items: center;
    font-size: 15px;  
  }

  .td-align-center {
    width: 160px;
    padding-left: 5px;
    text-align: center;
  }

  .link {
    color: #589BFF;
    cursor: pointer;
    font-size: 13px;
    font-weight: normal;
  }

  .button-finish{
    width: 100%; 
    display: flex; 
    flex-direction: row; 
    justify-content: center; 
    margin-top: 0px; 
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 768px) {
    td {
      padding: 5px;
      align-items: center;

      span {
        font-size: 8px;
        font-weight: 500;

        .vlr {
          font-size: 24px;
        }
      }

    }
    .td-align-center {
      width: 80px;
      text-align: center;
    }
    th {
      width: 70px;
      padding: 5px;
      align-items: center;
      font-size: 12px;  
    }
  }
`;

export const Card = styled.div`
  background-color: #FFF;
  width: 90%;
  min-width: 150px;

  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
  padding: 15px 15px 15px 15px;
  padding-top: 35px;

  transition: all 0.3s ease-out;
  
  &:hover{
      cursor: pointer;
      box-shadow: 2px 2px 10px -4px rgb(29, 33, 41);
      transform: scale(1.025);
  }

  h2 {
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
  }

  .btn {
    width: 100%;
    margin-top: 8px;
  }

  .itens {


    .plus {
      font-size: 50px;
      fonst-weight: bold;
      width: 100%;
      text-align: center;

    }
  }

  .preco {

    p {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
  
      span {
        font-size: medium;
        font-weight: 500;
  
        .vlr {
          font-size: 50px;
        }
      }
    }

    .legenda {
      font-size: 14px;
      /* margin-top: -20px; */
    }
  }

  .simulacao {
      width: 100%;
      text-align: center;

      p {
        width: 100%;
        text-align: center;
      }

      span {
        color: #589BFF;
        cursor: pointer;
        font-size: 15px;
        font-weight: normal;
      }
    }
`;

export const Pages = styled.div`
  width: ${props => props.showPrePago ? '500px' : '250px'};
  height: 75px;
  border: 1px solid transparent;
  border-radius: 12px;

  margin: auto;
  margin-top: 20px;
  
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    border-radius: ${props => props.showPrePago ? '12px 0px 0px 12px' : '12px 12px 12px 12px'};
  }

  div + div {
    border-radius: 0px 12px 12px 0px;
  }

  @media only screen and (max-width: 768px) {
    width: ${props => props.showPrePago ? '288px' : '250px'};
  }
`;

export const Page = styled.div`
  width: ${props => props.showPrePago ? '50%' : '100%'};
  height: 65px;
  margin: 5px;
  border: 1px solid transparent;
  
  background-color: ${props => props.isActive ? '#f8f8f9' : '#fff'};

  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease-out;
`;

export const Label = styled.p`
    width: 100%;
    margin: 0px;
    text-align: center;
    color:  ${props => props.isActive ? '#589BFF' : '#8A92A2'};
    font-family: "Lato", Sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.2px;
    transition: all 0.4s ease-out;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection ? props.flexDirection  : 'row'};
  justify-content: center;
  width:  ${props => props.width  ? props.width : '100%'};
  color: ${props => props.color ? props.color : 'white'};
  padding-bottom: ${props => props.paddingBottom && props.paddingBottom};
  margin: ${props => props.margin && props.margin};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'transparent'};
  border-radius: ${props => props.borderRadius && props.borderRadius};

  p{
    font-size: ${props => props.fontSize ? props.fontSize : '9px'};
    font-weight: ${props => props.fontWeight ? props.fontWeight : '800'};
  }
`;

export const Col = styled.div`
  text-align: center;
  width: ${props => props.width  ? props.width  : '100%'};
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#303848'};
  padding: ${props => props.padding ? props.padding : '12px 9px 10px 9px'};
  display: flex;
  flex-direction: column;
  border-radius: ${props => props.borderRadius && props.borderRadius};
  color: ${props => props.color ? props.color : 'black'};
  margin: ${props => props.margin && props.margin};

  h3{
    font-weight: 800;
    font-size: 16px;
    padding-bottom: 8px;
  }

  span{
    font-size: 15px;
    font-weight: 700;
    color: #dfe4eb;
  }

  .simule-aqui {
    font-size: 13px;
    font-weight: 400;
    padding: 16px 16px 0px 16px;
    text-align: start;
  }

  .pre-pago{
    color: #03903E;
    font-family: "Lato", Sans-serif;
    font-size: 14px;
    font-weight: 600;

  }
  b{
    color: #589BFF
  }
`;