import React, { useState, useEffect } from 'react';
import api from '../../services/api';
import {
    Container,
    SessionRow2,
    Card2,
    FormContainer2,
    Loading,
    ContainerLogo2
} from './style';
import Input from '../../components/input/input_v2';
import Button from '../../components/button/button_v2';
import logoBranco from '../../assets/simbolo-branco-1.png';
import Logo from "../../assets/logo.png";
import ModalGeneric from '../../components/modal/modalGeneric';
import { store } from "react-notifications-component";

export const AUTH_TOKEN_RD = "@agroReceita_rd_auth_token";
export const REFRESH_TOKEN_RD = "@agroReceita_rd_refresh_token";


const Cadastro = ({...props}) => {
    const [password, setPassword] = useState('');
    const [erroPassword, setErroPassword] = useState(false);
    const [erroPasswordMessage] = useState('');
    

    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [loadingOpen, setLoadingOpen] = useState(true);
    const [ticketIsValid, setTicketIsValid] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    const [email, setEmail] = useState('');

    const changePassword = (value) => {
        setPassword(value);
        setErroPassword(false);
    }

    const validaTicket = async () => {
        setLoadingOpen(true);
        try {
            const response = await api.post('/valida/ticket', {ticket: props.match.params.ticket})

            if (response) {

                setTicketIsValid(true);
                setEmail(response.data.data.username);
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
        
        setLoadingOpen(false);
    }

    useEffect(() => {
        validaTicket();
    },[]);

    const handlePost = async () => {
        setLoading(true);

        try {
            const payload = {
                password,
                ticket : props.match.params.ticket, 
            };
            
            const response = await api.post('/senha', payload);

            if (response) {
                setModalTitle(response.data.data);
                setModalMessage(response.data.message);
                setShowModal(true);
            }

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }

        setLoading(false);
    }

    useEffect(() => {
        if (password) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }

    },[password])

    return (
        <Container>
            <img src={logoBranco} alt='logo' className='imagem-fundo' />

            <SessionRow2>
                <Card2>

                    <ContainerLogo2>
                        <div className='logo'>
                            <a href="http://www.agroreceita.com.br" target="_blank">
                                <img src={Logo} alt="logo" />
                            </a>
                        </div>
                        <label className="title">Crie sua nova senha</label>
                        <label className="subtitle">Mantenha seus dados sempre atualizados</label>
                    </ContainerLogo2>
                </Card2>
                
                <Card2 backgroundColor='#fff'>

                    {loadingOpen ? (
                        <FormContainer2>
                            <div 
                                className='loadingTicket'
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center'
                                }}
                            >
                                <Loading />
                                <label style={{ marginTop: '20px' }}>Validando ticket de segurança</label>
                                
                            </div>
                        </FormContainer2>
                    ) : (

                        <FormContainer2> 
                            {ticketIsValid ? (
                                <>
                                    <Input 
                                        id="password"
                                        name='password'
                                        type='password'
                                        value={password}
                                        label="Digite a nova senha"
                                        onChange={(e) => changePassword(e)}
                                        erro={erroPassword}
                                        messageErro={erroPasswordMessage}
                                        marginBottom='0px'
                                    />

                                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems:'center', marginBottom: '40px' }}>
                                        <Button 
                                            width='151px'
                                            height='48px'
                                            value='Criar senha'
                                            outline={false}
                                            disabled={disabled}
                                            onClick={() => handlePost()}
                                            loading={loading}
                                        />
                                    </div>
                                </>
                            ) : (
                                <label style={{ marginTop: '20px' }}>Ticket de segurança inválido!</label>
                            )}
                        </FormContainer2>
                    )}

                </Card2>
            </SessionRow2>

            <ModalGeneric
                show={showModal}
                onHide={false}
            >
                <>
                    <h2 style={{ width: '100%', textAlign: 'center' }}>{modalTitle}</h2>
                    <p style={{ width: '100%', textAlign: 'center' }}>{modalMessage}</p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems:'center', marginBottom: '40px' }}>
                        <Button 
                            width='151px'
                            height='48px'
                            value='Entrar no sistema'
                            outline={false}
                            disabled={false}
                            onClick={() => window.location='/login'}
                            loading={false}
                        />
                    </div>
                </>
            </ModalGeneric>
        </Container>
    )
}

export default Cadastro;