import React, { useState, useEffect } from "react";

import Select from "../../components/select/select_v2";
import api from "../../services/api";
import { store } from "react-notifications-component";
import { Container } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import ImageSearch from "../../assets/compendiocartoon.svg";
import { FormContainer } from "./styles";
import Card from "../../components/card-compendio/index";
import DotsLoader from '../../components/loader';
import Header from "../../components/header";

const Compendio = ({ ...props }) => {

    const [culturaId, setCulturaId] = useState('');
    const [pragaId, setPragaId] = useState('');
    const [produtoId, setProdutoId] = useState('');
    const [culturas, setCulturas] = useState("");
    const [pragas, setPragas] = useState("");
    const [produtos, setProdutos] = useState("");

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);

    const [disabledCultura, setDisabledCultura] = useState(true);
    const [disabledPraga, setDisabledPraga] = useState(true);
    const [disabledProduto, setDisabledProduto] = useState(true);

    const [relacoes, setRelacoes] = useState([]);

    const [screenWidth, setScreen] = useState('');

    const [loading, setLoading] = useState(false);


    useEffect(()=>{
        setScreen(window.screen);
    },[window.screen])

    const formatCulturas = culturas => {
        culturas = culturas.map(cultura => {
            if(cultura.nome_cientifico != null){
                const format = {
                    value: cultura.id,
                    label: `${cultura.nome_comum}\n - ${cultura.nome_cientifico}`,
                };
                return format;

            }else{
                const format = {
                    value: cultura.id,
                    label: cultura.nome_comum,
                };
                return format;
            }
        });
        return culturas;
    };

    const formatPragas = pragas => {
        pragas = pragas.map(praga => {
            const format = {
                value: praga.id,
                label: `${praga.nome_comum}\n - ${praga.nome_cientifico}`,
            };
            return format;
        });
        return pragas;
    };

    const formatProdutos = produtos => {
        produtos = produtos.map(produto => {
            const format = {
                value: produto.id,
                label: `${produto.nome_comercial}\n - ${produto.nome_tecnico}`,
            };
            return format;
        });
        return produtos;
    };

    const reloadSelect = reload => {
        setCulturaId('');
        setProdutoId('');
        setPragaId('');

        const getCulturas = async () => {
            try {
                const response = await api.get(`/culturas`);
                let culturas = response.data.data;
                culturas = formatCulturas(culturas);
                setCulturas(culturas);
            } catch (error) {
            }
        }
        const getProdutos = async () => {
            try {
                const response = await api.get(`/produtos`);
                let produtos = response.data.data;
                produtos = formatProdutos(produtos);
                
                setProdutos(produtos);
                
            } catch (error) {
                if(error.response.status == 500){
                    window.location = '/login';
                }
            }
        }
        const getPragas = async () => {
            try {
                const response = await api.get(`/pragas`);
                let pragas = response.data.data;
                pragas = formatPragas(pragas);
                
                setPragas(pragas);
                
            } catch (error) {
            }
        }

        getCulturas();
        getProdutos();
        getPragas();
    }

    const searchRelation = async () => {

        try {
            setLoading(true);

            const response = await api.get(`/relacoes/compendio/${produtoId.value}/${culturaId.value}/${pragaId.value}`);
            const relacoes = response.data.data;

            let culturas = relacoes[0].cultura;
            let pragas = relacoes[0].praga;
            let produtos = relacoes[0].produto;

            culturas = formatCulturas([culturas]);
            setCulturas(culturas);

            pragas = formatPragas([pragas]);
            setPragas(pragas);

            produtos = formatProdutos([produtos]);
            setProdutos(produtos);

            setRelacoes(relacoes);
            setLoading(false);

        } catch (e) {
            setLoading(false);
            const errors = e;

            try {
                if (errors.response.data.errors) {
                    store.addNotification({
                        title: "Erro!",
                        message: `${errors.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                }
                
            } catch (error) {
                store.addNotification({
                    title: "Erro!",
                    message: `Ocorreu um erro com a relação selecionada!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const getRelations = async () => {
        try {
            const response = await api.get(
                `/compendio/${produtoId.value || "*"}/${culturaId.value ||
                "*"}/${pragaId.value || "*"}/*`);

            let culturas = response.data.data.culturas;
            let pragas = response.data.data.pragas;
            let produtos = response.data.data.produtos;

            setDisabledCultura(true);
            culturas = formatCulturas(culturas);
            setCulturas(culturas);
            setDisabledCultura(false);

            
            setDisabledPraga(true);
            pragas = formatPragas(pragas);
            setPragas(pragas);
            setDisabledPraga(false);


            setDisabledProduto(true);
            produtos = formatProdutos(produtos);
            setProdutos(produtos);
            setDisabledProduto(false);

        } catch (e) {

            const errors = e;

            setCulturas("");
            setPragas("");
            setProdutos("");
            reloadSelect();

            store.addNotification({
                title: "Erro!",
                message: `${errors.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    const getCulturas = async () => {
        try {
            setCulturas([""]);
            setDisabledCultura(true);

            const response = await api.get(`/culturas`);
            let culturas = response.data.data;
            culturas = formatCulturas(culturas);

            setCulturas(culturas);
            setDisabledCultura(false);
            
        } catch (error) {
        }
    }
    const getProdutos = async () => {
        try {
            setProdutos([""]);
            setDisabledPraga(true);
            const response = await api.get(`/produtos`);
            let produtos = response.data.data;
            produtos = formatProdutos(produtos);
            setProdutos(produtos);
            setDisabledProduto(false);
            
        } catch (error) {
        }

    }
    const getPragas = async () => {
        try {
            setPragas([""]);
            setDisabledPraga(true);
            const response = await api.get(`/pragas`);
            let pragas = response.data.data;
            pragas = formatPragas(pragas);
            setPragas(pragas);
            setDisabledPraga(false);
            
        } catch (error) {
        }

    }

    const changeCultura = (value) => {
        if (typeof culturaId === 'object') {
            if (culturaId.value !== value.value) {
                setCulturaId(value);
            }
        } else {
            if (culturaId !== value) {
                setCulturaId(value);
            }
        }

    }
    
    const changePraga = (value) => {
        if (typeof pragaId === 'object') {
            if (pragaId.value !== value.value) {
                setPragaId(value);
            }
        } else {
            if (pragaId !== value) {
                setPragaId(value);
            }
        }
    }

    const changeProduto = (value) => {
        if (typeof produtoId === 'object') {
            if (produtoId.value !== value.value) {
                setProdutoId(value);
            }
        } else {
            if (produtoId !== value) {
                setProdutoId(value);
            }
        }
    }

    useEffect(() => {
        if (disabledCultura == false && disabledPraga == false && disabledProduto == false) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [disabledCultura, disabledPraga, disabledProduto])

    useEffect(() => {
        if (!culturaId.value && !produtoId.value && !pragaId.value) {
            getCulturas();
            getPragas();
            getProdutos();
            setRelacoes([]);
        } else if (culturaId.value && produtoId.value && pragaId.value) {
            searchRelation();
        } else if (culturaId.value || produtoId.value || pragaId.value) {
            getRelations();
            setRelacoes([]);
        }

    }, [culturaId, produtoId, pragaId]);

    useEffect(() => {
        if (isOpen1 || isOpen2 || isOpen3) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [isOpen1, isOpen2, isOpen3])


    return (
        <>
            {/* <GenericUpper
                title="Compêndio Agrícola"
                subtitle="Comece por onde quiser"
            ></GenericUpper> */}

            <GenericUpper
                title="Compêndio Agrícola"
                subtitle="Comece por onde quiser"
            >

            </GenericUpper>

            <FormContainer card={culturaId.value && pragaId.value && produtoId.value ? true : false} isOpen={isOpen}>
                {/* <Container> */}

                    <form id="FormSelect">
                        <div className="selectValue">
                            <Select
                                name="agro_culture_id"
                                className="select"
                                label="Cultura"
                                id="cultura-compendio"
                                value={culturaId ? culturaId.value : ''}
                                disabled={loading}
                                onChange={cultura => changeCultura(cultura)}
                                options={culturas || [""]}
                                isClearable={true}
                                selectUniqueOption={false}
                                loading={loading}
                                searchPhoto={true}
                                isOpen={(e) => setIsOpen1(e)}
                                marginBottom='0px'
                            />
                        </div>

                        <div className="selectValue">
                            <Select
                                name="agro_pest_id"
                                className="select"
                                label="Praga ou Alvo"
                                id={`praga-compendio`}
                                value={pragaId ? pragaId.value : ''}
                                disabled={loading}
                                isClearable={true}
                                onChange={praga => changePraga(praga)}
                                options={pragas || [""]}
                                selectUniqueOption={false}
                                loading={loading}
                                searchPhoto={true}
                                isOpen={(e) => setIsOpen2(e)}
                                marginBottom='0px'
                            />
                        </div>

                        <div className="selectValue">
                            <Select
                                name="agro_product_id"
                                className="select"
                                label="Produto"
                                id={`produto-compendio`}
                                value={produtoId ? produtoId.value : ''}
                                disabled={loading}
                                isClearable={true}
                                onChange={produto => changeProduto(produto)}
                                options={produtos || [""]}
                                selectUniqueOption={false}
                                isArrow={true}
                                loading={loading}
                                solicitar={true}
                                isOpen={(e) => setIsOpen3(e)}
                                marginBottom='0px'
                            />
                        </div>

                    </form>
                    
                    <div className="loading" style={{width: '100%', margin: 'auto', display: 'flex', justifyContent: 'center'}}>
                        <DotsLoader show={loading}></DotsLoader>
                    </div>

                    {culturaId.value && pragaId.value && produtoId.value ? (
                        <Card
                            cultura={culturaId}
                            praga={pragaId}
                            produto={produtoId}
                            screen={screenWidth}
                            relacoes={relacoes}
                        >
                        </Card>
                    ) : (<>
                        <div className="info">
                            <label>Preencha os campos acima para começar...</label>
                            <img src={ImageSearch} width="300" />
                        </div>
                    </>)}

                {/* </Container> */}
            </FormContainer>
        </>
    );
};

export default Compendio;
