import styled from "styled-components";

export const TBodyContainer = styled.tbody`
  tr {
    cursor: pointer;
    height: 64px;
    background: #fff;

    padding: 16px 16px 0px 0px;
    border-bottom: 1px solid #f1f1f1;
    box-shadow: 0px 8px 29px 1px #f1f1f1;

    &:hover {
      outline: 1px solid #599bff;
    }
    td {
      padding: 0 0 0 10px;
      font-family: Lato;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #303848;
      

      label{
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        color: #bac1cf;
        margin-top: -3px;
      }

      .loja{
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #589bff;
      }
      
      .link-azul{
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #589bff;
        margin: 2px;
        padding: 8px;

        display: flex;
        flex-direction: row;
        align-items: center;


        transition: all 0.3s ease;
        
        &:hover {
          transform: scale(1.03);
        }
      }
      
      .link{
        font-family: Lato;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: 2px;
        padding: 8px;

        display: flex;
        flex-direction: row;
        align-items: center;

      }

      ul {
        margin: 2px;
        padding: 8px;
      }

      i {
        font-size: 16px;
      }

      &:first-of-type {
        font-family: Lato;
        font-size: ${props => (props.fontSize ? props.fontSize : "18px")};
        font-weight: ${props => (props.fontWeight ? props.fontWeight : "bold")};
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #303848;
      }

      &.error {
        background: transparent;
        text-align: center;
      }

      .estado{
        width: 81px;
        height: 25px;
        border-radius: 5px;
        background-color: #f3f3f5;
        padding-top: 3px;
        text-align: center;
      }

      .estado-inativo{
        width: 81px;
        height: 25px;
        border-radius: 5px;
        background-color: #f3f3f5;
        font-family: Lato;
        color: #ff5252;
        padding-top: 3px;
        text-align: center;
      }

      .status-at{
        margin-top: 20px;
        height: 25px;
        border-radius: 5px;
        background-color: #f3f3f5;
        padding: 3px;
        text-align: center;
      }

      .status-in{
        margin-top: 20px;
        height: 25px;
        border-radius: 5px;
        background-color: #f3f3f5;
        font-family: Lato;
        color: #ff5252;
        padding: 3px;
        text-align: center;
      }

      .art-grid{
        display: flex;
        flex-direction: row;

        img{
          width: 10px;
          height: 14px;
        }
        
        label{
          margin-left: 5px;
          margin-top: -2px;
        }
      }

      .quantidade-progress{
        width: 120px;
        margin: auto;
        .tipoArt{
          font-size: 9px;
        }
      }

      .crea{ 
        height: 25px;
        border-radius: 5px;
        padding-top: 3px;
      }
    }
  }
`;

export const SimpleTBodyContainer = styled.tbody`
  tr{
    td{
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
  }
`;
