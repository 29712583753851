import React from 'react';

const IconNotification = () => {


    return (
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 172 172">
            <g 
                fill="none" 
                fill-rule="nonzero" 
                stroke="none" 
                stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none" 
                text-anchor="none" 
                style={{mixBlendMode: 'normal'}}
            >

                <g fill="#8a92a2">
                    <path d="M86,14.33333c-5.934,0 -10.75,4.816 -10.75,10.75v4.98307c-18.53885,4.77861 -32.25,21.56799 -32.25,41.60026v43l-14.33333,14.33333v7.16667h44.93164c-1.26198,2.17784 -1.92819,4.64961 -1.93164,7.16667c0,7.91608 6.41725,14.33333 14.33333,14.33333c7.91608,0 14.33333,-6.41725 14.33333,-14.33333c-0.0077,-2.51833 -0.67877,-4.99018 -1.94564,-7.16667h44.94564v-7.16667l-14.33333,-14.33333v-43c0,-20.03227 -13.71115,-36.82165 -32.25,-41.60026v-4.98307c0,-5.934 -4.816,-10.75 -10.75,-10.75zM86,43c15.80967,0 28.66667,12.857 28.66667,28.66667v43v5.9349l1.23177,1.23177h-59.79687l1.23177,-1.23177v-5.9349v-43c0,-15.80967 12.857,-28.66667 28.66667,-28.66667z"></path>
                </g>
            </g>
        </svg>
    )

}

export default IconNotification