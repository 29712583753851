import styled from "styled-components";

export const InputContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  max-width: 400px;
  label {
    padding-left: 15px;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    color: #8a92a2;
  }
  textarea {
    margin: 10px 0 0;
    font-weight: 600;
    width: 100%;
    max-width: 400px;
    height: auto;
    min-height: 50px;
    padding: 10px;
    border: none;
    font-size: 16px;
    border-radius: 25px;
    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
    padding: 15px 25px;
    &.error {
      border: 1px solid #f01;
    }
    &::placeholder {
      color: #ced2dc;
    }
  }
`;

export const TextAreaContainerV2 = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '30px'};
  position: relative;

  textarea {
    &::placeholder {
      color: ${props => props.erro ? '#ff5252' : '#8a92a2'};
      opacity: 1; /* Firefox */
    }
  }
`;

export const Label = styled.label`
    width: auto;
  height: 3px;

  background-color: #FFF;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 0px;
  margin-left: 25px;
  margin-top: ${props => props.isDate ? '-2px' : !props.existValue ? !props.isFocus ? '24px' : '-2px' : '-2px'};

  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  position: absolute;
  
  color: ${props => props.erro ? '#ff5252' : '#8a92a2'};
  opacity: ${props => props.isDisabled ? '0.5' : '1'};
  z-index: 1;
  
  transition: all 0.3s;


  &:hover {
    cursor: ${props => !props.existValue ? !props.isFocus ? !props.isDisabled ? 'text' : 'context-menu' : 'context-menu' : 'context-menu'};
  }
`;

export const Unidade = styled.p`
  font-family: Lato;
  font-size: 12px;
  margin-top: -33px;
  padding-right: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${props => props.erro ? '#ff5252' : '#bac1cf'};
`;

export const Legenda = styled.p`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff5252;
  margin-top: 22px;
`;
