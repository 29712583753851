import React, { useState, useEffect, useRef } from 'react';
import { H3, Label, FilterArea, SelectArea, DataFilter, ChartArea, Loading, IconeBotao } from './style';
import api from '../../services/api';
import DateRange from '../date-range';
import { Line } from 'react-chartjs-2'
import Button from '../button/button_v2';
import { store } from 'react-notifications-component';
import { formatDate } from '../../services/formatDate';

const TotalReceitasEmitidas = ({ ...props }) => {

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 183));

    const [dataInicial, setDataInicial] = useState(priorDate.toISOString().slice(0, 10));
    const [dataFinal, setDataFinal] = useState(today.toISOString().slice(0, 10));

    const [dataGraph, setDataGraph] = useState([]);
    const [optionsGraph, setOptionsGraph] = useState([]);
    const [showChart, setShowChart] = useState(false);

    const [loadingXls, setShowLoadingXls] = useState(false);

    const [initialLoaded, setInitialLoaded] = useState(false);


    const [ref] = useState(useRef());

    const searchInitialData = async () => {
        try {
            const response = await api.post('/relatorios/receitas/grafico', {
                dtInicial: dataInicial,
                dtFinal: dataFinal
            });

            setDataGraph({
                labels: response.data.data.meses,
                datasets: [
                    {
                        label: 'Receitas',
                        data: response.data.data.qtdReceita,
                        backgroundColor: 'rgb(47, 187, 105, 0.4)',
                        borderColor: 'rgb(47, 187, 105, 1)',
                        borderWidth: 1,
                        tension: 0.3,
                    },
                ],
            });

            setOptionsGraph({
                responsive: true,
                scales: {
                    y:{
                        beginAtZero: true,
                        ticks: {
                            // color: 'black',
                            stepSize: 1,
                            // mirror: true,
                        },
                    },
                    x: {
                        ticks: {
                            // color: 'black',
                            font: {
                                size: 14,
                                // color: 'black'
                            },
                            stepSize: 1,
                            // mirror: true,
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: `Total de receitas emitidas por mês de ${formatDate(dataInicial)} à ${formatDate(dataFinal)}`,
                    },
                },
            });

            setInitialLoaded(true);
            setShowChart(true);
        } catch (error) {
            
        }
    }
    
    const updateData = async () => {
        if (initialLoaded) {
            try {
                const response = await api.post('/relatorios/receitas/grafico', {
                    dtInicial: dataInicial,
                    dtFinal: dataFinal,
                })
    
                setDataGraph({
                    labels: response.data.data.meses,
                    datasets: [
                        {
                            label: 'Receitas',
                            data: response.data.data.qtdReceita,
                            backgroundColor: 'rgb(47, 187, 105, 0.4)',
                            borderColor: 'rgb(47, 187, 105, 1)',
                            borderWidth: 1,
                            tension: 0.3,
                        },
                    ],
                });
    
                setOptionsGraph({
                    responsive: true,
                    indexAxis: 'x',
                    scales: {
                        y:{
                            beginAtZero: true,
                            ticks: {
                                // color: 'black',
                                stepSize: 1,
                                // mirror: true,
                            },
                        },
                        x: {
                            ticks: {
                                // color: 'black',
                                font: {
                                    size: 14,
                                    // color: 'black'
                                },
                                stepSize: 1,
                                // mirror: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: `Total de receitas emitidas por mês de ${formatDate(dataInicial)} à ${formatDate(dataFinal)}`,
                        },
                    },
                });
    
                setShowChart(true);
            } catch (error) {
                
            }
        }
    }

    const gerarXLS = async () => {
        const payload = {
            dtInicial: dataInicial,
            dtFinal: dataFinal,
        }

        try {
            setShowLoadingXls(true);

            const response = await api.post(`/relatorios/geral`, payload)


            let win = window.open(response.data.data.link);

            setShowLoadingXls(false);

        } catch (error) {
            setShowLoadingXls(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    useEffect(() => {
        searchInitialData();
    }, [])

    useEffect(() => {
        updateData();
    }, [dataInicial, dataFinal])
    
    return (
        <>
            <div style={{
                // display: 'flex',
                // flexDirection: 'row',
                // alignItems: 'center'
            }}>
                <IconeBotao>
                    <div className='card-titulo' style={{ 
                        height: '32px', 
                        marginTop: '8px',
                        display: 'flex', 
                        flexDirection: 'row', 
                        alignItems: 'center', 
                        justifyContent: 'start' 
                    }}>
                        <h2 style={{
                            textAlign: 'left',
                            textTransform: 'uppercase',
                            fontFamily: 'Lato',
                            fontSize: '10px',
                            color: '#8a92a2',
                            // paddingTop: '10px'
                        }}>
                            Receitas emitidas por mês
                        </h2>
                    </div>

                    <div className='icons' style={{
                        display: props.disabled == true ? 'none' : 'flex'
                    }}>    
                        {
                            props.isMobile == false && (
                                <DataFilter style={{
                                    width: '80%',
                                    marginLeft: 'auto',
                                    display: props.disabled == true ? 'none' : 'flex'
                                }}>
                                    <DateRange
                                        id='datarange'
                                        initial={dataInicial}
                                        final={dataFinal}
                                        onChangeInitial={(e) => setDataInicial(e)}
                                        onChangeFinal={(e) => setDataFinal(e)}
                                        erro={false}
                                        disabled={false}
                                        loading={false}
                                        marginBottom='0px'
                                    />
                                </DataFilter>
                            )
                        }

                        <div className='xlsx'>
                            <a onClick={()=> gerarXLS()}><img style={{
                                width: '22px',
                                height: '23px',
                                paddingTop: '2px',
                                paddingLeft: '3px'
                            }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAABNElEQVRYhe2VsUoDQRBAn0GUEM9OId+QUkhhaW9pZ21h6VcolhbBQM4yP5PKFLY2KgiKGmw9i5s14by9mxs2abIPljv2hpnH7ewuRCJ+toAr4BnIlGMK7IcSuGxQeCkST5LwUBkfXMIlaxo/DSVhFdgD7uX9AeiuWoBQElYBX2OWslGTsC6mLL5RrU1lcg0+0UqxVkABE1EgUsWEvIMPZGQy5/CdEzvAEPgA3oAU6FgEzqTArYxM5uoEBvw/iEYWgQT4KoxEIfAi831NkapdMAPGUjSR95ki56c8j4FtjUQVbu1dLyzi+wOnwI98ewROVi0AcMT8NsyAC6uAaz7XjFoByJf3XGJe0V9qf1ibcJEe+XJ8WwS027Aocl3y7a5pcdAfREWBNnADvJPviBTYtQhE1oNfipCaZCrkADUAAAAASUVORK5CYII=" /></a>                 
                            <p id='msgBaixar'>Baixar XLSX</ p>
                        </div>
                    </div>
                </IconeBotao>
                {
                    props.isMobile === true && (
                        <DataFilter style={{
                            width: props.isMobile == true ? '100%' : '80%',
                            marginTop: props.isMobile == true ? '10px' : '0px',
                            marginLeft: 'auto',
                            display: props.disabled == true ? 'none' : 'flex'
                        }}>
                            <DateRange
                                id='datarange'
                                initial={dataInicial}
                                final={dataFinal}
                                onChangeInitial={(e) => setDataInicial(e)}
                                onChangeFinal={(e) => setDataFinal(e)}
                                erro={false}
                                disabled={false}
                                loading={false}
                                marginBottom='0px'
                            />
                        </DataFilter>
                    )
                }
                <hr style={{
                    marginTop: '7px',
                    marginBottom: '0px'
                }} />
            </div>
            {showChart && loadingXls == false? (
                <>

                    <ChartArea style={{
                        padding: '15px 0px',
                        margin: 'auto',
                    }}> 
                        <Line 
                            data={dataGraph} 
                            height={props.height ? props.height : 250} 
                            options={{
                                plugins:{
                                    legend:{
                                        display: false,
                                    },
                                    title: {
                                        display: false,
                                        text: 'Receitas emitidas por mês',
                                    },
                                },
                                scales:{
                                    y:{
                                        beginAtZero: true,
                                        ticks:{
                                            stepSize: 1,
                                        }
                                    }
                                },
                                maintainAspectRatio: false,

                            }}
                        />
                    </ChartArea>
                </>
            ) : (<Loading />)}

            {/* <div 
                className="button-finish" 
                style={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end', 
                    marginTop: '10px', 
                    marginBottom: '10px' 
                }}
            >
                <Button 
                    width='100px'
                    height='40px'
                    value='GERAR XLSX'
                    outline={false}
                    disabled={false}
                    onClick={() => gerarXLS()}
                    loading={loadingXls}
                    tooltip={true}
                    showTooltipMsg={loadingXls ? 'Gerando xlsx' : 'Baixar relatório em xlsx'}
                    tooltipMarginLeft='0px'
                    margin='5px 10px'
                />
            </div> */}
        </>
    )
}

export default TotalReceitasEmitidas;
