import styled from "styled-components";
import iconCalendar from "../../assets/icons/icons8-calendário-16.png"

export const Card = styled.div`

    background-color: ${props => (props.cancelada ? "##fafafb" : "#FFF")};
    width: ${props => props.isMobile ? "750px" : "900px"};
    height: ${props => props.isOpen ? 'auto' : 'auto'};
    border-radius: 10px;
    margin: auto;
    margin-top: 28px;
    margin-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;     
    justify-content: space-around;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);

    #tooltip[data-show] {
        display: block;
    }


    .row{
        display: flex;
        flex-direction: ${'row'};
        justify-content: space-between;

        .title-name{
            font-family: Lato;
            font-size: 24px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #303848;
            margin-bottom: 0px;
            opacity: ${props => (props.cancelada ? "0.35" : "1")};

        }

        .subtitle{
            width: 45%;
            font-family: Lato;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: italic;
            line-height: normal;
            letter-spacing: normal;
            color: #bac1cf;
            margin-bottom: 0px;
            opacity: ${props => (props.cancelada ? "0.35" : "1")};
            
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }

        .item-right{
            text-align: right;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-start;

            .icons{
                width: ${props => props.estado === 'GO' || props.estado === 'SC' || props.estado === 'MT' || props.estado === 'ES' ? '160px' : '60px'};
                margin-left: 20px;
                display: flex;
                flex-direction: row;
                justify-content: ${props => props.cancelada ? 'center' : (props.estado === 'GO' || props.estado === 'SC' || props.estado === 'MT' || props.estado === 'ES' ? 'space-between' : 'flex-end')};

                .icons-buttons{
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    a{
                        p{
                            display:none;
                            position:absolute;
                            font-size: 11px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            align-items: center;
                            padding-top: 2px;
                            color:#FFF;
                            background-color: #1d2129;
                            width: 63px;
                            height: 22px;
                            opacity: 0.9;
                            border-radius: 5px;
                            margin-left: -22px;
                            margin-top: 5px;
    
                            &:before{
                                position: absolute;
                                background-color:  #1d2129;
                                width: 8px;
                                height: 8px;
                                top: -4px;
                                margin-left: 17px;
                                content: '';
                                transform: rotate(45deg);
                            }
    
                        }
    
                        &:hover{
                            text-decoration: none;
                            p{
                                display: block;
                            }
                        }
                    }
    
    
                    img{
                        cursor: pointer;
                    }
                    
                    .cancel{
                        margin-left: 20px;
                        height: 18px;
                    }

                }

                .info-cancel{
                        width: 93px;
                        height: 25px;
                        border-radius: 5px;
                        background-color: #f3f3f5;
                        margin-left: 10px;
                        opacity: 10;
    
                        p{
                            font-family: Lato;
                            font-size: 12px;
                            font-weight: bold;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: normal;
                            letter-spacing: normal;
                            text-align: center;
                            color: #ff5252;
                            padding: 5px;
                        }
                    }
            }

            .agronomo {
                margin-top: -5px;

                .lbl-item{
                    text-align: end;
                }
            }
        }


        .lbl-item{
            font-family: Lato;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #bac1cf;
            margin-bottom: 0px;
            opacity: ${props => (props.cancelada ? "0.35" : "1")};

        }
        
        .lbl-item-fabricante{
            font-family: Lato;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #303848;
            margin-bottom: 0px;
            opacity: ${props => (props.cancelada ? "0.35" : "1")};    
        }

        .lbl-info-fabricante {
            font-family: Lato;
            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #bac1cf;
            margin-bottom: 0px;
            opacity: ${props => (props.cancelada ? "0.35" : "1")};
        }

        .lbl-info{
            max-width: 300px;
            font-family: Lato;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #303848;
            margin-bottom: 0px;
            opacity: ${props => (props.cancelada ? "0.35" : "1")};


        }


        .lbl-info-local{
            max-width: 300px;
            font-family: Lato;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #589bff;
            margin-bottom: 0px;
            opacity: ${props => (props.cancelada ? "0.35" : "1")};

            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            img{
                margin-top: -4px;
            }

        }


        .item {
            width: 46%
        }

    }

    .rowBtn {
        width: 40%;
        margin: auto;
    }

    @media only screen and (max-width: 1024px) {
        width: 100%;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding-left: 20px;
        padding-right: 10px;
    }

    @media only screen and (max-width: 600px) {
        .rowBtn {
            width: 100%;
        }
    }
`;


export const Row = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: auto;
    display: ${props => props.isMobile ? 'none' : props.isDisplay ? 'flex' : 'flex'};
    flex-direction: ${props=> props.direction ? props.direction : 'row'};
    justify-content: ${props=> props.justifyContent ? props.justifyContent : 'start'};
    align-items: center;

    margin-bottom:  ${props => props.marginBottom ? props.marginBottom : '0px'};

    @media only screen and (max-width: 600px) {
        display: ${props => props.isMobile ? 'flex' : props.isDisplay ? 'none' : 'flex'};
        flex-direction: ${props => props.responsiveReverse ? 'column-reverse' : props.isTitle ? 'row' : 'column'};
        align-items: ${props => props.responsiveReverse ? 'flex-start' : props.align ? props.align : 'center'};
        width: ${props => props.widthResponsive ? props.widthResponsive : '100%'};
        margin-right: ${props => props.widthResponsive ? '10px' : '0px'};
    }
`;

export const Col = styled.div`
    width: ${props => props.width ? props.width : '50%'};
    height: auto;
    display: flex;
    flex-direction: ${props => props.direction ? props.direction : 'column'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : '0px'};

    @media only screen and (max-width: 600px) {
        flex-direction: ${props => props.isTitle ? 'column' : props.direction ? props.direction : props.responsiveReverse ? 'column' : 'row'};
        width: ${props => props.responsiveReverse && props.width ? props.width : '100%'};
        margin-top: ${props => props.responsiveReverse && props.marginTop ? props.marginTop : '0px'};
        margin-bottom: ${props => props.responsiveReverse && props.marginTop ? '0px' : '10px'};
        align-items: ${props => props.alignItems ? props.align ? props.align : props.alignItems : props.alignResponsive ? props.alignResponsive :'flex-start'};
        justify-content: space-between;
    }
`;

export const Title = styled.label`
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};

`;

export const ScientificName = styled.label`
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #bac1cf;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    padding-top: 6px;
    padding-left: 10px;
    padding-right: 10px;
`;

export const Manufacturer = styled.label`
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};   
`;

export const ManufacturerName = styled.label`
    font-family: Lato;
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bac1cf;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};
    padding-left: 10px;
`;

export const Item = styled.label`
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bac1cf;
    margin-bottom: 0px;
    text-align: ${props => props.align ? props.align : 'start'};
    opacity: ${props => (props.cancelada ? "0.35" : "1")};
`;

export const Info = styled.label`
    max-width: 100%;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const InfoFarm = styled.label`
    max-width: 100%;
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #589bff;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    img{
        margin-top: -4px;
    }
`;

export const FormContainer = styled.div`
  width: 430px;
  padding: 20px 0px 20px;
  margin: auto;
  background: #f8f8f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;


  form{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    height: auto;
    margin: auto;
    margin-top: 20px;

    .input-wrapper{
      width: 100%;
    }

    .arquivos{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: left;
      /* margin-left: -10px; */
      .btn-update{
        /* width: 200px; */
        display: flex;
        align-items: center;
        width: 195px;
        height: 40px;
        background-color: #2fbb69;
        border-radius: 30px;
        border: 1px dashed #2fbb69;
        font-size: 14px;
        color: #FFF;
        margin: 10px 10px 10px 0px;
        padding: 2px 5px;
        cursor: pointer;
        transition: all 0.3s;
        z-index: 2;
        display: flex;
        flex-direction: row;
        justify-content: center;
        text-align: center;
      

        &:hover{
          cursor: pointer;
          background: #2fbb69;
          color: #FFF;
          box-shadow: 0 0 20px 0 #2fbb69;
        }
      }
      .arq-name{
        display: flex;
        align-items: center;
        width: 350px;
        height: 40px;
        background-color: #FFF;
        border-radius: 30px;
        padding: 13px;
        padding-left: 60px;
        margin-left: -55px;
        margin-top: 7px;
        box-shadow: inset 0px 0px 7px 2px #b6b6b6;
        font-family: Lato;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;

        img {
          transform: rotate(45deg);
          z-index: 10;
        }

        img:hover{
          cursor: pointer;
        }
      }
    }
    .legenda {
      font-size: smaller;
      width: 100%;
      margin-left: 5px;
      text-align: center;
    }

  }
  
  .button-finish {
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
  }

  #back {
    width: 45% !important;
  }
  #save {
    width: 45% !important;
  }

  @media only screen and (max-width: 991px) {
    form {
      .arquivos{
        
        
        .btn-update{
          font-size: ${props => props.isModal ? '10px' : '12px'};
        }
        
        .arq-name{
          font-size: ${props => props.isModal ? '9px' : '12px'};
        }
      }
    }
  }

  @media only screen and (max-width: 780px) {
    width: 90%;
    .button-finish {
        height: ${props => props.isModal ? '50px' : '130px'};
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
    }
  }
  
  @media only screen and (max-width: 768px) {
    width: 80%;
    .button-finish {
        height: ${props => props.isModal ? '50px' : '130px'};
        justify-content: space-around;
        flex-direction: row;
        align-items: center;
    }
  }



  @media only screen and (max-width: 505px) {
    form {
      width: 100%;
    }

    #back {
      width: 90% !important;
    }
    #save {
      width: 90% !important;
    }
  }


  @media only screen and (max-width: 415px) {
    width: 100%;

    .button-finish {
      height: ${props => props.isModal ? '50px' : '130px'};
      justify-content: space-around;
      flex-direction: column;
    }
    
    #back {
      width: 90% !important;
    }
    #save {
      width: 90% !important;
    }

    form {
      width: 100%;
    }
  }

  @media only screen and (max-width: 375px) {
    form {
      .arquivos{
        
        
        .btn-update{
          font-size: ${props => props.isModal ? '10px' : '12px'};
        }
        
        .arq-name{
          font-size: ${props => props.isModal ? '9px' : '12px'};
        }
      }
    }
  }
  
  @media only screen and (max-width: 320px) {
    form {
      .arquivos{
        
        .btn-update{
          font-size: 10px;
        }
        
        .arq-name{
          font-size: ${props => props.isModal ? '9px' : '10px'};
        }
      }
    }
  }
`;


export const FormButtonContainer = styled.div`
background: #f8f8f9;
display: flex;
align-items: center;
margin-top: 20px;

background: #f8f8f9;

#a{
  width: 400px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;

}

@media only screen and (max-width: 768px) {
  background: #f8f8f9;
  display: flex;
  align-items: center;
  
  background: #f8f8f9;
  
  #a{
    width: 293px;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column-reverse;
    
    .voltar{
      width: 100%;
    }
    .cadastrar{
      width: 100%;

    }

    a{
      width: 100%;
      max-width: 400px;
      
    }

    button{
      width: 100%;
      max-width: 400px;
      padding: 16px 10px;
    }
  }
}
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Icons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;

    #send {

        svg {
            transition: all 0.2s ease;
            &:hover{
                transform: scale(1.15);
            }
        }


        p{
            width: 75px;
            height: 22px;

            display:none;
            position:absolute;
            align-items: center;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            
            margin-top: 5px;
            margin-left: -16px;
            
            padding-top: 2px;
            
            color:#FFF;
            background-color: #1d2129;
            opacity: 0.9;
            
            border-radius: 5px;

            &:before{
                position: absolute;
                background-color:  #1d2129;
                width: 8px;
                height: 8px;
                top: -4px;
                margin-left: 17px;
                content: '';
                transform: rotate(45deg);
            }

        }

        &:hover{
            text-decoration: none;
            p{
                display: block;
            }
        }
    }

    a{
        


        p{
            width: 63px;
            height: 22px;

            display:none;
            position:absolute;
            align-items: center;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            
            margin-top: 5px;
            margin-left: -22px;
            
            padding-top: 2px;
            
            color:#FFF;
            background-color: #1d2129;
            opacity: 0.9;
            
            border-radius: 5px;

            &:before{
                position: absolute;
                background-color:  #1d2129;
                width: 8px;
                height: 8px;
                top: -4px;
                margin-left: 17px;
                content: '';
                transform: rotate(45deg);
            }

        }

        &:hover{
            text-decoration: none;
            p{
                display: block;
            }
        }
    }


    img{
        cursor: pointer;
        
        transition: all 0.2s ease;
        &:hover{
            transform: scale(1.15);
        }
    }
    
    .cancel{
        margin-left: 20px;
        height: 18px;
    }

    @media only screen and (max-width: 600px) { 
        margin-left: 0px;
    }
`;

export const Tooltip = styled.p`
    display:none;
`;

export const Cancelada = styled.div`
    width: 93px;
    height: 25px;
    border-radius: 5px;
    background-color: #f3f3f5;
    margin-left: 10px;
    opacity: 10;

    p{
        font-family: Lato;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ff5252;
        padding-top: 5px;
    }
`;

export const ButtonExport = styled.div`

    
    @-webkit-keyframes rotating /* Safari and Chrome */ {
        from {
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }   

    img {
        -webkit-animation: rotating 0.7s linear infinite;
        -moz-animation: rotating 0.7s linear infinite;
        -ms-animation: rotating 0.7s linear infinite;
        -o-animation: rotating 0.7s linear infinite;
        animation: rotating 0.7s linear infinite;
    }

`;

export const Data = styled.div`
    width: 296px;
    height: 50px;
    border-radius: 25px;
    background-color: #f3f3f5;
    display: flex;
    justify-content: center;
    align-items: center;


    .input-data-filter{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .input-date{
            border: none;
            border-right: 1px;
            background-color: #f3f3f5;
            padding-right: 25px;
            height: 40px;
        }

        .input-date + .input-date{
            border-left: solid 1px #dfe4eb;
            padding-left: 25px;
            padding-right: 0px;
        }

        input[type="date"]::-webkit-calendar-picker-indicator{
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url(${iconCalendar}) no-repeat;
            border-width: thin;
            margin-top: 5px;
        }

        input[type="date"]::-webkit-inner-spin-button{
            margin-top: 8px;
        }

    }
`;