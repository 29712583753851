import React from 'react';
// import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Input from "../../components/input";
import {ModalFooter, ModalTitle, StyleModal} from "./styles";
import api from "../../services/api";
import { store } from "react-notifications-component";
import Modal from '.';

class ModalDocumentos extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            textfile: "ADICIONAR ANEXOS (OPCIONAL, MAX 3MB)",
            revisao: 0,
            ficha: 1,
            fe: 0,
            fispq: 0,
            file:[],
            documentos: [],
            namesfiles:[],
            produto: "",
            fabricante: "",
            obs: "",
        }
        
    }
    
    updateResponseYes = () => {
        let resposta = 1;
        this.props.update(resposta);
    }

    updateResponseNo = () => {
        let resposta = 0;
        this.props.update(resposta);
        this.setState({
            textfile: "",
            revisao: 0,
            ficha: 1,
            fe: 0,
            fispq: 0,
            file:[],
            documentos: [],
            namesfiles:[],
            produto: "",
            fabricante: "",
            obs: "",
        })
    }

    load = async () =>{
        const { produto, revisao, ficha, fispq, fe, fabricante, file, obs} = this.state;
        const payload = {
            nome_produto: produto,
            revisao,
            fe,
            fispq,
            fabricante,
            file: file,
            observacoes: obs
        };
        try{
            await api.post("/solicitar/documento/",payload);
            this.updateResponseNo();
            store.addNotification({
                title: `A solicitação foi enviada!`,
                message: `Aguarde enquanto validamos o pedido`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
    
        }catch(e){
                                            
            let inputWithErrors = document.querySelectorAll(".error");

            for (let input of inputWithErrors) {
                input.classList.remove("error");
            }

            if(!produto) {
                const input = document.getElementById('produto');
                input.classList.add("error");
            }

            if(!fabricante) {
                const input = document.getElementById('fabricante');
                input.classList.add("error");
            }


            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos em vermelho",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    onChange = async (e) =>{
        if(e.target.files){//38.706.531
            if(e.target.files[0].size < 3098486){
                if(
                    e.target.files[0].type != "application/pdf" && 
                    e.target.files[0].type != "text/plain"  &&
                    e.target.files[0].type != "image/png"   &&
                    e.target.files[0].type != "image/gif"   &&
                    e.target.files[0].type != "image/jpeg"  
                ){
                    store.addNotification({
                        title: "Erro!",
                        message: `O arquivo selecionado não possui a extensão correta`,
                        type: "warning",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                    return;
                }

                



                const {file} = this.state;
                let fil = e.target.files;
                let reader = new FileReader();
                reader.readAsDataURL(fil[0]);
                reader.onload=(e)=>{
                    file.push(e.target.result);
                }
                // let archives = (this.state.file.length) + 1;
                    this.state.namesfiles.push(e.target.files[0].name);
                    this.setState({textfile: (<>
                    <p>ADICIONAR ANEXOS (OPCIONAL, MAX 3MB)</p>
                        {this.state.namesfiles.map((files)=>{
                            return (<>
                            <dt>
                            <dd>{files}</dd>
                            </dt>
                            </>)
                        })}
                    </>)});
            }else{
                store.addNotification({
                    title: "Erro!",
                    message: `O arquivo selecionado extendeu o tamanho permitido!`,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                return;
            }
        }
    }

    render() {
        return (

                <Modal
                    {...this.props}
                    align='center' // center, end, start
                    justify='center' // center, end, start
                    responsive
                    width='60%'
                >
                    <ModalTitle> {this.props.title} </ModalTitle>



                    <div style={{ 
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginBottom: '32px'
                    }}>
                        <div className="Radioum" style={{justifyContent: "center", width: "100%"}}>
                            <div className="radio" style={{ display: "flex", justifyContent: "space-around", flexDirection: "row"}}>
                                <div className="input1">
                                    <input type="radio" name="radio1" onChange={e => this.setState({fe: 1})} /> Ficha de emergência <br />
                                </div>
                                <div className="input1">
                                    <input type="radio" name="radio3" onChange={e => this.setState({fispq: 1})} /> FISPQ <br />
                                </div>
                            </div>
                            <div className="radio" style={{ display: "flex", marginTop: "10px", justifyContent: "space-around", flexDirection: "row",  }}>
                                <div className="input1" style={{marginLeft: "-54px"}}>
                                    <input type="radio" name="radio2" onChange={e => this.setState({revisao: 0})} /> Nova <br />
                                </div>
                                <div className="input1" style={{marginRight: "-66px"}}>
                                    <input type="radio" name="radio2" onChange={e => this.setState({revisao: 1})} /> Revisão <br />
                                </div>
                            </div>
                        </div>
                                
                        <Input 
                            // label=" "
                            className="produto"
                            placeholder="Nome do produto"
                            type="nomedoproduto"
                            id="produto"
                            onChange={e => this.setState({produto: e.target.value})}
                        />
                                
                        <Input 
                            label="Fabricante"
                            className="produto"
                            placeholder="Fabricante"
                            type="fabricante"
                            id="fabricante"
                            onChange={e => this.setState({fabricante: e.target.value})}
                        />
                                
                        <div className='input-wrapper'>
                            {/* <label for='input-file' style={{ backgroundColor: " #fff", borderRadius: "1px", border: "1px dashed #8a92a2", fontSize: "14px",color: "#8a92a2", margin: " 10px", padding: " 12px 20px"}} multiple>
                                <p>{this.state.textfile}</p>
                                (Apenas: PDF, TXT, PNG, GIF, JPEG)
                            </label> */}

                            <div 
                                for='input-file' 
                                onClick={() => document.getElementById('input-file').click()}
                                style={{
                                    width: '100%',
                                    height: '108px',
                                    backgroundColor: '#fff',
                                    borderRadius: '1px',
                                    border: '1px dashed #8a92a2',
                                    color: '#8a92a2',
                                    padding: '12px 20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <p
                                    style={{
                                        width: '100%',
                                        textAlign: 'center',
                                        fontSize: '15px',
                                    }}
                                >
                                    {this.state.textfile}
                                </p>
                                (Apenas: PDF, TXT, PNG, GIF, JPEG)
                            </div>


                            <input 
                                style={{display: "none"}}
                                type="file"
                                id="input-file"
                                name="file"
                                accept="application/pdf, text/plain, image/png, image/gif, image/jpeg"
                                multiple
                                onChange={(e)=>this.onChange(e)}
                            />
                            <span id='file-name'></span>
                        
                        </div>
                                
                        <Input 
                            // label=" "
                            className="produto"
                            placeholder="Alguma observação? (opcional)"
                            type="fabricante"
                            onChange={e => this.setState({obs: e.target.value})}
                        />
                    </div>  




                    <ModalFooter justifyContent='space-evenly'>
                        <Button color="#ff5252" style={{ outline:0, padding: "10px"}} onClick={this.updateResponseNo}>Cancelar </Button>

                        <Button color="#00b43f" style={{ outline:0, padding: "10px"}} onClick={this.load}>Solicitar</Button>
                    </ModalFooter>

                </Modal>


        );
    }
}
export default ModalDocumentos;