import styled from "styled-components";

export const ScreenArea = styled.div`
    width: 100vw;
    height: 100vh;
    /* display: ${props => props.isOpen ? 'block' : 'none'}; */
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    background-color: rgba(0,0,0,0.2);
`;

export const Container = styled.div`

    right: ${props => props.isOpen ? '0%' : '-100%'};


    @keyframes open {
        0% {
            right: -100%;
        }
        100% {
            right: 0%;
        }
    }

    @keyframes close {
        0% {
            right: ${props => props.animation ? '0%' : '-100%'};
        }
        100% {
            right: -100%;
        }
    }


    width: 45%;
    max-width: 550px;
    height: 100vh;
    position: fixed;
    top: 0px;
    z-index:1001;
    
    animation-name: ${props => props.isOpen ? 'open' : 'close'};
    animation-duration: 1s;

    background-color: #FFF;
    border: 1px solid #FFF;

    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    box-shadow: 0px 2px 5px 1px rgba(29, 33, 41, 0.4);

    padding: 0px 20px 20px 20px;


    z-index: 1050;
    overflow:auto;


      /* width */
    ::-webkit-scrollbar {
        width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: transparent; 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
    

    @media only screen and (max-width: 768px) {
        min-width: 80%;
    }

`;

export const HeaderArea = styled.div`
    width: 42%;
    max-width: 550px;
    height: 50px;
    background-color: #FFF;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    padding-right: 50px;
    position: fixed;
    z-index: 500;

    @media only screen and (max-width: 768px) {
        min-width: 75%;
    }
    
    
`;

export const Title = styled.h4`

`;

export const Fundo = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
    z-index:1000;
`;

export const Close = styled.div`
    padding: 4px;
    background-color: #E8EAED;
    border-radius: 90%;
    cursor: pointer;
    margin-right: 15px;
    margin-left: -5px;
    
    .xis{
        /* border: solid 2px #5F6368; */
        border-radius: 90%;
        color: #5F6368;
        font-weight: 600;
        font-size: 12px;
        padding: 0px 6px 0px 6px;
        /* margin: 5px; */
    }

@media only screen and (max-width: 768px) {
    width: 27px;
}

    
`;

export const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e4e5e6;
    margin-top: 50px;
    position: fixed;
    z-index: 500;
`;

