import React, { useState, useEffect } from "react";

import Button from "../../button/button_v2";
import Select from "../../select/select_v2";
import Input from "../../input/input_v2";
import api from "../../../services/api";
import ModalYesNo from "../../modal/modalYesNo";
import Modal from "../../modal/modalGeneric";
import AddProdutor from '../../../pages/produtores/add';
import AddFazenda from '../../../pages/produtores/addlocal';

import { FormContainer } from "./styles";
import { Container } from "react-grid-system";
import { store } from "react-notifications-component";

// import Date from "datejs";

const Contracts = ({ ...props }) => {
    const {
        estado,
        agronomo,
        crea,
        numeroArt,
        quantidadeReceita,
        receitaInicial,
        quantidadeContratos,
        numeroOrdem,
        dataValidade
    } = props;

    // console.log(props);


    const [produtorId, setProdutorId] = useState("");
    const [localAplicacaoId, setLocalAplicacaoId] = useState("");

    const [produtores, setProdutores] = useState([]);
    const [locaisAplicacao, setlocaisAplicacao] = useState([]);
    const [filial, setFilial] = useState('');
    const [valorContrato, setValorContrato] = useState("");
    const [dataInicial, setDataInicial] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [newId, setNewId] = useState('');
    const [erroId, setErroId] = useState('');
    const [valReal, setValReal] = useState('');
    const [addModal, setAddModal] = useState(false);

    const [disabled, setDisabled] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const [addModalFazenda, setAddModalFazenda] = useState(false);
    const [idProdutor, setIdProdutor] = useState('');

    const [loading, setLoading] = useState(false);
    const [erroProdutor, setErroProdutor] = useState(false)
    const [erroLocal, setErrolocalAplicacao] = useState(false)
    const [erroValor, setErroValContrato] = useState(false)
    const [erroDataI, setErroDataInicial] = useState(false)
    const [erroDataF, setErroDataFinal] = useState(false)

    const [showModalAddFazenda, setShowModalAddFazenda] = useState(false);


    const updateIdProdutor = async () => {
        // console.log("UPDATE PRODUTOR");
        // console.log(id);

        // if (id) {
            setAddModalShow(false);
            // setIdProdutor(id);
            getFilial();
            // setAddModalFazenda(true);
        // }

    };

    const updateFazenda = async (e) => {
        // console.log("UPDATE FAZENDA");
        // console.log(e);
        // getfarms();
        setAddModalFazenda(false);
        getProducers();

    };

    const formatProducers = produtores => {
        produtores = produtores.map(produtor => {
            const format = {
                value: produtor.id,
                label: produtor.nome
            };
            return format;
        });
        return produtores;
    };

    const formatFarms = farms => {
        farms = farms.map(farm => {
            const format = {
                value: farm.id,
                label: farm.nome
            };
            return format;
        });
        return farms;
    };

    const getProducers = async (filial) => {
        // const response = await api.get(`/filiais/${filial}/produtores`);
        try {
            console.log(props.estado)
            const response = await api.get(`/produtores/estado/${props.estado.value || props.estado}`);
            let produtores = response.data.data;
            produtores = formatProducers(produtores);
            setProdutores(produtores);

        } catch (error) {
            console.log(error.response)
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            return;
        }
    };

    const getfarms = async () => {
        try {
            setLocalAplicacaoId('');

            const response = await api.get(`/fazendas/estado/${produtorId.value}/${props.estado.value || props.estado}`);
            let farms = response.data.data;
            farms = formatFarms(farms);
            setlocaisAplicacao(farms);
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });

        }
    };

    const getFilial = async () => {
        try {
            const response = await api.get(`/agronomos/${agronomo.value || agronomo}`);
            setFilial(response.data.data.filial.id);
            getProducers(response.data.data.filial.id);
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            // return;
        }
    };

    const saveART = async () => {

        const payload = {
            estado: estado.value || estado,
            agronomoId: agronomo.value || agronomo,
            produtorId: produtorId.value,
            nome_produtor: produtorId.label,
            creaId: crea.value || crea,
            numero_art: numeroArt,
            receita_inicial: receitaInicial,
            quantidade_contratos: quantidadeContratos,
            numero_ordem: numeroOrdem,
            data_validade: dataValidade
        };
        try {
            const response = await api.post(`/creas/${crea.value || crea}/arts`, payload);
            setNewId(response);

        } catch (error) {



            if (typeof error.response.data.errors === 'string') {
                setErroId(error.response.data.errors);
    
                store.addNotification({
                    title: "Erro!",
                    message: `${error.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                let e = error.response.data.errors.numero_art.notallowed;
                setErroId(e);
    
                store.addNotification({
                    title: "Erro!",
                    message: `${e}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });

                props.back();
            }


            // return;
        }
    };

    const saveContract = async e => {
        setLoading(true);

        if (erroId && newId === '') {
            setLoading(false);
            store.addNotification({
                title: "Erro!",
                message: `${erroId}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        } else {

            let id = '';

            if (props.isModal !== true) {
                id = newId.data.data.id;
            } else if (props.isModal === true && props.numeroArt && typeof props.numeroArt === 'number') {
                id = props.numeroArt
            } else {
                if (props.isModal === true && typeof props.numeroArt === 'string') {
                    if (props.artId && typeof props.artId === 'number') {
                        id = newId;
                    } else {
                        id = newId.data.data.id;
                    }
                } else {
                    if (props.artId) {
                        id = props.artId;
                    }
                }
            }
    
            // console.log("ID: " + id);
            // console.log("Local devolução Id: " + localAplicacaoId.value);
            // console.log(dataInicial);
            // console.log(dataFinal);
            let localId = localAplicacaoId.value;
    
            const payloadB = {
                agro_art_id: id,
                agro_farm_id: localId,
                nome_produtor: produtorId.label,
                valor_contrato: parseFloat(valorContrato.replace(',', '.')),
                data_inicio: dataInicial,
                data_termino: dataFinal
            };
    
    
            try {
                const responseB = await api.post(`/contratos`, payloadB);
                setLoading(false);
                console.log(props);
                if (props.isModal === true) {
                    console.log('É MODAL');
                    props.update();
                } else {
                    props.loc.history.push(`/contracts/${id}`);
                }
            } catch (err) {
                setLoading(false);

                if (typeof err.response.data.errors === 'string') {
                    store.addNotification({
                        title: "Erro!",
                        message: err.response.data.errors,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                } else {
                    const ERRO = err.response.data.errors;
                    let msgErro = '';
    
                    if (ERRO.invalid.data_termino !== undefined) {
                        setErroDataFinal(true);
                        msgErro=ERRO.invalid.data_termino;
                    }
    
                    if (ERRO.invalid.data_inicio !== undefined) {
                        setErroDataInicial(true);
                        msgErro=ERRO.invalid.data_inicio;
    
                    }
    
                    if (ERRO && err.response.data.message) {
                        setErrolocalAplicacao(true);
                        store.addNotification({
                            title: "Erro!",
                            message: msgErro === '' ? `${ERRO}` : `${msgErro}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    } else {
                        try {
                            
                            if (ERRO.invalid) {
                                
                                if(ERRO.invalid.agro_art_id){
                                    store.addNotification({
                                        title: "Erro!",
                                        message:  msgErro === '' ? `${ERRO.invalid.agro_art_id}` : `${msgErro}`,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 4000,
                                            onScreen: true
                                        }
                                    });
                                }else if (ERRO.invalid.data_inicio) {
            
                                    let inputWithErrors = document.querySelectorAll(".error");
            
                                    // for (let input of inputWithErrors) {
                                    //     input.classList.remove("error");
                                    // }
                                    for (let input of inputWithErrors) {
                                        // input.classList.remove("error");
                                        if (input === 'nome_produtor') {
                                            setErroProdutor(false);
                                        } else if (input === 'agro_farm_id') {
                                            setErrolocalAplicacao(false);
                                        } else if (input === 'valor_contrato') {
                                            setErroValContrato(false);
                            
                                        } else if (input === 'data_inicio') {
                                            setErroDataInicial(false);
                            
                                        } else if (input === 'data_termino') {
                                            setErroDataFinal(false);
                            
                                        }
                                    }
                
                                    const errors = err.response.data.errors.invalid;
                
                
                                    // for (let field in errors) {
                                    //     const input = document.getElementById(field);
                                    //     input.classList.add("error");
                                    // }
                                    for (let input of errors) {
                                        // input.classList.remove("error");
                                        if (input === 'nome_produtor') {
                                            setErroProdutor(true);
                                        } else if (input === 'agro_farm_id') {
                                            setErrolocalAplicacao(true);
                                        } else if (input === 'valor_contrato') {
                                            setErroValContrato(true);
                            
                                        } else if (input === 'data_inicio') {
                                            setErroDataInicial(true);
                            
                                        } else if (input === 'data_termino') {
                                            setErroDataFinal(true);
                                        }
                                    }
            
            
                                    store.addNotification({
                                        title: "Erro!",
                                        message:  msgErro === '' ? `${ERRO.invalid.data_inicio}` : `${msgErro}`,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 4000,
                                            onScreen: true
                                        }
                                    });
                                } else if (ERRO.invalid.data_termino) {
                                    let inputWithErrors = document.querySelectorAll(".error");
            
                                    for (let input of inputWithErrors) {
                                        // input.classList.remove("error");
                                        if (input === 'nome_produtor') {
                                            setErroProdutor(false);
                                        } else if (input === 'agro_farm_id') {
                                            setErrolocalAplicacao(false);
                                        } else if (input === 'valor_contrato') {
                                            setErroValContrato(false);
                            
                                        } else if (input === 'data_inicio') {
                                            setErroDataInicial(false);
                            
                                        } else if (input === 'data_termino') {
                                            setErroDataFinal(false);
                            
                                        }
                                    }
                
                                    const errors = err.response.data.errors.invalid;
                
                
                                    // for (let field in errors) {
                                    //     const input = document.getElementById(field);
                                    //     input.classList.add("error");
                                    // }
                                    for (let input of errors) {
                                        // input.classList.remove("error");
                                        if (input === 'nome_produtor') {
                                            setErroProdutor(true);
                                        } else if (input === 'agro_farm_id') {
                                            setErrolocalAplicacao(true);
                                        } else if (input === 'valor_contrato') {
                                            setErroValContrato(true);
                            
                                        } else if (input === 'data_inicio') {
                                            setErroDataInicial(true);
                            
                                        } else if (input === 'data_termino') {
                                            setErroDataFinal(true);
                                        }
                                    }
            
                                    store.addNotification({
                                        title: "Erro!",
                                        message: msgErro === '' ? `${ERRO.invalid.data_termino}` : `${msgErro}`,
                                        type: "danger",
                                        insert: "top",
                                        container: "top-right",
                                        animationIn: ["animated", "fadeIn"],
                                        animationOut: ["animated", "fadeOut"],
                                        dismiss: {
                                            duration: 4000,
                                            onScreen: true
                                        }
                                    });
                                }
                            } else {
            
                                let inputWithErrors = document.querySelectorAll(".error");
            
                                // for (let input of inputWithErrors) {
                                //     input.classList.remove("error");
                                // }
                                for (let input of inputWithErrors) {
                                    // input.classList.remove("error");
                                    if (input === 'nome_produtor') {
                                        setErroProdutor(false);
                                    } else if (input === 'agro_farm_id') {
                                        setErrolocalAplicacao(false);
                                    } else if (input === 'valor_contrato') {
                                        setErroValContrato(false);
                        
                                    } else if (input === 'data_inicio') {
                                        setErroDataInicial(false);
                        
                                    } else if (input === 'data_termino') {
                                        setErroDataFinal(false);
                        
                                    }
                                }
            
                                const errors = err.response.data.errors;
            
            
                                // for (let field in errors) {
                                //     const input = document.getElementById(field);
                                //     input.classList.add("error");
                                // }
            
                                for (let input of errors) {
                                    // input.classList.remove("error");
                                    if (input === 'nome_produtor') {
                                        setErroProdutor(true);
                                    } else if (input === 'agro_farm_id') {
                                        setErrolocalAplicacao(true);
                                    } else if (input === 'valor_contrato') {
                                        setErroValContrato(true);
                        
                                    } else if (input === 'data_inicio') {
                                        setErroDataInicial(true);
                        
                                    } else if (input === 'data_termino') {
                                        setErroDataFinal(true);
                                    }
                                }
            
                                
            
                                store.addNotification({
                                    title: "Erro!",
                                    message: "Verifique os campos em vermelho",
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            }
            
                        } catch (err) {
                            store.addNotification({
                                title: "Erro!",
                                message:  msgErro === '' ? `${ERRO}` : `${msgErro}`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    }

                }
    

            }
        }
    };

    const validateDataInicial = dataInicial => {

        if (dataInicial.length < 11) {
            setDataInicial(dataInicial);
        }
    };

    const validateDataFinal = dataFinal => {
        if (dataFinal.length < 11) {
            setDataFinal(dataFinal);
        }
    };

    const validateValorContrato = valorContrato => {
        setValorContrato(valorContrato);
    };

    const moeda = (valor) => {

        if (valor && typeof valor === 'string') {
            valor = valor + '';


            valor = parseInt(valor.replace(/[\D]+/g, ''));
            valor = valor + '';


            valor = valor.replace(/([0-9]{2})$/g, ".$1");
    
            if (valor.length > 6) {
                valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, "$1.$2");
            }

            console.log("SAVE MOEDA => ", valor)
    
            setValReal(valor);
            validateValorContrato(valor);
        } else {
            setValReal('');
            validateValorContrato('');
        }
    };

    const openModal = (e) => {
        // e.preventDefault();

        let inputWithErrors = document.querySelectorAll(".error");

        for (let input of inputWithErrors) {
            // input.classList.remove("error");
            if (input === 'nome_produtor') {
                setErroProdutor(false);
            } else if (input === 'agro_farm_id') {
                setErrolocalAplicacao(false);
            } else if (input === 'valor_contrato') {
                setErroValContrato(false);

            } else if (input === 'data_inicio') {
                setErroDataInicial(false);

            } else if (input === 'data_termino') {
                setErroDataFinal(false);

            }
        }

        if (!produtorId || !localAplicacaoId || !valReal || !dataInicial || !dataFinal) {
            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos em vermelho",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }

        if (!produtorId) {
            setErroProdutor(true);
        } else {
            setErroProdutor(false);
        }

        if (!localAplicacaoId) {
            setErrolocalAplicacao(true);
        } else {
            setErrolocalAplicacao(false);
        }

        if (!valReal) {
            setErroValContrato(true);
        } else {
            setErroValContrato(false);
        }

        if (!dataInicial) {
            setErroDataInicial(true);
        } else {
            if (dataInicial.length > 10) {
                setErroDataInicial(true);
            } else {
                setErroDataInicial(false);
            }
        }

        if (!dataFinal) {
            setErroDataFinal(true);
        } else {
            if (dataFinal.length > 10) {
                setErroDataFinal(true);
            } else {
                setErroDataFinal(false);
            }
        }

        if (produtorId, localAplicacaoId, valReal, dataInicial, dataFinal) {
            if (props.isModal === true) {
                saveContract();
            } else {
                setAddModal(true);
            }
        }

    };

    const openModalProdutor = e => {
        setAddModalShow(true);
    };

    const updateResposta = async (resposta) => {
        if (resposta == 1) {
            setAddModal(false);
            saveContract();
        } else {
            setAddModal(false);

        }
    };

    const modalClose = e => {

        if (addModal == true) {
            setAddModal(true);

        }
        if (addModalShow == true) {
            setAddModalShow(false);
        }

        if (addModalFazenda == true) {
            setAddModalFazenda(false);
        }
    };

    const addNewFazenda = () => {
        setShowModalAddFazenda(true);
    }

    const updateFazendas = () => {
        setShowModalAddFazenda(false);
        getfarms();
    }

    useEffect(() => {
        if (erroLocal === true) {
            setErrolocalAplicacao(false);
        }
    },[localAplicacaoId])

    useEffect(() => {
        // console.log(estado);
        if (props.isModal != true) {
            window.scrollTo(0, 0);
        }
        getFilial();
    }, [])

    useEffect(() => {
        
        console.log("CHANGE PRODUTOR ID => ", produtorId)
        if (produtorId) {
            getfarms();
        }
    }, [produtorId])

    useEffect(() => {
        if (props.isModal === true && props.numeroArt && typeof props.numeroArt === 'number') {
            console.log('');
        } else {
            if (props.isModal === true && typeof props.numeroArt === 'string') {
                console.log('');
                if (props.artId && typeof props.artId === 'number') {
                    setNewId(props.artId);
                } else {
                    saveART();
                }
            } else {
                saveART();
            }
        }
    }, []);


    return (
        <>
            <FormContainer isModal={props.isModal}>
                <Container>

                    {props.isModal === true ? (null) : (
                        <div className="steps">

                            <div className="btn-step">
                                <label>1</label>
                            </div>

                            <div className="btn-step">
                                <label>2</label>
                            </div>
                            <div className="btn-step-selected">
                                <label>3</label>
                            </div>
                        </div>
                    )}


                    <form 
                        // onSubmit={openModal}
                    >
                        {/* <div className="adicionar"> */}

                        <Select
                            name="produtor"
                            className="select"
                            label="Produtor"
                            id="nome_produtor"
                            value={produtorId.value || produtorId}
                            onChange={produtorId =>
                                produtorId ? setProdutorId(produtorId) : setProdutorId("")
                            }
                            options={produtores}
                            selectUniqueOption={true}
                            erro={erroProdutor}
                            disabled={false}
                            buttonAddNew={true}
                            onClickAddNew={() => openModalProdutor()}
                            isModal={props.isModal}
                        />
                            {/* <label className="add" onClick={openModalProdutor}>Adicionar novo</label>

                        </div> */}

                        <Select
                            name="localAplicacao"
                            className="select"
                            label="Local de Aplicação"
                            id="agro_farm_id"
                            value={localAplicacaoId.value || localAplicacaoId}
                            onChange={localAplicacao =>
                                localAplicacao
                                    ? setLocalAplicacaoId(localAplicacao)
                                    : setLocalAplicacaoId("")
                            }
                            options={locaisAplicacao}
                            selectUniqueOption={true}
                            erro={erroLocal}
                            disabled={false}
                            buttonAddNew={produtorId.value || produtorId ? true : false}
                            onClickAddNew={() => addNewFazenda()}
                        />

                        <Input
                            name="valorContrato"
                            className="text"
                            label="Valor do Contrato (R$)"
                            id="valor_contrato"
                            type='number'
                            value={valReal}
                            onChange={e => moeda(e)}
                            erro={erroValor}
                        />

                        <Input
                            name="dataInicial"
                            className="text"
                            label="Data Inicial"
                            id="data_inicio"
                            mask="99/99/9999"
                            type="date"
                            value={dataInicial}
                            onChange={e => validateDataInicial(e)}
                            erro={erroDataI}
                        />

                        <Input
                            name="dataFinal"
                            className="text"
                            label="Data Final"
                            id="data_termino"
                            mask="99/99/9999"
                            type="date"
                            value={dataFinal}
                            onChange={e => validateDataFinal(e)}
                            erro={erroDataF}
                        />

                        {/* <Button type="submit" >
                            Cadastrar
                        </Button> */}

                        <div className="button-finish" style={{ width: '100%', display: addModalShow === true ? 'none' : 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                            <Button 
                                width='100%'
                                height='50px'
                                value='SALVAR'
                                outline={false}
                                disabled={false}
                                // onClick={() => emitirReceita({stepOneParams, stepTwoParams, stepThreeParams})}
                                onClick={() => openModal()}
                                loading={loading}
                            />
                        </div>

                        <ModalYesNo
                            show={addModal}
                            onHide={modalClose}
                            resposta=''
                            update={(e) => updateResposta(e)}
                            title='Atenção!'
                            text='Você tem certeza que deseja salvar esta ART/TRT? Após confirmação os dados não poderão ser alterados ou removidos.'
                        ></ModalYesNo>

                        {/* <ModalAddProdutor
                            show={addModalShow}
                            onHide={modalClose}
                            update={updateIdProdutor}
                        /> */}

                        <Modal
                            show={addModalShow}
                            onHide={() => modalClose()}
                            size='lg'
                        >
                            <AddProdutor isModal={true} update={() => updateIdProdutor()}/>
                        </Modal>
                        
                        {/* {idProdutor > 0 ? (<>
                            <ModalAddFazenda
                                show={addModalFazenda}
                                produtorId={idProdutor}
                                onHide={modalClose}
                                update={updateFazenda}
                            />

                        </>) : null} */}

                        {showModalAddFazenda ? (
                            <Modal
                                size='lg'
                                show={showModalAddFazenda}
                                onHide={() => setShowModalAddFazenda(false)}
                            >
                                <AddFazenda 
                                    isModal={true} 
                                    update={() => updateFazendas()}
                                    match={{
                                        params: {
                                            id: produtorId.value || produtorId
                                        }
                                    }}
                                />
                            </Modal>
                        ) : (null)}
                    </form>
                </Container>
            </FormContainer>
        </>
    );
};
export default Contracts;
