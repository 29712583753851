import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';

class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    render(){
        return(
            
          //   <Modal
          //   {...this.props}
          //   size="lg"
          //   aria-labelledby = "contained-modal-title-vcenter"
          //   centered
          // >
          //   <Modal.Header closeButton
          //   style={{backgroundColor: " #f8f8f8"}}>
          //     <Modal.Title id="contained-modal-title-vcenter">
          //       {this.props.title}
          //     </Modal.Title>
          //   </Modal.Header>
          //   <Modal.Body
          //   style={{backgroundColor: " #f8f8f8"}}>
          //     {this.props.text}
          //   </Modal.Body>
          //   <Modal.Footer
          //   style={{backgroundColor: " #f8f8f8"}}>
       
          //     <Button outline style={{outline:0, padding: "15px"}}
          //               color="#00b43f"  onClick={this.props.onHide}>Fechar
          //     </Button>
                
          //   </Modal.Footer>
          // </Modal>


          <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
            width='50%'
          >
            <ModalTitle> {this.props.title} </ModalTitle>

            <ModalBody>
              {this.props.text}
            </ModalBody>

            <ModalFooter>
                <Button 
                  style={{outline:0, padding: "15px"}}
                  outline 
                  color="#00b43f"  
                  onClick={this.props.onHide}
                >
                  Fechar
                </Button>
            </ModalFooter>
          </Modal>
        );
    }
}
export default ModalNew;