import React, { useEffect, useState } from 'react'
import { Close, Container, HeaderArea, Line, ScreenArea, Title, Fundo } from './style';
import deletIcon from "../../assets/icons/icons-8-trash.png"

const CardRight = ({ ...props }) => {

    const [isOpen, setIsOpen] = useState(false);
    // const [animation, setAnimation] = useState(false);

    const close = () => {
        setIsOpen(false);
        props.updateIsOpen(false)
    }

    useEffect(() => {
        setIsOpen(props.open)
    }, [props.open])


    return (
        // <ScreenArea isOpen={isOpen} onClick={() => setIsOpen(false)}>
        <>
            {isOpen && (
                <Fundo onClick={() => close()}></Fundo>
            )}
            <Container isOpen={isOpen} animation={props.animation}>

                {console.log(isOpen, props.animation)}
                <HeaderArea>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}>
                        <Close onClick={() => close()}> <div className="xis">X</div></Close>
                        <Title>{props.title}</Title>
                    </div>

                    <div>
                        {props.isEdit && (
                            <img  className="delete" onClick={() => props.updateDelete()} src={deletIcon} style={{ cursor: 'pointer' }}/>
                        )}
                    </div>
                </HeaderArea>

                <Line />

                {props.children}
            </Container>
        </>
        // </ScreenArea>
    )
}

export default CardRight;