import styled from "styled-components";
export const NewsCardStyle = styled.div`

    width: 100%;
    
    .row-news{
        padding-bottom: 40px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: auto;

        a{
            text-decoration: none;
        }

        .news-card{
            /* width: 285px; */
            width: 100%;
            height: 348px;
            margin: auto;
            border-radius: 10px;
            box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
            background-color: #FFF;
        
            .image{
                height: 150px;
                background-color: #FFF;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                display: flex;
                justify-content: center;

                img{
                    width: 150px;
                    margin-top: 15px;
                }
            }
        
            .title{
                height: 63px;

                h3{
                    width: auto;
                    margin: auto;
                    font-family: Lato;
                    font-size: 16px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.31;
                    letter-spacing: normal;
                    text-align: center;
                    color: #1d2129;
                    margin-top: 40px;
                }
            }
        
            .data{
                font-family: Lato;
                font-size: 11px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #bac1cf;
                margin-top: 15px;
                margin-bottom: 15px;
            }
        
            .btn-area{
                margin-left: 65px;
                
                .btn{
                    width: 155px;
                    height: 30px;
                    padding: 6px 9px 9px 9px;
                    border-radius: 15px;
                    border: solid 1px #00b43f;
                    background-color: #FFF;
                    font-size: 10px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: #00b43f;
                }
                cursor: pointer;
            }
        }
    }
`;

export const NewsCardSchedule = styled.div`

.row-news{
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;

    a{
        text-decoration: none;
    }

    .news-card{
        margin-top: 0px;
        .image{
            height: 100px;
            background-color: #FFF;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            display: flex;
            justify-content: center;

            img{
                width: 150px;
                margin-top: 15px;
            }
        }
    
        .title{
            height: 63px;
            h3{
                width: auto;
                margin: auto;
                font-family: Lato;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                text-align: center;
                color: #1d2129;
                margin-top: 40px;
            }
        }

        .text{
            width: 100%;
            text-align: center;
            font-size: 14px;
            margin-top: -21px;
            color: #8a92a2;
        }
    
        .data{
            font-family: Lato;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #bac1cf;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    
        .btn-area{
            margin-left: 0px;
            display: flex;
            justify-content: center;
            margin-top: -10px;
            
            .btn{
                width: 155px;
                height: 30px;
                padding: 6px 9px 9px 9px;
                border-radius: 15px;
                border: solid 1px #00b43f;
                background-color: #FFF;
                font-size: 10px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #00b43f;
            }
            cursor: pointer;
        }
    }
}
`;

export const NewsCardCrm = styled.div`

.row-news{
    padding-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: auto;

    a{
        text-decoration: none;
    }

    .news-card{
        margin: auto;
        margin-top: 10px;
        
        .image{
            height: 100px;
            background-color: #FFF;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            display: flex;
            justify-content: center;

            img{
                width: 150px;
                margin-top: 15px;
            }
        }
    
        .title{
            height: 63px;
            h3{
                width: auto;
                margin: auto;
                font-family: Lato;
                font-size: 16px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.31;
                letter-spacing: normal;
                text-align: center;
                color: #1d2129;
                margin-top: 30px;
            }
        }

        .text{
            width: 100%;
            text-align: center;
            font-size: 14px;
            margin-top: -21px;
            color: #8a92a2;
        }
    
        .data{
            font-family: Lato;
            font-size: 11px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #bac1cf;
            margin-top: 15px;
            margin-bottom: 15px;
        }
    
        .btn-area{
            margin-left: 0px;
            display: flex;
            justify-content: center;
            margin-top: -10px;
            
            .btn{
                width: 155px;
                height: 30px;
                padding: 6px 9px 9px 9px;
                border-radius: 15px;
                border: solid 1px #00b43f;
                background-color: #FFF;
                font-size: 10px;
                font-weight: bold;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #00b43f;
            }
            cursor: pointer;
        }
    }
}
`;