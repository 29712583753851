import styled from "styled-components";

export const Card = styled.div`
  width: 100%;
  max-width: 615px;
  height: ${props => (props.openBox ? 'auto' : '90px')};
  /* max-height: 550px; */

  background-color: #FFF;

  margin-bottom: 20px;
  display: flex;
  flex-direction: ${props => (props.openBox ? 'collumn' : 'row')};
  justify-content: ${props => (props.openBox ? 'space-around' : 'space-between')};
  align-items: ${props => (props.openBox ? 'flex-start' : 'center')};
  border:  'solid 1px #e8edf3';
  border-radius: 10px;
  transition: all 0.2s;
  
  padding: 0 24px;
  padding-bottom: ${props => (props.openBox ? '30px' : '0px')};


  .selectValue{
    width: 270px;
    height: 50px;
  }

  .align {
    display: flex;                            
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .cancel {
    width: 15px;
    height: 17px;
    margin-top: ${props => (props.openBox ? '0px' : '0px')};
    transition: all 0.2s;
    
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
    &:active {
      transform: scale(0.8);
    }
  }
  .arrow {
    transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
    margin-top: 0px;
    transition: all 0.2s;
    &:hover {
      cursor: pointer;
      transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
    }

    &:active {
      transform: scale(0.8);
    }
  }


  @media only screen and (max-width: 780px) {
    height: auto;
    padding: ${props => (props.openBox ? '45px 24px 40px 24px' : '45px 24px 40px 24px')};

    .align {
      height: auto;
      display: flex;                            
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 10px;
    }
    .selectValue{
      width: 97%;
      height: 65px;
    }

    .cancel {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .arrow {
      transform: ${props => (!props.openBox ? 'none' : 'rotate(180deg)')};
      margin-top: ${props => (!props.openBox ? '-10px' : '0px')};
      
      position: ${props => props.openBox ? 'relative' : 'initial'};
      bottom: ${props => props.openBox ? '-358px' : '0px'};
      transition: all 0.2s;

      

      &:hover {
        cursor: pointer;
        transform: ${props => (!props.openBox ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
      }

      &:active {
        transform: scale(0.8);
      }
    }
  }
`;

export const Title = styled.h2`
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    margin-top: ${props => props.open ? '24px' : '0px'};
    margin-bottom: ${props => props.open ? '24px' : '0px'};
`;

export const Text = styled.label`
    font-family: Lato;
    font-size: 14px;
    color: #303848;
    margin-bottom: 0px;
    margin-top: 10px;
`;

export const Container = styled.div`
    width: 100%;
    max-width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Status = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${props => props.status === 1 ? '#2fb669' : '#FF0101'};
  border: 1px solid transparent;
  border-radius: 50%;
  margin-right: 10px;
`;

