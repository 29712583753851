import { parseISOWithOptions } from "date-fns/fp";
import styled from "styled-components";



export const Card = styled.div`
    width: ${props => props.width ? props.width : '29.5%'};
    height: ${props => props.height ? props.height : '100%'};

    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    padding: 0px 10px 5px 10px;

    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'wrap'};

    @media only screen and (max-width: 843px){
        margin-bottom: 16px;
    }
`;

export const Icons = styled.div`
    width: 90%;
    margin-top: 14px;
    font-size: 13px;
    display: contents;
    p{
        margin-bottom: 1px;
    }

    @media only screen and (min-width: 900px){
        display: flex;
        justify-content: space-between;
    }
`;

export const Icon = styled.div`
    text-align: center;
    width: 98%;

    @media only screen and (max-width: 899px){
        border-bottom: 1px solid #e5e5e5;
        margin: 5px 0px;
    }
`;


export const Title = styled.h2`
    /* color: #000;
    font-size: 20px; */
    width: 100%;
    font-family: Lato;
    font-size: ${props => props.fontSize ? props.fontSize : '10px'};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #8a92a2;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    margin-left: ${props => props.marginLeft ? props.marginLeft : '20px'};
    margin-top: ${props => props.marginTop ? props.marginTop : '27px'};
`;

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};

    .item-dados{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${props => props.isMobile === true ? '13px' : '11px'};
        margin-bottom: 1px;
        margin-left: ${props => props.isMobile === true ? '25px' : '0px'};

        img{
            margin-left: -109px;
            position: absolute;
        }

        #msgVentosCompleta{
            display: none;
        }

        &:hover{
            #msgVentosCompleta{
                transform: scale(1.1);
                display: flex;
                justify-content: center;
                position:absolute;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                text-align: center;
                align-items: center;
                color:#FFF;
                background-color: #1d2129;
                width: 200px !important;
                height: 18px;
                opacity: 0.9;
                border-radius: 5px;
                margin-bottom: 40px;
                margin-right: 40px;

                &:after{
                    display: inline-block;
                    position: absolute;
                    background-color: #1d2129;
                    width: 6px;
                    height: 6px;
                    top: 14px;
                    margin-left: 40px;
                    content: '';
                    transform: rotate(45deg);
                }
            }
        }
    }

    .item-dados .msgVentos{
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
        cursor: default;
    }

    @media only screen and (max-width: 815px) {
        width: 100%;
        flex-direction: column;
    }
`;

export const PrimeiraLinha = styled.div`
    width: 100%;
    display: flex;
    font-family: 'Lato';

    .itens-previsao{
        margin-left: 20px;
        color: rgb(171, 171, 171);
        font-size: 10px;
        margin-top: 4px;
        margin-bottom: 5px;
    }

    .dia-previsao{
        margin-left: auto;
        margin-right: 10px;
        color: rgb(171, 171, 171);
        font-size: 12px;
        text-align: right;
        width: 32%;
    }

    .numero-dia-previsao{
        color: black;
        font-size: 24px;
        margin-bottom: -5px;
        margin-right: 4px;
    }
`;

export const Col2 = styled.div`
    width: ${props => props.width ? props.width : '48%'};
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: center;

    @media only screen and (max-width: 780px) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
    }
`;

export const Col5 = styled.div`
    width: ${props => props.width ? props.width : '20%'};
    height: 200px;
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: center;

    @media only screen and (max-width: 780px) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
        justify-content: flex-start;

    }
`;

export const Col = styled.div`
    width: ${props => props.width ? props.width : '50%'};
    height: 200px;
    display: flex;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'column'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: center;

    @media only screen and (max-width: 815px) {
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
    }
`;

export const LineBox = styled.div`
    width: 1px;
    height: 200px;
    background-color: #e5e5e5;

    @media only screen and (max-width: 815px) {
        display: none;
    }
`;

export const Text = styled.p`
    width: 90%;
    color: #000;
    font-size: ${props => props.fontSize ? props.fontSize : '12px'};
    text-align: ${props => props.textAlign ? props.textAlign : 'center'};
    margin: 0px;
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};

`;

export const TextDirection = styled.p`
    width: 90%;
    color: #000;
    font-size: ${props => props.fontSize ? props.fontSize : '12px'};
    text-align: ${props => props.textAlign ? props.textAlign : 'center'};
    margin: 0px;
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};

    /* Safari and Chrome */
    @-webkit-keyframes rotatingVento  {
        0% {
            -webkit-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -o-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
        } 
        50% {
            -webkit-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -o-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
        }
        100% {
            -webkit-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -o-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
        } 
    }
    
    @keyframes rotatingVento {
        0% {
            -ms-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -moz-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -webkit-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -o-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
        } 
        50% {
            -ms-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -moz-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -webkit-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -o-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[1] === 'N' ? 540-49 : props.direcaoVento.split('-')[1] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[1] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[1] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[1] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[1] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[1] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[1] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
        }
        100% {
            -ms-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -moz-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -webkit-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            -o-transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
            transform: ${props => props.direcaoVento ? `rotate(${props.direcaoVento.split('-')[0] === 'N' ? 540-49 : props.direcaoVento.split('-')[0] === 'NE' ? 540-49+45 : props.direcaoVento.split('-')[0] === 'E' ? 540-49+90 : props.direcaoVento.split('-')[0] === 'SE' ? 540-49+135 : props.direcaoVento.split('-')[0] === 'S' ? 540-49+180 : props.direcaoVento.split('-')[0] === 'SW' ? 540-49+255 : props.direcaoVento.split('-')[0] === 'W' ? 540-49+270 : props.direcaoVento.split('-')[0] === 'NW' ? 540-49+315 : 540-49}deg)` : ''};
        } 
    }
    

    img {
        /* -webkit-animation: rotatingVento 2.7s linear infinite;
        -moz-animation: rotatingVento 2.7s linear infinite;
        -ms-animation: rotatingVento 2.7s linear infinite;
        -o-animation: rotatingVento 2.7s linear infinite;
        animation: rotatingVento 2.7s linear infinite; */
    }

`;

export const AreaInfo = styled.div`
    width: ${props => props.width ? props.width : '95%'};
    padding: 5px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'flex-start'};
    align-items: center;
`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 80px;
  height: 80px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;


  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;

export const ChartArea = styled.div`
    width: 100%;
    height: auto;
    min-height: 100px;

    .text-alert p{
        margin-top: 19px;
        margin-bottom: 0px;
        font-size: 11.5px;
        text-align: justify;
    }
`;

export const PrevPage = styled.div`
    cursor: pointer !important;
    font-size:20px;
    background: #FFF;
    width: 30px;
    height: 30px;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        margin: 0px;
        margin-top: -2px;
        font-weight: 700;
    }
    p::selection{
        cursor: pointer !important;
        background: transparent;
    }
`;

export const NextPage = styled.div`
cursor: pointer !important;
    font-size:20px;
    background: #FFF;
    width: 30px;
    height: 30px;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    border-radius: 5px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    p {
        margin: 0px;
        margin-top: -2px;
        font-weight: 700;
    }
    p::selection{
        cursor: pointer !important;
        background: transparent;
    }
`;

export const CarouselStyle = styled.div`
@font-face{
    font-family:"revicons";
    fallback:fallback;
    src:url("./revicons.woff") format('woff'),url("./revicons.ttf") format('ttf'),url("./revicons.eot") format('ttf')
}
.react-multi-carousel-list{
    display:flex;
    align-items:center;
    overflow:hidden;
    position:relative
}
.react-multi-carousel-track{
    list-style:none;
    padding:0;
    margin:0;
    display:flex;
    flex-direction:row;
    position:relative;
    transform-style:preserve-3d;
    backface-visibility:hidden;
    will-change:transform,transition
}
.react-multiple-carousel__arrow{
    display: none;
    position:absolute;
    outline:0;
    transition:all .5s;
    border-radius:50%;
    z-index:1000;
    border:0;
    background:rgba(0,0,0,0.2);
    width:25px;
    height:25px;
    opacity:1;
    cursor:pointer
}
.react-multiple-carousel__arrow:hover{
    background:rgba(0,0,0,0.5)
}
.react-multiple-carousel__arrow::before{
    font-size:20px;
    color:#fff;
    display:block;
    font-family:revicons;
    text-align:center;
    z-index:2;
    position:relative
}
.react-multiple-carousel__arrow:disabled{
    cursor:default;
    background:rgba(0,0,0,0.1)
}
.react-multiple-carousel__arrow--left{
    left:calc(4% + 1px)
}
.react-multiple-carousel__arrow--left::before{
    content:"<";
    font-size: 20px;
    margin: -3px 2px 0px 0px;
}
.react-multiple-carousel__arrow--right{
    right:calc(4% + 1px)
}
.react-multiple-carousel__arrow--right::before{
    content:">";
    font-size: 20px;
    margin: -3px 0px 0px 2px;
}
.react-multi-carousel-dot-list{
    position:absolute;
    bottom:0;
    display:flex;
    left:0;
    right:0;
    justify-content:center;
    margin:auto;
    padding:0;
    margin:0;
    list-style:none;
    text-align:center;
}
.react-multi-carousel-dot button{
    display:inline-block;
    width:12px;
    height:12px;
    border-radius:50%;
    opacity:1;
    padding:5px 5px 5px 5px;
    box-shadow:none;
    transition:background .5s;
    border-width:2px;
    border-style:solid;
    border-color:grey;
    padding:0;
    margin:0;
    margin-right:6px;
    outline:0;
    background:rgba(0,0,0,0.1);
    cursor:pointer;
}
.react-multi-carousel-dot button:hover:active{
    background:rgba(0,0,0,0.1)
}
.react-multi-carousel-dot--active button{
    background:rgba(0,0,0,0.3)
}
.react-multi-carousel-item{
    transform-style:preserve-3d;
    backface-visibility:hidden;
}

.row-2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

}

.text{
    color: #000;
    font-size: 15px;
    margin-left: 10px;

}

.min-max{
    color: #000;
    font-size: 25px;
    font-weight: bold;

}

.resumo{
    width: 100%;
    color: #000;
    font-size: 12px;
    text-align: center;
    margin-top: 8px;
}

.umidade{
    color: #000;
    font-size: 12px;
    text-align: center;
}

.vento{
    color: #000;
    font-size: 12px;
    text-align: center;
    margin-top: 8px;
}

.umidade-vento{
    /* margin: 0px 34px 0px 0px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){
    .react-multi-carousel-item{
        flex-shrink:0 !important
    }
    .react-multi-carousel-track{
        overflow:visible !important
    }
}
[dir='rtl'].react-multi-carousel-list{
    direction:rtl
}
.rtl.react-multiple-carousel__arrow--right{
    right:auto;
    left:calc(4% + 1px)
}
.rtl.react-multiple-carousel__arrow--right::before{
    content:"<"
}
.rtl.react-multiple-carousel__arrow--left{
    left:auto;
    right:calc(4% + 1px)
}
.rtl.react-multiple-carousel__arrow--left::before{
    content:">"
}

`;