import styled from "styled-components";
import iconCalendar from "../../assets/icons/icons8-calendário-16.png"

export const H3 = styled.h3`
    font-size: 18px;
    margin-bottom: 20px;
`;

export const Label = styled.label`
    padding-left: 15px;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    color: #8a92a2;
    margin-top: 20px;
`;

export const FilterArea = styled.div`
    width: 50%;
    display: flex;
    flex-direction: row;
    /* justify-content: flex-start; */
    align-items: flex-start;

    #labelcultura {
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      letter-spacing: normal;
      z-index: 1;
      opacity: 1;
      position: absolute;
      color: #8a92a2;
      margin-left: 23px;
      margin-top: 6px;
      background-color: #FFF;
    }
      
    #multiselectContainerReact{
      margin: 47px 0px 0px 0;

      .searchWrapper {
        border-radius:  25px !important;
        margin-top: -34px;
      }

      .searchBox{
        margin: 14px 0 0px 10px;
        background-color: #FFF;
      }      

      span{
        background: #00b43f;
        width: auto;
      }

      background: white;
      max-width: 220px;
      border-radius: 25px;
      
    
    }
    @media only screen and (max-width: 1285px){
      flex-direction: column !important;
      width: 100%;
      .alvosPorCultura{
        min-height: 500px !important;
      }
      #multiselectContainerReact{
        width: 256px;
      }
      .culturasAlv {
        width: 285px !important;
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
      }

      .eItXMf #multiselectContainerReact span {
        margin: 3px !important;
      }

      .searchBox {
        margin: 5px 0 6px 5px !important;
      }

      .optionListContainer{
        z-index: 3;
      }
    }
`;

export const SelectArea = styled.div`
    min-width: 80%;
    margin-left:10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;

    @media only screen and (max-width: 1285px){
      margin-left:0px;
      .dKWSnq{
        margin-top: 15% !important;
        margin-left: -28%;
        max-width: 269px;
      }
      .bPpYOh{
        margin-top: 15% !important;
        margin-left: -28%;
        max-width: 269px;
      }
    }
`;

export const ProdutosNome = styled.div`
  font-size: 15px;
  margin-top: 6px;
  margin-left: 15px;
`;

export const AreaTratada = styled.div`
    margin-top: 20px;
`;

export const IconeBotao = styled.div`
    display: flex;
    justify-content: space-between

    .pdf{
      #msgBaixarPdf{
        display: none;
      } 
  
      &:hover{
        #msgBaixarPdf{
            transform: scale(1.1);
            display: flex;
            justify-content: center;
            position:absolute;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            align-items: center;
            color:#FFF;
            background-color: #1d2129;
            width: 80px !important;
            height: 18px;
            opacity: 0.9;
            border-radius: 5px;
            margin-top: -17px;
            margin-left: -33px;
  
            &:after{
                display:flex;
                position: absolute;
                background-color:  #1d2129;
                width: 6px;
                height: 6px;
                top: 14px;
                margin-left: 0px;
                content: '';
                transform: rotate(45deg);
            }
        }
      }
    }

    .xlsx{
      #msgBaixar{
        display: none;
      } 
  
      &:hover{
        #msgBaixar{
            transform: scale(1.1);
            display: flex;
            justify-content: center;
            position:absolute;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            align-items: center;
            color:#FFF;
            background-color: #1d2129;
            width: 80px !important;
            height: 18px;
            opacity: 0.9;
            border-radius: 5px;
            margin-top: -42px;
            margin-left: -30px;
  
            &:after{
                display:flex;
                position: absolute;
                background-color:  #1d2129;
                width: 6px;
                height: 6px;
                top: 14px;
                content: '';
                transform: rotate(45deg);
            }
        }
      }
    }
`;

export const DataFilter = styled.div`
    width: 100%;
    height: auto;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .input-data-filter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .input-date{
            border: none;
            border-right: 1px;
            background-color: #f3f3f5;
            padding-right: 25px;
            height: 40px;
        }

        .input-date + .input-date{
            border-left: solid 1px #dfe4eb;
            padding-left: 25px;
            padding-right: 0px;
        }

        input[type="date"]::-webkit-calendar-picker-indicator{
            color: rgba(0, 0, 0, 0);
            opacity: 1;
            display: block;
            background: url(${iconCalendar}) no-repeat;
            border-width: thin;
            margin-top: 5px;
        }

        input[type="date"]::-webkit-inner-spin-button{
            margin-top: 8px;
        }

    }

`;

export const ChartArea = styled.div`
    width: 100%;
    height: auto;
    min-height: 100px;
    margin-top: 10px;
    padding-left: 5px;
`;


export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 80px;
  height: 80px;
  position: relative;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;


  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;

