import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import api from '../../services/api';
import googlemaps from '../../services/googlemaps';
import Exclamation from '../../assets/exclamation.svg';
import ok from '../../assets/ok.svg';
import Cancelar from '../../assets/cancelar.svg';
import { apiprevmet3 } from '../../services/inmet_api';
import { AreaInfo, Card, ChartArea, Col, Col2, Col5, Loading, Row, Text, Title, LineBox, TextDirection } from './style';
import { Line } from 'react-chartjs-2';
import { formatPrevisaoGrafico } from '../../services/format';
export const AGRORECEITA_USER_INFO_LOCATION = '@agroreceita_user_info_location';

const AlertClima = ({ ...props }) => {

    const { ibge } = props;

    const [dadosAlerta, setDadosAlerta] = useState(false);
    const [mensagemAlerta, setMensagemAlerta] = useState('');
    const [iconeAlerta, setIconeAlerta] = useState('');
    const [showChartResumo, setShowChartResumo] = useState(false);
    const [loading, setLoading] = useState(false);

    const getPrevisaoTempo = async (ibge) => {
        try {
            setLoading(true)
            const response = await apiprevmet3.get(`/previsao/${ibge}`);

            if (response) {
                setLoading(false)
                let prev = response.data;
                const p = formatPrevisaoGrafico(prev[ibge]);
                getAlerta(ibge, p);
                setShowChartResumo(true);
            }
        } catch (error) {
            setLoading(false)
            console.log(error);
            // store.addNotification({
            //     title: "Erro!",
            //     message: "Erro INMET",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }

    const getAlerta = async (ibge, p) => {
        try {
            const data = await apiprevmet3.get(`/estacao/proxima/${ibge}`);

            const response = await api.post(`/alertas`, {
                previsao: {
                    CHUVA: data.data.dados.CHUVA,
                    VEN_VEL: data.data.dados.VEN_VEL,
                    TEM_MIN: p.tmp_min[0],
                    TEM_MAX: p.tmp_max[0],
                    UMD_MIN: p.umidade_min[0],
                    UMD_MAX: p.umidade_max[0],
                }
            });

            if (response.data.data) {
                setMensagemAlerta(response.data.data.alerta);
                setIconeAlerta(response.data.data.icone);
            }

        } catch (error) {
            setDadosAlerta(true);
            // store.addNotification({
            //     title: "Erro!",
            //     message: "Erro INMET",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }


    useEffect(() => {
        getPrevisaoTempo(ibge);
    }, [ibge])

    return (
        <>
            <ChartArea>
                {loading ? (
                    <Loading/>
                ) : (
                    <>
                        {
                            mensagemAlerta !== "" && iconeAlerta != "" ? (
                                <>
                                    <div className='img-alert' style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}>
                                        <img width={'50px'} src={iconeAlerta == "warning" ? Exclamation : iconeAlerta == "ok" ? ok : Cancelar} />
                                    </div>
                                    <div className='text-alert'>
                                        <p>{mensagemAlerta}</p>
                                    </div>
                                </>
                            ) : dadosAlerta == true ? (
                                <>

                                    <div className='text-alert'>
                                        <p><b>Erro ao mostrar o alerta!</b></p>
                                        <p>O servidor do INMET não está retornando as informações equivalentes a esse local.</p>
                                    </div>
                                </>
                            ) : (<><p style={{ width: "100%", height: "100%", paddingTop: "80px", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", fontSize: "12px", color: "#8A92A2", textAlign: "center" }}>Não foi possível carregar os dados. Aguarde alguns minutos e tente recarregar a página.</p></>)
                        }
                    </>
                )}
            </ChartArea>
        </>
    )
}

export default AlertClima;
