import styled from "styled-components";

export const UpperContainer = styled.div`
  height: 70px;
  background: ${props => props.background || "#21262f"};
  background-size: cover;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  display: flex;

  .iconsLogo {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .logo{
      width: 200px;
      position: absolute;
      top: 20px;
      left: 20px;
      img{
          width: 150px;
      }
    }

    .faq{
      position: absolute;
      top: 25px;
      right: 50px;
      a{
          text-decoration: none;
          font-family: Lato;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #dfe4eb;
      }
    }
  }

  @media only screen and (max-width: 780px) {
    height: 90px;
  }
`;

export const ContainerNew = styled.div`
  width: ${props => props.isTest == true || props.isPrePago == true ? '93vw' : '79vw'};
  text-align: ${props => props.align || "center"};
  display: flex;
  flex-direction: row;
  align-items: ${props => props.search && "center"};
  justify-content: ${props => props.search || (props.isTest == true || props.isPrePago == true) ? 'flex-start' : 'center'};
  margin: auto;
  small {
    padding: 10px;
    font-size: 14px;
    margin-top: 20px;
    color: #808080;

    a{
      text-decoration: none;
      cursor: pointer;
      font-weight: 700;
      color: #589bff;

      &:hover {
      }

    }
  }

  @media only screen and (max-width: 979px) {
    flex-direction: column;
    margin-top: 30px;
  }

  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    margin-top: ${props => props.search ? '80px' : '30px'};
  }

  @media only screen and (max-width: 540px) {
    margin-top: ${props => props.hideFilter == false && props.search ? "120px" : props.search && '100px'};
    width: 90%;
  }
`;

export const SearchField = styled.div`
  margin-left: 10px;
  height: 37px;
  border-radius: 25px;
  box-shadow: 0px 0px 10px -1px black;
  width: 300px;
  color: white;
  background-color: #fff;
  border: none;
  padding: 0px 2px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0px;
  
  @media only screen and (max-width: 900px){
    margin-left: 0;
    width: 327px;
    border-radius: 40px;
  }

  @media only screen and (max-width: 540px){
    margin-bottom: 20px;
    border-radius: 20px;
  }

  @media only screen and (max-width: 340px){
    width: 100%;
  }
`;

export const Titulo = styled.h1`
  text-align: ${props => (props.search && props.isMobile == false ? 'left' : 'center')};
  color: rgb(255, 255, 255);
  font-weight: 800;
  font-size: 22px;
  margin-right: 24px;
  margin-bottom: 0px;

  i{
    margin-left: 4px;
  }
  
  @media only screen and (max-width: 900px){
    font-size: 18px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
    font-size: 18px;
    width: 100%;
    margin-top: ${props => props.hideFilter == false && props.search ? '40px' : props.search && props.estados ? '60px' : props.search && '45px'};
  }
  @media only screen and (max-width: 540px) {
    margin-right: 0px;
    margin-top: ${props => props.estados ? '56px' : props.search && props.hideFilter == false ? '30px' : props.search  ? '16px' : '12px'};
  }

`;

export const ExportButton = styled.div`
  height: 37px;
  width: 37px;
  background-color: #2fbb69;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px -1px black;
  cursor: pointer;
  font-size: 10px;
  color: white;

  img{
    width: 12px;
  }

  &:hover a{
    display:block;
  }

  p{
    margin-bottom: 0px;
    cursor: pointer;
  }

  a{
    width: 84px;
    height: 22px;

    display:none;
    position:absolute;
    align-items: center;

    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    
    margin-top: 38px;
    margin-left: -7px;
    
    padding-top: 2px;
    
    color:#FFF !important;
    background-color: #1d2129;
    opacity: 0.9;
    
    border-radius: 5px;

    &:before{
      position: absolute;
      background-color:  #1d2129;
      width: 8px;
      height: 8px;
      top: -4px;
      content: '';
      transform: rotate(45deg);
    }

    &:hover{
      text-decoration: none;
      p{
          display: block;
      }
    }
  }

  @media only screen and (max-width: 900px){
   height: 30px; 
   width: 33px;

  }

`;

export const AddButton = styled.div`
  height: 37px;
  width: 104px;
  background-color: #2fbb69;
  display: flex;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px -1px black;
  cursor: pointer;
  font-size: 10px;
  color: white;

  img{
    width: 12px;
  }

  &:hover a{
    display:block;
  }

  p{
    margin-bottom: 0px;
    cursor: pointer;
  }

  a{
    width: 84px;
    height: 22px;

    display:none;
    position:absolute;
    align-items: center;

    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    
    margin-top: 38px;
    margin-left: -7px;
    
    padding-top: 2px;
    
    color:#FFF !important;
    background-color: #1d2129;
    opacity: 0.9;
    
    border-radius: 5px;

    &:before{
      position: absolute;
      background-color:  #1d2129;
      width: 8px;
      height: 8px;
      top: -4px;
      content: '';
      transform: rotate(45deg);
    }

    &:hover{
      text-decoration: none;
      p{
          display: block;
      }
    }
  }

  @media only screen and (max-width: 900px){
   height: 30px; 
   width: 76px;

  }

  @media only screen and (max-width: 768px){
    display: none;
  }

`;

export const Desmarc = styled.div`
  width: 100vw;
  height: 104vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  background-color: #21262f6e;
`;

export const Search = styled.div`
  margin-top: 0vh;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 900px){
    margin-top: 1vh;
    justify-content: space-between;
    padding-bottom: 17px;
  }

  @media only screen and (max-width: 768px){
    margin-top: 0vh;
    width: 100%;
  }

  @media only screen and (max-width: 540px){
    flex-direction: column;
    margin-top:0px;
    padding-bottom: 0px;

    .buttons{
      margin-top: 16px;
    }
  }
`;

export const ExportOptions = styled.div`
  position: absolute;
  background-color: #FFF;
  width: 130px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 20px 0 rgb(29 33 41 / 15%);
  padding: 15px;
  margin-left: 8px;
  margin-top: 3px;
  z-index: 2;

  &:before{
    position: absolute;
    background-color:  #fff;
    width: 8px;
    height: 8px;
    top: -4px;
    content: '';
    transform: rotate(45deg);
  }

  .option{
    width: 100%;
    height: 36px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
      font-family: Lato;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8a92a2;
      cursor: pointer;
      margin-bottom: 0px;
    }

    &:hover{
      background-color: #f3f3f5;
      font-weight: bold;
      cursor: pointer;
    }
  }

`;

export const InputField = styled.input`
  height: 100%;
  width: 80%;
  background: transparent;
  border: none;
  color: black;
  font-size: 10px;
  z-index: 1;
`;

export const HeaderCompendioGratis = styled.div`
    background: url("https://www.agroreceita.com.br/wp-content/uploads/2020/09/símbolo-branco-1.png") center center no-repeat fixed;
    background-color: rgb(41, 46, 56);
    background-size: cover;
    background-position: 0px -20px;
    width: 100vw;
    height: 50vh;

    display: flex;
    flex-direction: column;
    justify-content: start;

    .header{
        width: 90%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 80px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo{
            img{
                width: 150px;
            }
        }

        .links{
            margin-left: 17px;
            a{
                font-family: "Lato", Sans-serif;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                margin-left: 60px;
                fill: #fff;
                color: #fff;
                background-color: #fff0;
                border-radius: 50px 50px 50px 50px;
                padding: 10px 12px 10px 12px;
                text-decoration: none;
                text-align: auto;
                transition: all 0.3s;
            }

            a:hover{
                background-color: #FFFFFF2B;
                
            }
        }

        .planos{
            #plans{
                font-family: "Lato", Sans-serif;
                font-size: 10px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                fill: #fff;
                color: #fff;
                background-color: #fff0;
                border-style: solid;
                border-width: 2px 2px 2px 2px;
                border-color: #fff;
                border-radius: 50px 50px 50px 50px;
                padding: 14px 14px 14px 14px;
                text-decoration: none;
                transition: all 0.3s;

                &:hover{
                    color: #21262F;
                    background-color: #fff;
                    border-color: #fff;
                }
            }

            #teste{
                display: inline-block;
                font-family: "Lato", Sans-serif;
                font-size: 11px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 2px;
                background-color: #2FBB69;
                border-style: solid;
                border-color: #2FBB69;
                border-radius: 50px 50px 50px 50px;
                padding: 17px 22px 17px 22px;
                text-decoration: none;
                transition: all 0.3s;
                color: #FFF;

                margin-left: 20px;

                &:hover{
                    transform: scale(1.1);
                }

            }
        }
    }

    .title{
        width: 100%;
        h1{
            color: #FFF;
            text-align: center;
            font-family: "Lato", Sans-serif;
            font-size: 28px;
            font-weight: 700;
            line-height: 34px;
        }
        h2{
            text-align: center;
            color: #BAC1CF;
            font-family: "Lato", Sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 29px;
        }
    }

`;

export const Plans = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  top:  ${props => props.isMobile && '29px'};
  left: ${props => props.isMobile && '60px'};
  transform: ${props => props.isMobile ? 'scale(0.8)' : 'scale(1.0)'};
  width: auto;
  height: 30px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(223, 228, 235);
  border-radius: 15px;
  background-color: rgb(88, 155, 255);
  padding-top: 9px;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 16px;
  cursor: pointer;
  z-index: 4;
  transition: all 0.2s ease-out;

  
  &:hover {
    background-color: rgb(88, 155, 255);
    transform: scale(1.06);
    box-shadow: 2px 2px 3px 1px rgba(88, 155, 255, 0.2);
  }

  @media only screen and (max-width: 979px){
    position: absolute;
    left: 56px;
  }

  @media only screen and (max-width: 760px){
    display: none;
  }
`;