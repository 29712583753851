import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import GenericUpper from "../../components/generic-upper";
import Button from "../../components/button";
import {ContainerNotificacao} from "./styles";
import CardNotificacao from "../../components/card-notificacao/index";
import ImageNotFound from '../../assets/not_found.svg';

const Notificacao = ({...props}) => {

    const [not, setNot] = useState([]);
    const [limit] = useState(5);
    const [offset, setoffset] = useState(0);
    const [hidebutton, setHidebutton] = useState(false);
    const [loading, setLoading] = useState(true);


    const getNotifications =  async ()=>{
        try {
            const response = await api.get("/notificacoes?limit="+limit+"&offset="+offset);
            const varia = await response.data.data;
            
            if (varia && varia.length > 0) {
                setNot(varia);
                setLoading(false);
                setHidebutton(false);
            } else {
                if (varia === false) {
                    setNot(false);
                }else{
                    setNot([varia]);
                }
                setLoading(false);
                setHidebutton(false);
            }
            
        } catch (error) {
        }
    }

    useEffect(()=>{
        getNotifications();
        
    },[])

    const loadmore = async ()=>{
            setLoading(true);
            setHidebutton(true);
            const newoffset = limit + offset;
    
            try {
                const response = await api.get("/notificacoes?limit="+limit+"&offset="+newoffset);
                const newvaria = await response.data.data;
               
                if (newvaria === false) {
                    setNot(false);
                }else{
                    const novasnot = not.concat(newvaria);
                    setNot(novasnot);
                }
                setoffset(newoffset);
                setHidebutton(false);
            } catch (erro) {
                setHidebutton(true);
            }
            
            setLoading(false);
        
    }


    useEffect(()=>{ console.log(not)},[not])
    return(
       <>
       <GenericUpper
                    title="Notificações"
                    subtitle="Noticias, novidades e alertas"
        ></GenericUpper>
       {not ? (
        <ContainerNotificacao>
            <div className="cardes">
                {not.map(n=>(

                    <CardNotificacao
                        visu={n.view}
                        id={n.agro_notification.id}
                        titulo_notificacao={n.agro_notification.titulo_notificacao}
                        data={n.agro_notification.data}
                        texto={n.agro_notification.texto}
                    >
                    </CardNotificacao>
                ))}
            <div className="seemore">    
            <Button
                style={{outline:0}} 
                    className="loadmore"
                    hide={hidebutton}
                    outline
                    onClick={loadmore}
                    color="#00b43f"
                    >
                    Carregar mais
            </Button>
            </div>
                </div>
        </ContainerNotificacao>
       ) : (
            <>
                <div className="loading" style={{
                        width: '100%',
                        height: '25vh',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                    <h4 style={{ textAlign: 'center' }}>Nenhuma notificação encontrada</h4>
                </div>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <img src={ImageNotFound} width="300" />
                </div>
            </>
       )}
       </>
    )
}

export default Notificacao;