import React, { useEffect, useState } from "react";
import { Container } from "react-grid-system";
import { ScreenClassRender } from "react-grid-system";
import { withRouter } from "react-router-dom";
import { store } from "react-notifications-component";
import api from "../../services/api";
import ReactHtmlParser from 'react-html-parser';
import Header from "../../components/header-home";
import TotalReceitasEmitidas from "../../components/relatorios/total-receitas-emitidas"
import LastPrescription from "../../components/last-prescription";
import LastPrescriptionError from "../../components/last-prescription/error-prescription";
import NewsCard from "../../components/news-home";
import Notification from "../../components/notification";
import ModalTesteTerminaHj from '../../components/modal/modalTesteGratis';
import ModalTermos from '../../components/modal/modalTextCheckbox';
import ModalGeneric from '../../components/modal/modalGeneric';
import BlackNovember from '../../assets/black-november.svg';
import Close from '../../assets/icons/close-white.svg';

import { Grid, Col, Row1, Row, AreaHome } from "./styles";
import Week from "../../components/clima/week";
import ResumoHoje from "../../components/clima/resumo_hoje";
import ResumoCrm from "../../components/crm/resumo/index"
import ResumoAgenda from "../../components/agenda/resumo"
import CardRight from "../../components/cardRight";

import IMG1 from '../../assets/Compendio agricola.svg';
import Welcome from '../../assets/welcome.svg';
import IMG2 from '../../assets/Solicitar produto.svg';
import IMG3 from '../../assets/report.svg';
import CardNewsHome from "../../components/news-home/card";
import Button from "../../components/button/button_v2";

export const USER = "@userinfo";
export const TERM = "@terms";
export const titulo = "@titulo";
export const open = "@open";
export const logged = '@logged';

function Home(props) {
    const [screenSize, setScreenSize] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [location, setLocation] = useState(null);
    const [receitas, setReceitas] = useState([]);
    const [news, setNews] = useState([]);
    const [limit, setLimit] = useState(1);
    const [isPrescription, setIsPrescription] = useState(false);
    const [redirect, setRedirect] = useState("");
    const [title1, setTitle1] = useState("");
    const [link1, setLink1] = useState("");
    const [date1, setDate1] = useState("");
    const [image1, setImage1] = useState("");
    const [user, setUser] = useState("");
    const [notification, setNotification] = useState([]);
    const [showTesteHj, setShowTesteHj] = useState(false);
    const [contTesteHj, setContTesteHj] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [subtitulo, setSubtitulo] = useState('');
    const [userValidade, setUserValidade] = useState('');
    const [showTerms, setShowTerms] = useState(false);
    const [showUpdateTerms, setShowUpdateTerms] = useState(false);
    const [prazoValidade, setPrazoValidade] = useState(false);
    const [showModalHomolog, setShowModalHomolog] = useState(false);
    const [msgHomologError, setMsgHomologError] = useState('');
    const [showModalLogged, setShowModalLogged] = useState(false);
    const [showModalAgronomos, setShowModalAgronomos] = useState(false);
    const [isRenderComponent, setIsRenderComponent] = useState(false);
    const [produtoresGeral, setProdutoresGeral] = useState(false);
    const [agronomos, setAgronomos] = useState(false);
    const [crea, setCrea] = useState(false);
    const [art, setArt] = useState(false);
    const [estado, setEstado] = useState("");
    const [contratos, setContratos] = useState(false);
    const [artId, setArtId] = useState(0);
    const [semReceitas, setSemReceitas] = useState(false);
    const [produtores, setProdutores] = useState(false);
    const [prodId, setProdId] = useState(0);
    const [fazendas, setFazendas] = useState(false);
    const [devolucao, setDevolucao] = useState(false);
    const [userConfig, setUserConfig] = useState([]);

    // const [modalShowPromo, setModalShowPromo] = useState(false);

    if (localStorage.getItem("lastAttemptedPath")) {
        const lastPath = localStorage.getItem("lastAttemptedPath");

        if (lastPath.includes('utm_source')) {
            let url = new URL(lastPath);

            let queryString = url.search;

            localStorage.setItem("utm_compra", queryString.substring(1))
        }

        localStorage.removeItem("lastAttemptedPath");

        window.location.href = lastPath;
    }

    const [loadingPrescription, setLoadingPrescription] = useState(true);

    const modalClose3 = () => {
        setShowTesteHj(false);
    }

    const closeTerms = () => {
        setShowTerms(false);
        setShowUpdateTerms(false);

        let term = JSON.parse(localStorage.getItem(TERM));

        if (term == 0) {
            props.history.push('/login');
        }
    }

    const updateTerms = async (resposta) => {
        try {
            let user = JSON.parse(localStorage.getItem(USER));

            const payload = {
                username: user.username,
                termos: resposta,
                type: 1
            }
            const response = await api.post(`/termos`, payload);

            localStorage.setItem(TERM, JSON.stringify(1));

            closeTerms();

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const checkHomolog = async () => {
        try {
            let userInfo = JSON.parse(localStorage.getItem(USER));

            const response = await api.get(`/homolog/check/${userInfo.id}`);

            if (response.data.message === 'Ok') {
            }
        } catch (error) {
            setShowModalHomolog(true);
            setMsgHomologError(error.response.data.errors);
        }
    }

    const inicializa1 = () => {
        let val = localStorage.getItem("@validade");

        localStorage.setItem("@agroreceita:is_user", true);

        setUserValidade(val);
        getReceitas();
        getUser();
        getNotification();


        getCadastros();

        // let userInfo = JSON.parse(localStorage.getItem(USER));
        // if (
        //     userInfo.contrato.agro_plan_config.agro_plan_id == 4 || 
        //     userInfo.contrato.agro_plan_config.agro_plan_id == 1
        // ) {
        //     setModalShowPromo(true);
        // }
    }

    useEffect(() => {
        if (!isRenderComponent) {
            setIsRenderComponent(true);
            inicializa1();

            let terms = JSON.parse(localStorage.getItem(TERM));
            let user = JSON.parse(localStorage.getItem(USER));

            setUserConfig(user);

            if (terms == 0 && user.contrato.agro_plan_config.is_teste != 1) {
                setShowTerms(true);
            } else if (terms == 2) {
                setShowUpdateTerms(true);
            }
        }
    }, [isRenderComponent]);

    const getNotification = async () => {
        try {
            const response = await api.get("/notificacoes/home");

            let notificacao = response.data.data;
            if (notificacao) {
                const b = notificacao.data.split('-');
                const ano = b[0];
                const mes = b[1];
                const dia = b[2].substr(0, 2);
                notificacao.data = `${dia}/${mes}/${ano}`
            }

            setNotification(notificacao);
        } catch (error) {
        }
    }

    const getUser = async () => {
        const response = await api.get('/session_ticket');

        setUser(response.data.data[0]);
    }

    const getReceitas = async () => {
        try {
            const response = await api.get("/receitas?limit=1&offset=0");

            const resultados = response.data.data.length;
            if (resultados != 0) {
                const receita = response.data.data;

                localStorage.setItem("@agroreceita:exist_prescription", true);
                setReceitas(receita[0]);
                setIsPrescription(true);
                setLoadingPrescription(false);
            }
        } catch (error) {
            localStorage.setItem("@agroreceita:exist_prescription", false);
            setSemReceitas(true);
            setLoadingPrescription(false);

        }
    };

    const getCadastros = async () => {
        try {
            const response = await api.get("/cadastrosbasicos");
            if (response) {
                setEstado(response.data.data.estado);
                setTimeout(() => {
                    setAgronomos(response.data.data.agronomos);
                    setProdutoresGeral(response.data.data.produtoresGeral);
                    setCrea(response.data.data.crea);
                    setArt(response.data.data.art);
                    setArtId(response.data.data.artId);
                    setContratos(response.data.data.contrato);
                    setDevolucao(response.data.data.devolucao);
                    setFazendas(response.data.data.fazendas);
                    setProdId(response.data.data.prodId);
                    setProdutores(response.data.data.produtores);
                }, 100);
            }

        } catch (error) {
        }
    };

    const getScreenSize = screenSize => {
        const sizesToHide = ["xl", "lg"];
        const hide = sizesToHide.includes(screenSize);
        const prevScreenSize = screenSize;

        if (prevScreenSize !== screenSize) {
            if (hide) {
                setScreenSize(screenSize);
                setIsMobile(false);
            } else {
                setScreenSize(screenSize);
                setIsMobile(true);
            }
        }
    };

    const redirectTo = location => {
        props.history.push(location);
    };



    return (
        <ScreenClassRender
            render={screenClass => (
                <>
                    {titulo && userValidade == 2 || (titulo && userValidade == 3) ? (
                        <ModalTesteTerminaHj
                            show={showTesteHj}
                            onHide={modalClose3}
                            title={titulo}
                            subtitle={subtitulo}
                            hoje={true}
                        >
                        </ModalTesteTerminaHj>
                    ) : (null)}

                    {userValidade < 0 ? (
                        <ModalTesteTerminaHj
                            show={showTesteHj}
                            onHide={() => modalClose3()}
                            title="Seu teste grátis terminou..."
                            subtitle="Assine o Agroreceita para não perder os recursos abaixo:"
                            hoje={true}
                        >
                        </ModalTesteTerminaHj>

                    ) : (null)}

                    {/* {modalShowPromo && (
                        <ModalGeneric
                            show={modalShowPromo}
                            removeHeader={true}
                            onHide={() => setModalShowPromo(false)}
                            backgroundColor='#1D2129'
                            responsive={true}
                            width={'600px'}
                            marginBottom={'0px'}
                        >
                            <div className="msg-promo">
                                <div className="logo">
                                    <img src={BlackNovember} />
                                </div>
                                <div className="texto">
                                    <p>Utilize o cupom "<b>AGROBLACK</b>" e garanta </p>
                                    <p><b>20% de desconto</b> nas 12 primeiras mensalidades!</p>
                                </div>
                            </div>

                            <div className="dois-cards">

                                <div className="card">
                                    <h6>Plano Light</h6>
                                    <p className="valor-antigo">R$ 139</p>
                                    <p className="valor-novo">R$ 111</p>
                                    <p className="tipo">mensais/usuário</p>

                                </div>
                                <div className="card">
                                    <h6>Plano Pro</h6>
                                    <p className="valor-antigo">R$ 189</p>
                                    <p className="valor-novo">R$ 151</p>
                                    <p className="tipo">mensais/usuário</p>


                                </div>
                            </div>

                            <div className="validade-promo">
                                <p>Promoção válida até 30 de novembro.</p>
                            </div>

                            <ButtonV2 
                                width='55%'
                                height='50px'
                                value='GARANTA DESCONTO'
                                outline={false}
                                disabled={false}
                                loading={false}
                                onClick={() => window.location = '/planos?utm_source=sistema&utm_medium=modal&utm_campaign=black-november-23&cupom=AGROBLACK'}
                                margin={'16px 0px'}
                            />

                            <div className="close-icon" onClick={() => setModalShowPromo(false)}>
                                <img src={Close} />
                                <p>Não quero desconto</p>
                            </div>
                        </ModalGeneric>

                    )} */}

                    {getScreenSize(screenClass)}

                    <Header title={user.name}>  </Header>

                    <div style={{ margin: '8px' }}>
                        <Grid>
                            <AreaHome width='90%' widthResp='100%'>
                                {
                                    props.isMobile && (
                                        <Row justifyContent='space-between'>
                                            <Col width='100%' margin='0px'>
                                                <Button
                                                    width='100%'
                                                    height='50px'
                                                    value='NOVA RECEITA'
                                                    outline={false}
                                                    disabled={false}
                                                    loading={false}
                                                    onClick={() => window.location = "/receitas/add"}
                                                />
                                            </Col>
                                        </Row>
                                    )
                                }
                                <Row justifyContent='space-between'>
                                    <Col width='66%' margin='0px'>
                                        {isPrescription === false && semReceitas === true ? (
                                            <LastPrescriptionError
                                                isMobile={isMobile}
                                                agronomos={agronomos}
                                                crea={crea}
                                                art={art}
                                                contrato={contratos}
                                                estado={estado}
                                                artId={artId}
                                                produtores={produtores}
                                                fazendas={fazendas}
                                                prodId={prodId}
                                                devolucao={devolucao}
                                                produtoresGeral={produtoresGeral}
                                            />
                                        ) : (
                                            <LastPrescription
                                                nomeProduto={`${receitas.nome_comercial}`}
                                                nomePraga={`${receitas.praga_nome_comum}`}
                                                nomeCultura={`${receitas.cultura_nome_comum}`}
                                                nomeProdutor={`${receitas.produtor_nome}`}
                                                local={`${receitas.local_aplicacao_nome}`}
                                                nomeTecnicoResponsavel={`${receitas.agronomo_nome}`}
                                                crea={`${receitas.agronomo_crea}`}
                                                art={`${receitas.numero_art}`}
                                                status={receitas.status}
                                                produtoresGeral={produtoresGeral}
                                                loadingPrescription={loadingPrescription}
                                            />
                                        )}

                                    </Col>

                                    <Col width='32%' alignItems='flex-end' alignSelf='stretch' margin='0px'>
                                        {notification ? (
                                            <Notification
                                                key={notification.id}
                                                titulo={notification.titulo_notificacao}
                                                data={notification.data}
                                                texto={ReactHtmlParser(notification.texto)}
                                            />
                                        ) : (
                                            <Notification
                                                key={0}
                                                titulo=""
                                                data=""
                                                texto="Ainda não há notificações"
                                            />
                                        )}
                                    </Col>
                                </Row>


                                <Row justifyContent='space-between' margin='0px' alignItems='flex-start'>
                                    <Col width='66%' margin='0px'>
                                        <Row margin='0px' padding='0px' justifyContent='space-between'>
                                            <Col width='48%' margin='0px'>
                                                <Week width='100%' />
                                            </Col>

                                            <Col width='48%' margin='0px'>
                                                <ResumoCrm width='100%' />
                                            </Col>
                                        </Row>

                                        <Row margin='0px' padding='0px'>
                                            <div className="total-receitas">
                                                <TotalReceitasEmitidas disabled={true} height={200} />
                                            </div>
                                        </Row>
                                    </Col>

                                    <Col width='32%' height='100%' margin='0px'>
                                        <ResumoAgenda />
                                    </Col>
                                </Row>



                                <Row justifyContent='space-between' margin='0px'>
                                    <Col width='32%' margin='0px'>
                                        <CardNewsHome
                                            title={`Compêndio agrícola`}
                                            text={`Consulte os  produtos agroquímicos registrados no Brasil`}
                                            data={`99/99/9999`}
                                            href={`/compendio`}
                                            img={`${IMG1}`}
                                            btnText={`BUSCAR PRODUTOS`}
                                        />
                                    </Col>

                                    <Col width='32%' margin='0px'>
                                        <CardNewsHome
                                            title={`Solicitar produtos`}
                                            text={`Solicitar a inclusão de novas bulas de defensivos agrícolas`}
                                            data={`${news[5]}`}
                                            href={`/solicitar`}
                                            img={`${IMG2}`}
                                            btnText={`SOLICITAR PRODUTO`}
                                        />
                                    </Col>

                                    <Col width='32%' margin='0px'>
                                        <CardNewsHome
                                            title={`Relatórios`}
                                            text={`Visualize os relatórios e métricas de suas receitas emitidas!`}
                                            data={`${news[8]}`}
                                            href={`/relatorios`}
                                            img={`${IMG3}`}
                                            btnText={`VER RELATÓRIOS`}
                                        />
                                    </Col>
                                </Row>
                            </AreaHome>

                            <>
                                <ModalTermos
                                    show={showTerms}
                                    termos={true}
                                    title="Termos de uso e política de privacidade"
                                    text={["Para criar sua conta no Agroreceita, é necessário aceitar nossos termos de uso e política de privacidade"]}
                                    btnOk={true}
                                    onHide={closeTerms}
                                    update={updateTerms}
                                ></ModalTermos>

                                <ModalTermos
                                    show={showUpdateTerms}
                                    termos={true}
                                    title="Olá, seja bem vindo!"
                                    text={["Atualizamos nossos termos de uso!", 'para continuar confirme sua leitura']}
                                    btnOk={true}
                                    onHide={closeTerms}
                                    update={updateTerms}
                                    noHeader
                                ></ModalTermos>

                                <ModalGeneric
                                    show={showModalHomolog}
                                    onHide={() => setShowModalHomolog(false)}
                                >
                                    <div className="container">
                                        <p style={{
                                            textAlign: 'center'
                                        }}><b>{msgHomologError}</b></p>

                                        <div className="btns" style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center',
                                            marginTop: '20px',
                                        }}>
                                            <Button
                                                style={{ outline: 0, width: '150px', height: '40px' }}
                                                onClick={e => redirectTo("/historico")}
                                                outline
                                                color="#00b43f">
                                                Enviar agora
                                            </Button>
                                            <Button
                                                style={{ outline: 0, width: '150px', height: '40px' }}
                                                onClick={() => setShowModalHomolog(false)}
                                                outline
                                                color="#f01">
                                                Enviar mais tarde
                                            </Button>
                                        </div>
                                    </div>
                                </ModalGeneric>

                                <ModalGeneric
                                    show={showModalLogged}
                                    onHide={false}
                                    size='sm'
                                >
                                    <div className="containerModal"
                                        style={{
                                            padding: '5px',
                                            Width: "auto",
                                            height: "auto"
                                        }}
                                    >
                                        <img src={Welcome} width="203" height="160"/>
                                        
                                        <h2 style={{
                                            textAlign: 'center',
                                            marginTop: '18px'
                                        }}>Bem-vindo(a) ao AgroReceita!</h2>

                                        <p style={{
                                            textAlign: 'center',
                                            marginTop: '18px',
                                        }}>Aproveite todas as funções do <b>AgroReceita PRO</b> e melhore sua tomada de decisões no campo.</p>

                                        <p style={{
                                            textAlign: 'center',
                                            marginTop: '20px',
                                            color: 'red'
                                        }}>Nada será enviado às Defesas Agropecuárias durante o período de teste grátis. Explore tudo sem medo.</p>

                                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                                            <Button
                                                width='296px'
                                                height='50px'
                                                value='ENTENDI'
                                                outline={false}
                                                disabled={false}
                                                onClick={() => setShowModalLogged(false)}
                                                loading={false}
                                            />
                                        </div>
                                    </div>

                                </ModalGeneric>
                            </>
                        </Grid>
                    </div>
                </>
            )}
        />
    );
}

export default withRouter(Home);