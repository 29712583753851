import axios from "axios";
import { getTokenRd } from "./auth";

const tokenAuth = getTokenRd();

const rdapi = axios.create({
  baseURL: "https://api.rd.services",   //rota de desenvolvimento
  headers: {
    common: {
      ContentType: 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${tokenAuth}`
    }
  }
});


export default rdapi;
