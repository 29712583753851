import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
import Button from '../button/index';
import Modal from '.';
import { ModalTitle } from './styles';
// import 'bootstrap/dist/css/bootstrap.min.css';

class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };



    render(){
        return(
            
          //   <Modal
          //   {...this.props}
          //   size="lg"
          //   aria-labelledby = "contained-modal-title-vcenter"
          //   centered
          // >
          //   <Modal.Header closeButton
          //   style={{backgroundColor: " #f8f8f8"}}>
          //     <Modal.Title id="contained-modal-title-vcenter">
          //       {this.props.title}
          //     </Modal.Title>
          //   </Modal.Header>
          //   <Modal.Body
          //   style={{backgroundColor: " #f8f8f8"}}>
          //     {this.props.text}
          //   </Modal.Body>
          //   <Modal.Footer
          //   style={{backgroundColor: " #f8f8f8"}}>
       
          //     <Button outline style={{padding: "15px", outline:0}}
          //               color="#00b43f"  onClick={this.props.onHide}>Fechar
          //     </Button>
                
          //   </Modal.Footer>
          // </Modal>

          <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
          >
            <ModalTitle>{this.props.title}</ModalTitle>

            <div style={{ marginTop: '32px' }}>
              {this.props.text}
            </div>
          </Modal>
          
        );
    }
}
export default ModalNew;