import styled from "styled-components";

export const ListContainer = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 20px;
`;

export const Item = styled.div`
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    img {
        display: none;
        position: relative;
        margin-top: 10px;
        cursor: pointer;
    }

    &:hover {
        img {
            display: flex;
        }
    }
`;

export const Value = styled.div`
    width: 42%;
    height: 50px;
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    margin-right: 20px;

    p {
        font-size: 12px;
        margin-bottom: 0px;
    }
`;

export const AddValue = styled.div`
    width: 42%;
    height: 50px;
    border-bottom: 1px solid #bac1cf;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    margin-right: 20px;
    cursor: text;


    .placeholder {
        cursor: text;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #bac1cf;
        margin-bottom: 3px;
    }


    input {
        border: 0px;
        background-color: transparent;

        &::placeholder {
            font: 11px/3 sans-serif;
            color: #bac1cf;
        }
    }
    
`;

export const Title = styled.h2`
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #303848;
    margin-bottom: 0px;
    opacity: ${props => (props.cancelada ? "0.35" : "1")};
    margin-top: 20px;

`;
