import React, { useEffect, useState } from 'react';
import { Card, CardMateria } from './styles';
import Button from '../../components/button/button_v2';


export const USER = "@userinfo";


const CardBlog = ({...props}) => {
    const { materias, isMobile } = props;

    const [contractSoftId, setContractSoftId] = useState('');


    const formatDate = (data) => {
        if (data.length === 10) {
            data = data + 'T00:00:00';
        }

        let now = new Date(data);
        let day = now.getDate();
        let month = now.getMonth();
        let year = now.getFullYear();
        month = month + 1;
        if (day <= 9) {
            day = "0" + day;
        }
        if (month <= 9) {
            month = "0" + month;
        }
        data = day + "/" + month + "/" + year;
        return data;
    }


    useEffect(() => {
        let user = JSON.parse(localStorage.getItem(USER));
        setContractSoftId(user.contract_software_id);
    },[])

    return (
        <>
            <Card>
                {materias.map(m =>(
                    <CardMateria isMobile={props.isMobile}>
                        <a href={m.url} target="_blank"><img src={m.image_url}></img></a>
                        <h3><a href={m.url} target="_blank">{m.titulo}</a></h3>
                        <div className='date'>
                            <span>
                                <i className='fa fa-calendar' width='30px'></i>
                                {formatDate(m.data) } 
                            </span>
                            <span className='bookmark'>
                                <i className='fa fa-bookmark' width='30px'></i>
                                {m.tags}
                            </span>
                        </div>
                        <div className="btn-area">
                            <Button
                                width="155px"
                                height="30px"
                                blog={true}
                                fontSize="10px"
                                outline={false}
                                disabled={false}
                                onClick={() => window.open(m.url, '_blank')}
                                loading={false}
                                value="ACESSAR MATÉRIA"
                            />
                        </div>
                    </CardMateria>
                ))}
            </Card>
        </>
    )
}

export default CardBlog;
