import React, { useState, useEffect, useRef } from 'react';
import { TextAreaContainerV2, Label, Unidade, Legenda } from './styles';


const TextAreaV2 = ({ ...props }) => {

    const [ref] = useState(useRef());
    const [isFocus, setIsFocus] = useState(false);
    const [value, setValue] = useState('');

    const changeTextArea = (e) => {
        if(props.digitavel === true){
            if (e !== value) {
                props.onChange(e);
            }
        }
    }


    const onFocusInput = () => {
        if (props.disabled === false || !props.disabled) {
            if (!isFocus) {
                document.getElementById(props.id).select();
            }
            setIsFocus(true);
        }
    }

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value);
        }
    },[props.value])

    return(
        <TextAreaContainerV2 erro={props.erro} marginBottom={props.marginBottom}>
            <Label 
                erro={props.erro}
                onClick={() => onFocusInput()}
                isFocus={isFocus}
                existValue={value === '' && value !== '0' && value !== 0 ? false : true}
            >{props.label || props.placeholder}</Label>

            <textarea  
                {...props} 
                ref={ref} 
                onChange={(e)=>changeTextArea(e.target.value)}
                style={{
                    width: '100%',
                    height: 'auto',
                    padding: '13px 15px 12px 25px',
                    borderRadius: '25px',
                    border: props.erro ? 'solid 1px #ff5252' : 'solid 1px #bac1cf',
                    backgroundColor: '#FFF',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    color: props.erro ? '#ff5252' : '#303848',
                }}
            >{value}</textarea> 
            {props.unidade ? (
                <Unidade erro={props.erro}>{props.unidade}</Unidade>
            ) : (null)}
            {props.erro && props.messageErro ? (
                <Legenda>{props.messageErro}</Legenda>
                ) : (<>
                {props.legenda && (
                    <Legenda>{props.legenda}</Legenda>
                )}
            </>)}
        </TextAreaContainerV2>
    )
}

export default TextAreaV2;
