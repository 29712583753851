import React, { useState, useEffect } from "react";

import GenericUpper from "../../components/generic-upper";
import ModalUpdate from '../../components/modal/modalGeneric';
import Step0 from "../../components/compra/step0";
import Step1 from "../../components/compra/step1";
import Step2 from "../../components/compra/step2";
import Step3 from "../../components/compra/step3";
import api from "../../services/api";
import { store } from "react-notifications-component";
import Button from "../../components/button/button_v2";

export const USER = "@userinfo";

const Compra = ({ ...props }) => {
    const [subtitle, setSubtitle] = useState("Dados cadastrais");
    const [align, setAlign] = useState("center");
    const [title, setTitle] = useState("");
    const [step, setStep] = useState(0);

    const [planoId, setPlanoId] = useState('');
    const [gridId, setGridId] = useState('');
    const [numUsers, setNumUsers] = useState('');
    const [numDocs, setNumDocs] = useState('');
    const [numpresctipt, setNumPrescript] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [valorPlano, setValorPlano] = useState('');


    //step1
    const [categoria, setCategoria] = useState('');
    const [razaoSocial, setRazaoSocial] = useState('');
    const [nomeFantasia, setNomeFantasia] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [complemento, setComplemento] = useState('');
    const [cidade, setCidade] = useState('');
    const [estado, setEstado] = useState('');
    const [telefone, setTelefone] = useState('');
    const [planoName, setPlanoName] = useState('');
    const [categoriaLabel, setCategoriaLabel] = useState('');


    //step2
    const [dataVencimento, setDataVencimento] = useState('');
    const [responsavel, setResponsavel] = useState('');
    const [cargo, setCargo] = useState('');
    const [departamento, setDepartamento] = useState('');
    const [email, setEmail] = useState('');
    const [telefone2, setTelefone2] = useState('');
    const [numeroPedido, setNumeroPedido] = useState('');
    const [userContract, setUserContract] = useState([]);
    const [cupom, setCoupon] = useState('');

    //step3
    const [step3Values, setStep3Values] = useState([]);

    const [showConfirmUpdate, setShowConfirmUpdate] = useState(false);

    const [cadastrar, setCadastrar] = useState(false);
    const [isFree, setIsFree] = useState(false);
    const [isUpdatePlan, setIsUpdatePlan] = useState(false);

    const [ass_resp_contr_id, setRespCompraId] = useState('');
    const [cad_id, setCadId] = useState('');
    const [fat_id, setFatId] = useState('');

    const [tipoCompra, setTipoCompra] = useState('');
    

    const updateStep0 = (gridId, planId, numUsersId, numDocuments, numPrescriptions, planoSelecionado, valorTotal, valorPlano) => {
        
        if (planId !== undefined) {
            setGridId(gridId);
            setPlanoId(planId);
                
            setPlanoName(planoSelecionado.label);
    
            setNumUsers(numUsersId);
            setNumDocs(numDocuments);
            setNumPrescript(numPrescriptions);
    
            setValorTotal(valorTotal);
            setValorPlano(valorPlano);
        }

    }

    const updateStep1 = (tipoCompra, categoriaId, razaoSocialId, nomeFantasiaId, cnpjId, inscricaoEstadualId, cepId, logradouroId, numeroId, bairroId, complementoId, cidadeId, estadoId, telefoneId, categoriaLabel) => {
        setCategoria(categoriaId);
        setRazaoSocial(razaoSocialId);
        setNomeFantasia(nomeFantasiaId);
        setCnpj(cnpjId);
        setInscricaoEstadual(inscricaoEstadualId);
        setCep(cepId);
        setLogradouro(logradouroId);
        setNumero(numeroId);
        setBairro(bairroId);
        setComplemento(complementoId);
        setCidade(cidadeId);
        setEstado(estadoId);
        setTelefone(telefoneId);
        setCategoriaLabel(categoriaLabel);

        console.log(tipoCompra);
        setTipoCompra(tipoCompra);
    }

    const updateStep2 = (dataVencimentoId, responsavelId, cargoId, departamentoId, emailId, telefoneId, numeroPedidoId) => {
        setDataVencimento(dataVencimentoId);
        setResponsavel(responsavelId);
        setCargo(cargoId);
        setDepartamento(departamentoId);
        setEmail(emailId);
        setTelefone2(telefoneId);
        setNumeroPedido(numeroPedidoId);
    }

    const updateStep3 = (values) => {
        setStep3Values(values);

        setTimeout(() => {
            atualizarPlano(planoId, gridId, values)
        }, 100);
    }

    const getPlano = async (id) => {
        
        const response =  await api.get(`/planos/${id}`);
        
        let plano = response.data.data[0];
        setPlanoName(plano.plan_name);
    } 


    const validateParams = async () => {
        let query = window.location.search.slice(1);
        let partes = query.split('/');

        let userInfo = JSON.parse(localStorage.getItem(USER));
        let isTest = userInfo = userInfo.contrato.agro_plan_config.is_teste;
        
        
        if (partes[0] === 2 || partes[0] === '2') {
            setIsFree(true);
        }
        
        getPlano(partes[0])
        setStep(0);
    }

    const atualizarPlano = async (id, gridId, step3) => {
        try {
            const payload = {
                tipo_compra: tipoCompra.value,
                cad_categoria: categoria,
                cad_razao_social: razaoSocial,
                cad_nome_fantasia: nomeFantasia,
                cad_cnpj: cnpj,
                cad_ie: inscricaoEstadual,
                cad_cep: cep,
                cad_endereco: logradouro,
                cad_numero: numero,
                cad_bairro: bairro,
                cad_complemento: complemento,
                cad_cidade: cidade,
                cad_estado: estado,
                cad_telefone: telefone,
                cad_id: cad_id,
    
                fat_data_vencimento: dataVencimento,
                fat_resp_cont_pagar: responsavel,
                fat_cargo: cargo,
                fat_departamento: departamento,
                fat_email_env_nfe: email,
                fat_telefone: telefone2,
                fat_num_ped_compra: numeroPedido || '',
                fat_id,
    
                ass_resp_contr_id,
                ass_resp_contr_nome_completo: step3.nome,
                ass_resp_contr_cargo: step3.cargo,
                ass_resp_contr_departamento: step3.departamento,
                ass_resp_contr_cpf: step3.cpf,
                ass_resp_contr_rg: step3.rg,
                ass_resp_contr_email: step3.email,

                qtd_users: numUsers,
                qtd_documents: numDocs,
                qtd_prescriptions: numpresctipt,
                valor_total_plano: valorTotal,
                valor_unitario: valorPlano,

                cupom: step3.cupom,
                termos: step3.termos,
                tipo_pagamento: step3.tipo_pagamento,
                grid_id: gridId || 0,

                nome_cartao: step3.nome_cartao || '',
                numero_cartao: step3.numero_cartao || '',
                expiracao_mes: step3.expiracao_mes || '',
                expiracao_ano: step3.expiracao_ano || '',
                ccv: step3.ccv || '',
                nome_completo_cartao: step3.nome_completo_cartao || '',
                email_cartao: step3.email_cartao || '',
                cpf_cnpj_cartao: step3.cpf_cnpj_cartao || '',
                cep_cartao: step3.cep_cartao || '',
                endereco_cartao: step3.endereco_cartao || '',
                complemento_cartao: step3.complemento_cartao || '',
                numero_endereco_cartao: step3.numero_endereco_cartao || '',
                fone_cartao: step3.fone_cartao || '',
                cel_cartao: step3.cel_cartao || '',
                salvar_cartao: step3.salvar_cartao || ''
            }

            if ((numUsers || numDocs || numpresctipt) && valorTotal && valorPlano) {
                const response = await api.post(`/atualizar/plano/${id}`, payload);

                if (response) {
                    window.location = '/compra/sucesso?update=true';
                }
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Informe todos os campos para realizar a compra`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const toStep = (num) => {
        let userInfo = JSON.parse(localStorage.getItem(USER));

        let isTest = userInfo = userInfo.contrato.agro_plan_config.is_teste;

        if (isTest === 1) {
            setStep(num);
        } else {
            setCadastrar(true);
        }
    }

    const nextStep = (num) => {
        if (planoId === 2) {
            if (num < step) {
                setStep(1);
            } else {
                setStep(3);
            }
        } else {
            setStep(num);
        }
    }

    const goHome = () => {
        setCadastrar(false);
        setShowConfirmUpdate(false);
    }

    const updatePlan = () => {
        setIsUpdatePlan(true);
        setShowConfirmUpdate(false);
        setStep(1);
    }

    const getContaInfo = async () => {
        try {
            const response = await api.get('/conta/info');

            const company = response.data.data.company;
            const faturamento = response.data.data.faturamento;
            const compra = response.data.data.compra;

            setCadId(company.id);
            
            updateStep1(
                '', 
                company.company_category_id, 
                company.razao_social, 
                company.nome_fantasia, 
                company.cnpj, 
                company.ie, 
                company.cep, 
                company.endereco, 
                company.numero, 
                company.bairro, 
                company.complemento, 
                company.cidade, 
                company.estado, 
                company.telefone1, 
                company.nome_categoria
            );

            if (faturamento) {
                setFatId(faturamento.id);

                updateStep2(
                    "", 
                    faturamento.nome, 
                    faturamento.cargo, 
                    faturamento.contact_area_id, 
                    faturamento.email, 
                    faturamento.telefone, 
                    faturamento.num_ped_compra
                );
            }

            if (compra) {
                setRespCompraId(compra.id);
                setStep3Values(compra)
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        validateParams();
        getContaInfo();
        setUserContract(JSON.parse(localStorage.getItem("@userinfo")));
    },[]);

    useEffect(() => {     
        window.scroll({top: 0, behavior: 'smooth'});
        
        if(step === 0){
            if (isFree) {
                setTitle("Dados do plano");
                setSubtitle("Formulário de cadastro");
                setAlign("center");
            } else {
                setTitle("Dados do plano");
                setSubtitle("Formulário de compra");
                setAlign("center");
            }
        }else if(step === 1){
            if (isFree) {
                setTitle("Dados cadastrais");
                setSubtitle("Formulário de cadastro");
                setAlign("left");
            } else {
                setTitle("Dados cadastrais");
                setSubtitle("Formulário de compra");
                setAlign("left");
            }
        }else if(step === 2){
            if (isFree) {
                setTitle("Dados de faturamento");
                setSubtitle("Formulário de cadastro");
                setAlign("left");
            } else {
                setTitle("Dados de faturamento");
                setSubtitle("Formulário de compra");
                setAlign("left");
            }
        }else{
            if (isFree) {
                setTitle("Dados do responsável pela conta");
                setSubtitle("Formulário de cadastro");
                setAlign("left");
            } else {
                setTitle("Dados do responsável pela compra");
                setSubtitle("Formulário de compra");
                setAlign("left");
            }
        }

    },[step])
  
    useEffect(() => {
        if (cadastrar === true) {
            if ((numUsers || numDocs || numpresctipt) && valorTotal && valorPlano) {
                setShowConfirmUpdate(true);
            }
        }
    },[cadastrar, numUsers, numDocs, numpresctipt, valorTotal, valorPlano, gridId])

    return (
        <>
            <GenericUpper
                align={align}
                title={title}
                subtitle={subtitle}
            ></GenericUpper>

            {step === 0 ? (
                <>
                    <Step0
                        planoId={planoId}
                        numUsers={numUsers}
                        numDocuments={numDocs}
                        numPrescriptions={numpresctipt}
                        valorTotalProps={valorTotal}
                        valorPlanoProps={valorPlano}
                        update={updateStep0}
                        next={()=>toStep(1)}
                        locProp={props}
                        cupom={(e) => setCoupon(e)}
                        isSimulation={false}
                    />
                </>
            ): step === 1 ? (
                <>
                    <Step1
                        update={updateStep1}
                        planoName={planoName}
                        numUsers={numUsers}
                        valorTotal={valorTotal}
                        categoria={categoria}
                        razao={razaoSocial}
                        nome={nomeFantasia}
                        cnpj={cnpj}
                        ie={inscricaoEstadual}
                        cep={cep}
                        logradouro={logradouro}
                        numero={numero}
                        bairro={bairro}
                        complemento={complemento}
                        cidade={cidade}
                        estado={estado}
                        telefone={telefone}

                        locProp={props}


                        next={()=>nextStep(2)}
                        prev={()=>setStep(0)}
                    />
                </>
            ): step === 2 ? (
                <>  
                    <Step2
                        update={updateStep2}
                        next={()=>setStep(3)}
                        prev={()=>setStep(1)}
                        prevToOne={()=>setStep(0)}
                        categoria={categoriaLabel}
                        nome={nomeFantasia}
                        cnpj={cnpj}
                        logradouro={logradouro}
                        cidade={cidade}
                        telefone={telefone}
                        
                        dataVencimento={dataVencimento}
                        responsavel={responsavel}
                        cargo={cargo}
                        departamento={departamento}
                        email={email}
                        telefoneProps={telefone2}
                        numeroPedido={numeroPedido}

                        locProp={props}
                    />
                </>
            ):(
                <>
                    <Step3
                        planoId={planoId}
                        gridId={gridId}
                        usuarios={numUsers}
                        documentos={numDocs}
                        receitas={numpresctipt}
                        valorTotal={valorTotal}
                        valorUnitario={valorPlano}

                        categoria={categoria}
                        razaoSocial={razaoSocial}
                        nomeFantasia={nomeFantasia}
                        cnpj={cnpj}
                        inscricaoEstadual={inscricaoEstadual}
                        cep={cep}
                        logradouro={logradouro}
                        numero={numero}
                        bairro={bairro}
                        complemento={complemento}
                        cidade={cidade}
                        estado={estado}
                        telefone={telefone}

                        dataVencimento={dataVencimento}
                        responsavel={responsavel}
                        cargo={cargo}
                        departamento={departamento}
                        email={email}
                        telefone2={telefone2}
                        numeroPedido={numeroPedido}

                        isUpdatePlan={isUpdatePlan}
                        values={step3Values}
                        // updatePlan={() => atualizarPlano(planoId, gridId)}
                        updatePlan={(payload3) => updateStep3(payload3)}

                        userEmail={userContract.username}
                        coupon={cupom} 
                        prev={()=>nextStep(2)}
                        prevToOne={()=>setStep(0)}
                        prevToTwo={()=>setStep(1)}
                        isFree={isFree}
                        tipoCompra={tipoCompra}
                    />
                </>
            )}

            <ModalUpdate
                size='md'
                show={showConfirmUpdate}
                onHide={() => goHome()}
            >
                <h4 style={{ 
                    width: '100%',
                    textAlign: 'center',
                 }}> Alterar meu plano </h4>
                <p style={{ 
                    width: '100%',
                    textAlign: 'center',
                 }}> Atenção, você está prestes a atualizar seu plano, tem certeza de que deseja continuar? </p>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '40px' }}>
                    <Button 
                        width='160px'
                        height='40px'
                        value='NÃO'
                        outline={true}
                        disabled={false}
                        onClick={() => goHome()}
                        loading={false}
                        red={true}
                    />
                    <Button 
                        width='160px'
                        height='40px'
                        value='SIM'
                        outline={false}
                        disabled={false}
                        onClick={() => updatePlan()}
                        loading={false}
                    />
                </div>
            </ModalUpdate>
        </>
    );
};

export default Compra;