import styled, { createGlobalStyle } from "styled-components";

import "simplebar/dist/simplebar.min.css";
import "react-notifications-component/dist/theme.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: 0;

}

body, html {
  font-family: 'Lato', sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  height: 0%;
  width: 100%;
  background-color: #f8f8f9;

  hr {
	border-color: rgba(0,0,0,0.1) !important;
  }

  input:-webkit-autofill {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
    /* width: 10px; */
    /* border-right: 1px solid #888 */
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 8px;
    
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
    /* z-index: 10; */
  }

}

button {
  outline: 0;
}

a {
  text-decoration: none;
  color: #599bff;
  cursor: pointer;
}

p {
	cursor: default;
  }
b {
	cursor: default;
}
`;

export default GlobalStyle;

export const Layout = styled.div`

`;

export const Main = styled.span`
  width: ${props => props.isFull === true ? '100%' : props.isOpen === false ? 'calc(100% - 50px)' : 'calc(100% - 250px)'};
  
  position: absolute;
  
  right: 0px;

  overflow-x: hidden;
  overflow-y: hidden;

  transition: all 0.2s ease-out;

`;


export const FormContainer = styled.div`
	width: 100%;
	/* background: #f8f8f9; */
	height: auto;
	display: flex;

`;

export const Form = styled.form`
	
	width: 100%;
	height: auto;
	max-width: 400px;

	display: block;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	position: relative;
	
	/* left: ${props => (props.step === 1 ? (props.isFocus ? props.isModal ? '12%' : '0px' : props.isModal ? '-70%' : '-50%') : (
		props.isFocus ? props.isModal ? '-15%' : '-2.55%' : props.isModal ? '90%' : '90%'
	))} */

	padding-top: 40px;
	margin-bottom: 20px;

	transition: all 0.2s ease-out;

	.labelcoord {
		width: 100%;
		margin-bottom: 10px;
		margin-left: 25px;
		font-family: Lato;
		font-size: 11px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #8a92a2;
	}


	.coord {
		width: 100%;
		height: 75px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
		.partCoord {
			width: 30%;
			height: 90px;

			span {
				position: relative;
				top: -100px;
				left: 123px;
				color: #8a92a2;
				font-weight: 500;
			}
		
		}
	}

	i {
		margin-left: 15px;
	}


	#labelcultura{
		margin: -10px 0px 18px 0px;
		font-family: Lato;
		font-size: 11px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		text-align: start;
		margin-left:25px;
		letter-spacing: normal;
		color: #8a92a2;
		z-index: 3;
	}

	.optionListContainer{
		z-index: 3;
	}

	#multiselectContainerReact{
		margin-top: -10px;
		border-radius: 25px;
		border: 1px solid rgb(186, 193, 207);
		background-color: rgb(255, 255, 255) !important;
		font-family: Lato;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		height: 100%;
		color: rgb(48, 56, 72);
		background: white;
		width: 100%;
		max-width: 400px;

		.searchWrapper{
			border: 0px solid #ccc;
			span{
				background-color: #00b43f;
			}
			input{
				margin: 5px 0px 30px 10px
			}
		}

	}

	@media only screen and (max-width: 820px) {
		width: 98%;
	}


	/* @media only screen and (max-width: 820px) {
		left: ${props => (props.step === 1 ? (props.isFocus ? props.isModal ? '2%' : '7%' : props.isModal ? '-80%' : '-60%') : (
			props.isFocus ? props.isModal ? '-42%' : '-29.45%' : props.isModal ? '90%' : '90%'
		))}
	}
	
	@media only screen and (max-width: 768px) {
		left: ${props => (props.step === 1 ? (props.isFocus ? props.isModal ? '-1%' : '-1%' : props.isModal ? '-70%' : '-55%') : (
			props.isFocus ? props.isModal ? '-51%' : '-51%' : props.isModal ? '90%' : '90%'
		))}
	} */
`;

export const SlideForm = styled.div`
	width: ${props => `${(props.totalForms*100)}%`};
	
	position: relative;

	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly;

	transition: all 0.2s ease;
	left: ${props => `${(props.foco*(-100))+100}%`};
`;

export const FormSlideContainer = styled.div`
	width: ${props => `${(100/(props.totalForms))}%`};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	transition: all 0.15s ease;
	opacity: ${props => props.isFocus ? 1 : 0};
	height: ${props => props.isFocus ? 'auto' : '0px'};

	#labelcultura{
		margin: -10px 0px 18px 0px;
		font-family: Lato;
		font-size: 11px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		margin-left:25px;
		letter-spacing: normal;
		color: #8a92a2;
		z-index: 3;
	}

	.optionListContainer{
		z-index: 3;
	}

	#multiselectContainerReact{
		margin-top: -10px;
		border-radius: 25px;
		border: 1px solid rgb(186, 193, 207);
		background-color: rgb(255, 255, 255) !important;
		font-family: Lato;
		font-size: 16px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		height: 100%;
		color: rgb(48, 56, 72);
		background: white;
		width: 100%;
		max-width: 400px;

		.searchWrapper{
			border: 0px solid #ccc;
			span{
				background-color: #00b43f;
			}
			input{
				margin: 5px 0px 30px 10px
			}
		}

	}
`;