import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import GenericUpper from "../../components/generic-upper";
import Agronomo from "../../components/creas/agronomos";
import AddCreas from "../../components/creas/creas";

const AddAgronomos = ({ ...props }) => {

    const [nome, setNome] = useState('');
    const [cpf, setCpf] = useState('');
    const [estadoId, setEstadoId] = useState('');
    const [numeroCrea] = useState('');
    const [tipoId] = useState('');
    const [title, setTitle] = useState('Cadastre um responsável técnico');
    const [step, setStep] = useState(1);
    const [align] = useState("center");
    const [certificado, setCertificado] = useState([]);
    const [sign, setSign] = useState('');
    const [email, setEmail] = useState("");
    const [enviar_receita, setEnviarReceita] = useState('');
    const [whatsapp, setWhatsapp] = useState('');


    const updateAgronomoInfo = (
        nome,
        cpf,
        file,
        signBase64,
        email,
        enviar,
        whatsapp
    ) => {
        setNome(nome);
        setCpf(cpf);
        setCertificado(file);
        setSign(signBase64);
        setEmail(email);
        setEnviarReceita(enviar);
        setWhatsapp(whatsapp);
    }

    useEffect(() => {
        if (step === 2) {
            setTitle('Cadastre o CREA,Visto ou CFTA')
        }
    }, [step]);

    useEffect(() => {
        if (props.estado) {
            setEstadoId(props.estado);
        }
    }, [props.estado])

    return (
        <>

            {props.isModal === true ? (
                <h4 style={{ textAlign: 'center' }}>{title}</h4> 
            ) : (
                <GenericUpper
                    align={align}
                    title={title}
                    help={true}
                    text={"Dúvidas para cadastrar um responsável técnico?"}
                    toSearch={'https://agroreceita.com.br/cadastro-responsavel-tecnico/'}
                    textToSearch={'Clique aqui e acesse o tutorial!'}
                ></GenericUpper>
            )}

            {step == 2 ? (
                <>
                    <AddCreas
                        nome={nome}
                        cpf={cpf}
                        email={email}
                        enviar_receita={enviar_receita}
                        sign={sign}
                        whatsapp={whatsapp}
                        estadoId={estadoId}
                        numeroCrea={numeroCrea}
                        tipoId={tipoId}
                        screenAdd={true}
                        loc={props}
                        certificado={certificado}
                        propsLocation = {props}
                        isModal={props.isModal}
                        update={() => props.update()}
                    />
                </>
            ) : (
                    <Agronomo
                        nome={nome}
                        cpf={cpf}
                        email={email}
                        update={updateAgronomoInfo}
                        next={() => setStep(2)}
                        propsLocation = {props}
                        isModal={props.isModal}
                    />
                )}
        </>
    );
};
export default withRouter(AddAgronomos); 