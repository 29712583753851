import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';

class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }

    updateResponseYes = () => {
        let resposta = 1;
        this.props.update(resposta);
    }

    updateResponseNo = () => {
        let resposta = 0;
        this.props.update(resposta);
    }

    render() {
        return (

            // <Modal
            //     {...this.props}
            //     size="lg"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8"}}>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //             {this.props.title}
            //         </Modal.Title>
            //     </Modal.Header >
            //     <Modal.Body style={{backgroundColor: " #f8f8f8"}}>
            //         {this.props.text}
            //     </Modal.Body>
            //     <Modal.Footer style={{backgroundColor: " #f8f8f8"}}>

            //         {this.props.btnNo == true ? (
            //             <Button style={{ outline:0, padding: "10px", width:"100px"}}
            //                 outline
            //                 color="#ff5252"
            //                 onClick={() => this.updateResponseNo()}
            //             >NÃO</Button>
            //         ):(
            //             <Button style={{ outline:0, padding: "10px", width:"100px"}}
            //                 outline
            //                 color="#ff5252"
            //                 onClick={() => this.updateResponseNo()}
            //             >CANCELAR</Button>
            //         )}


            //         <Button style={{ outline:0, padding: "10px", width:"100px"}}
            //             outline
            //             color="#00b43f"
            //             onClick={() => this.updateResponseYes()}
            //         >SIM</Button>

            //     </Modal.Footer>
            // </Modal>


            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='50%'
            >
                <ModalTitle> {this.props.title} </ModalTitle>

                <ModalBody>
                    {this.props.text}
                </ModalBody>

                <ModalFooter justifyContent='space-evenly'>
                    {this.props.btnNo == true ? (
                        <Button style={{ outline:0, padding: "10px", width:"100px"}}
                            outline
                            color="#ff5252"
                            onClick={() => this.updateResponseNo()}
                        >NÃO</Button>
                    ):(
                        <Button style={{ outline:0, padding: "10px", width:"100px"}}
                            outline
                            color="#ff5252"
                            onClick={() => this.updateResponseNo()}
                        >CANCELAR</Button>
                    )}

                    <Button style={{ outline:0, padding: "10px", width:"100px"}}
                        outline
                        color="#00b43f"
                        onClick={() => this.updateResponseYes()}
                    >SIM</Button>
                </ModalFooter>
            </Modal>

        );
    }
}
export default ModalNew;