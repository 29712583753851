import React, { component } from 'react';
// import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import { Link, useHistory } from 'react-router-dom';
import styled from "styled-components";
import Modal from '.';
import { ModalBody, ModalTitle } from './styles';

const StyleModal = styled.div`
    p {
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
    }

`;
const StyleModal2 = styled.div`
    p {
        font-family: Lato;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
    }

`;

class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }
    
    
    // updateResponseNo = () => {
    //     let resposta = 0;
    //     this.props.update(resposta);
    // }
    
    cometoplanos = () =>{
        window.location.href = "/planos";
    }

    render() {
        return (

            // <Modal
            //     {...this.props}
            //     size="md"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8", border: 'none'}}>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //             {/* <StyleModal2>
            //                 <p>Essa é uma função exclusiva para assinantes...</p>
            //             </StyleModal2> */}
            //         </Modal.Title>
            //     </Modal.Header>

            //     <Modal.Body style={{backgroundColor: " #f8f8f8"}}>
            //         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
            //             <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>Essa é uma função exclusiva para assinantes...</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>Assine o Agroreceita para desbloquear todos os recursos abaixo:</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Emissão de receitas</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Controle de Arts</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Relatórios completos</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Envio de dados para as defesas agropecuárias e CREA SP</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de produtores e locais de aplicação</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de responsáveis técnicos</p>
            //             <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Impressão e consulta de fichas de emergência e FISPQs</p>
                    
            //             <div style={{ marginLeft: '10px', width: '100%', textAlign: 'center', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse'}}>

            //                 <Button 
            //                     style={{ 
            //                         outline:0, 
            //                         padding: "5px", 
            //                         width:"166px", 
            //                         height: "45px" 
            //                     }}
            //                     color="#00b43f"
            //                     onClick={this.cometoplanos}
            //                 > 
            //                     ASSINAR AGORA 
            //                 </Button>
            //             </div>
            //         </div>
                   
            //     </Modal.Body>
            // </Modal>


            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='50%'
            >
                <ModalTitle> Essa é uma função exclusiva para assinantes... </ModalTitle>

                <ModalBody>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
                        {/* <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>Essa é uma função exclusiva para assinantes...</p> */}
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>Assine o Agroreceita para desbloquear todos os recursos abaixo:</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Emissão de receitas</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Controle de Arts</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Relatórios completos</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Envio de dados para as defesas agropecuárias e CREA SP</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de produtores e locais de aplicação</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Cadastro de responsáveis técnicos</p>
                        <p style={{color: '#303848', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}> Impressão e consulta de fichas de emergência e FISPQs</p>
                    
                        <div style={{ marginLeft: '10px', width: '100%', textAlign: 'center', marginTop: '20px', alignSelf: 'center', display: 'flex', justifyContent: 'space-around', flexDirection: 'row-reverse'}}>

                            <Button 
                                style={{ 
                                    outline:0, 
                                    padding: "5px", 
                                    width:"166px", 
                                    height: "45px" 
                                }}
                                color="#00b43f"
                                onClick={this.cometoplanos}
                            > 
                                ASSINAR AGORA 
                            </Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

        );
    }
}
export default ModalNew;