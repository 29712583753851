import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { store } from "react-notifications-component";
import api from "../../services/api";
import GenericUpper from "../../components/generic-upper";
import Input from "../../components/input/input_v2";
import Select from "../../components/select/select_v2";
import Button from "../../components/button/button_v2";
import estados from "../../services/estados";
import { Container } from "react-grid-system";
import { ProdutoresAddContainer, YesNoContainer } from "./styles";
import ModalEditLocal from "../../components/modal/modalEditProdutor";
import ModalYesNo from "../../components/modal/modalYesNo";
import ModalText from "../../components/modal/modalText";
import validadorDeCpf from "../../services/validadorCpf";

import { Multiselect } from 'multiselect-react-dropdown';
import buscaCep from "../../services/buscaCep";
import Modal from "../../components/modal/modalGeneric";
import Interruptor from "../../components/interruptor";
import MapaArea from "../../components/mapaArea";

const EditLocal = ({ ...props }) => {

    const [nomeLocal, setNomeLocal] = useState('');
    const [cpfCnpjLocal, setCpfCnpjLocal] = useState('');
    const [inscricaoEstadual, setInscricaoEstadual] = useState('');
    const [cepLocal, setCepLocal] = useState('');
    const [logradouroLocal, setLogradouroLocal] = useState('');
    const [bairroLocal, setBairroLocal] = useState('');
    const [numeroLocal, setNumeroLocal] = useState('');
    const [complementoLocal, setComplementoLocal] = useState('');
    const [cidadeLocal, setCidadeLocal] = useState('');
    const [codIbge, setCodIbge] = useState('');
    const [estadoLocal, setEstadoLocal] = useState('');
    const [numeroOrgao, setNumeroOrgao] = useState('');
    const [areaTotal, setAreaTotal] = useState('');
    const [cadastroAmbiental, setCadastroAmbiental] = useState('');
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [culturaId, setCulturaId] = useState('');
    const [culturas, setCulturas] = useState([]);
    const [estadoCep, setEstadoCep] = useState('');
    const [id, setId] = useState('');
    const [id_produtor, setIdProdutor] = useState('');
    const [msg, setMsg] = useState('');
    const [msg2, setMsg2] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalYesNo, setShowModalYesNo] = useState(false);
    const [locais, setLocais] = useState([]);
    const [enviadoCrea, setEnviadoCrea] = useState(false);
    const [respostaCrea, setRespostaCrea] = useState('');
    const [respostaArt, setRespostaArt] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [culturassend, setCulturassend] = useState([]);
    const [newcultures, setNewCultures] = useState([]);
    const [showModalText, setShowModalText] = useState(false);
    const [cepInicial, setCepInicial] = useState('');
    const [logradouroInicial, setLogradouroInicial] = useState('');
    const [numeroInicial, setNumeroInicial] = useState('');
    const [latitudeGraus, setLatitudeGraus] = useState('');
    const [latitudeMinutos, setLatitudeMinutos] = useState('');
    const [latitudeSegundos, setLatitudeSegundos] = useState('');
    const [longitudeGraus, setLongitudeGraus] = useState('');
    const [longitudeMinutos, setLongitudeMinutos] = useState('');
    const [longitudeSegundos, setLongitudeSegundos] = useState('');
    const [loading, setLoading] = useState(false);
    const [altitude, setAltitude] = useState('');
    const [erroLatitude, setErroLatitude] = useState(false);
    const [erroLongitude, setErroLongitude] = useState(false);
    const [erroAltitude, setErroAltitude] = useState(false);
    const [erroNome, setErroNome] = useState(false);
    const [erroCpf, setErroCpf] = useState(false);
    const [erroBairro, setErroBairro] = useState(false);
    const [erroCep, setErroCep] = useState(false);
    const [erroEndereco, setErroEndereco] = useState(false);
    const [erroNumero, setErroNumero] = useState(false);
    const [erroCidade, setErroCidade] = useState(false);
    const [erroEstado, setErroEstado] = useState(false);
    const [erroCpfMsg, setErroCpfMsg] = useState('');
    const [resolveConflict, setResolveConflict] = useState([]);
    const [btnCancelar, setBtnCancelar] = useState(false);
    const [modalGeneric, setModalGeneric] = useState(<></>);
    const [showModalGeneric, setShowModalGeneric] = useState(false);
    const [responseConflict, setResponseConflict] = useState([]);
    const [showMap, setShowMap] = useState(false);
    const [showInput, setShowInput] = useState(false);
    const [modoCoord, setModoCoord] = useState(0);
    const [statusLocal, setStatusLocal] = useState(0);
    

    const inicializa = async () => {
        try {
            
            const responseCulturas = await api.get(`culturas`);
            let c = responseCulturas.data.data;
            c = formatCulturas(c);
            setCulturas(c)

            let id = "";
            let idProdutor = "";

            if (props.idFazenda && props.idProdutor) {
                id = props.idFazenda;
                idProdutor = props.idProdutor;
            } else {
                id = props.match.params.idfazenda;
                idProdutor = props.match.params.idprodutor;
            }
    

            setId(id);
            setIdProdutor(idProdutor)

            const response = await api.get(`/fazendas/${id}`);
            
            const fazenda = response.data.data;
            
            setNomeLocal(fazenda.nome);
            setCpfCnpjLocal(fazenda.cpf_cnpj);
            setCodIbge(fazenda.cod_ibge);
            setAreaTotal(fazenda.area_total);
            setInscricaoEstadual(fazenda.inscricao_estadual);
            setCadastroAmbiental(fazenda.cadastro_ambiental);
            setNumeroOrgao(fazenda.inscricao_orgao);
            setCepLocal(fazenda.cep);
            setLogradouroLocal(fazenda.endereco);
            setNumeroLocal(fazenda.numero);
            setComplementoLocal(fazenda.complemento);
            setCidadeLocal(fazenda.cidade);
            setBairroLocal(fazenda.bairro);
            setEstadoLocal(fazenda.estado);
            setEstadoCep(fazenda.estado);
            setCepInicial(fazenda.cep);
            setLogradouroInicial(fazenda.endereco);
            setNumeroInicial(fazenda.numero);
            setLatitude(fazenda.latitude);
            setLongitude(fazenda.longitude);
            setAltitude(fazenda.altitude);
            setStatusLocal(fazenda.status);


            const select = formatCulturas(fazenda.cultura);
            setSelectedValues(select);


            let cultures = [];

            if (fazenda.cultura) {
                fazenda.cultura.map((fc) => {
                    cultures = [...cultures, fc.id]
                })
            }

            setCulturassend(cultures);
            
        } catch (error) {
        }

    }

    const formatCulturas = culturas => {
        if(culturas.length > 1){
            culturas = culturas.map(cultura => {
                const format = {
                    id: cultura.id,
                    name: cultura.nome_comum
                };
                return format;
            });
            return culturas;
        }else{
            return {
                id: culturas.id,
                name: culturas.nome_comum
            }
        }
    }

    const validateCpfCnpjLocal = cpfCnpjLocal => {
        setErroCpf(false);
        setCpfCnpjLocal(cpfCnpjLocal);
    }

    const validaCpf = (valor) => {
        if (valor.length <= 14) {
            const isValido = validadorDeCpf(valor);

            setErroCpf(!isValido);
            setErroCpfMsg('Informe um CPF válido!');

            if (!isValido) {
                store.addNotification({
                    title: "Erro!",
                    message: "Informe um CPF válido.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
            setErroCpf(false);
        }
    }

    const fetchCep = (cepLocal) => {
        setCepLocal(cepLocal);
    };

    const validaCep = async (cepLocal) => {
        try {
            const cep = await buscaCep(cepLocal);
            const { logradouro, localidade, bairro, ibge, uf } = cep;

            setLogradouroLocal(logradouro);
            setCidadeLocal(localidade);
            setBairroLocal(bairro);
            setCodIbge(ibge);
            setEstadoLocal(uf);
            setEstadoCep(uf);
        } catch (err) {
            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const limpaCep = e => {
        if ((estadoCep) != estadoLocal) {

            if ((cepLocal != "") || (logradouroLocal != "") || (bairroLocal != "") || (cidadeLocal != "") || (codIbge != "")) {
                setLogradouroLocal('');
                setBairroLocal('');
                setCidadeLocal('');
                setCodIbge('');
                setCepLocal('');
                setNumeroLocal('');
                setComplementoLocal('');


                let input = document.querySelector("#cepLocal");
                let select = document.querySelector("#estado");

                input.classList.add("error");
                select.classList.add("error");

                store.addNotification({
                    title: "Erro!",
                    message: "Este estado não pertence ao CEP informado!",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } else {
          

        }
    }

    const onSelect =(selectedList, selectedItem) => { 
        let c = [];
        const a = selectedList.map((list)=>{
           c.push(list.id)
        })  
        
        setCulturassend(c);
    }
 
    const onRemove = (selectedList, removedItem)=>{
        let c = [];
        const a = selectedList.map((list)=>{
            c.push(list.id)
        })  
        setCulturassend(c);
    }

    const changeModo = (e) => {

        if (e.value === 1 || e.value === 2) {
            setModoCoord(e);
            setLatitude('');
            setLongitude('');
    
    
            if (e.value === 1) {
                setShowMap(true);
                setShowInput(false);
            } else if (e.value === 2) {
                setShowInput(true);
                setShowMap(false);
            }
        }
    }

    const changeCoordinates = (lat, lng) => {
        if (lat, lng) {
            setLatitude(lat);
            setLongitude(lng);
        }
    }

    const validateLatitude = async (tipo, valor) => {        
        if(tipo === 1){

            if(latitudeGraus.length > valor.length){
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLatitudeGraus(`${separa[0]}${separa[1]}`);
                    }else{
                        setLatitudeGraus(valor);
                    }
                }else{
                    setLatitudeGraus(valor);
                }

            }else{
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLatitudeGraus(valor);
                    }else{

                        if(separa[2] === ','){
                            setLatitudeGraus(`${separa[0]}${separa[1]}.`);
                        }else{
                            setLatitudeGraus(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                }else{
                    setLatitudeGraus(valor);
                }
            }

        }else if(tipo === 2){

            if(latitudeMinutos.length > valor.length){
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLatitudeMinutos(`${separa[0]}${separa[1]}`);
                    }else{
                        setLatitudeMinutos(valor);
                    }
                }else{
                    setLatitudeMinutos(valor);
                }
            }else{
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLatitudeMinutos(valor);
                    }else{

                        if(separa[2] === ','){
                            setLatitudeMinutos(`${separa[0]}${separa[1]}.`);
                        }else{
                            setLatitudeMinutos(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                }else{
                    setLatitudeMinutos(valor);
                }
            }

        }else if(tipo === 3){
            if(latitudeSegundos.length > valor.length){
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLatitudeSegundos(`${separa[0]}${separa[1]}`)
                    }else{
                        setLatitudeSegundos(valor);
                    }
                }else{
                    setLatitudeSegundos(valor);
                }
            }else{
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLatitudeSegundos(valor);
                    }else{

                        if(separa[2] === ','){
                            setLatitudeSegundos(`${separa[0]}${separa[1]}.`);
                        }else{
                            setLatitudeSegundos(`${separa[0]}${separa[1]}.${separa[2]}`);

                        }
                    }
                }else{
                    setLatitudeSegundos(valor);
                }
            }
        }
    }

    const validateLongitude = async (tipo, valor) => {
        if(tipo === 1){

            if(longitudeGraus.length > valor.length){
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLongitudeGraus(`${separa[0]}${separa[1]}`);
                    }else{
                        setLongitudeGraus(valor);
                        
                    }
                }else{
                    setLongitudeGraus(valor);
                }
            }else{
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLongitudeGraus(valor);
                    }else{

                        if(separa[2] === ','){
                            setLongitudeGraus(`${separa[0]}${separa[1]}.`);
                        }else{
                            setLongitudeGraus(`${separa[0]}${separa[1]}.${separa[2]}`);

                        }
                    }
                }else{
                    setLongitudeGraus(valor);
                }
            }

        }else if(tipo === 2){

            if(longitudeMinutos.length > valor.length){
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLongitudeMinutos(`${separa[0]}${separa[1]}`);
                    }else{
                        setLongitudeMinutos(valor);
                    }
                }else{
                    setLongitudeMinutos(valor);
                }
            }else{
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLongitudeMinutos(valor);
                    }else{

                        if(separa[2] === ','){
                            setLongitudeMinutos(`${separa[0]}${separa[1]}.`);
                        }else{
                            setLongitudeMinutos(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                }else{
                    setLongitudeMinutos(valor);
                }
            }

        }else if(tipo === 3){

            if(longitudeSegundos.length > valor.length){
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLongitudeSegundos(`${separa[0]}${separa[1]}`);
                    }else{
                        setLongitudeSegundos(valor);
                        
                    }
                }else{
                    setLongitudeSegundos(valor);
                }
            }else{
                if(valor.length === 3){
                    let separa = valor.split("");
                    if(separa[2] === "."){
                        setLongitudeSegundos(valor);
                    }else{
                        if(separa[2] === ','){
                            setLongitudeSegundos(`${separa[0]}${separa[1]}.` );
                        }else{
                            setLongitudeSegundos(`${separa[0]}${separa[1]}.${separa[2]}`);
                        }
                    }
                }else{
                    setLongitudeSegundos(valor);
                }
            }
        }
    }

    const modalClose = e => {
        if(showModal === true){
            setShowModal(false);
        }
        if(showModalYesNo === true){
            setShowModalYesNo(false);
        }
    }

    const updateResposta = async (resposta) => {
        
        if (resposta === 1) {
            setRespostaArt(resposta);

            if((enviadoCrea === true) && (respostaCrea === 1)){
                
                const payload = {
    
                    nome: nomeLocal,
                    cpf_cnpj: cpfCnpjLocal,
                    inscricao_estadual: inscricaoEstadual,
                    cep: cepLocal,
                    endereco: logradouroLocal,
                    bairro: bairroLocal,
                    numero: numeroLocal,
                    complemento: complementoLocal,
                    cidade: cidadeLocal,
                    cod_ibge: codIbge,
                    estado: estadoLocal,
                    inscricao_orgao: numeroOrgao,
                    area_total: areaTotal,
                    cadastro_ambiental: cadastroAmbiental,
                    latitude,
                    longitude,
                    cultura: culturaId,
                    enviado_crea: respostaCrea,
                    alterar_dados_providenciar_nova_art: resposta,
                    alterar: 1,
                    exibeMensagem: 1

                };

                try {
                    const response = await api.put(`/fazendas/${id}/`, payload);
                    store.addNotification({
                        title: "Sucesso!",
                        message: "O local de aplicação foi editado corretamente.",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                    props.history.push(`/produtores/${id_produtor}`);
    
    
                } catch (error) {
                    store.addNotification({
                        title: "Erro!",
                        message: "Erro ao alterar Produtor.",
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });
                }
            }else{

                if((enviadoCrea === true) && (respostaCrea === 0)){  

                    const payload = {
    
                        nome: nomeLocal,
                        cpf_cnpj: cpfCnpjLocal,
                        inscricao_estadual: inscricaoEstadual,
                        cep: cepLocal,
                        endereco: logradouroLocal,
                        bairro: bairroLocal,
                        numero: numeroLocal,
                        complemento: complementoLocal,
                        cidade: cidadeLocal,
                        cod_ibge: codIbge,
                        estado: estadoLocal,
                        inscricao_orgao: numeroOrgao,
                        area_total: areaTotal,
                        cadastro_ambiental: cadastroAmbiental,
                        latitude,
                        longitude,
                        cultura: culturaId,
                        enviado_crea: respostaCrea,
                        alterar_dados_providenciar_nova_art: resposta
                    };
                    try {
                        const response = await api.put(`/fazendas/${id}/`, payload);
                        store.addNotification({
                            title: "Sucesso!",
                            message: "O local de aplicação foi editado corretamente.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                        props.history.push(`/produtores/${id_produtor}`);
        
                    } catch (error) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: "O local de aplicação foi editado corretamente.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                        props.history.push(`/produtores/${id_produtor}`);
                    }    
                }

                if(enviadoCrea === false){
                    
                    const payload = {
    
                        nome: nomeLocal,
                        cpf_cnpj: cpfCnpjLocal,
                        inscricao_estadual: inscricaoEstadual,
                        cep: cepLocal,
                        endereco: logradouroLocal,
                        bairro: bairroLocal,
                        numero: numeroLocal,
                        complemento: complementoLocal,
                        cidade: cidadeLocal,
                        cod_ibge: codIbge,
                        estado: estadoLocal,
                        inscricao_orgao: numeroOrgao,
                        area_total: areaTotal,
                        cadastro_ambiental: cadastroAmbiental,
                        latitude,
                        longitude,
                        cultura: culturaId,
                        alterar_dados_providenciar_nova_art: resposta,
                        alterar:1,
                        exibeMensagem:1
                    };
    
                    try {
                        const response = await api.put(`/fazendas/${id}/`, payload);
          
                        store.addNotification({
                            title: "Sucesso!",
                            message: "O local de aplicação foi editado corretamente.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                        props.history.push(`/produtores/${id_produtor}`);

        
                    } catch (error) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: "O local de aplicação foi editado corretamente.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                        props.history.push(`/produtores/${id_produtor}`);
                    }
                }
            }
            setShowModal(false);

        }else{
            setRespostaArt(0);

            if(enviadoCrea === true){
                if(respostaCrea === 1){
                    const payload = {
                        nome: nomeLocal,
                        cpf_cnpj: cpfCnpjLocal,
                        inscricao_estadual: inscricaoEstadual,
                        cep: cepLocal,
                        endereco: logradouroLocal,
                        bairro: bairroLocal,
                        numero: numeroLocal,
                        complemento: complementoLocal,
                        cidade: cidadeLocal,
                        cod_ibge: codIbge,
                        estado: estadoLocal,
                        inscricao_orgao: numeroOrgao,
                        area_total: areaTotal,
                        cadastro_ambiental: cadastroAmbiental,
                        latitude,
                        longitude,
                        cultura: culturaId,
                        enviado_crea: respostaCrea,
                        alterar_dados_providenciar_nova_art: resposta,
                        exibeMensagem: 1,
                        alterar: 0
                    };


                    try {
                        const response = await api.put(`/fazendas/${id}/`, payload);

                        if(response.data.message != ""){
                            setMsg2(response.data.message)
                            setShowModalText(true);
                        }
                    } catch (error) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: "O local de aplicação foi editado corretamente",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });

                        props.history.push(`/produtores/${id_produtor}`);
                    }

                }else{
                    if(respostaCrea === 0){
                        const payload = {
                            nome: nomeLocal,
                            cpf_cnpj: cpfCnpjLocal,
                            inscricao_estadual: inscricaoEstadual,
                            cep: cepLocal,
                            endereco: logradouroLocal,
                            bairro: bairroLocal,
                            numero: numeroLocal,
                            complemento: complementoLocal,
                            cidade: cidadeLocal,
                            cod_ibge: codIbge,
                            estado: estadoLocal,
                            inscricao_orgao: numeroOrgao,
                            area_total: areaTotal,
                            cadastro_ambiental: cadastroAmbiental,
                            latitude,
                            longitude,
                            cultura: culturaId,
                            enviado_crea: respostaCrea,
                            alterar_dados_providenciar_nova_art: resposta
                        };
                        try {
                            const response = await api.put(`/fazendas/${id}/`, payload);
                            store.addNotification({
                                title: "Sucesso!",
                                message: "O local de aplicação foi editado corretamente",
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: true
                                }
                            });
                            props.history.push(`/produtores/${id_produtor}`);
                        } catch (error) {
                            store.addNotification({
                                title: "Sucesso!",
                                message: "O local de aplicação foi editado corretamente",
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 5000,
                                    onScreen: true
                                }
                            });

                            props.history.push(`/produtores/${id_produtor}`);

                        }
                    }
                }



            }else{
                const payload = {

                    nome: nomeLocal,
                    cpf_cnpj: cpfCnpjLocal,
                    inscricao_estadual: inscricaoEstadual,
                    cep: cepLocal,
                    endereco: logradouroLocal,
                    bairro: bairroLocal,
                    numero: numeroLocal,
                    complemento: complementoLocal,
                    cidade: cidadeLocal,
                    cod_ibge: codIbge,
                    estado: estadoLocal,
                    inscricao_orgao: numeroOrgao,
                    area_total: areaTotal,
                    cadastro_ambiental: cadastroAmbiental,
                    latitude,
                    longitude,
                    cultura: culturaId,
                    alterar_dados_providenciar_nova_art: resposta,
                    exibeMensagem: 1,
                    alterar: 0
                };
    
                try {
                    const response = await api.put(`/fazendas/${id}/`, payload);
                    if(response.data.message != ""){
                        setMsg2(response.data.message);
                        setShowModalText(true);
                    }

                } catch (error) {
                    store.addNotification({
                        title: "Sucesso!",
                        message: "O local de aplicação foi editado corretamente",
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 5000,
                            onScreen: true
                        }
                    });

                    props.history.push(`/produtores/${id_produtor}`);
                }
            }
            setShowModal(false);
        }
    }

    const updateRespostaYesNo = async (resposta) => {
        if(resposta === 1){
            const payload = {
    
                nome: nomeLocal,
                cpf_cnpj: cpfCnpjLocal,
                inscricao_estadual: inscricaoEstadual,
                cep: cepLocal,
                endereco: logradouroLocal,
                bairro: bairroLocal,
                numero: numeroLocal,
                complemento: complementoLocal,
                cidade: cidadeLocal,
                cod_ibge: codIbge,
                estado: estadoLocal,
                inscricao_orgao: numeroOrgao,
                area_total: areaTotal,
                cadastro_ambiental: cadastroAmbiental,
                latitude,
                longitude,
                cultura: culturaId,
                enviado_crea: resposta,
                alterar: 0,
                exibeMensagem: 1
            };
    
            try {
                const response = await api.put(`/fazendas/${id}`, payload);

                setEnviadoCrea(true);
                setRespostaCrea(resposta);
            } catch (error) {
                setMsg(error.response.data.errors);
                if(error.response.data.errors === "A alteração dos dados exige uma nova ART. O que você deseja fazer?"){
                    setEnviadoCrea(true);
                    setRespostaCrea(resposta);

                }
            }
            setShowModal(true);
        }else{
            const payload = {
    
                nome: nomeLocal,
                cpf_cnpj: cpfCnpjLocal,
                inscricao_estadual: inscricaoEstadual,
                cep: cepLocal,
                endereco: logradouroLocal,
                bairro: bairroLocal,
                numero: numeroLocal,
                complemento: complementoLocal,
                cidade: cidadeLocal,
                cod_ibge: codIbge,
                estado: estadoLocal,
                inscricao_orgao: numeroOrgao,
                area_total: areaTotal,
                cadastro_ambiental: cadastroAmbiental,
                latitude,
                longitude,
                cultura: culturaId,
                enviado_crea: respostaCrea,
                alterar: 0,
                exibeMensagem: 1
            };
            let mensagem;
    
            try {
                const response = await api.put(`/fazendas/${id}/`, payload);

                setEnviadoCrea(true);
                setRespostaCrea(resposta);
                if(response.data.message != ""){
                    setMsg2(response.data.message);
                    setShowModalText(true);
                }
    
            } catch (error) {
                store.addNotification({
                    title: "Sucesso!",
                    message: "Estamos alterando os dados sob sua responsabilidade, considerando que os contratos da ART ainda não foram enviados para o CREA. Local de aplicação será mantido como ATIVO na ART",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 7000,
                        onScreen: true
                    }
                });
                props.history.push(`/produtores/${id_produtor}`);
            }
        }
        setShowModalYesNo(false);
    }

    const closeOk = e => {
        setShowModalText(false);
    }

    const updateOk = async (resposta) => {
        if(resposta === 1){
            setShowModalText(false);

            const payload = {
    
                nome: nomeLocal,
                cpf_cnpj: cpfCnpjLocal,
                inscricao_estadual: inscricaoEstadual,
                cep: cepLocal,
                endereco: logradouroLocal,
                bairro: bairroLocal,
                numero: numeroLocal,
                complemento: complementoLocal,
                cidade: cidadeLocal,
                cod_ibge: codIbge,
                estado: estadoLocal,
                inscricao_orgao: numeroOrgao,
                area_total: areaTotal,
                cadastro_ambiental: cadastroAmbiental,
                latitude,
                longitude,
                cultura: culturaId,
                enviado_crea: respostaCrea,
                alterar_dados_providenciar_nova_art: respostaArt,
                exibeMensagem: 1,
                alterar: 1,
            };

            try {
                await api.put(`/fazendas/${id}/`, payload);
                store.addNotification({
                    title: "Sucesso!",
                    message: "O local de aplicação foi editado corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

                props.history.push(`/produtores/${id_produtor}`);

            } catch (err) {
                                
                let inputWithErrors = document.querySelectorAll(".error");

                for (let input of inputWithErrors) {
                    input.classList.remove("error");
                }

                const errors = err.response.data.errors;


                for (let field in errors) {
                    const input = document.getElementById(field);
                    input.classList.add("error");
                }

                store.addNotification({
                    title: "Erro!",
                    message: "Verifique os campos em vermelho",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        }else{
            setShowModalText(false);
        }
    }

    const goBack = () => {
        if (props.isModal) {
            props.openModal(false);
        }else{
            props.history.goBack();
        }
    }

    const editPost = async e => {
        setLoading(true);

        if (erroCpf === true) {
            setLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: "Informe um CPF válido!",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
        } else {
            let lat = latitude;
            let lng = longitude;

            if (lat === '' && lng === '') {
                lat = `${latitudeGraus}°${latitudeMinutos}'${latitudeSegundos}"S`;
                lng = `${longitudeGraus}°${longitudeMinutos}'${longitudeSegundos}"W`
        
            }

            
            const newcultures = [...new Set(culturassend)];
            
            let existErro = false;
            
            if (estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP') {
                if (lat === '' || lat === `°'"S`) {
                    setErroLatitude(true);
                    existErro = true;
                }
                if (lng === '' || lng === `°'"W`) {
                    setErroLongitude(true);
                    existErro = true;
    
                }
    
                if (estadoLocal === 'SC' && altitude === '') {
                    setErroAltitude(true);
                    existErro = true;
    
                }
            }
            
            if(
                bairroLocal == '' || nomeLocal == '' || cpfCnpjLocal == ''  || cepLocal == '' || logradouroLocal == '' ||
                cidadeLocal == '' || estadoLocal == '' || numeroLocal == ''
            ){
                existErro = true;

                if (bairroLocal == '') {
                    setErroBairro(true);
                }

                if (nomeLocal == '') {
                    setErroNome(true);
                }

                if (cpfCnpjLocal == '') {
                    setErroCpf(true);
                }
                
                if (cepLocal == '') {
                    setErroCep(true);
                }

                if (logradouroLocal == '') {
                    setErroEndereco(true);
                }

                if (cidadeLocal == '') {
                    setErroCidade(true);
                }

                if (estadoLocal == '') {
                    setErroEstado(true);
                }

                if (numeroLocal == '') {
                    setErroNumero(true);
                }
            }

            if (existErro === false) {

                const payload = {
                    nome: nomeLocal,
                    cpf_cnpj: cpfCnpjLocal,
                    inscricao_estadual: inscricaoEstadual,
                    cep: cepLocal,
                    endereco: logradouroLocal,
                    bairro: bairroLocal,
                    numero: numeroLocal,
                    complemento: complementoLocal,
                    cidade: cidadeLocal,
                    cod_ibge: codIbge,
                    estado: estadoLocal,
                    inscricao_orgao: numeroOrgao,
                    area_total: areaTotal,
                    cadastro_ambiental: cadastroAmbiental,
                    latitude: lat,
                    longitude: lng,
                    altitude,
                    cultura: null,
                    exibeMensagem: 1,
                    status: statusLocal
                };
        
                try {

                    const responseFarm = await api.put(`/fazendas/${id}`, payload);

                    if (responseFarm) {
                        const id_fazenda = id;

                        const payload_culturas = {
                            agro_farms_id: id_fazenda,
                            agro_producers_id: id_produtor,
                            agro_cultures_id: newcultures,
                            status: 1
                        }

                        const responseCulturas = await api.post(`/fazenda/culturas`, payload_culturas);
                        if (responseCulturas) {
                            if (responseCulturas.status === 200) {
                                setLoading(false);
                                
                                if (props.isModal) {
                                    props.openModal(false);
                                }else{
                                    props.history.push(`/produtores/${id_produtor}`);
                                }
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: "O local de aplicação foi editado corretamente.",
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 5000,
                                        onScreen: true
                                    }
                                });
                            }
                        }
                    }
                    
                } catch (err) {
                    
                    if (err.response.status === 409) {
                        setLoading(false);
                        
                        const arts = err.response.data.data.arts;
                        const contracts = err.response.data.data.contratos;
                        const payloadFarm = err.response.data.data.payload_farm;

                        resolveFarmConflict(arts, contracts, payloadFarm);
                    } else {
                        setLoading(false);
                                
                        const errors = err.response.data.errors;
        
                        if(err.response.data.message === "(Apenas alterar os dados) alterar_dados_providenciar_nova_art => 0 ou (Alterar os dados e providenciar uma nova ART) alterar_dados_providenciar_nova_art => 1"){
                            setMsg(errors);
                            setShowModal(true);
                        }else{ 
                            setLoading(false);
                            store.addNotification({
                                title: "Erro!",
                                message: `${errors}`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    }
                    
                }
                
            } else {
                setLoading(false);
                store.addNotification({
                    title: "Erro!",
                    message: "Verifique os campos em vermelho",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }

    };

    const resolveFarmConflict = async (arts, contracts, payloadFarm) => {
        setResolveConflict({
            arts, 
            contracts, 
            payloadFarm
        })

        setModalGeneric(
            <div style={{
                width: '100%',
                padding: '10px',
            }}>
                <h2>Atenção</h2>
                <p>Este local de aplicação está vinculado em {contracts.length} {contracts.length > 1 ? `contratos ativos` : `contrato ativo`}.</p>
                <p>Confirme se os dados já foram enviados ao CREA para continuar com a edição do local de aplicação.</p>
                <table style={{
                    width: '100%',
                    height: 'auto',
                    margin: 'auto',
                    marginBottom: '20px'
                }}>
                    <tr>
                        <th style={{border: '1px solid #000', padding: '5px'}}>ART</th>
                        <th style={{border: '1px solid #000', padding: '5px'}}>Existem receitas emitidas?</th>
                        <th style={{border: '1px solid #000', padding: '5px'}}>CREA</th>
                    </tr>
                    {arts.map((art, i) => (
                        <tr>
                            <td style={{border: '1px solid #000', padding: '5px'}}>{art.numero_art}</td>
                            <td style={{border: '1px solid #000', padding: '5px'}}>{art.exist_prescription === true ? 'SIM' : 'NÃO'}</td>
                            <td style={{border: '1px solid #000', padding: '5px'}}> <YesNoContainer disabled={false} loading={false}> <span>Dados enviados ao CREA?</span> <Interruptor disabled={art.exist_prescription} value={art.exist_prescription === true ? true : responseConflict[i] ? responseConflict[i].enviado_crea : false} onChange={(e) => saveFarmConflict(e, art.numero_art, art.exist_prescription, art.agro_crea_id, i)}/> </YesNoContainer></td>
                        </tr>
                    ))}
                    

                </table>
                
                <p style={{ width: '100%', fontSize: '75%' }}><span style={{ color: '#F10' }}>*</span>Para as ARTs com os dados <u>já enviados ao CREA</u> é exigido uma nova ART, nesse caso estaremos <b>inativando</b> o contrato na ART vigente.</p>
                <p style={{ width: '100%', fontSize: '75%' }}><span style={{ color: '#F10' }}>*</span>Para as ARTs com os dados <u>não enviados ao CREA</u>, estaremos <b>alterando</b> os dados sob sua responsabilidade, mantendo o local de aplicação como <b>ativo</b> na ART vigente.</p>
                <p style={{ width: '100%', fontSize: '75%' }}><span style={{ color: '#F10' }}>*</span>Para as ARTs que possuem receitas emitidas vamos considerar que os dados <b><u>já foram enviados ao CREA</u></b>.</p>

                <div 
                    className="button-finish" 
                    style={{ 
                        width: '100%', 
                        display: 'flex', 
                        flexDirection: 'row', 
                        justifyContent: 'center',
                        alignItems: 'center', 
                        marginTop: '20px',
                        marginBottom: '20px',
                    }}
                >
                    <Button 
                        width='255px'
                        height='50px'
                        value='CONFIRMAR'
                        outline={false}
                        disabled={false}
                        onClick={() => confirmFarmConflict(payloadFarm)}
                        loading={false}
                    />
                    

                </div>
            </div>
        )

        setShowModalGeneric(true);
    }

    const saveFarmConflict = (enviado, numArt, existPrescription, agroCreaId, index) => {
        let resolve = responseConflict;

        resolve[index] = {
            enviado_crea: existPrescription === true ? true : enviado, 
            agro_crea_id: agroCreaId, 
            numero_art: numArt,
            exist_prescription: existPrescription
        };

        setResponseConflict(resolve);
    }

    const confirmFarmConflict = async (payloadFarm) => {
        setModalGeneric(<></>);
        setShowModalGeneric(false);
        setLoading(true);

        const payload = {
            arts: responseConflict,
            payload_farm: resolveConflict.payloadFarm || payloadFarm
        }

        try {
            const response = await api.put(`/fazendas/${id}/confirmar`, payload);

            if (response) {
                setLoading(false);
                props.history.push(`/produtores/${id_produtor}`);

                store.addNotification({
                    title: "Sucesso!",
                    message: "O local de aplicação foi editado corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            setLoading(false);

        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        inicializa();
    },[])


    return (
        <>
            {props.isModal === true ? (
                    <h4 style={{ textAlign: 'center' }}>Edição do Local de Aplicação</h4>
            ) : (
                <GenericUpper
                    title="Edição do Local de Aplicação"
                    subtitle="Edite esta Fazenda"
                ></GenericUpper>
            )}

            <ProdutoresAddContainer { ...props }>
                <Container>
                    <form>
                        <Input
                            label="Nome da fazenda"
                            value={nomeLocal}
                            name="nome"
                            className="input"
                            id="nomeId"
                            erro={erroNome}
                            onChange={e => setNomeLocal(e)}
                            maxlength={50}
                        />

                        <Input
                            label="CPF/CNPJ"
                            value={cpfCnpjLocal}
                            name="cpfCnpj"
                            className="input"
                            id="cpf_cnpjId"
                            onChange={e => validateCpfCnpjLocal(e)}
                            onBlur={(e) => validaCpf(e.target.value)}
                            erro={erroCpf}
                            messageErro={erroCpfMsg}
                            inputmode="numeric"
                        />

                        <Input
                            label="Inscrição Estadual (Opcional)"
                            value={inscricaoEstadual}
                            name="inscricaoEstadual"
                            className="input"
                            id="inscricao_estadualId"
                            onChange={e => setInscricaoEstadual(e)}
                        />
                        <Input
                            label="CEP"
                            id='cepId'
                            value={cepLocal}
                            className="input"
                            name="cep"
                            erro={erroCep}
                            onChange={e => fetchCep(e)}
                            onBlur={(e) => validaCep(e.target.value)}
                            inputmode="numeric"
                        />

                        <Input
                            label="Logradouro"
                            value={logradouroLocal}
                            name="logradouroLocal"
                            className="input"
                            id="enderecoId"
                            erro={erroEndereco}
                            onChange={e => setLogradouroLocal(e)}
                        />

                        <Input
                            label="Número"
                            value={numeroLocal}
                            name="numeroLocal"
                            className="input"
                            id="numeroId"
                            erro={erroNumero}
                            onChange={e => setNumeroLocal(e)}
                        />
                        <Input
                            label="Complemento(Opcional)"
                            value={complementoLocal}
                            name="complementoLocal"
                            className="input"
                            id="complementoLocalId"
                            onChange={e => setComplementoLocal(e)}
                        />

                        <Input
                            label="Bairro"
                            value={bairroLocal}
                            name="bairroLocal"
                            className="input"
                            id="bairroId"
                            erro={erroBairro}
                            onChange={e => setBairroLocal(e)}
                        />

                        <Input
                            label="Cidade"
                            value={cidadeLocal}
                            name="cidadeLocal"
                            className="input"
                            id="cidadeId"
                            erro={erroCidade}
                            onChange={e => setCidadeLocal(e)}
                        />

                        <Select
                            label="Estado"
                            value={estadoLocal}
                            name="estado"
                            className="select"
                            id="estadoId"
                            // onChange={state => this.setState({ estadoLocal: state.value })}
                            onChange={state => setEstadoLocal(state.value)}
                            options={estados}
                            onBlur={() => limpaCep()}
                            estado={true}
                            erro={erroEstado}
                            disabled={estadoLocal.value || estadoLocal ? true : false}
                        />

                        <Input
                            label="Código IBGE"
                            value={codIbge}
                            name="codIbge"
                            className="input"
                            id="cod_ibge"
                            onChange={e => setCodIbge(e)}
                            inputmode="numeric"
                        />
                        <Input
                            label="Número de inscrição do produtor ao órgão estadual (opcional)"
                            value={numeroOrgao}
                            name="numeroOrgao"
                            className="input"
                            id="numeroOrgaoId"
                            onChange={e => setNumeroOrgao(e)}
                        />
                        <Input
                            label="Área Total (ha) (opcional)"
                            value={areaTotal}
                            name="areaTotal"
                            className="input"
                            id="areaTotalId"
                            onChange={e => setAreaTotal(e)}
                            marginBottom='20px'
                            inputmode="numeric"
                        />

                        <div style={{ width: '100%', zIndex:3, marginBottom: '35px', display: 'flex', flexDirection: 'column'}}>
                            <label style={{marginLeft: '20px'}} id="labelcultura">Culturas (opcional)</label>
                            <Multiselect
                                className="multiSelectCulturas"
                                label="Culturas"
                                options={culturas}
                                placeholder="" // Options to display in the dropdown
                                selectedValues={selectedValues}
                                closeIcon="close"
                                onSelect={(list, item) => onSelect(list, item)}
                                onRemove={(list, item) => onRemove(list, item)}
                                displayValue="name" // Property name to display in the dropdown options 
                            />

                        </div>

                        <Input
                            label="Cadastro Ambiental Rural (opcional)"
                            value={cadastroAmbiental}
                            name="cadastroAmbientalRural"
                            className="input"
                            id="cadastroAmbientalRuralId"
                            // onChange={e => this.setState({ cadastroAmbiental: e })}
                            onChange={e => setCadastroAmbiental(e)}
                            marginBottom='20px'
                        />

                        <Select
                            label={estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP' ? "Selecionar coordenadas geográficas (Obrigatório)" : "Selecionar coordenadas geográficas (Opcional)"}
                            name="select_coordinates"
                            className="select"
                            id="select_coordinates"
                            onChange={(e) => changeModo(e)}
                            options={[
                                {value: 1, label: 'Selecionar no mapa'},
                                {value: 2, label: 'Digitar coordenadas'},

                            ]}
                            value={modoCoord}
                            estado={false}
                            loading={false}
                            disabled={false}
                            erro={erroLatitude == true || erroLongitude == true || erroAltitude == true ? true : false}
                            messageErro={false}
                        />


                        {latitude && longitude? (
                            <MapaArea 
                                label="Selecione o local de aplicação"
                                onChange={(lat, lng) => changeCoordinates(lat, lng)}
                                initialLat={latitude}
                                initialLng={longitude}
                            />
                        ) : (<>
                            {showMap ? (
                                <MapaArea 
                                    label="Selecione o local de aplicação"
                                    onChange={(lat, lng) => changeCoordinates(lat, lng)}
                                />
                            ) : showInput ? (
                                <>
                                    <label className='labelcoord'>{estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP' ? "Latitude (Obrigatório)": "Latitude (opcional)"}</label>
                                    <div className="coord">
                                        <div className="partCoord">
                                            <Input
                                                label='Graus'
                                                name="latitude"
                                                className="input"
                                                id="lgraus"
                                                value={latitudeGraus}
                                                maxLength={2}
                                                onChange={e => validateLatitude(1, e)}
                                                erro={erroLatitude}
                                                inputmode="numeric"
                                            />
                                            <span>°</span>
                                        </div>
                                        
                                        <div className="partCoord">
                                            <Input
                                                label='Minutos'
                                                name="latitude"
                                                className="input"
                                                id="lminutos"
                                                value={latitudeMinutos}
                                                maxLength={2}
                                                onChange={e => validateLatitude(2, e)}
                                                style={{marginTop: '16px'}}
                                                erro={erroLatitude}
                                                inputmode="numeric"

                                            />
                                            <span>'</span>
                                        </div>

                                        <div className="partCoord">
                                            <Input
                                                label='Segundos'
                                                name="latitude"
                                                className="input"
                                                id="lsegundos"
                                                value={latitudeSegundos}
                                                maxLength={4}
                                                onChange={e => validateLatitude(3, e)}
                                                style={{marginTop: '16px'}}
                                                erro={erroLatitude}
                                                inputmode="numeric"
                                            />
                                            <span>"S</span>
                                        </div>
                                    </div>

                                    <label className='labelcoord'>{estadoLocal === "ES" || estadoLocal === "PR" || estadoLocal === "GO" || estadoLocal === 'SC' || estadoLocal === 'SP' ? "Longitude (Obrigatório)": "Longitude (opcional)"}</label>
                                    <div className="coord">
                                        <div className="partCoord">
                                            <Input
                                                label='Graus'
                                                name="longitude"
                                                className="input"
                                                id="longrau"
                                                value={longitudeGraus}
                                                maxLength={2}
                                                onChange={e => validateLongitude(1, e)}
                                                erro={erroLongitude}
                                                inputmode="numeric"

                                            />
                                            <span>°</span>
                                        </div>
                                        
                                        <div className="partCoord">
                                            <Input
                                                label='Minutos'
                                                name="longitude"
                                                className="input"
                                                id="lonminutos"
                                                value={longitudeMinutos}
                                                maxLength={2}
                                                onChange={e => validateLongitude(2, e)}
                                                style={{marginTop: '16px'}}
                                                erro={erroLongitude}
                                                inputmode="numeric"

                                            />
                                            <span>'</span>
                                        </div>

                                        <div className="partCoord">
                                            <Input
                                                label='Segundos'
                                                name="longitude"
                                                className="input"
                                                id="lonsegundos"
                                                value={longitudeSegundos}
                                                maxLength={4}
                                                onChange={e => validateLongitude(3, e)}
                                                style={{marginTop: '16px'}}
                                                erro={erroLongitude}
                                                inputmode="numeric"

                                            />  
                                            <span>"W</span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </>)}

                        {estadoLocal === 'SC' ? (
                            <Input
                                label="Altitude (Obrigatório)"
                                value={altitude}
                                name="altitude"
                                className="input"
                                id="altitude"
                                onChange={e => {
                                    setAltitude(e);
                                    setErroAltitude(false);
                                }}
                                unidade="Metros"
                                erro={erroAltitude}
                                inputmode="numeric"
                            />
                        ) : (null)}

                        <Select
                            name="status"
                            value={statusLocal}
                            label="Status"
                            className="select"
                            id="status"
                            onChange={status => setStatusLocal(status.value)}
                            options={[
                                {label: 'Ativo', value: 1},
                                {label: 'Inativo', value: 0}
                            ]}
                        />

                        <ModalEditLocal
                            show={showModal}
                            onHide={() => modalClose()}
                            resposta=''
                            update={(e) => updateResposta(e)}
                            title='Atenção!'
                            text={msg}
                        ></ModalEditLocal>

                        <ModalYesNo
                            show={showModalYesNo}
                            onHide={() => modalClose()}
                            resposta=''
                            update={(e) => updateRespostaYesNo(e)}
                            title='Atenção!'
                            text={msg}
                            btnNo={true}
                        ></ModalYesNo>

                        <ModalText
                            show={showModalText}
                            onHide={() => closeOk()}
                            text={msg2}
                            btnOk={true}
                            btnCancelar={true}
                            update={(e) => updateOk(e)}
                        ></ModalText>

                        <Modal
                            show={showModalGeneric}
                            onHide={() => {
                                setShowModalGeneric(false);
                                setModalGeneric(false);
                            }}
                        >
                            {modalGeneric}
                        </Modal>

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Button 
                                width='45%'
                                height='50px'
                                value='VOLTAR'
                                outline={true}
                                disabled={false}
                                onClick={() => goBack()}
                                loading={false}
                            />
                            
                            <Button 
                                width='45%'
                                height='50px'
                                value='SALVAR'
                                outline={false}
                                disabled={false}
                                onClick={() => editPost()}
                                loading={loading}
                            />

                        </div>
                    </form>
                </Container>
            </ProdutoresAddContainer>
        </>
    )

}

export default withRouter(EditLocal);
