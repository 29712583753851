import React from "react";
import { SimpleTableContainer, TableContainer } from "./styles";

const SimpleTable = ({ ...props }) => {
  //Snippet que eu peguei do StackOverflow
  //Não entendi como funciona, mas funciona perfeitamente
  let asc = true;
  const getCellValue = (tr, idx) =>
    tr.children[idx].innerText || tr.children[idx].textContent;

  const comparer = (idx, asc) => (a, b) =>
    ((v1, v2) =>
      v1 !== "" && v2 !== "" && !isNaN(v1) && !isNaN(v2)
        ? v1 - v2
        : v1.toString().localeCompare(v2))(
          getCellValue(asc ? a : b, idx),
          getCellValue(asc ? b : a, idx)
        );

  document.querySelectorAll("th").forEach(th =>
    th.addEventListener("click", () => {
      // th.onLoad(), () => {
      const table = document.querySelector("tbody");
      Array.from(table.querySelectorAll("tr"))
        .sort(
          // comparer(Array.from(th.parentNode.children).indexOf(th), (asc = !asc))
          comparer(Array.from(th.parentNode.children).indexOf(th), (asc))

        )
        .forEach(tr => table.appendChild(tr));
    })
  );
  return <SimpleTableContainer>{props.children}</SimpleTableContainer>;
};

export default SimpleTable;
