import React, { useState, useEffect } from 'react';
import { Label, Mapa, AreaMapa } from './style';
import GoogleMap from 'google-map-react';
import Marker from './marker';

export const GOOGLE_MAPA_VERTICES = '@agroreceita_google_mapa_vertices';

const MapaArea = ({ ...props }) => {

    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [onMarkerClick, setOnMarkerClick] = useState('');
    const [fullScreen, setFullScreen] = useState(false);

    const [coordinates, setCoordinates] = useState({});
    const [centerCoordinates, setCenterCoordinates] = useState({});
    const [fullScreenControll, setFullScreenControll] = useState(false);

  const changeCoordinates = (map) => {
    setCoordinates({
      lat: map.lat,
      lng: map.lng,
    });
  }

    useEffect(() => {
        if (props.initialLat && props.initialLng) {
          //Casos de edição em que as coordenadas já vem preenchidas

          let lat = parseFloat(props.initialLat);
          let lng = parseFloat(props.initialLng);

          setLatitude(lat);
          setLongitude(lng);
          setCoordinates({
            lat: lat,
            lng: lng
          });
          setCenterCoordinates({
            lat: lat,
            lng: lng
          });

        } else {
          navigator.geolocation.getCurrentPosition(function(position){
  
              setCoordinates({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
              });
  
              setCenterCoordinates({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
              });
  
          },function(error){
              setCoordinates({
                  lat: -12.870424,
                  lng: -54.9476226
              });
              setCenterCoordinates({
                  lat: -12.870424,
                  lng: -54.9476226
              });
          })
        }

    }, [])

    useEffect(() => {
        props.onChange(coordinates.lat, coordinates.lng);
    }, [coordinates]);

    setTimeout(() => {
      var btnFullScreen = document.getElementsByClassName('gm-control-active gm-fullscreen-control');
      if (btnFullScreen.length > 0) {
        if (fullScreenControll === false) {
          setFullScreenControll(true);
        }
        btnFullScreen[0].onclick = function() {
          setFullScreen(!fullScreen);
        } 
      }
    }, 3000);


    return (
        <>
            <AreaMapa
              fullScreen={fullScreen}
            >

                <Label 
                  erro={false}
                  isFocus={true}
                  existValue={true}
                  onClick={() => console.log('')}
                  isDisabled={false}
                  isDate={false}
                >
                  {props.label}
                </Label>
                {console.log(fullScreenControll)}
                {/* {fullScreenControll === false && (
                  <>
                    {changeCoordinates(coordinates)}
                  </>
                )} */}
                <GoogleMap
                  bootstrapURLKeys={{
                    key: 'AIzaSyAn3UaVotrho9E6f_BWtW4wDshxnEmM8LI',
                    libraries: 'places,drawing',
                    language: 'pt'
                  }}
                  center={centerCoordinates}
                  defaultZoom={17}
                  options={{
                    streetViewControl: false,
                    scaleControl: true,
                    fullscreenControl: fullScreenControll,
                    mapTypeControl: true,
                    mapTypeId: 'hybrid',  //Nomes de rua habilitados por padrão
                    mapTypeControlOptions: true,
                    panControl: true,
                    zoomControl: true,
                    overviewMapControl: true,
                    rotateControl: true,
                    draggableCursor: 'default',  //cursor do maude padrao

                    //show places
                    styles: [{
                      "featureType": "all",
                      "elementType": "labels",
                      "stylers": [{
                        "visibility": "#on"
                      }]
                    }]
                  }}
                  onClick={(map) => changeCoordinates(map)}
                >
                    <Marker
                      fullScreen={fullScreen}
                      lat={coordinates.lat}
                      lng={coordinates.lng}
                      color={'#2fbb69'}
                    />
                </GoogleMap>
            </AreaMapa>
        </>
    )
}

export default MapaArea;