import React from "react";

import estados from "../../services/estados";
import api from "../../services/api";
import { store } from "react-notifications-component";
import GenericUpper from "../../components/generic-upper";
import { FiliaisAddContainer } from "./styles";
import { Container } from "react-grid-system";
import Input from "../../components/input";
import Button from "../../components/button";
import Select from "../../components/select";
import buscaCep from "../../services/buscaCep";

export const USER = "@userinfo";
class AddFiliais extends React.Component {
    state = {
        id: "",
        nome_fantasia: "",
        razao_social: "",
        cnpj: "",
        telefone: "",
        cep: "",
        endereco: "",
        cidade: "",
        bairro: "",
        numero: "",
        estado: "",
        telefoneEmergencia: "",
        file: ""
    };

    fetchCep = async () => {
        const { cep } = this.state;

        try {
            const responseCep = await buscaCep(cep);
            const { logradouro, localidade, bairro, uf } = responseCep;

            this.setState({
                endereco: logradouro,
                cidade: localidade,
                bairro: bairro,
                estado: uf
            });

        } catch (err) {
            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    handlePost = async e => {
        e.preventDefault();
        const {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            telefoneEmergencia,
            cep,
            endereco,
            cidade,
            bairro,
            numero,
            estado,
            file
        } = this.state;

        const payload = {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            telefone_emergencia: telefoneEmergencia,
            logo: file,
            cep,
            endereco,
            bairro,
            numero,
            cidade,
            estado,
            is_matriz: 1
        };

        try {
            const response = await api.put(`/filiais/${this.state.id}`, payload);

            if (response.status === 200) {
                this.props.history.push("/filiais");
                store.addNotification({
                    title: "Sucesso!",
                    message: "A empresa foi salva corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (err) {
            let inputWithErrors = document.querySelectorAll(".error");

            for (let input of inputWithErrors) {
                input.classList.remove("error");
            }

            const errors = err.response.data.errors;
            for (let field in errors) {
                const input = document.getElementById(field);
                input.classList.add("error");
            }

            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos em vermelho",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    validateIsMatriz(){
        let user = localStorage.getItem(USER);
        let obj = JSON.parse(user);

        try{
            if(obj.agro_subsidiary_id == obj.parent_id){
                return true
            }else{
                return false
            }
        }catch(e){
        }
        
        
    }

    componentDidMount = () => {
        if(this.validateIsMatriz() == true){
            this.getFiliais();
        }else{
            window.location.href = '/404';
        }
    }

    getFiliais = async () => {
        try {
            let user = localStorage.getItem(USER);
            let obj = JSON.parse(user);
    
            const response = await api.get(`/filiais/${obj.agro_subsidiary_id}`);
            const filiais = response.data.data;
    
            const {
                id,
                nome_fantasia,
                razao_social,
                cnpj,
                telefone,
                cep,
                endereco,
                numero,
                bairro,
                cidade,
                estado,
                telefone_emergencia
            } = filiais;
    
            this.setState({
                id,
                nome_fantasia,
                razao_social,
                cnpj,
                telefone,
                cep,
                endereco,
                numero,
                bairro,
                cidade,
                estado,
                telefoneEmergencia: telefone_emergencia
            });
            
        } catch (error) {
        }
    };
    render() {
        const {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            cep,
            endereco,
            cidade,
            bairro,
            numero,
            estado,
            telefoneEmergencia
        } = this.state;
        return (
            <>
                <GenericUpper
                    title="Minha empresa"
                    subtitle=""
                ></GenericUpper>

                <FiliaisAddContainer>
                    <Container>
                        <form onSubmit={this.handlePost}>
                            <Input
                                name="nome_fantasia"
                                type="text"
                                placeholder="Nome Fantasia"
                                id="nome_fantasia"
                                value={nome_fantasia}
                                onChange={e => this.setState({ nome_fantasia: e.target.value })}
                            />

                            <Input
                                name="razao_social"
                                type="text"
                                placeholder="Razão Social"
                                id="razao_social"
                                value={razao_social}
                                onChange={e => this.setState({ razao_social: e.target.value })}
                            />

                            <Input
                                name="cnpj"
                                type="text"
                                placeholder="CNPJ"
                                id="cnpj"
                                value={cnpj}
                                onChange={e => this.setState({ cnpj: e.target.value })}
                                mask="99.999.999/9999-99"
                            />

                            <Input
                                name="telefone"
                                type="text"
                                placeholder="Telefone"
                                id="telefone"
                                value={telefone}
                                onChange={e => this.setState({ telefone: e.target.value })}
                                mask="(99) 9999-9999"
                            />

                            <Input
                                name="telefoneEmergencia"
                                type="text"
                                placeholder="Telefone de emergência"
                                id="telefoneEmergencia"
                                value={telefoneEmergencia}
                                onChange={e => this.setState({ telefoneEmergencia: e.target.value })}
                            />

                            <Input
                                name="cep"
                                type="text"
                                placeholder="CEP"
                                mask="99999-999"
                                id="cep"
                                value={cep}
                                onChange={e => this.setState({ cep: e.target.value })}
                                onBlur={this.fetchCep}
                            />

                            <Input
                                name="endereco"
                                type="text"
                                placeholder="Endereço"
                                id="endereco"
                                value={endereco}
                                onChange={e => this.setState({ endereco: e.target.value })}
                            />

                            <Input
                                name="numero"
                                type="number"
                                placeholder="Número"
                                id="numero"
                                value={numero}
                                onChange={e => this.setState({ numero: e.target.value })}
                            />

                            <Input
                                name="bairro"
                                type="text"
                                placeholder="Bairro"
                                id="bairro"
                                value={bairro}
                                onChange={e => this.setState({ bairro: e.target.value })}
                            />

                            <Input
                                name="cidade"
                                type="text"
                                placeholder="Cidade"
                                id="cidade"
                                value={cidade}
                                onChange={e => this.setState({ cidade: e.target.value })}
                            />

                            <Select
                                name="estado"
                                className="select"
                                placeholder="Estado"
                                id="estado"
                                value={estado}
                                onChange={state => this.setState({ estado: state.value })}
                                options={estados}
                            />

                            <input 
                                type="file"
                                name="file"
                                multiple
                                onChange={e => this.setState({file: e.target.value})}
                            />

                            <Button style={{outline:0}} color="#00b43f" type="submit">
                                Salvar <i className="fa fa-arrow-right"></i>
                            </Button>
                        </form>
                    </Container>
                </FiliaisAddContainer>
            </>
        );
    }
}

export default AddFiliais;
