import React from "react";

import GenericUpper from "../../components/generic-upper";
import api from "../../services/api";
import { Container } from "react-grid-system";
import { FiliaisContainer } from "./styles";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import Button from "../../components/button";
import DotsLoader from "../../components/loader";
import { Link } from "react-router-dom";
import InputSearch from "../../components/input-search";
import ModalFuncaoBloqueada from '../../components/modal/modalFuncaoBloqueada';
import ModalTesteGratisAcabou from '../../components/modal/modalFuncaoBloqueada';

import CardGeral from "../../components/card-geral/index";

export const USER = "@userinfo";
export const VAL = "@validade";

class Filiais extends React.Component {
    state = {
        filiais: [],
        limit: 5,
        loading: true,
        hideButton: false,
        offSet: 0,
        result: "",
        result2: "",
        size_screen: window.screen.width,
        user: JSON.parse(localStorage.getItem(USER)),
        val: JSON.parse(localStorage.getItem(VAL)),
        modalTesteGratis: false,
        modalTesteGratisAcabou: false,
    };

    shouldComponentUpdate(width) {
        let shouldUpdate = this.state.size_screen !== width;
        return shouldUpdate;
    }

    componentDidUpdate(){
        this.size_screen = window.screen.width
    }

    componentDidMount() {
        const {user} = this.state;
        setInterval(async()=>{
            this.setState({size_screen: window.screen.width});
        }, 990);
        this.shouldComponentUpdate(window.screen.width);
        this.getFiliais();
    }

    updateValues = async (text, estado, search) => {
        if (search == 1) {
            if(!text && !estado){
                this.search('', '', 30, 0);
            }else{   
                this.setState({ result2: estado });
                this.setState({ result: text });
                this.search(text, estado, 30, 0);
            }
        }else{
            this.setState({result: text});
            this.setState({result2: estado});

        }
    }

    getFiliais = async () => {
        try {
            const { limit, offSet } = this.state;
            const response = await api.get("/filiais?limit=" + limit + "&offset=" + offSet);
            const filiais = response.data.data;
            
            if (filiais && filiais.length > 0) {
                this.setState({ filiais, loading: false, hideButton: false });
            } else {
                this.setState({ filiais: [filiais], loading: false, hideButton: false });
            }


            
        } catch (e) {

        }
    };

    getXlms = async () => {
        try {
            const response = await api.get("/relatorios/filiais", {
                responseType: "blob"
            });
    
            let blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "RelatorioFiliais.xlsx";
            link.href = url;
            link.click();
            
        } catch (error) {
        }
    };

    search = async () => {
        try {
            const { result, result2 } = this.state;
            if (result.length < 1 && result2.length < 1) {
                return;
            }
    
            const response = await api.get("/filiais");
            let filtredFiliais = response.data.data;
            if (result) {
                filtredFiliais = filtredFiliais.filter(city => {
                    return city.cidade
                        .toLocaleLowerCase()
                        .match(`${result.toLocaleLowerCase()}`);
                });
            }
            if (result2) {
                filtredFiliais = filtredFiliais.filter(local => {
                    return local.estado.match(`${result2}`);
                });
            }
            this.setState({
                hideButton: true,
                filiais: filtredFiliais
            });
            
        } catch (error) {
        }
    };

    cleanFilter = () => {
        this.setState({
            result: "",
            result2: "",
            offSet: 0
        });
        this.getFiliais();
    };

    LoadMore = async () => {
        this.setState({ loading: true, hideButton: true });
        const { filiais, limit, offSet } = this.state;
        const newOffset = limit + offSet;

        try {
            
            const response = await api.get(
                "/filiais?limit=" + limit + "&offset=" + newOffset
            );
            let newfiliais = response.data.data;
            
            const filiaisConCat = filiais.concat(newfiliais);
            
            this.setState({
                filiais: filiaisConCat,
                offSet: newOffset,
                hideButton: false
            });
        } catch (erro) {
            this.setState({
                hideButton: true
            });
        }
        this.setState({ loading: false });
    };

    onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.search();
        }
    }

    showModal = () => {
        this.setState({modalTesteGratis: true});
    }

    render() {
        const { filiais, loading, hideButton, result, user, size_screen, modalTesteGratis, modalTesteGratisAcabou, val } = this.state;

        return (
            <>
                <GenericUpper
                    title="Cadastros"
                    subtitle="Gerencie sua empresa e filiais"
                    search={true}
                    searchFunction={(text, estado, search) => this.updateValues(text, estado, search)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    addButton={true}
                    tooltip={"Adicionar"}
                    placeholder={'Cidade'}
                    linkSearch={user.contract_software_id == 6 ? ()=>this.showModal() : '/filiais/add'}
                ></GenericUpper>
                <FiliaisContainer>
                    <Container style={size_screen <= 768 ? {paddingLeft:"0px", paddingRight:"0px", margin: 'auto'} : {}}>

                    {size_screen <= 768 ? (<>

                        <div className="search" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '0px', width: '100%'}}>

                            <CardGeral
                                titulos={ ["FILIAIS","Nome", "Empresa","Cidade","Estado"]}
                                valores={filiais}
                            />

                            <DotsLoader style={{alignSelf: 'center'}} show={loading}></DotsLoader>
                            {filiais.length >= 5 && (
                                <Button
                                    style={{ outline:0, alignSelf: 'center' ,marginBottom: "17px",padding: '10px', width: '87%', maxWidth: '400px', padding: '15px'}}
                                    hide={hideButton}
                                    outline
                                    onClick={this.LoadMore}
                                    color="#00b43f"
                                >
                                    VER MAIS
                                </Button>

                            )}

                            <Button
                                style={{ outline:0, alignSelf: 'center' ,padding: '10px', width: '87%', maxWidth: '400px', padding: '15px'}}
                                onClick={this.getXlms}
                                outline
                                color="#00b43f"
                                >
                                Relatório
                            </Button>
                        </div>
                    </>) : (<>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Empresa</th>
                                    <th>Cidade</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            {this.state.filiais ? (<> 
                            
                                <TBody>
                                    {filiais.map(filial => (
                                        <>
                                            {filial.id ? (<> 
                                                <tr
                                                    onClick={(e) =>{
                                                        if(user.contract_software_id == 6 && val == 0) {
                                                            this.setState({modalTesteGratisAcabou: true})
                                                        }else{
                                                            this.props.history.push(`filiais/${filial.id}`)
                                                        }
                                                    }}
                                                    key={filial.id}
                                                    >
                                                    <td>{filial.nome_fantasia}</td>
                                                    {filial.is_matriz === 1 ? (<td>Matriz</td>):(<td>Filial</td>)}
                                                    <td>{filial.cidade}</td>
                                                    <td><div className="estado">{filial.estado}</div></td>
                                                </tr>
                                            
                                            </>):(null)}

                                        </>
                                    ))}
                                </TBody>
                            
                            </>):(null)}
                        </Table>
                        <DotsLoader show={loading}></DotsLoader>


                        <div className="botoesfinais">

                            {filiais.length >= 5 ? (
                                <Button
                                style={{outline:0}} 
                                className="loadmore"
                                hide={hideButton}
                                outline
                                onClick={this.LoadMore}
                                color="#00b43f"
                                >
                                    VER MAIS
                                </Button>
                            ):(null)}

                            <Button
                            style={{outline:0}} 
                                className="relatorio"
                                onClick={this.getXlms}
                                outline
                                color="#00b43f"
                                >
                                Relatório
                            </Button>
                        </div>
                    </>)}

                        <ModalTesteGratisAcabou
                            show={modalTesteGratisAcabou}
                            title="Essa é uma função exclusiva para assinantes..."
                            subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                            onHide={() => {
                                this.setState({modalTesteGratisAcabou: false})
                                window.location.href="/"
                            }}
                        ></ModalTesteGratisAcabou>

                        <ModalFuncaoBloqueada
                            show={modalTesteGratis}
                            onHide={() => {
                                this.setState({modalTesteGratis: false})
                                window.location.href="/"
                            }}
                        ></ModalFuncaoBloqueada>
                    </Container>
                </FiliaisContainer>
            </>
        );
    }
}

export default Filiais;
