import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
import Button from '../button/index';

// import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from '.';

class ModalLoginErrado extends React.Component {

    state = {
        disabled: true
    }

    constructor(props) {
        super(props);
    }

    updateResponseYes = () => {
      let resposta = 1;
      this.props.update(resposta);
    }
    
    updateResponseNo = () => {
      let resposta = 0;
      this.props.update(resposta);
    }

    validateConfirmar = (value) => {
        
        if(value == "CANCELAR"){
            this.setState({disabled: false});
        }else{
            this.setState({disabled: true});
        }
    }

    render() {
        return (

            // <Modal
            //     {...this.props}
            //     size="100px"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton style={{backgroundColor: "transparent", border: '0px', zIndex: '9'}}>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //         </Modal.Title>
            //     </Modal.Header >

            //     <Modal.Body style={{backgroundColor: " #fff", borderRadius: '3px', marginTop: '-38px'}}>
            //         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
            //             <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.title}</p>
            //             <p style={{marginTop: '-10px', color: '#bac1cf', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.text}</p>
                        
            //             <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', flexDirection: 'row-reverse'}}>
            //                 <Button style={{ outline:0,padding: "10px", width:"120px", height: "38px"}}
            //                     outline
            //                     color="#00b43f"
            //                     onClick={() => this.updateResponseNo()}
            //                 >CRIAR CONTA</Button>
            //                 <p style={{marginTop: '7px', marginRight: "15px"}}>Não possui cadastro?</p>
            //             </div>
            //         </div>
            //     </Modal.Body>

            // </Modal>


            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
            >
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column'}}>
                    <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.title}</p>
                    <p style={{marginTop: '-10px', color: '#bac1cf', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.text}</p>
                
                    <div style={{ marginTop: '15px', display: 'flex', justifyContent: 'center', flexDirection: 'row-reverse'}}>
                        
                        <Button style={{ outline:0,padding: "10px", width:"120px", height: "38px"}}
                            outline
                            color="#00b43f"
                            onClick={() => this.updateResponseNo()}
                        >
                            CRIAR CONTA
                        </Button>
                        
                        <p style={{marginTop: '7px', marginRight: "15px"}}>Não possui cadastro?</p>
                    </div>
                </div>
            </Modal>

        );
    }
}
export default ModalLoginErrado;