import React, {useEffect, useState} from "react";
import IconCheck from "../../assets/check.svg";
import Arvore01 from "../../assets/arvore-1.png";
import Arvore02 from "../../assets/arvore-2.png";
import Arvore03 from "../../assets/arvore-3.png";
import Arvore04 from "../../assets/arvore-4.png";
import Arvore05 from "../../assets/arvore-5.png";
import Arvore06 from "../../assets/arvore-6.png";
import Arvore07 from "../../assets/arvore-7.png";

import { 
    Col, 
    Header, 
    ErrorPrescriptionStyleLine,
    ErrorPrescriptionStyleImage,
    LastPrescriptionStyle, 
    Loading, 
    Row, 
    ArvoreImg,
    Steps,
    StepNext
} from "./style";

import ProgressBar from "../progress-bar";

const LastPrescriptionError = ({ ...props }) => {
    const {isMobile} = props;
    const [step01, setStep01] = useState(false);
    const [step02, setStep02] = useState(false);
    const [step03, setStep03] = useState(false);
    const [completed, setCompleted] = useState(0);

    const calculaPorcentagem = () =>{
        let comp = 0;
        if (props.estado == "SP") {
            if (props.agronomos == true) {
                comp = comp + 15;
            }
    
            if (props.art  == true) {
                comp = comp + 15;
            }
            
            if (props.contrato  == true) {
                comp = comp + 15;
            }
            
            if (props.crea == true) {
                comp = comp + 15;
            }
            
            if (props.devolucao == true) {
                comp = comp + 15;
            }
            
            if (props.fazendas == true) {
                comp = comp + 15;
            }
            
            if (props.produtores == true || props.produtoresGeral) {
                comp = comp + 15;
            }
        }else if (props.estado == "RJ") {
            if (props.agronomos == true) {
                comp = comp + 20;
            }
            
            if (props.crea == true) {
                comp = comp + 20;
            }
            
            if (props.devolucao == true) {
                comp = comp + 20;
            }
            
            if (props.fazendas == true) {
                comp = comp + 20;
            }
            
            if (props.produtores == true || props.produtoresGeral) {
                comp = comp + 20;
            }
        }else{
            if (props.agronomos == true) {
                comp = comp + 17;
            }
    
            if (props.art  == true) {
                comp = comp + 17;
            }
            
            if (props.crea == true) {
                comp = comp + 17;
            }
            
            if (props.devolucao == true) {
                comp = comp + 17;
            }
            
            if (props.fazendas == true) {
                comp = comp + 17;
            }
            
            if (props.produtores == true || props.produtoresGeral) {
                comp = comp + 17;
            }
        }

        return comp;
    }

    useEffect(()=>{
        if (props.agronomos === true && props.crea === true && props.art === true) {
            if (props.estado == "SP") {
                if (props.contrato === true) {
                    setStep01(true);
                }else{
                    setStep01(false);
                }
            }else{
                setStep01(true);
            }
        }

        if (props.produtores === true && props.fazendas == true) {
            if (props.agronomos === true && props.crea === true && props.art === true) {
                if (props.estado == "SP" && props.contrato == false) {
                    setStep02(false);
                }else{
                    setStep02(true);
                }
            }
        }

        if (props.devolucao === true) {
            if (props.produtores === true && props.fazendas == true) {
                if (props.agronomos === true && props.crea === true && props.art === true) {
                    if (props.estado == "SP" && props.contrato == false) {
                        setStep03(false);
                    }else{
                        setStep03(true);
                    }
                }
            }
        }

        let valorP = calculaPorcentagem();
        setCompleted(valorP);
    }, [props.agronomos,props.crea, props.art, props.contratos, props.produtores, props.fazendas, props.devolucao, props.produtoresGeral]);

    return (
        <LastPrescriptionStyle>
            {
                props.inicio === true ? (
                    <>
                        <Loading />
                    </>
                ) : (
                    <>
                        <Row>
                            <Header>
                                <div>
                                    <h2>CADASTROS INICIAIS</h2>
                                </div>
                            </Header>
                        </Row>

                        <hr/>

                        <Row>
                            <ProgressBar bgcolor={"#53C36E"} completed={completed > 100 ? 100 : completed} isMobile={isMobile}/>
                        </Row>

                        <Row>
                            <Col>
                                <Steps>
                                    <Col>
                                        <Row margin='0px' padding='0px' flexDirectrionResp='row'>
                                            <ErrorPrescriptionStyleImage backgroundColor={step01 == true ? "rgb(83,195,110)" : "rgb(243, 243, 245)"}>
                                                {step01 == true ? <img src={IconCheck}></img> : null}
                                            </ErrorPrescriptionStyleImage>

                                            <StepNext onClick={()=>
                                                props.agronomos == false ? window.location = "/agronomos/add" :
                                                props.estado !== "RJ" && props.art == false ? window.location = "/arts/add" : 
                                                props.estado == "SP" && props.contrato == false && props.artId != 0 ? window.location = `/newcontract/${props.artId}` : 
                                                props.produtoresGeral == false || (props.produtores == false && props.crea == true) ? window.location = "/produtores/add/" :
                                                props.fazendas == false && props.prodId != 0 ? window.location = `/localdeaplicacao/add/${props.prodId}` : 
                                                props.devolucao == false ? window.location = "/devolucoes/add" : null}
                                            >
                                                Responsável Técnico (CREA/ART)
                                            </StepNext>
                                        </Row>
                                        
                                        <Col alignItems='flex-start' flexDirectrionResp='column' alignItemsResp='flex-start' margin='0px'>
                                            <ErrorPrescriptionStyleLine backgroundColor={step01 == true ? "rgb(83,195,110)" : "rgb(243, 243, 245)"} />
                                            <ErrorPrescriptionStyleLine backgroundColor={step02 == true ? "rgb(83,195,110)" : "rgb(243, 243, 245)"} />
                                        </Col>

                                        <Row margin='0px' padding='0px' flexDirectrionResp='row'>
                                            <ErrorPrescriptionStyleImage backgroundColor={step02 == true ? "rgb(83,195,110)" : "rgb(243, 243, 245)"}>
                                                {step02 == true ? <img src={IconCheck}></img> : null}
                                            </ErrorPrescriptionStyleImage>

                                            <StepNext onClick={()=>
                                                props.produtoresGeral == false || (props.produtores == false && props.crea == true) ? window.location = "/produtores/add/" :
                                                props.fazendas == false && props.prodId != 0 ? window.location = `/localdeaplicacao/add/${props.prodId}` : 
                                                props.agronomos == false ? window.location = "/agronomos/add" :
                                                props.estado !== "RJ" && props.art == false ? window.location = "/arts/add" : 
                                                props.estado == "SP" && props.contrato == false && props.artId != 0 ? window.location = `/newcontract/${props.artId}` : 
                                                props.devolucao == false ? window.location = "/devolucoes/add" : null
                                            }
                                            >
                                                Produtor e Local de aplicação
                                            </StepNext>
                                        </Row>
                                        
                                        <Col alignItems='flex-start' flexDirectrionResp='column' alignItemsResp='flex-start' margin='0px'>
                                            <ErrorPrescriptionStyleLine backgroundColor={step02 == true ? "rgb(83,195,110)" : "rgb(243, 243, 245)"} />
                                            <ErrorPrescriptionStyleLine backgroundColor={step03 == true ? "rgb(83,195,110)" : "rgb(243, 243, 245)"} />
                                        </Col>

                                        <Row margin='0px' padding='0px' flexDirectrionResp='row'>
                                            <ErrorPrescriptionStyleImage backgroundColor={step03 == true ? "rgb(83,195,110)" : "rgb(243, 243, 245)"}>
                                                {step03 == true ? <img src={IconCheck}></img> : null}
                                            </ErrorPrescriptionStyleImage>

                                            <StepNext onClick={()=>
                                                props.devolucao == false ? window.location = "/devolucoes/add" : 
                                                props.agronomos == false ? window.location = "/agronomos/add" :
                                                props.estado !== "RJ" && props.art == false ? window.location = "/arts/add" : 
                                                props.estado == "SP" && props.contrato == false && props.artId != 0 ? window.location = `/newcontract/${props.artId}` :
                                                props.produtoresGeral == false || (props.produtores == false && props.crea == true) ? window.location = "/produtores/add/" :
                                                props.fazendas == false && props.prodId != 0 ? window.location = `/localdeaplicacao/add/${props.prodId}` : null
                                            }>
                                                Local de devolução
                                            </StepNext>
                                        </Row>
                                    </Col>
                                </Steps>
                            </Col>

                            <Col alignItems='flex-end' width='auto'>
                                <ArvoreImg>
                                    {props.estado == "SP" ? (
                                        completed <= 15 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore01} />
                                        ) : completed > 15 && completed <= 30 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore02} />
                                        ) : completed > 30 && completed <= 45 ? (
                                            <img style={{  width: '40px', marginLeft: '15px' }} src={Arvore03}/>
                                        ) : completed > 45 && completed <= 60 ? (
                                            <img style={{ width: '48px', marginLeft: '10px' }} src={Arvore04}/>
                                        ) : completed > 60 && completed <= 75 ? (
                                            <img style={{ width: '62px', marginLeft: '5px' }} src={Arvore05}/>
                                        ): completed > 75 && completed <= 99 ? (
                                            <img style={{ width: '74px' }} src={Arvore06}/>
                                        ): completed > 99 ? (
                                            <img style={{ width: '74px' }} src={Arvore07}/>
                                        ) : (null)
                                    ) : props.estado == "RJ" ? (
                                        completed <= 20 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore01} />
                                        ) : completed > 20 && completed <= 40 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore03} />
                                        ) : completed > 40 && completed <= 60 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore04}/>
                                        ) : completed > 60 && completed <= 80 ? (
                                            <img style={{ width: '48px', marginLeft: '10px' }} src={Arvore05}/>
                                        ) : completed > 80 && completed <= 99 ? (
                                            <img style={{ width: '62px', marginLeft: '5px' }} src={Arvore06}/>
                                        ) : completed > 99 ? (
                                            <img style={{ width: '62px', marginLeft: '5px' }} src={Arvore07}/>
                                        ) : (null)
                                    ) : (
                                        completed <= 17 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore01} />
                                        ) : completed > 17 && completed <= 34 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore02} />
                                        ) : completed > 34 && completed <= 51 ? (
                                            <img style={{ width: '40px', marginLeft: '15px' }} src={Arvore03}/>
                                        ) : completed > 51 && completed <= 68 ? (
                                            <img style={{ width: '48px', marginLeft: '10px' }} src={Arvore04}/>
                                        ) : completed > 68 && completed <= 85 ? (
                                            <img style={{ width: '62px', marginLeft: '5px' }} src={Arvore05}/>
                                        ) : completed > 99 ? (
                                            <img style={{ width: '74px' }} src={Arvore07}/>
                                        ) : (null)
                                    )}
                                </ArvoreImg>
                            </Col>
                        </Row>

                        <Row>
                            <div 
                                style={{
                                    color:"black",
                                    marginTop: "15px",
                                    marginBottom: "4px",
                                    fontSize: 12,
                                }}
                            >
                                {step01 == true && step02 == true && step03 == true ? 'Parabéns, agora você pode emitir suas receitas! 🥳 🎉' : 'Conclua todos os cadastros básicos para emitir sua receita!'}
                            </div>
                        </Row>
                    </>
                )
            }

            
        </LastPrescriptionStyle>
    )

}

export default LastPrescriptionError;


