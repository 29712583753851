import React, { useState, useEffect } from "react";
import { Card } from "./style";
import api from "../../services/api";
import ModalTesteGratisAcabou from '../modal/modalFuncaoBloqueada';
import ModalGeneric from '../modal/modalGeneric';
import ButtonV2 from "../button/button_v2";

export const USER = "@userinfo";
export const VAL = "@validade";

const CardCompendio = ({ ...props }) => {
    const {
        cultura,
        praga,
        produto,
        relacoes,
        emitir,
        screen,
        paramsUtm
    } = props


    const [culturaId, setCulturaId] = useState('');
    const [culturaName, setCulturaName] = useState('');
    const [pragaId, setPragaId] = useState('');
    const [pragaName, setPragaName] = useState('');
    const [produtoId, setProdutoId] = useState('');
    const [produtoName, setProdutoName] = useState('');
    const [produtoName2, setProdutoName2] = useState('');
    const [visibleCard, setVisibleCard] = useState(false);

    const [user] = useState(JSON.parse(localStorage.getItem(USER)));
    const [val] = useState(JSON.parse(localStorage.getItem(VAL)));
    const [modalTesteGratisAcabou, setModalTesteGratisAcabou] = useState(false);
    const [modalText, setModalText] = useState(false);

    const loadCard = () => {
        if (!cultura || !praga || !produto) {

        }


        if (cultura && praga && produto) {


            let culturaId = cultura.value;
            setCulturaName(cultura.label.split("-")[0]);

            let culturaName = cultura.label;

            let pragaId = praga.value;
            setPragaName(praga.label.split("-")[0])
       
            let pragaName = praga.label;

            let produtoId = produto.value;
            const ver = produto.label.split("-");
            
            if(ver.length > 2){
                if(ver[0].length < 4){
                    setProdutoName(ver[0]+ver[1])
                }else{
                    setProdutoName(ver[0]);
                }
            }else{
                setProdutoName(ver[0]);

            }
            let produtoName = produto.label;

            setCulturaId(culturaId);
            setPragaId(pragaId);
            setProdutoId(produtoId);
            setProdutoName2(produtoName2);
            setVisibleCard(true);

        }
    }

    const nextStep = async (modoId,relacaoId,exceptionId) => {
        const response = await api.get("/logs");
        if(exceptionId){
            window.location.href = `/receitas/add/?${culturaId}/${pragaId}/${produtoId}/${modoId}/${relacaoId}/${exceptionId}`;

        }else{
            window.location.href = `/receitas/add/?${culturaId}/${pragaId}/${produtoId}/${modoId}/${relacaoId}`;

        }
    }

    const redirectLogin = async () => {
        if(emitir == 2){
            setModalText(true);
        }else{
            window.location.href = '/login';
        }
    }

    const closeModal = () => {
        if(modalText == true){
            setModalText(false);
        }
    }

    const responseModal = valor => {
        if (valor == 0) {
            closeModal();
            window.open(`https://sistema.agroreceita.com.br/cadastro/free${paramsUtm}`);

        } else {
            closeModal();
            window.open('https://sistema.agroreceita.com.br');
        }
    }

    useEffect(() => {
        loadCard();
    }, [cultura, praga, produto])

    return (
        <>
            <ModalGeneric
                show={modalText}
                onHide={() => closeModal()}
                size='md'
            >
                <h2 style={{
                    fontFamily: 'Lato', 
                    fontSize: '22px', 
                    fontWeight: 'bold', 
                    fontStretch: 'normal', 
                    width: '100%', 
                    textAlign: 'center', 
                    alignSelf: 'center'
                }}> Cadastre-se grátis para emitir receitas </h2>

                <p style={{
                    color: 'rgb(48, 56, 72)',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    width: '100%',
                    textAlign: 'center',
                    alignSelf: 'center'
                }}>Emita até 5 receitas grátis por mês!</p>


                <div 
                    style={{ 
                        width: '100%', 
                        margin: 'auto',
                        marginTop: '20px', 
                        alignSelf: 'center', 
                        display: 'flex', 
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-evenly'
                    }}
                >
                    <ButtonV2
                        width='45%'
                        height='40px'
                        value='JÁ TENHO CADASTRO'
                        outline={true}
                        disabled={false}
                        onClick={() => responseModal(1)}
                        loading={false}
                        color="#21262F"
                    />
                    
                    <ButtonV2
                        width='45%'
                        height='40px'
                        value='COMECE JÁ'
                        outline={false}
                        disabled={false}
                        onClick={() => responseModal(0)}
                        loading={false}
                    />
                </div>

            </ModalGeneric>

            <>
                {relacoes.map( rel =>(
                    <>
                        {rel.agro_relations_exceptions.length > 0 ? (<>  
                            <>
                                <>
                                    {screen.width > 1065 ? (<>
                                        <Card isMobile={props.isMobile} key={rel.modo_aplicacao.id.toString()}>

                                            <div className="row-card1">
                                                <div className="infoCard">
                                                    <h3>{rel.produto.nome_comercial}</h3>
                                                    <label> {rel.produto.nome_tecnico} </label>
                                                </div>
                                                <div className="infoCard">
                                                    {rel.produto.receituario_dispensado === 1 ? (
                                                        <p className="dispensado">Produto dispensado de receituário</p>
                                                    ) : (<>
                                                        {user ? (<>
                                                            {user.contract_software_id == 6 && val == 0 ? (<>
                                                                <button style={{outline:0}} className="btn-right" onClick={() => setModalTesteGratisAcabou(true)}> Emitir Receita </button>  
                                                            </>) : (<>
                                                                <button style={{outline:0}} className="btn-right" onClick={() => nextStep(rel.modo_aplicacao.id, rel.id, rel.agro_relations_exceptions.agro_exception_id)}> Emitir Receita </button>
                                                            </>)}
                                                        </>):(<>
                                                            <button style={{outline:0}} className="btn-right" onClick={() => redirectLogin()}> Emitir Receita </button>
                                                        </>)}
                                                    </>)}

                                                </div>
                                            </div>

                                            <div className="row-card">
                                                <div className="infoCard">
                                                    <label> Praga </label>
                                                    <p>{rel.praga.nome_comum}</p>
                                                    <p>{rel.praga.nome_cientifico}</p>
                                                </div>

                                                <div className="infoCard">
                                                    <label> Fabricante </label>
                                                    <p>{rel.produto.fabricante}</p>
                                                </div>

                                                <div className="infoCard">
                                                    <label> Dosagem </label>
                                                    <p>{rel.dosagem_minima} a {rel.dosagem_maxima}{rel.unidade_dosagem.unidade} </p>
                                                </div>

                                                <div className="infoCard">
                                                    <label> Número de aplicações </label>
                                                    <p> Min: {rel.numero_aplicacoes_minimo} - Max: {rel.numero_aplicacoes_maximo} </p>
                                                </div>
                                            </div>

                                            <div className="row-card">
                                                <div className="infoCard">
                                                    <label> Cultura </label>
                                                    <p> {rel.cultura.nome_comum} </p>
                                                </div>

                                                <div className="infoCard">
                                                    <label> Fase </label>
                                                    {rel.agro_relations_exceptions.length > 0 ? (<>
                                                        {rel.agro_relations_exceptions.map(exe => (
                                                            <p> {exe.agro_exception.nome} </p>
                                                            ))}
                                                    </>):(<>
                                                        <p>...</p>
                                                    </>)}
                                                </div>

                                            

                                                <div className="infoCard">
                                                    <label> Intervalo de segurança </label>
                                                    <p>{rel.intervalo_seguranca}</p>
                                                </div>
                                                
                                                <div className="infoCard">
                                                    <label> Classe </label>
                                                    {rel.produto.classes.map(c => (
                                                        <p> {c.nome} </p>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className="row-card" style={{justifyContent: 'left'}}>
                                                <div className="infoCard">
                                                    <label> Modalidade de aplicação </label>
                                                    <p> {rel.modo_aplicacao.nome} </p>
                                                </div>
                                                <div className="infoCard" style={{marginLeft: '22px'}}>
                                                    <label> Volume de calda </label>
                                                    {rel.modo_aplicacao.id == 10?(
                                                        <p>{rel.calda_aerea}</p>
                                                        ):(
                                                            <p>{rel.calda_terrestre}</p>
                                                            )}
                                                
                                                </div>
                                                <div className="infoCard"  style={{marginLeft: '25px'}}>
                                                    <label> Restrito em </label>
                                                        {rel.estados_proibidos.length > 0 && rel.produto.receituario_dispensado === 0 ? (
                                                            <>
                                                                <div className="estados">
                                                                    {rel.estados_proibidos.map(e => (
                                                                        <p>{e}</p>
                                                                        ))}
                                                                </div>
                                                            </>
                                                        ):(
                                                            <>
                                                                <div className="estados">
                                                                    <label>...</label>
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                            </div>

                                            <div className="Epoca">
                                                <label> Epoca </label>
                                                <p>{rel.epoca_aplicacao}</p>
                                            </div>
                                        </Card>
                                    </>):(<>
                                        <Card isMobile={props.isMobile} key={rel.modo_aplicacao.id.toString()}>
                                            <div className="row-card1">
                                                <div className="infoCard">
                                                    <h3>{rel.produto.nome_comercial}</h3>
                                                    <label> {rel.produto.nome_tecnico} </label>
                                                </div>
                                            </div>

                                            <div className="group-them">
                                                <div className="colunas">
                                                    <div className="row-card-1">
                                                        <div className="infoCard">
                                                            <label> Praga </label>
                                                            <p>{rel.praga.nome_comum}</p>
                                                            <p>{rel.praga.nome_cientifico}</p>
                                                        </div>

                                                        <div className="infoCard">
                                                            <label> Cultura </label>
                                                            <p> {rel.cultura.nome_comum} </p>
                                                        </div>

                                                        <div className="infoCard">
                                                            <label> Classe </label>
                                                            {rel.produto.classes.map(c => (
                                                                <p> {c.nome} </p>
                                                            ))}
                                                        </div>

                                                        <div className="infoCard">
                                                            <label> Dosagem </label>
                                                            <p>{rel.dosagem}</p>
                                                        </div>

                                                        
                                                        <div className="infoCard">
                                                            <label> Volume de calda </label>
                                                            {rel.modo_aplicacao.id == 10?(
                                                                <p>{rel.calda_aerea}</p>
                                                            ):(
                                                                <p>{rel.calda_terrestre}</p>
                                                            )}
                                                        </div>     

                                                        <div className="infoCard">
                                                            <label> Intervalo de segurança </label>
                                                            <p>{rel.intervalo_seguranca}</p>
                                                        </div>
                                                    </div>

                                                    <div className="row-card-2">
                                                        <div className="infoCard">
                                                            <label> Fabricante </label>
                                                            <p>{rel.produto.fabricante}</p>
                                                        </div>

                                                        <div className="infoCard">
                                                            <label> Fase </label>
                                                            {rel.agro_relations_exceptions.length > 0 ? (<>
                                                                {rel.agro_relations_exceptions.map(exe => (
                                                                    <p> {exe.agro_exception.nome} </p>
                                                                    ))}
                                                            </>):(<>
                                                                <p>...</p>
                                                            </>)}
                                                        </div>

                                                        <div className="infoCard">
                                                            <label> Modalidade de aplicação </label>
                                                            <p style={{ width: "100px"}}> {rel.modo_aplicacao.nome} </p>
                                                        </div>

                                                        <div className="infoCard">
                                                            <label> Número de aplicações </label>
                                                            <p> Min: {rel.numero_aplicacoes_minimo} - Max: {rel.numero_aplicacoes_maximo} </p>
                                                        </div>

                                                        <div className="infoCard">
                                                            <label> Restrito em </label>
                                                                {rel.estados_proibidos.length > 0 && rel.produto.receituario_dispensado === 0 ? (
                                                                    <>
                                                                        <div className="estados">
                                                                            {rel.estados_proibidos.map(e => (
                                                                                <p>{e}</p>
                                                                            ))}
                                                                        </div>
                                                                    </>
                                                                ):(
                                                                    <>
                                                                        <div className="estados">
                                                                            <label>...</label>
                                                                        </div>
                                                                    </>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="infoCardEpoca">
                                                    <label> Epoca </label>
                                                    <p>{rel.epoca_aplicacao}</p>
                                                </div>
                                            </div>

                                            <div className="infoCard">
                                                {rel.produto.receituario_dispensado === 1 ? (
                                                    <p className="dispensado">Produto dispensado de receituário</p>
                                                ) : (<>
                                                    {user ? (<>
                                                        {user.contract_software_id == 6 && val == 0 ? (<>
                                                            <button style={{outline:0}} className="btn-right" onClick={() => setModalTesteGratisAcabou(true)}> Emitir Receita </button>  
                                                        </>) : (<>
                                                            <button style={{outline:0}} className="btn-right" onClick={() => nextStep(rel.modo_aplicacao.id, rel.id, rel.agro_relations_exceptions.agro_exception_id)}> Emitir Receita </button>
                                                        </>)}
                                                    
                                                    </>):(<>
                                                        <button style={{outline:0}} className="btn-right" onClick={() => redirectLogin()}> Emitir Receita </button>
                                                    </>)}
                                                </>)}
                                            </div>
                                        </Card>
                                    </>)}
                                </>
                            </>
                        </>):(
                            <>
                                {rel.modo_aplicacao.id ? (
                                    <>
                                        {screen.width > 1065 ? (<>
                                            <Card isMobile={props.isMobile} key={rel.modo_aplicacao.id.toString()}>

                                                <div className="row-card1">
                                                    <div className="infoCard">
                                                        <h3>{rel.produto.nome_comercial}</h3>
                                                        <label> {rel.produto.nome_tecnico} </label>
                                                    </div>
                                                    <div className="infoCard">
                                                        {rel.produto.receituario_dispensado === 1 ? (
                                                            <p className="dispensado">Produto dispensado de receituário</p>
                                                        ) : (<>
                                                            {user ? (<>
                                                                {user.contract_software_id == 6 && val == 0 ? (<>
                                                                    <button 
                                                                        style={{outline:0}} 
                                                                        className="btn-right" 
                                                                        onClick={() => setModalTesteGratisAcabou(true)}
                                                                    > 
                                                                        Emitir Receita 
                                                                    </button>  
                                                                </>) : (<>
                                                                    <button 
                                                                        style={{outline:0}} 
                                                                        className="btn-right" 
                                                                        onClick={() => nextStep(rel.modo_aplicacao.id, rel.id)}
                                                                    > 
                                                                        Emitir Receita 
                                                                    </button>
                                                                </>)}
                                                            </>):(<>
                                                                <button 
                                                                    style={{outline:0}} 
                                                                    className="btn-right" 
                                                                    onClick={() => redirectLogin()}
                                                                > 
                                                                    Emitir Receita 
                                                                </button>
                                                            </>)}
                                                        </>)}
                                                    </div>
                                                </div>

                                                <div className="row-card">
                                                    <div className="infoCard">
                                                        <label> Praga </label>
                                                        <p>{rel.praga.nome_comum}</p>
                                                        <p>{rel.praga.nome_cientifico}</p>
                                                    </div>

                                                    <div className="infoCard">
                                                        <label> Fabricante </label>
                                                        <p>{rel.produto.fabricante}</p>
                                                    </div>

                                                    <div className="infoCard">
                                                        <label> Dosagem </label>
                                                        <p>{rel.dosagem_minima} a {rel.dosagem_maxima}{rel.unidade_dosagem.unidade} </p>
                                                    </div>

                                                    <div className="infoCard">
                                                        <label> Número de aplicações </label>
                                                        <p> Min: {rel.numero_aplicacoes_minimo} - Max: {rel.numero_aplicacoes_maximo} </p>
                                                    </div>
                                                </div>


                                                <div className="row-card">
                                                    <div className="infoCard">
                                                        <label> Cultura </label>
                                                        <p> {rel.cultura.nome_comum} </p>
                                                    </div>

                                                    <div className="infoCard">
                                                        <label> Fase </label>
                                                        {rel.agro_relations_exceptions.length > 0 ? (<>
                                                            {rel.agro_relations_exceptions.map(exe => (
                                                                <p> {exe.agro_exception.nome} </p>
                                                                ))}
                                                        </>):(<>
                                                            <p>...</p>
                                                        </>)}
                                                    </div>

                                                

                                                    <div className="infoCard">
                                                        <label> Intervalo de segurança </label>
                                                        <p>{rel.intervalo_seguranca}</p>
                                                    </div>

                                                    <div className="infoCard">
                                                        <label> Classe </label>
                                                        {rel.produto.classes.map(c => (
                                                            <p> {c.nome} </p>
                                                            ))}
                                                    </div>
                                                </div>

                                                <div className="row-card" style={{justifyContent: 'left'}}>
                                                    <div className="infoCard">
                                                        <label> Modalidade de aplicação </label>
                                                        <p> {rel.modo_aplicacao.nome} </p>
                                                    </div>
                                                    <div className="infoCard" style={{marginLeft: '22px'}}>
                                                        <label> Volume de calda </label>
                                                        {rel.modo_aplicacao.id == 10?(
                                                            <p>{rel.calda_aerea}</p>
                                                            ):(
                                                                <p>{rel.calda_terrestre}</p>
                                                                )}
                                                    
                                                    </div>
                                                    <div className="infoCard"  style={{marginLeft: '25px'}}>
                                                        <label> Restrito em </label>
                                                            {rel.estados_proibidos.length > 0 && rel.produto.receituario_dispensado === 0 ? (
                                                                <>
                                                                    <div className="estados">
                                                                        {rel.estados_proibidos.map(e => (
                                                                            <p>{e}</p>
                                                                            ))}
                                                                    </div>

                                                                    
                                                                </>
                                                            ):(
                                                                <>
                                                                    <div className="estados">
                                                                        <label>...</label>
                                                                    </div>
                                                                </>
                                                            )}
                                                    </div>
                                                </div>

                                                <div className="Epoca">
                                                    <label> Epoca </label>
                                                    <p>{rel.epoca_aplicacao}</p>
                                                </div>
                                            </Card>
                                        </>):(<>
                                            <Card isMobile={props.isMobile} key={rel.modo_aplicacao.id.toString()}>
                                                <div className="row-card1">
                                                    <div className="infoCard">
                                                        <h3>{rel.produto.nome_comercial}</h3>
                                                        <label> {rel.produto.nome_tecnico} </label>
                                                    </div>
                                                </div>

                                                <div className="group-them">
                                                    <div className="colunas">
                                                        <div className="row-card-1">
                                                            <div className="infoCard">
                                                                <label> Praga </label>
                                                                <p>{rel.praga.nome_comum}</p>
                                                                <p>{rel.praga.nome_cientifico}</p>
                                                            </div>

                                                            <div className="infoCard">
                                                                <label> Cultura </label>
                                                                <p> {rel.cultura.nome_comum} </p>
                                                            </div>

                                                            <div className="infoCard">
                                                                <label> Classe </label>
                                                                {rel.produto.classes.map(c => (
                                                                    <p> {c.nome} </p>
                                                                ))}
                                                            </div>

                                                            <div className="infoCard">
                                                                <label> Dosagem </label>
                                                                <p>{rel.dosagem}</p>
                                                            </div>

                                                            
                                                            <div className="infoCard">
                                                                <label> Volume de calda </label>
                                                                {rel.modo_aplicacao.id == 10?(
                                                                    <p>{rel.calda_aerea}</p>
                                                                ):(
                                                                    <p>{rel.calda_terrestre}</p>
                                                                )}
                                                            
                                                            </div>     

                                                            <div className="infoCard">
                                                                <label> Intervalo de segurança </label>
                                                                <p>{rel.intervalo_seguranca}</p>
                                                            </div>

                                                        </div>

                                                        <div className="row-card-2">
                                                            <div className="infoCard">
                                                                <label> Fabricante </label>
                                                                <p>{rel.produto.fabricante}</p>
                                                            </div>

                                                            <div className="infoCard">
                                                                <label> Fase </label>
                                                                {rel.agro_relations_exceptions.length > 0 ? (<>
                                                                    {rel.agro_relations_exceptions.map(exe => (
                                                                        <p style={{ width: "100px"}}> {exe.agro_exception.nome} </p>
                                                                    ))}
                                                                </>):(<>
                                                                    <p>...</p>
                                                                </>)}
                                                            </div>

                                                            <div className="infoCard">
                                                                <label> Modalidade de aplicação </label>
                                                                <p style={{ width: "100px"}}> {rel.modo_aplicacao.nome} </p>
                                                            </div>

                                                            <div className="infoCard">
                                                                <label> Número de aplicações </label>
                                                                <p> Min: {rel.numero_aplicacoes_minimo} - Max: {rel.numero_aplicacoes_maximo} </p>
                                                            </div>

                                                        

                                                            <div className="infoCard">
                                                                <label> Restrito em </label>
                                                                    {rel.estados_proibidos.length > 0 && rel.produto.receituario_dispensado === 0 ? (
                                                                        <>
                                                                            <div className="estados">
                                                                                {rel.estados_proibidos.map(e => (
                                                                                    <p>{e}</p>
                                                                                ))}
                                                                            </div>

                                                                            
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            <div className="estados">
                                                                                <label>...</label>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="infoCardEpoca">
                                                        <label> Epoca </label>
                                                        <p>{rel.epoca_aplicacao}</p>
                                                    </div>
                                                </div>
                                                <div className="infoCard">
                                                    {rel.produto.receituario_dispensado === 1 ? (
                                                        <p className="dispensado">Produto dispensado de receituário</p>
                                                    ) : (<>
                                                        {user ? (<>
                                                            {user.contract_software_id == 6 && val == 0 ? (<>
                                                                <button style={{outline:0}} className="btn-right" onClick={() => setModalTesteGratisAcabou(true)}> Emitir Receita </button>  
                                                            </>) : (<>
                                                                <button style={{outline:0}} className="btn-right" onClick={() => nextStep(rel.modo_aplicacao.id, rel.id)}> Emitir Receita </button>
                                                            </>)}
                                                        </>):(<>
                                                            <button style={{outline:0}} className="btn-right" onClick={() => redirectLogin()}> Emitir Receita </button>
                                                        </>)}
                                                    </>)}
                                                </div>
                                            </Card>
                                        </>)}
                                    </>
                                ) : (null)}

                            </>
                        )}
                    </>
                ))}
            </>

            <ModalTesteGratisAcabou
                show={modalTesteGratisAcabou}
                title="Essa é uma função exclusiva para assinantes..."
                subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                onHide={() => {
                    setModalTesteGratisAcabou(false);
                    window.location.href="/"
                }}
            ></ModalTesteGratisAcabou>

        </>
    );
}
export default CardCompendio;
