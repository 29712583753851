import styled from "styled-components";

export const NavBarContainer = styled.nav`
  display: ${props => (props.hide ? "none" : "flex")};
  height: 100vh;

  width: ${props => (props.isMobile ? '280px' : props.isOpen === false ? '50px' : '250px')};
  position: fixed;
  top: 0;
  left: ${props => (props.isMobile && props.isOpen === false ? '-280px' : '0')};
  
  background: #1d2129;

  transition: all 0.2s ease-out;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  
  color: #dfe4eb;
  
  z-index: 999;
  overflow: ${props => (props.isMobile ? "" : props.isOpenMenu ? "auto" : "")};

  ::-webkit-scrollbar{ 
      width: 1px;
    }

  
`;


export const HeadMenu = styled.div`
  width: 100%;
  height: ${props => props.isOpenMenu ? '70px' : '80px'};
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.isOpenMenu ? 'left' : 'center'};
  align-items: center;
  padding-left: ${props => props.isOpenMenu ? '20px' : '0px'};
  border-bottom: 1px solid #303848;
  margin-bottom: 11px;

  img {
    height: 33px;
    cursor: pointer;
  }

  @media only screen and (max-width: 780px){
    height:  ${props => props.button ? '90px' : '110px'};
    flex-direction: column;
    margin: ${props => props.button && '4px 0px'};
    padding-bottom: ${props => props.button && '7px'};

    .line-1{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      width: ${props => props.button ? '100%' : '90%'};
      height: ${props => props.button ? '50%' : '70%'};
    }

    .line-2{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg{
      margin-left: 0px;
      height: ${props => props.button ? '44px' : '90%'};
    }

  }
 
`;

export const Items = styled.div`
  width: 100%;
  height: auto;
  overflow:auto;
  padding-left: 9px;
  padding-right: 9px;
  margin-bottom: 90px;

  @media only screen and (max-height:800px){
    height: calc(100vh - 82px);
    margin-bottom: 90px;
  }

  ::-webkit-scrollbar{ 
      width: 1px;
    }
`;

export const ItemMenu = styled.div`
  width: 100%;
  /* min-width: 230px; */
  height: 40px;
  /* margin-left: 9px; */
  margin-bottom: 3px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: 'Lato';
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  padding-left: ${props => props.isOpenMenu ? '11px' : '5px'};
  padding-right: ${props => props.isOpenMenu ? '11px' : '5px'};
  cursor: pointer;

  background-color:  ${props => props.active === true ? '#292e38' : 'transparent'};
  color: ${props => props.active === true ? '#fff' : '#8a92a2'};

  label {
    margin-bottom: 0px;
    width: 100%;
    cursor: pointer;
  }

  .arrow {
    width: 10px;
    transition: all 0.2s ease-out;
    transform: ${props => props.isOpen === true ? 'rotate(0deg)' : 'rotate(180deg)'};
  }

  svg {
    margin-right: ${props => props.isOpenMenu ? '15px' : '0px'};
    g {
      path {
        fill: ${props => props.active === true ? '#fff' : '#8a92a2'} !important;
      }
    }
  }


  p {
    width: auto;

    display:none;
    position:absolute;
    align-items: center;

    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    padding: 6px 10px;
    color:#FFF;
    background-color: #1d2129;
    border-radius: 5px;
    opacity: 0.9;

    margin-left: 45px;
    margin-top: 17px;

    &:before{
      position: absolute;
      background-color:  #1d2129;
      width: 8px;
      height: 8px;
      top: 8px;
      margin-left: -14px;
      content: '';
      transform: rotate(45deg);
    }
  }


  &:hover{
    background-color: #292e38;
    color: #FFF;

    p{
      display: ${props => props.isOpenMenu === false ? 'block' : 'none'};
    }

    svg {
      g {
        path {
          fill: #fff !important;
        }
      }
    }
  }
`;

export const DropSubItems = styled.div`
  transition: max-height 0.5s;
  max-height: ${props => (props.open ? "1000px" : "40px" )};     
`;

export const SubItem = styled.div`
  display: ${props => props.open === true ? 'flex' : 'none'};
  
  width: 77%;
  min-width: 191px;
  height: 40px;
  margin-left: 41px;
  margin-bottom: 3px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;

  font-family: 'Lato';
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  padding-left: 11px;
  padding-right: 11px;
  cursor: pointer;

  background-color:  ${props => props.active === true ? '#292e38' : 'transparent'};
  color: ${props => props.active === true ? '#fff' : '#8a92a2'};

  label {
    margin-bottom: 0px;
    width: 100%;
    cursor: pointer;
  }

  .arrow {
    width: 10px;
    transition: all 0.2s ease-out;
    transform: ${props => props.active === true ? 'rotate(0deg)' : 'rotate(180deg)'};
  }

  svg {
    margin-right: 15px;
    g {
      path {
        fill: ${props => props.active === true ? '#fff' : '#8a92a2'} !important;
      }
    }
  }

  &:hover{
    background-color: #292e38;
    color: #FFF;

    svg {
      g {
        path {
          fill: #fff !important;
        }
      }
    }
  }
`;

export const FooterMenu = styled.div`
  width: 100%;
  height: ${props => props.isOpenMenu ? '90px' : '45px'};
  border-top: 1px solid #303848;
  position: absolute;
  /* padding-left: 9px;
  padding-right: 9px; */
  bottom: 0px;
`;

export const ImgGooglePlay = styled.div`
    display: flex;
    justify-content: center;
    background-color: #1d2129;
    margin-bottom: 8px;
    //margin-left: 8px;
    a:hover{
      img{
        width: 120px;
        transition: all 0.5s;
      }
    }
    img{
      width: 112px;
      margin-top: 4px;
      opacity: 0.7;
    }
    .img-2{
      margin-left: 4px;
    }
`;


export const ItemFootMenu = styled.div`
  width: 100%;
  /* min-width: 230px; */
  /* margin-left: 9px; */
  padding-top: 16px;
  padding-bottom: 16px;

  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  padding-left: 11px;
  padding-right: 11px;
  cursor: pointer;

  background-color:  ${props => props.active === true ? '#292e38' : 'transparent'};
  color: ${props => props.active === true ? '#fff' : '#8a92a2'};

  label {
    margin-bottom: 0px;
    width: 100%;
    cursor: pointer;
  }

  .arrow {
    width: 10px;
    transition: all 0.2s ease-out;
    transform: ${props => props.active === true ? 'rotate(0deg)' : 'rotate(180deg)'}
  }

  svg {
    margin-right: ${props => props.isOpenMenu ? '15px' : '0px'};
    g {
      path {
        fill: ${props => props.active === true ? '#fff' : '#8a92a2'} !important;
      }
    }
  }

  p {
    width: auto;

    display:none;
    position:absolute;
    align-items: center;

    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;

    padding: 6px 10px;
    color:#FFF;
    background-color: #1d2129;
    border-radius: 5px;
    opacity: 0.9;

    margin-left: 45px;
    margin-top: 17px;

    &:before{
      position: absolute;
      background-color:  #1d2129;
      width: 8px;
      height: 8px;
      top: 8px;
      margin-left: -14px;
      content: '';
      transform: rotate(45deg);
    }
  }

  &:hover{
    color: #FFF;

    p{
      display: ${props => props.isOpenMenu === false ? 'block' : 'none'};
    }

    svg {
      g {
        path {
          fill: #fff !important;
        }
      }
    }
  }


`;

export const Terms = styled.div`
  width: 100%;
  height: 37px;
  background-color: #303848;

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  label {
    color: #5bb3ff;
    font-size: 11px;
    font-weight: bold;
    margin-bottom: 0px;
    cursor: pointer;
  }
`;

export const User = styled.div`
  width: 70px;
  height: 35px;
  position: absolute;
  right: 0;
  top: ${props => props.isMobile ? '30px' : '20px'};
  margin-right: ${props => props.isMobile ? '0px' : '10px'};
  padding: 0px 8px;
  z-index: 1;
  background-color: rgb(41, 46, 56);
  border: 1px solid rgb(41, 46, 56);
  border-radius: 25px;
  cursor: pointer;
  
  display: ${props => props.hide === true ? 'none' : 'flex'};
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #FFF;
  font-weight: 700;

  label {
    margin: 0px;
    cursor: pointer;
    width: 30px;
    height: 28px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
  }

  img{
    width: 16px;
    transform: ${props => props.showOptions ? 'rotate(360deg)' : 'rotate(180deg)'};
  }

  @media only screen and (max-width: 780px){
    position: relative;
    top: 0px;
    height: ${props => props.button ? '27px' : '32px'};
    margin-top: 6px;

    img{
      width: 16px;
      height: 8px;
    }
  }
`;

export const Options = styled.div`
  display: ${props => props.show === true ? 'flex' : 'none'};
  width: 150px;
  padding: 10px 8px;
  border-radius: 10px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #FFF;

  z-index: 50;
  position: absolute;
  /* top: 68px; */
  top: ${props => props.isMobile ? props.button ? '85px' : '67px' : '64px'};
  right: ${props => props.isMobile ? props.button ? '90px' : '94px' : '8px'};

  /* box-shadow: 1px -3px 12px 2px rgb(29, 33, 41); */
  box-shadow: 1px 2px 5px 1px rgba(29,33,41,0.4);

  transition: all 0.2s ease-out;


  label {
    cursor: pointer;
    margin-top: 10px;
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: rgb(138, 146, 162);
  }

  @media only screen and (max-width: 760px) {
    width: 176px;
  }

`;


export const Arrow = styled.div`
  display: ${props => props.show === true ? 'flex' : 'none'};
  position: absolute;
  transform: rotate(45deg);
  right: ${props => props.isMobile ? props.button ? '184px' : '210px' : '21px'};
  /* top: 64px; */
  top: ${props => props.isMobile ? props.button ? '80px' : '64px' : '62px'};
  z-index: 51;
  background-color: white;
  width: 10px;
  height: 10px;
`;

export const Help = styled.div`
  position: absolute;
  right: 7.5%;
  top: 24px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
  font-family: Lato, sans-serif;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  fill: rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0);
  border-radius: 50px;
  padding: 7px 12px;
  transition: all 0.2s ease-out;
  display: ${props => props.hide ? 'none' : 'flex'};

  &:hover{
    background-color: rgba(255, 255, 255, 0.17);
  }
`;

export const Plans = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  transform: ${props => props.isMobile ? 'scale(0.8)' : 'scale(1.0)'};
  width: auto;
  height: 30px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(223, 228, 235);
  border-radius: 15px;
  background-color: rgb(88, 155, 255);
  padding-top: 9px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  z-index: 4;
  transition: all 0.2s ease-out;

  @media only screen and (max-width: 600px){
    margin-top: 0px;
    position: relative;
  }

  &:hover {
    background-color: rgb(88, 155, 255);
    transform: scale(0.85);
    box-shadow: 2px 2px 3px 1px rgba(88, 155, 255, 0.2);
  }
`;

export const MenuHamburg = styled.div`
  cursor: pointer;
  /* margin-top: 20px; */

  img {
    display: flex;
    position: absolute;
    top: 44px;
    left: 15px;
    z-index: 1;
    width: 20px;
  }
`;


export const Notification = styled.div`

  @keyframes treme {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(30deg);}
    50% {transform: rotate(0deg);}
    75% {transform: rotate(-30deg);}
    100% {transform: rotate(0deg);}
  }
  
  @keyframes up {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(30deg);}
    50% {transform: rotate(0deg);}
    75% {transform: rotate(-30deg);}
    100% {transform: rotate(0deg);}
  }
  
  @keyframes down {
    0% {transform: rotate(0deg);}
    25% {transform: rotate(30deg);}
    50% {transform: rotate(0deg);}
    75% {transform: rotate(-30deg);}
    100% {transform: rotate(0deg);}
  }



  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top: ${props => props.isMobile ? '43px' : '29px'};
  right: ${props => props.isMobile ? '23px' : '94px'};
  width: 20px;
  height: 20px;

  cursor: pointer;
  z-index: 4;
  transition: all 0.2s ease-out;


  svg {
    animation: ${props => props.tremer ? 'treme 0.25s' : ''};
    animation-iteration-count: 2;
    transition-timing-function: ease-out;

    g {
      g {
        fill: ${props => props.active === true ? '#fff' : '#8a92a2'} !important;
      }
    }
  }

  &:hover {

    transform: scale(1.12);
    
    svg {
      animation: ${props => props.tremer ? 'up 0.2s' : ''};
      animation-iteration-count: 1;
      transition-timing-function: ease-out;
      g {  
        g {
          fill: #fff !important;
        }
      }
    }
  }
`;

export const Upgrade = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  justify-content: center;
  position: absolute;
  top: ${props => props.isMobile ? '28px' : '23px'};
  right: ${props => props.isMobile ? '52px' : '113px'};
  width: 120px;
  height: 30px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #FFF;
  border-radius: 15px;
  background-color: ${props => props.isMobile ? 'transparent' : 'rgb(48, 56, 72)'};
  padding-top: 9px;
  /* padding-left: 15px;
  padding-right: 15px; */

  cursor: pointer;
  z-index: 4;
  transition: all 0.2s ease-out;

  @media only screen and (max-width: 760px){
    width: 30px;
  }

  p{
    cursor: pointer;
  }

  #diamond {
    fill: #8a92a2 !important;
  }

  &:hover {

    transform: scale(1.06);
    color: #FFF;
    /* box-shadow: 2px 2px 3px 1px rgba(88, 155, 255, 0.2); */

    #diamond {
      fill: #fff !important;
    }

    svg {
      path{
        
      }
    }
  }

  
`;

export const ButtonNewP = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top: ${props => props.isMobile ? '28px' : '23px'};
  right: ${props => props.isMobile ? '36px' : props.isPro || props.isTest ? '125px' : '246px'};
  width: auto;
  height: 30px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #FFF;
  border-radius: 15px;
  background-color: ${props => props.isMobile ? 'transparent' : '#2fbb69'};
  padding-top: 9px;
  padding-left: 15px;
  padding-right: 15px;

  cursor: pointer;
  z-index: 4;
  transition: all 0.2s ease-out;

  #diamond {
    fill: #8a92a2 !important;
  }

  &:hover {

    transform: scale(1.06);
    color: #FFF;
    box-shadow: 2px 2px 3px 1px rgba(47, 187, 105, 0.2);

    #diamond {
      fill: #fff !important;
    }

    svg {
      path{
        
      }
    }
  }
`;

export const NumNotifications = styled.div`
  position: absolute;
  background-color: #ff5252;
  width: 16px;
  height: 16px;
  color: #FFF;
  font-size: 9.6px;
  font-weight: 900;
  border: 1px solid #ff5252;
  border-radius: 50%;
  text-align: center;
  top: -6px;
  left: 9px;
`;

export const BtnMinimize = styled.div`
  position: fixed;
  width: 20px;
  height: 20px;
  z-index: 1000;
  top: 58px;
  left: ${props => (props.isOpen === false ? '40px' : '240px')};
  border: 1px solid transparent;
  border-radius: 50%;
  background-color: #292e38;
  transition: all 0.2s ease-out;
  box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  @media only screen and (max-width: 780px) {
    top: 78px;
  }
`;
