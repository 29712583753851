const validadorDeCnpj = (cnpj) => {
    cnpj = cnpj.replace(/[^\d]+/g,''); // Remove caracteres não numéricos
  if (cnpj.length !== 14) return false; // Verifica o tamanho do CNPJ

  // Calcula o primeiro dígito verificador
  let sum = 0;
  let peso = 5;
  for (let i = 0; i < 12; i++) {
    sum += parseInt(cnpj.charAt(i)) * peso;
    peso = (peso === 2) ? 9 : peso - 1;
  }
  let digit1 = (sum % 11 < 2) ? 0 : (11 - sum % 11);

  // Verifica o primeiro dígito verificador
  if (digit1 !== parseInt(cnpj.charAt(12))) return false;

  // Calcula o segundo dígito verificador
  sum = 0;
  peso = 6;
  for (let i = 0; i < 13; i++) {
    sum += parseInt(cnpj.charAt(i)) * peso;
    peso = (peso === 2) ? 9 : peso - 1;
  }
  let digit2 = (sum % 11 < 2) ? 0 : (11 - sum % 11);

  // Verifica o segundo dígito verificador
  if (digit2 !== parseInt(cnpj.charAt(13))) return false;

  // Se chegou até aqui, o CNPJ é válido
  return true;
}

export default validadorDeCnpj;