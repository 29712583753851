import styled from "styled-components";

export const FormContainer = styled.div`
  padding-top: 50;
  background: #f8f8f9;
  padding-bottom: 220px;
  div {
    form {
      padding-top: 50px;
      margin: auto;
      min-width: 250px;
      max-width: 500px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      button {
        margin: 15px;
        width: 100%;
        max-width: 400px;
        height: 60px;
        i {
          margin-left: 15px;
        }
      }
    
      
    }
  }

`;


export const ContainerSuccess = styled.div`
  background-color: rgb(41, 46, 56);
  background-image: url('https://www.agroreceita.com.br/wp-content/uploads/2020/09/símbolo-branco-1.png');
  background-size: cover;
  -webkit-box-flex: 1;
  display: flex;
  position: relative;
  min-height: 100vh;
  background-position: 0px -20px;
  background-repeat: no-repeat;
  flex: 1 1 0%;
`;

export const Images = styled.div`

  @keyframes upCell {
    from {bottom: -505px;}
    to {bottom: 0px;}
  }


  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin-top: 3rem;
  margin-left: 8rem;

  .img1 img{
    width: 170px;
  }

  .img2 {
    position: absolute;
    animation-name: upCell;
    animation-duration: 2s;
    bottom: 0px;

    img {
      width: 332px;
    }
  }

  @media only screen and (max-width: 990px) {
    margin-left: 5rem;
  }
  
  @media only screen and (max-width: 830px) {
    margin-left: 4rem;
  }
  
  @media only screen and (max-width: 780px) {
    margin: auto;
    margin-top: 3rem;

    .img2 {

      img {
        display: none;
      }
    }
  }

`;

export const BannerTextRight = styled.div`

  @keyframes moveText {
    from {right: -650px;}
    to {right: 100px;}
  }

  position: fixed;
  width: 50vw;
  height: 100vh;
  animation-name: moveText;
  animation-duration: 1s;
  right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: #fff;
    font-family: "Lato", Sans-serif;
    font-size: 56px;
    font-weight: 700;
    line-height: 1.2em;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: .5rem;
  }
  .paragraph {
    margin-top: 15px;
    margin-bottom: 30px;
  }
  p {
    color: #BAC1CF;
    font-family: "Lato", Sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 25px;
    text-align: center;
  }

  @media only screen and (max-width: 1100px) {
    @keyframes moveText {
      from {right: -650px;}
      to {right: 10px;}
    }

    position: absolute;
    right: 10px;

  }

  @media only screen and (max-width: 780px) {
    @keyframes moveText {
      from {right: -650px;}
      to {right: 0px;}
    }
    width: 100vw;
    right: 0px;
    padding: 0px 10px 0px 10px;
  }
  
  @media only screen and (max-width: 695px) {
    h1 {
      font-size: 175%;
    }

    p {
      font-size: 100%;
    }
  }

  /* @media only screen and (max-width: 543px) {
    
  } */
`;
