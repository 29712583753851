import React, { useEffect, useState } from 'react';
import Input from '../input/input_v2';
import { Card, LabelText, User, Label } from './style';
import ArrowIcon from "../../assets/icons/icon-arrow.svg";
import api from '../../services/api';

const CardUserCfta = ({...props}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [userSc, setUserSc] = useState('');
    const [passSc, setPassSc] = useState('');
    const [userEs, setUserEs] = useState('');
    const [passEs, setPassEs] = useState('');

    useEffect(() => {
        if (userSc != '' && passSc != '' && userEs != '' && passEs != '') {
            props.update({
                sc: {
                    usuario: userSc,
                    senha: passSc
                },
                es: {
                    usuario: userEs,
                    senha: passEs
                }
            });
        } else if (userSc != '' && passSc != '') {
            props.update({
                sc: {
                    usuario: userSc,
                    senha: passSc
                }
            });
        } else if (userEs != '' && passEs != '') {
            props.update({
                es: {
                    usuario: userEs,
                    senha: passEs
                }
            });
        }
    },[userSc, passSc, userEs, passEs])

    useEffect(() => {
        if(props.users){
            props.users.map((u) => {
                if (u.estado === 'SC') {
                    setUserSc(u.usuario);
                    setPassSc(u.senha);
                } else if (u.estado === 'ES') {
                    setUserEs(u.usuario);
                    setPassEs(u.senha);
                }
            })
        }
    },[props.users]);

    return(
        <>
            {
                userSc && passSc && (
                    <>
                        <Label>Emissão de receitas para SC</Label>
                        <Card isOpen={isOpen} estado={props.estado}>
                            {!isOpen ? (
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <div style={{width: '90%'}} onClick={() => setIsOpen(!isOpen)}>
                                        <h3>SIGEN</h3>
                                    </div>

                                    <div style={{width: '10%'}}>
                                        <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => setIsOpen(!isOpen)}/>
                                    </div>
                                </div>
                            ) : (
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <>
                                        <User>
                                            <Input 
                                                id='userSc'
                                                name="user"
                                                type="text"
                                                label="Usuário SIGEN"
                                                onChange={e => setUserSc(e)}
                                                value={userSc}
                                                marginBottom="16px"
                                            />

                                            <Input 
                                                id='passSc'
                                                name="pass"
                                                type='password'
                                                label="Senha SIGEN"
                                                onChange={e => setPassSc(e)}
                                                value={passSc}
                                                marginBottom="16px"
                                            />
                                        </User>

                                    </>
                                    
                                    <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => setIsOpen(!isOpen)}/>

                                </div>
                            )}
                        </Card>
                        <LabelText style={{marginBottom: '16px'}}>Usuário e senha de acesso ao SIGEN. Obrigatório para emissão de receitas para SC.</LabelText>
                    </>
                )
            }

            {
                userEs && passEs && (
                    <>
                        <Label>Emissão de receitas para ES</Label>
                        <Card isOpen={isOpen2} estado={props.estado}>
                            {!isOpen2 ? (
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                    <div style={{width: '90%'}} onClick={() => setIsOpen2(!isOpen2)}>
                                        <h3>IDAF</h3>
                                    </div>
            
                                    <div style={{width: '10%'}}>
                                        <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => setIsOpen2(!isOpen2)}/>
                                    </div>
                                </div>
                            ) : (
                                <div style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                <>
                                        <User>
                                            <Input 
                                                id='user'
                                                name="user"
                                                type="text"
                                                label="Usuário IDAF"
                                                onChange={e => setUserEs(e)}
                                                value={userEs}
                                            />
            
                                            <Input 
                                                id='pass'
                                                name="pass"
                                                type='password'
                                                label="Senha IDAF"
                                                onChange={e => setPassEs(e)}
                                                value={passEs}
                                            />
                                        </User>
            
                                    </>
                                    
                                    <img className="arrow" src={ArrowIcon} alt='arrow' onClick={() => setIsOpen2(!isOpen2)}/>
            
                                </div>
                            )}
                        </Card>
                        <LabelText style={{display: 'flex', justifyContent: 'center', textAlign: 'center', fontSize: '11px', marginBottom: '45px'}}>Usuário e senha de acesso ao IDAF. Obrigatório para emissão de receitas para ES.</LabelText>
                    </>
                )
            }
            
            

        </>
    )
}

export default CardUserCfta