import React from 'react';

const IconYes = () => {


    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 30 30" width="19px" height="19px">
            <g id="surface30317301">
                <path 
                    style={{
                        stroke:'none',
                        fillRule:'nonzero',
                        fill:'rgb(21.568628%,71.764708%,43.529412%)',
                        fillOpacity:1,
                    }}
                    d="M 15 3 C 8.371094 3 3 8.371094 3 15 C 3 21.628906 8.371094 27 15 27 C 21.628906 27 27 21.628906 27 15 C 27 8.371094 21.628906 3 15 3 Z M 21.707031 12.707031 L 14.148438 20.265625 C 13.960938 20.453125 13.703125 20.558594 13.441406 20.558594 C 13.175781 20.558594 12.921875 20.453125 12.734375 20.265625 L 9.28125 16.8125 C 8.890625 16.421875 8.890625 15.789062 9.28125 15.398438 C 9.671875 15.007812 10.304688 15.007812 10.695312 15.398438 L 13.441406 18.144531 L 20.292969 11.292969 C 20.683594 10.902344 21.316406 10.902344 21.707031 11.292969 C 22.097656 11.683594 22.097656 12.316406 21.707031 12.707031 Z M 21.707031 12.707031 "
                />
            </g>
        </svg>
    )

}

export default IconYes