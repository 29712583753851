import React, { useState, useEffect, useCallback } from 'react';
import { ContainerSuccess, Title, ContainerBackground, Icon, Icon02, Marker, Markers, Content, Text, OptionTitle, FundoSt5, BoxTextoFotoSt5, ImgStep05, Option } from './styles';
import Receita from '../../assets/tour/undraw_receitas.svg';
import metricas from '../../assets/tour/metricas.svg';
import logoBranco from '../../assets/tour/logoIcone_branco.svg';
import emoji1 from '../../assets/tour/emoji1.svg';
import emoji2 from '../../assets/tour/emoji2.svg';
import { ImgFundo } from '../../pages/tour/styles';

const Step05 = ({ ...props }) => {

    return (
        <>
            <Content marginLeft={props.steps} style={{justifyContent: 'unset'}}>
                <BoxTextoFotoSt5>
                    <ImgStep05>
                        <img src={metricas} />
                    </ImgStep05>
                    <FundoSt5>
                        <Text>
                            <OptionTitle>Métricas essenciais</OptionTitle>
                            <Option>Identifique com mais clareza os dados das suas receitas emitidas, produtos mais indicados, clientes atendidos, total de área tratada, entre outros... <img src={emoji1} style={{height: '15px'}} /></Option>
                            <Option>E tenha mais assertividade na gestão dos seus negócios! <img src={emoji2} style={{height: '15px'}} /></Option>
                        </Text>
                    </FundoSt5>
                </BoxTextoFotoSt5>
                <ImgFundo>
                    <img src={logoBranco} />
                </ImgFundo>
            </Content>
        </>
    );
};

export default Step05;
