import styled from "styled-components";

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    
`;

export const ContainerStyles = styled.div`
    width: 100%;
    height: 14px;
    background-color: rgb(224, 224, 222);
    border-radius: 50px;
    float: left;
    padding: 2px;

    .fillerStyles {

        width: ${props => `${props.completed}%`};
        height: 100%;
        border-radius: 50px;
        background-color: rgb(83, 195, 110);
    }
`;

export const LabelStyles = styled.div`
    width: 45px; 
    color: black;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
`;