import React from "react";
import { Link } from "react-router-dom";
import estados from "../../services/estados";
import api from "../../services/api";
import { store } from "react-notifications-component";
import GenericUpper from "../../components/generic-upper";
import { FiliaisAddContainer } from "./styles";
import { Container } from "react-grid-system";

import TextArea from "../../components/textarea/text_area_v2";
import Input from "../../components/input/input_v2";
import Select from "../../components/select/select_v2";
import Button from "../../components/button/button_v2";

import DotsLoader from "../../components/loader";
import buscaCep from "../../services/buscaCep";

class EditFiliais extends React.Component {
    state = {
        nome_fantasia: "",
        razao_social: "",
        cnpj: "",
        telefone: "",
        cep: "",
        endereco: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        id: "",
        inscricao_estadual: "",
        file: [],
        namesfiles:[],
        textfile:"ADICIONAR ANEXOS (JPG e PNG, MAX 3MB)",
        is_matriz: "",
        telefone_emergencia: "",
        loading: false
    };

    componentDidMount() {
        this.getFiliais();
    }

    getFiliais = async () => {
        try {
            const id = this.props.match.params.id;
            this.setState({ id });
    
            const response = await api.get(`/filiais/${id}`);
            const filiais = response.data.data;
    
            const {
                nome_fantasia,
                razao_social,
                cnpj,
                telefone,
                cep,
                endereco,
                numero,
                bairro,
                cidade,
                estado,
                is_matriz,
                telefone_emergencia,
                inscricao_estadual
            } = filiais;
    
            this.setState({
                nome_fantasia,
                razao_social,
                cnpj,
                telefone,
                cep,
                endereco,
                numero,
                bairro,
                cidade,
                estado,
                is_matriz,
                inscricao_estadual,
                telefone_emergencia
            });
            
        } catch (error) {
            store.addNotification({
                title: "Aviso!",
                message: `${error.response.data.errors}`,
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    fetchCep = async () => {
        const { cep } = this.state;

        try {
            const responseCep = await buscaCep(cep);
            const { logradouro, localidade, bairro, uf } = responseCep;

            this.setState({
                endereco: logradouro,
                cidade: localidade,
                bairro: bairro,
                estado: uf
            });

        } catch (err) {
            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    editPost = async () => {

        this.setState({ loading: true });

        const {
            nome_fantasia,
            razao_social,
            telefone_emergencia,
            cnpj,
            telefone,
            cep,
            endereco,
            numero,
            bairro,
            cidade,
            estado,
            id,
            inscricao_estadual
        } = this.state;

        const payload = {
            nome_fantasia,
            razao_social,
            telefone_emergencia,
            cnpj,
            telefone,
            cep,
            endereco,
            numero,
            bairro,
            cidade,
            estado,
            is_matriz: 0,
            inscricao_estadual
        };

        try {
            const response = await api.put(`/filiais/${id}`, payload);

            if (response.status === 200) {
                this.setState({ loading: false });

                this.props.history.push("/filiais");
                store.addNotification({
                    title: "Sucesso!",
                    message: "A filial foi editada corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });

            }
        } catch (err) {
            this.setState({ loading: false });
            const errors = err.response.data.errors;

            for (let field in errors) {
            }

            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos em vermelho",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    handlePostMatriz = async () => {
        const {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            telefone_emergencia,
            cep,
            endereco,
            cidade,
            bairro,
            numero,
            estado,
            file,
            inscricao_estadual
        } = this.state;

        const payload = {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            telefone_emergencia,
            logo: file,
            cep,
            endereco,
            bairro,
            numero,
            cidade,
            estado,
            is_matriz: 1,
            inscricao_estadual
        };

        try {
            this.setState({loading: true});
            const response = await api.put(`/filiais/${this.state.id}`, payload);

            if (response.status === 200) {
                this.props.history.push("/filiais");
                store.addNotification({
                    title: "Sucesso!",
                    message: "A empresa foi salva corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
            this.setState({loading: false});
        } catch (err) {
            this.setState({loading: false});
            const errors = err.response.data.errors;

            for (let field in errors) {
            }

            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos vazios",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });

            this.setState({loading: false});
        }
    };


    onKeyDownFormater = (e, campo) => {
        if(e.keyCode === 8) {
            const value = document.getElementsByName(campo);
            return value[0].value;
        }
    }

    onChange = async (e) =>{
        if(e.target.files){
            if(e.target.files[0].type != "image/png" && e.target.files[0].type != "image/jpeg"){
                store.addNotification({
                    title: "Erro!",
                    message: `O arquivo selecionado não possui a extensão correta`,
                    type: "warning",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                return;
            }
        const {file} = this.state;
        let fil = e.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(fil[0]);
        reader.onload=(e)=>{
            this.setState({file:e.target.result})
        }
            this.state.namesfiles.push(e.target.files[0].name);
            this.setState({textfile: (<>
            <p>ADICIONAR ANEXOS (OPCIONAL, JPG e PNG)</p>
                <dt>
                    <dd>{e.target.files[0].name}</dd>
                </dt>
            </>)});
        }
    }

    back = () => {
        window.location = '/filiais';
    }
    
    render() {
        const {
            nome_fantasia,
            razao_social,
            cnpj,
            telefone,
            cep,
            endereco,
            numero,
            bairro,
            cidade,
            estado,
            is_matriz,
            telefone_emergencia,
            inscricao_estadual
        } = this.state;

        if( is_matriz == 0 ){

            return (
                <>
                    <GenericUpper
                        title="Edite sua empresa"
                        subtitle="Edite esta empresa"
                    ></GenericUpper>

                    <FiliaisAddContainer>
                        <Container>
                                <form>
                                    <TextArea
                                        name="nome_fantasia"
                                        type="text"
                                        label="Nome Fantasia"
                                        id="id_nome_fantasia"
                                        rows="1"
                                        cols="22"
                                        value={nome_fantasia}
                                        onChange={e => this.setState({ nome_fantasia: e.target.value })}
                                    />

                                    <TextArea
                                        name="razao_social"
                                        type="text"
                                        label="Razão Social"
                                        id="id_razao_social"
                                        rows="1"
                                        cols="22"
                                        value={razao_social}
                                        onChange={e => this.setState({ razao_social: e.target.value })}
                                    />

                                    <Input
                                        name="cnpj"
                                        type="text"
                                        label="CNPJ"
                                        id="id_cnpj"
                                        value={cnpj}
                                        onChange={e => this.setState({ cnpj: e })}
                                    />

                                    <Input
                                        name="telefone"
                                        type="text"
                                        label="Telefone"
                                        id="id_telefone"
                                        value={telefone}
                                        onChange={e => this.setState({ telefone: e })}
                                    />

                                    <Input
                                        name="cep"
                                        type="text"
                                        label="CEP"
                                        id="id_cep"
                                        value={cep}
                                        onChange={e => this.setState({ cep: e })}
                                        onBlur={this.fetchCep}
                                    />
                                    
                                    {
                                        inscricao_estadual !== null && (
                                            <Input
                                                name="Inscrição Estadual"
                                                type="text"
                                                label="Inscrição Estadual"
                                                id="inscricao_estadual"
                                                value={inscricao_estadual}
                                                onChange={e => this.setState({ inscricao_estadual: e })}
                                            />
                                        )
                                    }

                                    <Input
                                        name="endereco"
                                        type="text"
                                        label="Endereço"
                                        id="id_endereco"
                                        value={endereco}
                                        onChange={e => this.setState({ endereco: e })}
                                    />

                                    <Input
                                        name="numero"
                                        label="Número"
                                        id="id_numero"
                                        value={numero}
                                        onChange={e => this.setState({ numero: e })}
                                    />

                                    <Input
                                        name="bairro"
                                        type="text"
                                        label="Bairro"
                                        id="id_bairro"
                                        value={bairro}
                                        onChange={e => this.setState({ bairro: e })}
                                    />

                                    <Input
                                        name="cidade"
                                        type="text"
                                        label="Cidade"
                                        id="id_cidade"
                                        value={cidade}
                                        onChange={e => this.setState({ cidade: e })}
                                    />

                                    <Select
                                        name="estado"
                                        className="select"
                                        label="Estado"
                                        id="id_estado"
                                        value={estado}
                                        onChange={state => this.setState({ estado: state.value })}
                                        options={estados}
                                        estado={true}
                                    />

                                    <div 
                                        className="button-finish" 
                                        style={{ 
                                            width: '100%', 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center', 
                                            marginTop: '40px',
                                        }}
                                    >
                                        <Button 
                                            width='45%'
                                            height='50px'
                                            value='VOLTAR'
                                            outline={true}
                                            disabled={false}
                                            onClick={() => this.back()}
                                            loading={false}
                                        />

                                        <Button 
                                            width='45%'
                                            height='50px'
                                            value='SALVAR'
                                            outline={!this.state.loading}
                                            disabled={false}
                                            onClick={() => this.editPost()}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </form>
            
                            </Container>
                </FiliaisAddContainer>
                </>
            );  
        }else {
            return (<>
                 <GenericUpper
                        title="Edite sua empresa"
                        subtitle="Edite esta empresa"
                    ></GenericUpper>
                    <FiliaisAddContainer>
                        <Container>
                          <form>
                                <Input
                                    name="nome_fantasia"
                                    type="text"
                                    label="Nome Fantasia"
                                    id="id_nome_fantasia"
                                    rows="1"
                                    cols="22"
                                    value={nome_fantasia}
                                    onChange={e => this.setState({ nome_fantasia: e})}
                                />

                                <Input
                                    name="razao_social"
                                    type="text"
                                    label="Razão Social"
                                    id="id_razao_social"
                                    rows="1"
                                    cols="22"
                                    value={razao_social}
                                    onChange={e => this.setState({ razao_social: e })}
                                />

                                <Input
                                    name="cnpj"
                                    type="text"
                                    label="CNPJ"
                                    id="id_cnpj"
                                    value={cnpj}
                                    onChange={e => this.setState({ cnpj: e })}
                                />

                                <Input
                                    name="telefone"
                                    type="text"
                                    label="Telefone"
                                    id="id_telefone"
                                    value={telefone}
                                    onChange={e => this.setState({ telefone: e })}
                                />


                                <Input
                                    name="telefone_emergencia"
                                    type="text"
                                    label="Telefone de emergência"
                                    id="id_telefone_emergencia"
                                    value={telefone_emergencia}
                                    onChange={e => this.setState({ telefone_emergencia: e })}
                                />


                                <Input
                                    name="cep"
                                    type="text"
                                    label="CEP"
                                    id="id_cep"
                                    value={cep}
                                    onChange={e => this.setState({ cep: e })}
                                    onBlur={this.fetchCep}
                                />

                                { 
                                    inscricao_estadual !== null && (
                                        <Input
                                            name="Inscrição Estadual"
                                            type="text"
                                            label="Inscrição Estadual"
                                            id="inscricao_estadual"
                                            value={inscricao_estadual}
                                            onChange={e => this.setState({ inscricao_estadual: e })}
                                        />
                                    )
                                }

                                <Input
                                    name="endereco"
                                    type="text"
                                    label="Endereço"
                                    id="id_endereco"
                                    value={endereco}
                                    onChange={e => this.setState({ endereco: e })}
                                />

                                <Input
                                    name="numero"
                                    label="Número"
                                    id="id_numero"
                                    value={numero}
                                    onChange={e => this.setState({ numero: e })}
                                />

                                <Input
                                    name="bairro"
                                    type="text"
                                    label="Bairro"
                                    id="id_bairro"
                                    value={bairro}
                                    onChange={e => this.setState({ bairro: e })}
                                />

                                <Input
                                    name="cidade"
                                    type="text"
                                    label="Cidade"
                                    id="id_cidade"
                                    value={cidade}
                                    onChange={e => this.setState({ cidade: e })}
                                />

                                <Select
                                    name="estado"
                                    className="select"
                                    label="Estado"
                                    id="id_estado"
                                    value={estado}
                                    onChange={state => this.setState({ estado: state.value })}
                                    options={estados}
                                    estado={true}
                                />

                                <label style={{marginTop: '-20px', marginBottom: '2px', marginLeft: '-193px', fontSize: '12px', fontWeight: '700', color: '#8192a2'}}>Adicionar Logomarca: (Opcional) </label>
                                
                                {/* <div class='input-wrapper'>
                                    <label for='id_input-file' multiple>
                                        {this.state.textfile}
                                    </label>

                                    <input 
                                        style={{marginTop: '20px'}}
                                        type="file"
                                        id="id_input-file"
                                        name="file"
                                        accept="image/png, image/jpeg"
                                        multiple
                                        onChange={(e)=>this.onChange(e)}
                                    />

                                    <span id='file-name'></span>
                                    
                                </div>                                 */}



                                <div 
                                    for='id_input-file' 
                                    onClick={() => document.getElementById('id_input-file').click()}
                                    style={{
                                        width: '100%',
                                        height: '108px',
                                        backgroundColor: '#fff',
                                        borderRadius: '1px',
                                        border: '1px dashed #8a92a2',
                                        color: '#8a92a2',
                                        padding: '12px 20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <p
                                        style={{
                                            width: '100%',
                                            textAlign: 'center',
                                            fontSize: '15px',
                                        }}
                                    >
                                        {this.state.textfile}
                                    </p>
                                    {/* (Apenas: PDF, TXT, PNG, GIF, JPEG) */}
                                </div>
                                <input 
                                    style={{marginTop: '20px'}}
                                    type="file"
                                    id="id_input-file"
                                    name="file"
                                    accept="image/png, image/jpeg"
                                    multiple
                                    onChange={(e)=>this.onChange(e)}
                                />

                                <span id='file-name'></span>




                                <div 
                                    className="button-finish" 
                                    style={{ 
                                        width: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'row', 
                                        justifyContent: 'space-evenly',
                                        alignItems: 'center', 
                                        marginTop: '40px',
                                    }}
                                >
                                    <Button 
                                        width='45%'
                                        height='50px'
                                        value='VOLTAR'
                                        outline={true}
                                        disabled={false}
                                        onClick={() => this.back()}
                                        loading={false}
                                    />

                                    <Button 
                                        width='45%'
                                        height='50px'
                                        value='SALVAR'
                                        outline={!this.state.loading}
                                        disabled={false}
                                        onClick={() => this.handlePostMatriz()}
                                        loading={this.state.loading}
                                    />
                                </div>

                            </form>

                    </Container>
                </FiliaisAddContainer>
            </>);
        }
    }
};
export default  EditFiliais;