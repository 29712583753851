import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import GenericUpper from "../../components/generic-upper";
import ArtsOne from "../../components/arts/arts";
import ArtsTwo from "../../components/arts/arts2";
// import ArtsThree from "../../components/arts/arts";
import Contracts from "../../components/arts/contracts";
// import EditContracts from "../../components/arts/edits";
// import SpecificEdit from "../../components/arts/specific";


const AddArts = ({ ...props }) => {
    const [agronomo, setAgronomo] = useState('');
    const [estado, setEstado] = useState([]);
    const [filial, setFilial] = useState('');
    const [crea, setCrea] = useState('');
    const [numeroArt, setNumeroArt] = useState('');
    const [receitaInicial, setReceitaInicial] = useState('');
    const [quantidadeContratos, setQuantidadeContratos] = useState('');
    const [numeroOrdem, setNumeroOrdem] = useState('');
    const [dataValidade, setDataValidade] = useState('');
    const [title, setTitle] = useState('Cadastre uma ART/TRT');
    const [step, setStep] = useState(1);
    const [quantidadeReceita, setQuantidadeReceita] = useState('');
    const [produtorId, setProdutorId] = useState('');
    const [localDevolucaoId, setLocalDevolucaoId] = useState('');
    const [valorContrato, setValorContrato] = useState('');
    const [dataInicial, setDataInicial] = useState('');
    const [dataFinal, setDataFinal] = useState('');
    const [align] = useState("center");


    const updateArtsPass1 = (
        estado
    ) => {
        setEstado(estado);
    };



    const updateArtsInfo = (
          agronomo,
          estado,
          filial,
          crea,
          numeroArt,
          receitaInicial,
          quantidadeContratos,
          numeroOrdem,
          dataValidade
    ) => {

        setAgronomo(agronomo);
        setFilial(filial);
        setCrea(crea);
        setNumeroArt(numeroArt);
        setReceitaInicial(receitaInicial);
        setQuantidadeContratos(quantidadeContratos);
        setQuantidadeReceita(quantidadeReceita);
        setNumeroOrdem(numeroOrdem);
        setDataValidade(dataValidade);
    };


    useEffect(() => {
        if (step === 3) {
            setTitle('Cadastre um contrato');
        }
    }, [step]);


    useEffect(() => {
        if (props.isModal === true) {
            setEstado(props.estado);
            setAgronomo(props.agronomo);
            setCrea(props.crea);
        }
    },[props]);

    useEffect(() => {
        if (props.isModal === true) {
            setStep(2);
        }
    },[])


    return (
        <>
            {props.isModal === true ? (
                <h4 style={{ textAlign: 'center' }}> {step === 2 ? 'Cadastrar ART/TRT' : 'Cadastrar contrato'}</h4>
            ) : (
                <GenericUpper
                    align={align}
                    title={title}
                    help={true}
                    text={"Dúvidas para cadastrar uma ART ou TRT?"}
                    toSearch={'https://agroreceita.com.br/como-cadastrar-art-trt/'}
                    textToSearch={'Clique aqui e acesse o tutorial!'}
                ></GenericUpper>
            )}
            {step === 1 ? (
                <ArtsOne
                    estado={estado}
                    update={updateArtsPass1}
                    isModal={props.isModal}
                    next={() => setStep(2)}
                />
            ) : step === 2 ? (
                <ArtsTwo 
                    isModal={props.isModal}
                    estado={estado}
                    agronomo={agronomo}
                    crea={crea}
                    filial={filial}
                    numeroArt={numeroArt}
                    quantidadeReceita={quantidadeReceita}
                    receitaInicial={receitaInicial}
                    quantidadeContratos={quantidadeContratos}
                    numeroOrdem={numeroOrdem}
                    update={(
                            agronomo,
                            estado,
                            filial,
                            crea,
                            numeroArt,
                            receitaInicial,
                            quantidadeContratos,
                            numeroOrdem,
                            dataValidade
                            ) => updateArtsInfo(
                                    agronomo,
                                    estado,
                                    filial,
                                    crea,
                                    numeroArt,
                                    receitaInicial,
                                    quantidadeContratos,
                                    numeroOrdem,
                                    dataValidade
                                )
                            }
                    next={() => setStep(3)}
                    loc={props}
                    updateArt={() => props.updateArt()}

                />

                ) : step === 3 ? (
                    <>
                        <Contracts
                            estado={estado}
                            agronomo={agronomo}
                            crea={crea}
                            filial={filial}
                            numeroArt={numeroArt}
                            quantidadeReceita={quantidadeReceita}
                            receitaInicial={receitaInicial}
                            quantidadeContratos={quantidadeContratos}
                            numeroOrdem={numeroOrdem}
                            dataValidade={dataValidade}
                            produtorId={produtorId}
                            localDevolucaoId={localDevolucaoId}
                            valorContrato={valorContrato}
                            dataInicial={dataInicial}
                            dataFinal={dataFinal}
                            loc={props}
                            isModal={props.isModal}
                            update={() => props.updateArt()}
                            back={() => setStep(2)}
                        />
                    </>
                ) : null}
        </>
    )

}
export default withRouter(AddArts);