import styled from "styled-components";

export const Card = styled.div`
    width: 100%;
    height: ${props => ( !props.isOpen ? (
        props.tamanho ? (
            props.tamanho === 1 ? (
                '50px'
            ) : (
                props.tamanho === 2 ? (
                    props.estado != 'NACIONAL' ? (
                        '50px'
                    ) : (    
                        '85px'
                    )
                ) : ('0px')
            )
        ) : (
            '50px'
        )
    ):(
        props.tamanho ? (
            props.tamanho === 1 ? (
                '245px'
            ) : (
                props.tamanho === 2 ? (
                    props.estado != 'NACIONAL' ? (
                        '245px'
                    ) : (    
                        '485px'
                    )
                ) : ('0px')
            )
        ) : (
            'auto'
        )
    ))};

    padding: 12px;
    border-radius: 10px;
    border: 1px solid rgb(186, 193, 207);
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: ${props => (!props.isOpen ? 'space-between' : 'flex-start')};
    align-items: ${props => (!props.isOpen ? 'flex-start' : 'center')};;
    transition: all 0.2s ease-in-out;

    h3 {
        text-align: start;
        font-size: 20px;
    }

    .arrow {
        margin-top: ${props => (!props.isOpen ? '-10px' : '0px')};
        transform: ${props => (!props.isOpen ? 'none' : 'rotate(180deg)')};
        transition: all 0.2s;

        &:hover {
            cursor: pointer;
            transform: ${props => (!props.isOpen ? 'scale(1.2) rotate(0deg)' : 'scale(1.2) rotate(180deg)')};
        }

        &:active {
            transform: scale(0.8);
        }
    }
`;

export const Title = styled.h2`
    text-align: start;
    font-size: 18px;
`;

export const User = styled.div`
    width: 100%;
    margin-top: 16px;
`;

export const LabelText = styled.label`
    display: flex; 
    justify-content: center; 
    text-align: center; 
    font-size: 11px;
    margin-top: 8px;
`;

export const Label = styled.label`
  width: 100%;
  margin-bottom: 10px;
  margin-left: 15px;
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #8a92a2;
  display: inline-table;
  text-align: start;
`;