import styled from "styled-components";

export const FormContainer = styled.div`
  background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
  /* height: 135vh; */
  height: auto;

  .steps{
    display: flex;
    flex-direction: row;
    width: 180px;
    margin: auto;
    justify-content: space-evenly;
    .btn-step-selected{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
      border: solid 4px #f8f8f9;
      background-color: #589bff;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #FFF;
      margin-top: -20px;
      /* cursor: pointer; */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .btn-step{
      width: 40px;
      height: 40px;
      border-radius: 20px;
      box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
      border: solid 4px #f8f8f9;
      background-color: #ced2dc;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #FFF;
      margin-top: -20px;
      /* cursor: pointer; */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  form {
    background: transparent;

    margin: auto;
    min-width: 250px;
    display: flex;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    height: auto;
    padding-top: 40px;

    button{
      margin-left: 0px;
    }

    .adicionar{
      width: 135%;
      display: flex;
      flex-direction: row;

      .add{
        width: 146px;
        text-align: center;
        margin-top: auto;
        padding-bottom: 22px;
        margin-left: 10px;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #589bff;
        cursor: pointer
      }
    }


  }

  margin: 0px;
  /* button {
    padding: 20px;
    margin: 40px 15px 10px 10px;
    width: 100%;
    max-width: 400px;
    height: 60px;
  }
  i {
    margin-left: 15px;
  } */
  @media only screen and (max-width: 979px){
    .steps{
      .btn-step-selected{
        margin-top: 1px;
      }
      .btn-step{
        margin-top: 1px;
      }
    }
  }
  @media only screen and (max-width: 780px) {
    /* background: #f8f8f9; */
    height: auto;
  
    .steps{
      display: flex;
      flex-direction: row;
      width: 180px;
      margin: auto;
      justify-content: space-evenly;
      .btn-step-selected{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
        border: solid 4px #f8f8f9;
        background-color: #589bff;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #FFF;
        margin-top: -20px;
        /* cursor: pointer; */
      }
      .btn-step{
        width: 40px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0 0 13px 0 rgba(29, 33, 41, 0.05);
        border: solid 4px #f8f8f9;
        background-color: #ced2dc;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #FFF;
        margin-top: -20px;
        /* cursor: pointer; */
      }
    }
  
    form {
      /* background: #f8f8f9; */
  
      margin: auto;
      // min-width: 250px;
      display: flex;
      display: block;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      height: auto;
      max-width: 400px;

      
      /* button{
        width: 100%;
        max-width: 400px;
        margin-left: 0px;
        margin-top: 60px;
      } */
  
      .adicionar{
        width: 100%;
        max-width: 400px;
        display: flex;
        flex-direction: row;
  
        .add{
          width: 146px;
          text-align: center;
          margin-top: auto;
          padding-bottom: 22px;
          margin-left: 10px;
          font-family: Lato;
          font-size: 11px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #589bff;
          cursor: pointer;
          position: absolute;
          margin-top: 17px;
          margin-left: 160px;
        }
      }  
    }
  
    margin: 0px;
    /* button {
      padding: 20px;
      margin: 40px 15px 10px 10px;
      width: 100%;
      max-width: 400px;
      height: 60px;
    } */
    i {
      margin-left: 15px;
    }
  }
`;


export const InfoCard = styled.div`
  width: 285px;
  height: 345px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 30px;
  justify-content: space-around;
  h1 {
    font-size: 24px;
    color: #303848;
  }
  .item {
    p {
      color: #303848;
      font-size: 14px;
      font-weight: 700;
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
      i {
        margin-right: 7px;
        color: #bbb;
        margin-left: -5px;
      }
    }
    small {
      font-size: 11px;
      color: #bac1cf;
      font-weight: 700;
    }
  }
`;

export const InfoCardWrapper = styled.div`
  margin-top: -100px;
  width: 100%;
  display: ${props =>
    props.show === "sm" ? "none" : props.show === "xs" ? "none" : "flex"};
  justify-content: center;
  align-items: center;
`;

