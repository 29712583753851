import styled from "styled-components";

export const InputContainer = styled.div`
  
  width: 100%;
  height: 200px;
  border-radius: 25px;
  border: 1px solid rgb(186, 193, 207);
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '25px'};
  position: relative;
  background-color: #FFF;

  canvas {
    &::placeholder {
      color: ${props => props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#8a92a2'};
      opacity: 1; /* Firefox */
    }
  }

  .fa-eye {
    cursor: pointer;
    position: relative;
    top: -36px;
    left: 85%;

    transition: all 0.3s ease;
    &:hover{
      opacity: 0.5;
    }

    &:active {
      transform: scale(0.7);
    }
  }
  
  .fa-eye-slash {
    cursor: pointer;
    position: relative;
    top: -36px;
    left: 85%;

    transition: all 0.3s ease;
    &:hover{
      opacity: 0.5;
    }

    &:active {
      transform: scale(0.7);
    }
  }

  .help-buttom{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -37px;
    margin-right: -40px;

    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #589bff;
    cursor: pointer
  }

`;

export const Label = styled.label`
  width: auto;
  height: 3px;

  background-color: #FFF;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 0px;
  margin-left: 25px;
  margin-top: -11px;

  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  position: absolute;
  
  color: ${props => props.erro ? '#ff5252' : '#8a92a2'};
  opacity: ${props => props.isDisabled ? '0.5' : '1'};
  z-index: 1;
  
  transition: all 0.3s;
  border-radius: 65px;


  &:hover {
    cursor: ${props => !props.existValue ? !props.isFocus ? !props.isDisabled ? 'text' : 'context-menu' : 'context-menu' : 'context-menu'};
  }


`;

export const Linha = styled.div`
    width: 90%;
    height: 2px;
    background-color: #000;
    position: relative;
    margin: auto;
    margin-top: -30px;
`;

export const Container = styled.div`  
  width: 100%;
  height: auto;

  .arquivos{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      label{
        font-size: smaller;
        width: 100%;
        margin-left: 5px;
        text-align: center;
        color: #8a92a2;
      }
      .btn-update{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
        border: dashed 1px #8a92a2;
        border-radius: 8px;
      }
      .arq-name{
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          transform: rotate(45deg);
          z-index: 10;
        }

        img:hover{
          cursor: pointer;
        }
      }
    }
    .legenda {
      font-size: smaller;
      width: 100%;
      margin-left: 5px;
      text-align: center;
    }

    .button-finish{
      margin-bottom: 0px;
    }

    @media only screen and (max-width: 375px) {
        .btn-update{
          margin-bottom: 100px;
        }
        
        .arq-name{
          font-size: ${props => props.isModal ? '9px' : '12px'};
        }

        .button-finish{
          margin-bottom: 200px;
        } 
    }
`;