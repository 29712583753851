import styled from "styled-components";
import iconCalendar from "../../assets/icons/icons8-calendário-16.png"

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    width: 100%;
`;

export const CardMateria = styled.div`
    width: ${props => props.isMobile ? "90%" : "30%"};
    min-height: ${props => props.isMobile ? "40%" : "330px"};
    max-height: ${props => props.isMobile ? "40%" : "330px"};

    margin: auto;
    margin-bottom: 30px;
    display: inline;
    background-color:  #fff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
    transition: all 0.3s;

    h3{
        font-family: Lato;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        margin-top: ${props => props.isMobile ? "10px" : "5px"};
        margin-left: 8px;
        margin-right: 8px;
        max-width: ${props => props.isMobile ? "100%" : "100%"};
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    
    h3 a{
        color: #303848;
        text-decoration: none;
    }

    .date{
        font-family: Lato;
        font-size: 9px;
        text-align: center;
        color: #888196;
        margin-bottom: 20px;
    }

    .date i{
        color: #0170B9;
        margin-right: 2px;
    }

    .bookmark{
        margin-left: 10px;
    }

    img{
        min-height: 185px;
        width: 100%;
        max-height: 185px;
        object-fit: cover;
        object-position: center;
        border-radius:10px 10px 0px 0px;
    }

    :hover{
        background-color: #FFF;
        transform: scale(1.06);
    }

    .btn-area{
        display: flex;
        justify-content: center;
        margin-bottom: 15px;
    }
`;