import styled from "styled-components";

export const InputContainer = styled.div`
    background-color: #FFF;
    width: 500px;
    height: 60px;
    border-radius: 25px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 0px;

    .search-button{
        padding: 20px 5px;
        margin-right: 10px;
        cursor: pointer;

    }

    input{
        border: none;
        width: ${props => (props.estado ? "300px" : "430px")};
        height: 60px;
        margin-left: 25px;
    }

    label{
        width: 94px;
        height: 11px;
        font-family: Lato;
        font-size: 9px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: right;
        color: #303848;
        margin-top: 25px;
    }
    img{
        width: 16px;
        height: 16px;
        margin-top: -7px;
        margin-right: 15px;
    }

    select{
        border: none;
        font-family: Lato;
        font-size: 9px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: right;
        color: #303848;
        margin-right: 5px;
        
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        max-width: 500px;

        input{
            border: none;
            width: 100%;
            height: 60px;
            max-width: 400px;
        }
    
        label{
            width: 100%;
            max-width: 94px;
            height: 11px;
            font-family: Lato;
            font-size: 9px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: right;
            color: #303848;
            margin-top: 25px;
        }
        img{
            width: 16px;
            height: 16px;
            margin-top: -7px;
            margin-right: 15px;
        }
    
        select{
            border: none;
            font-family: Lato;
            font-size: 9px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: right;
            color: #303848;
            margin-right: 5px;
        }
    }
`;
