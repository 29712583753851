import styled from "styled-components";
import iconCalendar from "../../assets/icons/icons8-calendário-16.png"

const StyleModal = styled.div`

    .btnf{
        border-radius: 10px;
        border: solid 1.3px #589bff;
        color: #589bff;
        height: 20px;
        font-family: Lato;
        font-size: 8px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #589bff;
        margin-right: 10px;
        padding: 5px;
        margin-top: 20px; 
    }

    .btnf:hover{
        color: #589bff;
    }

    .lbl-title{
        font-family: Lato;
        font-size: 10px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2;
        letter-spacing: 0.7px;
        color: #8a92a2;
    }

    .select-responsavel{
        width: 460px;
        height: 50px;
        border-radius: 25px;
        background-color: #f3f3f5;
        display: flex;
        justify-content: center;
        align-items: center;

        .input-responsavel{
            width: 90%;
            border: none;
            background-color: #f3f3f5;
            height: 40px;
        }
    }

    .data-filter{
        width: 460px;
        height: 50px;
        border-radius: 25px;
        background-color: #f3f3f5;
        display: flex;
        justify-content: center;
        align-items: center;

        .input-data-filter{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .input-date{
                border: none;
                border-right: 1px;
                background-color: #f3f3f5;
                padding-right: 25px;
                height: 40px;
            }

            .input-date + .input-date{
                border-left: solid 1px #dfe4eb;
                padding-left: 25px;
                padding-right: 0px;
            }

            input[type="date"]::-webkit-calendar-picker-indicator{
                color: rgba(0, 0, 0, 0);
                opacity: 1;
                display: block;
                background: url(${iconCalendar}) no-repeat;
                border-width: thin;
                margin-top: 5px;
            }

            input[type="date"]::-webkit-inner-spin-button{
                /* display: none; */
                margin-top: 8px;
            }

            

            
        }
    }

    .btn-filters {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .css-qi07vp-control{
        width: 460px;
        background-color: #f3f3f5;

    }

    .css-10pdfwx-control{
        background-color: #f3f3f5;
    }

    .select1{
        width: 460px;
        background-color: #f3f3f5;
        border-radius: 25px;
    }



    @media only screen and (max-width: 760px) {

        .footer {
            flex-direction: column !important;
            align-items: center !important;

            .note {
                label { 
                    width: 100% !important;
                }
            }
        }

        .btnf{
            border-radius: 10px;
            border: solid 1.3px #589bff;
            color: #589bff;
            height: 20px;
            font-family: Lato;
            font-size: 8px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #589bff;
            margin-right: 10px;
            padding: 5px;
            margin-top: 20px; 
        }

        .btnf:hover{
            color: #589bff;
        }

        .button-finish {
            width: 100% !important;
        }



        .lbl-title{
            font-family: Lato;
            font-size: 10px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: 0.7px;
            color: #8a92a2;
        }

        .select-responsavel{
            width: 95%;
            max-width: 400px;
            height: 50px;
            border-radius: 25px;
            background-color: #f3f3f5;
            display: flex;
            justify-content: center;
            align-items: center;

            .input-responsavel{
                width: 95%;
                max-width: 400px;
                border: none;
                background-color: #f3f3f5;
                height: 40px;
            }
        }

        .data-filter{
            width: 95%;
            max-width: 400px;
            height: 50px;
            border-radius: 25px;
            background-color: #f3f3f5;
            display: flex;
            justify-content: center;
            align-items: center;

            .input-data-filter{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                .input-date{
                    width: 40%;
                    max-width: 400px;
                    border: none;
                    border-right: 1px;
                    background-color: #f3f3f5;
                    padding-right: 25px;
                    height: 40px;
                }

                .input-date + .input-date{
                    border-left: solid 1px #dfe4eb;
                    padding-left: 25px;
                    padding-right: 0px;
                }

                input[type="date"]::-webkit-calendar-picker-indicator{
                    color: rgba(0, 0, 0, 0);
                    opacity: 1;
                    display: block;
                    background: url(${iconCalendar}) no-repeat;
                    border-width: thin;
                    margin-top: 5px;
                }

                input[type="date"]::-webkit-inner-spin-button{
                    /* display: none; */
                    margin-top: 8px;
                }

                

                
            }
        }


        .css-qi07vp-control{
            width: 95%;
            max-width: 400px;
            background-color: #f3f3f5;

        }

        .css-10pdfwx-control{
            background-color: #f3f3f5;
        }

        .select1{
            width: 95%;
            max-width: 400px;
            background-color: #f3f3f5;
            border-radius: 25px;
        }

    }
`;

export default StyleModal;
