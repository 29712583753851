import React from 'react';

const Seta = ({ ...props }) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="25" height="25" viewBox="0 0 25 25" style={{
            marginTop: props.open === false ? '-3px' : '-4px',
            marginLeft: props.open === false ? '-3px' : '-3px',
            transform: props.open === false ? `rotate(317deg)` : `rotate(138deg)`,
        }}>
            <defs>
                <path id="x0pfwhiqra" d="M5.911 3.091L5.911 7.341 7.411 7.341 7.411 1.591 1.661 1.591 1.661 3.091z"/>
            </defs>
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g>
                        <g>
                            <g>
                                <g transform="translate(-1108 -352) translate(358 182) translate(105 135) matrix(1 0 0 -1 645 60) translate(8 9)">
                                    <mask id="1jwkup5qyb" fill="#fff">
                                        <use href="#x0pfwhiqra"/>
                                    </mask>
                                    <use fill="#fff" fill-rule="nonzero" href="#x0pfwhiqra"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}
export default Seta