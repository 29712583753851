export const formatDate = (date) => {

    var d = new Date(date.split('+')[0]),
        month = '' + (d.getUTCMonth() + 1),
        day = '' + (d.getUTCDate()),
        year = d.getUTCFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [day, month, year].join('/');
}

export const formatDateYMD = (date) => {

    try {
        var d = new Date(date.split('+')[0]),
            month = '' + (d.getUTCMonth() + 1),
            day = '' + (d.getUTCDate()),
            year = d.getUTCFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');

    } catch (error) {
        var d = new Date(date),
            month = '' + (d.getUTCMonth() + 1),
            day = '' + (d.getUTCDate()),
            year = d.getUTCFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }
}

export const formatDateTime = (date) => {

    var d = new Date(date.split('+')[0]),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hour = d.getHours(),
        min = d.getMinutes(),
        sec = d.getSeconds();

    if (month.length < 2) {
        month = '0' + month;
    }

    if (day.length < 2) {
        day = '0' + day;
    }


    let dt = [day, month, year].join('/');
    let hr = [hour, min < 10 ? min +'0' : min , sec + '0'].join(':');

    const dateTime = dt + ' ' + hr;

    return dateTime;
}

export function formatarData(str) { //função para deixar a data na agenda igual do Google
    var meses = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro",
                 "Outubro","Novembro","Dezembro"];

    var dias = ["domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]
    
    var partes = str.split('/').map(Number);
    
    var data = new Date('20' + partes[2], partes[1] - 1, partes[0]);
    var diaSemana = dias[data.getDay() % 7];
    var ano = partes[2];
    var mes = meses[data.getMonth()];
    return [mes.slice(0, 3).toUpperCase() + '.' + ' ', ano + ',' + ' ', '(' + diaSemana.slice(0, 3).toUpperCase() + ')' + '.'].join(' ');
}

export function pegarHora(str) { //função para agenda  
    console.log(str);
    
    var partes = str.split(' ').map(String);

    console.log(partes);

    if (partes.length > 1) {
        var horas = partes[1]
        var horasSplice = horas.slice(0, 5)
    
        return horasSplice;
    } else {
        var partes = str.split('T').map(String);

        console.log(partes);
        var horas = partes[1];
        console.log(horas);
        var horasSplice = horas.slice(0, 5);
    
        return horasSplice;
    }

}

export function pegarDia(str) { //função para agenda  


    var partes = str.split('T').map(String);

    if (partes.length > 1) {
        var partes = str.split('T').map(String);
        // var partesDia = partes[0].split(' ').map(String);
    
        console.log("PARTESSS => ", partes)

        var dia = partes[0].split('-');
        dia = dia[dia.length-1];


        // var dia = partesDia[0]
    
        return dia;

    } else {

        var partes = str.split('-').map(String);
        var partesDia = partes[2].split(' ').map(String);
    
        console.log(partesDia)
        var dia = partesDia[0]
    
        return dia;
    }
}

export function parseDateToObject(date) {
    const obj = new Date(date.split('+')[0]);
    return obj;
}