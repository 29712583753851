import React from 'react';

const IconNo = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" viewBox="0 0 80 80" width="19px" height="19px">
            <g id="surface30300195">
                <path 
                    style={{
                        stroke:'none',
                        fillRule: 'nonzero',
                        fill: 'rgb(80.784315%,82.352942%,86.274511%)',
                        fillOpacity: 1
                    }}
                    d="M 37.5 5 C 19.570312 5 5 19.570312 5 37.5 C 5 55.429688 19.570312 70 37.5 70 C 55.429688 70 70 55.429688 70 37.5 C 70 19.570312 55.429688 5 37.5 5 Z M 51.035156 47.5 L 47.5 51.035156 L 37.5 41.035156 L 27.5 51.035156 L 23.964844 47.5 L 33.964844 37.5 L 23.964844 27.5 L 27.5 23.964844 L 37.5 33.964844 L 47.5 23.964844 L 51.035156 27.5 L 41.035156 37.5 Z M 51.035156 47.5 "
                />
            </g>
        </svg>
    )
}

export default IconNo