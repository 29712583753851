import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Input from '../input/index';
import Select from '../select/index';
import StyleModal from './stylesModalFilter1';
import api from "../../services/api";
import { store } from "react-notifications-component";
import ModalYesNo from './modalYesNo';
import Modal from '.';
import { ModalTitle } from './styles';



class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        numeroOrdem: '',
        numeroOrdemInicial: '',
        maxNum: '',
        minNum: '',
        showModal: false

    }

    componentDidMount(){
        // console.log(this.props);
        // this.setState({ optionValue: this.props.optionValue });
        this.getQuantidade();    
    }

    getQuantidade = async () => {
        try {
            const response = await api.get(`/creasp/${this.props.artId}/contratos`);
            // console.log(response.data.data[0]);
            this.setState({numeroOrdemInicial: response.data.data[0].num_ordem_inicial});
            this.setState({maxNum: response.data.data[0].num_ordem_final});
            // console.log(this.state.maxNum);
            
        } catch (error) {
            // if(error.response.status == 500){
            //     window.location = '/login';
            // }
        }
        
    }

    confirm = () => {
        // this.setState({ showModal: true });
        this.exportar();
    }


    exportar = async () => {
        let respostaFiltro = this.state.numeroOrdem;
        // console.log(respostaFiltro);
        if(this.state.numeroOrdemInicial && respostaFiltro){
            let inputWithErrors = document.querySelectorAll(".error");
            for (let input of inputWithErrors) {
                input.classList.remove("error");
            }
            this.props.update(this.state.numeroOrdemInicial, respostaFiltro);
        }else{

            if(!this.state.numeroOrdemInicial){
                const input = document.getElementById("inicial");
                input.classList.add("error");
            }
            if(!respostaFiltro){
                const input = document.getElementById("final");
                input.classList.add("error");
            }

            store.addNotification({
                title: "Erro!",
                message: `Informe a faixa desejada de número de ordem`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    // modalClose = () => {
    //     if(this.state.showModal == true){
    //         this.setState({ showModal: false });
    //     }
    // }

    // updateResposta = resposta => {
    //     if(resposta == 1){
    //         this.exportar();
    //         this.modalClose();
    //     }else{
    //         this.props.onHide();
    //     }
    // }
    

    render() {
        return (

            // <Modal
            //     {...this.props}
            //     size="lg"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >
            //     <Modal.Header closeButton>
            //         <Modal.Title id="contained-modal-title-vcenter">
            //             {this.props.title} {this.props.option}
            //         </Modal.Title>
            //     </Modal.Header>


            //     <Modal.Body>
            //         <StyleModal>

            //             <label style={{ fonSize: "small" }}>Informe a faixa desejada do número de ordem para exportar o arquivo CREA</label>

            //             <Input
            //                 placeholder="Número de ordem inicial"
            //                 value={this.state.numeroOrdemInicial}
            //                 onChange={e => this.setState({ numeroOrdemInicial: e.target.value })}
            //                 type="number"
            //                 max={this.state.maxNum}
            //                 min={this.state.numeroOrdemInicial}
            //                 id="inicial"
                            
            //                 >
            //             </Input>
            //             <Input
            //                 // label="Número de ordem"
            //                 // placeholder="Número de ordem"
            //                 placeholder="Número de ordem final"
            //                 // value={this.state.numeroOrdemInicial}
            //                 onChange={e => this.setState({ numeroOrdem: e.target.value })}
            //                 type="number"
            //                 min={this.state.numeroOrdemInicial}
            //                 max={this.state.maxNum}
            //                 id="final"
                            
            //                 >
            //             </Input>
            //         </StyleModal>

            //     </Modal.Body>

            //     <Modal.Footer>
            //         <div className="footer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            //             <label style={{ fontSize: "medium", fontWeight: "700", width: "70%", textAlign: 'center' }}>A responsabilidade pelo envio dos dados para as defesas agropecuárias é do usuário. Tem certeza de que deseja gerar o arquivo?</label>

            //             <Button style={{outline:0, padding: "15px", height: '46px'}}
            //                 outline
            //                 color="#00b43f"
            //                 onClick={() => this.confirm()}
            //             >Exportar</Button>
            //         </div>

            //     </Modal.Footer>
            // </Modal>


            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                width='50%'
            >
                <ModalTitle>
                    {this.props.title} {this.props.option}
                </ModalTitle>

                <div>

                    <label style={{ fonSize: "small" }}>Informe a faixa desejada do número de ordem para exportar o arquivo CREA</label>

                    <Input
                        placeholder="Número de ordem inicial"
                        value={this.state.numeroOrdemInicial}
                        onChange={e => this.setState({ numeroOrdemInicial: e.target.value })}
                        type="number"
                        max={this.state.maxNum}
                        min={this.state.numeroOrdemInicial}
                        id="inicial"
                     />

                    <Input
                        placeholder="Número de ordem final"
                        onChange={e => this.setState({ numeroOrdem: e.target.value })}
                        type="number"
                        min={this.state.numeroOrdemInicial}
                        max={this.state.maxNum}
                        id="final"   
                    />


                </div>


                <div className="footer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <label style={{ fontSize: "medium", fontWeight: "700", width: "70%", textAlign: 'center' }}>A responsabilidade pelo envio dos dados para as defesas agropecuárias é do usuário. Tem certeza de que deseja gerar o arquivo?</label>

                    <Button style={{outline:0, padding: "15px", height: '46px'}}
                        outline
                        color="#00b43f"
                        onClick={() => this.confirm()}
                    >
                        Exportar
                    </Button>
                </div>
            </Modal>

        );
    }
}
export default ModalNew;