import React, { useState, useEffect } from "react";

import Select from "../../select/select_v2";

import Button from "../../button/button_v2";
import api from "../../../services/api";
import { store } from "react-notifications-component";
import { Container } from "react-grid-system";
import Input from "../../input/input_v2";


import ModalText from "../../modal/modalText";
import { FormContainer } from "./styles";

import AddAgronomo from '../../../pages/agronomos/add';
import Modal from '../../modal/modalGeneric';


import estados from "../../../services/estados";
import { Row, Col, Hidden } from "react-grid-system";

const ArtsOne = ({ ...props }) => {

    const { update, next } = props;
    const [agronomoId, setAgronomoId] = useState('');
    const [estadoId, setEstado] = useState('');
    const [creaId, setCreaId] = useState('');
    const [agronomos, setAgronomos] = useState([]);
    const [creas, setCreas] = useState([]);
    // const [filialId, setFilialId] = useState("");
    const [max, setMax] = useState("");
    const [numeroArt, setNumeroArt] = useState("");
    const [receitaInicial, setReceitaInicial] = useState("");
    const [quantidadeReceita, setQuantidadeReceita] = useState("");
    const [quantidadeContratos, setQuantidadeContratos] = useState("");
    const [numeroOrdem, setNumeroOrdem] = useState("");
    const [disabled, setDisabled] = useState(true);
    const [step] = useState(1);
    const [valorInformacao, setValorInformacao] = useState("");
    const [modalAgronomoShow, setModalAgronomoShow] = useState(false);
    const [modalCreaShow, setModalCreaShow] = useState(false);
    // const [nomeId, setNomeId] = useState('');
    // const [cpfId, setCpfId] = useState('');
    const [passo3, setPasso3] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [showModalValidade, setShowModalValidade] = useState(false);
    const [placeholderCfta, setPlaceholderCFTA] = useState('');
    const [placeholderTrt, setPlaceholderTRT] = useState('');

    const [erroNumeroArt, setErroNumeroArt] = useState(false);
    const [erroCreaId, setErroCreaId] = useState(false);
    const [erroAgronomoId, setErroAgronomoId] = useState(false);
    const [erroReceitaInicial, setErroReceitaInicial] = useState(false);
    const [erroQuantidadeContratos, setErroQuantidadeContratos] = useState(false);
    const [erroNumeroOrdem, setErroNumeroOrdem] = useState(false);

    const [qtdReceitasEmitidas, setQtdReceitasEmitidas] = useState('0');

    const [loading, setLoading] = useState(false);
    const [inativoSaldoArt, setInativoSaldoArt] = useState(false);

    const [legendaQuantidade, setLegendaQuantidade] = useState('');
    const [saldoArts, setSaldoArts] = useState(null);

    const [sendAlert, setSendAlert] = useState(true);

    const [dataValidade, setDataValidade] = useState('');


    // let maska = "A";




    const formatAgronomo = agronomos => {

        agronomos = agronomos.map(agronomo => {
            const format = {
                value: agronomo.id,
                label: agronomo.nome
            };

            return format;
        });

        return agronomos;
    };
    const formatCreas = creas => {
        creas = creas.map(crea => {
            const format = {
                value: crea.id,
                label: crea.crea
            };
            return format;
        });
        return creas;
    };



    const getAgronomos = async (valor) => {

        // const response = await api.get(`/agronomos?status=1&estado=${valor}`);
        if (valor) {

            let agronomosByState = [];

            const response = await api.get(`/agronomos/estado/${valor}?action=new`);
            let agronomos = response.data.data;

            agronomos = formatAgronomo(agronomos);
            setAgronomos(agronomos);
        }
    };
    // const getFilial = async agronomoId => {
    //     if (agronomoId) {
    //         try {
    //             const response = await api.get(`/agronomos/${agronomoId.value || agronomoId}`);
    //             setFilialId(response.data.data.filial);
    //         } catch (error) {
    //             // if(error.response.status == 500){
    //             //     window.location = '/login';
    //             // }
    //         }
    //     }
    // };

    const getCreas = async (agronomoId, valor) => {
        // setCreas([{ label: "Não encontrado" }]);
        if (agronomoId && valor) {
            try {
                const response = await api.get(
                    `/agronomos/${agronomoId.value || agronomoId || "*"}/creas?&estado=${valor || "*"}`
                );
                let creas = response.data.data;
                creas = formatCreas(creas);
                setCreas(creas);
            } catch (error) {

            }
        }
    };
    const getMax = async valor => {
        if (valor) {
            try {
                const response = await api.get(`/maxcontratos/${valor}`);
                const max = response.data.data;
                setMax(max);
            } catch (error) {
                // if(error.response.status == 500){
                //     window.location = '/login';
                // }
            }
        }
    };
    const testaArt = async () => {
        try {
            setErroNumeroArt(false);

            const response = await api.get(`/arts`);
            const arts = response.data.data;

            let isError = false;

            arts.map(art => {
                if (numeroArt === art.numero_art) {
                    if (agronomoId === art.crea.agronomo.id) {
                        isError = true;
                    }
                }
            });

            if (isError === true) {
                setErroNumeroArt(true);

                store.addNotification({
                    title: "Erro!",
                    message: "Esse número de ART/TRT já esta Cadastrado.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                return false;
            } else {
                setErroNumeroArt(false);
                return true;
            }

        } catch (error) {
            setErroNumeroArt(false);
            return true;
        }


    };



    const handlePost = async e => {
        // e.preventDefault();
        setLoading(true);
        setEstado(props.estado.value);

        const valor = props.estado.value || props.estado;

        // let inputWithErrors = document.querySelectorAll(".error");

        // for (let input of inputWithErrors) {
        //     input.classList.remove("error");
        // }

        if (valor === 'SP') {
            
            if (!creaId || !agronomoId || !numeroArt || !receitaInicial || !quantidadeContratos || !numeroOrdem) {
                if (!creaId) {
                    setErroCreaId(true);
                }

                if (!agronomoId) {
                    setErroAgronomoId(true);
                }

                if (!numeroArt) {
                    setErroNumeroArt(true);
                }

                if (!receitaInicial) {
                    setErroReceitaInicial(true);
                }

                if (!quantidadeContratos) {
                    setErroQuantidadeContratos(true);
                }

                if (!quantidadeReceita) {
                    setErroQuantidadeContratos(true);
                }


                store.addNotification({
                    title: "Erro!",
                    message: "Preencha os campos em vermelho para continuar",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                setLoading(false);
                return;
            } else {
                const payload = {
                    estado: valor,
                    creaId: creaId.value || creaId,
                    agronomoId: agronomoId.value || agronomoId,
                    numero_art: numeroArt,
                    quantidade_receitas: quantidadeReceita,
                    receita_inicial: receitaInicial,
                    quantidade_contratos: quantidadeContratos,
                    numero_ordem: numeroOrdem,
                    data_validade: dataValidade
                };

                try {
                    const response = await api.post(`/creas/${creaId.value || creaId}/arts`, payload);
                    if (response.status === 200) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: "O ART foi cadastrado corretamente.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }

                    if (props.isModal === true) {
                        props.updateArt();
                    } else {
                        props.loc.history.push('/arts');
                    }

                } catch (err) {
                    const ERRO = err.response.data.errors;
                    if (err.response.status === 406) {
                        setErroReceitaInicial(true)

                        store.addNotification({
                            title: "Erro!",
                            message: `${ERRO}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                        setLoading(false);

                    } else {
                        try {
                            let inputWithErrors = document.querySelectorAll(".error");

                            for (let input of inputWithErrors) {
                                // input.classList.remove("error");

                                if (input === 'creaId') {
                                    setErroCreaId(false);
                                } else if (input === 'agronomoId') {
                                    setErroAgronomoId(false);

                                } else if (input === 'numero_art') {
                                    setErroNumeroArt(false);

                                } else if (input === 'receita_inicial') {
                                    setErroReceitaInicial(false);

                                } else if (input === 'quantidade_receitas') {
                                    setErroQuantidadeContratos(false);
                                }
                            }

                            const errors = err.response.data.errors;


                            for (let field in errors) {
                                // const input = document.getElementById(field);
                                // input.classList.add("error");

                                if (field === 'creaId') {
                                    setErroCreaId(true);
                                } else if (field === 'agronomoId') {
                                    setErroAgronomoId(true);

                                } else if (field === 'numero_art') {
                                    setErroNumeroArt(true);

                                } else if (field === 'receita_inicial') {
                                    setErroReceitaInicial(true);

                                } else if (field === 'quantidade_receitas') {
                                    setErroQuantidadeContratos(true);
                                }

                            }

                            if (typeof errors === 'string') {
                                store.addNotification({
                                    title: "Erro!",
                                    message: errors,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });

                            } else {

                                store.addNotification({
                                    title: "Erro!",
                                    message: "Verifique os campos em vermelho",
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            }

                            setLoading(false);

                        } catch (err) {
                            store.addNotification({
                                title: "Erro!",
                                message: `${ERRO}`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                            setLoading(false);

                        }
                    }
                }
            }

        } else {
            if (!creaId || !agronomoId || !numeroArt || !receitaInicial || !quantidadeReceita) {

                if (!creaId) {
                    setErroCreaId(true);
                }

                if (!agronomoId) {
                    setErroAgronomoId(true);
                }

                if (!numeroArt) {
                    setErroNumeroArt(true);
                }

                if (!receitaInicial) {
                    setErroReceitaInicial(true);
                }

                if (!quantidadeReceita) {
                    setErroQuantidadeContratos(true);
                }


                store.addNotification({
                    title: "Erro!",
                    message: "Preencha os campos em vermelho para continuar",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                setLoading(false);
                return;
            } else {
                const payload = {
                    estado: valor,
                    creaId: creaId.value || creaId,
                    agronomoId: agronomoId.value || agronomoId,
                    numero_art: numeroArt,
                    quantidade_receitas: quantidadeReceita,
                    receita_inicial: receitaInicial,
                    qtd_avisar: saldoArts ? saldoArts.value : null,
                    qtd_receitas_emitidas: qtdReceitasEmitidas,
                    data_validade: dataValidade
                    // avisar: sendAlert ? 1 : 0
                };

                try {
                    const response = await api.post(`/creas/${creaId.value || creaId}/arts`, payload);
                    if (response.status === 200) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: "O ART foi cadastrado corretamente.",
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }

                    if (props.isModal === true) {
                        props.updateArt();
                    } else {
                        props.loc.history.push('/arts');
                    }

                } catch (err) {
                    if (err.response.status === 406) {
                        setErroReceitaInicial(true)
                        const ERRO = err.response.data.errors;

                        store.addNotification({
                            title: "Erro!",
                            message: `${ERRO}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                        setLoading(false);
                    } else {
                        const ERRO = err.response.data.errors;
                        try {
                            let inputWithErrors = document.querySelectorAll(".error");

                            for (let input of inputWithErrors) {
                                // input.classList.remove("error");

                                if (input === 'creaId') {
                                    setErroCreaId(false);
                                } else if (input === 'agronomoId') {
                                    setErroAgronomoId(false);

                                } else if (input === 'numero_art') {
                                    setErroNumeroArt(false);

                                } else if (input === 'receita_inicial') {
                                    setErroReceitaInicial(false);

                                } else if (input === 'quantidade_receitas') {
                                    setErroQuantidadeContratos(false);
                                }
                            }

                            const errors = err.response.data.errors;


                            for (let field in errors) {
                                // const input = document.getElementById(field);
                                // input.classList.add("error");

                                if (field === 'creaId') {
                                    setErroCreaId(true);
                                } else if (field === 'agronomoId') {
                                    setErroAgronomoId(true);

                                } else if (field === 'numero_art') {
                                    setErroNumeroArt(true);

                                } else if (field === 'receita_inicial') {
                                    setErroReceitaInicial(true);

                                } else if (field === 'quantidade_receitas') {
                                    setErroQuantidadeContratos(true);
                                }

                            }

                            if (typeof errors === 'string') {
                                store.addNotification({
                                    title: "Erro!",
                                    message: errors,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });

                            } else {

                                store.addNotification({
                                    title: "Erro!",
                                    message: "Verifique os campos em vermelho",
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            }

                            setLoading(false);

                        } catch (err) {
                            store.addNotification({
                                title: "Erro!",
                                message: `${ERRO}`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                            setLoading(false);

                        }
                    }
                }
            }
            setLoading(false);

        }


    };


    const nextStep = async e => {
        // e.preventDefault();


        let inputWithErrors = document.querySelectorAll(".error");

        for (let input of inputWithErrors) {
            if (input === 'creaId') {
                setErroCreaId(false);
            } else if (input === 'agronomoId') {
                setErroAgronomoId(false);

            } else if (input === 'numero_art') {
                setErroNumeroArt(false);

            } else if (input === 'receita_inicial') {
                setErroReceitaInicial(false);

            } else if (input === 'quantidade_receitas') {
                setErroQuantidadeContratos(false);
            }
        }

        if (!agronomoId || !creaId || !numeroArt || !receitaInicial || !parseInt(receitaInicial) || !quantidadeContratos || !numeroOrdem) {
            setLoading(false);

            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos em vermelho",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });

            if (!creaId) {
                setErroCreaId(true);
            }

            if (!agronomoId) {
                setErroAgronomoId(true);
            }

            if (!numeroArt) {
                setErroNumeroArt(true);
            }

            if (!receitaInicial) {
                setErroReceitaInicial(true);
            }

            if (!parseInt(receitaInicial)) {
                setErroReceitaInicial(true);
            }

            if (!quantidadeContratos) {
                setErroQuantidadeContratos(true);
            }

            if (!numeroOrdem) {
                setErroNumeroOrdem(true);
            }
        }

        const testArt = await testaArt();
        
        if (testArt) {
            if (agronomoId && estadoId && step && creaId && numeroArt && receitaInicial && parseInt(receitaInicial) && quantidadeContratos && numeroOrdem) {

                update(
                    agronomoId,
                    estadoId,
                    step,
                    creaId,
                    numeroArt,
                    receitaInicial,
                    quantidadeContratos,
                    numeroOrdem,
                    dataValidade
                );

                if (max.max_receitas || max.min_receitas) {
                    if (quantidadeReceita && quantidadeReceita != '') {
                        next();
                    }
                } else if (max.max_contratos || max.min_contratos) {
                    if (quantidadeContratos && quantidadeContratos != '') {
                        next();
                    }
                }

            } else if (agronomoId.value && creaId.estado && step && creaId.value && numeroArt && receitaInicial && quantidadeContratos && numeroOrdem) {
                
                update(
                    agronomoId.value,
                    creaId.estado,
                    step,
                    creaId.value,
                    numeroArt,
                    receitaInicial,
                    quantidadeContratos,
                    numeroOrdem,
                    dataValidade
                );


                if (max.max_receitas || max.min_receitas) {
                    if (quantidadeReceita && quantidadeReceita != '') {
                        next();
                    } else {
                    }
                } else if (max.max_contratos || max.min_contratos) {
                    if (quantidadeContratos && quantidadeContratos != '') {
                        next();
                    } else {

                    }
                }
            }
        }

    };

    const changeSendAlert = (e) => {
        setSendAlert(e);
    }

    const changeDataValidade = (e) => {
        setDataValidade(e);
    }



    const validateNumeroArt = numeroArt => {

        setNumeroArt(numeroArt);
    };
    const validateReceitaInicial = async receitaInicial => {
        setReceitaInicial(receitaInicial);
    };
    const validateNumeroOrdem = numeroOrdem => {
        setNumeroOrdem(numeroOrdem);
    };
    const validateQuantidadeContratos = quantidadeContratos => {
        setQuantidadeContratos(quantidadeContratos);
        setErroQuantidadeContratos(false);
    };
    const validateQuantidadeReceita = quantidadeReceita => {
        let inputWithErrors = document.querySelectorAll(".error");

        for (let input of inputWithErrors) {
            input.classList.remove("error");
        }

        setQuantidadeReceita(quantidadeReceita);
    };

    const validateQtdTotalReceitas = (valor) => {
        setQtdReceitasEmitidas(valor);
    }

    const validateInterval = valor => {
        if (valor == 0) {


            setQuantidadeReceita('');

            setErroQuantidadeContratos(true);

            store.addNotification({
                title: "Erro!",
                message: "Informe um valor maior que 0",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        } else {

            if (max) {
                if (max.min_receitas && max.max_receitas) {
                    if (valor < max.min_receitas || valor > max.max_receitas) {
                        //Exibir erro: Valor está fora da faixa de valor permitida
                        // alert("Valor está fora da faixa de valor permitida");
                        setQuantidadeReceita('');
                        setErroQuantidadeContratos(true);

                        // const input = document.getElementById('quantidade_receitas');
                        // input.classList.add("error");
                        store.addNotification({
                            title: "Erro!",
                            message: "O valor está fora da faixa de valor permitida",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                } else if (max.min_receitas) {
                    if (valor < max.min_receitas) {
                        //Exibir erro: Valor digitado é menor que o valor mínimo permitido
                        // alert("Valor digitado é menor que o valor mínimo permitido");
                        setQuantidadeReceita('');
                        // const input = document.getElementById('quantidade_receitas');
                        // input.classList.add("error");
                        setErroQuantidadeContratos(true);

                        store.addNotification({
                            title: "Erro!",
                            message: "O valor digitado é menor que o valor mínimo permitido",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });

                    }
                } else if (max.max_receitas) {
                    if (valor > max.max_receitas) {
                        //Exibir erro: Valor digitado é maior que o valor maximo permitido
                        // alert("Valor digitado é maior que o valor maximo permitido");
                        setQuantidadeReceita('');
                        setErroQuantidadeContratos(true);

                        // const input = document.getElementById('quantidade_receitas');
                        // input.classList.add("error");

                        store.addNotification({
                            title: "Erro!",
                            message: "O valor digitado é maior que o valor maximo permitido",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });

                    }
                } else if (max.min_contratos && max.max_contratos) {
                    if (valor < max.min_contratos || valor > max.max_contratos) {
                        //Exibir erro: Valor está fora da faixa de valor permitida
                        // alert("Valor está fora da faixa de valor permitida");
                        setQuantidadeContratos('');
                        setErroQuantidadeContratos(true);

                        // const input = document.getElementById('quantidadeContratos');
                        // input.classList.add("error");
                        store.addNotification({
                            title: "Erro!",
                            message: "O valor está fora da faixa de valor permitida",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });

                    }
                } else if (max.min_contratos) {
                    if (valor < max.min_contratos) {
                        //Exibir erro: Valor digitado é menor que o valor mínimo permitido
                        // alert("Valor digitado é menor que o valor mínimo permitido");
                        setQuantidadeContratos('');
                        // const input = document.getElementById('quantidadeContratos');
                        // input.classList.add("error");

                        setErroQuantidadeContratos(true);

                        store.addNotification({
                            title: "Erro!",
                            message: "O valor digitado é menor que o valor mínimo permitido",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                } else {
                    if (valor > max.max_contratos) {
                        //Exibir erro: Valor digitado é maior que o valor maximo permitido
                        // alert("Valor digitado é maior que o valor maximo permitido");
                        setQuantidadeContratos('');
                        setErroQuantidadeContratos(true);

                        // const input = document.getElementById('quantidadeContratos');
                        // input.classList.add("error");
                        store.addNotification({
                            title: "Erro!",
                            message: "O valor digitado é maior que o valor maximo permitido",
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        }
    }

    const validatesao = () => {
        if (props.estado.value === "SP" || props.estado === 'SP') {

            setPasso3(true);
            if (agronomoId) {
                if (creaId) {
                    if (numeroArt) {
                        if (receitaInicial && receitaInicial > 0) {

                            if (quantidadeContratos >= max.min_contratos && quantidadeContratos <= max.max_contratos) {
                                if (numeroOrdem) {

                                    setDisabled(false);

                                } else {

                                    setDisabled(true);
                                }
                            } else {

                                setDisabled(true);
                            }
                        } else {

                            setDisabled(true);
                        }
                    } else {

                        setDisabled(true);
                    }
                } else {

                    setDisabled(true);
                }
            } else {

                setDisabled(true);
            }
        }
    };
    const validateNacional = () => {
        if (props.estado.value === "NACIONAL" || props.estado === 'NACIONAL') {
            setPasso3(false);
            if (agronomoId) {
                if (creaId) {
                    if (numeroArt) {
                        if (receitaInicial) {

                            // if (quantidadeContratos >= max.min_contratos && quantidadeContratos <= max.max_contratos) {
                            if (quantidadeReceita >= max.min_receitas && quantidadeReceita <= max.max_receitas) {

                                setDisabled(false);

                            } else {

                                setDisabled(true);
                            }
                            // } else {

                            //     setDisabled(true);
                            // }
                        } else {

                            setDisabled(true);
                        }
                    } else {

                        setDisabled(true);
                    }
                } else {

                    setDisabled(true);
                }
            } else {

                setDisabled(true);
            }
        }
    };
    const validateforms = () => {

        if ((props.estado.value !== "SP" && props.estado !== 'SP') && (props.estado.value !== "NACIONAL" || props.estado !== 'NACIONAL')) {
            setPasso3(false);

            if (agronomoId) {

                if (creaId) {

                    if (numeroArt) {

                        if (receitaInicial && receitaInicial > 0) {
                            if (quantidadeReceita) {

                                if (quantidadeReceita > 0 && quantidadeReceita <= max.max_receitas) {

                                    setDisabled(false);
                                } else {
                                    setDisabled(true);
                                }
                            } else {
                                setDisabled(true);
                            }
                        } else {
                            setDisabled(true);
                        }
                    } else {

                        setDisabled(true);
                    }
                } else {

                    setDisabled(true);
                }
            } else {
                setDisabled(true);

            }
        }
    };

    const addModalAgronomo = () => {
        setModalAgronomoShow(true);
    };
    const addModalClose = e => {
        // e.preventDefault();

        if (modalAgronomoShow == true) {
            setModalAgronomoShow(false);
        }

        if (modalCreaShow == true) {
            setModalCreaShow(false);
        }

    };
    const modalClose = e => {
        if (showModal == true) {
            setShowModal(false);
        }

        if (showModal2 == true) {
            setShowModal2(false);
        }
    };

    const openModalText = e => {
        setShowModal(true);
    }

    const openModalText2 = e => {
        setShowModal2(true);
    }

    const openModalTextValidade = e => {
        setShowModalValidade(true);
    }


    const updateIdAgronomo = async () => {

        // setNomeId(nomeId);
        // setCpfId(cpfId);
        setModalAgronomoShow(false);
        // setModalCreaShow(true);

        getAgronomos(props.estado.value || props.estado);
        setAgronomoId('');

        //FALTA RECARREGAR CAMPO AGRONOMOS TODA VEZ QUE CRIAR UM NOVO PELO MODAL
    }


    const updateCrea = async (e) => {
        setModalCreaShow(false);

        getAgronomos(props.estado.value || props.estado);
        // getCreas();

    };

    const validaAgronomo = (valor) => {
        if (valor !== agronomoId) {
            setAgronomoId(valor);
        }
    }

    useEffect(() => {

        if (props.isModal != true) {
            window.scrollTo(0, 0);
        }

        if (props.isModal === true && props.agronomo && props.crea) {
            setAgronomoId(props.agronomo);
            setCreaId(props.crea);
        }

        let state = props.isModal != true ? props.estado.value || props.estado : props.crea.estado;

        getAgronomos(state);
        getMax(state);
        setEstado(state);

        if (state === 'SP') {
            setPasso3(true);
        }

        if (state === "NACIONAL") {
            let now = new Date();

            let d1 = now.getDate();
            let m1 = now.getMonth();
            let y1 = now.getFullYear();
            m1 = m1 + 1;


            if (m1 <= 9) {
                m1 = `0${m1}`;
            }

            setPlaceholderCFTA(`Ex: BR${y1}${m1}RA000001`);
            setPlaceholderTRT(`Ex: BR${y1}${m1}00001`);

        }
    }, [])

    useEffect(() => {
        const prop_estado = props.estado.value ? props.estado.value || props.estado : props.estado;
        if (prop_estado && agronomoId) {
            getCreas(agronomoId, prop_estado);
        }
    }, [agronomoId]);

    useEffect(() => {
        if (props.isModal === false) {
            if (estadoId.value !== "SP") {
                if (receitaInicial > max.max_receitas) {
                    setValorInformacao(`O numero Limite de receitas é ${max.max_receitas}`);
                }
            }
            if (estadoId.value !== "SP") {
                if (receitaInicial < max.max_receitas) {
                    setValorInformacao(``);
                }
            }
        } else {
            let e = props.estado.value || props.estado;
            if (e !== "SP") {
                if (receitaInicial > max.max_receitas) {
                    setValorInformacao(`O numero Limite de receitas é ${max.max_receitas}`);
                }
            }
            if (e !== "SP") {
                if (receitaInicial < max.max_receitas) {
                    setValorInformacao(``);
                }
            }
        }
    }, [receitaInicial, quantidadeReceita]);
    useEffect(() => {
        // validaMinas();
        validatesao();
        validateNacional();
        validateforms();

        if (saldoArts) {
            setInativoSaldoArt(false);
        } else {
            setInativoSaldoArt(false);
        }

        if (agronomoId && erroAgronomoId === true) {
            setErroAgronomoId(false);
        }
        if (creaId && erroCreaId === true) {
            setErroCreaId(false);
        }
        if (numeroArt && erroNumeroArt === true) {
            setErroNumeroArt(false);
        }
        if (receitaInicial && erroReceitaInicial === true) {
            setErroReceitaInicial(false);
        }

        if (quantidadeContratos && erroQuantidadeContratos === true) {
            setErroQuantidadeContratos(false);
        }
        if (quantidadeReceita && erroQuantidadeContratos === true) {
            setErroQuantidadeContratos(false);
        }

    }, [agronomoId, creaId, numeroArt, receitaInicial, quantidadeContratos, quantidadeReceita]);

    useEffect(() => {
        if (numeroOrdem && erroNumeroOrdem === true) {
            setErroNumeroOrdem(false);
        }
    }, [numeroOrdem])
    

    useEffect(() => {
        if (props.estado.value === "SP" || props.estado === 'SP') {
            if (quantidadeContratos > 300) {
                setLegendaQuantidade('Não é permitido mais do que 300 contratos.');
            } else if (quantidadeContratos < 52 && quantidadeContratos !== '') {
                setLegendaQuantidade('Não é permitido menos do que 52 contratos.');
            } else {
                if (max) {
                    if (max.min_contratos == null || max.min_contratos == 0) {
                        setLegendaQuantidade(`De 1 a ${max.max_contratos || "Não há"} contratos`);
                    } else {
                        setLegendaQuantidade(`De ${max.min_contratos} a ${max.max_contratos || "Não há"} contratos`);
                    }
                }
            }
        } else {
            if (max.min_receitas) {
                setLegendaQuantidade(`De ${max.min_receitas} a ${max.max_receitas || "Não há"} receitas`);
            } else {
                setLegendaQuantidade(`De 1 a ${max.max_receitas || "Não há"} receitas`);
            }
        }

    }, [quantidadeContratos, max]);

    const exibeCamposPlaceholder = (
        <>
            <Input
                name="numeroArt"
                type="text"
                id="numero_art"
                label="Número ART/TRT"
                // label={placeholderTrt}
                onChange={e => validateNumeroArt(e)}
                value={numeroArt}
                help={true}
                helpClick={() => openModalText2()}
                erro={erroNumeroArt}
            />

            <Input
                name="receitaInicial"
                type="text"
                label="Receita Inicial"
                // label={placeholderCfta}
                id="receita_inicial"
                onChange={e => validateReceitaInicial(e)}
                value={receitaInicial}
                help={true}
                helpClick={() => openModalText()}
                erro={erroReceitaInicial}
            // mask={props.estado.value == 'NACIONAL'? (`BR999999RA999999`):("")}
            />

            <Input
                name="quantidadeReceitas"
                type="number"
                label="Quantidade de Receitas Emitidas"
                id="qtd_receita_emitida"
                onChange={e => validateQtdTotalReceitas(e)}
                value={qtdReceitasEmitidas}
                // erro={erroReceitaInicial}
                legenda='Informe a quantidade de receitas que já foram emitidas para essa TRT.'
            />

        </>
    )


    const exibeCampos = (
        <>
            <Input
                name="numeroArt"
                type="text"
                id="numero_art"
                label="Número ART/TRT"
                onChange={e => validateNumeroArt(e)}
                value={numeroArt}
                erro={erroNumeroArt}
            // onBlur={testaArt}
            />

            <Input
                name="receitaInicial"
                type="text"
                label="Receita Inicial"
                id="receita_inicial"
                onChange={e => validateReceitaInicial(e)}
                value={receitaInicial}
                erro={erroReceitaInicial}

            // mask={props.estado.value == 'NACIONAL'? (`BR999999RA999999`):("")}
            />

        </>
    )

    return (
        <>
            <FormContainer {...props}>
                <Container>

                    {props.isModal === true ? (null) : (
                        <div className="steps">

                            <div className="btn-step">
                                <label>1</label>
                            </div>

                            <div className="btn-step-selected">
                                <label>2</label>
                            </div>
                            {props.estado.value === 'SP' || props.estado === 'SP' ? (
                                <div className="btn-step">
                                    <label>3</label>
                                </div>
                            ) : null}

                        </div>
                    )}

                    <Row>
                        <Col>
                            <form>
                                <Select
                                    name="agronomo"
                                    className="select"
                                    label={`Responsavel técnico`}
                                    id="agronomoId"
                                    disabled={false}
                                    onChange={(e) => validaAgronomo(e)}
                                    options={agronomos}
                                    value={agronomoId.value || agronomoId}
                                    selectUniqueOption={true}
                                    buttonAddNew={true}
                                    onClickAddNew={() => addModalAgronomo()}
                                    erro={erroAgronomoId}
                                    isModal={props.isModal}
                                />

                                <Select
                                    name="creas"
                                    className="select"
                                    label="CREA/VISTO/CFTA"
                                    id="creaId"
                                    onChange={creaId =>
                                        creaId ? setCreaId(creaId.value) : setCreaId("")
                                    }
                                    value={creaId.value || creaId}
                                    options={creas}
                                    disabled={false}
                                    selectUniqueOption={true}
                                    erro={erroCreaId}
                                />

                                {!placeholderTrt && !placeholderCfta ? (
                                    <>
                                        {exibeCampos}
                                    </>
                                ) : (
                                    <>
                                        {exibeCamposPlaceholder}
                                    </>
                                )}

                                {passo3 == true ? (
                                    <>
                                        {/* {teste} */}
                                        {max.min_contratos == null ? (
                                            <Input
                                                name="quantidadeContratos"
                                                type="number"
                                                min={1}
                                                max={max.max_contratos}
                                                id="quantidadeContratos"
                                                label="Quantidade de contratos"
                                                onChange={e =>
                                                    e
                                                        ? validateQuantidadeContratos(e)
                                                        : validateQuantidadeContratos("")
                                                }
                                                onBlur={e => validateInterval(e.target.value)}
                                                value={quantidadeContratos}
                                                erro={erroQuantidadeContratos}
                                                legenda={legendaQuantidade}
                                            />
                                        ) : (
                                            <Input
                                                name="quantidadeContratos"
                                                type="number"
                                                min={max.min_contratos}
                                                max={max.max_contratos}
                                                id="quantidadeContratos"
                                                label="Quantidade de contratos"
                                                value={quantidadeContratos}
                                                onChange={e =>
                                                    e
                                                        ? validateQuantidadeContratos(e)
                                                        : validateQuantidadeContratos("")
                                                }
                                                onBlur={e => validateInterval(e.target.value)}
                                                erro={erroQuantidadeContratos}
                                                legenda={legendaQuantidade}
                                            />
                                        )}




                                        {props.estado.value === "SP" || props.estado.value === "NACIONAL" || props.estado === 'SP' || props.estado === 'NACIONAL' ? (
                                            <>
                                                <Input
                                                    name="numeroOrdem"
                                                    type="text"
                                                    id="numeroOrdem"
                                                    label="Numero da Ordem"
                                                    maxlength={6}
                                                    onChange={e => validateNumeroOrdem(e)}
                                                    erro={erroNumeroOrdem}
                                                    value={numeroOrdem}
                                                />

                                                <Input
                                                    name="validade-art"
                                                    type="date"
                                                    label="Data de Validade (opcional)"
                                                    id="validade_art"
                                                    onChange={e => changeDataValidade(e)}
                                                    value={dataValidade}
                                                    onBlur={e => console.log(e)}
                                                    erro={''}
                                                    mask="99/99/9999"
                                                    help={true}
                                                    helpClick={() => openModalTextValidade()}
                                                />
                                            </>
                                        ) : null}

                                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                                            <Button
                                                width='100%'
                                                height='50px'
                                                value='AVANÇAR'
                                                outline={true}
                                                disabled={false}
                                                onClick={() => nextStep()}
                                                loading={false}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {max ? (
                                            <>
                                                {max.min_receitas && max.max_receitas ? (
                                                    <>
                                                        <Input
                                                            name="quantidade-receitas"
                                                            type="number"
                                                            min={max.min_receitas}
                                                            max={max.max_receitas}
                                                            label="Quantidade de Receitas"
                                                            id="quantidade_receitas"
                                                            onChange={e => e
                                                                ? validateQuantidadeReceita(e)
                                                                : validateQuantidadeReceita("")
                                                            }
                                                            onBlur={e => validateInterval(e.target.value)}
                                                            value={quantidadeReceita}
                                                            erro={erroQuantidadeContratos}
                                                            legenda={legendaQuantidade}
                                                        />

                                                        <Select
                                                            name="saldoArts"
                                                            className="select"
                                                            label="Me avisar quando o saldo estiver a partir de:"
                                                            id="saldoArts"
                                                            value={saldoArts}
                                                            disabled={!sendAlert}
                                                            loading={false}
                                                            erro={false}
                                                            onChange={e => setSaldoArts(e)}
                                                            options={[{ value: 5, label: '5 receitas para acabar' }, { value: 10, label: '10 receitas para acabar' }, { value: 15, label: '15 receitas para acabar' }]}
                                                            yesNo={true}
                                                            yesNoValue={sendAlert}
                                                            messageYesNo='Quero receber notificações'
                                                            onChangeYesNo={(e) => changeSendAlert(e)}
                                                            yesNoLeft='350px'
                                                            showDisabledYesNo={true}
                                                        />
                                                    </>

                                                ) : (
                                                    <>
                                                        {max.max_receitas ? (<>
                                                            <Input
                                                                name="quantidade-receitas"
                                                                type="number"
                                                                min={1}
                                                                max={max.max_receitas}
                                                                label="Quantidade de Receitas"
                                                                id="quantidade_receitas"
                                                                onChange={e => e
                                                                    ? validateQuantidadeReceita(e)
                                                                    : validateQuantidadeReceita("")
                                                                }
                                                                value={quantidadeReceita}
                                                                onBlur={e => validateInterval(e.target.value)}
                                                                erro={erroQuantidadeContratos}
                                                                legenda={legendaQuantidade}

                                                            />

                                                            <Input
                                                                name="validade-art"
                                                                type="date"
                                                                label="Data de Validade (opcional)"
                                                                id="validade_art"
                                                                onChange={e => changeDataValidade(e)}
                                                                value={dataValidade}
                                                                onBlur={e => console.log(e)}
                                                                erro={''}
                                                                mask="99/99/9999"
                                                                help={true}
                                                                helpClick={() => openModalTextValidade()}

                                                            />

                                                            <Select
                                                                name="saldoArts"
                                                                className="select"
                                                                label="Me avisar quando o saldo estiver a partir de:"
                                                                id="saldoArts"
                                                                value={saldoArts}
                                                                disabled={!sendAlert}
                                                                loading={false}
                                                                erro={false}
                                                                onChange={e => setSaldoArts(e)}
                                                                options={[{ value: 5, label: '5 receitas para acabar' }, { value: 10, label: '10 receitas para acabar' }, { value: 15, label: '15 receitas para acabar' }]}
                                                                yesNo={true}
                                                                yesNoValue={sendAlert}
                                                                messageYesNo='Quero receber notificações'
                                                                onChangeYesNo={(e) => changeSendAlert(e)}
                                                                yesNoLeft='350px'
                                                                showDisabledYesNo={true}
                                                            />
                                                        </>) : (<>
                                                            {max.min_receitas ? (<>
                                                                <Input
                                                                    name="quantidade-receitas"
                                                                    type="number"
                                                                    min={max.min_receitas}
                                                                    label="Quantidade de Receitas"
                                                                    id="quantidade_receitas"
                                                                    onChange={e => e
                                                                        ? validateQuantidadeReceita(e)
                                                                        : validateQuantidadeReceita("")
                                                                    }
                                                                    value={quantidadeReceita}
                                                                    onBlur={e => validateInterval(e.target.value)}
                                                                    erro={erroQuantidadeContratos}
                                                                    legenda={legendaQuantidade}

                                                                />

                                                                <Select
                                                                    name="saldoArts"
                                                                    className="select"
                                                                    label="Me avisar quando o saldo estiver a partir de:"
                                                                    id="saldoArts"
                                                                    value={saldoArts}
                                                                    disabled={!sendAlert}
                                                                    loading={false}
                                                                    erro={false}
                                                                    onChange={e => setSaldoArts(e)}
                                                                    options={[{ value: 5, label: '5 receitas para acabar' }, { value: 10, label: '10 receitas para acabar' }, { value: 15, label: '15 receitas para acabar' }]}
                                                                    yesNo={true}
                                                                    yesNoValue={sendAlert}
                                                                    messageYesNo='Quero receber notificações'
                                                                    onChangeYesNo={(e) => changeSendAlert(e)}
                                                                    yesNoLeft='350px'
                                                                    showDisabledYesNo={true}
                                                                />
                                                            </>) : (
                                                                <>

                                                                    {max.min_contratos == null ? (
                                                                        <Input
                                                                            name="quantidadeContratos"
                                                                            type="number"
                                                                            min={1}
                                                                            max={max.max_contratos}
                                                                            id="quantidadeContratos"
                                                                            label="Quantidade de contratos"
                                                                            onChange={e =>
                                                                                e
                                                                                    ? validateQuantidadeContratos(e)
                                                                                    : validateQuantidadeContratos("")
                                                                            }
                                                                            onBlur={e => validateInterval(e.target.value)}
                                                                            value={quantidadeContratos}
                                                                            erro={erroQuantidadeContratos}
                                                                            legenda={legendaQuantidade}
                                                                        />
                                                                    ) : (
                                                                        <Input
                                                                            name="quantidadeContratos"
                                                                            type="number"
                                                                            min={max.min_contratos}
                                                                            max={max.max_contratos}
                                                                            id="quantidadeContratos"
                                                                            label="Quantidade de contratos"
                                                                            value={quantidadeContratos}
                                                                            onChange={e =>
                                                                                e
                                                                                    ? validateQuantidadeContratos(e)
                                                                                    : validateQuantidadeContratos("")
                                                                            }
                                                                            onBlur={e => validateInterval(e.target.value)}
                                                                            erro={erroQuantidadeContratos}
                                                                            legenda={legendaQuantidade}
                                                                        />
                                                                    )}

                                                                    {props.estado.value === "SP" || props.estado.value === "NACIONAL" || props.estado === 'SP' || props.estado === 'NACIONAL' ? (
                                                                        <>
                                                                            <Input
                                                                                name="numeroOrdem"
                                                                                type="text"
                                                                                id="numeroOrdem"
                                                                                label="Numero da Ordem"
                                                                                maxlength={6}
                                                                                onChange={e => validateNumeroOrdem(e)}
                                                                                erro={erroNumeroOrdem}
                                                                                value={numeroOrdem}
                                                                            />

                                                                            <Input
                                                                                name="validade-art"
                                                                                type="date"
                                                                                label="Data de Validade (opcional)"
                                                                                id="validade_art"
                                                                                onChange={e => changeDataValidade(e)}
                                                                                value={dataValidade}
                                                                                onBlur={e => console.log(e)}
                                                                                erro={''}
                                                                                mask="99/99/9999"
                                                                                help={true}
                                                                                helpClick={() => openModalTextValidade()}

                                                                            />
                                                                        </>
                                                                    ) : null}

                                                                    <Select
                                                                        name="saldoArts"
                                                                        className="select"
                                                                        label="Me avisar quando o saldo estiver a partir de:"
                                                                        id="saldoArts"
                                                                        value={saldoArts}
                                                                        disabled={!sendAlert}
                                                                        loading={false}
                                                                        erro={false}
                                                                        onChange={e => setSaldoArts(e)}
                                                                        options={[{ value: 5, label: '5 receitas para acabar' }, { value: 10, label: '10 receitas para acabar' }, { value: 15, label: '15 receitas para acabar' }]}
                                                                        yesNo={true}
                                                                        yesNoValue={sendAlert}
                                                                        messageYesNo='Quero receber notificações'
                                                                        onChangeYesNo={(e) => changeSendAlert(e)}
                                                                        yesNoLeft='350px'
                                                                        showDisabledYesNo={true}
                                                                    />

                                                                </>

                                                            )}
                                                        </>)}
                                                    </>
                                                )}
                                            </>) : (null)}

                                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginBottom: '20px' }}>
                                            <Button
                                                width='100%'
                                                height='50px'
                                                value='CADASTRAR'
                                                outline={false}
                                                disabled={false}
                                                onClick={() => handlePost()}
                                                loading={loading}
                                            />
                                        </div>
                                    </>
                                )}

                            </form>

                            <ModalText
                                show={showModal}
                                onHide={modalClose}
                                text={"Se você faz parte do CFTA, Ao  adquirir  uma  TRT, você recebeu um número como este “BR202002RA000001”. Insira este número no campo Receita Inicial"}
                                btnOk={false}
                            />

                            <ModalText
                                show={showModal2}
                                onHide={modalClose}
                                text={"Se você faz parte do CFTA, Ao  adquirir  uma  TRT, você recebeu um número como este “BR20200200001”. Insira este número no campo ART/TRT"}
                                btnOk={false}
                            />

                            <ModalText
                                show={showModalValidade}
                                onHide={() => setShowModalValidade(false)}
                                text={"Este campo não é obrigatório! Coloque a data de validade da ART se você desejar que ela inative automaticamente após a data informada."}
                                btnOk={false}
                            />

                            <Modal
                                show={modalAgronomoShow}
                                onHide={() => addModalClose()}
                                size='lg'
                            >
                                <AddAgronomo isModal={true} update={() => updateIdAgronomo()} />
                            </Modal>

                        </Col>
                    </Row>
                </Container>
            </FormContainer>
        </>
    );
};
export default ArtsOne;
