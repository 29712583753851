import React, {useState, useEffect} from 'react';
import Button from '../../components/button/button_v2';
import GenericUpper from '../../components/generic-upper';
import logomini from '../../assets/logo-mini.png';
import logobling from '../../assets/logos/bling.png';
import logotiny from '../../assets/logos/tiny.png';
import logoomie from '../../assets/logos/omie.png';
import logocontaazul from '../../assets/logos/contaazul.png';
import logopluga from '../../assets/logos/pluga.png';
import InputV2 from '../../components/input/input_v2';
import List from '../../components/list';
import SelectV2 from '../../components/select/select_v2';
import api from '../../services/api';
import { Col, Container, FrameworksItem, FrameworksList, IntegracaoContainer, Text, Title } from './styles';


const AddIntegracao = ({ ...props }) => {
    const [functions, setFunctions] = useState([]);
    const [func, setFunc] = useState([]);
    const [url, setUrl] = useState('');
    const [headers, setHeaders] = useState([]);

    const [showFrameworks, setShowFrameworks] = useState(true);
    const [frameworks, setFrameworks] = useState([]);
    const [selectedFramework, setSelectedFramework] = useState(0);

    const getIntegrationFunctions = async () => {
        try {
            const response = await api.get('/integracao/not/functions');

            if (response) {
                setFunctions(response.data.data);
            }
        } catch (error) {
            
        }
    }

    const changeFunc = (e) => {
        setFunc(e);
    }

    const changeUrl = (value) => {
        if (value !== url) {
            setUrl(value);
        }
    }

    const changeHeaders = (value) => {
        let h = headers;

        h = [ ...h, value];

        setHeaders(h);
    }

    const save = () => {
        const payload = {
            url: url,
            function_id: func.value,
            header: headers,
            framework_id: selectedFramework
        }

        console.log(payload);

        // setOpen(false);
        // setEditado(false);
        props.save(payload);
    }

    const getFrameworks = async () => {
        try {
            const response = await api.get('/integracao/frameworks');

            if (response) {
                console.log(response.data)
                setFrameworks(response.data.data);
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    const selectFramework = (framework) => {
        setShowFrameworks(false);
        setSelectedFramework(framework.id);
    }

    useEffect(() => {
        getIntegrationFunctions();
        getFrameworks();
    }, []);

    return (
        <>
            {showFrameworks ? (
                <>
                    <Title>Integrar o AgroReceita</Title>

                    <Text>Selecione a ferramenta que melhor te atenda e faça suas automatizações.</Text>

                    <FrameworksList>
                        {frameworks.map((f) => (
                            <FrameworksItem onClick={() => selectFramework(f)}>
                                <Col alignItems='center'>

                                    {f.framework === 'AgroReceita' ? (
                                        <img src={logomini} height='100%' alt='Logo Agroreceita'/>
                                    ) : (
                                        <>
                                            {f.framework === 'Bling' ? (
                                                <img src={logobling} width='60px' alt='Logo Bling'/>
                                            ) : (
                                                <>
                                                    {f.framework === 'Tiny' ? (
                                                        <img src={logotiny} width='60px' alt='Logo Tiny'/>
                                                    ) : (
                                                        <>
                                                            {f.framework === 'Omie' ? (
                                                                <img src={logoomie} width='60px' alt='Logo Omie'/>
                                                            ) : (
                                                                <>
                                                                    {f.framework === 'Conta Azul' ? (
                                                                        <img src={logocontaazul} width='60px' alt='Logo Conta Azul'/>
                                                                    ) : (
                                                                        <>
                                                                        
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    )}


                                </Col>

                                <Col >
                                    {/* <label>{f.framework}</label> */}
                                    <label> Integração: {f.integration_type.id == 2 ? (
                                        <>
                                            <img src={logopluga} width='40px' alt='Logo Pluga'/>
                                        </>
                                    ) : (
                                        <>
                                            {f.integration_type.type}
                                        </>
                                    )}</label>
                                </Col>
                            </FrameworksItem>

                        ))}
                    </ FrameworksList>
                </>
            ) : (
                <Container>
                    <SelectV2
                        name="function"
                        className="func"
                        label="Integração"
                        id="func"
                        value={func.value || func}
                        onChange={e => changeFunc(e)}
                        options={functions}
                        selectUniqueOption={true}
                    />

                    <InputV2
                        id="url_id"
                        name="url"
                        label='URL'
                        value={url}
                        onChange={(e) => changeUrl(e)}
                    />

                    <List 
                        values={headers}
                        onChange={(e) => changeHeaders(e)}
                        id={props.functionId}
                    />

                    <div 
                        className="button" 
                        style={{ 
                            width: '100%', 
                            display: 'flex', 
                            flexDirection: 'row', 
                            justifyContent: 'center', 
                            marginTop: '40px', 
                            marginBottom: '60px' 
                        }}>

                        <Button 
                            width='296px'
                            height='50px'
                            value='SALVAR'
                            outline={false}
                            disabled={false}
                            onClick={() => save()}
                        />
                    </div>
                </Container>
            )}

        </>
    )
}

export default AddIntegracao;