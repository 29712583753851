import styled from "styled-components";


export const ContainerGeral = styled.div`
  padding: 50px 0;
  background: #f8f8f9;
  height: 100%;
  margin: auto;

`;

export const ContainerModal = styled.div`
    width: 100%;
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10% 0px 10%;
    height:auto;

    .acoes{
      width: 120%;
      padding:20px 20px 20px 20px;
      border: solid 1px rgb(186, 193, 207);
      margin-bottom: 30px;
    }

    .historico{
      width: 140%;
      padding:20px 0px 20px 0px;
      border: solid 1px rgb(186, 193, 207);
      margin-bottom: 30px;
    }

    .pTitulo{
      text-align: left;
      width: 100%;
      margin-bottom: 0px;
      color:#8A92A2;
      font-weight: 600;
      font-size: 14px;
    }

    .pTituloAcoes{
      text-align: left;
      width: 100%;
      margin-bottom: 0px;
      margin-left: -95px;
      color:#8A92A2;
      font-weight: 600;
      font-size: 14px;
    }

    .div-button{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      margin-top: 10px;
    }

    .button-finish{
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
    }

    .btnf{
        border-radius: 10px;
        border: solid 1.3px #589bff;
        color: #589bff;
        height: 20px;
        font-family: Lato;
        font-size: 8px;
        font-weight: 900;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #589bff;
        margin-right: 10px;
        padding: 5px;
        margin-top: 20px; 
    }

    .btnf:hover{
        color: #589bff;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 80px 0px 0px 0px;
        height: auto;
    }

`;

export const ContainerModalEdit = styled.div`
    width: 100%;
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10% 0px 10%;
    height:auto;



    .CardOp{
      width: 130%;
      height: 200px;
      padding: 20px;
      margin: 20px 0px 20px 0px;
      border-radius: 10px;
      box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
      background-color: rgb(255, 255, 255);
      border: none;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-box-align: center;
      transition: all 0.2s ease 0s;
      .editCard{
        height: 16px;
        margin: 0px;
        padding: 0px;
        width: 16px;
        display: none;
        flex-direction:row;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease;
        p{
            width: 75px;
            height: 22px;

            display:none;
            position:absolute;
            align-items: center;

            font-size: 11px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            
            margin-top: 80px;
            margin-left: -16px;
            
            padding-top: 2px;
            
            color:#FFF;
            background-color: #1d2129;
            opacity: 0.9;
            
            border-radius: 5px;

            &:before{
                position: absolute;
                background-color:  #1d2129;
                width: 8px;
                height: 8px;
                top: -4px;
                margin-left: 17px;
                content: '';
                transform: rotate(45deg);
            }

        }
        &:hover{
          p{
            display: block;
          }
          svg{
            &:hover{
              transform: scale(1.2);

            }
          }
        }
        &:active{
          svg{
            &:hover{
              transform: scale(0.9);

            }
          }
        }

      }
      &:hover{
        .editCard{
          display: flex;
          cursor: pointer;


        }
      }
    }

    .CardAcao{
      width: 130%;
      height: 118px;
      padding: 20px;
      margin: 20px 0px 20px 0px;
      border-radius: 10px;
      box-shadow: rgb(29 33 41 / 40%) 1px 2px 5px 1px;
      background-color: rgb(255, 255, 255);
      border: none;
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      -webkit-box-align: center;
      transition: all 0.2s ease 0s;
    }

    .headerCard{
      margin-bottom: 10px;
      height: 30px;
      display:flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      
    }



    .titleCard{
      font-family: Lato;
      font-size: 15px;
      font-weight: bold;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: rgb(48, 56, 72);
    }

    .itemsCard{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: left;
      margin-top: -5px;
    }

    .itens{
      font-family: Lato;
      font-size: 12px;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      padding: 6px;
    }

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 80px 0px 0px 0px;
        height: auto;
    }

`;

export const DivSearch = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items:center;
  margin: -50px 10px 0px 10px;
  padding: 10px;


.search{
  /* width: 100%; */
  display:flex;
  align-items: center;

}

.add{
  width: 50%;
  max-width: 200px;
  align-self: center;
  padding-left: 10px;
  button{
    width: 100%;
    height: 50px;
    align-self: center;
    max-width: 375px;
    min-width: 80px;
    border-radius: 30px;
    border: solid 3px #FFF;
  }     
}

.filterKanban{
  width: 60%;
  max-width: 375px;
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items:center;
  align-self: center;
  padding-right: 10px;
}

@media only screen and (max-width: 1270px) {
  .filterKanban{
    width: 60%;
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items:center;
    align-self: center;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 979px) {
  margin: 16px 10px 0px 10px;
}

`;

export const NewButton = styled.div`
  
  width:40%;
  min-width: 50px;
  background: #e8edf3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 7px 7px 7px 7px;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  border: 3px solid #FFF;
  color: #586377;
  font-weight: bold;
  font-size: 12px;
  transition: all 0.3s;

  &:hover{
    box-shadow: 0 0 8px 0 #e8edf3;

  }

  svg{
    width: 30px;
  }

    
  p{
      margin-bottom: 0rem;
    }

  
  @media only screen and (max-width: 1270px) {
    width: 50px;

    p{
      display: none;
    }
  }

  @media only screen and (max-width: 1146px) {
    width: 50px;

    p{
      display: none;
    }
  }
`;

export const SpaceButton = styled.div`
    position: absolute;
    width:25%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @media only screen and (max-width: 1146px) {
    }
`;

export const Kanban = styled.div`
  
  width:100%;

  .titulo{
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-weight:bold;
  }

  .corpo{
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
  }

  .row{
      background-color: black;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    .tipo-0{
      margin-top: 16px;
      border: solid 1px #adaeb1;
      border-radius: 3px;
      background-color: #adaeb1;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-1{
      margin-top: 16px;
      border: solid 1px #03903e;
      border-radius: 3px;
      background-color: #03903e;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-2{
      margin-top: 16px;
      border: solid 1px #d9261d;
      border-radius: 3px;
      background-color: #d9261d;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-3{
      margin-top: 16px;
      border: solid 1px #589bff;
      border-radius: 3px;
      background-color: #589bff;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-4{
      margin-top: 16px;
      border: solid 1px #2fbb69;
      border-radius: 3px;
      background-color: #2fbb69;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    

    .bolinha-vermelha{
      width: 8px;
      height: 8px;
      position: absolute;
      background-color: #d9261d;
      margin-left: 202px;
      margin-top: 3px;
      border: solid 1px #d9261d;
      border-radius: 50%;
    }

    .bolinha-azul{
      width: 8px;
      height: 8px;
      position: absolute;
      background-color: #589bff;
      margin-left: 202px;
      margin-top: 3px;
      border: solid 1px #589bff;
      border-radius: 50%;
    }

    .bolinha-verde{
      width: 8px;
      height: 8px;
      position: absolute;
      background-color: #2fbb69;
      margin-left: 202px;
      margin-top: 3px;
      border: solid 1px #2fbb69;
      border-radius: 50%;
    }
 
  .react-kanban-board{
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  padding:5px
}
.react-kanban-card{
  border-radius:3px;
  background-color:#fff;
  padding:10px;
  margin-bottom:7px
}

.react-kanban-card-skeleton,.react-kanban-card,.react-kanban-card-adder-form{
  box-sizing:border-box;
  white-space: nowrap;
  width:230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 1px 1px 3px 0px rgb(29 33 41 / 40%);
}

.react-kanban-card--dragging{
  box-shadow: 1px 1px 3px 0px rgb(29 33 41 / 80%);
  }

  .react-kanban-card__description{
    padding-top:10px;
    word-wrap: break-word;
    width:215px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-kanban-card__title{
  border-bottom:1px solid #eee;
  padding-bottom:5px;
  font-weight:bold;
  display:flex;
  justify-content:space-between
}

.react-kanban-column{
  padding:15px;
  border-radius:2px;
  background-color:#fff;
  border-radius: 10px;
  margin:5px;
  box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
}

.react-kanban-column input:focus{
  outline:none
  }

  .react-kanban-card-adder-form{
  border-radius:3px;
  background-color:#fff;
  padding:10px;
  margin-bottom:7px
}

.react-kanban-card-adder-form input{
  border:0px;
  font-family:inherit;
  font-size:inherit
}
.react-kanban-card-adder-button{
  width:100%;
  margin-top:5px;
  background-color:transparent;
  cursor:pointer;
  border:1px solid #ccc;
  transition:0.3s;
  border-radius:3px;
  font-size:20px;
  margin-bottom:10px;
  font-weight:bold
}

.react-kanban-card-adder-button:hover{
  background-color:#ccc
  }

  .react-kanban-card-adder-form__title{
  font-weight:bold;
  border-bottom:1px solid #eee;
  padding-bottom:5px;
  font-weight:bold;
  display:flex;
  justify-content:space-between;
  width:100%;
  padding:0px
}

.react-kanban-card-adder-form__title:focus{
  outline:none
  }

  .react-kanban-card-adder-form__description{
  width:100%;
  margin-top:10px
}

.react-kanban-card-adder-form__description:focus{
  outline:none
  }

  .react-kanban-card-adder-form__button{
  background-color:#eee;
  border:none;
  padding:5px;
  width:45%;
  margin-top:5px;
  border-radius:3px
}

.react-kanban-card-adder-form__button:hover{
  transition:0.3s;
  cursor:pointer;
  background-color:#ccc
}

.react-kanban-column-header{
  padding-bottom:10px;
  font-weight:bold
}

.react-kanban-column-header input:focus{
  outline:none
  }

  .react-kanban-column-header__button{
    color:#333333;
  background-color:#ffffff;
  border-color:#cccccc
}

.react-kanban-column-header__button:hover,.react-kanban-column-header__button:focus,.react-kanban-column-header__button:active{
  background-color:#e6e6e6
  }

  .react-kanban-column-adder-button{
    border:2px dashed #eee;
  height:132px;
  margin:5px
}

.react-kanban-column-adder-button:hover{
  cursor:pointer}



`;

