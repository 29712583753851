import React, { useState, useEffect } from "react";
import { Card, ProgressBar, Progress } from "./style";
import local from "../../assets/icons/icons-8-marker.png"
import api from "../../services/api";
import { store } from "react-notifications-component";

import ModalTelefone from '../modal/modalCadTelefoneEmergencia';
import ModalLimiteImpressao from '../modal/modalFuncaoBloqueadaImpressaoFe';
import ModalAlert from '../../components/modal/modalGeneric';
import Button from "../../components/button/button_v2";
import ModalConfirmar from "../../components/modal/modalConfirmar";
import { Icons } from "../../pages/produtores/styles";
import IconSchedule from "../../assets/icons/iconSchedule";
import IconReceita from "../../assets/icons/iconReceita";
import { formatDateTime } from "../../services/formatDate";
import IconRoom from "../../assets/icons/room";

const CardGeral = ({ ...props }) => {
    const {
        titulos,
        valores,
    } = props;

    const [modalText, setModalText] = useState('');
    const [modalTelefone, setModalTelefone] = useState('');
    const [modalLimiteImpressao, setModalLimiteImpressao] = useState('');

    const [modalApagarVisualizar, setModalApagarVisualizar] = useState(false);
    const [idGuia, setIdGuia] = useState(null);
    const [urlGuide, setUrlGuide] = useState("");
    const [showModalGuides, setShowModalGuides] = useState(false);

    const [loadingFE, setLoadingFE] = useState(false);
    const [loadingPDF, setLoadingPDF] = useState(false);

    const calculateProgress = (receitasEmitidas, quantidadeReceitas, quantidadeContratos, totalContratos) => {
        if ((receitasEmitidas >= 0) && (quantidadeReceitas || quantidadeContratos)) {
            if (!quantidadeContratos) {
                let porcentagemReceitasEmitidas = ((receitasEmitidas * 100) / quantidadeReceitas);
                let subProgress = ((120 * porcentagemReceitasEmitidas) / 100);
                let value = 120 - subProgress;
                let progress = parseInt(value);
                return progress + "px";
            } else {
                if (totalContratos) {
                    let porcentagemReceitasEmitidas = ((totalContratos * 100) / quantidadeContratos);
                    let subProgress = ((120 * porcentagemReceitasEmitidas) / 100);
                    let value = 120 - subProgress;
                    let progress = parseInt(value);
                    return progress + "px";
                } else {
                    let porcentagemReceitasEmitidas = ((0 * 100) / quantidadeContratos);
                    let subProgress = ((120 * porcentagemReceitasEmitidas) / 100);
                    let value = 120 - subProgress;
                    let progress = parseInt(value);
                    return progress + "px";
                }
            }
        }
    }

    const redicttoPDF = async (id) => {
        try {
            if (loadingPDF === false) {
                setLoadingPDF(true);
                const response = await api.post(`/fispq/${id}`);
                // window.open(`${response.data.data[0].link}`)
                // alert(`${response.data.data[0].link}`);
                setLoadingPDF(false);
                window.location = `${response.data.data[0].link}`;
            }
        } catch (error) {
        }
    }

    const deleteGuide = async (id) => {
        try {
            const response = await api.delete(`/guias/${id}`);
            if (response.data.message) {
                store.addNotification({
                    title: "Sucesso!",
                    message: `${response.data.message}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                setModalApagarVisualizar(false);
                setTimeout(() => {
                    window.location = "/guia_aplicacao"
                }, 1000);
            }
        } catch (error) {
            setModalApagarVisualizar(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const resposta = async (resposta, id) => {
        if (resposta == 1 && id) {
            deleteGuide(id);
        }
        setShowModalGuides(false);
    };

    const formatData = (data) => {
        var date = data;

        let now = new Date(date);

        let day = now.getDate();
        if (day <= 9) {
            day = "0" + day;
        }
        let month = now.getMonth();
        let year = now.getFullYear();
        month = month + 1;
        if (month <= 9) {
            month = "0" + month;
        }

        return day + "/" + month + "/" + year;

    };

    const downloadFE = async (id) => {
        try {
            if (loadingFE === false) {
                setLoadingFE(true);
                let expedidor = await api.get(`/expedidor`);
                expedidor = expedidor.data.data.id;
    
                const response = await api.post(`/ficha/${expedidor}/${id}`);
                // window.open(`${response.data.data.link}`)
                setLoadingFE(false);
                window.location = `${response.data.data.link}`;
            }

        } catch (err) {


            if (err.response.data.errors) {
                if (err.response.data.errors != "Você atingiu o limite de impressões gratuitas.") {
                    setModalText(err.response.data.errors);
                    setModalTelefone(true);
                } else {

                    if (err.response.data.errors == "Você atingiu o limite de impressões gratuitas.") {

                        setModalLimiteImpressao(true);

                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: err.response.data.errors ? `${err.response.data.errors}` : 'Tente novamente mais tarde',
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 5000,
                                onScreen: true
                            }
                        });
                    }
                }
            }
        }
    };

    const modalClose = e => {

        if (modalTelefone == true) {
            setModalTelefone(false);
        }

        if (modalLimiteImpressao == true) {
            window.location = '/'
            // setModalLimiteImpressao(false);
        }
    };


    useEffect(() => {

        if (valores.length > 0) {
            let format = [...valores, { idCard: 1 }];
        }

    }, [valores]);


    return (
        <>

            {titulos[0] === "USUARIOS" ? (<>
                {valores.map((val, index) => (<>
                    <Card idColor={index}>

                        <div id="container" onClick={() => {
                            window.location.href = `usuarios/${val.id}`;
                        }}>
                            <div id="titulos" >
                                <p >{titulos[1]}</p>
                                <p >{titulos[2]}</p>
                                <p >{titulos[3]}</p>
                                <p >{titulos[4]}</p>
                                <p >{titulos[5]}</p>
                                <p >{titulos[6]}</p>
                            </div>
                            <div style={{ marginTop: '16px' }} id="values">
                                <p key={val}>{val.username}</p>
                                <p>{val.agro_permission.permission}</p>
                                <p>{val.agro_subsidiary.nome_fantasia}</p>
                                <p>{val.agro_subsidiary.cidade}</p>
                                <p>{val.agro_subsidiary.is_matriz == 1 ? (<td>Matriz</td>) : (<td>Filial</td>)}</p>
                                <p>{val.status == "AT" ? (<td> <ul className="status-at">ATIVO</ul></td>) : (<td> <ul className="status-in">INATIVO</ul></td>)}</p>
                            </div>
                        </div>
                    </Card >
                </>))}
            </>) : (<>

                {titulos[0] === 'FILIAIS' ? (<>
                    {valores.map((val, index) => (<>
                        <Card idColor={index}>
                            <div id="container" onClick={() => {
                                window.location.href = `filiais/${val.id}`;
                            }}>
                                <div id="titulos" >
                                    <p >{titulos[1]}</p>
                                    <p >{titulos[2]}</p>
                                    <p >{titulos[3]}</p>
                                    <p >{titulos[4]}</p>
                                </div>
                                <div id="values">
                                    <p key={val}>{val.nome_fantasia}</p>
                                    {val.is_matriz === 1 ? (<td>Matriz</td>) : (<td>Filial</td>)}
                                    <p>{val.cidade}</p>
                                    <td><div className="estado">{val.estado}</div></td>
                                </div>
                            </div>
                        </Card >
                    </>))}

                </>) : (<>

                    {titulos[0] === 'ARTS' ? (<>
                        {valores.map((val, index) => (<>
                            <Card idColor={index}>
                                <div id="container" onClick={() => {
                                    window.location.href = `arts/${val.id}`;
                                }}>
                                    <div id="titulos" >
                                        <p >RESPONSÁVEL TÉCNICO</p>
                                        <p >{titulos[2]}</p>
                                        <p >{titulos[3]}</p>
                                        <p >{titulos[4]}</p>
                                        <p>  {val.crea.estado == 'SP' || val.crea.estado == 'MG' ? <> CONTRATOS </> : <> RECEITAS EMITIDAS </>}</p>
                                    </div>
                                    <div id="values" style={{ marginTop: '-8px' }}>

                                        {val.crea.tipo == 'crea' ? (<td style={{ marginBottom: '-16px', marginTop: '17px', fontWeight: 'bold', fontSize: '16px' }}>{val.crea.agronomo.nome} <br></br> <label style={{ color: '#bac1cf', fontSize: '11px' }}>Crea: {val.crea.crea} </label></td>) : (
                                            <>
                                                {val.crea.tipo == 'visto' ? (<td style={{ fontWeight: 'bold', fontSize: '16px' }}>{val.crea.agronomo.nome} <br></br> <label style={{ color: '#bac1cf', fontSize: '11px' }}>Visto: {val.crea.crea}</label></td>) : (<td style={{ fontWeight: 'bold', fontSize: '16px' }}>{val.crea.agronomo.nome} <br></br> <label style={{ color: '#bac1cf', fontSize: '11px' }}>Cfta: {val.crea.crea}</label></td>)}

                                            </>)}
                                        <td>{val.numero_art}</td>
                                        <td> <div className="val-grid"> <img src={local}></img> <label style={{ color: '#589bff' }} className="loja">{val.crea.agronomo.filial.nome_fantasia}</label></div></td>
                                        <td><div style={{ width: '60%', backgroundColor: "#dfebe2", display: 'flex', justifyContent: "center", borderRadius: '5px' }} className="estado">{val.crea.estado}</div></td>
                                        <td>
                                            <ProgressBar
                                                progress={calculateProgress(val.receitas_emitidas, val.quantidade_receitas, val.quantidade_contratos, val.contratos.qtde_contratos)}
                                            >
                                                <Progress>

                                                </Progress>
                                            </ProgressBar>
                                            <div className="quantidade-progress">
                                                {val.crea.estado == 'SP' || val.crea.estado == 'MG' ? (<>
                                                    {val.contratos.qtde_contratos ? (<>{val.contratos.qtde_contratos}</>) : (<>0</>)} / {val.quantidade_contratos}

                                                </>) : (<>
                                                    {val.receitas_emitidas} / {val.quantidade_receitas}

                                                </>)}

                                            </div>

                                        </td>
                                    </div>
                                </div>
                            </Card >
                        </>))}
                    </>) : (<>

                        {titulos[0] === 'DEVOLUCAO' ? (<>
                            {valores.map((val, index) => (<>
                                <Card idColor={index}>
                                    <div id="container" onClick={() => {
                                        window.location.href = `devolucoes/${val.id}`;
                                    }}>
                                        <div id="titulos" >
                                            <p >{titulos[1]}</p>
                                            <p >{titulos[2]}</p>
                                            <p >{titulos[3]}</p>
                                            <p >{titulos[4]}</p>
                                        </div>
                                        <div id="values">
                                            {val.status == 0 ? (<>
                                                <td><strike>{val.nome}</strike></td>
                                                <td><strike>{val.endereco}</strike></td>
                                                <td><strike>{val.cidade}</strike></td>
                                                <td><div className="estado-inativo">{val.estado}</div></td>
                                            </>) : (<>
                                                <td>{val.nome}</td>
                                                <td>{val.endereco}</td>
                                                <td>{val.cidade}</td>
                                                <td><div className="estado">{val.estado}</div></td>
                                            </>)}
                                        </div>
                                    </div>
                                </Card >
                            </>))}
                        </>) : (<>

                            {titulos[0] === 'AGRONOMOS' ? (<>

                                {valores.map((val, index) => (<>
                                    <Card idColor={index}>
                                        <div
                                            id="container"
                                            onClick={() => { window.location.href = `agronomos/${val.id}`; }}
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            <div
                                                className="nome"
                                            >
                                                <p style={{ marginLeft: '10%' }}>{titulos[1]}</p>
                                                <td style={{ marginRight: '10%' }} key={val.id}>{val.nome}</td>
                                            </div>

                                            <div
                                                className="crea"
                                                style={{ display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}
                                            >

                                                <td>
                                                    {val.creas.map(crea => (
                                                        <>
                                                            {crea.status == 0 ? (
                                                                <>
                                                                    <div className="individualCrea" style={{ marginLeft: '10%', marginRight: '10%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        <p>{crea.tipo.toUpperCase()}</p>
                                                                        <ul style={{ marginTop: '10px', marginBottom: '0px' }} key={crea}><strike>{crea.crea}</strike></ul>
                                                                        <div className="estado-inativo" style={{ position: "relative", top: "9px", right: "-25px" }}><label>{crea.estado}</label></div>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="individualCrea" style={{ marginLeft: '10%', marginRight: '10%', display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                                                                        <p>{crea.tipo.toUpperCase()}</p>
                                                                        <ul style={{ marginTop: '10px', marginBottom: '0px' }} key={crea} className="crea">{crea.crea}</ul>
                                                                        <div className="estado" style={{ position: "relative", top: "9px", right: "-25px" }}>{crea.estado}</div>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    ))}
                                                </td>
                                            </div>

                                        </div>
                                    </Card >
                                </>))}

                            </>) : (<>
                                {titulos[0] === 'PRODUTORES' ? (<>
                                    {valores.map((val, index) => (<>
                                        <Card idColor={index}>
                                            <div id="container">
                                                <div id="titulos" >
                                                    <p >{titulos[1]}</p>
                                                    <p >{titulos[2]}</p>
                                                    {val.fazendas.map((f, i) => ( 
                                                        <>
                                                            {i > 0 && (
                                                                <p> </p>
                                                            )}
                                                        </>
                                                    ))}
                                                    <p >{titulos[3]}</p>
                                                    {val.fazendas.map((f, i) => ( 
                                                        <>
                                                            {i > 0 && (
                                                                <p> </p>
                                                            )}
                                                        </>
                                                    ))}

                                                    <p >{titulos[4]}</p>
                                                    <p >{titulos[5]}</p>
                                                </div>
                                                <div id="values" style={{ width: "50%" }}>
                                                    
                                                    <td 
                                                        onClick={() => {
                                                            window.location.href = `produtores/${val.id}`;
                                                        }}
                                                    >
                                                        {val.status === "1" ? (
                                                            <>
                                                                {val.nome}
                                                            </>
                                                        ) : (
                                                            <strike>
                                                                {val.nome}
                                                            </strike>
                                                        )}
                                                    </td>


                                                    <td>
                                                        {val.fazendas.map((f) => (
                                                            <>
                                                                {f.status == '1' ? (
                                                                    <p 
                                                                        className="link-azul" 
                                                                        onClick={() => {
                                                                            window.location.href = `fazendas/${f.id}/${val.id}`;
                                                                        }}
                                                                    >
                                                                        <IconRoom fill='#589bff' width='14px' height='14px'/> <label>{f.nome}</label>
                                                                    </p>
                                                                ) : (
                                                                    <p 
                                                                        className="link-azul" 
                                                                        onClick={() => {
                                                                            window.location.href = `fazendas/${f.id}/${val.id}`;
                                                                        }}
                                                                    >
                                                                        <IconRoom fill='#589bff'  width='14px' height='14px' /> <strike> <label>{f.nome}</label></strike>
                                                                    </p>
                                                                )}
                                                            </>
                                                        ))}
                                                    </td>
                                                    

                                                    <td>
                                                        {val.fazendas.map((f) => (
                                                            <>
                                                                {f.status == '1' ? (
                                                                    <p 
                                                                        className="link" 
                                                                        onClick={() => {
                                                                            window.location.href = `fazendas/${f.id}/${val.id}`;
                                                                        }}
                                                                    >
                                                                        <label>{f.cidade} - {f.estado}</label>
                                                                    </p>
                                                                ) : (
                                                                    <p 
                                                                        className="link" 
                                                                        onClick={() => {
                                                                            window.location.href = `fazendas/${f.id}/${val.id}`;
                                                                        }}
                                                                    >
                                                                        <strike> <label>{f.cidade} - {f.estado}</label> </strike>
                                                                    </p>
                                                                )}
                                                            </>
                                                        ))}
                                                    </td>



                                                    
                                                    <td 
                                                        onClick={() => {
                                                            window.location.href = `produtores/${val.id}`;
                                                        }}
                                                    >
                                                        <div className="estado">
                                                            {val.estado}
                                                        </div>
                                                    </td>
                                                    
                                                    <td> 
                                                        <Icons>
                                                            <div 
                                                                id="send-resp" 
                                                                onClick={(e) => {
                                                                    window.location.href = `agenda/?${val.id}`
                                                                }}
                                                                key={val.id}
                                                                style={{
                                                                    marginRight: '16px'
                                                                }}
                                                            >
                                                                <IconSchedule width='20px' style={{ cursor: 'pointer' }} /><p>Agenda</p>
                                                            </div>

                                                            <div 
                                                                id="send-resp" 
                                                                onClick={(e) => {
                                                                    window.location.href = `historico/?${val.id}`
                                                                }}
                                                                key={val.id}
                                                                
                                                            >
                                                                <IconReceita width='20px' style={{ cursor: 'pointer' }} /><p>Receitas</p>
                                                            </div>
                                                        </Icons>
                                                    </td>
                                                </div>
                                            </div>
                                        </Card >
                                    </>))}
                                </>) : (<>
                                    {titulos[0] === 'FAZENDAS' ? (<>
                                        {valores.map((val, index) => (<>
                                            <Card idColor={index}>
                                                <div id="container" onClick={() => {
                                                    window.location.href = `fazendas/${val.id}/${val.produtor.id}`;
                                                }}>
                                                    <div id="titulos" >
                                                        <p >{titulos[1]}</p>
                                                        <p >{titulos[2]}</p>
                                                        <p >{titulos[3]}</p>
                                                        <p >{titulos[4]}</p>
                                                    </div>
                                                    <div id="values" style={{ width: "50%" }}>
                                                        <td>{val.nome}</td>
                                                        <td>{val.produtor.nome}</td>
                                                        <td>{val.cidade}</td>
                                                        <td><div className="estado">{val.estado}</div></td>
                                                    </div>
                                                </div>
                                            </Card >
                                        </>))}
                                    </>) : (<>
                                        {titulos[0] === 'DOCUMENTO' ? (<>
                                            {valores.map((val, index) => (<>
                                                <Card idColor={index}>
                                                    <div id="container" onClick={() => {
                                                        if (val.tipo == "FQ") {
                                                            redicttoPDF(val.id)
                                                        } else {
                                                            downloadFE(val.id)
                                                        }
                                                    }}>
                                                        <div id="titulos" style={{ marginTop: "8px" }}>
                                                            <p >{titulos[1]}</p>
                                                            <p >{titulos[2]}</p>
                                                            <p >{titulos[3]}</p>
                                                            <p >{titulos[4]}</p>
                                                        </div>
                                                        <div id="values">
                                                            <td>{val.nome_produto}</td>
                                                            <td>{val.manufacturer ? val.manufacturer.nome : ''}</td>
                                                            <td> <div className="art-grid"><label className="loja" style={{ color: "#589bff" }}>{val.data_atualizacao ? val.data_atualizacao.split('-').reverse().join('/') : "N/D"}</label></div></td>

                                                            <td><div className="estado">{val.tipo == "FQ" ? "FISPQ" : "FE"}</div></td>
                                                        </div>
                                                    </div>
                                                </Card >
                                            </>))}
                                        </>) : (<>
                                            {titulos[0] === 'CONTRATOS' ? (<>
                                                {valores.map((val, index) => (<>
                                                    <Card idColor={index}>
                                                        <div id="container" onClick={() => { window.location.href = `/editcontract/${val.id}` }}>

                                                            <div id="titulos" style={{ marginTop: "8px" }}>
                                                                <p >{titulos[1]}</p>
                                                                <p >{titulos[2]}</p>
                                                                <p >{titulos[3]}</p>
                                                                <p >{titulos[4]}</p>
                                                            </div>



                                                            <div id="values">
                                                                {val.status == 0 ? <td><strike>{val.nome_produtor}</strike></td> : <td>{val.nome_produtor}</td>}
                                                                {val.status == 0 ? <td><strike>{val.nome_local_aplicacao}</strike></td> : <td>{val.nome_local_aplicacao}</td>}
                                                                {val.status == 0 ? <td><strike>{formatData(val.data_inicio)}</strike></td> : <td>{formatData(val.data_inicio)}</td>}
                                                                {val.status == 0 ? <td><strike>{formatData(val.data_termino)}</strike></td> : <td>{formatData(val.data_termino)}</td>}
                                                            </div>
                                                        </div>
                                                    </Card >
                                                </>))}
                                            </>) : (<>
                                                {titulos[0] === 'FAZENDAS_PRODUTOR' ? (<>
                                                    {valores.map((val, index) => (<>
                                                        <Card idColor={index}>
                                                            <div id="container" onClick={() => window.location.href = `/fazendas/${val.id}/${val.agro_producer_id}`}>
                                                                <div id="titulos">
                                                                    <p >{titulos[1]}</p>
                                                                    <p >{titulos[2]}</p>
                                                                    {typeof (val.cultura) != 'string' && (
                                                                        <>
                                                                            {val.cultura.map((c, i) => (
                                                                                <>
                                                                                    {i > 0 && (
                                                                                        <p> </p>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                    <p >{titulos[3]}</p>
                                                                    <p >{titulos[4]}</p>
                                                                </div>
                                                                <div id="values">
                                                                    {val.status == 0 ? <td><strike>{val.nome}</strike></td> : <td>{val.nome}</td>}
                                                                    {val.status == 0 ? <td><strike>{val.cultura != null && val.cultura != "" && typeof (val.cultura) != 'string' ? val.cultura.map(cultura => { return <div style={{ marginLeft: '2px' }}>{cultura.nome_comum}</div> }) : <div style={{ marginLeft: '2px' }}>-</div>}</strike></td> : <td>{val.cultura != null && val.cultura != "" && typeof (val.cultura) != 'string' ? val.cultura.map(cultura => { return <div style={{ marginLeft: '2px' }}>{cultura.nome_comum}</div> }) : <div style={{ marginLeft: '2px' }}>-</div>}</td>}
                                                                    {val.status == 0 ? <td><div className="estado-inativo">{val.estado}</div> </td> : <td><div className="estado">{val.estado}</div></td>}
                                                                    {val.status == 0 ? <td><strike>{val.cpf_cnpj}</strike></td> : <td>{val.cpf_cnpj}</td>}
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </>))}
                                                </>) : (
                                                    <>
                                                        {titulos[0] === 'GUIAS' ? (<>
                                                            {valores.map((val, index) => (<>
                                                                <Card idColor={index}>
                                                                    <div id="container" onClick={() => { setModalApagarVisualizar(true); setIdGuia(val.id); setUrlGuide(val.pdf_guia) }}>
                                                                        <div id="titulos">
                                                                            <p >{titulos[1]}</p>
                                                                            <p>{titulos[2]}</p>
                                                                            {
                                                                                val.receitas && (
                                                                                    val.receitas.map((re, i) => (
                                                                                        <>
                                                                                            {i > 0 && (
                                                                                                <p> </p>
                                                                                            )}
                                                                                        </>
                                                                                    ))
                                                                                )
                                                                            }
                                                                                
                                                                            <p >{titulos[3]}</p>
                                                                            <p >{titulos[4]}</p>
                                                                            <p >{titulos[5]}</p>
                                                                        </div>
                                                                        <div id="values">
                                                                            {<td>{val.numero_receita}</td>}
                                                                            {<td>
                                                                                {
                                                                                    val.receitas ? (
                                                                                        val.receitas.map(re => (
                                                                                            <p className="link">
                                                                                                    <b>{re.nome_comercial}</b> ({re.praga_nome_comum})
                                                                                            </p>
                                                                                        ))
                                                                                    ) : (
                                                                                        <p className="link">
                                                                                            <b>{val.nome_comercial}</b> ({val.praga})
                                                                                        </p>
                                                                                    )
                                                                                }
                                                                            </td>}
                                                                            {<td>{val.praga}</td>}
                                                                            {<td>{val.cultura}</td>}
                                                                            {<td>{val.aplicador}</td>}
                                                                            {<td>{formatData(val.data_aplicacao)}</td>}
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </>))}
                                                        </>) : (
                                                            <>
                                                                {titulos[0] === 'CRM' ? (<>
                                                                    {valores.map((oportunidade, index) => (<>
                                                                        <Card idColor={index}>
                                                                            <div id="container" onClick={()=> oportunidade.oportunidade_id ? props.editClient(oportunidade.oportunidade_id) : props.editClient(oportunidade.opportunity_id)}>
                                                                                <div id="titulos">
                                                                                    <p >{titulos[1]}</p>
                                                                                    <p >{titulos[2]}</p>
                                                                                    <p >{titulos[3]}</p>
                                                                                    <p >{titulos[4]}</p>
                                                                                </div>
                                                                                <div id="values">
                                                                                    {<td>{oportunidade.fazenda_cliente_nome ? oportunidade.fazenda_cliente_nome + '-' + oportunidade.produtor_cliente_nome : oportunidade.fazenda_nome + '-' + oportunidade.produtor_nome}</td>}
                                                                                    {<td>{oportunidade.fazenda_cliente_nome ? oportunidade.produtor_cliente_telefone + '/' + oportunidade.produtor_cliente_celular : oportunidade.fazenda_telefone + '/' + oportunidade.fazenda_celular}</td>}
                                                                                    {<td>{oportunidade.data_acao === "-" || oportunidade.data_acao === null ? 'SEM DATA' : formatDateTime(oportunidade.data_acao)}</td>}
                                                                                    {<td>{oportunidade.tipo_acao === "-" || oportunidade.tipo_acao === null ? 'SEM AÇÃO' : oportunidade.tipo_acao}</td>}
                                                                                </div>
                                                                            </div>
                                                                        </Card>
                                                                    </>))}
                                                                </>) : (
                                                                    <>
                                                                        {titulos[0] === 'CREA' ? (<>
                                                                            {valores.map((crea, index) => (<>
                                                                                <Card idColor={index}>
                                                                                    <div id="container" onClick={e =>
                                                                                        this.props.history.push(`/creas/${crea.id}`)
                                                                                    }>
                                                                                        <div id="titulos">
                                                                                            <p >{titulos[1]}</p>
                                                                                            <p >{titulos[2]}</p>
                                                                                            <p >{titulos[3]}</p>
                                                                                            <p >{titulos[4]}</p>
                                                                                        </div>
                                                                                        <div id="values">
                                                                                            {crea.status == 0 ? (
                                                                                                <>
                                                                                                    <td><strike>{crea.crea}</strike></td>
                                                                                                    <td><strike>{crea.tipo}</strike></td>
                                                                                                    <td><div className="estado-inativo">{crea.estado}</div></td>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <td>{crea.crea}</td>
                                                                                                    <td>{crea.tipo}</td>
                                                                                                    <td><div className="estado">{crea.estado}</div></td>
                                                                                                    <td>{crea.assinatura === 'M' ? 'Manual' : crea.assinatura === 'D' ? 'Digital' : crea.assinatura === 'E' && 'Eletrônica'}</td>
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>
                                                                                </Card>
                                                                            </>))}
                                                                        </>) : (null)}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </>)}

                                        </>)}

                                    </>)}

                                </>)}

                            </>)}

                        </>)}

                    </>)}

                </>)}

            </>)}


            <ModalTelefone
                title="Aviso!"
                text={modalText}
                show={modalTelefone}
                onHide={modalClose}
            >

            </ModalTelefone>

            <ModalAlert
                show={modalApagarVisualizar}
                onHide={() => setModalApagarVisualizar(false)}
            >
                <h4 style={{
                    textAlign: 'center'
                }}>O que deseja realizar?</h4>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "30px",
                }}>
                    <Button
                        value='Apagar Guia'
                        width="200px"
                        height="35px"
                        outline={true}
                        color="#FF5277"
                        onClick={() => setShowModalGuides(true)}
                    />
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                }}>
                    <Button
                        value='Visualizar Guia'
                        width="200px"
                        height="35px"
                        onClick={() => { window.open(urlGuide, '_blank'); setModalApagarVisualizar(false) }}
                    />
                </div>
            </ModalAlert>

            <ModalConfirmar
                show={showModalGuides}
                text="Digite 'CANCELAR' para continuar o cancelamento do guia"
                title="Confirmar Cancelamento do Guia de Aplicação"
                id={idGuia}
                update={resposta}
                onHide={() => setShowModalGuides(false)}
            />


            <ModalLimiteImpressao
                show={modalLimiteImpressao}
                onHide={modalClose}
            >

            </ModalLimiteImpressao>

        </>
    );
}
export default CardGeral;
