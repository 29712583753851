import styled from "styled-components";

export const StyleModal = styled.div`

  background-color: #f8f8f9;

  .term{
      width: 100%;
      display: flex;
      flex-direction: row;

      #termos{
        margin-top: 2px;
      }

      .lblTermos{
        font-size: 11px;
        margin-bottom: 0px;
        margin-left: 10px;
      
        .link{
          color: #589bff;
          cursor: pointer;
        }
      }
  }

  .adicionar-help{
    width: 116%;
    display: flex;
    flex-direction: row;

    .help-buttom{
      /* width: 146px; */
      text-align: center;
      margin-top: auto;
      padding-bottom: 16px;
      margin-left: 10px;
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #589bff;
      cursor: pointer
    }
  }

  .add-row{
    width: 100%;
    display: flex;
    flex-direction: row;

    .add{
      width: 146px;
      text-align: center;
      margin-top: auto;
      padding-bottom: 16px;
      margin-left: 10px;
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #589bff;
      cursor: pointer
    }
  }

  .arquivos{
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    margin-left: -10px;

    .btn-update{
      /* width: 200px; */
      display: flex;
      align-items: center;
      height: 40px;
      background-color: #2fbb69;
      border-radius: 30px;
      border: 1px dashed #2fbb69;
      font-size: 65%;
      color: #FFF;
      margin: 10px;
      padding: 2px 20px;
      cursor: pointer;
      transition: all 0.3s;
      z-index: 2;
    
      &:hover{
        cursor: pointer;
        background: #2fbb69;
        color: #FFF;
        box-shadow: 0 0 20px 0 #2fbb69;
      }
    }

    .arq-name{
      display: flex;
      align-items: center;
      width: 350px;
      height: 40px;
      background-color: #FFF;
      border-radius: 30px;
      padding: 13px;
      padding-left: 60px;
      margin-left: -55px;
      margin-top: 7px;
      box-shadow: inset 0px 0px 7px 2px #b6b6b6;
      font-family: Lato;
      font-size: 65%;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    img {
      transform: rotate(45deg);
      z-index: 10;
    }

    img:hover{
      cursor: pointer;
    }
    

  }

  .legenda {
    font-size: smaller;
    width: 100%;
  }


  

    
  #multiselectContainerReact{
    // margin: 40px 0 10px 100px;
    ._2OR24XnUXt8OCrysr3G0XI{
      width: 400px;
      .searchBox{
        margin: 5px 0 30px 10px;
      }      
      span{
        background: #2fbb69;
        // width: auto;
      }
      background: white;
      // height: 50px;
      width: 400px;
      border-radius: 25px;
    }

  }

  #multiselectContainerReact{
    margin: 10px 0 10px 0px;
    ._2OR24XnUXt8OCrysr3G0XI{
      .searchBox{
        margin: 5px 0 30px 10px;
      }      
      span{
        background: #2fbb69;
        width: auto;
      }
      background: white;
      // height: 50px;
      width: 400px;
      border-radius: 25px;
    }

  }

  @media only screen and (max-width:  768){
    .bUjnyb #multiselectContainerReact ._2OR24XnUXt8OCrysr3G0XI{
      width: 100%;
    }

    
  }


  @media only screen and (max-width: 473px) {
    form {
      .input-wrapper {
        .arquivos {
          .help-buttom{
            i {
              margin-left: -44px !important;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .Radioum{
      width:  70%;
      max-width: 400px;
    }

    .produto {
      width: 100%;
      max-width: 400px;
    }

    .input-wrapper {
      width:  100%;
      max-width: 400px;
    }

    .botaum {
      width: 35%;
      max-width: 400px;
    }
  }
  @media only screen and (max-width: 333px) {
    form {
      .input-wrapper {
        .arquivos {
          .help-buttom{
            i {
              margin-top: -51px !important;
              margin-left: -44px !important;
            }
          }
        }
      }
    }
  }
`;

export const YesNoContainer = styled.div`
  width: 165px;
  height: 40px;
  padding: 8px 8px 7px 21px;
  border-radius: 8px;
  background-color: #f3f3f5;
  position: absolute;
  margin: 0px;
  margin-top: -46px;
  left: ${props => (!props.disabled ? '384px' : '33px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
  z-index: -1;


  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
  }


  @media only screen and (max-width: 1024px) {
    left: ${props => (!props.disabled ? '310px' : '33px')};
  }
  
  @media only screen and (max-width: 991px) {
    left: ${props => (!props.disabled ? '269px' : '33px')};
  }
  
  @media only screen and (max-width:780px) {
    display: none;
  }

  @media only screen and (max-width: 540px) {
  }
`;

export const YesNoContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 780px) {
    display: ${props => (!props.disabled ? 'flex' : 'none')};;
    flex-direction: row;
    align-items: center;
    width: 160px;
    height: 50px;
    padding: 8px 8px 7px 8px;
    border-radius: 8px;
    background-color: #f3f3f5;
    position: absolute;
    margin: 0px;
    left: 33%;
    top: ${props => (!props.disabled ? '35px' : '-6px')};
    transition: all 0.2s;
    z-index: -1;

    span {
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #586377;
    }
  }

  @media only screen and (max-width: 690px) {
    left: 32%
  }
  
  @media only screen and (max-width: 460px) {
    left: 28%;
  }

  @media only screen and (max-width: 420px) {
    left: 25%
  }
  @media only screen and (max-width: 390px) {
    left: 24%
  }
  @media only screen and (max-width: 350px) {
    left: 20%
  }
  @media only screen and (max-width: 320px) {
    left: 15%
  }
  @media only screen and (max-width: 280px) {
    left: 11%
  }
  @media only screen and (max-width: 270px) {
    left: 9%
  }

`;

export const ReceitasCanceladas = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyleCoordenadas = styled.div`
  .coordenada{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    color: #8a92a2;


    .part{
      width: 18%;
      display: flex;
      flex-direction: row;

      label{
        width: 120%;
      }
      input{
        width: 125px;
      }
      span{
        margin: auto;
        width: 10px;
        font-weight: 500;
        /* font-size: x-large; */
      }
    }
  }


  @media only screen and (max-width: 768px) {
    .coordenada{
      flex-direction: column;
      color: #8a92a2;

      .part{
        width: 100%;
        input{
          width: 95%;
        }
        span{
          margin-right: 10px;
        }
      }
    }
  }
`;




export const ModalArea = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0px;
  left: 0px;

  display: flex;
  flex-direction: column;
  align-items: ${props => props.align ? props.align : 'center'};
  justify-content: ${props => props.justify ? props.justify : 'center'};

  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(1.1px);
  -webkit-backdrop-filter: blur(1.1px);

  
  z-index: 1000;


    //Responsivo
    @media only screen and (max-width: 843px){
      justify-content: ${props => props.responsive ? 'flex-end' : 'center'};
    }
`;

export const ModalCard = styled.div`

  width: ${props => props.width ? props.width : props.size ? props.size === 'lg' ? '60%' : props.size === 'md' ? '50%' : props.size === 'sm' ? '30%' : 'auto' : 'auto'};
  height: ${props => props.align ? props.align === 'end' || props.align === 'start' ? '100vh' : 'auto' : 'auto'};
  max-height: 90vh;
  padding: 16px 32px;

  border: 1px solid transparent;
  /* border-radius: 10px; */
  border-radius: ${props => props.align ? props.align === 'end' ? '10px 0px 0px 10px' :  props.align === 'start' ? '0px 10px 10px 10px' : props.justify ? props.justify === 'end' ? '10px 10px 0px 0px' : props.justify === 'start' ? '0px 0px 10px 10px' : '25px' : '25px' : '25px'};
  box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
  background-color: ${props => props.backgroundColor ? props.backgroundColor : '#FFF'};

  overflow: auto;

  /* largura da barra de rolagem */
  &::-webkit-scrollbar {
    width: 4px; /* Ajuste a largura conforme necessário */
  }

  /* cor da barra de rolagem */
  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Ajuste a cor conforme necessário */
    border-radius: 16px;
  }

  /* fundo da barra de rolagem */
  &::-webkit-scrollbar-track {
    background: transparent; /* Isso remove o fundo da barra de rolagem */
  }

  /* caso deseje estilizar a barra de rolagem em navegadores Firefox */
  & {
    scrollbar-width: thin; /* largura da barra de rolagem */
    scrollbar-color: #888 transparent; /* cor da barra de rolagem e cor do fundo */
  }

  .msg-promo{
    p{
      font-family: Lato;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
      color: white;

      b{
        color: rgb(255, 82, 82);
        font-weight: 900;
      }
    }

    .logo{
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
    }

    .texto{
      padding: 24px;
    }
  }

  .dois-cards{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    width: 100%;

    .card{
      background-color: #292E38;
      padding: 23px;
      width: 48%;
      border-radius: 16px;

      h6{
        font-family: Lato;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
      }

      p{
        padding-top: 8px;
      }

      .valor-antigo{
        color: #BAC1CF;
        text-align: center;
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 130%;
        text-decoration-line: line-through;
      }

      .valor-novo{
        font-family: Lato;
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        color: #FF5252;
      }

      .tipo{
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
      }
    }
  }

  .validade-promo{
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    color: white;
    padding-top: 16px;
  }

  .close-icon{
    display: flex;
    justify-content: space-between;
    color: white;
    align-items: center;
    padding-top: 6px;
    width: 30%;
    cursor: pointer;

    img{
      width: 16px;
      cursor: pointer;
    }

    p{
      font-family: Lato;
      font-size: 10px;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
      letter-spacing: 1px;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  //Responsivo
  @media only screen and (max-width: 843px){
    width: 100%;
    flex-direction: column;
    border-radius: ${props => props.responsive ? '10px 10px 0px 0px' : '10px'};

    @supports (-webkit-touch-callout: none) {
      padding: 16px 32px 96px 32px;
    }

    .msg-promo{
      .texto{
        padding: 24px 0px;
      }
    }

    .close-icon{
      width: 50%;
    }

    .msg-promo p {
      font-size: 16px;
    }
  }
`;

export const ModalHeader = styled.div `
  width: 100%;
  height: 32px;

  display: ${props => props.removeHeader ? 'none' : 'flex'};
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.align ? props.align === 'end' ? 'start' :  props.align === 'start' ? 'end' : props.justify ? props.justify === 'end' ? 'center' :props.justify === 'start' ? 'center' : 'end' : 'end' : 'end'};
  
  img {
    width: 16px;
    cursor: pointer;
    position: fixed;
  }
`;

export const ModalTitle = styled.h3 `
  width: 100%;
  text-align: center;

  margin-bottom: 32px;
  margin-top: -32px;

`;

export const ModalBody = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  text-align: ${props => props.textAlign ? props.textAlign : 'center'};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '32px'} ;
`;

export const ModalFooter = styled.div`
  width: 100%;
  height: auto;

  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};

  flex-wrap: wrap;

  @media only screen and (max-width: 843px){
    .button-finish {
      width: 100% !important;
      margin-top: 8px;
    }

  }


`;
