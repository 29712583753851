// nome_cartao,
// num_cartao,
// expiracao_mes,
// expiracao_ano,
// ccv,
// nome_completo_cartao,
// email_cartao,
// cpf_cnpj_cartao,
// cep_cartao,
// endereco_cartao,
// complemento_cartao,
// numero_endereco_cartao,
// fone_cartao,
// cel_cartao,


import React, { useState, useEffect } from "react";
import Input from "../../input/input_v2";
import Button from "../../button/button_v2";
import buscaCep from "../../../services/buscaCep";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

export const FormCartao = ({ ...props }) => {

    const [step, setStep] = useState(1);

    const [nome_completo_cartao, setNomeCompletoCartao] = useState('');
    const [email_cartao, setEmailCartao] = useState('');
    const [cpf_cnpj_cartao, setCpfCnpjCartao] = useState('');
    const [cep_cartao, setCepCartao] = useState('');
    const [endereco_cartao, setEnderecoCartao] = useState('');
    const [complemento_cartao, setComplementoCartao] = useState('');
    const [numero_endereco_cartao, setNumeroEnderecoCartao] = useState('');
    const [fone_cartao, setFoneCartao] = useState('');
    const [cel_cartao, setCelCartao] = useState('');
    
    const [nome_cartao, setNomeCartao] = useState('');
    const [num_cartao, setNumCartao] = useState('');
    const [expiracao_mes, setExpiracaoMes] = useState('');
    const [expiracao_ano, setExpiracaoAno] = useState('');
    const [ccv, setCcv] = useState('');

    const [focus, setFocus] = useState('');
    const [cardIsValid, setCardIsValid] = useState(false);
    const [valuesCardIsValid, setValuesCardIsValid] = useState(false);
    const [disabledPayment, setDisabledPayment] = useState(true);

    const [erroNomeCompletoCartao, setErroNomeCompletoCartao] = useState(false);
    const [erroEmailCartao, setErroEmailCartao] = useState(false);
    const [erroCpfCnpjCartao, setErroCpfCnpjCartao] = useState(false);
    const [erroCepCartao, setErroCepCartao] = useState(false);
    const [erroEnderecoCartao, setErroEnderecoCartao] = useState(false);
    const [erroNumeroEnderecoCartao, setErroNumerEnderecoCartao] = useState(false);
    const [erroFoneCartao, setErroFoneCartao] = useState(false);
    const [erroCelCartao, setErroCelCartao] = useState(false);
    const [loading, setLoading] = useState(false);

    const setInitialValues = () => {
        if (props.value.email_cartao) {
            setEmailCartao(props.value.email_cartao);
        }

        if (props.value.cpf_cnpj_cartao) {
            setCpfCnpjCartao(props.value.cpf_cnpj_cartao);
        }

        if (props.value.cep_cartao) {
            setCepCartao(props.value.cep_cartao);
        }
        
        if (props.value.fone_cartao) {
            setFoneCartao(props.value.fone_cartao);
        }

        if (props.value.nome_completo_cartao) {
            setNomeCompletoCartao(props.value.nome_completo_cartao)
        }
        
        if (props.value.loading) {
            setLoading(props.value.loading)
        }
    }

    const changeCep = async (cep) => {
        // const c = buscaCep(cep_cartao);

        // console.log(c);

        setCepCartao(cep);

        try {
            const responseCep = await buscaCep(cep);
            const { logradouro, localidade, bairro, uf } = responseCep;

            setEnderecoCartao(logradouro);

        } catch (err) {
            // store.addNotification({
            //     title: "Erro!",
            //     message: err.message,
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }

    useEffect(() => {
        setInitialValues(props.values)
    }, [])

    const changeFocus = (isFocus, value) => {
        if (isFocus) {
            setFocus(value);
        } else {
            setFocus('');
        }
    }

    const validaMes = () => {
        let mes = parseInt(expiracao_mes);

        if (mes <= 12) {
            if (mes < 10) {
                setExpiracaoMes(`0${mes}`);
            }
        } else {
            setExpiracaoMes('');
        }
    }

    const validaCreditCard = (card, isValid) => {
        setCardIsValid(isValid);
    }

    const nextStep = (s) => {
        if (s === 2) {

            let erro = false;

            if (!nome_completo_cartao || nome_completo_cartao === '') {
                setErroNomeCompletoCartao(true);
                erro = true;
            }

            if (!email_cartao || email_cartao === '') {
                setErroEmailCartao(true);
                erro = true;
            }

            if (!cpf_cnpj_cartao || cpf_cnpj_cartao === '') {
                setErroCpfCnpjCartao(true);
                erro = true;
            }

            if (!cep_cartao || cep_cartao === '') {
                setErroCepCartao(true);
                erro = true;
            }

            if (!endereco_cartao || endereco_cartao === '') {
                setErroEnderecoCartao(true);
                erro = true;
            }

            if (!numero_endereco_cartao || numero_endereco_cartao === '') {
                setErroNumerEnderecoCartao(true);
                erro = true;
            }

            if (!fone_cartao || fone_cartao === '') {
                setErroFoneCartao(true);
                erro = true;
            }

            if (!cel_cartao || cel_cartao === '') {
                setErroCelCartao(true);
                erro = true;
            }



            if (erro === false) {
                setStep(s)
            }
        }
    }


    useEffect(() => {
        if (
            nome_cartao !== '' &&
            num_cartao !== '' &&
            expiracao_mes !== '' &&
            expiracao_ano !== '' &&
            ccv !== ''
        ) {
            setValuesCardIsValid(true);
        } else {
            setValuesCardIsValid(false);
        }
    }, [nome_cartao, num_cartao, expiracao_mes, expiracao_ano, ccv])

    useEffect(() => {
        if (cardIsValid && valuesCardIsValid) {
            setDisabledPayment(false);
        } else {
            setDisabledPayment(true);
        }
    }, [cardIsValid, valuesCardIsValid])

    return(

        <>
            {step === 1 ? (
                <div style={{
                    width: '100%',
                    maxWidth: '440px',
                    margin: 'auto'
                }}>
                    <h2 style={{
                        width: '100%',
                        display: 'flex',
                        flexDirectin: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '24px'
                    }}>Titular do cartão</h2>

                    <Input
                        name="nome"
                        id="nome_nome"
                        label="Nome do titular do cartão"
                        type="nome"
                        onChange={(e) => {
                            setNomeCompletoCartao(e);
                            setErroNomeCompletoCartao(false);
                        }}
                        value={nome_completo_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroNomeCompletoCartao}
                    />
                    
                    <Input
                        name="email"
                        id="email_id"
                        label="Email"
                        type="email"
                        onChange={(e) => {
                            setEmailCartao(e);
                            setErroEmailCartao(false);
                        }}
                        value={email_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroEmailCartao}
                    />
                    
                    <Input
                        name="cpfcnpj"
                        id="cpf_cnpj_id"
                        label="CPF/CNPJ"
                        type="text"
                        onChange={(e) => {
                            setCpfCnpjCartao(e);
                            setErroCpfCnpjCartao(false);
                        }}
                        value={cpf_cnpj_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroCpfCnpjCartao}
                        inputmode="numeric"
                    />
                    
                    <Input
                        name="cep"
                        id="cep_id"
                        label="CEP"
                        type="text"
                        onChange={(e) => {
                            changeCep(e);
                            setErroCepCartao(false);
                        }}
                        value={cep_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroCepCartao}
                        inputmode="numeric"
                    />

                    <Input
                        name="endereco"
                        id="id_endereco"
                        label="Logradouro"
                        type="text"
                        onChange={(e) => {
                            setEnderecoCartao(e);
                            setErroEnderecoCartao(false);
                        }}
                        value={endereco_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroEnderecoCartao}
                    />
                    
                    <Input
                        name="complemento"
                        id="id_complemento"
                        label="Complemento"
                        type="text"
                        onChange={(e) => setComplementoCartao(e)}
                        value={complemento_cartao}
                    />
                    
                    <Input
                        name="numero"
                        id="id_numero"
                        label="Número"
                        onChange={(e) => {
                            setNumeroEnderecoCartao(e);
                            setErroNumerEnderecoCartao(false);
                        }}
                        value={numero_endereco_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroNumeroEnderecoCartao}
                    />
                    
                    <Input
                        name="telefone"
                        id="id_telefone"
                        label="Telefone"
                        type="text"
                        onChange={(e) => {
                            setFoneCartao(e);
                            setErroFoneCartao(false);
                        }}
                        value={fone_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroFoneCartao}
                        inputmode="numeric"
                    />
                    
                    <Input
                        name="celular"
                        id="id_celular"
                        label="Celular"
                        type="text"
                        onChange={(e) => {
                            setCelCartao(e);
                            setErroCelCartao(false);
                        }}
                        value={cel_cartao}
                        messageErro="Campo obrigatório"
                        erro={erroCelCartao}
                        inputmode="numeric"
                    />


                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Button 
                            width='40%'
                            height='50px'
                            value='AVANÇAR'
                            outline={false}
                            disabled={false}
                            onClick={() => nextStep(2)}
                            loading={false}
                        />
                    </div>

                </div>
            ) : (
                <>
                    <h2 style={{
                        width: '100%',
                        display: 'flex',
                        flexDirectin: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: '24px'
                    }}>Informações do cartão</h2>
                    
                    <div style={{
                        display: 'flex',
                        flexDirection: window.screen.width <= 991 ? 'column-reverse' : 'row',
                        width: '80%',
                        alignItems: 'center',
                        justifyContent: 'space-evenly'
                    }}>
                        <div style={{
                            width: window.screen.width <= 991 ? '336px' : '350px',
                            marginTop: window.screen.width <= 991 ? '32px' : 'auto',
                        }}>
                            <Input
                                name="nome_cartao"
                                id="nome_cartao"
                                label="Nome no cartão"
                                type="text"
                                onChange={(e) => setNomeCartao(e)}
                                value={nome_cartao}
                                onFocus={(e) => changeFocus(e, 'name')}
                                messageErro="Campo obrigatório"
                            />
                            
                            <Input
                                name="credit-card"
                                id="num_cartao"
                                label="Número do cartão"
                                type="text"
                                inputmode="numeric"
                                onChange={(e) => setNumCartao(e)}
                                value={num_cartao}
                                onFocus={(e) => changeFocus(e, 'number')}
                                messageErro="Campo obrigatório"

                            />
                            
                            <Input
                                name="mes_expiracao"
                                id="mes_expiracao"
                                label="Mês de expiração"
                                type="number"
                                onChange={(e) => setExpiracaoMes(e)}
                                value={expiracao_mes}
                                onFocus={(e) => changeFocus(e, 'expiry')}
                                onBlur={() => validaMes()}
                                messageErro="Campo obrigatório"

                            />
                            
                            <Input
                                name="ano_expiracao"
                                id="ano_expiracao"
                                label="Ano de expiração"
                                type="number"
                                onChange={(e) => setExpiracaoAno(e)}
                                value={expiracao_ano}
                                onFocus={(e) => changeFocus(e, 'expiry')}
                                messageErro="Campo obrigatório"
                            />
                            
                            <Input
                                name="cvv"
                                id="id_cvv"
                                label="CVV"
                                type="number"
                                onChange={(e) => setCcv(e)}
                                value={ccv}
                                onFocus={(e) => changeFocus(e, 'cvc')}
                                messageErro="Campo obrigatório"
                            />
                        </div>

                        <div id="PaymentForm">
                            <Cards
                                cvc={ccv}
                                expiry={`${expiracao_mes}/${expiracao_ano}`}
                                focused={focus}
                                name={nome_cartao}
                                number={num_cartao}
                                callback={(e, i) => validaCreditCard(e, i)}
                                placeholders={{ name: 'SEU NOME AQUI' }}
                                locale={{valid: 'validade'}}
                            />
                        </div>
                    </div>



                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '20px', marginBottom: '20px' }}>
                        <Button 
                            width='40%'
                            height='50px'
                            value='FINALIZAR COMPRA'
                            outline={false}
                            disabled={disabledPayment}
                            onClick={() => {props.callback({
                                nome_completo_cartao,
                                email_cartao,
                                cpf_cnpj_cartao,
                                cep_cartao,
                                endereco_cartao,
                                complemento_cartao,
                                numero_endereco_cartao,
                                fone_cartao,
                                cel_cartao,
                                nome_cartao,
                                num_cartao,
                                expiracao_mes,
                                expiracao_ano,
                                ccv
                            }) ; setLoading(true)}}
                            loading={loading}
                        />
                    </div>
                </>
            )}
        
        </>


    )

}


