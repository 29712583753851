import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { Container } from "react-grid-system";
import Button from "../../components/button/button_v2";
import Input from "../../components/input/input_v2";
import Select from "../../components/select/select_v2";
import api from "../../services/api";
import { FormContainer } from "../../components/compra/step0/style";
import GenericUpper from '../../components/generic-upper';
import { store } from 'react-notifications-component';
import ModalGeneric from '../../components/modal/modalGeneric';
import Faturamento from '../faturamento/index';
import Radio from '../../components/radio';
import { FormCartao } from '../../components/compra/cartao';

const ComprarReceita = ({ ...props }) => {

    const [disabled, setDisabled] = useState(true);
    const [disabledNumUsers, setDisabledNumUsers] = useState(true);
    const [disabledPrescript, setDisabledPrescript] = useState(true);
    const [disabledDoc, setDisabledDoc] = useState(true);
    const [gridId, setGridId] = useState('');
    const [numUsersId, setNumUsersId] = useState('');
    const [numDocuments, setNumDocuments] = useState('');
    const [numPrescriptions, setNumPrescriptions] = useState('');
    const [valorTotal, setValorTotal] = useState('');
    const [valorPlano, setValorPlano] = useState('');
    const [plano, setPlano] = useState([]);
    const [planoSelecionado, setPlanoSelecionado] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingCard, setLoadingCard] = useState(false);
    const [showModalConfirm, setOpenModalConfirm] = useState(false);
    const [showModalPix, setOpenModalPix] = useState(false);
    const [showModalFaturamento, setShowModalFaturamento] = useState(false);

    const [qrCodePix, setQrCodePix] = useState('');
    const [email, setEmail] = useState('');
    const [pixCode, setPixCode] = useState('');
    const [copiado, setCopiado] = useState(false);

    const [paymentMethodId, setPaymentMethodId] = useState(0);
    const [showCard, setShowCard] = useState(false);
    
    
    const comprar = async (tipoPagamento, e) => {

        setOpenModalConfirm(false);
        setOpenModalPix(false);

        try {
            if (plano[0].plan_type_id === 2 || plano[0].plan_type_id === 4) {
                //por receita


                const payload = {
                    qtd_receitas: numPrescriptions, 
                    valor_item: valorPlano, 
                    valor_total: valorTotal, 
                    tipo_pagamento: tipoPagamento,
        
                    nome_cartao: e.nome_cartao || '',
                    numero_cartao: e.num_cartao || '',
                    expiracao_mes: e.expiracao_mes || '',
                    expiracao_ano: e.expiracao_ano || '',
                    ccv: e.ccv || '',
                    nome_completo_cartao: e.nome_completo_cartao || '',
                    email_cartao: e.email_cartao || '',
                    cpf_cnpj_cartao: e.cpf_cnpj_cartao || '',
                    cep_cartao: e.cep_cartao || '',
                    endereco_cartao: e.endereco_cartao || '',
                    complemento_cartao: e.complemento_cartao || '',
                    numero_endereco_cartao: e.numero_endereco_cartao || '',
                    fone_cartao: e.fone_cartao || '',
                    cel_cartao: e.cel_cartao || '',
                    salvar_cartao: 1
                }

                if ((numPrescriptions && numPrescriptions > 0)) {
                    setLoading(true);
                    const response = await api.post('/adquirir/receita', payload);
                    
                    if (response) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Compra realizada com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                        setLoading(false);
    
                        window.location = '/';
                    }
                } else {
                    setLoading(false);
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Informe a quantidade para continuar a compra!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 8000,
                            onScreen: true
                        }
                    });
                }
            } else if (plano[0].plan_type_id === 3) {

                const payload = {
                    qtd_receitas: numPrescriptions, 
                    valor_item: valorPlano, 
                    valor_total: valorTotal, 
                    tipo_pagamento: tipoPagamento,
        
                    nome_cartao: e.nome_cartao || '',
                    numero_cartao: e.num_cartao || '',
                    expiracao_mes: e.expiracao_mes || '',
                    expiracao_ano: e.expiracao_ano || '',
                    ccv: e.ccv || '',
                    nome_completo_cartao: e.nome_completo_cartao || '',
                    email_cartao: e.email_cartao || '',
                    cpf_cnpj_cartao: e.cpf_cnpj_cartao || '',
                    cep_cartao: e.cep_cartao || '',
                    endereco_cartao: e.endereco_cartao || '',
                    complemento_cartao: e.complemento_cartao || '',
                    numero_endereco_cartao: e.numero_endereco_cartao || '',
                    fone_cartao: e.fone_cartao || '',
                    cel_cartao: e.cel_cartao || '',
                    salvar_cartao: 1
                }

                if ((numDocuments && numDocuments > 0)) {
                    setLoading(true);
                    const response = await api.post('/adquirir/receita', payload);
                    
                    if (response) {
                        store.addNotification({
                            title: "Sucesso!",
                            message: `Compra realizada com sucesso!`,
                            type: "success",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 8000,
                                onScreen: true
                            }
                        });
                        setLoading(false);
    
                        window.location = '/';
                    }
                } else {
                    setLoading(false);
    
                    store.addNotification({
                        title: "Erro!",
                        message: `Informe a quantidade para continuar a compra!`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 8000,
                            onScreen: true
                        }
                    });
                }
            }
        } catch (error) {
            setLoading(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 8000,
                    onScreen: true
                }
            });
        }
        
    }


    const validaQtd = () => {
        if (numPrescriptions >= 40 || numDocuments >= 40) {
            setOpenModalConfirm(true);
        } else {
            store.addNotification({
                title: "Atenção!",
                message: `A quantidade minima para compra é de pelo menos 40 receitas`,
                type: "warning",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }

    }

    const getPlanos = async () => {
        try {
            const response =  await api.get(`/planos`);
            let planos = response.data.data;
            let format = formatPlanos(planos);
            setPlanos(format);

        } catch (error) {

        }
    }

    const formatPlanos = planos => {
        let formatPlano = planos.map(p => {
            const format = {
                value: p.id,
                label: p.plan_name,
                users: p.users_limit,
                documents: p.documents_limit,
                prescriptions: p.prescriptions_limit,
                type: p.plan_type_id
            };
            return format;
        });
        return formatPlano;
    }

    const getPlano = async (id) => {
        try {
            const response =  await api.get(`/planos/${id}`);
            let plano = response.data.data;
        
            setPlano(plano);

            let p = formatPlanos(plano);
            setPlanoSelecionado(p[0]);
            if(!numUsersId){
                setValorPlano(plano[0].plans_grid[0].vlr_unit);
            }else{
                plano[0].plans_grid.map(p => {
                    if(p.vlr_minimo == 0 && p.qtd_users == numUsersId){
                        setValorPlano(p.vlr_unit);
                    }else{
                        if(p.vlr_minimo == 1 && p.qtd_users <= numUsersId){
                            setValorPlano(p.vlr_unit);
                        }
                    }
                })
            }

            calculaValorTotal(plano, parseInt(numUsersId));

        } catch (error) {

        }
    } 

    const calculaValorTotal = (plano, vlr) => {
        console.log(plano);

        if (plano.length > 0) {
            const p = plano[0];
    
            if (p.plans_grid.length > 0) {
    
                let valorPlano = 0;
                let valorTotal = 0;
    
                let find = false;
    
                if (p.plan_type_id === 2 || p.plan_type_id === 3 || p.plan_type_id === 4) {
                    if (vlr >= 1) {
                        p.plans_grid.map((grid) => {
                            if (find === false) {
    
                                if (vlr <= parseInt(grid.qtd_users)) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                    
                                } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                } else if (parseInt(grid.vlr_minimo) === 1) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                }
                            }
    
                        })
                        
                        valorPlano = ""+valorPlano;
                        let calculaTotal = valorPlano.replace(",", ".");
                        valorTotal = vlr * calculaTotal;
                        valorTotal = valorTotal.toFixed(2);
                        valorTotal = ""+valorTotal;
            
                        valorTotal = valorTotal.replace(".", ",");
            
                        setValorPlano(valorPlano);
                        setValorTotal(valorTotal);
                    }
                    
                } else if (p.plan_type_id === 5) {
                    if (vlr >= 1) {
                        p.plans_grid.map((grid) => {
                            if (find === false) {
    
                                if (vlr == parseInt(grid.qtd_users)) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                    
                                } else if (vlr >= parseInt(grid.qtd_users) && parseInt(grid.vlr_minimo) === 1) {
                                    valorPlano = grid.vlr_unit;
                                    setGridId(grid.id);
                                    find = true;
                                }
                            }
    
                        })
                        
                        valorPlano = ""+valorPlano;
                        let calculaTotal = valorPlano.replace(",", ".");
                        valorTotal = vlr * calculaTotal;
                        valorTotal = valorTotal.toFixed(2);
                        valorTotal = ""+valorTotal;
            
                        valorTotal = valorTotal.replace(".", ",");
            
                        setValorPlano(valorPlano);
                        setValorTotal(valorTotal);
                    }
                }
            } else {
                //gratis
                setValorPlano('0,00');
                setValorTotal('0,00');
                setGridId(0);
            }
        }
    }

    const validateNumUsuarios = valor => {
        setNumUsersId(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor));
    }

    const validateNumPrescript = valor => {
        setNumPrescriptions(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor));

        if (planoSelecionado.type === 4) {
            setNumDocuments(parseInt(valor));
        }
    }

    const validateNumDocument = valor => {
        setNumDocuments(parseInt(valor));
        calculaValorTotal(plano, parseInt(valor));

        if (planoSelecionado.type === 4) {
            setNumPrescriptions(parseInt(valor));
        }
    }

    const validatePlano = plano => {        
        setPlanoSelecionado(plano); 
        getPlano(plano.value);

        if (plano.users === 1) {
            validateNumUsuarios(1)
            setDisabledNumUsers(true);
        } else {
            validateNumUsuarios('')
            setDisabledNumUsers(false);
        }

        if (plano.documents !== null) {
            validateNumDocument(plano.documents);
            setDisabledDoc(true)
        } else {
            validateNumDocument('');
            setDisabledDoc(false);
        }
        
        if (plano.prescriptions !== null) {
            validateNumPrescript(plano.prescriptions);
            setDisabledPrescript(true)
        } else {
            validateNumPrescript('');
            setDisabledPrescript(false);
        }
    }


    const selectActivePlan = () => {
        let user = JSON.parse(localStorage.getItem('@userinfo'));

        let planoId = user.contrato.agro_plan_config.agro_plan_id;
        let planoNome = user.contrato.agro_plan_config.nome_plano;

        setPlanoSelecionado({value: planoId, label: planoNome});
    }
    
    const openPix = async () => {
        setOpenModalConfirm(false);

        try {

            const vlr = valorTotal.replace(',', '.');

            const response = await api.get(`/gerarpix/${vlr}`);

            const qr = `data:image/png;base64, ${response.data.data.qrcode}`
            setQrCodePix(qr);
            setPixCode(response.data.data.codigo);

            setOpenModalPix(true);

        } catch (error) {
            
        }

    }

    const copyPix = async (pixCode) => {
        console.log(pixCode);
        await navigator.clipboard.writeText(pixCode);
        setCopiado(true);
    }

    const openFaturamento = () => {
        setShowModalFaturamento(true);
        setOpenModalConfirm(false);
    }

    const confirmFaturamento = () => {
        setShowModalFaturamento(false);
        setOpenModalConfirm(true);
    }

    const changeMethodPayment = (e) => {
        setPaymentMethodId(e);
    }

    const continuarCompra = () => {
        if (paymentMethodId === 3 || paymentMethodId === '3') {
            //Cartão de crédito
            setOpenModalConfirm(false);
            setShowCard(true);

        } else if (paymentMethodId === 2 || paymentMethodId === '2') {
            //PIX
            // setShowPaymentMethod(false);
            // openPix()

            comprar(2, '')
        } else {
            //BOLETO

            comprar(1, '')
        }
    }

    useEffect(() => {
        getPlanos();

        let user = JSON.parse(localStorage.getItem('@userinfo'));
        if (user && user !== null) {
            setEmail(user.username);
        }
    },[]);

    useEffect(() => {
        if (copiado === true) {
            setTimeout(() => {
                setCopiado(false);
            }, 4000);
        }
    },[copiado])

    useEffect(() => {
        if (numDocuments || numPrescriptions) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    },[numDocuments, numPrescriptions])

    useEffect(() => {
        if (planos.length > 0) {
            selectActivePlan()
        }
    }, [planos])

    return (
        <>
            <GenericUpper
                align="center"
                title="Comprar receitas"
                subtitle=""
            ></GenericUpper>

            <FormContainer>
                <Container>
                    <form>
                        <Select 
                            label="Escolha seu plano"
                            options={planos}
                            value={planoSelecionado.value || planoSelecionado}
                            onChange={(e) => validatePlano(e)}
                            disabled={true}
                            className="select"
                            id='plano'
                        />

                        {planoSelecionado.type === 5 ? (
                            <Input
                                name="numUsuarios"
                                label="Número de usuários"
                                type="number"
                                min="1" 
                                id='num_usuarios'
                                value={numUsersId}
                                disabled={false}
                                onChange={(e) => validateNumUsuarios(e)}
                            />
                        ) : (
                            <>
                                {planoSelecionado.type === 4 ? (
                                    <>
                                        {planoSelecionado.users !== null ? (
                                            <Input
                                                name="numUsuarios"
                                                label="Número de usuários"
                                                type="number"
                                                min="1" 
                                                id='num_usuarios'
                                                value={numUsersId}
                                                disabled={disabledNumUsers}
                                                onChange={(e) => validateNumUsuarios(e)}
                                            />
                                        ) : (null)}

                                        <Input
                                            name="numreceita"
                                            label="Número de receitas"
                                            type="number"
                                            min="1" 
                                            id='num_usuarios'
                                            value={numPrescriptions}
                                            disabled={false}
                                            onChange={(e) => validateNumPrescript(e)}
                                        />
                                        
                                        <Input
                                            name="numdocumentos"
                                            label="Número de documentos"
                                            type="number"
                                            min="1" 
                                            id='num_usuarios'
                                            value={numDocuments}
                                            disabled={disabledDoc}
                                            onChange={(e) => validateNumDocument(e)}
                                        />
                                    </>
                                ) : (
                                    <>
                                        {planoSelecionado.type === 3 ? (
                                            <>
                                                <Input
                                                    name="numdocumentos"
                                                    label="Número de documentos"
                                                    type="number"
                                                    min="1" 
                                                    id='num_usuarios'
                                                    value={numDocuments}
                                                    disabled={disabledDoc}
                                                    onChange={(e) => validateNumDocument(e)}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                {planoSelecionado.type === 2 ? (
                                                    <>
                                                        <Input
                                                            name="numreceita"
                                                            label="Número de receitas"
                                                            type="number"
                                                            min="1" 
                                                            id='num_usuarios'
                                                            value={numPrescriptions}
                                                            disabled={false}
                                                            onChange={(e) => validateNumPrescript(e)}
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                    
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}

                            </>
                        )}

                        <div className="valores">
                            {planoSelecionado.type === 5 ? (
                                <>
                                    {valorPlano ? (
                                        <h3 className="valor"><label>R$ {valorPlano}</label> mensais/usuário</h3>
                                    ):(
                                        <h3 className="valor"><label>R$ 0,00</label> mensais/usuário</h3>
                                    )}
                                    
                                    
                                    {valorTotal ? (
                                        <p className="total">Valor Total: R$ {valorTotal}</p>
                                    ):(
                                        <p className="total">Valor Total: R$ 0,00</p>
                                    )}
                                </>
                            ): (
                                <>
                                    {planoSelecionado.type === 4 ? (
                                        <>
                                            {valorPlano ? (
                                                <h3 className="valor"><label>R$ {valorPlano} </label> por receitas e documentos</h3>
                                            ):(
                                                <h3 className="valor"><label>R$ 0,00</label> por receitas e documentos</h3>
                                            )}
                                            
                                            
                                            {valorTotal ? (
                                                <p className="total">Valor Total: R$ {valorTotal}</p>
                                            ):(
                                                <p className="total">Valor Total: R$ 0,00</p>
                                            )}
                                        </>
                                    ): (
                                        <>
                                            {planoSelecionado.type === 3 ? (
                                                <>
                                                    {valorPlano ? (
                                                        <h3 className="valor"><label>R$ {valorPlano} </label> mensais/documentos</h3>
                                                    ):(
                                                        <h3 className="valor"><label>R$ 0,00</label> mensais/documentos</h3>
                                                    )}
                                                    
                                                    
                                                    {valorTotal ? (
                                                        <p className="total">Valor Total: R$ {valorTotal}</p>
                                                    ):(
                                                        <p className="total">Valor Total: R$ 0,00</p>
                                                    )}
                                                </>
                                            ): (
                                                <>
                                                    {planoSelecionado.type === 2 ? (
                                                        <>
                                                            {valorPlano ? (
                                                                <h3 className="valor"><label>R$ {valorPlano} </label> por receitas</h3>
                                                            ):(
                                                                <h3 className="valor"><label>R$ 0,00</label> por receitas</h3>
                                                            )}
                                                            
                                                            
                                                            {valorTotal ? (
                                                                <p className="total">Valor Total: R$ {valorTotal}</p>
                                                            ):(
                                                                <p className="total">Valor Total: R$ 0,00</p>
                                                            )}
                                                        </>
                                                    ): (
                                                        <>
                                                            
                                                        </>
                                                    )} 
                                                    
                                                </>
                                            )} 
                                        </>
                                    )}  
                                </>
                            )}  

                        </div>

                        {props.isOff === true ? (
                            null
                        ) : (
                            <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '40px', marginBottom: '60px' }}>
                                <Button 
                                    width='40%'
                                    height='50px'
                                    value='VOLTAR'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => props.history.goBack()}
                                    loading={false}
                                />
                                <Button 
                                    width='40%'
                                    height='50px'
                                    value={props.isSimulation === false ? 'AVANÇAR' : 'ADQUIRA JÁ'}
                                    outline={false}
                                    disabled={disabled}
                                    onClick={() => validaQtd()}
                                    loading={loading}
                                />
                            </div>
                        )}
                    </form>
                </Container>

            </FormContainer>



            <ModalGeneric
                show={showModalConfirm}
                onHide={() => setOpenModalConfirm(false)}
                size='md'
            >
                <>
                    {/* <h3 style={{width: '100%', textAlign: 'center'}}>Modo de pagamento</h3>
                    <p style={{width: '100%', textAlign: 'center'}}>Selecione a forma de pagamento.</p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '40px', marginBottom: '20px' }}>
                        <Button 
                            width='40%'
                            height='40px'
                            value='PIX'
                            outline={true}
                            disabled={false}
                            onClick={() => openPix()}
                            loading={false}
                        />
                        <Button 
                            width='40%'
                            height='40px'
                            value='Boleto'
                            outline={false}
                            disabled={false}
                            onClick={() => nextStep(1)} //Boleto
                            loading={false}
                        />
                    </div> */}
                    


                    <h3 style={{width: '100%', textAlign: 'center'}}>Modo de pagamento</h3>
                    <p style={{width: '100%', textAlign: 'center'}}>Selecione a forma de pagamento.</p>
                    
                    <div style={{
                        width: '40%',
                        margin: 'auto'
                    }}>
                        <Radio
                            options={[
                                {value: 1, label: 'BOLETO'},
                                {value: 2, label: 'PIX'},
                                {value: 3, label: 'CARTÃO DE CRÉDITO'}
                            ]}
                            onChange={(e) => changeMethodPayment(e)}
                        />
                    </div>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '40px', marginBottom: '20px' }}>
                        <Button 
                            width='40%'
                            height='40px'
                            value='CANCELAR'
                            red={true}
                            outline={true}
                            disabled={false}
                            onClick={() => setOpenModalConfirm(false)}
                            loading={false}
                        />

                        <Button 
                            width='40%'
                            height='40px'
                            value='CONTINUAR'
                            outline={false}
                            disabled={false}
                            onClick={() => continuarCompra()}
                            loading={false}
                        />
                    </div>




                </>
            </ModalGeneric>


            <ModalGeneric
                show={showCard}
                onHide={() => setShowCard(false)}
                size='lg'
            >
                <FormCartao 
                    value={{
                        nome_completo_cartao: '',
                        email_cartao: '',
                        cpf_cnpj_cartao: '',
                        cep_cartao: '',
                        numero_endereco_cartao: '',
                        fone_cartao: '',
                        loading: loadingCard
                    }}
                    callback={(e) => comprar(paymentMethodId, e)}
                />
            </ModalGeneric>

            <ModalGeneric
                show={showModalPix}
                onHide={() => setOpenModalPix(false)}
                size='lg'
            >
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    <h3 style={{
                        fontSize: '22px',
                        textAlign: 'center',
                        marginBottom: '30px'
                    }}>Leia o QRcode pelo aplicativo do seu banco</h3>

                    <img src={qrCodePix} alt="qrcode pix" style={{ margin: 'auto', marginBottom: '10px' }}/>
                    
                    <p
                        style={{width: 'auto', height: 'auto', textAlign: 'center', fontSize: 'small'}} 
                    > OU </p>

                    <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
                        <Button 
                            width='35%'
                            value={copiado ? 'Código copiado!' : 'Copiar código PIX'}
                            outline={copiado ? false : true}
                            disabled={false}
                            onClick={() => copyPix(pixCode)}
                            loading={false}
                        />
                    </div>
                    <p style={{width: 'auto', height: 'auto', textAlign: 'center', fontSize: 'small'}}> Após o pagamento, clique em <b>confirmar pagamento</b> para finalizar sua compra. </p>
                    <p style={{width: 'auto', height: 'auto', textAlign: 'center', fontSize: 'small'}}> As receitas serão liberadas após a confirmação do pagamento.</p>
                    <p style={{width: '100%', textAlign: 'center'}}>Caso queira confirmar suas informações de faturamento, <span onClick={() => openFaturamento()} style={{color: '#589BFF', cursor: 'pointer'}}>clique aqui</span>.</p>
                    
                </div>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: '40px', marginBottom: '20px' }}>
                    <Button 
                        width='40%'
                        height='50px'
                        value='CONFIRMAR PAGAMENTO'
                        outline={false}
                        disabled={false}
                        onClick={() => comprar(2, '')}
                        loading={false}
                    />
                </div>
            </ModalGeneric>

            <ModalGeneric
                show={showModalFaturamento}
                onHide={() => setShowModalFaturamento(false)}
                size='md'
            >
                <Faturamento 
                    isModal={true}
                    ok={() => confirmFaturamento()}
                />
            </ModalGeneric>

        </>
    );

}

export default withRouter(ComprarReceita);
