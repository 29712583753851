import React, {component} from 'react';
// import {Modal, Row, Col} from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import Modal from '.';
import { ModalBody, ModalFooter, ModalTitle } from './styles';

class ModalNew extends React.Component {
    constructor(props){
        super(props);
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    updateOk = () => {
      this.props.onHide();
    }



    render(){
        return(
            
        //     <Modal
        //       {...this.props}
        //       size="lg"
        //       aria-labelledby = "contained-modal-title-vcenter"
        //       centered
        //     >
              
        //     <Modal.Header closeButton style={{backgroundColor: " #f8f8f8", border: 'none'}}>
        //     </Modal.Header >

        //     <Modal.Body style={{backgroundColor: " #f8f8f8", borderRadius: '3px'}}>

        //         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>
        //             <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>TERMOS E CONDIÇÕES GERAIS DE USO</p>
        //             <p style={{textAlign: 'justify', fontWeight: '300', lineHeight: '1.5'}}>
        //                 Para garantir a todos os usuários a melhor experiência possível, nós, da AGRORECEITA, desejamos construir com vocês USUÁRIOS uma relação pautada na confiança e no respeito. Por isso, abaixo estão descritos os termos e condições gerais de uso que regulam a utilização da Plataforma AGRORECEITA. Antes de utilizar os Serviços da Plataforma, pedimos que você leia com atenção e compreenda os seguintes termos, pois, ao, aceitá-los, você concordará inteiramente com as disposições aqui contidas ao utilizar a Plataforma mesmo que parcialmente ou à título de teste, consentindo, inclusive, à AGRORECEITA o acesso, a coleta, o uso, o armazenamento, o tratamento e as técnicas de proteção às suas informações, necessárias para a execução das funcionalidades ofertadas pela Plataforma. 
        //                 Em caso de discordância de quaisquer termos aqui apresentados, a utilização da Plataforma deve ser imediatamente interrompida por você.
        //                 <br />
        //                 <br />
        //                 <b style={{fontWeight: '700'}}>INDICE</b> <br />
        //                 <br /><b style={{fontWeight: '700'}}>CAPÍTULO I- CONDIÇÕES GERAIS</b><br />
        //                 <br />1.1. O presente Termo e Condições Gerais de Uso (denominado somente “Termo de Uso”) é um contrato de adesão firmado entre a CONTRATANTE, pessoa física ou jurídica, e a <b>AGRORECEITA TECNOLOGIA AGRICOLA E AMBIENTAL LTDA</b>, pessoa jurídica de direito privado, devidamente inscrita no CNPJ sob o nº 44.098.901/0001-33 e Inscrição Estadual Isenta, situada na Rua Rosa Lotfi de Almeida Bueno nº 155, Vila Nastri II, na cidade de Itapetininga, Estado de São Paulo, CEP 18.206-390, doravante AGRORECEITA.<br />
        //                 <br />1.2. Este Termo de Uso vincula todas as atividades desenvolvidas e serviços oferecidos por meio da Plataforma AGRORECEITA (doravante denominada “Plataforma”), que engloba seus subdomínios, aplicativos, programas e demais extensões, podendo incluir os meios físicos associados, bem como quaisquer materiais impressos e qualquer documentação online ou eletrônica, disponibilizados neste ato por meio do website <a href="https://sistema.agroreceita.com.br/" target="_blank">https://sistema.agroreceita.com.br/</a> e <a href="https://agroreceita.com.br/" target="_blank">https://agroreceita.com.br/</a>.<br />
        //                 <br /><b style={{fontWeight: '700'}}>CAPÍTULO II- DAS DEFINIÇÕES</b><br />
        //                 <br />Ficam definidas as seguintes expressões utilizadas neste termo:
        //                 <br /> <br />2.1. Termo de Uso: significa este instrumento que regerá a relação comercial entre a CONTRATANTE e a AGRORECEITA, especificando as obrigações e os direitos estabelecidos em comum acordo entre as partes.
        //                 <br /> <br />2.2. Adesão e aceite: significa que pelo presente instrumento, a CONTRATANTE e os seus Usuários ficam ciente e concordam que, ao utilizarem a Plataforma, automaticamente, fazem adesão e concordam em se submeterem às condições do presente Termo de Uso.
        //                 <br /> <br />2.3. Usuários: os usuários são todas as pessoas, físicas ou jurídicas, que se utilizam da Plataforma.
        //                 <br /> <br />2.4. Dados do cliente: significa qualquer dado ou material fornecido ou enviado pelo CONTRATANTE para a Plataforma durante a utilização do AGRORECEITA.
        //                 <br /> <br />2.5. Administrador da Plataforma: usuário designado pelo CONTRATANTE, que está autorizado a criar contas de usuário e administrar a Plataforma.
        //                 <br /> <br />2.6. AGRORECEITA: O nome da Plataforma, cujos termos de utilização ora se definem, composta por ferramentas para gerenciamento de receituários agronômicos.
        //                 <br /> <br />2.7. Plataforma AGRORECEITA: é o web site que será acessado através do endereço <a href="https://sistema.agroreceita.com.br/" target="_blank" >https://sistema.agroreceita.com.br/</a> e que possibilita a interação com as funcionalidades da ferramenta.
        //                 <br /> <br />2.8. Receituário agronômico: documento obrigatório para a compra e venda de produtos agroquímicos, de uso exclusivo e sob a responsabilidade da CONTRATANTE.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPITULO III- DA LICENÇA DE USO DA PLATAFORMA</b>
        //                 <br /> <br />3.1. O presente Termo de Uso concede a CONTRATANTE uma licença revogável, não exclusiva e intransferível para uso da Plataforma AGRORECEITA, não permitindo o acesso à outras ferramentas, além daquelas originalmente localizadas na mesma. Em hipótese alguma, a CONTRATANTE terá acesso ao código fonte desta ou das demais Plataformas de propriedade intelectual da AGRORECEITA. A utilização da Plataforma deverá seguir as especificações prevista neste Termo de Uso, portanto a CONTRATANTE não poderá utilizar e nem permitir o seu uso de forma diferente.
        //                 <br /> <br />3.2. Para a utilização e acesso as funcionalidades da Plataforma AGRORECEITA, a CONTRATANTE deverá efetuar o cadastro no site e a adesão do presente Termo de Uso, de acordo com o plano contratado. A partir daí, uma conta será criada e o usuário administrador poderá cadastrar quantos usuários forem necessários.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO IV- DO PREÇO E FORMA DE PAGAMENTO</b>
        //                 <br /> <br />4.1. Para avaliação da Plataforma AGRORECEITA, a CONTRATANTE terá um período de testes gratuitos, contados a partir do preenchimento do cadastro e aceitação do presente Termo de Uso. Portanto, durante este período não haverá cobrança.
        //                 <br /> <br />4.2. Após o período de testes, a CONTRATANTE pagará mensalmente a AGRORECEITA o valor do plano de licenciamento escolhido pelo uso da Plataforma AGRORECEITA e pelos serviços que constituem o objeto deste instrumento.
        //                 <br /> <br />4.3. Caso a CONTRATANTE opte por outro plano de licenciamento, no decorrer da vigência do presente instrumento, os valores serão alterados de acordo com o respectivo plano escolhido.
        //                 <br /> <br />4.4. A AGRORECEITA enviará à CONTRATANTE nota fiscal e boleto bancário no e-mail cadastrado na Plataforma do AGRORECEITA no próximo dia útil após a contratação do plano escolhido e nos demais meses com antecedência mínima de 05 (cinco) dias da data do respectivo vencimento.
        //                 <br /> <br />4.5. Na ocorrência de atraso no pagamento, haverá incidência de multa de 2% (dois por cento) ao mês sob o valor da fatura, além de juros mensais de 1% (um por cento).
        //                 <br /> <br />4.6. Na hipótese de atraso por prazo superior a 15 (quinze) dias corridos, fica desde já acordado que a AGRORECEITA poderá suspender a execução do objeto deste Termo de Uso até que o ocorra o adimplemento do valor em aberto.
        //                 <br /> <br />4.7. Ainda, no caso de atraso superior a 05 (cinco) dias, a cobrança será feita pelo Departamento Jurídico da AGRORECEITA e, além da incidência dos juros e multa acima citados, fica autorizado o protesto do boleto bancário e a cobrança de 20% (vinte por cento) de honorários advocatícios sobre o valor devido, além de custas judiciais ou extrajudiciais, conforme o caso.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO V- DO REAJUSTE DO PREÇO</b>
        //                 <br /> <br />5.1. A assinatura referente ao plano contratado será reajustada automaticamente a cada 12 meses, contados a partir da data da adesão e aceite na Plataforma, pela variação do IGP-M (FGV), IPCA ou Taxa Selic, a critério da AGRORECEITA. Na ausência desses índices serão aplicados quaisquer outros índices inflacionários, determinados pelo Governo Federal, que venham a substitui-los.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO VI- DA VIGÊNCIA</b> 
        //                 <br /> <br />6.1. O presente instrumento terá início na data da sua adesão e aceite no Sistema AGRORECEITA e vigorará por prazo indeterminado, podendo ser rescindido a qualquer tempo por qualquer umas das partes, mediante aviso prévio e expresso no prazo de 30 (trinta) dias, sem que isso implique em pagamento de multa ou indenização.
        //                 <br /> <br /><b>Parágrafo único: </b> Caso não seja respeitado o prazo de 30 (trinta) dias acima citado, o <b>CONTRATANTE </b> deverá efetuar o pagamento equivalente a (1) uma mensalidade.
        //                 <br /> <br />6.2. Este Termo de Uso entra em vigor a partir da data do seu aceite pela CONTRATANTE, podendo ser modificado unilateralmente pela AGRORECEITA, a qualquer momento sem qualquer ônus, mediante comunicado através do próprio site ou envio de mensagem para o e-mail indicado pela CONTRATANTE.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO VII- DA RESCISÃO</b>
        //                 <br /> <br />7.1. A CONTRATANTE poderá, a qualquer momento e sem aviso prévio, solicitar a exclusão de sua conta e encerrar o relacionamento com a AGRORECEITA. O cancelamento da assinatura não permite o reembolso do valor faturado ou já pago. Após essa solicitação o acesso à Plataforma será encerrado.
        //                 <br /> <br />7.2. A rescisão deste instrumento se dará nos seguintes casos: (i) inadimplência; (ii) caso uma das PARTES entre em recuperação judicial ou extrajudicial, falência ou insolvência, ou interrompa suas atividades por qualquer razão; (iii) caso uma das PARTES deixe de cumprir com qualquer das obrigações presente neste instrumento.
        //                 <br /> <br />7.3. Ficará assegurado a AGRORECEITA, independente da forma de rescisão, o pagamento correspondente aos serviços prestados ou disponibilizados.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO VIII- DAS OBRIGAÇÕES DA CONTRATANTE</b>
        //                 <br /> <br />Constituem obrigações e responsabilidades da CONTRATANTE:
        //                 <br /> <br />8.1. Validar o acesso ao sistema contratado, de forma a garantir que o sistema se encontra em perfeitas condições de uso e operação.
        //                 <br /> <br />8.2. Ser responsável por todas as atividades que venha a ocorrer nas suas contas de usuário do sistema contratado, devendo obedecer a todas as exigências legais aplicáveis ao seu uso da Plataforma, incluindo aqueles relativos à transmissão de dados técnicos e pessoais, bem como os da privacidade de dados.
        //                 <br /> <br />8.3. Treinar ou contratar serviços de treinamento, caso julgue necessário, aos usuários e/ou novos funcionários que venham a utilizar o sistema contratado.
        //                 <br /> <br />8.4. Manter as instalações do computador dentro dos padrões estabelecidos pelos seus fornecedores (Software e Hardware) e compatíveis com o sistema contratado, bem como instalação elétrica adequada, aterramento, estabilizador de tensão elétrica e cabeamento em perfeito estado.
        //                 <br /> <br />8.5. Pagamento mensal e pontual do valor da assinatura referente a licença de uso, manutenção e prestação de serviços dentro do prazo de vigência deste Termo de Uso;
        //                 <br /> <br />8.6. Cumprir rigorosamente exigências previstas na legislação que rege a emissão do Receituário Agronômico, bem como demais exigências previstas no Regulamento de Transporte de Produtos Perigosos e suas alterações;
        //                 <br /> <br />8.7. Manter os dados cadastrais, como o nome, cargo, telefone e endereço de e-mail, do administrador da Plataforma, do responsável pelo faturamento e do(s) usuário(s) do sistema sempre atualizados em área específica dentro do próprio sistema. Se as informações fornecidas forem falsas ou fraudulentas, a AGRORECEITA se reserva o direito de encerrar o acesso a Plataforma AGRORECEITA tomando as medidas judiciais cabíveis à situação.
        //                 <br /> <br />8.8. Informar por e-mail (suporte@agroreceita.com.br) ou via sistema, em área específica para tal fim, as alterações em rótulos e bulas, na ficha de emergência e/ou FISPQs do fabricante, tão logo tenha conhecimento;
        //                 <br /> <br />8.9. Enviar à AGRORECEITA todas as informações solicitadas, referente aos produtos do fabricante, importador ou registrante, para o correto cadastramento dos dados de rótulos e bulas, das fichas de emergência e/ou FISPQs, especificando suas necessidades, visando uniformidade, padronização e veracidade das informações, de acordo com as normas vigentes.
        //                 <br /> <br />8.10. Notificar imediatamente a AGRORECEITA sobre a suspeita de violação de segurança ou sobre o uso não autorizado de senhas e contas;
        //                 <br /> <br />8.11 Não se fazer passar por outro usuário e não utilizar informações falsas para obter acesso à Plataforma.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO IX- DAS OBRIGAÇÕES DA AGRORECEITA</b>
        //                 <br /> <br />Constituem obrigações da AGRORECEITA:
        //                 <br /> <br />9.1. Elaborar o layout do Receituário Agronômico condizentes com a legislação que rege a emissão de receitas agronômicas;
        //                 <br /> <br />9.2. Disponibilizar o layout dos Receituários Agronômicos, bem como suas atualizações, desde que solicitadas pelo cliente;
        //                 <br /> <br />9.3. Oferecer serviços de suporte por meio de atendimento via WhatsApp (15) 98165.7739, telefone (15) 3272-9780, e-mail suporte@agroreceita.com.br em dias úteis (de segunda a sexta feira) no horário das 8h30 às 17h30;
        //                 <br /> <br />9.4 -Disponibilizar um S.A.C - Serviço de Atendimento ao Cliente, por meio do e-mail suporte@agroreceita.com.br, visando esclarecer dúvidas, orientações técnicas e troca de informações, que serão respondidas e/ou solucionadas em até 5 (cinco) dias úteis;
        //                 <br /> <br />9.5. Disponibilizar versões atualizadas do sistema;
        //                 <br /> <br />9.6. Fornecer cópia dos códigos fontes do sistema contratado, somente na hipótese de falência da AGRORECEITA, a fim de garantir a continuidade da utilização do sistema.
        //                 <br /> <br />9.7. Prestar os serviços com qualidade e transparência, fornecendo o acesso a Plataforma AGRORECEITA no decorrer da vigência deste Termo de Uso.
        //                 <br /> <br />9.8. Manter o sigilo das informações pessoais da CONTRATANTE, bem como seus registros de acesso, armazenados em ambiente seguro, em conformidade com as disposições da lei nº 12.965/2014.
        //                 <br /> <br />9.9. Os servidores do AGRORECEITA estão hospedados em ambientes seguros, garantindo o acesso a todas as funcionalidades da sua Plataforma.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO X- DAS DECLARAÇÕES DA CONTRATANTE</b> 
        //                 <br /> <br />10.1. A CONTRATANTE declara ser a pessoa autorizada para realizar a contratação da Plataforma AGRORECEITA, tendo lido, compreendido e aceito todas as condições deste Termo de Uso, tendo total entendimento dos direitos e obrigações decorrentes do mesmo. 
        //                 <br /> <br />10.2. A CONTRATANTE declara, também, que está ciente de que todas as operações que realizar na Plataforma AGRORECEITA serão registradas no banco de dados da AGRORECEITA, podendo tais informações ser utilizadas como prova pelas partes, independente do cumprimento de qualquer outra formalidade.
        //                 <br /> <br />10.3. A CONTRATANTE reconhece que a AGRORECEITA, em nenhuma circunstância, será responsável por quaisquer tipos de danos (indiretos, punitivos, incidentais, consequenciais e/ou outros) relacionados ao uso da Plataforma AGRORECEITA , incluindo perda de dados, receita, lucro cessante, uso ou outra vantagem econômica relacionados a esta prestação de serviço, como o uso ou incapacidade de usar o serviço, ou qualquer conteúdo obtido do serviço ou por intermédio dele, sendo este interrupção, imprecisão, erro ou omissão, independentemente de causa no conteúdo, mesmo que a parte da qual se tente reaver os danos, ou de seus licenciadores, tenham sido avisados previamente sobre a possibilidade de tais danos.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CAPÍTULO XI- DA ISENÇÃO DE RESPONSABILIDADE</b>
        //                 <br /> <br />11.1. A AGRORECEITA fica isenta de qualquer responsabilidade, indenização, perdas e danos, lucros cessantes ou prejuízos de qualquer natureza causados a CONTRATANTE e/ou terceiros, em virtude de interrupção do funcionamento da Plataforma AGRORECEITA, em razão de defeito na instalação física (computador-hardware), alteração na configuração do hardware, sistema operacional, softwares concorrentes, inadequação da rede local, manipulação indevida da Plataforma por parte dos usuários e/ou terceiros que tiverem acesso ao sistema, infestação de vírus do equipamento onde o sistema encontra-se em uso ou a ele tenha acesso, bem como qualquer outro evento gerado exclusivamente pela CONTRATANTE.
        //                 <br /> <br />11.2. Fica a AGRORECEITA isenta de qualquer responsabilidade sobre as informações contidas nos Receituários Agronômicos tendo em vista que é de responsabilidade da CONTRATANTE a prescrição correta dos defensivos agrícolas, bem como o envio do conteúdo dos produtos, sempre que encontrar qualquer nova informação ou necessidade de alteração, bem como o envio dos dados técnicos recebidos dos fabricantes, registrantes e/ou importador de produtos cadastrados ou não na Plataforma AGRORECEITA.
        //                 <br /> <br />11.3. A AGRORECEITA não se responsabiliza por atrasos ou outros problemas decorrentes do uso da internet ou de comunicações eletrônicas, que podem gerar falhas de entrega ou quaisquer outros danos.
        //                 <br /> <br />11.4. A AGRORECEITA não se responsabiliza pelo cumprimento dos prazos legais da CONTRATANTE para envio das informações referentes aos Receituários Agronômicos ás Defesas Agropecuárias de seus estados.
        //                 <br /> <br />11.5. A AGRORECEITA não se responsabiliza por invasões de hackers e/ou quaisquer outros ataques que venham a ocorrer no sistema, servidor ou Plataforma, não sendo a responsável por qualquer exclusão, utilização ou divulgação não autorizada das informações. Contudo, para a proteção das informações da CONTRATANTE, armazenadas na Plataforma AGRORECEITA, adota as medidas de segurança adequadas de acordo com os padrões de mercado. A CONTRATANTE, também, reconhece que nenhum sistema, servidor ou Plataforma estão imunes a invasões e/ou ataques de hackers.
        //                 <br /> <br />11.6. A AGRORECEITA não se responsabiliza pelas informações que a CONTRATANTE enviar para Plataforma AGRORECEITA durante a vigência deste Termo de Uso, bem como por qualquer correção, exclusão, alteração, perda ou falha no armazenamento de dados que venham a correr a partir do acesso autorizado, através de login e senha cadastrados na base da Plataforma AGRORECEITA.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CLÁUSULA XII- DA SOLICITAÇÃO DE UM NOVO PRODUTO</b>
        //                 <br /> <br />Em caso de inserção/atualização de um novo produto deverá a CONTRATANTE:
        //                 <br /> <br />12.1. Solicitar à AGRORECEITA com antecedência mínima de 2 (dois) dias úteis, enviando, por e-mail ou através do próprio sistema, todas as informações necessárias para a inserção e/ou atualização;
        //                 <br /> <br />12.2. Informar todos os dados originais do fabricante, registrante titular ou importador do produto, bem como todos os dados necessários para o correto preenchimento do cadastro.
        //                 <br /> <br />12.3. Os produtos serão cadastrados no sistema em lotes, de acordo com a quantidade enviada e no prazo estabelecido entre as partes.
        //                 <br /> <br />12.4. Após a atualização dos produtos, a AGRORECEITA disponibilizará os dados no sistema, conforme as especificações citadas nesta Cláusula e enviadas pela CONTRATANTE.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CLÁUSULA XIII- DAS CUSTOMIZAÇÕES</b>
        //                 <br /> <br />13.1. Em caso de necessidade de customização no sistema, a CONTRATANTE deverá enviar a solicitação por escrito à AGRORECEITA, descrevendo a necessidade para posterior avaliação e aprovação.
        //                 <br /> <br />13.2. Fica a exclusivo critério da AGRORECEITA a aprovação da customização solicitada. 
        //                 <br /> <br />Caso seja aprovada, de comum acordo entre as partes, serão definidos os valores e prazos para a implementação, mediante a formalização de um ANEXO que deverá compor o presente Termo de Uso.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CLÁUSULA XIV- DOS DIREITOS SOBRE A PLATAFORMA - AGRORECEITA</b>
        //                 <br /> <br />Fica desde já acordado que é terminantemente proibido ao CONTRATANTE, seus representantes legais, prepostos, empregados, gerentes, procuradores, sucessores ou terceiros interessados:
        //                 <br /> <br />14.1. Copiar, alterar, sublicenciar, vender, dar em locação, comodato ou garantia, doar, alienar de qualquer forma, transferir, emprestar ou ceder, total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o software, nem permitir seu uso por terceiros, a qualquer título, assim como seus manuais ou quaisquer informações relativas ao mesmo;
        //                 <br /> <br />14.2. Revelar, duplicar, copiar ou reproduzir, autorizar ou permitir o uso ou dar conhecimento a terceiros do material didático relacionado a Plataforma AGRORECEITA, excetuando-se a necessária capacitação de seus funcionários para uso da Plataforma em relação ao objeto deste instrumento, ficando, neste caso, responsável pela utilização indevida destas informações;
        //                 <br /> <br />14.3. Adulterar ou modificar os nomes, marcas, logotipos e demais signos distintivos da AGRORECEITA, existentes nas embalagens, manuais e na Plataforma AGRORECEITA e a que se refere o presente Termo de Uso, bem como não poderão ser objeto de venda, licenciamento, locação, comodato, doação, transferência ou transmissão onerosa ou gratuita, salvo prévia e expressa anuência da AGRORECEITA;
        //                 <br /> <br />14.4. A CONTRATANTE comunicará imediatamente à AGRORECEITA, no prazo de 48 (quarenta e oito) horas, sobre a existência de quaisquer ações judiciais, ou procedimentos extrajudiciais que tiver conhecimento, relativos à propriedade intelectual da Plataforma AGRORECEITA, nomes, marcas, logotipos e demais signos distintivos, deixando sob o exclusivo controle da AGRORECEITA a defesa que se fizer necessária;
        //                 <br /> <br />14.5. A CONTRATANTE reconhece, para os fins de direito, que os códigos fontes, propriedade intelectual e direitos autorais da Plataforma AGRORECEITA pertencem exclusivamente à AGRORECEITA, razão pela qual é vedado à CONTRATANTE promover qualquer tipo de modificação, customização, desenvolvimento, manutenção, suporte, capacitação e consultoria, dentre outros serviços incidentes sobre a Plataforma AGRORECEITA, por conta própria ou mediante empresa distinta da AGRORECEITA.
        //                 <br /> <br />14.6. A CONTRATANTE reconhece que a não observância de quaisquer destas obrigações configurará violação da legislação aplicável ao direito autoral e à utilização da Plataforma AGRORECEITA, submetendo-se, a própria, bem como seus representantes legais, prepostos, empregados, gerentes, procuradores, sucessores e/ou terceiros interessados, às sanções cíveis e penais cabíveis.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>XV- DA MULTA / DA INDENIZAÇÃO</b>
        //                 <br /> <br />15.1. Fica acordado entre as partes que, salvo as hipóteses expressas no presente instrumento, na hipótese de infração de qualquer cláusula do presente Termo de Uso, ficará a parte infratora sujeita ao pagamento de multa correspondente a 20% (vinte por cento), calculada sobre a média mensal do valor dos serviços prestados nos últimos 03 (três) meses, além de ressarcir a parte inocente pelas perdas e danos que ocasionar.
        //                 <br /> <br />15.2. Em caso de ação judicial, arcará também a parte infratora, além da multa acima citada, com honorários advocatícios desde já fixados em 20% (vinte por cento) e demais despesas incorridas pela parte inocente.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CLÁUSULA XVI- DA AUSÊNCIA DE VÍNCULO TRABALHISTA</b>
        //                 <br /> <br />16.1. Fica expressamente estipulado que não está caracterizado, qualquer vínculo empregatício ou de responsabilidade, por parte da AGRORECEITA em relação ao pessoal que a CONTRATANTE empregar direta ou indiretamente, para a utilização e operação do sistema ora contratado.
        //                 <br /> <br />16.2. A CONTRATANTE assume perante a AGRORECEITA a obrigação de excluí-la de imediato de todo e qualquer processo que seja ajuizado por empregado da CONTRATANTE, isentando-a de qualquer ônus ou responsabilidade, considerando a inexistência de qualquer vínculo trabalhista entre a AGRORECEITA e os funcionários da CONTRATANTE.
        //                 <br /> <br />16.3. Em sendo mantida a AGRORECEITA em eventuais reclamações trabalhistas ou ações, administrativas ou judiciais, a CONTRATANTE se obriga, desde logo, e sem qualquer discussão, a ressarcir à AGRORECEITA de todos os valores despendidos e de adiantar pagamentos a serem efetuados em razão de eventuais condenações, no prazo de 48 (quarenta e oito) horas contados da solicitação.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CLÁUSULA XVII- DAS CONDIÇÕES GERAIS</b>
        //                 <br /> <br />17.1. Os direitos da CONTRATANTE estão em conformidade com a Lei de Informática nº 7232/84, Decreto nº 93.295 de 25/09/86, Resolução CONIN 026/86, Lei de Software nº 7646/87 e Decreto nº 96.036 de 12/05/88.
        //                 <br /> <br />17.2. A CONTRATANTE autoriza a AGRORECEITA a divulgar e citar seu nome, inclusive com publicação do logotipo, sem pagamento de qualquer tipo de remuneração, em seus materiais promocionais, através de órgãos de comunicação, em sites, homepages, releases, presentations e quaisquer outros meios publicitários disponíveis, bem como expressar o nome da CONTRATANTE na lista de clientes do sistema.
        //                 <br /> <br />17.3. Em cumprimento a Lei Geral de Proteção de Dados, Lei 13.709/2018, a CONTRATANTE autoriza a AGRORECEITA a enviar emails marketing, com conteúdos relacionados a legislação de produtos químicos e materiais afins. A AGRORECEITA respeita a privacidade de seus clientes, parceiros e fornecedores, não fazendo spam.
        //                 <br /> <br />17.4. As partes assumem o compromisso de sigilo e confidencialidade em relação às informações e documentos que tiver acesso no desempenho dos serviços objeto do presente instrumento.
        //                 <br /> <br />17.5. Qualquer concessão ou tolerância das partes acerca do descumprimento de qualquer cláusula deste Termo de Uso deverá ser visto como mera liberalidade, não podendo ser invocado pela parte contrária como motivo para novação ou modificação do acordado.
        //                 <br /> <br />17.6. Para todos os efeitos, as partes declaram que não existe qualquer vinculação entre as empresas contratantes de ordem societária e administrativa, não se estabelecendo qualquer forma de sociedade, associação, parceria, consórcio ou responsabilidade solidária.
        //                 <br /> <br />17.7. Os direitos e obrigações assumidos neste instrumento não poderão ser objeto de cessão ou transferência, sem o prévio e expresso consentimento da outra parte.
        //                 <br /> <br />17.8. O presente Termo de Uso obriga as partes e seus sucessores, a qualquer título, respeitando as cláusulas que integram este instrumento que poderão ser alteradas somente mediante a celebração de um termo aditivo.
        //                 <br /> <br />17.9. Os casos omissos serão resolvidos de comum acordo, devendo ser elaborado um termo aditivo a este instrumento.
        //                 <br /> <br />
        //                 <b style={{fontWeight: '700'}}>CLÁUSULA XVIII- DO FORO</b>
        //                 <br /> <br />18.1. Fica eleito o Foro da cidade de Itapetininga, Estado de São Paulo, para dirimir quaisquer questões oriundas do presente Termo de Uso, com renúncia a qualquer outro, por mais privilegiado que seja.
        //                 <br /><br />
        //             </p>
        //             <p style={{textAlign: 'center', fontWeight: '300'}}>
        //                 <b style={{fontWeight: '700'}}>AGRORECEITA TECNOLOGIA AGRICOLA E AMBIENTAL LTDA</b>
        //                 <br />Última atualização 01/06/2022
        //             </p>
        //         </div>


        //         <div className="btns" style={{ display: 'flex' }}>
        //             {this.props.btnOk == true ? (
        //                 <Button
        //                 style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
        //                 color="#00b43f"
        //                 onClick={() => this.updateOk() }
        //                 >
        //                 ENTENDI
        //                 </Button>
        //             ):(null)}
        //         </div>

        //     </Modal.Body>
        //   </Modal>



        <Modal
            {...this.props}
            align='center' // center, end, start
            justify='center' // center, end, start
            responsive
            width='60%' 
        >
            <ModalTitle> TERMOS E CONDIÇÕES GERAIS DE USO </ModalTitle>

            <ModalBody>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', paddingLeft: '40px', paddingRight: '40px'}}>
                    {/* <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>TERMOS E CONDIÇÕES GERAIS DE USO</p> */}
                    <p style={{textAlign: 'justify', fontWeight: '300', lineHeight: '1.5'}}>
                        Para garantir a todos os usuários a melhor experiência possível, nós, da AGRORECEITA, desejamos construir com vocês USUÁRIOS uma relação pautada na confiança e no respeito. Por isso, abaixo estão descritos os termos e condições gerais de uso que regulam a utilização da Plataforma AGRORECEITA. Antes de utilizar os Serviços da Plataforma, pedimos que você leia com atenção e compreenda os seguintes termos, pois, ao, aceitá-los, você concordará inteiramente com as disposições aqui contidas ao utilizar a Plataforma mesmo que parcialmente ou à título de teste, consentindo, inclusive, à AGRORECEITA o acesso, a coleta, o uso, o armazenamento, o tratamento e as técnicas de proteção às suas informações, necessárias para a execução das funcionalidades ofertadas pela Plataforma. 
                        Em caso de discordância de quaisquer termos aqui apresentados, a utilização da Plataforma deve ser imediatamente interrompida por você.
                        <br />
                        <br />
                        <b style={{fontWeight: '700'}}>INDICE</b> <br />
                        <br /><b style={{fontWeight: '700'}}>CAPÍTULO I- CONDIÇÕES GERAIS</b><br />
                        <br />1.1. O presente Termo e Condições Gerais de Uso (denominado somente “Termo de Uso”) é um contrato de adesão firmado entre a CONTRATANTE, pessoa física ou jurídica, e a <b>AGRORECEITA TECNOLOGIA AGRICOLA E AMBIENTAL LTDA</b>, pessoa jurídica de direito privado, devidamente inscrita no CNPJ sob o nº 44.098.901/0001-33 e Inscrição Estadual Isenta, situada na Rua Rosa Lotfi de Almeida Bueno nº 155, Vila Nastri II, na cidade de Itapetininga, Estado de São Paulo, CEP 18.206-390, doravante AGRORECEITA.<br />
                        <br />1.2. Este Termo de Uso vincula todas as atividades desenvolvidas e serviços oferecidos por meio da Plataforma AGRORECEITA (doravante denominada “Plataforma”), que engloba seus subdomínios, aplicativos, programas e demais extensões, podendo incluir os meios físicos associados, bem como quaisquer materiais impressos e qualquer documentação online ou eletrônica, disponibilizados neste ato por meio do website <a href="https://sistema.agroreceita.com.br/" target="_blank">https://sistema.agroreceita.com.br/</a> e <a href="https://agroreceita.com.br/" target="_blank">https://agroreceita.com.br/</a>.<br />
                        <br /><b style={{fontWeight: '700'}}>CAPÍTULO II- DAS DEFINIÇÕES</b><br />
                        <br />Ficam definidas as seguintes expressões utilizadas neste termo:
                        <br /> <br />2.1. Termo de Uso: significa este instrumento que regerá a relação comercial entre a CONTRATANTE e a AGRORECEITA, especificando as obrigações e os direitos estabelecidos em comum acordo entre as partes.
                        <br /> <br />2.2. Adesão e aceite: significa que pelo presente instrumento, a CONTRATANTE e os seus Usuários ficam ciente e concordam que, ao utilizarem a Plataforma, automaticamente, fazem adesão e concordam em se submeterem às condições do presente Termo de Uso.
                        <br /> <br />2.3. Usuários: os usuários são todas as pessoas, físicas ou jurídicas, que se utilizam da Plataforma.
                        <br /> <br />2.4. Dados do cliente: significa qualquer dado ou material fornecido ou enviado pelo CONTRATANTE para a Plataforma durante a utilização do AGRORECEITA.
                        <br /> <br />2.5. Administrador da Plataforma: usuário designado pelo CONTRATANTE, que está autorizado a criar contas de usuário e administrar a Plataforma.
                        <br /> <br />2.6. AGRORECEITA: O nome da Plataforma, cujos termos de utilização ora se definem, composta por ferramentas para gerenciamento de receituários agronômicos.
                        <br /> <br />2.7. Plataforma AGRORECEITA: é o web site que será acessado através do endereço <a href="https://sistema.agroreceita.com.br/" target="_blank" >https://sistema.agroreceita.com.br/</a> e que possibilita a interação com as funcionalidades da ferramenta.
                        <br /> <br />2.8. Receituário agronômico: documento obrigatório para a compra e venda de produtos agroquímicos, de uso exclusivo e sob a responsabilidade da CONTRATANTE.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPITULO III- DA LICENÇA DE USO DA PLATAFORMA</b>
                        <br /> <br />3.1. O presente Termo de Uso concede a CONTRATANTE uma licença revogável, não exclusiva e intransferível para uso da Plataforma AGRORECEITA, não permitindo o acesso à outras ferramentas, além daquelas originalmente localizadas na mesma. Em hipótese alguma, a CONTRATANTE terá acesso ao código fonte desta ou das demais Plataformas de propriedade intelectual da AGRORECEITA. A utilização da Plataforma deverá seguir as especificações prevista neste Termo de Uso, portanto a CONTRATANTE não poderá utilizar e nem permitir o seu uso de forma diferente.
                        <br /> <br />3.2. Para a utilização e acesso as funcionalidades da Plataforma AGRORECEITA, a CONTRATANTE deverá efetuar o cadastro no site e a adesão do presente Termo de Uso, de acordo com o plano contratado. A partir daí, uma conta será criada e o usuário administrador poderá cadastrar quantos usuários forem necessários.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO IV- DO PREÇO E FORMA DE PAGAMENTO</b>
                        <br /> <br />4.1. Para avaliação da Plataforma AGRORECEITA, a CONTRATANTE terá um período de testes gratuitos, contados a partir do preenchimento do cadastro e aceitação do presente Termo de Uso. Portanto, durante este período não haverá cobrança.
                        <br /> <br />4.2. Após o período de testes, a CONTRATANTE pagará mensalmente a AGRORECEITA o valor do plano de licenciamento escolhido pelo uso da Plataforma AGRORECEITA e pelos serviços que constituem o objeto deste instrumento.
                        <br /> <br />4.3. Caso a CONTRATANTE opte por outro plano de licenciamento, no decorrer da vigência do presente instrumento, os valores serão alterados de acordo com o respectivo plano escolhido.
                        <br /> <br />4.4. A AGRORECEITA enviará à CONTRATANTE nota fiscal e boleto bancário no e-mail cadastrado na Plataforma do AGRORECEITA no próximo dia útil após a contratação do plano escolhido e nos demais meses com antecedência mínima de 05 (cinco) dias da data do respectivo vencimento.
                        <br /> <br />4.5. Na ocorrência de atraso no pagamento, haverá incidência de multa de 2% (dois por cento) ao mês sob o valor da fatura, além de juros mensais de 1% (um por cento).
                        <br /> <br />4.6. Na hipótese de atraso por prazo superior a 15 (quinze) dias corridos, fica desde já acordado que a AGRORECEITA poderá suspender a execução do objeto deste Termo de Uso até que o ocorra o adimplemento do valor em aberto.
                        <br /> <br />4.7. Ainda, no caso de atraso superior a 05 (cinco) dias, a cobrança será feita pelo Departamento Jurídico da AGRORECEITA e, além da incidência dos juros e multa acima citados, fica autorizado o protesto do boleto bancário e a cobrança de 20% (vinte por cento) de honorários advocatícios sobre o valor devido, além de custas judiciais ou extrajudiciais, conforme o caso.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO V- DO REAJUSTE DO PREÇO</b>
                        <br /> <br />5.1. A assinatura referente ao plano contratado será reajustada automaticamente a cada 12 meses, contados a partir da data da adesão e aceite na Plataforma, pela variação do IGP-M (FGV), IPCA ou Taxa Selic, a critério da AGRORECEITA. Na ausência desses índices serão aplicados quaisquer outros índices inflacionários, determinados pelo Governo Federal, que venham a substitui-los.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO VI- DA VIGÊNCIA</b> 
                        <br /> <br />6.1. O presente instrumento terá início na data da sua adesão e aceite no Sistema AGRORECEITA e vigorará por prazo indeterminado, podendo ser rescindido a qualquer tempo por qualquer umas das partes, mediante aviso prévio e expresso no prazo de 30 (trinta) dias, sem que isso implique em pagamento de multa ou indenização.
                        <br /> <br /><b>Parágrafo único: </b> Caso não seja respeitado o prazo de 30 (trinta) dias acima citado, o <b>CONTRATANTE </b> deverá efetuar o pagamento equivalente a (1) uma mensalidade.
                        <br /> <br />6.2. Este Termo de Uso entra em vigor a partir da data do seu aceite pela CONTRATANTE, podendo ser modificado unilateralmente pela AGRORECEITA, a qualquer momento sem qualquer ônus, mediante comunicado através do próprio site ou envio de mensagem para o e-mail indicado pela CONTRATANTE.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO VII- DA RESCISÃO</b>
                        <br /> <br />7.1. A CONTRATANTE poderá, a qualquer momento e sem aviso prévio, solicitar a exclusão de sua conta e encerrar o relacionamento com a AGRORECEITA. O cancelamento da assinatura não permite o reembolso do valor faturado ou já pago. Após essa solicitação o acesso à Plataforma será encerrado.
                        <br /> <br />7.2. A rescisão deste instrumento se dará nos seguintes casos: (i) inadimplência; (ii) caso uma das PARTES entre em recuperação judicial ou extrajudicial, falência ou insolvência, ou interrompa suas atividades por qualquer razão; (iii) caso uma das PARTES deixe de cumprir com qualquer das obrigações presente neste instrumento.
                        <br /> <br />7.3. Ficará assegurado a AGRORECEITA, independente da forma de rescisão, o pagamento correspondente aos serviços prestados ou disponibilizados.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO VIII- DAS OBRIGAÇÕES DA CONTRATANTE</b>
                        <br /> <br />Constituem obrigações e responsabilidades da CONTRATANTE:
                        <br /> <br />8.1. Validar o acesso ao sistema contratado, de forma a garantir que o sistema se encontra em perfeitas condições de uso e operação.
                        <br /> <br />8.2. Ser responsável por todas as atividades que venha a ocorrer nas suas contas de usuário do sistema contratado, devendo obedecer a todas as exigências legais aplicáveis ao seu uso da Plataforma, incluindo aqueles relativos à transmissão de dados técnicos e pessoais, bem como os da privacidade de dados.
                        <br /> <br />8.3. Treinar ou contratar serviços de treinamento, caso julgue necessário, aos usuários e/ou novos funcionários que venham a utilizar o sistema contratado.
                        <br /> <br />8.4. Manter as instalações do computador dentro dos padrões estabelecidos pelos seus fornecedores (Software e Hardware) e compatíveis com o sistema contratado, bem como instalação elétrica adequada, aterramento, estabilizador de tensão elétrica e cabeamento em perfeito estado.
                        <br /> <br />8.5. Pagamento mensal e pontual do valor da assinatura referente a licença de uso, manutenção e prestação de serviços dentro do prazo de vigência deste Termo de Uso;
                        <br /> <br />8.6. Cumprir rigorosamente exigências previstas na legislação que rege a emissão do Receituário Agronômico, bem como demais exigências previstas no Regulamento de Transporte de Produtos Perigosos e suas alterações;
                        <br /> <br />8.7. Manter os dados cadastrais, como o nome, cargo, telefone e endereço de e-mail, do administrador da Plataforma, do responsável pelo faturamento e do(s) usuário(s) do sistema sempre atualizados em área específica dentro do próprio sistema. Se as informações fornecidas forem falsas ou fraudulentas, a AGRORECEITA se reserva o direito de encerrar o acesso a Plataforma AGRORECEITA tomando as medidas judiciais cabíveis à situação.
                        <br /> <br />8.8. Informar por e-mail (suporte@agroreceita.com.br) ou via sistema, em área específica para tal fim, as alterações em rótulos e bulas, na ficha de emergência e/ou FISPQs do fabricante, tão logo tenha conhecimento;
                        <br /> <br />8.9. Enviar à AGRORECEITA todas as informações solicitadas, referente aos produtos do fabricante, importador ou registrante, para o correto cadastramento dos dados de rótulos e bulas, das fichas de emergência e/ou FISPQs, especificando suas necessidades, visando uniformidade, padronização e veracidade das informações, de acordo com as normas vigentes.
                        <br /> <br />8.10. Notificar imediatamente a AGRORECEITA sobre a suspeita de violação de segurança ou sobre o uso não autorizado de senhas e contas;
                        <br /> <br />8.11 Não se fazer passar por outro usuário e não utilizar informações falsas para obter acesso à Plataforma.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO IX- DAS OBRIGAÇÕES DA AGRORECEITA</b>
                        <br /> <br />Constituem obrigações da AGRORECEITA:
                        <br /> <br />9.1. Elaborar o layout do Receituário Agronômico condizentes com a legislação que rege a emissão de receitas agronômicas;
                        <br /> <br />9.2. Disponibilizar o layout dos Receituários Agronômicos, bem como suas atualizações, desde que solicitadas pelo cliente;
                        <br /> <br />9.3. Oferecer serviços de suporte por meio de atendimento via WhatsApp (15) 98165.7739, telefone (15) 3272-9780, e-mail suporte@agroreceita.com.br em dias úteis (de segunda a sexta feira) no horário das 8h30 às 17h30;
                        <br /> <br />9.4 -Disponibilizar um S.A.C - Serviço de Atendimento ao Cliente, por meio do e-mail suporte@agroreceita.com.br, visando esclarecer dúvidas, orientações técnicas e troca de informações, que serão respondidas e/ou solucionadas em até 5 (cinco) dias úteis;
                        <br /> <br />9.5. Disponibilizar versões atualizadas do sistema;
                        <br /> <br />9.6. Fornecer cópia dos códigos fontes do sistema contratado, somente na hipótese de falência da AGRORECEITA, a fim de garantir a continuidade da utilização do sistema.
                        <br /> <br />9.7. Prestar os serviços com qualidade e transparência, fornecendo o acesso a Plataforma AGRORECEITA no decorrer da vigência deste Termo de Uso.
                        <br /> <br />9.8. Manter o sigilo das informações pessoais da CONTRATANTE, bem como seus registros de acesso, armazenados em ambiente seguro, em conformidade com as disposições da lei nº 12.965/2014.
                        <br /> <br />9.9. Os servidores do AGRORECEITA estão hospedados em ambientes seguros, garantindo o acesso a todas as funcionalidades da sua Plataforma.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO X- DAS DECLARAÇÕES DA CONTRATANTE</b> 
                        <br /> <br />10.1. A CONTRATANTE declara ser a pessoa autorizada para realizar a contratação da Plataforma AGRORECEITA, tendo lido, compreendido e aceito todas as condições deste Termo de Uso, tendo total entendimento dos direitos e obrigações decorrentes do mesmo. 
                        <br /> <br />10.2. A CONTRATANTE declara, também, que está ciente de que todas as operações que realizar na Plataforma AGRORECEITA serão registradas no banco de dados da AGRORECEITA, podendo tais informações ser utilizadas como prova pelas partes, independente do cumprimento de qualquer outra formalidade.
                        <br /> <br />10.3. A CONTRATANTE reconhece que a AGRORECEITA, em nenhuma circunstância, será responsável por quaisquer tipos de danos (indiretos, punitivos, incidentais, consequenciais e/ou outros) relacionados ao uso da Plataforma AGRORECEITA , incluindo perda de dados, receita, lucro cessante, uso ou outra vantagem econômica relacionados a esta prestação de serviço, como o uso ou incapacidade de usar o serviço, ou qualquer conteúdo obtido do serviço ou por intermédio dele, sendo este interrupção, imprecisão, erro ou omissão, independentemente de causa no conteúdo, mesmo que a parte da qual se tente reaver os danos, ou de seus licenciadores, tenham sido avisados previamente sobre a possibilidade de tais danos.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CAPÍTULO XI- DA ISENÇÃO DE RESPONSABILIDADE</b>
                        <br /> <br />11.1. A AGRORECEITA fica isenta de qualquer responsabilidade, indenização, perdas e danos, lucros cessantes ou prejuízos de qualquer natureza causados a CONTRATANTE e/ou terceiros, em virtude de interrupção do funcionamento da Plataforma AGRORECEITA, em razão de defeito na instalação física (computador-hardware), alteração na configuração do hardware, sistema operacional, softwares concorrentes, inadequação da rede local, manipulação indevida da Plataforma por parte dos usuários e/ou terceiros que tiverem acesso ao sistema, infestação de vírus do equipamento onde o sistema encontra-se em uso ou a ele tenha acesso, bem como qualquer outro evento gerado exclusivamente pela CONTRATANTE.
                        <br /> <br />11.2. Fica a AGRORECEITA isenta de qualquer responsabilidade sobre as informações contidas nos Receituários Agronômicos tendo em vista que é de responsabilidade da CONTRATANTE a prescrição correta dos defensivos agrícolas, bem como o envio do conteúdo dos produtos, sempre que encontrar qualquer nova informação ou necessidade de alteração, bem como o envio dos dados técnicos recebidos dos fabricantes, registrantes e/ou importador de produtos cadastrados ou não na Plataforma AGRORECEITA.
                        <br /> <br />11.3. A AGRORECEITA não se responsabiliza por atrasos ou outros problemas decorrentes do uso da internet ou de comunicações eletrônicas, que podem gerar falhas de entrega ou quaisquer outros danos.
                        <br /> <br />11.4. A AGRORECEITA não se responsabiliza pelo cumprimento dos prazos legais da CONTRATANTE para envio das informações referentes aos Receituários Agronômicos ás Defesas Agropecuárias de seus estados.
                        <br /> <br />11.5. A AGRORECEITA não se responsabiliza por invasões de hackers e/ou quaisquer outros ataques que venham a ocorrer no sistema, servidor ou Plataforma, não sendo a responsável por qualquer exclusão, utilização ou divulgação não autorizada das informações. Contudo, para a proteção das informações da CONTRATANTE, armazenadas na Plataforma AGRORECEITA, adota as medidas de segurança adequadas de acordo com os padrões de mercado. A CONTRATANTE, também, reconhece que nenhum sistema, servidor ou Plataforma estão imunes a invasões e/ou ataques de hackers.
                        <br /> <br />11.6. A AGRORECEITA não se responsabiliza pelas informações que a CONTRATANTE enviar para Plataforma AGRORECEITA durante a vigência deste Termo de Uso, bem como por qualquer correção, exclusão, alteração, perda ou falha no armazenamento de dados que venham a correr a partir do acesso autorizado, através de login e senha cadastrados na base da Plataforma AGRORECEITA.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CLÁUSULA XII- DA SOLICITAÇÃO DE UM NOVO PRODUTO</b>
                        <br /> <br />Em caso de inserção/atualização de um novo produto deverá a CONTRATANTE:
                        <br /> <br />12.1. Solicitar à AGRORECEITA com antecedência mínima de 2 (dois) dias úteis, enviando, por e-mail ou através do próprio sistema, todas as informações necessárias para a inserção e/ou atualização;
                        <br /> <br />12.2. Informar todos os dados originais do fabricante, registrante titular ou importador do produto, bem como todos os dados necessários para o correto preenchimento do cadastro.
                        <br /> <br />12.3. Os produtos serão cadastrados no sistema em lotes, de acordo com a quantidade enviada e no prazo estabelecido entre as partes.
                        <br /> <br />12.4. Após a atualização dos produtos, a AGRORECEITA disponibilizará os dados no sistema, conforme as especificações citadas nesta Cláusula e enviadas pela CONTRATANTE.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CLÁUSULA XIII- DAS CUSTOMIZAÇÕES</b>
                        <br /> <br />13.1. Em caso de necessidade de customização no sistema, a CONTRATANTE deverá enviar a solicitação por escrito à AGRORECEITA, descrevendo a necessidade para posterior avaliação e aprovação.
                        <br /> <br />13.2. Fica a exclusivo critério da AGRORECEITA a aprovação da customização solicitada. 
                        <br /> <br />Caso seja aprovada, de comum acordo entre as partes, serão definidos os valores e prazos para a implementação, mediante a formalização de um ANEXO que deverá compor o presente Termo de Uso.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CLÁUSULA XIV- DOS DIREITOS SOBRE A PLATAFORMA - AGRORECEITA</b>
                        <br /> <br />Fica desde já acordado que é terminantemente proibido ao CONTRATANTE, seus representantes legais, prepostos, empregados, gerentes, procuradores, sucessores ou terceiros interessados:
                        <br /> <br />14.1. Copiar, alterar, sublicenciar, vender, dar em locação, comodato ou garantia, doar, alienar de qualquer forma, transferir, emprestar ou ceder, total ou parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, o software, nem permitir seu uso por terceiros, a qualquer título, assim como seus manuais ou quaisquer informações relativas ao mesmo;
                        <br /> <br />14.2. Revelar, duplicar, copiar ou reproduzir, autorizar ou permitir o uso ou dar conhecimento a terceiros do material didático relacionado a Plataforma AGRORECEITA, excetuando-se a necessária capacitação de seus funcionários para uso da Plataforma em relação ao objeto deste instrumento, ficando, neste caso, responsável pela utilização indevida destas informações;
                        <br /> <br />14.3. Adulterar ou modificar os nomes, marcas, logotipos e demais signos distintivos da AGRORECEITA, existentes nas embalagens, manuais e na Plataforma AGRORECEITA e a que se refere o presente Termo de Uso, bem como não poderão ser objeto de venda, licenciamento, locação, comodato, doação, transferência ou transmissão onerosa ou gratuita, salvo prévia e expressa anuência da AGRORECEITA;
                        <br /> <br />14.4. A CONTRATANTE comunicará imediatamente à AGRORECEITA, no prazo de 48 (quarenta e oito) horas, sobre a existência de quaisquer ações judiciais, ou procedimentos extrajudiciais que tiver conhecimento, relativos à propriedade intelectual da Plataforma AGRORECEITA, nomes, marcas, logotipos e demais signos distintivos, deixando sob o exclusivo controle da AGRORECEITA a defesa que se fizer necessária;
                        <br /> <br />14.5. A CONTRATANTE reconhece, para os fins de direito, que os códigos fontes, propriedade intelectual e direitos autorais da Plataforma AGRORECEITA pertencem exclusivamente à AGRORECEITA, razão pela qual é vedado à CONTRATANTE promover qualquer tipo de modificação, customização, desenvolvimento, manutenção, suporte, capacitação e consultoria, dentre outros serviços incidentes sobre a Plataforma AGRORECEITA, por conta própria ou mediante empresa distinta da AGRORECEITA.
                        <br /> <br />14.6. A CONTRATANTE reconhece que a não observância de quaisquer destas obrigações configurará violação da legislação aplicável ao direito autoral e à utilização da Plataforma AGRORECEITA, submetendo-se, a própria, bem como seus representantes legais, prepostos, empregados, gerentes, procuradores, sucessores e/ou terceiros interessados, às sanções cíveis e penais cabíveis.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>XV- DA MULTA / DA INDENIZAÇÃO</b>
                        <br /> <br />15.1. Fica acordado entre as partes que, salvo as hipóteses expressas no presente instrumento, na hipótese de infração de qualquer cláusula do presente Termo de Uso, ficará a parte infratora sujeita ao pagamento de multa correspondente a 20% (vinte por cento), calculada sobre a média mensal do valor dos serviços prestados nos últimos 03 (três) meses, além de ressarcir a parte inocente pelas perdas e danos que ocasionar.
                        <br /> <br />15.2. Em caso de ação judicial, arcará também a parte infratora, além da multa acima citada, com honorários advocatícios desde já fixados em 20% (vinte por cento) e demais despesas incorridas pela parte inocente.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CLÁUSULA XVI- DA AUSÊNCIA DE VÍNCULO TRABALHISTA</b>
                        <br /> <br />16.1. Fica expressamente estipulado que não está caracterizado, qualquer vínculo empregatício ou de responsabilidade, por parte da AGRORECEITA em relação ao pessoal que a CONTRATANTE empregar direta ou indiretamente, para a utilização e operação do sistema ora contratado.
                        <br /> <br />16.2. A CONTRATANTE assume perante a AGRORECEITA a obrigação de excluí-la de imediato de todo e qualquer processo que seja ajuizado por empregado da CONTRATANTE, isentando-a de qualquer ônus ou responsabilidade, considerando a inexistência de qualquer vínculo trabalhista entre a AGRORECEITA e os funcionários da CONTRATANTE.
                        <br /> <br />16.3. Em sendo mantida a AGRORECEITA em eventuais reclamações trabalhistas ou ações, administrativas ou judiciais, a CONTRATANTE se obriga, desde logo, e sem qualquer discussão, a ressarcir à AGRORECEITA de todos os valores despendidos e de adiantar pagamentos a serem efetuados em razão de eventuais condenações, no prazo de 48 (quarenta e oito) horas contados da solicitação.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CLÁUSULA XVII- DAS CONDIÇÕES GERAIS</b>
                        <br /> <br />17.1. Os direitos da CONTRATANTE estão em conformidade com a Lei de Informática nº 7232/84, Decreto nº 93.295 de 25/09/86, Resolução CONIN 026/86, Lei de Software nº 7646/87 e Decreto nº 96.036 de 12/05/88.
                        <br /> <br />17.2. A CONTRATANTE autoriza a AGRORECEITA a divulgar e citar seu nome, inclusive com publicação do logotipo, sem pagamento de qualquer tipo de remuneração, em seus materiais promocionais, através de órgãos de comunicação, em sites, homepages, releases, presentations e quaisquer outros meios publicitários disponíveis, bem como expressar o nome da CONTRATANTE na lista de clientes do sistema.
                        <br /> <br />17.3. Em cumprimento a Lei Geral de Proteção de Dados, Lei 13.709/2018, a CONTRATANTE autoriza a AGRORECEITA a enviar emails marketing, com conteúdos relacionados a legislação de produtos químicos e materiais afins. A AGRORECEITA respeita a privacidade de seus clientes, parceiros e fornecedores, não fazendo spam.
                        <br /> <br />17.4. As partes assumem o compromisso de sigilo e confidencialidade em relação às informações e documentos que tiver acesso no desempenho dos serviços objeto do presente instrumento.
                        <br /> <br />17.5. Qualquer concessão ou tolerância das partes acerca do descumprimento de qualquer cláusula deste Termo de Uso deverá ser visto como mera liberalidade, não podendo ser invocado pela parte contrária como motivo para novação ou modificação do acordado.
                        <br /> <br />17.6. Para todos os efeitos, as partes declaram que não existe qualquer vinculação entre as empresas contratantes de ordem societária e administrativa, não se estabelecendo qualquer forma de sociedade, associação, parceria, consórcio ou responsabilidade solidária.
                        <br /> <br />17.7. Os direitos e obrigações assumidos neste instrumento não poderão ser objeto de cessão ou transferência, sem o prévio e expresso consentimento da outra parte.
                        <br /> <br />17.8. O presente Termo de Uso obriga as partes e seus sucessores, a qualquer título, respeitando as cláusulas que integram este instrumento que poderão ser alteradas somente mediante a celebração de um termo aditivo.
                        <br /> <br />17.9. Os casos omissos serão resolvidos de comum acordo, devendo ser elaborado um termo aditivo a este instrumento.
                        <br /> <br />
                        <b style={{fontWeight: '700'}}>CLÁUSULA XVIII- DO FORO</b>
                        <br /> <br />18.1. Fica eleito o Foro da cidade de Itapetininga, Estado de São Paulo, para dirimir quaisquer questões oriundas do presente Termo de Uso, com renúncia a qualquer outro, por mais privilegiado que seja.
                        <br /><br />
                    </p>
                    <p style={{textAlign: 'center', fontWeight: '300'}}>
                        <b style={{fontWeight: '700'}}>AGRORECEITA TECNOLOGIA AGRICOLA E AMBIENTAL LTDA</b>
                        <br />Última atualização 01/06/2022
                    </p>
                </div>
            </ModalBody>

            <ModalFooter>
                {this.props.btnOk == true ? (
                    <Button
                        style={{ outline:0, padding: "10px", width:"100px", margin:"auto", marginTop: '15px'}}
                        color="#00b43f"
                        onClick={() => this.updateOk() }
                    >
                    ENTENDI
                    </Button>
                ):(null)}
            </ModalFooter>
        </Modal>

        );
    }
}
export default ModalNew;