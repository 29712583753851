import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: ${props => (props.width ? props.width : '400px')};
  width: ${props => (props.width ? props.width : '100%')};
  height: 90px;

  label {
    padding-left: ${props => (props.isCompact ? '30px' : '15px')};
    text-align: left;
    font-size: 11px;
    font-weight: bold;
    color: #8a92a2;
    position: ${props => (props.isCompact ? 'relative' : 'initial')};
    margin-bottom: ${props => (props.isCompact ? '7px' : '28px')};
    top: ${props => (props.isCompact ? '6px' : '0')};
    z-index: ${props => (props.isCompact ? '1' : '0')};
  }

  .select {
    .css-ygspie-control {
      background-color: #f3f3f5;
      border: none !important;
      &:hover {
        border-color: #f3f3f5 !important;
        box-shadow: 0 0 0 1px #f3f3f5;
      }
    }

    .css-1ededpl-control {
      background-color: #f3f3f5;
    }
    .css-26l3qy-menu {
      z-index: 2 !important;
      .css-1ml51p6-MenuList {
        z-index: 2 !important;
      }
    }
    &.error {
      border: 1px solid #f01;
      border-radius: 25px;
    }
  }
  
  .css-8m1df9-placeholder{
    margin-left: ${props => (props.state ? "30px" : "")};
  }

  .css-1uccc91-singleValue{
    margin-left: ${props => (props.state ? "30px" : "")};
  }

  .css-107lb6w-singleValue{
    margin-left: ${props => (props.state ? "30px" : "")};
  }

  .pin-estado{
    width: 20px;
    position: relative;
    margin-top: -41px;
    margin-bottom: 30px;
    left: 15px;

  }

  option{
    background-color: red;
  }
  
`;

export const SelectContainerV2 = styled.div`
    width: 100%;
    height: ${props => props.isOpen ? 'auto' : props.yesNo ? '70px' : '70px'};
    margin-bottom:  ${props => props.marginBottom ? props.marginBottom : '25px'};

    .container-input {
      display: flex;
      flex-direction: row-reverse;
      flex-wrap: wrap;
    }

    input {
      background-color: red;
      padding-right: 50px;
      &::placeholder {
        color: ${props => props.erro ? '#ff5252' : props.disabled || props.loading ? '#cfd5df' : '#8a92a2'};
        opacity: 1; /* Firefox */
      }
    }

    .help-buttom{
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: -24px;
      margin-right: -40px;

      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #589bff;
      cursor: pointer
    }

    .addnew {
      display: ${props => props.addNew ? 'flex' : 'none'};
      position: absolute;
      left: 101%;
    }

    .select {
      width: ${props => props.addNew ? '101%' : '100%'};
      /* max-width: 279px; */
    }

    @media only screen and (max-width: 780px) {
      margin-bottom: ${props => props.yesNo && props. disabled === false ? '58px' : props.marginBottom ? props.marginBottom : '25px'};
    }

    @media only screen and (max-width: 540px) {
      height: ${props => props.isOpen ? 'auto' : props.yesNo ? props.disabled === false ? '100px' : '70px' : '70px'};
      margin-bottom: ${props => props.yesNo && props. disabled === false ? '32px' : props.marginBottom ? props.marginBottom : '25px'};
      
      input {
        top: ${props => props.yesNo ? props.disabled === false ? '-6px' : '0px' : '0px'} !important;
      }
    }

    @media only screen and (max-width: 500px) {
      width: ${props => props.addNew ? '80%' : '100%'};
      .select {
        width: ${props => props.addNew ? '100%' : '100%'};
      }
    }
`;

export const Label = styled.label`
  width: auto;
  height: 3px;

  background-color: #FFF;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 0px;
  margin-left: 25px;
  margin-top: ${props => props.isDate ? '-2px' : !props.existValue ? !props.isFocus ? props.buttonAddNew ? '24px' : '24px' : props.buttonAddNew ? '-2px' : '-2px' :  props.buttonAddNew ? '-2px' : '-2px'};

  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  position: absolute;
  
  color: ${props => props.erro ? '#ff5252' : '#8a92a2'};
  opacity: ${props => props.isDisabled ? '0.5' : '1'};
  z-index: 1;
  
  transition: all 0.3s;

  border-radius: 65px;

  &:hover {
    cursor: ${props => !props.existValue ? !props.isFocus ? !props.isDisabled ? 'text' : 'context-menu' : 'context-menu' : 'context-menu'};
  }



  @media only screen and (max-width: 540px){
    margin-top: ${props => props.isDate ? '-2px' : !props.existValue ? !props.isFocus ? props.buttonAddNew ? '24px' : '24px' : props.buttonAddNew ? '-2px' : props.isYesNo ? '-8px' : '-2px' :  props.buttonAddNew ? '-2px' : props.isYesNo ? '-8px' : '-2px'};
  }

`;

export const Arrow = styled.div`
  position: relative;
  font-family: Lato;
  font-size: 12px;
  padding-right: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${props => props.erro ? '#ff5252' : '#bac1cf'};
  width: 26px;
  margin-left: ${props => props.isClearable ? '87%' : '87%'};
  margin-top: ${props => props.yesNo ? '-38px' : '-38px'};

  .arrow-select {
    transform: ${props => (!props.isOpen && !props.disabled ? 'none' : 'rotate(180deg)')} !important;
    transition: all 0.2s !important;
    &:hover {
      cursor: pointer !important;
      transform: ${props => (!props.isOpen && !props.disabled ? 'none' : 'scale(1.2) rotate(180deg)')}  !important;
    }

    &:active {
      transform: ${props => !props.disabled && 'scale(0.8)'} !important;
    }
  }

  @media only screen and (max-width: 780px) {
    margin-left: ${props => props.isClearable ? '88%' : props.addNew ? '83%' : '83%'};
  }

  @media only screen and (max-width: 540px) {
    margin-top: ${props => props.yesNo ? '-44px' : props.addNew ? '-38px' : '-38px'};
  }

`;

export const Clearabled = styled.div`
  width: 10px;
  position: relative;
  left: 82%;
  font-family: Lato;
  font-size: 12px;
  margin-top: ${props => props.isOpen ? '-21px' : '-21px'};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.erro ? '#ff5252' : '#bac1cf'};

  .delete-select {
    transition: all 0.2s !important;
    &:hover {
      cursor: pointer !important;
      transform: scale(1.2) !important;
    }

    &:active {
      transform: scale(0.8) !important;
    }
  }

  @media only screen and (max-width: 780px) {
    left: 82%;
  }

`;
export const Plus = styled.div`
  position: relative;
  width: 16px;
  height: 16px;

  left: 80%;
  font-family: Lato;
  font-size: 12px;
  margin-top: ${props => props.isOpen ? '-21px' : '-21px'};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: 0px solid;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
  letter-spacing: normal;
  color: ${props => props.erro ? '#ff5252' : '#bac1cf'};
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  transition:all 0.3s ease;
  cursor:pointer;
  #msg{
    display:none;
  }
  &:hover{
    transform: scale(1.3);
    #msg {
      display: flex;
      justify-content: center;
      position:absolute;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: center;
      align-items: center;
      color:#FFF;
      background-color: #1d2129;
      width: 90px;
      height: 18px;
      opacity: 0.9;
      border-radius: 5px;
      margin-bottom: 48px;
      margin-right: 40px;

      &:after{
        display:flex;
        position: absolute;
        background-color:  #1d2129;
        width: 6px;
        height: 6px;
        top: 14px;
        margin-left: 40px;
        content: '';
        transform: rotate(45deg);
    }
    }
  }

  .add-select {
    transition: all 0.2s !important;
    transform: rotate(45deg);
    &:hover {
      cursor: pointer !important;
    }

    &:active {

    }
  }

`;

export const Legenda = styled.p`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff5252;
  margin-top: 22px;

  @media only screen and (max-width: 500px) {
    min-width: 264px;
    margin-top: 16px;
  }
`;

export const Options = styled.div`
  width: 100%;
  position: relative;
  max-height: ${props => (props.isOpen ? '180px' : '0px')};
  margin-top: ${props => props.isClearable ? '17px' : '10px'};
  margin-bottom: ${props => props.marginBottom === '0px' ? '10px' : '0px'};
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${props => props.isSearch ? '#f3f3f5' : '#FFF'};
  border-bottom: ${props => (props.erro ? 'solid 1px #ff5252' : props => props.isSearch ? 'none' : 'solid 1px #bac1cf')};
  border-right: ${props => (props.erro ? 'solid 1px #ff5252' : props => props.isSearch ? 'none' : 'solid 1px #bac1cf')};
  border-left: ${props => (props.erro ? 'solid 1px #ff5252' : props => props.isSearch ? 'none' : 'solid 1px #bac1cf')};
  border-top: ${props => (props.erro ? 'solid 1px #ff5252' : 'solid 1px #bac1cf')};
  box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
  
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: max-height 0.25s ease-in;
  overflow-y: scroll;
  z-index: 10;

  &::-webkit-scrollbar {
    width: 0.4em;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.3);
    border-radius: 25px
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bac1cf;
  }
`;

export const Option = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 7%;
  padding-bottom: 7%;
  font-family: Lato;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.erro ? '#ff5252' : '#303848')};
  
  &:hover{
    background-color: ${props => (props.isSearch ? 'rgba(186, 193, 207, 0.5)' : '#F3F3F5')};
  }
  
  @media only screen and (max-width: 780px) {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export const NoOptions = styled.div`
  width: 100%;
  height: 35px;
  cursor: context-menu;
  padding-left: 3%;
  padding-right: 3%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => (props.erro ? '#ff5252' : '#303848')};

`;

export const ButtonAddNew = styled.p`
  width: 50px;
  position: relative;
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #589bff;
  cursor: pointer;
  margin: 0px;
  left: ${props => props.responsive ? '8%' : '0%'};
  top: ${props => props.responsive ? '70px' : '0px'};

  @media only screen and (max-width: 991px) {
    left: ${props => props.isModal ? props.responsive ? '8%' : '4px' : props.responsive ? '0%' : '4px%'};
    top: ${props => props.isModal ? props.responsive ? '70px' : '0px' : props.responsive ? '0px' : '0px'};
  }

  @media only screen and (max-width: 540px){
    left: ${props => props.responsive ? '8%' : '4px'};
    top: ${props => props.responsive ? '70px' : '0px'};
  }

`;

export const YesNoContainer = styled.div`
  width: 210px;
  height: 40px;
  padding: 8px 8px 7px 59px;
  border-radius: 8px;
  background-color: #f3f3f5;
  position: absolute;
  margin: 0px;
  margin-top: 5px;
  left: ${props => (!props.disabled ?  props.yesNoLeft ? props.yesNoLeft : props.responsive ? '20px' : '240px' : props.showDisabled ? props.yesNoLeft ? props.yesNoLeft : '240px' : '33px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;

  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
  }

  @media only screen and (max-width: 1024px) {
    left: ${props => (!props.disabled ? '250px' : '33px')};
  }
  
  @media only screen and (max-width: 991px) {
    left: ${props => (!props.disabled ? '269px' : '33px')};
  }
  
  @media only screen and (max-width:780px) {
    display: none;
  }

  @media only screen and (max-width: 540px) {
  }
`;


export const YesNoContainerMobile = styled.div`
  display: ${props => props.responsive ? 'flex' : 'none'};
  flex-direction: row;
  align-items: center;
  width: 160px;
  height: 50px;
  padding: 8px 8px 7px 8px;
  border-radius: 8px;
  background-color: #f3f3f5;
  position: absolute;
  margin: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  left: 26%;
  top: ${props => (!props.disabled ? '35px' : '-6px')};
  transition: all 0.2s;
  justify-content: space-between;

  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
  }

  @media only screen and (max-width: 780px) {
    display: ${props => (!props.disabled ? 'flex' : 'none')};;
    flex-direction: row;
    align-items: center;
    width: 160px;
    height: 50px;
    padding: 8px 8px 7px 8px;
    border-radius: 8px;
    background-color: #f3f3f5;
    position: absolute;
    margin: 0px;
    margin-top: 5px;
    left: 33%;
    top: ${props => (!props.disabled ? '35px' : '-6px')};
    transition: all 0.2s;

    span {
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #586377;
    }
  }

  @media only screen and (max-width: 690px) {
    left: 32%
  }
  
  @media only screen and (max-width: 460px) {
    left: 28%;
  }

  @media only screen and (max-width: 420px) {
    left: 25%
  }
  @media only screen and (max-width: 390px) {
    left: 24%
  }
  @media only screen and (max-width: 350px) {
    left: 20%
  }
  @media only screen and (max-width: 320px) {
    left: 15%
  }
  @media only screen and (max-width: 280px) {
    left: 11%
  }
  @media only screen and (max-width: 270px) {
    left: 9%
  }

`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 20px;
  height: 20px;
  position: relative;
  /* margin-left: ${props => props.addNew ? '69%' : '87%'}; */
  margin-left: 87%;
  margin-top: -36px;

  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;


  @media only screen and (max-width: 780px) {
    margin-left: ${props => props.addNew ? '87%' : '87%'};
  }

`;

