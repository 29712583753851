import styled from "styled-components";

export const RadioContainer = styled.div`
  width: ${props => props.width ? props.width : '100%'};
  height: auto;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '25px'};
  position: relative;
  z-index: 2;

  .help-buttom{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -37px;
    margin-right: -40px;

    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #589bff;
    cursor: pointer
  }
    .form-control + .form-control {
        margin-top: 1em;
    }

    input[type="radio"] {
        /* Add if not using autoprefixer */
        -webkit-appearance: none;
        appearance: none;
        /* For iOS < 15 to remove gradient background */
        background-color: #fff;
        /* Not removed via appearance */
        margin: 0;

        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid currentColor;
        border-radius: 50%;
        transform: translateY(-0.075em);

        /* ...existing styles */
        display: grid;
        place-content: center;
    }

    input[type="radio"]::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        border-radius: 50%;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #2fbb69;
    }

    input[type="radio"]:checked::before {
        transform: scale(1);
    }

  @media only screen and (max-width: 780px) {
    margin-bottom: ${props => props.yesNo && props. disabled === false ? '58px' : props.marginBottom ? props.marginBottom : '25px'};
  }

  @media only screen and (max-width: 540px) {
    height: ${props => props.isOpen ? 'auto' : props.yesNo ? props.disabled === false ? '100px' : '70px' : '70px'};
    margin-bottom: ${props => props.yesNo && props. disabled === false ? '32px' : props.marginBottom ? props.marginBottom : '25px'};
    
  }
`;

export const Options = styled.div`
    display: flex;
    flex-direction: column;

`;

export const Option = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 2px;

    label {
      margin: 0px;
      margin-left: 8px;
      font-size: 12px;
    }
`;
