import styled from "styled-components";

export const FormContainer = styled.div`
  background: #f8f8f9;
  height: auto;
  padding-bottom: 20px;
  form {
    background: #f8f8f9;

    margin: auto;
    min-width: 250px;
    display: flex;
    display: block;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding-top: 40px;

    .labelcoord {
      width: 100%;
      margin-bottom: 10px;
      /* margin-left: 52px; */
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #8a92a2;
    }

    .coord {
        width: 100%;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        
        .partCoord {
          width: 30%;
          height: 90px;

          span {
            position: relative;
            top: -100px;
            left: 123px;
            color: #8a92a2;
            font-weight: 500;
          }
          
        }
      }
  }

  margin: 0px;
  button {
    width: 100%;
    max-width: 400px;
    height: 50px;
  }
  i {
    margin-left: 15px;
  }

  #labelcultura{
    margin: -10px 0px 0px 25px;
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a92a2;
  }

  #multiselectContainerReact{
    margin: 10px 0 10px 0px;
    ._2OR24XnUXt8OCrysr3G0XI{
      .searchBox{
        margin: 5px 0 30px 10px;
      }      
      span{
        background: #00b43f;
        width: auto;
      }
      background: white;
      width: 100%;
      max-width: 400px;
      border-radius: 25px;
    }

  }

  @media only screen and (max-width: 768px) {
    form{

      .labelcoord {
        width: 100%;
        margin-bottom: 10px;
        margin-left: 52px;
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #8a92a2;
      }
    
      .coord {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .partCoord {
          width: 100%;
          span {
            position: relative;
            top: -85px;
            left: 91%;
            color: #8a92a2;
            font-weight: 500;
          }
        }
      }
    
    }
  }
`;

export const YesNoContainer = styled.div`
  width: 100%;
  margin: 0px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;

  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-right: 10px;
  }

`;

export const GuiasContainer = styled.section`
padding: 50px 0;
background: #f8f8f9;
height: 100%;
margin: auto;

.search-select{
  width: 500px;
  display: flex;
  flex-direction: row;
  margin-top: -110px;

}


.search{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -83px;
  margin-bottom: 20px;

  .exibe-options{        
    .opcoes{

      a {
        text-decoration: none;
      }

        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #FFF;
        width: 160px;
        border-radius: 10px;
        box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.15);
        padding: 15px;
        margin-left: 70px;
        margin-top: 5px;


        #arrow {
            position: relative;
            background-color: #FFF;
            width: 8px;
            height: 8px;
            top: -90px;
            margin-left: 104px;
            transform: rotate(45deg);
        }

        #tooltip[data-popper-placement^='top'] > #arrow {
            bottom: -4px;
        }

        #tooltip[data-popper-placement^='bottom'] > #arrow {
            top: -4px;
        }

        #tooltip[data-popper-placement^='left'] > #arrow {
            right: -4px;
        }

        #tooltip[data-popper-placement^='right'] > #arrow {
            left: -4px;
        }

        .option{ 
            width: 110%;
            height: 36px;
            border-radius: 5px;
            align-items: center;

            p{
                margin-left: 10px;
                margin-top: 11px;
                font-family: Lato;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: #8a92a2;
                cursor: pointer;
            }

            :hover{
                background-color: #f3f3f5;
                font-weight: bold;
                cursor: pointer;

            }
        }
    }

    .btn-relatorio{
        
        width: 145px;
        height: 40px;
        border-radius: 30px;
        border: solid 3px #f8f8f9;
        background-color: #00b43f;
        padding: 15px;

        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center; 
        cursor: pointer;
        margin-top: 10px;
        transition: all 0.3s;

        i{
            color: #FFF;
        }

        label{
          margin-top: 4px;
          font-family: Lato;
          font-size: 12px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #fff;
          cursor: pointer;
        }

        img{
          width: 10px;
          cursor: pointer;
        }

        &:hover{
            box-shadow: 0 0 20px 0 #00b43f;
        }
    }
  }
  .help-buttom{
    width: 30px;
    height: 30px;
    border-radius: 20px;
    border: solid 3px #f8f8f9;
    background-color: #f8f8f9;
    margin-right: 5%;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center; 
    cursor: pointer;
    margin-top: 17px;
    transition: all 0.3s;

    &:hover{
      box-shadow: 0 0 10px 0 #5DA8FD;
    }
  }
}

> div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .limpar{
    display: flex;
    justify-content: space-around;
    .botao{
      margin: 40px 30px 40px 1px;
      padding: 15px 50px;
    }
  .filterbuttons {
    width: 100%;
    max-width: 400px;
    .pesquisar {
      margin: 40px 0;
      padding: 20px 80px;
    }
  }
}
.botoesfinais{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 50%;
    margin-top: 20px;
    .loadmore {
      margin: 30px 0;
      padding: 15px 20px;
    }
    .relatorio {
      display: flex;
      margin: 30px 0;
      padding: 15px 20px;
    }
  }
}



@media only screen and (max-width: 768px) {
  padding: 0px;
  background: #f8f8f9;
  height: 100%;
  margin-top: 82px;
  .search-select{
    width: 100%;
    max-width: 375px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-top: -58px;
    margin-bottom: -30px;
    .select{                        
      width: 80%;
    }
  

    .add{
      margin-left: -30px;
      margin-top: 17px;
      width: 36%;
      justify-content: center;
      button{
        width:100px;
        padding: 16px;
        justify-content: center;
        border-radius: 30px;
        border: solid 3px #FFF;
      }     
  
    }
  
  }



  .search{
    width:100%;
    max-width: 375px;
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-top: -30px;
    
    
  }
      .add{
        width:100%;
        max-width: 375px;
        align-self: center;
        margin-top: -12px;
        button{
          width:100%;
          max-width: 375px;
          align-self: center;
          border-radius: 30px;
          border: solid 3px #FFF;
        }     
  }
  
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .loadmore {
      width:100%;
      max-width: 375px;
      align-self: center;
      margin-top: -40px;
      padding: 15px 20px;
    }
    .limpar{
      display: flex;
      justify-content: space-around;
      .botao{
        margin: 40px 30px 40px 1px;
        padding: 15px 50px;
      }
    .filterbuttons {
      width: 100%;
      max-width: 400px;
      .pesquisar {
        margin: 40px 0;
        padding: 20px 80px;
      }
    }
  }
    .relatorio {
      width:100%;
      max-width: 375px;
      display: flex;
      margin-left: 0px;
      padding: 15px;
      justify-content: center;
      margin-bottom: 10px
    }
    .add {
      width:100%;
      max-width: 375px;
      align-self: center;
      button {
        width:100%;
        max-width: 375px;
        align-self: center;
        margin: 15px 0 30px;
        padding: 20px 30px;
      }
    } 
  } 
}
`;
