import styled from "styled-components";

export const DevolucaoContainer = styled.section`
  padding: 50px 0;
  background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
  height: 100%;
  .search{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -83px;
    margin-bottom: 20px;

    a{
      text-decoration: none;
    }
  }
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    
    .filterbuttons {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-around;
      .pesquisar {
        margin: 30px 0;
        padding: 15px 20px;
      }
    }
    .salvar {
      width: 100%;
      max-width: 400px;
      display: flex;
      justify-content: space-around;
      .salvado {
        margin: 30px 0;
        padding: 15px 20px;
      }
    }


    .botoesfinais{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      width: 50%;
      margin-top: 20px;
      .loadmore {
        margin: 30px 0;
        padding: 15px 20px;
      }
      .relatorio {
        display: flex;
        margin: 30px 0;
        padding: 15px 20px;
      }
    }
    
  }

  @media only screen and (max-width: 768px) {
    padding-left: -15px;
    padding-right: -15px;
    /* background: #f8f8f9; */
    height: 100%;
    .search{
      display: flex;
      flex-direction: row;
      margin-left: 0px;
      margin-top: -80px;
      width: 100%;
      max-width: 375px;
    }
    .add{
      display:flex;
      justify-content: center;
      margin-left: 0px;
      margin-top: 12px;
      width: 100%;
      max-width: 375px;
      align-self: center;
      button{
        width: 100%;
        max-width: 375px;
        border-radius: 30px;
        border: solid 3px #FFF;
      }     
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .loadmore {
        margin-top: -16px;
        width: 87%;
        max-width: 375px;
        align-self: center;
        padding: 15px 20px;
      }
      .filterbuttons {
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: space-around;
        .pesquisar {
          margin: 30px 0;
          padding: 15px 20px;
        }
      }
      .salvar {
        width: 100%;
        max-width: 400px;
        display: flex;
        justify-content: space-around;
        .salvado {
          margin: 30px 0;
          padding: 15px 20px;
        }
      }
      .relatorio {
        display: flex;
        margin-left: 0px;
        justify-content: center;
        padding: 15px;
        width: 87%;
        max-width: 375px;
      }
      .add {
        align-self: center;
        margin-top: 12px;
        width: 87%;
        max-width: 375px;
        button {
          width: 100%;
         max-width: 375px;
          margin: 0px;
          padding: 15px 30px;
        }
      }
    }  
  }

  @media only screen and (max-width: 540px){
    padding: 0px;
    margin-top: 100px;
  }
`;

export const DevolucaoAddContainer = styled.div`
  padding: 50px 0;
  background: ${props => props.isModal ? '#fff' : '#f8f8f9'};
  div {
    form {
      margin: auto;
      max-width: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  
  .salvar {
    width: 100%;
    max-width: 425px;
    display: flex;
    justify-content: space-between;
    
    .add {
      text-decoration: none;

      .back {
        margin: 15px;
        padding: 15px 20px 15px 20px;
      }
    }
    
    .salvado {
      width: 60%;
    }
  }
`;
