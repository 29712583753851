import React, { useState, useEffect } from "react";
import Button from '../../button/button_v2';
import { Container } from "react-grid-system";
import Input from "../../input/input_v2";
import { Assinaturas, FormContainer, Label } from "./styles";
import { FormButtonContainer } from "./styles";
import { store } from "react-notifications-component";
import ModalNew from "../../modal/modal";
import ModalText from "../../modal/modalText";
import api from '../../../services/api';
import mais from "../../../assets/Mais.png";
import ModalPassword from '../../modal/modal_password';
import ModalAlert from '../../modal/modalGeneric';
import validadorDeCpf from "../../../services/validadorCpf";
import { logout } from "../../../services/auth";
import InputSign from "../../inputSign";
import DragDropUpload from "../../../components/drag-drop-upload/index"
import { IconUploads } from '../../../assets/icons/iconUploads';

export const USER = "@userinfo";


const Agronomo = ({ ...props }) => {
    const { next, update, nome, cpf, isModal, email } = props;

    const [load, setLoad] = useState(false);
    const [nomeId, setNomeId] = useState(nome);
    const [cpfId, setCpfId] = useState(cpf);
    const [disabled, setDisabled] = useState(true);
    const [emailAgronomo, setEmail] = useState(email);
    const [sendEmail, setSendEmail] = useState(false);

    let [soma, setSoma] = useState('');
    let [resto, setResto] = useState('');
    let [teste, setTeste] = useState(true);

    const [cpfValido, setCpfValido] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);
    const [file, setFile] = useState([]);
    const [namesfiles, setNamesfiles] = useState([]);
    const [textFile, setTextFile] = useState('');
    const [isValidate, setIsValidate] = useState(false);
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalText, setShowModalText] = useState(false);
    const [renderInput, setRenderInput] = useState(true);

    const [erroCpf, setErroCpf] = useState(false);
    const [erroCpfMsg, setErroCpfMsg] = useState(false);

    const [erroNome, setErroNome] = useState(false);
    const [erroNomeMsg, setErroNomeMsg] = useState('Informe o nome do responsável');

    const [showModalBlock, setShowModalBlock] = useState(false);
    const [digital, setDigital] = useState(false);
    const [eletronica, setEletronica] = useState(false);
    const [manual, setManual] = useState(false);

    const [sign, setSign] = useState('');

    const [disabledNext, setDisabledNext] = useState(false);
    const [showDisabledCad, setShowDisabledCad] = useState(false);

    const [whatsapp, setWhatsapp] = useState('');


    const validateNome = nomeId => {
        setNomeId(nomeId);
        setErroNome(false);
    };

    const validateCpf = cpfId => {
        setCpfId(cpfId);

        if (cpfId) {

            if (cpfId.length === 14) {
                const isValid = validadorDeCpf(cpfId);

                setCpfValido(isValid);

                if (!isValid) {
                    setErroCpf(true);
                    setErroCpfMsg('Informe um CPF válido!');
                } else {
                    setErroCpf(false);

                }
            } else {
                setCpfValido(false);
                setErroCpf(false);
            }
        } else {
            setCpfValido(false);
            setErroCpf(false);
        }

    };

    const validaFields = e => {
        if (nomeId) {
            if (cpfValido === false) {
                setErroCpf(true);
                setErroCpfMsg('CPF inválido');
                store.addNotification({
                    title: "Erro!",
                    message: "CPF inválido",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                setErroCpf(false);
            }
        } else {
            if (cpfValido === false) {
                setErroCpf(true);
                setErroCpfMsg('CPF inválido');
                store.addNotification({
                    title: "Erro!",
                    message: "CPF inválido",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                setErroCpf(false);
            }
        }
    };

    const addModalClose = () => {
        // e.preventDefault();
        setAddModalShow(false);
    };

    const modalTextClose = () => {
        setShowModalText(false);
    }

    const closeModalPassword = () => {
        setRenderInput(false);
        setShowModal(false);
        setPassword('');
        setNamesfiles([]);
        setNamesfiles([]);
        setTextFile('');
        setIsValidate(false);
    }

    const nextStep = async e => {
        if (!nomeId || !cpfId) {
            store.addNotification({
                title: "Erro!",
                message: `Verifique os campos em vermelho`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }

        if (!nomeId) {
            setErroNome(true);
        }
        if (!cpfId) {
            setErroCpf(true);
            setErroCpfMsg("Informe um CPF");
        }
        if (cpfValido == false) {
            store.addNotification({
                title: "Erro!",
                message: `CPF inválido`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        } else {
            if (nomeId && cpfId) {
                setErroNome(false);
                setErroCpf(false);
                update(nomeId, cpfId, namesfiles && isValidate ? true : false, sign, emailAgronomo, sendEmail, whatsapp);
                next();
            }
        }
    };

    const validaCertificado = async (file, password) => {
        if (cpfId) {
            if (file && !isValidate && password) {
                const payload = {
                    certificado: file,
                    cpf: cpfId,
                    senha: password,
                }
                try {

                    let user = JSON.parse(localStorage.getItem(USER));
                    if (user.contrato) {
                        if (user.contrato.agro_plan_functions) {
                            let isBlock = false;

                            user.contrato.agro_plan_functions.map((e) => {
                                console.log(e);

                                if (e.agro_plans_function.id === 9) {
                                    if (e.status === 0) {
                                        isBlock = true;
                                    }
                                }
                            });

                            if (isBlock === true) {
                                closeModalPassword();
                                store.addNotification({
                                    title: "Erro!",
                                    message: `A função de assinatura digital não está liberada para seu plano!`,
                                    type: "danger",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            } else {
                                const response = await api.post(`/sign/digital`, payload);
                                if (response) {
                                    setIsValidate(true);
                                }
                                const input = document.getElementById('cpfId');
                                input.classList.remove("error");
                                store.addNotification({
                                    title: "Sucesso!",
                                    message: `${response.data.data.msg}`,
                                    type: "success",
                                    insert: "top",
                                    container: "top-right",
                                    animationIn: ["animated", "fadeIn"],
                                    animationOut: ["animated", "fadeOut"],
                                    dismiss: {
                                        duration: 4000,
                                        onScreen: true
                                    }
                                });
                            }

                        } else {
                            logout();
                            window.location = '/login';
                        }
                    } else {
                        logout();
                        window.location = '/login';
                    }


                } catch (error) {
                    closeModalPassword();
                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        } else {
            const input = document.getElementById('cpfId');
            input.classList.add("error");

            store.addNotification({
                title: "Erro!",
                message: `Informe o CPF!`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    };

    const onChangeCertificate = (e) => {
        let user = JSON.parse(localStorage.getItem(USER));

        if (user.contract_software_id === 6) {
            //Abrir modal de bloqueio
            setShowModalBlock(true);
        } else {
            let cont = e.target.files.length;
            cont = cont - 1;

            if (e.target.files) {
                if (
                    e.target.files[cont].type == "application/x-pkcs12"
                ) {
                    let fil = e.target.files;
                    let reader = new FileReader();
                    reader.readAsDataURL(fil[0]);

                    reader.onload = (e) => {
                        setFile(e.target.result);
                        setTextFile(e.target.result);

                        if (cpfId) {
                            setShowModal(true);
                        } else {
                            const input = document.getElementById('cpfId');
                            input.classList.add("error");
                            store.addNotification({
                                title: "Erro!",
                                message: `Informe o CPF!`,
                                type: "danger",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    }
                    setNamesfiles(e.target.files[cont].name);

                } else {
                    store.addNotification({
                        title: "Erro!",
                        message: `O arquivo selecionado não possui a extensão correta`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Não é possível enviar mais que um arquivo!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }

    };

    const deleteFile = () => {
        setNamesfiles([]);
        setIsValidate(false);
    };

    const updatePassword = (e) => {
        validaCertificado(file, e);
        setShowModal(false);
    }

    const openModalText = () => {
        setShowModalText(true);
    }

    const saveSign = (e) => {
        setSign(e);
    }

    const tipoAssinatura = (e) => {
        if (e === 'E') {
            setEletronica(true)
            setDigital(false)
            setManual(false)
        } else if (e === 'D') {
            setDigital(true)
            setManual(false)
            setEletronica(false)
        } else {
            setManual(true)
            setEletronica(false)
            setDigital(false)
        }

    };

    const validaLimite = async () => {

        try {
            const response = await api.get('/agronomos/limite');

            if (response) {
                
            }
        } catch (error) {
            console.log("ERRO")
            setDisabledNext(true);
            setShowDisabledCad(true);
            // store.addNotification({
            //     title: "Erro!",
            //     message: `${error.response.data.errors}`,
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }

    }

    useEffect(() => {
        if (cpfValido == false) {
            setDisabled(true);
        } else {
            if (!nomeId || !cpfId) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }
    }, [nomeId, cpfId]);

    useEffect(() => {
        let cpf = cpfId.replace('___', '');
        cpf = cpf.replace('__', '');
        cpf = cpf.replace('.', '');
        cpf = cpf.replace('-', '');
        cpf = cpf.replace('.', '');
        cpf = cpf.replace('_____', '');
        cpf = cpf.replace('____', '');
        cpf = cpf.replace('___', '');
        cpf = cpf.replace('__', '');
        cpf = cpf.replace('_', '');
        if (cpf.length == 11) {
            if (cpfValido) {
                if (!isValidate) {
                    if (file.length > 0) {
                        if (!password) {
                            setShowModal(true);
                        }
                    }
                }
            }
        }
    }, [cpfId]);

    useEffect(() => {
        if (load === false) {
            setLoad(true);
            validaLimite();
        }

        return () => {}
    }, [])


    const back = () => {
        props.propsLocation.history.goBack();
    }

    return (
        <>
            <FormContainer isModal={props.isModal}>
                <Container style={{ width: '100%', paddingLeft: props.isModal ? '15px' : '15px', paddingRight: props.isModal ? '15px' : '15px' }}>
                    <form>
                        <Input
                            name="nome"
                            type="text"
                            label="Nome"
                            id="nomeId"
                            onChange={e => validateNome(e)}
                            erro={erroNome}
                            messageErro={erroNomeMsg}
                            value={nomeId}
                        />
                        <Input
                            label="E-mail (opcional)"
                            name="email"
                            className="input"
                            id="email"
                            onChange={e => setEmail(e)}
                            yesNo={true}
                            messageYesNo={'enviar receitas por email'}
                            erro={false}
                            yesNoSpanWidth={'80px'}
                            messageErro={false}
                            value={emailAgronomo}
                            onChangeYesNo={(e) => setSendEmail(e ? 1 : 0)}
                        />
                        <Input
                            name="cpf"
                            type="text"
                            label="CPF"
                            id="cpfId"
                            value={cpfId}
                            onChange={e => validateCpf(e)}
                            onBlur={(e) => validaFields(e.target.value)}
                            maxlength={14}
                            erro={erroCpf}
                            messageErro={erroCpfMsg}
                            inputmode="numeric"
                        />

                        <Input
                            name="whatsapp"
                            value={whatsapp}
                            type="text"
                            label="Whatsapp (opcional)"
                            id="whatsapp"
                            onChange={e => setWhatsapp(e)}
                            inputmode="numeric"
                        />



                        <div className="radios">
                            <input type="radio" id="m" name="fav_language" value="M" onChange={(e) => tipoAssinatura(e.target.value)}></input>
                            <label for="m">Assinatura Manual</label>
                            <input type="radio" id="d" name="fav_language" value="D" onChange={(e) => tipoAssinatura(e.target.value)}></input>
                            <label for="d">Assinatura Digital</label>
                            <input type="radio" id="e" name="fav_language" value="E" onChange={(e) => tipoAssinatura(e.target.value)}></input>
                            <label for="e">Assinatura Eletrônica</label>
                        </div>

                        {digital ? (
                            <>
                                <Assinaturas>Assinatura Digital é aquela que precisa de um certificado A1 válido. Aceita nos estados MT, PR, SC, RS, GO, MG, ES, SP e MS.</Assinaturas>
                                <div class='input-wrapper'>
                                    <label style={{
                                        paddingLeft: '15px',
                                        textAlign: 'left',
                                        fontSize: '12px',
                                        fontWeight: 700,
                                        color: '#8a92a2',
                                    }}>Upload certificado digital</label>
                                    <div className="arquivos">
                                        <label
                                            className='btn-update'
                                            for='input-file'
                                            multiple
                                            onClick={() => { setRenderInput(true) }}
                                        >
                                            <IconUploads width="auto" height="40px" marginBottom="100px" display="flex" alignItems="center" justifyContent="center" />
                                            Selecione o arquivo PFX em seu computador.<br></br><br></br>O upload do certificado é necessário caso deseje assinar sua receita digitalmente.
                                            <label className="arq-name">
                                                {namesfiles.length > 0 ?
                                                    (<>
                                                        {namesfiles}
                                                        <img src={mais} width="20" height="20" onClick={() => deleteFile()} />
                                                    </>) : "Nenhum arquivo selecionado"} </label>
                                        </label>
                                    </div>


                                    {renderInput ? (
                                        <input
                                            style={{ display: "none" }}
                                            type="file"
                                            id="input-file"
                                            name="file"
                                            multiple
                                            onChange={(e) => onChangeCertificate(e)}
                                        />
                                    ) : (null)}
                                </div>
                            </>


                        ) : eletronica ? (
                            <>
                                <Assinaturas>Assinatura Eletrônica é aquela que utiliza um conjunto de dados digitais para validação. Aceita em todos os estados brasileiros, com exceção de MT, PR, SC e RS.</Assinaturas>
                                <InputSign
                                    update={(e) => saveSign(e)}
                                />
                            
                            </>

                        ) :  manual ? (
                            <Assinaturas>Assinatura Manual é aquela onde você assina à próprio punho. Aceita em todos os estados brasileiros.</Assinaturas>
                        ) : (null)}
                    </form>
                </Container>

                <div
                    className="button-finish"
                    style={{
                        width: '100%',
                        display: 'flex',
                    }}
                >

                    {isModal ? (null) : (
                        <div className="voltar" id='back'>
                            <Button
                                width='100%'
                                height='50px'
                                value='VOLTAR'
                                outline={true}
                                disabled={false}
                                onClick={() => back()}
                                loading={false}
                            />

                        </div>
                    )}


                    <div className="cadastrar" id='save'>
                        <Button
                            width='100%'
                            height='50px'
                            value='CADASTRAR CREA/CFTA'
                            outline={false}
                            disabled={disabledNext}
                            onClick={() => nextStep()}
                            loading={false}
                        />
                    </div>

                </div>
            </FormContainer>

            <FormButtonContainer>
                <ModalNew
                    title={'CADASTRO'}
                    text={"É necessário adicionar um CREA/Visto para cadastrar um responsável técnico."}
                    show={addModalShow}
                    onHide={() => addModalClose()}
                />
                <ModalPassword
                    text={"Precisamos da sua senha para validar a autenticidade do certificado. Para sua segurança, essa senha não ficará salva em nossos servidores."}
                    show={showModal}
                    update={(e) => updatePassword(e)}
                    onHide={closeModalPassword}
                />
                <ModalText
                    show={showModalText}
                    onHide={modalTextClose}
                    text={"Certificado digital é um arquivo eletrônico que serve como identidade virtual para uma pessoa física ou jurídica. Ele serve para agilizar a assinatura conferindo legitimidade aos documentos digitais, que passam a possuir validade jurídica. Esse certificado deve ser emitido por uma Autoridade Certificadora vinculada ao ICP-Brasil"}
                    btnOk={false}
                />
            </FormButtonContainer>


            <ModalAlert
                show={showModalBlock}
                onHide={false}
            >
                <h4 style={{
                    textAlign: 'center'
                }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

                <p style={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}>
                    Para não comprometer o controle de suas receitas agronômicas essa função só pode ser utilizada nos planos Light e Pro. Entre em contato com nosso suporte e faça o upgrade do seu plano para desbloquear esse recurso.
                </p>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='ENTENDI'
                        outline={false}
                        disabled={false}
                        onClick={() => setShowModalBlock(false)}
                        loading={false}
                    />
                </div>
            </ModalAlert>
            
            <ModalAlert
                show={showDisabledCad}
                onHide={() => setShowDisabledCad(false)}
                size='md'
            >
                <h4 style={{
                    fontSize: '16px',
                    textAlign: 'center'
                }}>Limite de cadastros atingido!</h4>

                <p style={{
                    textAlign: 'center',
                    marginTop: '20px',
                    fontSize: '13px',
                    letterSpacing: '0px'
                }}>
                    Adquira mais licenças para adicionar novos responsáveis técnicos!
                </p>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='ADQUIRIR MAIS LICENÇAS'
                        outline={false}
                        disabled={false}
                        onClick={() => window.location = '/plano/edit'}
                        loading={false}
                    />
                </div>
            </ModalAlert>
        </>
    );
};
export default Agronomo;
