import React from 'react';
import { withRouter } from "react-router-dom";
import Logo from '../../assets/logo.png';
import Cell from '../../assets/celular.png';
import { ContainerSuccess, Images, BannerTextRight } from './style';

const SuccessTest = ({ ...props }) => {

    return (
        <ContainerSuccess>
            <Images>
                <div className="img1">
                    <a href="https://agroreceita.com.br">
                        <img src={Logo} />
                    </a>
                </div>
                <div className="img2">
                    <img src={Cell} />
                </div>
            </Images>
            <BannerTextRight>
                <h1>Acesse seu e-mail</h1>
                <h1>para validar sua conta!</h1>
                <div className="paragraph">
                    <p>Caso não encontre nosso e-mail confira sua caixa de spam.</p>
                </div>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems:'center', marginBottom: '40px' }}>

                </div>
            </BannerTextRight>
        </ContainerSuccess>
    );
}

export default withRouter(SuccessTest);
