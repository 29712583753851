import React, { useState, useEffect } from 'react';

import { RowCard } from './styles';
import Input from '../../input/input_v2';
import Select from "../../select/select_v2";


const Items = ({ ...props }) => {
    const [produto, setProduto] = useState('');
    const [quantidade, setQuantidade] = useState([]);
    const [unidade, setUnidade] = useState([]);
    const [ncmProduto, setNcmProduto] = useState([]);
    const [index, setIndex] = useState('');
    const [unidadeMedida] = useState([
        { value: 'L', label: 'Litro(s)' },
        { value: 'KG', label: 'Kilo(s)' },
        { value: 'UN', label: 'Unidade(s)' }
    ]);
    const [erroProdutos, setErroProdutos] = useState(false);
    const [erroUnidades, setErroUnidades] = useState(false);
    const [erroQuantidades, setErroQuantidades] = useState(false);
    const [erroNcm, setErroNcm] = useState(false);

    const changeProduto = (value) => {
        if (produto !== value) {
            setProduto(value);
            setErroProdutos(false);
            props.updateProduto(value, index);
        }
    }
    const changeQuantidade = (value) => {
        if (quantidade !== value) {
            let v = value.replace('.', ',');
            setQuantidade(v);
            setErroQuantidades(false);

            props.updateQuantidade(v, index);
        }
    }
    const changeUnidade = (value) => {
        setUnidade(value);
        setErroUnidades(false);
        props.updateUnidade(value.value, index);

        
    }
    
    const changeNcmProduto = (value) => {
        if (ncmProduto !== value) {
            setNcmProduto(value);
            setErroNcm(false);
            props.updateNcmProduto(value, index);
        }
    }

    useEffect(() => {
        setErroProdutos(false);
        setErroUnidades(false);
        setErroQuantidades(false);
        setErroNcm(false);

        const errosAddGo = props.errosNf || [];

        errosAddGo.forEach(erro => {
            if (erro === 'produtos') {
                setErroProdutos(true);
            }
            if (erro === 'unidades') {
                setErroUnidades(true);
            }
            if (erro === 'quantidades') {
                setErroQuantidades(true);
            }
            if (erro === 'ncmProdutos') {
                setErroNcm(true);
            }
        });

    }, [props.errosNf]);

    useEffect(() => {
        setIndex(props.index);
        changeProduto(props.produto)

        if (props.quantidades !== undefined) {
            changeQuantidade(props.quantidades);
            setErroQuantidades(false);
        }

        if (props.unidades !== undefined) {
            changeUnidade(props.unidades);
            setErroUnidades(false);

        }

        if (props.ncmprodutos !== undefined) {
            changeNcmProduto(props.ncmprodutos);
            setErroProdutos(false);
        }
    }, [])

    return (
        <>
            <RowCard
                isBorder={true}
            >
                <div className='align' style={{
                    width: '100%',
                    height: 'auto',
                    alignItems: "flex-start"
                }}>
                    <div className='input_area'>
                        <Input
                            name="produto_selecionado"
                            type="text"
                            id={`produtoselecionado${index}`}
                            label="Produto"
                            onChange={e => changeProduto(e)}
                            value={produto}
                            marginBottom='0px'
                            erro={erroProdutos}
                        />

                    </div>

                    <div className='input_area'>
                        <Select
                            name="unidade_medida"
                            className="select"
                            label="Unidade de medida"
                            id={`unidademedida${index}`}
                            options={unidadeMedida}
                            value={unidade.value || unidade}
                            onChange={(e) => changeUnidade(e)}
                            selectUniqueOption={true}
                            marginBottom='10px'
                            erro={erroUnidades}
                        />
                    </div>
                </div>

                <div className='align' style={{
                    width: '100%',
                    height: 'auto',
                    justifyContent: 'space-around'
                }}>

                    <div className='input_area'>
                        <Input
                            name="qtd_produto"
                            type="text"
                            id={`qtdproduto${index}`}
                            label="Qtdade Produto"
                            legenda={`Quantidade de produto adquirida`}
                            onChange={e => changeQuantidade(e)}
                            value={quantidade}
                            min={1}
                            erro={erroQuantidades}
                            marginBottom='0px'
                        />
                    </div>

                    <div className='input_area_2'>
                        <Input
                            name="ncm_produtos"
                            type="text"
                            id={`ncmprodutos${index}`}
                            label="NCM do produto"
                            legenda={`Nomenc. Comum do Mercosul. Ex: 84193100 (produtos agrícolas)`}
                            onChange={e => changeNcmProduto(e)}
                            value={ncmProduto}
                            erro={erroNcm}
                            marginBottom='0px'
                            min={1}
                        />
                    </div>
                </div>
            </RowCard>
        </>
    )
}

export default Items;