import styled from "styled-components";

export const UpperContainer = styled.div`
  height: ${props => (props.isMobile ? '90px' : '70px')};
  background: ${props => props.background || "#21262f"};
  background-size: cover;
  justify-content: center;
  flex-direction: column;
  display: flex;

    .header-desc{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: ${props => (props.isMobile ? "100%" : props.isTest == false && props.isPrePago == false ? "88%" : "90%")};
        margin: auto;
        align-items: center;
        padding-top: 9px;

        button{
            display: ${props => (props.isMobile ? "none" : "")};
        }
    }


  div {
    text-align: ${props => props.align || "center"};
    
    .text{
      margin: ${props => (props.isMobile ? "auto" : "")};

    }

    h1 {
      color: #fff;
      font-weight: 900;
      font-size: 24px;
      margin-left: ${props => props.isTest == true || props.isPrePago ? '110px' : '0px'};
      margin-top: ${props => (props.isMobile ? "7px" : "0px")};
      margin-bottom: 0px;
    }
    small {
      padding: 10px;
      font-size: 14px;
      margin-top: 20px;
      color: #808080;
    }
  }


  @media only screen and (max-width: 1140px){
    .header-desc{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  @media only screen and (max-width: 991px){
    .header-desc{
      .text{
        margin-left: 5px;
      }
    }
    div{
      h1{
        font-size: 18px;
      }
    }
  }

  @media only screen and (max-width: 780px){
    div{
      h1{
        font-size: 15px;
      }
    }
  }
  
  @media only screen and (max-width: 760px){
    .header-desc{
      .text{
        margin: 20px auto auto;
      }
    }
    div{
      h1{
        margin-left: 0px;
      }
    }
  }

  @media only screen and (max-width: 537px){
  }

  @media only screen and (max-width: 393px){
    div{
      h1{
        font-size: 16px;
      }
    }
  }

  @media only screen and (max-width: 320px){
    div{
      h1{
        font-size: 15px;
      }
    }
  }
`;

export const Plans = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: absolute;
  top:  ${props => props.isMobile && '29px'};
  left: ${props => props.isMobile && '60px'};
  transform: ${props => props.isMobile ? 'scale(0.8)' : 'scale(1.0)'};
  width: auto;
  height: 30px;
  font-family: Lato;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgb(223, 228, 235);
  border-radius: 15px;
  background-color: rgb(88, 155, 255);
  padding-top: 9px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  z-index: 4;
  transition: all 0.2s ease-out;

  &:hover {
    background-color: rgb(88, 155, 255);
    transform: scale(1.06);
    box-shadow: 2px 2px 3px 1px rgba(88, 155, 255, 0.2);
  }

  @media only screen and (max-width: 991px){
    margin-top: 0px;
    top: 10px;
    left:10px;
  }

  @media only screen and (max-width: 760px){
    display: none;
  }
`;
