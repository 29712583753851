import Axios from "axios";

export const getIp = async (callback) => {

    function response(s) {
        callback(window.userip);

        console.log(window.userip);
        // return window.userip;
        s.onload = s.onerror = null;
        document.body.removeChild(s);
    }
    

    window.userip = false;

    var s = document.createElement("script");
    s.async = true;
    s.src = "https://l2.io/ip.js?var=userip";
    
    s.onload = function() {
        response(s);
    };
    
    s.onerror = function() {
        response(s);
    };


    document.body.appendChild(s);

    // console.log("IP: ", ip);
    // return false;
}