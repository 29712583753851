import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "react-grid-system";
import Select from "../../components/select/select_v2";
import GenericUpper from "../../components/generic-upper";
import Input from "../../components/input/input_v2";
import api from "../../services/api";
import Button from "../../components/button/button_v2";
import { ArtsAddContainer } from "./styles";
import { store } from "react-notifications-component";
import ModalAlert from '../../components/modal/modalGeneric';

class EditArts extends React.Component {
    state = {
        agronomo_id: "",
        id: "",
        agronomos: [],
        creas: [],
        crea_id: "",
        crea_num: "",
        filial: "",
        numero_art: "",
        receita_inicial: "",
        quantidade_contratos: "",
        quantidade_receitas: "",
        validade_contratos: "",
        numero_ordem: "",
        data_validade: "",
        receitas_emitidas: "",
        estado: "",
        max: "",
        saldoArts: "",
        insestadual: '',
        responsavel_tecnico: "",
        status: '',
        loading: false,
        loadingR: false,
        loadingT: false,
        sendAlert: true,
        showModalIe: false,
        opStatus: [
            { value: 1, label: "Ativo" },
            { value: 0, label: "Inativo" }
        ],
        estados: [
            { value: "NACIONAL", label: "Nacional" },
            { value: "SP", label: "São Paulo" },
            { value: "RJ", label: "Rio de Janeiro" },
            { value: "SC", label: "Santa Catarina" },
            { value: "AC", label: "Acre" },
            { value: "AL", label: "Alagoas" },
            { value: "AP", label: "Amapa" },
            { value: "AM", label: "Amazonas" },
            { value: "BA", label: "Bahia" },
            { value: "CE", label: "Ceará" },
            { value: "DF", label: "Distrito Federal" },
            { value: "ES", label: "Espirito Santo" },
            { value: "GO", label: "Goiás" },
            { value: "MA", label: "Maranhão" },
            { value: "MT", label: "Mato Grosso" },
            { value: "MS", label: "Mato Grosso do Sul" },
            { value: "MG", label: "Minas Gerais" },
            { value: "PA", label: "Pará" },
            { value: "PB", label: "Paraíba" },
            { value: "PR", label: "Paraná" },
            { value: "PE", label: "Pernambuco" },
            { value: "PI", label: "Piaui" },
            { value: "RN", label: "Rio Grande do Norte" },
            { value: "RS", label: "Rio Grande do Sul" },
            { value: "RO", label: "Rondônia" },
            { value: "RR", label: "Roraima" },
            { value: "SE", label: "Sergipe" },
            { value: "TO", label: "Tocantins" },
            { value: "", label: "" }
        ]
    };

    componentDidMount() {
        this.getArts();
    }
    
    formatData = (data) => {
        let date = new Date(data);

        return date.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            timeZone: 'UTC'
        });
    };

    getArts = async () => {
        try {
            const id = this.props.match.params.id;
            this.setState({ id });

            const response = await api.get(`/arts/${id}`);
            const arts = response.data.data;
            let status = arts.status;

            let dataValidade = arts.data_validade !== null ? this.formatData(arts.data_validade) : '';

            this.setState({
                saldoArts: arts.avisar == 0 ? false : arts.qtd_avisar,
                sendAlert: arts.avisar === 1 ? true : false,
                crea_num: arts.crea.crea,
                crea_id: arts.crea.id,
                numero_art: arts.numero_art,
                numero_ordem: arts.numero_ordem,
                data_validade: dataValidade,
                quantidade_contratos: arts.quantidade_contratos,
                quantidade_receitas: arts.quantidade_receitas,
                receitas_emitidas: arts.receitas_emitidas,
                receita_inicial: arts.receita_inicial_cfta ? arts.receita_inicial_cfta : arts.receita_inicial,
                responsavel_tecnico: arts.crea.agronomo.nome,
                estado: arts.crea.estado,
                status
            });

        } catch (error) {
        }
    };

    editArt = async () => {
        this.setState({ loading: true });
        try {
            const {
                crea_id,
                saldoArts,
                numero_ordem,
                data_validade,
                numero_art,
                estado,
                quantidade_contratos,
                quantidade_receitas,
                receita_inicial,
            } = this.state;

            if (this.state.status == 0 || this.state.status == 1) {


                const payload = {
                    creaId: crea_id,
                    numero_ordem: numero_ordem || 0,
                    numero_art,
                    estado,
                    quantidade_contratos: quantidade_contratos || 0,
                    quantidade_receitas: quantidade_receitas || 0,
                    receita_inicial,
                    status: this.state.status,
                    qtd_avisar: this.state.saldoArts.value,
                    avisar: this.state.sendAlert ? 1 : 0,
                }
                const response = await api.put(`/arts/${this.state.id}`, payload);
                this.setState({ loading: false });

            } else {
                const payload = {
                    creaId: crea_id,
                    numero_ordem: numero_ordem || 0,
                    numero_art,
                    estado,
                    quantidade_contratos: quantidade_contratos || 0,
                    quantidade_receitas: quantidade_receitas || 0,
                    receita_inicial,
                    status: this.state.status.value,
                    qtd_avisar: this.state.saldoArts ? this.state.saldoArts.value : null,
                    avisar: this.state.sendAlert ? 1 : 0,
                };
                const response = await api.put(`/arts/${this.state.id}`, payload);
                this.setState({ loading: false });

            }

            store.addNotification({
                title: "Sucesso!",
                message: `A ART foi editada corretamente!`,
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            this.props.history.push('/arts');
            this.setState({ loading: false });


        } catch (error) {
            this.setState({ loading: false });

            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    relarioBaixaTrt = async () => {
        this.setState({ loadingR: true });
        try {
            const responseCrea = await api.get(`/art/relatorio/${this.state.crea_id}`);

            console.log(responseCrea)

            if (responseCrea && responseCrea.status == 200) {
                const response = await api.get(`/arts/relatorio/${this.state.id}`);
                window.open((await response).data.data.link);
                this.setState({ loadingR: false });
            }
        } catch (error) {
            if (error.response.status == 402) {
                this.setState({ showModalIe: true });
            }

            this.setState({ loadingR: false });
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    enviaInscricao = async (insestadual) => {
        this.setState({ loadingT: true });
        if (insestadual && insestadual != "") {
            try {
                const response = await api.post(`/art/relatorio/${this.state.crea_id}`, {
                    ie: insestadual,
                });

                if (response) {
                    this.setState({ showModalIe: false });
                    this.relarioBaixaTrt();
                }
            } catch (error) {

            }
        }
        this.setState({ loadingT: false });

    }

    goBack = () => {
        this.props.history.goBack();
    }

    addContract = () => {
        this.props.history.push(`/contracts/${this.props.match.params.id}`);
    }

    changeSendAlert = (e) => {
        console.log('SET: ', e)
        this.setState({
            sendAlert: e
        })
    }

    render() {
        const {
            crea_num,
            responsavel_tecnico,
            numero_art,
            numero_ordem,
            data_validade,
            quantidade_contratos,
            quantidade_receitas,
            receitas_emitidas,
            receita_inicial,
            validade_contratos,
            estado,
            saldoArts,
            sendAlert,
            insestadual
        } = this.state;
        return (
            <>
                <GenericUpper
                    title="Edite a ART/TRT"
                    subtitle="Edite esta ART/TRT"
                ></GenericUpper>
                <ArtsAddContainer>
                    <Container>
                        <form>
                            <Select
                                name="estado"
                                label="Estado"
                                className="select"
                                disabled
                                value={estado}
                                options={this.state.estados}
                                onChange={() => console.log('')}
                            />
                            <Input
                                name="agronomos"
                                label="Responsável Técnico"
                                className="select"
                                disabled
                                value={responsavel_tecnico}
                            />

                            <Input
                                name="creas"
                                label="CREA/Visto/CFTA"
                                className="select"
                                disabled
                                value={crea_num}
                            />

                            <Input
                                name="numero-art"
                                type="text"
                                disabled
                                label="Número ART/TRT"
                                value={numero_art}
                            />
                            <Input
                                name="receita-inicial"
                                type="text"
                                disabled
                                label="Receita Inicial"
                                value={receita_inicial}
                            />
                            <Input
                                name="receitas-emitidas"
                                type="text"
                                disabled
                                label="Receitas Emitidas"
                                value={receitas_emitidas}
                                onChange={(e) => e !== receitas_emitidas ? this.setState({ receitas_emitidas: e }) : console.log('')}
                            />

                            <Input
                                name="validade-art"
                                label="Data de Validade"
                                id="validade_art"
                                disabled
                                mask="99/99/9999"
                                value={data_validade}
                            />
                            {(estado === "SP") ? <>
                                <Input
                                    name="quantidade-contratos"
                                    type="number"
                                    disabled
                                    label="Quantidade de Contratos"
                                    value={quantidade_contratos}
                                />

                                <Input
                                    name="numero-ordem"
                                    type="number"
                                    disabled
                                    label="Número de Ordem"
                                    value={numero_ordem}
                                />
                            </> : (<>

                                <Input
                                    name="quantidade-receitas"
                                    type="text"
                                    label="Quantidade de Receitas"
                                    disabled
                                    value={quantidade_receitas}
                                />
                                <Select
                                    name="saldoArts"
                                    className="select"
                                    label="Me avisar quando o saldo estiver a partir de:"
                                    id="saldoArts"
                                    value={saldoArts}
                                    disabled={false}
                                    loading={false}
                                    erro={false}
                                    onChange={e => this.setState({ saldoArts: e })}
                                    options={[{ value: 5, label: '5 receitas para acabar' }, { value: 10, label: '10 receitas para acabar' }, { value: 15, label: '15 receitas para acabar' }]}
                                    yesNo={true}
                                    yesNoValue={sendAlert}
                                    messageYesNo='Quero receber notificações'
                                    onChangeYesNo={(e) => this.changeSendAlert(e)}
                                    yesNoLeft='350px'
                                />

                            </>)}
                            <Select
                                name="status"
                                className="text"
                                label="Status"
                                id="status"
                                value={this.state.status.value || this.state.status}
                                disabled={false}
                                onChange={e => this.setState({ status: e })}
                                options={this.state.opStatus}
                                selectUniqueOption={true}
                                erro={false}

                            />

                            {estado === 'SP' ? (
                                <>
                                    <div
                                        className="button-finish"
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '40px',
                                        }}
                                    >
                                        <Button
                                            width='100%'
                                            height='50px'
                                            value='SALVAR ART'
                                            outline={false}
                                            disabled={false}
                                            onClick={() => this.editArt()}
                                            loading={this.state.loading}
                                        />
                                    </div>


                                    <div
                                        className="button-finish"
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            marginTop: '40px',
                                        }}
                                    >
                                        <Button
                                            width='45%'
                                            height='50px'
                                            value='VOLTAR'
                                            outline={true}
                                            disabled={false}
                                            onClick={() => this.goBack()}
                                            loading={false}
                                        />
                                        <Button
                                            width='45%'
                                            height='50px'
                                            value='CONTRATOS'
                                            outline={true}
                                            disabled={false}
                                            onClick={() => this.addContract()}
                                            loading={false}
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="button-finish"
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginTop: '40px',
                                        }}
                                    >
                                        <Button
                                            width='100%'
                                            height='50px'
                                            value='SALVAR ART'
                                            outline={false}
                                            disabled={false}
                                            onClick={() => this.editArt()}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                    {
                                        this.state.estado === 'NACIONAL' ? (
                                            <div
                                                className="button-relatorio"
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    marginTop: '30px',
                                                }}
                                            >
                                                <Button
                                                    width='100%'
                                                    height='50px'
                                                    value='RELATÓRIO PARA BAIXA DE TRT'
                                                    outline={!this.state.loadingR}
                                                    disabled={false}
                                                    onClick={() => this.relarioBaixaTrt()}
                                                    loading={this.state.loadingR}
                                                />
                                            </div>
                                        ) : (null)
                                    }
                                </>
                            )}

                        </form>
                    </Container>
                </ArtsAddContainer>

                <ModalAlert
                    show={this.state.showModalIe}
                    onHide={() => this.setState({ showModalIe: false })}
                    title="Informe a inscrição estadual da sua empresa"
                >
                    <h2 style={{
                        width: '100%',
                        textAlign: 'center',
                        marginBottom: '20px',
                        fontSize: '22px'
                    }}>Informe a inscrição estadual da sua empresa</h2>

                    <div style={{
                        width: '100%',
                        padding: '2% 20%',
                        margin: 'auto'
                    }}>
                        <Input
                            id="insestadual"
                            label="Inscrição Estadual"
                            value={insestadual}
                            maxlength="5000"
                            onChange={(e) => this.setState({ insestadual: e })}
                            onBlur={(e) => this.setState({ insestadual: e.target.value })}
                        />


                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                            <Button
                                width='296px'
                                height='50px'
                                value='CADASTRAR'
                                outline={false}
                                disabled={insestadual.trim() === '' ? true : false}
                                onClick={() => this.enviaInscricao(insestadual)}
                                loading={this.state.loadingT}
                            />
                        </div>
                    </div>
                </ModalAlert>
            </>
        );
    }
}

export default withRouter(EditArts);
