import styled from "styled-components";

export const ContainerFooter = styled.div`
    width: 100%;
    height: 733px;
    background-color: #21262f;

    .section1 {
        width: 100%;
        height: 733px;
        background-color: #21262f;
        
        .row {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding-right: 130px;
            padding-left: 130px;
            position: relative;
            justify-content: center;

            .column {
                width: auto;
                max-width: 20%;
                display: flex;
                flex-direction: column;
                justify-content: left;
                flex-wrap: wrap;
                padding: 10px;
                margin-top: 48px;
                margin-right: 10px;
                margin-left: 10px;

                h2 {
                    color: #fff;
                    font-family: "Lato", Sans-serif;
                    font-size: 10px;
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 1.5em;
                    letter-spacing: 1px;
                    margin-bottom: 24px;

                    a {
                        color: #fff;
                        ont-family: "Lato", Sans-serif;
                        font-size: 10px;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 1.5em;
                        letter-spacing: 1px;
                        margin-bottom: 24px;
                        text-decoration: none;
                    }
                }

                p {
                    color: #8A92A2;
                    font-family: "Lato", Sans-serif;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 2em;
                    
                    a {
                        color: #8a92a2;
                        box-sizing: border-box;
                        font-family: "Lato", Sans-serif;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 2em;
                        text-decoration: none;
                    }
                }


                span {
                    color: #8a92a2;
                    box-sizing: border-box;
                    font-family: "Lato", Sans-serif;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 2em;
                    text-decoration: none;

                    a {
                        color: #589BFF;
                        font-family: "Lato", Sans-serif;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 2em;
                    }
                }

                .social-icons-wrapper{
                    display: flex;
                    flex-direction: row;

                    .item{
                        width: 53px;
                        i {
                            font-size: 24px;
                            color: #fff;

                        }
                        .instagram{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                        .facebook{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                        .linkedin{
                            i:hover{
                                color: #fff;
                                opacity: .9;
                                transform: scale(0.9);
                                transition: all 0.2s;
                            }
                        }
                    }
                }
            }

            .col {
                width: 50%;
                display: flex;
                flex-direction: row;

                p {
                    color: #8a92a2;
                    font-family: "Lato", Sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5em;
                    margin-left: -5px;

                    a {
                        color: #8a92a2;
                        font-family: "Lato", Sans-serif;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 1.5em;
                        text-decoration: none;
                    }
                }
                .linha {
                    color: #8a92a2;
                    font-family: "Lato", Sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.5em;
                    margin-left: 5px;
                    margin-right: 10px;
                }
            }
        }
    }

    .section2 {
        width: 100%;
        height: 43px;
        background-color: #fff;
        display: flex;
        flex-direction: row;
        flex: 1;
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
            color: #21262F;
            font-family: "Lato", Sans-serif;
            font-size: 12px;
            font-weight: 400;
            margin-right: 10px;
            margin-bottom: 0px;
        }
        a {
            img {
                width: 100px;
                height: 13px;
                margin-left: 10px;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        height: auto;

        .section1 {
            height: auto;

            .row {
                padding-right: 10px;
                padding-left: 10px;
                flex-direction: column;

                .column {
                    width: 100%;
                    padding-left: 30px;
                    padding-right: 30px;
                }

                .col {
                    width: 100%;
                    justify-content: center;
                    
                    p {
                        text-align: center !important; 
                        justify-content: center;
                        margin: auto
                    }
                }
            }
        }

        .section2 {
            height: 300px;
            flex-direction: column;
            p {
                margin-top: -170px;
            }
            a {
                img {
                    margin-left: -10px;
                }
            }
        }
    }
`;