import React from "react";

import InputMask from "inputmask";

import { InputContainer } from "./styles";

class Input extends React.Component {
  state = {
    ref: React.createRef()
  };
  componentDidMount() {
    const { ref } = this.state;

    let input = ref.current;

    let im = new InputMask(this.props.mask, { showMaskOnHover: false });

    im.mask(input);

    
  }
  render() {
    const { ref } = this.state;
    return (
      <InputContainer>
        <label>{this.props.label || this.props.placeholder}</label>
        <input  ref={ref} {...this.props}></input> 
        {this.props.unidade? (
          <label className="unidadeDose"> {this.props.unidade} </label>
        ):(null)}
      </InputContainer>
    );
  }
}

export default Input;
