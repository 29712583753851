import React, { useState, useEffect } from 'react';
import { H3, Label, FilterArea, SelectArea, DataFilter, ChartArea, Loading, IconeBotao } from './style';
import api from '../../services/api';
import DateRange from '../date-range';
import { Doughnut, Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { formatDate } from '../../services/formatDate';
import Button from '../button/button_v2';
import { store } from 'react-notifications-component';
import randomColor from 'randomcolor';
import IconeBaixar from '../../assets/iconeBaixar.svg';

ChartJS.register(ArcElement, Tooltip, Legend);

const AlvosMaisFrequentesPorPeriodo = ({ ...props }) => {

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 365));

    const [dataInicial, setDataInicial] = useState(priorDate.toISOString().slice(0, 10));
    const [dataFinal, setDataFinal] = useState(today.toISOString().slice(0, 10));

    const [dataGraph, setDataGraph] = useState([]);
    const [optionsGraph, setOptionsGraph] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const [mostrarBotao, setMostrarBotao] = useState(false);

    const [totalResults, setTotalResults] = useState(0);
    const [loadingPdf, setShowLoadingPdf] = useState(false);

    const searchInitialData = async () => {
        try {
            const response = await api.post('/relatorios/alvos/grafico', {
                dtInicial: dataInicial,
                dtFinal: dataFinal,
            })

            //setTotalResults(response.data.data.qtdReceitas.length);

            let colors = [];
            response.data.data.culturas.map(c =>{
                colors = [...colors, randomColor()];
            });

            if (response.data.data.pests.length > 0 && response.data.data.qtdReceitas.length > 0) {
                setMostrarBotao(true);
            }else{
                setMostrarBotao(false);
            }

            setDataGraph({
                labels: response.data.data.pests,
                datasets: [
                    {
                        data: response.data.data.qtdReceitas,
                        backgroundColor: colors,
                        //borderColor: 'rgb(47, 187, 105, 1)',
                        borderWidth: 1,
                    },
                ],
            });

            // setOptionsGraph({
            //     responsive: true,

            //     indexAxis: 'y',
            //     scales: {
            //         y: {
            //             ticks: {
            //                 color: 'black',
            //                 font: {
            //                     size: 10,
            //                     color: 'black'
            //                 },
            //                 stepSize: 1,
            //                 mirror: true,
            //             },
            //         },
            //     },
            //     plugins: {
            //         legend: {
            //             position: 'top',
            //         },
            //         title: {
            //             display: true,
            //             text: `Alvos mais frequentes de ${formatDate(response.data.data.dtInicial)} à ${formatDate(response.data.data.dtFinal)}`,
            //         },
            //     },
            // });

            setShowChart(true);
        } catch (error) {
            
        }
    }
    
    const updateData = async () => {
        try {
            const response = await api.post('/relatorios/alvos/grafico', {
                dtInicial: dataInicial,
                dtFinal: dataFinal,
            })

            let colors = [];
            response.data.data.pests.map(c =>{
                colors = [...colors, randomColor()];
            });

            if (response.data.data.pests.length > 0 && response.data.data.qtdReceitas.length > 0) {
                setMostrarBotao(true);
            }else{
                setMostrarBotao(false);
            }

            setTotalResults(response.data.data.qtdReceitas.length);

            setDataGraph({
                labels: response.data.data.pests,
                datasets: [
                    {
                        data: response.data.data.qtdReceitas,
                        backgroundColor: ['#2FBB69', '#50C681', '#63CC8F', '#7DD5A2', '#97DDB4'],
                        //borderColor: 'rgb(47, 187, 105, 1)',
                        borderWidth: 1,
                    },
                ],
            });

            // setOptionsGraph({
            //     responsive: true,

            //     indexAxis: 'y',
            //     scales: {
            //         y: {
            //             ticks: {
            //                 color: 'black',
            //                 font: {
            //                     size: 10,
            //                     color: 'black'
            //                 },
            //                 stepSize: 1,
            //                 mirror: true,
            //             },
            //         },
            //     },
            //     plugins: {
            //         legend: {
            //             position: 'top',
            //         },
            //         title: {
            //             display: true,
            //             text: `Alvos mais frequentes de ${formatDate(response.data.data.dtInicial)} à ${formatDate(response.data.data.dtFinal)}`,
            //         },
            //     },
            // });

            setShowChart(true);
        } catch (error) {
            
        }
    }

    const gerarPDF = async () => {
        const payload = {
            dtInicial: dataInicial,
            dtFinal: dataFinal,
        }

        try {
            setShowLoadingPdf(true);

            const response = await api.post(`/relatorios/alvos/pdf`, payload)


            let win = window.open(response.data.data.link);

            setShowLoadingPdf(false);

        } catch (error) {
            setShowLoadingPdf(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    useEffect(() => {
        searchInitialData();
    }, [])

    useEffect(() => {
        updateData();
    }, [dataInicial, dataFinal])

    useEffect(() => {
        if (totalResults > 20) {
            setShowChart(false);

            setTimeout(() => {
                setShowChart(true);
            }, 500);
        }
    }, [totalResults])
    
    return (
        <>
            <div>
                <IconeBotao>
                    <div className='card-titulo'><h2 style={{
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontFamily: 'Lato',
                        fontSize: '10px',
                        color: '#8a92a2',
                        paddingTop: '5px',
                        marginBottom: '0px'
                    }}>Alvos mais frequentes (%)</h2></div>
                    <div className='icons' style={{
                        display:'flex'
                    }}>    
                        <div className='pdf'>
                            <p id='msgBaixarPdf'>Baixar PDF</ p>
                            <a onClick={() => gerarPDF()}><img width={20} src={IconeBaixar} /></a>
                        </div>
                    </div>
                </IconeBotao>
                <p style={{marginBottom:'0px',fontSize: '10px'}}>últimos 12 meses</p>
            </div>
            {showChart && loadingPdf == false? (
                <>

                    <ChartArea style={{
                        padding: '15px 0px',
                        margin: 'auto',
                    }}> 
                        <Doughnut 
                            data={dataGraph} 
                            height={120} 
                            options={{
                                plugins:{
                                    legend:{
                                        position: "right",
                                        labels: {
                                            // This more specific font property overrides the global property
                                            font: {
                                                size: 12
                                            }
                                        }
                                    },
                                    title: {
                                        display: false,
                                        text: 'Alvos mais frequentes',
                                    },
                                },
                                maintainAspectRatio: false,

                            }}
                        />
                    </ChartArea>
                </>
            ) : (<Loading style={{
                width: '60px',
                height: '60px'
            }}/>)}

            {/* <div 
                className="button-finish" 
                style={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end', 
                    marginTop: '10px', 
                    marginBottom: '10px' 
                }}
            >
                <Button
                    width='100px'
                    height='40px'
                    value='GERAR PDF'
                    outline={false}
                    disabled={false}
                    onClick={() => gerarPDF()}
                    loading={loadingPdf}
                    tooltip={true}
                    showTooltipMsg={loadingPdf ? 'Gerando pdf' : 'Baixar relatório em pdf'}
                    tooltipMarginLeft='0px'
                    margin='5px 10px'
                />
            </div> */}
        </>
    )
}

export default AlvosMaisFrequentesPorPeriodo;
