import styled from 'styled-components';
import logoBranco from '../../assets/tour/logoIcone_branco.svg';

export const ContainerSuccess = styled.div`
  background-color: #2FBB69;
  width: 100%;
  transition: all 0.3s ease;
`;

export const Content = styled.div`
  height: 95vh;
  transition: all 0.3s ease;
  width: 100vw;
  display: flex;
  margin-left: ${props => props.marginLeft ? props.marginLeft : '0px'};
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ContainerBackground = styled.div`
  background-image: url(${logoBranco});
  transition: all 0.3s ease;
  background-size: cover;
  -webkit-box-flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-position: ${props => props.position ? props.position : '-880px'} 50%;
  background-repeat: no-repeat;
  flex: 1 1 0%;

  @media only screen and (max-width: 600px){
    background-position: 50%;
    background-size: 439px;
  }
`;

export const FundoSt1 = styled.div`
    position: absolute;
    background-color: white;
    height: 71%;
    width: 272px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media only screen and (max-width: 912px){
        width: 12%;
    }
`;

export const FundoSt2 = styled.div`
    background-color: white;
    border-radius: 20px;
    z-index: 1;
    margin: 0vh 5vw;

    @media only screen and (max-width: 600px){
        height: 360px;
        width: 300px;
        margin-top: 0px;
    }
`;

export const FundoSt3 = styled.div`
    height: 126px;
    border-radius: 20px;
    margin-right: 50vw;
    z-index: 1;
    font-family: 'Lato';

    @media only screen and (max-width: 912px){
        margin-right: 0vw;
    }
`;

export const FundoSt5 = styled.div`
    background-color: white;
    width: 288px;
    position: absolute;
    border-radius: 20px;
    margin-left: 3.7vw;
    z-index: 1;
    height: 40%;

    @media only screen and (max-width: 600px){
        width: 74vw !important;
        position: absolute;
        border-radius: 20px;
        margin-left: -36.3vw !important;
    }

    @media only screen and (max-width: 912px){
        background-color: white;
        width: 50vw;
        position: absolute;
        border-radius: 20px;
        margin-left: -30.3vw;
    }
`;
export const FundoSt6 = styled.div`
    height: 126px;
    border-radius: 20px;
    margin-left: 15vw;
    z-index: 1;
    font-family: 'Lato';

    @media only screen and (max-width: 912px){
        margin-top: -35vh;
    }
    @media only screen and (max-width: 600px){
        width: 74vw !important;
        position: absolute;
        border-radius: 20px;
        margin-left: 0vw;
    }

`;
export const FundoSt = styled.div`
    background-color: white;
    width: 288px;
    position: absolute;
    border-radius: 20px;
    margin-left: 3.7vw;
    z-index: 1;

    @media only screen and (max-width: 600px){
        width: 74vw !important;
        position: absolute;
        border-radius: 20px;
        margin-left: -36.3vw !important;
    }

    @media only screen and (max-width: 912px){
        background-color: white;
        width: 50vw;
        position: absolute;
        border-radius: 20px;
        margin-left: -30.3vw;
    }
`;

export const FundoSt7 = styled.div`
    height: 126px;
    border-radius: 20px;
    margin-left: 40vw;
    z-index: 1;
    font-family: 'Lato';

    @media only screen and (max-width: 912px){
        margin-left: 0px;
    }

    @media only screen and (max-width: 600px){
    }
`;

export const FundoSt4 = styled.div`
    height: 126px;
    border-radius: 20px;
    margin-left: 42vw;
    z-index: 1;
    font-family: 'Lato';
    margin-top: 5vh;

    @media only screen and (max-width: 912px){
        margin-left: 9vw;
        margin-top: 5vh;
    }

    @media only screen and (max-width: 600px){
        margin-left: 0vw;
    }
`;

export const BotaoSt5 = styled.div`
    width: 139px;
    height: 36px;
    background-color: #2fbb69;
    color: white;
    display: flex;
    font-weight: 600;
    border-radius: 50px;
    justify-content: center;
    margin: 10px 0px 20px 16px;
    align-items: center;
    cursor: pointer;

    &:hover{
        height: 40px;
        width: 145px;
        box-shadow: 2px 2px 10px #8080805c;
    }

    @media only screen and (max-width: 600px){
    }
`;

export const BoxSt31 = styled.p`
    margin-top: 5vh;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 22px;
    width: 240px;
    border-radius: 10px;
    padding: 15px 16px;
    font-weight: bold;

    @media only screen and (max-width: 600px){
        font-size: 25px !important;
        width: 275px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 40px;
        width: 330px;
    }
`;
export const BoxSt32 = styled.p`
    margin-top: 4vh;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 16px;
    width: 264px;
    border-radius: 10px;
    padding: 13px 23px;
    margin-left: 10vw;

    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 240px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 35px;
        width: 430px;
    }
`;
export const BoxSt33 = styled.p`
    margin-top: 4vh;
    margin-left: 2vw;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 16px;
    width: 240px;
    border-radius: 10px;
    padding: 13px 23px;

    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 240px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 35px;
        width: 430px;
    }
`;

export const BoxSt41 = styled.div`
    margin-top: 5vh;
    margin-left: 2vw;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 22px;
    width: 240px;
    border-radius: 10px;
    padding: 15px 16px;
    font-weight: bold;

    @media only screen and (max-width: 600px){
        font-size: 25px !important;
        width: 275px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 40px;
        width: 330px;
    }
`;
export const BoxSt42 = styled.div`
    margin-bottom: 1rem;
    background-color: white;
    font-size: 16px;
    width: 275px;
    border-radius: 10px;
    padding: 13px 23px;
    margin-left: -3vw;
    
    @media only screen and (max-width: 912px){
        font-size: 30px;
        width: 430px;
    }
    @media only screen and (max-width: 600px){
        font-size: 16px;
        width: 240px;
    }

`;
export const BoxSt43 = styled.div`
    margin-left: 5vw;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 16px;
    width: 240px;
    border-radius: 10px;
    padding: 13px 23px;

    @media only screen and (max-width: 912px){
        font-size: 30px;
        width: 430px;
    }
    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 240px !important;
    }

`;

export const BoxSt44 = styled.div`
    margin-left: -1vw;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 16px;
    width: 240px;
    border-radius: 10px;
    padding: 13px 23px;
 
    @media only screen and (max-width: 912px){
        font-size: 30px;
        width: 430px;
    }
    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 240px !important;
    }

 `;

export const BoxSt71 = styled.div`
    width: 100px;
    height: 100px;
    position: absolute;
    animation-name: example;
    animation-duration: 2s;
    animation-delay: 0s;
    margin-left: 5px;
    top: 12vh;

    @keyframes example {
        0%   {top:20vh; opacity: 0;}
        100% {top: 12vh;}
    }
    @media only screen and (max-width: 600px){
        margin-top: 8vh;
    }
    @media only screen and (max-width: 912px){
        img{
            display:none;
        }
    }
`;
export const BoxSt75 = styled.div`
    width: 100px;
    height: 100px;
    position: absolute;
    animation-name: example1;
    animation-duration: 2s;
    margin-left: 8px;
    animation-delay: 0s;
    top: 32vh;

    @keyframes example1 {
        0%   {top:40vh; opacity: 0;}
        100% {top: 32vh;}
    }

    @media only screen and (max-width: 600px){
        margin-top: 8vh;
    }
    @media only screen and (max-width: 912px){
        img{
            display:none;
        }
    }

    img{
        height: 13vh !important;
    }
`;
export const BoxSt76 = styled.div`
    width: 100px;
    height: 100px;
    position: absolute;
    top: 48vh;
    animation-name: example2;
    animation-duration: 2s;
    animation-delay: 0s;
    margin-left: 8px;

    @keyframes example2 {
        0%   {top: 50vh; opacity: 0;}
        100% {top: 48vh;}
    }

    @media only screen and (max-width: 600px){
        margin-top: 8vh;
    }

    @media only screen and (max-width: 912px){
        img{
            display:none;
        }
    }

    img{
        height: 13vh !important;
    }
`;

export const BoxSt72 = styled.p`
    margin-bottom: 5vh;
    background-color: white;
    font-size: 25px;
    width: 275px;
    border-radius: 10px;
    padding: 20px 23px;
    font-weight: bold;
    margin-left: -3vw;

    @media only screen and (max-width: 600px){
        font-size: 25px !important;
        width: 275px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 40px;
        width: 330px;
    }
`;
export const BoxSt73 = styled.p`
    margin-left: 8vw;
    margin-bottom: 5vh;
    background-color: white;
    font-size: 16px;
    width: 240px;
    border-radius: 10px;
    padding: 13px 23px;

    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 240px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 35px;
        width: 430px;
    }
`;

export const BoxSt74 = styled.p`
    margin-left: -7vw;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 16px;
    width: 244px;
    border-radius: 10px;
    padding: 13px 23px;

    @media only screen and (max-width: 600px){
        margin-left: 0vw !important;
        font-size: 16px !important;
        width: 244px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 35px;
        width: 351px;
    }
`;

export const BoxSt61 = styled.p`
    margin-bottom: 5vh;
    background-color: white;
    font-size: 25px;
    width: 275px;
    border-radius: 10px;
    padding: 20px 23px;
    font-weight: bold;
    margin-left: 5vw;

    @media only screen and (max-width: 600px){
        font-size: 25px !important;
        width: 275px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 40px;
        width: 330px;
    }
`;
export const BoxSt62 = styled.p`
    margin-left: -9vw;
    margin-bottom: 5vh;
    background-color: white;
    font-size: 16px;
    width: 240px;
    border-radius: 10px;
    padding: 13px 23px;

    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 240px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 35px;
        width: 430px;
    }
`;

export const BoxSt63 = styled.p`
    margin-left: 3vw;
    margin-bottom: 1rem;
    background-color: white;
    font-size: 16px;
    width: 244px;
    border-radius: 10px;
    padding: 8px 11px;

    @media only screen and (max-width: 600px){
        margin-left: 0vw !important;
        font-size: 16px !important;
        width: 244px !important;
    }

    @media only screen and (max-width: 912px){
        font-size: 35px;
        width: 351px;
    }
`;

export const TitleWel = styled.h3`
    color: #000;
    width: 100%;
    text-align: center;
    margin: 40px 0px 58px 0px;
    line-height: inherit;
    font-weight: bold;
    font-family: Lato;

    @media only screen and (max-width: 600px){
        font-size: 20px;
        line-height: 1.7;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const ImgFundo = styled.div`
    display: flex;
    position: absolute;
    width: 295px;
    justify-content: flex-start;
    overflow: hidden;
    margin-right: 78.5vw;

  img {
    height: 618px;
    opacity: 0.2;
    margin: 0px 0px 0px -318px;
  }

  @media only screen and (max-width: 912px){
    display:none;
  }

  
`;

export const Title = styled.h2`
    color: #fff;
    width: 100%;
    text-align: center;
    margin: 80px 0px 55px 0px;
    line-height: inherit;
    font-weight: bold;
    font-family: Lato;

    @media only screen and (max-width: 600px){
        font-size: 20px;
        margin: 47px 0px 55px 0px;
    }
`;

export const ImgStep02 = styled.div`
    img{
        max-width: 54vw;
    }

    @media only screen and (max-width: 600px){
        img{
            display: none;
        }
    }

    @media only screen and (max-width: 912px){
        img{
            display: none;
        }
    }
`;

export const ImgStep04 = styled.div`
    position: absolute;
    margin-left: 0vw;
    margin-top: 4vh;

    img{
        width: 34vw;
    }

    @media only screen and (max-width: 912px){
        img{
            display: none;
        }
    }
`;

export const ImgStep07 = styled.div`
    position: absolute;
    margin-left: 0vw;

    @media only screen and (max-width: 912px){
        img{
            display:none;
        }
    }
`;

export const ImgStep03 = styled.div`
    position: absolute;
    margin-left: 33vw;
    width: 100%;
    z-index: 9;
    img{
        z-index: 1;
        width: 27vw;
    }

    @media only screen and (max-width: 912px){
        img{
            display: none;
        }
    }
`;

export const ImgStep05 = styled.div`
    position: absolute;
    margin-left: -37.5vw;
    margin-top: -5vh;
    width: 100%;

    img{
        width: 41.2vw;
    }

    @media only screen and (max-width: 912px){
        img{
            display:none;
        }
    }
`;

export const ImgStep06 = styled.div`
    margin-left: 5vw;
    width: 100%;

    img{
        width: 36vw;
    }

    @media only screen and (max-width: 912px){
        img{
            display:none;
        }
    }
`;

export const TitleS2 = styled.p`
    color: white;
    text-align: justify;
    margin: 15px 0px;
    line-height: inherit;
    font-weight: bold;
    width: 74vw;
    font-family: Lato;
    font-size: 30px;

    @media only screen and (max-width: 600px){
        font-size: 20px;
        margin: 15px 0px 18px 0px;
    }
`;

export const TitleS5 = styled.p`
    color: white;
    text-align: left;
    margin: 15px 32vw 15px 0px;
    line-height: inherit;
    font-weight: bold;
    width: 34vw;
    font-family: Lato;
    font-size: 30px;

    @media only screen and (max-width: 912px){
        width: 84vw;
        margin: 15px 0px 18px 0px;
    }
    @media only screen and (max-width: 600px){
        font-size: 20px;
    }
`;

export const Text = styled.p`
    color: #000;
    text-align: center;
    width: 100%;
    line-height: inherit;
    font-family: Lato;
    font-size: 24px;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 75%;

    @media only screen and (max-width: 600px){
        font-size: 15px;
        width: 100%;
    }
`;

export const BoxTextoFoto = styled.div`
    display: flex;
    width: 84vw;
    justify-content: center;

    @media only screen and (max-width: 600px){
        img{
            display: none;
        }
    }
`;

export const BoxTextoFotoSt4 = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 6vh;
 
    @media only screen and (max-width: 600px){
        margin-left: 0vw;

        img{
            display: none;
        }
    }
`;

export const BoxTextoFotoSt7 = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 18vh;

    @media only screen and (max-width: 600px){
        margin-left: 0vw;

        img{
            display: none;
        }
    }
`;


export const BoxTextoFotoSt3 = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 16vw;
    margin-top: -45vh;

    @media only screen and (max-width: 600px){
        margin-left: 0vw;

        img{
            display: none;
        }
    }
`;

export const BoxTextoFotoSt5 = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 10vw;
    align-items: center;
    height: 100%;

    @media only screen and (max-width: 600px){
        margin-left: 0vw;

        img{
            display: none;
        }
    }
`;

export const Option = styled.p`
    color: #000;
    text-align: left;
    line-height: inherit;
    font-family: Lato;
    font-size: 17px;
    padding: 2px 22px;

    @media only screen and (max-width: 600px){
        font-size: 15px !important;
        width: 100%;
    }

    @media only screen and (max-width: 912px){
        font-size: 28px;
        width: 100%;
    }
`;
export const OptionSt6 = styled.p`
    color: #000;
    text-align: left;
    line-height: inherit;
    font-family: Lato;
    font-size: 17px;
    padding: 0px;

    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 100%;
    }

    @media only screen and (max-width: 912px){
        font-size: 28px;
        width: 100%;
    }
`;

export const OptionSt5 = styled.p`
    color: #000;
    text-align: left;
    line-height: inherit;
    font-family: Lato;
    font-size: 17px;
    padding: 2px 22px;

    @media only screen and (max-width: 600px){
        font-size: 16px !important;
        width: 100%;
    }

    @media only screen and (max-width: 912px){
        font-size: 28px;
        width: 100%;
    }
`;


export const OptionTitle = styled.p`
    color: #000;
    text-align: left;
    line-height: inherit;
    font-family: Lato;
    font-weight: bold;
    width: 50%;
    font-size: 24px;
    padding: 9px 20px;
    margin-bottom: 8px;

    @media only screen and (max-width: 600px){
        font-size: 15px !important;
        width: 100%;
    }

    @media only screen and (max-width: 912px){
        color: #000;
        text-align: left;
        line-height: inherit;
        font-family: Lato;
        font-weight: bold;
        width: 50%;
        font-size: 35px;
        padding: 9px 20px;
        margin-bottom: 8px;
    }
`;

export const OptionTitleSt5 = styled.p`
    color: #000;
    text-align: left;
    line-height: inherit;
    font-family: Lato;
    font-weight: bold;
    width: 50%;
    font-size: 24px;
    padding: 9px 20px;
    margin-bottom: 8px;

    @media only screen and (max-width: 600px){
        font-size: 15px !important;
        width: 100%;
    }

    @media only screen and (max-width: 912px){
        color: #000;
        text-align: left;
        line-height: inherit;
        font-family: Lato;
        font-weight: bold;
        width: 50%;
        font-size: 35px;
        padding: 9px 20px;
        margin-bottom: 8px;
    }
`;

export const Icon02 = styled.div`
    margin-left: 80%;
    margin-top: 5%;
    position: absolute;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
`;

export const Icon03 = styled.div`
    margin-right: 80%;
    transform: rotate(180deg);
    margin-top: 5%;
    position: absolute;
    height: 40px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
`;

export const Icon = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    img{
        height: 100%;
    }

    @media only screen and (max-width: 600px){
        height: 230px;
    }

`;