import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Input from "../input/input_v2";
import Button from '../button/index';
import api from "../../services/api";
import { store } from "react-notifications-component";
import { ModalBody, ModalTitle } from './styles';
import Modal from '.';

class ModalSenha extends React.Component {
    constructor(props) {
        super(props);
    }
    state ={
      username: ''
    }

    updateResponseYes = () => {
        let resposta = 1;
        this.props.update(resposta);
    }

    updateResponseNo = () => {
        let resposta = 0;
        this.props.update(resposta);
    }

    handleSignIn = async() => {
      const {username} = this.state;
      const payload = {
          username: username
        }
        console.log(payload);
      try{
        await api.post("/recover", payload);
        let resposta = 10;
        this.props.update(resposta, this.state.username);
      }catch (err) {
        // if(err.response.status == 500){
        //     window.location = '/login';
        // }else{
            const errors = await err.response.data.errors;
            
            store.addNotification({
                title: "Erro!",
                message: `${errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            // errors.map(errror => {    
            // })
        // }
      }
    }

    render() {
        return (
            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
            >
                <ModalTitle> {this.props.title} </ModalTitle>

                <ModalBody>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column', alignItems: 'center'}}>
                        {/* <p style={{fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', textAlign: 'center', width: '100%', alignSelf: 'center'}}>{this.props.title}</p> */}
                        <p style={{marginTop: '-18px', color: '#bac1cf', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center'}}>{this.props.text}</p>
                            
                            <div style={{ width: '100%', marginTop: '16px' }}>
                                <Input
                                    id='email-recover'
                                    label="E-mail"
                                    name="email"
                                    type="email"
                                    onChange={e => this.setState({ username: e })}
                                    value={this.state.username}
                                />
                            </div>

                        <Button 
                            style={{
                                outline:0, 
                                padding: '15px', 
                                width: '170px', 
                                alignSelf: 'center'
                            }} 
                            color="#00b43f" 
                            onClick={() => this.handleSignIn()} 
                        >
                            ALTERAR SENHA <i className="fa fa-arrow-right"></i>
                        </Button>
                    </div>
                </ModalBody>
            </Modal>

        );
    }
}
export default ModalSenha;