import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import { store } from "react-notifications-component";
import { Container, ScreenClassRender } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import CardBlog from "../../components/card-blog/card";
import DotsLoader from "../../components/loader";


import Paginator from '../../components/paginator';


import { BlogContainer, GuiaAplicacaoContainer } from "./styles";
export const USER = "@userinfo";

const Blog = ({ ...props }) => {

    const [result, setResult] = useState('');
    const [showFilter, setShowFilter] = useState(false);
    const [screenSize, setScreenSize] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [screenSize2, setScreenSize2] = useState(window.screen.width);

    const [limit, setLimit] = useState(9);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);

    const [materias, setMaterias] = useState([]);
    const [load, setLoad] = useState(false);
    const [totalReceitas, setTotalReceitas] = useState(0);


    useEffect(()=>{
        setScreenSize2(window.screen.width);
    },[window.screen.width])

    const getScreenSize = screensize => {
        const sizesToHide = ["xl", "lg"];
        const hide = sizesToHide.includes(screensize);
        const prevScreenSize = screenSize;
        

        if (prevScreenSize !== screensize) {
            if (hide) {
                setScreenSize(screensize);
                setIsMobile(false);
                
            } else {
                setScreenSize(screensize);
                setIsMobile(true);
            }
        }
    };

    const modalClose = e => {
        
        if(showFilter == true){
            setShowFilter(false);
        }
    };


    const search = async (result) => {
        if ((!result || result == '')) {
            setMaterias([]);
            setLoading(true);

            setOffset(0);
            getMaterias(limit, offset);
        }else{
            if (result.length > 0 ) {
                try {
                    setMaterias([]);
                    setLoading(true);

                    let payload = {
                        search: result ? result : "",
                    }
    
                    const response = await api.post(`/blog?limit=${limit}&offset=${offset}`, payload);
    
                    setMaterias(response.data.data);
    
                    setLoad(true);
                    setLoading(false);
    
                    if (totalReceitas !== response.data.message) {
                        updatePaginator(response.data.message);
                    }
    
                    setTimeout(() => {
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'smooth'
                        });
                    }, 200);
                
                } catch (error) {
                    setLoading(false);

                    store.addNotification({
                        title: "Erro!",
                        message: `${error.response.data.errors}`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        }

    }
 
    const onKeyDown = (e) => {

        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            setOffset(0);
            setResult(e.target.value);
            search(e.target.value);
        }else {
            // if(e.target.value){
            //     setResult(e.target.value);
            //     search(e.target.value);
            // }else if (e.target.value == ''){
            //     setResult(e.target.value);
            //     search(e.target.value);
            // }
        }
    };

    const updatePaginator = (total) => {
        setTotalReceitas(0);

        setTimeout(() => {
            setTotalReceitas(total);
        }, 100);
    }
    const getMaterias = async (limit, offset) => {
        if (limit != 0) {
            setOffset(offset);
    
            try {
                setLoading(true);

                let payload = {
                    search: result ? result : "",
                }

                const response = await api.post(`/blog?limit=${limit}&offset=${offset}`, payload);

                setMaterias(response.data.data);

                setLoad(true);
                setLoading(false);

                if (totalReceitas !== response.data.message) {
                    updatePaginator(response.data.message);
                }

                setTimeout(() => {
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'smooth'
                    });
                }, 200);
    
                
            } catch (error) {
               setLoading(false);
            }
        }
    };

    const updateLimitOffset = (l,o) => {
        if (l !== limit || o !== offset) {
            setLimit(l);
            setOffset(o);
            getMaterias(l, o);
        }
    }
    const updateValues = async (text, estado, pesquisa) => {
        
        // if (pesquisa == 1) {
        //     if (text) {  
                setResult(text);
                setOffset(0);
                search(text);
        //     }else{
        //         search();
        //     }
        // }
    };

    useEffect(() => {
        let pdf = localStorage.getItem('@prescription');
        if(pdf){
            window.open(pdf);
            localStorage.removeItem('@prescription');
        }
        getMaterias(limit, 0);

    }, []);

    useEffect(() => {
        if(result){
            search(result);
        }else{
            search('');
        }
    }, []);

    
    return (
        <ScreenClassRender
        render={screenClass => (
            <>
                {getScreenSize(screenClass)}

                <GenericUpper
                    title="Blog AgroReceita"
                    subtitle="Procure pelas matérias que deseja visualizar."
                    search={true}
                    searchFunction={(text) => updateValues(text, '', 1)}
                    placeholder="O que você está procurando hoje?"
                ></GenericUpper>

                <BlogContainer isMobile={isMobile}>
                    <Container id='container' style={{ maxWidth: '700px' }}>
                        <div className="search">
                            {screenSize2 > 768 && (
                                <div>
                                    
                                </div>
                            )}

                            {screenSize2 > 768 ? (<>
                                <div className="exibe-options">
                                    
                                </div>
                            </>):(<>
                                <div style={{display: 'flex', justifyContent: 'space-around', marginTop: '10px' }}>
            
                                </div>
                            </>)}
                        </div>

                        {loading ? (
                            <div className="loading" style={{
                                width: '100%',
                                height: '50vh',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <DotsLoader show={loading}></DotsLoader>
                            </div>
                        ) : (
                            <>    
                                {materias.length > 0 ? (
                                    <>
                                       <CardBlog 
                                            materias={materias}
                                            isMobile={isMobile}
                                            propsLocation={props}
                                        />
                                    </>
                                ) : (
                                    <div className="loading" style={{
                                        width: '100%',
                                        height: '25vh',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                        <h4 style={{textAlign: 'center'}}>Nenhuma matéria encontrada.</h4>
                                    </div>
                                )}
                            </>

                        )}
                
                        <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                            <Paginator perPage={limit} total={totalReceitas} totalItens={materias.length} onChange={(limit, offset) => updateLimitOffset(limit, offset)}/>
                        </div>

                    </Container>
                </BlogContainer>
            </>
        )}
    />);
};

export default Blog;
