import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { render } from 'react-dom';
import { Card, Row, Title, Calendario, CardTarefa } from "../../agenda/resumo/styles"
import api from "../../../services/api";
import { Container, ScreenClassRender } from "react-grid-system";
import { Loading } from '../../relatorios/style';
import Calendar from 'react-calendar';
import IconSchedule from "../../../assets/icons/iconSchedule";
import IconArrowRight from "../../../assets/icons/iconArrowRight";
import { formatDateTime } from '../../../services/formatDate';
import { Link } from "react-router-dom";
import NewsAgenda from "../../../components/news-home/agenda";


const ResumoAgenda = ({ ...props }) => {

    const [value, onChange] = useState(new Date());
    const [agendas, setAgendas] = useState([]);
    const [loading, setLoading] = useState(true)

    const getAgendas = async () => {
        try {
            setLoading(true)
            const response = await api.get('/agenda/resumo')

            if (response) {
                setLoading(false)
                const agendas = response.data.data
                setAgendas(agendas)
            }
        } catch (error) {
            setLoading(false)

        }

    }

    useEffect(() => {
        getAgendas()
    }, [])

    return (

        <>
            <Card
                width="100%"
                height="556px"

            >

                <div style={{
                    width: '100%',
                    maxWidth: '260px',
                    margin: 'auto'
                }}>
                    <Calendario>
                        <Calendar
                            calendarType={"US"}
                            formatShortWeekday={(locale, value) => ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'][value.getDay()]}
                            onChange={onChange} value={value} 
                        />
                    </Calendario>
                </div>

                {agendas.length > 0 ? (
                    <>
                        <Row justifyContent='flex-start'>
                            <Title>Compromissos</Title>
                            <Link to="/agenda" style={{ marginTop: '-4px', textDecoration: "none" }}>
                                <div className="ver_mais">Ver mais</div>
                            </Link>

                        </Row>
                        <hr width="100%"></hr>
                        {loading ? (
                            <div><Loading /></div>
                        ) : (
                            <>
                                {agendas.map(agenda => (
                                    <CardTarefa>
                                        <div className="row">
                                            <div className="icone"><IconSchedule width="30px" height="30px" /></div>
                                            <div className="column">
                                                <div className="tarefa">{agenda.title}</div>
                                                <div className="dados">{formatDateTime(agenda.date_begin)}</div>
                                            </div>
                                        </div>
                                    </CardTarefa>
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <NewsAgenda
                            title1={`Agenda de compromissos`}
                            text={`Cadastre suas tarefas, reuniões e compromissos.`}
                            data1={`99/99/9999`}
                            href1={`/agenda`}
                        // image={`${image1}`}
                        >
                        </NewsAgenda>
                    </>
                )}
            </Card>
        </>
    );
};

export default ResumoAgenda;
