import api from "./api";
import React, { useState, useEffect } from "react";
import modalExit from "../components/modal/modalExit";
import axios from "axios";

export const TOKEN_KEY = "@agroReceita-Token";
export const AUTH_TOKEN_RD = "@agroReceita_rd_auth_token";
export const REFRESH_TOKEN_RD = "@agroReceita_rd_refresh_token";
export const EXPIRATION = "@expiration";
export const USER = "@userinfo";
export const TERM = "@terms";
export const PLAN_NAME = "@plan_name";
export const screen = "@screen";
export const aviso = "@aviso";
export const link = "@link";
export const USER_VALIDADE = "@validade";
export const titulo = "@titulo";
export const subtitulo = "@subtitulo";
export const open = "@open";
export const logged = "@logged";
export const USER_ATIVO = "@user_ativo";  //verificar no login se usuário já está logado
export const USER_ID = "@agroreceita_useridentify";

export const GOOGLE_EMAIL = '@agroreceita_google_email';
export const GOOGLE_USER = '@agroreceita_google_user';
export const GOOGLE_USER_PROFILE = '@agroreceita_google_user_profile';

export const EXIT = '@agroreceita_exit';

export const isAuthenticated = () => {


  let expiration = localStorage.getItem(EXPIRATION);
  let now = new Date();

  if (expiration < now) {
    return false;
  }
  return true;
};

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const getTokenRd = () => localStorage.getItem(AUTH_TOKEN_RD);

export const login = (userData, screen) => {

  let now = new Date();
  localStorage.setItem(open, false);
  localStorage.setItem(PLAN_NAME, userData.user.contrato.agro_plan_config.nome_plano);
  localStorage.setItem(logged, userData.logged);
  localStorage.setItem(TOKEN_KEY, userData.token);

  localStorage.setItem(EXPIRATION, now.setHours(now.getHours() + 10));
  localStorage.setItem(USER, JSON.stringify(userData.user));
  localStorage.setItem(USER_ID, JSON.stringify(userData.user.id));

  localStorage.setItem(screen, '@screen1');
  localStorage.setItem(USER_ATIVO, "0");
  localStorage.setItem(EXIT, "0");

  localStorage.setItem(TERM, JSON.stringify(userData.termos));
};

export const logout = async () => {
  localStorage.setItem(EXIT, "1");

  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EXPIRATION);
  localStorage.removeItem(USER_VALIDADE);
  localStorage.removeItem(TERM);
  localStorage.removeItem(USER_ATIVO);
  localStorage.removeItem(PLAN_NAME);
  localStorage.removeItem(aviso);
  localStorage.removeItem(link);
  localStorage.removeItem(titulo);
  localStorage.removeItem(open);
  localStorage.removeItem(subtitulo);
  localStorage.removeItem(logged);
  localStorage.removeItem('@useraccesskey');
  localStorage.removeItem('@agroreceita_linkedin_id');
  localStorage.removeItem('@agroreceita_linkedin_nome_user');
  localStorage.removeItem('@agroreceita_linkedin_email');
  localStorage.removeItem('@agroreceita:exist_prescription');


  localStorage.removeItem(GOOGLE_EMAIL);
  localStorage.removeItem(GOOGLE_USER);
  localStorage.removeItem(GOOGLE_USER_PROFILE);

  const a = localStorage.getItem(USER);
  let obj = JSON.parse(a);
  localStorage.removeItem(USER);

  if (obj) {
    try {
      const response = await api.post(`/logout/${obj.id}?action=ok`);
    } catch (err) {
      const response = await api.post(`/logout/${obj.id}`);
    }
    // return response.data.data
  }
};

export const clearSession = async () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EXPIRATION);
  localStorage.removeItem(USER_VALIDADE);
  localStorage.removeItem(USER_ATIVO);
  localStorage.removeItem(aviso);
  localStorage.removeItem(link);
  localStorage.removeItem(titulo);
  localStorage.removeItem(open);
  localStorage.removeItem(subtitulo);
  localStorage.removeItem(USER);
  localStorage.removeItem(logged);
  localStorage.removeItem(GOOGLE_EMAIL);
  localStorage.removeItem(GOOGLE_USER);
  localStorage.removeItem(GOOGLE_USER_PROFILE);
  localStorage.removeItem('@agroreceita_linkedin_id');
  localStorage.removeItem('@agroreceita_linkedin_nome_user');
  localStorage.removeItem('@agroreceita_linkedin_email');
  localStorage.removeItem('@store');

}

export const authRD = async () => {

  const headers = {
    'Content-Type': 'application/json'
  }

  axios.get(`${process.env.REACT_APP_API_HOST}/rd/auth`, {
    'apiKey': process.env.REACT_APP_API_KEY
  }).then(rdAuth => {
    localStorage.setItem(AUTH_TOKEN_RD, rdAuth.data.data.token);
    localStorage.setItem(REFRESH_TOKEN_RD, rdAuth.data.data.refresh_token);
  })


}
export const checkSession = async () => {

  const userinfo = JSON.parse(localStorage.getItem(USER));

  if (userinfo) {
    let userTicketLogin = userinfo.agro_session_ticket;

    if (userTicketLogin && userTicketLogin !== undefined && userTicketLogin !== null) {
      try {
        const response = await api.get('/session_ticket');

        if (response) {
          if (userTicketLogin !== response.data.data[0].agro_session_ticket) {
            return false;
          } else {
            return true;
          }
        }
      } catch (error) {
        return true;

      }
    } else {
      return true;
    }
  } else {
    return true;
  }


}

export const validateTest = async () => {

  const userinfo = JSON.parse(localStorage.getItem(USER));
  if (userinfo) {
    if (userinfo.contrato.dias_restantes >= 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }

}

export const validaIsTest = async () => {

  const userinfo = JSON.parse(localStorage.getItem(USER));
  if (userinfo) {
    if (userinfo.contrato.agro_plan_config.is_teste == 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }

}

