import React from "react";
import { withRouter } from "react-router-dom";

import { Container } from "react-grid-system";
import api from "../../services/api";
import GenericUpper from "../../components/generic-upper";
import Table from "../../components/table";
import TBody from "../../components/tbody";
import ButtonOld from "../../components/button";
import Button from "../../components/button/button_v2";
import DotsLoader from "../../components/loader";
import InputSearch from "../../components/input-search";
import Importacao from "../../components/modal/modalImportacao";
import { ArtsContainer, ProgressBar, Progress } from "./styles";
import CardGeral from "../../components/card-geral/index";
import ModalTesteGratisAcabou from '../../components/modal/modalFuncaoBloqueada';
import Paginator from "../../components/paginator";

export const USER = "@userinfo";
export const VAL = "@validade";


class Arts extends React.Component {
    state = {
        arts: [],
        limit: 30,
        offSet: 0,
        totalPages: 0,
        loading: false,
        loadingMore: false,
        hideButton: false,
        result: "",
        result2: "",
        result3: "",
        result4: "",
        resultEstado: "",
        progressBar: '',
        size_screen: window.screen.width,
        importar: false,
        modalTesteGratisAcabou: false,
        user: JSON.parse(localStorage.getItem(USER)),
        val: JSON.parse(localStorage.getItem(VAL)),
        loadingSearch: false,
    };

    componentDidMount() {
        this.setState({size_screen: window.screen.width});
        this.search('', '', 30, 0);
    }

    updateResposta = async (resposta) => {
        if (resposta == 0) {
            this.setState({importar: false})
        }
    }

    updateValues = async (text, estado, search) => {
        if (search == 1) {
            if(!text && !estado){
                this.search('', '', 30, 0);
            }else{   
                this.setState({ result2: estado });
                this.setState({ result: text });
                this.search(text, estado, this.state.limit, this.state.offset);
            }
        }else{
            this.setState({result: text});
            this.setState({result2: estado});
        }
    }

    calculateProgress = (receitasEmitidas, quantidadeReceitas, quantidadeContratos, totalContratos) => {
        if ((receitasEmitidas >= 0) && (quantidadeReceitas || quantidadeContratos)) {

            if (!quantidadeContratos) {
                let porcentagemReceitasEmitidas = ((receitasEmitidas * 100) / quantidadeReceitas);
                let subProgress = ((120 * porcentagemReceitasEmitidas) / 100);
                let value = 120 - subProgress;

                let progress = parseInt(value);

                return progress + "px";

            } else {
                

                if(totalContratos){
                    let porcentagemReceitasEmitidas = ((totalContratos * 100) / quantidadeContratos);
                    let subProgress = ((120 * porcentagemReceitasEmitidas) / 100);
                    let value = 120 - subProgress;
                    let progress = parseInt(value);
                    return progress + "px";
                }else{
                    let porcentagemReceitasEmitidas = ((0 * 100) / quantidadeContratos);
                    let subProgress = ((120 * porcentagemReceitasEmitidas) / 100);
                    let value = 120 - subProgress;
                    let progress = parseInt(value);
                    return progress + "px";
                }
            }
        }
    }

    getArts = async () => {

        try {
            const { limit } = this.state;
            const response = await api.get("/arts?limit=" + limit);
            const arts = response.data.data;

            if (arts && arts.length > 0) {
                this.setState({ arts, loadingSearch: false, hideButton: false });
            } else {
                this.setState({ loadingSearch: false, hideButton: false });
            }
        } catch (error) {
            this.setState({ loadingSearch: false, hideButton: true });
        }
    };

    search = async ( result, result2, limit, offset ) => {

        this.setState({ arts: [], loadingSearch: true })

        const search = result;
        const estado = result2;

        try {

            const payload = {
                search,
                estado
            };

            const response = await api.post(`/art/pesquisa?limit=${limit}&offset=${offset}`, payload);

            this.setState({ 
                totalPages: response.data.data.total, 
                arts: response.data.data.arts,
                loadingSearch: false
            });
        } catch (error) {
            this.setState({ loadingSearch: false });
        }

    };

    getXlms = async () => {
        this.setState({ loading: true });
        try {
            const response = await api.get("/relatorios/arts", {
                responseType: "blob"
            });
    
            let blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "RelatorioARTS.xlsx";
            link.href = url;
            link.click();
            this.setState({ loading: false });
        
        } catch (error) {
            this.setState({ loading: false });
        }
    };

    cleanFilter = () => {
        this.setState({
            result: "",
            result2: "",
            result3: "",
            result4: "",
            offSet: 0
        });
        this.serch('', '', 30, 0);
    };

    loadMore = async () => {
        this.setState({ loadingMore: true, hideButton: true });
        const { limit, offSet, arts } = this.state;
        const newOffset = limit + offSet;

        try {
            const response = await api.get(
                "/arts?limit=" + limit + "&offset=" + newOffset
            );
            let newArts = response.data.data;
            const artsConCat = arts.concat(newArts);
            this.setState({
                arts: artsConCat,
                offSet: newOffset,
                hideButton: false
            });
        } catch (erro) {
            this.setState({
                hideButton: true
            });
        }
        this.setState({ loadingMore: false });
    };

    onKeyDown = (e) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            this.search(this.state.result, this.state.result2, 30, 0);
        }
    }

    openAdd = () => {
        this.props.history.push('/arts/add');
    }

    updateLimitOffset = (l,o) => {
        if (l !== this.state.limit || o !== this.state.offset) {
            this.setState({
                limit: l,
                offset: o
            })

            this.search(this.state.result, this.state.result2, l, o);
        }
    }

    render() {
        const {
            arts,
            loadingSearch,
            hideButton,
            result,
            size_screen,
            importar,
            modalTesteGratisAcabou,
            user,
            val,
            limit, 
            totalPages
        } = this.state;

        return (
            <>
                <GenericUpper 
                    title="ARTs/TRT" 
                    subtitle="Gerencie suas ARTs/TRT"
                    search={true}
                    searchFunction={(text, estado, search) => this.updateValues(text, estado, search)}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    estados={true}
                    placeholder="Número, Resp. Técnico, Loja ou CREA/Visto"
                    addButton={true}
                    tooltip={"Adicionar"}
                    linkSearch = {'/arts/add'}
                ></GenericUpper>

                <ArtsContainer isIndex={true}>
                    <Container  style={size_screen <= 768 ? {paddingLeft:"0px", paddingRight:"0px", margin: 'auto'} : {}}>
                
                    {size_screen <= 768 ? (<>
                        <div id="divCards" className="search" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '0px'}}>
                            <CardGeral
                                titulos={ ["ARTS","RESPONSÁVEIS TÉCNICOS", "NÚMERO","LOJA","ESTADO","RECEITAS EMITIDAS/CONTRATOS"]}
                                valores={arts}
                            />

                            <DotsLoader show={loadingSearch}></DotsLoader>

                            <Paginator 
                                perPage={limit} 
                                total={totalPages} 
                                onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                                totalItens={arts.length}
                            />

                            <div 
                                className="button-finish" 
                                style={{ 
                                    width: '100%', 
                                    height: '200px',
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center', 
                                }}
                            >
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='ADICIONAR ART/TRT'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => this.openAdd()}
                                    loading={false}
                                />
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='RELATÓRIO'
                                    outline={!this.state.loading}
                                    disabled={false}
                                    onClick={() => this.getXlms()}
                                    loading={this.state.loading}
                                />
                            </div>

                        </div>
                    
                    </>) : (<>

                        <Importacao
                            style={{display: "flex", justifyContent: "center"}}
                            show={importar}
                            onHide={e => {this.setState({importar: false})}}
                            step={1}
                            fields={[
                                "Estado",
                                "Responsável Técnico",
                                "CREA/Visto/CFTA",
                                "Número ART",
                                "Receita Inicial",
                                "Quantidade de contratos",
                                "Número da ordem",
                                "Produtor",
                                "Local de Aplicação",
                                "Valor do contrato",
                                "Data Inicial",
                                "Data Final"
                            ]}
                            resposta=''
                            update={this.updateResposta}
                            title='Importar ARTs/TRT'
                        ></Importacao>

                        <Table>
                            <thead>
                                <tr>
                                    <th>Responsáveis técnicos</th>
                                    <th>Número</th>
                                    <th>Loja</th>
                                    <th>Estado</th>
                                    <th>Receitas emitidas/Contratos</th>
                                </tr>
                            </thead>
                            <TBody>
                                {arts.map(art => (
                                    <>
                                    {art.length !== 0 ? (
                                        <tr
                                            onClick={e => {
                                                if(user.contract_software_id == 6 && val == 0) {
                                                    this.setState({modalTesteGratisAcabou: true})
                                                }else{
                                                    this.props.history.push(`arts/${art.id}`)
                                                }
                                            }}
                                            key={art.id}
                                        >
                                            
                                            {art.crea.tipo == 'crea' ? (<td>{art.crea.agronomo.nome} <br></br> <label>Crea: {art.crea.crea} </label></td>) : (
                                            <>
                                                {art.crea.tipo == 'visto' ? (<td>{art.crea.agronomo.nome} <br></br> <label>Visto: {art.crea.crea}</label></td>):(<td>{art.crea.agronomo.nome} <br></br> <label>Cfta: {art.crea.crea}</label></td>)}
                                            </>)}

                                            {art.status == 0 ? (
                                                <>
                                                    <td><strike>{art.numero_art}</strike></td>
                                                    <td><strike> <div className="art-grid">{art.crea.agronomo.filial.nome_fantasia}</div></strike></td>
                                                    <td><div className="estado-inativo">{art.crea.estado}</div></td>
                                                </>
                                            ):(
                                                <>
                                                <td>{art.numero_art}</td>
                                                <td> <div className="art-grid">{art.crea.agronomo.filial.nome_fantasia}</div></td>
                                                <td><div className="estado">{art.crea.estado}</div></td>
                                                </>
                                            )}


                                            <td>
                                                <ProgressBar
                                                    progress={this.calculateProgress(art.receitas_emitidas, art.quantidade_receitas, art.quantidade_contratos, art.contratos.qtde_contratos)}
                                                >
                                                    <Progress>

                                                    </Progress>
                                                </ProgressBar>
                                                <div className="quantidade-progress">
                                                    {art.crea.estado == 'SP' ? (<>
                                                    {art.contratos.qtde_contratos ? (<>{art.contratos.qtde_contratos}</>):(<>0</>)} / {art.quantidade_contratos} - <label className="tipoArt"> CONTRATOS </label>
                                                    
                                                    </>):(<>
                                                        {art.receitas_emitidas} / {art.quantidade_receitas} - <label className="tipoArt"> RECEITAS </label>
                                                    </>)}
                                                </div>
                                            </td>
                                        </tr>
                                    ):(<tr><td colSpan="100%" className="error">Nenhum resultado encontrado.</td></tr>)}
                                    </>
                                ))}
                                
                            </TBody>
                        </Table>

                        <DotsLoader show={loadingSearch}></DotsLoader>

                        <Paginator 
                            perPage={limit} 
                            total={totalPages} 
                            onChange={(limit, offset) => this.updateLimitOffset(limit, offset)}
                            totalItens={arts.length}
                        />

                        <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                                marginTop: '40px',
                            }}
                        >
                            <Button 
                                width='25%'
                                height='50px'
                                value='RELATÓRIO'
                                outline={!this.state.loading}
                                disabled={false}
                                onClick={() => this.getXlms()}
                                loading={this.state.loading}
                            />
                        </div>

                    </>)}


                        {modalTesteGratisAcabou ? (
                            <ModalTesteGratisAcabou
                                show={modalTesteGratisAcabou}
                                title="Essa é uma função exclusiva para assinantes..."
                                subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                                onHide={() => {
                                    this.setState({modalTesteGratisAcabou: false})
                                    this.props.history.push('/');
                                }}
                            ></ModalTesteGratisAcabou>

                        ) : (null)}
                    </Container>
                </ArtsContainer>
            </>
        );
    }
}

export default withRouter(Arts);
