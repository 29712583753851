import React, { useEffect, useState } from "react";
import { 
    PaginatorContainer,
    Item,
    DropItem,
    Options,
    ContainerPage,
    Legenda
} from "./style";

const Paginator = ({...props}) => {
    const [firstPage, setFirstPage] = useState(1);
    const [lastPage, setLastPage] = useState(0);
    const [perPage, setPerPage] = useState(0);
    const [pages, setPages] = useState([]);
    const [page, setPage] = useState(1);
    const [lastSelectPage, setLastSelectPage] = useState(1);

    const [limit, setLimit] = useState(0);
    const [offset, setOffset] = useState(0);

    const [openOptions, setOpenOptions] = useState(false);
    const [options, setOptions] = useState([]);

    const updatePages = (pages) => {
        setPages([]);
        setTimeout(() => {
            setPages(pages);
        }, 100);
    }

    useEffect(() => {
        setPerPage(props.perPage);
        setLastPage(Math.ceil(props.total/props.perPage));
        setLimit(props.perPage);
    },[props.total]);

    useEffect(() => {
        let p = [];
        for (let i = 1; i <= lastPage ; i++) {
            i <= 10 && p.push(i);
        }

        setPages(p);
    }, [lastPage]);

    useEffect(() => {
        let lastPaginator = pages[pages.length - 1];

        let newOptions = [];

        for (let i = 0; i <= lastPage; i++) {
            if (i > lastPaginator) {
                newOptions.push(i);
            }
        }

        setOptions(newOptions);
    },[pages])

    useEffect(() => {

        let lastPaginator = pages[pages.length - 1];

        if (page > lastPaginator) {

            setPages([]);
            let newPaginator = [];

            let cont = 0;

            for (let i = page; i > 0; i--) {
                if (cont < 10) {
                    newPaginator.push(i);
                }
                cont++;
            }

            newPaginator.reverse();
            updatePages(newPaginator);

        } else {
            if (lastSelectPage < page) {
                //UP PAGINATOR
                if (page === (lastSelectPage+1)) {
                    
                    let lastPaginator = pages[pages.length - 1];
                    let firstPaginator = pages[0];
    
                    if (lastPaginator < lastPage && (lastPaginator - page === 3)) {
                        let newPages = pages;
        
                        newPages.shift();
                        newPages.push(lastPaginator + 1);
        
                        updatePages(newPages);
                    } else if (lastPaginator < lastPage && (lastPaginator - page < 3)) {
                        let diferenca = lastPaginator - page;
    
                        if (diferenca < 0) {
                            setPages([]);
                            let newPages = pages;
        
                            newPages.shift();
                            newPages.push(lastPaginator + 1);
            
                            updatePages(newPages);
                        }
                    }
                } else {
                    if (page !== (lastSelectPage+1)) {
                        if (page === lastPage) {
                            setPages([]);
                            let newPages = [];
                            for (let i = page-pages.length; i <= lastPage; i++) {
                                i>0 && newPages.push(i);
                            }
                            updatePages(newPages);
                        }
                    }
                }
    
            } else if (lastSelectPage > page) {
                //DOWN PAGINATOR
                if (page === (lastSelectPage-1)) {
                    let lastPaginator = pages[pages.length - 1];
                    let firstPaginator = pages[0];
    
                    if (firstPaginator > firstPage && ((firstPaginator + 3) === page)) {
                        let newPages = pages;
        
                        newPages.reverse();
                        newPages.shift();
                        newPages.push(firstPaginator - 1);
                        newPages.reverse();
        
                        updatePages(newPages);
                    } else if (firstPaginator > firstPage && ((firstPaginator + 3) > page)) {
                        let diferenca = firstPaginator - page;
    
                        if (diferenca >= 1) {
                            setPages([]);
                            let newPages = pages;
    
                            newPages.reverse();
                            newPages.shift();
                            newPages.push(firstPaginator - 1);
                            newPages.reverse();
            
                            updatePages(newPages);
                        }
                    }
                } else {
                    if (page !== (lastSelectPage-1)) {
                        if (page === firstPage) {
                            setPages([]);
                            let newPages = [];
                            for (let i = 1; i <= pages.length; i++) {
                                newPages.push(i);
                            }
                            updatePages(newPages);
                        }
                    }
                }
            }
        }

        //Retornar limit e offset
        if (page === firstPage) {
            setLimit(perPage);
            setOffset(0);
            if (perPage !== 0) {
                props.onChange(perPage, 0);
            }
        } else if (page > firstPage) {
            let offsetValue = (limit*page) - limit;
            if (perPage != limit || offsetValue != offset) {
                setOffset(offsetValue);
    
                if (perPage !== 0 && offsetValue !== 0) {
                    props.onChange(perPage, offsetValue);
                }
            }
            
        }

        let newOptions = [];

        for (let i = 0; i <= lastPage; i++) {
            if (i > lastPaginator) {
                newOptions.push(i);
            }
        }

        setOptions(newOptions);


    },[page, perPage]);

    const controls = {
        next() {
            setLastSelectPage(page);
            let nextPage = page + 1;

            if (nextPage > lastPage) {
                nextPage--;
            } 

            setPage(nextPage);
        },
        prev() {
            setLastSelectPage(page);

            let prevPage = page - 1;

            if (prevPage < firstPage) {
                prevPage++;
            }

            setPage(prevPage);
        },
        goTo(pag) {

            setLastSelectPage(page);

            let toPage = pag;

            if (pag < firstPage) {
                toPage = firstPage;
            }

            if (pag > lastPage) {
                toPage = lastPage
            }

            setPage(toPage);

        },
        openOptions() {
            setOpenOptions(!openOptions)
        }
    }

    return(
        <ContainerPage>
            {pages.length > 0 ? (
                <PaginatorContainer>
                    <Item className='first' onClick={() => controls.goTo(firstPage)}>
                        <label> {' < Primeiro'} </label>
                    </Item>
                    <Item className='prev' onClick={() => controls.prev()}>
                        <label> {' < '} </label>
                    </Item>

                    {page && (
                        <>  
                            {pages.map((p, i) => (
                                <>
                                    <Item className='num' onClick={() => controls.goTo(p)} isActive={p === page ? true : false}>
                                        <label> {p} </label>
                                    </Item>
                                </>
                            ))}
                        </>
                    )}

                    {options.length > 0 && (
                        <Item className='options' onClick={() => controls.openOptions()} isOpen={openOptions}>
                            {openOptions ? (
                                <>
                                    <Options opLength={options.length}>

                                        {options.map((op, i) => (
                                            <DropItem onClick={() => controls.goTo(op)} ><label>{op}</label></DropItem>
                                        ))}
                                    </Options>
                                    <label className='more' > {' - '} </label>
                                </>
                            ) : (
                                <label className='more' > {' + '} </label>
                            )}
                            
                        </Item>
                    )}
                    <Item className='next' onClick={() => controls.next()}>
                        <label> {' > '} </label>
                    </Item>
                    <Item className='last' onClick={() => controls.goTo(lastPage)}>
                        <label> {' Último > '} </label>
                    </Item>
                </PaginatorContainer>
            ) : (null)}

            {pages.length > 0 ? (
                <Legenda>Página {page}  de {lastPage}, mostrando {props.totalItens} de  {props.total}</Legenda>
            ) : (null)}
        </ContainerPage>
    )
}

export default Paginator;
