import React, { useEffect, useState } from 'react';
import { ScreenClassRender } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";

import CardRelatorio from "../../components/card-relatorios";
import CardFormRelatorio from "../../components/card-relatorios/cardFormRelatorios";

import { RelatorioContainer } from "./styleRelatorios";

import AlvosMaisFrequentesPorPeriodo from '../../components/relatorios/alvos-frequentes-por-periodo';
import AlvosMaisFrequentesPorCulturaEPeriodo from '../../components/relatorios/alvos-frequentes-por-cultura-e-periodo';
import ProdutosMaisFrequentesPorPeriodo from '../../components/relatorios/produtos-frequentes-por-periodo';
import ProdutosMaisFrequentesPorCulturaEPeriodo from '../../components/relatorios/produtos-frequentes-por-cultura-e-periodo';

import { Col2, Row, Container, Drop, ColGraph02, RowGraph01, ColGraph03, RowGraph02, RowRel } from './styles';
import ArrowIcon from "../../assets/icons/icon-arrow.svg";
import ModalidadesMaisFrequentesPorPeriodo from '../../components/relatorios/modalidades-frequentes-por-periodo';
import CulturasMaisFrequentesPorPeriodo from '../../components/relatorios/culturas-frequentes-por-periodo';
import TotalAreaTratada from '../../components/relatorios/total-area-tratada';
import TotalReceitasEmitidas from '../../components/relatorios/total-receitas-emitidas';
import TotalReceitasAgronomoEPeriodo from '../../components/relatorios/total-receitas-por-agronomo-e-periodo';
import TotalReceitasArtEPeriodo from '../../components/relatorios/total-receitas-por-art-e-periodo';
import { Col } from 'react-bootstrap';
import QtdReceitasEmitidas from '../../components/relatorios/qtd-receitas-emitidas';
import ClientesAtendidos from '../../components/relatorios/clientes-atendidos';
import CardArtQtdCom from '../../components/relatorios/card-qtdAdq-art-comerc.js';
import { IconeBotao, Loading } from '../../components/relatorios/style';
import api from '../../services/api';
import MaiorComercializacao from '../../components/relatorios/maior-comercializacao';



const Historico = ({ ...props }) => {

    // const [tiposRelatorio] = useState([{value: 1, label:"Alvos mais frequentes por cultura e por período"}, {value: 2, label:"Produtos mais recomendados por cultura e por período"}, {value:3, label:"Produtos mais recomendados por período"}, {value:4, label:"Relatorio Geral"}]);
    const [screenSize, setScreenSize] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    // const [tipoRelatorio, setTipoRelatorio] = useState('');
    const [load, setLoad] = useState(false)

    const [openCultura, setOpenCultura] = useState(false);
    const [openModalidade, setOpenModalidade] = useState(false);
    const [openAlvo, setOpenAlvo] = useState(false);
    const [openProduto, setOpenProduto] = useState(false);
    const [openReceita, setOpenReceita] = useState(false);

    const getScreenSize = screensize => {
        setLoad(true);
        const sizesToHide = ["xl", "lg"];
        const hide = sizesToHide.includes(screensize);
        const prevScreenSize = screenSize;

        if (prevScreenSize !== screensize) {
            if (hide) {
                setScreenSize(screensize);
                setIsMobile(false);
                
            } else {
                setScreenSize(screensize);
                setIsMobile(true);
            }
        }
    };

    const clickOpenAlvo = () => {
        setOpenAlvo(!openAlvo);
    }

    const clickOpenCultura = () => {
        setOpenCultura(!openCultura);
    }
    
    const clickOpenModalidade = () => {
        setOpenModalidade(!openModalidade);
    }
    
    const clickOpenProduto = () => {
        setOpenProduto(!openProduto);
    }

    const clickOpenReceita = () => {
        setOpenReceita(!openReceita);
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         window.print();
            
    //     }, 1000);
    // }, [])

    // const updateValueCard = value => {
    //     setTipoRelatorio(value);
    // }

    return (
    
        <ScreenClassRender
            render={screenClass => (<>
                <GenericUpper
                    title="Relatórios"
                    subtitle="Visualize os relatórios e metricas de suas receitas emitidas!"
                ></GenericUpper>

                <RelatorioContainer isMobile={isMobile}>
                    {load === false ? getScreenSize(screenClass) : null}

                    
                    <Container style={{
                        flexDirection: isMobile == false ? 'row' : 'column',
                        marginTop: isMobile == false ?  '-32px' : '-40px',
                        justifyContent: 'space-between',
                    }}>
                        {
                            isMobile == false ? (
                                <>
                                    <ColGraph03>
                                        <QtdReceitasEmitidas />
                                    </ColGraph03>
                                    <ColGraph03>
                                        <TotalAreaTratada />
                                    </ColGraph03>
                                    <ColGraph03>
                                        <ClientesAtendidos />
                                    </ColGraph03>
                                    <ColGraph03>
                                        <MaiorComercializacao isMobile={false}/>
                                    </ColGraph03>
                                </>
                            ) : (
                                <>
                                    <RowRel>
                                        <QtdReceitasEmitidas />
                                    </RowRel>
                                    <RowRel>
                                        <TotalAreaTratada />
                                    </RowRel>
                                    <RowRel>
                                        <ClientesAtendidos />
                                    </RowRel>
                                    <RowRel>
                                        <MaiorComercializacao isMobile={true}/>
                                    </RowRel>
                                </>
                            )
                        }
                        
                    </Container>
                    <Container style={{
                        flexDirection: isMobile == false ? 'row' : 'column',
                        justifyContent: 'space-between',
                    }}>
                        {
                            isMobile == true ? (
                                <>
                                    <RowRel>
                                        <TotalReceitasEmitidas isMobile={true} />
                                    </RowRel>
                                    <RowRel>
                                        <CardArtQtdCom />
                                    </RowRel>
                                </>
                            ) : (
                                <>
                                    <RowGraph01>
                                        <TotalReceitasEmitidas isMobile={false} />
                                    </RowGraph01>

                                    <RowGraph02>
                                        <CardArtQtdCom />
                                    </RowGraph02>
                                </>
                            )
                        }
                        
                    </Container>
                    <Container style={{
                        flexDirection: isMobile == false ? 'row' : 'column',
                        marginTop: isMobile == false ? '20px' : '0px',
                        justifyContent: 'space-between',
                    }}>
                         {
                            isMobile == true ? (
                                <>
                                    <RowRel>
                                        <AlvosMaisFrequentesPorPeriodo />
                                    </RowRel>
                                    <RowRel>
                                        <CulturasMaisFrequentesPorPeriodo />
                                    </RowRel>
                                    <RowRel>
                                        <ProdutosMaisFrequentesPorPeriodo />
                                    </RowRel>
                                </>
                            ) : (
                                <>
                                   <ColGraph02>
                                        <AlvosMaisFrequentesPorPeriodo />
                                    </ColGraph02>
                                    <ColGraph02>
                                        <CulturasMaisFrequentesPorPeriodo />
                                    </ColGraph02>
                                    <ColGraph02>
                                        <ProdutosMaisFrequentesPorPeriodo />
                                    </ColGraph02>
                                </>
                            )
                        }
                        
                        
                    </Container>
                    
                </RelatorioContainer>            
            </>)}        
        />);
};


export default Historico;