import styled from "styled-components";

export const Head = styled.div`
    width: 100%;
    height: 95px;
    background-color: #21262f;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

export const Grid = styled.div`
    width: auto;
    height: 100%;
    padding: 8px 16px;
`;

export const Title = styled.h1`
    color: #fff;
    font-weight: 900;
    font-size: 28px;
`;

export const Subitle = styled.label`
    color: #BAC1CF;
    font-family: "Lato", Sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
`;