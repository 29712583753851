import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import GenericUpper from '../../../components/generic-upper';
import { Card, Col, Container, Title2 } from './style';

const HelpNewReceita = ({ ...props }) => {

    const goFullScreen = (id) => {
        let element = document.getElementById(id);       
        if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        } 
    }


    useEffect(() => {
        let vid = document.getElementById("v1");
        let vid2 = document.getElementById("v2");
        let vid3 = document.getElementById("v3");
        let vid4 = document.getElementById("v4");
        let vid5 = document.getElementById("v5");
        let vid6 = document.getElementById("v6");
        let vid7 = document.getElementById("v7");
        let vid8 = document.getElementById("v8");
        vid.play();
        vid2.play();
        vid3.play();
        vid4.play();
        vid5.play();
        vid6.play();
        vid7.play();
        vid8.play();
    })

    return (
        <>
            <GenericUpper
                align='center'
                title='Nova emissão de receita'
                subtitle='Siga os passos para entender melhor a nova emissão'
            >
            </GenericUpper>

            <Container>
                <Card>
                    <video id='v1' width="50%" autoplay muted loop onClick={() => goFullScreen('v1')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v1.mp4' type='video/mp4' />
                    </video>
                    <Col>
                        <Title2>Uma nova tela</Title2>
                        <p>Agora todas as informações da receita ficam em uma unica tela, facilitando a sua revisão antes do envio!</p>
                    </Col>
                </Card>
                
                <Card>
                    <Col>
                        <Title2>Prescrevendo um diagnóstico</Title2>
                        <p>Agora ficou muito mais prático para prescrever um diagnóstico, basta selecionar uma relação de estado, cultura, praga e produto.</p>
                    </Col>
                    <video id='v2' width="50%" autoplay muted loop onClick={() => goFullScreen('v2')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v2.mp4' type='video/mp4' />
                    </video>
                </Card>
                
                <Card>
                    <video id='v3' width="50%" autoplay muted loop onClick={() => goFullScreen('v3')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v3.mp4' type='video/mp4' />
                    </video>
                    <Col>
                        <Title2>Adicionando mais de um produto na receita</Title2>
                        <p>Se o estado selecionado permitir, você pode adicionar mais produtos em uma receita apenas clicando no botão 'Adicionar outro produto'.</p>
                    </Col>
                </Card>
                
                <Card>
                    <Col>
                        <Title2>Revisando um diagnóstico</Title2>
                        <p>Caso queira revisar um diagnóstico já montado, basta clicar na seta a direita para abrir os campos anteriormente preenchidos.</p>
                    </Col>
                    <video id='v4' width="50%" autoplay muted loop onClick={() => goFullScreen('v4')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v4.mp4' type='video/mp4' />
                    </video>
                </Card>
                
                <Card>
                    <video id='v5' width="50%" autoplay muted loop onClick={() => goFullScreen('v5')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v5.mp4' type='video/mp4' />
                    </video>
                    <Col>
                        <Title2>Removendo um diagnóstico</Title2>
                        <p>Para remover um diagnóstico, basta clicar na lixeira a esquerda do produto</p>
                    </Col>
                </Card>
                <Card>
                    <Col>
                        <Title2>Responsável Técnico</Title2>
                        <p>Nessa sessão você deve selecionar o responsável técnico que está prescrevendo a receita. Aqui também é possível adicionar novos cadastros se for necesário.</p>
                    </Col>
                    <video id='v6' width="50%" autoplay muted loop onClick={() => goFullScreen('v6')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v6.mp4' type='video/mp4' />
                    </video>
                </Card>
                <Card>
                    <video id='v7' width="50%" autoplay muted loop onClick={() => goFullScreen('v7')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v7.mp4' type='video/mp4' />
                    </video>
                    <Col>
                        <Title2>Onde o produto será aplicado?</Title2>
                        <p>Aqui é onde você seleciona o produtor, local de aplicação e o local de devolução das embalagens vazias. Você pode realizar novos cadastros se for necessário.</p>
                    </Col>
                </Card>
                <Card>
                    <Col>
                        <Title2>Emitindo a receita</Title2>
                        <p>Não se esqueça de revisar as informações selecionadas antes de emitir sua receita! Depois de revisado, basta clicar em 'Emitir Receita' e o arquivo PDF será gerado!</p>
                    </Col>
                    <video id='v8' width="50%" autoplay muted loop onClick={() => goFullScreen('v8')}>
                        <source src='https://sudeste-online.s3.amazonaws.com/ra-extras/video/receita/v8.mp4' type='video/mp4' />
                    </video>
                </Card>
            </Container>
        </>
    )
}

export default withRouter(HelpNewReceita);
