import React, {useEffect, useState} from 'react';
import { NewsCardStyle } from "./style";
import { Link } from "react-router-dom";
// import IMG from "../../assets/Compendio agricola.svg";
// import IMG2 from "../../assets/Solicitar produto.svg";
// import IMG3 from "../../assets/report.svg";
import Button from '../button';

const CardNewsHome = ({ ...props }) => {
    const [data, setData] = useState('');

    useEffect(()=>{
        const now = new Date        
        const dia = now.getDate()
        const ano = now.getFullYear()
        let mes = now.getMonth() + 1
        
        if(mes == 1){
            mes = "janeiro"
        } else if(mes == 2){
            mes = "fevereiro";
        } else if(mes == 3){
            mes = "março";
        } else if(mes == 4){
            mes = "abril"
        }else if(mes == 5){
            mes = "maio"
        }else if(mes == 6){
            mes = "junho"
        }else if(mes == 7){
            mes = "julho"
        }else if(mes == 8){
            mes = "agosto"
        }else if(mes == 9){
            mes = "setembro"
        }else if(mes == 10){
            mes = "outubro"
        }else if(mes == 11){
            mes = "novembro"
        }else if(mes == 12){
            mes = "desembro"
        }else{
            mes = "undefined"
        }
        setData(`${dia} de ${mes} de ${ano}`);
    },[])

    return (
        <NewsCardStyle>
            <div className="row-news">
                <div className="news-card">
                    <Link className="add" to={`${props.href}`}>
                        <div className="image">
                            <img src={props.img} style={{borderTopLeftRadius: "20px", borderTopRightRadius: "20px"}}/>
                        </div>
                    </Link>

                    <div className="title" style={{width: "95%", margin: "-14px auto -8px auto"}}>
                        <h3> {props.title} </h3>
                    </div>

                    <div className="text" style={{width: "95%", textAlign: "center", fontSize: "14px", margin: "-21px auto auto auto", color: "#8a92a2"}}>
                        <label>{props.text}</label>
                    </div>

                    <div className="data">
                    </div>

                    <div style={{ 
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}>
                        <Link className="add" to={`${props.href}`}>
                            <Button
                                className="btn-right"
                                color="#00b43f"
                                width="155px"
                                height="30px"
                                fontSize="10px"
                                outline="0"
                            >
                                {props.btnText}
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </NewsCardStyle>

    );
};

export default CardNewsHome;