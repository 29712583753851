import styled from "styled-components";

export const InputContainer = styled.div`
  margin: 10px 0;
  width: 100%;
  max-width: 400px;

  .unidadeDose{
    position: absolute;
    right: 17%;
    margin-top: 22px;
    font-size: 16px;
    color: #dce3eb;

  }


  label {
    padding-left: 15px;
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    color: #8a92a2;
  }
  input {
    margin: 10px 0 0;
    font-weight: 600;
    width: 100%;
    max-width: 400px;
    height: 50px;
    padding: 10px;
    border: none;
    font-size: 16px;
    border-radius: 25px;
    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
    padding: 15px 25px;
    &.error {
      border: 1px solid #f01;
    }
    &::placeholder {
      color: #ced2dc;
    }
  }
`;

export const InputContainerV2 = styled.div`
  width: ${props => props.width ? props.width : '100%'};
  height: 70px;
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : '25px'};
  position: relative;
  z-index: 2;

  input {
    &::placeholder {
      color: ${props => props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#8a92a2'};
      opacity: 1; /* Firefox */
    }
  }

  .fa-eye {
    cursor: pointer;
    position: relative;
    top: -36px;
    left: 85%;

    transition: all 0.3s ease;
    &:hover{
      opacity: 0.5;
    }

    &:active {
      transform: scale(0.7);
    }
  }
  
  .fa-eye-slash {
    cursor: pointer;
    position: relative;
    top: -36px;
    left: 85%;

    transition: all 0.3s ease;
    &:hover{
      opacity: 0.5;
    }

    &:active {
      transform: scale(0.7);
    }
  }

  .help-buttom{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: -37px;
    margin-right: -40px;

    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #589bff;
    cursor: pointer
  }
  @media only screen and (max-width: 780px) {
    margin-bottom: ${props => props.yesNo && props. disabled === false ? '58px' : props.marginBottom ? props.marginBottom : '25px'};
  }

  @media only screen and (max-width: 540px) {
    height: ${props => props.isOpen ? 'auto' : props.yesNo ? props.disabled === false ? '100px' : '70px' : '70px'};
    margin-bottom: ${props => props.yesNo && props. disabled === false ? '32px' : props.marginBottom ? props.marginBottom : '25px'};
    
    input {
      top: ${props => props.yesNo ? props.disabled === false ? '-6px' : '0px' : '0px'} !important;
    }
  }
`;

export const Label = styled.label`
  width: auto;
  height: 3px;

  background-color: #FFF;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 0px;
  margin-left: 25px;
  margin-top: ${props => props.isDate ? '-2px' : !props.existValue ? !props.isFocus ? '24px' : '-2px' : '-2px'};

  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  position: absolute;
  
  color: ${props => props.erro ? '#ff5252' : '#8a92a2'};
  opacity: ${props => props.isDisabled ? '0.5' : '1'};
  z-index: 1;
  
  transition: all 0.3s;
  border-radius: 65px;


  &:hover {
    cursor: ${props => !props.existValue ? !props.isFocus ? !props.isDisabled ? 'text' : 'context-menu' : 'context-menu' : 'context-menu'};
  }

  @media only screen and (max-width: 540px){
    margin-top: ${props => props.isDate ? '-2px' : !props.existValue ? !props.isFocus ? props.buttonAddNew ? '36px' : '24px' : props.buttonAddNew ? '11px' : props.isYesNo ? '-8px' : '-2px' :  props.buttonAddNew ? '11px' : props.isYesNo ? '-8px' : '-2px'};
  }
`;

export const Unidade = styled.p`
  font-family: Lato;
  font-size: 12px;
  margin-top: -33px;
  padding-right: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: ${props => props.erro ? '#ff5252' : props.disabled ? '#cfd5df' : '#8a92a2'};
`;

export const Legenda = styled.p`
  font-family: Lato;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ff5252;
  margin-top: ${props => props.unidade ? '22px' : props.isPassword ? '-21px' : '3px'};
`;

export const Loading = styled.div`

  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }
    
  @keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  }


  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 87%;
  margin-top: -35px;


  background-color: transparent;
  border-top: 1px solid #2fbb69;
  border-bottom: 1px solid #2fbb69;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-radius: 50%;
  -webkit-animation: rotating 0.7s linear infinite;
  -moz-animation: rotating 0.7s linear infinite;
  -ms-animation: rotating 0.7s linear infinite;
  -o-animation: rotating 0.7s linear infinite;
  animation: rotating 0.7s linear infinite;

`;
export const YesNoContainer = styled.div`
  width: auto;
  height: 40px;
  padding: 8px 8px 7px 21px;
  border-radius: 8px;
  background-color: #f3f3f5;
  position: absolute;
  margin: 0px;
  margin-top: -46px;
  left: ${props => (props.yesNoStepSix ? '-215px' : props.disabled ? '33px' : '384px')};
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
  z-index: -1;


  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
  }


  @media only screen and (max-width: 1024px) {
  /*  left: ${props => (props.yesNoStepSix ? '411px' : props.disabled ? '33px' : '384px')}; */
  }
  
  @media only screen and (max-width: 991px) {
  display: none;
  
  }
  
  @media only screen and (max-width:780px) {
    display: none;
  }

  @media only screen and (max-width: 540px) {
  }
`;


export const YesNoContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 991px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 160px;
    height: 50px;
    padding: 8px 8px 7px 8px;
    border-radius: 8px;
    background-color: #f3f3f5;
    position: absolute;
    margin: 0px;
    margin-top: 5px;
    left: 33%;
    top: 35px;
    transition: all 0.2s;
    z-index: -1;

    span {
      font-family: Lato;
      font-size: 11px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #586377;
    }
  }

  @media only screen and (max-width: 690px) {
    left: 32%
  }
  
  @media only screen and (max-width: 460px) {
    left: 28%;
  }

  @media only screen and (max-width: 420px) {
    left: 25%
  }
  @media only screen and (max-width: 390px) {
    left: 24%
  }
  @media only screen and (max-width: 350px) {
    left: 20%
  }
  @media only screen and (max-width: 320px) {
    left: 15%
  }
  @media only screen and (max-width: 280px) {
    left: 11%
  }
  @media only screen and (max-width: 270px) {
    left: 9%
  }

`;

export const Plus = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;

  right: 5%;
  top: 52%;
  font-family: Lato;
  font-size: 12px;
  margin-top: ${props => props.isOpen ? '-21px' : '-21px'};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: 0px solid;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
  letter-spacing: normal;
  color: ${props => props.erro ? '#ff5252' : '#bac1cf'};
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  transition:all 0.3s ease;
  cursor:pointer;
  #msg{
    display:none;
  }
  &:hover{
    transform: scale(1.1);
    #msg {
      display: flex;
      justify-content: center;
      position:absolute;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: center;
      align-items: center;
      color:#FFF;
      background-color: #1d2129;
      width: 110px;
      height: 18px;
      padding: 5px;
      opacity: 0.9;
      border-radius: 5px;
      margin-bottom: 48px;
      margin-right: 40px;

      &:after{
        display:flex;
        position: absolute;
        background-color:  #1d2129;
        width: 6px;
        height: 6px;
        top: 14px;
        margin-left: 40px;
        content: '';
        transform: rotate(45deg);
    }
    }
  }
  &:active {
    transform: scale(0.8);
  }

  .add-select {
    transition: all 0.2s !important;
    transform: rotate(45deg);
    &:hover {
      cursor: pointer !important;
    }

    &:active {

    }
  }

`;


