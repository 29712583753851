import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { withRouter } from 'react-router-dom';
import AlertClima from '../../components/clima/alert_clima';
import ChuvaMapa from '../../components/clima/chuva_mapa';
import NuvensMapa from '../../components/clima/nuvens_mapa';
import PrecipitacaoMapa from '../../components/clima/precipitacao_acumulada_mapa';
import ResumoWeekAlert from '../../components/clima/resumo_clima_alert';
import ResumoClimaHoje from '../../components/clima/resumo_clima_hoje';
import ResumoWeek from '../../components/clima/resumo_week';
import Temperatura from '../../components/clima/temperatura';
import Umidade from '../../components/clima/umidade';
import VaporAguaMapa from '../../components/clima/vapor_agua_mapa';
import VaporAguaRealcadoMapa from '../../components/clima/vapor_agua_realcado_mapa';
import Week from '../../components/clima/week';
import GenericUpper from '../../components/generic-upper';
import api from '../../services/api';
import { apiprevmet3 } from '../../services/inmet_api';
import { AreaInfo, CardMapa, Col2, FarmsContainer, Loading, Row, Text, Title } from './style';

const FarmPrevisao = ({ ...props }) => {

    const [fazenda, setFarm] = useState([]);
    const [previsao, setPrevisao] = useState([]);
    const [objKeys, setObjKeys] = useState([]);

    const [distanciaKM, setDistanciaKM] = useState(0);
    const [nomeCidade, setNomeCidade] = useState("");
    const [dtMedicao, setDtMedicao] = useState("");
    const [hrMedicao, setHrMedicao] = useState("");
    const [uf, setUf] = useState("");

    const getPrevisaoTempoHoje = async (ibge) => {
        try {
            const response = await apiprevmet3.get(`/estacao/proxima/${ibge}`);

            if (response) {
                setDistanciaKM(response.data.estacao.DISTANCIA_EM_KM);
                setNomeCidade(response.data.estacao.NOME);
                setUf(response.data.estacao.UF);
                setDtMedicao(moment(response.data.dados.DT_MEDICAO).format("DD/MM/YYYY"));
                setHrMedicao(response.data.dados.HR_MEDICAO.substring(0,2));
            }
        } catch (error) {

            // store.addNotification({
            //     title: "Erro!",
            //     message: "Erro INMET",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }

    const getPrevisaoTempo = async (ibge) => {
        try {
            const response = await apiprevmet3.get(`/previsao/${ibge}`);

            if (response) {
                let prev = response.data;
                setPrevisao(prev[ibge]);

                let keys = Object.keys(prev[ibge]);
                setObjKeys(keys)
            }
        } catch (error) {
            console.log(error);
            // store.addNotification({
            //     title: "Erro!",
            //     message: "Erro INMET",
            //     type: "danger",
            //     insert: "top",
            //     container: "top-right",
            //     animationIn: ["animated", "fadeIn"],
            //     animationOut: ["animated", "fadeOut"],
            //     dismiss: {
            //         duration: 4000,
            //         onScreen: true
            //     }
            // });
        }
    }

    const getFarm = async () => {

        const id = props.match.params.id;

        try {
            const response = await api.get(`/fazendas/${id}`);
            if (response) {
                setFarm(response.data.data);
            }   

            
            if(response.data.data.cod_ibge && response.data.data.cod_ibge != ''){
                getPrevisaoTempo(response.data.data.cod_ibge);
                getPrevisaoTempoHoje(response.data.data.cod_ibge);
            }else{
                store.addNotification({
                    title: "Erro!",
                    message: "Fazenda selecionada não possui código IBGE cadastrado!",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (error) {
            
        }
    }

    useEffect(() => {
        getFarm();
    }, [])

    return (
        <>
            <GenericUpper 
                title={'Previsão do tempo'}
                subtitle={`Previsão do tempo para ${fazenda.nome ? fazenda.nome : ''} `}
            />

            {fazenda.cod_ibge && (
                <>
                    {props.isMobile == false && window.innerWidth >= 1130 ? (
                            <Row isMobile={false} style={{marginTop: '-25px'}}>
                                <div className='card-alerta'>
                                    <AlertClima ibge={fazenda.cod_ibge} />
                                </div>
                                <div className='card-previsao'>
                                    <ResumoWeekAlert isMobile={props.isMobile} ibge={fazenda.cod_ibge} />

                                </div>
                            </Row>
                    ) : (
                        <>
                            <Row isMobile={true}>
                                <div className='card-alerta'>
                                    <AlertClima ibge={fazenda.cod_ibge} />

                                </div>
                            </Row>
                            <Row isMobile={true}>
                                <div className='card-previsao'>
                                    <Title>Previsão para os próximos 5 dias em <b>{fazenda.cidade} - {fazenda.estado}</b></Title>
                                    <ResumoWeekAlert isMobile={props.isMobile} mobilePrevisao={true} ibge={fazenda.cod_ibge} />

                                </div>
                            </Row>
                        </>
                    )}

                    <Row>
                        <div style={{
                            width: '93%',
                            borderRadius: '10px',
                            boxShadow: '1px 2px 5px 1px rgb(29 33 41 / 40%)',
                            marginTop: '40px',
                            marginBottom: '10px',
                            padding: '20px',
                            backgroundColor: '#FFF',
                        }}>
                            <Title style={{textAlign: 'center'}}><b>Estação automática de <span style={{color: 'red'}}>{nomeCidade} - {uf}</span> localizada a cerca de {distanciaKM}km do seu município - <span style={{color: 'red'}}>Registro horário: {dtMedicao} - {hrMedicao} UTC</span></b></Title>
                            <ResumoClimaHoje ibge={fazenda.cod_ibge}/>

                        </div>
                    </Row>


                    <Row>
                        <Col2>
                            <Temperatura ibge={fazenda.cod_ibge} />
                        </Col2>

                        <Col2>
                            <Umidade ibge={fazenda.cod_ibge} />
                        </Col2>
                    </Row>


                    <Row marginTop='30px' justifyContent='space-around'>
                        <CardMapa width="95%">
                            <PrecipitacaoMapa />
                        </CardMapa>
                    </Row>
                    
                    {/* <Row marginTop='30px' justifyContent='space-around'>
                        <CardMapa width="95%">
                            <ChuvaMapa />
                        </CardMapa>
                    </Row> */}

                    {/* <Row  marginTop='30px' justifyContent='space-around'>
                        <CardMapa>
                            <Title>Topo das Nuvens (T ºC)</Title>
                            <NuvensMapa estado={fazenda.estado} />
                        </CardMapa>
                        
                        <CardMapa>
                            <Title>Vapor d'Água</Title>
                            <VaporAguaMapa estado={fazenda.estado} />
                        </CardMapa>
                        
                        <CardMapa>
                            <Title>Vapor d'Água Realçado</Title>
                            <VaporAguaRealcadoMapa estado={fazenda.estado} />
                        </CardMapa>
                    </Row> */}
                </>
            )}


        </>
    )
}

export default withRouter(FarmPrevisao);

