import React, { useState, useEffect } from 'react';

import CadastroStep1 from '../../components/cadastro/step1_free';
import CadastroStep2 from '../../components/cadastro/step2_free';
import { clearSession } from '../../services/auth';

const Cadastro = ({...props}) => {

    const [step, setStep] = useState(1);
    const [step1Data, setStep1Data] = useState('');
    const [params, setParams] = useState([]);
    const [isCompendio, setIsCompendio] = useState(false);

    const update = (data) => {
        setStep1Data(data);
        setStep(2);
    }

    const getParams = (search) => {
        if (search) {
            let prms = search.split('&');
    
            let returnParams = [];
    
            prms.map((p) => {
                let splitSearch = p.split('=');
                let param = splitSearch[0];
                let value = splitSearch[1];
    
                if (param === 'utm_id' && value === 'compendio-gratis') {
                    setIsCompendio(true);
                }
                returnParams[param] = value;
            });

            return Object.assign({}, returnParams);
        } else {
            const payload = {
                button: '0'
            }

            return payload;
        }
    }

    useEffect(() => {
        clearSession();
        let search = props.location.search;
        search = search.split('?');
        search = search[1];

        let prms = getParams(search);
        setParams(prms);

    }, [])

    return (
        <>
            {step === 1 ? (
                <CadastroStep1 
                    updateData={(data) => update(data)}
                    next={() => setStep(2)}
                    isCompendio={isCompendio}
                />
            ) : (
                <CadastroStep2 
                    step1Data={step1Data}
                    next={() => setStep(3)}
                    history={props.history}
                    params={params}
                />
            )}
        </>
    )
}

export default Cadastro;