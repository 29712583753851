import React from "react";
import IconBell from "../../assets/icons/bell.svg";
import { Link } from "react-router-dom";
import { NotificationStyle } from "./style";

const Notification = ({ ...props }) => {
    return (
    
        <NotificationStyle>
            <div className="header-notifica">
                <div className="title">
                    <h2>NOTIFICAÇÕES</h2>
                </div>
                <div className="ver">
                    <Link to="/notificacao" style={{marginTop: '-4px'}}>
                    <label>Ver tudo</label>
                    </Link>
                    <img src={IconBell} />
                </div>
            </div>

            <hr />

            <div className="desc">
                <div className="title-notification">
                    <h2 className="title-not">{props.titulo}</h2>
                    <label className="date">{props.data}</label>
                </div>

                <div className="text">
                    <p>{props.texto}</p>
                </div>
            </div>
        </NotificationStyle>
)};

export default Notification;