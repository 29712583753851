import React, {useState, useEffect} from "react";
import { withRouter } from "react-router-dom";
import { ProdutoresContainer, Icons, Row, Col2, Btn, Title, Text, Subtitle, Col1, Paragraph, Download, Update } from "./styles";
import IconReceita from "../../assets/icons/iconReceita";
import IconSchedule from '../../assets/icons/iconSchedule';
import api from "../../services/api";
import { Container } from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import Table from "../../components/table";
import SimpleTable from "../../components/table/simple-table.js";
import TBody from "../../components/tbody";
import Button from "../../components/button/button_v2";
import DotsLoader from "../../components/loader";
import CardGeral from "../../components/card-geral/index";
import ModalTesteGratisAcabou from '../../components/modal/modalFuncaoBloqueada';
import ModalGeneric from '../../components/modal/modalGeneric';
import ModalText from '../../components/modal/modalText';
import Paginator from '../../components/paginator';
import Modal from '../../components/modal/modalGeneric';
import { store } from "react-notifications-component";
import mais from "../../assets/Mais.png";
import IconRoom from "../../assets/icons/room";
import IconHelp from "../../assets/icons/help";
import { Loading } from "../../components/button/styles";
import { SimpleTBodyContainer } from "../../components/tbody/styles.js";

export const USER = "@userinfo";
export const VAL = "@validade";


const Produtores = ({ ...props }) => {
    const [produtores, setProdutores] = useState([]);
    const [limit, setLimit] = useState(30);
    const [offset, setOffset] = useState(0);
    const [totalProdutores, setTotalProdutores] = useState(0);
    const [loading, setLoading] = useState(false);

    const [result, setResult] = useState('');
    const [result2, setResult2] = useState('');


    const [size_screen, setSizeScreen] = useState(window.screen.width);
    const [importar, setImportar] = useState(false);
    const [importarFarm, setImportarFarm] = useState(false);
    const [modalTesteGratisAcabou, setModalTesteGratisAcabou] = useState(false);
    const [user] = useState(JSON.parse(localStorage.getItem(USER)));
    const [val] = useState(JSON.parse(localStorage.getItem(VAL)));

    const [loadingSearch, setLoadingSearch] = useState(false);

    const [tipoImport, setTipoImport] = useState(1);
    const [file, setFile] = useState([]);
    const [namesfiles, setNamesfiles] = useState([]);
    const [textfile, setTextfile] = useState('');
    const [loadingImport, setLoadingImport] = useState(false);

    const [loadingValidate, setLoadingValidate] = useState(false);
    const [openValidate, setOpenValidate] = useState(false);
    const [errosArquivo, setErrosArquivo] = useState([]);

    const [showUpdate, setShowUpdate] = useState(true);
    const [showModalHelp, setShowModalHelp] = useState(false);
    const [showRelatorios, setShowRelatorios] = useState(false);
    


    const search = async (result, result2, limit, offset) => {
        try {
            setProdutores([]);
            setLoadingSearch(true);

            const search = result;
            const estado = result2;

            const payload = {
                search,
                estado
            }

            const response = await api.post(`/produtores/pesquisa?limit=${limit}&offset=${offset}`, payload);

            setProdutores(response.data.data.produtores);
            setTotalProdutores(response.data.data.total);
            setLoadingSearch(false);
        } catch (error) {
            setLoadingSearch(false);
        }
    };

    const updateValues = async (text, estado, s) => {
        if (s == 1) {
            
            if(!text && !estado){
                search('', '', 30, 0);
            }else{   
                setResult2(estado);
                setResult(text);
                search(text, estado, 30, 0);
            }
        }else{
            setResult2(estado);
            setResult(text);
        }
    }

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            search(result, result2, 30, 0);
        }
    }

    const updateLimitOffset = (l,o) => {
        if (l !== limit || o !== offset) {
            setLimit(l);
            setOffset(o);
            search(result, result2, l, o);
        }
    }

    const openAdd = () => {
        props.history.push('/produtores/add/');
    }

    const getXlms = async () => {
        setShowRelatorios(false);
        setLoading(true);

        try {
            const response = await api.get("/relatorios/produtores", {
                responseType: "blob"
            });
            
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "RelatorioProdutores.xlsx";
            link.href = url;
            link.click();
            
        } catch (error) {

        }
        setLoading(false);
    };
    
    const getXlmsFarms = async () => {
        setShowRelatorios(false);

        setLoading(true);

        try {
            const response = await api.get("/relatorios/fazendas", {
                responseType: "blob"
            });
            
            const blob = new Blob([response.data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });
            
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "RelatorioFazendas.xlsx";
            link.href = url;
            link.click();
            
        } catch (error) {

        }
        setLoading(false);
    };

    const updateResposta = async (resposta) => {
        if (resposta == 0) {
            setImportar(false);
        }
    }
    
    const updateRespostaFarm = async (resposta) => {
        if (resposta == 0) {
            setImportarFarm(false);
        }
    }

    const goToProducer = (id) => {
        if(user.contract_software_id == 6 && val == 0) {
            setModalTesteGratisAcabou(true);
        }else{    
            props.history.push(`produtores/${id}`)
        }
    }
    
    const goToFarm = (farmId, producerId) => {
        if(user.contract_software_id == 6 && val == 0) {
            setModalTesteGratisAcabou(true);
        }else{    
            props.history.push(`fazendas/${farmId}/${producerId}`);
        }
    }

    const goToAgenda = (id) => {
        if(user.contract_software_id == 6 && val == 0) {
            setModalTesteGratisAcabou(true);
        }else{    
            props.history.push(`agenda/?${id}`);
        }
    }
    
    const goToReceita = (id) => {
        if(user.contract_software_id == 6 && val == 0) {
            setModalTesteGratisAcabou(true);
        }else{    
            props.history.push(`historico/?${id}`);
        }
    }

    const openInportFarm = () => {
        setImportar(false);
        setImportarFarm(true);
    }

    const downloadProducersModel = () => {
        window.open('https://sudeste-online.s3.amazonaws.com/importacoes/importarprodutores.xlsx', '_blank');
    }
    
    const downloadFarmsModel = () => {
        window.open('https://sudeste-online.s3.amazonaws.com/importacoes/Importarlocaisdeaplicacao.xlsx', '_blank');
    }

    const validateArchieve = async (e) => {
        setLoadingValidate(true);
        setOpenValidate(true);

        const payload = {
            file: file[0]
        };

        try{
            if (tipoImport === 1) {
                const response = await api.post('/validar/cadastro/produtores', payload);

                setLoadingValidate(false);
            } else if (tipoImport === 2) {
                const response = await api.post('/validar/cadastro/fazendas', payload);

                setLoadingValidate(false);
            }
        }catch(e){

            if (e.response.status === 422) {
                setErrosArquivo(e.response.data.data);
                setLoadingValidate(false);
            }else{
                let i = document.getElementById('input-file');
                i.value = '';

                setFile([]);
                setNamesfiles([]);
                setTextfile('');
                setLoadingValidate(false);
    
                store.addNotification({
                    title: "Erro!",
                    message: `${e.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }

        }

        
    }

    const closeValidate = () => {
        setFile([]);
        setNamesfiles([]);
        setTextfile('');
        setOpenValidate(false);
        setErrosArquivo([]);
    }

    const onChange = async (e) =>{
        if (file.length == 0) {
            if(e.target.files){
                if(e.target.files[0].size < 3098486){
                    if(
                        e.target.files[0].type == "application/vnd.ms-excel" || 
                        e.target.files[0].type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ){                    

                        let fil = e.target.files;
                        let reader = new FileReader();
                        reader.readAsDataURL(fil[0]);
    
                        reader.onload=(e)=>{
                            var another = file.push(e.target.result);
                        }
                        
                        let archives = (file.length) + 1;
                        let nf = [];
                        

                        nf.push(e.target.files[0].name);
                        setNamesfiles(nf);

                        setTextfile(<>
                            {nf.map((files)=>{
                                return (
                                    <>
                                        {files}
                                    </>
                                )
                            })}
                        </>);


                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: `O arquivo selecionado não possui a extensão correta`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 3000,
                                onScreen: true
                            }
                        });
                    }
                } else {
                    store.addNotification({
                        title: "Erro!",
                        message: `Arquivo maior que o permitido`,
                        type: "danger",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            }
        } else {
            store.addNotification({
                title: "Erro!",
                message: `Não é possível enviar mais que um arquivo para importação`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const importarArquivo = async () => {
        setLoadingImport(true);

        const payload = {
            file: file[0]
        };

        try{
            if (tipoImport === 1) {
                const response = await api.post('/importar/cadastro/produtores', payload);
                

                setFile([]);
                setTipoImport(1);
                setLoadingImport(false);

                store.addNotification({
                    title: "Sucesso!",
                    message: `${response.data.data} ${response.data.message}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                search('', '', 30, 0);
                setImportar(false);
            } else if (tipoImport === 2) {

                const response = await api.post('/importar/cadastro/fazendas', payload);
                
                setFile([]);
                setTipoImport(1);
                setLoadingImport(false);

                store.addNotification({
                    title: "Sucesso!",
                    message: `${response.data.data} ${response.data.message}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                search('', '', 30, 0);
                setImportar(false);
            }
        }catch(e){

            console.log(e);

            let i = document.getElementById('input-file');
            i.value = '';

            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });


            setFile([]);
            setNamesfiles([]);
            setTextfile('');
            setLoadingImport(false);
        }
    }

    const deleteFile = () => {
        setFile([]);
        setNamesfiles([]);
        setTextfile('');

        let i = document.getElementById('input-file');
        i.value = '';
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        search('', '', 30, 0);
    }, [])

    useEffect(() => {
        setFile([]);
        setNamesfiles([]);
        setTextfile('');
        setShowUpdate(false);
    }, [tipoImport, importar])

    useEffect(() => {
        if (showUpdate === false) {
            setTimeout(() => {
                setShowUpdate(true);
            }, 50);
        }
    },[showUpdate])

    useEffect(() => {
        if (window.screen.width !== size_screen) {
            setSizeScreen(window.screen.width)
        }
    },[window.screen.width])


    return (
        <>
            <GenericUpper
                title="Produtores"
                subtitle="Gerencie seus produtores"
                search={true}
                searchFunction={(text, estado, search) => updateValues(text, estado, search)}
                placeholder="Nome, Cidade ou Loja"
                addButton={true}
                addOptions={true}
                onKeyDown={(e) => onKeyDown(e)}
                estados={true}
                tooltip={"Adicionar"}
                addOption01 = {'/produtores/add'}
                importar={(e) => setImportar(e)}
                importarFarm={(e) => openInportFarm()}
            ></GenericUpper>

            <ProdutoresContainer>
                <Container style={size_screen <= 768 ? {paddingLeft: "0px", paddingRight: "0px"}:{}}>
                    {size_screen <= 768 ? (
                        <>    
                            <CardGeral
                                // titulos={ ["PRODUTORES","NOME", "CIDADE", "TELEFONE","ESTADO", "HISTÓRICOS"]}
                                // valores={produtores}
                                titulos={ ["PRODUTORES", "NOME", "LOCAL DE APLICAÇÃO", "CIDADE", "ESTADO", "HISTÓRICOS"]}
                                valores={produtores}
                            />

                            <DotsLoader show={loadingSearch}></DotsLoader> 

                            <Paginator 
                                perPage={limit} 
                                total={totalProdutores} 
                                onChange={(limit, offset) => updateLimitOffset(limit, offset)}
                                totalItens={produtores.length}
                            />

                            <div 
                                className="button-finish" 
                                style={{ 
                                    width: '100%', 
                                    height: '200px',
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center', 
                                }}
                            >
                                <Button 
                                    width='87%'
                                    height='50px'
                                    value='ADICIONAR PRODUTORES'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => openAdd()}
                                    loading={false}
                                />
                                <Button 
                                    width='87%'
                                    height='50px'
                                    value='RELATÓRIO'
                                    outline={!loading}
                                    disabled={false}
                                    onClick={() => getXlms()}
                                    loading={loading}
                                />
                            </div>
                        </>
                    ):(
                        <>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Nome</th>
                                        <th>Local de aplicação</th>
                                        <th>Cidade</th>
                                        <th>Estado</th>
                                        <th>Históricos</th>
                                    </tr>
                                </thead>

                                <TBody>
                                    {produtores.map(produtor => (
                                        <tr>
                                            <td onClick={e => goToProducer(produtor.id)}>
                                                {produtor.status === "0" ? (
                                                    <>
                                                        <strike>
                                                            {produtor.nome}
                                                        </strike>

                                                        <br></br> 
                                                        <label><strike>Telefone: {produtor.telefone} </strike></label>
                                                    </>
                                                ) : (
                                                    <>
                                                        {produtor.nome}
                                                        <br></br> 
                                                        <label>Telefone: {produtor.telefone} </label>
                                                    </>
                                                )}
                                            </td>

                                            <td>
                                                {produtor.fazendas.map(f => (
                                                    <>
                                                        {f.status == 0 || produtor.status === "0" ? (
                                                            <>
                                                                <ul 
                                                                    className="link-azul" 
                                                                    onClick={() => goToFarm(f.id, produtor.id)}
                                                                >
                                                                    <strike> 
                                                                        <IconRoom 
                                                                            width='16px'
                                                                            height='16px'
                                                                            fill='#589bff'
                                                                        /> 
                                                                        {f.nome}
                                                                    </strike>
                                                                </ul>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ul 
                                                                    className="link-azul" 
                                                                    onClick={() => goToFarm(f.id, produtor.id)}
                                                                >
                                                                    <IconRoom 
                                                                        width='16px'
                                                                        height='16px'
                                                                        fill='#589bff'
                                                                    /> 
                                                                    {f.nome}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </>
                                                ))}
                                            </td>

                                            <td>
                                                {produtor.fazendas.map(f => (
                                                    <>
                                                        {f.status == 0 || produtor.status === "0" ? (
                                                            <>
                                                                <ul><strike>{f.cidade} - {f.estado}</strike></ul>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <ul  
                                                                    className="link"
                                                                    onClick={() => goToFarm(f.id, produtor.id)}
                                                                >
                                                                    {f.cidade} - {f.estado}
                                                                </ul>
                                                            </>
                                                        )}
                                                    </>
                                                ))}
                                            </td>

                                            <td onClick={e => goToProducer(produtor.id)}>
                                                {produtor.status === "0" ? (
                                                    <div className="estado-inativo">{produtor.estado}</div>
                                                    ) : (
                                                    <div className="estado">{produtor.estado}</div>
                                                )}
                                            </td>

                                            <td> 
                                                <Icons>

                                                    <div 
                                                        id="send" 
                                                        onClick={(e) => goToAgenda(produtor.id)}
                                                        key={produtor.id}
                                                        aria-describedby="tooltip" 
                                                    >
                                                        <IconSchedule 
                                                            width='20px' 
                                                            style={{
                                                                marginRight: '20px', 
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                        <p>Agenda</p>
                                                    </div>


                                                    <div 
                                                        id="send" 
                                                        onClick={(e) => goToReceita(produtor.id)}
                                                        key={produtor.id}
                                                        aria-describedby="tooltip" 
                                                    >
                                                        <IconReceita 
                                                            width='20px' 
                                                            style={{
                                                                marginRight: '20px', 
                                                                cursor: 'pointer'
                                                            }}
                                                        />
                                                        <p>Receitas</p>
                                                    </div>
                                                </Icons>
                                            </td>
                                        </tr>
                                    ))}
                                </TBody>
                            </Table>

                            <DotsLoader show={loadingSearch}></DotsLoader> 

                            <Paginator 
                                perPage={limit} 
                                total={totalProdutores} 
                                onChange={(limit, offset) => updateLimitOffset(limit, offset)}
                                totalItens={produtores.length}
                            />

                            <div 
                                className="button-finish" 
                                style={{ 
                                    width: '100%', 
                                    display: 'flex', 
                                    flexDirection: 'row', 
                                    justifyContent: 'space-evenly',
                                    alignItems: 'center', 
                                    marginTop: '40px',
                                }}
                            >
                                <Button 
                                    width='25%'
                                    height='50px'
                                    value='RELATÓRIOS'
                                    outline={!loading}
                                    disabled={false}
                                    // onClick={() => getXlms()}
                                    onClick={() => setShowRelatorios(true)}
                                    loading={loading}
                                />
                            </div>
                        </>
                    )}

                    {
                        openValidate ? (
                            <Modal
                                show={openValidate}
                                onHide={e => {setOpenValidate(false) ; setErrosArquivo([])}}
                                width='70%'
                            >
                                <Row>
                                    {
                                        errosArquivo.length > 0 ? (
                                            <Title>Encontramos alguns problemas...</Title>
                                        ) : (
                                            <Title>Validando arquivo...</Title>
                                        )
                                    }
                                </Row>
                                {
                                    errosArquivo.length > 0 ? (
                                        <>
                                            <SimpleTable>
                                                <thead>
                                                    <tr>
                                                        <th>Linha</th>
                                                        <th style={{
                                                            textAlign: 'left'
                                                        }}>Erros</th>
                                                    </tr>
                                                </thead>
                                                <SimpleTBodyContainer>
                                                    {
                                                        errosArquivo.map(erros => (
                                                            <tr>
                                                                <td style={{
                                                                    textAlign: 'center'
                                                                }}>{erros.linha}</td>
                                                                <td>{erros.erros}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </SimpleTBodyContainer>
                                            </SimpleTable>
                                            
                                            <Row>
                                                <Text><b>Você precisa corrigir os erros acima para continuar com a importação!</b></Text> 
                                            </Row>

                                            <Row>
                                                <Button 
                                                    width='25%'
                                                    height='50px'
                                                    value='ENTENDI'
                                                    outline={false}
                                                    disabled={false}
                                                    onClick={() => closeValidate()}
                                                    loading={false}
                                                />
                                            </Row>

                                        </>
                                    ) : loadingValidate ? (
                                        <Loading 
                                            outline={true}
                                        />
                                    ) : (
                                        setOpenValidate(false),
                                        importarArquivo()
                                    )
                                }
                                

                            </Modal>
                        ) : (
                            <Modal
                                show={importar}
                                onHide={e => {setImportar(false)}}
                                width='70%'
                            >
                                <Row>
                                    <Col2>
                                        <Btn 
                                            onClick={() => setTipoImport(1)}
                                            active={tipoImport === 1 ? true : false}
                                        >
                                            <Title>Importar produtores</Title>
                                            <Text>Inclua seus clientes no AgroReceita através de uma planilha em Excel.</Text>
                                            
                                        </Btn>
                                    </Col2>

                                    <Col2>
                                        <Btn 
                                            onClick={() => setTipoImport(2)}
                                            active={tipoImport === 2 ? true : false}
                                        >
                                            <Title>Importar locais de aplicação</Title>
                                            <Text>Inclua as fazendas de seus produtores no AgroReceita através de uma planilha em Excel.</Text>

                                            {/* <Row>
                                                <Download onClick={() => downloadFarmsModel()}>Baixar modelo</Download>
                                            </Row> */}
                                        </Btn>
                                    </Col2>
                                </Row>
                                
                                {tipoImport === 1 && (
                                    <>
                                        <Row>
                                            <Subtitle>Siga os passos abaixo para realizar a importação de produtores:
                                                <IconHelp 
                                                    width='19px'
                                                    height='19px'
                                                    fill='#5DA8FD'
                                                    cursor='pointer'
                                                    onClick={()=>setShowModalHelp(true)} 
                                                />
                                            </Subtitle>
                                        </Row>

                                        <Row>
                                            <Col1>
                                                <ol start="1">
                                                    <li><Paragraph> <Download onClick={() => downloadProducersModel()}>Baixe aqui</Download> o modelo de planilha</Paragraph></li>
                                                    <p style={{
                                                        marginBottom: '5px',
                                                        // marginTop: '-15px'
                                                    }}><b>ATENÇÃO:</b>  Preencha todas as colunas e não remova a primeira linha da planilha</p>
                                                    <li><Paragraph>Selecione o arquivo em seu computador e clique em Importar</Paragraph></li>

                                                </ol>
                                            </Col1>
                                        </Row>
                                    </>
                                )}
                                
                                {tipoImport === 2 && (
                                    <>
                                        <Row>
                                            <Subtitle>Siga os passos abaixo para realizar a importação de seus locais de aplicação:
                                                <IconHelp 
                                                    width='19px'
                                                    height='19px'
                                                    fill='#5DA8FD'
                                                    cursor='pointer'
                                                    onClick={()=>setShowModalHelp(true)} 
                                                />
                                            </Subtitle>
                                        </Row>

                                        <Row>
                                            <Col1>
                                                <ol start="1">
                                                    <li><Paragraph>Certifique-se de já ter feito o cadastramento ou importação de seus produtores previamente.</Paragraph></li>
                                                    <li><Paragraph> <Download onClick={() => downloadFarmsModel()}>Baixe aqui</Download> o modelo de planilha</Paragraph></li>
                                                    <p style={{
                                                        marginBottom: '5px',
                                                        // marginTop: '-15px'
                                                    }}><b>ATENÇÃO:</b> Preencha todas as colunas e não remova a primeira linha da planilha</p>
                                                    <li><Paragraph>Selecione o arquivo em seu computador e clique em Importar</Paragraph></li>
                                                </ol>
                                            </Col1>
                                        </Row>
                                    </>
                                )}


                                {tipoImport > 0 && showUpdate && (
                                    <Update>
                                        <div class='input-wrapper'>
                                            <label></label>

                                            <div className="arquivos">

                                                <label 
                                                    className='btn-update'
                                                    style={{ marginTop: '17px' }}
                                                    for='input-file' 
                                                    multiple
                                                >
                                                    Selecionar arquivo
                                                </label>

                                                <label className="arq-name"> 
                                                    {textfile ? (
                                                        <> 
                                                            {textfile} <img src={mais} width="20" height="20" onClick={() => deleteFile()} /> 
                                                        </>
                                                    ) : "Nenhum arquivo selecionado"} 
                                                </label>
                                            </div>

                                            <label className="legenda" >Selecione o arquivo XLS ou XLSX em seu computador (tamanho máximo de 3MB)</label>
                                            
                                            <input 
                                                style={{display: "none"}}
                                                type="file"
                                                id="input-file"
                                                name="file"
                                                multiple
                                                onChange={(e)=> onChange(e)}
                                                
                                            />

                                            <span id='file-name'></span>
                                        </div>
                                    </Update> 
                                )}

                                <Row>
                                    <div 
                                        className="button-finish" 
                                        style={{ 
                                            width: '100%', 
                                            display: 'flex', 
                                            flexDirection: 'row', 
                                            justifyContent: 'space-evenly',
                                            alignItems: 'center', 
                                            marginTop: '40px',
                                        }}
                                    >
                                        <Button 
                                            width='25%'
                                            height='50px'
                                            value='IMPORTAR'
                                            outline={false}
                                            disabled={false}
                                            onClick={() => validateArchieve()}
                                            loading={loadingImport}
                                        />
                                    </div>
                                </Row>
                            </Modal>
                        )
                    }


                    <ModalTesteGratisAcabou
                        show={modalTesteGratisAcabou}
                        title="Essa é uma função exclusiva para assinantes..."
                        subtitle="Assine o Agroreceita para desbloquear todos os recursos abaixo:"
                        onHide={() => {
                            setModalTesteGratisAcabou(false);
                            window.location.href="/"
                        }}
                    />
                </Container>
                <ModalText
                    show={showModalHelp}
                    onHide={() => setShowModalHelp(false)}
                    text="Dúvidas para importar um produtor ou local de aplicação? "
                    btnOk={false}
                    title={'AJUDA'}
                    toSearch={'https://agroreceita.com.br/como-importar-produtores-e-locais-de-aplicacao/'}
                    textToSearch={'Clique aqui e acesse o tutorial!'}
                />


                <ModalGeneric
                    show={showRelatorios}
                    onHide={() => setShowRelatorios(false)}
                    size='lg'
                >
                    <h4 style={{textAlign: 'center'}}>Escolha qual relatório deseja exportar</h4>

                    <div 
                            className="button-finish" 
                            style={{ 
                                width: '100%', 
                                display: 'flex', 
                                flexDirection: 'row', 
                                justifyContent: 'space-evenly',
                                alignItems: 'center', 
                                marginTop: '40px',
                                paddingTop: '32px'
                            }}
                        >
                            <Button 
                                width='30%'
                                height='50px'
                                value='PRODUTORES'
                                outline={!loading}
                                disabled={false}
                                onClick={() => getXlms()}
                                loading={loading}
                            />
                            
                            <Button 
                                width='30%'
                                height='50px'
                                value='LOCAIS DE APLICAÇÂO'
                                outline={!loading}
                                disabled={false}
                                onClick={() => getXlmsFarms()}
                                loading={loading}
                            />
                        </div>
                </ModalGeneric>
            </ProdutoresContainer>
        </>
    )

}

export default withRouter(Produtores);
