import React from 'react';
import { ContainerFooter } from './style';


const Footer = ({ ...props }) => { 

    return (
        <>
            <ContainerFooter>
                <div className="section1">
                    <div className="row">
                        <div className="column">
                            <h2>EMPRESA</h2>
                            <p>
                                <a href="https://www.agroreceita.com.br/sobre-nos">
                                    <span>Quem somos</span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/ajuda/">
                                    <span>Central de ajuda </span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/fale-conosco/">
                                    <span>Fale conosco</span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/fale-com-especialista/">
                                    <span>Solicite um especialista</span>
                                </a>
                            </p>

                            <h2><a href="https://www.agroreceita.com.br/materiais/">MATERIAIS GRATUITOS</a></h2>
                            <h2><a href="https://www.agroreceita.com.br/blog/">BLOG</a></h2>
                        </div>

                        <div className="column">
                            <h2>FUNCIONALIDADES</h2>
                            <p>
                                <a href="https://www.agroreceita.com.br/receita-agronomica/?">
                                    <span>Receita Agronômica</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/compendio-gratis">
                                    <span>Compêndio Agrícola Gratuíto</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/aplicativo-compendio-agricola">
                                    <span>Aplicativo Compêndio Agrícola</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/aplicativo-receituario-agronomico">
                                    <span>Aplicativo Receituário Agronômico</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/sistema-integracao-receituario-agronomico">
                                    <span>API de Integração</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/cadastro-de-clientes/">
                                    <span>Cadastro de Clientes</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/assinatura-digital-e-eletronica/">
                                    <span>Assinatura eletrônica</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/previsao-do-tempo/">
                                    <span>Previsão do Tempo</span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/ficha-emergencia-fispq/">
                                    <span>FISPQ e Ficha de Emergência</span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/homologacao-defesas-agropecuarias/">
                                    <span>Homologação junto ás Defesas</span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/relatorios-receituario-agronomico/">
                                    <span>Relatórios Essenciais</span>
                                </a>
                            </p>
                        </div>

                        <div className="column">
                            <h2>PLANOS</h2>
                            <p>
                                <a href="https://www.agroreceita.com.br/planos/">
                                    <span>Plano Free</span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/planos/">
                                    <span>Plano Light</span>
                                </a>
                            </p>

                            <p>
                                <a href="https://www.agroreceita.com.br/planos/">
                                    <span>Plano Pro</span>
                                </a>
                            </p>
                            
                            <p>
                                <a href="https://www.agroreceita.com.br/pre-pago/">
                                    <span>Plano Pré-pago</span>
                                </a>
                            </p>

                            <h2 style={{
                                margin: '36px 0px 24px 0px'
                            }}>REDES SOCIAIS</h2>
                            <div class="social-icons-wrapper">
                                <div class="item">
                                    <a class="instagram" href="https://www.instagram.com/agroreceita" target="_blank">
                                        <span class="elementor-screen-only"></span>
                                        <i class="fab fa-instagram"></i>					
                                    </a>
                                </div>

                                <div class="item">
                                    <a class="facebook" href="https://www.facebook.com/AgroReceita" target="_blank">
                                        <span class="elementor-screen-only"></span>
                                        <i class="fab fa-facebook"></i>					
                                    </a>
                                </div>

                                <div class="item">
                                    <a class="linkedin" href="https://www.linkedin.com/company/agroreceita" target="_blank">
                                        <span class="elementor-screen-only"></span>
                                        <i class="fab fa-linkedin"></i>					
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="column">
                            <h2>CONTATO</h2>
                            <span><a href="mailto:vendas@agroreceita.com.br">vendas@agroreceita.com.br</a></span>
                            <span> <a href="tel:+551532729780">(15) 3272-9780</a> / <a href="tel:+5515981657739">(15) 98165-7739</a> </span>
                            <p>R. Rosa Lotfi de Almeida Bueno, 155 – Vila Nastri II, Itapetininga (SP). CEP: 18.206-390</p>
                            <p>R. Isidoro Garcês, 77-3 Rio Tavares, Florianópolis (SC). CEP: 88.048-444</p>
                            
                            <a href="https://play.google.com/store/apps/details?id=com.agroreceita">
                                <img width="227" alt="" data-srcset="https://agroreceita.com.br/wp-content/uploads/2021/11/Design-sem-nome-1.png 646w, https://agroreceita.com.br/wp-content/uploads/2021/11/Design-sem-nome-1-300x116.png 300w" data-src="https://agroreceita.com.br/wp-content/uploads/2021/11/Design-sem-nome-1.png" data-sizes="(max-width: 646px) 100vw, 646px" class="attachment-large size-large lazyloaded" src="https://agroreceita.com.br/wp-content/uploads/2021/11/Design-sem-nome-1.png" sizes="(max-width: 646px) 100vw, 646px" srcset="https://agroreceita.com.br/wp-content/uploads/2021/11/Design-sem-nome-1.png 646w, https://agroreceita.com.br/wp-content/uploads/2021/11/Design-sem-nome-1-300x116.png 300w"/>
                            </a>
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col">

                            <p>
                                <a href="https://www.agroreceita.com.br/politica-de-privacidade/">
                                    <span>
                                    Política de Privacidade 
                                    </span>
                                </a>
                            </p>
                            <p className="linha"> | </p>
                            <p>
                                <a href="https://www.agroreceita.com.br/termos-de-uso/">
                                    <span>
                                    Termos de Uso 
                                    </span>
                                </a>
                            </p>
                        </div>
                        <div className="col">
                            <p style={{
                                width: '100%',
                                textAlign: 'right',
                            }}>2020-2022 © Todos os direitos reservados</p>
                        </div>
                    </div>

                </div>
            </ContainerFooter>
        </>
    )
}

export default Footer;
