import React, { useState, useEffect } from "react";

import Select from "../../select/select_v2";
import Button from "../../button/button_v2";
import api from "../../../services/api";
import { Container } from "react-grid-system";
import Input from "../../input/input_v2";
import { FormContainer } from "./styles";
import { store } from "react-notifications-component";
export const USER = "@userinfo";

const AddCreas = ({ ...props }) => {

    const { agronomoId } = props;

    const [nome, setNome] = useState('');
    const [agronomo, setAgronomo] = useState('');
    const [filial, setFilial] = useState('');
    const [numeroCrea, setNumeroCrea] = useState('');
    const [tipoId, setTipoId] = useState('');
    const tipos = [{
        value: 'crea', label: 'CREA'
    }, {
        value: 'visto', label: 'VISTO'
    }, {
        value: 'cfta', label: 'CFTA'
    }];
    const [receitaInicial, setReceitaInicial] = useState('');
    const [receitasEmitidas, setReceitasEmitidas] = useState('');
    const [status] = useState({value: 1, label: 'Ativo'});
    const [homologAutomatico, setHomologAutomatico] = useState('');

    const estados = [
        { value: "NACIONAL", label: "Nacional" },
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ];

    const estados1 = [
        { value: "SP", label: "São Paulo" },
        { value: "RJ", label: "Rio de Janeiro" },
        { value: "SC", label: "Santa Catarina" },
        { value: "AC", label: "Acre" },
        { value: "AL", label: "Alagoas" },
        { value: "AP", label: "Amapa" },
        { value: "AM", label: "Amazonas" },
        { value: "BA", label: "Bahia" },
        { value: "CE", label: "Ceará" },
        { value: "DF", label: "Distrito Federal" },
        { value: "ES", label: "Espirito Santo" },
        { value: "GO", label: "Goiás" },
        { value: "MA", label: "Maranhão" },
        { value: "MT", label: "Mato Grosso" },
        { value: "MS", label: "Mato Grosso do Sul" },
        { value: "MG", label: "Minas Gerais" },
        { value: "PA", label: "Pará" },
        { value: "PB", label: "Paraíba" },
        { value: "PR", label: "Paraná" },
        { value: "PE", label: "Pernambuco" },
        { value: "PI", label: "Piaui" },
        { value: "RN", label: "Rio Grande do Norte" },
        { value: "RS", label: "Rio Grande do Sul" },
        { value: "RO", label: "Rondônia" },
        { value: "RR", label: "Roraima" },
        { value: "SE", label: "Sergipe" },
        { value: "TO", label: "Tocantins" },
        { value: "", label: "" }
    ];
    const [estadoId, setEstadoId] = useState(estados || "");
    
    const [isCrea, setIsCrea] = useState(false);
    const [isVisto, setIsVisto] = useState(false);
    const [EsconderBotao, setEsconderBotao] = useState(false);


    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [typePassword, setTypePassword] = useState('password');

    const [tiposAssinatura, setTiposAssinatura] = useState([
        {value: 'M', label: 'Manual'},
        {value: 'D', label: 'Digital'},
        {value: 'E', label: 'Eletrônica'}
    ]);
    const [assinaturaId, setAssinaturaId] = useState('');
    const [texto, setTexto] = useState('');

    const [loading, setLoading] = useState(false);

    const [erroCrea, setErroCrea] = useState(false);
    const [erroCreaMsg, setErroCreaMsg] = useState('');
    const [contractSoftId, setContractSoftId] = useState('');

    const [blockSign, setBlockSign] = useState(false);

    const selectAssinatura = (ass) => {
        if (ass.value === 'M') {
            setTexto('Assinatura Manual é aquela onde você assina à próprio punho. Aceita em todos os estados brasileiros.');
        } else if (ass.value === 'D') {
            setTexto('Assinatura Digital é aquela que precisa de um certificado A1 válido. Aceita nos estados MT, PR, SC, RS, GO, MG, ES e SP.');
        } else if (ass.value === 'E') {
            setTexto('Assinatura Eletrônica é aquela que utiliza um conjunto de dados digitais para validação. Aceita em todos os estados brasileiros, com exceção de MT, PR, SC e RS.');
        } else {
            setTexto('');
        }
        setAssinaturaId(ass);
    }

    const validateNumeroCrea = numeroCrea => {
        setNumeroCrea(numeroCrea);
    }
    const validateReceitaInicial = receitaInicial => {
        let inputWithErrors = document.querySelectorAll(".error");

        for (let input of inputWithErrors) {
            input.classList.remove("error");
        }

        setReceitaInicial(receitaInicial);
    }
    const validateReceitaZero = valor => {
        if(valor == 0){
            setReceitaInicial('');
            
            const select = document.getElementById("receita_inicial");
            select.classList.add("error");

            store.addNotification({
                title: "Erro!",
                message: `O campo receita inicial deve ser maior que zero`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    //Função para buscar os agronomos
    const getAgronomos = async () => {
        try {
            const response = await api.get(`/agronomos/${agronomoId}`);
            const agronomo = response.data.data;
            setAgronomo(agronomo);
            setNome(agronomo.nome);
            setFilial(agronomo.filial.nome_fantasia);
            if (agronomo.creas.length > 0) {

                agronomo.creas.map(c => {
                    if(c.status == 1 ){
                        setIsCrea(true);
                    }
                })
            }
        } catch (error) {
        }
    };

    const validaEstadoVisto = async () => {
        agronomo.creas.map(c => {
            if(c.tipo == 'crea' && c.estado == estadoId && c.status == 1){
                setErroCrea(true);
                setErroCreaMsg('Responsável Técnico já possui CREA cadastrado');

                store.addNotification({
                    title: "Erro!",
                    message: `O Responsável Técnico já possui CREA cadastrado nesse estado! `,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                })
            }else if(c.tipo == 'visto' && c.estado == estadoId && c.status == 1){
                setErroCrea(true);
                setErroCreaMsg('Responsável Técnico já possui VISTO cadastrado para esse estado!');


                store.addNotification({
                    title: "Erro!",
                    message: `O Responsável Técnico já possui VISTO cadastrado para esse estado! `,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                })
            }else{
                setErroCrea(false);
            }
        })

    };

    const handlePost = async e => {
        setLoading(true);

        const payload = {
            estado: estadoId.value ? estadoId.value :  estadoId,
            homologar_automatico: (
                estadoId.value === 'MT' || estadoId.value === 'SC' || estadoId.value === 'ES' || estadoId.value === 'GO' || estadoId === 'NACIONAL'
            ) && contractSoftId != 6 ? 1 : 0,            
            crea: numeroCrea,
            tipo: tipoId.value,
            status: status.value,
            receita_inicial: receitaInicial,
            receitas_emitidas: receitasEmitidas,
            filial: filial,
            assinatura: assinaturaId.value,
            usuario,
            senha,
        }

        try {
            const responsee = await api.post(`/agronomos/${agronomoId}/creas`, payload);
            setLoading(false);
            window.location.href = `/agronomos/${agronomoId}`;
            return (<>

                {store.addNotification({
                    title: "Sucesso!",
                    message: `O ${tipoId.label} foi salvo`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                })

                }
            </>)
        } catch (err) {
            setLoading(false);

            try {
                let inputWithErrors = document.querySelectorAll(".error");
    
                for (let input of inputWithErrors) {
                    input.classList.remove("error");
                }
    
                const errors = err.response.data.errors;
    
                for (let field in errors) {
                    const input = document.getElementById(field);
                    input.classList.add("error");
                    if(errors.estado){
                        field = field+ " : " +errors.estado.existant;
                    }
                }

                store.addNotification({
                    title: "Erro!",
                    message: `Verifique os campos em vermelho!`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                
            } catch (error) {
                store.addNotification({
                    title: "Erro!",
                    message: `${err.response.data.errors}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }

    const getTiposAssinatura = async (estado) => {
        try {
            if (estado) {
                setTiposAssinatura([]);
                const response = await api.get(`/sign/estado/${estado}`);
    
                const tipos = response.data.data;
                let tipo = []
    
                if (tipos.manual === true) {
                    tipo = [...tipo, {value: 'M', label: 'Manual'}];
                } 
                if (tipos.digital === true) {
                    tipo = [...tipo, {value: 'D', label: 'Digital'}]; 
                }
                if (tipos.eletronica === true) {
                    tipo = [...tipo, {value: 'E', label: 'Eletrônica'}]; 
                }
    
                setTiposAssinatura(tipo);
            }
        } catch (error) {
            setTiposAssinatura([
                {value: 'M', label: 'Manual'},
                {value: 'D', label: 'Digital'},
                {value: 'E', label: 'Eletrônica'}
            ]);
        }
    }

    const changeTipoCrea = (tipo) => {
        if (tipo) {
            if (tipo.value != tipoId.value) {
                setTipoId(tipo);
                if(tipo.value === 'cfta') {
                    setEstadoId('NACIONAL');
                }else {
                    if (estadoId.value == 'NACIONAL') {
                        setEstadoId({value: '', label: ''});
                    }
                }
            }
        } else {
            setTipoId('');
        }
    }

    const changeEstado = (estado) => {
        if (estadoId === '') {
            setEstadoId(estado);

            if (estado.value === 'NACIONAL') {
                setTipoId({value: 'cfta', label: 'CFTA'});
            }

            if (estado.value) {
                getTiposAssinatura(estado.value);
            }
        } else if (estadoId.value != estado.value) {
            setEstadoId(estado);
            
            if (estado.value === 'NACIONAL') {
                setTipoId({value: 'cfta', label: 'CFTA'});
            }

            if (estado.value) {
                getTiposAssinatura(estado.value);
            }
        }
    }

    useEffect(() => {
        if (agronomoId) {
            getAgronomos();
        }

        let user = JSON.parse(localStorage.getItem(USER));

        setContractSoftId(user.contract_software_id);

        user.contrato.agro_plan_functions.map((e) => {
            if (e.agro_plans_function.id === 9) {
                if (e.status === 0) {
                    setBlockSign(true);
                }
            }
        });

        setHomologAutomatico({value: 0, label: 'Não'});
    }, [])


    useEffect(() => {
        if ((tipoId.value == 'crea') && isCrea == true) {
            setErroCrea(true);
            setErroCreaMsg('Responsável Técnico já possui CREA');

            setEsconderBotao(true);

            store.addNotification({
                title: "Erro!",
                message: `O Responsável Técnico já possui CREA cadastrado! `,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            })
        } else {
            setErroCrea(false);
            if(tipoId.value == 'visto'){
                validaEstadoVisto();
            }else if(tipoId.value == 'cfta'){

            }
            setEsconderBotao(false);

        }
    }, [tipoId]);

    useEffect(() => {
        if (tiposAssinatura.length > 0) {
            selectAssinatura({value: 'M', label: 'Manual'})
        }
    },[tiposAssinatura]);

    useEffect(() => {
        console.log(blockSign);
    }, [blockSign])


    return (<>

        <FormContainer { ...props }>
            <Container>

                <form 
                    style={ estadoId === 'RJ' ? {marginTop:"119px"}: {marginTop:"50px"}}
                >
                    <Input
                        name="responsavelTecnico"
                        type="text"
                        label="Responsável Técnico"
                        id="responsavelTecnico"
                        value={nome}
                    />

                    <Input
                        name="filial"
                        type="text"
                        label="Filial"
                        id="filial"
                        value={filial}
                    />

                    <Select
                        name="tipo"
                        className="select"
                        label="Tipo"
                        id="tipo"
                        disabled={false}
                        value={tipoId.value || tipoId}
                        onChange={tipo => changeTipoCrea(tipo)}
                        options={tipos}
                        selectUniqueOption={true}
                        erro={erroCrea}
                        messageErro={erroCreaMsg}
                    />

                    {tipoId.value == 'cfta' ? (
                        <Select
                            name="estado"
                            className="select"
                            label="Estado"
                            id="estado"
                            disabled={true}
                            value={"NACIONAL"}
                            estado={true}
                            onChange={estado => (changeEstado(estado))}
                            options={estados}
                            selectUniqueOption={true}
                        />
                    ):(
                        <Select
                            name="estado"
                            className="select"
                            label="Estado"
                            id="estado"
                            disabled={false}
                            value={estadoId.value != '' ? estadoId.value : '' }
                            estado={true}
                            onChange={estado => (changeEstado(estado))}
                            options={estados1}
                            selectUniqueOption={true}
                        />
                    )}

                    <Input
                        name="numeroCrea"
                        type="text"
                        label="Número Crea/Visto/CFTA"
                        id="crea"
                        onChange={e => validateNumeroCrea(e)}
                    />

                    {estadoId === "SC" || estadoId.value === 'SC' ? (
                        <>
                            <Input 
                                name="user"
                                type="text"
                                label="Usuário SIGEN"
                                onChange={e => setUsuario(e)}
                                value={usuario}
                                id='userId'
                            />

                            <div className="senha">
                                <Input 
                                    name="pass"
                                    type={typePassword}
                                    label="Senha SIGEN"
                                    onChange={e => setSenha(e)}
                                    value={senha}
                                    id='passId'
                                />
                            </div>
                        </>
                    ):(<>
                        {estadoId === "ES" || estadoId.value === 'ES' ? (
                            <>
                                <Input 
                                    name="user"
                                    type="text"
                                    label="Usuário IDAF"
                                    id='userId'
                                    onChange={e => setUsuario(e)}
                                    value={usuario}
                                />

                                <div className="senha">
                                    <Input 
                                        name="pass"
                                        type={typePassword}
                                        label="Senha IDAF"
                                        onChange={e => setSenha(e)}
                                        value={senha}
                                        id='passId'
                                    />
                                </div> 
                            </>
                        ):(
                            null
                        )}
                    </>)}

                    <Select
                        name="assinatura"
                        className="select"
                        label="Tipo de assinatura preferencial"
                        id="assinatura"
                        options={tiposAssinatura}
                        value={assinaturaId.value || assinaturaId}
                        onChange={(e) => selectAssinatura(e)}
                        selectUniqueOption={true}
                    />
                    <label style={{ textAlign: 'center', fontSize: '11px' }}>{texto}</label>

                    {estadoId === 'RJ'  || estadoId.value === 'RJ' ? <>
                        <Input
                            name="receitaInicial"
                            type="text"
                            label="Receita Inicial"
                            id="receita_inicial"
                            value={receitaInicial}
                            onChange={e => validateReceitaInicial(e)}
                            onBlur={e => validateReceitaZero(e.target.value)}
                        />

                    </> : null}

                    <div className="button-add" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px', marginBottom: '60px' }}>
                        <Button 
                            width='100%'
                            height='50px'
                            value='CADASTRAR'
                            outline={false}
                            disabled={EsconderBotao}
                            onClick={() => handlePost()}
                            loading={loading}
                        />
                    </div>
                </form>
            </Container>
        </FormContainer>
    </>);
};

export default AddCreas;
