import React, { useState, useEffect, useCallback } from 'react';
import { ContainerSuccess, TitleS2, ContainerBackground, Icon, Icon02, Marker, Markers, Content, Text,Icon03, Option, BoxTextoFoto, ClickEsquerda, ClickDireita, ImgStep06, OptionSt5, BotaoSt5, TitleS5, FundoSt6, BoxSt61, BoxSt62, BoxSt63, OptionSt6 } from './styles';
import TelaEmissao from '../../assets/tour/tela_emissao.svg';
import Seta from '../../assets/tour/undraw_arrow.svg';
import Card06 from '../../assets/tour/card06.svg';
import logoBranco from '../../assets/tour/logoIcone_branco.svg';
import { ImgFundo } from '../../pages/tour/styles';

const Step06 = ({ ...props }) => {

    return (
        <>
            <Content marginLeft={props.steps}>
                <BoxTextoFoto>
                    <FundoSt6>
                        <BoxSt61>Gerenciamento e controle de clientes</BoxSt61>
                        <BoxSt62>📑 Mantenha o histórico de seus clientes organizado por:</BoxSt62>
                        <BoxSt63>
                            <Text>
                                <OptionSt6>✅ Receitas emitidas</OptionSt6>
                                <OptionSt6>✅ Compromissos agendados</OptionSt6>
                                <OptionSt6>✅ Planejamento futuro</OptionSt6>
                            </Text>
                        </BoxSt63>
                    </FundoSt6>
                    <ImgStep06>
                        <img src={Card06} />
                    </ImgStep06>
                </BoxTextoFoto>
            </Content>
        </>
    );
};

export default Step06;
