import React from 'react';
import {Pin, Pulse} from './style';

const Marker = ({ metric, color, lat, lng, setTemperature, setHumidity, fullScreen }) => {
  const onClickDevice = async () => {
    // setTemperature((old) => {
    //   if (metric?.temperature) {
    //     return `${metric.temperature.toLocaleString('pt-BR')}ºC`;
    //   }

    //   return old;
    // });
    // setHumidity(`${metric.humidity.toLocaleString('pt-BR')}%`);
  };

  return (
    <>
      <Pin
        fullScreen={fullScreen}
        // onClick={() => onClickDevice(lat, lng)}
        style={{ backgroundColor: color, cursor: 'pointer', zIndex: '20' }}
      />
      <Pulse fullScreen={fullScreen} />
    </>
  );
};

export default Marker;