import React from 'react'

const IconReceita = () => {

    return (
        <svg 
            xmlns="http://www.w3.org/2000/svg"
            x="0px" 
            y="0px"  
            width="24" 
            height="24" 
            viewBox="0 0 172 172"
            style={{ fill:'#000000' }}
        > 
            <g 
                fill="none" 
                fill-rule="nonzero" 
                stroke="none" 
                stroke-width="1" 
                stroke-linecap="butt" 
                stroke-linejoin="miter" 
                stroke-miterlimit="10" 
                stroke-dasharray="" 
                stroke-dashoffset="0" 
                font-family="none" 
                font-weight="none" 
                font-size="none" 
                text-anchor="none" 
                style={{ mixBlendMode: 'normal' }}
            >
                {/* <path d="M0,172v-172h172v172z" fill="none">
                </path>

                <g fill="#8a92a2"> */}
                    <path d="M71.55469,21.83594l-1.67969,2.18359c0,0 -10.75,15.28516 -10.75,37.625c0,12.28271 3.71631,20.17724 8.73438,24.69141c5.01807,4.51416 10.66602,5.375 13.60547,5.375c6.42481,0 10.89697,-3.31738 14.61328,-4.87109l-4.03125,-9.91016c-4.8291,2.01563 -8.67139,4.03125 -10.58203,4.03125c-1.11279,0 -3.96826,-0.35693 -6.38281,-2.51953c-2.41455,-2.1626 -5.20703,-6.55078 -5.20703,-16.79687c0,-15.76807 5.33301,-25.2373 7.39063,-28.55469c6.63477,2.39356 14.38232,3.7793 21.5,6.88672c4.19922,1.82666 7.64258,4.26221 10.07813,7.22266c2.43555,2.96045 4.03125,6.52979 4.03125,12.09375c0,5.31201 -2.03662,7.89453 -4.03125,9.74219c-0.33594,0.31494 -0.5249,0.41992 -0.83984,0.67188c-2.91846,-6.67676 -7.62158,-13.18555 -14.94922,-18.98047l-6.55078,8.56641c16.46094,13.01758 15.62109,28.42871 15.62109,43.16797h10.75c0,-6.34082 -0.04199,-14.06738 -1.67969,-22.00391c1.44873,-0.75586 3.2544,-1.88965 5.03906,-3.52734c3.71631,-3.40136 7.39063,-9.32227 7.39063,-17.63672c0,-7.72656 -2.45654,-14.21435 -6.38281,-18.98047c-3.92627,-4.76611 -9.1333,-7.83154 -14.27734,-10.07812c-10.30908,-4.49316 -21.64697,-6.31982 -24.85937,-7.89453l-1.17578,-0.50391zM56.4375,102.46094c-5.52197,0.02099 -9.74219,1.34375 -9.74219,1.34375l-0.16797,0.16797l-25.53125,9.74219l-4.87109,1.84766l1.84766,4.87109l7.05469,19.82031l1.84766,5.20703l5.03906,-1.84766l21.5,-8.23047l35.94531,19.82031l2.35156,1.17578l2.35156,-0.83984l63.82813,-26.53906l-4.03125,-9.91016l-61.64453,25.36328l-35.94531,-19.65234l-2.18359,-1.34375l-2.18359,1.00781l-18.64453,7.05469l-3.35937,-9.57422l20.32422,-7.89453c0,0 2.60352,-0.83984 6.38281,-0.83984c3.7793,0 8.14648,0.79785 11.92578,4.19922c5.54297,4.8081 9.80518,8.25146 15.62109,10.07813c5.81592,1.82666 12.32471,2.05762 23.34766,2.01563v-10.75c-10.81299,0.04199 -16.46094,-0.35693 -20.15625,-1.51172c-3.65332,-1.15479 -6.29883,-3.31738 -11.58984,-7.89453c-0.04199,-0.04199 -0.12597,0.04199 -0.16797,0c-6.21484,-5.54297 -13.66846,-6.90771 -19.14844,-6.88672z">
                    </path>
                {/* </g> */}
            </g>
        </svg>
    )
}

export default IconReceita