import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: auto;
    margin: auto;
    padding: 40px 15px;

`;

export const Title = styled.h4`
    text-align: center;
    margin-bottom: 30px;
`;

export const Title2 = styled.h6`
    text-align: left;

    @media only screen and (max-width: 990px) {
        margin: 10px 0px 10px 0px;
    }
`;

export const Card = styled.div`
    width: 100%;
    background-color: #FFF;
    padding-top: 57px;
    padding-bottom: 57px;
    border-radius: 10px;
    box-shadow: 0 0 20px 0 rgba(29, 33, 41, 0.05);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 35px;

    video {
        border-radius: 8px;
        box-shadow: 1px 1px 3px #999, 2px 2px 5px #999, 3px 3px 5px #999, 2px 2px 5px #999, 5px 5px 5px #999, 6px 6px 20px #999;
        cursor: zoom-in;
        transition: all 0.2s;

        &:hover {
            transform: scale(1.04);
        }
    }

    @media only screen and (max-width: 990px) {
        flex-direction: column;
        padding: 20px;
    }
`;

export const Col = styled.div`
    width: 40%;

    @media only screen and (max-width: 990px) {
        width: 100%;

        p{
            margin: 10px 0px 10px 0px;
        }

    }

`;