import axios from "axios";

const googlemaps = axios.create({
  baseURL: 'https://maps.googleapis.com/maps/api/geocode/',
//   json?latlng=-23.613100,%20-48.062051&sensor=true&key=AIzaSyAn3UaVotrho9E6f_BWtW4wDshxnEmM8LI'
});

// api.interceptors.request.use(async config => {
//   config.headers.apiKey = process.env.REACT_APP_API_KEY;
//   const token = getToken();
//   if (token) {
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   return config;
// });

export default googlemaps;
