import React, { useState, useEffect }  from "react";

import { TBodyContainer } from "./styles";
import DotsLoader from "../loader";

const TBody = ({ ...props }) => {
    const [loader, setLoader] = useState(false);

    useEffect(()=>{
        if(props.loaded && props.fichas){
            setLoader(false);
        }else{

        }

        if(props.fichas && props.loaded == false){
            setLoader(false);
        }else{
            if(props.loaded == false){
                setLoader(true);
            }else{
                setLoader(false);
            }
        }
    },[props.fichas, props.loaded])

    return (
        <TBodyContainer>
            {props.children.length > 0 ? (
                props.children
            ) : (
                    <tr>
                        <td colSpan="100%" className="error">
                            {loader ? (<>
                                <DotsLoader show={true}></DotsLoader>
                            </>) : (<>
                                {props.fichas ? (null) : (<>
                                    Nenhum resultado encontrado.
                                </>) }
                            </>)}
                        </td>
                    </tr>
                )}
        </TBodyContainer>
    );
};

export default TBody;
