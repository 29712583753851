import styled from "styled-components";

export const ContainerModal = styled.div`
    width: 100%;
    max-width: 450px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10% 0px 10%;
    height:auto;

    @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 80px 0px 0px 0px;
        height: auto;
    }

`;

export const TitleModal = styled.h1`
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    padding-bottom: 20px;

`;

export const Pages = styled.div`
  width: 100%;
  height: 75px;
  border: 1px solid transparent;
  border-radius: 12px;

  margin: auto;
  margin-bottom: 30px;
  
  background-color: #fff;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    border-radius: 12px 0px 0px 12px;
  }

  div + div {
    border-radius: 0px 12px 12px 0px;
  }
`;

export const Page = styled.div`
  width: 50%;
  height: 65px;
  margin: 5px;
  border: 1px solid transparent;
  
  background-color: ${props => props.isActive ? '#f8f8f9' : '#fff'};

  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.4s ease-out;
`;

export const LabelPage = styled.p`
    width: 100%;
    margin: 0px;
    text-align: center;
    color:  ${props => props.isActive ? '#589BFF' : '#8A92A2'};
    font-family: "Lato", Sans-serif;
    font-size: 17px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.2px;
    transition: all 0.4s ease-out;
`;

export const FundoCalendario = styled.div`
  height: 83vh;
  width: 100%;
  padding: 10px;

  .button-finish {
    display: none;
  }

  @media only screen and (max-width: 980px){
    padding: 70px 0px 0px 0px;
  }

  @media only screen and (max-width: 760px){
    .button-finish {
      display: flex;
    }
  }



`;

export const YesNoContainer = styled.div`
  width: 230px;
  height: 40px;
  padding: 8px 8px 7px 21px;
  border-radius: 8px;
  background-color: #f3f3f5;
  margin: 0px;
  margin-right: -20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
  z-index: 1;


  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
  }


  @media only screen and (max-width: 1024px) {
    left: ${props => (!props.disabled ? '310px' : '33px')};
  }
  
  @media only screen and (max-width: 991px) {
    left: ${props => (!props.disabled ? '269px' : '33px')};
  }
  
`;

export const YesNoContainerGeral = styled.div`
  width: 100%;
  padding: 8px 8px 7px 0px;
  border-radius: 8px;
  background-color: #f3f3f5;
  margin: -30px 0px 20px 0px;
  margin-right: -1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s;
  z-index: 1;


  span {
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #586377;
    margin-right: 140px;
    margin-left: 10px;
  }

  @media only screen and (max-width: 903px) {
    span{
      margin-right: 130px;
      margin-left: 5px;
    }
  }


`;

export const Plus = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;

  left: 80%;
  font-family: Lato;
  font-size: 12px;
  margin-top: -35px;
  margin-left: -23px;
  z-index: 1000;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  border: 0px solid;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
  letter-spacing: normal;
  color: ${props => props.erro ? '#ff5252' : '#bac1cf'};
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: center;
  transition:all 0.3s ease;
  cursor:pointer;
  #msg{
    display:none;
  }
  &:hover{
    transform: scale(1.3);
    #msg {
      display: flex;
      justify-content: center;
      position:absolute;
      font-size: 10px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      text-align: center;
      align-items: center;
      color:#FFF;
      background-color: #1d2129;
      width: 90px;
      height: 18px;
      opacity: 0.9;
      border-radius: 5px;
      margin-bottom: 48px;
      margin-right: 40px;

      &:after{
        display:flex;
        position: absolute;
        background-color:  #1d2129;
        width: 6px;
        height: 6px;
        top: 14px;
        margin-left: 40px;
        content: '';
        transform: rotate(45deg);
      }
    }
  }

    @media only screen and (max-width: 768px) {
        margin-top: -39px;
        margin-left: 10px;
    }

    @media only screen and (max-width: 861px) {
        margin-top: -22px;
        margin-left: -15px;
    }


  .add-select {
    transition: all 0.2s !important;
    transform: rotate(45deg);
    &:hover {
      cursor: pointer !important;
    }

    &:active {

    }
  }

`;