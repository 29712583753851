import React, { useState, useEffect, useRef } from 'react';
import { H3, Label, FilterArea, SelectArea, DataFilter, ChartArea, Loading, IconeBotao } from './style';
import api from '../../services/api';
import DateRange from '../date-range';
import { Line } from 'react-chartjs-2'
import Button from '../button/button_v2';
import { store } from 'react-notifications-component';
import IconeBaixar from '../../assets/iconeBaixar.svg';


const TotalAreaTratada = ({ ...props }) => {

    // const today = new Date();
    // const priorDate = new Date(new Date().setDate(today.getDate() - 30));

    // const [dataInicial, setDataInicial] = useState(priorDate.toISOString().slice(0, 10));
    // const [dataFinal, setDataFinal] = useState(today.toISOString().slice(0, 10));

    const [dataGraph, setDataGraph] = useState([]);
    const [optionsGraph, setOptionsGraph] = useState([]);
    const [showChart, setShowChart] = useState(false);
    const [qtdArea, setQtdArea] = useState(null);

    const [ref] = useState(useRef());

    const [loadingPdf, setShowLoadingPdf] = useState(false);

    const searchInitialData = async () => {
        try {
            const response = await api.get('/relatorios/area/grafico')

            setQtdArea(response.data.data.qtdArea);

            setDataGraph({
                labels: response.data.data.meses,
                datasets: [
                    {
                        label: 'Área (Ha)',
                        data: response.data.data.qtdArea,
                        backgroundColor: 'rgb(47, 187, 105, 0.4)',
                        borderColor: 'rgb(47, 187, 105, 1)',
                        borderWidth: 1,
                        tension: 0.3,
                    },
                ],
            });

            setOptionsGraph({
                responsive: true,
                indexAxis: 'x',
                scales: {
                    y:{
                        beginAtZero: true,
                    },
                    x: {
                        ticks: {
                            // color: 'black',
                            font: {
                                size: 14,
                                // color: 'black'
                            },
                            stepSize: 1,
                            // mirror: true,
                        },
                    },
                },
                plugins: {
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: true,
                        text: `Total da área tratada nos ultimos 12 meses`,
                    },
                },
            });

            setShowChart(true);
        } catch (error) {
            
        }
    }
    
    // const updateData = async () => {
    //     try {
    //         const response = await api.post('/relatorios/area/grafico', {
    //             dtInicial: dataInicial,
    //             dtFinal: dataFinal,
    //         })

    //         setDataGraph({
    //             labels: response.data.data.produtos,
    //             datasets: [
    //                 {
    //                     label: 'Produto',
    //                     data: response.data.data.qtdReceitas,
    //                     backgroundColor: 'rgb(47, 187, 105, 0.4)',
    //                     borderColor: 'rgb(47, 187, 105, 1)',
    //                     borderWidth: 1,
    //                 },
    //             ],
    //         });

    //         setOptionsGraph({
    //             responsive: true,
    //             indexAxis: 'y',
    //             scales: {
    //                 y: {
    //                     ticks: {
    //                         color: 'black',
    //                         font: {
    //                             size: 10,
    //                             color: 'black'
    //                         },
    //                         stepSize: 1,
    //                         mirror: true,
    //                     },
    //                 },
    //             },
    //             plugins: {
    //                 legend: {
    //                     position: 'top',
    //                 },
    //                 title: {
    //                     display: true,
    //                     text: `Produtos mais frequentes de ${response.data.data.dtInicial} à ${response.data.data.dtFinal}`,
    //                 },
    //             },
    //         });

    //         setShowChart(true);
    //     } catch (error) {
            
    //     }
    // }

    const gerarPDF = async () => {
        try {
            setShowLoadingPdf(true);

            const response = await api.get(`/relatorios/area/pdf`)

            let win = window.open(response.data.data.link);

            setShowLoadingPdf(false);

        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    useEffect(() => {
        searchInitialData();
    }, [])

    // useEffect(() => {
    //     updateData();
    // }, [dataInicial, dataFinal])
    
    return (
        <>
            <div>
                <IconeBotao>
                    <div className='card-titulo'><h2 style={{
                        textAlign: 'left',
                        textTransform: 'uppercase',
                        fontFamily: 'Lato',
                        fontSize: '10px',
                        color: '#8a92a2',
                        paddingTop: '10px',
                        marginBottom: '0px'
                    }}>Total de área tratada</h2></div>
                    <div className='icons' style={{
                        display:'flex'
                    }}>    
                        <div className='pdf'>
                            <p id='msgBaixarPdf'>Baixar PDF</ p>
                            <a onClick={() => gerarPDF()}><img width={20} src={IconeBaixar} /></a>
                        </div>
                    </div>
                </IconeBotao>
            </div>
            {
                qtdArea !== null && loadingPdf == false ? (
                    <>
                        <p style={{fontSize: '10px'}}>últimos 12 meses</p>
                        <h1>{qtdArea} Ha</h1>
                    </>
                ) : (
                    <Loading style={{
                        width: '30px',
                        height: '30px',
                        marginTop: '20px',
                        marginBottom: '6px'
                    }}/>
                )
            }
            {/* <div 
                className="button-finish" 
                style={{ 
                    width: '100%', 
                    display: 'flex', 
                    flexDirection: 'row', 
                    justifyContent: 'flex-end', 
                    marginTop: '10px', 
                    marginBottom: '10px' 
                }}
            >
                <Button
                    width='100px'
                    height='40px'
                    value='GERAR PDF'
                    outline={false}
                    disabled={false}
                    onClick={() => gerarPDF()}
                    loading={loadingPdf}
                    tooltip={true}
                    showTooltipMsg={loadingPdf ? 'Gerando pdf' : 'Baixar relatório em pdf'}
                    tooltipMarginLeft='0px'
                    margin='5px 10px'
                />
            </div> */}
        </>
    )
}

export default TotalAreaTratada;
