import React, { useEffect, useState } from 'react';
import { Card, ButtonExport,Data, Label,Row, Col, Title, ScientificName, Manufacturer, ManufacturerName, Item, Info, Icons, InfoFarm, Cancelada } from './styles';
import Button from '../../components/button/button_v2';
import imprimeIcon from "../../assets/icons/icons-8-print.png"
import cancelaIcon from "../../assets/icons/icons-8-trash.png"

import local from "../../assets/icons/icons-8-marker.png"
import api from '../../services/api';

import { store } from "react-notifications-component";
import DotsLoader from "../../components/loader";
import ModalConfirmar from "../../components/modal/modalConfirmar";
import ModalAlert from '../../components/modal/modalGeneric';
import HomologButton from './homologButton';
import statusIcon0 from "../../assets/icons/status-0.png"
import statusIcon1 from "../../assets/icons/status-1.png"
import statusIcon2 from "../../assets/icons/status-2.png"
import statusIcon3 from "../../assets/icons/status-3.png"
import Input from '../../components/input/input_v2';
import IconSendEmail from '../../assets/icons/email';
import IconSendWebHook from '../../assets/icons/send_webhook';
import IconGuide from '../../assets/icons/iconGuide';

import Select from "../select/select_v2";
import AddAplicadores from '../card-guides/aplicadores';
import AddProviders from '../card-guides/companiesGuides';

import iconCopy from '../../assets/icons/icons8-copiar-32.png';
import moment from 'moment/moment';

export const USER = "@userinfo";


const CardPrescription = ({...props}) => {
    const { receita, isMobile } = props;
    const [openPrescription, setOpenPrescription] = useState(false);
    const [r] = useState(receita);
    const [loadingExport, setLoadingExport] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showModalGuia, setShowModalGuia] = useState(false);
    const [mostrarGuia, setMostrarGuia] = useState(false);
    const [idReceita, setIdReceita ] = useState();
    const [idReceitaExport, setIdReceitaExport ] = useState();
    const [estadoReceitaExport, setEstadoReceitaExport ] = useState();

    const [estadoClickReceita, setEstadoClick] = useState();
    const [contractSoftId, setContractSoftId] = useState('');
    const [showModalBlock, setShowModalBlock] = useState(false);
    const [showModalExport, setModalExport] = useState(false);
    const [motivoAtraso] = useState('');
    const [showSend, setShowSend] = useState(false);
    const [showPrescriptionR, setShowPrescriptionR] = useState(false);

    const [email, setEmail] = useState('');
    const [idSendPrescription, setIdSendPrescription] = useState('');
    const [idSendPrescriptionRelation, setIdSendPrescriptionRelation] = useState(null);
    const [loadingSend, setLoadingSend] = useState(false);

    const [showAddAplicador, setShowAddAplicador] = useState(false);
    const [showAddProvider, setShowAddProvider] = useState(false);

    const [aplicadores, setAplicadores] = useState("");
    const [aplicadorId, setAplicadorId] = useState("");
    const [showSendGuide, setShowSendGuide] = useState("");
    const [aplicadorDisabled, setAplicadorDisabled] = useState(false);
    const [nomeAplicador, setNomeAplicador] = useState("");
    const [cpfAplicador, setCpfAplicador] = useState("");
    const [registroAplicador, setRegistroAplicador] = useState("");

    const [providers, setProviders] = useState("");
    const [prescriptions, setPrescriptions] = useState("");
    const [product, setProduct] = useState("");
    const [pests, setPests] = useState("");
    const [mostrar, setMostrar] = useState(false);

    const [providerId, setProviderId] = useState("");
    const [providerDisabled, setProviderDisabled] = useState(false);
    const [razao_social, setRazaoSocial] = useState("");
    const [cnpj, setCnpj] = useState("");

    const [dataAplicacao, setDataAplicacao] = useState("");

    const [loadingCampoNome, setLoadingCampoNome] = useState(false);


    const formatDate = (data) => {
        if (data.includes('T')) {
            const dataN = data.split('T')[0];
            data = moment(dataN).format('DD/MM/YYYY');
            return data;
        }else{
            return moment(data).format('DD/MM/YYYY');
        }
    }

    const sendPrescriptionGuide = async () => {
        setLoadingSend(true);

        const payload = {
            applicator_id: aplicadorId,
            provider_id: providerId,
            data: dataAplicacao,
            prescription_id: idSendPrescription,
            p_relation_id: idSendPrescriptionRelation
        }
        try {
            const response = await api.post(`/guias`, payload);
            if (response) {
                window.open(response.data.data.link, '_blank');
                setLoadingSend(false);
                window.location = '/guia_aplicacao';
                store.addNotification({
                    title: "Sucesso!",
                    message: `Guia de aplicação gerado com sucesso!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                setShowSendGuide(false);
            }
            
        } catch (e) {
            setLoadingSend(false);

            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors ? e.response.data.errors : "Erro ao criar o guia de aplicação. Tente novamente mais tarde!"}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            setShowSendGuide(false);
        }

    }

    const sendPrescriptionWebHook = async (id) => {
        try {
            const response = await api.post('/integracao/enviar', {id});

            if (response) {
                store.addNotification({
                    title: "Sucesso!",
                    message: `Receita enviada com sucesso!`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        } catch (e) {
            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors ? e.response.data.errors : "Não foi possível enviar a receita!" }`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const formatApplicators = aplicadores => {
        aplicadores = aplicadores.map(aplicador => {
            const format = {
                value: aplicador.id,
                label: aplicador.nome_aplicador
            };
            return format;
        });
        return aplicadores;
    };
    const formatProviders = providers => {
        providers = providers.map(provider => {
            const format = {
                value: provider.id,
                label: provider.razao_social
            };
            return format;
        });
        return providers;
    };
    const formatPrescriptions = prescriptions => {
        let presc = prescriptions
        presc = [
            {
                prescriptions_id: null,
                nome_comercial: 'Todos',
                praga: ''
              },
              ...presc
        ]
        prescriptions = presc.map((p, i) => {
            if (i === 0){
                const format = {
                    value: null,
                    label: `Todos os produtos do receituário`
                };
                return format;
            }else{
                const format = {
                    value: p.prescriptions_id,
                    label: `${p.nome_comercial} (${p.praga})`
                };
                return format;
            }
        });
        return prescriptions;
    };
    const _onFocus = (e) => {
        e.currentTarget.type = "date";
    }

    const _onBlur = (e) => {
        //e.currentTarget.type = "text";
        //e.currentTarget.placeholder = "Data de Aplicação";
    }

    const getApplicators = async () => {
        try {
            setAplicadorDisabled(true);
            setLoadingCampoNome(true);
            const response = await api.get(`/aplicador`);

            let aplicadores = response.data.data;
            aplicadores = formatApplicators(aplicadores);
            setAplicadores(aplicadores);
            setLoadingCampoNome(false);
            setAplicadorDisabled(false);
        } catch (e) {
            setAplicadorDisabled(true);
            setLoadingCampoNome(false);
            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors ? e.response.data.errors : "Não há aplicadores cadastrados!" }`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 7000,
                    onScreen: true
                }
            });
            // }
        }
    };
    const getProviders = async () => {
        try {
            setProviderDisabled(true);
            setLoadingCampoNome(true);
            const response = await api.get(`/prestador`);

            let providers = response.data.data;
            providers = formatProviders(providers);
            setProviders(providers);
            setLoadingCampoNome(false);
            setProviderDisabled(false);
        } catch (e) {
            setProviderDisabled(true);
            setLoadingCampoNome(false);
            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors ? e.response.data.errors : "Não há prestadoras de serviços cadastradas!" }`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 7000,
                    onScreen: true
                }
            });
            // }
        }
    };
    const getPrescriptions = async () => {
        try {
            setLoadingCampoNome(true);
            const response = await api.get(`/guias/receitas/${r.id}`);
            

            let prescriptions = response.data.data;
            prescriptions = formatPrescriptions(prescriptions);
            setPrescriptions(prescriptions);
            setLoadingCampoNome(false);
        } catch (e) {
            setLoadingCampoNome(false);
            store.addNotification({
                title: "Erro!",
                message: `${e.response.data.errors ? e.response.data.errors : "Não foi possivel gerar o guia!" }`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 7000,
                    onScreen: true
                }
            });
            // }
        }
    };

    const openPlanos = () => {
        setShowModalBlock(false);
        window.location = '/planos';
    }   

    const modalClose = e => {
        setShowModal(false);
    };

    const cancelarReceita = async (id, motivo) => {
        setLoadingExport(true);
        try {
            let msg;
            if (estadoClickReceita !== "GO" && estadoClickReceita !== "MT" && estadoClickReceita !== "SC" && estadoClickReceita !== "ES") {
                const response = await api.post(`/receitas/${id}`, {motivo});
                if (response) {
                    msg = response.data.message;
                    store.addNotification({
                        title: "Sucesso!",
                        message: `${msg}`,
                        type: "success",
                        insert: "top",
                        container: "top-right",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                            duration: 4000,
                            onScreen: true
                        }
                    });
                }
            } else {
                try {
                    if(estadoClickReceita === "GO"){
                        const response = await api.post(`/receitas/${id}`, {motivo});
                        msg = response.data.message;
                        if (msg) {
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${msg}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    } else if (estadoClickReceita === 'MT') {
                        const response = await api.post(`/receitas/${id}`, {motivo});
                        msg = response.data.message;
                        if (msg) {
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${msg}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    } else if(estadoClickReceita === "SC"){
                        const response = await api.post(`/receitas/${id}`, {motivo});
                        msg = response.data.message;
                        
                        if (msg) {
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${msg}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    } else if(estadoClickReceita === "ES"){
                        const response = await api.post(`/receitas/${id}`, {motivo});
                        msg = response.data.message;
                        if (msg) {
                            store.addNotification({
                                title: "Sucesso!",
                                message: `${msg}`,
                                type: "success",
                                insert: "top",
                                container: "top-right",
                                animationIn: ["animated", "fadeIn"],
                                animationOut: ["animated", "fadeOut"],
                                dismiss: {
                                    duration: 4000,
                                    onScreen: true
                                }
                            });
                        }
                    }                    
                } catch (error) {
                    if (error.response.data.errors) {
                        store.addNotification({
                            title: "Erro!",
                            message: `${error.response.data.errors}`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });

                    } else {
                        store.addNotification({
                            title: "Erro!",
                            message: `Erro ao recarregar receitas`,
                            type: "danger",
                            insert: "top",
                            container: "top-right",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                                duration: 4000,
                                onScreen: true
                            }
                        });
                    }
                    
                }

            }

            props.updateReceitas();

        } catch (error) {
            if (error.response.data.errors) {
                let m = error.response.data.errors;
                store.addNotification({
                    title: "Erro!",
                    message: `${m}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            } else {
                store.addNotification({
                    title: "Erro!",
                    message: `Erro ao cancelar receita`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }

        setLoadingExport(false);

    }

    const respostaMt = async (resposta, motivo) => {
        if(resposta == 1){
            cancelarReceita(idReceita, motivo);
            modalClose();
        }
    };

    const resposta = async (resposta) => {
        if(resposta == 1){
            cancelarReceita(idReceita, '');
            modalClose();
        }
    };

    const openModal = async (receita) => {
        setEstadoClick(receita.local_aplicacao_estado);
        
        try {
            const response = await api.post(`/periodo/cancelamento/${receita.id}`);

            if(response.data.data == "OK"){
                setIdReceita(receita.id);
                setShowModal(true);
            }
            
        } catch (error) {
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    const openModalSend = (id) => {
        setIdSendPrescription(id);
        setShowSend(true);
    }

    const openModalSendGuide = (id) => {
        if (mostrarGuia == true) {
            setIdSendPrescription(id);
            if ((Array.isArray(r.nome_comercial) && r.guias !== null && r.nome_comercial.length == 1) || Array.isArray(r.nome_comercial) == false) {
                getApplicators();
                getProviders();
                setShowSendGuide(true);
            }else{
                getPrescriptions();
                setShowPrescriptionR(true);
            }
        }else{
            setShowModalGuia(true);
        }
    }
    const sendPrescriptionMail = async () => {
        setLoadingSend(true);

        const payload = {
            email,
            receita: idSendPrescription
        }
        try {
            const response = await api.post(`/receitas/enviarReceita`, payload);
                        
            if (response) {
                setLoadingSend(false);
                let msg = response.data.message;
                store.addNotification({
                    title: "Sucesso!",
                    message: `${msg}`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                setShowSend(false);
            }
            
        } catch (error) {
            setLoadingSend(false);

            store.addNotification({
                title: "Erro!",
                message: `${'Ocorreu um problema no envio do e-mail!'}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
            setShowSend(false);
        }
    }

    const clonar = (receita) => {
        console.log(receita);

        const relations = receita.relacoes;
        const products = receita.produtos;
        const cultures = receita.culturas;
        const pests = receita.pragas;
        const aplications = receita.modos_aplicacoes;
        const doses = receita.dose;
        const numAplicacao = receita.numero_aplicacoes;
        const areas = receita.area_tratar;
        const quantidades = receita.quantidade_adquirir;
            
        let r = '';
        let prod = '';
        let c = '';
        let pst = '';
        let apl = '';
        let d = '';
        let n = '';
        let a = '';
        let q = '';


        cultures.map((id) => {
            if (c) {
                c = `${c};${id}`
            } else {
                c = `${id}`
            }
        })
        relations.map((id) => {
            if (r) {
                r = `${r};${id}`
            } else {
                r = `${id}`
            }
        })
        products.map((id) => {
            if (prod) {
                prod = `${prod};${id}`
            } else {
                prod = `${id}`
            }
        })
        pests.map((id) => {
            if (pst) {
                pst = `${pst};${id}`
            } else {
                pst = `${id}`
            }
        })
        aplications.map((id) => {
            if (apl) {
                apl = `${apl};${id}`
            } else {
                apl = `${id}`
            }
        })
        doses.map((id) => {
            if (d) {
                d = `${d};${id}`
            } else {
                d = `${id}`
            }
        })
        numAplicacao.map((id) => {
            if (n) {
                n = `${n};${id}`
            } else {
                n = `${id}`
            }
        })
        areas.map((id) => {
            if (a) {
                a = `${a};${id}`
            } else {
                a = `${id}`
            }
        })
        quantidades.map((id) => {
            if (q) {
                q = `${q};${id}`
            } else {
                q = `${id}`
            }
        })

        window.location.href = `/receitas/add/?cultures=${c}&pests=${pst}&products=${prod}&aplications=${apl}&relations=${r}&estado=${receita.local_aplicacao_estado}&dosages=${d}&numapli=${n}&areas=${a}&qtd=${q}`;

    }

    useEffect(() => {
        let user = JSON.parse(localStorage.getItem(USER));
        if (user.contrato.agro_plan_config.is_teste == 1 && user.contrato.dias_restantes > 0) {
            setMostrarGuia(true);
        }else{
            user.contrato.agro_plan_functions.map((f, c)=>{
                if (f.agro_plans_function.id == 11) {
                        if (f.status == 1) {
                            setMostrarGuia(true);
                        }
                }
            })
        }
        setContractSoftId(user.contract_software_id);
    },[])

    useEffect(() => {
        var c = 0;
        if ((Array.isArray(r.nome_comercial) === true && r.guias !== null && r.guias !== r.nome_comercial.length)) {
            setMostrar(true);
        }else if (r.guias == 0 && r.guias !== null) {
            setMostrar(true);
        }
    }, [])

    return (
        <>
            <Card isOpen={openPrescription} isMobile={isMobile} key={r.id} estado={r.local_aplicacao_estado} cancelada={r.status === 0 || r.status === "0" ? true : false}>
                {typeof r.nome_comercial === 'string' ? (
                    <>
                        <Row marginBottom='20px' responsiveReverse={true}>
                            <Col isTitle={true}>
                                <Row isTitle={true}>
                                    <Title cancelada={ r.status === 0 ? true : false}>{r.nome_comercial}</Title>
                                    <ScientificName cancelada={ r.status === 0 ? true : false}> {r.nome_tecnico}</ScientificName>
                                </Row>      
                                
                                <Row isTitle={true}>
                                    <Manufacturer cancelada={ r.status === 0 ? true : false}>Fabricante</Manufacturer>
                                    <ManufacturerName cancelada={ r.status === 0 ? true : false}> {r.fabricante}</ManufacturerName>
                                </Row> 

                            </Col>
                            
                            <Col isTitle={true} direction='row' justifyContent='flex-end' alignResponsive='center'>
                                <Col>
                                    <Row justifyContent='flex-end' widthResponsive='auto'>
                                        <Item align='end' cancelada={ r.status === 0 ? true : false}>RECEITA: {r.numero}</Item>
                                    </Row>

                                    <Row justifyContent='flex-end' widthResponsive='auto'>
                                        <Item align='end' cancelada={ r.status === 0 ? true : false}>{r.agronomo_nome} - {formatDate(r.data_emissao)}</Item>
                                    </Row>
                                </Col>

                                <Col 
                                    width='auto' 
                                    justifyContent='center' 
                                    direction={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'row-reverse' : 'column'}
                                    alignResponsive={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'flex-start' : 'flex-end'}
                                >
                                    {r.status === 1 ? (
                                        <>
                                            <Row 
                                                justifyContent='flex-end'
                                                widthResponsive='auto' 
                                                align={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'center' : 'flex-end'}
                                            >
                                                <Icons>

                                                    {r.enviar_webhook === 1 && r.webhook_enviado === 0 ? (
                                                        <div id="send" onClick={() => sendPrescriptionWebHook(r.id)} aria-describedby="tooltip" ><IconSendWebHook width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Enviar via WebHook</p></div>
                                                    ) : (null)}

                                                    <div id="send" onClick={() => openModalSend(r.id)} aria-describedby="tooltip" ><IconSendEmail width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Compartilhar</p></div>
                                                    {
                                                        mostrar ? (
                                                            <div id="send" onClick={() => openModalSendGuide(r.id)} aria-describedby="tooltip"><IconGuide width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Gerar guia</p></div>
                                                        ): (null)
                                                    }      

                                                    <div id="send" onClick={() => clonar(r)} aria-describedby="tooltip" > <img src={iconCopy} width='20px' style={{marginRight: '20px', cursor: 'pointer'}} alt='Copiar código'/> <p>Clonar</p></div>
                                                    
                                                    <a  id="imprimi" href={r.receita_pdf} target="_blank" aria-describedby="tooltip"> <img src={imprimeIcon} title=""/> <p>Imprimir</p> </a>
                                                    <img  className="cancel" onClick={() => openModal(r)} src={cancelaIcon} />
                                                </Icons>
                                            </Row>

                                            <Row widthResponsive='auto'>
                                                <Icons>
                                                    {r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? (
                                                        <HomologButton 
                                                            estado={ r.local_aplicacao_estado }
                                                            receitaId={r.id}
                                                            statusEnvio={ parseInt(r.status_envio) }
                                                            updateReceitas={ () => props.updateReceitas() }
                                                            propsLocation={props.propsLocation}
                                                        />
                                                    ) : (null)}
                                                    
                                                </Icons>
                                            </Row>
                                        </>
                                    ) : (
                                        <Cancelada>
                                            <p>CANCELADA</p>
                                        </Cancelada>
                                    )}
                                </Col>
                            </Col>
                        </Row>      
                        
                        <Row  marginBottom='20px'>
                            <Col responsiveReverse={true}>
                                <Item cancelada={ r.status === 0 ? true : false}>Cultura</Item>
                                <Info cancelada={ r.status === 0 ? true : false}>{r.cultura_nome_comum}</Info>
                            </Col>
                            <Col responsiveReverse={true}>
                                <Item cancelada={ r.status === 0 ? true : false}>Praga</Item>
                                <Info cancelada={ r.status === 0 ? true : false}>{r.praga_nome_comum}</Info>
                            </Col>
                            <Col responsiveReverse={true}>
                                <Item cancelada={ r.status === 0 ? true : false}>Produtor</Item>
                                <Info cancelada={ r.status === 0 ? true : false}>{r.produtor_nome}</Info>
                            </Col>
                            <Col responsiveReverse={true}>
                                <Item cancelada={ r.status === 0 ? true : false}>Local de aplicação</Item>
                                <InfoFarm cancelada={ r.status === 0 ? true : false}><img src={local} /> {r.local_aplicacao_nome}</InfoFarm>
                            </Col>

                        </Row>     
                    </>
                ) : (
                    <>
                    {r.nome_comercial.length > 1 ? (
                        <>
                            {openPrescription ? (
                                <>
                                    {r.nome_comercial.map((nome, index) => (
                                        <Row marginBottom='20px' justifyContent='space-between' responsiveReverse={true}>
                                            <Row isTitle={true} isMobile={true}>
                                                <Manufacturer cancelada={ r.status === 0 ? true : false}>Fabricante</Manufacturer>
                                                <ManufacturerName cancelada={ r.status === 0 ? true : false}> {r.fabricante[index]}</ManufacturerName>
                                            </Row>  

                                            <Col justifyContent='space-between' width='auto'>
                                                <Row isTitle={true} >
                                                    <Title isTitle={true} cancelada={ r.status === 0 ? true : false}>{nome}</Title>
                                                    <ScientificName cancelada={ r.status === 0 ? true : false}> {r.nome_tecnico[index]}</ScientificName>
                                            
                                                    <Col width='auto' marginLeft='20px' marginTop='10px' responsiveReverse={true} alignItems='center' align='flex-start'>
                                                        <Item cancelada={ r.status === 0 ? true : false}>Praga</Item>
                                                        <Info cancelada={ r.status === 0 ? true : false}>{r.praga_nome_comum[index]}</Info>
                                                    </Col>
                                                </Row>  
                                                <Row isTitle={true} isDisplay={true}>
                                                    <Manufacturer cancelada={ r.status === 0 ? true : false}>Fabricante</Manufacturer>
                                                    <ManufacturerName cancelada={ r.status === 0 ? true : false}> {r.fabricante[index]}</ManufacturerName>
                                                </Row>      

                                            </Col>
                                            
                                            {index === 0 ? (
                                                <Col isTitle={true} direction='row' justifyContent='flex-end' alignResponsive='center'>
                                                    <Col>
                                                        <Row justifyContent='flex-end' widthResponsive='auto'>
                                                            <Item align='end' cancelada={ r.status === 0 ? true : false}>RECEITA: {r.numero}</Item>
                                                        </Row>

                                                        <Row justifyContent='flex-end' widthResponsive='auto'>
                                                            <Item align='end' cancelada={ r.status === 0 ? true : false}>{r.agronomo_nome} - {formatDate(r.data_emissao)}</Item>
                                                        </Row>
                                                    </Col>

                                                    <Col 
                                                        width='auto' 
                                                        justifyContent='center' 
                                                        direction={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'row-reverse' : 'column'}
                                                        alignResponsive={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'flex-start' : 'flex-end'}
                                                    >
                                                        {r.status === 1 ? (
                                                            <>
                                                                <Row 
                                                                    justifyContent='flex-end'
                                                                    widthResponsive='auto' 
                                                                    align={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'center' : 'flex-end'}
                                                                >
                                                                    <Icons>
                                                                    {r.enviar_webhook === 1 && r.webhook_enviado === 0 ? (
                                                                        <div id="send" onClick={() => sendPrescriptionWebHook(r.id)} aria-describedby="tooltip" ><IconSendWebHook width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Enviar via WebHook</p></div>
                                                                    ) : (null)}

                                                                        <div id="send" onClick={() => openModalSend(r.id)} aria-describedby="tooltip" ><IconSendEmail width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Compartilhar</p></div>
                                                                        {
                                                                            mostrar ? (
                                                                                <div id="send" onClick={() => openModalSendGuide(r.id)} aria-describedby="tooltip"><IconGuide width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Gerar guia</p></div>
                                                                            ): (null)
                                                                        }      
                                                                        <div id="send" onClick={() => clonar(r)} aria-describedby="tooltip" > <img src={iconCopy} width='20px' style={{marginRight: '20px', cursor: 'pointer'}} alt='Copiar código'/> <p>Clonar</p></div>                                                         
                                                                        <a  id="imprimi" href={r.receita_pdf} target="_blank" aria-describedby="tooltip"> <img src={imprimeIcon} title=""/> <p>Imprimir</p> </a>
                                                                        <img  className="cancel" onClick={() => openModal(r)} src={cancelaIcon} />

                                                                    </Icons>
                                                                </Row>

                                                                <Row widthResponsive='auto'>
                                                                    <Icons>
                                                                        {r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? (
                                                                            <HomologButton 
                                                                                estado={ r.local_aplicacao_estado }
                                                                                receitaId={r.id}
                                                                                statusEnvio={ parseInt(r.status_envio) }
                                                                                updateReceitas={ () => props.updateReceitas() }
                                                                                propsLocation={props.propsLocation}
                                                                            />
                                                                        ) : (null)}
                                                                        
                                                                    </Icons>
                                                                </Row>
                                                            </>
                                                        ) : (
                                                            <Cancelada>
                                                                <p>CANCELADA</p>
                                                            </Cancelada>
                                                        )}
                                                    </Col>
                                                </Col>
                                            ) : (null)}
                                        </Row>
                                    ))}

                                    <Row  marginBottom='20px'>
                                        <Col responsiveReverse={true}>
                                            <Item cancelada={ r.status === 0 ? true : false}>Cultura</Item>
                                            <Info cancelada={ r.status === 0 ? true : false}>{r.cultura_nome_comum}</Info>
                                        </Col>
                                        <Col responsiveReverse={true}>
                                            <Item cancelada={ r.status === 0 ? true : false}>Produtor</Item>
                                            <Info cancelada={ r.status === 0 ? true : false}>{r.produtor_nome}</Info>
                                        </Col>
                                        <Col responsiveReverse={true}>
                                            <Item cancelada={ r.status === 0 ? true : false}>Local de aplicação</Item>
                                            <InfoFarm cancelada={ r.status === 0 ? true : false}><img src={local} /> {r.local_aplicacao_nome}</InfoFarm>
                                        </Col>

                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row marginBottom='20px' responsiveReverse={true}>
                                        <Col isTitle={true}>
                                            <Row isTitle={true}>
                                                <Title cancelada={ r.status === 0 ? true : false}>{r.nome_comercial[0]}</Title>
                                                <ScientificName cancelada={ r.status === 0 ? true : false}> {r.nome_tecnico[0]}... </ScientificName>
                                            </Row>      
                                            
                                            <Row isTitle={true}>
                                                <Manufacturer cancelada={ r.status === 0 ? true : false}>Fabricante</Manufacturer>
                                                <ManufacturerName cancelada={ r.status === 0 ? true : false}> {r.fabricante[0]}... </ManufacturerName>
                                            </Row>      

                                        </Col>
                                        
                                        <Col isTitle={true} direction='row' justifyContent='flex-end' alignResponsive='center'>
                                            <Col>
                                                <Row justifyContent='flex-end' widthResponsive='auto'>
                                                    <Item align='end' cancelada={ r.status === 0 ? true : false}>RECEITA: {r.numero}</Item>
                                                </Row>

                                                <Row justifyContent='flex-end' widthResponsive='auto'>
                                                    <Item align='end' cancelada={ r.status === 0 ? true : false}>{r.agronomo_nome} - {formatDate(r.data_emissao)}</Item>
                                                </Row>
                                            </Col>

                                            <Col width='auto' direction={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'row-reverse' : 'column'}>
                                                {r.status === 1 ? (
                                                    <>
                                                        <Row 
                                                            justifyContent='flex-end' 
                                                            widthResponsive={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'auto' : '100%'}
                                                            align={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'center' : 'flex-end'}
                                                        >
                                                            <Icons>
                                                                {r.enviar_webhook === 1 && r.webhook_enviado === 0 ? (
                                                                    <div id="send" onClick={() => sendPrescriptionWebHook(r.id)} aria-describedby="tooltip" ><IconSendWebHook width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Enviar via WebHook</p></div>
                                                                ) : (null)}
                                                                <div id="send" onClick={() => openModalSend(r.id)} aria-describedby="tooltip" ><IconSendEmail width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Compartilhar</p></div>
                                                                {
                                                                    mostrar ? (
                                                                        <div id="send" onClick={() => openModalSendGuide(r.id)} aria-describedby="tooltip"><IconGuide width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Gerar guia</p></div>
                                                                    ): (null)
                                                                }           
                                                                <div id="send" onClick={() => clonar(r)} aria-describedby="tooltip" > <img src={iconCopy} width='20px' style={{marginRight: '20px', cursor: 'pointer'}} alt='Copiar código'/> <p>Clonar</p></div>                                                  
                                                                <a  id="imprimi" href={r.receita_pdf} target="_blank" aria-describedby="tooltip"> <img src={imprimeIcon} title=""/> <p>Imprimir</p> </a>
                                                                <img  className="cancel" onClick={() => openModal(r)} src={cancelaIcon} />

                                                            </Icons>
                                                        </Row>

                                                        <Row widthResponsive={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'auto' : '100%'}>
                                                            <Icons>
                                                                {r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? (
                                                                    <HomologButton 
                                                                        estado={ r.local_aplicacao_estado }
                                                                        receitaId={r.id}
                                                                        statusEnvio={ parseInt(r.status_envio) }
                                                                        updateReceitas={ () => props.updateReceitas() }
                                                                        propsLocation={props.propsLocation}
                                                                    />
                                                                ) : (null)}
                                                                
                                                            </Icons>
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <Cancelada>
                                                        <p>CANCELADA</p>
                                                    </Cancelada>
                                                )}
                                            </Col>
                                        </Col>
                                    </Row>      
                                    
                                    <Row  marginBottom='20px'>
                                        <Col responsiveReverse={true}>
                                            <Item cancelada={ r.status === 0 ? true : false}>Cultura</Item>
                                            <Info cancelada={ r.status === 0 ? true : false}>{r.cultura_nome_comum}</Info>
                                        </Col>
                                        <Col responsiveReverse={true}>
                                            <Item cancelada={ r.status === 0 ? true : false}>Praga</Item>
                                            <Info cancelada={ r.status === 0 ? true : false}>{r.praga_nome_comum[0]}...</Info>
                                        </Col>
                                        <Col responsiveReverse={true}>
                                            <Item cancelada={ r.status === 0 ? true : false}>Produtor</Item>
                                            <Info cancelada={ r.status === 0 ? true : false}>{r.produtor_nome}</Info>
                                        </Col>
                                        <Col responsiveReverse={true}>
                                            <Item cancelada={ r.status === 0 ? true : false}>Local de aplicação</Item>
                                            <InfoFarm cancelada={ r.status === 0 ? true : false}><img src={local} /> {r.local_aplicacao_nome}</InfoFarm>
                                        </Col>

                                    </Row>
            
                                    <div className="rowBtn" style={{
                                        justifyContent: 'center',
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                    }}>
                                        {openPrescription === false && (
                                            <Button 
                                                width='100%'
                                                height='30px'
                                                value='Vizualizar receita completa'
                                                outline={true}
                                                disabled={r.status === 0 || r.status === "0" ? true : false}
                                                onClick={() => setOpenPrescription(true)}
                                            />
                                        )}
                                    </div>      
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            <Row marginBottom='20px' justifyContent='space-between' responsiveReverse={true}>
                                
                                <Col isTitle={true} direction='column' justifyContent='flex-end' alignResponsive='center'>
                                    <Row isTitle={true}>
                                        {/* Título/Nome do produto da receita e nome técnico */}
                                        <Title cancelada={ r.status === 0 ? true : false}>{r.nome_comercial[0]}</Title>
                                        <ScientificName cancelada={ r.status === 0 ? true : false}> {r.nome_tecnico[0]} </ScientificName>
                                    </Row>      
                                    
                                    <Row isTitle={true}>
                                        <Manufacturer cancelada={ r.status === 0 ? true : false}>Fabricante</Manufacturer>
                                        <ManufacturerName cancelada={ r.status === 0 ? true : false}> {r.fabricante[0]} </ManufacturerName>
                                    </Row>      

                                </Col>
                                
                                <Col isTitle={true} direction='row' justifyContent='flex-end' alignResponsive='center'>
                                    <Col alignItems='center'>
                                        <Row justifyContent='flex-end' widthResponsive='auto'>
                                            <Item align='end' cancelada={ r.status === 0 ? true : false}> RECEITA: {r.numero}</Item>
                                        </Row>

                                        <Row justifyContent='flex-end' widthResponsive='auto'>
                                            <Item align='end' cancelada={ r.status === 0 ? true : false}>{r.agronomo_nome} - {formatDate(r.data_emissao)}</Item>
                                        </Row>
                                    </Col>

                                    <Col 
                                        width='auto' 
                                        direction={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'row-reverse' : 'column'}
                                    >
                                        
                                        {r.status === 1 ? (
                                            <>
                                                <Row 
                                                    justifyContent='flex-end' 
                                                    widthResponsive={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'auto' : '100%'}
                                                    align={r.local_aplicacao_estado === 'GO' || (r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'center' : 'flex-end'}
                                                >
                                                    <Icons>
                                                        {r.enviar_webhook === 1 && r.webhook_enviado === 0 ? (
                                                            <div id="send" onClick={() => sendPrescriptionWebHook(r.id)} aria-describedby="tooltip" ><IconSendWebHook width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Enviar via WebHook</p></div>
                                                        ) : (null)}
                                                        <div id="send" onClick={() => openModalSend(r.id)} aria-describedby="tooltip" ><IconSendEmail width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Compartilhar</p></div>
                                                        {
                                                            mostrar ? (
                                                                <div id="send" onClick={() => openModalSendGuide(r.id)} aria-describedby="tooltip"><IconGuide width='20px' style={{marginRight: '20px', cursor: 'pointer'}}/><p>Gerar guia</p></div>
                                                            ): (null)
                                                        }
                                                        <div id="send" onClick={() => clonar(r)} aria-describedby="tooltip" > <img src={iconCopy} width='20px' style={{marginRight: '20px', cursor: 'pointer'}} alt='Copiar código'/> <p>Clonar</p></div>
                                                        <a  id="imprimi" href={r.receita_pdf} target="_blank" aria-describedby="tooltip"> <img src={imprimeIcon} title=""/> <p>Imprimir</p> </a>
                                                        <img  className="cancel" onClick={() => openModal(r)} src={cancelaIcon} />
                                                    </Icons>
                                                </Row>

                                                <Row widthResponsive={r.local_aplicacao_estado === 'GO' ||(r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? 'auto' : '100%'}>
                                                    <Icons>
                                                        {r.local_aplicacao_estado === 'GO' ||(r.local_aplicacao_estado === 'MT' && r.nu_receita !== 'COMPLEMENTAR') || r.local_aplicacao_estado === 'SC' || r.local_aplicacao_estado === 'ES' ? (
                                                            <HomologButton 
                                                                estado={ r.local_aplicacao_estado }
                                                                receitaId={r.id}
                                                                statusEnvio={ parseInt(r.status_envio) }
                                                                updateReceitas={ () => props.updateReceitas() }
                                                                propsLocation={props.propsLocation}
                                                            />
                                                        ) : (null)}
                                                        
                                                    </Icons>
                                                </Row>
                                            </>
                                        ) : (
                                            <Cancelada>
                                                <p>CANCELADA</p>
                                            </Cancelada>
                                        )}
                                    </Col>
                                </Col>
                            </Row>      
                            
                            <Row>
                                <Col responsiveReverse={true}>
                                    <Item cancelada={ r.status === 0 ? true : false}>Cultura</Item>
                                    <Info cancelada={ r.status === 0 ? true : false}>{r.cultura_nome_comum}</Info>
                                </Col>
                                <Col responsiveReverse={true}>
                                    <Item cancelada={ r.status === 0 ? true : false}>Praga</Item>
                                    <Info cancelada={ r.status === 0 ? true : false}>{r.praga_nome_comum[0]}</Info>
                                </Col>
                                <Col responsiveReverse={true}>
                                    <Item cancelada={ r.status === 0 ? true : false}>Produtor</Item>
                                    <Info cancelada={ r.status === 0 ? true : false}>{r.produtor_nome}</Info>
                                </Col>
                                <Col responsiveReverse={true}>
                                    <Item cancelada={ r.status === 0 ? true : false}>Local de aplicação</Item>
                                    <InfoFarm cancelada={ r.status === 0 ? true : false}><img src={local} /> {r.local_aplicacao_nome}</InfoFarm>
                                </Col>

                            </Row>      
                        </>
                    )}
                    </>
                )}
            </Card>
        


            <ModalAlert
                show={showModalBlock}
                onHide={false}
            >
                <h4 style={{
                    textAlign: 'center'
                }}>Essa é uma função exclusiva para clientes AgroReceita</h4>

                <p style={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}>
                    Para não comprometer o controle de suas receitas agronômicas durante o período de teste esta função é bloqueada.
                </p>
                
                <p style={{
                    textAlign: 'center',
                    marginTop: '20px',
                }}>
                    Assine o AgroReceita para desbloquear esta função!
                </p>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='VER PLANOS'
                        outline={false}
                        disabled={false}
                        onClick={() => openPlanos(false)}
                        loading={false}
                    />
                </div>
            </ModalAlert>
        
            <ModalConfirmar
                show={showModal}
                estado={estadoClickReceita}
                text="Digite 'CANCELAR' para continuar o cancelamento da receita" 
                title="Confirmar Cancelamento de Receita"
                id={idReceita}
                update={resposta}
                updateMt={(r, motivo) => respostaMt(r, motivo)}
                onHide={modalClose}
            />

            <ModalAlert
                show={showSend}
                onHide={() => setShowSend(false)}
                size='md'
            >
                <h4 style={{
                    textAlign: 'center'
                }}>Enviar receita por Email</h4>
                
                <div style={{ width: 'auto', maxWidth: '400px', margin: 'auto' }}>
                    <Input
                        id="email"
                        value={email}
                        label="Email"
                        onChange={(e) => setEmail(e)}
                        onBlur={(e) => setEmail(e.target.value)}
                    />

                </div>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='Enviar'
                        outline={false}
                        disabled={false}
                        onClick={() => sendPrescriptionMail()}
                        loading={loadingSend}
                    />
                </div>
            </ModalAlert>

            <ModalAlert
                show={showPrescriptionR}
                onHide={() => setShowPrescriptionR(false)}
                size='md'
            >
                <h4 style={{
                    textAlign: 'center',
                    marginBottom: '20px'
                }}>Escolha o produto e praga para o guia</h4>
                
                <div style={{ width: 'auto', maxWidth: '400px', margin: 'auto' }}>
                    <Select
                        name="Produto (Praga)"
                        className="select"
                        label="Produto (Praga)"
                        id="product_pest"
                        value={prescriptions.value || prescriptions}
                        onChange={idSendPrescriptionRelation =>
                            idSendPrescriptionRelation ? setIdSendPrescriptionRelation(idSendPrescriptionRelation.value) : setIdSendPrescriptionRelation("")
                        }
                        options={prescriptions}
                        selectUniqueOption={true}
                        loading={loadingCampoNome}
                        isModal={true}
                    />

                </div>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '30px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='AVANÇAR'
                        outline={false}
                        disabled={false}
                        onClick={() => {setShowPrescriptionR(false) ; setShowSendGuide(true); getApplicators(); getProviders()}}
                        loading={loadingSend}
                    />
                </div>
            </ModalAlert>

            <ModalAlert
                show={showSendGuide}
                onHide={() => setShowSendGuide(false)}
                size='md'
            >
                <h4 style={{
                    textAlign: 'center'
                }}>Imprimir Guia de Aplicação</h4>
                <div style={{width:'80%', marginTop: '35px'}}>
                    <Select
                        name="Aplicador"
                        className="select"
                        label="Aplicador"
                        id="nome_aplicador"
                        value={aplicadorId.value || aplicadorId}
                        onChange={aplicadorId =>
                            aplicadorId ? setAplicadorId(aplicadorId.value) : setAplicadorId("")
                        }
                        options={aplicadores}
                        selectUniqueOption={true}
                        disabled={aplicadorDisabled}
                        buttonAddNew={true}
                        loading={loadingCampoNome}
                        onClickAddNew={() => setShowAddAplicador(true)}
                        isModal={true}
                    />
                </div>
                <div style={{width:'80%', marginTop: '35px'}}>
                    <Select
                        name="Prestador"
                        className="select"
                        label="Prestador"
                        id="razao"
                        value={providerId.value || providerId}
                        onChange={providerId =>
                            providerId ? setProviderId(providerId.value) : setProviderId("")
                        }
                        options={providers}
                        selectUniqueOption={true}
                        disabled={providerDisabled}
                        buttonAddNew={true}
                        loading={loadingCampoNome}
                        onClickAddNew={() => setShowAddProvider(true)}
                        isModal={true}
                    />
                </div>
                <Data>
                    <div className="input-data-filter">
                        <input
                            className="input-date"
                            type="text"
                            placeholder="Data de aplicação"
                            onFocus={(e) => e.currentTarget.type = "date"}
                            onBlur={(e) => {
                                e.currentTarget.type = "text";
                                e.currentTarget.placeholder = "Data de aplicação";
                            }}
                            onChange={e => setDataAplicacao(e.target.value)}
                        />
                    </div>
                </Data>
                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='Enviar'
                        outline={false}
                        disabled={false}
                        onClick={() => sendPrescriptionGuide()}
                        loading={loadingSend}
                    />
                </div>
            </ModalAlert>

            <ModalAlert
                show={showAddAplicador}
                onHide={() => setShowAddAplicador(false)}
                size='md'
            >
                <AddAplicadores 
                    aplicadores={aplicadores}
                    nome={nomeAplicador}
                    registro={registroAplicador}
                    cpf={cpfAplicador}
                    isModal={true}
                    success={() => {setShowAddAplicador(false) ; getApplicators()}}
                />
            </ModalAlert>

            <ModalAlert
                show={showModalGuia}
                size='lg'
                onHide={() =>{ 
                    setShowModalGuia(false);
                }}
            >
                <p 
                    style={{
                        fontFamily: 'Lato', 
                        fontSize: '22px', 
                        fontWeight: 'bold', 
                        fontStretch: 'normal', 
                        width: '100%', 
                        textAlign: 'center', 
                        alignSelf: 'center'
                    }}
                > Essa função está disponível apenas no plano PRO.</p>

                <p style={{
                    color: '#303848', 
                    fontFamily: 'Lato', 
                    fontSize: '16px', 
                    fontWeight: 'normal', 
                    width: '100%', 
                    textAlign: 'center', 
                    alignSelf: 'center'
                }}>Faça o upgrade do seu plano para imprimir Guias de Aplicação.</p>

                <div className="button-finish" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: '40px' }}>
                    <Button
                        width='296px'
                        height='50px'
                        value='VER PLANOS'
                        outline={false}
                        disabled={false}
                        onClick={() => openPlanos()}
                        loading={false}
                    />
                </div>
            </ModalAlert>

            <ModalAlert
                show={showAddProvider}
                onHide={() => setShowAddProvider(false)}
                size='md'
            >
                <AddProviders 
                    providers={providers}
                    razao={razao_social}
                    cnpj={cnpj}
                    isModal={true}
                    success={() => {setShowAddProvider(false) ; getProviders()}}
                />
            </ModalAlert>
        </>
    )
}

export default CardPrescription;
