import React, { useState, useEffect } from 'react';

import { ContainerInterruptor, InterruptorArea, InterruptorMarker } from './style';
const Interruptor = ({ ...props }) => {

    const [isOn, setIsOn] = useState(props.value);

    const changeIsOn = () => {
        if (props.disabled === true) {
            setIsOn(isOn)
        } else {
            setIsOn(!isOn)
        }
    }

    useEffect(() => {
        props.onChange(isOn);
    }, [isOn]);

    useEffect(() => {
        if (props.value != isOn) {
            setIsOn(props.value);
        }
    },[props.value]);
    return (
        <ContainerInterruptor onClick={() => changeIsOn()}>
            <InterruptorArea isOn={isOn} onClick={() => changeIsOn()}>
                <InterruptorMarker isOn={isOn} onClick={() => changeIsOn()}/>
            </InterruptorArea>
        </ContainerInterruptor>
    )
}

export default Interruptor;
