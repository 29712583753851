import React,{ useState, useEffect } from "react";
import api from "../../services/api";
import {
    Container,
    ScreenClassRender
} from "react-grid-system";
import GenericUpper from "../../components/generic-upper";
import Select from "../../components/select/select_v2";
import Input from "../../components/input/input_v2";
import Button  from '../../components/button/button_v2';
import { store } from "react-notifications-component";
import { UserContainer } from "./styles";

export const USER = "@userinfo";

const EditUsuario = ({...props}) => {


    const [permissoes, setPermissoes] = useState([])
    const [lojas, setLojas] = useState([]);
    const [permissaoId, setPermissaoId] = useState('');
    const [permissaoLabel, setPermissaoLabel] = useState('');
    const [email, setEmail] = useState('');
    const [enviar_receita, setEnviarReceita] = useState('');
    const [nome, setNome] = useState('');
    const [lojaId, setLojaId] = useState('');
    const [lojaLabel, setLojaLabel] = useState('');
    const [opStatus, setOpStatus] = useState([
        { value: "AT", label: "Ativo" },
        { value: "IN", label: "Inativo" }
    ]);
    const [disabledUserInativo, setDisabledUserInativo] = useState(false);
    const [status, setStatus] = useState('');
    const [userId, setUserId] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [paramPost, setParamPost] = useState(false);

    const [loading, setLoading] = useState(false);
    const [userPlanId, setUserPlanId] = useState();


    const getFiliais = async () => {
        try {
            const response = await api.get("/filiais");
            const filiais = response.data.data;
            if (filiais && filiais.length > 0) {
                let filialFormat = formatFiliais(filiais);
                setLojas(filialFormat);
                
            } else {
                setLojas([{value: filiais.id, label: filiais.nome_fantasia}]);
                
            }
        } catch (e) {
        }
    };

    const getPermission = async () => {
        const response = await api.get(`/nivel/permissao`);
        
        console.log(response)
        
        let v = response.data.data.nivel;
    
        v[1] = {
            value: 1,
            label: v[1]
        }
    
        v[2] = {
            value: 2,
            label: v[2]
        }
    
        v[3] = {
            value: 3,
            label: v[3]
        }
    
        for (let i in v) {
            if (v[i].label !== undefined) {
                permissoes.push(v[i]);
            }
        }
        
    }
    

    const formatFiliais = (filiais) => {
        let resultFilial = [];
        filiais.map(f => {
            let format = {value: f.id, label: f.nome_fantasia}
            resultFilial = [...resultFilial, format];
        })

        return resultFilial;
    }


    const validatePermissao = async (permissao) => {
        setPermissaoId(permissao.value);
        setPermissaoLabel(permissao.label);
    }

    const validateNome = async (nome) => {
        setNome(nome);
    }

    const validateEmail = async (email) => {
        setEmail(email);
    }

    const validateLoja = async (loja) => {
        setLojaId(loja.value);
        setLojaLabel(loja.label);
    }

    const validateStatus = async (status) => {
        setStatus(status.value);
        if(status.value == "AT" && disabledUserInativo == true){
            setDisabledUserInativo(false);
        }
    }

    const salvarUser = async () => {
        try {
            setLoading(true);
            if(paramPost == false){
                if(lojaId){
                    const payload = {
                        agro_permission_id: permissaoId,
                        username: email,
                        agro_subsidiary_id: lojaId,
                        status: status,
                        name: nome,
                        enviar_receita,
                        whatsapp
                    }
                    const response = await api.put(`/users/${userId}`, payload);
                }else{
                    const payload = {
                        agro_permission_id: permissaoId,
                        username: email,
                        agro_subsidiary_id: '',
                        status: status,
                        name: nome,
                        enviar_receita,
                        whatsapp
                    }

                    const response = await api.put(`/users/${userId}`, payload);
                }
                
            }else{
                if(lojaId){
                    const payload = {
                        agro_permission_id: permissaoId,
                        username: email,
                        agro_subsidiary_id: lojaId,
                        status: status,
                        name: nome,
                        enviar_receita,
                        whatsapp
                    }
                    const response = await api.put(`/users/${userId}?action=edition`, payload);
                }else{
                    const payload = {
                        agro_permission_id: permissaoId,
                        username: email,
                        agro_subsidiary_id: '',
                        status: status,
                        name: nome,
                        enviar_receita,
                        whatsapp
                    }
                    const response = await api.put(`/users/${userId}?action=edition`, payload);
                }
            }

            props.history.push('/usuarios');
            setLoading(false);

        } catch (err) {

            setLoading(false);

            const ERRO = err.response.data.errors;
            try {
                let inputWithErrors = document.querySelectorAll(".error");
    
                for (let input of inputWithErrors) {
                    input.classList.remove("error");
                }
    
                const errors = err.response.data.errors;
    
                for (let field in errors) {
                    const input = document.getElementById(field);
                    input.classList.add("error");
                }

                store.addNotification({
                    title: "Erro!",
                    message: `Verifique os campos vermelhos`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
                
            } catch (error) {
                store.addNotification({
                    title: "Erro!",
                    message: `${ERRO}`,
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 4000,
                        onScreen: true
                    }
                });
            }
        }
    }


    const getUsers = async (id) => {
        const response = await api.get(`/users/${id}`);
        console.log(response.data.data);
        const users = response.data.data[0];

        setNome(users.name);
        setPermissaoId(users.agro_permission_id);
        setEmail(users.username);
        setLojaId(users.agro_subsidiary_id);
        setStatus(users.status);
        setEnviarReceita(users.enviar_receita);
        setWhatsapp(users.contact.whatsapp);

        if(users.status == "IN"){
            setDisabledUserInativo(true);
        }else{
            setParamPost(true);
        }
    } 

    const back = () => {
        window.location = "/usuarios";
    }

    const getUserPlan = () => {
        const user = JSON.parse(localStorage.getItem(USER));
        const planId = user.contrato.agro_plan_config.agro_plan_id;
        setUserPlanId(planId);
    }


    useEffect(() => {
        setUserId(props.match.params.id);
        getFiliais();
        getUserPlan();
        getPermission();
    },[])

    useEffect(() => {
        if(lojas && permissoes){
            if(!nome){
                getUsers(props.match.params.id);
            }
        }
    },[lojas, permissoes])

    return(
        <ScreenClassRender
            render={screenClass => (
                <>
                    <GenericUpper
                        title="Editar usuário"
                        subtitle="Gerencie seus usuários"
                    ></GenericUpper>

                    <UserContainer>
                        <Container style={{ width: '400px', paddingRight: '0px', paddingLeft: '0px' }}>
                            <div className="container-center">

                                <Select 
                                    label="Permissão"
                                    id='permission'
                                    options={permissoes}
                                    value={permissaoId}
                                    onChange={e => validatePermissao(e)}
                                    disabled={disabledUserInativo}
                                />

                                <Input 
                                    label="Nome"
                                    name="nome"
                                    id="name_id"
                                    value={nome}
                                    onChange={e => validateNome(e)}
                                    disabled={disabledUserInativo}
                                />

                                {enviar_receita !== '' && (
                                    <Input 
                                        label="Email"
                                        type="email"
                                        name="email"
                                        id="username"
                                        value={email}
                                        onChange={e => validateEmail(e)}
                                        disabled={disabledUserInativo}
                                        yesNo={true}
                                        messageYesNo={'enviar receitas por email'}
                                        onChangeYesNo={(e) => setEnviarReceita(e ? 1 : 0)} 
                                        initialYesNoValue={enviar_receita}
                                    />
                                )}

                                <Input
                                    name="whatsapp"
                                    value={whatsapp}
                                    type="text"
                                    label="Whatsapp (opcional)"
                                    id="whatsapp"
                                    onChange={e => setWhatsapp(e)}
                                    inputmode="numeric"
                                />

                                <Select 
                                    label="Loja"
                                    id='loja_id'
                                    options={lojas}
                                    value={lojaId}
                                    onChange={e => validateLoja(e)}
                                    disabled={disabledUserInativo}

                                />

                                <Select 
                                    label="Status"
                                    id='status_id'
                                    options={opStatus}
                                    value={status}
                                    onChange={e => validateStatus(e)}
                                />


                                <div 
                                    className="cadastrar" 
                                    id='save'
                                    style={{ 
                                        width: '100%', 
                                        display: 'flex', 
                                        flexDirection: 'row', 
                                        justifyContent: 'space-evenly',
                                    }}
                                >
                                    <Button 
                                        width='45%'
                                        height='50px'
                                        value='VOLTAR'
                                        outline={true}
                                        disabled={false}
                                        onClick={() => back()}
                                        loading={false}
                                    />

                                    <Button 
                                        width='45%'
                                        height='50px'
                                        value='SALVAR'
                                        outline={false}
                                        disabled={false}
                                        onClick={() => salvarUser()}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </Container>
                    </UserContainer>
                </>
            )}
        />
    )
}

export default EditUsuario;
