import React, { useState, useEffect, useRef } from 'react';
import { h4, Label, FilterArea, SelectArea, DataFilter, ChartArea, Loading, IconeBotao } from './style';
import api from '../../services/api';
import DateRange from '../date-range';
import { Line } from 'react-chartjs-2'
import Button from '../button/button_v2';
import { store } from 'react-notifications-component';
import IconeBaixar from '../../assets/iconeBaixar.svg';


const CardArtQtdCom = ({ ...props }) => {

    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 365));
    const priorDate90 = new Date(new Date().setDate(today.getDate() - 90));

    const [dataInicial, setDataInicial] = useState(priorDate.toISOString().slice(0, 10));
    const [dataInicial90, setDataInicial90] = useState(priorDate90.toISOString().slice(0, 10));
    const [dataFinal, setDataFinal] = useState(today.toISOString().slice(0, 10));
    const [texto, setTexto] = useState("");
    const [qtdAdquirida, setQtdAdquirida] = useState([]);

    const [labels, setLabels] = useState([]);
    const [data, setDatas] = useState([]);

    const [showChart, setShowChart] = useState(false);
    const [qtdReceita, setQtdReceita] = useState(null);

    const [ref] = useState(useRef());

    const [loadingPdf, setShowLoadingPdf] = useState(false);

    const searchInitialData = async () => {
        try {
            const response = await api.post('/relatorios/arts/grafico/qtd', {
                dtInicial: dataInicial,
                dtFinal: dataFinal,
            });
            
            const response90 = await api.post('/relatorios/arts/grafico/qtd', {
                dtInicial: dataInicial90,
                dtFinal: dataFinal,
            });

            const responseQtd = await api.get('/relatorios/receitas/qtdAdquirida');

            setQtdReceita(response.data.data.qtdArts);
            setLabels(response90.data.data.meses);
            setDatas(response90.data.data.arts);
            setTexto(response.data.data.dataInicial + " a " + response.data.data.dataFinal);
            setQtdAdquirida(responseQtd.data.data.dados);

            setShowChart(true);
        } catch (error) {
            
        }
    }

    const gerarPDF = async () => {
        try {
            setShowLoadingPdf(true);

            const response = await api.get(`/relatorios/area/pdf`)

            let win = window.open(response.data.data.link);

            setShowLoadingPdf(false);

        } catch (error) {
            setShowLoadingPdf(false);
            store.addNotification({
                title: "Erro!",
                message: `${error.response.data.errors}`,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 4000,
                    onScreen: true
                }
            });
        }
    }

    useEffect(() => {
        searchInitialData();
    }, [])

    return (
        <>
                {
                    qtdReceita !== null ? (
                        <>
                            {/* <div>
                                <IconeBotao>
                                    <div className='card-titulo'><h2 style={{
                                        textAlign: 'left',
                                        textTransform: 'uppercase',
                                        fontFamily: 'Lato',
                                        fontSize: '10px',
                                        color: '#8a92a2',
                                        paddingTop: '10px',
                                        marginBottom: '0px'
                                    }}>Maior comercialização *</h2></div>
                                    <div className='icons' style={{
                                        display:'flex'
                                    }}></div>
                                </IconeBotao>
                            </div>
                            <>
                                <p style={{marginBottom: '7px', fontSize: '12px'}}>últimos 12 meses</p>
                                <h4>{qtdReceita}</h4>
                            </> */}
                            <div>
                                <IconeBotao>
                                    <div className='card-titulo'><h2 style={{
                                        textAlign: 'left',
                                        textTransform: 'uppercase',
                                        fontFamily: 'Lato',
                                        fontSize: '10px',
                                        color: '#8a92a2',
                                        paddingTop: '10px',
                                        marginBottom: '0px'
                                    }}>Quantidade adquirida</h2></div>
                                    <div className='icons' style={{
                                        display:'flex'
                                    }}></div>
                                </IconeBotao>
                            </div>
                            <>
                                <p style={{marginBottom: '7px', fontSize: '10px'}}>últimos 12 meses</p>
                                <h5>{qtdAdquirida[0]} KG</h5>
                                <h5>{qtdAdquirida[1]} L</h5>
                            </>
                            

                            <div>
                                <IconeBotao>
                                    <div className='card-titulo'><h2 style={{
                                        textAlign: 'left',
                                        textTransform: 'uppercase',
                                        fontFamily: 'Lato',
                                        fontSize: '10px',
                                        color: '#8a92a2',
                                        paddingTop: '10px',
                                        marginBottom: '0px'
                                    }}>Número de Art/trt emitidas</h2></div>
                                    <div className='icons' style={{
                                        display:'flex'
                                    }}></div>
                                </IconeBotao>
                            </div>
                            <>
                                <p style={{marginBottom: '7px', fontSize: '10px'}}>últimos 90 dias</p>
                                <h5>{qtdReceita}</h5>
                            </>
                            <Line 
                                data={{
                                    labels: labels,
                                    datasets: [
                                        {
                                            label: 'Receitas',
                                            data: data,
                                            backgroundColor: 'rgb(47, 187, 105, 0.4)',
                                            borderColor: 'rgb(47, 187, 105, 1)',
                                            borderWidth: 1,
                                            tension: 0.3,
                                        },
                                    ],
                                }} 
                                height={120} 
                                options={{
                                    plugins:{
                                        legend:{
                                            display: false,
                                        },
                                        title: {
                                            display: false,
                                            font:{
                                                size: 8,
                                            },
                                            text: texto,
                                        },
                                    },
                                    scales:{
                                        y: {
                                            beginAtZero: true,
                                            display: true,
                                            ticks:{
                                                stepSize: 1,

                                            }
                                        },
                                        // x:{
                                        //     ticks:{
                                        //         display: false
                                        //     }
                                        // }
                                    }

                                }}
                            />
                        </>
                    ) : (<Loading />)
                }

            

        </>
    )
}

export default CardArtQtdCom;
