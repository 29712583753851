import React from "react";

import estados from "../../services/estados";
import api from "../../services/api";
import { store } from "react-notifications-component";
import GenericUpper from "../../components/generic-upper";
import { DevolucaoAddContainer } from "./styles";
import { Container } from "react-grid-system";
import Input from "../../components/input/input_v2";
import Select from "../../components/select/select_v2";
import Button from "../../components/button/button_v2";
import buscaCep from "../../services/buscaCep";

class AddDevolucao extends React.Component {
    
     state = {
        nome: "",
        estado: "",
        cep: "",
        telefone: "",
        celular: "",
        responsavel: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        OpStatus: [{ value: 1, label: "Ativo" }, { value: 0, label: "Inativo" }],
        status: 1,
        filiais: [],
        loading: false,

        erroFilial: false,
        erroFilialMsg: '',
        
        erroBairro: false,
        erroBairroMsg: '',
        
        erroCep: false,
        erroCepMsg: '',

        erroCidade: false,
        erroCidadeMsg: '',

        erroEndereco: false,
        erroEnderecoMsg: '',

        erroEstado: false,
        erroEstadoMsg: '',

        erroNome: false,
        erroNomeMsg: '',

        erroNumero: false,
        erroNumeroMsg: '',

        erroStatus: false,
        erroStatusMsg: '',

        loadingCep: false,
    };

    componentDidMount() {
        if (this.props.isModal != true) {
            window.scrollTo(0, 0);
        }
        this.getFiliais();
    };

    handlePost = async e => {

        this.setState({ loading: true });

        const {
            nome,
            responsavel,
            telefone,
            celular,
            estado,
            numero,
            complemento,
            cep,
            endereco,
            bairro,
            cidade,
            filial,
            status
        } = this.state;

        const payload = {
            nome,
            estado,
            endereco,
            numero,
            complemento,
            bairro,
            cidade,
            telefone,
            celular,
            responsavel,
            cep,
            agro_subsidiary_id: filial,
            filial: filial ? filial.value : "",
            status
        };

        try {
            const response = await api.post("/devolucoes", payload);

            if (response.status === 200) {
                this.setState({ loading: false });

                if (this.props.isModal === true) {
                    this.props.update();
                } else {
                    this.props.history.push("/devolucoes");
                }
                store.addNotification({
                    title: "Sucesso!",
                    message: "A devolução foi cadastrada corretamente.",
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            }
        } catch (err) {
            this.setState({ loading: false });

            this.setState({
                erroFilial: false,
                erroBairro: false,
                erroCep: false,
                erroCidade: false,
                erroEndereco: false,
                erroEstado: false,
                erroNome: false,
                erroNumero: false,
                erroStatus: false
            });



            const errors = err.response.data.errors;

            for (let field in errors) {
                if ( field === 'agro_subsidiary_id'){
                    this.setState({
                        erroFilial: true,
                        erroFilialMsg: 'O campo não pode estar em branco',
                    });
                } else if ( field === 'bairro'){
                    this.setState({
                        erroBairro: true,
                        erroBairrolMsg: 'O campo não pode estar em branco',
                    });
                }else if ( field === 'cep'){
                    this.setState({
                        erroCep: true,
                        erroCepMsg: 'O campo não pode estar em branco',
                    });
                }else if ( field === 'cidade'){
                    this.setState({
                        erroCidade: true,
                        erroCidadeMsg: 'O campo não pode estar em branco',
                    });
                }else if ( field === 'endereco'){
                    this.setState({
                        erroEndereco: true,
                        erroEnderecoMsg: 'O campo não pode estar em branco',
                    });
                }else if ( field === 'estado'){
                    this.setState({
                        erroEstado: true,
                        erroEstadoMsg: 'O campo não pode estar em branco',
                    });
                }else if ( field === 'nome'){
                    this.setState({
                        erroNome: true,
                        erroNomeMsg: 'O campo não pode estar em branco',

                    });
                }else if ( field === 'numero'){
                    this.setState({
                        erroNumero: true,
                        erroNumeroMsg: 'O campo não pode estar em branco',

                    });
                }else if ( field === 'status'){
                    this.setState({
                        erroStatus: true,
                        erroStatusMsg: 'O campo não pode estar em branco',
                    });
                }
            }

            store.addNotification({
                title: "Erro!",
                message: "Verifique os campos em vermelho",
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
        }
    };

    cangeCep = (e) => {
        this.setState({ cep: e, erroCep: false });
    }

    fetchCep = async () => {
        this.setState({ loadingCep: true });
        let separaCep = this.state.cep;

        try {
            const cep = await buscaCep(separaCep);
            const { logradouro, bairro, localidade, uf } = cep;

            this.setState({
                endereco: logradouro,
                erroEndereco: false,

                bairro,
                erroBairro: false,

                cidade: localidade,
                erroCidade: false,

                estado: uf,
                erroEstado: false,

                loadingCep: false
            });


        } catch (err) {

            store.addNotification({
                title: "Erro!",
                message: err.message,
                type: "danger",
                insert: "top",
                container: "top-right",
                animationIn: ["animated", "fadeIn"],
                animationOut: ["animated", "fadeOut"],
                dismiss: {
                    duration: 5000,
                    onScreen: true
                }
            });
            this.setState({ loadingCep: false, erroCep: true, erroCepMsg: err.message });
        }
    };
    
    getFiliais = async () => {
        try {
            const response = await api.get("/filiais");
            const filiais = response.data.data;
            
            if(filiais.length){
                const formatFiliais = filiais.map(f => {
                    let format = {
                        value: f.id,
                        label: f.nome_fantasia
                    };
                    return format;
                });    
                    
                
                if (filiais && filiais.length > 0) {                
                    this.setState({ filiais: formatFiliais });
                } else {
                    this.setState({ filiais: formatFiliais });
    
                }

            }else{
                const formatFiliais = [{value: filiais.id, label: filiais.nome_fantasia}];
                this.setState({ filiais: formatFiliais });
            }


            
            
        } catch (e) {
            this.setState({ loading: false });
        }
    };


    render() {
        const {
            nome,
            estado,
            endereco,
            numero,
            complemento,
            bairro,
            cidade,
            cep,
            responsavel,
            telefone,
            celular,
        } = this.state;

        return (
            <>

                {this.props.isModal === true ? (
                    <h4 style={{ textAlign: 'center' }}>Local de devolução</h4>
                ) : (
                    <GenericUpper
                        title="Cadastro de Devolução"
                        subtitle="Cadastre um local de devolução"
                        help={true}
                        text={"Dúvidas para cadastrar um local de devolução?"}
                        toSearch={'https://agroreceita.com.br/como-cadastrar-locais-de-devolucao/'}
                        textToSearch={'Clique aqui e acesse o tutorial!'}
                    ></GenericUpper>
                )}

                <DevolucaoAddContainer { ...this.props }>
                    <Container style={{ paddingLeft: this.props.isModal ? '0px' : '15px', paddingRight: this.props.isModal ? '0px' : '15px' }}>
                        <form>
                            <Input
                                name="nome"
                                type="text"
                                label="Nome"
                                value={nome}
                                id="nome"
                                onChange={e => this.setState({ nome: e, erroNome: false })}
                                erro={this.state.erroNome}
                                messageErro={this.state.erroNomeMsg}
                            />

                            <Input
                                name="responsavel"
                                type="text"
                                label="Responsável (opcional)"
                                value={responsavel}
                                id="responsave_"
                                onChange={e => this.setState({ responsavel: e })}
                                erro={false}
                                messageErro=''
                            />

                            <Select 
                                name="filial"
                                className="select"
                                label="Filial"
                                id="agro_subsidiary_id"
                                value={this.state.filial}
                                onChange={f => this.setState({ filial: f.value, erroFilial: false })}
                                options={this.state.filiais}
                                disabled={false}
                                erro={this.state.erroFilial}
                                messageErro={this.state.erroFilialMsg}
                                selectUniqueOption={true}

                            />

                            <Input
                                name="telefone"
                                type="text"
                                label="Telefone (opcional)"
                                id="telefone"
                                value={telefone}
                                onChange={e => this.setState({ telefone: e })}
                            />

                            <Input
                                name="celular"
                                type="text"
                                label="Celular (opcional)"
                                id="celular"
                                value={celular}
                                onChange={e => this.setState({ celular: e })}
                            />

                            <Input
                                name="cep"
                                type="text"
                                label="CEP"
                                id="cep"
                                value={cep}
                                onChange={e => this.cangeCep(e)}
                                onBlur={(e) => this.fetchCep(e.target.value)}
                                erro={this.state.erroCep}
                                messageErro={this.state.erroCepMsg}
                                loading={this.state.loadingCep}
                                disabled={this.state.loadingCep}
                            />

                            <Input
                                name="endereco"
                                type="text"
                                label="Endereço"
                                id="endereco"
                                value={endereco}
                                onChange={e => this.setState({ endereco: e, erroEndereco: false })}
                                erro={this.state.erroEndereco}
                                messageErro={this.state.erroEnderecoMsg}
                                loading={this.state.loadingCep}
                                disabled={this.state.loadingCep}
                            />

                            <Input
                                name="numero"
                                label="Número"
                                id="numero"
                                value={numero}
                                onChange={e => {
                                    this.setState({ numero: e, erroNumero: false })
                                }}
                                erro={this.state.erroNumero}
                                messageErro={this.state.erroNumeroMsg}
                            />

                            <Input
                                name="complemento"
                                type="text"
                                label="Complemento (Opcional)"
                                id="complemento"
                                value={complemento}
                                onChange={e => this.setState({ complemento: e })}
                            />

                            <Input
                                name="bairro"
                                type="text"
                                label="Bairro"
                                id="bairro"
                                value={bairro}
                                onChange={e => this.setState({ bairro: e, erroBairro: false })}
                                erro={this.state.erroBairro}
                                messageErro={this.state.erroBairroMsg}
                                loading={this.state.loadingCep}
                                disabled={this.state.loadingCep}
                            />

                            <Input
                                name="cidade"
                                type="text"
                                label="Cidade"
                                id="cidade"
                                value={cidade}
                                onChange={e => this.setState({ cidade: e, erroCidade: false })}
                                erro={this.state.erroCidade}
                                messageErro={this.state.erroCidadeMsg}
                                loading={this.state.loadingCep}
                                disabled={this.state.loadingCep}
                            />

                            <Select
                                name="estado"
                                className="select"
                                label="Estado"
                                id="estado_id"
                                value={estado}
                                onChange={state => this.setState({ estado: state.value, erroEstado: false })}
                                options={estados}
                                estado={true}
                                disabled={this.state.estado.value || this.state.estado ? true : false}
                                erro={this.state.erroEstado}
                                messageErro={this.state.erroEstadoMsg}
                                loading={this.state.loadingCep}
                            />

                            <div id="a" style={{
                                height: '0px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '40px'
                            }}>
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='CONSULTAR INPEV'
                                    outline={true}
                                    disabled={false}
                                    onClick={() => window.open('https://www.inpev.org.br/logistica-reversa/unidades-recebimento/', '_blank')}
                                    loading={this.state.loading}
                                />
                            </div>
                            
                            <div id="a" style={{
                                height: '0px',
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '40px',
                            }}>
                                <Button 
                                    width='100%'
                                    height='50px'
                                    value='CADASTRAR'
                                    outline={false}
                                    disabled={false}
                                    onClick={() => this.handlePost()}
                                    loading={this.state.loading}
                                />
                            </div>
                        </form>
                    </Container>
                </DevolucaoAddContainer>
            </>
        );
    }
}

export default AddDevolucao;
