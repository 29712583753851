import React, { component } from 'react';
// import { Modal, Row, Col } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Button from '../button/index';
import ModalTermos from './modalTermos';
import ModalPoliticas from './modalPoliticas';
import Modal from '.';
import { ModalBody, ModalTitle } from './styles';
// parametros_necessários{
//     termos: true || false,
//     title: 'string',
//     text: ['array', 'array'],
//     checkboxText: 'string',
//     btnOk: true || false,
//     btnCancelar: true || false
// }

class ModalNew extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        check: 0,
        checkTermos: 0,
        showTermos: false,
        showPoliticas: false
    }

    styles = {
        control: provided => ({
            ...provided,
            padding: "15px"
        })
    };

    updateOk = () => {
        if(this.props.termos == true){ //se o modal for usado para os termos
            this.props.update(this.state.checkTermos); //retorna se checkbox está marcado ou não
        }else{
            this.props.update(this.state.check); //retorna se chackbox comum está marcado ou não
        }
    }

    updateCancelar = () => {
        if(this.props.termos == true){ //se o modal for usado para os termos
            this.props.update(0); //retorna se checkbox desmarcado
        }else{
            this.props.update(0); //retorna se chackbox desmarcado
        }
    }

    validateCheck = e => {
        if(this.state.check == 0){
            this.setState({ check: e });
        }else{
            this.setState({ check: 0});
        }
    }



    //termos
    validateTermos = async e => {
        if(this.state.checkTermos == 0){
            this.setState({ checkTermos: e });
        }else{
            this.setState({ checkTermos: 0 });
        }
    }

    openTermos = async e => {
        this.setState({ showTermos: true });        
    }

    openPoliticas = async e => {
        this.setState({ showPoliticas: true });        
    }

    closeModal = () => {
        if(this.state.showTermos == true){
            this.setState({ showTermos: false });        
        }

        if(this.state.showPoliticas == true){
            this.setState({ showPoliticas: false });        

        }

    }



    render() {
        return (

            // <Modal
            //     {...this.props}
            //     size="150px"
            //     aria-labelledby="contained-modal-title-vcenter"
            //     centered
            // >

            //     {this.props.noHeader ? (null) : (
            //         <Modal.Header closeButton style={{ backgroundColor: " #f8f8f8" }}>
            //         </Modal.Header >
            //     )}

            //     <Modal.Body style={{ backgroundColor: " #f8f8f8", borderRadius: '3px' }}>

            //         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>
            //             {/* Titulo */}
            //             {this.props.title ? (<>
            //                 <p style={{ fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center' }}>
            //                     {this.props.title}
            //                 </p>
            //             </>) : (null)}




            //             {/* textos */}
            //             {/* deve receber um array de textos para exibir paragrafos separados */}
            //             {this.props.text && this.props.text != [] ? (<>
                            
            //                 {this.props.text.map(txt => (
            //                     <>
            //                         <p style={{ marginTop: '-10px', color: '#000', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center' }}>
            //                             {txt}
            //                         </p>
            //                     </>
            //                 ))}

            //             </>):(null)}


            //             {/* checkbox */}
            //             {/* passar parametro adicional para termos de uso e politica de privacidade como true somente para exibir o checkbox já definido para este caso */}
            //             {this.props.termos == true ? (<>
            //                 <div className="term" style={{width: "100%", display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center'}}>
            //                     <input type="checkbox" id="termos" value="1" onChange={e => this.validateTermos(e.target.value)} style={{ marginTop: "-8px" }}/> 
            //                     <label for="termos" className="lblTermos" style={{ fontSize: "11px", marginBottom: "0px", marginLeft: "10px" }}> Li e concordo com os <label className="link" style={{ color: "#589bff", cursor: "pointer" }} onClick={() => this.openTermos()}>Termos de uso</label> e a <label className="link" style={{ color: "#589bff", cursor: "pointer" }} onClick={() => this.openPoliticas()}>Politica de Privacidade</label>  </label>
            //                 </div>
            //             </>):(<>
            //                 {this.props.checkboxText ? (
            //                     <div className="term" styles={{width: "100%", display: "flex", flexDirection: "row"}}>
            //                         <input type="checkbox" id="cbx" value="1" onChange={e => this.validateCheck(e.target.value)} style={{ marginTop: "2px" }}/> 
            //                         <label for="cbx" className="lblText" style={{ fontSize: "11px", marginBottom: "0px", marginLeft: "10px" }}> {this.props.checkboxText} </label>
            //                     </div>
            //                 ):(null)}
            //             </>)}


            //             <div className="btns" style={{ display: 'flex' }}>
            //                 {this.props.btnOk == true ? (
            //                     <Button
            //                         style={{ outline: 0, padding: "10px", width: "100px", margin: "auto", marginTop: '15px' }}
            //                         color="#00b43f"
            //                         onClick={() => this.updateOk()}
            //                     >
            //                         OK
            //                     </Button>
            //                 ) : (null)}
            //                 {this.props.btnCancelar == true ? (
            //                     <Button
            //                         style={{ outline: 0, padding: "10px", width: "100px", margin: "auto", marginTop: '15px' }}
            //                         color="#F00"
            //                         onClick={() => this.updateCancelar()}
            //                     >
            //                         CANCELAR
            //                     </Button>
            //                 ) : (null)}
            //             </div>
            //         </div>



            //         <ModalTermos
            //             show={this.state.showTermos}
            //             // title="Termos de uso"
            //             // text="What is Lorem Ipsum?
            //             // Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        
            //             // Why do we use it?
            //             // It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
            //             btnOk={true}
            //             onHide={this.closeModal}
            //         ></ModalTermos>

            //         <ModalPoliticas
            //             show={this.state.showPoliticas}
            //             // title="Politica de Privacidade"
            //             // text="What is Lorem Ipsum?
            //             // Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        
            //             // Why do we use it?
            //             // It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
            //             btnOk={true}
            //             onHide={this.closeModal}
            //         ></ModalPoliticas>
            //     </Modal.Body>
            // </Modal>



            <Modal
                {...this.props}
                align='center' // center, end, start
                justify='center' // center, end, start
                responsive
                // width='150px'
            >

                <ModalTitle>
                        {/* Titulo */}
                        {this.props.title ? (<>
                            <p style={{ fontFamily: 'Lato', fontSize: '22px', fontWeight: 'bold', fontStretch: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center' }}>
                                {this.props.title}
                            </p>
                        </>) : (null)}
                </ModalTitle>

                <ModalBody textAlign='start'>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around', flexDirection: 'column' }}>

                        {/* textos */}
                        {/* deve receber um array de textos para exibir paragrafos separados */}
                        {this.props.text && this.props.text != [] ? (<>
                            
                            {this.props.text.map(txt => (
                                <>
                                    <p style={{ marginTop: '-10px', color: '#000', fontFamily: 'Lato', fontSize: '16px', fontWeight: 'normal', width: '100%', textAlign: 'center', alignSelf: 'center' }}>
                                        {txt}
                                    </p>
                                </>
                            ))}

                        </>):(null)}


                        {/* checkbox */}
                        {/* passar parametro adicional para termos de uso e politica de privacidade como true somente para exibir o checkbox já definido para este caso */}
                        {this.props.termos == true ? (<>
                            <div className="term" style={{width: "100%", display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: 'center'}}>
                                <input type="checkbox" id="termos" value="1" onChange={e => this.validateTermos(e.target.value)} style={{ marginTop: "-8px" }}/> 
                                <label for="termos" className="lblTermos" style={{ fontSize: "11px", marginBottom: "0px", marginLeft: "10px" }}> Li e concordo com os <label className="link" style={{ color: "#589bff", cursor: "pointer" }} onClick={() => this.openTermos()}>Termos de uso</label> e a <label className="link" style={{ color: "#589bff", cursor: "pointer" }} onClick={() => this.openPoliticas()}>Politica de Privacidade</label>  </label>
                            </div>
                        </>):(<>
                            {this.props.checkboxText ? (
                                <div className="term" styles={{width: "100%", display: "flex", flexDirection: "row"}}>
                                    <input type="checkbox" id="cbx" value="1" onChange={e => this.validateCheck(e.target.value)} style={{ marginTop: "2px" }}/> 
                                    <label for="cbx" className="lblText" style={{ fontSize: "11px", marginBottom: "0px", marginLeft: "10px" }}> {this.props.checkboxText} </label>
                                </div>
                            ):(null)}
                        </>)}


                        <div className="btns" style={{ display: 'flex' }}>
                            {this.props.btnOk == true ? (
                                <Button
                                    style={{ outline: 0, padding: "10px", width: "100px", margin: "auto", marginTop: '15px' }}
                                    color="#00b43f"
                                    onClick={() => this.updateOk()}
                                >
                                    OK
                                </Button>
                            ) : (null)}
                            {this.props.btnCancelar == true ? (
                                <Button
                                    style={{ outline: 0, padding: "10px", width: "100px", margin: "auto", marginTop: '15px' }}
                                    color="#F00"
                                    onClick={() => this.updateCancelar()}
                                >
                                    CANCELAR
                                </Button>
                            ) : (null)}
                        </div>
                    </div>
                </ModalBody>

                <ModalTermos
                      show={this.state.showTermos}
                      // title="Termos de uso"
                      // text="What is Lorem Ipsum?
                      // Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                        
                      // Why do we use it?
                      // It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
                      btnOk={true}
                      onHide={this.closeModal}
                ></ModalTermos>

                <ModalPoliticas
                    show={this.state.showPoliticas}
                    // title="Politica de Privacidade"
                    // text="What is Lorem Ipsum?
                    // Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                    
                    // Why do we use it?
                    // It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)."
                    btnOk={true}
                    onHide={this.closeModal}
                ></ModalPoliticas>
            </Modal>

        );
    }
}
export default ModalNew;