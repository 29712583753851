import styled from "styled-components";

export const Card = styled.div`
    width: ${props => props.width ? props.width : '29.5%'};
    /* min-width: 285px; */
    height: ${props => props.height ? props.height : '100%'};
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
    padding: 0px 10px 5px 10px;
    flex-direction: ${props => props.flexDirection ? props.flexDirection : 'row'};
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    flex-wrap: ${props => props.flexWrap ? props.flexWrap : 'wrap'};

    @media only screen and (max-width: 843px){
      margin-bottom: 16px;
    }
`;

export const Row = styled.div`
    width: 100%;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: ${props => props.justifyContent ? props.justifyContent : 'center'};
    align-items: ${props => props.alignItems ? props.alignItems : 'center'};
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'};
    margin-bottom: ${props => props.marginBottom ? props.marginBotom : '-10px'};

    .item-dados{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: ${props => props.isMobile === true ? '13px' : '11px'};
        margin-bottom: 1px;
        margin-left: ${props => props.isMobile === true ? '25px' : '0px'};

        img{
            margin-left: -109px;
            position: absolute;
        }

        #msgVentosCompleta{
            display: none;
        }

        &:hover{
            #msgVentosCompleta{
                transform: scale(1.1);
                display: flex;
                justify-content: center;
                position:absolute;
                font-size: 10px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                text-align: center;
                align-items: center;
                color:#FFF;
                background-color: #1d2129;
                width: 200px !important;
                height: 18px;
                opacity: 0.9;
                border-radius: 5px;
                margin-bottom: 40px;
                margin-right: 40px;

                &:after{
                    display: inline-block;
                    position: absolute;
                    background-color: #1d2129;
                    width: 6px;
                    height: 6px;
                    top: 14px;
                    margin-left: 40px;
                    content: '';
                    transform: rotate(45deg);
                }
            }
        }
    }

    .item-dados .msgVentos{
        white-space: nowrap;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
        cursor: default;
    }

    .ver_mais{
      width: 50px;
      display: flex;
      font-size: 10px;
      align-items: center;
      margin-left: ${props => props.marginLeft ? props.marginLeft : '30px'};
      /* margin-top: ${props => props.marginTop ? props.marginTop : '20px'}; */
      font-family: Lato;
      font-size: ${props => props.fontSize ? props.fontSize : '10px'};
      color: #589bff;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      -webkit-letter-spacing: normal;
      -moz-letter-spacing: normal;
      -ms-letter-spacing: normal;
      letter-spacing: normal;
    }

    /* @media only screen and (max-width: 815px) {
        width: 100%;
        flex-direction: column;
    } */
`;

export const Title = styled.h2`
    /* color: #000;
    font-size: 20px; */
    width: 100%;
    font-family: Lato;
    font-size: ${props => props.fontSize ? props.fontSize : '10px'};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #8a92a2;
    text-align: ${props => props.textAlign ? props.textAlign : 'left'};
    margin: 0px;
    /* margin-left: ${props => props.marginLeft ? props.marginLeft : '20px'};
    margin-top: ${props => props.marginTop ? props.marginTop : '27px'};
    margin-bottom: ${props => props.marginBottom ? props.marginBottom : '0px'}; */
`;

export const CardResumo = styled.div`
width:100%;

  .titulo{
    font-family: Lato;
    color: #303848;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    font-weight:bold;
  }

  .corpo{
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #303848;
  }

  .row{
      background-color: black;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    .tipo-0{
      margin-top: 16px;
      border: solid 1px #adaeb1;
      border-radius: 3px;
      background-color: #adaeb1;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-1{
      margin-top: 16px;
      border: solid 1px #03903e;
      border-radius: 3px;
      background-color: #03903e;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-2{
      margin-top: 16px;
      border: solid 1px #d9261d;
      border-radius: 3px;
      background-color: #d9261d;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-3{
      margin-top: 16px;
      border: solid 1px #589bff;
      border-radius: 3px;
      background-color: #589bff;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    .tipo-4{
      margin-top: 16px;
      border: solid 1px #2fbb69;
      border-radius: 3px;
      background-color: #2fbb69;
      padding: 3px 5px;
      width: auto;

      p{
        margin: 0px;
        text-align: center;
        color: white;
        font-family: Lato;
        font-size: 12px;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }

    }

    

    .bolinha-vermelha{
      width: 8px;
      height: 8px;
      position: absolute;
      background-color: #d9261d;
      margin-left: 202px;
      margin-top: 3px;
      border: solid 1px #d9261d;
      border-radius: 50%;
    }

    .bolinha-azul{
      width: 8px;
      height: 8px;
      position: absolute;
      background-color: #589bff;
      margin-left: 202px;
      margin-top: 3px;
      border: solid 1px #589bff;
      border-radius: 50%;
    }

    .bolinha-verde{
      width: 8px;
      height: 8px;
      position: absolute;
      background-color: #2fbb69;
      margin-left: 202px;
      margin-top: 3px;
      border: solid 1px #2fbb69;
      border-radius: 50%;
    }
 
  .react-kanban-board{
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: flex-start;  
  padding:5px
}
.react-kanban-card{
    width: 95%;
    border-radius:3px;
    background-color:#fff;
    padding:6px;
    margin: 11px 8px 0px 6px;
  
}

.react-kanban-card-skeleton,.react-kanban-card,.react-kanban-card-adder-form{
  box-sizing:border-box;
  white-space: nowrap;
  width:95%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 1px 1px 3px 0px rgb(29 33 41 / 20%);
    border-left: solid 3px #2FBB69;
}

.react-kanban-card--dragging{
  box-shadow: 1px 1px 3px 0px rgb(29 33 41 / 80%);
  }

  .react-kanban-card__description{
    padding-top:10px;
    word-wrap: break-word;
    width:215px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .react-kanban-card__title{
  border-bottom:1px solid #eee;
  padding-bottom:5px;
  font-weight:bold;
  display:flex;
  justify-content:space-between;
}

.react-kanban-column{
  padding:15px;
  border-radius:2px;
  background-color:#fff;
  border-radius: 10px;
  margin:5px;
  box-shadow: 1px 2px 5px 1px rgb(29 33 41 / 40%);
}

.react-kanban-column input:focus{
  outline:none;
}

  .react-kanban-card-adder-form{
  border-radius:3px;
  background-color:#fff;
  padding:10px;
  margin-bottom:7px;
}

.react-kanban-card-adder-form input{
  border:0px;
  font-family:inherit;
  font-size:inherit;
}
.react-kanban-card-adder-button{
  width:100%;
  margin-top:5px;
  background-color:transparent;
  cursor:pointer;
  border:1px solid #ccc;
  transition:0.3s;
  border-radius:3px;
  font-size:20px;
  margin-bottom:10px;
  font-weight:bold;
}

.react-kanban-card-adder-button:hover{
  background-color:#ccc;
  }

  .react-kanban-card-adder-form__title{
  font-weight:bold;
  border-bottom:1px solid #eee;
  padding-bottom:5px;
  font-weight:bold;
  display:flex;
  justify-content:space-between;
  width:100%;
  padding:0px;
}

.react-kanban-card-adder-form__title:focus{
  outline:none;
  }

  .react-kanban-card-adder-form__description{
  width:100%;
  margin-top:10px;
}

.react-kanban-card-adder-form__description:focus{
  outline:none;
  }

  .react-kanban-card-adder-form__button{
  background-color:#eee;
  border:none;
  padding:5px;
  width:45%;
  margin-top:5px;
  border-radius:3px;
}

.react-kanban-card-adder-form__button:hover{
  transition:0.3s;
  cursor:pointer;
  background-color:#ccc;
}

.react-kanban-column-header{
  padding-bottom:10px;
  font-weight:bold;
}

.react-kanban-column-header input:focus{
  outline:none;
  }

  .react-kanban-column-header__button{
    color:#333333;
  background-color:#ffffff;
  border-color:#cccccc;
}

.react-kanban-column-header__button:hover,.react-kanban-column-header__button:focus,.react-kanban-column-header__button:active{
  background-color:#e6e6e6;
  }

  .react-kanban-column-adder-button{
    border:2px dashed #eee;
  height:132px;
  margin:5px;
}

.react-kanban-column-adder-button:hover{
  cursor:pointer
}
`;

