import styled from "styled-components";

export const InputContainer = styled.div`
    background-color: #FFF;
    width: 360px;
    height: 37px;
    border-radius: 25px;
    box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 0px;

    .search-button{
        /* padding: 18px 5px;
        margin-right: 10px; */
        cursor: pointer;

    }

    .inputs{
        display: flex;
        flex-direction: row;
        margin-top: 1px;
    }

    .xis{
        position: absolute;
        width: 16px;
        height: 16px;

        left: 80%;
        font-family: Lato;
        font-size: 12px;
        margin-top: 21px;
        margin-left: -169px;
        z-index: 1000;
        font-weight: bold;
        padding: 11px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        border: 0px solid;
        border-radius: 50%;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 5px;
        letter-spacing: normal;
        color: ${props => props.erro ? '#ff5252' : '#bac1cf'};
        display: flex;
        flex-direction:row;
        align-items: center;
        justify-content: center;
        transition:all 0.3s ease;
        cursor:pointer;
        #msg{
            display:none;
        }
        &:hover{
            transform: scale(1.3);
            #msg {
            display: flex;
            justify-content: center;
            position:absolute;
            font-size: 10px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            text-align: center;
            align-items: center;
            color:#FFF;
            background-color: #1d2129;
            width: 90px;
            height: 18px;
            opacity: 0.9;
            border-radius: 5px;
            margin-bottom: 48px;
            margin-right: 40px;

            &:after{
                display:flex;
                position: absolute;
                background-color:  #1d2129;
                width: 6px;
                height: 6px;
                top: 14px;
                margin-left: 40px;
                content: '';
                transform: rotate(45deg);
            }
            }
        }

            @media only screen and (max-width: 768px) {
                margin-top: 16px;
                margin-left: -149px;
            }


        .add-select {
            transition: all 0.2s !important;
            transform: rotate(45deg);
            &:hover {
            cursor: pointer !important;
            }

            &:active {

            }
        }
    }


    input{
        border: none;
        width: ${props => (props.estado ? "239px" : "305px")};
        height: 32px;
        margin-left: ${props => props.documento ? '35px' : '25px'};
        margin-top: 2px;

        &::-webkit-input-placeholder{
            font-size: 13px;
        }
    }

    label{
        width: 94px;
        height: 11px;
        font-family: Lato;
        font-size: 9px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: right;
        color: #303848;
        margin-top: 25px;
    }
    img{
        width: 13px;
        height: 13px;
        margin-top: 12px;
        margin-right: 10px;
    }

    select{
        border: none;
        font-family: Lato;
        font-size: 9px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.5px;
        text-align: right;
        color: #303848;
        margin-right: 5px;
        background-color: #FFF;

        #value {
            text-align: left;
        }
        
    }


    @media only screen and (max-width: 768px) {
        max-width: 500px;
        height: ${props => (props.estado ? "100px" : "60px")};
        margin: auto;

        .search-button{
            width: 12%;
            cursor: pointer;

        }

        .inputs{
            display: flex;
            flex-direction: ${props => (props.estado ? "column" : "row")};
            margin-top: 2px;
            width: ${props => props.pesquisa && '70%'};
        }

        input{
            border-radius: 25px;
            height: 60px;
            margin-left: ${props => (props.estado ? "0px" : "25px")};
            margin-top: ${props => (props.estado ? "0px" : "-2px")};
            max-width: 400px;
            &::-webkit-input-placeholder{
            font-size: 11px;
        }
        }
    
        label{
            width: 100%;
            max-width: 94px;
            height: 11px;
            font-family: Lato;
            font-size: 9px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: right;
            color: #303848;
            margin-top: 25px;
        }

        img{
            width: ${props => (props.estado ? "20px" : "16px")};
            height: ${props => (props.estado ? "20px" : "16px")};
            margin-top: ${props => (props.estado ? "20px" : "22px")};
            margin-left: ${props => (props.estado ? "12px" : "0px")};
        }
    
        select{
            border: none;
            width: 119%;
            font-family: Lato;
            font-size: 9px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: right;
            color: #303848;
            margin-right: 5px;
            background-color: #FFF;
        }

    }

    @media only screen and (max-width: 540px){
        width: 100%;

        select{
            width: 100%;
        }
    }

`;
