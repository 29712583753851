import styled from "styled-components";

export const NotificationStyle = styled.div`

  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 1px 2px 5px 1px rgba(29, 33, 41, 0.4);
  background-color: #FFF;
  /* margin-left: 15px;
  margin-right: 15px; */
  padding: 16px;

  .header-notifica{
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    /* padding: 0px 16px 8px 16px; */

    .ver{
      display:flex;
      flex-direction: row;
    }
  }

  .desc{
    width: 100%;
    margin: auto;



    .title-notification{
      .title-not{
        margin-top: 20px;
        margin-bottom: 0px;
        font-family: Lato;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.31;
        letter-spacing: normal;
        color: #1d2129;
      }

      .date{
        font-family: Lato;
        font-size: 11px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #bac1cf;
      }
    }

    .text{
      width: 100%;
      margin-top: 20px;
      /* max-height: 85px; */
      font-family: Lato;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.54;
      letter-spacing: normal;
      color: #8a92a2;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  
  h2{
    font-family: Lato;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #8a92a2;
  }
  
  img{
    color: #8a92a2;
    width: 26px;
    height: 26px;
    margin-top: -5px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  label{
    font-family: Lato;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #589bff;
    cursor: pointer;
  }
  
  hr{
    width: 96%;
    margin: 2px;
  }


  @media only screen and (max-width: 843px){
    margin-top: 16px;
  }
`;













